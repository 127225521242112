export default {
  status: [],
  executionStatus: [
    'ERROR',
    'MERGE_ERROR',
    'INVALID_CREDENTIALS',
    'ALREADY_LOGGED_IN',
    'SITE_NOT_AVAILABLE',
    'INVALID_CREDENTIALS_MFA',
    'USER_INPUT_TIMEOUT',
    'ACCOUNT_LOCKED',
    'ACCOUNT_NEEDS_ACTION',
    'CONNECTION_ERROR',
    'USER_AUTHORIZATION_NOT_GRANTED'
  ]
}
