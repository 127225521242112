<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="640px"
    :fullscreen="isMobile"
  >
    <v-card class="no-radius" color="darkgrey">
      <v-card-text :class="isIOS ? 'ios-padding' : ''">
        <v-container fluid grid-list-md>
          <v-layout row wrap class="pt-6">
            <v-flex xs12>
              <h1 class="white--text">uplanner agora é Open!</h1>
              <p class="mt-1">
                Agora você pode sincronizar seu extrato bancário e fatura de cartão, graças a nossa parceria com a
                Pluggy, empresa líder em Open Finance no Brasil e na América Latina!
              </p>
            </v-flex>

            <v-flex xs12 class="mt-2">
              <h2 class="white--text">Funciona assim</h2>
              <p class="mt-1 mb-1">
                Você tem o poder de compartilhar de forma segura e simples seus dados financeiros com a UPlanner.
              </p>
              <p>
                Com o seu consentimento, nós faremos um modo leitura da sua conta ou fatura do cartão para listar suas
                transações dentro do nosso app!
              </p>

              <p class="mb-0">
                <b>Bancos disponíveis para sincronização</b>
              </p>

              <div class="d-flex align-center">
                <div class="d-flex align-center mr-5">
                  <div class="indicator indicator__green mr-2"></div>
                  <p class="pa-0 ma-0">Conta corrente</p>
                </div>

                <div class="d-flex align-center">
                  <div class="indicator indicator__yellow mr-2"></div>
                  <p class="pa-0 ma-0">Cartão de crédito</p>
                </div>
              </div>

              <div class="d-flex align-center flex-wrap my-4">
                <div
                  :key="bank.icon"
                  v-for="bank in banks"
                  class="d-flex flex-column align-center mr-2 mt-2"
                >
                  <img :src="getImgUrl(bank.icon)" class="bank-icon">

                  <div class="d-flex align-center justify-center mt-2">
                    <div class="indicator indicator__green mr-1" v-if="bank.conta"></div>
                    <div class="indicator indicator__yellow" v-if="bank.cartao"></div>
                  </div>
                </div>
              </div>
            </v-flex>

            <v-flex xs12>
              <v-btn
                large
                block
                color="primary"
                @click="useAutomatic"
                :loading="syncLoader"
              >
                Sincronizar {{ typeDesc }}
              </v-btn>
            </v-flex>

            <v-flex xs12>
              <v-btn
                large
                block
                color="primary"
                @click="useManual"
                :disabled="syncLoader"
              >
                Adicionar {{ typeDesc }} manual
              </v-btn>
            </v-flex>

            <v-flex xs12>
              <v-btn
                text
                large
                block
                color="white"
                @click="closeDialog"
                :disabled="syncLoader"
              >
                Cancelar
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import pluggyWidget from "@/utils/pluggy/pluggyWidget";

export default {
  name: 'DialogAddAccount',
  data: () => ({
    dialog: false,
    linkId: null,
    syncLoader: false,
    type: 'bank-account',
    banks:[
      {
        icon: 'nubank.png',
        conta: true,
        cartao: true
      },
      {
        icon: 'itau.png',
        conta: true,
        cartao: true
      },
      {
        icon: 'santander.png',
        conta: true,
        cartao: true
      },
      {
        icon: 'bradesco.png',
        conta: true,
        cartao: true
      },
      {
        icon: 'inter.png',
        conta: true,
        cartao: false
      },
      {
        icon: 'bb.png',
        conta: true,
        cartao: true
      },
      {
        icon: 'caixa.png',
        conta: true,
        cartao: true
      },
      {
        icon: 'mp.png',
        conta: true,
        cartao: false
      },
      {
        icon: 'modal.png',
        conta: true,
        cartao: false
      },
      {
        icon: 'c6.png',
        conta: true,
        cartao: true
      }
    ]
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    typeDesc() {
      return this.type === 'bank-account' ? 'conta' : 'cartão';
    },
    isCustomer() {
      return this.user && this.user.role === 'customer'
    },
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    getImgUrl(name) {
      let images = require.context('../../../../assets/banking/', false, /\.png$/)
      return images('./' + name)
    },
    openDialog({type, linkId}) {
      if (type) {
        this.type = type;
      }

      if (this.user && this.user.permissions.openBanking) {
        if (linkId) {
          this.linkId = linkId;
        }

        this.dialog = true;
      } else {
        this.useManual()
      }
    },
    closeDialog() {
      this.dialog = false;
      this.linkId = null;
      this.syncLoader = false;
      this.type = 'bank-account';
    },
    useManual() {
      if (this.type === 'bank-account') {
        this.$bus.$emit('showDialogFormBankAccount');
      } else if (this.type === 'credit-card') {
        this.$bus.$emit('showDialogFormCreditCard');
      }

      this.closeDialog();
    },
    async useAutomatic() {
      try {
        this.syncLoader = true

        pluggyWidget.loadScript()
        await pluggyWidget.useAutomatic(this.itemId, pluggyWidget.onSuccess, this.$bus)

        this.syncLoader = false
        this.closeDialog()
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.syncLoader = false
        this.$noty.error('Desculpe! Houve um problema! Tente novamente mais tarde!')
      }
    }
  },
  created() {
    this.$bus.$on('showDialogAddAccount', this.openDialog)
  }
}
</script>

<style scoped>
.v-card__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  line-height: 1em;
  letter-spacing: .05em;
}

.v-btn {
  text-transform: none;
}

.indicator {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.indicator__green {
  background-color: #63CB24;
}

.indicator__yellow {
  background-color: #CFA12A;
}

.bank-icon {
  width: 35px;
  border-radius: 5px;
}

@media only screen and (min-width: 600px) {
  .indicator {
    width: 10px;
    height: 10px;
  }

  .bank-icon {
    width: 48px;
  }
}
</style>
