<template>
  <v-main>
    <v-container grid-list-md>
      <v-alert
        v-if="existPendingRequestReward && user.role === 'admin'"
        dense
        color="error"
      >
        <v-row dense align="center">
          <v-col>
            Nova solicitação de Reembolso </v-col>
          <v-col class="shrink">
            <v-btn color="grey3" @click="redirectRecommendations">Detalhes</v-btn>
          </v-col>
        </v-row>

      </v-alert>

      <v-layout row wrap>
        <v-flex lg4 xs4 v-if="user.role === 'admin'">
          <CardTotalUsers />
        </v-flex>

        <v-flex lg4 xs4 v-if="user.role === 'admin'">
          <CardTotalActiveUsers />
        </v-flex>

        <v-flex
          :lg4="user.role === 'admin'"
          :sm12="user.role === 'comercial'"
          xs4
          v-if="['admin', 'comercial'].includes(user.role)"
        >
          <CardTotalNewUsers />
        </v-flex>

        <v-flex xs12 sm12 md6 lg6 v-if="user.role === 'admin'">
          <CardChartUsers/>
        </v-flex>

        <v-flex xs12 sm12 md6 lg6 v-if="user.role === 'admin'">
          <CardChartGroupedUsers />
        </v-flex>

        <v-flex xs12>
          <CardTableUsers />
        </v-flex>
      </v-layout>
    </v-container>

    <DialogFormPlanner :estados="estados" />
    <DialogFormCompany :estados="estados" />

    <DialogCompanyDetails />
    <DialogUpdateUserPlan />
    <DialogCalendlyPlanner/>
    <DialogImportUsers/>
    <DialogUploadUsersResult/>
    <DialogUpdateUserIuguSubscription/>
    <DialogChangeUserPlaner/>
    <DialogFormSupport/>
    <DialogUserContacts/>
    <DialogChangePlannerSupervisor v-if="user && user.role === 'admin'"/>
  </v-main>
</template>

<script>
import CardChartUsers from '@/components/Manager/dashboard/CardChartUsers';
import CardTableUsers from '@/components/Manager/dashboard/CardTableUsers';
import CardTotalUsers from '@/components/Admin/dashboard/cards/CardTotalUsers';
import DialogUpdateUserPlan from '@/components/Admin/dialogs/DialogUpdateUserPlan';
import DialogFormAdmin from '@/components/Manager/dashboard/forms/DialogFormAdmin';
import CardTotalNewUsers from '@/components/Admin/dashboard/cards/CardTotalNewUsers';
import DialogFormPlanner from '@/components/Manager/dashboard/forms/DialogFormPlanner';
import DialogFormCompany from '@/components/Manager/dashboard/forms/DialogFormCompany';
import DialogFormCustomer from '@/components/Manager/dashboard/forms/DialogFormCustomer';
import CardChartGroupedUsers from '@/components/Manager/dashboard/CardChartGroupedUsers';
import CardTotalActiveUsers from '@/components/Admin/dashboard/cards/CardTotalActiveUsers';
import DialogPasswordResetLink from '@/components/Manager/dashboard/DialogPasswordResetLink';
import DialogFormEmailUpdate from '@/components/Manager/dashboard/forms/DialogFormEmailUpdate';
import DialogCompanyDetails from '@/components/Manager/dashboard/dialogs/DialogCompanyDetails';
import estados from '@/utils/estados';
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';
import CardUsersTimeSeries from '@/components/Dashboard/cards/CardUsersTimeSeries';
import DialogCalendlyPlanner from '@/components/Admin/dialogs/DialogCalendlyPlanner';
import DialogImportUsers from '@/components/Admin/dialogs/DialogImportUsers';
import DialogUploadUsersResult from '@/components/Admin/dialogs/DialogUploadUsersResult';
import getRewardRequests from '@/api/rewardRequests/getRewardRequests';
import verifyPendingRewardRequest from '../api/rewardRequests/verifyPendingRewardRequest';
import DialogUpdateUserIuguSubscription from '@/components/Admin/dialogs/DialogUpdateUserIuguSubscription';
import DialogChangeUserPlaner from "@/components/Admin/dialogs/DialogChangeUserPlanner";
import DialogFormSupport from "@/components/Manager/dashboard/forms/DialogFormSupport";
import DialogUserContacts from "@/components/Manager/dashboard/dialogs/DialogUserContacts";
import DialogChangePlannerSupervisor from "@/components/Admin/dialogs/DialogChangePlannerSupervisor";

export default {
  name: 'Admin',
  components: {
    DialogChangePlannerSupervisor,
    DialogUserContacts,
    DialogFormSupport,
    DialogChangeUserPlaner,
    DialogUpdateUserIuguSubscription,
    DialogUploadUsersResult,
    DialogImportUsers,
    DialogCalendlyPlanner,
    CardUsersTimeSeries,
    CardChartUsers,
    CardTotalUsers,
    CardTableUsers,
    DialogFormAdmin,
    CardTotalNewUsers,
    DialogFormPlanner,
    DialogFormCompany,
    DialogFormCustomer,
    DialogUpdateUserPlan,
    CardTotalActiveUsers,
    DialogCompanyDetails,
    CardChartGroupedUsers,
    DialogFormEmailUpdate,
    DialogPasswordResetLink
  },
  data: () => ({
    estados,
    existPendingRequestReward: false
  }),
  methods: {
    redirectRecommendations() {
      this.$router.push('/admin/recommendations')
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    requestReward() {
      return this.$store.getters.requestReward
    },
  },
  created() {
    verifyPendingRewardRequest()
      .then(resp => {
        this.existPendingRequestReward = resp
      });
  }
};
</script>

<style scoped>
.v-main {
  background-color: #131313 !important;
}

.container {
  max-width: 1366px !important;
  margin-top: 46px;
}

.add-btn {
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}
</style>
