<template>
  <div v-ripple class="card-container">
    <div class="plan-infos-container" @click="showDialogPlanoAcao">
      <v-avatar
        size="40"
        class="mr-2"
        color="black"
      >
        <v-icon :color="getColors(plan.type.value)">
          mdi-flag-outline
        </v-icon>
      </v-avatar>

      <div class="plan-infos">
        <span class="plan-description white--text">{{ plan.description }}</span>
        <span class="plan-deadline grey1--text">{{ formatDate(plan.deadline) }}</span>
      </div>
    </div>

    <v-checkbox
      dense
      class="pa-0"
      color="primary"
      @change="updatePlan"
      v-model="plan.completed"
      v-if="!loader && allowed"
    ></v-checkbox>

    <v-progress-circular
      indeterminate
      v-if="loader"
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import saveActionPlan from '@/api/actionPlans/saveActionPlan';

export default {
  name: 'CardListPlanosAcao',
  props: ['actionPlan'],
  data: () => ({
    plan: null,
    loader: false
  }),
  methods: {
    copy() {
      this.plan = JSON.parse(JSON.stringify(this.actionPlan));
    },
    getColors(type) {
      switch (type) {
        case 1:
          return 'error';
        case 2:
          return 'warning';
        default:
          return 'success';
      }
    },
    formatDate(date) {
      if (date)
        return moment(date).add(3, 'hours').locale('pt-BR').format('DD MMM, YYYY');

      return '';
    },
    updatePlan() {
      if (this.allowed) {
        this.loader = true;

        saveActionPlan(this.plan)
          .then(actionPlan => {
            this.$store.dispatch('saveActionPlan', actionPlan);
            this.loader = false;
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível salvar este plano. Tente novamente mais tarde!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    showDialogPlanoAcao() {
      this.$bus.$emit('showDialogPlanoAcao', this.plan);
    }
  },
  computed: {
    allowed() {
      return this.$store.getters.allowed;
    }
  },
  watch: {
    actionPlan: {
      deep: true,
      handler() {
        this.copy();
      }
    }
  },
  created() {
    this.copy();
  }
}
</script>

<style scoped>
.card-container {
  padding: 10px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  border-radius: 2px;
  justify-content: space-between;
  border-bottom: 1px solid #919191;
}

.plan-infos-container {
  display: flex;
  align-items: center;
}

.plan-infos {
  display: flex;
  flex-direction: column;
}

.plan-description {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.plan-deadline {
  font-size: 10px;
  line-height: 1.5em;
  letter-spacing: .1em;
}
</style>
