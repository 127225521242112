import store from '../store/index';
import firebase from 'firebase/app';
import router from '../router/index';

export default () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      store.dispatch('logout');
      if (store.getters.isMobile) {
        router.push('/auth');
      } else {
        router.push('/login');
      }
    });
};
