import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const getIuguCustomerInvoices = (customerId, page = 1, status = '', countOnly = false) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const options = {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        const url = `/iugu/invoices/customer?customer_id=${customerId}&page=${page}&status=${status}&countOnly=${countOnly}`

        return httpClient(url, options)
      })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}

export default getIuguCustomerInvoices
