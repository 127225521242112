import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const createPassword = data => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            data,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }

          const url = '/users/firebase/create-password'

          return httpClient(url, options)
        })
        .then(() => resolve())
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default createPassword
