<template>
  <v-card class="elevation-12 mt-3 px-1">
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 lg6 md12>
          <v-form @submit.prevent="getUsers" class="events-form">
            <v-select
              label="Tipo de Evento"
              color="accent"
              :items="eventTypes"
              item-text="text"
              item-value="value"
              v-model="filter.type"
            ></v-select>

            <v-select
              label="Classificação"
              color="accent"
              :items="sortTypes"
              item-text="text"
              item-value="value"
              v-model="filter.sort"
            ></v-select>

            <div class="events-form-buttons">
              <v-btn rounded dark depressed class="ml-2" type="submit" color="darkgrey">
                <v-icon>mdi-cloud-search</v-icon>
              </v-btn>
            </div>
          </v-form>
        </v-flex>

        <v-flex xs12>
          <TableUserHistory :user="user" />
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import TableUserHistory from '../tables/TableUserHistory';

export default {
  name: 'TabHistory',
  components: { TableUserHistory },
  props: ['user'],
  data: () => ({
    eventTypes: [
      { text: 'Todos', value: '' },
      { text: 'Cadastro', value: 'signup' },
      { text: 'Login', value: 'signin' },
      { text: 'Habilitado', value: 'status-enabled' },
      { text: 'Desabilitado', value: 'status-disabled' },
      { text: 'Desabilitado', value: 'status-disabled' },
      { text: 'Troca de plano', value: 'plan-changed' },
      { text: 'Troca de senha', value: 'password-changed' },
      { text: 'Troca de email', value: 'email-changed' },
      { text: 'Troca de planejador', value: 'planner-changed' },
      { text: 'Troca de empresa', value: 'company-changed' },
      { text: 'Link de redefinição de senha', value: 'pass-reset-link' }
    ],
    sortTypes: [
      { text: 'Mais novos primeiro', value: -1 },
      { text: 'Mais antigos primeiro', value: 1 }
    ],
    filter: {
      type: '',
      sort: -1
    }
  }),
  methods: {
    getUsers() {
      this.$bus.$emit('getUserHistory', this.filter);
    }
  }
};
</script>

<style scoped>
.events-form {
  display: flex;
  align-items: center;
}

.events-form .v-select {
  margin-left: 10px;
  width: calc(50% - 10px);
}

@media only screen and (max-width: 599px) {
  .events-form {
    flex-direction: column;
  }

  .events-form .v-select {
    margin-left: 10px;
    width: calc(100% - 10px);
  }
}
</style>
