<template>
  <v-card color="grey3">
    <v-progress-linear
      v-show="loader"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <v-card-title class="grey1--text">
      <span>Colaboradores ativos/trial</span>
      <span class="ml-2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              color="grey"
              v-bind="attrs"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Colaboradores que aderiram ao planejamento financeiro, divididos por plano</span>
        </v-tooltip>
      </span>
    </v-card-title>

    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 sm6>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ totalPayersPlanoIndividual }}</span>
              <span class="payer-desc primary--text">Colaboradores Plano Individual</span>
            </div>
          </v-flex>

          <v-flex xs12 sm6>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ totalPayersPlanoFamiliar }}</span>
              <span class="payer-desc primary--text">Colaboradores Plano Familiar</span>
            </div>
          </v-flex>
        </v-layout>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          dark
          small
          rounded
          color="purple"
          class="px-5 text-lowercase"
          @click.stop="showEmployeesHistory"
        >
          Detalhes
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';
import calculateCompanyPayers from '@/api/companies/calculateCompanyPayers';

export default {
  name: 'CardCompanyPayers',
  data: () => ({
    loader: false,
    totalValue: 0,
    totalPayers: 0,
    numberToCurrency,
    totalValuePlanoFamiliar: 0,
    totalValuePlanoIndividual: 0,
    totalPayersPlanoFamiliar: 0,
    totalPayersPlanoIndividual: 0
  }),
  computed: {
    isCompany() {
      return this.$store.getters.user && this.$store.getters.user.role === 'company';
    },
    user() {
      return this.isCompany ? this.$store.getters.user : this.$store.getters.company;
    },
    valorPlanoFamiliar() {
      if (this.user && this.user.companyPayment && this.user.companyPayment.values) {
        return this.user.companyPayment.values.employeeFamilyValue;
      }
    },
    valorPlanoIndividual() {
      if (this.user && this.user.companyPayment && this.user.companyPayment.values) {
        return this.user.companyPayment.values.employeeInvidualValue;
      }
    }
  },
  methods: {
    getPayersData() {
      if (this.user) {
        this.loader = true;

        calculateCompanyPayers(this.user._id)
          .then(data => {
            this.totalValue = data.totalValue;
            this.totalPayers = data.totalPayers;
            this.totalValuePlanoIndividual = data.totalValuePlanoIndividual;
            this.totalValuePlanoFamiliar = data.totalValuePlanoFamiliar;
            this.totalPayersPlanoIndividual = data.totalPayersPlanoIndividual;
            this.totalPayersPlanoFamiliar = data.totalPayersPlanoFamiliar;
            this.loader = false;
          })
          .catch(err => {
            this.loader = false;
            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    showEmployeesHistory() {
      const currentPeriod = this.$date().format('YYYY-MM');
      const userCreatedPeriod = this.$date(this.user.createdAt).format('YYYY-MM');

      if (userCreatedPeriod !== currentPeriod) {
        this.$bus.$emit('showDialogEmployeesHistory');
      } else {
        this.$noty.info('Não há histórico disponível');
      }
    }
  },
  mounted() {
    this.getPayersData();
  }
};
</script>

<style scoped>
.payer-value {
  font-size: 15px;
  font-weight: bold;
}

.payer-desc {
  font-size: 9px;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 375px) {
  .payer-value {
    font-size: 15px;
  }
}

@media only screen and (min-width: 414px) {
  .payer-value {
    font-size: 18px;
  }

  .payer-desc {
    font-size: 10px;
  }
}
</style>
