<template>
  <v-main class="margin-menu">
    <OverlaySync :overlay="loader" text="Buscando planos..." />
    <MobilePageTitle
      title="Planos de Ação"
      :loader="loader"
      v-if="isCustomer"
    />

    <v-container grid-list-md class="main-container">
      <v-layout row wrap>
        <v-flex xs12 class="my-5 hidden-sm-and-down">
          <div class="title-container">
            <v-btn
              icon
              @click="goBack"
              v-if="isCustomer"
              :disabled="loader"
              class="back-button hidden-md-and-up"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <span class="white--text mx-2 page-title">Planos de Ação</span>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="white" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>
                Registre todas as ações, delimite prazos e responsáveis.<br />
                Esta base serve para complementos e revisões.
              </span>
            </v-tooltip>
          </div>
        </v-flex>

        <v-flex xs12 md6 lg6>
          <ActionPlansFilter />
        </v-flex>

        <v-flex xs12 class="mt-5">
          <v-tabs grow centered v-model="tab" background-color="grey3">
            <v-tab href="#tab-0">Pendentes</v-tab>
            <v-tab href="#tab-1">Concluídos</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-0">
              <ListPlanosAcao :completed="false" class="hidden-md-and-up" />
              <TablePlanosAcao :completed="false" class="hidden-sm-and-down" />
            </v-tab-item>

            <v-tab-item value="tab-1">
              <ListPlanosAcao :completed="true" class="hidden-md-and-up" />
              <TablePlanosAcao :completed="true" class="hidden-sm-and-down" />
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-container>

    <DialogPlanoAcao />
    <DialogFormPlanoAcao />
    <DialogRemovePlanoAcao />

    <v-slide-y-transition>
      <v-btn
        fab
        small
        right
        fixed
        bottom
        color="darkgrey"
        v-if="allowed"
        @click="showDialogFormPlanoAcao"
        class="primary--text mb-15 hidden-md-and-up"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-slide-y-transition>
  </v-main>
</template>

<script>
import OverlaySync from '@/components/Global/OverlaySync';
import MobilePageTitle from '@/components/Global/MobilePageTitle';
import FormSearchPlanosAcao from '@/components/Customer/PlanosAcao/forms/FormSearchPlanosAcao';
import ListPlanosAcao from '@/components/Customer/PlanosAcao/lists/ListPlanosAcao';
import DialogPlanoAcao from '@/components/Customer/PlanosAcao/dialogs/DialogPlanoAcao';
import DialogFormPlanoAcao from '@/components/Customer/PlanosAcao/dialogs/DialogFormPlanoAcao';
import DialogRemovePlanoAcao from '@/components/Customer/PlanosAcao/dialogs/DialogRemovePlanoAcao';
import TablePlanosAcao from '@/components/Customer/PlanosAcao/tables/TablePlanosAcao';
import getCustomerData from '@/utils/getCustomerData';
import getUserActionPlans from '@/api/actionPlans/getUserActionPlans';
import ActionPlansFilter from '@/components/Customer/PlanosAcao/filters/ActionPlansFilter';
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

export default {
  name: 'PlanosAcao',
  // metaInfo() {
  //   return {
  //     title: 'Planos de ação',
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo);
  //     },
  //   };
  // },
  components: {
    ActionPlansFilter,
    TablePlanosAcao,
    DialogRemovePlanoAcao,
    DialogFormPlanoAcao,
    DialogPlanoAcao,
    ListPlanosAcao,
    FormSearchPlanosAcao,
    MobilePageTitle,
    OverlaySync,
  },
  data: () => ({
    loader: false,
    tab: 'tab-0',
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    allowed() {
      return this.$store.getters.allowed;
    },
  },
  methods: {
    goBack() {
      window.history.back();
    },
    getData() {
      if (!this.user) {
        this.loader = true;
        getCustomerData()
          .then(() => {
            this.getActionPlans();
          })
          .catch((err) => {
            this.$router.push('/');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      } else {
        this.getActionPlans();
      }
    },
    showDialogFormPlanoAcao() {
      if (this.allowed) this.$bus.$emit('showDialogFormPlanoAcao');
    },
    getActionPlans() {
      getUserActionPlans(this.user._id)
        .then((resp) => {
          this.loader = false;
          this.$store.dispatch('setActionPlans', resp.actionPlans);
        })
        .catch((err) => {
          this.loader = false;

          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    },
  },
  beforeCreate() {
    this.$vuetify.theme.dark = true;
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.v-main {
  background-color: #2d2d2d;
}

.container {
  max-width: 960px;
}

.v-card.card-container {
  background-color: transparent !important;
}

.theme--dark.v-tabs-items {
  background-color: transparent !important;
}

@media only screen and (min-width: 960px) {
  .v-main {
    background-color: #131313;
  }

  .container.main-container {
    width: 100%;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0.1em;
  }

  .v-card.card-container {
    background-color: #2d2d2d !important;
  }
}
</style>
