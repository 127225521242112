<template>
  <v-expansion-panel v-if="category" style="z-index: 1">
    <v-expansion-panel-header class="primary--text pa-3">
      <div class="d-flex align-center">
        <v-progress-circular
          :rotate="360"
          :size="progressSize"
          :width="2"
          class="mr-5"
          :value="totalUsedPercent"
          :color="category.color"
        >
          <div class="d-flex flex-column text-center">
            <v-icon color="white">{{ category.icon }}</v-icon>
            <small class="grey1--text">{{ parseInt(totalUsedPercent) }}%</small>
          </div>
        </v-progress-circular>

        <div class="d-flex flex-column flex-grow-1">
          <span class="white--text cat-title text-uppercase">{{ category.name }}</span>

          <div class="mt-2">
            <v-container grid-list-md class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs6 sm4 md3 class="pa-0">
                  <v-tooltip bottom open-delay="1000">
                    <template v-slot:activator="{ on }">
                      <div class="item-values-value mr-5 d-flex flex-column align-start" v-on="on">
                        <span class="item-values-value-number grey1--text">{{ numberToCurrency(totalLimit) }}</span>
                        <span class="item-values-value-label primary--text">Limite</span>
                      </div>
                    </template>
                    <span>Limite máximo que você pode gastar</span>
                  </v-tooltip>
                </v-flex>

                <v-flex sm4 md3 class="pa-0 hidden-xs-only">
                  <v-tooltip bottom open-delay="1000">
                    <template v-slot:activator="{ on }">
                      <div class="item-values-value mr-5 d-flex flex-column align-start" v-on="on">
                      <span class="item-values-value-number grey1--text">
                        {{ numberToCurrency(totalUsed) }}
                      </span>
                        <span class="item-values-value-label primary--text">Usado</span>
                      </div>
                    </template>
                    <span>O total que você já gastou</span>
                  </v-tooltip>

                </v-flex>

                <v-flex xs6 sm4 md3 class="pa-0">
                  <v-tooltip bottom open-delay="1000">
                    <template v-slot:activator="{ on }">
                      <div class="item-values-value mr-5 d-flex flex-column align-start" v-on="on">
                      <span class="item-values-value-number grey1--text">
                        {{ numberToCurrency(totalAvailable) }}
                      </span>
                        <span class="item-values-value-label primary--text">Disponível</span>
                      </div>
                    </template>
                    <span>Quanto lhe resta para gastar</span>
                  </v-tooltip>

                </v-flex>

                <v-flex xs4 sm4 md3 class="pa-0 hidden-sm-and-down">
                  <v-tooltip bottom open-delay="1000">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="item-values-value mr-5 d-flex flex-column align-start" v-on="on" v-bind="attrs">
                      <span class="item-values-value-number grey1--text">
                        {{ percentOverRevenue.toFixed(2) }}%
                      </span>
                        <span class="item-values-value-label primary--text">Da sua receita</span>
                      </div>
                    </template>
                    <span>Percentual que você já gastou em relação a sua receita total</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </div>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="pa-0 pb-2 category-content">
      <v-container grid-list-md class="pa-0">
        <v-layout row wrap class="pa-0">
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>

          <v-flex
            xs12 md6 lg4
            :key="item._id"
            v-for="item in items"
          >
            <v-card color="grey2">
              <v-card-text>
                <div class="d-flex align-center">
                  <div class="d-flex flex-column flex-grow-1">
                    <div>
                      <span class="white--text cat-title">{{ item.subCategory ? item.subCategory.name : 'Não categorizada' }}</span>
                      <v-progress-linear
                        :value="calcUsedPercent(item.limite, item.total)"
                        :color="getItemColor(item)"
                      ></v-progress-linear>
                    </div>


                    <div class="mt-2">
                      <v-container grid-list-md class="ma-0 pa-0">
                        <v-layout row wrap class="ma-0 pa-0">
                          <v-flex xs4 sm4 class="pa-0">
                            <div class="item-values-value d-flex flex-column align-start">
                              <span class="item-values-value-number grey1--text">
                                {{ numberToCurrency(item.limite) }}
                              </span>
                              <span class="item-values-value-label primary--text">
                                Limite
                                <span :class="item.limiteFixo ? 'hidden-xs-only' : ''">
                                  {{ item.limiteFixo ? 'Fixo' : '' }}
                                </span>
                              </span>
                            </div>
                          </v-flex>

                          <v-flex xs4 class="pa-0">
                            <div class="item-values-value d-flex flex-column align-center">
                              <span class="item-values-value-number grey1--text">
                                {{ numberToCurrency(item.total) }}
                              </span>
                              <span class="item-values-value-label primary--text">Usado</span>
                            </div>
                          </v-flex>

                          <v-flex xs4 sm4 class="pa-0">
                            <div class="item-values-value d-flex flex-column align-end">
                              <span class="item-values-value-number grey1--text">
                                {{ numberToCurrency(calcUsed(item.limite, item.total)) }}
                              </span>
                              <span class="item-values-value-label primary--text">Disponível</span>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </div>
                  </div>
                </div>

                <v-card-actions class="mt-5 pa-0">
                  <v-btn
                    text
                    x-small
                    class="mr-1"
                    color="white"
                    :disabled="!item.total"
                    @click="showDialogDespesasItem(item)"
                  >
                    Transações
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    x-small
                    class="mr-1"
                    color="white"
                    :disabled="!allowed || item.isInvalid || !validPeriod"
                    @click="showDialogFormItemOrcamento(item)"
                  >
                    Editar
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    x-small
                    color="white"
                    @click="showDialogDeleteItemOrcamento(item)"
                    :disabled="!allowed || item.isInvalid || (validPeriod && item.total > 0)"
                  >
                    Remover
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { sumBy, orderBy } from 'lodash'
import numberToCurrency from '@/utils/numberToCurrency';

export default {
  name: 'PanelCategory',
  props: ['categoryName', 'validPeriod', 'period'],
  data: () => ({
    numberToCurrency,
    progressSize: 60,
    progressWidth: 5,
    progressAvatar: 40
  }),
  computed: {
    orcamento() {
      return this.$store.getters.orcamento
    },
    items() {
      return orderBy(this.orcamento.items.enabled.filter(item => {
        return item.category && item.category.name === this.categoryName
      }), 'subCategory.name')
    },
    totalLimit() {
      return sumBy(this.items, item => item.limite)
    },
    totalUsed() {
      return sumBy(this.items, item => item.total)
    },
    totalUsedPercent() {
      if (this.totalLimit) {
        return (this.totalUsed / this.totalLimit) * 100
      }
      return 100
    },
    totalAvailable() {
      let total = 0

      this.items.forEach(it => {
        let value = parseFloat((it.limite - it.total).toFixed(2))

        if (value > 0) {
          total += value
        }
      })

      return total
    },
    totalAvailablePercent() {
      let percent = 100 - this.totalUsedPercent

      if (percent < 0) {
        percent = 0
      }

      return percent
    },
    categories() {
      return this.$store.getters.categories
    },
    category() {
      let category =  this.categories.find(cat => cat.name === this.categoryName)

      if (!category && this.categoryName) {
        category = {
          name: this.categoryName,
          icon: 'mdi-dots-horizontal'
        }
      }

      return category
    },
    percentOverRevenue() {
      if (this.orcamento.parametros.receita.value) {
        return (this.totalUsed / this.orcamento.parametros.receita.value) * 100
      }

      return 100
    },
    allowed() {
      return this.$store.getters.allowed;
    }
  },
  methods: {
    getItemColor(item) {
      if (item.firstWarning && item.secondWarning && item.thirdWarning) {
        let second = (item.secondWarning.percent / 100);
        let third = (item.thirdWarning.percent / 100);
        let used = item.total / item.limite;

        if (used < second)
          return 'success';
        else if (used >= second && used < third)
          return 'warning';
        else
          return 'error';
      }

      return 'error'
    },
    getItemAvailableLimit(limit, used) {
      let total = limit - used

      if (total < 0) {
        total = 0
      }

      return numberToCurrency(total)
    },
    setProgressValues() {
      const width = window.innerWidth

      if (width < 600) {
        this.progressSize = 60
        this.progressAvatar = 40
        this.progressWidth = 5
      } else {
        this.progressSize = 70
        this.progressAvatar = 60
      }
    },
    calcUsedPercent(limit, used) {
      return (used / limit) * 100
    },
    calcUsed(limit, used) {
      let total = limit - used

      if (total < 0) {
        total = 0
      }

      return total
    },
    showDialogDespesasItem(item) {
      if (item.total)
        this.$bus.$emit('showDialogDespesasItem', { item, period: this.period });
    },
    showDialogFormItemOrcamento(item) {
      if (this.allowed) {
        this.$bus.$emit('showDialogFormItemOrcamento', {
          item,
          orcamentoId: this.orcamento._id
        });
      }
    },
    showDialogDeleteItemOrcamento(item) {
      if (this.allowed) {
        this.$bus.$emit("showDialogDeleteItemOrcamento", {
          orcamento: this.orcamento._id,
          itemId: item._id
        });
      }
    }
  },
  mounted() {
    this.setProgressValues()
  }
}
</script>

<style>
.v-expansion-panel-header.category-header {
  padding: 0 10px !important;
}
.v-expansion-panel-content.category-content .v-expansion-panel-content__wrap {
  padding: 0 10px !important;
}

.cat-title {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .05em;
}

.item-category {
  font-size: 12px !important;
  letter-spacing: .1em;
}

.v-card__title.item-title {
  font-size: 12px !important;
  font-weight: bold;
  letter-spacing: .1em;
}

.item-progress-value {
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  font-size: 10px;
  letter-spacing: .1em;
}

.item-values-label {
  font-size: 9px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.item-values-value {
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: .05em;
}

.value-item {
  font-size: 9px;
  line-height: 1.5em;
}

.value-item span:nth-child(2) {
  font-weight: bold;
  font-size: 8px;
  letter-spacing: .1em;
  line-height: 1.5em;
}

.v-btn--text .v-btn__content {
  font-size: 11px !important;
  text-transform: none !important;
}

@media only screen and (min-width: 375px) {
  .value-item {
    font-size: 10px;
  }

  .value-item span:nth-child(2) {
    font-size: 9px;
  }
}

@media only screen and (min-width: 414px) {
  .value-item {
    font-size: 11px;
  }

  .value-item span:nth-child(2) {
    font-size: 9px;
  }
}

@media only screen and (min-width: 600px) {
  .item-category {
    font-size: 15px !important;
  }

  .cat-title {
    font-size: 15px;
  }

  .value-item {
    font-size: 13px;
  }

  .value-item span:nth-child(2) {
    font-size: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .cat-title {
    font-size: 15px;
  }

  .value-item {
    font-size: 15px;
  }

  .value-item span:nth-child(2) {
    font-size: 12px;
  }
}

@media only screen and (min-width: 960px) {
  .item-category {
    font-size: 12px !important;
  }

  .item-values-value-number {
    font-size: 12px;
    letter-spacing: .1em;
    line-height: 1.5em;
  }

  .item-values-value-label {
    font-size: 10px;
    letter-spacing: .1em;
  }
}
</style>
