<template>
  <v-container :style="'min-height:' + containerHeight">
    <div class="grid-item" :class="isIOS ? 'mt-10' : ''">
      <h1 class="auth-title">
        Você está a
        <br />
        <span class="primary--text">um login</span>
        <br />de transformar
        <br />a sua vida
      </h1>
    </div>

    <div class="grid-item">
      <v-form @submit.prevent="login">
        <v-text-field
          dark
          dense
          type="email"
          label="E-mail"
          v-model="email"
          color="secondary"
          @input="$v.email.$touch()"
          :error-messages="emailErrors"
        ></v-text-field>

        <v-text-field
          dark
          dense
          label="Senha"
          type="password"
          color="secondary"
          v-model="password"
          @input="$v.password.$touch()"
          :error-messages="passwordErrors"
        ></v-text-field>

        <a class="secondary--text auth-link" @click.stop.prevent="showDialogForgotPassword">
          Esqueceu a senha?
        </a>

        <div>
          <v-btn
            rounded
            depressed
            type="submit"
            color="primary"
            :loading="loader"
            class="black--text mb-3 mt-5"
          >
            Login
          </v-btn>

          <v-btn
            rounded
            outlined
            depressed
            type="button"
            color="primary"
            @click="signup"
            :disabled="loader"
          >
            Cadastre-se
          </v-btn>
        </div>

        <div class="link-container text-center mt-3">
          <router-link to="/politica-privacidade" class="primary--text">Politica de privacidade</router-link>
        </div>

        <div class="link-container text-center mt-3">
          <router-link to="/termos-uso" class="primary--text">Termo de adesão</router-link>
        </div>
      </v-form>

     <span class="auth-desc primary--text" v-if="!isIOS">
       <a href="https://uplanner.com.br" target="_blank" >
         Não possui uma conta? <b>cadastre-se</b>
       </a>
      </span>

      <img
        src="../../../assets/simbolo-uplanner.png"
        class="auth-logo mb-5"
      />
    </div>
  </v-container>
</template>

<script>
import firebase from 'firebase/app';
import { required } from 'vuelidate/lib/validators';
import getUserByUid from '@/api/users/getUserByUid';
import authWithSocial from "../../../utils/authWithGoogle";
import savePermissions from '@/api/users/permissionsUser';

export default {
  name: 'AuthContainerTwo',
  data: () => ({
    loader: false,
    email: '',
    password: '',
    googleLoader: false,
    facebookLoader: false,
    containerHeight: '100vh'
  }),
  methods: {
    reset() {
      this.email = '';
      this.password = '';
      this.$v.$reset();
    },
    back() {
      this.$bus.$emit('goToWindow', 1);
      this.reset();
    },
    signup() {
      if (!this.loader) {
        this.$bus.$emit('showDialogCadastro');
      }
    },
    async definePermissions(user) {
      user.permissions = {
        perfilLimitado: false,
        cashflow: true,
        transactions: true,
        relatorios: true,
        planosAcao: true,
        orcamento: true,
        indFinanceira: true,
        objetivo: true,
        investimentos: true,
        lifeInsurance:  true
      }

      const savedUser = await savePermissions(user._id, user.permissions)
      this.$store.dispatch('setUser', savedUser);
    },
    login() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(result => getUserByUid(result.user.uid, true))
          .then(user => {
            this.$store.dispatch('setUser', user);

            if(user && user.role === 'customer' && !user.permissions) {
              this.definePermissions(user)
            }

            if (user.role === 'company') {
              this.$router.push('/company');
            } else {
              this.$router.push('/');
            }

            this.loader = false;
          })
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);

            this.$bus.$emit('showError', err);
            this.$noty.error("E-mail e/ou senha incorretos");
            this.loader = false;
          });
      }
    },
    showDialogForgotPassword() {
      this.$bus.$emit('showDialogForgotPassword', this.email);
    },
    authWithSocial(type) {
      if (!this.googleLoader && !this.facebookLoader) {
        if (type === 'google')
          this.googleLoader = true;
        else
          this.facebookLoader = true;

        authWithSocial(true, type)
          .then(result => this.$router.push('/'))
          .catch(err => {
            if (process.env.NODE_ENV !== 'production')
              console.error(err);

            this.googleLoader = false;
            this.facebookLoader = false;

            if (err.code && err.code !== "auth/popup-closed-by-user")
              this.$noty.error("Desculpe! Não foi possível acessar sua conta. Verifique seus dados e tente novamente!");
          });
      }
    }
  },
  validations: {
    email: { required },
    password: { required }
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push('E-mail é obrigatório');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('Senha é obrigatória');
      return errors;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
  beforeMount() {
    if (window.innerHeight < 568) {
      this.containerHeight = `568px`;
    } else {
      this.containerHeight = `${window.innerHeight}px`;
    }
  },
  mounted() {
    let self = this;

    window.addEventListener('resize', function() {
      if (window.innerHeight < 568) {
        self.containerHeight = `568px`;
      } else {
        self.containerHeight = `${window.innerHeight}px`;
      }
    })
  }
};
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 320px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0px;
  }

  .link-container {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .1em;
  }

  .grid-item {
    width: 100%;
    min-height: 1px;
  }

  .v-btn.back-btn {
    margin-left: -10px;
    margin-bottom: 5px;
  }

  .v-btn .v-icon {
    font-size: 40px !important;
  }

  .auth-title {
    color: white;
    font-size: 25px;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  }

  .auth-link {
    font-size: 14px;
    padding: 5px;
    display: block;
  }

  .auth-button {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 25px;
    border-radius: 28px;
    background-color: white;
    height: 40px;
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 12px;
    outline: 0;
    vertical-align: middle;
    align-self: flex-end;
  }

  .auth-button img {
    width: 17px;
    max-width: 100%;
    margin-right: 40px;
  }

  .v-form .v-btn {
    height: 40px !important;
    width: 100%;
  }

  .v-btn.v-size--default {
    font-size: 11px !important;
  }

  .auth-desc {
    display: block;
    font-size: 12px;
    letter-spacing: 0.05em;
    margin-top: 10px;
    text-align: center;
    padding: 10px 2px;
    cursor: pointer;
    text-decoration: none;
  }

  .auth-desc b {
    text-transform: uppercase;
  }

  .auth-logo {
    display: block;
    max-width: 48px;
    margin: 0 auto;
  }

  @media only screen and (min-width: 414px) {
    .auth-title {
      font-size: 30px;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 959px) {
    .container {
      max-width: 440px;
      padding-top: 45px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 15px;
    }

    .v-btn .v-icon {
      font-size: 60px !important;
    }

    .auth-title {
      font-size: 40px;
    }

    .v-form .v-btn {
      display: block;
      height: 40px !important;
      width: 244px;
      margin-left: auto;
      margin-right: auto;
    }

    .v-btn.v-size--default {
      font-size: 12px !important;
    }

    .auth-button {
      width: 244px;
      margin-left: auto;
      margin-right: auto;
      font-size: 12px;
    }

    .auth-button img {
      width: 20px;
      margin-right: 20px;
    }

    .auth-desc {
      font-size: 14px;
    }

    .auth-logo {
      max-width: 62px;
    }
  }
</style>
