import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const getInvestmentsByUserId = userId => {
  return new Promise((resolve, reject) => {
    try {
      if (!userId)
        reject({ message: 'user-id-not-sent' });

      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let url = `/investments/users/${userId}`;
          let headers = { Authorization: `Bearer ${idToken}` };

          return httpClient(url, { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default getInvestmentsByUserId;
