<template>
  <div class="chart-card">
    <h1 class="chart-card-title primary--text mb-2">Fluxo de Caixa Total</h1>

    <apexchart
      type="donut"
      :options="options"
      :series="series"
      width="100%"
      height="300"
    ></apexchart>

    <div class="chart-card-legends mt-2">
      <v-layout row wrap>
        <v-flex xs6 sm4 md6 v-for="(item, index) in legends" :key="index">
          <div class="chart-card-legends-item">
            <v-avatar size="10" :color="item.color" class="mr-2"></v-avatar>
            <div class="d-flex flex-column">
              <span class="chart-card-legends-item--title">{{ item.text }}</span>
              <span class="chart-card-legends-item--value">{{ item.value }}</span>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
  let total = 0;

  import {sum} from 'lodash'
  import VueApexCharts from 'vue-apexcharts';
  import numberToCurrency from "../../../utils/numberToCurrency";

  export default {
    name: 'CardFluxoTotal',
    props: ['transactions'],
    components: { apexchart: VueApexCharts },
    data: () => ({
      options: {
        colors: ['#338A2D', '#E62D2A', '#D1AC68'],
        labels: ['Receitas', 'Despesas', 'Investimentos'],
        dataLabels: { enabled: false },
        legend: { show: false },
        stroke: { width: 0 },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: v => {
              let percent = ((v / total) * 100).toFixed(2)
              return `${numberToCurrency(v)} (${percent}%)`
            }
          }
        }
      },
      series: [
        {
          name: 'Receitas',
          type: 'column',
          data: []
        },
        {
          name: 'Despesas',
          type: 'column',
          data: []
        },
        {
          name: 'Investimentos',
          type: 'column',
          data: []
        },
        {
          name: 'Balanço',
          type: 'line',
          data: []
        },
      ],
      legends: [
        {
          text: 'Receitas',
          color: '#338A2D',
          value: 0
        },
        {
          text: 'Despesas',
          color: '#E62D2A',
          value: 0
        },
        {
          text: 'Investimentos',
          color: '#D1AC68',
          value: 0
        }
      ]
    }),
    methods: {
      buildChart() {
        let options = JSON.parse(JSON.stringify(this.options));
        this.series = [];

        total = sum(this.transactions);

        this.legends.forEach((l, i) => {
          let percent = this.transactions[i] ? ((this.transactions[i] / total) * 100).toFixed(2) : 0;
          l.value = `${numberToCurrency(this.transactions[i])} (${percent}%)`
          this.series.push(parseFloat(this.transactions[i]));
        });

        this.options = options;
      }
    },
    watch: {
      transactions: {
        deep: true,
        handler() {
          this.buildChart();
        }
      }
    },
    mounted() {
      this.buildChart();
    }
  }
</script>

