<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4">Renda</span>
      </v-card-title>

      <v-card-text class="py-5">
        <div>
          <span class="input-label primary--text">Renda INSS</span>
          <v-text-field
            dense
            type="tel"
            color="grey1"
            v-money="money"
            v-model="rendaInss"
            id="input-renda-inss"
            prepend-inner-icon="mdi-cash"
          ></v-text-field>
        </div>

        <div>
          <span class="input-label primary--text">Outras fontes de renda</span>
          <v-text-field
            dense
            type="tel"
            color="grey1"
            v-money="money"
            v-model="rendaOutros"
            id="input-renda-outros"
            prepend-inner-icon="mdi-cash"
          ></v-text-field>
        </div>

        <v-card-actions class="px-0 pb-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            small
            color="primary"
            @click="submit"
            :loading="loader"
            class="darkgrey--text"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {VMoney} from 'v-money'
  import removeMoneyMask from "../../../../utils/removeMoneyMask";
  import updateIndFinanceira from "../../../../api/indFinanceira/updateIndFinanceira";

  export default {
    name: 'DialogFormRendasIndFinanceira',
    directives: { money: VMoney },
    data: () => ({
      dialog: false,
      loader: false,
      rendaInss: '',
      rendaOutros: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      }
    }),
    computed: {
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === `customer`;
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
    },
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
      },
      submit() {
        this.loader = true;

        let rendas = {
          rendaInss: removeMoneyMask(this.rendaInss),
          rendaOutros: removeMoneyMask(this.rendaOutros)
        }

        updateIndFinanceira(this.indFinanceira._id, { rendas })
          .then(indFinanceira => {
            this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
            this.close();
            this.$noty.success('Dados salvos com sucesso!')
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível atualizar seus dados. Tente novamente!');
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      },
      resetMoneyInput() {
        let input = document.getElementById('input-renda-inss');
        if (input) {
          input.value = this.rendaInss;
          input.dispatchEvent(new Event('input'));
        }

        input = document.getElementById('input-renda-outros');
        if (input) {
          input.value = this.rendaOutros;
          input.dispatchEvent(new Event('input'));
        }
      }
    },
    created() {
      this.$bus.$on('showDialogformRendaIndFinanceira', () => {
        this.rendaInss = this.indFinanceira.rendas.rendaInss.toFixed(2);
        this.rendaOutros = this.indFinanceira.rendas.rendaOutros.toFixed(2);
        this.resetMoneyInput();

        this.dialog = true;
      });
    }
  }
</script>

<style scoped>
  .dialog-form-title .white--text {
    line-height: 1.5em !important;
  }

  .input-label {
    font-size: 9px;
    letter-spacing: .1em;
  }

  @media only screen and (min-width: 600px) {
    .input-label {
      font-size: 12px;
    }
  }
</style>
