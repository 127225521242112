<template>
  <v-card color="transparent" flat>
    <v-card-text class="px-0" v-if="relacionamento">
      <v-container fluid grid-list-md class="px-0">
        <v-layout row wrap>
          <v-flex
            xs12 sm6 md4
            class="d-flex flex-column"
            v-if="relacionamento.conhecem"
          >
            <span class="input-label primary--text">A quanto tempo se conhecem?</span>
            <span class="input-label">{{ relacionamento.conhecem }}</span>
          </v-flex>

          <v-flex
            xs12 sm6 md4
            class="d-flex flex-column"
            v-if="relacionamento.casados"
          >
            <span class="input-label primary--text">A quanto tempo são casados(as)?</span>
            <span class="input-label">{{ relacionamento.casados }}</span>
          </v-flex>

          <v-flex
            xs12 sm6 md4
            class="d-flex flex-column"
            v-if="relacionamento.regimeBens"
          >
            <span class="input-label primary--text">Qual é o regime de bens?</span>
            <span class="input-label">{{ relacionamento.regimeBens }}</span>
          </v-flex>

          <v-flex
            xs12 sm6 md4
            class="d-flex flex-column"
            v-if="relacionamento.divorciados"
          >
            <span class="input-label primary--text">A quanto tempo são divorciados(as)?</span>
            <span class="input-label">{{ relacionamento.divorciados }}</span>
          </v-flex>

          <v-flex
            xs12 sm6 md4
            class="d-flex flex-column"
            v-if="relacionamento.viuvo"
          >
            <span class="input-label primary--text">A quanto tempo é viúvo(a)?</span>
            <span class="input-label">{{ relacionamento.viuvo }}</span>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-card-actions class="pb-5">
      <v-spacer></v-spacer>

      <v-btn
        v-if="allowed"
        outlined
        rounded
        color="primary"
        class="px-10 text-capitalize"
        @click="showDialogEditRelacionamento"
      >
        Editar
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CardRelacionamento',
  computed: {
    allowed() {
      return this.$store.getters.allowed
    },
    perfil() {
      return this.$store.getters.perfil
    },
    estadoCivil() {
      if (this.perfil && this.perfil.informacoesBasicas && this.perfil.informacoesBasicas.usuario) {
        return this.perfil.informacoesBasicas.usuario.estadoCivil
      }

      return null
    },
    relacionamento() {
      if (this.perfil) {
        return this.perfil.relacionamento
      }

      return null
    }
  },
  methods: {
    showDialogEditRelacionamento() {
      if (this.allowed) {
        this.$bus.$emit('showDialogEditRelacionamento')
      }
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .input-label {
    font-size: 14px;
  }
}
</style>
