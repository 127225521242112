<template>
  <v-card color="white" flat class="animated fadeInRight">
    <v-card-text class="pa-0">
      <div class="info-container pt-5">
        <span class="info-number">{{ type === 'invoice' ? numberToCurrency(total) : total }}</span>
        <small class="info-subtitle">{{ title }}</small>
      </div>

      <div class="chart-container hidden-xs-only">
        <apexchart
          width="100%"
          height="100"
          type="area"
          :options="chartOptions.options"
          :series="chartOptions.series"
        ></apexchart>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import numberToCurrency from '@/utils/numberToCurrency';

import countUsers from '@/api/users/countUsers';
import getPlannersSeries from '@/api/companies/getPlannersSeries';
import getCustomersSeries from '@/api/companies/getCustomersSeries';

export default {
  name: 'CardCompanyDetailsChart',
  components: { apexchart: VueApexCharts },
  props: ['user', 'type'],
  data: () => ({
    numberToCurrency,
    total: 0,
    chartOptions: {
      options: {
        colors: ['#47484D'],
        chart: {
          type: 'area',
          toolbar: { show: false }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        tooltip: { marker: { show: false } },
        xaxis: {
          categories: [],
          labels: { show: false },
          axisBorder: { show: false },
          axisTicks: { show: false }
        },
        yaxis: {
          labels: { show: false },
          axisBorder: { show: false },
          axisTicks: { show: false }
        },
        grid: { show: false },
        legend: { show: false },
        dataLabels: { enabled: false },
        crosshairs: { show: false },
        fill: {
          gradient: {
            shade: 'light',
            shadeIntensity: 1,
            opacityFrom: [0.5],
            opacityTo: [0]
          }
        },
        noData: {
          text: 'Nenhum usuário vínculado',
          verticalAlign: 'middle'
        }
      },
      series: [
        {
          name: 'Planejadores',
          data: [0, 0, 0, 0, 0, 0]
        }
      ]
    },
    months: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez'
    ]
  }),
  computed: {
    title() {
      switch (this.type) {
        case 'invoice':
          return 'Fatura Atual';
        case 'planners':
          return 'Planejadores';
        default:
          return 'Clientes';
      }
    }
  },
  methods: {
    getPlanners() {
      let promises = [
        getPlannersSeries(this.user._id),
        countUsers({ company: this.user._id, type: 'planner' })
      ];

      Promise.all(promises)
        .then(results => {
          let chartOptions = JSON.parse(JSON.stringify(this.chartOptions));
          chartOptions.series[0].data = results[0];
          this.chartOptions = { ...this.chartOptions, ...chartOptions };
          this.total = results[1].count;
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    },
    getCustomers() {
      console.log('run');
      let promises = [
        getCustomersSeries(this.user._id),
        countUsers({ company: this.user._id, type: 'customer' })
      ];

      Promise.all(promises)
        .then(results => {
          let chartOptions = JSON.parse(JSON.stringify(this.chartOptions));
          chartOptions.series[0].data = results[0];
          this.chartOptions = { ...this.chartOptions, ...chartOptions };
          this.total = results[1].count;
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    }
  },
  created() {
    let date = new Date();
    date.setMonth(date.getMonth() - 5);

    for (let i = 0; i <= 6; i++) {
      let label = `${this.months[date.getMonth()]}/${date.getFullYear()}`;
      this.chartOptions.options.xaxis.categories.push(label);
      date.setMonth(date.getMonth() + 1);
    }

    this.chartOptions.series[0].name = this.title;

    if (this.type === 'planners') this.getPlanners();
    else if (this.type === 'customers') this.getCustomers();
  }
};
</script>

<style scoped>
.info-container {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.info-number {
  font-size: 2em;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.info-subtitle {
  font-size: 1.125em;
  margin-top: 5px;
}

@media only screen and (max-width: 375px) {
  .info-container {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5px;
    text-align: center;
  }

  .info-number {
    font-size: 14px;
  }

  .info-subtitle {
    font-size: 12px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 480px) {
  .info-container {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5px;
    text-align: center;
  }

  .info-number {
    font-size: 16px;
  }

  .info-subtitle {
    font-size: 14px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .info-container {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5px;
    text-align: center;
  }

  .info-number {
    font-size: 20px;
  }

  .info-subtitle {
    font-size: 16px;
  }
}
</style>
