<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="482px"
    :fullscreen="isMobile"
  >
    <v-card color="grey2" :class="isMobile ? 'no-radius' : ''">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          small
          color="grey1"
          class="back-button ml-auto"
          @click="dialog = false"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <h1 class="white--text text-uppercase">Carteira global</h1>

        <p class="grey1--text">
          Serão criadas três carteiras de investimento: Reserva de Emergência
          (Liquidez), Projetos de vida (1-6 anos) e Aposentadoria (Longo prazo >
          6 anos).
        </p>

        <p class="grey1--text">
          Cada carteira contém ferramentas e produtos de investimentos
          compatíveis com o risco, liquidez e objetivos específicos. Segue
          abaixo os produtos e as classes de ativos que se adequam a cada
          carteira:
        </p>

        <div v-for="(wallet, i) in wallets" :key="i">
          <h4 class="grey1--text">{{ wallet.name }}</h4>
          <p class="grey1--text">{{ wallet.text }}</p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogWalletsDescription',
  data: () => ({
    dialog: false,
    wallets: [
      {
        name: 'Carteira de Reserva de Emergência',
        text:
          'Produtos de baixíssimo risco e liquidez diária, como poupança, fundos de renda fixa e títulos privados com liquidez diária.'
      },
      {
        name: 'Carteira dos projetos de vida',
        text:
          'Produtos de baixo risco e alta previsibilidade de retorno, como fundos de renda fixa, crédito privado, títulos privados protegidos pelo FGC (Fundo Garantidor de Crédito) como CDB/LCI/LCA/LC, títulos públicos e fundos multimercado de baixa volatilidade.'
      },
      {
        name: 'Carteira da independência financeira',
        text:
          'Produtos de longo prazo divididos em três estratégias: Sucessão (recursos livres de inventário como previdência e seguros de vida), Inflação (produtos indexados à inflação que tem como objetivo manter o poder de compra do capital, como títulos públicos, debêntures, fundos de renda fixa e imóveis para geração de renda) e Crescimento (parcela do patrimônio destinado à geração de valor e crescimento patrimonial por meio de produtos com maior risco e também maior potencial de ganho como ações, venture, participações societárias, moeda estrangeira, fundos multimercado de alta volatilidade e imóveis para venda).'
      }
    ]
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  created() {
    this.$bus.$on('showDialogWalletsDescription', () => {
      this.dialog = true;
    });
  }
};
</script>

<style scoped>
  .v-card__title.dialog-form-title {
    background-color: transparent !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  .v-card__title.dialog-form-title span {
    margin-left: 0 !important;
  }

  .v-card__title.dialog-form-title .v-icon {
    font-size: 25px !important;
  }

  h1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5em;
  }

  h4 {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  p {
    font-size: 10px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  @media only screen and (min-width: 600px) {
    h1, h4, p {
      font-size: 15px;
    }
  }
</style>
