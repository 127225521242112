<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    max-width="500px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="#404040">
      <v-card-title 
        class="dialog-form-title" 
        style="border-radius: 0 !important;" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="primary"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text ml-4">
          {{ creditCard._id ? 'Editar' : 'Novo' }} Cartão de Crédito
        </span>
      </v-card-title>

      <v-card-text>
        <!-- <v-form @submit.prevent="submit"> -->
          <v-container class="px-0" grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="input-label primary--text">Nome do cartão</span>
                <v-text-field
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="creditCard.name"
                  :error-messages="nameErrors"
                  @input="$v.creditCard.name.$touch()"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 v-if="!creditCard.pluggy">
                <span class="input-label primary--text">Banco</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  :items="bancos"
                  item-color="primary"
                  v-model="creditCard.bank"
                  :error-messages="bankErrors"
                  @change="$v.creditCard.bank.$touch()"
                ></v-select>
              </v-flex>

              <v-slide-x-transition>
                <v-flex xs12 sm12 md6 lg6 v-if="creditCard.bank === 'outro' && !creditCard.pluggy">
                  <span class="input-label primary--text">Nome do banco</span>
                  <v-text-field
                    color="grey1"
                    class="mt-0 pt-0"
                    v-model="otherBank"
                  ></v-text-field>
                </v-flex>
              </v-slide-x-transition>

              <v-flex xs12 md6 v-if="!creditCard.pluggy">
                <span class="input-label primary--text">Limite</span>
                <v-text-field
                  type="tel"
                  v-money="money"
                  id="input-limit-value"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="creditCard.limit"
                  :error-messages="limitErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md6 v-if="!creditCard.pluggy">
                <span class="input-label primary--text">Bandeira</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  :items="flags"
                  menu-props="auto"
                  item-value="value"
                  v-model="creditCard.flag"
                  :error-messages="flagErrors"
                  @change="$v.creditCard.flag.$touch()"
                >
                  <template v-slot:item="{ item, index }">
                    <img :src="getImgUrl(item.value)" style="width: 48px" class="mr-2">
                    <span>{{ item.text }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <img :src="getImgUrl(item.value)" style="width: 32px" class="mr-2">
                    {{ item.text }}
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12 md6 v-if="!creditCard.pluggy">
                <span class="input-label primary--text">Dia de fechamento da fatura</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  :items="days"
                  menu-props="auto"
                  v-model="creditCard.close"
                ></v-select>
              </v-flex>

              <v-flex xs12 md6 v-if="!creditCard.pluggy">
                <span class="input-label primary--text">Dia de vencimento da fatura</span>
                <v-select
                  :items="days"
                  menu-props="auto"
                  v-model="creditCard.pay"
                  color="grey1"
                  class="mt-0 pt-0"
                ></v-select>
              </v-flex>

              <v-flex xs12 class="mb-2 mt-2" v-if="!creditCard.pluggy">
                <v-menu offset-y close-on-content-click v-model="colorMenu">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      dark
                      block
                      rounded
                      depressed
                      v-on="on"
                      :color="creditCard.color"
                      class="px-5"
                      :class="isLight ? 'darkgrey--text' : 'white--text'"
                    >
                      Escolha a cor
                    </v-btn>
                  </template>

                  <v-dialog
                    scrollable
                    max-width="300px"
                    v-model="colorMenu"
                    :fullscreen="isMobile"
                  >
                    <v-card :class="!isMobile ? 'pt-5' : ''">
                      <v-card-title v-if="isMobile">
                        <v-btn
                          icon
                          class="mr-2"
                          @click="colorMenu = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        Cor
                      </v-card-title>
                      <v-card-text>
                        <v-layout row wrap>
                          <v-flex
                            xs2
                            :key="color"
                            v-for="color in categoryColors"
                            class="mb-1"
                          >
                            <v-btn
                              fab
                              dark
                              small
                              depressed
                              type="button"
                              :color="color"
                              @click.stop="creditCard.color = color; colorMenu = false"
                            ></v-btn>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              rounded
              class="px-5 darkgrey--text"
              color="primary"
              @click="submit"
              :loading="loader"
            >
              Salvar
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        <!-- </v-form> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { required, minLength, maxLength } from 'vuelidate/lib/validators'
  import categoryColors from '@/utils/categoryColors';
  import removeMoneyMask from "../../../../utils/removeMoneyMask";
  import saveCreditCard from "../../../../api/creditCards/saveCreditCard";
  import stringCapitalize from "../../../../utils/stringCapitalize";
  const tinycolor = require("tinycolor2");

  const isZero = v => removeMoneyMask(v) > 0;

  export default {
    name: 'DialogFormCreditCard',
    data: () => ({
      dialog: false,
      loader: false,
      colorMenu: false,
      categoryColors,
      creditCard: {
        pay: 1,
        close: 1,
        name: '',
        flag: '',
        limit: 0,
        color: '#2d2d2d',
        bank: ''
      },
      days: [
        1,2,3,4,5,6,7,8,9,10,
        11,12,13,14,15,16,17,18,19,20,
        21,22,23,24,25,26,27,28,29,30,31
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
      flags: [
        { text: 'Visa', value: 'visa' },
        { text: 'Master Card', value: 'mastercard' },
        { text: 'American Express', value: 'americanexpress' },
        { text: 'BNDES', value: 'bndes' },
        { text: 'Dinners', value: 'dinners' },
        { text: 'Elo', value: 'elo' },
        { text: 'Hipercard', value: 'hipercard' },
        { text: 'Outra', value: 'other' },
        { text: 'Sorocred', value: 'sorocred' }
      ],
      bancos: [
        {text: 'Banco do Brasil', value: 'banco-do-brasil'},
        {text: 'Bradesco', value: 'bradesco'},
        {text: 'Caixa Econômica Federal', value: 'caixa-economica-federal'},
        {text: 'CrediCard', value:'credicard'},
        {text: 'Latam Pass', value:'latampass'},
        {text: 'HSBC', value: 'hsbc'},
        {text: 'Inter', value: 'inter'},
        {text: 'Itaú', value: 'itau'},
        {text: 'Nubank', value: 'nubank'},
        {text: 'Santander', value: 'santander'},
        {text: 'Outro', value: 'outro'}
      ],
      otherBank: ''
    }),
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.creditCard = {
          pay: 1,
          close: 1,
          name: '',
          flag: '',
          limit: 0,
          bank: '',
          color: '#2d2d2d'
        }

        this.otherBank = ''

        this.resetMoneyInput();
        this.$v.$reset();
      },
      resetMoneyInput() {
        let input = document.getElementById('input-limit-value');

        if (input) {
          input.value = this.creditCard.limit;
          input.dispatchEvent(new Event('input'));
        }
      },
      getImgUrl(flag) {
        let images = require.context('../../../../assets/credit-card/', false, /\.png$/)
        return images('./' + flag + ".png")
      },
      submit() {
        this.$v.$touch();

        if (this.creditCard.bank !== 'outro') {
          this.$v.otherBank.$reset();
        }

        if (!this.$v.creditCard.$anyError && !this.$v.otherBank.$anyError) {
          this.loader = true;

          let creditCard = JSON.parse(JSON.stringify(this.creditCard));

          creditCard.user = this.user._id;

          if (this.creditCard.bank === 'outro') {
            creditCard.bank =  stringCapitalize(this.otherBank);
            creditCard.hasOtherBank = true
          } else {
            creditCard.bank =  stringCapitalize(creditCard.bank);
          }


          creditCard.limit = removeMoneyMask(creditCard.limit);

          saveCreditCard(creditCard)
            .then(savedCreditCard => {
              this.$store.dispatch('saveCreditCard', savedCreditCard);
              this.close();
              this.$noty.success('Cartão de crédito salvo com sucesso!')
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível salvar este cartão. Tente novamente!');
              if (process.env.NODE_ENV !== 'production') console.error(err);
            });
        }
      }
    },
    computed: {
      isLight() {
        return tinycolor(this.creditCard.color).isLight();
      },
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === `customer`;
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      nameErrors() {
        const errors = [];
        if (!this.$v.creditCard.name.$dirty) return errors;
        !this.$v.creditCard.name.required && errors.push('Dê um nome ao cartão');
        !this.$v.creditCard.name.minLength && errors.push('O nome deve conter, no mínimo, 3 caracteres');
        !this.$v.creditCard.name.maxLength && errors.push('O nome deve conter, no máximo, 50 caracteres');
        return errors;
      },
      flagErrors() {
        const errors = [];
        if (!this.$v.creditCard.flag.$dirty) return errors;
        !this.$v.creditCard.flag.required && errors.push('Selecione a bandeira do cartão');
        return errors;
      },
      bankErrors() {
        const errors = [];
        if (!this.$v.creditCard.bank.$dirty) return errors;
        !this.$v.creditCard.bank.required && errors.push('Informe o nome do banco referente ao cartão');
        return errors;
      },
      limitErrors() {
        const errors = [];
        if (!this.$v.creditCard.limit.$dirty) return errors;
        !this.$v.creditCard.limit.isZero && errors.push('Insira um valor maior do que zero');
        return errors;
      },
      otherBankErrors() {
        const errors = [];
        if (!this.$v.otherBank.$dirty) return errors;
        !this.$v.otherBank.required && errors.push('Dê um nome ao banco');
        !this.$v.otherBank.minLength && errors.push('O nome do banco deve conter, no mínimo, 3 caracteres');
        !this.$v.otherBank.maxLength && errors.push('O nome do banco deve conter, no máximo, 50 caracteres');
        return errors;
      },
    },
    validations: {
      creditCard: {
        limit: { isZero },
        flag: { required },
        bank: { required },
        name: { required, minLength: minLength(2), maxLength: maxLength(50) },
      },
      otherBank: { required, minLength: minLength(2), maxLength: maxLength(50) }
    },
    created() {
      this.$bus.$on('showDialogFormCreditCard', creditCard => {
        if (creditCard) {
          this.creditCard = JSON.parse(JSON.stringify(creditCard));

          const bancoIndex = this.bancos.findIndex(b => b.value === this.creditCard.bank.toLowerCase())
          if (bancoIndex === -1) {
            this.otherBank = this.creditCard.bank;
            this.creditCard.bank = 'outro';
          } else {
            this.creditCard.bank = this.creditCard.bank.toLowerCase()
          }

          this.creditCard.limit = this.creditCard.limit.toFixed(2);
          this.resetMoneyInput();
        }

        this.dialog = true;
      });
    }
  }
</script>

<style scoped>
.v-btn.v-size--default {
  font-size: 11px !important;
}
</style>
