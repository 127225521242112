<template>
  <v-card flat color="grey3">
    <v-card-title :class="isIOS ? 'ios-padding' : ''">Relacionamento</v-card-title>

    <v-card-text class="px-1">
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 sm6 md4 v-if="hasConjuge">
            <span class="input-label primary--text">A quanto tempo se conhecem?</span>
            <v-select
              class="pt-0 mt-0"
              :items="tempos"
              v-model="rel.conhecem"
              :error-messages="conhecemErrors"
            ></v-select>
          </v-flex>

          <v-flex xs12 sm6 md4 v-if="['Casado(a)', 'União estável'].includes(estadoCivil)">
            <span class="input-label primary--text">A quanto tempo são casados?</span>
            <v-select
              class="pt-0 mt-0"
              :items="tempos"
              v-model="rel.casados"
              :error-messages="casadosErrors"
            ></v-select>
          </v-flex>

          <v-flex xs12 sm6 md4 v-if="estadoCivil === 'Divorciado(a)'">
            <span class="input-label primary--text">A quanto tempo são divorciados?</span>
            <v-select
              class="pt-0 mt-0"
              :items="tempos"
              v-model="rel.divorciados"
              :error-messages="divorciadosErrors"
            ></v-select>
          </v-flex>

          <v-flex xs12 sm6 md4 v-if="estadoCivil === 'Viúvo(a)'">
            <span class="input-label primary--text">A quanto tempo é viúvo(a)?</span>
            <v-select
              class="pt-0 mt-0"
              :items="tempos"
              v-model="rel.viuvo"
              :error-messages="viuvoErrors"
            ></v-select>
          </v-flex>

          <v-flex xs12 sm6 md4 v-if="['Casado(a)', 'União estável'].includes(estadoCivil)">
            <span class="input-label primary--text">Qual é o regime de bens?</span>
            <v-select
              class="pt-0 mt-0"
              :items="regimes"
              v-model="rel.regimeBens"
              :error-messages="regimeBensErrors"
            ></v-select>
          </v-flex>

          <v-flex xs12>
            <v-card-actions class="pa-0 ma-0">
              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="stepBack"
              >
                Voltar
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                rounded
                color="primary"
                class="px-5 darkgrey--text"
                :loading="loader"
                @click.stop="submit"
              >
                Próximo
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'FormRelacionamento',
  props: ['relacionamento', 'profileId', 'estadoCivil', 'hasConjuge'],
  data: () => ({
    loader: false,
    rel: {
      viuvo: '',
      casados: '',
      regimeBens: '',
      divorciados: '',
      conhecem: ''
    },
    tempos: [
      'Menos de 1 ano',
      'Entre 1 e 5 anos',
      'Entre 5 e 10 anos',
      'Mais de 10 anos'
    ],
    regimes: [
      'Comunhão parcial de bens',
      'Comunhão universal de bens',
      'Separação total de bens',
      'Separação obrigatória de bens',
      'Participação final no aquestos'
    ]
  }),
  validations: {
    rel: {
      viuvo: {
        isValid(v) {
          return this.estadoCivil !== 'Viúvo(a)' || !!v && this.tempos.includes(v)
        }
      },
      casados: {
        isValid(v) {
          return !['Casado(a)', 'União estável'].includes(this.estadoCivil) || !!v && this.tempos.includes(v)
        }
      },
      regimeBens: {
        isValid(v) {
          return !['Casado(a)', 'União estável'].includes(this.estadoCivil) || !!v && this.regimes.includes(v)
        }
      },
      divorciados: {
        isValid(v) {
          return this.estadoCivil !== 'Divorciado(a)' || !!v && this.tempos.includes(v)
        }
      },
      conhecem: {
        isValid(v) {
          return !this.hasConjuge || !!v && this.tempos.includes(v)
        }
      },
    }
  },
  computed: {
    // helpers
    isIOS() {
      return this.$store.getters.isIOS
    },
    // error messages
    viuvoErrors() {
      if (!this.$v.rel.viuvo.$anyDirty) {
        return []
      }

      if (this.$v.rel.viuvo.$anyError) {
        return ['Selecione um período']
      }
    },
    casadosErrors() {
      if (!this.$v.rel.casados.$anyDirty) {
        return []
      }

      if (this.$v.rel.casados.$anyError) {
        return ['Selecione um período']
      }
    },
    divorciadosErrors() {
      if (!this.$v.rel.divorciados.$anyDirty) {
        return []
      }

      if (this.$v.rel.divorciados.$anyError) {
        return ['Selecione um período']
      }
    },
    conhecemErrors() {
      if (!this.$v.rel.conhecem.$anyDirty) {
        return []
      }

      if (this.$v.rel.conhecem.$anyError) {
        return ['Selecione um período']
      }
    },
    regimeBensErrors() {
      if (!this.$v.rel.regimeBens.$anyDirty) {
        return []
      }

      if (this.$v.rel.regimeBens.$anyError) {
        return ['Selecione um regime']
      }
    },
  },
  methods: {
    stepBack() {
      this.$bus.$emit('backProfileStep')
    },
    copyData() {
      if (this.relacionamento) {
        this.rel = {
          ...this.rel,
          ...this.relacionamento
        }
      }

      if (this.estadoCivil === 'Viúvo(a)') {
        this.conhecem = ''
        this.casados = ''
        this.regimeBens = ''
        this.divorciados = ''
      } else if (this.estadoCivil === 'Divorciado(a)') {
        this.conhecem = ''
        this.casados = ''
        this.regimeBens = ''
        this.viuvo = ''
      } else if (['Casado(a)', 'União estável'].includes(this.estadoCivil)) {
        this.conhecem = ''
        this.viuvo = ''
        this.divorciados = ''
      } else if (this.hasConjuge) {
        this.casados = ''
        this.regimeBens = ''
        this.viuvo = ''
        this.divorciados = ''
      }
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          const relacionamento = JSON.parse(JSON.stringify(this.rel))
          const perfil = await updatePerfil(this.profileId, { relacionamento, step: 3 })

          await this.$store.dispatch('setPerfil', perfil)
          this.$bus.$emit('nextProfileStep')
        }
      } catch (err) {
        this.$noty.error('Desculpe, não foi possível salvar seus dados. Tente novamente mais tarde.')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.loader = false
      }
    }
  },
  mounted() {
    this.copyData()
  }
}
</script>

<style scoped>
@media only screen and (max-width: 475px) {
  .v-card__actions {
    display: flex;
    flex-direction: column-reverse !important;
    text-align: center;
  }

  .v-card__actions .v-btn {
    display: block;
    margin: 0 0 10px 0 !important;
    width: 100%;
  }
}
</style>
