<template>
  <v-card color="grey3">
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 class="mb-2">
            <h1 class="payer-title white--text">
              Fatura Empresa<span class="purple--text">.</span>

              <span class="ml-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-on="on"
                        color="grey"
                        v-bind="attrs"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>Valores pagos pela empresa à UPlanner, referente aos usuários do período selecionado.</span>
                </v-tooltip>
              </span>
            </h1>
          </v-flex>

          <v-flex xs12 sm4>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ numberToCurrency(invoice.value) }}</span>
              <span class="payer-desc primary--text">Valor Total</span>
            </div>
          </v-flex>

          <v-flex xs12 sm8>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ invoice.employees }} colaboradores</span>
              <span class="payer-desc primary--text">Referente a</span>
            </div>
          </v-flex>

          <v-flex xs12 class="mt-2">
            <h2 class="payer-title grey1--text">Detalhes</h2>
          </v-flex>

          <v-flex xs12 sm4>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ numberToCurrency(values.companyIndividual) }}</span>
              <span class="payer-desc primary--text">Valor Plano Individual</span>
            </div>
          </v-flex>

          <v-flex xs12 sm4>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ invoice.employeesIndividual }}</span>
              <span class="payer-desc primary--text">Colaboradores</span>
            </div>
          </v-flex>

          <v-flex xs12 sm4>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ numberToCurrency(invoice.individualValue) }}</span>
              <span class="payer-desc primary--text">Total Plano Individual</span>
            </div>
          </v-flex>

          <v-flex xs12 my-2></v-flex>

          <v-flex xs12 sm4>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ numberToCurrency(values.companyFamily) }}</span>
              <span class="payer-desc primary--text">Valor Plano Familiar</span>
            </div>
          </v-flex>

          <v-flex xs12 sm4>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ invoice.employeesFamily }}</span>
              <span class="payer-desc primary--text">Colaboradores</span>
            </div>
          </v-flex>

          <v-flex xs12 sm4>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ numberToCurrency(invoice.familyValue) }}</span>
              <span class="payer-desc primary--text">Total Plano Familiar</span>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';

export default {
  name: 'CardCompanyHistoryInvoice',
  props: ['invoice', 'values'],
  data: () => ({
    numberToCurrency
  })
};
</script>

<style scoped>
.payer-title {
  font-size: 18px;
}

.payer-value {
  font-size: 15px;
  font-weight: bold;
}

.payer-desc {
  font-size: 9px;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 414px) {
  .payer-value {
    font-size: 18px;
  }

  .payer-desc {
    font-size: 10px;
  }
}
</style>
