<template>
  <v-card flat color="grey3">
    <v-card-title :class="isIOS ? 'ios-padding' : ''">Independência Financeira</v-card-title>

    <v-card-text class="px-1" v-if="independenciaFinanceira">
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 sm12 md4>
            <span class="input-label primary--text">Idade em que deseja alcançar a independência financeira</span>
            <v-text-field
              type="number"
              suffix="anos"
              class="pt-0 mt-0"
              color="grey1"
              v-model.number="idadeIndependencia"
              :error-messages="idadeIndependenciaErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md4 v-if="hasConjuge">
            <span
              class="input-label primary--text">Idade em que o cônjuge deseja alcançar a independência financeira</span>
            <v-text-field
              type="number"
              suffix="anos"
              class="pt-0 mt-0"
              color="grey1"
              v-model.number="idadeIndependenciaConjuge"
              :error-messages="idadeIndependenciaConjugeErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md4>
            <span class="input-label primary--text">Renda desejada na<br>independência</span>
            <v-text-field
              type="tel"
              v-money="money"
              class="pt-0 mt-0"
              color="grey1"
              v-model="rendaDesejada"
              :error-messages="rendaDesejadaErrors"
              id="perfil-ind-financeira-renda"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 class="mt-10">
            <v-card-actions class="pa-0 ma-0 submit-buttons">
              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="stepBack"
              >
                Voltar
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="skip"
              >
                Pular
              </v-btn>

              <v-btn
                rounded
                color="primary"
                class="px-5 darkgrey--text"
                :loading="loader"
                @click.stop="submit(null)"
              >
                Concluir perfil
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import {VMoney} from 'v-money'
import removeMoneyMask from '@/utils/removeMoneyMask';
import updateIndFinanceira from '@/api/indFinanceira/updateIndFinanceira';
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'FormIndFinanceira',
  directives: { money: VMoney },
  props: ['profileId', 'hasConjuge'],
  data: () => ({
    loader: false,
    rendaDesejada: '',
    idadeIndependencia: 65,
    idadeIndependenciaConjuge: 65,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    }
  }),
  validations: {
    rendaDesejada: {
      isValid: v => !!v && removeMoneyMask(v) > 0
    },
    idadeIndependencia: {
      isValid: v => !!v && v >= 18
    },
    idadeIndependenciaConjuge: {
      isValid(v) {
        return !this.hasConjuge || (!!v && v >= 18);
      }
    }
  },
  computed: {
    // error messages
    rendaDesejadaErrors() {
      if (!this.$v.rendaDesejada.$anyDirty) {
        return [];
      }

      if (this.$v.rendaDesejada.$anyError) {
        return ['Preencha uma renda válida'];
      }
    },
    idadeIndependenciaErrors() {
      if (!this.$v.idadeIndependencia.$anyDirty) {
        return [];
      }

      if (this.$v.idadeIndependencia.$anyError) {
        return ['Preencha uma idade válida'];
      }
    },
    idadeIndependenciaConjugeErrors() {
      if (!this.$v.idadeIndependenciaConjuge.$anyDirty) {
        return [];
      }

      if (this.$v.idadeIndependenciaConjuge.$anyError) {
        return ['Preencha uma idade válida'];
      }
    },
    // data
    independenciaFinanceira() {
      return this.$store.getters.independenciaFinanceira;
    },
    // helpers
    isIOS() {
      return this.$store.getters.isIOS;
    }
  },
  methods: {
    copyData() {
      if (this.independenciaFinanceira) {
        this.rendaDesejada = this.independenciaFinanceira.premissas.rendaDesejada.toFixed(2);
        this.idadeIndependencia = this.independenciaFinanceira.premissas.idadeIndependencia;
        this.idadeIndependenciaConjuge = this.independenciaFinanceira.premissas.idadeIndependenciaConjuge;
      }

      this.dispatchMoneyInputs();
    },
    skip() {
      this.submit(true);
    },
    stepBack() {
      this.$bus.$emit('backProfileStep');
    },
    async submit(skip) {
      try {
        let data = {
          completed: true,
          step: 9
        }

        if (!skip) {
          this.$v.$touch()

          if (!this.$v.$anyError) {
            this.loader = true

            let indFinanceira = JSON.parse(JSON.stringify(this.independenciaFinanceira))

            indFinanceira.premissas.rendaDesejada = removeMoneyMask(this.rendaDesejada)
            indFinanceira.premissas.idadeIndependencia = this.idadeIndependencia
            indFinanceira.premissas.idadeIndependenciaConjuge = this.idadeIndependenciaConjuge

            indFinanceira = await updateIndFinanceira(indFinanceira._id, indFinanceira)
            await this.$store.dispatch('setIndependenciaFinanceira', indFinanceira)

            const perfil = await updatePerfil(this.profileId, data)
            await this.$store.dispatch('setPerfil', perfil)

            this.$bus.$emit("closeDialogProfile");
          }

          return
        } else {
          updatePerfil(this.profileId, data).then((perfil) => {
            this.$store.dispatch("setPerfil", perfil);
          })
        }



        this.$noty.success('Perfil completado com sucesso!')
        this.$bus.$emit('closeDialogProfile')
      } catch (err) {
        this.$noty.error('Desculpe! Não conseguimos salvar seus dados. Tente novamente mais tarde.');

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.loader = false;
      }
    },
    dispatchMoneyInputs() {
      const input = document.getElementById('perfil-ind-financeira-renda');
      if (input) {
        input.value = this.rendaDesejada;
        input.dispatchEvent(new Event('input'));
      }
    }
  },
  mounted() {
    this.copyData();
  }
};
</script>

<style scoped>
@media only screen and (max-width: 475px) {
  .v-card__actions.submit-buttons {
    display: flex;
    flex-direction: column-reverse !important;
    text-align: center;
  }

  .v-card__actions.submit-buttons .v-btn {
    display: block;
    margin: 0 0 10px 0 !important;
    width: 100%;
  }
}
</style>
