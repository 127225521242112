<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    persistent
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="error"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="error--text">
          Remover Dependente
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <p>Tem certeza que deseja remover este dependente?</p>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            small
            color="error"
            :loading="loader"
            class="white--text"
            @click="submit"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import removeLifeInsuranceDependent from '@/api/lifeInsurance/removeLifeInsuranceDependent';

export default {
  name: 'DialogLifeInsuranceRemoveDependent',
  data: () => ({
    dialog: false,
    loader: false,
    insuranceId: null,
    dependentId: null
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.insuranceId = null;
      this.dependentId = null;
    },
    submit() {
      this.loader = true;

      removeLifeInsuranceDependent(this.insuranceId, this.dependentId)
        .then(lifeInsurance => {
          this.$store.dispatch('saveLifeInsurance', lifeInsurance)
          this.close();
        })
        .catch(err => {
          this.loader = false;
          this.$noty.error('Desculpe! Não foi possível salvar estes dados. Tente novamente!');

          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }
        });
    }
  },
  created() {
    this.$bus.$on('showDialogLifeInsuranceRemoveDependent', data => {
      if (data) {
        this.insuranceId = data.insuranceId;
        this.dependentId = data.dependentId;
        this.dialog = true;
      }
    });
  }
}
</script>
