import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const updateInitialAmount = (accountId, data) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'PUT';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `bank-accounts/${accountId}`;

        return httpClient(url, { method, headers, data });
      })
      .then(resp =>
        resolve(resp.data)
      )
      .catch(reject);
  });
}

export default updateInitialAmount;
