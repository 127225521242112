<template>
  <v-speed-dial
    v-model="fab"
    direction="top"
    class="speed-dial"
    transition="slide-y-reverse-transition"
    v-if="allowed"
    style="z-index: 10"
  >
    <template v-slot:activator>
      <v-btn fab dark color="primary" class="darkgrey--text" :disabled="!allowed">
        <v-icon v-if="fab">mdi-close</v-icon>
        <v-icon v-else>mdi-plus</v-icon>
      </v-btn>
    </template>

    <div
      class="text-center floating-btn-item"
      @click="showDialogFormCreditCardExpense"
    >
      <v-btn
        fab
        dark
        small
        color="warning"
      >
        <i class="white--text lni lni-credit-cards"></i>
      </v-btn>
      <small class="floating-btn-item-desc">
        Cartão de<br>Crédito
      </small>
    </div>

    <div class="text-center floating-btn-item" @click="showDialogFormExpense">
      <v-btn
        fab
        dark
        small
        color="error"
      >
        <i class="white--text lni lni-wallet"></i>
      </v-btn>
      <small class="floating-btn-item-desc">Débito</small>
    </div>

    <div class="text-center floating-btn-item" @click="showDialogFormRevenue">
      <v-btn
        fab
        dark
        small
        color="success"
      >
        <i class="white--text lni lni-revenue"></i>
      </v-btn>
      <small class="floating-btn-item-desc">Receita</small>
    </div>

    <div class="text-center floating-btn-item" @click="showDialogFormInvestment">
      <v-btn
        fab
        dark
        small
        color="primary"
      >
        <i class="white--text lni lni-stats-up"></i>
      </v-btn>
      <small class="floating-btn-item-desc">Investimento</small>
    </div>

    <div class="text-center floating-btn-item" @click.stop="showDialogBankTransfer">
      <v-btn
        fab
        dark
        small
        color="info"
      >
        <i class="white--text lni lni-investment"></i>
      </v-btn>
      <small class="floating-btn-item-desc">Transferência</small>
    </div>

    <div class="text-center floating-btn-item" @click.stop="showDialogFormImportPdf">
      <v-btn
        fab
        dark
        small
        class="item6 grey3"
      >
        <i class="white--text lni lni-add-files"></i>
      </v-btn>
      <small class="floating-btn-item-desc">Importar</small>
    </div>
  </v-speed-dial>
</template>

<script>
export default {
  name: 'ButtonForms',
  data: () => ({
    fab: false
  }),
  methods: {
    showDialogFormRevenue() {
      this.$bus.$emit('showDialogFormRevenue');
    },
    showDialogFormExpense() {
      this.$bus.$emit('showDialogFormExpense');
    },
    showDialogBankTransfer() {
      this.$bus.$emit('showDialogBankTransfer');
    },
    showDialogFormInvestment() {
      this.$bus.$emit('showDialogFormInvestment');
    },
    showDialogFormCreditCardExpense() {
      this.$bus.$emit('showDialogFormCreditCardExpense');
    },
    showBankAccountsDialog() {
      this.$bus.$emit('showDialogMobileBankAccountsList');
    },
    showDialogFormImportPdf() {
      this.$bus.$emit('showDialogImportTransactionsFromFile');
    }
  },
  computed: {
    allowed() {
      return this.$store.getters.allowed;
    }
  }
};
</script>

<style scoped>
.speed-dial {
  right: 0;
  bottom: 0;
  margin-right: 40px;
  margin-bottom: 40px;
  position: fixed !important;
}

.lni {
  font-size: 20px;
}

.floating-btn-item {
  width: 180px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.floating-btn-item-desc {
  display: block;
  width: 100%;
  font-size: 12px;
  letter-spacing: .05em;
  font-weight: 500;
  line-height: 1.2em;
}
</style>
