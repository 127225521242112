<template>
  <v-card
    flat
    color="grey3"
    class="card-pendencia"
    @click="showDialogPendencias(type)"
  >
    <v-card-title>
      <v-badge avatar overlap>
        <template v-slot:badge>
          <v-avatar :color="color">{{ qtde }}</v-avatar>
        </template>
        <v-avatar color="darkgrey" class="pendencia-avatar">
          <v-icon :color="color">{{ icon }}</v-icon>
        </v-avatar>
      </v-badge>
    </v-card-title>

    <v-card-text class="pendencia-info">
      <span class="pendencia-desc">{{ title }}</span>
      <span class="pendencia-value" :class="color + '--text'">
          <span>R$</span> {{ value }}
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CardPendencia',
  props: ['color', 'qtde', 'value', 'icon', 'title', 'type', 'period'],
  methods: {
    showDialogPendencias(type) {
      this.$bus.$emit('showDialogPendencias', {type, period: this.period});
    }
  }
}
</script>

<style scoped>
.v-card__text.pendencia-info {
  display: flex;
  flex-direction: column;
}

.pendencia-desc {
  color: #9e9e9e;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: .1em;
}

.pendencia-value {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .1em;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.pendencia-value span {
  font-size: 10px;
}

@media only screen and (min-width: 600px) {
  .v-avatar.pendencia-avatar {
    width: 45px !important;
    height: 47px !important;
  }

  .v-avatar.pendencia-avatar .v-icon {
    font-size: 23px;
  }

  .pendencia-desc {
    font-size: 12px;
    line-height: 1.5em;
  }

  .pendencia-value {
    font-size: 18px;
  }

  .pendencia-value span {
    font-size: 13px;
  }
}

@media only screen and (min-width: 960px) {
  .v-card.card-pendencia {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .v-card__text.pendencia-info {
    padding: 0 !important;
    margin-left: 10px;
  }
}
</style>
