import httpClient from "../../plugins/httpClient";
import firebase from 'firebase/app';

const getRewardRequests = (nome_email = '', status= '', page = 1, limit = 10) => {
  return new Promise((resolve, reject) => {

    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'GET';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/reward-requests?name_email=${nome_email}&status=${status}&page=${page}&limit=${limit}`;

        return httpClient(url, { headers, method });
      })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(reject);
  });
}

export default getRewardRequests
