<template>
  <v-card :color="cardColor">
    <div class="text-center pt-3 pb-5 text-uppercase primary--text">
      Meu gráfico da independência
    </div>

    <apexchart
      type="line"
      :options="options"
      :series="series"
      width="100%"
      height="350"
    ></apexchart>

    <v-card-text class="legends">
      <div xs12 v-for="item in legends">
        <div class="legend-item">
          <v-avatar size="10" :color="item.color" class="mr-2"></v-avatar>
          <span class="legend-item--title">{{ item.text }}</span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import {orderBy} from 'lodash'
  import VueApexCharts from 'vue-apexcharts';
  import plotRendaConsumo from "../../../../utils/indFinanceira/plotRendaConsumo";
  import numberToCurrency from "../../../../utils/numberToCurrency";
  import plotReal from "../../../../utils/indFinanceira/plotReal";

  export default {
    name: 'CardGraficoIndFinanceira',
    props: ['tab'],
    components: { apexchart: VueApexCharts },
    data: () => ({
      options: {
        colors: ['#D1AC68', '#FF3636', '#FFF'],
        labels: [],
        chart: {
          type: 'line',
          toolbar: { show: false },
          background: 'rgba(255,255,255, 0)',
          zoom: { enabled: false }
        },
        dataLabels: { enabled: false },
        legend: { show: false },
        grid: { show: false },
        stroke: {
          width: 2,
          curve: 'smooth',
          dashArray: [5, 5, 0]
        },
        xaxis: {
          show: true,
          type: 'category',
          categories: [],
          labels: {
            rotateAlways: true,
            rotate: -45,
            style: {
              colors: '#fff'
            }
          }
        },
        yaxis: {
          min: 0,
          labels: {
            show: true,
            align: 'right',
            style: {
              colors: '#919191'
            },
            formatter: v => Math.ceil(parseInt(v) / 1000) + 'K'
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: v => numberToCurrency(v)
          }
        },
        markers: {
          size: 0
        }
      },
      legends: [
        {
          text: 'Renda vitalícia',
          color: '#D1AC68'
        },
        {
          text: 'Consumo de patrimônio',
          color: '#ff3636'
        },
        {
          text: 'Cenário real',
          color: '#FFF'
        }
      ],
      series: [
        {
          name: 'Renda vitalícia',
          data: [],
          type: 'line'
        },
        {
          name: 'Consumo patrimônio',
          data: [],
          type: 'line'
        },
        {
          name: 'Cenário real',
          data: [],
          type: 'area'
        }
      ]
    }),
    computed: {
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      cardColor() {
        return this.$route.name === 'Cashflow' || (this.tab && this.tab === 'cashflow') ? '' : 'grey2'
      }
    },
    methods: {
      buildChart() {
        if (this.indFinanceira) {
          let options = JSON.parse(JSON.stringify(this.options));

          let periodosProducao = this.indFinanceira.premissas.anosProducao * 12;
          let periodos = (this.indFinanceira.premissas.idadeExpectativaVida - this.indFinanceira.premissas.idadeAtual) * 12;

          let rendaLiquida = 0;

          if (this.indFinanceira.rendas.items.length) {
            let renda = orderBy(this.indFinanceira.rendas.items, 'data')[0];
            rendaLiquida += renda.valor;
          }

          rendaLiquida -= (this.indFinanceira.rendas.rendaInss + this.indFinanceira.rendas.rendaOutros);

          let renda = plotRendaConsumo(
            this.indFinanceira.taxasProducao.taxaLiquidaMensal,
            this.indFinanceira.taxasConsumo.taxaLiquidaMensal,
            periodos,
            periodosProducao,
            this.indFinanceira.premissas.patrimonioLiquidoAtual,
            this.indFinanceira.patrimonio.parcelaPatrimonioRenda,
            rendaLiquida
          );

          let intervalos = 12;
          let pageWidth = window.innerWidth;

          if (pageWidth < 600) {
            intervalos = 36;
          } else if (pageWidth < 960) {
            intervalos = 24;
          }

          this.series[0].data = [];
          let len = renda.length;
          for (let i = 0; i < len; i += intervalos) {
            this.series[0].data.push(renda[i]);
          }

          let consumo = plotRendaConsumo(
            this.indFinanceira.taxasProducao.taxaLiquidaMensal,
            this.indFinanceira.taxasConsumo.taxaLiquidaMensal,
            periodos,
            periodosProducao,
            this.indFinanceira.premissas.patrimonioLiquidoAtual,
            this.indFinanceira.patrimonio.parcelaPatrimonioConsumo,
            rendaLiquida
          );

          this.series[1].data = [];
          len = consumo.length;
          for (let i = 0; i < len; i += intervalos) {
            this.series[1].data.push(consumo[i]);
          }

          let real = plotReal(
            this.indFinanceira.taxasProducao.taxaLiquidaMensal,
            this.indFinanceira.taxasConsumo.taxaLiquidaMensal,
            periodos,
            periodosProducao,
            this.indFinanceira.premissas.patrimonioLiquidoAtual,
            this.indFinanceira.aplicacoes,
            this.indFinanceira.rendas.items,
            this.indFinanceira.rendas.rendaInss,
            this.indFinanceira.rendas.rendaOutros,
            this.indFinanceira.projetos,
            this.indFinanceira.dataInicial
          );

          let idadeInicial = this.indFinanceira.premissas.idadeAtual;
          options.labels.push(idadeInicial + ' anos');

          this.series[2].data = [];
          len = real.length;
          for (let i = 0; i < len; i += intervalos) {
            idadeInicial += (intervalos / 12);
            options.labels.push(idadeInicial + ' anos');
            this.series[2].data.push(real[i]);
          }

          options.yaxis = {
            min: 0,
            labels: {
              show: true,
              align: 'right',
              style: {
                colors: '#9E9E9E'
              },
              formatter: v => Math.ceil(parseInt(v) / 1000) + 'K'
            }
          }
          options.tooltip =  {
            theme: 'dark',
              y: {
              formatter: v => numberToCurrency(v > 0 ? v : 0)
            }
          };

          options.fill = {
            opacity: [1, 1, 0.10],
            gradient: {
              inverseColors: false,
              shade: 'light',
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100]
            }
          }

          this.options = options;
        }
      }
    },
    watch: {
      indFinanceira: {
        deep: true,
        handler() {
          if (this.indFinanceira)
            this.buildChart();
        }
      }
    },
    created() {
      this.buildChart();
    }
  }
</script>

<style scoped>
  .text-uppercase {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: .1em;
  }

  .v-card.chart-card {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }

  .v-card__title.chart-title {
    font-size: 12px !important;
    letter-spacing: .1em;
    font-weight: bold;
  }

  .legends {
    display: flex;
    flex-direction: column;
  }

  .legend-item {
    display: flex;
    margin-bottom: 2px;
    margin-right: 20px;
  }

  .legend-item div {
    display: flex;
    flex-direction: column;
  }

  .legend-item--title {
    font-size: 12px;
    letter-spacing: .1em;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    line-height: 12px;
  }

  @media only screen and (min-width: 600px) {
    .legends {
      flex-direction: row;
    }
  }
</style>
