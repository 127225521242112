import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Contar total de usuário agrupados pelo status
 * @param {object} query {companyId, plannerId, type}
 */
const countUsersGroupedByType = query =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };

        let url = '/users/count/status-group?';

        if (query.companyId) url += `companyId=${query.companyId}&`;

        if (query.plannerId) url += `plannerId=${query.plannerId}&`;

        if (query.type) url += `type=${query.type}&`;

        return httpClient(url, { headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });

export default countUsersGroupedByType;
