<template>
  <v-main :class="isIOS ? 'ios-padding' : ''">
    <v-container grid-list-md class="px-5">
      <v-layout row wrap>
        <v-flex xs12>
          <v-btn icon large @click="goBack" id="topo">
            <v-icon x-large color="darkgrey">mdi-chevron-left</v-icon>
          </v-btn>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <h1>Política <br class="hidden-md-and-up">de Privacidade<span class="primary--text">.</span></h1>
          <h2 class="doc-subtitle grey--text">
            A presente política contem informações sobre como tratamos, total ou parcialmente os dados pessoais dos
            usuários que nos acessam.
          </h2>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3>Definições</h3>
          </div>

          <p class="doc-paragraph">
            A Uplanner (gênero feminino): refere-se à empresa e sua respectiva razão social;
          </p>

          <p class="doc-paragraph">
            O Uplanner (gênero masculino): refere-se ao Sistema Uplanner, que abrange Webapp e App.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">1.1</span> Da privacidade</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">1.2</span> Desenvolvida pela UPLANNER SISTEMA DE PLANEJAMENTO FINANCEIRO LTDA,
            pessoa jurídica de direito privado, inscrita no CNPJ sob n. 33.610.156/0001-43 com objetivo de esclarecer
            aos interessados acerca dos tipos de dados coletados e a transparência quanto ao seu tratamento.
          </p>

          <p class="doc-paragraph">
            A presente foi elaborada em conformidade com a Lei Federal .12.965 de 23 de abril de 2014 (Marco Civil da
            Internet), com a Lei Federal 13.709, de 14 de agosto de 2018 (Lei de proteção de Dados Pessoais), com o
            Regulamento EU n.2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais),
            bem como em harmonia aos princípios da Lei n. 8.078 de 11 de setembro de 1990 (Código de Defesa do
            Consumidor).
          </p>

          <p class="doc-paragraph">
            <span class="sec-number-text">1.3 Atenção</span> O registro, navegação e uso do <strong>Uplanner</strong>
            depende do aceite dos termos e desta Política de Privacidade. Caso Usuário não concorde com alguma das
            condições previstas, recomenda-se que feche a página sem realizar o cadastro.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">1.4</span> Tendo em vista as finalidades previstas na Lei Geral de Proteção de
            Dados Pessoais, em respeito à privacidade e a inviolabilidade da intimidade, da honra e da imagem a Uplanner
            compromete-se a cumprir as normas previstas no RGPD observando seus princípios:
          </p>

          <ul type="a">
            <li>
              <p class="doc-paragraph">
                Os dados pessoais do usuário serão processados de forma lícita, leal e transparente;
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                Os dados pessoais do usuário serão coletados apenas para as finalidades determinadas, explícitas e
                legítimas, não sendo tratadas posteriormente de forma incompatível com estas finalidades;
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                Os dados pessoais do usuário serão coletados de forma adequada, pertinente e limitada às necessidades do
                objetivo para os quais eles são processados;
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                Os dados pessoais do usuário serão exatos e atualizados sempre que necessário, de maneira que os dados
                inexatos sejam apagados ou retificados quando possível;
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                Os dados pessoais do usuário serão conservados de forma que permita a identificação dos titulares dos
                dados apenas durante o período necessário para as finalidades as quais são tratados
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                Os dados pessoais do usuário serão tratados de forma segura, protegidos do tratamento não autorizado ou
                ilícito e contra a sua perda, destruição ou danificação acidental adotando as medidas técnicas ou
                organizativas adequadas.
              </p>
            </li>
          </ul>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">2.1</span> Dos dados pessoais coletados</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">2.2</span> Dados Pessoais são todo e quaisquer dados que permitam a identificação
            pessoal do Usuário.
          </p>

          <p class="doc-paragraph">
            Os Dados Pessoais incluem entre outros o nome completo, e-mail, número de telefone e CPF fornecidos pelo
            Usuário ao Uplanner. Quando você acessa nosso site, também recebemos automaticamente o protocolo de internet
            do seu computador, endereço de IP, a fim de obter informações que nos ajudam a aprender sobre seu navegador
            e sistema operacional.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">2.2.1</span> Dados Financeiros – Todo e quaisquer dados financeiros do Usuário que
            detalham, de forma específica e pessoal, informações de transações - que podem ser coletadas de sua(s)
            conta(s) pelo Software ou inseridas manualmente pelo Usuário na sua Conta Uplanner - incluindo, sem
            limitação, renda mensal, gastos, a natureza e categoria de suas despesas, dívidas e investimentos, entre
            outros dados do tipo que sejam relevantes ao planejamento financeiro do Usuário.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">3.0</span> Dos dados pessoais sensíveis</h3>
          </div>

          <p class="doc-paragraph">
            O aplicativo poderá coletar dados sensíveis a fim de proporcionar ao usuário uma experiência personalizada.
            O tratamento destes dados será feito quando o titular ou seu responsável legal, der um consentimento para as
            finalidades determinadas, de forma que esse consentimento será previamente solicitado, específico e
            destacado.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">4.0</span> Do consentimento</h3>
          </div>

          <p class="doc-paragraph">
            Quando você fornece informações pessoais como nome, telefone e endereço, para completar seu cadastro no
            Uplanner entendemos que você está de acordo com a coleta de dados a serem utilizados pela nossa empresa.
          </p>

          <p class="doc-paragraph">
            Se pedimos suas informações pessoais por uma razão secundária, como marketing, pediremos diretamente seu
            consentimento, e lhe ofereceremos a oportunidade de dizer não.
          </p>

          <p class="doc-paragraph">
            Pensando nos princípios da boa fé, transparência e ética, o Uplanner grava suas reuniões realizadas por meio
            de Webconferência para sua segurança, disponibilizando-as mediante requisição judicial se necessário,
            armazenando-as não mais do que o previsto em lei.
          </p>

          <p class="doc-paragraph">
            Em respeito a facilidade de acecsso, o Usuário pode mediante requisição obter as informações de seus
            direitos elencados no Artigo 18 da Lei nº13.709 de agosto de 2018 bem como se após nos fornecer seus dados,
            mudar de ideia poderá revogar seu consentimento entrando em contato conosco em <a
            href="mailto:suporte@uplanner.com.br" class="primary--text">suporte@uplanner.com.br</a> ou
            nos enviando uma correspondência em: Uplanner Rua Visconde do Rio Branco, 1488, sala 910 – Curitiba-PR;
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">5.0</span> Dos serviços de terceiros</h3>
          </div>

          <p class="doc-paragraph">
            No geral, os fornecedores terceirizados do Uplanner irão apenas coletar, usar e divulgar suas informações na
            medida do necessário para permitir que eles realizem os serviços que eles nos fornecem.
          </p>

          <p class="doc-paragraph">
            Entretanto, certos fornecedores de serviços terceirizados, têm sua própria política de privacidade em
            respeito à informação que somos obrigados a fornecê-los para suas transações. Para esses fornecedores,
            recomendamos que você leia suas políticas de privacidade para que possa entender a maneira na qual suas
            informações pessoais serão usadas por esses fornecedores.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">6.1</span> Da finalidade e tratamento dos dados</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">6.2</span>
            Os dados pessoais coletados por meio do aplicativo têm por finalidade facilitar, e cumprir os compromissos
            estabelecidos para os seguintes fins:
          </p>

          <p class="doc-paragraph">
            <strong>Aprimoramento da Plataforma</strong> - Todos e quaisquer os dados coletados poderão ser utilizados
            para manter e
            aprimorar o funcionamento do Aplicativo/Site. O Uplanner e/ou seu Planejador Vinculado poderão acessar os
            seus Dados Financeiros para lhe prestar atendimento quando necessário, por exemplo: para entender o motivo
            de um problema de importação de conta bancária ao Software.
          </p>

          <p class="doc-paragraph">
            <strong>Contato</strong> - O Uplanner e/ou seu Planejador Vinculado poderá enviar-lhes e-mails e/ou
            notificações com alertas e comunicados relacionados ao Uplanner a fim de auxiliá-los a explorar todas as
            funcionalidades do Uplanner, como alertas para realização de planejamento financeiro, inclusão de
            funcionalidades, lembretes de uso do Site/Aplicativo, entre outros. Se o Usuário não quiser mais receber
            e-mails ou notificações deverá enviar a solicitação para
            <a href="mailto:contato@uplanner.com.br">contato@uplanner.com.br</a>.
          </p>

          <p class="doc-paragraph">
            <strong>Avisos</strong> - O Uplanner poderá analisar, de forma automatizada - por meio do Software - e/ou
            caso a caso, os dados coletados para fins de análise financeira individualizada objetivando auxiliar seu
            planejamento de gastos.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">6.3</span>
            O tratamento de dados pessoais para finalidades não previstas na Política de Privacidade somente ocorrerá
            mediante comunicação prévia ao usuário sendo que em todo caso, os direitos e obrigações aqui previstos
            permanecerão aplicáveis.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">7.0</span> Do armazenamento de dados</h3>
          </div>

          <p class="doc-paragraph">
            Os dados poderão ser armazenados em servidores localizados no Brasil e/ou em território internacional.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">8.0</span> Da segurança e do tratamento dos dados pessoais</h3>
          </div>

          <p class="doc-paragraph">
            É nossa prioridade a segurança de todos os dados relacionados aos nossos Usuários. Mesmo sabendo que todo
            sistema de proteção está sujeito a defeitos e possíveis violações que levem ao vazamento de dados, buscamos
            sempre evitar que isso ocorra ao adotar um nível de proteção alto, certificado por empresas especializadas
            em segurança.
          </p>

          <p class="doc-paragraph">
            Todos os dados identificáveis são armazenados de forma criptografada. As informações são protegidas com alta
            tecnologia para que os dados do Usuário permaneçam em sigilo. Além disso, essa tecnologia visa impedir que
            as informações sejam transmitidas ou acessadas por terceiros não autorizados.
          </p>

          <p class="doc-paragraph">
            Por todo o tempo em que o Usuário mantiver a sua Conta Uplanner, todas as informações coletadas serão
            armazenadas em servidores com alto padrão de segurança.
          </p>

          <p class="doc-paragraph">
            No entanto, a Uplanner se exime de responsabilidade por culpa exclusiva de terceiros, como em caso de ataque
            de harkers ou cakcers, ou culpa exclusiva do usuário, como no
            caso que ele mesmo transfere seus dados a um terceiro. O aplicativo se compromete ainda, a comunicar o
            usuário em prazo adequado caso ocorra algum tipo de violação da segurança de seus dados pessoais que possa
            lhe causar um alto risco para seus direitos e liberdades pessoais.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">9.0</span> Do prazo de conservação dos dados</h3>
          </div>

          <p class="doc-paragraph">
            Os dados serão mantidos pelo Uplanner durante a vigência da relação com os Usuários na plataforma e,
            posteriormente a isso, apenas dentro do prazo legal estabelecido.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">10.</span> Das alterações</h3>
          </div>

          <p class="doc-paragraph">
            Esta Política de Privacidade poderá ser atualizada a qualquer momento, especialmente para adaptá-las as
            evoluções do aplicativo Uplanner, seja pela disponibilização de novas funcionalidades, seja pela supressão
            ou modificação daquelas já existentes.
          </p>

          <p class="doc-paragraph">
            O usuário será explicitamente notificado em caso de alteração desta política.
          </p>

          <p class="doc-paragraph">
            Ao utilizar o serviço, após eventuais modificações, o usuário demonstra sua concordância com as novas
            normas. Caso discorde de alguma das modificações, deverá pedir, imediatamente o cancelamento de sua conta a
            apresentar sua ressalva ao serviço de atendimento, se assim desejar.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">11.</span> Do direito aplicável ao foro</h3>
          </div>

          <p class="doc-paragraph">
            Para a solução das controvérsias decorrentes do presente instrumento, será aplicado integralmente o Direito
            brasileiro.
          </p>

          <p class="doc-paragraph">
            Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede do editor do
            aplicativo.
          </p>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

export default {
  name: 'PoliticaPrivacidade',
  // metaInfo() {
  //   return {
  //     title: "Politica de privacidade",
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo)
  //     }
  //   };
  // },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    }
  },
  methods: {
    goBack() {
      window.history.back();
    }
  },
  beforeMount() {
    this.$vuetify.theme.light = true;
    this.$vuetify.theme.dark = false;
  }
};
</script>

<style scoped>
.v-main {
  background-color: #f6f6f6 !important;
}

.container {
  max-width: 600px !important;
}

h1 {
  font-weight: 900;
  font-size: 40px !important;
  line-height: 1em;
}

.doc-subtitle {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2em;
}

.doc-sec-title::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  background: #D1AC68;
  margin-top: 2px;
  margin-left: -5px;
}

.doc-sec-title h3 {
  font-size: 20px;
  font-weight: 900;
  line-height: 1em;
}

.sec-number {
  font-size: 14px;
  color: grey;
}

.doc-paragraph,
.sec-number-text {
  font-size: 16px;
  line-height: 1.5em;
}

.sec-number-text {
  color: grey;
  font-weight: bold;
}
</style>
