import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const getInvoiceTransactions = invoiceId => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let url = `/transactions/invoices/${invoiceId}`;
        let headers = { Authorization: `Bearer ${idToken}` };

        return httpClient(url, { headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default getInvoiceTransactions;
