import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#D1AC68',
        secondary: '#fff',
        accent: '#001136',
        grey1: '#9e9e9e',
        grey2: '#fff',
        grey3: '#2D2D2D',
        darkgrey: '#131313'
      },
      dark: {
        primary: '#D1AC68',
        secondary: '#fff',
        accent: '#D1AC68',
        grey1: '#9e9e9e',
        grey2: '#404040',
        grey3: '#2D2D2D',
        darkgrey: '#131313',
        bg: '#131313',
        purple: "#9468D1"
      }
    }
  },
  lang: {
    locales: { pt },
    current: 'pt'
  }
});
