<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-card class="safe-area-bottom">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          @click.stop="dialog = false"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="ml-2"> Recompensas </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-container grid-list-md fluid class="pa-0">
          <v-layout row wrap>
            <v-flex xs12>
              <v-card color="grey2" width="100%">
                <v-card-title
                  style="font-size: 14px"
                  class="primary--text text-uppercase font-weight-bold"
                >
                  Minhas Recompensas
                </v-card-title>

                <v-card-text>
                  <apexchart
                    type="donut"
                    :options="options"
                    :series="series"
                    width="100%"
                  ></apexchart>

                  <div>
                    <v-avatar size="10" color="#1C4781" class="ml-2 mr-3"></v-avatar>
                    <span class="legend-item--title">Total ganho R$ {{ usufruido }}</span>
                  </div>

                  <div>
                    <v-avatar size="10" color="#219653" class="ml-2 mr-3"></v-avatar>
                    <span class="legend-item--title">Total pendentes R$ {{ totalGanho }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12>
              <v-card color="grey2" width="100%">
                <v-card-title
                  style="font-size: 14px"
                  class="primary--text text-uppercase font-weight-bold"
                >
                  Todas as Recompensas
                </v-card-title>

                <v-card-text class="d-flex flex-column">
                  <v-card
                    v-for="i in recommendations"
                    :key="i._id"
                    width="100%"
                    color="grey3"
                    class="py-3 mb-2"
                  >
                    <v-card-text class="d-flex align-center py-3">
                      <div class="mr-7">
                        <i v-if="!!i.purchased" class="lni lni-rocket purple--text size-icon ml-3"></i>
                        <i v-else class="lni lni-alarm-clock primary--text size-icon ml-3"></i>
                      </div>

                      <div v-if="!!i.purchased" class="d-flex flex-column justify-center align-center">
                        <v-row>{{ i.recommendationNumber }}ª Indicação: um mês grátis!</v-row>
                      </div>

                      <div v-else class="d-flex flex-column justify-center align-center">
                        <v-row class="ml-3 mr-2">{{ i.name }} ainda não realizou a compra...</v-row>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { orderBy } from 'lodash'
import numberToCurrency from '@/utils/numberToCurrency';
import VueApexCharts from 'vue-apexcharts';
import dayjs from 'dayjs';

export default {
  name: "DialogRecommendationRewards",
  components: { apexchart: VueApexCharts },
  data: () => ({
    dialog: false,
    options: {
      colors: ['#1C4781', '#219653'],
      labels: ['Usufruídas', 'Total Ganho'],
      chart: {
        toolbar: { show: false },
        background: 'grey1'
      },
      dataLabels: { enabled: false },
      legend: { show: false },
      grid: { show: false },
      stroke: {
        width: [0, 0, 0, 2],
        curve: 'smooth'
      },
      xaxis: {
        show: false,
        type: 'category',
        categories: [],
        labels: {
          rotateAlways: true,
          rotate: -90,
          style: {
            colors: '#9E9E9E'
          }
        }
      },
      yaxis: {
        min: 0,
        labels: {
          show: true,
          align: 'right',
          style: {
            colors: '#9E9E9E'
          },
          formatter: v => Math.ceil(parseInt(v) / 1000) + 'K'
        }
      },
      tooltip: {
        theme: 'dark',
        y: {
          formatter: v => numberToCurrency(v)
        }
      },
      markers: {
        size: 5,
        colors: 'white',
        strokeWidth: 0
      },
    },
    series: [],
    legends: [
      {
        text: 'Todas as Recompensas',
        color: '#219653',
      },
      {
        text: 'Recompensas Usufruídas',
        color: '#1C4781',
      },
    ],

  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    comprasConfirmadas() {
      return this.recommendations.filter(r => r.purchased);
    },
    recommendations() {
      return orderBy(this.$store.getters.recommendations, ['purchased', 'recommendationNumber'], 'desc');
    },
    usufruido() {
      if(this.recommendations && this.recommendations.length) {
        const usufruidas = this.recommendations.filter(i => i.rewardEnjoyed)
        let total = _.sumBy(usufruidas, 'valueReward')

        total = total.toFixed(2).replace('.', ',')

        this.series[0] = (parseFloat(total))

        return total
      }
    },
    totalGanho() {
      if(this.recommendations && this.recommendations.length){
        const usufruidas = this.recommendations.filter(i => !!i.purchased && !i.rewardEnjoyed)
        let total = _.sumBy(usufruidas, 'valueReward')

        total = total.toFixed(2).replace('.',',')

        this.series[1] = (parseFloat(total))

        return total
      }
    }
  },
  methods: {
    formatedDate(date) {
      return dayjs(date).format('DD/MM/YYYY')
    }
  },
  created() {
    this.$bus.$on('showDialogRecommendationRewards', () => {
      if(!this.recommendations || !this.recommendations.length){
        this.dialog = false
        this.$noty.info("Você ainda não convidou seus amigos e não tem recompensas!")
      }  else if (!this.comprasConfirmadas[0]) {
        this.$noty.info("Desculpe! Sua indicação ainda não confirmou a compra!")
      }
      else{
        this.dialog = true
      }
    })
  }
}
</script>

<style scoped>

</style>
