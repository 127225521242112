<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
  >
    <v-card class="no-radius">
      <v-card-title
        class="dialog-form-title px-2"
        :class="isIOS ? ['ios-padding', 'fixed-title'] : ''"
      >
        <v-btn
          icon
          class="mr-5"
          color="primary"
          @click.stop="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text">Informações Básicas</span>
      </v-card-title>

      <v-card-text class="px-2" :class="isMobile ? 'mobile-margin' : ''">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm9 md9>
                <span class="input-label primary--text">Nome completo</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="usuario.nome"
                  :error-messages="usuarioNomeErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm3 md3>
                <span class="input-label primary--text">Data de nascimento</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-mask="'##/##/####'"
                  placeholder="dd/mm/aaaa"
                  v-model="usuario.dataNascimento"
                  :error-messages="usuarioDataNascimentoErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 md3>
                <span class="input-label primary--text">CPF</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="usuario.cpf"
                  :error-messages="usuarioCpfErrors"
                  v-mask="'###.###.###-##'"
                  id="usuario-cpf"
                ></v-text-field>
              </v-flex>

              <v-flex xs5 sm4 md3>
                <span class="input-label primary--text">Código país</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="usuario.phoneCountryCode"
                  :error-messages="usuarioPhoneCountryCodeErrors"
                  v-mask="'#####'"
                  prefix="+"
                  hint="Código de telefone do país"
                  persistent-hint
                ></v-text-field>
              </v-flex>

              <v-flex xs7 sm4 md3>
                <span class="input-label primary--text">Celular</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="usuario.phoneNumber"
                  :error-messages="usuarioPhoneNumberErrors"
                  v-mask="'##############'"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md3>
                <span class="input-label primary--text">Estado civil</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="usuario.estadoCivil"
                  :error-messages="usuarioEstadoCivilErrors"
                  :items="itemsEstadoCivil"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <span class="title">Endereço</span>
              </v-flex>

              <v-flex xs12 sm12 md3>
                <span class="input-label primary--text">CEP</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="usuario.endereco.cep"
                  :error-messages="usuarioAddressCepErrors"
                  v-mask="'#####-###'"
                  id="usuario-cep"
                  hint="Preencha com um CEP brasileiro"
                  persistent-hint
                  :loading="loaderCepUsuario"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md6>
                <span class="input-label primary--text">Logradouro</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="usuario.endereco.logradouro"
                  :error-messages="usuarioAddressLogradouroErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md3>
                <span class="input-label primary--text">Número</span>
                <v-text-field
                  type="number"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model.number="usuario.endereco.numero"
                  :error-messages="usuarioAddressNumeroErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md4>
                <span class="input-label primary--text">Bairro</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model.number="usuario.endereco.bairro"
                  :error-messages="usuarioAddressBairroErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md4>
                <span class="input-label primary--text">Cidade</span>
                <v-autocomplete
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model.number="usuario.endereco.cidade"
                  :error-messages="usuarioAddressCidadeErrors"
                  :loading="loaderCidadesUsuario"
                  :items="cidadesUsuario"
                  item-text="nome"
                  item-value="nome"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 sm12 md4>
                <span class="input-label primary--text">Estado</span>
                <v-autocomplete
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model.number="usuario.endereco.estado"
                  :error-messages="usuarioAddressEstadoErrors"
                  :items="estados"
                  item-text="nome"
                  return-object
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Complemento</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model.number="usuario.endereco.complemento"
                  :error-messages="usuarioAddressComplementoErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-switch
                  flat
                  inset
                  color="primary"
                  label="Adicionar conjuge"
                  v-model="hasConjuge"
                ></v-switch>
              </v-flex>

              <v-expand-transition>
                <v-container v-if="hasConjuge" class="pa-0 ma-0" grid-list-md fluid>
                  <v-layout class="pa-0 ma-0" row wrap>
                    <v-flex xs12 sm9 md9>
                      <span class="input-label primary--text">Nome completo</span>
                      <v-text-field
                        type="text"
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model="conjuge.nome"
                        :error-messages="conjugeNomeErrors"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <span class="input-label primary--text">Data de nascimento</span>
                      <v-text-field
                        type="tel"
                        color="grey1"
                        class="mt-0 pt-0"
                        v-mask="'##/##/####'"
                        placeholder="dd/mm/aaaa"
                        v-model="conjuge.dataNascimento"
                        :error-messages="conjugeDataNascimentoErrors"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs5 sm4 md3>
                      <span class="input-label primary--text">Código país</span>
                      <v-text-field
                        type="tel"
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model="conjuge.phoneCountryCode"
                        :error-messages="conjugePhoneCountryCodeErrors"
                        v-mask="'#####'"
                        prefix="+"
                        hint="Código de telefone do país"
                        persistent-hint
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs7 sm4 md3>
                      <span class="input-label primary--text">Celular</span>
                      <v-text-field
                        type="tel"
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model="conjuge.phoneNumber"
                        :error-messages="conjugePhoneNumberErrors"
                        v-mask="'##############'"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md3>
                      <span class="input-label primary--text">Estado civil</span>
                      <v-select
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model="conjuge.estadoCivil"
                        :error-messages="conjugeEstadoCivilErrors"
                        :items="itemsEstadoCivil"
                      ></v-select>
                    </v-flex>

                    <v-flex xs12>
                      <span class="title">Endereço</span>
                    </v-flex>

                    <v-flex xs12 sm12 md3>
                      <span class="input-label primary--text">CEP</span>
                      <v-text-field
                        type="tel"
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model="conjuge.endereco.cep"
                        :error-messages="conjugeAddressCepErrors"
                        v-mask="'#####-###'"
                        id="usuario-cep"
                        hint="Preencha com um CEP brasileiro"
                        persistent-hint
                        :loading="loaderCepConjuge"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md6>
                      <span class="input-label primary--text">Logradouro</span>
                      <v-text-field
                        type="text"
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model="conjuge.endereco.logradouro"
                        :error-messages="conjugeAddressLogradouroErrors"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md3>
                      <span class="input-label primary--text">Número</span>
                      <v-text-field
                        type="number"
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model.number="conjuge.endereco.numero"
                        :error-messages="conjugeAddressNumeroErrors"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md4>
                      <span class="input-label primary--text">Bairro</span>
                      <v-text-field
                        type="text"
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model.number="conjuge.endereco.bairro"
                        :error-messages="conjugeAddressBairroErrors"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md4>
                      <span class="input-label primary--text">Cidade</span>
                      <v-autocomplete
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model.number="conjuge.endereco.cidade"
                        :error-messages="conjugeAddressCidadeErrors"
                        :loading="loaderCidadesConjuge"
                        :items="cidadesConjuge"
                        item-text="nome"
                        item-value="nome"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 sm12 md4>
                      <span class="input-label primary--text">Estado</span>
                      <v-autocomplete
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model.number="conjuge.endereco.estado"
                        :error-messages="conjugeAddressEstadoErrors"
                        :items="estados"
                        item-text="nome"
                        return-object
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <span class="input-label primary--text">Complemento</span>
                      <v-text-field
                        type="text"
                        color="grey1"
                        class="mt-0 pt-0"
                        v-model.number="conjuge.endereco.complemento"
                        :error-messages="conjugeAddressComplementoErrors"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expand-transition>

              <v-flex xs12>
                <v-card-actions class="pa-0 ma-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    rounded
                    color="primary"
                    class="px-5 darkgrey--text"
                    :loading="loader"
                    @click.stop="submit"
                  >
                    Salvar
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import * as cpf from '@fnando/cpf';
import { required } from 'vuelidate/lib/validators';
import getCidadesByEstado from '@/api/address/getCidadesByEstado';
import getAddressByCep from '@/api/brasil-api/getAddressByCep';
import stringCapitalize from '@/utils/stringCapitalize';
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'DialogEditBasicInfos',
  data: () => ({
    dialog: false,
    loader: false,
    loaderCepUsuario: false,
    loaderCepConjuge: false,
    loaderCidadesUsuario: false,
    loaderCidadesConjuge: false,
    usuario: {
      cpf: '',
      nome: '',
      estadoCivil: '',
      phoneNumber: '',
      dataNascimento: '',
      phoneCountryCode: '55',
      endereco: {
        cep: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: null,
        logradouro: '',
        complemento: ''
      }
    },
    hasConjuge: false,
    conjuge: {
      nome: '',
      estadoCivil: '',
      phoneNumber: '',
      dataNascimento: '',
      phoneCountryCode: '55',
      endereco: {
        cep: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: null,
        logradouro: '',
        complemento: ''
      }
    },
    itemsEstadoCivil: [
      'Solteiro(a)',
      'Casado(a)',
      'Divorciado(a)',
      'Viúvo(a)',
      'União estável'
    ],
    cidadesUsuario: [],
    cidadesConjuge: []
  }),
  validations: {
    usuario: {
      nome: {
        isValid: v => !!v && v.trim().length <= 150 && v.split(/\s+/g).length > 1
      },
      cpf: {
        isValid: v => !!v && cpf.isValid(v)
      },
      estadoCivil: { required },
      phoneNumber: {
        isValid: v => !!v && v.length >= 4 && v.length <= 20
      },
      phoneCountryCode: {
        isValid: v => !!v && v.length <= 5
      },
      dataNascimento: {
        isValid(v) {
          return !!v && /\d{2}\/\d{2}\/\d{4}/.test(v);
        }
      },
      endereco: {
        cep: {
          isValid: v => !!v && v.length === 9
        },
        numero: {
          isValid: v => !!v && v > 0 && v < 100000
        },
        cidade: {
          isValid: v => !!v && v.length <= 256
        },
        logradouro: {
          isValid: v => !!v && v.length <= 256
        },
        bairro: {
          isValid: v => !!v && v.length <= 256
        },
        estado: { required },
        complemento: {
          isValid: v => !v || v.length <= 256
        }
      }
    },
    conjuge: {
      nome: {
        isValid: v => !!v && v.trim().length <= 150 && v.split(/\s+/g).length > 1
      },
      estadoCivil: { required },
      phoneNumber: {
        isValid: v => !!v && v.length >= 4 && v.length <= 20
      },
      phoneCountryCode: {
        isValid: v => !!v && v.length <= 5
      },
      dataNascimento: {
        isValid(v) {
          return !!v && /\d{2}\/\d{2}\/\d{4}/.test(v);
        }
      },
      endereco: {
        cep: {
          isValid: v => !!v && v.length === 9
        },
        numero: {
          isValid: v => !!v && v > 0 && v < 100000
        },
        cidade: {
          isValid: v => !!v && v.length <= 256
        },
        logradouro: {
          isValid: v => !!v && v.length <= 256
        },
        bairro: {
          isValid: v => !!v && v.length <= 256
        },
        estado: { required },
        complemento: {
          isValid: v => !v || v.length <= 256
        }
      }
    }
  },
  computed: {
    // form data
    estados() {
      return this.$store.getters.estados;
    },
    perfil() {
      return this.$store.getters.perfil;
    },
    // helpers
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    // error messages
    usuarioNomeErrors() {
      if (!this.$v.usuario.nome.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.nome.$anyError) {
        return ['Preencha um nome válido'];
      }
    },
    conjugeNomeErrors() {
      if (!this.$v.conjuge.nome.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.nome.$anyError) {
        return ['Preencha um nome válido'];
      }
    },
    usuarioCpfErrors() {
      if (!this.$v.usuario.cpf.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.cpf.$anyError) {
        return ['Preencha um cpf válido'];
      }
    },
    usuarioPhoneNumberErrors() {
      if (!this.$v.usuario.phoneNumber.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.phoneNumber.$anyError) {
        return ['Preencha um celular válido'];
      }
    },
    conjugePhoneNumberErrors() {
      if (!this.$v.conjuge.phoneNumber.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.phoneNumber.$anyError) {
        return ['Preencha um celular válido'];
      }
    },
    usuarioPhoneCountryCodeErrors() {
      if (!this.$v.usuario.phoneCountryCode.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.phoneCountryCode.$anyError) {
        return ['Preencha um código válido'];
      }
    },
    conjugePhoneCountryCodeErrors() {
      if (!this.$v.conjuge.phoneCountryCode.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.phoneCountryCode.$anyError) {
        return ['Preencha um código válido'];
      }
    },
    usuarioEstadoCivilErrors() {
      if (!this.$v.usuario.estadoCivil.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.estadoCivil.$anyError) {
        return ['Selecione um estado civil válido'];
      }
    },
    conjugeEstadoCivilErrors() {
      if (!this.$v.conjuge.estadoCivil.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.estadoCivil.$anyError) {
        return ['Selecione um estado civil válido'];
      }
    },
    usuarioDataNascimentoErrors() {
      if (!this.$v.usuario.dataNascimento.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.dataNascimento.$anyError) {
        return ['Preencha uma data válida'];
      }
    },
    conjugeDataNascimentoErrors() {
      if (!this.$v.conjuge.dataNascimento.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.dataNascimento.$anyError) {
        return ['Preencha uma data válida'];
      }
    },
    usuarioAddressComplementoErrors() {
      if (!this.$v.usuario.endereco.complemento.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.endereco.complemento.$anyError) {
        return ['Preencha um complemento válido'];
      }
    },
    usuarioAddressEstadoErrors() {
      if (!this.$v.usuario.endereco.estado.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.endereco.estado.$anyError) {
        return ['Preencha um estado válido'];
      }
    },
    usuarioAddressBairroErrors() {
      if (!this.$v.usuario.endereco.bairro.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.endereco.bairro.$anyError) {
        return ['Preencha um bairro válido'];
      }
    },
    usuarioAddressLogradouroErrors() {
      if (!this.$v.usuario.endereco.logradouro.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.endereco.logradouro.$anyError) {
        return ['Preencha um logradouro válido'];
      }
    },
    usuarioAddressCidadeErrors() {
      if (!this.$v.usuario.endereco.cidade.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.endereco.cidade.$anyError) {
        return ['Preencha uma cidade válida'];
      }
    },
    usuarioAddressNumeroErrors() {
      if (!this.$v.usuario.endereco.numero.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.endereco.numero.$anyError) {
        return ['Preencha um número válido'];
      }
    },
    usuarioAddressCepErrors() {
      if (!this.$v.usuario.endereco.cep.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.endereco.cep.$anyError) {
        return ['Preencha um cep válido'];
      }
    },
    conjugeAddressComplementoErrors() {
      if (!this.$v.conjuge.endereco.complemento.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.endereco.complemento.$anyError) {
        return ['Preencha um complemento válido'];
      }
    },
    conjugeAddressEstadoErrors() {
      if (!this.$v.conjuge.endereco.estado.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.endereco.estado.$anyError) {
        return ['Preencha um estado válido'];
      }
    },
    conjugeAddressBairroErrors() {
      if (!this.$v.conjuge.endereco.bairro.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.endereco.bairro.$anyError) {
        return ['Preencha um bairro válido'];
      }
    },
    conjugeAddressLogradouroErrors() {
      if (!this.$v.conjuge.endereco.logradouro.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.endereco.logradouro.$anyError) {
        return ['Preencha um logradouro válido'];
      }
    },
    conjugeAddressCidadeErrors() {
      if (!this.$v.conjuge.endereco.cidade.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.endereco.cidade.$anyError) {
        return ['Preencha uma cidade válida'];
      }
    },
    conjugeAddressNumeroErrors() {
      if (!this.$v.conjuge.endereco.numero.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.endereco.numero.$anyError) {
        return ['Preencha um número válido'];
      }
    },
    conjugeAddressCepErrors() {
      if (!this.$v.conjuge.endereco.cep.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.endereco.cep.$anyError) {
        return ['Preencha um cep válido'];
      }
    }
  },
  methods: {
    openDialog() {
      if (this.perfil) {
        this.usuario = JSON.parse(JSON.stringify(this.perfil.informacoesBasicas.usuario));

        if (/\d{4}-\d{2}-\d{2}/.test(this.usuario.dataNascimento)) {
          this.usuario.dataNascimento = this.usuario.dataNascimento.split(/-/g).reverse().join('/');
        }

        this.hasConjuge = !!this.perfil.informacoesBasicas.conjuge;

        if (this.hasConjuge && this.perfil.informacoesBasicas.conjuge && this.perfil.informacoesBasicas.conjuge.nome) {
          this.conjuge = JSON.parse(JSON.stringify(this.perfil.informacoesBasicas.conjuge));
          if (/\d{4}-\d{2}-\d{2}/.test(this.conjuge.dataNascimento)) {
            this.conjuge.dataNascimento = this.conjuge.dataNascimento.split(/-/g).reverse().join('/');
          }
        }

        this.updateInputs();

        this.dialog = true;
      }
    },
    updateInputs() {
      let usuarioCpfInput = document.getElementById('usuario-cpf');
      if (usuarioCpfInput) {
        usuarioCpfInput.value = this.usuario.cpf;
        usuarioCpfInput.dispatchEvent(new Event('input'));
      }

      let usuarioCepInput = document.getElementById('usuario-cep');
      if (usuarioCepInput) {
        usuarioCepInput.value = this.usuario.endereco.cep;
        usuarioCepInput.dispatchEvent(new Event('input'));
      }

      let conjugeCpfInput = document.getElementById('conjuge-cpf');
      if (conjugeCpfInput) {
        conjugeCpfInput.value = this.usuario.cpf;
        conjugeCpfInput.dispatchEvent(new Event('input'));
      }

      let conjugeCepInput = document.getElementById('conjuge-cep');
      if (conjugeCepInput) {
        conjugeCepInput.value = this.usuario.endereco.cep;
        conjugeCepInput.dispatchEvent(new Event('input'));
      }
    },
    closeDialog() {
      this.dialog = false;
      this.loader = false;
      this.usuario = {
        cpf: '',
        nome: '',
        estadoCivil: '',
        phoneNumber: '',
        dataNascimento: '',
        phoneCountryCode: '55',
        endereco: {
          cep: '',
          numero: '',
          bairro: '',
          cidade: '',
          estado: null,
          logradouro: '',
          complemento: ''
        }
      };
      this.hasConjuge = false;
      this.conjuge = {
        cpf: '',
        nome: '',
        estadoCivil: '',
        phoneNumber: '',
        dataNascimento: '',
        phoneCountryCode: '55',
        endereco: {
          cep: '',
          numero: '',
          bairro: '',
          cidade: '',
          estado: null,
          logradouro: '',
          complemento: ''
        }
      };
      this.$v.$reset();
    },
    async getCidades(idEstado, type) {
      try {
        if (idEstado && type) {
          if (type === 'usuario') {
            this.loaderCidadesUsuario = true;
          } else {
            this.loaderCidadesConjuge = true;
          }

          const cidades = await getCidadesByEstado(idEstado);

          if (type === 'usuario') {
            this.cidadesUsuario = cidades;
          } else {
            this.cidadesConjuge = cidades;
          }
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        if (type === 'usuario') {
          this.loaderCidadesUsuario = false;
        } else {
          this.loaderCidadesConjuge = false;
        }
      }
    },
    async getAddress(cep, type) {
      try {
        if (type === 'usuario') {
          this.loaderCepUsuario = true;
        } else {
          this.loaderCepConjuge = true;
        }

        cep = cep.replace(/\D+/g, '');

        const address = await getAddressByCep(cep);

        this[type].endereco.logradouro = address.street;
        this[type].endereco.bairro = address.neighborhood;
        this[type].endereco.cidade = address.city;
        this[type].endereco.estado = this.estados.find(e => e.sigla === address.state);

        await this.getCidades(this[type].endereco.estado.id, type);
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        if (type === 'usuario') {
          this.loaderCepUsuario = false;
        } else {
          this.loaderCepConjuge = false;
        }
      }
    },
    async submit() {
      try {
        this.$v.usuario.$touch();

        if (this.hasConjuge) {
          this.$v.conjuge.$touch();
        } else {
          this.$v.conjuge.$reset();
        }

        if (!this.$v.$anyError) {
          this.loader = true;

          const usuario = JSON.parse(JSON.stringify(this.usuario));

          usuario.nome = stringCapitalize(usuario.nome);

          if (/\d{2}\/\d{2}\/\d{4}/.test(usuario.dataNascimento)) {
            usuario.dataNascimento = usuario.dataNascimento.split(/\//g).reverse().join('-');
          }

          usuario.cpf = usuario.cpf.replace(/\D+/g, '');
          usuario.phoneNumber = usuario.phoneNumber.replace(/\D+/g, '');
          usuario.endereco.cep = usuario.endereco.cep.replace(/\D+/g, '');
          usuario.phoneCountryCode = usuario.phoneCountryCode.replace(/\D+/g, '');

          let conjuge = null;

          if (this.hasConjuge) {
            conjuge = JSON.parse(JSON.stringify(this.conjuge));

            conjuge.nome = stringCapitalize(conjuge.nome);

            if (/\d{2}\/\d{2}\/\d{4}/.test(conjuge.dataNascimento)) {
              conjuge.dataNascimento = conjuge.dataNascimento.split(/\//g).reverse().join('-');
            }

            conjuge.phoneNumber = conjuge.phoneNumber.replace(/\D+/g, '');
            conjuge.endereco.cep = conjuge.endereco.cep.replace(/\D+/g, '');
            conjuge.phoneCountryCode = conjuge.phoneCountryCode.replace(/\D+/g, '');
          }

          const informacoesBasicas = {
            usuario,
            conjuge
          };

          let step = 2;

          if (usuario.estadoCivil === 'Solteiro(a)' && !this.hasConjuge) {
            step = 3;
          }

          const perfil = await updatePerfil(this.perfil._id, { informacoesBasicas, step });
          await this.$store.dispatch('setPerfil', perfil);

          this.$noty.success('Dados salvos com sucesso!');
          this.closeDialog();
        }
      } catch (err) {
        this.$noty.error('Desculpe, não foi possível salvar seus dados. Tente novamente mais tarde.');

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.loader = false;
      }
    }
  },
  watch: {
    hasConjuge(val) {
      if (!val) {
        this.$v.conjuge.$reset();
      }
    },
    'usuario.endereco.cep'(val) {
      this.$v.usuario.endereco.cep.$touch();
      if (!this.$v.usuario.endereco.cep.$anyError) {
        this.getAddress(val, 'usuario');
      }
    },
    'conjuge.endereco.cep'(val) {
      this.$v.conjuge.endereco.cep.$touch();
      if (!this.$v.conjuge.endereco.cep.$anyError) {
        this.getAddress(val, 'conjuge');
      }
    }
  },
  created() {
    this.$bus.$on('showDialogEditBasicInfos', this.openDialog);
  }
};
</script>

<style scoped>
.v-card__title.dialog-form-title.fixed-title {
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 10;
}

.v-card__text.mobile-margin {
  padding-top: 90px !important;
}
</style>
