<template>
  <v-dialog
    v-model="dialog"
    :persistent="loader"
    max-width="500px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          x-small
          color="error"
          :disabled="loader"
          class="back-button"
          @click.stop="dialog = false"
        >
          <v-icon x-small>mdi-close</v-icon>
        </v-btn>

        <span class="error--text ml-4">
          Cancelar Assinatura
        </span>
      </v-card-title>

      <v-card-text class="mt-2">
        <p>
          Tem certeza que deseja cancelar sua assinatura?<br>
          <span class="error--text">
            Você não poderá acessar os recursos da UPlanner e perderá contato com seu planejador.
          </span>
        </p>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            dark
            rounded
            class="px-5"
            color="error"
            @click="submit"
            :loading="loader"
          >
            Cancelar Assinatura
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import suspendIuguSubscription from '@/api/iugu/subscriptions/suspendIuguSubscription';

export default {
  name: 'DialogCancelIuguSubscription',
  data: () => ({
    dialog: false,
    loader: false,
    subsciptionId: null
  }),
  methods: {
    openDialog(subscriptionId) {
      if (subscriptionId) {
        this.subsciptionId = subscriptionId
        this.dialog = true
      }
    },
    async submit() {
      try {
        this.loader = true
        await suspendIuguSubscription(this.subsciptionId)
        this.$noty.success('Assinatura desativada com sucesso!')

        this.dialog = false

        location.reload()
      } catch (err) {
        this.loader = false
        this.$noty.error('Desculpe! Não foi possível desativar sua assinatura. Tente novamente mais tarde!')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      }
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.loader = false
        this.subsciptionId = null
      }
    }
  },
  created() {
    this.$bus.$on('showDialogCancelIuguSubscription', this.openDialog)
  }
}
</script>
