<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card v-if="plan">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="error"
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="error--text ml-4">Remover Plano</span>
      </v-card-title>

      <v-card-text class="pt-5">
        <p>Tem certeza que deseja remover o plano <b>{{ plan.description }}</b>?</p>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            dark
            small
            color="error"
            @click="submit"
            :loading="loader"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import deleteActionPlan from '@/api/actionPlans/deleteActionPlan';

export default {
  name: 'DialogRemovePlanoAcao',
  data: () => ({
    dialog: false,
    loader: false,
    plan: null
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.plan = null;
    },
    submit() {
      this.loader = true;

      deleteActionPlan(this.plan._id)
        .then(() => {
          this.$store.dispatch('removeActionPlan', this.plan._id);
          this.close();
        })
        .catch(err => {
          this.loader = false;
          this.$noty.error('Desculpe! Não foi possível remover este plano. Tente novamente!');

          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    }
  },
  created() {
    this.$bus.$on('showDialogRemovePlanoAcao', plan => {
      if (plan) {
        this.plan = plan;
        this.dialog = true;
      }
    });
  }
}
</script>
