import dayjs from 'dayjs'
import { sum } from 'lodash'

export default (data, startDate, endDate, future) => {
  let monthsCount = data.length
  const currentPeriod = dayjs().format('YYYY-MM')

  if (!future) {
    if (!dayjs(startDate).isAfter(currentPeriod) && dayjs(endDate).isAfter(currentPeriod)) {
      monthsCount = dayjs(currentPeriod).diff(startDate, 'month') + 1
    }
  }

  const total = sum(data)
  let media = 0

  if (total > 0) {
    media = parseFloat((total / monthsCount).toFixed(2))
  }

  return media
}
