import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const deleteCreditCardTransaction = (transaction, fixedId = '', deleteType = '', openedInvoice = '') => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'DELETE';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/transactions/credit-cards/${transaction._id}?`;

        if (deleteType) {
          url += `deleteType=${deleteType}&`
        }

        if (openedInvoice) {
          url += `openedInvoice=${openedInvoice}`
        }

        let data = transaction;

        return httpClient(url, { headers, method, data });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default deleteCreditCardTransaction;

