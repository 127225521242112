<template>
  <v-content :style="'height: ' + currentHeight + 'px;'">
    <v-window v-model="step">
      <v-window-item :value="1" class="auth-step">
        <AuthContainerTwo />
      </v-window-item>
    </v-window>
  </v-content>
</template>

<script>
import AuthContainerTwo from '@/components/Auth/containers/AuthContainerTwo';

export default {
  name: 'Auth',
  components: {
    AuthContainerTwo
  },
  data: () => ({
    step: 1,
    currentHeight: 0
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    }
  },
  methods: {
    login() {
      if (this.isMobile) this.$bus.$emit('showDialogMobileLogin');
      else this.$router.push('/login');
    },
    showDialogMobileSignup() {
      this.$bus.$emit('showDialogMobileSignup');
    }
  },
  created() {
    if (!this.isMobile) {
      this.$router.push('/login');
    }

    this.$bus.$on('goToWindow', windowNumber => {
      this.step = windowNumber;
    });
  },
  beforeMount() {
    this.currentHeight = window.innerHeight;
    
    let self = this;
    window.addEventListener('resize', function(e) {
      self.currentHeight = window.innerHeight;
    });
  }
};
</script>

<style scoped>
  .ios-padding {
    padding-bottom: 40px !important;
  }

  @media only screen and (max-width: 375px) {
    .v-content {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  @media only screen and (max-width: 599px) {
    .v-content {
      background: url('../assets/mobile/auth-mobile1.png') center no-repeat no-repeat fixed;
      background-size: 100% auto;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }

    .auth-step:nth-child(2) {
      background-color: rgb(0, 0, 0) !important;
      background-color: rgba(0, 0, 0, 0.7) !important;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }

    .auth-step:nth-child(3) {
      background: url('../assets/mobile/auth-bg2-mobile.png') center no-repeat no-repeat fixed;
      background-size: 100% auto;
      overflow-y: scroll;
      scroll-behavior: smooth;
      background-color: rgba(0, 0, 0, 0.7) !important;
    }
  }

  @media only screen and (min-width: 600px) {
    .v-content {
      background: url('../assets/mobile/auth-mobile2.png') center no-repeat no-repeat fixed;
      background-size: 100% auto;
    }

    .auth-step:nth-child(2) {
      background-color: rgb(0, 0, 0) !important;
      background-color: rgba(0, 0, 0, 0.7) !important;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }

    .auth-step:nth-child(3) {
      background: url('../assets/mobile/auth-bg2-mobile.png') center no-repeat no-repeat fixed;
      background-size: 100% auto;
      overflow-y: scroll;
      scroll-behavior: smooth;
      background-color: rgba(0, 0, 0, 0.7) !important;
    }
  }
</style>
