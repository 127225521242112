<template>
  <v-card flat>
    <v-card-title>PGBL</v-card-title>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-layout row wrap>
          <v-flex lg4 md4 sm12 xs12>
            <v-text-field
              hint="Em %"
              type="number"
              color="primary"
              persistent-hint
              label="Taxa PGBL"
              v-model.number="pgbl"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            rounded
            depressed
            class="px-5 darkgrey--text"
            type="submit"
            color="primary"
            :loading="loader"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import saveConfigIr from '@/api/config/saveConfigIr';

export default {
  name: 'CardConfigPgbl',
  data: () => ({
    loader: false,
    pgbl: 0
  }),
  computed: {
    config() {
      return this.$store.getters.configIr;
    }
  },
  methods: {
    prepareData() {
      if (this.config.pgbl) {
        this.pgbl = this.config.pgbl;
      }
    },
    submit() {
      this.loader = true;

      saveConfigIr(this.config._id, { pgbl: this.pgbl })
        .then(config => {
          this.loader = false;
          this.$store.dispatch('setConfigIr', config);
          this.$noty.success('Dados salvos com sucesso!');
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
          this.$noty.error('Falha ao salvar dados! Tente novamente mais tarde');
        });
    }
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.prepareData();
      }
    }
  },
  mounted() {
    this.prepareData();
  }
};
</script>
