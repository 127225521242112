<template>
  <v-card flat class="animated fadeInRight">
    <v-card-title class="title-container">
      <span class="total-desc">Usuários Diários</span>

      <v-menu left bottom v-model="menu" :nudge-width="200" :close-on-content-click="false">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-icon v-on="{ ...tooltip, ...menu }">mdi-filter-variant</v-icon>
            </template>
            <span>Filtro</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-card-text>
            <v-select
              label="Períodos"
              color="accent"
              v-model="period"
              :items="periods"
              item-text="text"
              item-value="value"
            ></v-select>

            <v-select
              label="Tipo"
              color="accent"
              v-model="type"
              :items="userTypes"
              item-text="text"
              item-value="value"
            ></v-select>

            <v-btn
              small
              dark
              block
              rounded
              depressed
              type="button"
              color="darkgrey"
              :loading="loader"
              @click.stop="countUsers"
            >Filtrar</v-btn>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12>
          <apexchart
            width="100%"
            height="300px"
            type="area"
            :options="chartOptions.options"
            :series="chartOptions.series"
          ></apexchart>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import countUsersSeries from '@/api/users/countUsersSeries';

export default {
  name: 'CardCompanyActiveUsers',
  props: ['user'],
  components: {
    apexchart: VueApexCharts
  },
  data: () => ({
    chartOptions: {
      options: {
        colors: ['#DFCDA6', '#47484D'],
        chart: {
          id: 'registered-users',
          type: 'area',
          toolbar: {
            show: false
          }
        },
        xaxis: { categories: [] },
        stroke: {
          curve: 'smooth'
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 5
        }
      },
      series: [
        {
          name: 'Usuários registrados',
          data: []
        },
        {
          name: 'Usuários ativos',
          data: []
        }
      ]
    },
    periods: [
      {
        text: 'Últimos 7 dias',
        value: 'last7'
      },
      {
        text: 'Últimos 15 dias',
        value: 'last15'
      },
      {
        text: 'Últimos 30 dias',
        value: 'last30'
      }
    ],
    period: 'last7',
    menu: false,
    userTypes: [
      { text: 'Todos', value: '' },
      { text: 'Clientes', value: 'customer' },
      { text: 'Planejadores', value: 'planner' }
    ],
    type: '',
    loader: false
  }),
  methods: {
    countUsers() {
      this.loader = true;

      let filter = {
        period: this.period,
        type: this.type
      };

      Promise.all([
        countUsersSeries('signup', filter, this.user._id),
        countUsersSeries('signin', filter, this.user._id)
      ])
        .then(results => {
          let registeredUsers = results[0];
          let activeUsers = results[1];

          let chartOptions = {
            options: { xaxis: { categories: [] } },
            series: [
              {
                name: 'Usuários registrados',
                data: []
              },
              {
                name: 'Usuários ativos',
                data: []
              }
            ]
          };

          registeredUsers.forEach(data => {
            chartOptions.options.xaxis.categories.push(data.label);
            chartOptions.series[0].data.push(data.total);
          });

          activeUsers.forEach(data => {
            chartOptions.series[1].data.push(data.total);
          });

          this.chartOptions = { ...this.chartOptions, ...chartOptions };
          this.loader = false;
        })
        .catch(err => {
          console.error(err);
          this.loader = false;
        });
    }
  },
  created() {
    this.countUsers();
  }
};
</script>

<style scoped>
.total-desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.filter-container {
  display: flex;
  align-items: center;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
