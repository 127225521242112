import store from '@/store'
import firebase from "firebase/app"
import httpClient from "@/plugins/httpClient"

export default data => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const user = store.getters.user
        const url = '/users/change-planner'

        const options = {
          data,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
            role: user ? user.role : null
          }
        }

        return httpClient(url, options)
      })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}
