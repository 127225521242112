<template>
  <v-bottom-sheet
    v-if="event"
    width="500px"
    v-model="show"
    overlay-color="transparent"
  >
    <v-sheet color="grey2" class="pa-0 mx-auto radius">
      <v-list dense color="grey2">
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon color="primary">mdi-tag-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Tipo</v-list-item-subtitle>
            <v-list-item-title>{{ formatType(event.type) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon color="primary">mdi-calendar</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Data</v-list-item-subtitle>
            <v-list-item-title>{{ formatDate(event.date) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="event.author">
          <v-list-item-avatar>
            <v-icon color="primary">mdi-account-check-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Executado por</v-list-item-subtitle>
            <v-list-item-title>{{ event.author.displayName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="event.type === 'planner-changed'">
          <v-list-item-avatar>
            <v-icon color="primary">mdi-account-arrow-left-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Planejador anterior</v-list-item-subtitle>
            <v-list-item-title>
              {{ event.oldPlanner ? (event.oldPlanner.displayName ?
                event.oldPlanner.displayName : 'Não identificado') : 'Não possuia' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="event.type === 'planner-changed'">
          <v-list-item-avatar>
            <v-icon color="primary">mdi-account-arrow-right-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Planejador atual</v-list-item-subtitle>
            <v-list-item-title>
              {{ event.newPlanner ? (event.newPlanner.displayName ?
                event.newPlanner.displayName : 'Não identificado') : 'Não possui' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="event.type === 'company-changed'">
          <v-list-item-avatar>
            <v-icon color="primary">mdi-bank-transfer</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Empresa anterior</v-list-item-subtitle>
            <v-list-item-title>
              {{ event.oldCompany ? (event.oldCompany.displayName ?
                event.oldCompany.displayName : 'Não identificado') : 'Não possuia' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="event.type === 'company-changed'">
          <v-list-item-avatar>
            <v-icon color="primary">mdi-bank-transfer</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Empresa atual</v-list-item-subtitle>
            <v-list-item-title>
              {{ event.newCompany ? (event.newCompany.displayName ?
                event.newCompany.displayName : 'Não identificado') : 'Não possui' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="event.type === 'email-changed'">
          <v-list-item-avatar>
            <v-icon color="primary">mdi-email-sync-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>E-mail anterior</v-list-item-subtitle>
            <v-list-item-title>{{ event.oldEmail }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="event.type === 'email-changed'">
          <v-list-item-avatar>
            <v-icon color="primary">mdi-email-sync-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>E-mail atual</v-list-item-subtitle>
            <v-list-item-title>{{ event.newEmail }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import moment from 'moment-timezone';

  export default {
    name: 'EventDetails',
    data: () => ({
      show: false,
      event: null
    }),
    methods: {
      formatType(type) {
        switch (type) {
          case 'signin':
            return 'Login';
          case 'signup':
            return 'Registro';
          case 'planner-changed':
            return 'Troca de planejador';
          case 'status-enabled':
            return 'Usuário habilitado';
          case 'status-disabled':
            return 'Usuário desabilitado';
          case 'plan-changed':
            return 'Troca de plano';
          case 'password-changed':
            return 'Alteração de senha';
          case 'pass-reset-link':
            return 'Redefinição de senha requisitada';
          default:
            return '';
        }
      },
      formatDate(date) {
        if (date)
          return moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss');

        return '';
      },
    },
    watch: {
      show() {
        if (!this.show) {
          this.event = null;
        }
      }
    },
    created() {
      this.$bus.$on('showEventDetails', event => {
        if (event) {
          this.event = JSON.parse(JSON.stringify(event));
          this.show = true;
        }
      });
    }
  }
</script>

<style scoped>
  .v-dialog.v-dialog--active.v-bottom-sheet {
    border-radius: 5px 5px 0 0 !important;
  }

  .v-list-item__title, .v-list-item__subtitle {
    line-height: 1.5em !important;
    letter-spacing: .1em !important;
  }
</style>
