<template>
  <v-card color="transparent" flat>
    <v-card-text class="px-0" v-if="patrimonio">
      <v-container fluid grid-list-md class="px-0">
        <v-layout row wrap>
          <v-flex
            xs12 md4
            :key="item._id"
            v-for="item in patrimonio.items"
          >
            <CardItemPatrimonio :item="item" :actions="true"/>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-card-actions v-if="allowed">
      <v-spacer></v-spacer>

      <v-btn
        v-if="allowed"
        outlined
        rounded
        color="primary"
        class="px-10 text-capitalize"
        @click="showDialogFormPatrimonio"
      >
        Adicionar Patrimônio
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';
import CardItemPatrimonio from '@/components/Customer/Perfil/cards/CardItemPatrimonio';

export default {
  name: 'CardPatrimonio',
  components: { CardItemPatrimonio },
  data: () => ({
    numberToCurrency
  }),
  computed: {
    patrimonio() {
      return this.$store.getters.patrimonio;
    },
    allowed() {
      return this.$store.getters.allowed;
    }
  },
  methods: {
    showDialogFormPatrimonio() {
      this.$bus.$emit('showDialogFormPatrimonio')
    }
  }
}
</script>
