import httpClient from '../../plugins/httpClient';
import firebase from 'firebase/app';

const updateRewardRequest = (data) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'PUT';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/reward-requests/${data._id}`;
        return httpClient(url, { headers, method, data });
      })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(reject);
  });
};

export default updateRewardRequest;
