<template>
  <div>
    <v-app-bar height="50px">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer dark fixed temporary color="darkgrey" v-model="drawer">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img v-if="user.photoURL" :src="user.photoURL" />

            <v-avatar v-else color="grey lighten-2">
              <v-icon></v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.displayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ roleName() }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list dense nav>
        <v-list-item v-for="(item, i) in menuItems" :key="i" :to="item.path">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click.stop="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import logout from '@/utils/logout';

export default {
  name: 'Menu',
  data: () => ({
    drawer: false,
    photoUrl: '',
    menuItems: [
      {
        text: 'Ínicio',
        path: '/',
        icon: 'mdi-home'
      },
      {
        text: 'Perfil',
        path: '/profile',
        icon: 'mdi-account'
      }
    ]
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    roleName() {
      switch (this.user.role) {
        case 'admin':
          return 'Administrador';
        case 'company':
          return 'Empresa';
        default:
          return '';
      }
    },
    logout() {
      logout();
    }
  },
  created() {
    this.photoUrl = firebase.auth().currentUser.photoURL;
  },
  mounted() {

    if (this.user.role === 'admin') {
      this.menuItems.push({
        text: 'Indicações',
        path: '/admin/recommendations',
        icon: 'mdi-finance'
      });

      this.menuItems.push({
        text: 'Categorias',
        path: '/admin/categorias',
        icon: 'mdi-format-list-bulleted'
      });

      this.menuItems.push({
        text: 'Imposto de Renda',
        path: '/admin/imposto-renda',
        icon: 'mdi-file-document-box'
      });

      this.menuItems.push({
        text: 'EAD',
        path: '/admin/ead',
        icon: 'mdi-library-video'
      });
    } else if (this.user.role === 'company') {
      this.menuItems.push({
        text: 'Financeiro',
        path: '/financeiro',
        icon: 'mdi-finance'
      });

      this.menuItems.push({
        text: 'EAD',
        path: '/ead',
        icon: 'mdi-library-video'
      });
    } else if (this.user.role === 'planner') {
      this.menuItems.push({
        text: 'Financeiro',
        path: '/financeiro',
        icon: 'mdi-finance'
      });

      this.menuItems.push({
        text: 'EAD',
        path: '/ead',
        icon: 'mdi-library-video'
      });
    } else {
      this.menuItems.push({
        text: 'Fluxo de Caixa',
        path: '/fluxo-caixa',
        icon: 'mdi-clipboard-flow'
      });

      this.menuItems.push({
        text: 'Aposentadoria',
        path: '/aposentadoria',
        icon: 'mdi-clipboard-flow'
      });

      this.menuItems.push({
        text: 'Gestão de Riscos',
        path: '/gestao-riscos',
        icon: 'mdi-clipboard-flow'
      });

      this.menuItems.push({
        text: 'Planos de Ação',
        path: '/planos-acao',
        icon: 'mdi-clipboard-flow'
      });

      this.menuItems.push({
        text: 'Imposto de Renda',
        path: '/imposto-renda',
        icon: 'mdi-clipboard-flow'
      });

      this.menuItems.push({
        text: 'Meu Planejador',
        path: '/customer/planner',
        icon: 'mdi-account-badge-horizontal'
      });

      this.menuItems.push({
        text: 'Formas de Pagamento',
        path: '/pagamento',
        icon: 'mdi-credit-card'
      });
    }
  }
};
</script>

<style scoped></style>
