<template>
  <v-dialog
    persistent
    :fullscreen="isMobile"
    v-model="dialog"
    max-width="500px"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey3">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text">
          Editar {{ filho.nome }}
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <div class="input-container">
            <span class="input-label primary--text">Idade</span>
            <v-text-field
              dense
              readonly
              color="grey1"
              suffix="anos"
              :value="filho.idade"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-calendar
              </v-icon>
            </v-text-field>
          </div>

          <div class="input-container">
            <span class="input-label primary--text">Nível de escolaridade garantida</span>
            <v-select
              dense
              color="grey1"
              :items="niveisEscolaridade"
              v-model="filho.nivelEscolaridadeGarantida"
              :error-messages="nivelEscolaridadeGarantidaErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-school
              </v-icon>
            </v-select>
          </div>

          <div class="input-container">
            <span class="input-label primary--text">Custo educacional mensal</span>
            <v-text-field
              dense
              color="grey1"
              type="tel"
              v-money="money"
              v-model="filho.custoEducacional"
              id="input-insurance-son-custo-educacional"
              :error-messages="custoEducacionalErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-cash-multiple
              </v-icon>
            </v-text-field>
          </div>

          <div class="input-container">
            <span class="input-label primary--text">Prazo de cobertura</span>
            <v-text-field
              dense
              color="grey1"
              type="tel"
              suffix="anos"
              v-model.number="filho.prazoCobertura"
              :error-messages="prazoCoberturaErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-calendar
              </v-icon>
            </v-text-field>
          </div>

          <div class="input-container">
            <span class="input-label primary--text">Reajuste anual</span>
            <v-text-field
              dense
              color="grey1"
              type="tel"
              suffix="%"
              v-model.number="filho.reajusteAnual"
              :error-messages="reajusteAnualErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-percent
              </v-icon>
            </v-text-field>
          </div>

          <div class="input-container">
            <span class="input-label primary--text">Intercâmbio</span>
            <v-text-field
              dense
              color="grey1"
              type="tel"
              v-money="money"
              v-model="filho.intercambio"
              id="input-insurance-son-itercambio"
              :error-messages="intercambioErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-wallet-travel
              </v-icon>
            </v-text-field>
          </div>

          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              fab
              small
              type="submit"
              color="primary"
              :loading="loader"
              class="darkgrey--text"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money'
import { minValue, required } from 'vuelidate/lib/validators'
import removeMoneyMask from '@/utils/removeMoneyMask'
import saveLifeInsuranceSon from '@/api/lifeInsurance/saveLifeInsuranceSons'

export default {
  name: 'DialogFormInsuranceSon',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    isSpouse: false,
    maxAgeSonCoverage: 24,
    filho: {
      nome: '',
      custoEducacional: '',
      nivelEscolaridadeGarantida: '',
      prazoCobertura: '',
      intercambio: '',
      reajusteAnual: 0
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    niveisEscolaridade: [
      'Pré-escola',
      'Ensino fundamental',
      'Ensino médio',
      'Ensino superior',
      'Nenhum'
    ]
  }),
  validations: {
    filho: {
      custoEducacional: {
        isValid(v) {
          return !!v && removeMoneyMask(v) >= 0;
        }
      },
      nivelEscolaridadeGarantida: {
        isValid(v) {
          return !!v;
        }
      },
      prazoCobertura: {
        isValid(v) {
          return !!v && v >= 0;
        }
      },
      intercambio: {
        isValid(v) {
          return !!v && removeMoneyMask(v) >= 0;
        }
      },
      reajusteAnual: {
        required,
        min: minValue(0)
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    custoEducacionalErrors() {
      const errors = [];

      if (!this.$v.filho.custoEducacional.$anyDirty) {
        return errors;
      }

      !this.$v.filho.custoEducacional.isValid && errors.push('Preencha um valor válido');

      return errors;
    },
    nivelEscolaridadeGarantidaErrors() {
      const errors = [];

      if (!this.$v.filho.nivelEscolaridadeGarantida.$anyDirty) {
        return errors;
      }

      !this.$v.filho.nivelEscolaridadeGarantida.isValid && errors.push('Selecione um nível');

      return errors;
    },
    prazoCoberturaErrors() {
      const errors = [];

      if (!this.$v.filho.prazoCobertura.$anyDirty) {
        return errors;
      }

      !this.$v.filho.prazoCobertura.isValid && errors.push('Preencha um prazo válido');

      return errors;
    },
    intercambioErrors() {
      const errors = [];

      if (!this.$v.filho.intercambio.$anyDirty) {
        return errors;
      }

      !this.$v.filho.intercambio.isValid && errors.push('Preencha um valor válido');

      return errors;
    },
    reajusteAnualErrors() {
      const errors = [];

      if (!this.$v.filho.reajusteAnual.$anyDirty) {
        return errors;
      }

      (!this.$v.filho.reajusteAnual.required ||
        !this.$v.filho.reajusteAnual.min) && errors.push('Preencha um percentual válido');

      return errors;
    },
    isCustomer() {
      return this.$store.getters.user.role === 'customer';
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
    },
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.filho = {
        nome: '',
        custoEducacional: 0,
        nivelEscolaridadeGarantida: '',
        prazoCobertura: 0,
        intercambio: 0,
        reajusteAnual: 0
      }
      this.resetMoneyInputs();
      this.$v.$reset();
    },
    resetMoneyInputs() {
      let input = document.getElementById('input-insurance-son-custo-educacional');
      if (input) {
        input.value = parseFloat(this.filho.custoEducacional).toFixed(2);
        input.dispatchEvent(new Event('input'));
      }

      input = document.getElementById('input-insurance-son-intercambio');
      if (input) {
        input.value = parseFloat(this.filho.intercambio).toFixed(2);
        input.dispatchEvent(new Event('input'));
      }
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        let filho = JSON.parse(JSON.stringify(this.filho))



        filho.custoEducacional = removeMoneyMask(filho.custoEducacional)
        filho.intercambio = removeMoneyMask(filho.intercambio)

        saveLifeInsuranceSon(this.lifeInsurance._id, filho)
          .then(lifeInsurance => {
            this.$store.dispatch('saveLifeInsurance', lifeInsurance)
            this.close();
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível salvar estes dados. Tente novamente!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          });
      }
    }
  },
  watch: {
    'filho.nivelEscolaridadeGarantida'(newValue) {
      if (newValue === 'Pré-escola' && this.filho.idade < 5) {
        this.filho.prazoCobertura = 5 - this.filho.idade;
      } else if (newValue === 'Ensino fundamental' && this.filho.idade < 14) {
        this.filho.prazoCobertura = 14 - this.filho.idade;
      } else if (newValue === 'Ensino médio' && this.filho.idade < 17) {
        this.filho.prazoCobertura = 17 - this.filho.idade;
      } else if (newValue === 'Ensino superior' && this.filho.idade < 24) {
        this.filho.prazoCobertura = 24 - this.filho.idade;
      } else {
        this.filho.prazoCobertura = 0;
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormInsuranceSon', data => {
      if (data.filho) {
        this.filho = JSON.parse(JSON.stringify(data.filho));

        if (this.filho.custoEducacional && this.filho.custoEducacional.toFixed) {
          this.filho.custoEducacional = this.filho.custoEducacional.toFixed(2);
        }

        if (this.filho.intercambio && this.filho.intercambio.toFixed) {
          this.filho.intercambio = this.filho.intercambio.toFixed(2);
        }

        this.resetMoneyInputs()

        this.isSpouse = data.isSpouse;
        this.dialog = true;
      }
    });
  }
}
</script>
