<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    :persistent="loader"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey3">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="primary"
          :disabled="loader"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-3">
        <v-form @submit.prevent="submit" ref="ccform">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="input-label primary--text">Nome do titular</span>
                <v-text-field
                  color="grey1"
                  class="mt-0 pt-0"
                  data-iugu="full_name"
                  v-model="creditCard.full_name"
                  :error-messages="cardFullNameErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Número do cartão</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  data-iugu="number"
                  v-model="creditCard.number"
                  v-mask="'#### #### #### ####'"
                  :error-messages="cardNumberErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <span class="input-label primary--text">Válido até</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  v-mask="'##/##'"
                  class="mt-0 pt-0"
                  placeholder="MM/AA"
                  data-iugu="expiration"
                  v-model="creditCard.expiration"
                  :error-messages="cardExpirationErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <span class="input-label primary--text">CVV</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  v-mask="'###'"
                  class="mt-0 pt-0"
                  data-iugu="verification_value"
                  v-model="creditCard.verification_value"
                  :error-messages="cardCvvErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-checkbox
                  dense
                  color="primary"
                  label="Definir como método padrão de pagamentos"
                  v-model="creditCard.isDefault"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions class="px-0">
            <v-btn
              block
              large
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              class="darkgrey--text px-5"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import config from '@/config';
import stringCapitalize from '@/utils/stringCapitalize';
import createIuguCustomerPaymentMethod from '@/api/iugu/paymentMethods/createIuguCustomerPaymentMethod';

export default {
  name: 'DialogFormMeioPagamento',
  data: () => ({
    dialog: false,
    loader: false,
    creditCard: {
      number: '',
      full_name: '',
      expiration: '',
      verification_value: '',
      brand: null,
      isDefault: false
    },
    errorMessage:'Desculpe! Não foi possível salvar este meio de pagamento. Tente novamente mais tarde!'
  }),
  validations: {
    creditCard: {
      number: {
        isValid(v) {
          return !!v && Iugu.utils.validateCreditCardNumber(v);
        }
      },
      full_name: {
        isValid(v) {
          return !!v && v.length <= 150 && v.split(/\s/gm).filter(w => !!w).length > 1
        }
      },
      expiration: {
        isValid(v) {
          return !!v && Iugu.utils.validateExpirationString(v);
        }
      },
      verification_value: {
        isValid(v) {
          return !!v && Iugu.utils.validateCVV(v, this.creditCard.brand);
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isIOS() {
      return this.$store.getters.isIOS
    },
    cardNumberErrors() {
      const errors = [];

      if (!this.$v.creditCard.number.$anyError) {
        return errors;
      }

      !this.$v.creditCard.number.isValid && errors.push('Preencha um número válido')

      return errors;
    },
    cardFullNameErrors() {
      const errors = [];

      if (!this.$v.creditCard.full_name.$anyError) {
        return errors;
      }

      !this.$v.creditCard.full_name.isValid && errors.push('Preencha um nome válido')

      return errors;
    },
    cardExpirationErrors() {
      const errors = [];

      if (!this.$v.creditCard.expiration.$anyError) {
        return errors;
      }

      !this.$v.creditCard.expiration.isValid && errors.push('Preencha uma data válida')

      return errors;
    },
    cardCvvErrors() {
      const errors = [];

      if (!this.$v.creditCard.verification_value.$anyError) {
        return errors;
      }

      !this.$v.creditCard.verification_value.isValid && errors.push('Preencha um CVV válido')

      return errors;
    }
  },
  methods: {
    submit() {
      this.$v.$touch()

      if (!this.$v.$anyError) {
        this.loader = true
        const self = this

        Iugu.createPaymentToken(this.$refs.ccform.$el, async function (response) {
          try {
            if (response.errors || !response.id) {
              self.loader = false;
              self.$noty.error(self.errorMessage)
            } else {
              let paymentMethodData = {
                token: response.id,
                set_as_default: self.creditCard.isDefault,
                customer_id: self.user.iuguId,
                description: `Cartão de ${stringCapitalize(self.creditCard.full_name)}`
              }

              const paymentMethod = await createIuguCustomerPaymentMethod(self.user.iuguId, paymentMethodData)

              self.$store.dispatch('saveMeioPagamento', paymentMethod)
              self.dialog = false
              self.$noty.success('Meio de pagamento salvo com sucesso!')
            }
          } catch (err) {
            console.error(err)

            self.loader = false
            self.$noty.error(self.errorMessage)
          }
        })
      }
    },
    reset() {
      this.loader = false
      this.creditCard = {
        number: '',
        full_name: '',
        expiration: '',
        verification_value: '',
        brand: null
      }
      this.$v.$reset()
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.reset()
      }
    },
    'creditCard.number'() {
      this.creditCard.brand = Iugu.utils.getBrandByCreditCardNumber(this.creditCard.number);
    }
  },
  created() {
    this.$bus.$on('showDialogFormMeioPagamento', () => {
      this.dialog = true
    })
  },
  mounted() {
    Iugu.setAccountID(config.iuguAccountId);

    if (process.env.NODE_ENV !== 'production') {
      Iugu.setTestMode(true);
    }

    Iugu.setup();
  }
}
</script>

<style scoped>
@media only screen and (min-width: 600px) {
  .v-card__text.dialog-form-title span {
    font-size: 16px;
  }
}
</style>
