import firebase from 'firebase/app'

/**
 * Autenticação com google ou facebook
 * @param isMobile
 * @param type
 * @returns {Promise}
 */
const authWithSocial = (isMobile, type) => {
  let provider = type === 'google' ? new firebase.auth.GoogleAuthProvider() : new firebase.auth.FacebookAuthProvider();
  return isMobile ? firebase.auth().signInWithRedirect(provider) : firebase.auth().signInWithPopup(provider);
};

export default authWithSocial;
