<template>
  <v-dialog
    fullscreen
    scrollable
    persistent
    v-model="dialog"
  >
    <v-card color="grey3" class="no-radius" v-if="investment">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="primary"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text :class="isIOS ? 'ios-margin' : 'normal-margin'" class="px-1">
        <v-container class="grid-list-md">
          <v-layout row wrap>
            <v-flex xs12>
              <v-card>
                <div class="d-flex align-center justify-space-between pr-2">
                  <div>
                    <v-card-title class="investment-desc primary--text text-uppercase">
                      {{ investment.description }}
                    </v-card-title>
                    <v-card-subtitle class="investment-type">
                      {{ investment.category }} - {{ investment.type }}
                    </v-card-subtitle>
                  </div>

                  <v-menu
                    left
                    bottom
                    origin="right top"
                    :disabled="!allowed"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        color="white"
                        :disabled="!allowed"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list nav dense>
                      <v-list-item @click="showDialogFormInvestmentTransaction(null)">Aportar/Resgatar</v-list-item>
                      <v-list-item @click="showDialogFormRentabilidade(null)">Adicionar rentabilidade</v-list-item>
                      <v-list-item @click="showFormWalletInvestment">Editar</v-list-item>
                      <v-list-item @click="showDialogRemoveInvestment">Remover</v-list-item>
                    </v-list>
                  </v-menu>
                </div>


                <v-card-text>
                  <div class="total-alocado mb-1">
                    <span class="total-alocado-desc">Total alocado</span>
                    <span class="total-alocado-value white--text">
                      <span>R$</span> {{ calcTotal }}
                    </span>
                  </div>

                  <v-divider></v-divider>

                  <div class="total-alocado mt-2">
                    <span class="total-alocado-desc">Instituição</span>
                    <span class="total-alocado-value">
                      {{ investment.broker }}
                    </span>
                  </div>

                  <div class="total-alocado">
                    <span class="total-alocado-desc">Data inicial</span>
                    <span class="total-alocado-value">
                      {{ formatDate(investment.initialDate) }}
                    </span>
                  </div>

                  <div class="total-alocado">
                    <span class="total-alocado-desc">Total aplicado</span>
                    <span class="total-alocado-value">
                      {{ calcTotalApplied() }}
                    </span>
                  </div>

                  <div class="total-alocado">
                    <span class="total-alocado-desc">Total resgatado</span>
                    <span class="total-alocado-value">
                      {{ calcTotalRescued() }}
                    </span>
                  </div>

                  <div class="total-alocado">
                    <span class="total-alocado-desc">
                      Evolução
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon color="grey1" v-on="on">mdi-information-outline</v-icon>
                        </template>
                        <span>
                          Diferença em % do valor total alocado em relação ao aporte inicial.
                        </span>
                      </v-tooltip>
                    </span>
                    <span class="total-alocado-value">
                      {{ evolution() }}
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12>
              <v-card>
                <v-card-title class="investment-desc primary--text text-uppercase">
                  Evolução mensal
                </v-card-title>

                <v-card-text>
                  <apexchart
                    type="line"
                    :options="options"
                    :series="series"
                    width="100%"
                    height="350"
                  ></apexchart>

                  <div class="legends-container">
                    <div class="legend-item" v-for="(item, i) in legends" :key="i">
                      <v-avatar size="10" :color="item.color" class="mr-2"></v-avatar>
                      <span class="legend-item--title">{{ item.text }}</span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12>
              <v-card>
                <v-card-title class="investment-desc primary--text text-uppercase">
                  Movimentações
                </v-card-title>

                <v-card-text v-if="investment.transactions.length">
                  <div class="transactions-list">
                    <div
                      :key="item._id"
                      class="transaction"
                      v-for="item in orderedTransactions"
                    >
                      <div class="transaction-inside">
                        <v-avatar small color="grey2" class="mr-3">
                          <v-icon :color="item.type === 'in' ? 'success' : (item.type === 'var' ? 'info' : 'error')">
                            {{
                              item.type === 'in' ? 'mdi-arrow-up' : (item.type === 'var' ? 'mdi-chart-line-variant' : 'mdi-arrow-down')
                            }}
                          </v-icon>
                        </v-avatar>

                        <div class="transaction-details">
                          <span class="transaction-value white--text">
                            <span>R$</span> {{ currency(item.type === 'out' ? item.value * -1 : item.value) }}
                          </span>
                          <span class="transaction-date grey1--text">
                            Em {{ formatDate(item.date) }}
                          </span>
                        </div>
                      </div>

                      <v-menu
                        left
                        bottom
                        :disabled="!allowed"
                        origin="right top"
                        transition="scale-transition"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                            color="grey1"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list nav dense>
                          <v-list-item @click="showDialogFormInvestmentTransaction(item)">Editar</v-list-item>
                          <v-list-item @click="showDialogRemoveInvestmentTransaction(item._id)">Remover</v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </v-card-text>

                <v-card-text class="empty-container pt-5 pb-10" v-else>
                  <h3 class="empty-title text-center grey--text">Nenhum aporte ou resgate realizado</h3>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {orderBy} from 'lodash';
import moment from 'moment-timezone';
import VueApexCharts from 'vue-apexcharts';

import currency from '@/utils/currency';
import numberToCurrency from '@/utils/numberToCurrency';
import removeMoneyMask from '@/utils/removeMoneyMask';

const PERIOD_PATTERN = 'YYYY-MM';
const TIMEZONE = 'America/Sao_Paulo';

export default {
  name: 'DialogWalletInvestment',
  components: {apexchart: VueApexCharts},
  data: () => ({
    currency,
    dialog: false,
    investmentId: null,
    period: null,
    series: [
      {
        name: 'Aportes',
        type: 'column',
        data: []
      },
      {
        name: 'Resgates',
        type: 'column',
        data: []
      },
      {
        name: 'Rentabilidade',
        type: 'column',
        data: []
      },
      {
        name: 'Alocado',
        type: 'line',
        data: []
      }
    ],
    options: {
      labels: [],
      colors: ['#338A2D', '#E62D2A', '#008FFB', '#9E9E9E'],
      chart: {
        toolbar: {
          show: false
        },
        background: 'rgba(255,255,255, 0)'
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      grid: {
        show: false
      },
      stroke: {
        width: [0, 0, 0, 4],
        curve: 'smooth'
      },
      xaxis: {
        show: true,
        type: 'category',
        categories: [],
        labels: {
          rotateAlways: true,
          rotate: -45,
          style: {
            colors: '#9E9E9E'
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          align: 'right',
          style: {
            colors: '#9E9E9E'
          },
          formatter: v => numberToCurrency(v)
        }
      },
      tooltip: {
        theme: 'dark',
        y: {
          formatter: v => numberToCurrency(v)
        }
      },
      markers: {
        size: 5,
        colors: 'white',
        strokeWidth: 0
      }
    },
    legends: [
      {
        text: 'Aportes',
        color: '#338A2D'
      },
      {
        text: 'Resgates',
        color: '#E62D2A'
      },
      {
        text: 'Rentabilidade',
        color: '#008FFB'
      },
      {
        text: 'Alocado',
        color: '#9e9e9e'
      }
    ]
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    months() {
      return this.$store.getters.months;
    },
    orderedTransactions() {
      if (this.investment)
        return orderBy(this.investment.transactions, ['date', 'value']);
    },
    investment() {
      return this.$store.getters.investments.find(i => i._id === this.investmentId);
    },
    calcTotal() {
      if (this.investment) {
        let total = this.investment.initialValue;

        this.investment.transactions
          .filter(t => {
            return (this.$date(t.date).isSameOrAfter(
              this.investment.initialDate, 'day') && t.date.slice(0, 7) <= this.period)
          })
          .forEach(t => {
            total += ((t.type === 'in' || t.type === 'var') ? t.value : (t.value * -1));
          });

        return currency(total);
      }

      return currency(0);
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.investmentId = null;
      this.options = {
        labels: [],
        colors: ['#338A2D', '#E62D2A', '#008FFB', '#9E9E9E'],
        chart: {
          toolbar: {
            show: false
          },
          background: 'rgba(255,255,255, 0)'
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          show: false
        },
        stroke: {
          width: [0, 0, 0, 4],
          curve: 'smooth'
        },
        xaxis: {
          show: true,
          type: 'category',
          categories: [],
          labels: {
            rotateAlways: true,
            rotate: -45,
            style: {
              colors: '#9E9E9E'
            }
          }
        },
        yaxis: {
          labels: {
            show: true,
            align: 'right',
            style: {
              colors: '#9E9E9E'
            },
            formatter: v => numberToCurrency(v)
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: v => numberToCurrency(v)
          }
        },
        markers: {
          size: 5,
          colors: 'white',
          strokeWidth: 0
        }
      }

      this.series = [
        {
          name: 'Aportes',
          type: 'column',
          data: []
        },
        {
          name: 'Resgates',
          type: 'column',
          data: []
        },
        {
          name: 'Rentabilidade',
          type: 'column',
          data: []
        },
        {
          name: 'Alocado',
          type: 'line',
          data: []
        }
      ];
    },
    buildChart() {
      let series = [
        {
          name: 'Aportes',
          type: 'column',
          data: []
        },
        {
          name: 'Resgates',
          type: 'column',
          data: []
        },
        {
          name: 'Rentabilidade',
          type: 'column',
          data: []
        },
        {
          name: 'Alocado',
          type: 'line',
          data: []
        }
      ];
      let options = JSON.parse(JSON.stringify(this.options));

      options.labels = [];

      let startDate = moment(moment().subtract(12, 'months')).tz(TIMEZONE);
      let [startYear, startMonth] = startDate.format(PERIOD_PATTERN).split('-');
      let [initialYear, initialMonth] = this.investment.initialDate.split('-');

      for (let i = 0; i <= 12; i++) {
        series[0].data[i] = 0;
        series[1].data[i] = 0;
        series[2].data[i] = 0;
        series[3].data[i] = series[3].data[i - 1] !== undefined ? series[3].data[i - 1] : 0;

        if (i === 0 && (startYear > initialYear || (startYear == initialYear && startMonth > initialMonth))) {
          series[3].data[i] = this.investment.initialValue;

          let transactions = this.investment.transactions.filter(t => {
            let [tYear, tMonth] = t.date.split('-');
            return tYear > initialYear || (tYear == initialYear && tMonth > initialMonth);
          });

          for (let y = 0; y < transactions.length; y++) {
            let t = transactions[y];
            series[3].data[i] += (t.type === 'in' || t.type === 'var') ? t.value : (t.value * -1);
          }
        }

        if (startYear == initialYear && startMonth == initialMonth) {
          series[3].data[i] += this.investment.initialValue;
        }

        let transactions = this.investment.transactions.filter(t => {
          let [tYear, tMonth] = t.date.split('-');
          return tYear == startYear && tMonth == startMonth;
        });

        for (let y = 0; y < transactions.length; y++) {
          let t = transactions[y];

          if (t.type === 'in') {
            series[0].data[i] += t.value;
            series[3].data[i] += t.value;
          } else if (t.type === 'out') {
            series[1].data[i] -= t.value;
            series[3].data[i] -= t.value;
          } else if (t.type === 'var') {
            series[2].data[i] += t.value;
            series[3].data[i] += t.value;
          }
        }

        let month = this.months.find(m => m.numero == startMonth);
        options.labels.push(`${month.sigla}/${startYear}`);

        startMonth = parseInt(startMonth) + 1;
        if (startMonth > 12) {
          startMonth = 1;
          startYear = parseInt(startYear) + 1;
        }
      }

      options.xaxis.categories = options.labels;
      options.yaxis = {
        labels: {
          show: true,
          align: 'right',
          style: {
            colors: '#9E9E9E'
          },
          formatter: v => numberToCurrency(v)
        }
      };

      this.series = series;
      this.options = options;
    },
    formatDate(date) {
      if (date) {
        return this.$date(date).tz(TIMEZONE).format('DD/MM/YYYY');
      }

      return '';
    },
    calcTotalApplied() {
      let total = 0;

      if (this.investment) {
        total += this.investment.initialValue;

        this.investment.transactions.filter(t => t.type === 'in').forEach(t => {
          total += t.value;
        });
      }

      return numberToCurrency(total);
    },
    calcTotalRescued() {
      let total = 0;

      if (this.investment) {
        this.investment.transactions.filter(t => t.type === 'out').forEach(t => {
          total += t.value;
        });
      }

      return numberToCurrency(total);
    },
    evolution() {
      let variation = 0;

      if (this.investment) {
        let total = removeMoneyMask('R$ ' + this.calcTotal);
        variation = ((total / this.investment.initialValue) * 100) - 100;
      }

      return variation.toFixed(2) + '%';
    },
    showDialogFormInvestmentTransaction(transaction) {
      if (this.allowed) {
        let event;

        if (transaction && transaction.type === 'var') {
          event = 'showDialogFormRentabilidade';
        } else {
          event = 'showDialogFormInvestmentTransaction';
        }

        this.$bus.$emit(event, {
          transaction,
          investmentId: this.investment._id,
          initialDate: this.investment.initialDate
        });
      }
    },
    showDialogRemoveInvestmentTransaction(transactionId) {
      if (this.allowed) {
        this.$bus.$emit('showDialogRemoveInvestmentTransaction', {
          transactionId,
          investmentId: this.investment._id
        });
      }
    },
    showFormWalletInvestment() {
      if (this.allowed) {
        this.$bus.$emit('showDialogFormWalletInvestment', {
          wallet: this.investment.wallet,
          investment: this.investment
        });
      }
    },
    showDialogRemoveInvestment() {
      if (this.allowed) {
        this.$bus.$emit('showDialogRemoveInvestment', {
          _id: this.investment._id,
          description: this.investment.description
        });
        this.close();
      }
    },
    showDialogFormRentabilidade() {
      if (this.allowed)
        this.$bus.$emit('showDialogFormRentabilidade', {investmentId: this.investmentId});
    }
  },
  watch: {
    investment: {
      deep: true,
      handler() {
        if (this.investment) {
          this.buildChart();
        }
      }
    }
  },
  created() {
    this.$bus.$on('showDialogWalletInvestment', investmentId => {
      if (investmentId) {
        this.investmentId = investmentId;
        this.period = this.$date().format('YYYY-MM')
        this.dialog = true;
      }
    });
  }
};
</script>

<style scoped>
.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 960px;
}

.dialog-form-title {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v-card__text.ios-margin {
  margin-top: 86px !important;
}

.v-card__text.normal-margin {
  margin-top: 62px !important;
}

.v-card__title.investment-desc {
  font-size: 15px !important;
  font-weight: bold !important;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.v-card__subtitle.investment-type {
  font-size: 10px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.total-alocado {
  display: flex;
  justify-content: space-between;
}

.total-alocado-desc, .total-alocado-value {
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.total-alocado-value {
  font-weight: bold;
}

.total-alocado-value span {
  font-size: 9px;
}

.legends-container {
  display: flex;
}

.legend-item {
  margin-right: 20px;
}

.legend-item--title {
  font-size: 10px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.transactions-list {
  display: flex;
  flex-direction: column;
}

.transaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #919191;
}

.transaction:last-child {
  border-bottom: none;
}

.transaction-inside {
  display: flex;
  align-items: center;
}

.transaction-details {
  display: flex;
  flex-direction: column;
}

.transaction-value {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.transaction-value span {
  font-size: 10px;
}

.transaction-date {
  font-size: 10px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 600px) {
  .v-card__title.investment-desc {
    font-size: 18px !important;
  }

  .v-card__subtitle.investment-type {
    font-size: 12px
  }

  .total-alocado-desc, .total-alocado-value {
    font-size: 15px;
  }

  .total-alocado-value span {
    font-size: 12px;
  }

  .transaction-value {
    font-size: 18px;
  }

  .transaction-value span {
    font-size: 12px;
  }

  .transaction-date {
    font-size: 12px;
  }
}

@media only screen and (min-width: 960px) {
  .v-card__title.investment-desc {
    font-size: 20px !important;
  }

  .v-card__subtitle.investment-type {
    font-size: 15px
  }

  .total-alocado-desc, .total-alocado-value {
    font-size: 18px;
  }

  .total-alocado-value span {
    font-size: 12px;
  }

  .transaction-value {
    font-size: 20px;
  }

  .transaction-date {
    font-size: 15px;
  }
}
</style>
