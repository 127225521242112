<template>
  <div class="chart-card">
    <h1 class="chart-card-title primary--text mb-2">Saldo Acumulado Mensal</h1>

    <apexchart
      type="area"
      :options="options"
      :series="series"
      width="100%"
      height="350"
    ></apexchart>

    <div class="chart-card-legends mt-2">
      <v-layout row wrap>
        <v-flex xs6 v-for="item in legends" :key="item.text">
          <div class="chart-card-legends-item">
            <v-avatar size="10" :color="item.color" class="mr-2"></v-avatar>
            <span class="chart-card-legends-item--title">{{ item.text }}</span>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';
  import numberToCurrency from "../../../utils/numberToCurrency";

  export default {
    name: 'CardSaldoMensal',
    props: ['transactions', 'labels'],
    components: { apexchart: VueApexCharts },
    data: () => ({
      options: {
        colors: ['#9e9e9e'],
        labels: ['Saldo'],
        chart: {
          toolbar: { show: false },
          background: 'rgba(255,255,255, 0)'
        },
        dataLabels: { enabled: false },
        legend: { show: false },
        grid: { show: false },
        stroke: {
          width: [4],
          curve: 'smooth'
        },
        xaxis: {
          show: true,
          type: 'category',
          categories: [],
          labels: {
            rotateAlways: true,
            rotate: -45,
            style: {
              colors: '#9E9E9E'
            }
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: v => numberToCurrency(v)
          }
        },
        markers: {
          size: 5,
          colors: 'white',
          strokeWidth: 0
        },
      },
      series: [
        {
          name: 'Saldo',
          data: []
        }
      ],
      legends: [
        {
          text: 'Saldo',
          color: '#9e9e9e'
        }
      ]
    }),
    methods: {
      buildChart() {
        let options = JSON.parse(JSON.stringify(this.options));

        options.xaxis.categories = this.labels;
        options.yaxis = {
          labels: {
            show: false
          }
        }

        this.series[0].data = this.transactions;
        this.options = options;
      }
    },
    watch: {
      transactions: {
        deep: true,
        handler() {
          this.buildChart();
        }
      }
    },
    mounted() {
      this.buildChart();
    }
  }
</script>
