<template>
  <v-dialog
    fullscreen
    persistent
    v-model="dialog"
  >
    <v-card color="grey2" class="no-radius" v-if="planner">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button mr-5"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="white--text">Integração Calendly</span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-container grid-list-md class="px-0">
          <v-layout row wrap>
            <v-flex xs12>
              <span class="input-label primary--text">E-mail cadastrado no Calendly</span>
              <v-text-field
                dense
                type="email"
                color="grey1"
                v-model="calendlyEmail"
                :loading="integrationLoader"
                :error-messages="calendlyEmailErrors"
              ></v-text-field>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="availableUser">
                <span class="input-label primary--text">Token de Integração</span>
                <br>
                <span
                  @click="showTokenTutorialDialog"
                  class="input-label input-label-link grey1--text"
                >
                  Clique aqui e veja como conseguir um
                </span>

                <v-text-field
                  dense
                  color="grey1"
                  persistent-hint
                  v-model="calendlyToken"
                  hint="Cole seu token aqui"
                  :loading="eventsLoader"
                  :error-messages="calendlyTokenErrors"
                ></v-text-field>
              </v-flex>
            </v-slide-x-transition>

            <v-slide-x-transition>
              <v-flex xs12 class="mt-5" v-if="availableIntegration && events.length">
                <h4 class="primary--text mb-3">Selecione quais eventos deseja integrar</h4>

                <v-card
                  class="mb-2"
                  @click="event.selected = !event.selected"
                  color="darkgrey"
                  v-for="event in events"
                  :key="event.id"
                >
                  <v-progress-linear
                    :background-color="event.attributes.color"
                    :color="event.attributes.color"
                    value="0"
                  ></v-progress-linear>

                  <div class="event-card-title">
                    <v-icon color="primary" class="mr-2">
                      {{ event.selected ? 'mdi-checkbox-marked-circle-outline' : 'mdi-checkbox-blank-circle-outline' }}
                    </v-icon>
                    <div class="d-flex flex-column">
                      <span>{{ event.attributes.name }}</span>
                      <small class="grey1--text">{{ event.attributes.duration }} min</small>
                    </div>
                  </div>
                </v-card>
              </v-flex>
            </v-slide-x-transition>

            <v-slide-x-transition>
              <v-flex xs12 v-if="hasSelected">
                <v-card-actions class="px-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    large
                    block
                    rounded
                    @click="submit"
                    color="primary"
                    :loading="loader"
                    class="darkgrey--text"
                  >
                    Próximo
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
            </v-slide-x-transition>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { email } from 'vuelidate/lib/validators'
import checkCalendlyIntegration from '@/api/calendly/checkCalendlyIntegration';
import getPlannerCalendlyEvents from '@/api/calendly/getPlannerCalendlyEvents';
import createCalendlyWebhook from '@/api/calendly/createCalendlyWebhook';
import saveUser from '@/api/users/saveUser';
import getCalendlyWebhooks from '@/api/calendly/getCalendlyWebhooks';
import deleteCalendlyWebhooks from '@/api/calendly/deleteCalendlyWebhook';

export default {
  name: 'DialogCalendlyPlanner',
  data: () => ({
    dialog: false,
    loader: false,
    integrationLoader: false,
    eventsLoader: false,
    planner: null,
    calendlyToken: '',
    calendlyEmail: '',
    events: [],
    availableUser: false,
    availableIntegration: false,
    isEdit: false
  }),
  validations: {
    calendlyToken: {
      isValid(v) {
        return !!v && v.trim().length === 32
      }
    },
    calendlyEmail: {
      email,
      required(v) {
        return !!v
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    calendlyTokenErrors() {
      const errors = []

      if (!this.$v.calendlyToken.$dirty) {
        return errors
      }

      !this.$v.calendlyToken.isValid && errors.push('Insira um token válido')

      return errors
    },
    calendlyEmailErrors() {
      const errors = []

      if (!this.$v.calendlyEmail.$dirty) {
        return errors
      }

      (!this.$v.calendlyEmail.required ||
        !this.$v.calendlyEmail.email) && errors.push('Insira um e-mail válido')

      return errors
    },
    hasSelected() {
      return this.events.length && this.events.filter(e => e.selected).length
    }
  },
  methods: {
    close() {
      this.isEdit = false
      this.dialog = false
      this.loader = false
      this.planner = null
      this.calendlyEmail = ''
      this.calendlyToken = ''
      this.events = []
      this.integrationLoader = false
      this.availableUser = false
      this.availableIntegration = false
      this.eventsLoader = false
      this.$v.$reset()
    },
    getEvents() {
      this.$v.calendlyToken.$touch()

      if (this.$v.calendlyToken.isValid) {
        this.eventsLoader = true

        getPlannerCalendlyEvents(this.calendlyToken.trim())
          .then(events => {
            events.forEach(event => {
              event.selected = event.attributes.active
            })

            this.events = events
            this.availableIntegration = true
            this.eventsLoader = false
          })
          .catch(err => {
            this.eventsLoader = false
            this.availableIntegration = true

            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true
          let planner = JSON.parse(JSON.stringify(this.planner))
          const token = this.calendlyToken.trim().toUpperCase()
          const hooks = await getCalendlyWebhooks(token)

          if (hooks.length) {
            await deleteCalendlyWebhooks(token, hooks[0].id)
          }

          const resp = await createCalendlyWebhook(this.calendlyToken)

          planner.calendly = {
            email: this.calendlyEmail.trim().toLowerCase(),
            tokenIntegracao: this.calendlyToken.trim(),
            webhookId: resp.id,
            eventTypes: this.events.filter(e => e.selected)
          }

          planner = await saveUser(planner)

          this.$bus.$emit('managerGetUsers');
          this.showDialogSetupCalendly(planner)
          this.close();
        }
      } catch(err) {
        this.loader = false
        this.$noty.error('Falha ao integrar planejador. Tente novamente mais tarde!')
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      }
    },
    showTokenTutorialDialog() {},
    checkIntegration() {
      this.$v.calendlyEmail.$touch()

      if (this.$v.calendlyEmail.email) {
        this.integrationLoader = true

        checkCalendlyIntegration(this.calendlyEmail.trim().toLowerCase(), this.planner._id)
          .then(resp => {
            this.availableUser = !resp.exists
            this.integrationLoader = false
          })
          .catch(err => {
            this.integrationLoader = false
            this.availableUser = false

            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    },
    showDialogSetupCalendly(planner) {
      this.$bus.$emit('showDialogSetupCalendly', planner)
    }
  },
  watch: {
    calendlyEmail() {
      if (!this.isEdit && this.dialog) {
        this.checkIntegration()
      } else {
        this.isEdit = false
      }
    },
    calendlyToken() {
      if (!this.isEdit && this.dialog) {
        this.getEvents()
      } else {
        this.isEdit = false
      }
    }
  },
  created() {
    this.$bus.$on('showDialogCalendlyPlanner', planner => {
      if (planner) {
        if (planner.calendly && planner.calendly.webhookId) {
          this.isEdit = true
        }

        this.planner = planner

        if (this.planner.calendly) {
          if (this.planner.calendly.email) {
            this.calendlyEmail = this.planner.calendly.email
            this.availableUser = true
          }

          if (this.planner.calendly.tokenIntegracao) {
            this.calendlyToken = this.planner.calendly.tokenIntegracao
            this.availableIntegration = true
          }
        }

        this.dialog = true
      }
    })
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
}

.event-card-title {
  padding: 16px;
  display: flex;
  align-items: center;
}

.event-card-title span {
  font-size: 12px;
  letter-spacing: .1em;
  line-height: 1.5em;
}

.event-card-title small {
  font-size: 10px;
  letter-spacing: .1em;
  line-height: 1.5em;
}

h4 {
  font-size: 12px;
  letter-spacing: .1em;
}

@media only screen and (min-width: 600px) {
  h4 {
    font-size: 15px;
  }

  .event-card-title span {
    font-size: 15px;
  }

  .event-card-title small {
    font-size: 12px;
  }
}
</style>
