<template>
  <v-main>
    <v-container grid-list-md>
      <OverlaySync
        :overlay="loader"
        text="Buscando dados..."
      />

      <v-layout row wrap v-if="user">
        <v-flex
          xs12
          class="my-10 safe-area-top d-flex flex-column"
        >
          <span class="page-title primary--text text-uppercase">
            Bem vind@, {{ user.displayName.split(/\s+/gm)[0] }}!
          </span>

          <span v-if="user.atendimento && user.atendimento.billingUrl && user.isUplanner">
             <span class="input-label">
               Seu link de vendas é: <span class="primary--text">{{ user.atendimento.billingUrl }}</span>
             </span>
          </span>
        </v-flex>

        <v-flex xs4 sm4 md4 lg4>
          <CardTotalUsers/>
        </v-flex>

        <v-flex xs4 sm4 md4 lg4>
          <CardTotalUsersEnabled/>
        </v-flex>

        <v-flex xs4 sm4 md4 lg4>
          <CardTotalNewUsers/>
        </v-flex>

        <v-flex xs12>
          <CardUsersTimeSeries/>
        </v-flex>

        <v-flex xs12>
          <CardTableUsers/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
  import CardTotalUsers from '../../components/Dashboard/cards/CardTotalUsers'
  import CardTotalUsersEnabled from "../../components/Dashboard/cards/CardTotalUsersEnabled";
  import CardTotalNewUsers from '../../components/Dashboard/cards/CardTotalNewUsers'
  import CardUsersTimeSeries from "../../components/Dashboard/cards/CardUsersTimeSeries";
  import CardTableUsers from "../../components/Manager/dashboard/CardTableUsers";
  import OverlaySync from '@/components/Global/OverlaySync';
  import getUserById from '@/api/users/getUserById';
  // import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';
  import CardLockedCustomers from '@/components/Planner/cards/CardLockedCustomers';

  export default {
    name: 'Planner',
    // metaInfo() {
    //   return {
    //     title: "Planejador",
    //     changed(metaInfo) {
    //       sendMetaInfoData(metaInfo)
    //     }
    //   };
    // },
    components: {
      CardLockedCustomers,
      OverlaySync,
      CardTableUsers, CardUsersTimeSeries, CardTotalNewUsers, CardTotalUsersEnabled, CardTotalUsers},
    data: () => ({
      loader: false
    }),
    computed: {
      isPlanner() {
        return this.$store.getters.user && this.$store.getters.user.role === 'planner';
      },
      user() {
        return this.isPlanner ? this.$store.getters.user : this.$store.getters.planner;
      },
      isIOS() {
        return this.$store.getters.isIOS;
      },
      loggedUser() {
        return this.$store.getters.user
      }
    },
    methods: {
      getData() {
        this.loader = true;
        let id = this.$route.params.id;

        this.$store.dispatch(`setPlanner`, null);

        if (id) {
          getUserById(id)
            .then(user => {
              this.$store.dispatch(`setPlanner`, user);
              this.loader = false;
            })
            .catch(err => {
              this.$router.push(`/`);
              if (process.env.NODE_ENV !== `production`) {
                console.error(err);
              }
            });
        } else {
          this.$router.push(`/`);
        }
      }
    },
    beforeCreate() {
      this.$vuetify.theme.dark = true;
    },
    created() {
      if (!this.isPlanner) {
        this.getData();
      }
    }
  }
</script>

<style scoped>
  .v-main {
    background: #131313 !important;
  }

  .page-title {
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .1em;
  }
</style>
