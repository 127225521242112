<template>
  <v-dialog
    fullscreen
    v-model="dialog"
    :persistent="subscriptionLoader || loader"
  >
    <v-card class="no-radius" color="grey3">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          @click.stop="closeDialog"
          class="back-button"
          :disabled="subscriptionLoader || loader"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4">
          Minha Assinatura
        </span>
      </v-card-title>

      <v-card-text class="px-1">
        <v-fade-transition v-if="subscriptionLoader">
          <div
            class="subscription-container"
            :style="`height: ${screenHeight}px;`"
          >
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </v-fade-transition>

        <v-fade-transition v-if="subscription">
          <v-container class="pt-2" grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <div class="d-flex align-center">
                  <v-avatar color="transparent">
                    <v-icon color="primary">
                      {{ status ? 'mdi-calendar-check-outline' : 'mdi-calendar-remove-outline' }}
                    </v-icon>
                  </v-avatar>

                  <div class="d-flex flex-column align-start">
                    <span class="subscription-item__info-value white--text">
                      {{ status ? 'Ativa' : 'Suspensa' }}
                    </span>
                    <span class="subscription-item__info-label primary--text">Status</span>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm6>
                <div class="d-flex align-center">
                  <v-avatar color="transparent">
                    <v-icon color="primary">mdi-calendar-blank-outline</v-icon>
                  </v-avatar>

                  <div class="d-flex flex-column align-start">
                    <span class="subscription-item__info-value white--text">{{ createdAt }}</span>
                    <span class="subscription-item__info-label primary--text">Criada em</span>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm6>
                <div class="d-flex align-center">
                  <v-avatar color="transparent">
                    <v-icon color="primary">mdi-cash</v-icon>
                  </v-avatar>

                  <div class="d-flex flex-column align-start">
                    <span class="subscription-item__info-value white--text">{{ price }}</span>
                    <span class="subscription-item__info-label primary--text">Preço</span>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm6>
                <div class="d-flex align-center">
                  <v-avatar color="transparent">
                    <v-icon color="primary">mdi-cash-usd-outline</v-icon>
                  </v-avatar>

                  <div class="d-flex flex-column align-start">
                    <span class="subscription-item__info-value white--text">{{ paymentMethod }}</span>
                    <span class="subscription-item__info-label primary--text">Meio de pagamento</span>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12>
                <div class="d-flex align-center">
                  <v-avatar color="transparent">
                    <v-icon color="primary">mdi-text-box-check-outline</v-icon>
                  </v-avatar>

                  <div class="d-flex flex-column align-start">
                    <span class="subscription-item__info-value white--text">{{ subscription.plan_name }}</span>
                    <span class="subscription-item__info-label primary--text">Plano</span>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-fade-transition>
      </v-card-text>

      <v-card-actions class="safe-area-bottom" v-if="showCancel">
        <v-spacer></v-spacer>
        <v-btn
          dark
          x-lage
          rounded
          class="px-5"
          color="error"
          @click="showDialogCancelIuguSubscription"
        >
          Cancelar Assinatura
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getIuguSubscription from '@/api/iugu/subscriptions/getIuguSubscription';
import suspendIuguSubscription from '@/api/iugu/subscriptions/suspendIuguSubscription';
import numberToCurrency from '@/utils/numberToCurrency';
import config from "@/config";

export default {
  name: 'DialogCustomerSubscription',
  data: () => ({
    dialog: false,
    loader: false,
    subscription: null,
    subscriptionLoader: false,
    screenHeight: 568
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    user() {
      return this.$store.getters.user
    },
    status() {
      return this.subscription && this.subscription.active
    },
    createdAt() {
      if (this.subscription) {
        return this.$date(this.subscription.created_at).format('DD/MM/YYYY')
      }
      return ''
    },
    price() {
      let price = 0

      if (this.subscription && this.subscription.price_cents > 0) {
        price = (this.subscription.price_cents / 100)
      }

      return numberToCurrency(price)
    },
    paymentMethod() {
      if (this.subscription) {
        switch (this.subscription.payable_with) {
          case 'bank_slip':
            return 'Boleto'
          case 'pix':
            return 'Pix'
          default:
            return 'Cartão de crédito'
        }
      }

      return ''
    },
    showCancel() {
      if (this.subscription) {
        return (
          this.$date().isBefore(config.subscriptionReferenteDate, 'day') ||
          this.$date().diff(this.subscription.created_at, 'day') <= 7
        )
      }

      return false
    }
  },
  methods: {
    openDialog() {
      if (this.user && this.user.role === 'customer' && this.user.iuguSubscription) {
        this.getSubscription()
        this.screenHeight = window.innerHeight - 54;
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.subscription = null
      this.subscriptionLoader = false
    },
    formatInvoiceDate(invoiceDate) {
      if (invoiceDate) {
        return invoiceDate.split('-').reverse().join('/')
      }

      return ''
    },
    formatInvoiceStatus(invoiceStatus) {
      switch (invoiceStatus) {
        case 'pending':
          return 'Pendente'
        case 'canceled':
          return 'Cancelada'
        case 'paid':
          return 'Paga'
        default:
          return ''
      }
    },
    showDialogCancelIuguSubscription() {
      if (this.showCancel) {
        this.$bus.$emit('showDialogCancelIuguSubscription', this.subscription.id)
        this.closeDialog()
      }
    },
    async getSubscription() {
      try {
        if (this.user && this.user.role === 'customer' && this.user.iuguSubscription) {
          this.subscriptionLoader = true
          this.subscription = await getIuguSubscription(this.user.iuguSubscription)
          this.subscriptionLoader = false
        }
      } catch (err) {
        this.$noty.error('Desculpe! Não foi possível encontrar dados de sua assinatura. Tente novamente mais tarde!')

        if (process.env.NODE_ENV !== 'development') {
          console.error(err)
        }

        this.closeDialog()
      }
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeDialog()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogCustomerSubscription', this.openDialog)
  }
}
</script>

<style scoped>
.subscription-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subscription-item__info-value {
  font-size: 12px;
  line-height: 1.2em;
  letter-spacing: .1em;
}

.subscription-item__info-label {
  font-size: 9px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 600px;
  }

  .subscription-item__info-value {
    font-size: 15px;
  }

  .subscription-item__info-label {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .subscription-section-title {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .1em;
  }
}
</style>


