import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar todos os vídeos cadastrados no EAD
 * @param {number} page
 * @param {number} limit
 * @param {string} type
 * @param {string} category
 * @param {boolean} active
 * @param {string} search
 */
const getVideos = (
  page = 1,
  limit = 1,
  type = '',
  category = '',
  active = '',
  search = ''
) => {
  return new Promise((resolve, reject) => {
    let user = store.getters.user;

    if (user.role === 'admin') {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          let headers = {
            role: user.role,
            Authorization: `Bearer ${idToken}`
          };

          let url = `/videos?page=${page}&limit=${limit}&type=${type}&category=${category}&active=${active}&search=${search}`;

          return httpClient(url, { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });
};

export default getVideos;
