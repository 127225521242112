import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/app';
import logout from '@/utils/logout';

import routes from './routes';
import isMobileTablet from '../utils/isMobileTablet';
import store from '../store/index'

Vue.use(VueRouter);

const router = new VueRouter({
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let isMobile = isMobileTablet();
  let user = firebase.auth().currentUser;
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  store.dispatch('setIsMobile', isMobile);

  if (!user && requiresAuth) {
    logout()
    return isMobile ? next('/auth') : next('/login');
  } else if (user && (to.path === '/auth' || to.path === '/login' || to.path === '/signup')) {
    return next('/');
  }

  return next();
});

export default router;
