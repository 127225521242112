import countryPhoneMasks from '@/utils/countryPhoneMasks'

const getSelectedCountry = codigo => {
  if (codigo) {
    return countryPhoneMasks.find(cpm => cpm.codigo === codigo)
  }

  return null
}

export default getSelectedCountry
