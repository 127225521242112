<template>
  <v-dialog
    fullscreen
    scrollable
    v-model="dialog"
  >
    <v-card color="darkgrey" class="no-radius">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4" v-if="plannerId && customerId">
          Anotações - {{ customerName }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container v-if="loader" fluid fill-height>
          <v-layout align-center justify-center>
            <div class="loader-container">
              <v-progress-circular
                size="50"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <span class="notes-loader-desc primary--text mt-3">Buscando anotações...</span>
            </div>
          </v-layout>
        </v-container>

        <v-slide-x-transition>
          <v-container fluid fill-height v-if="!loader && !notes.length">
            <v-layout align-center justify-center>
              <span class="empty-title grey1--text">Nenhuma anotação criada</span>
            </v-layout>
          </v-container>
        </v-slide-x-transition>

        <v-slide-x-transition>

          <v-container fluid grid-list-md v-if="!loader && notes.length">

            <v-layout row wrap>
              <v-flex xs12 sm6 md4 lg3 v-for="note in notes" :key="note._id">
                <CardNote :note="note"/>
              </v-flex>
            </v-layout>

            <div class="text-center mt-5">
              <v-pagination
                circle
                color="primary"
                v-model="pagination.page"
                :length="pagination.totalPages"
              ></v-pagination>
            </div>
          </v-container>
        </v-slide-x-transition>
      </v-card-text>

      <v-btn
        fab
        dark
        right
        small
        bottom
        absolute
        color="primary"
        style="margin-bottom: 40px"v-if="user && user.role === 'planner'"
        @click.stop="showDialogFormNote(null)"
      >
        <v-icon color="darkgrey">mdi-plus</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
  import getCustomerNotes from '../../../../api/notes/getCustomerNotes';
  import CardNote from '../cards/CardNote';

  export default {
    name: 'DialogNotes',
    components: { CardNote },
    data: () => ({
      dialog: false,
      loader: false,
      plannerId: null,
      customerId: null,
      notes: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        totalPages: 0
      },
      search: '',
      customerName: null
    }),
    computed: {
      user() {
        return this.$store.getters.user;
      },
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      customer() {
        return this.$store.getters.customer;
      }
    },
    methods: {
      getNotes() {
        if (this.plannerId && this.customerId) {
          this.loader = true;

          getCustomerNotes(this.plannerId, this.customerId, this.pagination.page, this.search)
            .then(data => {
              this.pagination.totalItems = data.count;
              this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage);
              this.notes = data.notes;
              this.loader = false;
            })
            .catch(err => {
              this.close();
              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      },
      close() {
        this.dialog = false;
        this.loader = false;
        this.notes = [];
        this.plannerId = null;
        this.customerId = null;
        this.search = '';
        this.customerName = '';
        this.pagination = {
          page: 1,
          itemsPerPage: 10,
          totalItems: 0,
          totalPages: 0
        };
      },
      showDialogFormNote(note) {
        this.$bus.$emit('showDialogFormNote', note);
      }
    },
    created() {
      this.$bus.$on('showDialogNotes', data => {
        if (!data) {
          data = {}
        }

        if (this.user && this.user.role === 'customer') {
          data.customerId = this.user._id;
          data.plannerId = this.user.planner ? this.user.planner._id : '';
          this.customerName = this.user.displayName;
        } else {
          this.customerName = this.customer.displayName;
        }

        if (data.plannerId && data.customerId) {
          this.plannerId = data.plannerId;
          this.customerId = data.customerId;
          this.dialog = true;
          this.getNotes();
        }
      });

      this.$bus.$on('noteSaved', () => {
        this.getNotes();
      });
    }
  }
</script>

<style scoped>
  .notes-loader-desc {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .empty-title {
    font-size: 20px;
    font-weight: bold;
  }
</style>
