import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const getUsersWithoutPlanner = (page = 1, limit = 10, search = '') => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const url = `/users/without-planner?page=${page}&limit=${limit}&search=${search}`
          const options = {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }

          return httpClient(url, options)
        })
        .then(resp => resolve(resp.data))
        .catch(reject)
    } catch(err) {
      reject(err)
    }
  })
}

export default getUsersWithoutPlanner
