import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const updatePerfil = (profileId, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'PUT';
          let url = `/profiles/${profileId}`;
          let headers = { Authorization: `Bearer ${idToken}` };

          return httpClient(url, { method, headers, data });
        })
        .then(resp =>  resolve(resp.data) )
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default updatePerfil;
