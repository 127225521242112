import {sumBy, uniqBy} from 'lodash'
import store from '@/store'

export default (currentPeriod, referencePeriod) => {
  let transactions = [];

  if (referencePeriod) {
    const invoiceTransactions = store.getters.transactions.filter(({invoice, creditCard, isInvoicePayment}) => {
      return (
        invoice &&
        creditCard &&
        !isInvoicePayment &&
        (
          (
            referencePeriod <= currentPeriod &&
            invoice.paid && invoice.paidIn &&
            invoice.paidIn.slice(0, 7) === referencePeriod
          ) ||
          (
            !creditCard.archived &&
            referencePeriod > currentPeriod &&
            !invoice.paid &&
            invoice.paymentDate.slice(0, 7) === referencePeriod
          )
        )
      );
    })

    const invoices = uniqBy(invoiceTransactions.filter(tr => {
      return !!tr.creditCard.pluggy
    }).map(tr => tr.invoice), invoice => invoice._id)


    transactions = [
      ...transactions,
      ...invoices,
      ...invoiceTransactions.filter(tr => !tr.creditCard.pluggy)
    ];

    transactions = [
      ...transactions,
      ...store.getters.transactions.filter(({
        bankAccount,
        creditCard,
        invoice,
        date,
        consolidated,
        type,
        isTransfer,
        investment,
        isInvoicePayment
      }) => {
        return (
          type === 'saida' &&
          !isInvoicePayment &&
          !isTransfer &&
          !investment &&
          !invoice &&
          !creditCard &&
          bankAccount &&
          !bankAccount.archived &&
          date.slice(0, 7) === referencePeriod &&
          (referencePeriod > currentPeriod || consolidated)
        );
      })
    ];
  }

  return sumBy(transactions, 'value')
}
