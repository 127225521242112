import { render, staticRenderFns } from "./CardInvestmentsWallet.vue?vue&type=template&id=054152ce&scoped=true&"
import script from "./CardInvestmentsWallet.vue?vue&type=script&lang=js&"
export * from "./CardInvestmentsWallet.vue?vue&type=script&lang=js&"
import style0 from "./CardInvestmentsWallet.vue?vue&type=style&index=0&id=054152ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "054152ce",
  null
  
)

export default component.exports