<template>
  <div class="uplanner-slider" v-dragscroll>
    <v-card
      dark
      :key="item.id"
      color="darkgrey"
      v-for="item in items"
      class="uplanner-slider-item mr-2"
    >
      <div class="pluggy-item_section d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-img
            width="32"
            height="21"
            class="mr-1"
            :src="item.connector.imageUrl"
            v-if="selectedItem !== item.id"
          ></v-img>

          <v-icon
            size="20"
            class="mr-4"
            color="primary"
            v-if="selectedItem === item.id"
          >
            mdi-checkbox-marked-circle-outline
          </v-icon>

          <h2 class="pluggy-item-name white--text">
            {{ item.connector ? item.connector.name : '' }}
          </h2>
        </div>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              rounded
              v-on="on"
              color="white"
              v-bind="attrs"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list nav dense>
            <v-list-item @click="updateItem(item.id)">
              <v-list-item-title>Atualizar credenciais</v-list-item-title>
            </v-list-item>

            <v-list-item @click="removeItem(item)">
              <v-list-item-title>Remover conexão</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div
        style="height: 36px;"
        class="text-center pluggy-item-name d-flex align-center justify-center"
      >
        <span v-if="!isActive(item)">
          Atualize suas credenciais!
        </span>

        <span v-if="isActive(item)">
          Última atualização: <br>
          {{ formatDate(item.lastUpdatedAt) }}
        </span>
      </div>

      <div
        v-if="!isActive(item)"
        @click="updateItem(item.id)"
        class="pluggy-item_section pluggy-item_action pluggy-item-name text-center warning--text text-decoration-underline"
      >
        Atualizar credenciais
      </div>

      <div
        v-if="isActive(item)"
        @click="setItem(item.id)"
        :class="selectedItem === item.id ? 'white--text' : 'primary--text'"
        class="pluggy-item_section pluggy-item_action pluggy-item-name text-center text-decoration-underline"
      >
        Ver detalhes
      </div>
    </v-card>
  </div>
</template>

<script>
import pluggyWidget from "@/utils/pluggy/pluggyWidget";
import pluggyItemStatusErrors from "@/utils/pluggy/pluggyItemStatusErrors";

export default {
  name: 'CarousselOpenBankingItems',
  props: ['items'],
  data: () => ({
    selectedItem: null
  }),
  methods: {
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY HH:mm')
      }

      return ''
    },
    selectDefaultItem() {
      if (this.items && this.items.length) {
        if (this.$route.query.item) {
          this.selectedItem = this.$route.query.item
        } else {
          this.selectedItem = this.items[0].id
        }
      } else {
        this.selectedItem = null
      }

      this.$bus.$emit('setPluggyItem', this.selectedItem)
    },
    updateItem(itemId) {
      pluggyWidget.loadScript()
      pluggyWidget.useAutomatic(itemId, pluggyWidget.onSuccess)
    },
    isActive(item) {
      return (!pluggyItemStatusErrors.status.includes(item.status) &&
        !pluggyItemStatusErrors.executionStatus.includes(item.executionStatus))
    },
    setItem(itemId) {
      this.selectedItem = itemId
      this.$bus.$emit('setPluggyItem', itemId)
    },
    removeItem(item) {
      this.$bus.$emit('showDialogRemovePluggyItem', item)
    }
  },
  watch: {
    items() {
      if (!this.selectedItem) {
        this.selectDefaultItem()
      }
    }
  },
  mounted() {
    this.selectDefaultItem()
  },
  created() {
    this.$bus.$on('setPluggyDefaultItem', this.selectDefaultItem)
  }
}
</script>

<style scoped>
.pluggy-item_section {
  padding: 16px;
}

.pluggy-item_action {
  margin-top: 8px;
  border-top: 1px #9e9e9e solid;
  text-transform: none;
  transition: ease .5s;
  cursor: pointer;
}

.pluggy-item_action:hover {
  background-color: #2d2d2d;
}
</style>
