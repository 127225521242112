import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar lista de nomes das empresas cadastradas
 */
const getCompaniesNames = () =>
  new Promise((resolve, reject) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          let headers = { Authorization: `Bearer ${idToken}` };
          return httpClient('/companies/names', { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });

export default getCompaniesNames;
