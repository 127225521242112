<template>
  <v-container grid-list-md class="ma-0 pa-0">
    <v-layout row wrap class="ma-0 pa-0">
      <v-flex xs12>
        <span class="input-label primary--text">Categoria</span>
        <v-select
          dense
          color="grey1"
          class="mt-0 pt-0"
          :items="categories"
          item-value="_id"
          item-text="name"
          v-model="category"
          :error-messages="categoryErrors"
        >
          <v-icon color="grey1" class="input-icon" slot="prepend-inner">
            mdi-bookmark-outline
          </v-icon>

          <template v-slot:item="{ item, index }">
            <v-icon small class="mr-2" :color="item.color">{{ item.icon }}</v-icon>
            <span>{{ item.name }}</span>
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip small outlined :color="item.color">
              <v-icon small class="mr-2">{{ item.icon }}</v-icon>
              <span>{{ item.name }}</span>
            </v-chip>
          </template>
        </v-select>
      </v-flex>

      <v-flex xs12 v-if="category === 'outro'">
        <span class="input-label primary--text">Nome da categoria</span>
        <v-text-field
          dense
          color="grey1"
          class="mt-0 pt-0"
          v-model="categoryName"
          :error-messages="categoryNameErrors"
        ></v-text-field>
      </v-flex>

      <v-flex xs12>
        <span class="input-label primary--text">Sub-Categoria</span>
        <v-select
          dense
          color="grey1"
          class="mt-0 pt-0"
          :items="subCategories"
          item-value="_id"
          item-text="name"
          v-model="subCategory"
          :error-messages="subCategoryErrors"
        >
          <template v-slot:item="{ item, index }">
            <span>{{ item.name }}</span>
            <v-icon small class="ml-2" v-if="item.fixed" color="error">mdi-pin</v-icon>
          </template>

          <template v-slot:selection="{ item, index }">
            <span>{{ item.name }}</span>
            <v-icon small class="ml-2" v-if="item.fixed">mdi-pin</v-icon>
          </template>
        </v-select>
      </v-flex>

      <v-flex xs12 v-if="subCategory === 'outro'">
        <span class="input-label primary--text">Nome da sub-categoria</span>
        <v-text-field
          dense
          color="grey1"
          class="mt-0 pt-0"
          v-model="subCategoryName"
          :error-messages="subCategoryNameErrors"
        ></v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import {orderBy} from "lodash";

export default {
  name: 'FormPendencia',
  props: ['type', 'transaction', 'dialog'],
  data: () => ({
    category: null,
    categoryName: '',
    subCategory: null,
    subCategoryName: ''
  }),
  validations: {
    category: {required},
    subCategory: {
      isValid(v) {
        return this.type === 'entrada' || !!v
      }
    },
    categoryName: {
      isValid(v) {
        return this.category !== 'outro' || (!!v && v.trim().length <= 50)
      }
    },
    subCategoryName: {
      isValid(v) {
        return this.subCategory !== 'outro' || (!!v && v.trim().length <= 50)
      }
    }
  },
  computed: {
    categories() {
      return [
        ...orderBy(this.$store.getters.categories.filter(cat => {
          if (this.type === 'entrada') {
            return cat.type === 'receita'
          } else {
            return cat.type === 'despesa'
          }
        }), 'name'),
        {
          _id: 'outro',
          name: 'Outro',
          color: '#D1AC68',
          subs: []
        }
      ]
    },
    selectedCategory() {
      return this.categories.find(cat => cat._id === this.category)
    },
    subCategories() {
      if (this.selectedCategory) {
        return [
          ...orderBy(this.selectedCategory.subs, 'name'),
          {
            _id: 'outro',
            name: 'Outro'
          }
        ]
      }

      return []
    },
    categoryErrors() {
      if (!this.$v.category.$anyDirty)
        return []

      if (this.$v.category.$anyError) {
        return ['Selecione uma categoria válida']
      }
    },
    categoryNameErrors() {
      if (!this.$v.categoryName.$anyDirty)
        return []

      if (this.$v.categoryName.$anyError) {
        return ['Preencha um nome válido']
      }
    },
    subCategoryErrors() {
      if (!this.$v.subCategory.$anyDirty)
        return []

      if (this.$v.subCategory.$anyError) {
        return ['Selecione uma sub-categoria válida']
      }
    },
    subCategoryNameErrors() {
      if (!this.$v.subCategoryName.$anyDirty)
        return []

      if (this.$v.subCategoryName.$anyError) {
        return ['Preencha um nome válido']
      }
    }
  },
  methods: {
    setValues() {
      if (this.transaction) {
        this.category = this.transaction.category
        this.subCategory = this.transaction.subCategory
      } else {
        this.category = null
        this.categoryName = ''
        this.subCategory = null
        this.subCategoryName = ''
      }

      this.$v.$reset()
    }
  },
  watch: {
    transaction(oldVal) {
      if (!oldVal) {
        this.setValues()
      }
    },
    category(val, oldVal) {
      if (this.category === 'outro') {
        this.subCategory = 'outro'
      } else if (!!oldVal) {
        this.subCategory = null
      }

      this.$v.$reset()
    }
  },
  mounted() {
    this.setValues()
  }
}
</script>
