export default {
  setInterest: (context, payload) => context.commit('setInterest', payload),
  setIsMobile: (context, payload) => context.commit('setIsMobile', payload),
  setIsIOS: (context, payload) => context.commit('setIsIOS', payload),
  setUser: (context, payload) => context.commit('setUser', payload),
  resetCustomer: (context, payload) => context.commit('resetCustomer'),
  // admin
  setConfigIr: (context, payload) => context.commit('setConfigIr', payload),
  saveConfigIrAliquotaIrpf: (context, payload) => context.commit('saveConfigIrAliquotaIrpf', payload),
  setPlans: (context, payload) => context.commit('setPlans', payload),
  savePlan: (context, payload) => context.commit('savePlan', payload),
  deletePlan: (context, payload) => context.commit('deletePlan', payload),
  setCompaniesNames: (context, payload) => context.commit('setCompaniesNames', payload),
  setPlannersNames: (context, payload) => context.commit('setPlannersNames', payload),
  setCompany: (context, payload) => context.commit('setCompany', payload),
  // customer
  setBankAccounts: (context, payload) => context.commit('setBankAccounts', payload),
  saveBankAccount: (context, payload) => context.commit('saveBankAccount', payload),
  updateBankAccountBalance: (context, payload) => context.commit('updateBankAccountBalance', payload),
  archiveBankAccount: (context, payload) => context.commit('archiveBankAccount', payload),
  resetTransactions: (context) => context.commit('resetTransactions'),
  setTransactions: (context, payload) => context.commit('setTransactions', payload),
  saveTransaction: (context, payload) => context.commit('saveTransaction', payload),
  removeTransaction: (context, payload) => context.commit('removeTransaction', payload),
  setCategories: (context, payload) => context.commit('setCategories', payload),
  saveCategory: (context, payload) => context.commit('saveCategory', payload),
  setCreditCards: (context, payload) => context.commit('setCreditCards', payload),
  saveCreditCard: (context, payload) => context.commit('saveCreditCard', payload),
  updateCreditCardLimit: (context, payload) => context.commit('updateCreditCardLimit', payload),
  resetInvoices: (context) => context.commit('resetInvoices'),
  setInvoices: (context, payload) => context.commit('setInvoices', payload),
  saveInvoice: (context, payload) => context.commit('saveInvoice', payload),
  paidInvoice: (context, payload) => context.commit('paidInvoice', payload),
  updateInvoiceValue: (context, payload) => context.commit('updateInvoiceValue', payload),
  setPendencias: (context, payload) => context.commit('setPendencias', payload),
  removePendencia: (context, payload) => context.commit('removePendencia', payload),
  setOrcamento: (context, payload) => context.commit('setOrcamento', payload),
  saveItemOrcamento: (context, payload) => context.commit('saveItemOrcamento', payload),
  saveTransactionsPeriod: (context, payload) => context.commit('saveTransactionsPeriod', payload),
  setPerfil: (context, payload) => context.commit('setPerfil', payload),
  updatePerfil: (context, payload) => context.commit('updatePerfil', payload),
  setPatrimonio: (context, payload) => context.commit('setPatrimonio', payload),
  saveItemPatrimonio: (context, payload) => context.commit('saveItemPatrimonio', payload),
  removeItemPatrimonio: (context, payload) => context.commit('removeItemPatrimonio', payload),
  setIndependenciaFinanceira: (context, payload) => context.commit('setIndependenciaFinanceira', payload),
  saveProjetoIndFinanceira: (context, payload) => context.commit('saveProjetoIndFinanceira', payload),
  removeProjetoIndFinanceira: (context, payload) => context.commit('removeProjetoIndFinanceira', payload),
  logout: (context) => context.commit('logout'),
  setCustomer: (context, payload) => context.commit(`setCustomer`, payload),
  saveLiquidez: (context, payload) => context.commit(`saveLiquidez`, payload),
  setPlanner: (context, payload) => context.commit(`setPlanner`, payload),
  setInvestments: (context, payload) => context.commit('setInvestments', payload),
  saveInvestment: (context, payload) => context.commit('saveInvestment', payload),
  deleteInvestment: (context, payload) => context.commit('deleteInvestment', payload),
  saveInvestmentTransaction: (context, payload) => context.commit('saveInvestmentTransaction', payload),
  deleteInvestmentTransaction: (context, payload) => context.commit('deleteInvestmentTransaction', payload),
  setActionPlans: (context, payload) => context.commit('setActionPlans', payload),
  saveActionPlan: (context, payload) => context.commit('saveActionPlan', payload),
  removeActionPlan: (context, payload) => context.commit('removeActionPlan', payload),
  checkActionPlans: (context, payload) => context.commit('checkActionPlans'),
  syncBankAccount: (context, payload) => context.commit('syncBankAccount', payload),
  setLifeInsurances: (context, payload) => context.commit('setLifeInsurances', payload),
  saveLifeInsurance: (context, payload) => context.commit('saveLifeInsurance', payload),
  saveLifeInsuranceDependentsNote: (context, payload) => context.commit('saveLifeInsuranceDependentsNote', payload),
  deleteLifeInsuranceDependentsNote: (context, payload) => context.commit('deleteLifeInsuranceDependentsNote', payload),
  setMeiosPagamento: (context, payload) => context.commit('setMeiosPagamento', payload),
  saveMeioPagamento: (context, payload) => context.commit('saveMeioPagamento', payload),
  deleteMeioPagamento: (context, payload) => context.commit('deleteMeioPagamento', payload),
  setSubscriptionSuspended: (context, payload) => context.commit('setSubscriptionSuspended', payload),

  // recommendations
  setRecommendations: (context, payload) => context.commit('setRecommendations', payload),
  saveRecommendation: (context, payload) => context.commit('saveRecommendation', payload),

  //banksAndCodes
  setBanksAndCodes: (context, payload) => context.commit('setBanksAndCodes', payload),

  //requestReward
  setRequestReward: (context, payload) => context.commit("setRequestReward", payload),
  saveRequestReward: (context, payload) => context.commit("saveRequestReward", payload),
  setOpenBankingError: (context, payload) => context.commit("setOpenBankingError", payload),

  // pluggy
  clearPluggyItemData: (context, payload) => context.commit('clearPluggyItemData', payload),

  // pendentCreditCards
  setPendentCreditCards: (context, payload) => context.commit('setPendentCreditCards', payload),
  deletePendentCreditCard: (context, payload) => context.commit('deletePendentCreditCard', payload),
  setTotalPendencias: (context, payload) => context.commit('setTotalPendencias', payload)
};
