import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const checkCalendlyIntegration = (email, plannerId = '') => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }

          const url = `/calendly/check-integration?email=${email}&plannerId=${plannerId}`

          return httpClient(url, options)
        })
        .then(resp => resolve(resp.data))
        .catch(reject)
    } catch(err) {
      reject(err)
    }
  })
}

export default checkCalendlyIntegration
