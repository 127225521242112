<template>
  <v-main class="margin-menu">
    <OverlaySync :overlay="loader" text="Buscando dados..." />

    <MobilePageTitle title="Investimentos" :loader="loader" />

    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 class="text-center">
          <div class="page-title hidden-sm-and-down mt-md-10">
            Investimentos
          </div>
          <span class="page-sub-title grey1--text text-center">
            Reserva de emergência, projetos de vida e independência financeira
            <v-icon color="grey1" @click="showDialogWalletsDescription">mdi-information-outline</v-icon>
          </span>
        </v-flex>

        <v-slide-x-transition>
          <v-flex xs12 md6 offset-md3 lg4 offset-lg4 v-if="period">
            <CardTotalApplied :period="period" />
          </v-flex>
        </v-slide-x-transition>

        <v-slide-x-transition>
          <v-flex xs12 v-if="wallets && wallets.securityWallet">
            <CardInvestmentsWallet
              :period="period"
              wallet-name="security"
              :wallet="wallets.securityWallet"
            />
          </v-flex>
        </v-slide-x-transition>

        <v-slide-x-transition>
          <v-flex xs12 v-if="wallets && wallets.lifeProjectsWallet">
            <CardInvestmentsWallet
              :period="period"
              wallet-name="life-projects"
              :wallet="wallets.lifeProjectsWallet"
            />
          </v-flex>
        </v-slide-x-transition>

        <v-slide-x-transition>
          <v-flex xs12 v-if="wallets && wallets.independenceWallet && liquidez">
            <CardInvestmentsWallet
              :period="period"
              wallet-name="independence"
              :wallet="wallets.independenceWallet"
            />
          </v-flex>
        </v-slide-x-transition>
      </v-layout>
    </v-container>

    <DialogWalletsDescription />
    <DialogWalletInvestments />
    <DialogFormWalletInvestment />
    <DialogRemoveInvestment />
    <DialogFormInvestmentTransaction />
    <DialogWalletInvestment />
    <DialogRemoveInvestmentTransaction />
    <DialogFormRentabilidade />
  </v-main>
</template>

<script>
import OverlaySync from '@/components/Global/OverlaySync';
import MobilePageTitle from '@/components/Global/MobilePageTitle';
import DialogWalletsDescription from '@/components/Customer/Investimentos/dialogs/DialogWalletsDescription';
import CardTotalApplied from '@/components/Customer/Investimentos/cards/CardTotalApplied';

import getLoggedUserData from '@/utils/getLoggedUserData';
import getInvestmentsWallets from '@/api/investimentos/getInvestmentsWallets';
import getInvestmentsByUserId from '@/api/investimentos/getInvestmentsByUserId';
import CardInvestmentsWallet from '@/components/Customer/Investimentos/cards/CardInvestmentsWallet';
import DialogWalletInvestments from '@/components/Customer/Investimentos/dialogs/DialogWalletInvestments';
import getLiquidezByUserId from '@/api/liquidez/getLiquidezByUserId';
import getUserIndFinanceira from '@/api/indFinanceira/getUserIndFinanceira';
import DialogFormWalletInvestment from '@/components/Customer/Investimentos/dialogs/DialogFormWalletInvestment';
import DialogRemoveInvestment from '@/components/Customer/Investimentos/dialogs/DialogRemoveInvestment';
import DialogFormInvestmentTransaction from '@/components/Customer/Investimentos/dialogs/DialogWalletInvestmentTransaction';
import DialogWalletInvestment from '@/components/Customer/Investimentos/dialogs/DialogWalletInvestment';
import DialogRemoveInvestmentTransaction from '@/components/Customer/Investimentos/dialogs/DialogRemoveInvestmentTransaction';
import DialogFormRentabilidade from '@/components/Customer/Investimentos/dialogs/DialogFormRentabilidade';

export default {
  name: 'Investimentos',
  components: {
    DialogFormRentabilidade,
    DialogRemoveInvestmentTransaction,
    DialogWalletInvestment,
    DialogFormInvestmentTransaction,
    DialogRemoveInvestment,
    DialogFormWalletInvestment,
    DialogWalletInvestments,
    CardInvestmentsWallet,
    CardTotalApplied,
    DialogWalletsDescription,
    MobilePageTitle,
    OverlaySync,
  },
  data: () => ({
    loader: false,
    currentDate: null,
    year: null,
    month: null,
    period: '',
    wallets: [],
    years: [],
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    months() {
      return this.$store.getters.months;
    },
    liquidez() {
      return this.$store.getters.liquidez;
    },
    investments() {
      return this.$store.getters.investments;
    },
    indFinanceira() {
      return this.$store.getters.independenciaFinanceira;
    },
  },
  methods: {
    showDialogWalletsDescription() {
      this.$bus.$emit('showDialogWalletsDescription');
    },
    getData() {
      this.loader = true;

      if (!this.user) {
        getLoggedUserData()
          .then(() => {
            this.setupYears();
            this.getInvestmentsData();

            if (!this.liquidez) this.getLiquidez();

            if (
              !this.indFinanceira ||
              this.indFinanceira.period !== this.period
            ) {
              this.getIndFinanceira();
            }
          })
          .catch((err) => {
            this.$router.push('/');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      } else {
        this.getInvestmentsData();

        if (!this.liquidez) this.getLiquidez();

        if (!this.indFinanceira || this.indFinanceira.period !== this.period) {
          this.getIndFinanceira();
        }
      }
    },
    getInvestmentsData() {
      let promises = [
        getInvestmentsWallets(this.user._id, this.period),
        getInvestmentsByUserId(this.user._id),
      ];

      Promise.all(promises)
        .then((results) => {
          this.wallets = results[0];
          this.$store.dispatch('setInvestments', results[1]);
          this.loader = false;
        })
        .catch((err) => {
          this.$router.push('/');
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    },
    setupYears() {
      if (this.user) {
        let createdYear = this.$date(this.user.createdAt).year();
        let limit = createdYear + (this.year - createdYear + 1);

        for (let i = createdYear; i < limit; i++) {
          this.years.push(i);
        }
      } else {
        this.years = [this.year];
      }
    },
    getWallets() {
      let month = this.month;

      if (month < 10) month = `0${month}`;

      this.period = `${this.year}-${month}`;
      this.loader = true;

      getInvestmentsWallets(this.user._id, this.period)
        .then((wallets) => {
          this.wallets = wallets;
          this.loader = false;
        })
        .catch((err) => {
          this.loader = false;

          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    },
    getLiquidez() {
      getLiquidezByUserId(this.user._id)
        .then((liquidez) => {
          this.$store.dispatch('saveLiquidez', liquidez);
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    },
    getIndFinanceira() {
      getUserIndFinanceira(this.user._id, this.period)
        .then((indFinanceira) => {
          this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    },
  },
  beforeCreate() {
    this.$vuetify.theme.dark = true;
  },
  created() {
    this.currentDate = this.$date()
    this.year = this.currentDate.year();
    this.month = this.currentDate.month() + 1;
    this.period = this.currentDate.format('YYYY-MM');

    this.getData();
    this.setupYears();

    this.$bus.$on('updateInvestmentWallets', (wallets) => {
      this.wallets = wallets;
    });
  },
};
</script>

<style scoped>
.v-main {
  background-color: #131313 !important;
  padding-bottom: 70px !important;
}

.container {
  max-width: 1020px;
}

.page-sub-title {
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0.1em;
}

@media only screen and (min-width: 600px) {
  .page-sub-title {
    display: block;
    font-size: 14px;
    text-align: center;
  }
}

@media only screen and (min-width: 960px) {
  .page-title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 1.5em;
    letter-spacing: 0.1em;
  }
}

@media only screen and (min-width: 1264px) {
  .container {
    width: 100%;
  }
}
</style>
