import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const getLifeInsurance = (userId, isSpouse) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }
          const url = `/life-insurances/users/${userId}?isSpouse=${isSpouse}`

          return httpClient(url, options)
        })
        .then(resp => resolve(resp.data))
        .catch(reject)
    } catch(err) {
      reject(err)
    }
  })
}

export default getLifeInsurance
