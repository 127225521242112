<template>
  <v-main class="pb-10 margin-menu">
    <OverlaySync :overlay="loader" />
    <MobilePageTitle
      class="fixed-card"
      title="Independência Financeira"
      :loader="loader"
    />

    <div class="month-selector dark-bg">
      <v-btn
        icon
        color="grey1"
        class="btn-month"
        :disabled="disableBack"
        @click.stop="changePeriod('back')"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <span class="month-name">{{ monthStr }}</span>

      <v-btn
        icon
        color="grey1"
        class="btn-month"
        :disabled="disableNext"
        @click.stop="changePeriod('next')"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <v-container class="pt-0 pb-10" grid-list-md v-if="indFinanceira">
      <v-layout row wrap>
        <v-flex xs12 class="my-5">
          <h1 class="ind-title primary--text text-uppercase px-5">
            Projeção patrimonial e planejamento da
            <br class="hidden-sm-and-down" />
            independência financeira
          </h1>
        </v-flex>

        <v-flex xs12>
          <v-expansion-panels multiple v-model="panels">
            <v-expansion-panel>
              <v-expansion-panel-header
                class="ind-section-title text-uppercase"
              >
                Informações gerais e patrimônio atual
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <CardInformacoesGerais />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                class="ind-section-title text-uppercase"
              >
                Premissas de independência financeira
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <CardRendaIndFinanceira />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                class="ind-section-title text-uppercase"
              >
                <div class="ind-section-title--two-line">
                  <span>Taxa de juros</span>
                  <span class="grey1--text">Período de acumulação</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <CardTaxasIndFinanceira origin="taxasProducao" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                class="ind-section-title text-uppercase"
              >
                <div class="ind-section-title--two-line">
                  <span>Taxa de juros</span>
                  <span class="grey1--text">Independência financeira</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <CardTaxasIndFinanceira origin="taxasConsumo" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>

        <v-flex xs12 sm12 md4 lg4>
          <CardRendaVitalicia />
        </v-flex>

        <v-flex xs12 sm12 md4 lg4>
          <CardConsumoPatrimonio />
        </v-flex>

        <v-flex xs12 sm12 md4 lg4>
          <CardCenarioReal />
        </v-flex>

        <v-flex xs12>
          <CardGraficoIndFinanceira />
        </v-flex>

        <v-flex xs12>
          <CardProjetosIndFinanceira />
        </v-flex>
      </v-layout>
    </v-container>

    <DialogFormTaxas />
    <DialogFormInfosGerais />
    <DialogAplicacoesMensais />
    <DialogRendasIndFinanceira />
    <DialogFormRendasIndFinanceira />
  </v-main>
</template>

<script>
import moment from 'moment-timezone';

import OverlaySync from '../../components/Global/OverlaySync';
import MobilePageTitle from '../../components/Global/MobilePageTitle';
import CardCenarioReal from '../../components/Customer/IndFinanceira/cards/CardCenarioReal';
import CardRendaVitalicia from '../../components/Customer/IndFinanceira/cards/CardRendaVitalicia';
import CardConsumoPatrimonio from '../../components/Customer/IndFinanceira/cards/CardConsumoPatrimonio';
import CardInformacoesGerais from '../../components/Customer/IndFinanceira/cards/CardInformacoesGerais';

import getCustomerData from '../../utils/getCustomerData';
import getUserIndFinanceira from '../../api/indFinanceira/getUserIndFinanceira';
import DialogFormInfosGerais from '../../components/Customer/IndFinanceira/dialogs/DialogFormInfosGerais';
import DialogAplicacoesMensais from '../../components/Customer/IndFinanceira/dialogs/DialogAplicacoesMensais';
import CardRendaIndFinanceira from '../../components/Customer/IndFinanceira/cards/CardRendaIndFinanceira';
import DialogFormRendasIndFinanceira from '../../components/Customer/IndFinanceira/dialogs/DialogFormRendasIndFinanceira';
import DialogRendasIndFinanceira from '../../components/Customer/IndFinanceira/dialogs/DialogRendasIndFinanceira';
import CardTaxasIndFinanceira from '../../components/Customer/IndFinanceira/cards/CardTaxasIndFinanceira';
import DialogFormTaxas from '../../components/Customer/IndFinanceira/dialogs/DialogFormTaxasIndFinanceira';
import CardGraficoIndFinanceira from '../../components/Customer/IndFinanceira/cards/CardGraficoIndFinanceira';
import CardProjetosIndFinanceira from '../../components/Customer/IndFinanceira/cards/CardProjetosIndFinanceira';
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

export default {
  name: 'IndFinanceira',
  // metaInfo() {
  //   return {
  //     title: 'Independência financeira',
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo);
  //     },
  //   };
  // },
  components: {
    CardProjetosIndFinanceira,
    CardGraficoIndFinanceira,
    DialogFormTaxas,
    CardTaxasIndFinanceira,
    DialogRendasIndFinanceira,
    DialogFormRendasIndFinanceira,
    CardRendaIndFinanceira,
    DialogAplicacoesMensais,
    DialogFormInfosGerais,
    CardInformacoesGerais,
    CardCenarioReal,
    CardConsumoPatrimonio,
    CardRendaVitalicia,
    MobilePageTitle,
    OverlaySync,
  },
  data: () => ({
    loader: false,
    currentDate: null,
    currentPeriod: null,
    monthStr: '',
    periodPattern: 'YYYY-MM',
    year: null,
    panels: false,
  }),
  computed: {
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    indFinanceira() {
      return this.$store.getters.independenciaFinanceira;
    },
    disableBack() {
      let [year, month] = this.currentPeriod.split('-');
      let [userYear, userMonth] = moment(this.user.createdAt).format('YYYY-MM');
      return userYear > year || (userYear === year && userMonth >= month);
    },
    disableNext() {
      let [periodYear, periodMonth] = this.currentPeriod.split('-');
      let [year, month] = moment().format('YYYY-MM').split('-');
      return periodYear === year && periodMonth === month;
    },
  },
  methods: {
    getData() {
      if (!this.user) {
        getCustomerData().then(() => {
          this.indFinanceira();
        });
      } else if (
        !this.indFinanceira ||
        this.indFinanceira.period !== this.currentPeriod
      ) {
        this.getIndFinanceira();
      } else {
        this.loader = false;
      }
    },
    getIndFinanceira() {
      this.loader = true;

      getUserIndFinanceira(this.user._id, this.currentPeriod)
        .then((indFinanceira) => {
          this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
          this.loader = false;
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.$router.push('/');
        });
    },
    changePeriod(type) {
      if (type === 'next') this.currentDate.add(1, 'month');
      else if (type === 'back') this.currentDate.subtract(1, 'month');

      this.currentPeriod = this.currentDate.format(this.periodPattern);
      this.getIndFinanceira();
    },
  },
  watch: {
    currentPeriod() {
      this.monthStr = this.currentDate.format('MMMM');

      if (this.year !== this.currentDate.year())
        this.monthStr += `/${this.currentDate.year()}`;
    },
    indFinanceira: {
      deep: true,
      handler() {
        if (
          this.indFinanceira.period === this.currentPeriod &&
          !this.indFinanceira.dataInicial
        ) {
          this.indFinanceira.dataInicial = this.currentDate.format(
            'YYYY-MM-DD'
          );
        }
      },
    },
  },
  beforeCreate() {
    this.$vuetify.theme.dark = true;
  },
  created() {
    this.loader = true;
    this.currentDate = moment().tz('America/Sao_Paulo');
    this.year = this.currentDate.year();
    this.currentPeriod = this.currentDate.format('YYYY-MM');

    this.getData();
  },
};
</script>

<style scoped>
.container {
  max-width: 1020px;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #404040 !important;
}

.ind-title {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: 0.1em;
}

.ind-section-title {
  font-size: 12px !important;
  letter-spacing: 0.1em;
  line-height: 1.5em;
}

.ind-section-title--two-line {
  display: flex !important;
  flex-direction: column !important;
}

.ind-section-title--two-line .grey1--text {
  font-size: 9px;
}

@media only screen and (min-width: 600px) {
  .ind-title,
  .ind-section-title {
    font-size: 15px !important;
  }

  .ind-section-title--two-line .grey1--text {
    font-size: 10px;
  }
}

@media only screen and (min-width: 960px) {
  .container {
    width: 718px !important;
  }

  .ind-title {
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    font-size: 20px !important;
  }

  .ind-section-title {
    font-size: 16px !important;
  }

  .ind-section-title--two-line .grey1--text {
    font-size: 12px;
  }
}

@media only screen and (min-width: 960px) {
  .container {
    width: 100% !important;
  }
}
</style>
