<template>
  <v-card flat color="grey3">
    <v-card-title :class="isIOS ? 'ios-padding' : ''">Experiência Financeira</v-card-title>

    <v-card-text class="px-1">
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12>
            <h3>{{ username }}</h3>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span>Experiência com dinheiro na infância e prática de poupar durante a vida.</span>
          </v-flex>

          <v-flex xs12>
            <span class="input-label primary--text">Recebia mesada na infância?</span>
            <v-switch
              flat
              inset
              color="primary"
              :label="usuario.mesada ? 'Recebia' : 'Não recebia'"
              v-model="usuario.mesada"
              :error-messages="usuarioMesadaErrors"
            ></v-switch>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span>
              E hoje, gasta mais do que ganha, gasta exatamente o que ganha ou sobra alguma coisa? <br>
              Se sobra, qual a sua estimativa?
            </span>
          </v-flex>

          <v-flex xs12 sm6 md6>
            <span class="input-label primary--text">Capacidade atual de poupar</span>
            <v-select
              color="grey1"
              class="pt-0 mt-0"
              :items="capacidades"
              v-model="usuario.capacidadePoupanca.descricao"
              :error-messages="usuarioCapacidadePoupancaDescricaoErrors"
            ></v-select>
          </v-flex>

          <v-flex xs12 sm6 md6>
            <span class="input-label primary--text">Estimativa de poupança</span>
            <v-text-field
              color="grey1"
              class="pt-0 mt-0"
              v-money="money"
              v-model="usuario.capacidadePoupanca.estimativa"
              id="usuario-estimativa-popupanca"
              :error-messages="usuarioCapacidadePoupancaEstimativaErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span>
              Quanto você gasta por mês, considerando gastos fixos como: aluguel, energia, telefone. <br>
              Custos variáveis como: lazer, supermercado e outros.
            </span>
          </v-flex>

          <v-flex xs12 sm12 md6>
            <span class="input-label primary--text">Estimativa de despesas mensais</span>
            <v-text-field
              color="grey1"
              class="pt-0 mt-0"
              v-money="money"
              v-model="usuario.despesasMensais"
              id="usuario-despesas-mensais"
              :error-messages="usuarioDespesasMensaisErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span>
              Experiência com investimentos <br>
              Com quais investimentos você já teve experiências?
            </span>
          </v-flex>

          <v-flex xs12 sm12 md6>
            <span class="primary--text input-label">Selecionar investimentos</span>
            <v-select
              multiple
              color="grey1"
              class="pt-0 mt-0"
              v-model="usuario.experienciasInvestimentos"
              :items="investimentos"
            ></v-select>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span>
              Como você determina sua experiência com investimentos? <br>
              <a
                href="#!"
                class="purple--text"
                @click.prevent="showDialogFatoresPerfil"
              >
                Classifique de acordo com os seguintes fatores.
              </a>
            </span>
          </v-flex>

          <v-flex xs12 sm12 md6>
            <span class="input-label primary--text">Selecionar fator de experiência</span>
            <v-select
              color="grey1"
              class="pt-0 mt-0"
              v-model="usuario.fatorExperiencia"
              :items="fatoresExperiencia"
              :error-messages="usuarioFatorExperienciaErrors"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container grid-list-md v-if="hasConjuge">
        <v-layout row wrap>
          <v-flex xs12 class="my-5">
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12>
            <h3>{{ conjugeName }}</h3>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span>Experiência com dinheiro na infância e prática de poupar durante a vida.</span>
          </v-flex>

          <v-flex xs12>
            <span class="input-label primary--text">Recebia mesada na infância?</span>
            <v-switch
              flat
              inset
              color="primary"
              :label="conjuge.mesada ? 'Recebia' : 'Não recebia'"
              v-model="conjuge.mesada"
              :error-messages="conjugeMesadaErrors"
            ></v-switch>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span>
              E hoje, gasta mais do que ganha, gasta exatamente o que ganha ou sobra alguma coisa? <br>
              Se sobra, qual a sua estimativa?
            </span>
          </v-flex>

          <v-flex xs12 sm6 md6>
            <span class="input-label primary--text">Capacidade atual de poupar</span>
            <v-select
              color="grey1"
              class="pt-0 mt-0"
              :items="capacidades"
              v-model="conjuge.capacidadePoupanca.descricao"
              :error-messages="conjugeCapacidadePoupancaDescricaoErrors"
            ></v-select>
          </v-flex>

          <v-flex xs12 sm12 md6>
            <span class="input-label primary--text">Estimativa de poupança</span>
            <v-text-field
              color="grey1"
              class="pt-0 mt-0"
              v-money="money"
              v-model="conjuge.capacidadePoupanca.estimativa"
              id="conjuge-estimativa-popupanca"
              :error-messages="conjugeCapacidadePoupancaEstimativaErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span>
              Quanto você gasta por mês, considerando gastos fixos como: aluguel, energia, telefone. <br>
              Custos variáveis como: lazer, supermercado e outros.
            </span>
          </v-flex>

          <v-flex xs12 sm12 md6>
            <span class="input-label primary--text">Estimativa de despesas mensais</span>
            <v-text-field
              color="grey1"
              class="pt-0 mt-0"
              v-money="money"
              v-model="conjuge.despesasMensais"
              id="conjuge-despesas-mensais"
              :error-messages="conjugeDespesasMensaisErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span>
              Experiência com investimentos <br>
              Com quais investimentos você já teve experiências?
            </span>
          </v-flex>

          <v-flex xs12 sm12 md6>
            <span class="primary--text input-label">Selecionar investimentos</span>
            <v-select
              multiple
              color="grey1"
              class="pt-0 mt-0"
              v-model="conjuge.experienciasInvestimentos"
              :items="investimentos"
            ></v-select>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span>
              Como você determina sua experiência com investimentos? <br>
              <a
                href="#!"
                class="purple--text"
                @click.prevent="showDialogFatoresPerfil"
              >
                Classifique de acordo com os seguintes fatores.
              </a>
            </span>
          </v-flex>

          <v-flex xs12 sm12 md6>
            <span class="input-label primary--text">Selecionar fator de experiência</span>
            <v-select
              color="grey1"
              class="pt-0 mt-0"
              v-model="conjuge.fatorExperiencia"
              :items="fatoresExperiencia"
              :error-messages="conjugeFatorExperienciaErrors"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 class="mt-10">
            <v-card-actions class="pa-0 ma-0 submit-buttons">
              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="stepBack"
              >
                Voltar
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="skip"
              >
                Pular
              </v-btn>

              <v-btn
                rounded
                color="primary"
                class="px-5 darkgrey--text"
                :loading="loader"
                @click.stop="submit"
              >
                Próximo
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money';
import removeMoneyMask from '../../../../utils/removeMoneyMask';
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'FormExperienciaFinanceira',
  directives: { money: VMoney },
  props: [
    'profileId',
    'experienciaFinanceira',
    'hasConjuge',
    'username',
    'conjugeName'
  ],
  data: () => ({
    loader: false,
    fatoresExperiencia: [
      'Cultural',
      'Contextual',
      'Individual'
    ],
    capacidades: [
      'Gasto mais do que ganho',
      'Gasto tudo o que ganho',
      'Gasto menos do que ganho'
    ],
    investimentos: [
      'Ações',
      'CDB',
      'Debêntures',
      'Fundo de ações',
      'Fundo de renda fixa',
      'Fundo imobiliário',
      'Fundo multimercados',
      'Imobiliário',
      'LCI/LCA/LC',
      'Poupança',
      'Previdência',
      'Título de capitalização',
      'Título público'
    ],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    usuario: {
      mesada: false,
      despesasMensais: '',
      primeiroInvestimento: '',
      fatorExperiencia: '',
      experienciasInvestimentos: [],
      capacidadePoupanca: {
        descricao: '',
        estimativa: ''
      }
    },
    conjuge: {
      mesada: false,
      despesasMensais: '',
      primeiroInvestimento: '',
      fatorExperiencia: '',
      experienciasInvestimentos: [],
      capacidadePoupanca: {
        descricao: '',
        estimativa: ''
      }
    }
  }),
  validations: {
    usuario: {
      mesada: {
        isValid: v => typeof v === 'boolean'
      },
      despesasMensais: {
        isValid: v => typeof v !== 'undefined' && removeMoneyMask(v) >= 0
      },
      primeiroInvestimento: {
        isValid: v => !v || v.length <= 150
      },
      fatorExperiencia: {
        isValid(v) {
          return !v || this.fatoresExperiencia.includes(v);
        }
      },
      capacidadePoupanca: {
        descricao: {
          isValid(v) {
            return !v || this.capacidades.includes(v)
          }
        },
        estimativa: {
          isValid: v => !v || removeMoneyMask(v) >= 0
        }
      }
    },
    conjuge: {
      mesada: {
        isValid: v => typeof v === 'boolean'
      },
      despesasMensais: {
        isValid: v => typeof v !== 'undefined' && removeMoneyMask(v) >= 0
      },
      primeiroInvestimento: {
        isValid: v => !v || v.length <= 150
      },
      fatorExperiencia: {
        isValid(v) {
          return !v || this.fatoresExperiencia.includes(v);
        }
      },
      capacidadePoupanca: {
        descricao: {
          isValid(v) {
            return !v || this.capacidades.includes(v);
          }
        },
        estimativa: {
          isValid: v => !v || removeMoneyMask(v) >= 0
        }
      }
    }
  },
  computed: {
    // helpers
    isIOS() {
      return this.$store.getters.isIOS;
    },
    // errors messages
    usuarioMesadaErrors() {
      if (!this.$v.usuario.mesada.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.mesada.$anyError) {
        return ['Marque uma opção válida'];
      }
    },
    usuarioDespesasMensaisErrors() {
      if (!this.$v.usuario.despesasMensais.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.despesasMensais.$anyError) {
        return ['Preencha um valor válido'];
      }
    },
    usuarioPrimeiroInvestimentoErrors() {
      if (!this.$v.usuario.primeiroInvestimento.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.primeiroInvestimento.$anyError) {
        return ['Preencha uma descrição válida'];
      }
    },
    usuarioFatorExperienciaErrors() {
      if (!this.$v.usuario.fatorExperiencia.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.fatorExperiencia.$anyError) {
        return ['Selecione uma opção válida'];
      }
    },
    usuarioCapacidadePoupancaDescricaoErrors() {
      if (!this.$v.usuario.capacidadePoupanca.descricao.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.capacidadePoupanca.descricao.$anyError) {
        return ['Preencha uma descrição válida'];
      }
    },
    usuarioCapacidadePoupancaEstimativaErrors() {
      if (!this.$v.usuario.capacidadePoupanca.estimativa.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.capacidadePoupanca.estimativa.$anyError) {
        return ['Preencha uma estimativa válida'];
      }
    },
    conjugeMesadaErrors() {
      if (!this.$v.conjuge.mesada.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.mesada.$anyError) {
        return ['Marque uma opção válida'];
      }
    },
    conjugeDespesasMensaisErrors() {
      if (!this.$v.conjuge.despesasMensais.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.despesasMensais.$anyError) {
        return ['Preencha um valor válido'];
      }
    },
    conjugePrimeiroInvestimentoErrors() {
      if (!this.$v.conjuge.primeiroInvestimento.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.primeiroInvestimento.$anyError) {
        return ['Preencha uma descrição válida'];
      }
    },
    conjugeFatorExperienciaErrors() {
      if (!this.$v.conjuge.fatorExperiencia.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.fatorExperiencia.$anyError) {
        return ['Selecione uma opção válida'];
      }
    },
    conjugeCapacidadePoupancaDescricaoErrors() {
      if (!this.$v.conjuge.capacidadePoupanca.descricao.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.capacidadePoupanca.descricao.$anyError) {
        return ['Preencha uma descrição válida'];
      }
    },
    conjugeCapacidadePoupancaEstimativaErrors() {
      if (!this.$v.conjuge.capacidadePoupanca.estimativa.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.capacidadePoupanca.estimativa.$anyError) {
        return ['Preencha uma estimativa válida'];
      }
    }
  },
  methods: {
    showDialogFatoresPerfil() {
      this.$bus.$emit('showDialogFatoresPerfil');
    },
    copyData() {
      if (this.experienciaFinanceira) {
        this.usuario = {
          ...this.usuario,
          ...this.experienciaFinanceira.usuario
        }

        this.usuario.despesasMensais = this.usuario.despesasMensais.toFixed(2)
        this.usuario.capacidadePoupanca.estimativa = this.usuario.capacidadePoupanca.estimativa.toFixed(2)

        let input = document.getElementById('usuario-despesas-mensais')
        if (input) {
          input.value = this.usuario.despesasMensais
          input.dispatchEvent(new Event('input'))
        }

        input = document.getElementById('usuario-estimativa-popupanca')
        if (input) {
          input.value = this.usuario.capacidadePoupanca.estimativa
          input.dispatchEvent(new Event('input'))
        }

        if (this.hasConjuge) {
          this.conjuge = {
            ...this.conjuge,
            ...this.experienciaFinanceira.conjuge
          }

          this.conjuge.despesasMensais = this.conjuge.despesasMensais.toFixed(2)
          this.conjuge.capacidadePoupanca.estimativa = this.conjuge.capacidadePoupanca.estimativa.toFixed(2)

          input = document.getElementById('conjuge-despesas-mensais')
          if (input) {
            input.value = this.conjuge.despesasMensais
            input.dispatchEvent(new Event('input'))
          }

          input = document.getElementById('conjuge-estimativa-popupanca')
          if (input) {
            input.value = this.conjuge.capacidadePoupanca.estimativa
            input.dispatchEvent(new Event('input'))
          }
        }
      }
    },
    skip() {
      updatePerfil(this.profileId, { step: 6 }).then((perfil) => {
        this.$store.dispatch("setPerfil", perfil);
      });

      this.$bus.$emit("nextProfileStep");
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.hasConjuge) {
          this.$v.conjuge.$reset()
        }

        if (!this.$v.$anyError) {
          this.loader = true

          const usuario = JSON.parse(JSON.stringify(this.usuario))

          usuario.despesasMensais = removeMoneyMask(usuario.despesasMensais)
          usuario.capacidadePoupanca.estimativa = removeMoneyMask(usuario.capacidadePoupanca.estimativa)

          let conjuge = null

          if (this.hasConjuge) {
            conjuge = JSON.parse(JSON.stringify(this.conjuge))
            conjuge.despesasMensais = removeMoneyMask(conjuge.despesasMensais)
            conjuge.capacidadePoupanca.estimativa = removeMoneyMask(conjuge.capacidadePoupanca.estimativa)
          }

          const experienciaFinanceira = { usuario, conjuge }
          const perfil = await updatePerfil(this.profileId, { experienciaFinanceira, step: 6 })

          await this.$store.dispatch('setPerfil', perfil)
          this.$bus.$emit("nextProfileStep")
        }
      } catch (err) {
        this.$noty.error("Desculpe, não foi possível salvar seus dados. Tente novamente mais tarde.");

        if (process.env.NODE_ENV !== "production") {
          console.error(err);
        }
      } finally {
        this.loader = false
      }
    },
    stepBack() {
      this.$bus.$emit("backProfileStep");
    }
  },
  mounted() {
    this.copyData()
  }
};
</script>

<style scoped>
@media only screen and (max-width: 475px) {
  .v-card__actions.submit-buttons {
    display: flex;
    flex-direction: column-reverse !important;
    text-align: center;
  }

  .v-card__actions.submit-buttons .v-btn {
    display: block;
    margin: 0 0 10px 0 !important;
    width: 100%;
  }
}
</style>
