import firebase from 'firebase/app';
import httpClient from "../../plugins/httpClient";

const deleteAplicacaoIndFinanceira = (id, aplicacaoId) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'DELETE';
          let headers = {Authorization: `Bearer ${idToken}`};
          let url = `/independencia-financeira/${id}/aplicacoes/${aplicacaoId}`;

          return httpClient(url, {headers, method});
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch (err) {
      reject(err);
    }
  });
}

export default deleteAplicacaoIndFinanceira;
