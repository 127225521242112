import firebase from 'firebase/app';
import httpClient from '../../plugins/httpClient';

/**
 * Buscar determinado usuário pelo ID
 * @param userId
 * @returns {Promise<unknown>}
 */
const getUserById = userId =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        return httpClient(`/users/${userId}`, { headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });

export default getUserById;
