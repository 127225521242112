<template>
  <v-app>
    <BottomMenu v-if="width < 960 && user && user.role === 'customer' && showMenuRoute" />
    <NavigationDrawer v-if="user && user.role === 'customer' && showMenuRoute"/>

    <CompanyToolbar
      class="hidden-sm-and-down"
      v-if="user && ['planner', 'company'].includes(user.role)"
    />

    <CompanyMobileMenu
      class="hidden-md-and-up"
      v-if="user && ['planner', 'company'].includes(user.role)"
    />

    <CompanyNavigationDrawer v-if="user && ['planner', 'company'].includes(user.role)"/>

    <MenuAdmin v-if="user && ['admin', 'support', 'comercial', 'supervisor'].includes(user.role)"/>

    <ButtonForms class="hidden-sm-and-down" v-if="user && user.role !== 'admin'"/>
    <router-view></router-view>


    <DialogCadastro/>
    <DialogResetPassword />
    <DialogForgotPassword />
    <DialogFormRevenue />
    <DialogFormExpense />
    <DialogBankTransfer />
    <DialogFormCreditCard />
    <DialogFormInvestment />
    <DialogFormBankAccount />
    <DialogRemoveTransaction />
    <DialogArchiveCreditCard />
    <DialogUpdateMultipleTransaction />
    <DialogInvoice />
    <FormEmail />
    <DialogFormCreditCardExpense />
    <DialogMobileBankAccountsList />
    <DialogFormPendencia />
    <DialogCreditCardsList />
    <DialogProfile />
    <DialogFormPatrimonio />
    <DialogFormProjetoVida />
    <DialogFatoresPerfil />
    <DialogMenuConfiguracoes />
    <DialogCategorias />
    <DialogNotes />
    <DialogPendenciaEstorno />
    <DialogPendencias />
    <DialogFormPendenciaCartao />
    <DialogFormImportOfx />
    <DialogFormImportPdf />
    <DialogImportTransactionsFromFile/>
    <DialogBlockedUser v-if="user && user.role === 'customer'"/>
    <DialogFormNote v-if="user && user.role !== 'customer'"/>
    <DialogFormCustomer v-if="user && user.role !== 'customer'"/>
    <DialogFormEmailUpdate v-if="user && user.role !== 'customer'"/>
    <DialogUpdateUserStatus v-if="user && user.role !== 'customer'"/>
    <DialogPasswordResetLink v-if="user && user.role !== 'customer'"/>
    <DialogSetupCalendly v-if="user && ['admin', 'supervisor'].includes(user.role)"/>
    <DialogCategoryForm/>
    <DialogCustomerActivity/>
    <DialogTerms/>
    <DialogUnlockCustomer v-if="user && user.role !== 'customer'"/>
    <DialogMeetingsConfirmation v-if="user && !['customer', 'company'].includes(user.role)"/>
    <FormSenha />
    <DialogFormCreateCustomerPassword v-if="user && user.role !== 'customer'"/>
    <DialogSubscriptionSuspended v-if="user && user.role === 'customer'"/>
    <DialogAccessPermission v-if="user && user.role !== 'customer'"/>
    <DialogCustomerSubscription v-if="user && user.role === 'customer'"/>
    <DialogCancelIuguSubscription v-if="user && user.role === 'customer'"/>
    <DialogDeleteFreeUser v-if="user && user.role === 'customer' && user.isFree"/>
    <DialogCompanyPurchaseUrl v-if="user && user.role === 'admin'"/>
    <DialogRecommendationInvites v-if="user && user.role === 'customer'"/>
    <DialogRecommendationRewards v-if="user && user.role === 'customer'"/>
    <DialogRecommendationRegulamento v-if="user && user.role === 'customer'" />
    <BottomSheetShareRecommendation v-if="user && user.role === 'customer'"/>
    <DialogRewardRequest v-if="user && user.role === 'customer'"/>
    <DialogDetailsPendingRewards v-if="user && user.role === 'admin'"/>
    <DialogCustomerInvoiceDetails v-if="user"/>
    <DialogChangeUserCanceledStatus v-if="user && user.role === 'admin'"/>
    <DialogNewClients v-if="user && user.role !== 'customer'"/>
    <DialogFormSeguroVidaPerfil/>
    <DialogFormUser v-if="user && user.role === 'admin'"/>
    <DialogAddAccount v-if="user && ['customer', 'planner'].includes(user.role)"/>
    <DialogPendentCreditCards v-if="user && user.role === 'customer'"/>
    <PluggyWidgetEventListener v-if="user && user.role === 'customer'"/>
  </v-app>
</template>

<script>
import FormSenha from '@/components/Customer/Cadastro/forms/FormSenha';
import FormEmail from '@/components/Customer/Cadastro/forms/FormEmail';
import BottomMenu from '@/components/Customer/menu/bottomMenu';
import NavigationDrawer from '@/components/Customer/menu/navigationDrawer';
import ButtonForms from '@/components/Customer/menu/ButtonForms';
import isMobileTablet from '@/utils/isMobileTablet';
import DialogForgotPassword from './components/Auth/DialogForgotPassword';
import DialogResetPassword from './components/Auth/DialogResetPassword';
import DialogFormRevenue from './components/Customer/Cashflow/dialogs/DialogFormRevenue';
import DialogFormExpense from './components/Customer/Cashflow/dialogs/DialogFormExpense';
import DialogRemoveTransaction from './components/Customer/Cashflow/dialogs/DialogRemoveTransaction';
import DialogUpdateMultipleTransaction from './components/Customer/Cashflow/dialogs/DialogUpdateMultipleTransaction';
import DialogBankTransfer from './components/Customer/Cashflow/dialogs/DialogBankTransfer';
import DialogFormBankAccount from './components/Customer/Cashflow/dialogs/DialogFormBankAccount';
import DialogFormCreditCard from './components/Customer/Cashflow/dialogs/DialogFormCreditCard';
import DialogArchiveCreditCard from './components/Customer/Cashflow/dialogs/DialogArchiveCreditCard';
import DialogFormInvestment from './components/Customer/Cashflow/dialogs/DialogFormInvestment';
import DialogInvoice from './components/Customer/Cashflow/dialogs/DialogInvoice';
import DialogFormCreditCardExpense from './components/Customer/Cashflow/dialogs/DialogFormCreditCardExpense';
import isIOS from './utils/isIOS';
import DialogMobileBankAccountsList from './components/Customer/Cashflow/dialogs/DialogMobileBankAccountsList';
import DialogFormPendencia from './components/Customer/Cashflow/dialogs/DialogFormPendencia';
import DialogCreditCardsList from './components/Customer/Cashflow/dialogs/DialogCreditCardsList';
import DialogProfile from './components/Customer/Perfil/dialogs/DialogProfile';
import getPerfil from './api/perfil/getPerfil';
import DialogFormPatrimonio from './components/Customer/Perfil/dialogs/DialogFormPatrimonio';
import DialogFormProjetoVida from './components/Customer/IndFinanceira/dialogs/DialogFormProjetoVida';
import DialogFatoresPerfil from './components/Customer/Perfil/dialogs/DialogFatoresPerfil';
import DialogMenuConfiguracoes from './components/Customer/Configuracoes/dialogs/DialogMenuConfiguracoes';
import DialogCategorias from './components/Customer/Categorias/dialogs/DialogCategorias';
import DialogFormCustomer from './components/Manager/dashboard/forms/DialogFormCustomer';
import DialogFormEmailUpdate from './components/Manager/dashboard/forms/DialogFormEmailUpdate';
import DialogUpdateUserStatus from './components/Manager/dashboard/dialogs/DialogUpdateUserStatus';
import DialogPasswordResetLink from './components/Manager/dashboard/DialogPasswordResetLink';
import DialogNotes from './components/Planner/customer/dialogs/DialogNotes';
import DialogFormNote from './components/Planner/customer/dialogs/DialogFormNote';
import DialogCategoryForm from './components/Customer/Categorias/dialogs/DialogCategoryForm';
import DialogCustomerActivity from './components/Planner/customer/dialogs/DialogCustomerActivity';
import DialogTerms from '@/components/Global/DialogTerms';
import firebase from 'firebase/app';
import DialogPendenciaEstorno from '@/components/Customer/Cashflow/dialogs/DialogPendenciaEstorno';
import DialogPendencias from '@/components/Customer/Cashflow/dialogs/DialogPendencias';
import DialogFormPendenciaCartao from '@/components/Customer/Cashflow/dialogs/DialogFormPendenciaCartao';
import DialogFormImportOfx from '@/components/Customer/Cashflow/dialogs/DialogFormImportOfx';
import DialogFormImportPdf from '@/components/Customer/Cashflow/dialogs/DialogFormImportPdf';
import DialogBlockedUser from '@/components/Global/DialogBlockedUser';
import getUserByUid from '@/api/users/getUserByUid';
import logout from '@/utils/logout';
import DialogSetupCalendly from '@/components/Admin/dialogs/DialogSetupCalendly';
import DialogUnlockCustomer from '@/components/Planner/dialogs/DialogUnlockCustomer';
import DialogMeetingsConfirmation from '@/components/Planner/dialogs/DialogMeetingsConfirmation';
import DialogCustomerInvoiceDetails from '@/components/Customer/Invoice/dialogs/DialogCustomerInvoiceDetails';
import DialogFormCreateCustomerPassword from '@/components/Planner/dialogs/DialogFormCreateCustomerPassword';
import checkIuguSubscriptionStatus from '@/api/iugu/subscriptions/checkIuguSubscriptionStatus';
import DialogSubscriptionSuspended from '@/components/Customer/Global/DialogSubscriptionSuspended';
import CompanyNavigationDrawer from '@/components/Company/CompanyNavigationDrawer';
import CompanyToolbar from '@/components/Company/CompanyToolbar';
import CompanyMobileMenu from '@/components/Company/CompanyMobileMenu';
import MenuAdmin from '@/components/Manager/menu/MenuAdmin';
import DialogAccessPermission from '@/components/Manager/dashboard/dialogs/DialogAcessPermission';
import DialogCustomerSubscription from '@/components/Customer/Global/DialogCustomerSubscription';
import DialogCancelIuguSubscription from '@/components/Customer/Global/DialogCancelIuguSubscription';
import DialogCompanyPurchaseUrl from '@/components/Company/dialogs/DialogCompanyPurchaseUrl';

import DialogRecommendationInvites from '@/components/Customer/Recommendation/dialogs/DialogRecommendationInvites'
import DialogRecommendationRewards from '@/components/Customer/Recommendation/dialogs/DialogRecommendationRewards'
import DialogRecommendationRegulamento from '@/components/Customer/Recommendation/dialogs/DialogRecommendationRegulamento'
import DialogRewardRequest from '@/components/Customer/Recommendation/dialogs/DialogRewardRequest'
import BottomSheetShareRecommendation from '@/components/Customer/Recommendation/bottomSheets/BottomSheetShareRecommendation'
import DialogDetailsPendingRewards from '@/components/Admin/recommendations/dialogs/DialogDetailsPendingRewards';
import DialogChangeUserCanceledStatus from '@/components/Admin/dialogs/DialogChangeUserCanceledStatus';
import DialogNewClients from '@/components/Dashboard/dialogs/DialogNewClients';
import DialogFormSeguroVidaPerfil from '@/components/Customer/Perfil/dialogs/DialogFormSeguroVidaPerfil';
import DialogFormUser from "@/components/Manager/dashboard/forms/DialogFormUser";
import DialogAddAccount from "@/components/Customer/Cashflow/dialogs/DialogAddAccount";
import pluggyWidget from "@/utils/pluggy/pluggyWidget";
import DialogPendentCreditCards from "@/components/Customer/OpenBanking/dialogs/DialogPendentCreditCards";
import DialogCadastro from "@/components/Customer/Global/DialogCadastro";
import DialogDeleteFreeUser from "@/components/Customer/Global/DialogDeleteFreeUser";
import DialogImportTransactionsFromFile from "@/components/Customer/Cashflow/dialogs/DialogImportTransactionsFromFile";
import PluggyWidgetEventListener from "@/components/Customer/OpenBanking/events/PluggyWidgetEventListener";
let perfilInterval;

export default {
  name: 'App',
  data: () => ({
    width: 320,
  }),
  components: {
    PluggyWidgetEventListener,
    DialogImportTransactionsFromFile,
    DialogPendentCreditCards,
    DialogAddAccount,
    DialogDeleteFreeUser,
    DialogCadastro,
    DialogFormUser,
    DialogFormSeguroVidaPerfil,
    DialogNewClients,
    DialogChangeUserCanceledStatus,
    DialogCompanyPurchaseUrl,
    DialogCancelIuguSubscription,
    DialogCustomerSubscription,
    DialogAccessPermission,
    DialogSubscriptionSuspended,
    MenuAdmin,
    CompanyMobileMenu,
    CompanyToolbar,
    CompanyNavigationDrawer,
    ButtonForms,
    NavigationDrawer,
    BottomMenu,
    FormSenha,
    DialogFormCreateCustomerPassword,
    DialogMeetingsConfirmation,
    DialogUnlockCustomer,
    DialogSetupCalendly,
    DialogBlockedUser,
    DialogFormImportOfx,
    DialogFormImportPdf,
    DialogFormPendenciaCartao,
    DialogPendencias,
    DialogPendenciaEstorno,
    DialogTerms,
    FormEmail,
    DialogCustomerActivity,
    DialogCategoryForm,
    DialogFormNote,
    DialogNotes,
    DialogPasswordResetLink,
    DialogUpdateUserStatus,
    DialogFormEmailUpdate,
    DialogFormCustomer,
    DialogCategorias,
    DialogMenuConfiguracoes,
    DialogFatoresPerfil,
    DialogFormProjetoVida,
    DialogFormPatrimonio,
    DialogProfile,
    DialogCreditCardsList,
    DialogFormPendencia,
    DialogMobileBankAccountsList,
    DialogFormCreditCardExpense,
    DialogInvoice,
    DialogFormInvestment,
    DialogArchiveCreditCard,
    DialogFormCreditCard,
    DialogFormBankAccount,
    DialogBankTransfer,
    DialogUpdateMultipleTransaction,
    DialogRemoveTransaction,
    DialogFormExpense,
    DialogFormRevenue,
    DialogResetPassword,
    DialogForgotPassword,
    DialogRecommendationInvites,
    DialogRecommendationRewards,
    DialogRecommendationRegulamento,
    BottomSheetShareRecommendation,
    DialogRewardRequest,
    DialogDetailsPendingRewards,
    DialogCustomerInvoiceDetails
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    perfil() {
      return this.$store.getters.perfil;
    },
    showMenuRoute() {
      return !['/politica-privacidade', '/termos-uso'].includes(this.$route.path)
    },
    subscriptionSuspended() {
      return this.$store.getters.subscriptionSuspended
    }
  },
  methods: {
    showCustomerProfileDialog() {
      if (this.user && this.user.role === 'customer') {
        if (!this.user.isUplanner || !this.user.locked) {
          perfilInterval = setInterval(() => {
            if (this.perfil && !this.perfil.completed) {
              this.$bus.$emit('showDialogProfile');
            } else if (this.perfil && this.perfil.completed) {
              clearInterval(perfilInterval);
            }
          }, 1000);
        } else if (this.user.isUplanner && this.user.locked) {
          this.$bus.$emit('showDialogBlockedUser')
        }
      }
    },
    getPerfil() {
      if (this.user && this.user.role === 'customer') {
        getPerfil(this.user._id).then((perfil) => {
          this.$store.dispatch('setPerfil', perfil);
        });
      }
    },
    getUser() {
      if (firebase.auth().currentUser) {
        getUserByUid(firebase.auth().currentUser.uid)
          .then((user) => {
            this.$store.dispatch('setUser', user);

            this.$store.dispatch('setIsMobile', isMobileTablet());
            this.getPerfil();
            this.showCustomerProfileDialog();

            this.$bus.$on('closeDialogProfile', () => {
              clearInterval(perfilInterval);
            });
          })
          .catch((err) => {
            if (process.env.NODE_ENV !== `production`) {
              console.error(err);
            }

            logout();
          });
      }
    },
    getSubscriptionStatus() {
      if (
        this.user &&
        this.user.role === 'customer' &&
        this.user.iuguSubscription
      ) {
        checkIuguSubscriptionStatus(this.user.iuguSubscription)
          .then(resp => {
            this.$store.dispatch('setSubscriptionSuspended', resp.suspended)
          })
          .catch(err => {
            this.$store.dispatch('setSubscriptionSuspended', false)
          })
      } else {
        this.$store.dispatch('setSubscriptionSuspended', false)
      }
    }
  },
  watch: {
    user() {
      this.getPerfil();
      this.showCustomerProfileDialog();
      // this.getSubscriptionStatus()
    },
    perfil() {
      if (this.perfil && !this.perfil.completed) {
        this.showCustomerProfileDialog();
      }
    }
  },
  beforeCreate() {
    this.$vuetify.theme.dark = true;
  },
  created() {
    this.getUser();
    let self = this;
    this.width = window.innerWidth;
    window.addEventListener('resize', function () {
      self.width = window.innerWidth;
    });

    this.$bus.$on('showNoty', ({type, message}) => {
      this.$noty[type](message)
    })
  },
  mounted() {
    // this.getSubscriptionStatus()

    let query = this.$route.query;

    if (query.mode === 'resetPassword') {
      this.$bus.$emit('showDialogResetPassword', query);
    }

    this.$store.dispatch('setIsIOS', isIOS());

    let self = this;

    if (navigator.splashscreen) navigator.splashscreen.hide();

    window.addEventListener('resize', function () {
      self.$store.dispatch('setIsMobile', isMobileTablet());
      self.$store.dispatch('setIsIOS', isIOS());
    });

    pluggyWidget.loadScript()
  },
};
</script>

<style>
@import "./styles/credit-cards.css";
@import './styles/recommendation-style.css';

.v-application {
  /* padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); */
}

.noty_body {
  font-family: Roboto, san-serif !important;
}

.noty_theme__sunset.noty_bar {
  margin-top: 0 !important;
  padding-top: 8px !important;
  padding-bottom: 7px !important;
}

@supports (-webkit-touch-callout: none) {
  .noty_theme__sunset.noty_bar {
    padding-top: 40px !important;
  }
}

.loader-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.v-card {
  border-radius: 5px !important;
  transition: all 0.7s !important;
}

.v-application .v-card.elevation-12 {
  webkit-box-shadow: 0 3px 5px -1px rgba(161, 165, 175, 0.2),
    0 6px 10px 0 rgba(161, 165, 175, 0.14),
    0 1px 18px 0 rgba(161, 165, 175, 0.12) !important;
  box-shadow: 0 3px 5px -1px rgba(161, 165, 175, 0.2),
    0 6px 10px 0 rgba(161, 165, 175, 0.14),
    0 1px 18px 0 rgba(161, 165, 175, 0.12) !important;
}

.customer-navigation-drawer .v-navigation-drawer__content::-webkit-scrollbar {
  width: 0 !important;
}

@media only screen and (max-width: 959px) {
  .noty_layout {
    width: 100% !important;
    padding-top: env(safe-area-inset-top) !important;
  }

  #noty_layout__topRight {
    top: 0 !important;
    right: 0 !important;
    max-width: 959px !important;
    padding-top: calc(5px + env(safe-area-inset-top)) !important;
  }
}
</style>
