<template>
  <v-card flat color="grey2">
    <v-card-text>
      <v-form @submit.prevent="getAdminCategories">
        <v-layout row wrap>
          <v-flex lg4 md4 sm6 xs12>
            <v-text-field
              color="grey1"
              label="Pesquisar"
              v-model="filter.search"
            ></v-text-field>
          </v-flex>

          <v-flex lg2 md2 sm6 xs12>
            <v-btn
              rounded
              dark
              depressed
              color="primary"
              type="submit"
              class="mt-2 mr-2 darkgrey--text"
            >
              <v-icon>mdi-search-web</v-icon>
            </v-btn>

            <v-btn
              dark
              rounded
              depressed
              type="button"
              color="primary"
              class="mt-2 darkgrey--text"
              @click.stop.prevent="showDialogFormAdminCategory"
            >
              <v-icon>mdi-playlist-plus</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>

      <TableCategories class="mt-5" />
    </v-card-text>
  </v-card>
</template>

<script>
import TableCategories from '../tables/TableCategories';
export default {
  name: 'CardTableCategories',
  components: { TableCategories },
  data: () => ({
    types: [
      { text: 'Todos', value: '' },
      { text: 'Despesa', value: 'despesa' },
      { text: 'Receita', value: 'receita' }
    ],
    filter: {
      search: '',
      type: ''
    }
  }),
  methods: {
    getAdminCategories() {
      this.$bus.$emit('getAdminCategories', this.filter);
    },
    showDialogFormAdminCategory() {
      this.$bus.$emit('showDialogFormAdminCategory');
    }
  },
  mounted() {
    this.getAdminCategories();
  }
};
</script>
