<template>
  <v-container grid-list-md class="pa-0 ma-0">
    <v-layout row wrap class="pa-0 ma-0">
      <v-flex xs12>
        <span class="input-label primary--text">{{ labelBankAccount }}</span>
        <v-select
          dense
          color="grey1"
          item-text="name"
          item-value="_id"
          class="mt-0 pt-0"
          :items="bankAccounts"
          v-model="selectedBankAccount"
          :error-messages="bankAccountErrors"
        ></v-select>
      </v-flex>

      <v-expand-transition>
        <v-flex
          xs12
          v-if="selectedBankAccountData && selectedBankAccountData.pluggy"
        >
          <h2 class="text-center" v-if="!transactions.length">
            Nenhuma transação encontrada
          </h2>

          <div v-if="transactions.length">
            <span class="input-label primary--text mb-5">
              Selecione a transação que representa a {{ transferType }} na conta selecionada
            </span>

            <div class="tr-list mt-3">
              <v-card
                class="mb-2"
                :key="tr._id"
                v-for="tr in transactions"
                @click.stop="selectedTransaction = tr._id"
                :color="selectedTransaction === tr._id ? 'primary' : 'darkgrey'"
              >
                <v-card-text>
                  <div>
                    <div
                      class="tr-list_item-label"
                      :class="selectedTransaction === tr._id ? 'darkgrey--text' : 'white--text'"
                    >
                      {{ tr.description }}
                    </div>

                    <div
                      class="tr-list_item-label mt-1"
                      :class="selectedTransaction === tr._id ? 'darkgrey--text' : 'primary--text'"
                    >
                      {{ numberToCurrency(tr.value) }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-flex>
      </v-expand-transition>
    </v-layout>
  </v-container>
</template>

<script>
import numberToCurrency from "@/utils/numberToCurrency";
import removeMoneyMask from "@/utils/removeMoneyMask";
import getUserTransactions from "@/api/transactions/getUserTransactions";

export default {
  name: 'FormTransferencia',
  props: ['type', 'bankAccount', 'period', 'originValue', 'originBankAccount', 'destinyBankAccount'],
  data: () => ({
    numberToCurrency,
    selectedBankAccount: null,
    selectedTransaction: null,
    transactionsLoader: false,
    currentPeriod: null,
  }),
  validations: {
    selectedBankAccount: {
      isValid(v) {
        return !!v && v !== this.bankAccount
      }
    },
    selectedTransaction: {
      isValid(v) {
        return !this.selectedBankAccountData || !this.selectedBankAccountData.pluggy || !!v
      }
    },
  },
  computed: {
    bankAccountErrors() {
      if (!this.$v.selectedBankAccount.$anyDirty)
        return []

      if (this.$v.selectedBankAccount.$anyError) {
        return ['Selecione uma conta válida']
      }
    },
    transactionErrors() {
      if (!this.$v.selectedTransaction.$anyDirty)
        return []

      if (this.$v.selectedTransaction.$anyError) {
        return ['Selecione uma transação válida']
      }
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts.filter(ba => {
        return !ba.archived && ba._id !== this.bankAccount
      })
    },
    transferType() {
      return this.type === 'entrada' ? 'saída' : 'entrada'
    },
    selectedBankAccountData() {
      if (this.selectedBankAccount) {
        return this.$store.getters.bankAccounts.find(ba => ba._id === this.selectedBankAccount)
      }
      return null
    },
    selectedTransactionData() {
      return this.transactions.find(tr => tr._id === this.selectedTransaction)
    },
    labelBankAccount() {
      if (this.type === 'entrada') {
        return 'Conta de origem'
      } else {
        return 'Conta de destino'
      }
    },
    transactions() {
      if (this.selectedBankAccountData && this.selectedBankAccountData.pluggy) {
        return this.$store.getters.transactions.filter(tr => {
          return (
            !tr.invoice &&
            !tr.isTransfer &&
            !tr.investment &&
            tr.type !== this.type &&
            tr.value === removeMoneyMask(this.originValue) &&
            tr.bankAccount._id === this.selectedBankAccount &&
            tr.date.slice(0, 7) === this.period
          )
        })
      }

      return []
    },
    user() {
      return this.$store.getters.user.role === 'CUSTOMER' ? this.$store.getters.user : this.$store.getters.customer
    }
  },
  methods: {
    setBankAccount(bankAccount) {
      this.selectedBankAccount = bankAccount
    },
    setTransaction(transaction) {
      this.selectedTransaction = transaction
    },
    async getTransactions() {
      if (this.period !== this.currentPeriod) {
        const transactions = await getUserTransactions(this.user._id, this.period)
        await this.$store.dispatch('setTransactions', transactions)
      }
    },
  },
  watch: {
    transactions() {
      if (this.transactions.length === 1) {
        this.setTransaction(this.transactions[0]._id)
      }
    },
    bankAccount() {
      this.getT
    }
  },
  mounted() {
    this.currentPeriod = this.$date().format('YYYY-MM')
    this.getTransactions()

    if (this.originBankAccount) {
      this.selectedBankAccount = this.originBankAccount
    } else if (this.destinyBankAccount) {
      this.selectedBankAccount = this.destinyBankAccount
    }
  }
}
</script>

<style scoped>
.tr-list {
  display: flex;
  flex-direction: column;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
}

.tr-list_item-label {
  font-size: 12px;
  line-height: 1em;
}
</style>
