<template>
  <v-card flat class="pa-0" color="transparent">
    <v-card-text class="pa-0">
      <v-container grid-list-md class="pa-0">
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-percent</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Taxa bruta anual</span>
                <span class="item-data-value white--text">{{ taxas.taxaBrutaAnual.toFixed(2) }}%</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-percent</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Taxa bruta mensal</span>
                <span class="item-data-value white--text">{{ taxas.taxaBrutaMensal.toFixed(2) }}%</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-percent</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Alíquota IR</span>
                <span class="item-data-value white--text">{{ taxas.aliquotaIr.toFixed(2) }}%</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-percent</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Inflação</span>
                <span class="item-data-value white--text">{{ taxas.inflacao.toFixed(2) }}%</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-percent</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Taxa líquida anual</span>
                <span class="item-data-value white--text">{{ taxas.taxaLiquidaAnual.toFixed(2) }}%</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-percent</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Taxa líquida mensal</span>
                <span class="item-data-value white--text">{{ taxas.taxaLiquidaMensal.toFixed(2) }}%</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 class="mt-6">
            <v-card-actions class="px-0 btn-container">
              <v-spacer></v-spacer>

              <v-btn
                rounded
                outlined
                class="px-5"
                color="primary"
                v-if="unlock"
                :disabled="!allowed"
                @click="showDialogFormTaxas"
              >
                Editar
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import moment from 'moment-timezone'
  import numberToCurrency from "../../../../utils/numberToCurrency";

  export default {
    name: 'CardTaxasIndFinanceira',
    props: ['origin'],
    data: () => ({
      currentDate: null,
      numberToCurrency
    }),
    computed: {
      allowed() {
        return this.$store.getters.allowed;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      taxas() {
        if (this.indFinanceira && this.origin)
          return this.indFinanceira[this.origin];
      },
      unlock() {
        return this.indFinanceira.period === this.currentDate.format('YYYY-MM');
      }
    },
    methods: {
      showDialogFormTaxas() {
        if (this.origin)
          this.$bus.$emit('showDialogFormTaxas', this.origin);
      }
    },
    created() {
      this.currentDate = moment().tz('America/Sao_Paulo');
    }
  }
</script>

<style scoped>
  .item {
    border-bottom: solid 2px #919191;
    display: flex;
    align-items: flex-end;
  }

  .item-icon {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .item-data {
    display: flex;
    flex-direction: column;
  }

  .item-data-label {
    font-size: 9px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .item-data-value {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .1em;
  }

  @media only screen and (max-width: 390px) {
    .btn-container {
      display: flex;
      flex-direction: column;
    }

    .btn-container .v-btn {
      margin-bottom: 5px;
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @media only screen and (min-width: 600px) {
    .item-data-label {
      font-size: 12px;
    }

    .item-data-value {
      font-size: 15px;
    }
  }

  @media only screen and (min-width: 960px) {
    .v-icon.item-icon {
      font-size: 20px !important;
    }

    .item-data-value {
      font-size: 16px;
    }
  }
</style>
