<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-card class="no-radius safe-area-bottom">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          @click.stop="dialog = false"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="ml-2"> Regulamento</span>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-card color="grey2" class="mt-5 mb-5" width="100%">
              <v-flex class="ml-2 mt-5">
                <span class="primary--text text-uppercase font-weight-bold">Convites: </span>
              </v-flex>
              <v-flex class="ml-2 mt-5">
            <span>
              <p>
                Você pode compartilhar seu código com quantas pessoas desejar.
                É possível visualizar quantas indicações você fez e quantas foram
                aceitas por seus amigos e colegas.
              </p>
              <p>
                1. Os convites aceitos são os convites utilizados no momento da compra de
                um plano Uplanner, por uma indicação sua.
              </p>
              <p>
                2. Um convite é considerado como pendente quando você comparilha seu
                código com alguém e a pessoa apenas inicia o cadastro no site da Uplanner,
                mas não finaliza a compra. Dessa forma, você verá sua indicação como “Pendente” ou "Visualizado".
              </p>
              <p>
                Após sua indicação efetuar o cadastro, escolher um plano,
                confirmar o pagamento, você verá a indicação como “Convite Confirmado”.
              </p>
            </span>
              </v-flex>
            </v-card>
          </v-layout>

          <v-layout row wrap>
            <v-card color="grey2" class="mt-5 mb-5" width="100%">
              <v-flex class="ml-2 mt-5">
                <span class="primary--text text-uppercase font-weight-bold">Recompensas: </span>
              </v-flex>
              <v-flex class="ml-2 mt-5">
            <span>
              <p class="primary--text">Como funciona?</p>
              <p>A cada compra feita usando seu código, você ganha uma recompensa na Uplanner. </p>
              <p>
                1. Ao indicar um amigo e este se cadastrar utilizando seu código,
                você ganha 1 (um) mês grátis na Uplanner e a pessoa indicada ganha 10%
                de desconto na compra de qualquer plano.
              </p>
              <p>
                2. Para quem indica, as recompensas são cumulativas.
                Por exemplo: você pode indicar 5 (cinco) amigos e terá 5 (cinco) meses gratuitos.
              </p>
              <p>
                3. Para indicados, os descontos não são cumulativos, ou seja,
                não é possível ser indicado por duas pessoas. É permitido utilizar somente 1 (um)
                código de indicação no momento da contratação de um plano Uplanner.
              </p>
              <p class="primary--text">Como posso usufruir das recompensas?</p>
              <p>
                Sempre que uma compra for realizada e um recompensa for ganha, será utilizada automaticamente. Ou seja, seus descontos serão aplicados automaticamente para o mês seguinte.
              </p>
              <p>Para compras anuais, se paga com cartão de crédito haverá um reembolso no valor correspondente a um mês. Caso tenha pago com boleto bancário, deverá entrar em contato na central de atendimento, selecionar a opção de "Recompensa Plano Anual pago em boleto" e encaminhar os dados bancários para o reembolso. A conta bancária deve estar em seu nome.</p>
            </span>
              </v-flex>
            </v-card>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogRecommendationRegulamento',
  data: () => ({
    dialog: false
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    }
  },
  created() {
    this.$bus.$on('showDialogRecommendationRegulamento', () => {
      this.dialog = true;
    });
  }
};
</script>

<style scoped>
.container {
  max-width: 600px !important;
}

p {
  line-height: 1.5em;
}

.dialog-form-title {
  position: fixed;
  display: block;
  width: 100%;
  z-index: 2;
  top: 0;
}

.v-card__text {
  margin-top: 58px !important;
}
</style>
