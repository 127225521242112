<template>
  <div class="chart-card">
    <h1 class="chart-card-title primary--text">Ativos</h1>

    <apexchart
      type="bar"
      :options="options"
      :series="series"
      width="100%"
      height="350"
    ></apexchart>

    <div class="chart-card-legends mt-2">
      <div
        v-for="(item, index) in legends"
        :key="index" class="list-item"
      >
        <div
          class="legend-icon mr-2"
          :style="`background-color: ${item.color};`"
        ></div>

        <div class="legend-values">
          <span class="legend-values--desc">{{ item.text }}</span>
          <span class="legend-values--desc grey1--text">{{ item.desc }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';
  import numberToCurrency from "../../../utils/numberToCurrency";

  export default {
    name: 'CardAtivosMensal',
    props: ['labels', 'transactions'],
    components: { apexchart: VueApexCharts },
    data: () => ({
      options: {
        colors: ['#D1AC68', '#ccc'],
        labels: [],
        chart: {
          toolbar: { show: false },
          background: 'rgba(255,255,255, 0)'
        },
        dataLabels: { enabled: false },
        legend: { show: false },
        grid: { show: false },
        stroke: {
          width: [0, 0, 0, 2],
          curve: 'smooth'
        },
        xaxis: {
          show: true,
          type: 'category',
          categories: [],
          labels: {
            rotateAlways: true,
            rotate: -45,
            style: {
              colors: '#9E9E9E'
            }
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: v => numberToCurrency(v)
          }
        },
        markers: {
          size: 5,
          colors: 'white',
          strokeWidth: 0
        },
      },
      series: [
        {
          name: 'Disponível',
          type: 'column',
          data: []
        },
        {
          name: 'Uso pessoal',
          type: 'column',
          data: []
        }
      ],
      legends: [
        {
          text: 'Disponível',
          color: '#D1AC68',
          desc: '(caixa e investimentos)'
        },
        {
          text: 'Uso pessoal',
          color: '#ccc',
          desc: '(móveis e imóveis)'
        }
      ]
    }),
    methods: {
      buildChart() {
        let options = JSON.parse(JSON.stringify(this.options));
        let series = [
          {
            name: 'Disponível',
            type: 'column',
            data: []
          },
          {
            name: 'Uso pessoal',
            type: 'column',
            data: []
          }
        ];
        options.xaxis.categories = this.labels;

        this.transactions.forEach((item, i) => {
          series[0].data[i] = item.disponivel;
          series[1].data[i] = item.usoPessoal;
        });

        options.yaxis = {
          labels: {
            show: false
          }
        }

        this.options = options;
        this.series = series;
      }
    },
    watch: {
      transactions: {
        deep: true,
        handler() {
          this.buildChart();
        }
      }
    },
    mounted() {
      this.buildChart();
    }
  }
</script>

<style scoped>
.chart-card-legends {
  height: 88px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.chart-card-legends::-webkit-scrollbar {
  width: 4px;
}

.chart-card-legends::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.chart-card-legends::-webkit-scrollbar-thumb {
  background: #888;
}

.list-item {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 8px;
}

.legend-icon {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.legend-values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.legend-values--desc {
  font-size: 10px;
  line-height: 1.2em;
  letter-spacing: .1em;
}
</style>

