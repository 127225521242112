import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const getUserPatrimonio = userId => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let url = `/patrimonio/users/${userId}`;
          let headers = { Authorization: `Bearer ${idToken}` };

          return httpClient(url, { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default getUserPatrimonio;
