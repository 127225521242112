<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    max-width="500px"
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn icon :disabled="loader" @click.stop="close" class="back-button">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span>Alterar Saldo Inicial</span>
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="input-label primary--text">Saldo inicial</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  v-money="money"
                  class="mt-1 pt-1"
                  id="input-initial-amount-value"
                  v-model="bankAccount.initialAmount"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-cash
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Data inicial</span>
                <v-dialog
                  ref="dialog"
                  v-model="dateMenu"
                  :return-value.sync="bankAccount.initialAmountDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 pt-0"
                      :value="formatDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        color="grey1"
                        class="input-icon"
                        slot="prepend-inner"
                      >
                        mdi-calendar
                      </v-icon>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    color="primary"
                    v-model="bankAccount.initialAmountDate"
                    scrollable
                    locale="pt-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey2" @click="dateMenu = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.dialog.save(bankAccount.initialAmountDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions class="px-0 mt-5">
            <v-spacer></v-spacer>

            <v-btn
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              class="black--text px-4"
            >
              Salvar
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money';
import moment from 'moment-timezone';
import { required } from 'vuelidate/lib/validators';

import removeMoneyMask from '../../../../utils/removeMoneyMask';
import updateInitialAmount from '../../../../api/bankAccounts/updateInitialAmount';

const isZero = (v) => removeMoneyMask(v) > 0;

export default {
  name: 'DialogFormInitialAmount',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    bankAccount: {
      _id: '',
      initialAmount: 0,
      initialAmountDate: '',
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    },
    dateMenu: false,
    today: '',
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.bankAccount = {
        _id: '',
        initialAmount: 0,
        initialAmountDate: '',
      };
      this.resetMoneyInput();
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        let bankAccount = JSON.parse(JSON.stringify(this.bankAccount));

        bankAccount.initialAmount = removeMoneyMask(bankAccount.initialAmount);
        bankAccount.initialAmountDate = bankAccount.initialAmountDate.split('/').reverse().join('-');

        delete bankAccount.history;
        delete bankAccount.pass;

        updateInitialAmount(bankAccount._id, bankAccount)
          .then((savedAccount) => {
            this.$store.dispatch('saveBankAccount', savedAccount);
            this.close();
            this.$noty.success('Saldo alterado com sucesso!');
          })
          .catch((err) => {
            if (process.env.NODE_ENV !== 'production') console.error(err);
            this.loader = false;
            this.$noty.error(
              'Desculpe! Não foi possível alterar o saldo inicial desta conta. Tente novamente!'
            );
          });
      }
    },
    resetMoneyInput() {
      let input = document.getElementById('input-initial-amount-value');

      if (input) {
        input.value = this.bankAccount.initialAmount;
        input.dispatchEvent(new Event('input'));
      }
    },
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    formatDate() {
      if (this.bankAccount.initialAmountDate)
        return moment(this.bankAccount.initialAmountDate).format('DD/MM/YYYY');
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    initialAmountDateErrors() {
      const errors = [];
      if (!this.$v.bankAccount.initialAmountDate.$dirty) return errors;
      !this.$v.bankAccount.initialAmountDate.required &&
        errors.push('Selecione a data referente ao saldo inicial');
      return errors;
    },
  },
  validations: {
    bankAccount: {
      initialAmountDate: { required },
    },
  },
  created() {
    this.$bus.$on('showDialogFormInitialAmount', (bankAccount) => {
      if (bankAccount) {
        this.today = this.$date().format('YYYY-MM-DD');
        this.bankAccount = JSON.parse(JSON.stringify(bankAccount));
        this.bankAccount.initialAmount = this.bankAccount.initialAmount.toFixed(2);
        this.resetMoneyInput();
        
        this.dialog = true;
      }
    });
  },
};
</script>

<style scoped>
.v-card__title {
  background-color: #131313 !important;
}

.v-card__title > span {
  font-size: 15px !important;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.v-btn.v-size--default {
  font-size: 11px !important;
}

@media only screen and (min-width: 600px) {
  .v-btn.back-button .v-btn__content i {
    font-size: 50px !important;
  }

  .v-card__title > span {
    font-size: 20px !important;
    margin-left: 40px !important;
  }
}
</style>
