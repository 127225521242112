<template>
  <v-dialog
    persistent
    fullscreen
    v-model="dialog"
  >
    <v-card class="no-radius">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="white"
          class="mr-2"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="white--text">{{ company._id ? 'Editar' : 'Adicionar' }} Empresa</span>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12 class="mt-3">
              <h1>Dados da Empresa</h1>
            </v-flex>

            <v-flex xs12 md6>
              <span class="input-label primary--text">Razão Social</span>
              <v-text-field
                type="text"
                class="mt-0 pt-0"
                color="grey1"
                v-model="company.razaoSocial"
                :error-messages="razaoSocialErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md6>
              <span class="input-label primary--text">Nome fantasia</span>
              <v-text-field
                type="text"
                class="mt-0 pt-0"
                color="grey1"
                v-model="company.displayName"
                :error-messages="displayNameErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs3 sm3 md2>
              <span class="input-label primary--text">Código do país</span>
              <v-text-field
                type="tel"
                class="mt-0 pt-0"
                color="grey1"
                v-model="company.phoneCountryCode"
                :error-messages="phoneCountryCodeErrors"
                prefix="+"
                v-mask="'#####'"
              ></v-text-field>
            </v-flex>

            <v-flex xs9 sm3 md4>
              <span class="input-label primary--text">Celular</span>
              <v-text-field
                type="tel"
                class="mt-0 pt-0"
                color="grey1"
                v-model="company.phoneNumber"
                :error-messages="phoneNumberErrors"
                v-mask="'#####################'"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6>
              <span class="input-label primary--text">CNPJ</span>
              <v-text-field
                type="tel"
                class="mt-0 pt-0"
                color="grey1"
                v-model="company.cpf_cnpj"
                :error-messages="cpf_cnpjErrors"
                v-mask="'##.###.###/####-##'"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="mt-2">
              <h2>Endereço</h2>
            </v-flex>

            <v-flex xs12 sm12 md3>
              <span class="input-label primary--text">CEP</span>
              <v-text-field
                type="tel"
                class="mt-0 pt-0"
                color="grey1"
                v-model="address.cep"
                :error-messages="cepErrors"
                v-mask="'#####-###'"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text">Logradouro</span>
              <v-text-field
                type="text"
                class="mt-0 pt-0"
                color="grey1"
                v-model="address.logradouro"
                :error-messages="logradouroErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md3>
              <span class="input-label primary--text">Número</span>
              <v-text-field
                type="number"
                class="mt-0 pt-0"
                color="grey1"
                v-model.number="address.numero"
                :error-messages="numeroErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md4>
              <span class="input-label primary--text">Bairro</span>
              <v-text-field
                type="text"
                class="mt-0 pt-0"
                color="grey1"
                v-model="address.bairro"
                :error-messages="bairroErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md4>
              <span class="input-label primary--text">Estado</span>
              <v-select
                :items="estados"
                return-object
                item-text="nome"
                class="mt-0 pt-0"
                color="grey1"
                v-model="address.estado"
                :error-messages="estadoErrors"
              ></v-select>
            </v-flex>

            <v-flex xs12 sm12 md4>
              <span class="input-label primary--text">Cidade</span>
              <v-autocomplete
                :items="cities"
                item-text="nome"
                item-value="nome"
                class="mt-0 pt-0"
                color="grey1"
                v-model="address.cidade"
                :error-messages="cidadeErrors"
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12>
              <span class="input-label primary--text">Complemento</span>
              <v-text-field
                type="text"
                class="mt-0 pt-0"
                color="grey1"
                v-model="address.complemento"
                :error-messages="complementoErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="mt-5">
              <h1>Dados Colab</h1>
            </v-flex>

            <v-flex xs12 md6>
              <span class="input-label primary--text">Tipo de contrato</span>
              <v-select
                :items="billingTypes"
                item-value="value"
                item-text="text"
                class="mt-0 pt-0"
                color="grey1"
                v-model="companyPayment.billingType"
                :error-messages="billingTypeErrors"
                @change="setupPrices(); dispatchMoneyInputs();"
              ></v-select>
            </v-flex>

            <v-flex xs12 md3>
              <span class="input-label primary--text">Dia de vencimento da fatura</span>
              <v-text-field
                type="number"
                class="mt-0 pt-0"
                color="grey1"
                v-model.number="companyPayment.invoice.expireDate"
                :error-messages="expireDateErrors"
                prefix="Todo dia "
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md3>
              <v-checkbox
                v-model="companyPayment.hasTrial"
                label="Possui trial"
                color="primary"
              ></v-checkbox>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <span class="input-label primary--text">Valor pago pela empresa</span>
              <v-text-field
                type="tel"
                class="mt-0 pt-0"
                color="grey1"
                v-model="companyPayment.values.companyValueIndividual"
                :error-messages="companyValueIndividualErrors"
                v-money="money"
                id="companyValueIndividual"
                hint="Plano individual"
                persistent-hint
                :disabled="companyPayment.billingType === 'no-payment'"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <span class="input-label primary--text">Valor pago pela empresa</span>
              <v-text-field
                type="tel"
                class="mt-0 pt-0"
                color="grey1"
                v-model="companyPayment.values.companyValueFamily"
                :error-messages="companyValueFamilyErrors"
                v-money="money"
                id="companyValueFamily"
                hint="Plano familiar"
                persistent-hint
                :disabled="companyPayment.billingType === 'no-payment'"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <span class="input-label primary--text">Valor pago pelo colaborador</span>
              <v-text-field
                type="tel"
                class="mt-0 pt-0"
                color="grey1"
                v-model="companyPayment.values.employeeInvidualValue"
                :error-messages="employeeInvidualValueErrors"
                v-money="money"
                id="employeeInvidualValue"
                hint="Plano individual"
                persistent-hint
                :disabled="companyPayment.billingType === 'full-payment'"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <span class="input-label primary--text">Valor pago pelo colaborador</span>
              <v-text-field
                type="tel"
                class="mt-0 pt-0"
                color="grey1"
                v-model="companyPayment.values.employeeFamilyValue"
                :error-messages="employeeFamilyValueErrors"
                v-money="money"
                id="employeeFamilyValue"
                hint="Plano familiar"
                persistent-hint
                :disabled="companyPayment.billingType === 'full-payment'"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md6 class="mt-4">
              <h2 class="mb-3">E-mails adicionais que receberão a fatura</h2>

              <div class="d-flex flex-column">
                <div
                  class="d-flex align-center"
                  v-for="(item, i) in companyPayment.invoice.emails"
                  :key="i"
                >
                  <div class="mr-3" style="flex-grow: 1">
                    <span class="input-label grey1--text">E-mail</span>
                    <v-text-field
                      type="email"
                      class="mt-0 pt-0"
                      v-model="item.email"
                      color="grey1"
                    ></v-text-field>
                  </div>

                  <v-btn
                    fab
                    dark
                    small
                    color="error"
                    @click="removeEmail(i)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>

                <div class="text-right">
                  <v-btn
                    small
                    rounded
                    color="grey1"
                    class="darkgrey--text px-5"
                    @click="addEmail"
                  >
                    Adicionar outro
                  </v-btn>
                </div>
              </div>
            </v-flex>

            <v-flex xs12 md6 class="mt-5">
              <h2 class="mb-3">Whatsapps adicionais que receberão a fatura</h2>

              <div class="d-flex flex-column">
                <div
                  class="d-flex align-center"
                  v-for="(item, i) in companyPayment.invoice.phones"
                  :key="i"
                >
                  <div class="mr-3" style="width: 30%">
                    <span class="input-label grey1--text">Código do país</span>
                    <v-text-field
                      type="tel"
                      class="mt-0 pt-0"
                      v-model="item.countryCode"
                      color="grey1"
                      prefix="+"
                      v-mask="'#####'"
                    ></v-text-field>
                  </div>

                  <div class="mr-3" style="flex-grow: 1">
                    <span class="input-label grey1--text">Whatsapp</span>
                    <v-text-field
                      type="tel"
                      class="mt-0 pt-0"
                      v-model="item.phone"
                      color="grey1"
                      v-mask="'####################'"
                    ></v-text-field>
                  </div>

                  <v-btn
                    fab
                    dark
                    small
                    color="error"
                    @click="removePhone(i)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>

                <div class="text-right">
                  <v-btn
                    small
                    rounded
                    color="grey1"
                    class="darkgrey--text px-5"
                    @click="addPhone"
                  >
                    Adicionar outro
                  </v-btn>
                </div>
              </div>
            </v-flex>

            <v-flex xs12 class="mt-5" v-if="!company._id">
              <h1>Dados de acesso</h1>
            </v-flex>

            <v-flex xs12 md6 v-if="!company._id">
              <span class="input-label primary--text">E-mail</span>
              <v-text-field
                type="email"
                class="mt-0 pt-0"
                color="grey1"
                v-model="login.email"
                :error-messages="emailErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md3 v-if="!company._id">
              <span class="input-label primary--text">Senha</span>
              <v-text-field
                type="password"
                class="mt-0 pt-0"
                color="grey1"
                v-model="login.password"
                :error-messages="passwordErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md3 v-if="!company._id">
              <span class="input-label primary--text">Repita a senha</span>
              <v-text-field
                type="password"
                class="mt-0 pt-0"
                color="grey1"
                v-model="login.repeatPassword"
                :error-messages="repeatPasswordErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="d-flex justify-end pt-10">
              <v-btn
                rounded
                outlined
                color="grey1"
                :disabled="loader"
                @click="closeDialog"
                class="px-5 text-capitalize mr-2"
              >
                Cancelar
              </v-btn>

              <v-btn
                rounded
                color="primary"
                @click="submit"
                :loading="loader"
                class="px-5 darkgrey--text text-capitalize"
              >
                Salvar
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>

    <OverlaySync :overlay="companyLoader" text="Buscando dados..."/>
  </v-dialog>
</template>

<script>
import { pick } from 'lodash';
import { VMoney } from 'v-money';
import { isValid as isValidCnpj } from '@fnando/cnpj';
import isEmail from 'validator/lib/isEmail';

import {
  required,
  email,
  minValue,
  maxLength,
  minLength,
  numeric,
  maxValue
} from 'vuelidate/lib/validators';

import removeMoneyMask from '@/utils/removeMoneyMask';
import getUserById from '@/api/users/getUserById';
import getCidadesByEstado from '@/api/address/getCidadesByEstado';
import getConfig from '@/api/config/getConfig';
import OverlaySync from '@/components/Global/OverlaySync';
import createCompany from '@/api/companies/createCompany';
import updateCompany from '@/api/companies/updateCompany';
import getAddressByCep from '@/api/brasil-api/getAddressByCep';
import checkEmailAlreadyUsed from '@/api/users/checkEmailAlreadyUsed';

export default {
  name: 'DialogFormCompany',
  components: { OverlaySync },
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    companyLoader: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    billingTypes: [
      { text: 'Apenas empresa paga', value: 'full-payment' },
      { text: 'Empresa e colaborador pagam', value: 'partial-payment' },
      { text: 'Apenas colaborador paga', value: 'no-payment' }
    ],
    company: {
      displayName: '',
      razaoSocial: '',
      cpf_cnpj: '',
      phoneNumber: '',
      phoneCountryCode: '55'
    },
    address: {
      cep: '',
      numero: '',
      bairro: '',
      logradouro: '',
      cidade: '',
      estado: null,
      complemento: ''
    },
    companyPayment: {
      hasTrial: true,
      billingType: 'full-payment',
      values: {
        companyValueFamily: '',
        companyValueIndividual: '',
        employeeInvidualValue: '',
        employeeFamilyValue: ''
      },
      invoice: {
        expireDate: '',
        emails: [],
        phones: []
      },
      invoiceEmail: '',
      invoicePhone: ''
    },
    login: {
      email: '',
      password: '',
      repeatPassword: ''
    },
    cities: [],
    config: null,
    emailLoader: false,
    emailUsed: false
  }),
  validations: {
    company: {
      displayName: {
        required,
        maxLength: maxLength(150)
      },
      razaoSocial: {
        required,
        maxLength: maxLength(150)
      },
      cpf_cnpj: { required, isValidCnpj },
      phoneNumber: {
        required,
        numeric,
        maxLength: maxLength(20)
      },
      phoneCountryCode: {
        required,
        numeric,
        maxLength: maxLength(5)
      }
    },
    address: {
      cep: {
        required,
        isValid: v => !!v && v.replace(/\D+/g, '').length === 8
      },
      numero: {
        required,
        numeric,
        minValue: minValue(1)
      },
      bairro: {
        required,
        maxLength: maxLength(150)
      },
      logradouro: {
        required,
        maxLength: maxLength(150)
      },
      cidade: {
        required,
        maxLength: maxLength(150)
      },
      estado: { required },
      complemento: { maxLength: maxLength(150) }
    },
    companyPayment: {
      billingType: { required },
      values: {
        companyValueFamily: {
          required,
          isValid: v => removeMoneyMask(v) >= 0
        },
        companyValueIndividual: {
          required,
          isValid: v => removeMoneyMask(v) >= 0
        },
        employeeInvidualValue: {
          required,
          isValid: v => removeMoneyMask(v) >= 0
        },
        employeeFamilyValue: {
          required,
          isValid: v => removeMoneyMask(v) >= 0
        }
      },
      invoice: {
        expireDate: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(31)
        }
      }
    },
    login: {
      email: {
        required,
        email,
        isUsed(v) {
          return !this.emailUsed
        }
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(16)
      },
      repeatPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(16),
        sameAs(v) {
          return v === this.login.password;
        }
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    estados() {
      return this.$store.getters.estados;
    },
    // error messages
    // company
    displayNameErrors() {
      if (!this.$v.company.displayName.$anyDirty) {
        return [];
      }
      if (this.$v.company.displayName.$anyError) {
        return ['Preencha um nome válido'];
      }
    },
    razaoSocialErrors() {
      if (!this.$v.company.razaoSocial.$anyDirty) {
        return [];
      }
      if (this.$v.company.razaoSocial.$anyError) {
        return ['Preencha uma razão social válida'];
      }
    },
    cpf_cnpjErrors() {
      if (!this.$v.company.cpf_cnpj.$anyDirty) {
        return [];
      }
      if (this.$v.company.cpf_cnpj.$anyError) {
        return ['Preencha um CNPJ válido'];
      }
    },
    phoneNumberErrors() {
      if (!this.$v.company.phoneNumber.$anyDirty) {
        return [];
      }
      if (this.$v.company.phoneNumber.$anyError) {
        return ['Preencha um número válido'];
      }
    },
    phoneCountryCodeErrors() {
      if (!this.$v.company.phoneCountryCode.$anyDirty) {
        return [];
      }
      if (this.$v.company.phoneCountryCode.$anyError) {
        return ['Preencha um código válido'];
      }
    },
    // address
    cepErrors() {
      if (!this.$v.address.cep.$anyDirty) {
        return [];
      }
      if (this.$v.address.cep.$anyError) {
        return ['Preencha um CEP válido'];
      }
    },
    numeroErrors() {
      if (!this.$v.address.numero.$anyDirty) {
        return [];
      }
      if (this.$v.address.numero.$anyError) {
        return ['Preencha um número válido'];
      }
    },
    bairroErrors() {
      if (!this.$v.address.bairro.$anyDirty) {
        return [];
      }
      if (this.$v.address.bairro.$anyError) {
        return ['Preencha um bairro válido'];
      }
    },
    logradouroErrors() {
      if (!this.$v.address.logradouro.$anyDirty) {
        return [];
      }
      if (this.$v.address.logradouro.$anyError) {
        return ['Preencha um logradouro válido'];
      }
    },
    cidadeErrors() {
      if (!this.$v.address.cidade.$anyDirty) {
        return [];
      }
      if (this.$v.address.cidade.$anyError) {
        return ['Selecione uma cidade válida'];
      }
    },
    estadoErrors() {
      if (!this.$v.address.estado.$anyDirty) {
        return [];
      }
      if (this.$v.address.estado.$anyError) {
        return ['Selecione um estado válido'];
      }
    },
    complementoErrors() {
      if (!this.$v.address.complemento.$anyDirty) {
        return [];
      }
      if (this.$v.address.complemento.$anyError) {
        return ['Preencha um complemento válido'];
      }
    },
    // companyPayment
    billingTypeErrors() {
      if (!this.$v.companyPayment.billingType.$anyDirty) {
        return [];
      }
      if (this.$v.companyPayment.billingType.$anyError) {
        return ['Selecione um tipo válido'];
      }
    },
    companyValueFamilyErrors() {
      if (!this.$v.companyPayment.values.companyValueFamily.$anyDirty) {
        return [];
      }
      if (this.$v.companyPayment.values.companyValueFamily.$anyError) {
        return ['Preencha um valor válido'];
      }
    },
    companyValueIndividualErrors() {
      if (!this.$v.companyPayment.values.companyValueIndividual.$anyDirty) {
        return [];
      }
      if (this.$v.companyPayment.values.companyValueIndividual.$anyError) {
        return ['Preencha um valor válido'];
      }
    },
    employeeInvidualValueErrors() {
      if (!this.$v.companyPayment.values.employeeInvidualValue.$anyDirty) {
        return [];
      }
      if (this.$v.companyPayment.values.employeeInvidualValue.$anyError) {
        return ['Preencha um valor válido'];
      }
    },
    employeeFamilyValueErrors() {
      if (!this.$v.companyPayment.values.employeeFamilyValue.$anyDirty) {
        return [];
      }
      if (this.$v.companyPayment.values.employeeFamilyValue.$anyError) {
        return ['Preencha um valor válido'];
      }
    },
    expireDateErrors() {
      if (!this.$v.companyPayment.invoice.expireDate.$anyDirty) {
        return [];
      }
      if (this.$v.companyPayment.invoice.expireDate.$anyError) {
        return ['Preencha um dia válido'];
      }
    },
    // login
    emailErrors() {
      if (!this.$v.login.email.$anyDirty) {
        return [];
      }
      if (this.$v.login.email.$anyError) {
        return ['Preencha um e-mail válido'];
      }
    },
    passwordErrors() {
      if (!this.$v.login.password.$anyDirty) {
        return [];
      }
      if (this.$v.login.password.$anyError) {
        return ['Preencha uma senha válida'];
      }
    },
    repeatPasswordErrors() {
      if (!this.$v.login.repeatPassword.$anyDirty) {
        return [];
      }
      if (this.$v.login.repeatPassword.$anyError) {
        return ['Preencha uma senha válida'];
      }
    }
  },
  methods: {
    openDialog(companyId) {
      this.getData(companyId);
      this.dialog = true;
    },
    copyCompanyData(company) {
      this.company = pick(company, [
        '_id',
        'uid',
        'displayName',
        'razaoSocial',
        'phoneNumber',
        'phoneCountryCode',
        'cpf_cnpj',
        'iuguId',
        'idNimbly'
      ]);

      const values = Object.keys(company.companyPayment.values);

      for (let i = 0; i < values.length; i++) {
        company.companyPayment.values[values[i]] = company.companyPayment.values[values[i]].toFixed(2);
      }

      this.companyPayment.values = company.companyPayment.values
      this.companyPayment.invoice = company.companyPayment.invoice
      this.companyPayment.hasTrial = company.companyPayment.hasTrial
      this.companyPayment.billingType = company.companyPayment.billingType

      if (!this.companyPayment.invoice.emails.length) {
        this.addEmail();
      } else {
        this.companyPayment.invoice.emails = this.companyPayment.invoice.emails.map(email => {
          return { email };
        });
      }

      if (!this.companyPayment.invoice.phones.length) {
        this.addPhone();
      }

      this.address = pick(company.address, [
        'cep',
        'numero',
        'bairro',
        'cidade',
        'estado',
        'logradouro',
        'complemento'
      ]);

      this.dispatchMoneyInputs();
    },
    closeDialog() {
      this.dialog = false;
      this.loader = false;

      this.company = {
        displayName: '',
        razaoSocial: '',
        cpf_cnpj: '',
        phoneNumber: '',
        phoneCountryCode: '55'
      };
      this.address = {
        cep: '',
        numero: '',
        bairro: '',
        logradouro: '',
        cidade: '',
        estado: null,
        complemento: ''
      };
      this.companyPayment = {
        hasTrial: true,
        billingType: 'full-payment',
        values: {
          companyValueFamily: '',
          companyValueIndividual: '',
          employeeInvidualValue: '',
          employeeFamilyValue: ''
        },
        invoice: {
          expireDate: '',
          emails: [],
          phones: []
        }
      };
      this.login = {
        email: '',
        password: '',
        repeatPassword: ''
      };

      this.$v.$reset();
    },
    setupPrices() {
      if (this.config && this.config.defaultValues) {
        const { familyMonthly, individualMonthly } = this.config.defaultValues;

        let companyValueFamily = 0
        let companyValueIndividual = 0
        let employeeFamilyValue = 0
        let employeeInvidualValue = 0

        if (this.companyPayment.billingType === 'full-payment') {
          companyValueFamily = familyMonthly.toFixed(2);
          companyValueIndividual = individualMonthly.toFixed(2);
        } else if (this.companyPayment.billingType === 'partial-payment') {
          const familyValue = (familyMonthly / 2).toFixed(2);
          const individualValue = (individualMonthly / 2).toFixed(2);

          companyValueFamily = familyValue;
          companyValueIndividual = individualValue;
          employeeFamilyValue = familyValue;
          employeeInvidualValue = individualValue;
        } else if (this.companyPayment.billingType === 'no-payment') {
          employeeFamilyValue = familyMonthly.toFixed(2);
          employeeInvidualValue = individualMonthly.toFixed(2);
        }

        this.companyPayment.values.companyValueFamily = companyValueFamily
        this.companyPayment.values.companyValueIndividual = companyValueIndividual
        this.companyPayment.values.employeeFamilyValue = employeeFamilyValue
        this.companyPayment.values.employeeInvidualValue = employeeInvidualValue
      }
    },
    dispatchMoneyInputs() {
      [
        'companyValueFamily',
        'companyValueIndividual',
        'employeeFamilyValue',
        'employeeInvidualValue'
      ].forEach(id => {
        let input = document.getElementById(id);

        if (input) {
          input.value = this.companyPayment.values[id];
          input.dispatchEvent(new Event('input'));
        }
      });
    },
    removeEmail(index) {
      this.companyPayment.invoice.emails.splice(index, 1);

      if (!this.companyPayment.invoice.emails.length) {
        this.addEmail();
      }
    },
    removePhone(index) {
      this.companyPayment.invoice.phones.splice(index, 1);

      if (!this.companyPayment.invoice.phones.length) {
        this.addPhone();
      }
    },
    addEmail() {
      this.companyPayment.invoice.emails.push({ email: '' });
    },
    addPhone() {
      this.companyPayment.invoice.phones.push({ countryCode: '55', phone: '' });
    },
    async checkEmail() {
      try {
        this.emailLoader = true

        this.$v.login.email.$touch()

        if (this.$v.login.email.email) {
          const email = this.login.email.trim().toLowerCase()
          const {exists} = await checkEmailAlreadyUsed(email, this.company._id)

          this.emailUsed = exists
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.emailLoader = false
      }
    },
    async getAddress() {
      try {
        const cep = this.address.cep.replace(/\D+/g, '');

        if (cep && cep.length === 8) {
          this.cepLoader = true;

          const address = await getAddressByCep(cep);

          this.address.logradouro = address.street;
          this.address.cidade = address.city;
          this.address.bairro = address.neighborhood;
          this.address.estado = this.estados.find(e => e.sigla === address.state);
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.cepLoader = false;
      }
    },
    async getCompany(companyId) {
      const company = await getUserById(companyId);
      this.copyCompanyData(company);
    },
    async getConfig() {
      this.config = await getConfig();
    },
    async getData(companyId) {
      try {
        this.companyLoader = true;
        const promises = [this.getConfig()];

        if (companyId) {
          promises.push(this.getCompany(companyId));
        }

        await Promise.all(promises);

        if (!companyId) {
          this.setupPrices();
        }

        this.dispatchMoneyInputs();
      } catch (err) {
        this.$noty.error('Desculpe! Não foi possível buscar os dados da empresa. Tente novamente mais tarde!');
        this.closeDialog();

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.companyLoader = false;
      }
    },
    async getCities() {
      try {
        this.$v.address.estado.$touch();

        if (!this.$v.address.estado.$anyError) {
          this.cities = await getCidadesByEstado(this.address.estado.id);
        }
      } catch (err) {
        this.cities = [];

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      }
    },
    async submit() {
      try {
        this.$v.$touch();

        if (this.company._id) {
          this.$v.login.$reset();
        }

        if (
          !this.$v.company.$anyError &&
          !this.$v.companyPayment.$anyError &&
          !this.$v.login.$anyError &&
          !this.$v.address.$anyError
        ) {
          this.loader = true;

          const company = {
            ...this.login,
            ...this.company,
            address: JSON.parse(JSON.stringify(this.address)),
            companyPayment: JSON.parse(JSON.stringify(this.companyPayment))
          };

          company.cpf_cnpj = company.cpf_cnpj.replace(/\D+/g, '');
          company.address.cep = company.address.cep.replace(/\D+/g, '');

          const valuesKeys = Object.keys(company.companyPayment.values);
          for (let i = 0; i < valuesKeys.length; i++) {
            const key = valuesKeys[i];
            company.companyPayment.values[key] = removeMoneyMask(company.companyPayment.values[key]);
          }

          const invoiceEmails = [];

          for (let i = 0; i < company.companyPayment.invoice.emails.length; i++) {
            const item = company.companyPayment.invoice.emails[i];

            if (isEmail(item.email)) {
              invoiceEmails.push(item.email.trim().toLowerCase());
            }
          }

          company.companyPayment.invoice.emails = JSON.parse(JSON.stringify(invoiceEmails));

          company.companyPayment.invoice.phones = company.companyPayment.invoice.phones.filter(item => {
            return item.countryCode && item.phone;
          });

          if (!company.companyPayment.coupons) {
            company.companyPayment.coupons = {
              family: {
                description: `Colab - ${company.displayName}`,
                price_cents: 0,
                quantity: 1,
                recurrent: true
              },
              individual: {
                description: `Colab - ${company.displayName}`,
                price_cents: 0,
                quantity: 1,
                recurrent: true
              },
              cashflow: {
                description: `Colab - ${company.displayName}`,
                price_cents: 0,
                quantity: 1,
                recurrent: true
              }
            };
          }

          const {familyMonthly, individualMonthly, cashflowMonthly} = this.config.defaultValues

          if (company.companyPayment.billingType === 'full-payment') {
            company.companyPayment.coupons.family.price_cents = Number.parseInt(
              ((familyMonthly * -1) * 100).toFixed(2));

            company.companyPayment.coupons.individual.price_cents = Number.parseInt(
              ((individualMonthly * -1) * 100).toFixed(2));

            company.companyPayment.coupons.cashflow.price_cents = Number.parseInt(
              ((cashflowMonthly * -1) * 100).toFixed(2));

            company.companyPayment.values.employeeCashflowValue = 0;
            company.companyPayment.values.companyCashflowValue = cashflowMonthly;
          } else {
            const familyDiff = (familyMonthly - company.companyPayment.values.employeeFamilyValue);
            const individualDiff = (individualMonthly - company.companyPayment.values.employeeInvidualValue);

            company.companyPayment.coupons.family.price_cents = Number.parseInt(((familyDiff * -1) * 100).toFixed(2));
            company.companyPayment.coupons.individual.price_cents = Number.parseInt(((individualDiff * -1) * 100).toFixed(2));

            const companyPaymentPercent = (company.companyPayment.values.companyValueIndividual / individualMonthly);
            company.companyPayment.values.companyCashflowValue = Number.parseFloat(
              (cashflowMonthly * companyPaymentPercent).toFixed(2));

            const employeePaymentPercent = (company.companyPayment.values.employeeInvidualValue / individualMonthly);
            company.companyPayment.values.employeeCashflowValue = Number.parseFloat(
              (cashflowMonthly * employeePaymentPercent).toFixed(2));

            const cashflowDiff = (cashflowMonthly - company.companyPayment.values.employeeCashflowValue);
            company.companyPayment.coupons.cashflow.price_cents = Number.parseInt(((cashflowDiff * -1) * 100).toFixed(2));
          }

          if (!company._id) {
            await createCompany(company);
          } else {
            delete company.email;
            delete company.password;
            delete company.repeatPassword;

            await updateCompany(company._id, company);
          }

          this.$bus.$emit('managerGetUsers');
          this.closeDialog();
          this.$noty.success('Dados salvos com sucesso!');
        }
      } catch (err) {
        this.$noty.error('Desculpe! Não foi possível salvar estes dados. Tente novamente mais tarde!');

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.loader = false;
      }
    }
  },
  watch: {
    'address.estado'() {
      this.getCities();
    },
    'address.cep'() {
      this.getAddress();
    },
    'login.email'() {
      this.checkEmail()
    }
  },
  created() {
    this.$bus.$on('showFormCompany', this.openDialog);
  }
};
</script>

<style scoped>
.container {
  max-width: 960px;
}
</style>
