<template>
  <v-card color="grey2" height="100%" class="card-column">
    <v-card-title class="primary--text text-uppercase text-center">Reserva de Emergência</v-card-title>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <div class="input-container">
          <span class="input-label primary--text">Períodos</span>
          <v-select
            dense
            color="grey1"
            item-text="text"
            v-model="months"
            item-value="value"
            :items="monthItems"
          >
            <v-icon slot="prepend-inner" color="grey1" class="input-icon">
              mdi-calendar
            </v-icon>
          </v-select>
        </div>

        <div class="input-container">
          <span class="input-label primary--text">Gastos mensais</span>
          <v-text-field
            dense
            color="grey1"
            v-money="money"
            v-model="monthlyValue"
            id="input-monthly-value"
          >
            <v-icon slot="prepend-inner" color="grey1" class="input-icon">
              mdi-cash-multiple
            </v-icon>
          </v-text-field>
        </div>

        <div class="total-container text-center mt-3">
          <p>
            Total:
            <span class="total-value">
              <span>R$</span> {{ currency(total) }}
            </span>
          </p>
        </div>

        <div class="text-center mt-10">
          <v-btn
            rounded
            type="submit"
            color="primary"
            :loading="loader"
            :disabled="!allowed"
            class="darkgrey--text"
          >
            Salvar
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
  import { VMoney } from 'v-money'
  import removeMoneyMask from '../../../../utils/removeMoneyMask';
  import currency from '../../../../utils/currency';
  import updateLiquidez from '../../../../api/liquidez/updateLiquidez';

  export default {
    name: 'CardSecurityWallet',
    directives: { money: VMoney },
    data: () => ({
      loader: false,
      monthItems: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
      months: 6,
      monthlyValue: 0,
      currency
    }),
    computed: {
      allowed() {
        return this.$store.getters.allowed;
      },
      liquidez() {
        return this.$store.getters.liquidez;
      },
      total() {
        return this.months * removeMoneyMask(this.monthlyValue);
      }
    },
    methods: {
      setMonths() {
        for (let i = 3; i <= 24; i++) {
          this.monthItems.push({
            text: i + ' meses',
            value: i
          });
        }
      },
      copyData() {
        if (this.liquidez) {
          this.months = this.liquidez.securityWallet.months;
          this.monthlyValue = this.liquidez.securityWallet.monthlyValue.toFixed(2);
          this.resetMoneyInput();
        }
      },
      resetMoneyInput() {
        let input = document.getElementById('input-monthly-value');

        if (input) {
          input.value = this.monthlyValue;
          input.dispatchEvent(new Event('input'));
        }
      },
      submit() {
        this.loader = true;

        let securityWallet = {
          months: this.months,
          monthlyValue: removeMoneyMask(this.monthlyValue),
          total: removeMoneyMask(this.total)
        }

        updateLiquidez(this.liquidez._id, { securityWallet })
          .then(liquidez => {
            this.loader = false;
            this.$store.dispatch('saveLiquidez', liquidez);
            this.copyData();
            this.$noty.success('Dados salvos com sucesso!')
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível atualizar seus dados. Tente novamente!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    created() {
      this.setMonths();
      this.copyData();
    }
  }
</script>

<style scoped>
  .v-card.column-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .v-card__title {
    display: block !important;
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 1.5em !important;
    letter-spacing: .1em !important;
  }

  .input-icon {
    font-size: 16px !important;
    margin-right: 5px;
    margin-top: 5px;
  }

  .total-container p {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .total-value {
    font-size: 18px;
  }

  .total-value span {
    font-size: 10px;
  }

  .input-label {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  @media only screen and (min-width: 600px) {
    .v-card__title {
      font-size: 15px !important;
    }

    .total-container p {
      font-size: 15px;
    }

    .total-value {
      font-size: 24px;
    }

    .total-value span {
      font-size: 13px;
    }
  }

  @media only screen and (min-width: 960px) {
    .v-card__title {
      font-size: 20px !important;
    }

    .total-container p {
      font-size: 15px;
    }

    .total-value {
      font-size: 24px;
    }

    .total-value span {
      font-size: 13px;
    }
  }

  @media only screen and (min-width: 1264px) {
    .v-card__title {
      font-size: 16px !important;
    }
  }
</style>
