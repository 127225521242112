<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card color="grey2">
      <v-card-title
        class="dialog-form-title"
        style="border-radius: 0 !important"
      >
        <v-btn
          icon
          color="white"
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        Redefinir Senha
      </v-card-title>

      <v-card-text class="px-1">
        <v-card-text>
          <p class="description">
            Para redefinir sua senha, é necessário enviar o link de redefinição
            para seu e-mail. Clique no botão abaixo e receba o link na caixa de
            entrada do e-mail cadastrado!
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            rounded
            @click="submit"
            color="primary"
            class="px-5 darkgrey--text"
            :loading="loader"
          >
            Enviar link
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/app';
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from 'vuelidate/lib/validators';
import logout from '../../../../utils/logout';

export default {
  name: 'FormSenha',
  data() {
    return {
      dialog: false,
      loader: false
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    submit() {
      this.loader = true

      firebase
        .auth()
        .sendPasswordResetEmail(this.user.email)
        .then(() => {
          this.loader = false
          this.dialog = false;
          this.$noty.success(
            'Link de redefinição enviado com sucesso! Verifique a caixa de entrada do e-mail cadastrado!'
          );
        });
    },
    close() {
      this.dialog = false;
    },
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(32),
    },
    repeatPassword: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(32),
      sameAsPassword: sameAs('password'),
    },
  },
  created() {
    this.$bus.$on('ShowFormSenha', () => {
      this.dialog = true;
    });
  },
};
</script>

<style scoped>
.form-title {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.checkbox-container .input-label {
  font-size: 12px;
  color: white !important;
  cursor: pointer;
}

p.description {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0.1em;
}

@media only screen and (min-width: 600px) {
  .form-title {
    font-size: 15px;
  }

  p.description {
    font-size: 14px;
  }

  .checkbox-container .input-label {
    font-size: 12px;
    color: white !important;
  }
}

@media only screen and (min-width: 960px) {
  .form-title {
    font-size: 20px;
  }

  .checkbox-container .input-label {
    font-size: 16px;
  }
}
</style>
