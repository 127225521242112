<template>
  <v-card flat height="100%" color="grey2" class="chart-card">
    <v-card-title>
      <span class="total-desc primary--text">Usuários por Tipo</span>
    </v-card-title>

    <apexchart
      width="100%"
      height="300px"
      type="donut"
      :options="chartOptions.options"
      :series="chartOptions.series"
    ></apexchart>

    <div class="d-flex align-center pa-5 pt-0">
      <v-layout row wrap>
        <v-flex xs12 sm4 md2 v-for="(item, index) in legends" :key="index">
          <div class="legend-item mr-4">
            <v-avatar size="10" :color="item.color" class="mr-2"></v-avatar>
            <div>
              <span class="legend-item--title">{{ item.text }}</span>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import countUsersGroupedByType from '@/api/users/countUsersGroupedByType';

export default {
  name: 'CardChartGroupedUsers',
  components: {
    apexchart: VueApexCharts
  },
  data: () => ({
    chartOptions: {
      options: {
        colors: ['#D1AC68', '#9e9e9e', '#131313'],
        labels: ['Planejadores', 'Empresas', 'Clientes'],
        chart: {
          type: 'donut'
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val.toFixed(2) + '%';
          }
        },
        stroke: { width: 0 },
        legend: { show: false },
        tooltip: {
          theme: 'dark'
        },
      },
      series: [0, 0, 0]
    },
    legends: [
      {
        color: '#D1AC68',
        text: 'Clientes'
      },
      {
        color: '#131313',
        text: 'Empresas'
      },
      {
        color: '#9e9e9e',
        text: 'Planejadores'
      }
    ]
  }),
  methods: {
    countUsers() {
      countUsersGroupedByType()
        .then(resp => {
          let chartOptions = {
            options: {
              labels: resp.labels
            },
            series: resp.series
          };

          this.chartOptions = { ...this.chartOptions, ...chartOptions };
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  watch: {
    period() {
      this.countUsers();
    }
  },
  created() {
    this.countUsers();

    let interval = setInterval(() => {
      if (this.user) {
        this.countUsers();
      } else {
        clearInterval(interval);
      }
    }, 60000);
  }
};
</script>

<style scoped>
.v-card.chart-card {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.total-desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .1em;
}

.filter-container {
  display: flex;
  align-items: center;
}

.legends {
  display: flex;
  align-items: center;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.legend-item--title {
  font-size: 12px;
  letter-spacing: .1em;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  line-height: 1.5em;
}
</style>
