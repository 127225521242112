<template>
  <v-main>
    <OverlaySync :overlay="loader"/>
    <MenuPlanner v-if="user && user.role !== 'admin'"/>

    <v-container
      grid-list-md
      v-if="user"
      :style="isIOS ? 'margin-top: 98px' : 'margin-top: 68px'"
    >
      <v-layout row wrap>
        <v-flex xs12>
          <FormPhoto/>
        </v-flex>

        <v-flex xs12>
          <FormUserData/>
        </v-flex>

        <v-flex xs12>
          <FormUpdateEmail/>
        </v-flex>

        <v-flex xs12>
          <FormConfigPassword/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
  import firebase from 'firebase/app'

  import MenuPlanner from '../components/Dashboard/menus/MenuPlanner';
  import OverlaySync from '../components/Global/OverlaySync';
  import FormUserData from '../components/Config/forms/FormUserData';

  import getUserByUid from '../api/users/getUserByUid';
  import logout from '../utils/logout';
  import FormPhoto from '../components/Config/forms/FormPhoto';
  import FormUpdateEmail from '../components/Config/forms/FormUpdateEmail';
  import FormConfigPassword from '../components/Config/forms/FormConfigPassword';
  // import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

  export default {
    name: 'Config',
    // metaInfo() {
    //   return {
    //     title: "Configurações de acesso",
    //     changed(metaInfo) {
    //       sendMetaInfoData(metaInfo)
    //     }
    //   };
    // },
    components: { FormConfigPassword, FormUpdateEmail, FormPhoto, FormUserData, OverlaySync, MenuPlanner },
    data: () => ({
      loader: false
    }),
    computed: {
      user() {
        return this.$store.getters.user;
      },
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      }
    },
    methods: {
      getUser() {
        this.loader = true;

        getUserByUid(firebase.auth().currentUser.uid)
          .then(user => {
            this.$store.dispatch('setUser', user);
            this.loader = false;
          })
          .catch(err => {
            logout();
          });
      }
    },
    beforeMount() {
      this.$vuetify.theme.dark = true;
    },
    created() {
      if (!this.user)
        this.getUser();
    }
  }
</script>

<style scoped>
  main.v-main {
    background-color: #2d2d2d !important;
  }

  @media only screen and (min-width: 960px) {
    .container {
      max-width: 600px;
    }
  }
</style>
