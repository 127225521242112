<template>
  <v-dialog
    persistent
    scrollable
    fullscreen
    v-model="dialog"
  >
    <v-card>
      <v-card-title class="dialog-form-title no-radius" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="grey1"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="grey1--text ml-4">
          Cartões de Crédito
        </span>
      </v-card-title>

      <v-card-text class="px-3">
        <v-container grid-list-md class="px-0">
          <v-layout row wrap>
            <v-flex xs12>
              <v-tabs v-model="tab">
                <v-tab>Ativos</v-tab>
                <v-tab>Arquivados</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item class="pt-5">
                  <v-layout row wrap>
                    <v-flex
                      xs12 sm6 md4 lg4 xl4
                      :key="card._id"
                      v-for="card in activeCreditCards"
                    >
                      <CardCreditCard :card="card"/>
                    </v-flex>

                    <v-flex xs12 class="text-center mt-10" v-if="!isMobile">
                      <v-btn
                        rounded
                        outlined
                        class="px-5"
                        color="primary"
                        :disabled="!allowed"
                        @click.stop="showForm(null)"
                      >
                        Novo cartão
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-tab-item>

                <v-tab-item v-if="archivedCreditCards.length" class="pt-5">
                  <v-layout row wrap>
                    <v-flex
                      xs12 sm6 md4 lg4 xl4
                      :key="card._id"
                      v-for="card in archivedCreditCards"
                    >
                      <CardCreditCard :card="card"/>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
              </v-tabs-items>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-btn
        fab
        dark
        right
        small
        bottom
        fixed
        absolute
        color="primary"
        class="hidden-md-and-up"
        style="margin-bottom: 40px"
        @click.stop="showForm(null)"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import currency from "../../../../utils/currency";
import dayjs from 'dayjs';
import CardCreditCard from "@/components/Customer/Cashflow/cards/CardCreditCard";

export default {
  name: 'DialogCreditCardsList',
  components: {CardCreditCard},
  data: () => ({
    dialog: false,
    loader: false,
    tab: null,
    currency
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === `customer`;
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
    },
    creditCards() {
      return this.$store.getters.creditCards;
    },
    activeCreditCards() {
      return this.creditCards.filter(cc => !cc.archived);
    },
    archivedCreditCards() {
      return this.creditCards.filter(cc => cc.archived);
    },
    allowed() {
      return this.$store.getters.allowed;
    }
  },
  methods: {
    showDetails(itemId) {
      this.close()
      this.$router.push(`/open-banking?item=${itemId}`)
    },
    close() {
      this.dialog = false;
      this.loader = false;
    },
    getImgUrl(flag) {
      let images = require.context('../../../../assets/credit-card/', false, /\.png$/)
      return images('./' + flag + ".png")
    },
    showForm(card) {
      if (this.allowed) {
        if (card) {
          this.$bus.$emit('showDialogFormCreditCard', card);
        } else {
          if (this.isCustomer) {
            this.$bus.$emit('showDialogAddAccount', {type: 'credit-card'});
          } else {
            this.$bus.$emit('showDialogFormCreditCard');
          }
        }
      }
    },
    showDialogInvoice(creditCardId) {
      const period = this.$date().add(1, 'month').format('YYYY-MM');
      this.$bus.$emit('showDialogCustomerInvoiceDetails', {creditCardId, period});
    },
    showDialogArchiveCreditCard(card) {
      if (this.allowed)
        this.$bus.$emit('showDialogArchiveCreditCard', card);
    }
  },
  created() {
    this.$bus.$on('showDialogCreditCardsList', () => {
      this.dialog = true;
    });
  }
}
</script>

<style scoped>
.container {
  max-width: 960px !important;
}

.v-card__title.card-title,
.card-limit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-flag {
  width: 32px;
}

.card-name {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  align-items: flex-end;
  justify-content: space-between;
}

.card-name--text {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.card-invoice-total__value span {
  font-size: 9px;
}

.v-progress-linear__content strong {
  font-size: 16px !important;
  font-weight: normal !important;
}

.card-limit {
  width: 100%;
  margin-top: 5px;
  align-items: flex-end;
}

.card-limit--desc {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: .1em;
}

.card-limit--value {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: .1em;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.card-limit--value span {
  font-size: 10px;
}

@media only screen and (min-width: 375px) {
  .card-flag {
    width: 48px;
  }
}

@media only screen and (min-width: 600px) {
  .card-name--text {
    font-size: 14px;
  }

  .card-invoice-total__value span {
    font-size: 12px;
  }
}
</style>
