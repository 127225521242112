<template>
  <v-card height="100%">
    <v-card-title class="primary--text text-uppercase pb-0">
      Balanço mensal
    </v-card-title>

    <v-card-text class="mt-4">
      <v-layout row wrap>
        <v-flex xs6 class="pa-0">
          <div class="chart-container">
            <apexchart
              type="bar"
              :options="options"
              :series="series"
            ></apexchart>
          </div>
        </v-flex>

        <v-flex xs6 class="legend-list">
          <div class="legend-item" :key="i" v-for="(item, i) in legends">
            <v-avatar size="10" :color="item.color" class="mr-1"></v-avatar>
            <div>
              <span class="legend-item--title">{{ item.text }}</span>
              <span class="legend-item--value">{{ item.value }}</span>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';
  import numberToCurrency from "../../../../utils/numberToCurrency";
  import {sumBy, uniqBy} from 'lodash';
  import currency from "@/utils/currency";
  import totalRevenueFromPeriod from "@/utils/cashflow/totalRevenueFromPeriod";
  import totalExpensesFromPeriod from "@/utils/cashflow/totalExpensesFromPeriod";
  import totalInvestmentsFromPeriod from "@/utils/cashflow/totalInvestmentsFromPeriod";

  export default {
    name: 'CardChartBarBalanco',
    props: ['period'],
    components: { apexchart: VueApexCharts },
    data: () => ({
      options: {
        colors: ['#338A2D', '#E62D2A', '#FFC107'],
        labels: ['Receita', 'Despesa', 'Investimento'],
        chart: {
          toolbar: { show: false }
        },
        stroke: { show: false, width: 0 },
        plotOptions: {
          bar: { horizontal: false },
        },
        fill: { opacity: 1 },
        dataLabels: { enabled: false },
        legend: { show: false },
        grid: { show: false },
        xaxis: {
          labels: { show: false },
          axisTicks: { show: false },
          axisBorder: { show: true },
          crosshairs: { show: false }
        },
        yaxis: {
          labels: { show: false },
          axisTicks: { show: false },
          axisBorder: { show: false },
          crosshairs: { show: false }
        },
        tooltip: {
          enabled: false
        }
      },
      series: [],
      legends: []
    }),
    computed: {
      currentPeriod() {
        return this.$date().format('YYYY-MM');
      },
      revenue() {
        return totalRevenueFromPeriod(this.currentPeriod, this.period)
      },
      expense() {
        return totalExpensesFromPeriod(this.currentPeriod, this.period)
      },
      investment() {
        return totalInvestmentsFromPeriod(this.currentPeriod, this.period)
      }
    },
    methods: {
      buildChart() {
        this.legends = [
          { text: 'Receita', value: numberToCurrency(this.revenue), color: '#338A2D' },
          { text: 'Despesa', value: numberToCurrency(this.expense), color: '#E62D2A' },
          { text: 'Investimento', value: numberToCurrency(this.investment), color: '#FFC107' },
        ]

        this.series = [
          { name: 'Receita', data: [this.revenue] },
          { name: 'Despesa', data: [this.expense] },
          { name: 'Investimento', data: [this.investment] }
        ];
      }
    },
    watch: {
      transactions: {
        deep: true,
        handler() {
          this.buildChart();
        }
      }
    },
    created() {
      this.buildChart();
    }
  }
</script>

<style scoped>
  .chart-container {
    /* height: 120px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .v-card__title {
    font-size: 12px !important;
    letter-spacing: .1em;
    font-weight: 500;
  }

  .legend-item {
    display: flex;
    margin-bottom: 2px;
  }

  .legend-item div {
    display: flex;
    flex-direction: column;
  }

  .legend-item--title {
    font-size: 12px;
    letter-spacing: .1em;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    line-height: 12px;
  }

  .legend-item--value {
    font-size: 9px;
    line-height: 9px;
    color: #9e9e9e;
  }

  @media only screen and (min-width: 600px) {
    .chart-container {
      height: 239px;
    }

    .legend-list {
      height: 239px;
    }

    .legend-item {
      margin-bottom: 5px;
    }
  }

  @media only screen and (min-width: 960px) {
    .v-card__title {
      font-size: 14px !important;
      line-height: 16px;
    }

    .chart-container {
      height: auto;
      padding-top: 10px;
    }

    .legend-list {
      height: 109px;
    }

    .legend-item {
      margin-bottom: 5px;
    }
  }
</style>
