import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const suspendIuguSubscription = async subscriptionId => {
  if (!subscriptionId) {
    throw new Error('missing subscriptionId')
  }

  const idToken = await firebase.auth().currentUser.getIdToken(true)

  const url = `/iugu/subscriptions/${subscriptionId}/suspend`
  const options = {
    data: {},
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }

  await httpClient(url, options)
}

export default suspendIuguSubscription
