<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          large
          color="white"
          @click="close"
          :disabled="loader"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="white--text ml-3">Criar senha</span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <div class="mt-2">
            <span class="input-label primary--text">Senha</span>
            <v-text-field
              color="grey1"
              class="mt-0 pt-0"
              v-model="data.password"
              :error-messages="passwordErrors"
              @click:append="showPass = !showPass"
              :type="showPass ? 'text' : 'password'"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>
          </div>

          <div class="mt-2">
            <span class="input-label primary--text">Repita a senha</span>
            <v-text-field
              color="grey1"
              class="mt-0 pt-0"
              v-model="data.repeatPassword"
              :error-messages="repeatPasswordErrors"
              :type="showRepeatPass ? 'text' : 'password'"
              @click:append="showRepeatPass = !showRepeatPass"
              :append-icon="showRepeatPass ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>
          </div>

          <div class="d-flex align-center justify-space-between">
            <v-btn
              text
              rounded
              class="px-5"
              type="button"
              color="grey1"
              @click="close"
              :disabled="loader"
            >
              Cancelar
            </v-btn>

            <v-btn
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              class="px-5 darkgrey--text"
            >
              Concluir
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import createPassword from '@/api/users/createPassword';

export default {
  name: 'DialogFormCreateCustomerPassword',
  data: () => ({
    dialog: false,
    loader: false,
    data: {
      userId: '',
      password: '',
      repeatPassword: ''
    },
    showPass: false,
    showRepeatPass: false
  }),
  validations: {
    data: {
      password: {
        isValid(v) {
          return !!v && v.trim().length >= 8 && v.trim().length <= 32
        }
      },
      repeatPassword: {
        isValid(v) {
          return v === this.data.password
        }
      }
    }
  },
  computed: {
    passwordErrors() {
      const errors = []

      if (!this.$v.data.password.$anyDirty) {
        return errors
      }

      if (!this.$v.data.password.isValid) {
        errors.push('Preencha uma senha válida')
      }

      return errors
    },
    repeatPasswordErrors() {
      const errors = []

      if (!this.$v.data.repeatPassword.$anyDirty) {
        return errors
      }

      if (!this.$v.data.repeatPassword.isValid) {
        errors.push('As senhas devem ser iguais')
      }

      return errors
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.loader = false
      this.data = {
        userId: '',
        password: '',
        repeatPassword: ''
      }
      this.$v.$reset()
    },
    submit() {
      this.$v.$touch()

      if (!this.$v.$anyError) {
        this.loader = true

        const data = { ...this.data }
        delete data.repeatPassword

        createPassword(data)
          .then(() => {
            this.close()
            this.$noty.success('Senha criada com sucesso!')
            this.$bus.$emit('managerGetUsers')
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }

            this.loader = false
            this.$noty.error('Falha ao criar senha! Tente novamente mais tarde!')
          })
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormCreateCustomerPassword', userId => {
      if (userId) {
        this.data.userId = userId
        this.dialog = true
      }
    })
  }
}
</script>
