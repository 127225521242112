<template>
  <v-dialog persistent scrollable :fullscreen="isMobile" v-model="dialog" max-width="500px">
    <v-card :class="isMobile ? 'no-radius' : ''">
      <v-progress-linear indeterminate :height="10" color="success" v-if="isMobile && loader"></v-progress-linear>

      <v-card-title>
        <div class="title">
          <v-btn icon v-if="isMobile" class="mr-4" @click.stop.prevent="close">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ user._id ? 'Editar' : 'Adicionar' }} Admin
        </div>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="save">
          <v-text-field
            type="text"
            color="grey1"
            label="Nome completo"
            v-model="user.displayName"
            :error-messages="displayNameErrors"
            @input="$v.user.displayName.$touch()"
          ></v-text-field>

          <v-text-field
            type="email"
            label="E-mail"
            color="grey1"
            v-if="!user._id"
            v-model="user.email"
            :error-messages="emailErrors"
            @input="$v.user.email.$touch()"
          ></v-text-field>

          <v-text-field
            label="Senha"
            type="password"
            v-if="!user._id"
            color="grey1"
            v-model="user.password"
            :error-messages="passwordErrors"
            @input="$v.user.password.$touch()"
          ></v-text-field>

          <v-text-field
            type="password"
            v-if="!user._id"
            label="Repita a senha"
            color="grey1"
            v-model="user.repeatPassword"
            :error-messages="repeatPasswordErrors"
            @input="$v.user.repeatPassword.$touch()"
          ></v-text-field>

          <v-card-actions class="pa-0">
            <v-btn
              text
              small
              rounded
              class="px-5"
              color="primary"
              type="buttom"
              :disabled="loader"
              @click.stop="close"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              small
              rounded
              depressed
              class="px-5 darkgrey--text"
              color="primary"
              :loading="loader"
              type="submit"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';
import stringCapitalize from '@/utils/stringCapitalize';

import checkEmailAlreadyUsed from '@/api/users/checkEmailAlreadyUsed';
import saveUser from '../../../../api/users/saveUser';

export default {
  name: 'DialogFormAdmin',
  data: () => ({
    dialog: false,
    loader: false,
    user: {
      role: 'admin',
      displayName: '',
      email: '',
      password: '',
      repeatPassword: ''
    },
    emailUsed: false
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    loggedUser() {
      return this.$store.getters.user;
    },
    company() {
      return this.$store.getters.company;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) return errors;
      !this.$v.user.email.required && errors.push('E-mail é obrigatório');
      !this.$v.user.email.email && errors.push('E-mail inválido');
      this.emailUsed && errors.push('E-mail já cadastrado');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) return errors;
      !this.$v.user.password.required && errors.push('Senha é obrigatória');
      !this.$v.user.password.minLength &&
        errors.push('A senha deve conter, no mínimo, 8 caracteres');
      !this.$v.user.password.maxLength &&
        errors.push('A senha deve conter, no máximo, 150 caracteres');
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) return errors;
      !this.$v.user.password.required && errors.push('Senha é obrigatória');
      this.user.repeatPassword !== this.user.password &&
        errors.push('As senhas devem ser iguais');
      return errors;
    },
    displayNameErrors() {
      const errors = [];
      if (!this.$v.user.displayName.$dirty) return errors;
      !this.$v.user.displayName.required && errors.push('Nome é obrigatório');
      !this.$v.user.displayName.minLength &&
        errors.push('O nome deve conter, no mínimo, 3 caracteres');
      !this.$v.user.displayName.maxLength &&
        errors.push('O nome deve conter, no máximo, 150 caracteres');
      return errors;
    }
  },
  methods: {
    checkEmail() {
      this.$v.user.email.$touch();

      if (!this.$v.user.email.$anyError) {
        checkEmailAlreadyUsed(this.user.email)
          .then(exists => (this.emailUsed = exists))
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    save() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        let user = JSON.parse(JSON.stringify(this.user));

        user.email = user.email.toLowerCase().trim();
        user.displayName = stringCapitalize(user.displayName);
        user.role = 'admin';

        saveUser(user)
          .then(() => {
            this.close();
            this.$noty.success('Usuário criado com sucesso!');
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') console.error(err);
            this.loader = false;
            this.$noty.error('Falha ao criar usuário! Tente novamente');
          });
      }
    },
    close() {
      this.dialog = false;
      this.loader = false;
      this.user = {
        role: 'customer',
        displayName: '',
        email: '',
        password: '',
        repeatPassword: ''
      };

      this.$v.$reset();
    }
  },
  validations: {
    user: {
      email: { required, email },
      displayName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150)
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(150)
      },
      repeatPassword: { required }
    }
  },
  watch: {
    'user.email'() {
      this.checkEmail();
    }
  },
  created() {
    this.$bus.$on('showFormAdmin', () => {
      this.dialog = true;
    });
  }
};
</script>

<style scoped>
.v-card.no-radius {
  border-radius: 0 !important;
}

.title {
  display: flex;
  align-items: center;
}
</style>
