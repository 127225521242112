<template>
  <v-card flat color="grey3">
    <v-card-text class="text-center d-flex flex-column">
      <span class="primary--text total-title">Cobertura total necessária</span>
      <span class="white--text total-value">
        <span>R$</span> {{ currency(lifeInsurance.coberturaTotal) }}
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import currency from '@/utils/currency';

export default {
  name: 'CardCoberturaTotal',
  props: ['isSpouse'],
  data: () => ({
    currency
  }),
  computed: {
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    }
  }
}
</script>

<style scoped>
.total-title {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.total-value {
  font-size: 25px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.total-value span {
  font-size: 15px;
}

@media only screen and (min-width: 600px) {
  .total-title {
    font-size: 15px;
  }

  .total-value {
    font-size: 35px;
  }

  .total-value span {
    font-size: 20px;
  }
}

@media only screen and (min-width: 960px) {
  .total-title {
    font-size: 20px;
  }
}
</style>
