<template>
  <v-dialog persistent v-model="dialog" max-width="400px">
    <v-card>
      <v-card-title>
        <div class="title">Redefinir Senha</div>
      </v-card-title>

      <v-card-text>
        <p class="description">
          Tem certeza que deseja enviar o link de redefinição de senha para
          <b>{{ email }}</b>?
        </p>

        <v-card-actions class="pa-0">
          <v-btn
            small
            text
            rounded
            color="primary"
            class="px-5"
            :disabled="loader"
            @click.stop="close"
          >
            Cancelar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            small
            rounded
            depressed
            class="px-5 darkgrey--text"
            color="primary"
            @click.stop="send"
            :disabled="loader"
          >Enviar
          </v-btn>
        </v-card-actions>
      </v-card-text>

      <v-progress-linear indeterminate color="success" v-if="loader"></v-progress-linear>
    </v-card>
  </v-dialog>
</template>

<script>
  import firebase from 'firebase/app';
  import sendResetPassLink from '@/api/users/sendResetPassLink';

  export default {
    name: 'DialogPasswordResetLink',
    data: () => ({
      dialog: false,
      loader: false,
      email: '',
      userId: ''
    }),
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.email = '';
        this.userId = '';
      },
      send() {
        this.loader = true;

        firebase
          .auth()
          .sendPasswordResetEmail(this.email)
          .then(() => {
            sendResetPassLink(this.userId, this.email);
            this.close();
            this.$noty.success('Link de redefinição enviado com sucesso!');
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') console.error(err);
            this.loader = false;
            this.$noty.error('Falha ao enviar link. Tente novamente mais tarde!');
          });
      }
    },
    created() {
      this.$bus.$on('showDialogPasswordResetLink', data => {
        if (data.email) {
          this.email = data.email;
          this.userId = data.userId;
          this.dialog = true;
        }
      });
    }
  };
</script>

<style scoped>
  .description {
    font-size: 16px;
  }
</style>
