<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="450px"
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          class="mr-2"
          color="white"
          :disabled="loader"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="white--text">Cartão pendente</span>
      </v-card-title>

      <v-card-text class="pt-5" v-if="pendentCreditCard">
        <v-form @submit.prevent="submit">
          <div>
            <span class="input-label primary--text">Cartão</span>
            <v-text-field
              readonly
              color="grey1"
              class="mt-0 pt-0"
              :value="pendentCreditCard.name"
            ></v-text-field>
          </div>

          <div>
            <span class="input-label primary--text">Banco</span>
            <v-text-field
              readonly
              color="grey1"
              class="mt-0 pt-0"
              :value="pendentCreditCard.bank"
            ></v-text-field>
          </div>

          <div>
            <span class="input-label primary--text">Dia de fechamento da fatura</span>
            <v-text-field
              type="number"
              color="grey1"
              class="mt-0 pt-0"
              v-model="closeDate"
              :error-messages="closeDateErrors"
            ></v-text-field>
          </div>

          <v-card-actions class="px-0 mt-4">
            <v-btn
              rounded
              outlined
              type="button"
              color="grey1"
              :disabled="loader"
              @click="closeDialog"
              class="text-capitalize px-5"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              dark
              rounded
              type="submit"
              color="success"
              :loading="loader"
              class="text-capitalize px-5"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import createCreditCardFromPendentCreditCard from "@/api/creditCards/createCreditCardFromPendentCreditCard";
import deletePendentCreditCard from "@/api/pendentCreditCards/deletePendentCreditCard";

export default {
  name: 'DialogFormPendentCreditCard',
  data: () => ({
    dialog: false,
    loader: false,
    closeDate: null,
    pendentCreditCard: null
  }),
  validations: {
    closeDate: {
      isValid(v) {
        return !!v && v >= 1 && v <= 31
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    closeDateErrors() {
      if (!this.$v.closeDate.$anyDirty) {
        return []
      }
      if (this.$v.closeDate.$anyError) {
        return ['Preencha um dia válido']
      }
    }
  },
  methods: {
    openDialog(pendentCreditCard) {
      if (pendentCreditCard) {
        this.pendentCreditCard = pendentCreditCard
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.closeDate = null
      this.pendentCreditCard = null

      this.$v.$reset()
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          const data = {
            closeDate: parseInt(this.closeDate),
            accountId: this.pendentCreditCard.accountId
          }

          const {creditCard, transactions} = await createCreditCardFromPendentCreditCard(data)

          this.$store.dispatch('saveCreditCard', creditCard)
          transactions.forEach(tr => this.$store.dispatch('saveTransaction', tr))

          await deletePendentCreditCard(this.pendentCreditCard._id)
          this.$store.dispatch('deletePendentCreditCard', this.pendentCreditCard._id)

          this.closeDialog()
          this.$noty.success('Dados salvos com sucesso!')
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.$noty.error('Desculpe! Não foi possível salvar os dados do cartão. Tente novamente mais tarde!')
      } finally {
        this.loader = false
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormPendentCreditCard', this.openDialog)
  }
}
</script>
