import firebase from 'firebase/app';
import httpClient from "../../plugins/httpClient";
import deletePendencia from "./deletePendencia";

const getPendencias = userId => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let url = `/pendencias/users/${userId}`;
        let headers = { Authorization: `Bearer ${idToken}` };

        return httpClient(url, { headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default getPendencias;
