import httpClient from "../../plugins/httpClient";
import firebase from 'firebase/app';

const getRewardRequestById = id => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'GET';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/reward-requests/${id}`;

        return httpClient(url, { headers, method });
      })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(reject);
  });
}

export default getRewardRequestById
