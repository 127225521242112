<template>
  <v-form @submit.prevent="submit" v-if="userData">
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <h1 class="primary--text">Cadastro</h1>
        </v-flex>

        <v-flex xs12>
          <span class="input-label primary--text">Nome</span>
          <v-text-field
            dense
            color="grey1"
            v-model="userData.displayName"
            :error-messages="displayNameErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 v-if="user.role === 'company'">
          <span class="input-label primary--text">Razão social</span>
          <v-text-field
            dense
            color="grey1"
            v-model="userData.razaoSocial"
            :error-messages="razaoSocialErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <span class="input-label primary--text">
            {{ user.role === 'planner' ? 'CPF' : 'CNPJ' }}
          </span>
          <v-text-field
            dense
            type="tel"
            color="grey1"
            v-model="userData.cpf_cnpj"
            :error-messages="cpfCnpjErrors"
            @input="$v.userData.cpf_cnpj.$touch()"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <span class="input-label primary--text">Celular/Whatsapp</span>
          <v-text-field
            dense
            type="tel"
            color="grey1"
            v-model="userData.phoneNumber"
            v-mask="'## #####-####'"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 v-if="user.role !== 'admin'">
          <v-container grid-list-md class="pa-0">
            <v-layout row wrap>
              <v-flex xs12>
                <h4 class="primary--text">Endereço</h4>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">CEP</span>
                <v-text-field
                  dense
                  type="tel"
                  color="grey1"
                  v-mask="'#####-###'"
                  v-model="userData.address.cep"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Logradouro</span>
                <v-text-field
                  dense
                  color="grey1"
                  v-model="userData.address.logradouro"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Número</span>
                <v-text-field
                  dense
                  type="tel"
                  color="grey1"
                  v-model="userData.address.numero"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Complemento</span>
                <v-text-field
                  dense
                  color="grey1"
                  v-model="userData.address.complemento"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Bairro</span>
                <v-text-field
                  dense
                  color="grey1"
                  v-model="userData.address.bairro"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Estado</span>
                <v-autocomplete
                  dense
                  return-object
                  color="grey1"
                  :items="estados"
                  item-text="nome"
                  v-model="userData.address.estado"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Cidade</span>
                <v-autocomplete
                  dense
                  color="grey1"
                  :items="cidades"
                  item-text="nome"
                  item-value="nome"
                  v-model="userData.address.cidade"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>

        <v-flex xs12 class="text-center">
          <v-btn
            rounded
            type="submit"
            color="primary"
            :loading="loader"
            class="darkgrey--text"
          >
            Salvar
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
  import { orderBy } from 'lodash'
  import { format as formatCPF, isValid as isValidCPF } from '@fnando/cpf'
  import { format as formatCNPJ, isValid as isValidCNPJ } from '@fnando/cnpj'
  import { required, minLength, maxLength } from 'vuelidate/lib/validators'

  import getCidadesByEstado from '../../../api/address/getCidadesByEstado';
  import getAddressByCep from '../../../api/brasil-api/getAddressByCep';
  import checkCpfCnpj from '../../../api/users/checkCpfCnpj';
  import stringCapitalize from '../../../utils/stringCapitalize';
  import saveUser from '../../../api/users/saveUser';

  const isValid = v => isValidCPF(v) || isValidCNPJ(v);

  export default {
    name: 'FormUserData',
    data: () => ({
      loader: false,
      userData: null,
      cidades: [],
      invalidDocument: false,
      cpfUsed: false
    }),
    computed: {
      user() {
        return this.$store.getters.user;
      },
      estados() {
        return orderBy(this.$store.getters.estados, 'nome');
      },
      displayNameErrors() {
        const errors = [];
        if (!this.$v.userData.displayName.$dirty) return errors;
        !this.$v.userData.displayName.required && errors.push('Insira o nome');
        !this.$v.userData.displayName.minLength && errors.push('O nome deve conter, no mínimo, 3 caracteres');
        !this.$v.userData.displayName.maxLength && errors.push('O nome deve conter, no máimo, 150 caracteres');
        return errors;
      },
      razaoSocialErrors() {
        const errors = [];
        if (!this.$v.userData.razaoSocial.$dirty) return errors;
        !this.$v.userData.razaoSocial.required && errors.push('Insira a razão social');
        !this.$v.userData.razaoSocial.minLength && errors.push('A razão social deve conter, no mínimo, 3 caracteres');
        !this.$v.userData.razaoSocial.maxLength && errors.push('A razão social deve conter, no máimo, 250 caracteres');
        return errors;
      },
      cpfCnpjErrors() {
        const errors = [];
        if (!this.$v.userData.cpf_cnpj.$dirty) return errors;
        !this.$v.userData.cpf_cnpj.required && errors.push(`Insira o ${this.user.role === 'planner' ? 'CPF' : 'CNPJ'}`);
        this.invalidDocument && errors.push(`${this.user.role === 'planner' ? 'CPF' : 'CNPJ'} inválido`);
        this.cpfUsed && errors.push('Documento já cadastrado');
        return errors;
      }
    },
    methods: {
      getCidades() {
        if (this.userData.address.estado.id) {
          getCidadesByEstado(this.userData.address.estado.id)
            .then(cidades => {
              this.cidades = cidades
            })
            .catch(err => {
              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      },
      getAddress() {
        if (this.userData.address.cep) {
          let cep = this.userData.address.cep.replace(/\D+/g, '');

          if (cep.length === 8) {
            getAddressByCep(cep)
              .then(address => {
                this.userData.address.logradouro = address.street;
                this.userData.address.bairro = address.neighborhood;
                this.userData.address.cidade = address.city;
                this.userData.address.estado = this.estados.find(
                  e => e.sigla.toLowerCase() === address.state.toLowerCase()
                );
              })
              .catch(err => {
                if (process.env.NODE_ENV !== 'production') console.error(err);
              });
          }
        }
      },
      checkCpfCnpj() {
        if (this.user.cpf_cnpj && !this.$v.user.cpf_cnpj.$anyError) {
          let cpf_cnpj = this.user.cpf_cnpj.replace(/\D+/g, '');
          checkCpfCnpj(cpf_cnpj, this.user._id, this.user.role)
            .then(resp => (this.cpfUsed = resp.exists))
            .catch(err => {
              if (process.env.NODE_ENV !== 'production') console.error(err);
            });
        }
      },
      submit() {
        this.$v.$touch();
        this.invalidDocument = !isValidCPF(this.userData.cpf_cnpj) && !isValidCNPJ(this.userData.cpf_cnpj);

        if (this.user.role === 'planner')
          this.$v.userData.razaoSocial.$reset();

        if (!this.$v.userData.cpf_cnpj.$anyError &&
          !this.$v.userData.displayName.$anyError &&
          !this.$v.userData.razaoSocial.$anyError) {
          this.loader = true;

          let user = JSON.parse(JSON.stringify(this.userData));

          user.cpf_cnpj = user.cpf_cnpj.replace(/\D+/gm, '').trim();
          user.displayName = stringCapitalize(user.displayName);

          if (user.razaoSocial)
            user.razaoSocial = stringCapitalize(user.razaoSocial);

          if (user.address.cep)
            user.address.cep = user.address.cep.replace(/\D+/gm, '').trim();

          saveUser(user)
            .then(user => {
              this.loader = false;
              this.$store.dispatch('setUser', user);
              this.$noty.success('Dados salvos com sucesso!');
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível salvar seus dados. Tente novamente!');

              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      }
    },
    validations: {
      userData: {
        displayName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(150)
        },
        razaoSocial: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(250)
        },
        cpf_cnpj: {
          required
        }
      }
    },
    watch: {
      'userData.address.estado'() {
        this.getCidades();
      },
      'userData.address.cep'() {
        this.getAddress();
      },
      'customer.cpf_cnpj'(newValue, oldValue) {
        newValue = newValue ? newValue.replace(/\D+/g, '') : '';
        oldValue = oldValue ? oldValue.replace(/\D+/g, '') : '';

        if (newValue !== oldValue) {
          this.checkCpfCnpj();
        }
      }
    },
    created() {
      this.userData = JSON.parse(JSON.stringify(this.user));
      this.userData.cpf_cnpj = this.user.role === 'planner' ? formatCPF(this.userData.cpf_cnpj) : formatCNPJ(this.userData.cpf_cnpj);

      if (!this.userData.address) {
        this.userData.address = {
          logradouro: '',
          numero: '',
          cidade: '',
          estado: '',
          complemento: '',
          bairro: ''
        }
      }

      if (this.userData.address.estado) {
        this.getCidades();
      }
    }
  }
</script>

<style scoped>
  h1, h4 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  }

  h4 {
    font-size: 16px;
  }

  .input-label {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
  }
</style>
