import firebase from 'firebase/app';
import httpClient from "../../plugins/httpClient";

const saveEvent = data => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let url = '/events';
          let method = 'POST';
          let headers = { Authorization: `Bearer ${idToken}` };

          return httpClient(url, { headers, method, data });
        })
        .then(resp => resolve)
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default saveEvent;
