<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
  >
    <v-card class="no-radius">
      <v-card-title
        class="dialog-form-title px-2"
        :class="isIOS ? ['ios-padding', 'fixed-title'] : ''"
      >
        <v-btn
          icon
          class="mr-5"
          color="primary"
          @click.stop="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text">Proteção Familiar</span>
      </v-card-title>

      <v-card-text class="px-2" :class="isMobile ? 'mobile-margin' : ''" v-if="perfil">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex
              xs12 md4
              :key="item._id"
              v-for="item in perfil.protecaoFamiliar.seguros"
            >
              <v-card
                outlined
                height="100%"
                class="d-flex flex-column justify-space-between"
              >
                <v-card-text>
                  <div class="d-flex flex-column ">
                    <span class="pat-item-title primary--text">{{ item.operadora }}</span>
                    <span class="pat-item-type">{{ item.tipo }}</span>
                  </div>

                  <div class="d-flex flex-column mt-2">
                    <div class="pat-item-info-desc d-flex justify-space-between">
                      <div class="d-flex flex-column">
                        <span class="input-label">Valor pago</span>
                        <span class="white--text input-label">{{ numberToCurrency(item.valorPago) }}</span>
                      </div>

                      <div class="d-flex flex-column">
                        <span class="input-label">Repetição</span>
                        <span class="white--text input-label">{{ item.repetir }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-column mt-2" v-if="item.dataVencimento">
                    <div class="pat-item-info-desc d-flex justify-space-between">
                      <div class="d-flex flex-column" style="width: 100% !important;">
                        <span class="input-label">Vencimento</span>
                        <span class="white--text input-label">{{ formatDate(item.dataVencimento) }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-column mt-2" v-if="item.obs">
                    <div class="pat-item-info-desc d-flex justify-space-between">
                      <div class="d-flex flex-column" style="width: 100% !important;">
                        <span class="input-label">Observação</span>
                        <span class="white--text input-label">{{ item.obs }}</span>
                      </div>
                    </div>
                  </div>
                </v-card-text>

                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    color="error"
                    :loading="deleteLoader === item._id"
                    @click.stop="removeItem(item._id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    color="white"
                    @click.stop="showForm(item)"
                  >
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-flex xs12 class="mt-10 text-center">
              <v-btn
                dark
                small
                rounded
                class="px-5"
                color="purple"
                @click.stop="showForm(null)"
              >
                Adicionar seguro
              </v-btn>
            </v-flex>

            <v-flex xs12 class="mt-10">
              <v-card-actions class="pa-0 ma-0">
                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  color="primary"
                  class="px-5 darkgrey--text"
                  :loading="loader"
                  @click.stop="submit"
                >
                  Salvar
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'DialogEditProtecaoFamiliar',
  data: () => ({
    numberToCurrency,
    dialog: false,
    loader: false,
    deleteLoader: ''
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    perfil() {
      return this.$store.getters.perfil
    },
  },
  methods: {
    openDialog() {
      if (this.perfil) {
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.deleteLoader = ''
    },
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY')
      }

      return ''
    },
    showForm(item) {
      this.$bus.$emit('showDialogFormSeguroVidaPerfil', item)
    },
    removeItem(itemId) {
      if (itemId) {
        this.deleteLoader = itemId

        setTimeout(() => {
          const index = this.perfil.protecaoFamiliar.seguros.findIndex(item => item._id === itemId)
          if (index > -1) {
            this.perfil.protecaoFamiliar.seguros.splice(index, 1)
          }

          this.deleteLoader = itemId
        }, 1500)
      }
    },
    async submit() {
      try {
        this.loader = true

        const protecaoFamiliar = JSON.parse(JSON.stringify(this.perfil.protecaoFamiliar))

        protecaoFamiliar.seguros =  protecaoFamiliar.seguros.map(item => {
          if (typeof item._id === 'number') {
            delete item._id
          }

          return item
        })

        const perfil = await updatePerfil(this.perfil._id, { protecaoFamiliar })
        await this.$store.dispatch('setPerfil', perfil)

        this.$noty.success('Dados salvos com sucesso!')
        this.closeDialog()
      } catch (err) {
        this.$noty.error('Desculpe! Não conseguimos salvar seus dados. Tente novamente mais tarde.')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.loader = false
      }
    }
  },
  created() {
    this.$bus.$on("showDialogEditSeguros", this.openDialog)
  }
}
</script>

<style scoped>
.pat-item-title {
  font-size: 12px !important;
  font-weight: bold;
  letter-spacing: .1em !important;
  text-transform: uppercase;
  line-height: 1em;
}

.pat-item-type {
  font-size: 11px;
  letter-spacing: .1em !important;
}

.pat-item-info-desc {
  margin-top: 5px;
}

.pat-item-info-desc div {
  width: 50%;
}
</style>
