import firebase from 'firebase/app';
import httpClient from '../../plugins/httpClient';

/**
 * Get customer`s notes
 * @param plannerId
 * @param customerId
 * @param page
 * @param search
 * @returns {Promise<Array>}
 */
const getCustomerNotes = (plannerId, customerId, page = 1, search = '') => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let headers = { Authorization: `Bearer ${idToken}` };
          let url = `/notes/planners/${plannerId}/customers/${customerId}?page=${page}&search=${search}`;

          return httpClient(url, { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default getCustomerNotes;
