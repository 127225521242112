import firebase from 'firebase/app';
import httpClient from '../../plugins/httpClient';

const updateIndFinanceira = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'PUT';
          let headers = { Authorization: `Bearer ${idToken}` };
          let url = `/independencia-financeira/${id}`;

          return httpClient(url, { headers, method, data });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch (err) {
      reject(err);
    }
  });
};

export default updateIndFinanceira;
