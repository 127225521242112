<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isMobile"
    :persistent="loader"
    max-width="500px"
  >
    <v-card color="grey3" :class="isMobile ? 'no-radius' : ''">
      <v-card-title
        class="dialog-form-title"
        :class="isMobile && isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text">
          {{ asset._id ? 'Editar' : 'Adicionar' }} Ativo
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <div class="input-container">
            <div class="input-label primary--text">Descrição</div>
            <v-text-field
              dense
              color="grey1"
              v-model="asset.descricao"
              :error-messages="descricaoErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-file-document
              </v-icon>
            </v-text-field>
          </div>

          <div class="input-container">
            <div class="input-label primary--text">Valor</div>
            <v-text-field
              dense
              type="tel"
              color="grey1"
              v-money="money"
              v-model="asset.valor"
              :error-messages="valorErrors"
              id="input-insurance-asset-value"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-cash
              </v-icon>
            </v-text-field>
          </div>

          <div class="input-container">
            <div class="input-label primary--text">Estado</div>
            <v-autocomplete
              dense
              :items="estados"
              item-value="sigla"
              item-text="nome"
              color="grey1"
              v-model="asset.estado"
              :error-messages="estadoErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-map
              </v-icon>
            </v-autocomplete>
          </div>

          <div class="input-container">
            <div class="input-label primary--text">Taxas</div>
            <v-text-field
              dense
              type="tel"
              color="grey1"
              v-model.number="asset.taxa"
              :error-messages="taxaErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-percent
              </v-icon>
            </v-text-field>
          </div>

          <div class="input-container">
            <div class="input-label primary--text">Total</div>
            <v-text-field
              dense
              readonly
              color="grey1"
              :value="numberToCurrency(asset.total)"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-cash
              </v-icon>
            </v-text-field>
          </div>

          <v-card-actions class="px-0">
            <v-btn
              text
              small
              rounded
              class="px-5"
              type="button"
              @click="close"
              color="primary"
              :disabled="loader"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              small
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              class="px-5 darkgrey--text"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money'
import numberToCurrency from '@/utils/numberToCurrency';
import removeMoneyMask from '@/utils/removeMoneyMask';
import saveLifeInsuranceInventoryAsset from '@/api/lifeInsurance/saveLifeInsuranceInventoryAsset';

export default {
  name: 'DialogFormInsuranceInventoryAsset',
  directives: { money: VMoney },
  data: () => ({
    numberToCurrency,
    dialog: false,
    loader: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    asset: {
      descricao: '',
      valor: 0,
      estado: '',
      total: 0,
      taxa: 0
    },
    insuranceId: null,
    taxas: {
      AC: 2, AL: 2, AP: 4, AM: 2, BA: 3.5,
      CE: 4, DF: 4, ES: 4, GO: 4, MA: 3,
      MT: 2, MS: 2, MG: 3, PA: 4, PB: 2,
      PR: 4, PE: 2, PI: 2, RJ: 4.5, RN: 3,
      RS: 4, RO: 2, RR: 4, SC: 1, SE: 4,
      TO: 2
    }
  }),
  validations: {
    asset: {
      descricao: {
        isValid(v) {
          return !!v && v.length <= 150
        }
      },
      valor: {
        isValid(v) {
          return !!v && removeMoneyMask(v) > 0
        }
      },
      estado: {
        isValid(v) {
          return !!v && v.length === 2
        }
      },
      taxa: {
        isValid(v) {
          return v !== null && v !== undefined && v !== '' && v >= 0
        }
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    estados() {
      return this.$store.getters.estados;
    },
    descricaoErrors() {
      const errors = []

      if (!this.$v.asset.descricao.$anyDirty) {
        return errors
      }

      !this.$v.asset.descricao.isValid && errors.push('Preencha uma descrição válida')

      return errors
    },
    valorErrors() {
      const errors = []

      if (!this.$v.asset.valor.$anyDirty) {
        return errors
      }

      !this.$v.asset.valor.isValid && errors.push('Preencha um valor válido')

      return errors
    },
    estadoErrors() {
      const errors = []

      if (!this.$v.asset.estado.$anyDirty) {
        return errors
      }

      !this.$v.asset.estado.isValid && errors.push('Selecione um estado')

      return errors
    },
    taxaErrors() {
      const errors = []

      if (!this.$v.asset.taxa.$anyDirty) {
        return errors
      }

      !this.$v.asset.taxa.isValid && errors.push('Preencha uma taxa válida')

      return errors
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;

      this.asset = {
        descricao: '',
        valor: 0,
        estado: '',
        total: 0,
        taxa: 0
      }

      this.resetMoneyInput();
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch()

      if (!this.$v.$anyError) {
        this.loader = true

        const asset = JSON.parse(JSON.stringify(this.asset))

        asset.valor = removeMoneyMask(asset.valor)

        saveLifeInsuranceInventoryAsset(this.insuranceId, asset)
          .then(lifeInsurance => {
            this.$store.dispatch('saveLifeInsurance', lifeInsurance)
            this.close()
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível salvar este ativo. Tente novamente mais tarde!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    },
    resetMoneyInput() {
      const input = document.getElementById('input-insurance-asset-value')
      if (input) {
        input.value = this.asset.valor
        input.dispatchEvent(new Event('input'))
      }
    },
    calcTotal() {
      if (this.asset.taxa) {
        this.asset.total = removeMoneyMask(this.asset.valor) * (this.asset.taxa / 100)
      } else {
        this.asset.total = 0
      }
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.close()
      }
    },
    'asset.estado'() {
      if (this.asset.estado) {
        this.asset.taxa = this.taxas[this.asset.estado] + 8
      } else {
        this.asset.taxa = 0
      }
    },
    'asset.taxa'() {
      this.calcTotal()
    },
    'asset.valor'() {
      this.calcTotal()
    }
  },
  created() {
    this.$bus.$on('showDialogFormInsuranceInventoryAsset', data => {
      if (data.insuranceId) {
        this.insuranceId = data.insuranceId

        if (data.asset) {
          this.asset = JSON.parse(JSON.stringify(data.asset))
          this.asset.valor = this.asset.valor.toFixed(2)
          this.asset.total = this.asset.total.toFixed(2)
          this.resetMoneyInput();
        }

        this.dialog = true
      }
    })
  }
}
</script>

<style scoped></style>
