<template>
  <v-form @submit.prevent="submit">
    <v-container
      grid-list-md
      v-if="usuario"
    >
      <v-layout row wrap>
        <v-flex xs12>
          <span class="primary--text">Razão social</span>
          <v-text-field
            v-model="usuario.razaoSocial"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="razaoSocialErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6>
          <span class="primary--text">Nome fantasia</span>
          <v-text-field
            v-model="usuario.displayName"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="displayNameErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6>
          <span class="primary--text">CNPJ</span>
          <v-text-field
            type="tel"
            v-model="usuario.cpf_cnpj"
            color="grey1"
            class="mt-0 pt-0"
            v-mask="'##.###.###/####-##'"
            :error-messages="cnpjErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <h2 class="title">Endereço</h2>
        </v-flex>

        <v-flex xs12 md3>
          <span class="primary--text">CEP</span>
          <v-text-field
            type="tel"
            v-model="usuario.address.cep"
            color="grey1"
            class="mt-0 pt-0"
            v-mask="'#####-###'"
            :error-messages="cepErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md9>
          <span class="primary--text">Logradouro</span>
          <v-text-field
            v-model="usuario.address.logradouro"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="logradourErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md3>
          <span class="primary--text">Número</span>
          <v-text-field
            type="number"
            v-model.number="usuario.address.numero"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="numeroErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md9>
          <span class="primary--text">Complemento</span>
          <v-text-field
            v-model="usuario.address.complemento"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="complementoErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md4>
          <span class="primary--text">Bairro</span>
          <v-text-field
            v-model="usuario.address.bairro"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="bairroErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md4>
          <span class="primary--text">Cidade</span>
          <v-autocomplete
            v-model="usuario.address.cidade"
            color="grey1"
            class="mt-0 pt-0"
            :items="cidades"
            item-text="nome"
            item-value="nome"
            :error-messages="cidadeErrors"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs12 md4>
          <span class="primary--text">Estado</span>
          <v-autocomplete
            v-model="usuario.address.estado"
            color="grey1"
            class="mt-0 pt-0"
            :items="estados"
            return-object
            item-text="nome"
            :error-messages="estadoErrors"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs12 class="text-right">
          <v-btn
            rounded
            type="submit"
            color="primary"
            :loading="loader"
            class="darkgrey--text"
          >
            Salvar
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { pick } from 'lodash'
import firebase from 'firebase/app'
import * as cnpj from "@fnando/cnpj"
import { required, maxLength, minValue, maxValue } from 'vuelidate/lib/validators'
import getAddressByCep from '@/api/brasil-api/getAddressByCep';
import getCidadesByEstado from '@/api/address/getCidadesByEstado';
import stringCapitalize from '@/utils/stringCapitalize';
import saveUser from '@/api/users/saveUser';

export default {
  name: 'FormCompanyCadastro',
  data: () => ({
    usuario: {
      razaoSocial: '',
      displayName: '',
      cpf_cnpj: '',
      address: {
        cep: '',
        numero: '',
        bairro: '',
        cidade: '',
        logradouro: '',
        complemento: '',
        estado: null
      }
    },
    cidades: [],
    loader: false,
    addressLoader: false
  }),
  validations: {
    usuario: {
      razaoSocial: {
        required,
        max: maxLength(150)
      },
      displayName: {
        required,
        max: maxLength(150)
      },
      cpf_cnpj: {
        isValid: v => cnpj.isValid(v)
      },
      address: {
        cep: {
          isValid: v => v.replace(/\D+/gm, '').length === 8
        },
        numero: {
          min: minValue(1),
          max: maxValue(100000)
        },
        bairro: {
          required,
          max: maxLength(150)
        },
        cidade: {
          required,
          max: maxLength(150)
        },
        logradouro: {
          required,
          max: maxLength(150)
        },
        complemento: {
          max: maxLength(150)
        },
        estado: { required }
      }
    }
  },
  computed: {
    isCompany() {
      return this.$store.getters.user && this.$store.getters.user.role === 'company'
    },
    user() {
      return this.isCompany ? this.$store.getters.user : this.$store.getters.company
    },
    estados() {
      return this.$store.getters.estados
    },
    razaoSocialErrors() {
      if (!this.$v.usuario.razaoSocial.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.razaoSocial.required || !this.$v.usuario.razaoSocial.max) {
        return ['Preencha uma razão social válida']
      }
    },
    displayNameErrors() {
      if (!this.$v.usuario.displayName.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.displayName.required || !this.$v.usuario.displayName.max) {
        return ['Preencha um nome válido']
      }
    },
    cnpjErrors() {
      if (!this.$v.usuario.cpf_cnpj.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.cpf_cnpj.isValid) {
        return ['Preencha um CNPJ válido']
      }
    },
    cepErrors() {
      if (!this.$v.usuario.address.cep.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.cep.isValid) {
        return ['Preencha um CEP válido']
      }
    },
    numeroErrors() {
      if (!this.$v.usuario.address.numero.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.numero.min || !this.$v.usuario.address.numero.max) {
        return ['Preencha um número válido']
      }
    },
    bairroErrors() {
      if (!this.$v.usuario.address.bairro.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.bairro.required || !this.$v.usuario.address.bairro.max) {
        return ['Preencha um bairro válido']
      }
    },
    cidadeErrors() {
      if (!this.$v.usuario.address.cidade.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.cidade.required || !this.$v.usuario.address.cidade.max) {
        return ['Selecione uma cidade válida']
      }
    },
    logradourErrors() {
      if (!this.$v.usuario.address.logradouro.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.logradouro.required || !this.$v.usuario.address.logradouro.max) {
        return ['Preencha um logradouro válido']
      }
    },
    complementoErrors() {
      if (!this.$v.usuario.address.complemento.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.complemento.max) {
        return ['Preencha um complemento válido']
      }
    },
    estadoErrors() {
      if (!this.$v.usuario.address.estado.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.estado.required) {
        return ['Selecione um estado válido']
      }
    }
  },
  methods: {
    copy() {
      if (this.user) {
        this.usuario = pick(this.user, Object.keys(this.usuario))
        this.usuario.cpf_cnpj = cnpj.format(this.usuario.cpf_cnpj)
      }
    },
    getAddress() {
      this.$v.usuario.address.cep.$touch()

      if (!this.$v.usuario.address.cep.$anyError) {
        this.addressLoader = true

        getAddressByCep(this.usuario.address.cep.replace(/\D+/gm, ''))
          .then(address => {
            this.usuario.address.logradouro = address.street
            this.usuario.address.cidade = address.city
            this.usuario.address.bairro = address.neighborhood
            this.usuario.address.estado = this.estados.find(e => e.sigla === address.state.toUpperCase())
            this.addressLoader = false
          })
          .catch(err => {
            console.error(err)
            this.addressLoader = false
          })
      }
    },
    getCidades() {
      if (this.usuario.address.estado) {
        getCidadesByEstado(this.usuario.address.estado.id)
          .then(cidades => {
            this.cidades = cidades
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          const usuario = JSON.parse(JSON.stringify(this.usuario))

          usuario._id = this.user._id
          usuario.cpf_cnpj = usuario.cpf_cnpj.replace(/\D+/gm, '')
          usuario.razaoSocial = stringCapitalize(usuario.razaoSocial)
          usuario.displayName = stringCapitalize(usuario.displayName)
          usuario.address.cep = usuario.address.cep.replace(/\D+/gm, '')

          await firebase.auth().currentUser.updateProfile({ displayName: usuario.displayName })

          const user = await saveUser(usuario)

          this.$store.dispatch('setUser', user)
          this.loader = false
          this.$noty.success('Dados atualizados com sucesso!')
        }
      } catch (err) {
        this.loader = false
        this.$noty.error('Desculpe! Não foi possível salvar seus dados. Tente novamente mais tarde!')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      }
    }
  },
  watch: {
    'usuario.address.cep'() {
      this.getAddress()
    },
    'usuario.address.estado'() {
      this.getCidades()
    }
  },
  mounted() {
    this.copy()
  }
}
</script>
