import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const companyEmployeePaymentLink = employeeEmail => {
  firebase.auth().currentUser.getIdToken(true)
    .then(idToken => {
      const url = `/messages/employees?email=${employeeEmail}`
      const options = {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }

      return httpClient(url, options)
    })
    .catch(console.error)
}

export default companyEmployeePaymentLink
