<template>
  <v-main class="safe-area-top safe-area-bottom">
    <v-container grid-list-md class="mb-10 pb-10">
      <v-layout row wrap>
        <v-flex xs12 class="mb-5">
          <h1 class="payment-methods-title">Meios de Pagamento<span class="primary--text">.</span></h1>
        </v-flex>

        <v-flex xs12 class="mb-5">
          <v-btn
            text
            rounded
            color="primary"
            @click="addPaymentMethod"
          >
            <v-icon class="mr-2">mdi-credit-card-plus-outline</v-icon>
            Alterar cartão
          </v-btn>
        </v-flex>

        <v-flex
          xs12 sm6 lg4
          :key="item.id"
          v-for="item in meiosPagamento"
        >
          <v-card
            color="grey3"
            :class="showCard === item.id ? 'elevation-6' : 'elevation-1'"
          >
            <v-card-title class="payment-method-card-title primary--text">
              {{ item.description }}
            </v-card-title>

            <v-card-text>
              <div class="item-info">
                <span class="item-info-desc">{{ item.data.holder_name }}</span>
                <span class="item-info-title">Proprietário</span>
              </div>

              <div class="item-info">
                <span class="item-info-desc">{{ item.data.display_number }}</span>
                <span class="item-info-title">Número</span>
              </div>

              <div class="item-info">
                <span class="item-info-desc">{{ item.data.month }}/{{ item.data.year }}</span>
                <span class="item-info-title">Válido até</span>
              </div>

              <div class="item-info">
                <span class="item-info-desc">{{ item.data.brand }}</span>
                <span class="item-info-title">Bandeira</span>
              </div>

              <div class="text-center">
                <v-btn
                  icon
                  color="error"
                  :loading="deleteLoader"
                  @click.stop="removePaymentMethod(item.id)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <DialogFormMeioPagamento/>
    <DialogDeleteMeioPagamento/>
  </v-main>
</template>

<script>
import getIuguCustomerPaymentMethods from '@/api/iugu/paymentMethods/getIuguCustomerPaymentMethods';
import DialogFormMeioPagamento from '@/components/Customer/MeiosPagamento/dialogs/DialogFormMeioPagamento';
import DialogDeleteMeioPagamento from '@/components/Customer/MeiosPagamento/dialogs/DialogDeleteMeioPagamento';

export default {
  name: 'MeiosPagamento',
  components: { DialogDeleteMeioPagamento, DialogFormMeioPagamento },
  data: () => ({
    loader: false,
    deleteLoader: false,
    showCard: null,
  }),
  computed: {
    user() {
      return this.$store.getters.user
    },
    meiosPagamento() {
      return this.$store.getters.meiosPagamento
    }
  },
  methods: {
    getPaymentMethods() {
      if (this.user && this.user.iuguId && this.user.iuguSubscription) {
        this.loader = true

        getIuguCustomerPaymentMethods(this.user.iuguId)
          .then(data => {
            this.$store.dispatch('setMeiosPagamento', data)
            this.loader = false
          })
          .catch(err => {
            this.loader = false

            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    },
    toggleCard(cardId) {
      this.showCard = this.showCard !== cardId ? cardId : null
    },
    removePaymentMethod(paymentMethodId) {
      this.$bus.$emit('showDialogDeleteMeioPagamento', paymentMethodId)
    },
    addPaymentMethod() {
      this.$bus.$emit('showDialogFormMeioPagamento')
    }
  },
  created() {
    this.getPaymentMethods()
  }
}
</script>

<style scoped>
@import "../../styles/payment-methods.css";

@media only screen and (min-width: 960px) {
  .container {
    max-width: 768px !important;
  }
}

@media only screen and (min-width: 1264px) {
  .container {
    max-width: 1200px !important;
  }
}
</style>
