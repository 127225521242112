<template>
  <v-card color="grey3" height="100%">
    <v-card-text class="d-flex flex-column align-start">
      <v-icon color="primary" class="mb-1">{{ icon }}</v-icon>

      <div class="d-flex align-center justify-space-between" style="width: 100%">
        <div class="d-flex flex-column align-start">
          <span class="total-number">
            {{ total }}
            <small class="grey--text" v-if="totalParts">/ {{totalParts}}</small>
          </span>

          <span class="total-number-desc primary--text">
            {{ description }}
          </span>
        </div>

        <div>
          <v-tooltip top v-if="tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-on="on"
                  color="grey"
                  v-bind="attrs"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>{{ tooltip }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CardTotalEmployess',
  props: [
    'total',
    'description',
    'icon',
    'totalParts',
    'tooltip'
  ]
};
</script>

<style scoped>
.total-number {
  font-size: 15px;
  font-weight: bold;
}

.total-number-desc {
  font-size: 9px;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 375px) {
  .total-number {
    font-size: 15px;
  }
}

@media only screen and (min-width: 414px) {
  .total-number {
    font-size: 18px;
  }

  .total-number-desc {
    font-size: 10px;
  }
}
</style>
