<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card v-if="user" color="grey2">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          class="mr-2"
          color="white"
          :disabled="loader"
          @click.stop="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span>Alterar planejador de {{firstName}}</span>
      </v-card-title>

      <v-card-title>
        <v-form @submit.prevent="submit" style="width: 100%">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 v-if="user.planner">
                <span class="input-label primary--text">Planejador(a) atual</span>
                <v-text-field
                  dense
                  disabled
                  color="grey1"
                  class="mt-0 pt-0"
                  :value="user.planner.displayName"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Selecione o novo planejador(a)</span>
                <v-autocomplete
                  dense
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="plannerId"
                  :items="planners"
                  item-text="displayName"
                  item-value="_id"
                  :error-messages="plannerIdErrors"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions>
            <v-btn
              rounded
              outlined
              type="button"
              color="grey1"
              :disabled="loader"
              @click="closeDialog"
              class="text-capitalize px-5"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              dark
              rounded
              type="submit"
              color="success"
              :loading="loader"
              class="text-capitalize px-5"
            >
              Alterar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import changeUserPlanner from "@/api/users/changeUserPlanner"
import getPlannersNames from "@/api/planners/getPlannersNames";

export default {
  name: 'DialogChangeUserPlaner',
  data: () => ({
    user: null,
    dialog: false,
    loader: false,
    plannerId: null
  }),
  validations: {
    plannerId: {required}
  },
  computed: {
    planners() {
      if (this.user) {
        return this.$store.getters.plannersNames.filter(p => {
          return p.isUplanner && (!this.user.planner || p._id !== this.user.planner._id)
        });
      }

      return []
    },
    firstName() {
      if (this.user) {
        return this.user.displayName.split(/\s+/gm)[0]
      }

      return ''
    },
    plannerIdErrors() {
      if (!this.$v.plannerId.$anyDirty) {
        return []
      }
      if (this.$v.plannerId.$anyError) {
        return ['Selecione um planejador(a) válido']
      }
    }
  },
  methods: {
    openDialog(user) {
      if (user) {
        this.getPlannersNames()

        this.user = user
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false

      this.user = null
      this.loader = false
      this.plannerId = null
    },
    async getPlannersNames() {
      try {
        const planners = await getPlannersNames()
        await this.$store.dispatch('setPlannersNames', planners)
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.closeDialog()
      }
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          await changeUserPlanner({
            userId: this.user._id,
            plannerId: this.plannerId
          })

          this.closeDialog()
          this.$noty.success('Planejador alterado com sucesso!')
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.loader = false
        this.$noty.error('Falha ao alterar planejador')
      }
    }
  },
  created() {
    this.$bus.$on('showDialogChangeUserPlanner', this.openDialog)
  }
}
</script>
