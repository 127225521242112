<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card dark color="#282828">
      <v-card-title>Redefina sua senha</v-card-title>
      <v-card-subtitle>Insira sua nova senha abaixo!</v-card-subtitle>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-text-field
            dark
            dense
            outlined
            rounded
            :type="showPass ? 'text' : 'password'"
            class="mt-1"
            label="Senha"
            v-model="password"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            :error-messages="passwordErrors"
            @input="$v.password.$touch()"
          ></v-text-field>

          <v-text-field
            dark
            dense
            outlined
            rounded
            :type="showRepeatPass ? 'text' : 'password'"
            class="mt-1"
            label="Repita a senha"
            v-model="repeatPassword"
            :append-icon="showRepeatPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showRepeatPass = !showRepeatPass"
            :error-messages="repeatPasswordErrors"
            @input="$v.repeatPassword.$touch()"
          ></v-text-field>

          <v-card-actions class="pa-0">
            <v-btn
              text
              rounded
              class="px-5"
              type="button"
              :disabled="loader"
              @click.stop.prevent="close"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              rounded
              depressed
              class="px-5 black--text"
              type="submit"
              color="primary"
              :loading="loader"
            >
              Redefinir
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {required, minLength, maxLength, sameAs} from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import logout from "../../utils/logout";

  export default {
    name: 'DialogResetPassword',
    data: () => ({
      dialog: false,
      loader: false,
      password: '',
      repeatPassword: '',
      oobCode: '',
      showPass: false,
      showRepeatPass: false
    }),
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.password = '';
        this.repeatPassword = '';
        this.oobCode = '';
        this.$v.$reset();
      },
      submit() {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          firebase.auth().confirmPasswordReset(this.oobCode, this.password)
            .then(() => {
              this.close();
              logout();
              this.$noty.success('Senha redefinida com sucesso!');
            })
            .catch(err => {
              if (process.env.NODE_ENV !== 'production') console.error(err);
              this.loader = false;
              this.$noty.warning('Seu link expirou! Por favor, faça uma nova requisição para redefinir sua senha!');
            });
        }
      }
    },
    validations: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(32)
      },
      repeatPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(32),
        sameAsPassword: sameAs('password')
      }
    },
    computed: {
      passwordErrors() {
        const errors = [];
        if (!this.$v.password.$dirty) return errors;
        !this.$v.password.required && errors.push('Senha é obrigatória');
        !this.$v.password.minLength && errors.push('A senha deve conter, no mínimo, 8 caracteres');
        !this.$v.password.maxLength && errors.push('A senha deve conter, no máximo, 32 caracteres');
        return errors;
      },
      repeatPasswordErrors() {
        const errors = [];
        if (!this.$v.repeatPassword.$dirty) return errors;
        !this.$v.repeatPassword.required && errors.push('Senha é obrigatória');
        !this.$v.repeatPassword.minLength && errors.push('A senha deve conter, no mínimo, 8 caracteres');
        !this.$v.repeatPassword.maxLength && errors.push('A senha deve conter, no máximo, 32 caracteres');
        !this.$v.repeatPassword.sameAsPassword && errors.push('As senhas devem ser iguais');
        return errors;
      }
    },
    created() {
      this.$bus.$on('showDialogResetPassword', query => {
        firebase.auth().verifyPasswordResetCode(query.oobCode)
          .then(() => {
            this.oobCode = query.oobCode;
            this.dialog = true;
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production')
              console.error(err);
          })
      })
    }
  };
</script>

<style scoped></style>
