import firebase from "firebase/app";
import httpClient from "@/plugins/httpClient";

export default (plannerId, supervisor) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const url = `/users/${plannerId}/change-supervisor`

        const options = {
          method: 'POST',
          data: { supervisor },
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        return httpClient(url, options)
      })
      .then(resp => resolve())
      .catch(reject)
  })
}
