<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
  >
    <v-card class="no-radius">
      <v-card-title
        class="dialog-form-title px-2"
        :class="isIOS ? ['ios-padding', 'fixed-title'] : ''"
      >
        <v-btn
          icon
          class="mr-5"
          color="primary"
          @click.stop="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text">Filhos</span>
      </v-card-title>

      <v-card-text class="px-2" :class="isMobile ? 'mobile-margin' : ''">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 v-for="filho in filhos" :key="filho._id">
                <v-layout row wrap class="px-0 mx-0">
                  <v-flex xs12 sm9 md3>
                    <span class="input-label primary--text">Nome</span>
                    <v-text-field
                      type="text"
                      color="grey1"
                      class="mt-0 pt-0"
                      v-model="filho.nome"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm3 md2>
                    <span class="input-label primary--text">Idade</span>
                    <v-text-field
                      type="number"
                      color="grey1"
                      class="mt-0 pt-0"
                      v-model.number="filho.idade"
                      suffix="anos"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm4 md2>
                    <span class="input-label primary--text">Custo mensal</span>
                    <v-text-field
                      type="tel"
                      v-money="money"
                      color="grey1"
                      class="mt-0 pt-0"
                      v-model="filho.custoMensal"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs10 sm7 md4>
                    <span class="input-label primary--text">Descrição do custo</span>
                    <v-text-field
                      type="text"
                      color="grey1"
                      class="mt-0 pt-0"
                      v-model="filho.descricao"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs2 sm1 md1 class="pt-5 text-right">
                    <v-btn
                      icon
                      color="error"
                      @click="removeSon(filho._id)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-flex>

                  <v-flex xs12 class="my-2">
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 class="my-5 text-center">
                <v-btn
                  dark
                  rounded
                  small
                  color="purple"
                  class="px-5"
                  :disabled="loader"
                  @click.stop="addSon"
                >
                  Adicionar filho
                </v-btn>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="pa-0 ma-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    rounded
                    color="primary"
                    class="px-5 darkgrey--text"
                    :loading="loader"
                    @click.stop="submit"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money'
import stringCapitalize from '@/utils/stringCapitalize';
import removeMoneyMask from '@/utils/removeMoneyMask';
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'DialogEditSons',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    filhos: []
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    perfil() {
      return this.$store.getters.perfil
    }
  },
  methods: {
    openDialog() {
      if (this.perfil) {
        this.filhos = JSON.parse(JSON.stringify(this.perfil.sons)).map(son => {
          son.custoMensal = son.custoMensal.toFixed(2)
          return son
        })

        if (!this.filhos.length) {
          this.addSon()
        }

        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.filhos = []
    },
    addSon() {
      this.filhos.push({
        _id: `${Date.now()}`,
        nome: '',
        descricao: '',
        idade: '',
        custoMensal: 'R$ 0,00'
      })
    },
    removeSon(id) {
      const index = this.filhos.findIndex(son => son._id === id)
      if (index > -1) {
        this.filhos.splice(index, 1)
      }

      if (!this.filhos.length) {
        this.addSon()
      }
    },
    async submit() {
      try {
        this.loader = true

        let sons = JSON.parse(JSON.stringify(this.filhos))

        sons = sons.map(son => {
          const obj = {
            _id: son._id,
            nome: stringCapitalize(son.nome),
            idade: Number.parseInt(son.idade),
            descricao: son.descricao.trim(),
            custoMensal: removeMoneyMask(son.custoMensal)
          }

          if (!son._id.replace(/\d+/g, '').length) {
            delete obj._id
          }

          return obj
        }).filter(son => {
          return (
            (typeof son.nome === 'string' && son.nome.length <= 256) &&
            (typeof son.idade === 'number' && !Number.isNaN(son.idade) && son.idade >= 0 && son.idade <= 100) &&
            (!son.descricao || (typeof son.descricao === 'string' && son.descricao.length <= 256)) &&
            (typeof son.custoMensal === 'number' && son.idade >= 0)
          )
        })

        const hasSons = !!sons.length

        const perfil = await updatePerfil(this.perfil._id, { sons, hasSons })
        await this.$store.dispatch('setPerfil', perfil)

        this.$noty.success('Dados salvos com sucesso!')
        this.closeDialog()
      } catch (err) {
        this.$noty.error('Desculpe, não foi possível salvar seus dados. Tente novamente mais tarde.')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.loader = false
      }
    }
  },
  created() {
    this.$bus.$on('showDialogEditSons', this.openDialog)
  }
}
</script>

<style scoped>
.v-card__title.dialog-form-title.fixed-title {
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 10;
}

.v-card__text.mobile-margin {
  padding-top: 90px !important;
}
</style>
