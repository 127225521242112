<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card v-if="transaction">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="error"
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="error--text ml-4">Remover</span>
      </v-card-title>

      <v-card-text>
        <p class="dialog-form-description">Tem certeza que deseja remover esta transação?</p>

        <v-card-actions
          class="px-0 dialog-form-btn-container"
          v-if="transaction.fixed || transaction.divided"
        >
          <v-btn
            block
            rounded
            class="mb-2"
            color="error"
            :loading="loader && !type"
            :disabled="loader && !!type"
            @click="submit('')"
          >
            Remover apenas essa
          </v-btn>

          <v-btn
            block
            rounded
            class="mb-2"
            color="error"
            :loading="loader && type === 'next'"
            :disabled="loader && type !== 'next'"
            @click="submit('next')"
          >
            Remover essa e as próximas
          </v-btn>

          <v-btn
            block
            rounded
            color="error"
            @click="submit('all')"
            :loading="loader && type === 'all'"
            :disabled="loader && type !== 'all'"
          >
            Remover todas
          </v-btn>
        </v-card-actions>

        <v-card-actions class="px-0" v-else>
          <v-spacer></v-spacer>

          <v-btn
            fab
            color="error"
            @click="submit('')"
            :loading="loader"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import deleteTransfer from '../../../../api/transactions/deleteTransfer';
import deleteTransaction from '../../../../api/transactions/deleteTransaction';
import deleteCreditCardTransaction from '../../../../api/transactions/deleteCreditCardTransaction';
import removeMoneyMask from '../../../../utils/removeMoneyMask';

export default {
  name: 'DialogRemoveTransaction',
  data: () => ({
    dialog: false,
    loader: false,
    transaction: null,
    openedInvoice: null,
    type: ''
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.deleteType = '';
      this.transaction = null;
    },
    submit(deleteType) {
      this.loader = true;
      this.type = deleteType;

      let promises = [];

      if (this.transaction.isTransfer)
        promises.push(deleteTransfer(this.transaction.transferId, this.transaction.fixedId, deleteType));
      else if (this.transaction.creditCard)
        promises.push(deleteCreditCardTransaction(this.transaction, this.transaction.fixedId, deleteType, this.openedInvoice));
      else
        promises.push(deleteTransaction(this.transaction._id, deleteType));

      Promise.all(promises)
        .then(results => {
          let resp = results[0];
          if (resp) {
            if (resp.isPaid || resp.hasPaid) {
              this.loader = false
              this.$noty.warning('Você não pode remover despesas de faturas pagas!');
            } else {
              if (resp.bankAccount)
                this.$store.dispatch('updateBankAccountBalance', resp.bankAccount);

              if (resp.bankAccounts) {
                resp.bankAccounts.forEach(ba => {
                  this.$store.dispatch('updateBankAccountBalance', ba);
                });
              }

              if (resp.invoice) {
                this.$store.dispatch('updateInvoiceValue', resp.invoice);
              } else if (this.transaction.invoice) {
                let invoice = this.$store.getters.invoices.find(inv => {
                  return inv._id === this.transaction.invoice._id;
                });

                if (invoice) {
                  invoice.value -= removeMoneyMask(this.transaction.value);
                  this.$store.dispatch('updateInvoiceValue', invoice);
                }
              }

              if (resp.invoices) {
                resp.invoices.forEach(invoice => {
                  this.$store.dispatch('updateInvoiceValue', invoice);
                });
              }

              if (resp.creditCard && resp.creditCard._id)
                this.$store.dispatch('updateCreditCardLimit', resp.creditCard);

              if (resp.creditCards) {
                resp.creditCards.forEach(cc => {
                  this.$store.dispatch('updateCreditCardLimit', cc);
                });
              }

              if (this.transaction.creditCard) {
                this.$store.dispatch('removeTransaction', {
                  _id: this.transaction._id,
                  fixedId: this.transaction.fixedId,
                  dividedId: this.transaction.dividedId,
                  type: deleteType
                });
              } else {
                this.$store.dispatch('removeTransaction', {
                  _id: this.transaction._id,
                  type: deleteType
                });
              }

              this.close();
            }
          }
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
          this.$noty.error('Desculpe! Não foi possível remover esta transação. Tente novamente!');
        });
    },
    requestOpenedInvoice() {
      this.$bus.$emit('requestOpenedInvoice')
    },
    getOpenedInvoice(openedInvoice) {
      if (openedInvoice) {
        this.openedInvoice = openedInvoice
      }
    }
  },
  created() {
    this.$bus.$on('showDialogRemoveTransaction', transaction => {
      if (transaction) {
        this.requestOpenedInvoice()

        this.transaction = transaction;
        this.dialog = true;
      }
    });

    this.$bus.$on('getOpenedInvoice', this.getOpenedInvoice)
  }
};
</script>
