<template>
  <v-dialog
    fullscreen
    persistent
    v-model="dialog"
  >
    <v-card color="grey2" class="no-radius" v-if="user">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button mr-5"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="white--text">
          Configurações Calendly
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 class="mb-5">
              <h1 class="primary--text events-title">
                Eventos de {{ displayName }}
              </h1>
            </v-flex>

            <v-flex
              xs12
              :key="item._id"
              v-for="item in eventTypes"
            >
              <v-card color="darkgrey">
                <v-progress-linear
                  :background-color="item.attributes.color"
                  :color="item.attributes.color"
                  value="0"
                ></v-progress-linear>

                <div class="event-card-title">
                  <div class="d-flex flex-column">
                    <span>{{ item.attributes.name }}</span>
                    <small class="grey1--text">{{ item.attributes.duration }} min</small>
                  </div>
                </div>

                <div class="event-card-form">
                  <div class="d-flex align-center">
                    <div class="d-flex align-center mr-2">
                      <v-checkbox
                        color="primary"
                        v-model="item.isExtra"
                        :disabled="isExtraEvent && item._id !== isExtraEvent._id"
                      ></v-checkbox>
                      <span
                        @click="checkIsExtra(item)"
                        class="input-label primary--text checkbox-label"
                      >
                        Reunião extra
                      </span>
                    </div>

                    <div class="d-flex align-center">
                      <v-checkbox
                        color="primary"
                        v-model="item.isTrial"
                        :disabled="isTrialEvent && item._id !== isTrialEvent._id"
                      ></v-checkbox>
                      <span
                        @click="checkIsTrial(item)"
                        class="input-label primary--text checkbox-label"
                      >
                        Reunião Trial
                      </span>
                    </div>
                  </div>

                  <div>
                    <span class="input-label primary--text">Períodos</span>
                    <v-select
                      multiple
                      color="grey1"
                      :items="ranges"
                      item-text="text"
                      class="mt-0 pt-0"
                      item-value="value"
                      v-model="item.range"
                      @change="uniqueDays(item)"
                    ></v-select>
                  </div>
                </div>
              </v-card>
            </v-flex>

            <v-flex xs12>
              <v-btn
                large
                block
                rounded
                @click="submit"
                color="primary"
                :loading="loader"
                class="darkgrey--text"
              >
                Concluir
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { difference } from 'lodash'
import saveUser from '@/api/users/saveUser';

export default {
  name: 'DialogSetupCalendly',
  data: () => ({
    dialog: false,
    loader: false,
    periods: [],
    user: null,
    eventTypes: [],
    ranges: [
      {
        text: '1ª reunião',
        value: 1
      },
      {
        text: '2ª reunião',
        value: 2
      },
      {
        text: '3ª reunião',
        value: 3
      },
      {
        text: '4ª reunião',
        value: 4
      },
      {
        text: '5ª reunião',
        value: 5
      },
      {
        text: '6ª reunião',
        value: 6
      },
      {
        text: '7ª reunião',
        value: 7
      },
      {
        text: '8ª reunião',
        value: 8
      },
      {
        text: '9ª reunião',
        value: 9
      },
      {
        text: '10ª reunião',
        value: 10
      },
      {
        text: '11ª reunião',
        value: 11
      },
      {
        text: '12ª reunião',
        value: 12
      }
    ]
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isExtraEvent() {
      return this.eventTypes.find(e => e.isExtra)
    },
    isTrialEvent() {
      return this.eventTypes.find(e => e.isTrial)
    },
    displayName() {
      if (this.user) {
        return this.user.displayName.split(/\s+/gm)[0]
      }
      return ''
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.loader = false
    },
    uniqueDays(item) {
      this.eventTypes.forEach(e => {
        if (e._id !== item._id) {
          if (e.range) {
            e.range = difference(e.range, item.range)
          }
        }
      })
    },
    checkIsExtra(item) {
      if (!(this.isExtraEvent && item._id !== this.isExtraEvent._id)) {
        item.isExtra = !item.isExtra
      }
    },
    checkIsTrial(item) {
      if (!(this.isTrialEvent && item._id !== this.isTrialEvent._id)) {
        item.isTrial = !item.isTrial
      }
    },
    submit() {
      if (this.user) {
        this.loader = true

        let user = JSON.parse(JSON.stringify(this.user))

        user.calendly.eventTypes = this.eventTypes

        saveUser(user)
          .then(() => {
            this.$bus.$emit('managerGetUsers');
            this.$noty.success('Integração concluída com sucesso!')
            this.close()
          })
          .catch(err => {
            this.loader = false
            this.$noty.error('Falha ao integrar planejador. Tente novamente mais tarde!')

            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.eventTypes = []
        this.user = null
      }
    }
  },
  created() {
    this.$bus.$on('showDialogSetupCalendly', user => {
      if (user) {
        this.user = JSON.parse(JSON.stringify(user))
        this.eventTypes = JSON.parse(JSON.stringify(this.user.calendly.eventTypes))
        this.dialog = true
      }
    })
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
}

.events-title {
  font-size: 20px;
  letter-spacing: .1em;
  font-weight: normal;
}

.event-card-title {
  padding: 16px;
  display: flex;
  align-items: center;
}

.event-card-form {
  padding: 0 16px;
}

.event-card-title span {
  font-size: 12px;
  letter-spacing: .1em;
  line-height: 1.5em;
}

.event-card-title small {
  font-size: 10px;
  letter-spacing: .1em;
  line-height: 1.5em;
}

.checkbox-label {
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .event-card-title span {
    font-size: 15px;
  }

  .event-card-title small {
    font-size: 12px;
  }
}
</style>
