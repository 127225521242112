import firebase from "firebase/app"
import httpClient from "@/plugins/httpClient"

export default itemId => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const url = `/pluggy-items/${itemId}/anonymize`

        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        return httpClient(url, options)
      })
      .then(resp => resolve())
      .catch(reject)
  })
}
