<template>
  <v-container grid-list-md class="pa-0 ma-0">
    <v-layout row wrap class="pa-0 ma-0">
      <v-flex xs12>
        <span class="input-label primary--text">A qual cartão a fatura pertence?</span>
        <v-select
          dense
          color="grey1"
          item-text="name"
          class="mt-0 pt-0"
          v-model="creditCard"
          :items="creditCards"
          item-value="_id"
          :error-messages="creditCardErrors"
        ></v-select>
      </v-flex>

      <v-flex xs12>
        <span class="input-label primary--text">Selecione a fatura que foi paga</span>
        <v-select
          dense
          color="grey1"
          item-text="text"
          class="mt-0 pt-0"
          v-model="invoice"
          :items="invoices"
          item-value="value"
          :loading="invoiceLoader"
          :error-messages="invoiceErrors"
        ></v-select>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import getInvoice from "@/api/invoices/getInvoice";

export default {
  name: 'FormPgtoFatura',
  data: () => ({
    date: null,
    invoice: null,
    invoiceId: null,
    isPaid: false,
    creditCard: null,
    invoiceLoader: false
  }),
  validations: {
    creditCard: {
      isValid(v) {
        return !!v
      }
    },
    invoice: {
      isValid(v) {
        return !!v && !this.isPaid
      }
    }
  },
  computed: {
    creditCards() {
      return this.$store.getters.creditCards.filter(cc => !cc.archived)
    },
    invoices() {
      const invoices = []

      if (this.date) {
        for (let i = 0; i < 7; i++) {
          invoices.push({
            value: this.date.format('YYYY-MM'),
            text: this.date.format('MMMM YYYY').toUpperCase()
          })

          this.date = this.date.add(1, 'month')
        }
      }

      return invoices
    },
    invoiceErrors() {
      if (!this.$v.invoice.$anyDirty)
        return []

      if (this.isPaid)
        return ['Essa fatura já está paga']
      else if (this.$v.invoice.$anyError)
        return ['Selecione uma fatura válida']
    },
    creditCardErrors() {
      if (!this.$v.creditCard.$anyDirty)
        return []

      if (this.$v.creditCard.$anyError)
        return ['Selecione um cartão válido']
    },
  },
  methods: {
    setDefaultDates() {
      this.isPaid = false
      this.date = this.$date().subtract(6, 'month')
    },
    async checkInvoicePayment() {
      try {
        this.invoiceLoader = true

        const invoice = await getInvoice(this.creditCard, this.invoice)

        this.isPaid = invoice.paid
        this.invoiceId = invoice._id
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.invoice = null
        this.invoiceId = null
      } finally {
        this.$v.invoice.$touch()
        this.invoiceLoader = false
      }
    }
  },
  watch: {
    invoice() {
      this.checkInvoicePayment()
    },
    creditCard() {
      if (this.invoice) {
        this.checkInvoicePayment()
      }
    }
  },
  mounted() {
    this.setDefaultDates()
  }
}
</script>
