import { render, staticRenderFns } from "./CardInsuranceInventario.vue?vue&type=template&id=1e22c9e8&scoped=true&"
import script from "./CardInsuranceInventario.vue?vue&type=script&lang=js&"
export * from "./CardInsuranceInventario.vue?vue&type=script&lang=js&"
import style0 from "./CardInsuranceInventario.vue?vue&type=style&index=0&id=1e22c9e8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e22c9e8",
  null
  
)

export default component.exports