<template>
  <v-dialog
    persistent
    fullscreen
    v-model="dialog"
  >
    <v-card class="no-radius" color="grey2">
      <v-app-bar fixed dense color="darkgrey">
        <v-btn
          icon
          color="primary"
          :disabled="loadLoader"
          @click.stop="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-app-bar-title class="primary--text">Cadastro de Cliente</v-app-bar-title>
      </v-app-bar>

      <v-card-text style="padding-top: 60px">
        <v-form @submit.prevent="submit" ref="customerForm">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="title primary--text">Dados pessoais</span>
              </v-flex>

              <v-flex xs12>
                <span class="input-label white--text">Nome completo</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="pt-0 mt-0"
                  :loading="loadLoader"
                  v-model="customer.displayName"
                  :error-messages="displayNameErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md4>
                <span class="input-label white--text">CPF</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="pt-0 mt-0"
                  v-mask="'###.###.###-##'"
                  v-model="customer.cpf_cnpj"
                  :error-messages="cpfErrors"
                  id="customer-cpf"
                ></v-text-field>
              </v-flex>

              <v-flex xs2 sm2 md4>
                <span class="input-label white--text">Código do país</span>
                <v-text-field
                  prefix="+"
                  type="tel"
                  color="grey1"
                  v-mask="'#####'"
                  class="pt-0 mt-0"
                  v-model="customer.phoneCountryCode"
                  :error-messages="phoneCountryCodeErrors"
                  hint="Código de telefone do país"
                  persistent-hint
                  id="customer-phone-country-code"
                ></v-text-field>
              </v-flex>

              <v-flex xs10 sm10 md4>
                <span class="input-label white--text">Celular</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="pt-0 mt-0"
                  v-mask="'####################'"
                  :error-messages="phoneNumberErrors"
                  v-model="customer.phoneNumber"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md3>
                <span class="input-label white--text">CEP</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="pt-0 mt-0"
                  v-mask="'#####-###'"
                  :error-messages="addressCepErrors"
                  v-model="customer.address.cep"
                  hint="Preencha com um CEP brasileiro"
                  persistent-hint
                  :loading="cepLoader"
                  id="customer-cep"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md6>
                <span class="input-label white--text">Logradouro</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="pt-0 mt-0"
                  :error-messages="addressLogradouroErrors"
                  v-model="customer.address.logradouro"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md3>
                <span class="input-label white--text">Número</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="pt-0 mt-0"
                  v-mask="'######'"
                  :error-messages="addressNumeroErrors"
                  v-model="customer.address.numero"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md4>
                <span class="input-label white--text">Bairro</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="pt-0 mt-0"
                  :error-messages="addressBairroErrors"
                  v-model="customer.address.bairro"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md4>
                <span class="input-label white--text">Cidade</span>
                <v-autocomplete
                  color="grey1"
                  class="pt-0 mt-0"
                  :error-messages="addressCidadeErrors"
                  v-model="customer.address.cidade"
                  :items="cidades"
                  item-text="nome"
                  item-value="nome"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 sm12 md4>
                <span class="input-label white--text">Estado</span>
                <v-autocomplete
                  color="grey1"
                  class="pt-0 mt-0"
                  return-object
                  :error-messages="addressEstadoErrors"
                  v-model="customer.address.estado"
                  :items="estados"
                  item-text="nome"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12>
                <span class="input-label white--text">Complemento</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="pt-0 mt-0"
                  :error-messages="addressComplementoErrors"
                  v-model="customer.address.complemento"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 v-if="user.role === 'admin'">
                <span class="title primary--text">Dados de planejamento</span>
              </v-flex>

              <v-flex xs12 v-if="user.role === 'admin'">
                <v-switch
                  flat
                  inset
                  color="primary"
                  label="É um cliente UPlanner"
                  v-model="customer.isUplanner"
                ></v-switch>
              </v-flex>

              <v-flex xs12 sm12 md6 v-if="['admin', 'comercial'].includes(user.role)">
                <span class="input-label white--text">Empresa</span>
                <v-autocomplete
                  color="grey1"
                  class="pt-0 mt-0"
                  v-model="customer.company"
                  :items="companies"
                  item-text="displayName"
                  item-value="_id"
                  clearable
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 sm12 md6 v-if="user.role === 'admin' && !customer._id">
                <span class="input-label white--text">Planejador</span>
                <v-autocomplete
                  color="grey1"
                  class="pt-0 mt-0"
                  v-model="customer.planner"
                  :items="planners"
                  item-text="displayName"
                  item-value="_id"
                  clearable
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 v-if="!customer._id">
                <span class="title primary--text">Dados de acesso</span>
              </v-flex>

              <v-flex xs12 sm12 md6 v-if="!customer._id">
                <span class="input-label white--text">E-mail</span>
                <v-text-field
                  type="email"
                  color="grey1"
                  class="pt-0 mt-0"
                  :error-messages="emailErrors"
                  v-model="customer.email"
                  :loading="emailLoader"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md3 v-if="!customer._id && !customer.company">
                <span class="input-label white--text">Senha</span>
                <v-text-field
                  type="password"
                  color="grey1"
                  class="pt-0 mt-0"
                  :error-messages="passwordErrors"
                  v-model="password"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md3 v-if="!customer._id && !customer.company">
                <span class="input-label white--text">Repita a senha</span>
                <v-text-field
                  type="password"
                  color="grey1"
                  class="pt-0 mt-0"
                  :error-messages="repeatPasswordErrors"
                  v-model="repeatPassword"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="pa-0 ma-0">
                  <v-btn
                    rounded
                    outlined
                    class="px-5"
                    type="button"
                    color="grey1"
                    :disabled="loadLoader"
                    @click.stop="closeDialog"
                  >
                    Cancelar
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    rounded
                    type="submit"
                    color="primary"
                    :loading="loader"
                    :disabled="loadLoader"
                    class="px-5 darkgrey--text"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import getIuguPlanById from '@/api/iugu/plans/getIuguPlanById';

const CryptoJS = require('crypto-js')

import { pick } from 'lodash'
import * as cpf from "@fnando/cpf"
import { required, email } from 'vuelidate/lib/validators'

import getPlannersNames from '@/api/planners/getPlannersNames'
import getCompaniesNames from '@/api/companies/getCompaniesNames'
import getUserById from '@/api/users/getUserById'
import checkEmailAlreadyUsed from '@/api/users/checkEmailAlreadyUsed'
import getAddressByCep from '@/api/brasil-api/getAddressByCep'
import getCidadesByEstado from '@/api/address/getCidadesByEstado'
import stringCapitalize from '@/utils/stringCapitalize'
import changeIuguSubscriptionPlan from '@/api/iugu/subscriptions/changeIuguSubscriptionPlan';
import createPiperunPerson from '@/api/piperun/createPiperunPerson';
import createPiperunDeal from '@/api/piperun/createPiperunDeal';
import saveUser from '@/api/users/saveUser';
import companyEmployeePaymentLink from '@/api/messages/companyEmployeePaymentLink';

export default {
  name: 'DialogFormCustomer',
  data: () => ({
    dialog: false,
    loader: false,
    cepLoader: false,
    loadLoader: false,
    emailLoader: false,
    customer: {
      displayName: '',
      email: '',
      cpf_cnpj: '',
      phoneCountryCode: '55',
      phoneNumber: '',
      company: '',
      planner: '',
      isUplanner: true,
      address: {
        cep: '',
        numero: '',
        logradouro: '',
        bairro: '',
        cidade: '',
        estado: null,
        complemento: ''
      }
    },
    password: '',
    repeatPassword: '',
    cidades: []
  }),
  validations: {
    password: {
      isValid(v) {
        return !!this.customer._id || (!!v && v.length >= 8 && v.length <= 48)
      }
    },
    repeatPassword: {
      isSame(v) {
        return !!this.customer._id || (!!v && v === this.password);
      }
    },
    customer: {
      email: { required, email },
      cpf_cnpj: {
        isValid: v => !!v && cpf.isValid(v)
      },
      displayName: {
        isValid: v => !!v && v.trim().length <= 150 && v.split(/\s+/g).length > 1
      },
      phoneNumber: {
        isValid: v => !!v && v.length >= 4 && v.length <= 48
      },
      phoneCountryCode: {
        isValid: v => !!v && v.length <= 5
      },
      address: {
        cep: {
          isValid: v => !!v && v.length === 9
        },
        numero: {
          isValid: v => !!v && v > 0 && v < 100000
        },
        cidade: {
          isValid: v => !!v && v.length <= 256
        },
        logradouro: {
          isValid: v => !!v && v.length <= 256
        },
        bairro: {
          isValid: v => !!v && v.length <= 256
        },
        estado: { required },
        complemento: {
          isValid: v => !v || v.length <= 256
        }
      }
    }
  },
  computed: {
    // error messages
    addressComplementoErrors() {
      if (!this.$v.customer.address.complemento.$anyDirty) {
        return [];
      }

      if (this.$v.customer.address.complemento.$anyError) {
        return ['Preencha um complemento válido'];
      }
    },
    addressEstadoErrors() {
      if (!this.$v.customer.address.estado.$anyDirty) {
        return [];
      }

      if (this.$v.customer.address.estado.$anyError) {
        return ['Preencha um estado válido'];
      }
    },
    addressBairroErrors() {
      if (!this.$v.customer.address.bairro.$anyDirty) {
        return [];
      }

      if (this.$v.customer.address.bairro.$anyError) {
        return ['Preencha um bairro válido'];
      }
    },
    addressLogradouroErrors() {
      if (!this.$v.customer.address.logradouro.$anyDirty) {
        return [];
      }

      if (this.$v.customer.address.logradouro.$anyError) {
        return ['Preencha um logradouro válido'];
      }
    },
    addressCidadeErrors() {
      if (!this.$v.customer.address.cidade.$anyDirty) {
        return [];
      }

      if (this.$v.customer.address.cidade.$anyError) {
        return ['Preencha uma cidade válida'];
      }
    },
    addressNumeroErrors() {
      if (!this.$v.customer.address.numero.$anyDirty) {
        return [];
      }

      if (this.$v.customer.address.numero.$anyError) {
        return ['Preencha um número válido'];
      }
    },
    addressCepErrors() {
      if (!this.$v.customer.address.cep.$anyDirty) {
        return [];
      }

      if (this.$v.customer.address.cep.$anyError) {
        return ['Preencha um cep válido'];
      }
    },
    phoneNumberErrors() {
      if (!this.$v.customer.phoneNumber.$anyDirty) {
        return [];
      }

      if (this.$v.customer.phoneNumber.$anyError) {
        return ['Preencha um celular válido'];
      }
    },
    phoneCountryCodeErrors() {
      if (!this.$v.customer.phoneCountryCode.$anyDirty) {
        return [];
      }

      if (this.$v.customer.phoneCountryCode.$anyError) {
        return ['Preencha um código válido'];
      }
    },
    displayNameErrors() {
      if (!this.$v.customer.displayName.$anyDirty) {
        return [];
      }

      if (this.$v.customer.displayName.$anyError) {
        return ['Preencha um nome válido'];
      }
    },
    cpfErrors() {
      if (!this.$v.customer.cpf_cnpj.$anyDirty) {
        return [];
      }

      if (this.$v.customer.cpf_cnpj.$anyError) {
        return ['Preencha um cpf válido'];
      }
    },
    emailErrors() {
      if (!this.$v.customer.email.$anyDirty) {
        return [];
      }

      if (this.$v.customer.email.$anyError || this.emailUsed) {
        return ['Preencha um e-mail válido'];
      }
    },
    passwordErrors() {
      if (!this.$v.password.$anyDirty) {
        return [];
      }

      if (this.$v.password.$anyError) {
        return ['Preencha uma senha válida'];
      }
    },
    repeatPasswordErrors() {
      if (!this.$v.repeatPassword.$anyDirty) {
        return [];
      }

      if (this.$v.repeatPassword.$anyError) {
        return ['As senhas devem ser iguais'];
      }
    },
    // form data
    planners() {
      return this.$store.getters.plannersNames.filter(p => p.isUplanner === this.customer.isUplanner);
    },
    companies() {
      return this.$store.getters.companiesNames;
    },
    estados() {
      return this.$store.getters.estados;
    },
    // helpers
    isIOS() {
      return this.$store.getters.isIOS;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    async openDialog(userId) {
      try {
        this.dialog = true;

        if (userId) {
          this.loadLoader = true;

          const user = await getUserById(userId);

          this.customer = {
            ...this.customer,
            ...pick(user, [
              '_id', 'displayName', 'email', 'cpf_cnpj',
              'address', 'phoneCountryCode', 'phoneNumber', 'plan',
              'company', 'planner', 'isUplanner', 'iuguId', 'iuguSubscription',
              'idPiperunPerson', 'idPiperunDeal'
            ])
          };

          if (this.customer.company && this.customer.company._id) {
            this.customer.company = this.customer.company._id
          }

          if (this.customer.planner && this.customer.planner._id) {
            this.customer.planner = this.customer.planner._id
          }

          this.updateInputs()
          this.loadLoader = false;
        }

        if (['admin', 'comercial'].includes(this.user.role)) {
          await Promise.all([
            this.getPlanners(),
            this.getCompanies()
          ]);
        }
      } catch (err) {
        this.closeDialog();
        this.$noty.error('Falha ao abrir formulário. Tente novamente mais tarde.');

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      }
    },
    closeDialog() {
      this.dialog = false;
      this.loader = false;
      this.loadLoader = false;
      this.emailLoader = false;
      this.emailUsed = false;
      this.cidades = [];
      this.password = '';
      this.repeatPassword = '';
      this.$refs.customerForm.reset()
      this.customer = {
        displayName: '',
        email: '',
        cpf_cnpj: '',
        phoneCountryCode: '55',
        phoneNumber: '',
        company: '',
        planner: '',
        isUplanner: true,
        address: {
          cep: '',
          numero: '',
          logradouro: '',
          bairro: '',
          cidade: '',
          estado: null,
          complemento: ''
        }
      };
      this.$v.$reset();
    },
    updateInputs() {
      let input = document.getElementById('customer-cpf')

      if (input) {
        input.value = this.customer.cpf_cnpj
        input.dispatchEvent(new Event('input'))
      }

      input = document.getElementById('customer-phone-country-code')

      if (input) {
        input.value = this.customer.phoneCountryCode
        input.dispatchEvent(new Event('input'))
      }

      input = document.getElementById('customer-cep')

      if (input) {
        input.value = this.customer.address.cep
        input.dispatchEvent(new Event('input'))
      }
    },
    async getPlanners() {
      try {
        const planners = await getPlannersNames();
        await this.$store.dispatch('setPlannersNames', planners);
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      }
    },
    async getCompanies() {
      try {
        const companies = await getCompaniesNames();
        await this.$store.dispatch('setCompaniesNames', companies);
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      }
    },
    async checkEmail() {
      try {
        if (this.customer.email) {
          this.emailLoader = true;

          const email = this.customer.email.trim().toLowerCase()
          const response = await checkEmailAlreadyUsed(email)

          this.emailUsed = response.exists;
        } else {
          this.emailUsed = false;
        }
      } catch (err) {
        this.emailUsed = false;

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.emailLoader = false;
      }
    },
    async submit() {
      try {
        this.loader = true

        if (!this.customer._id) {
          await this.checkEmail()
        }

        this.$v.$touch();

        if (this.customer._id) {
          this.$v.password.$reset()
          this.$v.repeatPassword.$reset()
          this.$v.customer.email.$reset()
        }

        if (!this.customer._id && this.customer.company) {
          this.$v.password.$reset()
          this.$v.repeatPassword.$reset()
        }

        if (
          !this.$v.customer.$anyError &&
          !this.$v.password.$anyError &&
          !this.$v.repeatPassword.$anyError && !this.emailUsed
        ) {
          let user = JSON.parse(JSON.stringify(this.customer))

          delete user.repeatPassword

          if (!user._id && !user.company) {
            user.password = this.password
          }

          user.email = user.email.toLowerCase().trim()
          user.displayName = stringCapitalize(user.displayName)
          user.cpf_cnpj = user.cpf_cnpj.replace(/\D+/g, '')
          user.address.cep = user.address.cep.replace(/\D+/g, '')

          if (this.user.role === 'planner') {
            user.isUplanner = this.user.isUplanner
            user.planner = this.user._id
          }

          if (!user.isUplanner) {
            user.locked = false
          }

          if (!user.company) {
            user.company = null
          }

          if (!user.planner) {
            user.planner = null
          }

          const promises = []

          if (user.company) {
            if (user.iuguSubscription) {
              const company = this.companies.find(cp => cp._id === user.company)

              if (company && company.companyPayment && company.companyPayment.plans && company.companyPayment.plans.length) {
                const plansPromise = []

                for (let i = 0; i < company.companyPayment.plans.length; i++) {
                  plansPromise.push(getIuguPlanById(company.companyPayment.plans[i]))
                }

                const plans = await Promise.all(plansPromise)
                let plan = null

                if (user.plan.identifier.includes('familia')) {
                  plan = plans.find(p => p.identifier.includes('familia'))
                } else if (user.plan.identifier.includes('individual')) {
                  plan = plans.find(p => p.identifier.includes('individual'))
                }

                if (plan) {
                  user.plan = plan
                  promises.push(changeIuguSubscriptionPlan(user.iuguSubscription, plan.identifier))
                }
              }
            }

            // if (!user.idPiperunPerson) {
            //   const person = await createPiperunPerson({
            //     name: user.displayName,
            //     contact_emails: [ { email: user.email } ],
            //     contact_phones: [ { phone: `${user.phoneCountryCode}${user.phoneNumber}` } ]
            //   })
            //
            //   user.idPiperunPerson = person.id
            // }

            // if (!user.idPiperunDeal) {
            //   const deal = await createPiperunDeal({
            //     person_id: user.idPiperunPerson,
            //     title: user.displayName
            //   })
            //
            //   user.idPiperunDeal = deal.id
            // }
          }

          promises.push(saveUser(user))

          await Promise.all(promises)

          if (!user._id && user.company) {
            companyEmployeePaymentLink(user.email)
          }

          this.$bus.$emit('managerGetUsers')
          this.closeDialog()
        }
      } catch (err) {
        this.$noty.error('Falha ao salvar cliente. Tente novamente mais tarde.');

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.loader = false;
        this.emailLoader = false;
        this.cepLoader = false;
      }
    },
    async getCidades() {
      try {
        if (this.customer.address.estado) {
          this.cidades = await getCidadesByEstado(this.customer.address.estado.id);
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      }
    },
    async getAddress() {
      try {
        this.cepLoader = true;
        const cep = this.customer.address.cep.replace(/\D+/g, '');
        const address = await getAddressByCep(cep);

        this.customer.address.logradouro = address.street;
        this.customer.address.bairro = address.neighborhood;
        this.customer.address.cidade = address.city;
        this.customer.address.estado = this.estados.find(e => e.sigla === address.state);

        await this.getCidades();
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.cepLoader = false;
      }
    }
  },
  watch: {
    'customer.address.cep'(val) {
      if (!!val && val.length === 9) {
        this.getAddress();
      }
    }
  },
  created() {
    this.$bus.$on('showFormCustomer', this.openDialog);
  }
};
</script>

<style scoped>
.container {
  max-width: 768px;
}
</style>
