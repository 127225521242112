<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="768px"
  >
    <v-card>
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          class="mr-2"
          color="primary"
          :disabled="loader"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text">Importar transações</span>
      </v-card-title>

      <v-card-title class="pt-2">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <span class="input-label primary--text">Tipo de arquivo</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  :items="fileTypes"
                  item-text="text"
                  item-value="value"
                  v-model="fileType"
                  :disabled="fileTypes.length === 1"
                ></v-select>
              </v-flex>

              <v-flex xs12 md4>
                <span class="input-label primary--text">Tipo de importação</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  :items="accountTypes"
                  item-text="text"
                  item-value="value"
                  v-model="accountType"
                ></v-select>
              </v-flex>

              <v-flex xs12 md4>
                <span class="input-label primary--text">Selecionar {{ labelAccount }}</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  :items="accounts"
                  item-text="name"
                  item-value="_id"
                  v-model="account"
                  :error-messages="accountErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Selecionar arquivo</span>
                <v-file-input
                  color="grey1"
                  class="mt-0 pt-0"
                  accept=".ofx"
                  v-model="file"
                  prepend-icon=""
                  :error-messages="fileErrors"
                  prepend-inner-icon="mdi-paperclip"
                ></v-file-input>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="pa-y ma-0">
                  <v-btn
                    outlined
                    rounded
                    color="grey1"
                    class="px-5 text-capitalize"
                    type="button"
                    @click="closeDialog"
                    :disabled="loader"
                  >
                    Cancelar
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    rounded
                    class="px-5 text-capitalize darkgrey--text"
                    type="submit"
                    :loading="loader"
                    color="primary"
                  >
                    Importar
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import importTransactionsFromOFX from "@/api/transactions/importTransactionsFromOFX";

export default {
  name: 'DialogImportTransactionsFromFile',
  data: () => ({
    dialog: false,
    loader: false,
    accountType: 'bank-account',
    accountTypes: [
      {
        text: 'Conta bancária',
        value: 'bank-account'
      },
      {
        text: 'Cartão de crédito',
        value: 'credit-card'
      }
    ],
    file: [],
    account: null,
    fileType: 'ofx',
    fileTypes: [
      {
        text: 'OFX',
        value: 'ofx'
      }
    ]
  }),
  validations: {
    account: {
      isValid(v) {
        return this.accountType === 'bank-account' || !!v
      }
    },
    file: {required},
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    fileErrors() {
      if (!this.$v.file.$anyDirty)
        return []

      if (this.$v.file.$anyError)
        return ['Selecione um arquivo válido']
    },
    accountErrors() {
      if (!this.$v.account.$anyDirty)
        return []

      if (this.$v.account.$anyError)
        return ['Selecione uma conta/cartão válido']
    },
    creditCards() {
      return this.$store.getters.creditCards.filter(cc => {
        return !cc.archived && !cc.pluggy
      })
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts.filter(ba => {
        return !ba.archived && !ba.pluggy
      })
    },
    accounts() {
      if (this.accountType === 'credit-card') {
        return this.creditCards
      }

      return this.bankAccounts
    },
    labelAccount() {
      if (this.accountType === 'credit-card')
        return 'cartão'

      return 'conta'
    },
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === 'customer'
    },
    user() {
      if (!this.isCustomer)
        return this.$store.getters.customer

      return this.$store.getters.user
    }
  },
  methods: {
    openDialog() {
      if (!this.isMobile) {
        this.dialog = true
        this.account = this.accounts[0]._id
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false

      this.file = []
      this.creditCard = null
      this.bankAccount = null
      this.accountType = 'bank-account'

      this.$v.$reset()
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          const importData = {
            user: this.user._id,
            accountType: this.accountType
          }

          if (this.accountType === 'bank-account') {
            importData.bankAccount = this.account
          } else {
            importData.creditCard = this.account
          }

          const toBase64 = file => {
            return new Promise((resolve, reject) => {
              let reader = new FileReader()

              reader.readAsDataURL(file)

              reader.onload = () => {
                resolve(reader.result)
                reader = null
              }

              reader.onerror = (error) => reject(error)
            })
          }

          importData.fileBase64 = await toBase64(this.file)

          const {
            isEmpty,
            isInvalid,
            creditCard,
            bankAccount,
            transactions
          } = await importTransactionsFromOFX(importData)

          console.log({
            isEmpty,
            isInvalid,
            creditCard,
            bankAccount,
            transactions
          })


          if (isEmpty){
            this.$noty.warning('Nenhuma transação encontrada!')
            this.closeDialog()
          } else if (isInvalid) {
            this.$noty.warning('Arquivo inválido! Verifique o tipo de importação!')
          } else {
            if (creditCard)
              await this.$store.dispatch('saveCreditCard', creditCard)

            if (bankAccount)
              await this.$store.dispatch('saveBankAccount', bankAccount)

            transactions.forEach(tr => this.$store.dispatch('saveTransaction', tr))

            this.closeDialog()
            this.$noty.success('Transações importadas com sucesso!')
          }
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.$noty.error('Desculpe! Não conseguimos importar as transações deste arquivo. Tente novamente mais tarde!')
      } finally {
        this.loader = false
      }
    }
  },
  watch: {
    accounts() {
      if (this.accounts.length)
        this.account = this.accounts[0]._id
    }
  },
  created() {
    this.$bus.$on('showDialogImportTransactionsFromFile', this.openDialog)
  }
}
</script>
