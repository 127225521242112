<template>
  <v-main class="margin-menu pb-10 mb-10 safe-area-top">
    <OverlaySync :overlay="loader" text="Preparando relatório..."/>

    <MobilePageTitle :title="title" :loader="loader"/>

    <v-container grid-list-md v-if="reports">
      <v-layout row wrap>
        <v-flex xs12 class="hidden-sm-and-down text-center">
          <h1 class="main-title">{{ title }}</h1>
          <span class="grey1--text report-sub-title">{{ filterDesc }}</span>
        </v-flex>

        <v-flex xs12>
          <span class="text-uppercase primary--text report-title">Filtrar</span>
          <p class="grey1--text report-sub-title hidden-md-and-up">
            {{ filterDesc }}
          </p>
        </v-flex>

        <v-flex xs12>
          <v-layout row wrap>
            <v-flex lg2 md3 sm4 xs12>
              <v-select
                label="Selecionar período"
                color="primary"
                persistent-hint
                :items="filters"
                item-text="text"
                v-model="filter"
                item-value="value"
                item-color="primary"
                :hint="formatedPeriod"
                :menu-props="{ auto: true, overflowY: true }"
              ></v-select>
            </v-flex>

            <v-scroll-x-transition>
              <v-flex lg1 md2 sm3 xs6 v-if="filter === 'custom'">
                <v-menu
                  ref="menu"
                  min-width="290px"
                  v-model="startMenu"
                  :close-on-click="false"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-on="on"
                      :value="formatStartDate"
                      label="Período inicial"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    type="month"
                    color="primary"
                    v-model="startDate"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-scroll-x-transition>

            <v-scroll-x-transition>
              <v-flex lg1 md2 sm3 xs6 v-if="filter === 'custom'">
                <v-menu
                  ref="menu"
                  v-model="endMenu"
                  min-width="290px"
                  :close-on-click="false"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-on="on"
                      label="Período final"
                      :value="formatEndDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    type="month"
                    color="primary"
                    v-model="endDate"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-scroll-x-transition>

            <v-flex
              lg2
              :offset-lg5="filter === 'custom'"
              :offset-lg8="filter !== 'custom'"
              md3
              :offset-md6="filter !== 'custom'"
              :offset-md2="filter === 'custom'"
              :sm4="filter !== 'custom'"
              :offset-sm4="filter !== 'custom'"
              :sm12="filter === 'custom'"
              :xs12="filter !== 'customer'"
              :xs9="filter === 'custom'"
              class="my-2"
            >
              <span
                @click="advanced = !advanced"
                class="advanced-filter text-sm-right"
                :class="filter !== 'custom' ? 'text-xs-center' : 'text-right'"
              >
                Filtro avançado
                <v-icon v-if="!advanced">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-chevron-up</v-icon>
              </span>
            </v-flex>

            <v-expand-transition>
              <v-flex xs12 v-if="advanced">
                <v-layout row wrap>
                  <v-flex xs12 sm4 md3 lg2>
                    <v-switch
                      flat
                      color="primary"
                      v-model="future"
                      label="Projetar futuro"
                    ></v-switch>
                  </v-flex>

                  <v-flex xs12 sm8 md3>
                    <v-select
                      label="Filtrar por conta bancária"
                      color="primary"
                      persistent-hint
                      :items="bankAccountsFilter"
                      item-text="name"
                      v-model="selectedBankAccount"
                      item-value="_id"
                      item-color="primary"
                      :menu-props="{ auto: true, overflowY: true }"
                    >
                      <template v-slot:item="{ item, index }">
                        <v-icon small class="mr-2" :color="item.color"
                        >mdi-bank
                        </v-icon>
                        <span>{{ item.name }}</span>
                      </template>

                      <template v-slot:selection="{ item, index }">
                        <v-chip small outlined :color="item.color">
                          <span>{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-expand-transition>

            <v-flex xs12 sm12 md2 offset-md5>
              <v-btn
                dark
                block
                rounded
                class="px-5"
                color="purple"
                @click="getReports"
              >
                Filtrar
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <span class="text-uppercase primary--text report-section-title"
          >Geral</span
          >
        </v-flex>

        <v-flex xs12 md4>
          <CardFluxoCaixaMensal
            :labels="labels"
            :transactions="reports.fluxoCaixaMensal"
          />
        </v-flex>

        <v-flex xs12 md4>
          <CardFluxoCaixaTotal :transactions="reports.fluxoCaixaTotal"/>
        </v-flex>

        <v-flex xs12 md4>
          <CardSaldoMensal :labels="labels" :transactions="reports.balancoMensal"/>
        </v-flex>

        <v-flex xs12 class="mt-10 d-flex align-center justify-space-between">
          <span class="text-uppercase primary--text report-section-title">Receita</span>

          <span
            @click="advancedReceita = !advancedReceita"
            class="advanced-filter text-right ma-0"
          >
            Filtrar por categoria
            <v-icon v-if="!advancedReceita">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-up</v-icon>
          </span>
        </v-flex>

        <v-expand-transition>
          <v-flex xs12 v-if="advancedReceita">
            <v-layout row wrap>
              <v-flex xs12 sm6 md4 lg3>
                <v-select
                  label="Filtrar por categoria"
                  :items="categoriesReceita"
                  item-text="name"
                  return-object
                  color="primary"
                  item-color="primary"
                  v-model="categoryReceita"
                >
                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color">{{
                        item.icon
                      }}
                    </v-icon>
                    <span class="white--text">{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <v-icon small class="mr-2" :color="item.color">{{
                          item.icon
                        }}
                      </v-icon>
                      <span class="white--text">{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md4 lg3>
                <v-select
                  label="Filtrar por sub-categoria"
                  :items="subCategoriesReceita"
                  item-text="name"
                  item-value="_id"
                  color="primary"
                  item-color="primary"
                  v-model="subCategoryReceita"
                >
                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="categoryReceita.color">
                      {{ categoryReceita.icon }}
                    </v-icon>
                    <span class="white--text">{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="categoryReceita.color">
                      <v-icon small class="mr-2" :color="categoryReceita.color">
                        {{ categoryReceita.icon }}
                      </v-icon>
                      <span class="white--text">{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12 sm3 md2 lg1>
                <v-btn dark block rounded color="purple" @click="getReports">
                  Filtrar
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-expand-transition>

        <v-flex xs12 md4>
          <CardReceitaMensal
            :labels="labels"
            :transactions="reports.receitaMensal"
            :start-date="startDate"
            :end-date="endDate"
            :future="future"
          />
        </v-flex>

        <v-flex xs12 md4>
          <CardReceitaTotal
            :transactions="reports.receitaTotal"
            :category="categoryReceita"
          />
        </v-flex>

        <v-flex xs12 md4>
          <CardReceitaTotalContas :transactions="reports.receitaTotalConta"/>
        </v-flex>

        <v-flex xs12 class="mt-10 d-flex align-center justify-space-between">
          <span class="text-uppercase primary--text report-section-title"
          >Despesa</span
          >

          <span
            @click="advancedDespesa = !advancedDespesa"
            class="advanced-filter text-right ma-0"
          >
            Filtrar por Categoria
            <v-icon v-if="!advancedDespesa">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-up</v-icon>
          </span>
        </v-flex>

        <v-expand-transition>
          <v-flex xs12 v-if="advancedDespesa">
            <v-layout row wrap>
              <v-flex xs12 sm6 md4 lg3>
                <v-select
                  label="Filtrar por categoria"
                  :items="categoriesDespesa"
                  item-text="name"
                  return-object
                  color="primary"
                  item-color="primary"
                  v-model="categoryDespesa"
                >
                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color"
                    >{{ item.icon }}
                    </v-icon>
                    <span class="white--text">{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <v-icon small class="mr-2" :color="item.color"
                      >{{ item.icon }}
                      </v-icon>
                      <span class="white--text">{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md4 lg3>
                <v-select
                  label="Filtrar por sub-categoria"
                  :items="subCategoriesDespesa"
                  item-text="name"
                  item-value="_id"
                  color="primary"
                  item-color="primary"
                  v-model="subCategoryDespesa"
                >
                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="categoryDespesa.color"
                    >{{ categoryDespesa.icon }}
                    </v-icon>
                    <span class="white--text">{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="categoryDespesa.color">
                      <v-icon small class="mr-2" :color="categoryDespesa.color"
                      >{{ categoryDespesa.icon }}
                      </v-icon>
                      <span class="white--text">{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12 sm3 md2 lg1>
                <v-btn dark block rounded color="purple" @click="getReports">
                  Filtrar
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-expand-transition>

        <v-flex xs12 md4>
          <CardDespesaMensal
            :labels="labels"
            :transactions="reports.despesaMensal"
            :start-date="startDate"
            :end-date="endDate"
            :future="future"
          />
        </v-flex>

        <v-flex xs12 md4>
          <CardDespesaTotal
            :transactions="reports.despesaTotal"
            :category="categoryDespesa"
          />
        </v-flex>

        <v-flex xs12 md4>
          <CardDespesaTotalContas :transactions="reports.despesaTotalConta"/>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <span class="text-uppercase primary--text report-section-title">
            Investimento e Patrimônio
          </span>
        </v-flex>

        <v-flex xs12 md4>
          <CardInvestimentoMensal
            :labels="labels"
            :transactions="reports.fluxoCaixaMensal[2].data"
            :start-date="startDate"
            :end-date="endDate"
            :future="future"
          />
        </v-flex>

        <v-flex xs12 md4>
          <CardPatrimonioMensal :labels="labels" :transactions="reports.patrimonio"/>
        </v-flex>

        <v-flex xs12 md4>
          <CardAtivosMensal :labels="labels" :transactions="reports.ativos"/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { orderBy } from 'lodash';
import OverlaySync from '../../components/Global/OverlaySync';
import getLoggedUserData from '../../utils/getLoggedUserData';
import getUserReports from '../../api/reports/getUserReports';
import CardFluxoCaixaMensal from '../../components/Customer/Reports/CardFluxoCaixaMensal';
import CardFluxoCaixaTotal from '../../components/Customer/Reports/CardFluxoCaixaTotal';
import CardSaldoMensal from '../../components/Customer/Reports/CardSaldoMensal';
import CardReceitaMensal from '../../components/Customer/Reports/CardReceitaMensal';
import CardDespesaMensal from '../../components/Customer/Reports/CardDespesaMensal';
import CardInvestimentoMensal from '../../components/Customer/Reports/CardInvestimentoMensal';
import CardReceitaTotal from '../../components/Customer/Reports/CardReceitaTotal';
import CardDespesaTotal from '../../components/Customer/Reports/CardDespesaTotal';
import CardReceitaTotalContas from '../../components/Customer/Reports/CardReceitaTotalContas';
import CardDespesaTotalContas from '../../components/Customer/Reports/CardDespesaTotalConta';
import CardPatrimonioMensal from '../../components/Customer/Reports/CardPatrimonioMensal';
import CardAtivosMensal from '../../components/Customer/Reports/CardAtivosMensal';
import getCategoriesUser from '@/api/categoriesUser/getCategoriesUser';
import MobilePageTitle from '@/components/Global/MobilePageTitle';

export default {
  name: 'Relatorios',
  components: {
    MobilePageTitle,
    CardAtivosMensal,
    CardPatrimonioMensal,
    CardDespesaTotalContas,
    CardReceitaTotalContas,
    CardDespesaTotal,
    CardReceitaTotal,
    CardInvestimentoMensal,
    CardDespesaMensal,
    CardReceitaMensal,
    CardSaldoMensal,
    CardFluxoCaixaTotal,
    CardFluxoCaixaMensal,
    OverlaySync
  },
  data: () => ({
    loader: false,
    reports: null,
    startDate: null,
    endDate: null,
    currentDate: null,
    filter: 'current_year',
    title: 'Relatórios',
    filters: [
      { text: 'Este mês', value: 'current_month' },
      { text: 'Últimos 3 meses', value: 'last_3_months' },
      { text: 'Últimos 6 meses', value: 'last_6_months' },
      { text: 'Últimos 12 meses', value: 'last_12_months' },
      { text: 'Este ano', value: 'current_year' },
      { text: 'Escolher período', value: 'custom' }
    ],
    startMenu: false,
    endMenu: false,
    future: false,
    advanced: false,
    categoryReceita: {
      name: 'Todas',
      _id: '',
      color: 'primary',
      icon: 'mdi-dots-horizontal'
    },
    subCategoryReceita: '',
    categoryDespesa: {
      name: 'Todas',
      _id: '',
      color: 'primary',
      icon: 'mdi-dots-horizontal'
    },
    subCategoryDespesa: '',
    selectedBankAccount: '',
    advancedReceita: false,
    advancedDespesa: false,
    filterDesc:
      'Use os filtros para manipular as informações que deseja visualizar.'
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    formatedPeriod() {
      if (this.startDate && this.endDate) {
        let start = this.$date(this.startDate).format('MMM YYYY');
        let end = this.$date(this.endDate).format('MMM YYYY');
        return `De ${start} a ${end}`;
      }

      return '';
    },
    formatStartDate() {
      if (this.startDate) {
        return this.$date(this.startDate).format('MMM YYYY');
      }
    },
    formatEndDate() {
      if (this.startDate) {
        return this.$date(this.endDate).format('MMM YYYY');
      }
    },
    labels() {
      let labels = [];

      let diff = this.$date(this.endDate).diff(this.startDate, 'month');

      if (!diff) diff = 1;

      let date = this.$date(this.startDate);

      for (let i = 0; i <= diff; i++) {
        if (date.year() !== this.currentDate.year()) {
          labels.push(date.format('MMM YY'));
        } else {
          labels.push(date.format('MMM'));
        }

        date = date.add(1, 'month');
      }

      return labels;
    },
    categories() {
      return this.$store.getters.categories;
    },
    categoriesReceita() {
      let categories = orderBy(
        this.categories.filter((t) => t.type === 'receita'),
        'name'
      );
      categories.unshift({
        name: 'Todas',
        _id: '',
        color: 'primary',
        icon: 'mdi-dots-horizontal'
      });
      return categories;
    },
    subCategoriesReceita() {
      let subs;

      if (
        this.categoryReceita &&
        this.categoryReceita.subs &&
        this.categoryReceita.subs.length
      ) {
        subs = orderBy(this.categoryReceita.subs, 'name');
        subs.unshift({ name: 'Todas', _id: '' });
      } else {
        subs = [{ name: 'Todas', _id: '' }];
      }

      return subs;
    },
    categoriesDespesa() {
      let categories = orderBy(
        this.categories.filter((t) => t.type === 'despesa'),
        'name'
      );
      categories.unshift({
        name: 'Todas',
        _id: '',
        color: 'primary',
        icon: 'mdi-dots-horizontal'
      });
      return categories;
    },
    subCategoriesDespesa() {
      let subs;

      if (
        this.categoryDespesa &&
        this.categoryDespesa.subs &&
        this.categoryDespesa.subs.length
      ) {
        subs = orderBy(this.categoryDespesa.subs, 'name');
        subs.unshift({ name: 'Todas', _id: '' });
      } else {
        subs = [{ name: 'Todas', _id: '' }];
      }

      return subs;
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts.filter((ba) => !ba.archived);
    },
    bankAccountsFilter() {
      let accounts = orderBy(this.bankAccounts, 'name');
      accounts.unshift({ name: 'Todas', _id: '', color: 'primary' });
      return accounts;
    }
  },
  methods: {
    getData() {
      if (this.user) {
        this.getReports();
      } else {
        this.loader = true;
        getLoggedUserData().then(() => {
          this.getReports();
        });
      }
    },
    getReports() {
      let endDate = this.endDate;
      this.loader = true;
      if (this.filter === 'custom' && this.endDate.length === 7) {
        const lastDayInMonth = this.$date(this.endDate).daysInMonth();
        endDate = `${this.endDate}-${lastDayInMonth}`;
      }

      getUserReports(
        this.user._id,
        this.startDate,
        endDate,
        this.future,
        this.selectedBankAccount,
        this.categoryReceita._id,
        this.subCategoryReceita,
        this.categoryDespesa._id,
        this.subCategoryDespesa
      )
        .then((reports) => {
          this.reports = reports;
          this.loader = false;
        })
        .catch((err) => {
          this.loader = false;
          this.$router.push('/cashflow');
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    },
    goBack() {
      window.history.back();
    },
    prepareDates() {
      if (this.filter === 'current_month') {
        let period = this.currentDate.format('YYYY-MM');
        this.startDate = period + '-01';
        this.endDate = period + '-' + this.currentDate.daysInMonth();
      } else if (this.filter === 'last_3_months') {
        let date = this.$date(this.currentDate.format('YYYY-MM-DD')).subtract(
          2,
          'month'
        );
        let period = date.format('YYYY-MM');
        this.startDate = period + '-01';
        this.endDate =
          this.currentDate.format('YYYY-MM') +
          '-' +
          this.currentDate.daysInMonth();
      } else if (this.filter === 'last_6_months') {
        let date = this.$date(this.currentDate.format('YYYY-MM-DD')).subtract(
          5,
          'month'
        );
        let period = date.format('YYYY-MM');
        this.startDate = period + '-01';
        this.endDate =
          this.currentDate.format('YYYY-MM') +
          '-' +
          this.currentDate.daysInMonth();
      } else if (this.filter === 'last_12_months') {
        let date = this.$date(this.currentDate.format('YYYY-MM-DD')).subtract(
          11,
          'month'
        );
        let period = date.format('YYYY-MM');
        this.startDate = period + '-01';
        this.endDate =
          this.currentDate.format('YYYY-MM') +
          '-' +
          this.currentDate.daysInMonth();
      } else if (this.filter === 'current_year') {
        let year = this.currentDate.year();
        this.startDate = `${year}-01-01`;
        this.endDate = `${year}-12-31`;
      }
    },
    updateRelatorio() {
      this.getReports();
    },
    getCategories() {
      getCategoriesUser(this.user._id)
        .then((categories) => {
          this.$store.dispatch('setCategories', categories);
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    }
  },
  beforeMount() {
    this.$vuetify.theme.dark = true;
  },
  watch: {
    filter() {
      if (this.filter !== 'custom') {
        this.prepareDates();
      }
    }
  },
  created() {
    this.currentDate = this.$date();

    if (this.isMobile) {
      this.filter = 'last_3_months';
    }

    this.prepareDates();
  },
  mounted() {
    this.getData();

    this.$bus.$on('updateRelatorio', () => {
      this.updateRelatorio();
    });

    if (!this.categories.length) {
      this.getCategories();
    }
  }
};
</script>

<style scoped>
@import '../../styles/reports-charts.css';

.container {
  width: 100% !important;
}

.main-title {
  font-size: 20px;
  color: white;
  letter-spacing: 0.1em;
  font-weight: 500;
  text-align: center;
  margin-top: 50px;
}

.report-title {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.report-sub-title {
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 1.5em;
}

.advanced-filter {
  display: block;
  margin-top: 10px;
  text-align: center;
  color: #d1ac68;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
}

.advanced-filter .v-icon {
  color: #d1ac68;
  margin-bottom: 3px;
}

.report-section-title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

@media only screen and (min-width: 960px) {
  .report-sub-title {
    display: block;
    text-align: center;
  }
}
</style>
