<template>
  <v-card flat color="grey3">
    <v-card-title class="primary--text seguro-vida main-title">Despesas com funeral</v-card-title>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-container class="pa-0">
          <v-layout class="pa-0" row wrap>
            <v-flex xs12 sm6 offset-sm3 md4 offset-md4>
              <span class="seguro-vida input-label primary--text">Valor</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                v-money="money"
                v-model="despesasFuneral.value"
                :error-messages="valueErrors"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-flower-outline
                </v-icon>
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            type="submit"
            color="primary"
            class="seguro-vida darkgrey--text px-5"
            :loading="loader"
            :disabled="!allowed"
          >
            Salvar
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'
import removeMoneyMask from '@/utils/removeMoneyMask'
import updateLifeInsurace from '@/api/lifeInsurance/updateLifeInsurance'

export default {
  name: 'CardInsuranceDespesasFuneral',
  props: ['isSpouse'],
  components: { money: VMoney },
  data: () => ({
    loader: false,
    despesasFuneral: {
      value: 0
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    }
  }),
  validations: {
    despesasFuneral: {
      value: {
        isValid(v) {
          return !!v && removeMoneyMask(v) >= 0;
        }
      }
    }
  },
  computed: {
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    },
    valueErrors() {
      const errors = [];

      if (this.$v.despesasFuneral.value.$anyDirty) {
        return errors;
      }

      !this.$v.despesasFuneral.value.isValid && errors.push('Preencha um valor válido');

      return errors;
    },
    allowed() {
      return this.$store.getters.allowed
    }
  },
  methods: {
    copyData() {
      if (this.lifeInsurance) {
        this.despesasFuneral = JSON.parse(JSON.stringify(this.lifeInsurance.despesasFuneral));
        this.despesasFuneral.value = this.despesasFuneral.value.toFixed(2);
        this.resetMoneyInput();
      }
    },
    resetMoneyInput() {
      let input = document.getElementById('input-insurance-funeral' + this.lifeInsurance._id);
      if (input) {
        input.value = this.despesasFuneral.value;
        input.dispatchEvent(new Event('input'));
      }
    },
    submit() {
      if (this.allowed) {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          let despesasFuneral = JSON.parse(JSON.stringify(this.despesasFuneral));

          despesasFuneral.value = removeMoneyMask(despesasFuneral.value)

          updateLifeInsurace(this.lifeInsurance._id, { despesasFuneral })
            .then(lifeInsurance => {
              this.loader = false;
              this.$noty.success('Dados salvos com sucesso!');
              this.$store.dispatch('saveLifeInsurance', lifeInsurance);
              this.copyData();
              this.$noty.success('Dados salvos com sucesso!')
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível salvar estes dados. Tente novamente mais tarde!');

              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      }
    }
  },
  watch: {
    lifeInsurance: {
      deep: true,
      handler() {
        this.copyData();
      }
    }
  },
  created() {
    this.copyData();
  }
}
</script>
