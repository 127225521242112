<template>
  <v-card color="grey2">
    <v-progress-linear
      indeterminate
      color="primary"
      v-show="loader"
    ></v-progress-linear>

    <v-card-title class="primary--text">Dados de Pagamento</v-card-title>

    <v-card-text>
      <v-list color="transparent" two-line>
        <v-list-item v-for="item in items" :key="item.id">
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle class="white--text">{{ item.value }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import getIuguCustomerById from '@/api/iugu/customers/getIuguCustomerById';
import getIuguSubscription from '@/api/iugu/subscriptions/getIuguSubscription';
import stringCapitalize from '@/utils/stringCapitalize';

export default {
  name: 'CardIuguData',
  props: ['iuguId', 'iuguSubscription'],
  data: () => ({
    loader: false,
    customer: null,
    subscription: null
  }),
  computed: {
    items() {
      let items = []

      if (this.customer && this.customer.custom_variables && this.customer.custom_variables.length) {
        items = [
          ...items,
          ...this.customer.custom_variables.map(item => {
            return {
              name: stringCapitalize(item.name),
              value: item.value
            }
          })
        ]
      }

      if (this.subscription) {
        items.push({
          name: 'Plano',
          value: this.subscription.plan_name
        })
      }

      return items
    }
  },
  methods: {
    async getCustomer() {
      if (this.iuguId) {
        this.customer = await getIuguCustomerById(this.iuguId);
      }
    },
    async getSubscription() {
      if (this.iuguSubscription) {
        this.subscription = await getIuguSubscription(this.iuguSubscription);
      }
    },
    async getData() {
      try {
        this.loader = true;
        await Promise.all([this.getCustomer(), this.getSubscription()]);
      } catch (err) {
        this.customer = null;
        this.subscription = null;

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.loader = false;
      }
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
