<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey2">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="primary"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="primary--text ml-4">
          {{ investment._id ? 'Editar' : 'Novo' }} Investimento
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <span class="input-label primary--text">Carteira</span>
                <v-select
                  dense
                  color="grey1"
                  :items="wallets"
                  v-model="investment.wallet"
                  :error-messages="walletErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Descrição</span>
                <v-text-field
                  dense
                  color="grey1"
                  v-model="investment.description"
                  :error-messages="descriptionErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Categoria</span>
                <v-select
                  dense
                  color="grey1"
                  :items="categories"
                  v-model="investment.category"
                  :error-messages="categoryErrors"
                ></v-select>
              </v-flex>

              <v-slide-x-transition>
                <v-flex xs12 md6 v-if="investment.category === 'Outro'">
                  <span class="input-label primary--text"
                    >Descreva a categoria</span
                  >
                  <v-text-field
                    dense
                    color="grey1"
                    v-model="otherCategory"
                    :error-messages="otherCategoryErrors"
                  ></v-text-field>
                </v-flex>
              </v-slide-x-transition>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Tipo</span>
                <v-select
                  dense
                  color="grey1"
                  :items="types"
                  v-model="investment.type"
                  :error-messages="typeErrors"
                ></v-select>
              </v-flex>

              <v-slide-x-transition>
                <v-flex xs12 md6 v-if="investment.type === 'Outro'">
                  <span class="input-label primary--text">Descreva o tipo</span>
                  <v-text-field
                    dense
                    color="grey1"
                    v-model="otherType"
                    :error-messages="otherTypeErrors"
                  ></v-text-field>
                </v-flex>
              </v-slide-x-transition>

              <v-flex xs12 md6>
                <span class="input-label primary--text"
                  >Data de aplicação inicial</span
                >
                <v-dialog
                  ref="dialog"
                  v-model="dateMenu"
                  :return-value.sync="investment.initialDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 pt-0"
                      :value="formatDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        color="grey1"
                        class="input-icon"
                        slot="prepend-inner"
                      >
                        mdi-calendar
                      </v-icon>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    color="primary"
                    v-model="investment.initialDate"
                    scrollable
                    locale="pt-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey2" @click="dateMenu = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.dialog.save(investment.initialDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text"
                  >Valor de aplicação inicial</span
                >
                <v-text-field
                  dense
                  type="tel"
                  color="grey1"
                  v-money="money"
                  v-model="investment.initialValue"
                  id="input-investment-initial-value"
                  :error-messages="initialValueErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Instituição</span>
                <v-autocomplete
                  dense
                  color="grey1"
                  :items="brokers"
                  v-model="investment.broker"
                  :error-messages="brokerErrors"
                ></v-autocomplete>
              </v-flex>

              <v-slide-x-transition>
                <v-flex xs12 md6 v-if="investment.broker === 'Outra'">
                  <span class="input-label primary--text"
                    >Nome da corretora</span
                  >
                  <v-text-field
                    dense
                    color="grey1"
                    v-model="otherBroker"
                    :error-messages="otherBrokerErrors"
                  ></v-text-field>
                </v-flex>
              </v-slide-x-transition>

              <v-flex xs12>
                <v-card-actions class="px-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    fab
                    small
                    type="submit"
                    color="primary"
                    :loading="loader"
                    class="darkgrey--text"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money';
import moment from 'moment-timezone';
import { orderBy, capitalize } from 'lodash';
import removeMoneyMask from '@/utils/removeMoneyMask';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import stringCapitalize from '@/utils/stringCapitalize';
import saveInvestment from '@/api/investimentos/saveInvestment';

export default {
  name: 'DialogFormWalletInvestment',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    dateMenu: false,
    categories: [
      'COE',
      'Fundos de investimento',
      'Previdencia privada',
      'Renda fixa',
      'Renda variável',
      'Tesouro direto',
      'Outro',
    ],
    investment: {
      description: '',
      category: '',
      type: '',
      initialValue: 0,
      initialDate: '',
      wallet: '',
      broker: '',
    },
    otherType: '',
    otherCategory: '',
    otherBroker: '',
    showKeepLoader: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    },
    wallets: [
      { text: 'Reserva de Emergência', value: 'security' },
      { text: 'Independencia financeira', value: 'independence' },
      { text: 'Projetos de vida', value: 'life-projects' },
    ],
  }),
  validations: {
    investment: {
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      category: { required },
      type: { required },
      initialValue: {
        greaterThanZero(v) {
          return removeMoneyMask(v) > 0;
        },
      },
      initialDate: {
        required,
      },
      wallet: { required },
      broker: { required },
    },
    otherType: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
    otherBroker: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
    otherCategory: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
  },
  computed: {
    formatDate() {
      return moment(this.investment.initialDate).format('DD/MM/YYYY');
    },
    types() {
      switch (this.investment.category) {
        case 'Renda fixa':
          return [
            'CDB',
            'CRA',
            'CRI',
            'Debenture',
            'LC',
            'LCA',
            'LCI',
            'LF',
            'LFSN',
            'Outro',
          ];
        case 'Tesouro direto':
          return ['IPCA', 'Pré-fixado', 'Selic', 'Outro'];
        case 'Fundos de investimento':
          return [
            'Fundo alternativo',
            'Fundo de renda fixa',
            'Fundo de renda variável',
            'Fundo internacional',
            'Fundo multimercados',
            'Outro',
          ];
        case 'Previdencia privada':
          return [
            'Renda fixa pré-fixada',
            'Renda fixa pós-fixada',
            'Multimercados',
            'Outro',
          ];
        case 'Renda variável':
          return [
            'Ações',
            'Aluguel de ações',
            'ETFs',
            'Fundos imobiliários',
            'Outro',
          ];
        default:
          return ['Outro'];
      }
    },
    brokers() {
      let brokers = orderBy(
        [
          'Agora',
          'Alfa',
          'Modal Mais',
          'Banco Do Brasil',
          'Caixa Econômica Federal',
          'Ativa Investimentos',
          'Banco Bnp Paribas Brasil',
          'Banco Bradesco',
          'Banco Santander',
          'Bgc Liquidez',
          'Bradesco Kirton',
          'Btg Pactual',
          'Citibank',
          'Clear Corretora',
          'Cm Capital Markets',
          'Coinvalores',
          'Credit Suisse',
          'Deutsche Bank',
          'Fator',
          'Genial',
          'Goldman Sachs Do Brasil',
          'Guide Investimentos',
          'H.commcor',
          'Haitong Securities Do Brasil',
          'Icap Do Brasil',
          'Ing',
          'Interfloat Hz',
          'Intl Fcstone',
          'Banco Itau',
          'J.p. Morgan',
          'Merrill Lynch',
          'Morgan Stanley',
          'Mirae Investimentos',
          'Necton Investimentos',
          'Nova Futura',
          'Planner Corretora De Valores',
          'Renascenca',
          'Rico',
          'Safra Corretora De Valores E Cambio',
          'Banco Santander',
          'Socopa Sociedade Corretora Paulista',
          'Tendencia',
          'Terra Investimentos',
          'Tov',
          'Tullett Prebon Brasil',
          'Ubs Brasi',
          'Um Investimentos',
          'Votorantim',
          'Walpires',
          'Xp Investimentos',
          'Warren',
        ],
        (v) => v
      );

      brokers.push('Outra');

      return brokers;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    walletErrors() {
      const errors = [];
      if (!this.$v.investment.wallet.$dirty) return errors;
      !this.$v.investment.wallet.required &&
        errors.push('Selecione a carteira');
      return errors;
    },
    categoryErrors() {
      const errors = [];
      if (!this.$v.investment.category.$dirty) return errors;
      !this.$v.investment.category.required &&
        errors.push('Selecione a categoria');
      return errors;
    },
    otherCategoryErrors() {
      const errors = [];
      if (!this.$v.otherCategory.$dirty) return errors;
      !this.$v.otherCategory.required &&
        errors.push('Insira o nome da categoria');
      !this.$v.otherCategory.minLength &&
        errors.push('O nome da categoria deve conter, no mínimo, 1 caracter');
      !this.$v.otherCategory.maxLength &&
        errors.push(
          'O nome da categoria deve conter, no máximo, 100 caracteres'
        );
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.investment.type.$dirty) return errors;
      !this.$v.investment.type.required && errors.push('Selecione o tipo');
      return errors;
    },
    otherTypeErrors() {
      const errors = [];
      if (!this.$v.otherType.$dirty) return errors;
      !this.$v.otherType.required && errors.push('Insira o nome do tipo');
      !this.$v.otherType.minLength &&
        errors.push('O nome do tipo deve conter, no mínimo, 1 caracter');
      !this.$v.otherType.maxLength &&
        errors.push('O nome do tipo deve conter, no máximo, 100 caracteres');
      return errors;
    },
    brokerErrors() {
      const errors = [];
      if (!this.$v.investment.broker.$dirty) return errors;
      !this.$v.investment.broker.required &&
        errors.push('Selecione a corretora');
      return errors;
    },
    otherBrokerErrors() {
      const errors = [];
      if (!this.$v.otherBroker.$dirty) return errors;
      !this.$v.otherBroker.required &&
        errors.push('Insira o nome da corretora');
      !this.$v.otherBroker.minLength &&
        errors.push('O nome da corretora deve conter, no mínimo, 1 caracter');
      !this.$v.otherBroker.maxLength &&
        errors.push(
          'O nome da corretora deve conter, no máximo, 100 caracteres'
        );
      return errors;
    },
    initialDateErrors() {
      const errors = [];
      if (!this.$v.investment.initialDate.$dirty) return errors;
      !this.$v.investment.initialDate.required && errors.push('Insira a data');
      !this.$v.investment.initialDate.isValid && errors.push('Data inválida');
      return errors;
    },
    initialValueErrors() {
      const errors = [];
      if (!this.$v.investment.initialValue.$dirty) return errors;
      !this.$v.investment.initialValue.greaterThanZero &&
        errors.push('Insira um valor maior do que R$ 0,00');
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.investment.description.$dirty) return errors;
      !this.$v.investment.description.required &&
        errors.push('Insira a descrição');
      !this.$v.investment.description.minLength &&
        errors.push('A descrição deve conter, no mínimo, 1 caracter');
      !this.$v.investment.description.maxLength &&
        errors.push('A descrição deve conter, no máximo, 100 caracteres');
      return errors;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.reset();
    },
    reset() {
      this.investment = {
        description: '',
        category: '',
        type: '',
        initialValue: 0,
        initialDate: '',
        wallet: '',
        broker: '',
      };
      this.investment.initialDate = moment()
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD');
      this.resetMoneyInput();
      this.$v.$reset();
      this.showKeepLoader = false;
    },
    resetMoneyInput() {
      let input = document.getElementById('input-investment-initial-value');
      if (input) {
        input.value = this.investment.initialValue;
        input.dispatchEvent(new Event('input'));
      }
    },
    submit() {
      this.$v.$touch();

      if (this.investment.type !== 'Outro') {
        this.$v.otherType.$reset();
      }

      if (this.investment.broker !== 'Outra') {
        this.$v.otherBroker.$reset();
      }

      if (this.investment.category !== 'Outro') {
        this.$v.otherCategory.$reset();
      }

      if (
        !this.$v.investment.$anyError &&
        !this.$v.otherType.$anyError &&
        !this.$v.otherBroker.$anyError &&
        !this.$v.otherCategory.$anyError
      ) {
        this.loader = true;

        let investment = JSON.parse(JSON.stringify(this.investment));

        if (investment.type === 'Outro') {
          investment.type = capitalize(this.otherType);
        }

        if (investment.broker === 'Outra') {
          investment.broker = stringCapitalize(this.otherBroker);
        }

        if (investment.category === 'Outro') {
          investment.category = capitalize(this.otherCategory);
        }

        investment.initialValue = removeMoneyMask(investment.initialValue);

        investment.user = this.user._id;
        saveInvestment(investment)
          .then((savedInvestment) => {
            this.$store.dispatch('saveInvestment', savedInvestment);
            this.close();
            this.$noty.success('Dados salvos com sucesso!');
          })
          .catch((err) => {
            this.loader = false;
            this.$noty.error(
              'Desculpe! Não foi possível salvar este investimento. Tente novamente!'
            );

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
  },
  created() {
    this.$bus.$on('showDialogFormWalletInvestment', (data) => {
      if (data.investment) {
        this.investment = JSON.parse(JSON.stringify(data.investment));
        this.investment.initialDate = this.$date(this.investment.initialDate).add(3, 'hour').format('YYYY-MM-DD')
        this.investment.initialValue = this.investment.initialValue.toFixed(2);

        if (!this.types.includes(this.investment.type)) {
          this.otherType = this.investment.type;
          this.investment.type = 'Outro';
        }

        if (!this.categories.includes(this.investment.category)) {
          this.otherCategory = this.investment.category;
          this.investment.category = 'Outro';
        }

        if (!this.brokers.includes(this.investment.broker)) {
          this.otherBroker = this.investment.broker;
          this.investment.broker = 'Outra';
        }

        this.resetMoneyInput();
      } else if (data.wallet) {
        this.investment.wallet = data.wallet;
        this.investment.initialDate = this.$date().format('YYYY-MM-DD')
      }

      this.dialog = true;
    });
  },
};
</script>

<style scoped>
.input-label {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: 0.1em;
}
</style>
