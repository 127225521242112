<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    :fullscreen="isMobile"
    :max-width="maxWidth + 'px'"
  >
    <v-card color="grey2" :class="isMobile ? 'no-radius' : ''" v-if="origin">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4" v-if="origin === 'taxasConsumo'">
          Taxas de <br class="hidden-md-and-up"> independência financeira
        </span>

        <span class="white--text ml-4" v-else>
          Taxas do <br class="hidden-md-and-up"> período de produção
        </span>
      </v-card-title>

      <v-card-text class="pt-3" v-if="taxas">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm6 md4>
              <span class="input-label primary--text">Taxa bruta anual</span>
              <v-text-field
                dense
                type="number"
                color="grey1"
                prepend-inner-icon="mdi-percent"
                v-model.number="taxas.taxaBrutaAnual"
                :error-messages="taxaErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <span class="input-label primary--text">Taxa bruta mensal</span>
              <v-text-field
                dense
                readonly
                color="grey1"
                :value="taxaBrutaMensal"
                prepend-inner-icon="mdi-percent"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <span class="input-label primary--text">Alíquota IR</span>
              <v-text-field
                dense
                type="number"
                color="grey1"
                prepend-inner-icon="mdi-percent"
                v-model.number="taxas.aliquotaIr"
                :error-messages="aliquotaErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <span class="input-label primary--text">Inflação</span>
              <v-text-field
                dense
                type="number"
                color="grey1"
                v-model.number="taxas.inflacao"
                prepend-inner-icon="mdi-percent"
                :error-messages="inflacaoErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <span class="input-label primary--text">Taxa líquida anual</span>
              <v-text-field
                dense
                readonly
                color="grey1"
                :value="taxaLiquidaAnual"
                prepend-inner-icon="mdi-percent"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <span class="input-label primary--text">Taxa líquida mensal</span>
              <v-text-field
                dense
                readonly
                color="grey1"
                :value="taxaLiquidaMensal"
                prepend-inner-icon="mdi-percent"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-actions class="px-0 pb-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            color="primary"
            @click="submit"
            :loading="loader"
            class="darkgrey--text"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { required, minValue } from 'vuelidate/lib/validators'

  import calcTaxaMensal from "../../../../utils/indFinanceira/calcTaxaMensal";
  import calcTaxaAnualLiquida from "../../../../utils/indFinanceira/calcTaxaLiquidaAnual";
  import updateIndFinanceira from "../../../../api/indFinanceira/updateIndFinanceira";

  export default {
    name: 'DialogFormTaxas',
    data: () => ({
      dialog: false,
      loader: false,
      origin: null,
      maxWidth: 788,
      taxas: {
        taxaBrutaAnual: 0,
        aliquotaIr: 0,
        inflacao: 0
      },
    }),
    computed: {
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      taxaBrutaMensal() {
        if (this.taxas && this.taxas.taxaBrutaAnual)
          return calcTaxaMensal(this.taxas.taxaBrutaAnual);

        return 0;
      },
      taxaLiquidaAnual() {
        if (this.taxas && this.taxas.taxaBrutaAnual) {
          return calcTaxaAnualLiquida(this.taxas.taxaBrutaAnual,
            this.taxas.aliquotaIr ? this.taxas.aliquotaIr : 0,
            this.taxas.inflacao);
        }

        return 0;
      },
      taxaLiquidaMensal() {
        if (this.taxas && this.taxaLiquidaAnual)
          return calcTaxaMensal(this.taxaLiquidaAnual);

        return 0;
      },
      aliquotaErrors() {
        const errors = [];
        if (!this.$v.taxas.aliquotaIr.$dirty) return errors;
        !this.$v.taxas.aliquotaIr.required && errors.push('Insira a taxa bruta anual');
        !this.$v.taxas.aliquotaIr.minValue && errors.push('Insira um valor maior ou igual a 0');
        return errors;
      },
      taxaErrors() {
        const errors = [];
        if (!this.$v.taxas.taxaBrutaAnual.$dirty) return errors;
        !this.$v.taxas.taxaBrutaAnual.required && errors.push('Insira a taxa bruta anual');
        !this.$v.taxas.taxaBrutaAnual.minValue && errors.push('Insira um valor maior ou igual a 0');
        return errors;
      },
      inflacaoErrors() {
        const errors = [];
        if (!this.$v.taxas.inflacao.$dirty) return errors;
        !this.$v.taxas.inflacao.required && errors.push('Insira a inflação');
        !this.$v.taxas.inflacao.minValue && errors.push('Insira um valor maior ou igual a 0');
        return errors;
      }
    },
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.origin = null;
        this.taxas = {
          taxaBrutaAnual: 0,
          aliquotaIr: 0
        };
        this.$v.$reset();
      },
      submit() {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          let taxas = JSON.parse(JSON.stringify(this.taxas));

          taxas.taxaBrutaMensal = this.taxaBrutaMensal;
          taxas.taxaLiquidaAnual = this.taxaLiquidaAnual;
          taxas.taxaLiquidaMensal = this.taxaLiquidaMensal;

          let data = {};
          data[this.origin] = taxas;

          updateIndFinanceira(this.indFinanceira._id, data)
            .then(indFinanceira => {
              this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
              this.close();
              this.$noty.success('Dados salvos com sucesso!')
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível atualizar seus dados. Tente novamente!');
              if (process.env.NODE_ENV !== 'production') console.error(err);
            });
        }
      },
    },
    validations: {
      taxas: {
        inflacao: { required, minValue: minValue(0) },
        aliquotaIr: { required, minValue: minValue(0) },
        taxaBrutaAnual: { required, minValue: minValue(0) }
      }
    },
    created() {
      this.$bus.$on('showDialogFormTaxas', origin => {
        if (origin && this.indFinanceira && this.indFinanceira[origin]) {
          this.origin = origin;
          this.taxas = JSON.parse(JSON.stringify(this.indFinanceira[this.origin]));

          this.taxas.taxaBrutaAnual = parseFloat(this.taxas.taxaBrutaAnual.toFixed(2));
          this.taxas.aliquotaIr = parseFloat(this.taxas.aliquotaIr.toFixed(2));

          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
  .dialog-form-title .white--text {
    line-height: 1.5em !important;
  }

  .input-label {
    font-size: 9px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  @media only screen and (min-width: 600px) {
    .input-label {
      font-size: 12px;
    }
  }
</style>

