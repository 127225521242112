import store from '../store/index';
import firebase from 'firebase/app';

import Auth from '@/views/Auth';
import Login from '@/views/Login';
import Index from '@/views/Index';
import Signup from '@/views/Signup';
import Ead from '@/views/admin/Ead';
import Profile from '@/views/admin/Profile';
import Cashflow from '@/views/customer/Cashflow';
import Transactions from '@/views/customer/Transactions';
import Recommendations from '@/views/admin/Recommendations';
import Categorias from '@/views/admin/Categorias';
import ImpostoRenda from '@/views/admin/ImpostoRenda';
import Orcamento from "../views/customer/Orcamento";
import Relatorios from "../views/customer/Relatorios";
import Perfil from "../views/customer/Perfil";
import PoliticaPrivacidade from "../views/PoliticaPrivacidade";
import IndFinanceira from "../views/customer/IndFinanceira";
import Customer from "../views/planner/Customer";
import Config from '../views/Config';
import Objetivo from '../views/customer/Objetivo';
import Planner from '@/views/planner/Planner';
import Investimentos from '@/views/customer/Investimentos';
import PlanosAcao from '@/views/customer/PlanosAcao';
import SeguroVida from '@/views/customer/SeguroVida';
import Termos from '@/views/Termos';
import CreatePassword from '@/views/CreatePassword';
import MeiosPagamento from '@/views/customer/MeiosPagamento';
import Company from '@/views/Company';
import AliquotaNfse from "@/views/admin/AliquotaNfse";
import OpenBanking from "@/views/customer/OpenBanking";
import Cadastro from "@/views/customer/Cadastro";

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      let user = store.getters.user;

      if (user) {
        if (user.role === 'company') {
          return next('/company')
        } else if (user.role !== 'customer') {
          next()
        } else {
          return next('/cashflow')
        }
      }
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/create-password',
    name: 'CreatePassword',
    component: CreatePassword,
    beforeEnter: (to, from, next) => {
      return firebase.auth().currentUser ? next('/') : next();
    }
  },
  {
    path: '/admin/Recommendations',
    name: 'Recommendations',
    component: Recommendations,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      let user = store.getters.user;
      return user && user.role === 'admin' ? next() : next('/');
    }
  },
  {
    path: '/admin/categorias',
    name: 'Categorias',
    component: Categorias,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      let user = store.getters.user;
      return user && user.role === 'admin' ? next() : next('/');
    }
  },
  {
    path: '/admin/imposto-renda',
    name: 'ImpostoRenda',
    component: ImpostoRenda,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      let user = store.getters.user;
      return user && user.role === 'admin' ? next() : next('/');
    }
  },
  {
    path: '/admin/aliquota-nfse',
    name: 'AliquotaNfse',
    component: AliquotaNfse,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      let user = store.getters.user;
      return user && user.role === 'admin' ? next() : next('/');
    }
  },
  {
    path: '/admin/ead',
    name: 'Ead',
    component: Ead,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      let user = store.getters.user;
      return user && user.role === 'admin' ? next() : next('/');
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/investimentos',
    name: 'Investimentos',
    component: Investimentos,
    meta: { requiresAuth: true },
    beforeEnter(to, form, next) {
      const permissions = store.getters.user.permissions
      let userPermission = permissions ? permissions.investimentos : null;

      return !userPermission ? next('/') : next();
    }
  },
  {
    path: '/planos-acao',
    name: 'PlanosAcao',
    component: PlanosAcao,
    meta: { requiresAuth: true },
    beforeEnter(to, form, next) {
      const permissions = store.getters.user.permissions
      let userPermission = permissions ? permissions.planosAcao : null;

      return !userPermission ? next('/') : next();
    }
  },
  {
    path: '/cashflow',
    name: 'Cashflow',
    component: Cashflow,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      next(vm => {
        vm.prevRoute = from;
      });
    }
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
    meta: { requiresAuth: true }
  },
  {
    path: '/orcamento',
    name: 'Orcamento',
    component: Orcamento,
    meta: { requiresAuth: true },
    beforeEnter(to, form, next) {
      const permissions = store.getters.user.permissions
      let userPermission = permissions ? permissions.orcamento : null;

      return !userPermission ? next('/') : next();
    }
  },
  {
    path: '/relatorios',
    name: 'Relatorios',
    component: Relatorios,
    meta: { requiresAuth: true },
    beforeEnter(to, form, next) {
      const permissions = store.getters.user.permissions
      let userPermission = permissions ? permissions.relatorios : null;

      return !userPermission ? next('/') : next();
    }
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil,
    meta: { requiresAuth: true }
  },
  {
    path: '/politica-privacidade',
    name: 'PoliticaPrivacidade',
    component: PoliticaPrivacidade
  },
  {
    path: '/termos-uso',
    name: 'Termos',
    component: Termos
  },
  {
    path: '/ind-financeira',
    name: 'IndFinanceira',
    component: IndFinanceira,
    meta: { requiresAuth: true },
    beforeEnter(to, form, next) {
      const permissions = store.getters.user.permissions
      let userPermission = permissions ? permissions.indFinanceira : null;

      return !userPermission ? next('/') : next();
    }
  },
  {
    path: '/objetivo',
    name: 'Objetivo',
    component: Objetivo,
    meta: { requiresAuth: true },
    beforeEnter(to, form, next) {
      const permissions = store.getters.user.permissions
      let userPermission = permissions ? permissions.objetivo : null;

      return !userPermission ? next('/') : next();
    }
  },
  {
    path: '/seguro-vida',
    name: 'SeguroVida',
    component: SeguroVida,
    meta: { requiresAuth: true },
    beforeEnter(to, form, next) {
      const permissions = store.getters.user.permissions
      let userPermission = permissions ? permissions.lifeInsurance : null;

      return !userPermission ? next('/') : next();
    }
  },
  {
    path: '/customer/:id',
    name: 'Customer',
    component: Customer,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      let userRole = store.getters.user ? store.getters.user.role : null;
      return userRole === 'customer' ? next('/') : next();
    }
  },
  {
    path: '/company/:id',
    name: 'Company Page',
    component: Company,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      let userRole = store.getters.user ? store.getters.user.role : null;
      return userRole !== 'admin' ? next('/') : next();
    }
  },
  {
    path: '/planner/config',
    name: 'Config',
    component: Config,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      let userRole = store.getters.user ? store.getters.user.role : null;
      return userRole === 'customer' ? next('/') : next();
    }
  },
  {
    path: '/planner/:id',
    name: 'Planner',
    component: Planner,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      let userRole = store.getters.user ? store.getters.user.role : null;
      return ['planner', 'customer'].includes(userRole) ? next('/') : next();
    }
  },
  {
    path: '/meios-pagamento',
    name: 'MeiosPagamento',
    component: MeiosPagamento,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      let userRole = store.getters.user ? store.getters.user.role : null;
      return userRole === 'customer' ? next() : next('/')
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    beforeEnter: (to, from, next) => {
      const user = store.getters.user
      return (user && user.role === 'company') ? next() : next('/')
    }
  },
  {
    path: '/open-banking',
    name: 'OpenBanking',
    component: OpenBanking,
    beforeEnter: (to, from, next) => {
      const user = store.getters.user
      return (user && user.role === 'customer') ? next() : next('/')
    }
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    component: Cadastro,
    meta: { requiresAuth: true }
  }
];

export default routes;
