<template>
  <v-card color="transparent" flat>
    <v-card-text class="px-0" v-if="perfil">
      <v-container fluid grid-list-md class="px-0" v-if="usuario">
        <v-layout row wrap>
          <v-flex xs12 v-if="conjuge">
            <h3>{{ perfil.informacoesBasicas.usuario.nome }}</h3>
          </v-flex>

          <v-flex xs12 class="d-flex flex-column">
            <span class="input-label primary--text">Recebia mesada?</span>
            <span class="input-label">{{ usuario.mesada ? 'Sim' : 'Não' }}</span>
          </v-flex>

          <v-flex xs12 class="d-flex flex-column">
            <span class="input-label primary--text">Estimativa total de despesas mensais</span>
            <span class="input-label">{{ numberToCurrency(usuario.despesasMensais) }}</span>
          </v-flex>

          <v-flex xs12 class="d-flex flex-column" v-if="usuario.fatorExperiencia">
            <span class="input-label primary--text">Fator determinante para experiência com investimentos</span>
            <span class="input-label">{{ usuario.fatorExperiencia }}</span>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <h4>Capacidade de poupança</h4>
          </v-flex>

          <v-flex
            xs12 sm6 md3
            class="d-flex flex-column"
            v-if="usuario.capacidadePoupanca && usuario.capacidadePoupanca"
          >
            <span class="input-label primary--text">Capacidade atual de poupar</span>
            <span class="input-label">{{ usuario.capacidadePoupanca.descricao }}</span>
          </v-flex>

          <v-flex
            xs12 sm6 md3
            class="d-flex flex-column"
            v-if="usuario.capacidadePoupanca && usuario.capacidadePoupanca"
          >
            <span class="input-label primary--text">Estimativa de poupança</span>
            <span class="input-label">{{ numberToCurrency(usuario.capacidadePoupanca.estimativa) }}</span>
          </v-flex>

          <v-flex
            xs12 md8
            class="d-flex flex-column mt-5"
            v-if="usuario.experienciasInvestimentos.length"
          >
            <span class="input-label primary--text">Experiência com investimentos</span>
            <span class="input-label">{{ formatExperiencia(usuario.experienciasInvestimentos) }}</span>
          </v-flex>
        </v-layout>
      </v-container>

      <v-divider v-if="conjuge"></v-divider>

      <v-container fluid grid-list-md class="px-0" v-if="conjuge">
        <v-layout row wrap>
          <v-flex xs12 v-if="perfil.informacoesBasicas.conjuge && perfil.informacoesBasicas.conjuge.nome">
            <h3>{{ perfil.informacoesBasicas.conjuge.nome }}</h3>
          </v-flex>

          <v-flex xs12 class="d-flex flex-column">
            <span class="input-label primary--text">Recebia mesada?</span>
            <span class="input-label">{{ conjuge.mesada ? 'Sim' : 'Não' }}</span>
          </v-flex>

          <v-flex xs12 class="d-flex flex-column">
            <span class="input-label primary--text">Estimativa total de despesas mensais</span>
            <span class="input-label">{{ numberToCurrency(conjuge.despesasMensais) }}</span>
          </v-flex>

          <v-flex xs12 class="d-flex flex-column" v-if="conjuge.fatorExperiencia">
            <span class="input-label primary--text">Fator determinante para experiência com investimentos</span>
            <span class="input-label">{{ conjuge.fatorExperiencia }}</span>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <h4>Capacidade de poupança</h4>
          </v-flex>

          <v-flex
            xs12 sm6 md3
            class="d-flex flex-column"
            v-if="conjuge.capacidadePoupanca && conjuge.capacidadePoupanca"
          >
            <span class="input-label primary--text">Capacidade atual de poupar</span>
            <span class="input-label">{{ conjuge.capacidadePoupanca.descricao }}</span>
          </v-flex>

          <v-flex
            xs12 sm6 md3
            class="d-flex flex-column"
            v-if="conjuge.capacidadePoupanca && conjuge.capacidadePoupanca"
          >
            <span class="input-label primary--text">Estimativa de poupança</span>
            <span class="input-label">{{ numberToCurrency(conjuge.capacidadePoupanca.estimativa) }}</span>
          </v-flex>

          <v-flex
            xs12 md8
            class="d-flex flex-column mt-5"
            v-if="conjuge.experienciasInvestimentos.length"
          >
            <span class="input-label primary--text">Experiência com investimentos</span>
            <span class="input-label">{{ formatExperiencia(conjuge.experienciasInvestimentos) }}</span>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-card-actions class="pb-5">
      <v-spacer></v-spacer>

      <v-btn
        v-if="allowed"
        outlined
        rounded
        color="primary"
        class="px-10 text-capitalize"
        @click="showDialogEditExperienciaFinanceira"
      >
        Editar
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';

export default {
  name: 'CardExperienciaFinanceira',
  data: () => ({
    numberToCurrency
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed
    },
    perfil() {
      return this.$store.getters.perfil
    },
    usuario() {
      if (this.perfil && this.perfil.experienciaFinanceira) {
        return this.perfil.experienciaFinanceira.usuario
      }

      return null
    },
    conjuge() {
      if (this.perfil && this.perfil.experienciaFinanceira) {
        return this.perfil.experienciaFinanceira.conjuge
      }

      return null
    }
  },
  methods: {
    showDialogEditExperienciaFinanceira() {
      if (this.allowed)
        this.$bus.$emit('showDialogEditExperienciaFinanceira');
    },
    formatExperiencia(items) {
      if (items.length > 1)
        return items.join(', ');
      else
        return items[0];
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .input-label {
    font-size: 14px;
  }
}
</style>
