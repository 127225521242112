<template>
  <v-dialog persistent fullscreen scrollable v-model="dialog">
    <v-card class="no-radius">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn icon :color="type.color" @click.stop="close" class="back-button">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text rounded v-on="on" class="type-btn" :color="type.color">
              {{ type.text }}
              <v-icon class="ml-2">mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="item in types"
              :key="item.value"
              @click="type = item"
              class="type-list-item"
            >
              <v-list-item-title :class="item.color + '--text'">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu
          bottom
          left
          offset-y
          min-width="300px"
          max-width="320px"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="ml-auto hidden-md-and-up">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-card color="grey3">
            <v-card-text class="pt-7">
              <h3 class="primary--text mb-5">Filtros</h3>

              <v-select
                dense
                label="Tipo"
                color="primary"
                :items="accountTypes"
                item-text="text"
                item-value="value"
                v-model="accountType"
              ></v-select>

              <v-slide-x-transition>
                <v-select
                  dense
                  multiple
                  label="Contas bancárias"
                  v-if="accountType === 'bankAccount'"
                  :items="activeBankAccounts"
                  item-value="_id"
                  item-text="name"
                  v-model="selectedBankAccounts"
                ></v-select>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-select
                  dense
                  multiple
                  label="Cartões de crédito"
                  v-if="accountType === 'creditCard'"
                  :items="activeCreditCards"
                  item-value="_id"
                  item-text="name"
                  v-model="selectedCreditCards"
                ></v-select>
              </v-slide-x-transition>

              <v-divider class="my-5"></v-divider>

              <v-btn
                v-if="isMobile && allowedToRemoveAll"
                dark
                small
                block
                rounded
                color="error"
                @click.stop="showDialogRemoveAllPendencias"
              >
                Remover todas
              </v-btn>
            </v-card-text>

          </v-card>
        </v-menu>

      </v-card-title>

      <v-card-text class="pt-5">
        <div class="lists-container">
          <div class="list-container-header hidden-sm-and-down text-right">
            <v-menu
              bottom
              left
              offset-y
              min-width="300px"
              max-width="320px"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  rounded
                  v-on="on"
                  color="#404040"
                  class="primary--text ml-auto elevation-5 btn-filtros"
                >
                  Filtros
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-card color="grey3">
                <v-card-text class="pt-7">
                  <v-select
                    dense
                    label="Tipo"
                    color="primary"
                    :items="accountTypes"
                    item-text="text"
                    item-value="value"
                    v-model="accountType"
                  ></v-select>

                  <v-slide-x-transition>
                    <v-select
                      dense
                      multiple
                      label="Contas bancárias"
                      v-if="accountType === 'bankAccount'"
                      :items="activeBankAccounts"
                      item-value="_id"
                      item-text="name"
                      v-model="selectedBankAccounts"
                    ></v-select>
                  </v-slide-x-transition>

                  <v-slide-x-transition>
                    <v-select
                      dense
                      multiple
                      label="Cartões de crédito"
                      v-if="accountType === 'creditCard'"
                      :items="activeCreditCards"
                      item-value="_id"
                      item-text="name"
                      v-model="selectedCreditCards"
                    ></v-select>
                  </v-slide-x-transition>

                  <v-divider class="my-5"></v-divider>

                  <v-btn
                    dark
                    small
                    block
                    rounded
                    color="error"
                    v-if="allowedToRemoveAll"
                    @click.stop="showDialogRemoveAllPendencias"
                  >
                    Remover todas
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-menu>

            <v-btn
              v-if="!isMobile && allowedToRemoveAll"
              class="ml-2"
              max-width="200px"
              dark
              small
              rounded
              color="error"
              @click.stop="showDialogRemoveAllPendencias"
            >
              Remover todas
            </v-btn>
          </div>

          <div
            :key="date"
            class="transactions-list"
            v-for="date in transactionsDates"
          >
            <v-subheader class="list-title px-0 primary--text">
              {{ formatListDate(date) }}
            </v-subheader>

            <swipe-list
              :ref="'list-' + date"
              :items="groupedTransactions[date]"
            >
              <template
                v-slot="{ item, index, revealLeft, revealRight, close }"
              >
                <div
                  v-ripple
                  @click="showForm(item)"
                  class="transaction py-4 animated fadeInLeft transition-fast-out-slow-in"
                >
                  <v-avatar
                    size="30"
                    color="darkgrey"
                    class="transaction-avatar elevation-0"
                  >
                    <v-icon
                      size="13"
                      :color="item.type === 'saida' ? (item.investment ? 'primary' : 'error') : 'success'"
                    >
                      {{ item.type === 'entrada' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                    </v-icon>
                  </v-avatar>

                  <div class="transaction-content">
                    <div class="transaction-description">
                      <span class="transaction-description--text">
                        {{ item.description }}
                      </span>
                      <span class="transaction-description--value">
                        <span>R$</span> {{ currency(item.type === 'saida' ? item.value * -1 : item.value) }}
                      </span>
                    </div>

                    <div class="transaction-category">
                      <span class="transaction-category--desc">
                        {{
                          item.creditCard
                            ? item.creditCard.name
                            : item.bankAccount.name
                        }}
                      </span>
                    </div>
                  </div>

                  <v-menu bottom left close-on-click v-if="!isMobile">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        class="btn-menu ml-2"
                        :disabled="!allowed"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="showForm(item)">
                        <v-list-item-icon>
                          <v-icon color="success">mdi-thumb-up</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Salvar</v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="remove(item)" v-if="!item.pluggy">
                        <v-list-item-icon>
                          <v-icon color="error">mdi-trash-can-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Remover</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>

              <template v-slot:right="{ item, close }">
                <div class="swipeout-action" v-if="!item.pluggy">
                  <v-btn
                    icon
                    large
                    @click="remove(item)"
                    :loading="removeLoader === item._id"
                    :disabled="!allowed"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>

                <div class="swipeout-action">
                  <v-btn
                    icon
                    large
                    @click="
                      close();
                      showForm(item);
                    "
                    :disabled="!allowed"
                  >
                    <v-icon color="success"> mdi-thumb-up </v-icon>
                  </v-btn>
                </div>
              </template>
            </swipe-list>
          </div>
        </div>

        <div class="text-center mt-5">
          <v-pagination
            circle
            color="primary"
            v-model="pagination.page"
            :length="pagination.totalPages"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>

    <DialogRemoveAllPendencias />
  </v-dialog>
</template>

<script>
import { groupBy, orderBy } from 'lodash';
import moment from 'moment-timezone';
import { SwipeList } from 'vue-swipe-actions';
import currency from '../../../../utils/currency';
import deletePendencia from '../../../../api/pendencias/deletePendencia';
import DialogRemoveAllPendencias from '@/components/Customer/Cashflow/dialogs/DialogRemoveAllPendencias';
import filterPeriodTransactions from "@/utils/filterPeriodTransactions";

export default {
  name: 'DialogPendencias',
  components: { DialogRemoveAllPendencias, SwipeList },
  data: () => ({
    currency,
    dialog: false,
    period: '',
    type: { text: 'Todas', value: '', color: 'white' },
    types: [
      { text: 'Todas', value: '', color: 'white' },
      { text: 'Entradas pendentes', value: 'receita', color: 'success' },
      { text: 'Saídas pendentes', value: 'despesa', color: 'error' },
      { text: 'Investimentos pendentes', value: 'investimento', color: 'primary' },
    ],
    selectedBankAccounts: [],
    selectedCreditCards: [],
    removeLoader: '',
    accountTypes: [
      { text: 'Todos', value: '' },
      { text: 'Conta bancária', value: 'bankAccount' },
      { text: 'Cartão de crédito', value: 'creditCard' },
    ],
    accountType: '',
    pagination: {
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,
      totalPages: 0
    }
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    pendencias() {
      return orderBy(this.$store.getters.pendencias.filter((pendencia) => {
        if (
          !this.type.value ||
          (this.type.value === 'receita' && pendencia.type === 'entrada') ||
          (this.type.value === 'despesa' && pendencia.type === 'saida' && !pendencia.investment) ||
          (this.type.value === 'investimento' && pendencia.type === 'saida' && pendencia.investment)
        ) {
          if (!this.accountType) {
            return pendencia;
          } else if (!!pendencia[this.accountType]) {
            if (this.accountType === 'bankAccount') {
              return (
                !pendencia.creditCard &&
                !this.selectedBankAccounts.length ||
                this.selectedBankAccounts.includes(pendencia.bankAccount._id)
              );
            } else if (this.accountType === 'creditCard') {
              return (
                !pendencia.bankAccount &&
                !this.selectedCreditCards.length ||
                this.selectedCreditCards.includes(pendencia.creditCard._id)
              );
            }
          }
        }
      }), 'date')
    },
    filteredPendencias() {
      return this.pendencias.slice(
        ((this.pagination.page - 1) * this.pagination.itemsPerPage),
        (this.pagination.page * this.pagination.itemsPerPage)
      );
    },
    groupedTransactions() {
      return groupBy(this.filteredPendencias, 'date');
    },
    transactionsDates() {
      return orderBy(Object.keys(this.groupedTransactions), v => v, ['asc']);
    },
    categories() {
      return this.$store.getters.categories;
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts;
    },
    activeBankAccounts() {
      return orderBy(this.bankAccounts.filter((ba) => !ba.archived), 'name');
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    allowed() {
      return this.$store.getters.allowed;
    },
    creditCards() {
      return this.$store.getters.creditCards;
    },
    activeCreditCards() {
      return orderBy(this.creditCards.filter((cc) => !cc.archived), 'name');
    },
    allowedToRemoveAll() {
      return !this.pendencias.filter(p => p.pluggy).length
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.type = { text: 'Todas', value: '', color: 'white' };
    },
    formatListDate(date) {
      if (date === this.today) return 'Hoje';

      return moment(date).format('DD/MM/YYYY');
    },
    remove(item) {
      if (this.allowed) {
        this.removeLoader = item._id;

        deletePendencia(item._id)
          .then(() => {
            this.$store.dispatch('removePendencia', item._id);
            this.removeLoader = false;
          })
          .catch((err) => {
            this.removeLoader = false;
            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    showForm(item) {
      if (this.allowed) {
        if (item.bankAccount && !item.creditCard) {
          this.$bus.$emit('showDialogFormPendencia', item);
        } else if (item.creditCard) {
          if (item.type === 'saida') {
            this.$bus.$emit('showDialogFormPendenciaCartao', item);
          } else {
            this.$bus.$emit('showDialogPendenciaEstorno', item);
          }
        }
      }
    },
    showDialogRemoveAllPendencias() {
      if (this.allowed) {
        this.$bus.$emit(
          'showDialogRemoveAllPendencias',
          this.filteredPendencias.filter((p) => p._id && !p.pluggy).map(p => p._id)
        );
      }
    },
  },
  watch: {
    type() {
      this.pagination.page = 1
    },
    accountType() {
      if (!this.accountType) {
        this.selectedBankAccounts = [];
        this.pagination.page = 1
      }
    },
    pendencias(newVal) {
      this.pagination.totalItems = this.pendencias.length
      this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
    }
  },
  created() {
    this.$bus.$on('showDialogPendencias', ({type, period}) => {
      this.period = period

      if (type) {
        this.type = this.types.find((t) => t.value === type);
      }

      this.dialog = true;
    });
  },
};
</script>

<style scoped>
.v-btn.type-btn,
.type-list-item .v-list-item__title {
  font-size: 15px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 0.1em !important;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.bank-selector .v-label {
  font-size: 12px !important;
  letter-spacing: 0.1em !important;
}

.list-title {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.lists-container {
  width: 100%;
  min-height: 1px;
}

.transactions-list {
  padding-left: 5px;
  padding-right: 5px;
}

.transaction {
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  transition: ease all 0.8s;
  display: flex;
  align-items: center;
}

.transaction .v-avatar.transaction-avatar {
  margin-right: 10px;
}

.transaction-content {
  width: 100%;
  margin-right: 5px;
}

.transaction-description {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.transaction-description--text {
  font-size: 9px !important;
  letter-spacing: 0.1em;
  line-height: 1.5em;
}

.transaction-description--value {
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.1em;
  width: 150px;
  text-align: right;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.transaction-category {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #9e9e9e;
}

.transaction-category--desc {
  font-size: 12px;
  letter-spacing: 0.1em;
}

.swipeout-action {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #131313;
}

.swipeout-action:nth-child(1) {
  margin-left: 10px;
}

@media only screen and (min-width: 600px) {
  .list-title {
    font-size: 15px;
  }

  .transaction .v-avatar.transaction-avatar {
    width: 40px !important;
    height: 36px !important;
  }

  .transaction .v-avatar .v-icon {
    font-size: 22px !important;
  }

  .transaction-description--text {
    font-size: 12px !important;
  }

  .transaction-description--value {
    font-size: 12px;
  }

  .transaction-description--value span {
    font-size: 12px;
  }

  .transaction-category--icon .v-avatar .v-icon {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 960px) {
  .lists-container {
    border-radius: 5px;
    background-color: #404040;
    padding: 10px 20px 20px 20px;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .transaction {
    cursor: pointer;
    transition: ease all 1s !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .transaction:hover {
    background-color: #505050;
    opacity: 0.1;
  }

  .transaction-description--value {
    font-size: 15px;
  }
}
</style>
