import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const updateCompany = (companyId, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            data,
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }

          const url = `/companies/${companyId}`

          return httpClient(url, options)
        })
        .then(resp => resolve(resp.data))
        .catch(reject)
    } catch (err) {
      reject(err)
    }
  })
}

export default updateCompany
