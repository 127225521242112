<template>
  <v-main class="margin-menu">
    <OverlaySync :overlay="loader" text="Buscando dados..." />
    <MobilePageTitle
      title="Seguro de vida"
      :loader="loader"
      v-if="isCustomer"
    />

    <v-container grid-list-md class="main-container pb-10">
      <v-layout row wrap>
        <v-flex xs12 class="my-5 hidden-sm-and-down">
          <div class="title-container">
            <span class="white--text page-title">Seguro de Vida</span>
            <span class="white--text page-subtitle">Simulador</span>
          </div>
        </v-flex>

        <v-flex xs12>
          <TabsLifeInsurance />
        </v-flex>
      </v-layout>
    </v-container>

    <DialogFormInsuranceSon/>
    <DialogFormInsuranceDependente/>
    <DialogLifeInsuranceRemoveDependent/>
    <DialogFormInsuranceDependentNote/>
    <DialogDeleteInsuranceDependentNote/>
    <DialogFormInsuranceInventoryAsset/>
    <DialogDeleteInsuranceInventoryAsset/>

    <CustomerMobileMenu class="hidden-md-and-up" v-if="isCustomer"/>
  </v-main>
</template>

<script>
import OverlaySync from '@/components/Global/OverlaySync';
import MobilePageTitle from '@/components/Global/MobilePageTitle';
import getCustomerData from '@/utils/getCustomerData';
import TabsLifeInsurance from '@/components/Customer/LifeInsurance/tabs/TabsLifeInsurance';
import getPerfil from '@/api/perfil/getPerfil';
import DialogFormInsuranceSon from '@/components/Customer/LifeInsurance/dialogs/DialogFormInsuranceSon';
import DialogFormInsuranceDependente from '@/components/Customer/LifeInsurance/dialogs/DialogFormInsuranceDependente';
import DialogLifeInsuranceRemoveDependent
  from '@/components/Customer/LifeInsurance/dialogs/DialogLifeInsuranceRemoveDependent';
import DialogFormInsuranceDependentNote
  from '@/components/Customer/LifeInsurance/dialogs/DialogFormInsuranceDependetNote';
import DialogDeleteInsuranceDependentNote
  from '@/components/Customer/LifeInsurance/dialogs/DialogDeleteInsuranceDependentNote';
import DialogFormInsuranceInventoryAsset
  from '@/components/Customer/LifeInsurance/dialogs/DialogFormInsuranceInventoryAsset';
import DialogDeleteInsuranceInventoryAsset
  from '@/components/Customer/LifeInsurance/dialogs/DialogDeleteInsurancenInventoryAsset';
import ButtonForms from '@/components/Customer/menu/ButtonForms';
import CustomerMobileMenu from '@/components/Customer/menu/CustomerMobileMenu';

export default {
  name: 'SeguroVida',
  components: {
    CustomerMobileMenu,
    ButtonForms,
    DialogDeleteInsuranceInventoryAsset,
    DialogFormInsuranceInventoryAsset,
    DialogDeleteInsuranceDependentNote,
    DialogFormInsuranceDependentNote,
    DialogLifeInsuranceRemoveDependent,
    DialogFormInsuranceDependente,
    DialogFormInsuranceSon,
    TabsLifeInsurance,
    MobilePageTitle,
    OverlaySync,
  },
  data: () => ({
    loader: false,
  }),
  computed: {
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === 'customer'
      );
    },
    user() {
      return !this.isCustomer
        ? this.$store.getters.customer
        : this.$store.getters.user;
    },
    perfil() {
      return this.$store.getters.perfil;
    },
  },
  methods: {
    goBack() {
      window.history.back();
    },
    getData() {
      if (!this.user) {
        this.loader = true;
        getCustomerData()
          .then(() => {
            this.getProfile();
          })
          .catch((err) => {

            this.$router.push('/');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      } else {
        this.getProfile();
      }
    },
    getProfile() {
      if (this.user) {
        getPerfil(this.user._id)
          .then((perfil) => {
            this.$store.dispatch('setPerfil', perfil);
            this.loader = false;
          })
          .catch((err) => {
            this.loader = false;

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
  },
  beforeMount() {
    this.$vuetify.theme.dark = true;
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.container {
  max-width: 1020px;
}

@media only screen and (min-width: 960px) {
  .v-main {
    background-color: #131313;
  }

  .container.main-container {
    width: 100%;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .page-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0.1em;
  }

  .page-subtitle {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.1em;
  }
}
</style>
