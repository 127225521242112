export default str => {
  if (str) {
    str = str.toLowerCase();
    str = str.split(' ');

    for (let i = 0, x = str.length; i < x; i++) {
      str[i] = (str[i][0] ? str[i][0].toUpperCase() : '') + str[i].substr(1);
    }

    return str.join(' ');
  }

  return '';
};
