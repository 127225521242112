<template>
  <v-card color="grey2" height="100%" class="column-card">
    <v-card-title class="primary--text text-uppercase text-center">Independência</v-card-title>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <div>
          <v-radio-group v-model="isConsumptionValue">
            <v-radio :value="false" label="Viver de renda"></v-radio>
            <v-radio :value="true" label="Consumo de patrimônio"></v-radio>
          </v-radio-group>
        </div>

        <div>
          <div class="total-container text-center mt-3">
            <p>
              Total:
              <span class="total-value">
              <span>R$</span> {{ currency(value) }}
            </span>
            </p>
          </div>

          <div class="text-center mt-10">
            <v-btn
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              :disabled="!allowed"
              class="darkgrey--text"
            >
              Salvar
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
  import moment from 'moment-timezone'
  import currency from '../../../../utils/currency';
  import getUserIndFinanceira from '../../../../api/indFinanceira/getUserIndFinanceira';
  import updateLiquidez from '../../../../api/liquidez/updateLiquidez';

  export default {
    name: 'CardIndependenciaLiquidez',
    data: () => ({
      currency,
      loader: false,
      currentDate: null,
      isConsumptionValue: false
    }),
    computed: {
      allowed() {
        return this.$store.getters.allowed;
      },
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === 'customer';
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
      liquidez() {
        return this.$store.getters.liquidez;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      value() {
        if (this.indFinanceira) {
          if (this.isConsumptionValue)
            return this.indFinanceira.patrimonio.patrimonioConsumo;
          else
            return this.indFinanceira.patrimonio.patrimonioRenda;
        }

        return 0;
      }
    },
    methods: {
      copyData() {
        if (this.liquidez) {
          this.isConsumptionValue = this.liquidez.isConsumptionValue;
        }
      },
      getIndFinanceira() {
        if (this.allowed) {
          getUserIndFinanceira(this.user._id, this.currentDate.format('YYYY-MM'))
            .then(indFinanceira => {
              this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
            })
            .catch(err => {
              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      },
      submit() {
        if (this.allowed) {
          this.loader = true;

          let data = {
            isConsumptionValue: this.isConsumptionValue,
            independenceValue: this.value
          }

          updateLiquidez(this.liquidez._id, data)
            .then(liquidez => {
              this.loader = false;
              this.$store.dispatch('saveLiquidez', liquidez);
              this.$noty.success('Dados salvos com sucesso!')
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível salvar seus dados. Tente novamente!');

              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      }
    },
    created() {
      this.currentDate = moment().tz('America/Sao_Paulo');
      if (!this.indFinanceira)
        this.getIndFinanceira();
    }
  }
</script>

<style scoped>
  .v-card.column-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .v-card__title {
    display: block !important;
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 1.5em !important;
    letter-spacing: .1em !important;
  }

  .input-icon {
    font-size: 16px !important;
    margin-right: 5px;
    margin-top: 5px;
  }

  .total-container p {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .total-value {
    font-size: 18px;
  }

  .total-value span {
    font-size: 10px;
  }

  .input-label {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  @media only screen and (min-width: 600px) {
    .v-card__title {
      font-size: 15px !important;
    }

    .total-container p {
      font-size: 15px;
    }

    .total-value {
      font-size: 24px;
    }

    .total-value span {
      font-size: 13px;
    }
  }

  @media only screen and (min-width: 960px) {
    .v-card__title {
      font-size: 20px !important;
    }

    .total-container p {
      font-size: 15px;
    }

    .total-value {
      font-size: 24px;
    }

    .total-value span {
      font-size: 13px;
    }
  }

  @media only screen and (min-width: 1264px) {
    .v-card__title {
      font-size: 16px !important;
    }
  }
</style>
