import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const saveTransfer = (data, updateType = '', openedPeriod = '') => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = data.transferId ? 'PUT' : 'POST';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/transactions/transfers${ data.transferId ? '/' + data.transferId : '' }?`;

        if (updateType) {
          url += `updateType=${updateType}&`
        }

        if (openedPeriod) {
          url += `openedPeriod=${openedPeriod}`
        }

        return httpClient(url, { method, headers, data });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default saveTransfer;
