import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const saveBankAccount = data => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = data._id ? 'PUT' : 'POST';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/bank-accounts${data._id ? '/' + data._id : ''}`;

        return httpClient(url, { method, headers,data });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default saveBankAccount;