<template>
  <v-card color="grey2" height="100%">
    <v-card-title class="text-uppercase pb-0">Cenário real</v-card-title>

    <v-card-text>
      <div class="cenario-container">
        <span class="cenario-title grey1--text">Patrimônio atingido</span>
        <span class="cenario-value">{{ numberToCurrency(realidade.patrimonioAtingido) }}</span>
      </div>

      <div class="cenario-container">
        <span class="cenario-title grey1--text">Renda reservando patrimônio</span>
        <span class="cenario-value">{{ numberToCurrency(realidade.rendaReservaPatrimonio) }}</span>
      </div>

      <div class="cenario-container">
        <span class="cenario-title grey1--text">Renda consumindo patrimônio</span>
        <span class="cenario-value">{{ numberToCurrency(realidade.rendaConsumoPatrimonio) }}</span>
      </div>
    </v-card-text>

    <v-card-actions class="pt-0">
      <small class="advisor grey1--text">*Sem considerar projetos de vida</small>
    </v-card-actions>
  </v-card>
</template>

<script>
  import numberToCurrency from "../../../../utils/numberToCurrency";

  export default {
    name: 'CardCenarioReal',
    data: () => ({
      numberToCurrency
    }),
    computed: {
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      realidade() {
        return this.indFinanceira.realidade;
      }
    }
  }
</script>

<style scoped>
  .advisor {
    font-size: 9px;
    letter-spacing: .1em;
  }

  .v-card {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }

  .text-uppercase {
    font-size: 12px;
    letter-spacing: .1em;
  }

  .cenario-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .cenario-title {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .cenario-value {
    color: white;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  }

  @media only screen and (min-width: 600px) {
    .text-uppercase {
      font-size: 15px;
    }
  }

  @media only screen and (min-width: 960px) {
    .text-uppercase {
      font-size: 16px;
    }
  }
</style>
