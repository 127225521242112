import store from '@/store'
import { sumBy, uniqBy } from 'lodash'

const calcBalanceProjectionFromPeriod = (period, dayjs, accounts, filter) => {
  let total = 0

  if (!period) {
    return total
  }

  const currentDate = dayjs()
  const date = currentDate.format('YYYY-MM-DD')
  const currentPeriod = currentDate.format('YYYY-MM')
  const isCurrent = period === currentPeriod
  const isFuture = period > currentPeriod
  const transactions = store.getters.transactions

  if (!filter.bankAccounts.length) {
    const invoices = uniqBy(transactions.filter(tr => {
      const {creditCard, invoice} = tr

      if (creditCard && !creditCard.archived) {
        if (invoice && !invoice.paid) {
          return (
            (isCurrent && invoice.paymentDate.slice(0, 7) === period && invoice.paymentDate >= date) ||
            (isFuture && invoice.paymentDate.slice(0, 7) >= currentPeriod && invoice.paymentDate.slice(0, 7) < filter.period)
          )
        }

        return (tr.invoice && !tr.invoice.paid &&
          tr.invoice.paymentDate.slice(0, 7) >= period && tr.invoice.paymentDate.slice(0, 7) < filter.period)
      }
    }), tr => tr.invoice._id).map(tr => tr.invoice)

    if (invoices.length) {
      const invoicesTotal = Number.parseFloat(sumBy(invoices, 'value').toFixed(2))
      total = Number.parseFloat((total - invoicesTotal).toFixed(2))
    }
  }

  const periodTransactions = transactions.filter(tr => {
    if (!tr.invoice && !tr.consolidated && tr.bankAccount && !tr.bankAccount.archived && tr.date >= date) {
      return (
        (isCurrent && tr.date.slice(0, 7) === period) ||
        (isFuture && tr.date.slice(0, 7) === currentPeriod)
      )
    }
  })

  const revenue = periodTransactions.filter(tr => tr.type === 'entrada')
  const revenueTotal = parseFloat(sumBy(revenue, 'value').toFixed(2))

  const expenses = periodTransactions.filter(tr => tr.type === 'saida')
  const expenseTotal = parseFloat(sumBy(expenses, 'value').toFixed(2))

  total = parseFloat((total + revenueTotal - expenseTotal).toFixed(2))

  return total
}

export default calcBalanceProjectionFromPeriod
