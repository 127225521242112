<template>
  <v-main>
    <MenuAdmin />
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat color="transparent">
            <v-tabs v-model="tab" show-arrows background-color="transparent">
              <v-tab
                :key="i"
                :href="`#tab-${i}`"
                v-for="(item, i) in tabs"
              >
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-0" class="mt-3">
                <TablePendingRewards />
              </v-tab-item>
              <v-tab-item value="tab-1" class="mt-3">
                <TableAllRecommendations />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>

import MenuAdmin from '@/components/Manager/menu/MenuAdmin';
import TablePendingRewards from '@/components/Admin/recommendations/tables/TablePendingRewards';
import TableAllRecommendations from '@/components/Admin/recommendations/tables/TableAllRecommendations';

export default {
  name: 'Recommendations',
  data: () => ({
    tab: 'tab-0',
    tabs: ['Solicitações de Recompensa', 'Todas as Indicações']
  }),
  components: {
    MenuAdmin,
    TablePendingRewards,
    TableAllRecommendations,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  }

};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin-top: 46px;
  margin-right: auto;
  margin-left: auto;
}

.theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>
