<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
  >
    <v-card class="no-radius">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button mr-5"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text">{{ planner._id ? 'Editar' : 'Adicionar' }} planejador</span>
      </v-card-title>

      <v-progress-linear
        indeterminate
        :height="10"
        color="primary"
        v-if="loadLoader"
      ></v-progress-linear>

      <v-card-text class="mt-5">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 class="mb-5">
                <h1 class="white--text font-weight-black">Dados pessoais</h1>
              </v-flex>

              <v-flex xs12 lg6>
                <span class="input-label accent--text">Nome completo *</span>
                <v-text-field
                  dense
                  color="grey1"
                  v-model="planner.displayName"
                  :error-messages="displayNameErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg3>
                <span class="input-label accent--text">CPF</span>
                <v-text-field
                  dense
                  type="tel"
                  color="grey1"
                  v-mask="'###.###.###-##'"
                  v-model="planner.cpf_cnpj"
                  :error-messages="cpfCnpjErrors"
                  @input="$v.planner.cpf_cnpj.$touch()"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg3>
                <span class="input-label primary--text">Celular</span>

                <v-layout row wrap>
                  <v-flex xs4 class="py-0">
                    <v-select
                      dense
                      :items="countryPhoneMasks"
                      item-value="codigo"
                      item-text="sigla"
                      v-model="planner.phoneCountryCode"
                    >
                      <template v-slot:item="{ item, index }">
                        <img
                          :src="item.flag"
                          :alt="item.sigla"
                          style="width: 32px; height: 16px;"
                        >

                        <span class="ml-2">+{{ item.codigo }}</span>
                      </template>

                      <template v-slot:selection="{ item, index }">
                        <img
                          :src="item.flag"
                          :alt="item.sigla"
                          style="width: 32px; height: 16px;"
                        >
                      </template>
                    </v-select>
                  </v-flex>

                  <v-flex xs8 class="py-0">
                    <v-text-field
                      dense
                      type="tel"
                      color="grey1"
                      v-mask="phoneMask"
                      v-model="planner.phoneNumber"
                      :error-messages="phoneNumberErrors"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

              </v-flex>

              <v-flex xs12 lg3>
                <span class="input-label accent--text">CEP</span>
                <v-text-field
                  dense
                  type="tel"
                  color="grey1"
                  v-mask="'#####-###'"
                  v-model="planner.address.cep"
                  :error-messages="cepErrors"
                  @input="$v.planner.address.cep.$touch()"
                  :loading="addressLoader"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg6>
                <span class="input-label accent--text">Logradouro</span>
                <v-text-field
                  dense
                  color="grey1"
                  v-model="planner.address.logradouro"
                  :error-messages="logradouroErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg3>
                <span class="input-label accent--text">Número</span>
                <v-text-field
                  dense
                  type="number"
                  color="grey1"
                  v-model="planner.address.numero"
                  :error-messages="numeroErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg6>
                <span class="input-label accent--text">Complemento</span>
                <v-text-field
                  dense
                  color="grey1"
                  v-model="planner.address.complemento"
                  :error-messages="complementoErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg6>
                <span class="input-label accent--text">Bairro</span>
                <v-text-field
                  dense
                  color="grey1"
                  v-model="planner.address.bairro"
                  :error-messages="bairroErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg6>
                <span class="input-label accent--text">Estado</span>
                <v-autocomplete
                  dense
                  color="grey1"
                  :items="estados"
                  item-text="nome"
                  return-object
                  v-model="planner.address.estado"
                  :error-messages="estadoErrors"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 lg6>
                <span class="input-label accent--text">Cidade</span>
                <v-autocomplete
                  dense
                  color="grey1"
                  :items="cidades"
                  item-text="nome"
                  item-value="nome"
                  v-model="planner.address.cidade"
                  :error-messages="cidadeErrors"
                  :loading="citiesLoader"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 class="my-5" v-if="!planner._id">
                <h1 class="white--text font-weight-black">Dados de acesso</h1>
              </v-flex>

              <v-flex xs12 lg6 v-if="!planner._id">
                <span class="input-label accent--text">E-mail *</span>
                <v-text-field
                  dense
                  type="email"
                  color="grey1"
                  v-model="planner.email"
                  :error-messages="emailErrors"
                  @input="$v.planner.email.$touch()"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg3 v-if="!planner._id">
                <span class="input-label accent--text">Senha *</span>
                <v-text-field
                  dense
                  type="password"
                  color="grey1"
                  v-model="planner.password"
                  :error-messages="passwordErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg3 v-if="!planner._id">
                <span class="input-label accent--text">Repita a senha *</span>
                <v-text-field
                  dense
                  type="password"
                  color="grey1"
                  v-model="planner.repeatPassword"
                  :error-messages="repeatPasswordErrors"
                  @input="$v.planner.repeatPassword.$touch()"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 class="my-5" v-if="user.role === 'admin'">
                <h1 class="white--text font-weight-black">Dados de planejamento</h1>
              </v-flex>

              <v-flex
                xs12
                :sm6="planner.isUplanner"
                :sm12="!planner.isUplanner"
                v-if="user.role === 'admin'"
              >
                <v-switch
                  flat
                  inset
                  color="primary"
                  v-model="planner.isUplanner"
                  label="É um planejador UPlanner"
                ></v-switch>
              </v-flex>

              <v-slide-x-transition>
                <v-flex
                  xs12
                  sm6
                  v-if="['admin', 'supervisor'].includes(user.role) && planner.isUplanner"
                >
                  <v-switch
                    flat
                    inset
                    color="primary"
                    v-model="planner.atendimento.enabled"
                    label="Habilitado para atendimentos"
                  ></v-switch>
                </v-flex>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-flex xs12 sm6 md4 v-if="planner.isUplanner && !planner._id && user.role === 'admin'">
                  <span class="input-label primary--text">Supervisor</span>
                  <v-select
                    dense
                    color="grey1"
                    v-model="planner.supervisor"
                    :error-messages="supervisorErrors"
                    :items="supervisorsNames"
                    item-text="displayName"
                    item-value="_id"
                  ></v-select>
                </v-flex>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-flex xs12 sm6 md4 v-if="planner.isUplanner">
                  <span class="input-label primary--text">Limite de clientes</span>
                  <v-text-field
                    dense
                    type="tel"
                    color="grey1"
                    persistent-hint
                    suffix="clientes"
                    prefix="Até"
                    hint="0 (zero) indica que não há limites"
                    v-model="planner.atendimento.limiteClientes"
                    :error-messages="atendimentoLimiteClientesErrors"
                  ></v-text-field>
                </v-flex>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-flex xs12 sm6 md4 v-if="planner.isUplanner">
                  <span class="input-label primary--text">Faixa etária</span>
                  <v-text-field
                    dense
                    type="tel"
                    color="grey1"
                    persistent-hint
                    suffix="anos"
                    prefix="Até"
                    hint="Idade máxima permitida p/ atendimento"
                    v-model="planner.atendimento.idade"
                    :error-messages="atendimentoIdadeErrors"
                  ></v-text-field>
                </v-flex>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-flex xs12 sm6 md4 v-if="planner.isUplanner">
                  <span class="input-label primary--text">Faixa salarial</span>
                  <v-select
                    dense
                    color="grey1"
                    item-value="value"
                    item-text="text"
                    :items="faixasSalariais"
                    v-model="planner.atendimento.renda"
                    :error-messages="atendimentoRendaErrors"
                  ></v-select>
                </v-flex>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-flex xs12 sm6 md4 v-if="planner.isUplanner">
                  <span class="input-label primary--text">Link de video conferência</span>
                  <v-text-field
                    dense
                    color="grey1"
                    v-model="planner.atendimento.video"
                    :error-messages="atendimentoVideoErrors"
                  ></v-text-field>
                </v-flex>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-flex xs12 v-if="planner.isUplanner">
                  <h2 class="white--text font-weight-black">Dias de atendimento</h2>

                  <v-layout row wrap class="mx-0 mt-3 pa-0">
                    <v-flex
                      xs12 md6
                      v-for="dia in diasAtendimento"
                      :key="dia.key"
                      class="d-flex align-center px-0"
                    >

                      <div>
                        <div
                          class="d-flex align-center"
                          style="cursor: pointer"
                        >
                          <v-checkbox
                            density="compact"
                            v-model="planner.atendimento.dias[dia.key].selected"
                          ></v-checkbox>

                          <span
                            class="input-label"
                            :class="planner.atendimento.dias[dia.key].selected ? 'primary--text' : 'grey--text'"
                          >
                            {{ dia.text }}
                          </span>
                        </div>

                        <v-select
                          multiple
                          dense
                          :disabled="!planner.atendimento.dias[dia.key].selected"
                          color="grey1"
                          :items="horariosAtendimento"
                          item-text="text"
                          item-value="value"
                          v-model="planner.atendimento.dias[dia.key].horarios"
                        ></v-select>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-slide-x-transition>

              <v-flex xs12 class="mt-10">
                <v-card-actions class="pa-0">
                  <v-btn
                    text
                    rounded
                    class="px-5"
                    color="primary"
                    @click="close"
                    type="button"
                    :disabled="loader || loadLoader"
                  >
                    Cancelar
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    rounded
                    class="px-5 darkgrey--text"
                    type="submit"
                    color="primary"
                    :loading="loader"
                    :disabled="loadLoader"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {orderBy} from 'lodash'
import {isValid} from '@fnando/cpf'
import {required, minLength, maxLength, email} from 'vuelidate/lib/validators'

import getCompaniesNames from '@/api/companies/getCompaniesNames';
import getAddressByCep from '@/api/brasil-api/getAddressByCep';
import getCidadesByEstado from '@/api/address/getCidadesByEstado';
import checkEmailAlreadyUsed from '@/api/users/checkEmailAlreadyUsed';
import checkCpfCnpj from '@/api/users/checkCpfCnpj';
import stringCapitalize from '@/utils/stringCapitalize';
import saveUser from '@/api/users/saveUser';
import getUserById from '@/api/users/getUserById';
import countryPhoneMasks from '@/utils/countryPhoneMasks';
import getSelectedCountry from '@/utils/phone/getSelectedCountry';
import getCountryPhoneMasks from '@/utils/phone/getCountryPhoneMasks';

const CryptoJS = require('crypto-js')

import config from '@/config';
import getSupervisorsNames from "@/api/users/getSupervisorsNames";

export default {
  name: 'DialogFormPlanner',
  data: () => ({
    countryPhoneMasks,
    dialog: false,
    loader: false,
    cpfUsed: false,
    cpfLoader: false,
    emailUsed: false,
    emailLoader: false,
    citiesLoader: false,
    addressLoader: false,
    supervisorsNames: [],
    planner: {
      role: 'planner',
      displayName: '',
      cpf_cnpj: '',
      phoneNumber: '',
      phoneCountryCode: '55',
      email: '',
      password: '',
      passwordRepeat: '',
      isUplanner: false,
      scheduleUrl: '',
      supervisor: null,
      address: {
        cep: '',
        numero: '',
        bairro: '',
        cidade: '',
        logradouro: '',
        complemento: '',
        estado: null
      },
      company: null,
      atendimento: {
        idade: '',
        renda: null,
        video: '',
        dias: {
          segunda: {
            selected: true,
            horarios: ['manha', 'tarde', 'noite']
          },
          terca: {
            selected: true,
            horarios: ['manha', 'tarde', 'noite']
          },
          quarta: {
            selected: true,
            horarios: ['manha', 'tarde', 'noite']
          },
          quinta: {
            selected: true,
            horarios: ['manha', 'tarde', 'noite']
          },
          sexta: {
            selected: true,
            horarios: ['manha', 'tarde', 'noite']
          },
          sabado: {
            selected: true,
            horarios: ['manha', 'tarde', 'noite']
          }
        },
        enabled: true,
        limiteClientes: 0
      }
    },
    cidades: [],
    loadLoader: false,
    faixasSalariais: [
      {text: 'Até R$ 2.000,00', value: 2000},
      {text: 'Até R$ 5.000,00', value: 5000},
      {text: 'Até R$ 8.000,00', value: 8000},
      {text: 'Até R$ 15.000,00', value: 15000},
      {text: 'Sem limite', value: 0}
    ],
    horariosAtendimento: [
      {
        text: 'Manhã',
        value: 'manha'
      },
      {
        text: 'Tarde',
        value: 'tarde'
      },
      {
        text: 'Noite',
        value: 'noite'
      },
    ],
    diasAtendimento: [
      {
        text: 'Segunda-feira',
        key: 'segunda'
      },
      {
        text: 'Terça-feira',
        key: 'terca'
      },
      {
        text: 'Quarta-feira',
        key: 'quarta'
      },
      {
        text: 'Quinta-feira',
        key: 'quinta'
      },
      {
        text: 'Sexta-feira',
        key: 'sexta'
      },
      {
        text: 'Sábado',
        key: 'sabado'
      },
    ]
  }),
  validations: {
    planner: {
      displayName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(150)
      },
      cpf_cnpj: {
        required,
        isValid
      },
      phoneNumber: {
        required,
        maxLength: maxLength(50)
      },
      phoneCountryCode: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(32)
      },
      repeatPassword: {
        required,
        isValid(v) {
          return v === this.planner.password;
        }
      },
      supervisor: {
        isValid(v) {
          return !this.planner.isUplanner || !!this.planner._id || !!v
        }
      },
      address: {
        cep: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(9)
        },
        logradouro: {
          required,
          maxLength: maxLength(150)
        },
        bairro: {
          required,
          maxLength: maxLength(150)
        },
        cidade: {
          required,
          maxLength: maxLength(150)
        },
        numero: {
          required,
          isValid: v => v > 0
        },
        estado: {
          required
        },
        complemento: {
          maxLength: maxLength(150)
        }
      },
      atendimento: {
        idade: {
          isValid(v) {
            return !this.planner.isUplanner || (!!v && v <= 120)
          }
        },
        renda: {
          isValid(v) {
            return !this.planner.isUplanner || typeof v === 'number'
          }
        },
        video: {
          isValid(v) {
            return !this.planner.isUplanner || !!v
          }
        },
        limiteClientes: {
          isValid(v) {
            return v >= 0
          }
        }
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    user() {
      return this.$store.getters.user;
    },
    companiesNames() {
      return orderBy(this.$store.getters.companiesNames, 'displayName');
    },
    estados() {
      return orderBy(this.$store.getters.estados, 'nome');
    },
    selectedCountryCode() {
      return getSelectedCountry(this.planner.phoneCountryCode)
    },
    phoneMask() {
      return getCountryPhoneMasks(this.selectedCountryCode, this.planner.phoneNumber)
    },
    supervisorErrors() {
      if (!this.$v.planner.supervisor.$anyDirty) {
        return []
      }
      if (this.$v.planner.supervisor.$anyError) {
        return ['Selecione o supervisor']
      }
    },
    atendimentoRendaErrors() {
      const errors = []

      if (!this.$v.planner.atendimento.renda.$dirty) {
        return errors
      }

      !this.$v.planner.atendimento.renda.isValid && errors.push('Selecione uma faixa salarial')

      return errors
    },
    atendimentoIdadeErrors() {
      const errors = []

      if (!this.$v.planner.atendimento.idade.$dirty) {
        return errors
      }

      !this.$v.planner.atendimento.idade.isValid && errors.push('Preencha uma idade válida')

      return errors
    },
    atendimentoLimiteClientesErrors() {
      const errors = []

      if (!this.$v.planner.atendimento.limiteClientes.$dirty) {
        return errors
      }

      !this.$v.planner.atendimento.limiteClientes.isValid && errors.push('Preencha um limite válido')

      return errors
    },
    displayNameErrors() {
      const errors = [];
      if (!this.$v.planner.displayName.$dirty) return errors;
      (!this.$v.planner.displayName.required || !this.$v.planner.displayName.minLength ||
        !this.$v.planner.displayName.maxLength) && errors.push('Preencha um nome válido');
      return errors;
    },
    cpfCnpjErrors() {
      const errors = [];
      if (!this.$v.planner.cpf_cnpj.$dirty) return errors;
      (!this.$v.planner.cpf_cnpj.required || !this.$v.planner.cpf_cnpj.isValid) && errors.push('Preencha um CPF válido');
      this.cnpjUsed && errors.push('CNPJ já cadastrado');
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.planner.phoneNumber.$dirty) return errors;
      (!this.$v.planner.phoneNumber.required || !this.$v.planner.phoneNumber.maxLength ||
        !this.$v.planner.phoneCountryCode.required) && errors.push('Preencha um celular válido');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.planner.email.$dirty) return errors;
      (!this.$v.planner.email.required || !this.$v.planner.email.email) && errors.push('Preencha um e-mail válido');
      this.emailUsed && errors.push('E-mail já cadastrado');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.planner.password.$dirty) return errors;
      (!this.$v.planner.password.required || !this.$v.planner.password.minLength ||
        !this.$v.planner.password.maxLength) && errors.push('Preencha uma senha válida');
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.planner.repeatPassword.$dirty) return errors;
      (!this.$v.planner.repeatPassword.required || !this.$v.planner.repeatPassword.isValid) && errors.push('As senhas devem ser iguais');
      return errors;
    },
    cepErrors() {
      const errors = [];
      if (!this.$v.planner.address.cep.$dirty) return errors;
      (!this.$v.planner.address.cep.required || !this.$v.planner.address.cep.minLength ||
        !this.$v.planner.address.cep.maxLength) && errors.push('Preencha um CEP válido');
      return errors;
    },
    logradouroErrors() {
      const errors = [];
      if (!this.$v.planner.address.logradouro.$dirty) return errors;
      (!this.$v.planner.address.logradouro.required ||
        !this.$v.planner.address.logradouro.maxLength) && errors.push('Preencha um logradouro válido');
      return errors;
    },
    bairroErrors() {
      const errors = [];
      if (!this.$v.planner.address.bairro.$dirty) return errors;
      (!this.$v.planner.address.bairro.required ||
        !this.$v.planner.address.bairro.maxLength) && errors.push('Preencha um bairro válido');
      return errors;
    },
    cidadeErrors() {
      const errors = [];
      if (!this.$v.planner.address.bairro.$dirty) return errors;
      (!this.$v.planner.address.bairro.required ||
        !this.$v.planner.address.bairro.maxLength) && errors.push('Selecione uma cidade');
      return errors;
    },
    numeroErrors() {
      const errors = [];
      if (!this.$v.planner.address.numero.$dirty) return errors;
      (!this.$v.planner.address.numero.required ||
        !this.$v.planner.address.numero.isValid) && errors.push('Preencha um número válido');
      return errors;
    },
    estadoErrors() {
      const errors = [];
      if (!this.$v.planner.address.bairro.$dirty) return errors;
      !this.$v.planner.address.bairro.required && errors.push('Selecione um estado');
      return errors;
    },
    complementoErrors() {
      const errors = [];
      if (!this.$v.planner.address.complemento.$dirty) return errors;
      !this.$v.planner.address.complemento.maxLength && errors.push('Use no máximo 150 caracteres');
      return errors;
    },
    atendimentoVideoErrors() {
      const errors = []

      if (!this.$v.planner.atendimento.video.$dirty) {
        return errors
      }

      !this.$v.planner.atendimento.video.isValid && errors.push('Preencha o link da sala de video conferência')

      return errors
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.loadLoader = false;
      this.emailLoader = false;
      this.emailUsed = false;
      this.cnpjLoader = false;
      this.cnpjLoader = false;
      this.citiesLoader = false;
      this.addressLoader = false;
      this.planner = {
        role: 'planner',
        displayName: '',
        cpf_cnpj: '',
        phoneNumber: '',
        phoneCountryCode: '55',
        email: '',
        password: '',
        passwordRepeat: '',
        isUplanner: false,
        scheduleUrl: '',
        supervisor: null,
        address: {
          cep: '',
          numero: '',
          bairro: '',
          cidade: '',
          logradouro: '',
          complemento: '',
          estado: null
        },
        company: null,
        planner: null,
        atendimento: {
          idade: '',
          renda: null,
          video: '',
          dias: {
            segunda: {
              selected: true,
              horarios: ['manha', 'tarde', 'noite']
            },
            terca: {
              selected: true,
              horarios: ['manha', 'tarde', 'noite']
            },
            quarta: {
              selected: true,
              horarios: ['manha', 'tarde', 'noite']
            },
            quinta: {
              selected: true,
              horarios: ['manha', 'tarde', 'noite']
            },
            sexta: {
              selected: true,
              horarios: ['manha', 'tarde', 'noite']
            },
            sabado: {
              selected: true,
              horarios: ['manha', 'tarde', 'noite']
            }
          }
        }
      }
      this.planner.atendimento.idade = ''
      this.planner.atendimento.renda = null
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (this.planner._id) {
        this.$v.planner.password.$reset();
        this.$v.planner.repeatPassword.$reset();
      }

      if (!this.planner.isUplanner) {
        this.$v.planner.atendimento.$reset()
      }

      let hasError = false;

      Object.keys(this.$v.planner).forEach(key => {
        if (this.$v.planner[key].$anyError) {
          hasError = true;
        }
      });

      if (!hasError) {
        this.loader = true;

        let user = JSON.parse(JSON.stringify(this.planner));

        user.email = user.email.toLowerCase().trim();
        user.cpf_cnpj = user.cpf_cnpj.replace(/\D+/gm, '').trim();
        user.displayName = stringCapitalize(user.displayName).trim();
        user.phoneNumber = user.phoneNumber.replace(/\D+/gm, '').trim();
        user.phoneCountryCode = user.phoneCountryCode.replace(/\D+/gm, '').trim();
        user.address.cep = user.address.cep.replace(/\D+/gm, '').trim();

        if (!user.phoneCountryCode) {
          user.phoneCountryCode = '55'
        }

        if (user.isUplanner && user.atendimento.whereby) {
          user.atendimento.whereby = user.atendimento.whereby.trim()
        }

        if (!user._id) {
          delete user.repeatPassword
        }

        if (this.user.role === 'supervisor') {
          user.isUplanner = true
          user.supervisor = this.user._id
        }

        saveUser(user)
          .then(() => {
            this.$bus.$emit('managerGetUsers');
            this.close();
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível salvar este usuário. Tente novamente!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    getAddress() {
      this.$v.planner.address.cep.$touch();

      if (!this.$v.planner.address.cep.$anyError) {
        this.addressLoader = true;

        let cep = this.planner.address.cep.replace(/\D+/g, '');
        getAddressByCep(cep)
          .then(address => {
            this.addressLoader = false;

            this.planner.address.logradouro = address.street;
            this.planner.address.bairro = address.neighborhood;
            this.planner.address.cidade = address.city;
            this.planner.address.estado = this.estados.find(
              e => e.sigla.toLowerCase() === address.state.toLowerCase()
            );
          })
          .catch(err => {
            this.addressLoader = false;

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    getCidades() {
      if (this.planner.address.estado && this.planner.address.estado.id) {
        this.citiesLoader = true;

        getCidadesByEstado(this.planner.address.estado.id)
          .then(cidades => {
            this.citiesLoader = false;
            this.cidades = cidades;
          })
          .catch(err => {
            this.citiesLoader = false;

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    checkEmail() {
      if (
        this.planner.email &&
        !this.$v.planner.email.$anyError &&
        !this.planner._id
      ) {
        this.emailLoader = true;
        let email = this.planner.email.toLowerCase().trim();

        checkEmailAlreadyUsed(email, this.planner._id)
          .then(resp => {
            this.emailUsed = resp.exists;
            this.emailLoader = false;
          })
          .catch(err => {
            this.emailUsed = false;
            this.emailLoader = false;

            if (process.env.NODE_ENV !== 'production') {
              console.log(err);
            }
          });
      } else {
        this.emailUsed = false;
      }
    },
    checkCpfCnpj() {
      if (this.planner.cpf_cnpj && !this.$v.planner.cpf_cnpj.$anyError) {
        this.cpfLoader = true;
        let cpf_cnpj = this.planner.cpf_cnpj.replace(/\D+/g, '');

        checkCpfCnpj(cpf_cnpj, this.planner._id, 'company')
          .then(resp => {
            this.cpfUsed = resp.exists;
            this.cpfLoader = false;
          })
          .catch(err => {
            this.cpfUsed = false;
            this.cpfLoader = false;

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      } else {
        this.cnpjUsed = false;
      }
    },
    openDialog(userId) {
      this.getSupervisorsNames()

      if (userId) {
        this.loadLoader = true;

        getUserById(userId)
          .then(user => {
            this.planner = user;

            if (!this.planner.phoneCountryCode) {
              this.planner.phoneCountryCode = '55'
            }

            if (!this.planner.atendimento) {
              this.planner.atendimento = {
                idade: '',
                renda: null,
                video: '',
                dias: {
                  segunda: {
                    selected: true,
                    horarios: ['manha', 'tarde', 'noite']
                  },
                  terca: {
                    selected: true,
                    horarios: ['manha', 'tarde', 'noite']
                  },
                  quarta: {
                    selected: true,
                    horarios: ['manha', 'tarde', 'noite']
                  },
                  quinta: {
                    selected: true,
                    horarios: ['manha', 'tarde', 'noite']
                  },
                  sexta: {
                    selected: true,
                    horarios: ['manha', 'tarde', 'noite']
                  },
                  sabado: {
                    selected: true,
                    horarios: ['manha', 'tarde', 'noite']
                  }
                },
                enabled: true,
                limiteClientes: 0
              }
            } else if (!this.planner.atendimento.dias.segunda) {
              delete this.planner.atendimento.horarios

              this.planner.atendimento.dias = {
                segunda: {
                  selected: true,
                  horarios: ['manha', 'tarde', 'noite']
                },
                terca: {
                  selected: true,
                  horarios: ['manha', 'tarde', 'noite']
                },
                quarta: {
                  selected: true,
                  horarios: ['manha', 'tarde', 'noite']
                },
                quinta: {
                  selected: true,
                  horarios: ['manha', 'tarde', 'noite']
                },
                sexta: {
                  selected: true,
                  horarios: ['manha', 'tarde', 'noite']
                },
                sabado: {
                  selected: true,
                  horarios: ['manha', 'tarde', 'noite']
                }
              }
            } else {
              if (typeof this.planner.atendimento.dias.segunda.selected === 'undefined') {
                this.planner.atendimento.dias.segunda.selected = true
              }

              if (typeof this.planner.atendimento.dias.terca.selected === 'undefined') {
                this.planner.atendimento.dias.terca.selected = true
              }

              if (typeof this.planner.atendimento.dias.quarta.selected === 'undefined') {
                this.planner.atendimento.dias.quarta.selected = true
              }

              if (typeof this.planner.atendimento.dias.quinta.selected === 'undefined') {
                this.planner.atendimento.dias.quinta.selected = true
              }

              if (typeof this.planner.atendimento.dias.sexta.selected === 'undefined') {
                this.planner.atendimento.dias.sexta.selected = true
              }

              if (typeof this.planner.atendimento.dias.sabado.selected === 'undefined') {
                this.planner.atendimento.dias.sabado.selected = true
              }
            }

            if (this.planner.atendimento &&
              (typeof this.planner.atendimento.limiteClientes === 'undefined' ||
                typeof this.planner.atendimento.enabled === 'undefined')
            ) {
              this.planner.atendimento.enabled = true
              this.planner.atendimento.limiteClientes = 0
            }

            if (this.user.role === 'supervisor') {
              this.planner.isUplanner = true
              this.planner.supervisor = this.user._id
            }

            this.loadLoader = false;
          })
          .catch(err => {
            this.close();
            this.$noty.error('Desculpe! Não foi possível buscar esse usuário. Atualize a página e tente novamente!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      } else if (this.user.role === 'supervisor') {
        this.planner.isUplanner = true
        this.planner.supervisor = this.user._id
      }


      this.dialog = true;
    },
    async getSupervisorsNames() {
      try {
        this.supervisorsNames = await getSupervisorsNames()
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.supervisorsNames = []
      }
    }
  },
  watch: {
    'planner.address.cep'() {
      this.getAddress();
    },
    'planner.address.estado'() {
      this.getCidades();
    },
    'planner.email'() {
      this.checkEmail();
    },
    'planner.cpf_cnpj'() {
      this.checkCpfCnpj();
    }
  },
  created() {
    this.$bus.$on('showFormPlanner', this.openDialog)
  }
}
</script>

<style scoped>
.container {
  max-width: 960px;
}

.input-label {
  font-weight: bold;
  letter-spacing: normal;
}
</style>
