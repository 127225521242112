<template>
  <v-card flat>
    <v-card-title>
      <span class="desc primary--text">Perfil</span>
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-text-field
          color="grey1"
          label="Nome"
          v-model="user.displayName"
          :error-messages="displayNameErrors"
          @input="$v.user.displayName.$touch()"
        ></v-text-field>

        <div v-if="user.role === 'customer' || user.role === 'planner'">
          <v-text-field
            color="grey1"
            label="CPF"
            v-model="user.cpf_cnpj"
            v-mask="'###.###.###-##'"
            :error-messages="docErrors"
            @input="$v.user.cpf_cnpj.$touch()"
          ></v-text-field>
        </div>

        <div v-if="user.role === 'company'">
          <v-text-field
            color="grey1"
            label="Razão Social"
            v-model="user.razaoSocial"
          ></v-text-field>

          <v-text-field
            type="tel"
            label="CNPJ"
            color="primary"
            v-model="user.cpf_cnpj"
            v-mask="'##.###.###/####-##'"
            :error-messages="docErrors"
            @input="$v.user.cpf_cnpj.$touch()"
          ></v-text-field>
        </div>

        <div v-if="user.role !== 'admin'">
          <v-text-field
            type="tel"
            label="CEP"
            color="primary"
            v-model="user.address.cep"
            v-mask="'##.###-###'"
          ></v-text-field>

          <v-text-field
            color="primary"
            label="Logradouro"
            v-model="user.address.logradouro"
          ></v-text-field>

          <v-text-field
            type="tel"
            color="primary"
            label="Número"
            v-model="user.address.numero"
          ></v-text-field>

          <v-text-field
            type="text"
            color="primary"
            label="Complemento"
            v-model="user.address.complemento"
          ></v-text-field>

          <v-text-field
            color="primary"
            label="Bairro"
            v-model="user.address.bairro"
          ></v-text-field>

          <v-autocomplete
            return-object
            label="Estado"
            color="primary"
            :items="estados"
            item-text="nome"
            v-model="user.address.estado"
          ></v-autocomplete>

          <v-autocomplete
            label="Cidade"
            color="primary"
            :items="cidades"
            item-text="nome"
            item-value="nome"
            v-model="user.address.cidade"
          ></v-autocomplete>
        </div>

        <v-card-actions class="px-0 pb-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            rounded
            depressed
            class="px-5 darkgrey--text"
            type="submit"
            color="primary"
            :loading="loader"
          >Salvar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import estados from '@/utils/estados';
import saveUser from '@/api/users/saveUser';
import checkCpfCnpj from '@/api/users/checkCpfCnpj';
import validateCpfCnpj from '@/utils/validateCpfCnpj';
import stringCapitalize from '@/utils/stringCapitalize';
import getAddressByCep from '@/api/address/getAddressByCep';
import getCidadesByEstado from '@/api/address/getCidadesByEstado';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'CardFormProfile',
  data: () => ({
    estados,
    user: {
      displayName: '',
      razaoSocial: '',
      cpf_cnpj: '',
      address: {
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: {}
      }
    },
    loader: false,
    cidades: [],
    cpfUsed: false
  }),
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    displayNameErrors() {
      const errors = [];
      if (!this.$v.user.displayName.$dirty) return errors;
      !this.$v.user.displayName.required &&
        errors.push('Nome/Razão social é obrigatório');
      !this.$v.user.displayName.minLength &&
        errors.push('O nome/razão social deve conter, no mínimo, 3 caracteres');
      !this.$v.user.displayName.maxLength &&
        errors.push(
          'O nome/razão social deve conter, no máximo, 100 caracteres'
        );
      return errors;
    },
    docErrors() {
      const errors = [];
      if (!this.$v.user.cpf_cnpj.$dirty) return errors;
      !this.$v.user.cpf_cnpj.isValid && errors.push('Número inválido');
      this.cpfUsed && errors.push('Documento já cadastrado');
      return errors;
    }
  },
  methods: {
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError && !this.cpfUsed) {
        this.loader = true;

        let user = JSON.parse(JSON.stringify(this.user));

        user.displayName = stringCapitalize(user.displayName);
        user.cpf_cnpj = user.cpf_cnpj
          ? user.cpf_cnpj.replace(/\D+/gm, '')
          : null;
        user.address.cep = user.address.cep
          ? user.address.cep.replace(/\D+/gm, '')
          : null;

        saveUser(user)
          .then(user => {
            this.$store.dispatch('setUser', user);
            this.loader = false;
            this.$noty.success('Dados salvos com sucesso!');
          })
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);
            this.loader = false;
            this.$noty.error('Falha ao salvar dados! Tente novamente.');
          });
      }
    },
    getAddress() {
      if (this.user.address.cep && this.user.address.cep.length === 10) {
        let cep = this.user.address.cep.replace(/\D+/gm, '');

        getAddressByCep(cep)
          .then(address => {
            if (!this.user.address) this.user.address = {};

            this.user.address.logradouro = address.logradouro;
            this.user.address.bairro = address.bairro;
            this.user.address.cidade = address.localidade;
            this.user.address.estado = this.estados.find(
              e => e.sigla.toLowerCase() === address.uf.toLowerCase()
            );
          })
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    getCidades() {
      if (
        this.user.address &&
        this.user.address.estado &&
        this.user.address.estado.id
      ) {
        getCidadesByEstado(this.user.address.estado.id)
          .then(cidades => (this.cidades = cidades))
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    checkCpfCnpj() {
      if (this.user.cpf_cnpj && !this.$v.user.cpf_cnpj.$anyError) {
        let cpf_cnpj = this.user.cpf_cnpj.replace(/\D+/g, '');
        checkCpfCnpj(cpf_cnpj, this.user._id)
          .then(resp => (this.cpfUsed = resp.exists))
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    }
  },
  validations: {
    user: {
      displayName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100)
      },
      cpf_cnpj: { isValid: validateCpfCnpj }
    }
  },
  watch: {
    'user.address.cep'() {
      this.getAddress();
    },
    'user.address.estado'() {
      this.getCidades();
    },
    'user.cpf_cnpj'() {
      this.checkCpfCnpj();
    }
  },
  mounted() {
    this.user = JSON.parse(JSON.stringify(this.loggedUser));
    if (!this.user.address) this.user.address = {};
  }
};
</script>

<style scoped>
.desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .1em;
}
</style>
