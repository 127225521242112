<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    :persistent="loader"
  >
    <v-card color="grey3">
      <v-card-title class="safe-area-top pt-3 darkgrey d-flex justify-space-between">
        <v-btn
          icon
          color="primary"
          :disabled="loader"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text dialog-title">Importar Usuários</span>

        <v-btn icon></v-btn>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <div>
            <span class="input-label primary--text">Empresa</span>
            <v-autocomplete
              name="company"
              color="grey1"
              class="mt-0 pt-0"
              v-model="company"
              :items="companiesNames"
              item-text="displayName"
              item-value="_id"
              :error-messages="companyErrors"
            ></v-autocomplete>
          </div>

          <div>
            <span class="input-label primary--text">Selecionar planilha</span>
            <v-file-input
              name="file"
              :accept="accept"
              prepend-icon=""
              color="grey1"
              class="mt-0 pt-0"
              show-size
              id="file"
              ref="file"
              v-model="file"
            ></v-file-input>
          </div>

          <v-card-actions class="px-0 safe-area-bottom">
            <v-spacer></v-spacer>

            <v-btn
              dark
              large
              rounded
              depressed
              class="px-5"
              type="submit"
              color="purple"
              :loading="loader"
            >
              Importar
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import config from '@/config';
import getCompaniesNames from '@/api/companies/getCompaniesNames';
import { orderBy } from 'lodash';
import { required } from 'vuelidate/lib/validators'
import uploadUsers from '@/api/users/updloadUsers';

export default {
  name: 'DialogImportUsers',
  data: () => ({
    dialog: false,
    loader: false,
    company: null,
    file: null,
    host: config.api.host,
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
  }),
  validations: {
    company: { required }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    companiesNames() {
      return orderBy(this.$store.getters.companiesNames, 'displayName');
    },
    companyErrors() {
      if (!this.$v.company.$anyDirty) {
        return []
      }
      if (!this.$v.company.required) {
        return ['Selecione a empresa']
      }
    }
  },
  methods: {
    openDialog() {
      this.getCompanies()
      this.dialog = true
    },
    reset() {
      this.loader = false
      this.company = null
      this.file = null
      this.$v.$reset()
    },
    getCompanies() {
      getCompaniesNames()
        .then(companies => {
          this.$store.dispatch('setCompaniesNames', companies);
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    },
    submit() {
      this.$v.$touch()

      if (!this.$anyError) {
        this.loader = true

        let formData = new FormData()

        formData.append('file', this.file)
        formData.append('company', this.company)

        uploadUsers(formData)
          .then(resp => {
            this.$bus.$emit('showDialogUploadUsersResult', resp)
            this.dialog = false
          })
          .catch(err => {
            console.error(err)
            this.loader = false
          })
      }
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.reset()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogImportUsers', this.openDialog)
  }
}
</script>
