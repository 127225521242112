<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="400px"
    :fullscreen="isMobile"
  >
    <v-card color="grey3" :class="isMobile ? 'no-radius' : ''">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="primary"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="primary--text ml-4">
          {{ transaction._id ? 'Editar' : 'Novo' }} Aporte/Resgate
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="input-label primary--text">Tipo</span>
                <v-select
                  dense
                  color="grey1"
                  :items="types"
                  item-text="text"
                  item-value="value"
                  v-model="transaction.type"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Data</span>
                <v-dialog
                  ref="dialog"
                  v-model="dateMenu"
                  :return-value.sync="transaction.date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 pt-0"
                      :value="formatDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        color="grey1"
                        class="input-icon"
                        slot="prepend-inner"
                      >
                        mdi-calendar
                      </v-icon>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    color="primary"
                    v-model="transaction.date"
                    scrollable
                    locale="pt-br"
                    :min="initialDate"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey2" @click="dateMenu = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.dialog.save(transaction.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Valor</span>
                <v-text-field
                  dense
                  type="tel"
                  color="grey1"
                  v-money="money"
                  v-model="transaction.value"
                  :error-messages="valueErrors"
                  id="input-investment-tr-value"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Descrição</span>
                <v-text-field
                  dense
                  color="grey1"
                  v-model="transaction.description"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    fab
                    small
                    type="submit"
                    color="primary"
                    :loading="loader"
                    class="darkgrey--text"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money';
import { required } from 'vuelidate/lib/validators';
import removeMoneyMask from '@/utils/removeMoneyMask';
import saveInvestmentTransaction from '@/api/investimentos/saveInvestmentTransaction';

export default {
  name: 'DialogFormInvestmentTransaction',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    investmentId: null,
    dateMenu: false,
    transaction: {
      type: 'in',
      date: '',
      value: '',
      description: '',
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    },
    types: [
      { text: 'Aporte', value: 'in' },
      { text: 'Resgate', value: 'out' },
    ],
    initialDate: ''
  }),
  validations: {
    transaction: {
      date: {
        required,
      },
      value: {
        greaterThanZero(v) {
          return removeMoneyMask(v) > 0;
        },
      },
    },
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.transaction.date.$dirty) return errors;
      !this.$v.transaction.date.required && errors.push('Insira a data');
      !this.$v.transaction.date.isValid && errors.push('Data inválida');
      return errors;
    },
    valueErrors() {
      const errors = [];
      if (!this.$v.transaction.value.$dirty) return errors;
      !this.$v.transaction.value.greaterThanZero &&
        errors.push('Insira um valor maior do que R$ 0,00');
      return errors;
    },
    formatDate() {
      if (this.transaction.date)
        return this.$date(this.transaction.date).format('DD/MM/YYYY');
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.investmentId = null;
      this.initialDate = ''
      this.transaction = {
        type: 'in',
        date: '',
        value: 0,
        description: '',
      };
      this.resetMoneyInput();
      this.$v.$reset();
    },
    resetMoneyInput() {
      let input = document.getElementById('input-investment-tr-value');
      if (input) {
        input.value = this.transaction.value;
        input.dispatchEvent(new Event('input'));
      }
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        let transaction = JSON.parse(JSON.stringify(this.transaction));

        transaction.value = removeMoneyMask(transaction.value);

        saveInvestmentTransaction(this.investmentId, transaction)
          .then((data) => {
            this.$store.dispatch('saveInvestmentTransaction', {
              transaction: data,
              investmentId: this.investmentId,
            });

            this.close();
          })
          .catch((err) => {
            this.loader = false;
            this.$noty.error(
              'Desculpe! Não foi possível salvar este item. Tente novamente!'
            );

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
  },
  created() {
    this.$bus.$on('showDialogFormInvestmentTransaction', (data) => {
      if (data.investmentId) {
        this.investmentId = data.investmentId;
        this.initialDate = data.initialDate

        if (data.transaction) {
          this.transaction = JSON.parse(JSON.stringify(data.transaction));
          this.transaction.value = this.transaction.value.toFixed(2);
          this.transaction.date = this.$date(this.transaction.date).format(
            'YYYY-MM-DD'
          );

          this.resetMoneyInput();
        } else {
          this.transaction.date = this.$date().format('YYYY-MM-DD');
        }

        this.dialog = true;
      }
    });
  },
};
</script>

<style scoped>
.input-label {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: 0.1em;
}
</style>
