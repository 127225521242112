<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    max-width="600px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey3" v-if="plan">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="primary"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span>Detalhes Plano de Ação </span>

      </v-card-title>

      <v-card-text class="mt-3">
        <div class="plan-info d-flex align-start">
          <v-icon small color="primary" class="mr-2">mdi-file-outline</v-icon>

          <div class="plan-info-content d-flex flex-column">
            <span class="plan-info-title white--text">Título</span>
            <span class="plan-info-desc grey1--text">{{ plan.description }}</span>
          </div>
        </div>

        <div class="plan-info d-flex align-start mt-5" v-if="plan.observations">
          <v-icon small color="primary" class="mr-2">mdi-clipboard-text-outline</v-icon>

          <div class="plan-info-content d-flex flex-column">
            <span class="plan-info-title white--text">Descrição</span>
            <span class="plan-info-desc grey1--text">{{ plan.observations }}</span>
          </div>
        </div>

        <div class="plan-info d-flex align-start mt-5">
          <v-icon small color="primary" class="mr-2">mdi-account-group-outline</v-icon>

          <div class="plan-info-content d-flex flex-column">
            <span class="plan-info-title white--text">Responsáveis</span>
            <span class="plan-info-desc grey1--text">{{ plan.responsibles.join(', ') }}</span>
          </div>
        </div>

        <div class="plan-info d-flex align-start mt-5">
          <v-icon small color="primary" class="mr-2">mdi-calendar-blank</v-icon>

          <div class="plan-info-content d-flex flex-column">
            <span class="plan-info-title white--text">Criado em</span>
            <span class="plan-info-desc grey1--text">{{ formatDate(plan.date) }}</span>
          </div>
        </div>

        <div class="plan-info d-flex align-start mt-5">
          <v-icon small color="primary" class="mr-2">mdi-calendar-alert</v-icon>

          <div class="plan-info-content d-flex flex-column">
            <span class="plan-info-title white--text">Data limite</span>
            <span class="plan-info-desc grey1--text">{{ formatDate(plan.deadline) }}</span>
          </div>
        </div>

        <div class="plan-info d-flex align-start mt-5">
          <v-icon small color="primary" class="mr-2">mdi-flag-outline</v-icon>

          <div class="plan-info-content d-flex flex-column">
            <span class="plan-info-title white--text">Prioridade</span>
            <span class="plan-info-desc grey1--text">{{ plan.type.name }}</span>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="pb-2">
        <v-spacer></v-spacer>
        <v-btn
          icon
          large
          class="mx-2"
          color="error"
          @click="remove"
          :disabled="loader || !allowed"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          large
          class="mx-2"
          :loading="loader"
          @click="updatePlan"
          :disabled="!allowed"
          :color="plan.completed ? 'error' : 'success'"
        >
          <v-icon>{{ plan.completed ? 'mdi-cancel' : 'mdi-check-circle-outline' }}</v-icon>
        </v-btn>

        <v-btn
          icon
          large
          class="mx-2"
          color="white"
          @click="edit"
          :disabled="loader || !allowed"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment-timezone'
import saveActionPlan from '@/api/actionPlans/saveActionPlan';

export default {
  name: 'DialogPlanoAcao',
  data: () => ({
    dialog: false,
    plan: null,
    loader: false
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    allowed() {
      return this.$store.getters.allowed;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.plan = null;
    },
    edit() {
      this.$bus.$emit('showDialogFormPlanoAcao', this.plan);
      this.close();
    },
    remove() {
      if (this.allowed) {
        this.$bus.$emit('showDialogRemovePlanoAcao', {
          _id: this.plan._id,
          description: this.plan.description
        });
        this.close();
      }
    },
    formatDate(date) {
      if (date)
        return moment(date).add(3, 'hours').locale('pt-BR').format('DD MMMM, YYYY');

      return '';
    },
    updatePlan() {
      this.loader = true;

      this.plan.completed = !this.plan.completed;

      saveActionPlan(this.plan)
        .then(actionPlan => {
          this.$store.dispatch('saveActionPlan', actionPlan);
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          this.$noty.error('Desculpe! Não foi possível salvar este plano. Tente novamente mais tarde!');

          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    }
  },
  created() {
    this.$bus.$on('showDialogPlanoAcao', plan => {
      if (plan) {
        this.plan = JSON.parse(JSON.stringify(plan));
        this.dialog = true;
      }
    });
  }
}
</script>

<style scoped>
.dialog-form-title {
  background-color: transparent !important;
}

.plan-info-title,
.plan-info-desc {
  font-size: 12px;
  line-height: 1.5em;
  font-weight: bold;
  letter-spacing: .1em;
}

.plan-info-desc {
  font-weight: normal;
}

@media only screen and (min-width: 375px) {
  .plan-info-title,
  .plan-info-desc {
    font-size: 15px;
  }
}
</style>
