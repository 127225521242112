import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const deleteTransfer = (transferId, fixedId = '', deleteType = '') => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'DELETE';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/transactions/transfers/${transferId}?fixedId=${fixedId}&deleteType=${deleteType}`;

        return httpClient(url, { headers, method });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export  default deleteTransfer;

