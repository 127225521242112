<template>
  <v-card flat color="grey2">
    <v-card-text>
      <div class="text-right hidden-sm-and-down">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon color="grey1" dark v-on="on">mdi-information-outline</v-icon>
          </template>
          <span>
            Total de usuários registrados.
            <br />Exceto administradores
          </span>
        </v-tooltip>
      </div>

      <div class="total-container">
        <span class="total-number">{{ totalUsers }}</span>
        <span class="total-desc">Usuários Registrados</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import countUsers from '@/api/users/countUsers';

export default {
  name: 'CardTotalActiveUsers',
  data: () => ({
    totalUsers: 0
  }),
  methods: {
    countUsers() {
      countUsers()
        .then(data => {
          this.totalUsers = data.count;
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  created() {
    this.countUsers();
  },
  mounted() {
    setInterval(this.countUsers, 60000);
  }
};
</script>

<style scoped>
.total-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
}

.total-number {
  font-size: 35px;
  margin-bottom: 10px;
  font-family: 'Roboto Condensed', Roboto, sans-serif;
}

.total-desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
  letter-spacing: .1em;
}

@media only screen and (max-width: 959px) {
  .v-card__text {
    padding: 10px 5px 5px 5px !important;
  }
}

@media only screen and (max-width: 599px) {
  .total-container {
    padding-top: 0;
  }

  .total-number {
    margin-bottom: 0;
    font-size: 25px;
  }

  .total-desc {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 507px) {
  .total-desc {
    letter-spacing: auto;
    font-size: 8px;
    text-align: center;
  }
}
</style>
