<template>
  <v-dialog
    fullscreen
    v-model="dialog"
  >
    <v-card>
      <div class="card-title" :class="isIOS ? 'ios-padding' : ''">
        <h1>Proteger os seus dados<br> é proteger você<span class="primary--text">!</span></h1>

        <div class="card-text mt-5">
          <p>
            Quando dizemos que o nosso plano é cuidar de você, não falamos apenas do seu dinheiro, mas também da
            segurança dos seus dados. Com a nova Lei Geral de Proteção de Dados (Lei nº 13.079), a UPlanner tem mais uma
            oportunidade de provar o seu compromisso com a integridade, transparência e confidencialidade.
          </p>

          <p>
            Informamos que, para melhor atender a todos, realizamos um aditivo nos termos de adesão, a tratar de
            informações para os novos usuários. O aditivo encontra-se nos tópicos 8.2.1 - Da renovação. Os demais tópicos foram
            renumerados para coerência, porém sem alterações textuais. <b>Salientamos que as alterações mencionadas, passam
            a ter validade a partir do dia 20/12/2022 para todos os usuários.</b>
          </p>

          <p>
            Por isso, atualizamos nosso
            <router-link to="/termos-uso">Termo de adesão</router-link>
            e nossa
            <router-link to="/politica-privacidade">Política de privacidade</router-link>
            .
          </p>
        </div>

        <div class="card-action">
          <v-btn
            block
            large
            rounded
            color="primary"
            @click="accept"
            :loading="loader"
            class="darkgrey--text"
          >
            Aceitar
          </v-btn>
        </div>
      </div>

      <img src="../../assets/illustrations/undraw_security_o890.svg" class="draw">
    </v-card>
  </v-dialog>
</template>

<script>
import acceptTerms from '@/api/users/acceptTerms';
import getIpData from "@/api/ipapi/getIpData";
import {pick} from "lodash";

let checkInterval;

export default {
  name: 'DialogTerms',
  data: () => ({
    dialog: false,
    loader: false
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    route() {
      return this.$route.path;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    }
  },
  methods: {
    async accept() {
      try {
        this.loader = true

        const ipData = await getIpData()

        const acceptData = {
          accepted: true,
          date: this.$date().toISOString(),
          ipData: pick(ipData, [ 'ip', 'version', 'timezone' ])
        }

        const user = await acceptTerms(this.user._id, acceptData)

        await this.$store.dispatch('setUser', user)

        this.dialog = false
        this.loader = false

        clearInterval(checkInterval)
      } catch (err) {
        this.loader = false

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      }
    }
  },
  watch: {
    route() {
      if (['/termos-uso', '/politica-privacidade'].includes(this.route)) {
        this.dialog = false;
      }
    },
    user() {
      checkInterval = setInterval(() => {
        if (this.user && !this.user.termsAndPrivacyAccepted &&
          !this.dialog && !['/termos-uso', '/politica-privacidade'].includes(this.route)) {
          this.dialog = true;
        } else if (this.user && this.user.termsAndPrivacyAccepted) {
          this.dialog = false;
          clearInterval(checkInterval);
        }
      }, 1000);
    }
  },
  created() {
    checkInterval = setInterval(() => {
      if (this.user && !this.user.termsAndPrivacyAccepted &&
        !this.dialog && !['/termos-uso', '/politica-privacidade'].includes(this.route)) {
        this.dialog = true;
      } else if (this.user && this.user.termsAndPrivacyAccepted) {
        this.dialog = false;
        clearInterval(checkInterval);
      }
    }, 1000);
  }
}
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.card-title {
  width: 100%;
  padding: 16px 16px 0 16px;
}

.card-title h1 {
  font-size: 24px;
  line-height: 1.1em;
}

.draw {
  max-width: 55%;
}

.card-text p {
  font-size: 12px;
  line-height: 1.5em;
}

.card-action {
  width: 100%;
}

@media only screen and (min-width: 375px) {
  .card-title h1 {
    font-size: 30px;
  }

  .card-text p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 414px) {
  .card-title h1 {
    font-size: 30px;
  }

  .card-text p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .card-title {
    max-width: 600px;
  }

  .draw {
    max-width: 30%;
  }
}
</style>


