<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          class="mr-3"
          :disabled="loader"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="white--text">Atribuir assinatura à {{ name }}</span>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-form @submit.prevent="submit">
          <span class="input-label primary--text">ID da Assinatura Iugu</span>
          <v-text-field
            type="text"
            color="grey1"
            class="pt-0 mt-0"
            v-model="subscriptionId"
            :error-messages="subscriptionIdErrors"
          ></v-text-field>

          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>

            <v-btn
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              class="px-5 darkgrey--text"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import updateUserIuguSubscription from '@/api/users/updateUserIuguSubscription';

export default {
  name: 'DialogUpdateUserIuguSubscription',
  data: () => ({
    dialog: false,
    loader: false,
    userId: '',
    name: '',
    subscriptionId: ''
  }),
  validations: {
    subscriptionId: {
      required,
      minLength: minLength(32),
      maxLength: maxLength(32)
    }
  },
  computed: {
    subscriptionIdErrors() {
      if (!this.$v.subscriptionId.$anyDirty) {
        return [];
      }
      if (this.$v.subscriptionId.$anyError) {
        return ['Preencha um identificador válido'];
      }
    }
  },
  methods: {
    openDialog({ name, userId, subscriptionId }) {
      this.name = name;
      this.userId = userId;

      if (subscriptionId) {
        this.subscriptionId = subscriptionId;
      }

      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.loader = false;
      this.name = '';
      this.userId = '';
      this.subscriptionId = '';
      this.$v.$reset();
    },
    async submit() {
      try {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          await updateUserIuguSubscription(this.userId, this.subscriptionId);

          this.closeDialog();
          this.$bus.$emit('managerGetUsers');
          this.$noty.success('Assinatura atribuída com sucesso!');
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }

        this.$noty.error('Falha ao atribuir assinatura. Tente novamente mais tarde.');
      } finally {
        this.loader = false;
      }
    }
  },
  created() {
    this.$bus.$on('showDialogUpdateUserIuguSubscription', this.openDialog);
  }
};
</script>
