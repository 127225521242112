<template>
  <v-bottom-sheet v-model="sheet">
    <v-sheet
      color="grey3"
      class="text-center safe-area-bottom"
      height="200px"
    >
      <v-flex class="d-flex flex-wrap justify-center align-center">
        <ShareNetwork
          v-for="network in networks"
          :network="network.rede"
          :key="network.rede"
          :title="title"
          :url="user.shortLink ? user.shortLink : user.recommendationLink"
          :description="desc.description"

          class="text-decoration-none"
        >
          <v-flex class="ma-8">
            <v-icon large :color="network.color">{{ network.icon }}</v-icon>
          </v-flex>
        </ShareNetwork>
      </v-flex>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: "BottomSheetShareRecommendation",
  data: () => ({
    sheet: false,
    code: '',
    desc: {
      title: '',
      description: 'Acesse agora o link e comece a transformar sua vida financeira!'
    },
    networks: [
      {'rede': 'whatsapp', 'icon': 'mdi-whatsapp', 'color': '#34af23'},
      {'rede': 'facebook', 'icon': 'mdi-facebook', 'color': '#3b5998'},
      {'rede': 'linkedin', 'icon': 'mdi-linkedin', 'color': '#007bb6'},
      {'rede': 'email', 'icon': 'mdi-email-open-outline', 'color': '#ef4056'},
    ],
    showSharing: false
  }),
  computed: {
    title() {
      return `Olá, amigo(a)! Conheça a UPlanner e ganhe 10% OFF na compra com o código: ${this.code.toUpperCase()}`
    },
    user() {
      return this.$store.getters.user
    }
  },
  created() {
    this.$bus.$on('showBottomSheetShareRecommendation', (code) => {
      if (code) {
        this.code = code
        this.sheet = true
      }
    })
  }
}
</script>

