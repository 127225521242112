<template>
  <v-dialog
    fullscreen
    scrollable
    persistent
    v-model="dialog"
  >
    <v-card class="no-radius" v-if="creditCard">
      <v-card-title class="dialog-form-title" style="border-radius: 0 !important;" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="ml-4">
          Detalhes da Fatura
        </span>
      </v-card-title>

      <v-card-text class="pa-0 ma-0">
        <div class="month-selector">
          <v-btn icon color="#9E9E9E" @click.stop="changePeriod('back')" class="btn-month">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="month-name">{{ monthStr }}</span>
          <v-btn icon color="#9E9E9E" @click.stop="changePeriod('next')" class="btn-month">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>

        <div class="invoice-status hidden-md-and-up">
          <span class="invoice-status-text">Fatura Fechada</span>
        </div>

        <v-container grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12 class="card-info">
              <v-avatar class="mr-2" size="23" color="#131313">
                <v-icon size="13" :color="creditCard.color">
                  mdi-credit-card
                </v-icon>
              </v-avatar>

              <div class="card-name">
                <span class="card-name-text">{{ creditCard.name }}</span>
                <span class="card-name-bank">{{ creditCard.bank }}</span>
              </div>

              <img :src="getImgUrl(creditCard.flag)" class="card-flag">
            </v-flex>

            <v-flex xs6>
                <span class="invoice-date">
                  <v-icon color="#9e9e9e" size="14" class="mr-2">mdi-calendar</v-icon>
                  <span>{{ formatInvoiceDate(invoice.closeDate) }}</span>
                  <v-icon color="#9e9e9e" size="14" class="ml-auto">mdi-chevron-down</v-icon>
                </span>
              <small>Dia do fechamento</small>
            </v-flex>

            <v-flex xs6>
                <span class="invoice-date">
                  <v-icon color="#9e9e9e" size="14" class="mr-2">mdi-calendar</v-icon>
                  <span>{{ formatInvoiceDate(invoice.paymentDate) }}</span>
                  <v-icon color="#9e9e9e" size="14" class="ml-auto">mdi-chevron-down</v-icon>
                </span>
              <small>Dia do pagamento</small>
            </v-flex>

            <v-flex xs12 class="text-center invoice-value">
              <span class="invoice-value-desc">Valor da fatura</span>
              <span class="invoice-value-number">
                  <span>R$</span> {{ currency(invoice.value) }}
                </span>
            </v-flex>

            <v-flex xs12>
              <v-card-actions class="pa-0">
                <v-spacer></v-spacer>
                <v-btn
                  small
                  rounded
                  depressed
                  color="primary"
                  class="black--text px-4"
                >
                  {{ invoice.paid ? 'Cancelar pagamento' : 'Pagar' }}
                </v-btn>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider style="height: 2px;"></v-divider>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment-timezone'
  import currency from "../../../../utils/currency";

  export default {
    name: 'DialogInvoice',
    data: () => ({
      dialog: false,
      loader: false,
      period: '',
      creditCard: null,
      currentDate: null,
      monthStr: '',
      year: '',
      currency
    }),
    computed: {
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === `customer`;
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      invoice() {
        return this.$store.getters.invoices.find(invoice => {
          return (invoice.creditCard === this.creditCard._id &&
            invoice.closeDate.slice(0,7) === this.currentDate.format('YYYY-MM'))
        });
      },
      isIOS() {
        return this.$store.getters.isIOS;
      }
    },
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.creditCard = null;
        this.currentDate = null;
      },
      changePeriod(type) {
        if (type === 'next')
          this.currentDate.add(1, 'months');
        else
          this.currentDate.subtract(1, 'months');

        this.monthStr = this.currentDate.format('MMMM');

        if (this.currentDate.year() !== this.year)
          this.monthStr += `/${this.currentDate.year()}`;

        this.getInvoice();
      },
      getInvoice() {

      },
      getImgUrl(flag) {
        let images = require.context('../../../../assets/credit-card/', false, /\.png$/)
        return images('./' + flag + ".png")
      },
      formatInvoiceDate(date) {
        return moment(date).format('DD MMM, YYYY');
      }
    },
    created() {
      this.$bus.$on('showDialogInvoice', card => {
        if (card) {
          this.currentDate = moment().tz('America/Sao_Paulo');
          this.creditCard = card;

          if (this.currentDate.date() > this.creditCard.close)
            this.currentDate.add(1, 'months');

          this.year = this.currentDate.year();
          this.monthStr = this.currentDate.format('MMMM');

          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
  .month-selector, .invoice-status {
    background-color: #131313;
  }

  .invoice-status {
    padding: 0px 10px 10px 10px;
    text-align: center;
  }

  .invoice-status-text {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: .1em;
  }

  .card-info {
    display: flex;
    align-items: flex-start;
  }

  .card-name {
    display: flex;
    flex-direction: column;
  }

  .card-name-text {
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 13px;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  .card-name-bank {
    font-size: 9px;
    letter-spacing: .1em;
  }

  .card-flag {
    display: block;
    width: 32px;
    margin-left: auto;
  }

  .invoice-date {
    display: flex;
    width: 100%;
    border-bottom: 1.5px solid #9e9e9e;
  }

  .invoice-value {
    display: flex;
    flex-direction: column;
  }

  .invoice-value-desc {
    color: #9e9e9e;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: .1em;
  }

  .invoice-value-number {
    color: white;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: .1em;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  }

  .invoice-value-number span {
    font-size: 10px;
  }
</style>
