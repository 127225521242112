<template>
  <div class="chart-card">
    <h1 class="chart-card-title primary--text mb-2">Receita Total</h1>

    <apexchart
      type="donut"
      :options="options"
      :series="series"
      width="100%"
      height="300"
    ></apexchart>

    <div class="chart-card-legends mt-2">
      <div
        v-for="(item, index) in legends"
        :key="index" class="list-item"
      >
        <div
          class="legend-icon mr-2"
          :style="`background-color: ${item.color};`"
        ></div>

        <div class="legend-values">
          <span class="legend-values--desc">{{ item.text }}</span>
          <span class="legend-values--desc grey1--text">{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import randomHexColor from "random-hex-color";

let total = 0;

import VueApexCharts from 'vue-apexcharts';
import numberToCurrency from "../../../utils/numberToCurrency";

export default {
  name: 'CardReceitaTotal',
  props: ['transactions', 'category'],
  components: {apexchart: VueApexCharts},
  data: () => ({
    options: {
      colors: [],
      labels: [],
      dataLabels: {enabled: false},
      legend: {show: false},
      stroke: {width: 0},
      tooltip: {
        theme: 'dark',
        y: {
          formatter: v => {
            let percent = ((v / total) * 100).toFixed(2)
            return `${numberToCurrency(v)} (${percent}%)`
          }
        }
      }
    },
    series: [],
    legends: []
  }),
  computed: {
    categories() {
      return this.$store.getters.categories.filter(cat => cat.type === 'receita');
    }
  },
  methods: {
    buildChart() {
      let options = JSON.parse(JSON.stringify(this.options));

      options.colors = [];
      options.labels = [];

      this.legends = [];
      this.series = [];

      if (this.transactions.length) {
        total = this.transactions.reduce((a, b) => {
          return {value: a.value + b.value};
        }).value;
      }

      this.transactions.forEach(item => {
        options.labels.push(item.name);

        let cat = this.categories.find(cat => cat.name === item.name);

        if (!cat) {
          cat = {
            name: 'Não categorizada',
            color: '#D1AC68'
          }
        }

        let percent = parseFloat(((item.value / total) * 100).toFixed(2));

        if (!this.category.subs) {
          cat = this.categories.find(cat => cat.name === item.name);

          if (cat) {
            options.colors.push(cat.color);
            this.legends.push({
              text: cat.name,
              color: cat.color,
              value: `${numberToCurrency(item.value)} (${percent}%)`
            });
          }
        } else {
          cat = this.category.subs.find(cat => cat.name === item.name);
          let color = randomHexColor();
          options.colors.push(color);
          this.legends.push({
            text: cat.name,
            color,
            value: `${numberToCurrency(item.value)} (${percent}%)`
          });
        }

        this.series.push(item.value);
      });

      this.options = options;
    }
  },
  watch: {
    transactions: {
      deep: true,
      handler() {
        this.buildChart();
      }
    }
  },
  mounted() {
    this.buildChart();
  }
}
</script>

<style scoped>
.chart-card-legends {
  height: 88px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.chart-card-legends::-webkit-scrollbar {
  width: 4px;
}

.chart-card-legends::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.chart-card-legends::-webkit-scrollbar-thumb {
  background: #888;
}

.list-item {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 8px;
}

.legend-icon {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.legend-values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.legend-values--desc {
  font-size: 10px;
  line-height: 1.2em;
  letter-spacing: .1em;
}
</style>
