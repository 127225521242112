<template>
  <v-main>
    <v-container fill-height>
      <div class="grid-item">
        <h1 class="auth-title">
          Você está a
          <span class="primary--text">um login</span>
          <br/>de transformar
          <br/>a sua vida
        </h1>

        <img src="../assets/web/login-logo.png" class="auth-logo hidden-lg-and-up"/>
        <img src="../assets/web/logo-desktop.png" class="auth-logo hidden-md-and-down"/>
      </div>

      <div class="grid-item auth-form">
        <v-form @submit.prevent="login">
          <v-text-field
            dark
            dense
            outlined
            rounded
            type="email"
            label="E-mail"
            v-model="email"
            @input="$v.email.$touch()"
            :error-messages="emailErrors"
          ></v-text-field>

          <v-text-field
            dark
            dense
            rounded
            outlined
            label="Senha"
            v-model="password"
            @click:append="show = !show"
            @input="$v.password.$touch()"
            :error-messages="passwordErrors"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          ></v-text-field>

          <div class="text-right">
            <a class="white--text auth-link" @click.stop="showDialogForgotPassword">
              Esqueceu a senha?
            </a>
          </div>

          <div class="form-actions mt-5">
            <v-btn
              rounded
              depressed
              type="submit"
              color="primary"
              :loading="loader"
              class="black--text"
            >
              Login
            </v-btn>

            <v-btn
              rounded
              outlined
              depressed
              type="button"
              color="primary"
              @click="goToSignup"
              :disabled="loader"
            >
              Cadastre-se
            </v-btn>
          </div>

          <div class="link-container text-center mt-5">
            <router-link to="/politica-privacidade" class="primary--text">Politica de privacidade</router-link>
          </div>

          <div class="link-container text-center mt-5">
            <router-link to="/termos-uso" class="primary--text">Termo de adesão</router-link>
          </div>
        </v-form>
      </div>
    </v-container>
  </v-main>
</template>

<script>
  import firebase from 'firebase/app';
  import {required} from 'vuelidate/lib/validators';
  import getUserByUid from '@/api/users/getUserByUid';
  import authWithSocial from "../utils/authWithGoogle";
  // import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

  export default {
    name: 'Login',
    // metaInfo() {
    //   return {
    //     title: "Login",
    //     changed(metaInfo) {
    //       sendMetaInfoData(metaInfo)
    //     }
    //   };
    // },
    data: () => ({
      loader: false,
      email: '',
      password: '',
      show: false,
      googleLoader: false,
      facebookLoader: false
    }),
    methods: {
      login() {
        this.$v.$touch();

        if (!this.$v.$anyError && !this.loader) {
          this.loader = true;

          firebase
            .auth()
            .signInWithEmailAndPassword(this.email, this.password)
            .then(result => getUserByUid(result.user.uid, true))
            .then(user => {
              this.$store.dispatch('setUser', user);

              if(user && user.role === 'customer' && !user.permissions) {
                this.definePermissions(user)
              }

              if (user.role === 'company') {
                this.$router.push('/company');
              } else {
                this.$router.push('/');
              }

              this.loader = false;
            })
            .catch(err => {
              if (process.env.NODE_ENV !== 'production') {
                console.error(err)
              }

              this.$noty.error('E-mail ou senha incorretos');
              this.loader = false;
            });
        }
      },
      goToSignup() {
        this.$bus.$emit('showDialogCadastro');
      },
      showDialogForgotPassword() {
        this.$bus.$emit('showDialogForgotPassword', this.email);
      },
      authWithSocial(type) {
        if (!this.googleLoader && !this.facebookLoader) {
          if (type === 'google')
            this.googleLoader = true;
          else
            this.facebookLoader = true;

          authWithSocial(false, type)
            .then(result => this.$router.push('/'))
            .catch(err => {
              if (process.env.NODE_ENV !== 'production')
                console.error(err);

              this.googleLoader = false;
              this.facebookLoader = false;

              if (err && err.code && err.code !== "auth/popup-closed-by-user")
                this.$noty.error("Desculpe! Não foi possível acessar sua conta. Verifique seus dados e tente novamente!");
            });
        }
      }
    },
    validations: {
      email: {required},
      password: {required}
    },
    computed: {
      emailErrors() {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        !this.$v.email.required && errors.push('E-mail é obrigatório');
        return errors;
      },
      passwordErrors() {
        const errors = [];
        if (!this.$v.password.$dirty) return errors;
        !this.$v.password.required && errors.push('Senha é obrigatória');
        return errors;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      isIOS() {
        return this.$store.getters.isIOS
      }
    },
    created() {
      if (this.isMobile) {
        let params = this.$route.query;

        this.$router.push({
          path: '/auth',
          query: {
            mode: params.mode,
            oobCode: params.oobCode,
            lang: params.lang,
            apiKey: params.apiKey
          }
        });
      }
    }
  };
</script>

<style scoped>
  .v-main {
    background: url('../assets/web/desktop-bg1.png') center no-repeat no-repeat;
    background-size: cover;
    padding-top: 50px !important;
    padding-bottom: 45px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 728px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .grid-item {
    width: 100%;
    min-height: 1px;
  }

  .auth-title {
    color: white;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', 'Roboto',  sans-serif;
    line-height: 1.1em;
  }

  .auth-form {
    width: 228px;
    display: flex;
    flex-direction: column;
  }

  .v-btn.social-btn .v-icon {
    font-size: 15px !important;
  }

  .v-input__control .v-text-field__details {
    margin-bottom: 0 !important;
  }

  .auth-link {
    padding: 10px;
    font-size: 12px;
    text-decoration: underline;
  }

  .form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form-actions .v-btn {
    width: 104px !important;
    height: 29px !important;
  }

  .form-actions .v-btn.v-size--default {
    font-size: 10px !important;
  }

  .auth-logo {
    position: fixed;
    bottom: 30px;
    right: 15%;
  }

  .link-container {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .1em;
  }

  @media only screen and (min-width: 1264px) {
    .v-main {
      background: url('../assets/web/desktop-bg12.png') center no-repeat no-repeat;
      background-size: cover;
      padding-top: 70px !important;
      padding-bottom: 45px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .container {
      max-width: 960px !important;
    }

    .auth-form {
      width: 303px;
    }

    .v-btn.social-btn {
      width: 34px;
      height: 34px;
    }

    .v-btn.social-btn .v-icon {
      font-size: 23px !important;
    }

    .form-actions .v-btn {
      width: 137px !important;
      height: 38px !important;
    }

    .form-actions .v-btn.v-size--default {
      font-size: 13px !important;
    }
  }
</style>
