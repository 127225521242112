<template>
  <v-card
    :flat="isMobile"
    class="card-total"
    :color="isMobile ? 'transparent' : 'grey2'"
  >
    <v-card-text class="px-0">
      <v-avatar color="darkgrey" size="20">
        <v-icon size="12" color="primary">{{icon}}</v-icon>
      </v-avatar>

      <div class="card-total--number">
        <span class="card-total--number-desc"> {{ label }}</span>
        <span class="card-total--number-value">
          <span>R$</span> {{ formatedValue }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import currency from "@/utils/currency";

export default {
  name: 'ListTransactionsTotal',
  props: ['icon', 'label', 'total'],
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    formatedValue() {
      if (typeof this.total === 'number') {
        return currency(this.total)
      }

      return currency(0)
    }
  }
}
</script>

<style scoped>
.card-total .v-card__text {
  display: flex !important;
  align-items: center;
}

.card-total .v-card__text .v-avatar {
  margin-right: 10px !important;
}

.card-total--number {
  display: flex;
  flex-direction: column;
}

.card-total--number-desc {
  font-size: 10px;
  letter-spacing: .1em;
}

.card-total--number-value {
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .1em;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.card-total--number-value span {
  font-size: 9px;
}

@media only screen and (min-width: 375px) {
  .card-total .v-avatar {
    width: 22px !important;
    height: 22px !important;
  }

  .card-total--number-value {
    font-size: 15px;
  }
}

@media only screen and (min-width: 414px) {
  .card-total--number-value {
    font-size: 16px;
  }

  .card-total .v-avatar {
    width: 30px !important;
    height: 30px !important;
  }
}

@media only screen and (min-width: 600px) {
  .card-total--number-desc {
    font-size: 13px;
  }

  .card-total--number-value {
    font-size: 18px;
  }

  .card-total--number-value span {
    font-size: 12px;
  }
}

@media only screen and (min-width: 960px) {
  .card-total {
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 5px !important;
    margin-bottom: 20px !important;
  }

  .card-total .v-avatar {
    width: 42px !important;
    height: 42px !important;
  }

  .card-total .v-avatar .v-icon {
    font-size: 22px !important;
  }
}
</style>
