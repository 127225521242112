import { render, staticRenderFns } from "./DialogWalletInvestments.vue?vue&type=template&id=62954733&scoped=true&"
import script from "./DialogWalletInvestments.vue?vue&type=script&lang=js&"
export * from "./DialogWalletInvestments.vue?vue&type=script&lang=js&"
import style0 from "./DialogWalletInvestments.vue?vue&type=style&index=0&id=62954733&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62954733",
  null
  
)

export default component.exports