import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

export default (bankAccountId, period) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let headers = {Authorization: `Bearer ${idToken}`};
          let url = `/transactions/bank-accounts/${bankAccountId}/not-revised?period=${period}`;

          return httpClient(url, {headers});
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch (err) {
      reject(err);
    }
  });
}
