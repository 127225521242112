<template>
  <v-main class="pb-10">
    <v-container grid-list-md class="px-10 safe-area-top safe-area-bottom mb-10">
      <v-layout row wrap>
        <v-flex xs12>
          <TableCompanyEmployess/>
        </v-flex>

        <v-flex xs12 v-if="showInvoices">
          <TableCompanyInvoices/>
        </v-flex>
      </v-layout>
    </v-container>

    <DialogCompanyEmployee/>
    <DialogCompanyInvoice/>
    <DialogCompanyEmployeesHistory/>
  </v-main>
</template>

<script>
import CompanyMobileMenu from '@/components/Company/CompanyMobileMenu';
import CompanyNavigationDrawer from '@/components/Company/CompanyNavigationDrawer';
import TableCompanyEmployess from '@/components/Company/tables/TableCompanyEmployees';
import TableCompanyInvoices from '@/components/Company/tables/TableCompanyInvoices';
import CompanyToolbar from '@/components/Company/CompanyToolbar';
import DialogCompanyEmployee from '@/components/Company/dialogs/DialogCompanyEmployee';
import DialogCompanyInvoice from '@/components/Company/dialogs/DialogCompanyInvoice';
import DialogCompanyEmployeesHistory from '@/components/Company/dialogs/DialogCompanyEmployeesHistory';
import getUserById from '@/api/users/getUserById';
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

export default {
  name: 'Company',
  // metaInfo() {
  //   return {
  //     title: "Colab",
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo)
  //     }
  //   };
  // },
  components: {
    DialogCompanyEmployeesHistory,
    DialogCompanyInvoice,
    DialogCompanyEmployee,
    CompanyToolbar,
    TableCompanyInvoices,
    TableCompanyEmployess,
    CompanyNavigationDrawer,
    CompanyMobileMenu
  },
  computed: {
    isCompany() {
      return this.$store.getters.user && this.$store.getters.user.role === 'company'
    },
    user() {
      return this.isCompany ? this.$store.getters.user : this.$store.getters.company
    },
    showInvoices() {
      return this.user && this.user.companyPayment && this.user.companyPayment.billingType !== 'no-payment'
    }
  },
  methods: {
    getCompany() {
      if (!this.isCompany) {
        getUserById(this.$route.params.id)
          .then(company => {
            this.$store.dispatch('setCompany', company)
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }

            this.$router.push('/')
          })
      }
    }
  },
  created() {
    this.getCompany()
  }
}
</script>

