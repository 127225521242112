import firebase from 'firebase/app';

import store from '@/store/index';
import httpClient from '@/plugins/httpClient';

const deleteAdminCategory = categoryId =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'DELETE';
        let url = `/categories/${categoryId}`;
        let headers = {
          role: store.getters.user.role,
          Authorization: `Bearer ${idToken}`
        };

        return httpClient(url, { method, headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });

export default deleteAdminCategory;
