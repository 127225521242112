import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar dados de determinado usuário utilizando seu UID
 * @param {string} uid
 * @param {string} login
 */
const getUserByUid = (uid, login = '') => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        return httpClient(`/users/firebase/user/${uid}?login=${login}`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}


export default getUserByUid;
