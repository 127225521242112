import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Contar usuários agrupados por tipo
 */
const countUsersGroupedByType = (companyId = '') =>
  new Promise((resolve, reject) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          let headers = { Authorization: `Bearer ${idToken}` };
          return httpClient(`/users/count/type-group?companyId=${companyId}`, {
            headers
          });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });

export default countUsersGroupedByType;
