import { orderBy } from 'lodash'

export default {
  interest: state => state.interest,
  isMobile: state => state.isMobile,
  isIOS: state => state.isIOS,
  estados: state => orderBy(state.estados, 'nome'),
  user: state => state.user,
  countryCodes: state => state.countryCodes,
  allowed: state => (state.user && ['planner', 'customer'].includes(state.user.role)),
  // admin
  configIr: state => state.configIr,
  plans: state => state.plans,
  companiesNames: state => state.companiesNames,
  plannersNames: state => state.plannersNames,
  company: state => state.company,
  // customer
  months: state => state.months,
  bankAccounts: state => state.bankAccounts,
  transactions: state => state.transactions,
  creditCards: state => state.creditCards,
  categories: state => state.categories,
  invoices: state => state.invoices,
  pendencias: state => state.pendencias,
  orcamento: state => state.orcamento,
  transactionsPeriods: state => state.transactionsPeriods,
  perfil: state => state.perfil,
  patrimonio: state => state.patrimonio,
  independenciaFinanceira: state => state.independenciaFinanceira,
  customer: state => state.customer,
  liquidez: state => state.liquidez,
  planner: state => state.planner,
  investments: state => state.investments,
  syncBankAccount: state => state.syncBankAccount,
  actionPlans: state => state.actionPlans,
  userLifeInsurance: state => state.lifeInsurances.find(li => !li.isSpouse),
  spouseLifeInsurance: state => state.lifeInsurances.find(li => li.isSpouse),
  meiosPagamento: state => state.meiosPagamento,
  subscriptionSuspended: state => state.subscriptionSuspended,

  // recommendations
  recommendations: state => state.recommendations,
 //banksAndCodes
  banksAndCodes: state => state.banksAndCodes,
  requestReward: state => state.requestReward,
  hasOpenBankingError: state => state.hasOpenBankingError,

  // pendentCreditCards
  pendentCreditCards: state => state.pendentCreditCards,
  totalPendencias: state => state.totalPendencias
};
