import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

export default id => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const url = `/pendent-credit-cards/${id}`
        const options = {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        return httpClient(url, options)
      })
      .then(resp => resolve())
      .catch(reject)
  })
}
