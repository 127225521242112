var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-5"},[_c('UsersStatus'),_c('v-data-table',{attrs:{"dense":"","items":_vm.users,"loading":_vm.loader,"hide-default-header":_vm.isMobile,"hide-default-footer":"","headers":_vm.tableHeaders},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"width":"90px","padding":"5px"}},[_c('v-icon',{attrs:{"color":_vm.userStatusColor(props.item)}},[_vm._v(" "+_vm._s(_vm.userStatusIcon(props.item))+" ")])],1),_c('td',{staticClass:"hidden-xs-only",staticStyle:{"width":"100px"}},[_c('v-chip',{class:props.item.disabled ? 'black--text' : 'white--text',attrs:{"small":"","color":_vm.getRoleColor(props.item.role, props.item.disabled)}},[_vm._v(" "+_vm._s(_vm.getRoleName(props.item.role))+" ")])],1),_c('td',{staticClass:"user-name"},[_vm._v(" "+_vm._s(props.item.displayName.length && _vm.isMobile > 22 ? props.item.displayName.slice(0, 22) + '...' : props.item.displayName)+" ")]),_c('td',{staticClass:"hidden-md-and-down"},[_vm._v(" "+_vm._s(props.item.isUplanner && !props.item.company ? 'Uplanner' : (props.item.company ? props.item.company.displayName : ''))+" ")]),_c('td',{staticClass:"hidden-sm-and-down"},[_vm._v(" "+_vm._s(props.item.planner ? props.item.planner.displayName : '')+" ")]),_c('td',{staticClass:"hidden-sm-and-down"},[_vm._v(" "+_vm._s(props.item.supervisor ? props.item.supervisor.displayName : '')+" ")]),_c('td',{staticClass:"hidden-sm-and-down text-center"},[_vm._v(" "+_vm._s(_vm.formatDate(props.item.createdAt))+" ")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"30px","padding":"0"}},[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":"","nav":"","color":"grey2"}},[(
                  ['customer', 'planner', 'company'].includes(props.item.role) &&
                  ['planner', 'admin', 'supervisor'].includes(_vm.user.role)
                )?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDetails(props.item)}}},[_vm._v(" Detalhes ")]):_vm._e(),(['support', 'comercial'].includes(_vm.user.role))?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){return _vm.showDialogUserContacts(props.item)}}},[_vm._v(" Contatos ")]):_vm._e(),(props.item.uid && ['planner', 'admin', 'supervisor', 'comercial'].includes(_vm.user.role))?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showDialogFormUser(props.item)}}},[_vm._v(" Editar ")]):_vm._e(),(
                  props.item.role === 'company' &&
                  _vm.user.role !== 'support'
                )?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showPurchaseUrl(props.item.displayName, props.item.companyPayment.billingUrl, props.item.role)}}},[_vm._v(" Link Colab ")]):_vm._e(),(props.item.role === 'planner' && props.item.isUplanner && _vm.user.role !== 'support')?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showPurchaseUrl(props.item.displayName, props.item.atendimento.billingUrl, props.item.role)}}},[_vm._v(" Link do planejador ")]):_vm._e(),(props.item.role === 'planner' && props.item.isUplanner)?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialogCalendlyPlanner(props.item)}}},[_vm._v(" Integrar Calendly ")]):_vm._e(),(!props.item.uid && ['planner', 'admin', 'supervisor'].includes(_vm.user.role))?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showFormCreateCustomerPassword(props.item._id)}}},[_vm._v(" Criar senha ")]):_vm._e(),(
                  props.item.uid &&
                  props.item.locked &&
                  props.item.role === 'customer' &&
                  ['planner', 'admin', 'supervisor'].includes(_vm.user.role)
                )?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.unlock(props.item._id, props.item.planner, props.item.company)}}},[_vm._v(" Desbloquear ")]):_vm._e(),(props.item.uid && props.item.isUplanner && props.item.role === 'customer')?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialogMeetingsConfirmation(props.item._id, !!props.item.company)}}},[_vm._v(" Reuniões ")]):_vm._e(),(
                  props.item.uid &&
                  props.item.role === 'customer' &&
                  ['planner', 'admin', 'supervisor'].includes(_vm.user.role)
                )?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialogNotes(props.item)}}},[_vm._v(" Anotações ")]):_vm._e(),(props.item.uid && ['planner', 'admin', 'supervisor'].includes(_vm.user.role))?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialogCustomerActivity(props.item)}}},[_vm._v(" Log de Atividades ")]):_vm._e(),(
                  props.item.uid &&
                  props.item.role === 'customer' &&
                  ['planner', 'admin', 'supervisor'].includes(_vm.user.role)
                )?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialogAcessPermission(props.item)}}},[_vm._v(" Editar Permissões ")]):_vm._e(),(
                  props.item.role === 'customer' &&
                  ['admin', 'supervisor'].includes(_vm.user.role)
                )?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialogChangeUserPlanner(props.item)}}},[_vm._v(" Alterar planejador ")]):_vm._e(),(
                  props.item.role === 'planner' &&
                  _vm.user.role === 'admin'
                )?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialogChangePlannerSupervisor(props.item)}}},[_vm._v(" Alterar supervisor ")]):_vm._e(),(props.item.uid && ['admin', 'supervisor', 'planner'].includes(_vm.user.role))?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialogPasswordResetLink(props.item)}}},[_vm._v(" Alterar senha ")]):_vm._e(),(props.item.uid && ['admin', 'supervisor', 'planner'].includes(_vm.user.role))?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialogEmailUpdate(props.item)}}},[_vm._v(" Alterar e-mail ")]):_vm._e(),(
                  (props.item.uid || props.item.iuguSubscription) &&
                  (
                    ['admin', 'supervisor'].includes(_vm.user.role) ||
                    (_vm.user.role === 'planner' && !_vm.user.isUplanner)
                  )
                )?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialogUpdateUserStatus(props.item)}}},[_vm._v(" "+_vm._s(props.item.disabled ? 'Habilitar' : 'Desabilitar')+" ")]):_vm._e(),(
                  props.item.company &&
                  props.item.role === 'customer' &&
                  !props.item.iuguSubscription &&
                  ['admin', 'supervisor', 'planner', 'comercial'].includes(_vm.user.role)
                )?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.changeCancelStatus(props.item)}}},[_vm._v(" "+_vm._s(typeof props.item.canceled !== 'undefined' && props.item.canceled ? 'Ativar' : 'Cancelar')+" ")]):_vm._e(),(
                  props.item.role === 'customer' &&
                  ['admin', 'supervisor'].includes(_vm.user.role)
                )?_c('v-list-item',{staticStyle:{"min-width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.updateIuguSubscription(props.item)}}},[_vm._v(" Atribuir assinatura ")]):_vm._e()],1)],1)],1)])]}}])}),_c('div',{staticClass:"text-center mt-5"},[_c('v-pagination',{attrs:{"circle":"","color":"primary","length":_vm.pagination.totalPages},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }