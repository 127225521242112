<template>
  <v-subheader class="list-title primary--text d-flex align-center justify-space-between">
    {{ formatedDate }}

    <v-slide-x-reverse-transition>
      <div
        v-if="showBalance"
        class="d-flex flex-column align-end"
      >
        <span>
          <span>R$</span>
          {{ formatedBalance }}
        </span>

        <small class="grey--text">
          {{ formatedLabel }}
        </small>
      </div>
    </v-slide-x-reverse-transition>
  </v-subheader>
</template>

<script>
import currency from "@/utils/currency";

export default {
  name: 'ListTransactionsSubheader',
  props: ['date', 'showBalance', 'balance'],
  computed: {
    formatedDate() {
      if (this.date) {
        return this.$date(this.date).format('ddd DD/MM/YYYY')
      }

      return ''
    },
    formatedBalance() {
      if (typeof this.balance !== 'undefined') {
        return currency(this.balance)
      }

      return currency(0)
    },
    formatedLabel() {
      let label = 'Saldo diário'

      if (this.$date().isSameOrBefore(this.date, 'day')) {
        label += ' projetado'
      }

      return label
    }
  }
}
</script>
