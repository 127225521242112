<template>
  <v-card flat color="transparent" class="pb-0">
    <v-card-title class="primary--text pendencias-title pl-0">Pendências</v-card-title>

    <v-card-text class="px-0 pb-0">
      <v-layout row wrap>
        <v-flex xs12 v-if="hasPendentCreditCards">
          <AlertPendentCreditCards/>
        </v-flex>

        <v-flex xs6 sm6 md4 v-if="entradas">
          <CardPendencia
            type="receita"
            color="success"
            :period="period"
            icon="mdi-arrow-up"
            :value="totalEntradas"
            :qtde="entradas"
            title="Entradas pendentes"
          />
        </v-flex>

        <v-flex xs6 sm6 md4 v-if="saidas">
          <CardPendencia
            type="despesa"
            color="error"
            :period="period"
            icon="mdi-arrow-down"
            :value="totalSaidas"
            :qtde="saidas"
            title="Despesas pendentes"
          />
        </v-flex>

        <v-flex xs6 sm6 md4 v-if="investimentos">
          <CardPendencia
            color="primary"
            :period="period"
            type="investimento"
            icon="mdi-arrow-down"
            title="Invest. pendentes"
            :value="totalInvestimentos"
            :qtde="investimentos"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import currency from "@/utils/currency";
import CardPendencia from "@/components/Customer/Cashflow/cards/CardPendencia";
import AlertPendentCreditCards from "@/components/Customer/Cashflow/alerts/AlertPendentCreditCards";

export default {
  name: 'CardPendencias',
  components: {AlertPendentCreditCards, CardPendencia},
  props: ['period'],
  computed: {
    totalPendencias() {
      return this.$store.getters.totalPendencias
    },
    entradas() {
      if (this.totalPendencias)
        return this.totalPendencias.revenue.items

      return 0
    },
    saidas() {
      if (this.totalPendencias)
        return this.totalPendencias.expenses.items

      return 0
    },
    investimentos() {
      if (this.totalPendencias)
        return this.totalPendencias.investments.items

      return 0
    },
    totalEntradas() {
      if (this.totalPendencias)
        return currency(parseFloat(this.totalPendencias.revenue.total))

      return currency(0)
    },
    totalSaidas() {
      if (this.totalPendencias)
        return currency(parseFloat(this.totalPendencias.expenses.total))

      return currency(0)
    },
    totalInvestimentos() {
      if (this.totalPendencias)
        return currency(parseFloat(this.totalPendencias.investments.total))

      return currency(0)
    },
    hasPendentCreditCards() {
      return !!this.$store.getters.pendentCreditCards.length
    },
    user() {
      return this.$store.getters.user
    }
  }
}
</script>

<style scoped>
.pendencias-title {
  font-size: 12px;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}
</style>
