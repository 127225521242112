<template>
  <v-card
    dark
    :ripple="false"
    elevation="0"
    color="transparent"
    class="bordered-card px-0"
  >
    <v-card-text class="d-flex flex-row align-start">
      <v-avatar
        dark
        :color="'#' + connector.primaryColor"
        class="open-banking-account__avatar mr-4"
      >
        <v-icon class="open-banking-account__icon">{{ icon }}</v-icon>
      </v-avatar>

      <div class="open-banking-account__content">
        <div class="open-banking-account__content-item">
          <span class="input-label white--text">{{ type }}</span>
          <span class="open-banking-account__content-value white--text">{{ account.name }} {{ account.number }}</span>
        </div>

        <div class="open-banking-account__content-item">
          <span class="input-label white--text">{{ balance }}</span>
          <span class="open-banking-account__content-value white--text">{{ balanceValue }}</span>
        </div>

        <div
          @click="toggleTransactions"
          style="cursor: pointer"
          class="open-banking-account__content-item"
        >
          <div class="primary--text open-banking-account__content-value d-flex align-center justify-space-between">
            {{ showTransactions ? 'Ocultar' : 'Mostrar' }} Transações

            <v-icon color="primary">
              {{ showTransactions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-expand-transition>
      <v-card-text v-if="showTransactions">
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="transactionsLoader"
        ></v-progress-linear>

        <h2
          class="white--text text-center"
          v-if="!transactions.length && !transactionsLoader"
        >
          Nenhuma transação encontrada
        </h2>

        <div class="open-banking-account__transactions-list">
          <v-card
            class="mb-2"
            color="darkgrey"
            :key="transaction.id"
            v-for="transaction in transactions"
          >
            <v-card-text>
              <div class="open-banking-account__transactions-list-item">
                <div class="open-banking-account__content-item mb-0" style="width: 100%">
                  <span class="open-banking-account__content-value white--text">
                    {{ transaction.description }}
                  </span>
                </div>

                <div class="open-banking-account__content-item mb-0" style="width: 100%">
                  <span class="open-banking-account__content-value">
                    {{ numberToCurrency(transaction.amount) }} em {{ formatDate(transaction.date) }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <div class="text-center mt-5" v-if="pagination.totalPages > 1">
          <v-pagination
            circle
            v-model="pagination.page"
            :length="pagination.totalPages"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import numberToCurrency from "@/utils/numberToCurrency";
import getPluggyTransactionsByPluggyAccountId from "@/api/pluggyAccounts/getPluggyTransactionsByPluggyAccountId";

export default {
  name: 'CardOpenBankingAccount',
  props: ['account', 'connector'],
  data: () => ({
    numberToCurrency,
    transactions: [],
    showTransactions: false,
    transactionsLoader: false,
    pagination: {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      itemsPerPage: 25
    }
  }),
  computed: {
    icon() {
      switch (this.account.type) {
        case 'BANK':
          return 'mdi-bank'
        case 'CREDIT':
          return 'mdi-credit-card'
        default:
          return ''
      }
    },
    type() {
      switch (this.account.type) {
        case 'BANK':
          return 'Conta'
        case 'CREDIT':
          return 'Cartão'
        default:
          return ''
      }
    },
    balance() {
      switch (this.account.type) {
        case 'BANK':
          return 'Saldo'
        case 'CREDIT':
          return 'Limite disponível'
        default:
          return ''
      }
    },
    balanceValue() {
      switch (this.account.type) {
        case 'BANK':
          return numberToCurrency(this.account.balance)
        case 'CREDIT':
          return numberToCurrency(this.account.creditData.availableCreditLimit)
        default:
          return numberToCurrency(0)
      }
    }
  },
  methods: {
    toggleTransactions() {
      this.showTransactions = !this.showTransactions
    },
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY')
      }
      return ''
    },
    async getTransactions() {
      try {
        if (this.showTransactions) {
          this.transactionsLoader = true
          const {totalItems, transactions} = await getPluggyTransactionsByPluggyAccountId(this.account.id, this.pagination.page)

          this.pagination.totalItems = totalItems
          this.pagination.totalPages = Math.ceil(totalItems / this.pagination.itemsPerPage)
          this.transactions = transactions
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.transactionsLoader = false
      }
    }
  },
  watch: {
    'pagination.page'() {
      this.getTransactions()
    },
    'showTransactions'() {
      if (!this.transactions.length && this.showTransactions) {
        this.getTransactions()
      }
    }
  },
  mounted() {
    this.getTransactions()
  }
}
</script>

<style scoped>
.v-avatar.open-banking-account__avatar {
  min-width: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.v-icon.open-banking-account__icon {
  font-size: 12px !important;
}

.open-banking-account__content,
.open-banking-account__content-item {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.open-banking-account__content-item {
  margin-bottom: 20px;
}

.open-banking-account__content .input-label {
  font-size: 10px;
  line-height: 1.5em;
}

.open-banking-account__content-value {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.open-banking-account__transactions-list-item {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 600px) {
  .v-avatar.open-banking-account__avatar {
    width: 34px !important;
    height: 34px !important;
  }

  .v-icon.open-banking-account__icon {
    font-size: 20px !important;
  }
}

@media only screen and (min-width: 1120px) {
  .open-banking-account__content {
    flex-direction: row;
    align-items: center;
  }

  .open-banking-account__content-item {
    margin-bottom: 0;
    width: 33.3%;
  }

  .open-banking-account__content .open-banking-account__content-item:last-child .open-banking-account__content-value {
    justify-content: end !important;
  }
}
</style>
