import firebase from 'firebase/app';
import httpClient from '../../plugins/httpClient';

/**
 * Save customer`s note
 * @param data
 * @returns {Promise<Object>}
 */
const saveNote = data => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let url = '/notes';
          let method = 'POST';
          let headers = { Authorization: `Bearer ${idToken}` };

          return httpClient(url, { headers, method, data });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default saveNote;
