<template>
  <v-dialog
    persistent
    v-model="dialog"
    :fullscreen="isMobile"
    :max-width="!isPluggy ? '450px' : '768px'"
  >
    <v-card>
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          class="mr-2"
          :disabled="loader"
          @click="closeDialog"
          :color="invoice && invoice.paid ? 'error' : 'success'"
        >
          <v-icon>{{ isMobile ? 'mdi-arrow-left' : 'mdi-close' }}</v-icon>
        </v-btn>

        <span :class="invoice && invoice.paid ? 'error--text' : 'success--text'">
          {{ invoice && invoice.paid ? 'Cancelar pagamento' : 'Pagar fatura' }}
        </span>
      </v-card-title>

      <v-card-text v-if="invoice">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 v-if="!invoice.paid">
                <span class="input-label primary--text">Conta bancária</span>
                <v-select
                  color="grey1"
                  item-text="name"
                  item-value="_id"
                  class="mt-0 pt-0"
                  menu-props="auto"
                  :items="bankAccounts"
                  v-model="bankAccount"
                  :error-messages="bankAccountErrors"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-bank
                  </v-icon>

                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color">mdi-bank</v-icon>
                    <span>{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>

              <v-expand-transition>
                <v-flex xs12 v-if="bankAccount && !isPluggy && !invoice.paid">
                  <FormInvoicePayment
                    :invoice-value="invoice.value"
                    ref="formInvoicePayment"
                  />
                </v-flex>

                <v-flex xs12 v-if="bankAccount && isPluggy && !invoice.paid">
                  <FormInvoicePaymentFromTransaction
                    :bank-account="bankAccount"
                    ref="formInvoicePaymentFromTransaction"
                  />
                </v-flex>
              </v-expand-transition>

              <v-flex xs12 class="mb-2" v-if="invoice.paid">
                <h2 class="white--text">Tem certeza que deseja cancelar o pagamento desta fatura?</h2>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="ma-0 px-0">
                  <v-btn
                    rounded
                    outlined
                    color="grey1"
                    type="button"
                    :disabled="loader"
                    @click="closeDialog"
                    class="px-5 text-capitalize"
                  >
                    Cancelar
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    rounded
                    type="submit"
                    :loading="loader"
                    class="px-5 text-capitalize white--text"
                    :color="invoice && invoice.paid ? 'error' : 'success'"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import {orderBy} from 'lodash'
import FormInvoicePayment from "@/components/Customer/Invoice/forms/FormInvoicePayment";
import payInvoice from "@/api/invoices/payInvoice";
import removeMoneyMask from "@/utils/removeMoneyMask";
import cancelInvoicePayment from "@/api/invoices/cancelInvoicePayment";
import FormInvoicePaymentFromTransaction from "@/components/Customer/Invoice/forms/FormInvoicePaymentFromTransaction";

export default {
  name: 'DialogInvoicePayment',
  components: {FormInvoicePaymentFromTransaction, FormInvoicePayment},
  data: () => ({
    dialog: false,
    loader: false,
    invoice: null,
    bankAccount: null
  }),
  validations: {
    bankAccount: {
      required(v) {
        return !!v
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    bankAccountErrors() {
      if (!this.$v.bankAccount.$anyDirty) return []

      if (this.$v.bankAccount.$anyError)
        return ['Selecione uma conta válida']
    },
    formatedDate() {
      if (this.date) {
        return this.$date(this.date).format('DD/MM/YYYY')
      }

      return ''
    },
    bankAccounts() {
      return orderBy(this.$store.getters.bankAccounts.filter(ba => !ba.archived), 'name')
    },
    isPluggy() {
      if (this.bankAccount) {
        const bankAccount = this.bankAccounts.find(ba => ba._id === this.bankAccount)
        return !!bankAccount && !!bankAccount.pluggy
      }

      return null
    }
  },
  methods: {
    openDialog(invoice) {
      if (invoice) {
        this.invoice = JSON.parse(JSON.stringify(invoice))
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false

      this.date = null
      this.loader = false
      this.invoice = null
      this.paymentValue = ''
      this.bankAccount = null
      this.paymentType = 'bank-account'

      this.$v.$reset()
    },
    async submit() {
      try {
        let hasError = false
        const {formInvoicePayment, formInvoicePaymentFromTransaction} = this.$refs

        if (!this.invoice.paid) {
          this.$v.$touch()

          hasError = this.$v.$anyError

          if (formInvoicePayment) {
            formInvoicePayment.$v.$touch()

            if (!hasError) {
              hasError = formInvoicePayment.$v.$anyError
            }
          } else if (formInvoicePaymentFromTransaction) {
            formInvoicePaymentFromTransaction.$v.$touch()

            if (!hasError) {
              hasError = formInvoicePaymentFromTransaction.$v.$anyError
            }
          }
        }

        if (!hasError) {
          this.loader = true

          if (formInvoicePayment || this.invoice.paid) {
            await this.changeInvoicePaymentStatus()
          } else if (formInvoicePaymentFromTransaction) {
            await this.payInvoiceWithTransaction()
          }

          this.$noty.success('Fatura alterada com sucesso!')
          this.closeDialog()
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.loader = false
        this.$noty.error('Desculpe! Não foi possível alterar esta fatura. Tente novamente mais tarde!')
      }
    },
    async changeInvoicePaymentStatus() {
      console.log('changeInvoicePaymentStatus')

      let promise = null

      if (!this.invoice.paid) {
        const {formInvoicePayment} = this.$refs

        const data = {
          date: formInvoicePayment.date,
          bankAccount: this.bankAccount,
          value: removeMoneyMask(formInvoicePayment.value)
        }

        promise = payInvoice(this.invoice._id, data)
      } else {
        promise = cancelInvoicePayment(this.invoice._id)
      }

      const { bankAccount, creditCard, invoice, transaction, transactions } = await promise

      transactions.forEach(tr => {
        this.$store.dispatch('saveTransaction', tr)
      })

      await Promise.all([
        this.$store.dispatch('saveInvoice', invoice),
        this.$store.dispatch('saveCreditCard', creditCard),
        this.$store.dispatch('saveBankAccount', bankAccount)
      ])

      if (transaction) {
        await this.$store.dispatch('saveTransaction', transaction)
      }
    },
    async payInvoiceWithTransaction() {
      console.log('payInvoiceWithTransaction')

      const {formInvoicePaymentFromTransaction} = this.$refs
      const paymentTransaction = formInvoicePaymentFromTransaction.getTransaction()

      const data = {
        date: paymentTransaction.date,
        value: paymentTransaction.value,
        transaction: paymentTransaction._id,
        bankAccount: formInvoicePaymentFromTransaction.bankAccount
      }

      const { bankAccount, creditCard, invoice, transaction, transactions } = await payInvoice(this.invoice._id, data)

      transactions.forEach(tr => {
        this.$store.dispatch('saveTransaction', tr)
      })

      await Promise.all([
        this.$store.dispatch('saveInvoice', invoice),
        this.$store.dispatch('saveCreditCard', creditCard),
        this.$store.dispatch('saveBankAccount', bankAccount),
        this.$store.dispatch('saveTransaction', transaction)
      ])
    }
  },
  created() {
    this.$bus.$on('showDialogInvoicePayment', this.openDialog)
  }
}
</script>
