<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    max-width="500px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" v-if="item && period" color="grey2">
      <v-card-title
        class="primary--text text-uppercase"
        :class="isIOS ? 'ios-padding' : ''"
      >
        {{ item.subCategory.name }}
      </v-card-title>
      <v-card-subtitle class="grey1--text">{{ item.category.name }}</v-card-subtitle>

      <v-card-text>
        <div class="list-filter"></div>

        <div class="list">
          <div
            class="list-item"
            :key="transaction._id"
            v-for="transaction in currentTransactions"
          >
            <div class="list-item--main">
              <span class="list-item--main-date">
                {{ formatDate(transaction.date) }}
                <span v-if="transaction.divided">
                  ({{ transaction.dividedPart }}/{{ transaction.dividedParts }})
                </span>
              </span>
              <span class="list-item--main-bank">
                {{ transaction.creditCard ? transaction.creditCard.name : transaction.bankAccount.name }}
              </span>
            </div>
            <div class="list-item--sub">
              <span class="list-item--sub-icon">
                <v-icon class="mr-1">{{ transaction.creditCard ? 'mdi-credit-card' : 'mdi-bank' }}</v-icon>
                <span v-if="transaction.obs || transaction.description">
                  {{ transaction.obs || transaction.description}}
                </span>
              </span>
              <span class="list-item--sub-value">
                <span>R$</span> {{ currency(transaction.value) }}
              </span>
            </div>
          </div>
        </div>

        <div class="total-container">
          <span class="total-desc">Total: </span>
          <span class="total-value">
            <span>R$</span> {{ total }}
          </span>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          fab
          small
          color="primary"
          class="darkgrey--text"
          @click="close"
          :loading="loader"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { orderBy } from 'lodash'
  import moment from 'moment-timezone'
  import currency from "../../../../utils/currency";
  import getUserTransactions from "../../../../api/transactions/getUserTransactions";
  import getInvoiceTransactions from "../../../../api/transactions/getInvoiceTransactions";
  import getPeriodInvoicesTransactions from "../../../../api/transactions/getPeriodInvoicesTransactions";

  export default {
    name: 'DialogDespesasItem',
    data: () => ({
      dialog: false,
      loader: false,
      item: null,
      period: null,
      currentPeriod: null,
      currency,
      filter: { text: 'Todas', value: '' }
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === `customer`;
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
      transactions() {
        return this.$store.getters.transactions;
      },
      filteredTransaction() {
        return this.$store.getters.transactions.filter(tr => {
          if (
            tr.type === 'saida' &&
            (
              (tr.bankAccount && !tr.bankAccount.archived) ||
              (tr.invoice && tr.creditCard && (!tr.creditCard.archived || tr.invoice.paid))
            ) &&
            (
              (!tr.invoice && tr.bankAccount && tr.date.slice(0, 7) === this.period) ||
              (tr.invoice && !tr.invoice.paid && !tr.isInvoicePayment && tr.invoice.paymentDate.slice(0, 7) === this.period) ||
              (tr.invoice && tr.invoice.paid && !tr.isInvoicePayment && tr.invoice.paidIn.slice(0, 7) === this.period)
            )
          ) {
            if (this.item.isCategoryInvalid || this.item.isSubCategoryInvalid) {
              return (
                (
                  !tr.category &&
                  !tr.subCategory &&
                  this.item.isCategoryInvalid &&
                  this.item.isSubCategoryInvalid
                ) ||
                (
                  tr.category &&
                  tr.category._id === this.item.category._id &&
                  !tr.subCategory &&
                  !this.item.isCategoryInvalid &&
                  this.item.isSubCategoryInvalid
                )
              )
            } else {
              return (
                tr.category && tr.category._id === this.item.category._id &&
                tr.subCategory && tr.subCategory._id === this.item.subCategory._id
              )
            }
          }
        })
      },
      currentTransactions() {
        if (this.item && this.period) {
          return orderBy(this.filteredTransaction, ['date', 'description', 'obs']);
        } else {
          return [];
        }
      },
      bankAccounts() {
        return this.$store.getters.bankAccounts;
      },
      creditCards() {
        return this.$store.getters.creditCards;
      },
      activeBankAccounts() {
        return this.bankAccounts.filter(ba => !ba.archived);
      },
      activeCreditCards() {
        return this.creditCards.filter(cc => !cc.archived);
      },
      title() {
        if (this.item) {
          let title = this.item.subCategory.name + ' - ' + this.item.category.name;

          let charLimit = 19;

          if (this.innerWidth >= 375)
            charLimit = 25;

          if (this.innerWidth >= 414)
            charLimit = 30;

          if (this.innerWidth >= 600)
            charLimit = 2000;

          if (title.length > charLimit)
            title = title.slice(0,charLimit) + '...';

          return title;
        }
      },
      total() {
        let total = 0;

        if (this.currentTransactions.length) {
          total += this.currentTransactions.reduce((a, b) => {
            return { value: a.value + b.value };
          }).value;
        }

        return currency(total);
      },
      isPast() {
        if (this.period && this.currentPeriod) {
          let [periodYear, periodMonth] = this.period.split('-');
          let [currentYear, currentMonth] = this.currentPeriod.split('-');

          periodYear = parseInt(periodYear);
          periodMonth = parseInt(periodMonth);
          currentYear = parseInt(currentYear);
          currentMonth = parseInt(currentMonth);

          return periodYear < currentYear || (periodYear === currentYear && periodMonth < currentMonth);
        }
      }
    },
    methods: {
      getTransactions() {
        this.loader = true;

        let promises = [
          getUserTransactions(this.user._id, this.period),
          getPeriodInvoicesTransactions(this.user._id, this.period)
        ];

        Promise.all(promises)
          .then(results => {
            this.$store.dispatch('setTransactions', results[0]);
            this.$store.dispatch('setTransactions', results[1]);
            this.loader = false;
          })
          .catch(err => {
            this.loader = false;
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      },
      close() {
        this.dialog = false;
        this.loader = false;
        this.period = null;
        this.item = null;
        this.filter = { text: 'Todas', value: '' };
      },
      formatDate(date) {
        if (date) {
          return moment(date).tz('America/Sao_Paulo').format('DD MMM, YYYY');
        }
      }
    },
    created() {
      this.$bus.$on('showDialogDespesasItem', data => {
        if (data.item && data.period) {
          this.item = JSON.parse(JSON.stringify(data.item));
          this.period = data.period;
          this.getTransactions();
          this.currentPeriod = moment().format('YYYY-MM');
          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
  .dialog-card-title {
    background-color: #131313 !important;
  }

  .v-card__title {
    font-size: 12px !important;
    letter-spacing: .1em !important;
    line-height: 2em !important;
  }

  .v-card__subtitle {
    font-size: 9px !important;
    letter-spacing: .1em !important;
    line-height: 1.5em;
  }

  .list-item {
    padding: 10px;
    border-bottom: 2px solid #919191;
  }

  .list-item--main, .list-item--sub {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 10px;
    letter-spacing: .1em;
    color: white;
    line-height: 1.5em;
  }

  .list-item--sub {
    color: #919191;
  }

  .list-item--sub-icon .v-icon {
    font-size: 12px !important;
    color: #919191;
  }

  .total-container {
    padding: 20px 0;
    text-align: center;
  }

  .total-desc {
    color: #919191;
    font-size: 12px;
    letter-spacing: .1em;
  }

  .total-value {
    color: white;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .1em;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  }

  .total-value span {
    font-size: 10px;
  }

  @media only screen and (min-width: 600px) {
    .v-card__title {
      font-size: 15px !important;
    }

    .v-card__subtitle {
      font-size: 12px !important;
    }

    .list-item--main, .list-item--sub {
      font-size: 12px;
    }

    .list-item--sub-icon .v-icon {
      font-size: 15px !important;
    }

    .total-container {
      padding: 20px 0;
      text-align: center;
    }

    .total-value {
      font-size: 25px;
    }

    .total-value span {
      font-size: 10px;
    }
  }
</style>
