import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const removeRefund = (invoiceId, refundId) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'DELETE';
          let headers = { Authorization: `Bearer ${idToken}` };
          let url = `/invoices/${invoiceId}/refunds/${refundId}`;

          return httpClient(url, { method, headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default removeRefund;
