<template>
  <v-dialog
    scrollable
    fullscreen
    v-model="dialog"
  >
    <v-card class="no-radius" color="grey2">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="primary"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="primary--text ml-4">
          {{ walletName }}
        </span>
      </v-card-title>

      <v-card-text v-if="walletInvestments.length" class="pt-5 px-1">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-slide-x-transition :key="item._id" v-for="item in walletInvestments">
              <v-flex xs12 sm6 md4 lg4>
                <v-card color="grey3" raised @click="showDialogWalletInvestment(item._id)">
                  <div class="bar-container">
                    <div class="bar-title-container">
                      <span class="bar-title primary--text">{{ item.description }}</span>
                      <span class="bar-subtitle grey1--text">{{ item.broker }} - {{ item.category }} - {{ item.type }}</span>
                    </div>

                    <v-menu
                      left
                      bottom
                      origin="right top"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          :disabled="!allowed"
                          color="grey1"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list nav>
                        <v-list-item @click="showFormWalletInvestment(item)">Editar</v-list-item>
                        <v-list-item @click="showDialogFormInvestmentTransaction(item._id)">Aportar/Resgatar</v-list-item>
                        <v-list-item @click="showDialogFormRentabilidade(item._id)">Adicionar rentabilidade</v-list-item>
                        <v-list-item @click="showDialogRemoveInvestment(item)">Remover</v-list-item>
                      </v-list>
                    </v-menu>
                  </div>

                  <div class="total-container">
                    <span class="total-value">
                      <span>R$</span> {{ currency(getTotal(item)) }}
                    </span>
                    <span class="initial-date grey1--text">
                      Aporte inicial de R$ {{ currency(item.initialValue) }} em {{ formatDate(item.initialDate) }}
                    </span>
                  </div>
                </v-card>
              </v-flex>
            </v-slide-x-transition>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-card-text class="empty-container" v-else>
        <h1 class="empty-title text-center grey--text">Nenhum ativo nesta cateira</h1>
      </v-card-text>

      <v-btn
        fab
        fixed
        right
        bottom
        color="primary"
        :small="isMobile"
        :disabled="!allowed"
        class="grey3--text btn-add-category"
        @click="showFormWalletInvestment(null)"
      >
        <v-icon :large="!isMobile">mdi-plus</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { orderBy } from 'lodash'
import moment from 'moment-timezone'
import currency from '@/utils/currency';

export default {
  name: 'DialogWalletInvestments',
  data: () => ({
    dialog: false,
    wallet: '',
    currency
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed;
    },
    investments() {
      return this.$store.getters.investments;
    },
    walletInvestments() {
      return orderBy(this.investments.filter(inv => inv.wallet === this.wallet), 'date');
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    walletName() {
      switch (this.wallet) {
        case 'security':
          return 'Carteira de reserva de emergência';
        case 'independence':
          return 'Carteira da independência financeira';
        case 'life-projects':
          return 'Carteira dos projetos de vida';
        default:
          return '';
      }
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    getTotal(investment) {
      let total = investment.initialValue;

      investment.transactions.forEach(t => {

        if(t.type === 'in' || t.type === 'var'){
          total += t.value
        } else {
          total += (t.value * -1);
        }
        // total += t.type === 'in' ? t.value : (t.value * -1);
      });

      return total;
    },
    formatDate(date) {
      if (date) {
        return moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY');
      }

      return '';
    },
    showFormWalletInvestment(investment) {
      if (this.allowed)
      this.$bus.$emit('showDialogFormWalletInvestment', { wallet: this.wallet, investment });
    },
    showDialogRemoveInvestment(item) {
      if (this.allowed) {
        this.$bus.$emit('showDialogRemoveInvestment', {
          _id: item._id,
          description: item.description
        });
      }
    },
    showDialogFormInvestmentTransaction(investmentId) {
      if (this.allowed)
      this.$bus.$emit('showDialogFormInvestmentTransaction', { investmentId });
    },
    showDialogWalletInvestment(investmentId) {
      this.$bus.$emit('showDialogWalletInvestment', investmentId);
    },
    showDialogFormRentabilidade(investmentId) {
      if (this.allowed)
        this.$bus.$emit('showDialogFormRentabilidade', { investmentId });
    }
  },
  created() {
    this.$bus.$on('showDialogWalletInvestments', wallet => {
      if (wallet) {
        this.wallet = wallet;
        this.dialog = true;
      }
    });
  }
}
</script>

<style scoped>
.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.v-card__title.dialog-form-title span {
  line-height: 1.5em !important;
  font-size: 12px !important;
}

.bar-container, .total-container {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.bar-title-container {
  display: flex;
  flex-direction: column;
}

.bar-title,
.bar-subtitle {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.bar-subtitle {
  font-size: 10px;
  text-transform: none;
}

.total-container {
  padding-top: 0;
  flex-direction: column;
}

.total-value {
  font-size: 20px;
  font-weight: bold;
}

.total-value span {
  font-size: 12px;
}

.initial-date {
  font-size: 9px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 375px) {
  .v-card__title.dialog-form-title span {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 414px) and (max-width: 600px) {
  .v-card__title.dialog-form-title span {
    font-size: 14px !important;
  }
}
</style>
