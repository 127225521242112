<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <h1 class="primary--text">Senha</h1>
        <p class="grey1--text">Envie um link de redefinição de senha para seu e-mail.</p>
      </v-flex>

      <v-flex xs12 class="text-center">
        <v-btn
          rounded
          color="primary"
          :loading="loader"
          @click="sendLink"
          class="darkgrey--text"
        >
          Enviar link
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import firebase from 'firebase/app'

  export default {
    name: 'FormConfigPassword',
    data: () => ({
      loader: false,
    }),
    methods: {
      sendLink() {
        this.loader = true;

        firebase.auth().sendPasswordResetEmail(firebase.auth().currentUser.email)
          .then(() => {
            this.loader = false;
            this.$noty.success('Link enviado com sucesso!');
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível enviar seu link. Tente novamente!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    }
  }
</script>

<style scoped>
  h1, h4 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  }

  p {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  h4 {
    font-size: 16px;
  }

  .input-label {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
  }
</style>
