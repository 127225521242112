<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card v-if="creditCard">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="primary"
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="ml-4">
          {{ title }}
        </span>
      </v-card-title>

      <v-card-text>
        <span class="dialog-form-description">{{ content }}</span>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            color="primary"
            @click="submit"
            :loading="loader"
            class="px-5 darkgrey--text"
          >
            {{ buttonText }}
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import saveCreditCard from '@/api/creditCards/saveCreditCard';

  export default {
    name: 'DialogArchiveCreditCard',
    data: () => ({
      dialog: false,
      loader: false,
      creditCard: null
    }),
    computed: {
      title() {
        if (this.creditCard) {
          if (this.creditCard.archived) {
            if (this.creditCard.pluggy) {
              return 'Ativar sincronização'
            } else {
              return 'Desarquivar cartão'
            }
          } else {
            if (this.creditCard.pluggy) {
              return 'Desativar sincronização'
            } else {
              return 'Arquivar cartão'
            }
          }
        }
      },
      content() {
        if (this.creditCard) {
          if (this.creditCard.pluggy) {
            return `Tem certeza que deseja ${this.creditCard.archived ? 'ativar' : 'desativar'} a sincronização da conta?`
          } else {
            return `Tem certeza que deseja ${this.creditCard.archived ? 'desarquivar' : 'arquivar'} a conta?`
          }
        }
      },
      buttonText() {
        if (this.creditCard) {
          if (this.creditCard.pluggy) {
            return this.creditCard.archived ? 'Ativar' : 'Desativar'
          } else {
            return this.creditCard.archived ? 'Desarquivar' : 'Arquivar'
          }
        }
      }
    },
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.creditCard = null;
      },
      submit() {
        this.loader = true;

        const creditCard = JSON.parse(JSON.stringify(this.creditCard))

        creditCard.archived = !creditCard.archived

        saveCreditCard(creditCard)
          .then(savedCreditCard => {
            this.close();
            this.$store.dispatch('saveCreditCard', savedCreditCard);
            this.$noty.success(`Cartão ${savedCreditCard.archived ? 'arquivado' : 'desarquivado'} com sucesso!`)

            this.$bus.$emit('updateListPendencias')
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível salvar este cartão. Tente novamente!');
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    created() {
      this.$bus.$on('showDialogArchiveCreditCard', creditCard => {
        if (creditCard) {
          this.creditCard = JSON.parse(JSON.stringify(creditCard));
          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
.v-btn.v-size--default {
  font-size: 11px !important;
}
</style>
