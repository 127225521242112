<template>
  <v-dialog scrollable persistent v-model="dialog" max-width="500px" :fullscreen="isMobile">
    <v-card :class="isMobile ? 'no-radius' : ''">
      <v-progress-linear :height="5" indeterminate color="primary" v-if="isMobile && loader"></v-progress-linear>

      <v-card-title>
        <div class="title">
          <v-btn icon v-if="isMobile" class="mr-4" @click.stop.prevent="close">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ video._id ? 'Editar' : 'Adicionar' }} Video
        </div>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-text-field
            type="text"
            color="primary"
            label="Título"
            v-model="video.name"
            :error-messages="nameErrors"
            @input="$v.video.name.$touch()"
          ></v-text-field>

          <v-textarea rows="3" color="accent" label="Descrição" v-model="video.description"></v-textarea>

          <v-text-field
            type="text"
            color="primary"
            label="Código"
            v-model="video.cod"
            :error-messages="codErrors"
            @input="$v.video.cod.$touch()"
          ></v-text-field>

          <v-select
            color="primary"
            label="Categoria"
            :items="categories"
            item-text="text"
            item-value="value"
            v-model="video.category"
            :error-messages="categoryErrors"
            @input="$v.video.category.$touch()"
          ></v-select>

          <v-select
            color="primary"
            label="Tipo"
            :items="types"
            item-text="text"
            item-value="value"
            v-model="video.type"
            :error-messages="typeErrors"
            @input="$v.video.type.$touch()"
          ></v-select>

          <v-card-actions class="px-0">
            <v-btn
              text
              rounded
              depressed
              class="px-5"
              type="button"
              color="primary"
              :disabled="loader"
              @click.stop.prevent="close"
            >Cancelar</v-btn>

            <v-spacer></v-spacer>

            <v-btn
              rounded
              depressed
              class="px-5 darkgrey--text"
              type="submit"
              color="primary"
              :loading="loader"
            >Salvar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import saveVideo from '@/api/videos/saveVideo';
import stringCapitalize from '@/utils/stringCapitalize';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'DialogFormVideo',
  data: () => ({
    dialog: false,
    loader: false,
    video: {
      name: '',
      cod: '',
      category: '',
      type: '',
      description: ''
    },
    types: [
      { text: 'Todos', value: 'all' },
      { text: 'Cliente', value: 'customer' },
      { text: 'Empresa', value: 'company' },
      { text: 'Planejador', value: 'planner' }
    ],
    categories: [
      { text: 'Aposentadoria', value: 'aposentadoria' },
      { text: 'Fluxo de caixa', value: 'fluxo-caixa' },
      { text: 'Gestão de riscos', value: 'gestao-riscos' },
      { text: 'Gestão tributária', value: 'gestao-tributaria' },
      { text: 'Investimentos', value: 'investimentos' },
      { text: 'Liquidez', value: 'liquidez' },
      { text: 'Planos de ação', value: 'planos-acao' },
      { text: 'Outros', value: 'outro' }
    ]
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.video = {
        name: '',
        cod: '',
        category: '',
        type: ''
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;
        this.video.name = stringCapitalize(this.video.name);

        saveVideo(this.video)
          .then(() => {
            this.$bus.$emit('getVideos');
            this.$noty.success('Video salvo com sucesso!');
            this.close();
          })
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);
            this.loader = false;
            this.$noty.error(
              'Falha ao salvar video! Tente novamente mais tarde'
            );
          });
      }
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.video.name.$dirty) return errors;
      !this.$v.video.name.required && errors.push('Título é obrigatório');
      !this.$v.video.name.minLength &&
        errors.push('O título deve conter, no mínimo, 3 caracteres');
      !this.$v.video.name.maxLength &&
        errors.push('O título deve conter, no máximo, 150 caracteres');
      return errors;
    },
    codErrors() {
      const errors = [];
      if (!this.$v.video.cod.$dirty) return errors;
      !this.$v.video.cod.required && errors.push('Código é obrigatório');
      return errors;
    },
    categoryErrors() {
      const errors = [];
      if (!this.$v.video.category.$dirty) return errors;
      !this.$v.video.category.required &&
        errors.push('Categoria é obrigatória');
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.video.type.$dirty) return errors;
      !this.$v.video.type.required && errors.push('Tipo é obrigatório');
      return errors;
    }
  },
  validations: {
    video: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150)
      },
      cod: {
        required
      },
      category: {
        required
      },
      type: {
        required
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormVideo', video => {
      if (video) {
        this.video = JSON.parse(JSON.stringify(video));
      }
      this.dialog = true;
    });
  }
};
</script>

<style scoped>
.v-card.no-radius {
  border-radius: 0 !important;
}

.title {
  display: flex;
  align-items: center;
}
</style>
