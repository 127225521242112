<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    :persistent="loader"
  >
    <v-card color="gre3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="error"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="error--text">
          Remover Observação
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <p class="white--text">Tem certeza que quer remover esta observação?</p>

        <v-card-actions class="px-0">
          <v-btn
            text
            small
            rounded
            class="px-5"
            color="white"
            @click="close"
            :disabled="loader"
          >
            Cancelar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            dark
            small
            rounded
            class="px-5"
            color="error"
            @click="submit"
            :loading="loader"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import removeLifeInsuranceDependentsNote from '@/api/lifeInsurance/removeLifeInsuranceDependentsNote';

export default {
  name: 'DialogDeleteInsuranceDependentNote',
  data: () => ({
    dialog: false,
    loader: false,
    noteId: null,
    insuranceId: null
  }),
  methods: {
    close() {
      this.dialog = false
      this.loader = false
      this.noteId = null
      this.insuranceId = null
    },
    submit() {
      this.loader = true

      removeLifeInsuranceDependentsNote(this.insuranceId, this.noteId)
        .then(() => {
          this.$store.dispatch('deleteLifeInsuranceDependentsNote', {
            noteId: this.noteId,
            insuranceId: this.insuranceId,
          })

          this.close()
        })
        .catch(err => {
          this.loader = false
          this.$noty.error('Desculpe! Não foi possível remover esta observação. Tente novamente mais tarde!', 'error')

          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }
        })
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.close()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogDeleteInsuranceDependentNote', data => {
      if (data.insuranceId && data.noteId) {
        this.noteId = data.noteId
        this.insuranceId = data.insuranceId
        this.dialog = true
      }
    })
  }
}
</script>
