<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    max-width="600px"
    :fullscreen="isMobile"
  >
    <v-card color="grey2" :class="isMobile ? 'no-radius' : ''">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4">
          {{ note._id ? 'Editar' : 'Adicionar' }} Anotação
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md fluid>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="input-label primary--text">Título</span>
                <v-text-field
                  dense
                  type="text"
                  color="grey1"
                  v-model="note.title"
                  :error-messages="titleErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 v-if="editor" class="py-10">
                <span class="input-label primary--text">Anotações</span>
                <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                  <div class="menubar mb-5 mt-3">
                    <v-btn-toggle dense dark multiple>
                      <v-btn
                        small
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                      >
                        <v-icon>mdi-format-bold</v-icon>
                      </v-btn>

                      <v-btn
                        small
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                      >
                        <v-icon>mdi-format-italic</v-icon>
                      </v-btn>

                      <v-btn
                        small
                        :class="{ 'is-active': isActive.strike() }"
                        @click="commands.strike"
                      >
                        <v-icon>mdi-format-strikethrough</v-icon>
                      </v-btn>

                      <v-btn
                        small
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline"
                      >
                        <v-icon>mdi-format-underline</v-icon>
                      </v-btn>

                      <v-btn
                        small
                        :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                        @click="commands.heading({ level: 2 })"
                      >
                        <v-icon>mdi-format-header-1</v-icon>
                      </v-btn>

                      <v-btn
                        small
                        :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                        @click="commands.heading({ level: 3 })"
                      >
                        <v-icon>mdi-format-header-2</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </editor-menu-bar>

                <editor-content
                  class="editor__content"
                  :editor="editor"
                />
              </v-flex>

              <v-flex xs12>
                <v-switch
                  color="primary"
                  label="Meu cliente pode ler"
                  v-model="note.customerCanView"
                ></v-switch>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="pa-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    fab
                    small
                    type="submit"
                    color="primary"
                    :loading="loader"
                    class="darkgrey--text"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from 'tiptap-extensions';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import saveNote from '../../../../api/notes/saveNote';

export default {
  name: 'DialogFormNote',
  components: {
    EditorContent,
    EditorMenuBar
  },
  data: () => ({
    dialog: false,
    loader: false,
    note: {
      title: '',
      content: '',
      customerCanView: true
    },
    editor: null,
    contentError: false
  }),
  computed: {
    isCustomer() {
      return this.user.role === 'customer';
    },
    customer() {
      return this.$store.getters.customer;
    },
    user() {
      return this.$store.getters.user;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.note.title.$dirty) return errors;
      !this.$v.note.title.required && errors.push('Digite o título');
      !this.$v.note.title.minLength && errors.push('O título deve conter, no mínimo, 3 caracteres');
      !this.$v.note.title.maxLength && errors.push('O título deve conter, no máximo, 50 caracteres');
      return errors;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.note = {
        title: '',
        content: '',
        customerCanView: true
      };
      this.$v.$reset();
      this.editor.destroy();
      this.editor = null;
    },
    submit() {
      this.$v.$touch();
      this.note.content = this.editor.getHTML();

      if (!this.$v.$anyError && !this.contentError) {
        this.loader = true;

        let note = JSON.parse(JSON.stringify(this.note));

        note.user = this.user._id;
        note.customer = this.customer._id;

        saveNote(note)
          .then(() => {
            this.$bus.$emit('noteSaved');
            this.close();
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível salvar suas anotações. Tente novamente!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    formatDate(date) {
      if (date)
        dayjs(date).format('DD/MM/YYYY');
      return '';
    }
  },
  validations: {
    note: {
      title: { required, minLength: minLength(3), maxLength: maxLength(50) }
    }
  },
  created() {
    this.$bus.$on('showDialogFormNote', note => {
      if (note) {
        this.note = JSON.parse(JSON.stringify(note));
      }

      this.editor = new Editor({
        content: this.note.content,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ]
      });

      this.dialog = true;
    });
  }
};
</script>

<style scoped>
.input-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.editor__content {
  background-color: #717373;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif !important;
}
</style>
