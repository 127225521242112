export default {
  firebase: {
    apiKey: 'AIzaSyAfSACUPQr_q2UfRI7xitEMJYatLGRs-Kc',
    authDomain: 'dev-uplanner.firebaseapp.com',
    databaseURL: 'https://dev-uplanner.firebaseio.com',
    projectId: 'dev-uplanner',
    storageBucket: 'dev-uplanner.appspot.com',
    messagingSenderId: '692534059456',
    appId: '1:692534059456:web:e41c3810eb8c862e502825',
    measurementId: 'G-1C84PRX8LP'
  },
  api: {
    host: '/api'
  },
  pass: 's@ss_2020',
  calendly: {
    api: 'https://calendly.com/api/v1',
    listener: 'https://uplanner.app/api/calendly/event-listener'
  },
  bankCreditCardsPdf: [
    { 'name': 'Banco do Brasil', 'value': 'Banco-do-brasil' },
    { 'name': 'Bradesco', 'value': 'Bradesco' },
    { 'name': 'Creditcard', 'value': 'Credicard' },
    { 'name': 'Inter', 'value': 'Inter' },
    { 'name': 'Itaú', 'value': 'Itau' },
    { 'name': 'Nubank', 'value': 'Nubank' },
    { 'name': 'Santander', 'value': 'Santander' }
  ],
  bankExtratoPdf: [
    { 'name': 'Bradesco', 'value': 'bradesco' },
    { 'name': 'Inter', 'value': 'inter' },
    { 'name': 'Itaú', 'value': 'itau' },
    { 'name': 'Santander', 'value': 'santander' }
  ],
  appVersion: '1.17.26',
  iuguAccountId: '75E8AAF682CC406186A57D2553C6DCF1',
  subscriptionReferenteDate: '2022-08-02',
  pluggy: {
    script: 'https://cdn.pluggy.ai/pluggy-connect/v2.3.1/pluggy-connect.js'
  },
  production: false
};
