import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const getCompanyEmployess = (companyId, page = 1, search = '', status = '') => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const options = {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        const url = `/companies/${companyId}/employees?page=${page}&search=${search}&status=${status}`

        return httpClient(url, options)
      })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}

export default getCompanyEmployess
