import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Salvar aliquota IRPF na configuração de parâmetros do gestor tributário
 * @param {string} configId
 * @param {object} item
 */
const saveAliquotaIr = (configId, item) =>
  new Promise((resolve, reject) => {
    let user = store.getters.user;

    if (user && user.role === 'admin') {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          let data = item;
          let method = 'POST';
          let headers = {
            role: user.role,
            Authorization: `Bearer ${idToken}`
          };
          let url = `/config/ir/${configId}/aliquota-irpf`;

          return httpClient(url, { method, headers, data });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });

export default saveAliquotaIr;
