<template>
  <v-card flat color="grey3">
    <v-card-title class="primary--text seguro-vida main-title">Custos com dependentes</v-card-title>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-container class="pa-0">
          <v-layout class="pa-0" row wrap>
            <v-flex xs12>
              <span class="white--text seguro-vida section-title">Filhos</span>
            </v-flex>

            <v-flex xs12 class="text-center" v-if="!custosDependentes.filhos.length">
              <h3 class="grey1--text">Não possui filhos</h3>
            </v-flex>

            <v-flex
              xs12 sm6 md4 lg4
              :key="filho._id"
              v-for="filho in custosDependentes.filhos"
            >
              <v-card height="100%" class="seguro-vida card-column">
                <v-card-text>
                  <v-card-title class="seguro-vida item-title pa-0 white--text">{{ filho.nome }}</v-card-title>
                  <div class="seguro-vida info-item">
                    <span class="seguro-vida info-item-title primary--text">Nível de escolaridade garantida: </span>
                    <span class="seguro-vida info-item-value">{{ filho.nivelEscolaridadeGarantida }}</span>
                  </div>
                  <div class="seguro-vida info-item">
                    <span class="seguro-vida info-item-title primary--text">Custo educacional mensal: </span>
                    <span class="seguro-vida info-item-value">{{ numberToCurrency(filho.custoEducacional) }}</span>
                  </div>
                  <div class="seguro-vida info-item" v-if="filho.reajusteAnual !== undefined">
                    <span class="seguro-vida info-item-title primary--text">Reajuste anual: </span>
                    <span class="seguro-vida info-item-value">{{ filho.reajusteAnual.toFixed(2) }}%</span>
                  </div>
                  <div class="seguro-vida info-item">
                    <span class="seguro-vida info-item-title primary--text">Prazo de cobertura: </span>
                    <span class="seguro-vida info-item-value">
                      {{ filho.prazoCobertura ? filho.prazoCobertura + ' anos' : '' }}
                    </span>
                  </div>
                  <div class="seguro-vida info-item">
                    <span class="info-item-title primary--text">Intercâmbio: </span>
                    <span class="info-item-value">{{ numberToCurrency(filho.intercambio) }}</span>
                  </div>
                </v-card-text>

                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    small
                    outlined
                    @click="showDialogFormInsuranceSon(filho)"
                    color="primary"
                    :disabled="!allowed"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-flex xs12 class="d-flex flex-column align-center justify-center py-5">
              <span class="seguro-vida input-label grey1--text">Total</span>
              <span class="seguro-vida total-value white--text">
                <span>R$</span> {{ totalFilhos }}
              </span>
            </v-flex>

            <v-flex xs12 class="px-5 mt-5">
              <v-divider></v-divider>
            </v-flex>

            <v-flex xs12 class="mt-10">
              <span class="white--text seguro-vida section-title">Outros dependentes</span>
            </v-flex>

            <v-flex xs12 class="text-center" v-if="!custosDependentes.outrosDependentes.length">
              <h3 class="grey1--text">Não possui outros dependentes</h3>
            </v-flex>

            <v-flex
              xs12 sm6 md4 lg4
              :key="dependente._id"
              v-for="dependente in custosDependentes.outrosDependentes"
            >
              <v-card height="100%" class="seguro-vida card-column">
                <v-card-text>
                  <v-card-title class="seguro-vida item-title pa-0 white--text">{{ dependente.descricao }}</v-card-title>

                  <div class="seguro-vida info-item">
                    <span class="seguro-vida info-item-title primary--text">Valor mensal: </span>
                    <span class="seguro-vida info-item-value">{{ numberToCurrency(dependente.valorMensal) }}</span>
                  </div>
                  <div class="seguro-vida info-item">
                    <span class="seguro-vida info-item-title primary--text">Prazo de cobertura: </span>
                    <span class="seguro-vida info-item-value">{{ dependente.prazoCobertura }} anos</span>
                  </div>
                </v-card-text>

                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    small
                    outlined
                    @click="showDialogFormInsuranceDependente(dependente)"
                    color="primary"
                    :disabled="!allowed"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    outlined
                    @click="showDialogLifeInsuranceRemoveDependent(dependente._id)"
                    color="primary"
                    :disabled="!allowed"
                  >
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-flex xs12 class="d-flex flex-column align-center justify-center py-5">
              <span class="seguro-vida input-label grey1--text">Total</span>
              <span class="seguro-vida total-value white--text">
                <span>R$</span> {{ totalOutrosDependentes }}
              </span>
            </v-flex>

            <v-flex xs12 class="text-center mt-5">
              <v-btn
                small
                rounded
                outlined
                color="primary"
                class="seguro-vida"
                :disabled="!allowed"
                @click="showDialogFormInsuranceDependente(null)"
              >
                Adicionar outro dependente
              </v-btn>
            </v-flex>

            <v-flex xs12 class="px-5 mt-5">
              <v-divider></v-divider>
            </v-flex>

            <v-flex xs12 class="mt-10">
              <span class="white--text seguro-vida section-title">Observações</span>
            </v-flex>

            <v-flex xs12 class="text-center" v-if="!custosDependentes.notes.length">
              <h3 class="grey1--text">Nenhuma observação Adicionada</h3>
            </v-flex>

            <v-flex
              xs12 sm6 md4 lg4
              :key="note._id"
              v-for="note in custosDependentes.notes"
            >
              <v-card height="100%" class="seguro-vida card-column">
                <v-card-text>
                  <v-card-title class="seguro-vida item-title pa-0 white--text">{{ note.title }}</v-card-title>

                  <div class="seguro-vida info-item mt-2">
                    <p>{{ note.text }}</p>
                  </div>
                </v-card-text>

                <v-card-actions class="pt-0 text-right">
                  <div class="text-left" style="line-height: 1em">
                    <small class="grey1--text">Criada em: {{ formatDate(note.date) }}</small>
                    <br>
                    <small class="grey1--text">Última atualização: {{ formatDate(note.updated) }}</small>
                  </div>

                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    small
                    outlined
                    @click="showDialogFormInsuranceDependentNote(note)"
                    color="primary"
                    :disabled="!allowed"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    outlined
                    color="primary"
                    :disabled="!allowed"
                    @click="showDialogDeleteInsuranceDependentNote(note._id)"
                  >
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-flex xs12 class="text-center mt-5">
              <v-btn
                small
                rounded
                outlined
                color="primary"
                class="seguro-vida"
                :disabled="!allowed"
                @click="showDialogFormInsuranceDependentNote(null)"
              >
                Adicionar observação
              </v-btn>
            </v-flex>

            <v-flex xs12 class="px-5 mt-5">
              <v-divider></v-divider>
            </v-flex>

            <v-flex xs12 class="d-flex flex-column align-center justify-center py-5">
              <span class="seguro-vida input-label total-label primary--text">Total em custos com dependentes</span>
              <span class="seguro-vida total-value total white--text">
                <span>R$</span> {{ currency(custosDependentes.total) }}
              </span>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs'
import numberToCurrency from '@/utils/numberToCurrency';
import currency from '@/utils/currency';
import updateLifeInsurace from '@/api/lifeInsurance/updateLifeInsurance';

export default {
  name: 'CardInsuranceDependentes',
  props: ['isSpouse'],
  data: () => ({
    currency,
    numberToCurrency,
    loader: false,
    custosDependentes: {
      total: 0,
      filhos: [],
      outrosDependentes: [],
      obs: ''
    },
  }),
  validations: {
    custosDependentes: {
      obs: {
        isValid(v) {
          return !v || v.length <= 256
        }
      }
    }
  },
  computed: {
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    },
    totalFilhos() {
      let total = 0

      this.custosDependentes.filhos.forEach(filho => {
        let custo = 0

        if (filho.reajusteAnual) {
          let custoMensalAtualizado = parseFloat(filho.custoEducacional)

          for (let i = 0; i < filho.prazoCobertura; i++) {
            custo += (custoMensalAtualizado * 12)
            custoMensalAtualizado += (custoMensalAtualizado * (filho.reajusteAnual / 100))
          }
          custo += parseFloat(filho.intercambio)
        } else {
          custo = (parseFloat(filho.custoEducacional) * filho.prazoCobertura * 12) + parseFloat(filho.intercambio)
        }

        total += custo
      })

      return currency(total)
    },
    totalOutrosDependentes() {
      let total = 0

      this.custosDependentes.outrosDependentes.forEach(item => {
        total += (parseFloat(item.valorMensal) * item.prazoCobertura * 12)
      })

      return currency(total)
    },
    allowed() {
      return this.$store.getters.allowed;
    },
    obsErrors() {
      const errors = [];

      if (!this.$v.custosDependentes.obs.$anyDirty) {
        return errors;
      }

      !this.$v.custosDependentes.obs.isValid && errors.push('Não ultrapasse 256 caracteres');

      return errors;
    }
  },
  methods: {
    copyData() {
      if (this.lifeInsurance) {
        this.custosDependentes = JSON.parse(JSON.stringify(this.lifeInsurance.custosDependentes));
        this.custosDependentes.filhos.forEach(filho => {
          filho.custoEducacional = filho.custoEducacional.toFixed(2);
          filho.intercambio = filho.intercambio.toFixed(2);
        });
        this.custosDependentes.outrosDependentes.forEach(dependente => {
          dependente.valorMensal = dependente.valorMensal.toFixed(2);
        });
      }
    },
    submit() {
      this.$v.$touch()

      if (!this.$v.$anyError) {
        this.loader = true;

        const custosDependentes = JSON.parse(JSON.stringify(this.custosDependentes))

        updateLifeInsurace(this.lifeInsurance._id, { custosDependentes })
          .then(lifeInsurance => {
            this.$store.dispatch('saveLifeInsurance', lifeInsurance)
            this.loader = false;
            this.$noty.success('Dados salvos com sucesso!')
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível alterar seus dados. Tente novamente mais tarde!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    },
    showDialogFormInsuranceSon(filho) {
      if (this.allowed) {
        this.$bus.$emit('showDialogFormInsuranceSon', {
          filho,
          isSpouse: this.isSpouse
        });
      }
    },
    showDialogFormInsuranceDependente(dependente) {
      if (this.allowed) {
        this.$bus.$emit('showDialogFormInsuranceDependente', {
          dependente,
          isSpouse: this.isSpouse
        });
      }
    },
    showDialogLifeInsuranceRemoveDependent(dependentId) {
      if (this.allowed) {
        this.$bus.$emit('showDialogLifeInsuranceRemoveDependent', {
          dependentId,
          insuranceId: this.lifeInsurance._id
        });
      }
    },
    formatDate(date) {
      if (date) {
        return dayjs(date).format('DD/MM/YYYY HH:mm')
      }
      return ''
    },
    showDialogFormInsuranceDependentNote(note) {
      if (this.allowed) {
        this.$bus.$emit('showDialogFormInsuranceDependentNote', {
          note,
          insuranceId: this.lifeInsurance._id
        })
      }
    },
    showDialogDeleteInsuranceDependentNote(noteId) {
      if (this.allowed && noteId) {
        this.$bus.$emit('showDialogDeleteInsuranceDependentNote', {
          noteId,
          insuranceId: this.lifeInsurance._id
        })
      }
    }
  },
  watch: {
    lifeInsurance: {
      deep: true,
      handler() {
        this.copyData();
      }
    }
  },
  created() {
    this.copyData();
  }
}
</script>

<style scoped>
.seguro-vida.input-label.total-label {
  font-size: 15px;
  line-height: 1.5em;
}

.seguro-vida.total-value.total {
  font-size: 24px;
  line-height: 1.5em;
}
</style>
