<template>
  <v-card color="grey2">
    <v-card-title class="primary--text">Dados Pessoais</v-card-title>

    <v-card-text class="px-1">
      <v-container grid-list-md fluid v-if="usuario">
        <v-layout row wrap>
          <v-flex xs12 v-if="conjuge">
            <h3>{{ usuario.nome }}</h3>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="!perfilLimitado">
            <span class="input-label primary--text">Idade</span>
            <span class="input-label">{{ getCurrentAge(usuario.dataNascimento) }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="!perfilLimitado">
            <span class="input-label primary--text">Data de nascimento</span>
            <span class="input-label">{{ formatDate(usuario.dataNascimento) }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="usuario.email">
            <span class="input-label primary--text">E-mail</span>
            <span class="input-label">{{ usuario.email }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="usuario.phoneNumber">
            <span class="input-label primary--text">Celular</span>
            <span class="input-label">{{ formatPhoneNumber(usuario.phoneCountryCode, usuario.phoneNumber) }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="!perfilLimitado">
            <span class="input-label primary--text">Estado Civil</span>
            <span class="input-label">{{ usuario.estadoCivil }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column">
            <span class="input-label primary--text">CPF</span>
            <span class="input-label">{{ formatCpfCnpj(usuario.cpf) }}</span>
          </v-flex>

          <v-flex xs12>
            <h4>Endereço</h4>
          </v-flex>

          <v-flex xs12 sm4  class="d-flex flex-column" v-if="usuario.endereco && usuario.endereco.cep">
            <span class="input-label primary--text">CEP</span>
            <span class="input-label">{{ formatCep(usuario.endereco.cep) }}</span>
          </v-flex>

          <v-flex xs12 sm8 class="d-flex flex-column" v-if="usuario.endereco && usuario.endereco.logradouro">
            <span class="input-label primary--text">Logradouro</span>
            <span class="input-label">{{ usuario.endereco.logradouro }}, {{ usuario.endereco.numero }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="usuario.endereco && usuario.endereco.bairro">
            <span class="input-label primary--text">Bairro</span>
            <span class="input-label">{{  usuario.endereco.bairro  }}</span>
          </v-flex>

          <v-flex xs12 sm8 class="d-flex flex-column" v-if="usuario.endereco && usuario.endereco.cidade">
            <span class="input-label primary--text">Cidade</span>
            <span class="input-label">{{  usuario.endereco.cidade  }}, {{ usuario.endereco.estado.sigla }}</span>
          </v-flex>
        </v-layout>
      </v-container>

      <v-divider></v-divider>

      <v-container grid-list-md fluid v-if="conjuge && conjuge.nome">
        <v-layout row wrap>
          <v-flex xs12>
            <h3>{{ conjuge.nome }}</h3>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column">
            <span class="input-label primary--text">Idade</span>
            <span class="input-label">{{ getCurrentAge(conjuge.dataNascimento) }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column">
            <span class="input-label primary--text">Data de nascimento</span>
            <span class="input-label">{{ formatDate(conjuge.dataNascimento) }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="conjuge.email">
            <span class="input-label primary--text">E-mail</span>
            <span class="input-label">{{ conjuge.email }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="conjuge.phoneNumber">
            <span class="input-label primary--text">Celular</span>
            <span class="input-label">{{ formatPhoneNumber(conjuge.phoneCountryCode, conjuge.phoneNumber) }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="conjuge.cpf">
            <span class="input-label primary--text">CPF</span>
            <span class="input-label">{{ formatCpfCnpj(conjuge.cpf) }}</span>
          </v-flex>

          <v-flex xs12>
            <h4>Endereço</h4>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="conjuge.endereco && conjuge.endereco.cep">
            <span class="input-label primary--text">CEP</span>
            <span class="input-label">{{ formatCep(conjuge.endereco.cep) }}</span>
          </v-flex>

          <v-flex xs12 sm8 class="d-flex flex-column" v-if="conjuge.endereco && conjuge.endereco.logradouro">
            <span class="input-label primary--text">Logradouro</span>
            <span class="input-label">{{ conjuge.endereco.logradouro }}, {{ conjuge.endereco.numero }}</span>
          </v-flex>

          <v-flex xs12 sm4 class="d-flex flex-column" v-if="conjuge.endereco && conjuge.endereco.bairro">
            <span class="input-label primary--text">Bairro</span>
            <span class="input-label">{{  conjuge.endereco.bairro  }}</span>
          </v-flex>

          <v-flex xs12 sm8 class="d-flex flex-column" v-if="conjuge.endereco && conjuge.endereco.cidade">
            <span class="input-label primary--text">Cidade</span>
            <span class="input-label">{{  conjuge.endereco.cidade  }}, {{ conjuge.endereco.estado.sigla }}</span>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-card-actions class="pb-5">
      <v-spacer></v-spacer>

      <v-btn
        v-if="allowed"
        outlined
        rounded
        color="primary"
        class="px-10 text-capitalize"
        @click="showDialogEditBasicInfos"
      >
        Editar
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  isValid as isValidCpf,
  format as formatCpf
} from "@fnando/cpf"

import {
  isValid as isValidCnpj,
  format as formatCnpj
} from "@fnando/cnpj"

export default {
  name: 'CardInformacoesBasicas',
  data: () => ({
    loader: false,
    currentDate: null
  }),
  computed: {
    isCustomer() {
      return this.$store.getters.user.role === 'customer'
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer
    },
    perfilLimitado() {
      return this.user && this.user.permissions && this.user.permissions.perfilLimitado;
    },
    perfil() {
      return this.$store.getters.perfil;
    },
    usuario() {
      if (this.perfil) {
        return this.perfil.informacoesBasicas.usuario
      }

      return null
    },
    conjuge() {
      if (this.perfil) {
        return this.perfil.informacoesBasicas.conjuge
      }

      return null
    },
    allowed() {
      return this.$store.getters.allowed;
    }
  },
  methods: {
    getCurrentAge(date) {
      if (date) {
        return this.$date().diff(date, 'year')
      }

      return ''
    },
    formatDate(date) {
      if (date) {
        if (/\d{2}\/\d{2}\/\d{4}/.test(date)) {
          date = date.split('/').reverse().join('-')
        }

        return this.$date(date).format('DD/MM/YYYY')
      }

      return ''
    },
    formatPhoneNumber(countryCode, phone) {
      if (phone) {
        if (!countryCode) {
          countryCode = '55'
        }

        if (countryCode === '55') {
          const ddd = phone.slice(0, 2)
          const phonePartOne = phone.slice(2,7)
          const phonePartTwo = phone.slice(7,phone.length)

          return `+${countryCode} (${ddd}) ${phonePartOne}-${phonePartTwo}`
        } else {
          return `+${countryCode} ${phone}`
        }
      }

      return ''
    },
    formatCep(cep) {
      if (cep) {
        const partOne = cep.slice(0, 5)
        const partTwo = cep.slice(5, cep.length)

        return `${partOne}-${partTwo}`
      }

      return ''
    },
    formatCpfCnpj(cpf_cnpj) {
      if (isValidCpf(cpf_cnpj)) {
        return formatCpf(cpf_cnpj)
      } else if (isValidCnpj(cpf_cnpj)) {
        return formatCnpj(cpf_cnpj)
      }

      return ''
    },
    showDialogEditBasicInfos() {
      if (this.allowed) {
        this.$bus.$emit('showDialogEditBasicInfos')
      }
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .input-label {
    font-size: 14px;
  }
}
</style>
