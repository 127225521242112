import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const saveCreditCard = data => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'POST';
        let url = `/credit-cards`;
        let headers = { Authorization: `Bearer ${idToken}` };

        if (data._id) {
          method = 'PUT'
          url += `/${data._id}`
        }

        return httpClient(url, { method, headers, data });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default saveCreditCard;
