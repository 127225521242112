import firebase from 'firebase/app';
import httpClient from '../../plugins/httpClient';

/**
 * Buscar lista de eventos de determinado usuário
 * @param userId
 * @param page
 * @param limit
 * @param type
 * @param sort
 * @returns {Promise<unknown>}
 */
const getEventsByUserId = (
  userId,
  page = 1,
  limit = 10,
  type = '',
  sort = -1
) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/events/user/${userId}?page=${page}&limit=${limit}&type=${type}&sort=${sort}`;

        return httpClient(url, { headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
};

export default getEventsByUserId;
