<template>
  <v-main>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <h1 class="white--text">Alíquota NFS-e</h1>
        </v-flex>

        <v-flex xs12 sm12 md6 lg4>
          <v-form @submit.prevent="submit">
            <v-card>
              <v-card-text>
                <span class="input-label primary--text">Alíquota atual</span>
                <v-text-field
                  dense
                  suffix="%"
                  type="number"
                  class="pt-0 mt-0"
                  v-model="aliquota"
                  :disabled="loader || getter"
                ></v-text-field>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  class="px-5"
                  type="submit"
                  color="success"
                  :loading="loader"
                  :disabled="getter"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import {maxValue, minValue} from 'vuelidate/lib/validators'
import getAliquotaNfse from "@/api/aliquotaNfse/getAliquotaNfse";
import saveAliquotaNfse from "@/api/aliquotaNfse/saveAliquotaNfse";

export default {
  name: 'AliquotaNfse',
  data: () => ({
    loader: false,
    getter: false,
    aliquota: 0
  }),
  validations: {
    aliquota: {
      maxValue: maxValue(100),
      minValue: minValue(0)
    }
  },
  computed: {
    aliquotaErrors() {
      if (!this.$v.aliquota.$anyDirty) {
        return []
      }
      if (!this.$v.aliquota.$anyError) {
        return ['Preencha uma alíquota válida']
      }
    }
  },
  methods: {
    async getAliquota() {
      try {
        this.getter = true
        const data = await getAliquotaNfse()

        if (data) {
          this.aliquota = data.aliquota
        }
      } catch (err) {
        this.$noty.error('Falha ao buscar aliquota')
      } finally {
        this.getter = false
      }
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true
          await saveAliquotaNfse({ aliquota: this.aliquota })
          this.$noty.success('Alíquota salva com sucesso!')
        }
      } catch (err) {
        this.$noty.error('Falha ao salvar alíquota')
      } finally {
        this.loader = false
      }
    }
  },
  created() {
    this.getAliquota()
  }
}
</script>
