<template>
  <v-card color="transparent" flat>
    <v-card-text class="px-0" v-if="indFinanceira">
      <v-container fluid grid-list-md class="px-0">
        <v-layout row wrap>
          <v-flex xs12 sm6 md4 lg3 class="d-flex flex-column">
            <span class="input-label primary--text">Idade em que deseja alcançar sua independência</span>
            <span class="input-label">{{ premissas.idadeIndependencia }} anos</span>
          </v-flex>

          <v-flex
            xs12 sm6 md4 lg3
            class="d-flex flex-column"
            v-if="hasConjuge && premissas.idadeIndependenciaConjuge"
          >
            <span class="input-label primary--text">Idade em que o(a) cônjuge deseja alcançar a independência</span>
            <span class="input-label">{{ premissas.idadeIndependenciaConjuge }} anos</span>
          </v-flex>

          <v-flex xs12 sm6 md4 lg3 class="d-flex flex-column">
            <span class="input-label primary--text">Renda desejada</span>
            <span class="input-label">{{ numberToCurrency(premissas.rendaDesejada) }}</span>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-card-actions class="pb-5">
      <v-spacer></v-spacer>

      <v-btn
        v-if="allowed"
        outlined
        rounded
        color="primary"
        class="px-10 text-capitalize"
        @click="showDialogEditIndFinanceira"
      >
        Editar
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';

export default {
  name: 'CardIndFinanceira',
  data: () => ({
    numberToCurrency
  }),
  computed: {
    indFinanceira() {
      return this.$store.getters.independenciaFinanceira
    },
    premissas() {
      if (this.indFinanceira)
        return this.indFinanceira.premissas;

      return null;
    },
    perfil() {
      return this.$store.getters.perfil;
    },
    hasConjuge() {
      return this.perfil && this.perfil.informacoesBasicas.conjuge && this.perfil.informacoesBasicas.conjuge.nome;
    },
    allowed() {
      return this.$store.getters.allowed;
    }
  },
  methods: {
    showDialogEditIndFinanceira() {
      if (this.allowed)
        this.$bus.$emit('showDialogEditIndFinanceira');
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .input-label {
    font-size: 14px;
  }
}
</style>
