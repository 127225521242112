import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Salvar video EAD
 * @param {object} video
 */
const saveVideo = video =>
  new Promise((resolve, reject) => {
    let user = store.getters.user;

    if (user.role === 'admin') {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          let data = video;
          let method = 'POST';
          let headers = {
            role: user.role,
            Authorization: `Bearer ${idToken}`
          };
          let url = `/videos`;

          if (video._id) {
            method = 'PUT';
            url += `/${video._id}`;
          }

          return httpClient(url, { headers, data, method });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });

export default saveVideo;
