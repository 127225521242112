<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    :persistent="loader"
  >
    <v-card color="grey2" v-if="orcamento">
      <v-card-title class="dialog-card-title">
        <v-btn
          icon
          color="grey1"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="grey1--text ml-4">
          Alterar aplicação
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-text-field
                  min="0"
                  max="100"
                  suffix="%"
                  type="number"
                  color="grey1"
                  label="Percentual"
                  @input="updateValue"
                  @change="updateValue"
                  v-model.number="orcamento.aplicacao.percent"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6>
                <v-text-field
                  type="tel"
                  color="grey1"
                  v-money="money"
                  label="Valor da aplicação"
                  v-model="orcamento.aplicacao.value"
                  id="input-value-orcamento-aplicacao"
                  @input="updatePercent"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>

            <v-btn
              fab
              small
              type="submit"
              color="primary"
              class="darkgrey--text px-5"
              :loading="loader"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { VMoney } from 'v-money'
  import updateAplicacaoOrcamento from "../../../../api/orcamento/updateAplicacaoOrcamento";
  import removeMoneyMask from "../../../../utils/removeMoneyMask";

  export default {
    name: 'DialogFormAplicacaoOrcamento',
    directives: { money: VMoney },
    data: () => ({
      dialog: false,
      loader: false,
      orcamento: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
    }),
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.orcamento = null;
      },
      submit() {
        this.loader = true;

        let aplicacao = {
          percent: this.orcamento.aplicacao.percent,
          value: removeMoneyMask(this.orcamento.aplicacao.value)
        };

        updateAplicacaoOrcamento(this.orcamento._id, aplicacao)
          .then(orcamento => {
            this.$store.dispatch('setOrcamento', orcamento);
            this.close();
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Falha ao atualizar orçamento! Tente novamente.');
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      },
      updateValue() {
        this.orcamento.aplicacao.value = (this.orcamento.receita *
          (this.orcamento.aplicacao.percent / 100)).toFixed(2);
        let input = document.getElementById('input-value-orcamento-aplicacao');
        if (input) {
          input.value = this.orcamento.aplicacao.value;
          input.dispatchEvent(new Event('change'));
        }
      },
      updatePercent() {
        let percent = removeMoneyMask(this.orcamento.aplicacao.value) / this.orcamento.receita;
        if (percent !== this.orcamento.aplicacao.percent)
          this.orcamento.aplicacao.percent = parseFloat((percent * 100).toFixed(2));
      }
    },
    created() {
      this.$bus.$on('showDialogFormAplicacaoOrcamento', orcamento => {
        if (orcamento) {
          this.orcamento = JSON.parse(JSON.stringify(orcamento));
          this.orcamento.aplicacao.percent = this.orcamento.aplicacao.percent.toFixed(2);
          this.orcamento.aplicacao.value = this.orcamento.aplicacao.value.toFixed(2);
          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
  .dialog-card-title {
    background-color: #131313 !important;
  }
</style>
