<template>
  <v-card color="grey3" height="100%">
    <v-card-text class="d-flex align-center">
      <div class="card-avatar hidden-xs-only">
        <v-avatar color="transparent">
          <v-icon color="primary">{{ icon }}</v-icon>
        </v-avatar>
      </div>

      <div class="d-flex flex-column align-start pl-2">
        <span class="total-number">{{ total }}</span>
        <span class="total-number-desc primary--text">Fatura de {{ invoicePeriod }}</span>
      </div>

      <v-btn
        icon
        large
        color="purple"
        class="ml-auto"
        @click="showDetails"
      >
        <v-icon large>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-text>

    <v-progress-linear
      v-show="loader"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <DialogCompanyFutureInvoiceDetails/>
  </v-card>
</template>

<script>
import getCompanyFutureInvoice from '@/api/companies/getCompanyFutureInvoice';
import numberToCurrency from '@/utils/numberToCurrency';
import DialogCompanyFutureInvoiceDetails from '@/components/Company/dialogs/DialogCompanyFutureInvoiceDetails';

export default {
  name: 'CardFutureInvoice',
  components: { DialogCompanyFutureInvoiceDetails },
  props: ['icon', 'companyId'],
  data: () => ({
    total: 0,
    loader: true,
    invoice: null
  }),
  computed: {
    invoicePeriod() {
      return this.$date().add(1, 'month').format('MMM/YYYY')
    }
  },
  methods: {
    getTotal() {
      getCompanyFutureInvoice(this.companyId)
        .then(resp => {
          this.invoice = resp
          this.total = numberToCurrency(this.invoice.invoice.value)
          this.loader = false
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }

          this.loader = false
          this.total = numberToCurrency(0)
        })
    },
    showDetails() {
      this.$bus.$emit('showDialogCompanyFutureInvoiceDetails', this.invoice)
    }
  },
  mounted() {
    this.getTotal()
  }
}
</script>

<style scoped>
.total-number {
  font-size: 15px;
  font-weight: bold;
}

.total-number-desc {
  font-size: 9px;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 375px) {
  .total-number {
    font-size: 15px;
  }
}

@media only screen and (min-width: 414px) {
  .total-number {
    font-size: 18px;
  }

  .total-number-desc {
    font-size: 10px;
  }
}
</style>
