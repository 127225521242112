<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="768px"
    :fullscreen="isMobile"
  >
    <v-card v-if="transaction" color="grey3">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          class="mr-2"
          :disabled="loader"
          @click="closeDialog"
          :color="dialogProps.color"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span :class="`${dialogProps.color}--text`">Revisar {{ dialogProps.name }}</span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <FormTransaction
            :type="type"
            v-if="transaction"
            ref="formTransaction"
            :transaction="transaction"
          />

          <v-flex xs12>
            <span class="input-label primary--text">Tipo</span>
            <v-select
              dense
              color="grey1"
              class="mt-0 pt-0"
              :items="transactionTypes"
              item-value="value"
              item-text="name"
              v-model="type"
            ></v-select>
          </v-flex>

          <v-expand-transition>
            <FormPendencia
              :type="type"
              :dialog="dialog"
              ref="formPendencia"
              :transaction="transaction"
              v-if="['saida', 'entrada'].includes(type)"
            />
          </v-expand-transition>

          <v-expand-transition>
            <FormPgtoFatura
              ref="formPgtoFatura"
              v-if="type === 'pagamento-fatura'"
            />
          </v-expand-transition>

          <v-expand-transition>
            <FormTransferencia
              :type="transaction.type"
              :origin-value="transaction.value"
              ref="formTransferencia"
              v-if="type === 'transferencia'"
              :bank-account="transaction.bankAccount._id"
              :period="transaction.date.slice(0, 7)"
              :origin-bank-account="transaction.transferOriginBankAccount"
              :destiny-bank-account="transaction.transferDestinyBankAccount"
            />
          </v-expand-transition>

          <v-flex xs12>
            <span class="input-label primary--text">Observação</span>
            <v-text-field
              dense
              color="grey1"
              class="mt-0 pt-0"
              v-model="transaction.obs"
              :error-messages="obsErrors"
            ></v-text-field>
          </v-flex>

          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              large
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              class="px-10 darkgrey--text text-capitalize"
            >
              Salvar
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FormTransaction from "@/components/Customer/Cashflow/forms/FormTransaction";
import FormPendencia from "@/components/Customer/Cashflow/forms/FormPendencia";
import stringCapitalize from "@/utils/stringCapitalize";
import randomHexcolor from "random-hex-color";
import ObjectID from "bson-objectid";
import saveCategoryUser from "@/api/categoriesUser/saveCategoryUser";
import saveTransaction from "@/api/transactions/saveTransaction";
import removeMoneyMask from "@/utils/removeMoneyMask";
import FormPgtoFatura from "@/components/Customer/Cashflow/forms/FormPgtoFatura";
import payInvoice from "@/api/invoices/payInvoice";
import FormTransferencia from "@/components/Customer/Cashflow/forms/FormTransferencia";
import undoTransfer from "@/api/transactions/undoTransfer";

export default {
  name: 'DialogFormPendencia',
  components: {FormTransferencia, FormPgtoFatura, FormPendencia, FormTransaction},
  data: () => ({
    dialog: false,
    loader: false,
    type: null,
    transaction: null
  }),
  validations: {
    transaction: {
      obs: {
        isValid(v) {
          return !v || v.trim().length <= 150
        }
      }
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isIOS() {
      return this.$store.getters.isIOS
    },
    dialogProps() {
      if (this.transaction) {
        if (this.transaction.type === 'saida') {
          return {color: 'error', name: 'Saída'}
        } else if (this.transaction.type === 'entrada') {
          return {color: 'success', name: 'Entrada'}
        }
      }

      return {color: '', name: ''}
    },
    currentType() {
      if (this.$refs.formTransaction) {
        return this.$refs.formTransaction.type
      }

      return null
    },
    transactionTypes() {
      if (this.transaction) {
        if (this.transaction.type === 'entrada') {
          return [
            {
              name: 'Receita',
              value: 'entrada'
            },
            {
              name: 'Transferência entre contas',
              value: 'transferencia'
            }
          ]
        } else {
          return [
            {
              name: 'Despesa',
              value: 'saida'
            },
            {
              name: 'Investimento',
              value: 'investimento'
            },
            {
              name: 'Pagamento de fatura',
              value: 'pagamento-fatura'
            },
            {
              name: 'Transferência entre contas',
              value: 'transferencia'
            }
          ]
        }
      }

      return []
    },
    transactions() {
      return this.$store.getters.pendencias
    },
    categories() {
      return this.$store.getters.categories
    },
    obsErrors() {
      if (this.transaction) {
        if (!this.$v.transaction.obs.$anyDirty)
          return []
        if (this.$v.transaction.obs.$anyError)
          return ['Preencha uma observação válida']
      }

      return []
    }
  },
  methods: {
    openDialog(transaction) {
      if (transaction.isTransfer && !transaction.bankAccount.pluggy) {
        let transactionId = transaction.transferOriginTransaction

        if (!transactionId)
          transactionId = transaction.transferDestinyTransaction

        const originTransaction = this.transactions.find(tr => tr._id === transactionId)

        this.transaction = JSON.parse(JSON.stringify(originTransaction))
      } else {
        this.transaction = JSON.parse(JSON.stringify(transaction))
      }

      this.transaction.value = transaction.value.toFixed(2)

      this.setTransactionType()

      if (this.transaction.category) {
        this.transaction.category = this.transaction.category._id
      }

      if (this.transaction.subCategory) {
        this.transaction.subCategory = this.transaction.subCategory._id
      }

      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.transaction = null

      this.$v.$reset()
    },
    setTransactionType() {
      if (this.transaction) {
        const {type, isTransfer, investment, isInvoicePayment} = this.transaction

        if (type === 'entrada') {
          this.type = isTransfer ? 'transferencia' : 'entrada'
        } else if (investment) {
          this.type = 'investimento'
        } else if (isInvoicePayment) {
          this.type = 'pagamento-fatura'
        } else if (isTransfer) {
          this.type = 'transferencia'
        } else {
          this.type = 'saida'
        }
      }
    },
    setupFormTransferencia() {
      if (this.type === 'transferencia') {
        const {
          type,
          transferOriginBankAccount,
          transferOriginTransaction,
          transferDestinyBankAccount,
          transferDestinyTransaction
        } = this.transaction

        if (type === 'entrada') {
          this.$refs.formTransferencia.setBankAccount(transferOriginBankAccount)

          if (this.$refs.formTransferencia.selectedBankAccountData &&
            this.$refs.formTransferencia.selectedBankAccountData.pluggy) {
            this.$refs.formTransferencia.setTransaction(transferOriginTransaction)
          }
        } else {
          this.$refs.formTransferencia.setBankAccount(transferDestinyBankAccount)

          if (this.$refs.formTransferencia.selectedBankAccountData &&
            this.$refs.formTransferencia.selectedBankAccountData.pluggy) {
            this.$refs.formTransferencia.setTransaction(transferDestinyTransaction)
          }
        }
      }
    },
    async saveCategory(newCategoryName, newSubCategoryName) {
      let categoryData = null
      let subCategoryData = null

      if (['saida', 'entrada'].includes(this.type)) {
        const {category, categoryName, subCategory, subCategoryName} = this.$refs.formPendencia

        if (category === 'outro') {
          categoryData = {
            subs: [],
            _id: ObjectID().str,
            color: randomHexcolor(),
            icon: 'mdi-dots-horizontal',
            user: this.transaction.user,
            name: stringCapitalize(categoryName),
            type: this.type === 'entrada' ? 'receita' : 'despesa'
          }
        } else {
          categoryData = this.categories.find(cat => cat._id === category)
        }

        if (subCategory === 'outro') {
          subCategoryData = {
            _id: ObjectID().str,
            category: categoryData._id,
            user: this.transaction.user,
            name: stringCapitalize(subCategoryName)
          }

          categoryData.subs.push(subCategoryData)
        }
      } else if (this.type === 'investimento') {
        categoryData = {
          subs: [],
          _id: ObjectID().str,
          color: '#00E396',
          icon: 'mdi-chart-line-variant',
          user: this.transaction.user,
          name: 'Investimentos',
          type: 'investment'
        }

        subCategoryData = {
          _id: ObjectID().str,
          category: categoryData._id,
          user: this.transaction.user,
          name: 'Investimento'
        }

        categoryData.subs.push(subCategoryData)
      } else if (this.type === 'transferencia') {
        const category = this.categories.find(cat => cat.type === 'transfer')

        if (category) {
          categoryData = JSON.parse(JSON.stringify(category))

          subCategoryData = categoryData.subs.find(sub => {
            return sub.name === newSubCategoryName
          })
        } else {
          categoryData = {
            subs: [],
            _id: ObjectID().str,
            color: '#008FFB',
            icon: 'mdi-bank-transfer',
            user: this.transaction.user,
            name: 'Transferência',
            type: 'transfer'
          }
        }

        if (!subCategoryData) {
          subCategoryData = {
            _id: ObjectID().str,
            category: categoryData._id,
            name: newSubCategoryName,
            user: this.transaction.user
          }

          categoryData.subs.push(subCategoryData)
        }
      }

      if (categoryData) {
        const savedCategory = await saveCategoryUser(categoryData)

        await this.$store.dispatch('saveCategory', savedCategory)

        return {
          category: categoryData._id,
          subCategory: subCategoryData._id
        }
      }
    },
    async submit() {
      try {
        this.$v.$touch()

        let hasError = this.$v.$anyError

        const {
          formPendencia,
          formTransaction,
          formPgtoFatura,
          formTransferencia
        } = this.$refs

        const transactionId = this.transaction._id

        if (['entrada', 'saida'].includes(this.type)) {
          formPendencia.$v.$touch()
          formTransaction.$v.$touch()

          if (!hasError) {
            hasError = formPendencia.$v.$anyError || formTransaction.$v.$anyError
          }

          if (!hasError) {
            await this.saveTransaction()
          }
        } else if (this.type === 'investimento' && !hasError) {
          await this.saveInvestment()
        } else if (this.type === 'pagamento-fatura') {
          formPgtoFatura.$v.$touch()
          hasError = formPgtoFatura.$v.$anyError

          if (!hasError) {
            await this.savePgtoFatura()
          }
        } else if (this.type === 'transferencia') {
          formTransferencia.$v.$touch()
          hasError = formTransferencia.$v.$anyError

          if (!hasError) {
            await this.saveTransfer()
          }
        }

        if (!hasError) {
          this.$noty.success('Alterações realizadas com sucesso!')
          this.closeDialog()
          this.$bus.$emit('updateListPendencias')
          this.$store.dispatch('removePendencia', transactionId)
        }
      } catch (err) {
        this.$noty.error('Desculpe! Não conseguimos salvar as alterações realizadas. Tente novamente mais tarde!')
        this.loader = false

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.loader = false
      }
    },
    async saveTransaction() {
      let transaction = null

      if (!this.transaction.bankAccount.pluggy && !this.transaction.ofx && this.transaction.pluggy) {
        transaction = this.transactions.find(tr => {
          return (tr._id !== this.transaction._id &&
            tr.pluggy.transaction === this.transaction.pluggy.transaction)
        })

        if (!transaction) {
          throw new Error('Invalid transaction')
        }

        transaction = JSON.parse(JSON.stringify(transaction))
      } else {
        transaction = JSON.parse(JSON.stringify(this.transaction))
      }

      const {formPendencia} = this.$refs

      this.loader = true

      if (this.transaction.transferId) {
        await this.undoTransfer(this.transaction.transferId)
      }

      transaction.isRevised = true
      transaction.transferId = null
      transaction.isTransfer = false
      transaction.investment = false
      transaction.isInvoiceRefund = false
      transaction.isInvoicePayment = false
      transaction.transferOriginBankAccount = null
      transaction.transferOriginTransaction = null
      transaction.transferDestinyBankAccount = null
      transaction.transferDestinyTransaction = null
      transaction.bankAccount = transaction.bankAccount._id
      transaction.value = removeMoneyMask(transaction.value)

      if (transaction.obs) {
        transaction.obs = transaction.obs.trim()
      } else {
        transaction.obs = null
      }

      if ([formPendencia.category, formPendencia.subCategory].includes('outro')) {
        const {category, subCategory} = await this.saveCategory()

        transaction.category = category
        transaction.subCategory = subCategory
      } else {
        transaction.category = formPendencia.category
        transaction.subCategory = formPendencia.subCategory
      }

      const {bankAccount, transaction: savedTransaction} = await saveTransaction(transaction)

      await Promise.all([
        this.$store.dispatch('saveBankAccount', bankAccount),
        this.$store.dispatch('saveTransaction', savedTransaction)
      ])
    },
    async saveInvestment() {
      this.loader = true

      const transaction = JSON.parse(JSON.stringify(this.transaction))

      transaction.isRevised = true
      transaction.transferId = null
      transaction.isTransfer = false
      transaction.investment = true
      transaction.isInvoiceRefund = false
      transaction.isInvoicePayment = false
      transaction.transferOriginBankAccount = null
      transaction.transferOriginTransaction = null
      transaction.transferDestinyBankAccount = null
      transaction.transferDestinyTransaction = null
      transaction.bankAccount = transaction.bankAccount._id
      transaction.value = removeMoneyMask(transaction.value)

      let category = this.categories.find(cat => cat.type === 'investment')

      if (!category) {
        let {category: categoryId, subCategory} = await this.saveCategory()

        transaction.category = categoryId
        transaction.subCategory = subCategory
      } else {
        transaction.category = category._id
        transaction.subCategory = category.subs[0]._id
      }

      if (transaction.obs) {
        transaction.obs = transaction.obs.trim()
      } else {
        transaction.obs = null
      }

      const {bankAccount, transaction: savedTransaction} = await saveTransaction(transaction)

      await Promise.all([
        this.$store.dispatch('saveBankAccount', bankAccount),
        this.$store.dispatch('saveTransaction', savedTransaction)
      ])

      if (this.transaction.transferId) {
        await this.undoTransfer(this.transaction.transferId)
      }
    },
    async savePgtoFatura() {
      this.loader = true

      const formPgtoFatura = this.$refs.formPgtoFatura

      const data = {
        date: this.transaction.date,
        value: removeMoneyMask(this.transaction.value),
        transaction: this.transaction._id,
        bankAccount: this.transaction.bankAccount._id
      }

      const {
        bankAccount,
        creditCard,
        invoice,
        transaction,
        transactions
      } = await payInvoice(formPgtoFatura.invoiceId, data)

      transactions.forEach(tr => {
        this.$store.dispatch('saveTransaction', tr)
      })

      await Promise.all([
        this.$store.dispatch('saveInvoice', invoice),
        this.$store.dispatch('saveCreditCard', creditCard),
        this.$store.dispatch('saveBankAccount', bankAccount),
        this.$store.dispatch('saveTransaction', transaction)
      ])

      if (this.transaction.transferId) {
        await this.undoTransfer(this.transaction.transferId)
      }
    },
    async saveTransfer() {
      this.loader = true

      if (this.transaction.transferId) {
        await this.undoTransfer(this.transaction.transferId)
      }

      const transaction = JSON.parse(JSON.stringify(this.transaction))
      const {selectedBankAccountData, selectedTransactionData} = this.$refs.formTransferencia

      let subCategoryName = ''

      if (transaction.type === 'entrada') {
        subCategoryName = `De ${selectedBankAccountData.name} p/ ${transaction.bankAccount.name}`
      } else {
        subCategoryName = `De ${transaction.bankAccount.name} p/ ${selectedBankAccountData.name}`
      }

      const {category, subCategory} = await this.saveCategory(null, subCategoryName)


      transaction.category = category
      transaction.subCategory = subCategory

      transaction.isRevised = true
      transaction.isTransfer = true
      transaction.investment = false
      transaction.isInvoiceRefund = false
      transaction.isInvoicePayment = false
      transaction.bankAccount = transaction.bankAccount._id
      transaction.value = removeMoneyMask(transaction.value)

      transaction.transferId = ObjectID().str

      if (transaction.obs) {
        transaction.obs = transaction.obs.trim()
      } else {
        transaction.obs = null
      }

      const promises = []

      if (selectedBankAccountData.pluggy) {
        if (transaction.type === 'entrada') {
          transaction.transferOriginTransaction = selectedTransactionData._id
          transaction.transferOriginBankAccount = selectedBankAccountData._id

          selectedTransactionData.transferDestinyTransaction = transaction._id
          selectedTransactionData.transferDestinyBankAccount = transaction.bankAccount
        } else {
          transaction.transferDestinyTransaction = selectedTransactionData._id
          transaction.transferDestinyBankAccount = selectedBankAccountData._id

          selectedTransactionData.transferOriginTransaction = transaction._id
          selectedTransactionData.transferOriginBankAccount = transaction.bankAccount
        }

        promises.push(saveTransaction(transaction))

        selectedTransactionData.isRevised = true
        selectedTransactionData.isTransfer = true
        selectedTransactionData.investment = false
        selectedTransactionData.obs = transaction.obs
        selectedTransactionData.isInvoiceRefund = false
        selectedTransactionData.isInvoicePayment = false
        selectedTransactionData.category = transaction.category
        selectedTransactionData.transferId = transaction.transferId
        selectedTransactionData.subCategory = transaction.subCategory
        selectedTransactionData.bankAccount = selectedTransactionData.bankAccount._id

        promises.push(saveTransaction(selectedTransactionData))
      } else {
        let newTransaction = JSON.parse(JSON.stringify(transaction))

        delete newTransaction._id
        delete newTransaction.createdAt
        delete newTransaction.updatedAt
        delete newTransaction.description

        newTransaction.bankAccount = selectedBankAccountData._id
        newTransaction.type = transaction.type === 'entrada' ? 'saida' : 'entrada'

        const {transaction: savedTransaction} = await saveTransaction(newTransaction)

        newTransaction._id = savedTransaction._id

        if (newTransaction.type === 'entrada') {
          newTransaction.transferOriginTransaction = transaction._id
          newTransaction.transferOriginBankAccount = transaction.bankAccount

          transaction.transferDestinyTransaction = savedTransaction._id
          transaction.transferDestinyBankAccount = newTransaction.bankAccount

          newTransaction.transferDestinyTransaction = null
          newTransaction.transferDestinyBankAccount = null
        } else {
          newTransaction.transferDestinyTransaction = transaction._id
          newTransaction.transferDestinyBankAccount = transaction.bankAccount

          transaction.transferOriginTransaction = savedTransaction._id
          transaction.transferOriginBankAccount = newTransaction.bankAccount

          newTransaction.transferOriginTransaction = null
          newTransaction.transferOriginBankAccount = null
        }

        promises.push(saveTransaction(transaction))
        promises.push(saveTransaction(newTransaction))
      }

      const results = await Promise.all(promises)

      for (let i = 0; i < results.length; i++) {
        await this.$store.dispatch('saveBankAccount', results[i].bankAccount)
        await this.$store.dispatch('saveTransaction', results[i].transaction)
      }
    },
    async undoTransfer(transferId) {
      const {bankAccounts, transactions, deletedTransactions} = await undoTransfer(transferId)

      for (let i = 0; i < bankAccounts.length; i++) {
        await this.$store.dispatch('saveBankAccount', bankAccounts[i])
      }

      for (let i = 0; i < transactions.length; i++) {
        await this.$store.dispatch('saveTransaction', transactions[i])
      }

      for (let i = 0; i < deletedTransactions.length; i++) {
        await this.$store.dispatch('removeTransaction', {_id: deletedTransactions[i]})
      }
    }
  },
  watch: {
    type(val) {
      setTimeout(this.setupFormTransferencia, 500)
    }
  },
  created() {
    this.$bus.$on('showDialogFormPendencia', this.openDialog)
  }
}
</script>
