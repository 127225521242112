<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="isMobile"
    max-width="600px"
  >
    <v-card color="grey3" :class="isMobile ? 'no-radius' : ''">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="primary"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text ml-4">
          {{ plan._id ? 'Editar' : 'Novo' }} plano de ação
        </span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-container fluid grid-list-xs>
            <v-layout row>
              <v-flex xs12>
                <span class="input-label primary--text">Título</span>
                <v-text-field
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="plan.description"
                  :error-messages="descriptionErrors"
                >
                </v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Descrição</span>
                <v-textarea
                  rows="2"
                  outlined
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="plan.observations"
                  :error-messages="observationErrors"
                >
                </v-textarea>
              </v-flex>

              <v-flex xs12 sm6>
                <span class="input-label primary--text">Data de criação</span>
                <v-dialog
                  ref="dialog"
                  v-model="dateMenu"
                  :return-value.sync="plan.date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 pt-0"
                      :value="formatDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="dateErrors"
                    >
                      <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                        mdi-calendar
                      </v-icon>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    color="primary"
                    v-model="plan.date"
                    scrollable
                    locale="pt-br"
                    :max="today"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey2" @click="dateMenu = false">
                      Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="$refs.dialog.save(plan.date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12 sm6>
                <span class="input-label primary--text">Prazo de execução</span>
                <v-dialog
                  ref="dialogDeadline"
                  v-model="dateMenuDeadline"
                  :return-value.sync="plan.deadline"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 pt-0"
                      :value="formatDeadline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="deadlineErrors"
                    >
                      <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                        mdi-calendar
                      </v-icon>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    color="primary"
                    v-model="plan.deadline"
                    scrollable
                    locale="pt-br"
                    :min="today"
                  >
                    <v-spacer></v-spacer>

                    <v-btn color="grey2" @click="dateMenuDeadline = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.dialogDeadline.save(plan.deadline)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Tipo</span>
                <v-select
                  :items="types"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="plan.type"
                  return-object
                  item-text="name"
                  :error-messages="typeErrors"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-flag-outline
                  </v-icon>

                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color">
                      mdi-flag-outline
                    </v-icon>
                    <span>{{ item.name }}</span>
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Responsáveis</span>
                <v-select
                  multiple
                  color="grey1"
                  class="mt-0 pt-0"
                  :items="responsibles"
                  v-model="plan.responsibles"
                  :error-messages="responsiblesErrors"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-account-group-outline
                  </v-icon>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined color="primary">
                      <span>{{ item }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="px-0">
            <v-row >
              <v-btn
                text
                rounded
                class="px-5"
                type="button"
                @click="close"
                :disabled="loader"
              >
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                type="submit"
                color="primary"
                class="darkgrey--text px-5"
                :loading="loader"
              >
                Salvar
              </v-btn>
            </v-row>
          </v-card-actions>

        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import getResponsibles from '@/api/actionPlans/getResponsibles';
import saveActionPlan from '@/api/actionPlans/saveActionPlan';

export default {
  name: 'DialogFormPlanoAcao',
  data: () => ({
    dialog: false,
    loader: false,
    dateMenu: false,
    dateMenuDeadline: false,
    datePattern: 'YYYY-MM-DD',
    dateRegex: /ˆ\d{2}\/\d{2}\/\d{4}$/gm,
    plan: {
      date: '',
      description: '',
      responsibles: [],
      deadline: '',
      observations: '',
      type: null,
      completed: false
    },
    types: [
      { name: 'Urgente', value: 1, color: 'error' },
      { name: 'Prioridade', value: 2, color: 'warning' },
      { name: 'Necessário', value: 3, color: 'success' }
    ],
    responsibles: [],
    today: ''
  }),
  validations: {
    plan: {
      date: {
        isValid: (v) => !!v && /\d{4}-\d{2}-\d{2}/.test(v)
      },
      description: {
        isValid(v) {
          return !!v && v.length <= 80;
        }
      },
      deadline: {
        isValid: (v) => !!v && /\d{4}-\d{2}-\d{2}/.test(v)
      },
      observations: {
        isValid(v) {
          return !v || v.length <= 150;
        }
      },
      type: { required },
      responsibles: {
        isValid(v) {
          return v.length >= 1;
        }
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === 'customer'
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    formatDate() {
      if (this.plan.date) {
        return this.plan.date.split('-').reverse().join('/');
      }
      return '';
    },
    formatDeadline() {
      return this.plan.deadline.split('-').reverse().join('/');
    },
    dateErrors() {
      const errors = [];

      if (!this.$v.plan.date.$dirty) {
        return errors;
      }

      !this.$v.plan.date.isValid && errors.push('Selecione uma data válida');

      return errors;
    },
    deadlineErrors() {
      const errors = [];

      if (!this.$v.plan.deadline.$dirty) {
        return errors;
      }

      !this.$v.plan.deadline.isValid &&
      errors.push('Selecione uma data válida');

      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.plan.description.$dirty) {
        return errors;
      }

      !this.$v.plan.description.isValid &&
      errors.push('Título muito longo!');

      return errors;
    },
    typeErrors() {
      const errors = [];

      if (!this.$v.plan.type.$dirty) {
        return errors;
      }

      !this.$v.plan.type.required && errors.push('Selecione um tipo válido');

      return errors;
    },
    observationErrors() {
      const errors = [];

      if (!this.$v.plan.observations.$dirty) {
        return errors;
      }

      !this.$v.plan.observations.isValid &&
      errors.push('Não ultrapasse 150 caracteres');

      return errors;
    },
    responsiblesErrors() {
      const errors = [];

      if (!this.$v.plan.responsibles.$dirty) {
        return errors;
      }

      !this.$v.plan.responsibles.isValid &&
      errors.push('Selecione um responsável');

      return errors;
    }
  },
  methods: {
    sanitizeDate(date) {
      date = this.$date(date).add(3, 'hour');

      if (!date.isValid()) {
        date = this.$date();
      }

      return date.format(this.datePattern);
    },
    getResponsibles() {
      if (!this.responsibles.length) {
        getResponsibles(this.user._id)
          .then((responsibles) => {
            this.responsibles = responsibles;
          })
          .catch((err) => {
            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    close() {
      this.dialog = false;
      this.loader = false;
      this.plan = {
        date: '',
        description: '',
        responsibles: [],
        deadline: '',
        observations: '',
        type: null,
        completed: false
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        const plan = JSON.parse(JSON.stringify(this.plan));

        plan.user = this.user._id;
        plan.planner =
          this.user.planner && this.user.planner._id
            ? this.user.planner._id
            : this.user.planner;

        saveActionPlan(plan)
          .then((savedPlan) => {
            this.close();
            this.$store.dispatch('saveActionPlan', savedPlan);
            this.$noty.success('Plano salvo com sucesso!');
          })
          .catch((err) => {
            this.loader = false;
            this.$error(
              'Desculpe! Não foi possível salvar este plano. Tente novamente mais tarde!'
            );

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormPlanoAcao', (plan) => {
      if (plan) {
        this.plan = JSON.parse(JSON.stringify(plan));
        this.plan.date = this.sanitizeDate(this.plan.date);
        this.plan.deadline = this.sanitizeDate(this.plan.deadline);
      } else {
        this.plan.date = this.sanitizeDate();
        this.plan.deadline = this.sanitizeDate();
      }

      this.getResponsibles();
      this.dialog = true;
    });

    this.today = this.$date().format(this.datePattern);
  }
};
</script>
