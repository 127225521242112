<template>
  <div class="chips-container">
    <v-chip
      :key="i"
      class="legend-item mr-1"
      @click="filter(item.value)"
      v-for="(item, i) in legends"
      :color="type !== item.value ? 'transparent' : ''"
    >
      <v-avatar
        size="29"
        class="mr-2"
        color="darkgrey"
      >
        <v-icon
          small
          :color="item.color"
        >
          mdi-flag-outline
        </v-icon>
      </v-avatar>

      <span class="white--text legend-item-text">{{ item.text }}</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'ActionPlansFilter',
  data: () => ({
    type: '',
    legends: [
      { text: 'Urgente', color: 'error', value: 1 },
      { text: 'Prioridade', color: 'warning', value: 2 },
      { text: 'Necessário', color: 'success', value: 3 }
    ]
  }),
  methods: {
    filter(value) {
      if (this.type !== value) {
        this.type = value;
      } else {
        this.type = '';
      }

      this.$bus.$emit('filterActionPlans', this.type);
    }
  }
}
</script>

<style scoped>
.legend-item-text {
  font-size: 9px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.legend-item {
  margin-right: 10px;
}

.chips-container {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 960px) {
  .chips-container {
    justify-content: left;
  }


  .legend-item {
    margin-bottom: 18px;
    margin-right: 20px;
  }

  .legend-item-text {
    font-size: 12px;
  }
}
</style>
