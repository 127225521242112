import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const saveCreditCard = data => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'POST';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = '/reward-requests';
        return httpClient(url, { method, headers, data });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default saveCreditCard;
