<template>
  <v-main class="margin-menu">
    <OverlaySync :overlay="loader" />

    <MobilePageTitle title="Orçamento" :loader="loader" />

    <div class="month-selector dark-bg">
      <v-btn
        icon
        color="grey1"
        class="btn-month"
        @click.stop="changePeriod('back')"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <span class="month-name">{{ monthStr }}</span>

      <v-btn
        icon
        color="grey1"
        class="btn-month"
        @click.stop="changePeriod('next')"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <v-container grid-list-md class="pt-0 pb-10 mb-10" v-if="orcamento">
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat color="grey3">
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md6 lg3>
                  <v-card color="grey2" raised @click="showDialogReceita">
                    <v-card-text class="budget-card">
                      <v-icon color="primary" class="budget-card-icon"
                        >mdi-cash-100</v-icon
                      >
                      <span class="budget-card-desc">
                        <span class="budget-card-desc--text primary--text"
                          >Receita</span
                        >
                        <span class="budget-card-desc--value">
                          <span>R$</span>
                          {{ currency(orcamento.parametros.receita.value) }}
                        </span>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs12 md6 lg3>
                  <v-card color="grey2" raised>
                    <v-card-text class="d-flex align-center justify-space-between">
                      <div class="budget-card">
                        <v-icon
                          color="primary"
                          class="budget-card-icon"
                        >
                          mdi-cash
                        </v-icon>
                        <span class="budget-card-desc">
                          <span class="budget-card-desc--text primary--text">
                            Reembolsos
                          </span>
                          <span class="budget-card-desc--value">
                            <span>R$</span>
                            {{ currency(orcamento.parametros.refund) }}
                          </span>
                        </span>
                      </div>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on="on"
                            color="grey1"
                            v-bind="attrs"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>Reembolsos de cartão de crédito</span>
                      </v-tooltip>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs12 md6 lg3>
                  <v-card
                    color="grey2"
                    raised
                    @click="showDialogFormAplicacaoOrcamento"
                  >
                    <v-card-text class="budget-card">
                      <v-icon color="primary" class="budget-card-icon"
                        >mdi-chart-arc</v-icon
                      >
                      <span class="budget-card-desc mr-5">
                        <span class="budget-card-desc--text primary--text"
                          >Percentual</span
                        >
                        <span class="budget-card-desc--value">
                          {{
                            parseFloat(
                              orcamento.parametros.aplicacao.percent.toFixed(2)
                            )
                          }}%
                        </span>
                      </span>

                      <span class="budget-card-desc">
                        <span class="budget-card-desc--text primary--text"
                          >Aplicação mensal</span
                        >
                        <span class="budget-card-desc--value">
                          <span>R$</span>
                          {{ currency(orcamento.parametros.aplicacao.value) }}
                        </span>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs12 md6 lg3>
                  <v-card color="grey2" raised @click="showDialogFixedExpenses">
                    <v-card-text class="budget-card">
                      <v-icon color="primary" class="budget-card-icon"
                        >mdi-arrow-down</v-icon
                      >
                      <span class="budget-card-desc">
                        <span class="budget-card-desc--text primary--text"
                          >Despesas fixas</span
                        >
                        <span class="budget-card-desc--value">
                          <span>R$</span>
                          {{ currency(orcamento.parametros.despesaFixa.value) }}
                        </span>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12>
          <v-card flat color="grey3">
            <v-card-title class="primary--text teto">
              <div style="display: flex; flex-direction: column">
                <span class="teto-title">Teto orçamentário</span>
                <span class="teto-subtitle">Despesas variáveis</span>
              </div>

              <v-btn
                fab
                small
                depressed
                color="grey2"
                class="ml-auto"
                v-if="validPeriod"
                :disabled="!allowed"
                @click="showDialogFormAddItemOrcamento"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-card color="grey2" raised>
                    <v-card-text class="budget-card center">
                      <span class="budget-card-desc text-center">
                        <span class="budget-card-desc--text grey1--text">Limite disponível</span>
                        <span class="budget-card-desc--value white--text">
                          <span>R$</span>
                          {{ currency(orcamento.parametros.disponivel) }}
                        </span>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs12 md4>
                  <v-card color="grey2" raised>
                    <v-card-text class="budget-card center">
                      <span class="budget-card-desc text-center">
                        <span class="budget-card-desc--text grey1--text"
                          >Limite utilizado</span
                        >
                        <span class="budget-card-desc--value white--text">
                          <span>R$</span>
                          {{
                            currency(orcamento.parametros.despesaVariavel.value)
                          }}
                        </span>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs12 md4>
                  <v-card color="grey2" raised>
                    <v-card-text class="budget-card center">
                      <span class="budget-card-desc text-center">
                        <span class="budget-card-desc--text grey1--text"
                          >Saldo</span
                        >
                        <span class="budget-card-desc--value white--text">
                          <span>R$</span>
                          {{
                            currency(
                              orcamento.parametros.disponivel -
                                orcamento.parametros.despesaVariavel.value
                            )
                          }}
                        </span>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 :key="category" v-for="category in groupedItems">
          <v-expansion-panels>
            <PanelCategory
              :period="period"
              :category-name="category"
              :valid-period="validPeriod"
            />
          </v-expansion-panels>
        </v-flex>
      </v-layout>
    </v-container>

    <DialogDespesasItem />
    <DialogFormItemOrcamento />
    <DialogFormAplicacaoOrcamento />
    <DialogFormAddItemOrcamento />
    <DialogFixedExpenses />
    <DialogDeleteItemOrcamento />
    <DialogReceita />
    <DialogDespesaFixa />
  </v-main>
</template>

<script>
import { orderBy, groupBy } from 'lodash';
import moment from 'moment-timezone';

import OverlaySync from '../../components/Global/OverlaySync';

import currency from '../../utils/currency';
import numberToCurrency from '../../utils/numberToCurrency';
import getOrcamentoByUserId from '../../api/orcamento/getOrcamentoByUserId';
import DialogFormItemOrcamento from '../../components/Customer/Orcamento/dialogs/DialogFormItemOrcamento';
import DialogDespesasItem from '../../components/Customer/Orcamento/dialogs/DialogDespesasItem';
import getCustomerData from '../../utils/getCustomerData';
import DialogFormAplicacaoOrcamento from '../../components/Customer/Orcamento/dialogs/DialogFormAplicacaoOrcamento';
import DialogFormAddItemOrcamento from '../../components/Customer/Orcamento/dialogs/DialogFormAddItemOrcamento';
import DialogFixedExpenses from '../../components/Customer/Orcamento/dialogs/DialogFixedExpenses';
import DialogDeleteItemOrcamento from '../../components/Customer/Orcamento/dialogs/DialogDeleteItemOrcamento';
import DialogReceita from '../../components/Customer/Orcamento/dialogs/DialogReceita';
import DialogDespesaFixa from '../../components/Customer/Orcamento/dialogs/DialogDespesaFixa';
import MobilePageTitle from '../../components/Global/MobilePageTitle';
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';
import PanelCategory from '@/components/Customer/Orcamento/expansion-panels/PanelCategory';

export default {
  name: 'Orcamento',
  // metaInfo() {
  //   return {
  //     title: 'Orcamento',
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo);
  //     },
  //   };
  // },
  components: {
    PanelCategory,
    MobilePageTitle,
    DialogDespesaFixa,
    DialogReceita,
    DialogDeleteItemOrcamento,
    DialogFixedExpenses,
    DialogFormAddItemOrcamento,
    DialogFormAplicacaoOrcamento,
    DialogDespesasItem,
    DialogFormItemOrcamento,
    OverlaySync,
  },
  data: () => ({
    timezone: 'America/Sao_Paulo',
    periodPattern: 'YYYY-MM',
    loader: false,
    period: '',
    currentDate: null,
    today: 1,
    year: null,
    monthStr: '',
    currentPeriod: '',
    startPeriod: '',
    currency,
    numberToCurrency,
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    orcamento() {
      return this.$store.getters.orcamento;
    },
    totalUtilizado() {
      let total = 0;

      if (this.orcamento) {
        this.orcamento.items.enabled.forEach((item) => {
          total += item.limite;
        });
      }

      return total;
    },
    totalDisponivel() {
      if (this.orcamento)
        return this.orcamento.parametros.disponivel - this.totalUtilizado;

      return 0;
    },
    items() {
      return this.orcamento.items.enabled.map(item => {
        if (!item.category) {
          item.category = {name: 'Não categorizada'}
        }

        if (!item.subCategory) {
          item.subCategory = {name: 'Não categorizada'}
        }

        return item
      });
    },
    groupedItems() {
      return orderBy(
        Object.keys(groupBy(this.items, item => item.category.name)).filter((k) => !!k),
        (k) => k
      );
    },
    limiteConfigurado() {
      let total = 0;

      if (this.items.length) {
        total = this.items.reduce((a, b) => {
          return { limite: a.limite + b.limite };
        }).limite;
      }

      return total;
    },
    validPeriod() {
      let periodDate = moment(this.period + '-01');
      let currentDate = moment(this.currentPeriod + '-01');

      return (
        periodDate.isAfter(currentDate, 'year') ||
        (periodDate.isSame(currentDate, 'year') &&
          periodDate.isSameOrAfter(currentDate, 'month'))
      );
    },
    userPeriod() {
      return moment(this.user.createdAt)
        .tz('America/Sao_Paulo')
        .format('YYYY-MM');
    },
    allowed() {
      return this.$store.getters.allowed;
    },
  },
  methods: {
    goBack() {
      window.history.back();
    },
    getOrcamento() {
      if (this.user) {
        this.loader = true;

        getOrcamentoByUserId(this.user._id, this.period)
          .then((orcamento) => {
            this.$store.dispatch('setOrcamento', null);
            this.$store.dispatch('setOrcamento', orcamento);
            this.loader = false;
          })
          .catch((err) => {
            this.loader = false;
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    changePeriod(type) {
      if (type === 'next') this.currentDate.add(1, 'month');
      else if (type === 'back') this.currentDate.subtract(1, 'month');

      this.period = this.currentDate.format(this.periodPattern);
      this.getOrcamento();
    },
    getItemColor(item) {
      let second = item.secondWarning.percent / 100;
      let third = item.thirdWarning.percent / 100;
      let used = item.total / item.limite;

      if (used < second) return 'success';
      else if (used >= second && used < third) return 'warning';
      else return 'error';
    },
    showDialogFormItemOrcamento(item) {
      if (this.allowed) {
        this.$bus.$emit('showDialogFormItemOrcamento', {
          item,
          orcamentoId: this.orcamento._id,
        });
      }
    },
    showDialogDespesasItem(item) {
      if (item.total)
        this.$bus.$emit('showDialogDespesasItem', {
          item,
          period: this.period,
        });
    },
    showDialogFormAplicacaoOrcamento() {
      if (this.validPeriod && this.allowed) {
        this.$bus.$emit('showDialogFormAplicacaoOrcamento', {
          _id: this.orcamento._id,
          aplicacao: this.orcamento.parametros.aplicacao,
          receita: this.orcamento.parametros.receita.value,
        });
      }
    },
    showDialogFormAddItemOrcamento() {
      if (this.validPeriod && this.allowed) {
        this.$bus.$emit('showDialogFormAddItemOrcamento');
      }
    },
    showDialogFixedExpenses() {
      this.$bus.$emit('showDialogFixedExpenses', this.period);
    },
    showDialogDeleteItemOrcamento(item) {
      if (this.allowed) {
        this.$bus.$emit('showDialogDeleteItemOrcamento', {
          orcamento: this.orcamento._id,
          itemId: item._id,
        });
      }
    },
    showDialogReceita() {
      if (this.validPeriod && this.allowed) {
        this.$bus.$emit('showDialogReceita', {
          orcamento: this.orcamento._id,
          value: this.orcamento.parametros.receita.value,
          fixed: this.orcamento.parametros.receita.fixed,
        });
      }
    },
    updateOrcamento() {
      this.getOrcamento();
    },
  },
  watch: {
    period() {
      this.monthStr = this.currentDate.format('MMMM');

      if (this.year !== this.currentDate.year())
        this.monthStr += `/${this.currentDate.year()}`;
    },
    user() {
      if (this.user)
        this.startPeriod = moment(this.user.createdAt).format(
          this.periodPattern
        );
    },
  },
  beforeCreate() {
    this.$vuetify.theme.dark = true;
  },
  created() {
    this.currentDate = moment().tz(this.timezone);
    this.today = this.currentDate.date();
    this.year = this.currentDate.year();
    this.currentPeriod = this.currentDate.format(this.periodPattern);

    this.loader = true;
    getCustomerData().then(() => {
      this.startPeriod = moment(this.user.createdAt).format(this.periodPattern);
      this.changePeriod();
    });

    this.$bus.$on('updateOrcamento', () => {
      this.updateOrcamento();
    });
  },
};
</script>

<style scoped>
.v-content {
  background-color: #131313 !important;
  padding-bottom: 70px !important;
}

.container {
  max-width: 1264px;
}

.v-icon.budget-card-icon {
  font-size: 27px !important;
  margin-right: 10px;
}

.budget-card {
  display: flex !important;
  align-items: center !important;
}

.budget-card.center {
  justify-content: center;
  align-items: center;
}

.budget-card-desc {
  display: flex;
  flex-direction: column;
}

.budget-card-desc--text {
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0.1em;
}

.budget-card-desc--value {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.disponivel-desc {
  font-size: 12px;
  letter-spacing: 0.1em;
  margin-right: 5px;
}

.disponivel-value {
  color: white;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1em;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.disponivel-value span {
  font-size: 9px;
}

.v-card__title.teto {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.teto-title {
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.teto-subtitle {
  color: white;
  font-size: 10px;
  line-height: 1.5em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.item-title {
  display: flex;
  flex-direction: column;
}

.item-title--main {
  color: white;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.item-title--sub {
  font-size: 9px;
  letter-spacing: 0.1em;
}

.item-numbers,
.item-numbers-item {
  display: flex;
  flex-direction: column;
}

.item-numbers {
  justify-content: space-around;
}

.item-numbers-item {
  margin-bottom: 10px;
}

.item-numbers-item:last-child {
  margin-bottom: 0;
}

.item-numbers-item--desc {
  color: white;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1em;
}

.item-numbers-item--value {
  color: #9e9e9e;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.1em;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #2d2d2d !important;
}

@media only screen and (min-width: 600px) {
  .budget-card-desc--text {
    font-size: 12px;
    line-height: 12px;
  }

  .budget-card-desc--value {
    font-size: 15px;
  }

  .disponivel-value {
    font-size: 25px;
  }

  .teto-title {
    font-size: 15px;
    line-height: 1.5em;
  }

  .teto-subtitle {
    font-size: 12px;
    line-height: 1.5em;
  }

  .budget-card.center {
    justify-content: flex-start !important;
  }

  .budget-card-desc.text-center {
    text-align: left !important;
  }

  .item-orcamento {
    overflow-x: hidden;
  }

  .v-card__text.item-card-text {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .v-card__text.item-card-text.border-sm {
    border-left: 1px solid #919191;
  }
}

@media only screen and (min-width: 960px) {
  .item-title--main {
    font-size: 16px;
    line-height: 1.5em;
  }

  .item-title--sub {
    font-size: 12px;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1263px) {
  .v-card__text.item-card-text.border-md {
    border-right: 1px solid #919191;
  }
}

@media only screen and (min-width: 1264px) {
  .v-card__text.item-card-text.border-lg {
    border-right: 1px solid #919191;
  }
}
</style>
