import { sum } from 'lodash';
import dayjs from 'dayjs';

export default (data, startDate, endDate, future) => {
  // const mediaArray = []
  // let monthsCount = data.length
  // const currentPeriod = dayjs().format('YYYY-MM')
  //
  // if (!future) {
  //   if (!dayjs(startDate).isAfter(currentPeriod) && dayjs(endDate).isAfter(currentPeriod)) {
  //     monthsCount = dayjs(currentPeriod).diff(startDate, 'month') + 1
  //   }
  // }
  //
  // for (let i = 0; i < data.length; i++) {
  //   if (i < monthsCount) {
  //     const total = sum(data.slice(0, (i + 1)))
  //     mediaArray.push(parseFloat((total / (i + 1)).toFixed(2)))
  //   } else {
  //     mediaArray.push(0)
  //   }
  // }
  //
  // return mediaArray


  const mediaMensal = []

  for (let i = 0; i < data.length; i++) {
    const values = data.slice(0, i + 1)
    const somaTotal = sum(values)
    const media = (somaTotal / values.length)

    mediaMensal.push(media)
  }

  return mediaMensal
}
