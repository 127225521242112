<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    :fullscreen="isMobile"
    :max-width="maxWidth + 'px'"
  >
    <v-card color="grey2" :class="isMobile ? 'no-radius' : ''">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4">
          Informações gerais e <br class="hidden-md-and-up" />
          patrimônio atual
        </span>
      </v-card-title>

      <v-card-text v-if="premissas">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text">Data inicial</span>
              <v-dialog
                ref="dialog"
                v-model="dateMenu"
                :return-value.sync="dataInicial"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mt-0 pt-0"
                    :value="formatDate"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      color="grey1"
                      class="input-icon"
                      slot="prepend-inner"
                    >
                      mdi-calendar
                    </v-icon>
                  </v-text-field>
                </template>

                <v-date-picker
                  no-title
                  color="primary"
                  v-model="dataInicial"
                  scrollable
                  locale="pt-br"
                >
                  <v-spacer></v-spacer>

                  <v-btn color="grey2" @click="dateMenu = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="$refs.dialog.save(dataInicial)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text">Idade inicial</span>
              <v-text-field
                dense
                type="number"
                color="grey1"
                suffix="anos"
                v-model.number="premissas.idadeAtual"
                prepend-inner-icon="mdi-cake-variant"
                :error-messages="idadeAtualErrors"
                @input="$v.premissas.idadeAtual.$touch()"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text"
                >Idade de independência financeira</span
              >
              <v-text-field
                dense
                type="number"
                color="grey1"
                suffix="anos"
                v-model.number="premissas.idadeIndependencia"
                prepend-inner-icon="mdi-beach"
                :error-messages="idadeIndependenciaErrors"
                @input="$v.premissas.idadeIndependencia.$touch()"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text"
                >Horizonte de produção/acumulação</span
              >
              <v-text-field
                dense
                readonly
                color="grey1"
                suffix="anos"
                prepend-inner-icon="mdi-cash"
                :value="premissas.idadeIndependencia - premissas.idadeAtual"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text">Expectativa de vida</span>
              <v-text-field
                dense
                type="number"
                color="grey1"
                suffix="anos"
                v-model.number="premissas.idadeExpectativaVida"
                prepend-inner-icon="mdi-timer-sand-empty"
                :error-messages="idadeExpectativaVidaErrors"
                @input="$v.premissas.idadeExpectativaVida.$touch()"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text"
                >Patrimônio líquido atual</span
              >
              <v-text-field
                dense
                type="tel"
                color="grey1"
                v-money="money"
                id="input-pat-liquido"
                v-model="premissas.patrimonioLiquidoAtual"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            color="primary"
            @click="submit"
            :loading="loader"
            class="darkgrey--text"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money';
import moment from 'moment-timezone';
import {
  required,
  minValue,
  maxValue,
  between,
} from 'vuelidate/lib/validators';
import removeMoneyMask from '../../../../utils/removeMoneyMask';
import getUserIndFinanceira from '../../../../api/indFinanceira/getUserIndFinanceira';
import updateIndFinanceira from '../../../../api/indFinanceira/updateIndFinanceira';

export default {
  name: 'DialogFormInfosGerais',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    dateMenu: false,
    loader: false,
    premissas: null,
    currentPeriod: null,
    dataInicial: '',
    maxWidth: 718,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    },
  }),
  computed: {
    formatDate() {
      if (this.dataInicial)
        return moment(this.dataInicial).format('DD/MM/YYYY');

      return '';
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    indFinanceira() {
      return this.$store.getters.independenciaFinanceira;
    },
    perfil() {
      return this.$store.getters.perfil;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    idadeAtualErrors() {
      const errors = [];
      if (!this.$v.premissas.idadeAtual.$dirty) return errors;
      !this.$v.premissas.idadeAtual.required &&
        errors.push('Insira a idade inicial');
      !this.$v.premissas.idadeAtual.min &&
        errors.push('A idade deve ser maior do que 15 anos');
      !this.$v.premissas.idadeAtual.max &&
        errors.push('A idade deve ser menor do que 120 anos');
      return errors;
    },
    idadeIndependenciaErrors() {
      const errors = [];
      if (!this.$v.premissas.idadeIndependencia.$dirty) return errors;
      !this.$v.premissas.idadeIndependencia.required &&
        errors.push('Insira a idade de independência financeira');
      !this.$v.premissas.idadeIndependencia.min &&
        errors.push('A idade deve ser maior do que 15 anos');
      !this.$v.premissas.idadeIndependencia.max &&
        errors.push('A idade deve ser menor do que 120 anos');
      return errors;
    },
    idadeExpectativaVidaErrors() {
      const errors = [];
      if (!this.$v.premissas.idadeExpectativaVida.$dirty) return errors;
      !this.$v.premissas.idadeExpectativaVida.required &&
        errors.push('Insira a expectativa de vida');
      !this.$v.premissas.idadeExpectativaVida.min &&
        errors.push('A idade deve ser maior do que 15 anos');
      !this.$v.premissas.idadeExpectativaVida.max &&
        errors.push('A idade deve ser menor do que 120 anos');
      return errors;
    },
  },
  methods: {
    getIdadeAtual() {
      if (
        this.premissas &&
        !this.premissas.idadeAtual &&
        this.perfil &&
        this.perfil.informacoesBasicas.usuario.dataNascimento
      ) {
        let dataNascimento = this.perfil.informacoesBasicas.usuario
          .dataNascimento;
        return moment().tz('America/Sao_Paulo').diff(dataNascimento, 'years');
      } else if (this.premissas && this.premissas.idadeAtual) {
        return this.premissas.idadeAtual;
      } else {
        return 0;
      }
    },
    setMaxWidth() {
      if (window.innerWidth < 1264) this.maxWidth = 718;
      else this.maxWidth = 980;
    },
    close() {
      this.dialog = false;
      this.loader = false;

      let input = document.getElementById('input-pat-liquido');
      if (input) {
        input.value = 0;
        input.dispatchEvent(new Event('input'));
      }

      this.premissas = null;
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        let premissas = JSON.parse(JSON.stringify(this.premissas));
        let dataInicial = this.dataInicial;

        premissas.patrimonioLiquidoAtual = removeMoneyMask(
          premissas.patrimonioLiquidoAtual
        );
        premissas.anosProducao =
          premissas.idadeIndependencia - premissas.idadeAtual;

        if (
          this.indFinanceira.period.slice(0, 7) !==
          this.currentPeriod.slice(0, 7)
        ) {
          getUserIndFinanceira(this.user._id, this.currentPeriod.slice(0, 7))
            .then((indFinanceira) => {
              this.savePremissas(indFinanceira._id, premissas, dataInicial);
              this.$noty.success('Dados salvos com sucesso!');
            })
            .catch((err) => {
              this.loader = false;
              this.$noty.error(
                'Desculpe! Não foi possível atualizar seus dados. Tente novamente!'
              );
              if (process.env.NODE_ENV !== 'production') console.error(err);
            });
        } else {
          this.savePremissas(this.indFinanceira._id, premissas, dataInicial);
        }
      }
    },
    savePremissas(indFinanceiraId, premissas, dataInicial) {
      updateIndFinanceira(indFinanceiraId, { premissas, dataInicial })
        .then((indFinanceira) => {
          if (
            this.indFinanceira.period.slice(0, 7) ===
            this.currentPeriod.slice(0, 7)
          ) {
            this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
          }

          this.close();
        })
        .catch((err) => {
          this.loader = false;
          this.$noty.error(
            'Desculpe! Não foi possível atualizar seus dados. Tente novamente!'
          );
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    },
  },
  validations: {
    premissas: {
      idadeAtual: { required, min: minValue(15), max: maxValue(120) },
      idadeIndependencia: { required, min: minValue(15), max: maxValue(120) },
      idadeExpectativaVida: { required, min: minValue(15), max: maxValue(120) },
    },
  },
  created() {
    this.$bus.$on('showDialogFormInfosGerais', (premissas) => {
      if (premissas) {
        this.premissas = JSON.parse(JSON.stringify(premissas));
        if (this.premissas.patrimonioLiquidoAtual)
          this.premissas.patrimonioLiquidoAtual = parseFloat(
            this.premissas.patrimonioLiquidoAtual
          ).toFixed(2);
        this.dataInicial = this.indFinanceira.dataInicial;
      } else {
        this.premissas = {
          idadeAtual: this.getIdadeAtual(),
          idadeAposentadoria: 70,
          idadeExpectativaVida: 100,
          anosProducao: 70 - this.getIdadeAtual(),
          patrimonioLiquidoAtual: 0,
        };
      }

      if (!this.dataInicial)
        this.dataInicial = moment()
          .tz('America/Sao_Paulo')
          .format('YYYY-MM-DD');

      this.currentPeriod = moment()
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD');
      this.dialog = true;
    });
    this.setMaxWidth();
  },
};
</script>

<style scoped>
.dialog-form-title .white--text {
  line-height: 1.5em !important;
}

.input-label {
  font-size: 9px;
  letter-spacing: 0.1em;
}

@media only screen and (min-width: 600px) {
  .input-label {
    font-size: 12px;
  }
}
</style>
