<template>
  <v-container grid-list-md class="pa-0 ma-0">
    <v-layout row wrap class="pa-0 ma-0">
      <v-flex xs12>
        <span class="input-label primary--text">Descrição</span>
        <v-text-field
          dense
          readonly
          disabled
          color="grey1"
          class="mt-0 pt-0"
          id="input-value-pendencia"
          :value="transaction.description"
        ></v-text-field>
      </v-flex>

      <v-flex xs12>
        <span class="input-label primary--text">Valor</span>
        <v-text-field
          dense
          id="input-value-pendencia"
          color="grey1"
          v-money="money"
          class="mt-0 pt-0"
          v-model="transaction.value"
          :readonly="!!transaction.pluggy"
          :disabled="!!transaction.pluggy"
          :error-messages="valueErrors"
        ></v-text-field>
      </v-flex>

      <v-flex xs12>
        <span class="input-label primary--text">Data</span>
        <v-dialog
          persistent
          ref="dialog"
          width="290px"
          v-model="dateMenu"
          :return-value.sync="transaction.date"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              readonly
              v-on="on"
              dense
              v-bind="attrs"
              class="mt-0 pt-0"
              :value="formatedDate"
              :readonly="!!transaction.pluggy"
              :disabled="!!transaction.pluggy"
              :error-messages="dateErrors"
            ></v-text-field>
          </template>

          <v-date-picker
            no-title
            scrollable
            locale="pt-br"
            color="primary"
            v-model="transaction.date"
          >
            <v-spacer></v-spacer>
            <v-btn color="grey2" @click="dateMenu = false">Cancelar</v-btn>
            <v-btn color="primary" @click="$refs.dialog.save(transaction.date)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-flex>

      <v-flex xs12>
        <span class="input-label primary--text">Conta Bancária</span>
        <v-text-field
          dense
          color="grey1"
          class="mt-0 pt-0"
          readonly
          disabled
          :value="transaction.bankAccount.name"
        ></v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {VMoney} from "v-money";
import removeMoneyMask from "@/utils/removeMoneyMask";

export default {
  name: 'FormTransaction',
  props: ['type', 'transaction'],
  directives: {money: VMoney},
  data: () => ({
    dateMenu: false,
    money: {
      precision: 2,
      decimal: ',',
      prefix: 'R$ ',
      thousands: '.'
    }
  }),
  validations: {
    transaction: {
      value: {
        isValid(v) {
          return !!v && removeMoneyMask(v) > 0
        }
      },
      date: {
        isValid(v) {
          return !!v && this.$date(v).isValid()
        }
      }
    }
  },
  computed: {
    formatedDate() {
      if (this.transaction) {
        return this.$date(this.transaction.date).format('DD/MM/YYYY')
      }
      return ''
    },
    valueErrors() {
      if (!this.$v.transaction.value.$anyDirty)
        return []

      if (this.$v.transaction.value.$anyError) {
        return ['Preencha um valor válido']
      }
    },
    dateErrors() {
      if (!this.$v.transaction.date.$anyDirty)
        return []

      if (this.$v.transaction.date.$anyError) {
        return ['Preencha uma data válida']
      }
    }
  }
}
</script>
