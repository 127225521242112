import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const saveCreditCardTransaction = (data, updateType = '', openedInvoice = '') => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = data._id ? 'PUT' : 'POST';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/transactions/credit-cards${data._id ? '/' + data._id : ''}?`;

        if (updateType) {
          url += `updateType=${updateType}&`
        }

        if (openedInvoice) {
          url += `openedInvoice=${openedInvoice}&`
        }

        return httpClient(url, { method, headers, data });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default saveCreditCardTransaction;
