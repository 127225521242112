export default {
  interest: null,
  isMobile: false,
  isIOS: false,
  estados: [
    {
      id: 11,
      sigla: 'RO',
      nome: 'Rondônia'
    },
    {
      id: 12,
      sigla: 'AC',
      nome: 'Acre'
    },
    {
      id: 13,
      sigla: 'AM',
      nome: 'Amazonas'
    },
    {
      id: 14,
      sigla: 'RR',
      nome: 'Roraima'
    },
    {
      id: 15,
      sigla: 'PA',
      nome: 'Pará'
    },
    {
      id: 16,
      sigla: 'AP',
      nome: 'Amapá'
    },
    {
      id: 17,
      sigla: 'TO',
      nome: 'Tocantins'
    },
    {
      id: 21,
      sigla: 'MA',
      nome: 'Maranhão'
    },
    {
      id: 22,
      sigla: 'PI',
      nome: 'Piauí'
    },
    {
      id: 23,
      sigla: 'CE',
      nome: 'Ceará'
    },
    {
      id: 25,
      sigla: 'PB',
      nome: 'Paraíba'
    },
    {
      id: 24,
      sigla: 'RN',
      nome: 'Rio Grande do Norte'
    },
    {
      id: 26,
      sigla: 'PE',
      nome: 'Pernambuco'
    },
    {
      id: 28,
      sigla: 'SE',
      nome: 'Sergipe'
    },
    {
      id: 31,
      sigla: 'MG',
      nome: 'Minas Gerais'
    },
    {
      id: 27,
      sigla: 'AL',
      nome: 'Alagoas'
    },
    {
      id: 32,
      sigla: 'ES',
      nome: 'Espírito Santo'
    },
    {
      id: 33,
      sigla: 'RJ',
      nome: 'Rio de Janeiro'
    },
    {
      id: 29,
      sigla: 'BA',
      nome: 'Bahia'
    },
    {
      id: 35,
      sigla: 'SP',
      nome: 'São Paulo'
    },
    {
      id: 41,
      sigla: 'PR',
      nome: 'Paraná'
    },
    {
      id: 42,
      sigla: 'SC',
      nome: 'Santa Catarina'
    },
    {
      id: 43,
      sigla: 'RS',
      nome: 'Rio Grande do Sul'
    },
    {
      id: 52,
      sigla: 'GO',
      nome: 'Goiás'
    },
    {
      id: 50,
      sigla: 'MS',
      nome: 'Mato Grosso do Sul'
    },
    {
      id: 51,
      sigla: 'MT',
      nome: 'Mato Grosso'
    },
    {
      id: 53,
      sigla: 'DF',
      nome: 'Distrito Federal'
    }
  ],
  countryCodes: [
    { code: '+61', country: 'Austrália' },
    { code: '+55', country: 'Brasil' },
    { code: '+34', country: 'Espanha' },
    { code: '+1', country: 'Estados Unidos' }
  ],
  user: null,
  syncBankAccount: false,
  // admin
  configIr: null,
  plans: [],
  companiesNames: [],
  plannersNames: [],
  company: null,
  // customer
  months: [
    { nome: 'Janeiro', sigla: 'Jan', numero: 1 },
    { nome: 'Fevereiro', sigla: 'Fev', numero: 2 },
    { nome: 'Março', sigla: 'Mar', numero: 3 },
    { nome: 'Abril', sigla: 'Abr', numero: 4 },
    { nome: 'Maio', sigla: 'Mai', numero: 5 },
    { nome: 'Junho', sigla: 'Jun', numero: 6 },
    { nome: 'Julho', sigla: 'Jul', numero: 7 },
    { nome: 'Agosto', sigla: 'Ago', numero: 8 },
    { nome: 'Setembro', sigla: 'Set', numero: 9 },
    { nome: 'Outubro', sigla: 'Out', numero: 10 },
    { nome: 'Novembro', sigla: 'Nov', numero: 11 },
    { nome: 'Dezembro', sigla: 'Dez', numero: 12 }
  ],
  bankAccounts: [],
  transactions: [],
  transactionsPeriods: [],
  creditCards: [],
  categories: [],
  invoices: [],
  orcamento: null,
  perfil: null,
  patrimonio: null,
  independenciaFinanceira: null,
  customer: null,
  liquidez: null,
  planner: null,
  investments: [],
  actionPlans: [],
  lifeInsurances: [],
  meiosPagamento: [],
  recommendations: [],
  subscriptionSuspended: false,
  banksAndCodes: [],
  requestReward: [],
  hasOpenBankingError: false,
  pendentCreditCards: [],
  totalPendencias: {
    revenue: {
      items: 0,
      total: 0
    },
    expenses: {
      items: 0,
      total: 0
    },
    investments: {
      items: 0,
      total: 0
    }
  },
  pendencias: []
};
