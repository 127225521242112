<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card v-if="user">
      <v-card-title>
        <div class="title">
          {{ user.disabled ? 'Habilitar' : 'Desabilitar' }} {{ user.displayName }}
        </div>
      </v-card-title>

      <v-card-text>
        <div>
          <p>
            Tem certeza que deseja {{ user.disabled ? 'habilitar' : 'desabilitar' }} este usuário?
          </p>
          <p
            class="warning--text"
            v-if="user.role === 'customer' && user.iuguSubscription && user.disabled"
          >
            A assinatura do usuário será reativada e poderá gerar conbranças!
          </p>
          <p
            class="warning--text"
            v-if="user.role === 'customer' && user.iuguSubscription && !user.disabled"
          >
            A assinatura do usuário será desativada!
          </p>
        </div>

        <v-card-actions class="pa-0">
          <v-btn
            text
            small
            rounded
            class="px-5"
            color="primary"
            :disabled="loader"
            @click.stop="close"
          >
            Cancelar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            small
            rounded
            depressed
            color="primary"
            :loading="loader"
            @click.stop="submit"
            class="px-5 darkgrey--text"
          >
            {{ user.disabled ? 'Habilitar' : 'Desabilitar' }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import changeStatus from '@/api/users/changeStatus';

export default {
  name: 'DialogUpdateUserStatus',
  data: () => ({
    dialog: false,
    loader: false,
    user: null
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.user = null;
    },
    submit() {
      this.loader = true;

      const data = { disabled: !this.user.disabled }

      changeStatus(this.user._id, data)
        .then(() => {
          this.$bus.$emit('managerGetUsers');
          this.$noty.success(`Usuário ${this.user.disabled ? 'habilitado' : 'desabilitado'} com sucesso!`);
          this.close();
        })
        .catch(err => {
          this.loader = false;
          this.$noty.error(`Falha ao ${this.user.disabled ? 'habilitar' : 'desabilitar'} usuário!`);

          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    }
  },
  watch: {
    disablePlanners() {
      this.keepPlanners = !this.disablePlanners;
    }
  },
  created() {
    this.$bus.$on('showDialogUpdateUserStatus', user => {
      if (user) {
        this.user = user;
        this.dialog = true;
      }
    });
  }
}
</script>
