import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar total de planejadores de determinada empresa nos últimos 6 meses
 * @param {string} companyId
 */
const getPlannersSeries = companyId =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        return httpClient(`/companies/${companyId}/planners/series`, {
          headers
        });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });

export default getPlannersSeries;
