<template>
  <v-dialog
    v-model="dialog"
    :persistent="loader"
    max-width="500px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          x-small
          color="error"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon x-small>mdi-close</v-icon>
        </v-btn>

        <span class="error--text ml-4">
          Remover pendências
        </span>
      </v-card-title>

      <v-card-text class="mt-5">
        <p class="white--text">
          Tem certeza que deseja remover {{ ids.length }} pendências?
        </p>
        <v-card-actions class="px-0">
          <v-btn
            text
            small
            rounded
            color="white"
            @click="close"
            :disabled="loader"
          >
            Cancelar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            dark
            small
            rounded
            depressed
            class="px-5"
            color="error"
            @click="submit"
            :loading="loader"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import deleteMultiplePendentTransactions from "@/api/transactions/deleteMultiplePendentTransactions";

export default {
  name: 'DialogRemoveAllPendencias',
  data: () => ({
    ids: [],
    dialog: false,
    loader: false
  }),
  computed: {
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === 'customer'
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.ids = [];
    },
    submit() {
      if (this.ids.length) {
        this.loader = true;

        deleteMultiplePendentTransactions(this.user._id, this.ids)
          .then(() => {
            this.ids.forEach(id => {
              this.$store.dispatch('removePendencia', id)
            });
            this.close();
            this.$noty.success('Pendências removidas com sucesso!')
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível remover estas pendências. Tente novamente mais tarde!', 'error');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.close()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogRemoveAllPendencias', ids => {
      if (ids.length) {
        this.ids = ids
        this.dialog = true;
      }
    })
  }
}
</script>
