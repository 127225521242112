import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar total de clientes de determinada empresa nos últimos 6 meses
 * @param {string} plannerId
 * @param {string} companyId
 */
const getCustomersSeries = companyId => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        return httpClient(`/companies/${companyId}/customers/series`, {
          headers
        });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
};

export default getCustomersSeries;
