<template>
  <div class="form-inventario">


    <v-form @submit.prevent="">
      <v-layout row wrap>
        <v-flex xs12>
          <div class="input-label primary--text">Valor</div>
          <v-text-field
            type="tel"
            color="grey1"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'FormInsuranceCustoInventario',
  props: ['title', 'description', 'type', 'isSpouse'],
  computed: {
    isCustomer() {
      return this.$store.getters.user.role === 'customer';
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
    },
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    }
  }
}
</script>

<style scoped>
.title-form-inventario {
  display: block;
  margin-bottom: 20px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
}
</style>
