<template>
  <v-card color="grey2">
    <v-card-title class="primary--text text-uppercase text-center">Projetos de Vida</v-card-title>

    <v-card-text>
      <v-container grid-list-md class="px-0 py-0">
        <v-layout row wrap class="px-0">
          <v-flex xs12 sm4 class="text-center">
            <div class="period-title primary--text text-uppercase">1 a 2 anos</div>
            <div class="period-title white--text text-uppercase">{{ numberToCurrency(short.total) }}</div>
          </v-flex>

          <v-flex xs6 sm4 class="text-center">
            <div class="year-title primary--text">1º ano</div>
            <div class="period-title grey1--text text-uppercase">{{ numberToCurrency(short.firstPeriod) }}</div>
          </v-flex>

          <v-flex xs6 sm4 class="text-center">
            <div class="year-title primary--text">2º ano</div>
            <div class="period-title grey1--text text-uppercase">{{ numberToCurrency(short.secondPeriod) }}</div>
          </v-flex>

          <v-flex xs12 class="hidden-sm-and-down mt-5">
            <div class="divider"></div>
          </v-flex>

          <v-flex xs12 sm4 class="mt-5 text-center">
            <div class="period-title primary--text text-uppercase">3 a 4 anos</div>
            <div class="period-title white--text text-uppercase">{{ numberToCurrency(medium.total) }}</div>
          </v-flex>

          <v-flex xs6 sm4 class="mt-sm-5 text-center">
            <div class="year-title primary--text">3º ano</div>
            <div class="period-title grey1--text text-uppercase">{{ numberToCurrency(medium.firstPeriod) }}</div>
          </v-flex>

          <v-flex xs6 sm4 class="mt-sm-5 text-center">
            <div class="year-title primary--text">4º ano</div>
            <div class="period-title grey1--text text-uppercase">{{ numberToCurrency(medium.secondPeriod) }}</div>
          </v-flex>

          <v-flex xs12 class="hidden-sm-and-down mt-5">
            <div class="divider"></div>
          </v-flex>

          <v-flex xs12 sm4 class="mt-5 text-center">
            <div class="period-title primary--text text-uppercase">5 anos ou mais</div>
            <div class="period-title white--text text-uppercase">{{ numberToCurrency(long.total) }}</div>
          </v-flex>

          <v-flex xs12 sm4 class="mt-sm-5 text-center">
            <div class="year-title primary--text">A partir do 5º ano</div>
            <div class="period-title grey1--text text-uppercase">{{ numberToCurrency(long.total) }}</div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import numberToCurrency from '../../../../utils/numberToCurrency';

  export default {
    name: 'CardLifeProjectsLiquidez',
    data: () => ({
      numberToCurrency
    }),
    computed: {
      liquidez() {
        return this.$store.getters.liquidez;
      },
      short() {
        return this.liquidez.lifeProjects.short;
      },
      medium() {
        return this.liquidez.lifeProjects.medium;
      },
      long() {
        return this.liquidez.lifeProjects.long;
      }
    }
  }
</script>

<style scoped>
  .v-card__title {
    display: block !important;
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 1.5em !important;
    letter-spacing: .1em !important;
  }

  .input-icon {
    font-size: 16px !important;
    margin-right: 5px;
    margin-top: 5px;
  }

  .total-container p {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .total-value {
    font-size: 18px;
  }

  .total-value span {
    font-size: 10px;
  }

  .input-label {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .period-title {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .year-title {
    font-size: 10px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .divider {
    min-height: 2px;
    background-color: #919191;
  }

  @media only screen and (min-width: 600px) {
    .v-card__title {
      font-size: 15px !important;
    }

    .total-container p {
      font-size: 15px;
    }

    .total-value {
      font-size: 24px;
    }

    .total-value span {
      font-size: 13px;
    }

    .period-title {
      font-size: 15px;
    }

    .year-title {
      font-size: 12px;
    }
  }

  @media only screen and (min-width: 960px) {
    .v-card__title {
      font-size: 20px !important;
    }

    .total-container p {
      font-size: 15px;
    }

    .total-value {
      font-size: 24px;
    }

    .total-value span {
      font-size: 13px;
    }

    .period-title {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 1264px) {
    .v-card__title {
      font-size: 16px !important;
    }
  }
</style>
