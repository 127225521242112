import {sumBy} from 'lodash'
import store from '@/store'


export default (currentPeriod, referencePeriod) => {
  const revenue = store.getters.transactions.filter(({
     date,
     type,
     bankAccount,
     consolidated,
     isTransfer
   }) => {
    return (
      !isTransfer &&
      !!bankAccount &&
      type === 'entrada' &&
      date.slice(0, 7) === referencePeriod &&
      (referencePeriod > currentPeriod || consolidated)
    );
  });

  return sumBy(revenue, 'value')
}
