<template>
  <v-dialog
    persistent
    :fullscreen="isMobile"
    v-model="dialog"
    max-width="500px"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey3">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text">
          {{ dependente._id ? 'Editar' : 'Adicionar' }} Dependente
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <div class="input-container">
            <span class="input-label primary--text">Nome</span>
            <v-text-field
              dense
              color="grey1"
              v-model="dependente.descricao"
              :error-messages="descricaoErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-account
              </v-icon>
            </v-text-field>
          </div>

          <div class="input-container">
            <span class="input-label primary--text">Valor mensal</span>
            <v-text-field
              dense
              color="grey1"
              type="tel"
              v-money="money"
              v-model="dependente.valorMensal"
              id="input-insurance-dependente-valor-mensal"
              :error-messages="valorMensalErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-cash-multiple
              </v-icon>
            </v-text-field>
          </div>

          <div class="input-container">
            <span class="input-label primary--text">Prazo de cobertura</span>
            <v-text-field
              dense
              color="grey1"
              type="tel"
              suffix="anos"
              v-model.number="dependente.prazoCobertura"
              :error-messages="prazoCoberturaErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon mr-1"
                slot="prepend-inner"
              >
                mdi-calendar
              </v-icon>
            </v-text-field>
          </div>

          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              fab
              small
              type="submit"
              color="primary"
              :loading="loader"
              class="darkgrey--text"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money'
import removeMoneyMask from '@/utils/removeMoneyMask'
import saveLifeInsuranceDependent from '@/api/lifeInsurance/saveLifeInsuranceDependent';

export default {
  name: 'DialogFormInsuranceDependente',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    isSpouse: false,
    dependente: {
      descricao: '',
      valorMensal: 0,
      prazoCobertura: 0
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    }
  }),
  validations: {
    dependente: {
      descricao: {
        isValid(v) {
          return !!v && v.length <= 150;
        }
      },
      valorMensal: {
        isValid(v) {
          return !!v && removeMoneyMask(v) > 0;
        }
      },
      prazoCobertura: {
        isValid(v) {
          return v > 0;
        }
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return this.$store.getters.user.role === 'customer';
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
    },
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    },
    descricaoErrors() {
      const errors = [];

      if (!this.$v.dependente.descricao.$anyDirty) {
        return errors;
      }

      !this.$v.dependente.descricao.isValid && errors.push('Preencha um nome válido')

      return errors;
    },
    valorMensalErrors() {
      const errors = [];

      if (!this.$v.dependente.valorMensal.$anyDirty) {
        return errors;
      }

      !this.$v.dependente.valorMensal.isValid && errors.push('Preencha um valor válido')

      return errors;
    },
    prazoCoberturaErrors() {
      const errors = [];

      if (!this.$v.dependente.prazoCobertura.$anyDirty) {
        return errors;
      }

      !this.$v.dependente.prazoCobertura.isValid && errors.push('Preencha um prazo válido')

      return errors;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.isSpouse = false;
      this.dependente = {
        descricao: '',
        valorMensal: 0,
        prazoCobertura: 0
      };
      this.resetMoneyInput();
      this.$v.$reset();
    },
    resetMoneyInput() {
      let input = document.getElementById("input-insurance-dependente-valor-mensal")
      if (input) {
        input.value = this.dependente.valorMensal
        input.dispatchEvent(new Event('input'))
      }
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        let dependente = JSON.parse(JSON.stringify(this.dependente));

        dependente.valorMensal = removeMoneyMask(dependente.valorMensal);

        saveLifeInsuranceDependent(this.lifeInsurance._id, dependente)
          .then(lifeInsurance => {
            this.$store.dispatch('saveLifeInsurance', lifeInsurance)
            this.close();
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível salvar estes dados. Tente novamente!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          });
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormInsuranceDependente', data => {
      if (data.dependente) {
        this.dependente = JSON.parse(JSON.stringify(data.dependente));
        this.dependente.valorMensal = parseFloat(this.dependente.valorMensal).toFixed(2);
        this.resetMoneyInput()
      }

      this.isSpouse = !!data.isSpouse;
      this.dialog = true;
    });
  }
}
</script>
