import firebase from 'firebase/app';
import moment from 'moment-timezone';
import httpClient from '@/plugins/httpClient';

/**
 * Verificar planos que estão próximos do vencimento
 * @param userId
 * @returns {Promise<>}
 */
const checkActionPlansDeadline = userId => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let date = moment().tz('America/Sao_Paulo').format('YYYY-MM-DD');

          let url = `/action-plans/users/${userId}/check-deadline?date=${date}`;
          let headers = { Authorization: `Bearer ${idToken}` };

          return httpClient(url, { headers });
        })
        .then(resp => resolve())
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default checkActionPlansDeadline;
