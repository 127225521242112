import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const saveConfigIr = (configId, data) =>
  new Promise((resolve, reject) => {
    let user = store.getters.user;

    if (user && user.role === 'admin') {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'PUT';
          let headers = {
            role: user.role,
            Authorization: `Bearer ${idToken}`
          };
          let url = `/config/ir/${configId}`;

          return httpClient(url, { method, headers, data });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });

export default saveConfigIr;
