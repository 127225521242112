<template>
  <v-navigation-drawer
    fixed
    color="grey4"
    v-model="drawer"
    :dark="!mobileScreen"
    :temporary="mobileScreen"
    :permanent="!mobileScreen"
    :expand-on-hover="!mobileScreen"
    @transitionend="toggleBottomMenu"
    :class="mobileScreen ? 'pb-10' : ''"
    class="safe-area-top safe-area-bottom"
    :width="mobileScreen ? width + 'px' : '290px'"
  >
    <v-list dense>
      <v-list-item two-line class="mb-1 px-2">
        <v-list-item-avatar>
          <v-img v-if="this.user.photoURL" :src="this.user.photoURL"></v-img>
          <v-icon color="grey1" v-if="!this.user.photoURL">mdi-account</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="profile-name">
            {{ this.user.displayName }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <div class="px-2 my-1">
        <v-divider></v-divider>
      </div>

      <v-list-item class="pl-2" to="/">
        <v-list-item-avatar>
          <i class="lni primary--text lni-home"></i>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Início</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="pl-2" @click.stop="toggleConfigsMenu">
        <v-list-item-avatar>
          <i class="lni primary--text lni-cog"></i>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Configurações</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="grey1">{{
              configsMenu ? 'mdi-chevron-up' : 'mdi-chevron-down'
            }}</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-expand-transition>
        <div v-if="configsMenu" class="grey3">
          <v-list-item class="pl-2" to="/cadastro">
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Meu cadastro</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pl-2" @click="showDialogFormEmail">
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Redefinir e-mail</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pl-2" @click="showFormSenha">
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Redefinir senha</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-expand-transition>

      <div class="px-2 my-1">
        <v-divider></v-divider>
      </div>

      <v-list-item class="pl-2" @click="logout">
        <v-list-item-avatar>
          <i class="lni primary--text lni-power-switch"></i>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import logout from '@/utils/logout'

export default {
  name: 'CompanyNavigationDrawer',
  data: () => ({
    drawer: false,
    width: 320,
    configsMenu: false,
  }),
  computed: {
    user() {
      return this.$store.getters.user
    },
    mobileScreen() {
      return this.width < 960
    }
  },
  methods: {
    toggle(show) {
      this.drawer = show
    },
    setAndWatchWidth() {
      var self = this

      self.width = window.innerWidth

      window.addEventListener('resize', function () {
        self.width = window.innerWidth
      })
    },
    toggleBottomMenu() {
      this.$bus.$emit('toggleCompanyDrawer', this.drawer)
    },
    logout() {
      logout()
    },
    showDialogFormEmail() {
      this.$bus.$emit('showFormEmail');
    },
    showFormSenha() {
      this.$bus.$emit('ShowFormSenha');
    },
    toggleConfigsMenu() {
      this.configsMenu = !this.configsMenu;
    },
  },
  created() {
    this.$bus.$on('toggleCompanyDrawer', this.toggle)
  }
}
</script>
