import firebase from 'firebase/app';
import apiConfig from '@/plugins/httpClient'

const savePermissions = (userId, data) => {

  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        return apiConfig({
          url: `/users/${userId}/permissions`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${idToken}`
          },
          data
        });
      })
      .then(resp => {
        resolve(resp.data)
      })
      .catch(reject);
  });
}

export default savePermissions;
