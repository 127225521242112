import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Criar novo usuário
 * @param {object} user
 */
const createUser = user =>
  new Promise((resolve, reject) => {
    let currentUser = store.getters.user;
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        return httpClient({
          url: `/users`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
            author: currentUser ? currentUser._id : ''
          },
          data: user
        });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });

export default createUser;
