<template>
  <v-dialog persistent v-model="dialog" max-width="500px" v-if="invoice && creditCard">
    <v-card color="grey2" :class="isMobile ? 'no-radius' : ''">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="white"
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-2"> Alterar datas da fatura </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <div>
            <v-dialog
              width="290px"
              ref="closeDateDialog"
              v-model="closeDateModal"
              :return-value.sync="invoice.closeDate"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="input-labe primary--text">Data de fechamento</div>
                <v-text-field
                  dense
                  readonly
                  v-on="on"
                  v-bind="attrs"
                  :error-messages="closeDateErrors"
                  :value="formatDate(invoice.closeDate)"
                >
                  <v-icon
                    color="primary"
                    class="input-icon"
                    slot="prepend-inner"
                  >
                    mdi-calendar
                  </v-icon>
                </v-text-field>
              </template>

              <v-date-picker
                no-title
                scrollable
                locale="pt-br"
                v-model="invoice.closeDate"
                :max="$date(invoice.paymentDate).subtract(1, 'day').format('YYYY-MM-DD')"
                :min="`${invoice.closeDate.slice(0,7)}-01`"
              >
                <v-spacer></v-spacer>
                <v-btn color="grey2" @click="closeDateModal = false">
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.closeDateDialog.save(invoice.closeDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>

          <div>
            <v-dialog
              width="290px"
              ref="paymentDateDialog"
              v-model="paymentDateModal"
              :return-value.sync="invoice.paymentDate"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="input-labe primary--text">Data de vencimento</div>
                <v-text-field
                  dense
                  readonly
                  v-on="on"
                  v-bind="attrs"
                  :error-messages="paymentDateErrors"
                  :value="formatDate(invoice.paymentDate)"
                >
                  <v-icon
                    color="primary"
                    class="input-icon"
                    slot="prepend-inner"
                  >
                    mdi-calendar
                  </v-icon>
                </v-text-field>
              </template>

              <v-date-picker
                no-title
                scrollable
                locale="pt-br"
                v-model="invoice.paymentDate"
                :min="$date(invoice.closeDate).add(1, 'day').format('YYYY-MM-DD')"
                :max="`${invoice.paymentDate.slice(0,7)}-31`"
              >
                <v-spacer></v-spacer>
                <v-btn color="grey2" @click="paymentDateModal = false">
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.paymentDateDialog.save(invoice.paymentDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>

          <v-card-actions class="px-0 btn-container">
            <v-btn
              text
              small
              rounded
              type="button"
              @click="close"
              color="primary"
              :disabled="loader"
              class="hidden-xs-only"
            >
              Cancelar
            </v-btn>

            <v-spacer class="hidden-xs-only"></v-spacer>

            <v-btn
              small
              rounded
              outlined
              class="px-5"
              type="button"
              color="primary"
              :disabled="loader"
              @click="setDefault"
            >
              Usar datas do cartão
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              small
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              class="px-5 darkgrey--text"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import updateInvoiceDates from '@/api/invoices/updateInvoiceDates';

export default {
  name: 'DialogFormInvoiceDates',
  data: () => ({
    dialog: false,
    paymentDateDialog: false,
    closeDateDialog: false,
    loader: false,
    closeDateModal: false,
    paymentDateModal: false,
    invoice: {
      _id: '',
      closeDate: '',
      creditCard: '',
      paymentDate: '',
    },
    dateRegexp: /\d{4}-\d{2}-\d{2}/,
  }),
  validations: {
    invoice: {
      closeDate: {
        isValid(v) {
          return this.dateRegexp.test(v);
        },
      },
      paymentDate: {
        isValid(v) {
          return (
            this.dateRegexp.test(v) && dayjs(v).isAfter(this.invoice.closeDate)
          );
        },
      },
    },
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    closeDateErrors() {
      const errors = [];

      if (!this.$v.invoice.closeDate.$anyDirty) {
        return errors;
      }

      !this.$v.invoice.closeDate.isValid &&
        errors.push('Preencha uma data válida');

      return errors;
    },
    paymentDateErrors() {
      const errors = [];

      if (!this.$v.invoice.paymentDate.$anyDirty) {
        return errors;
      }

      !this.$v.invoice.paymentDate.isValid &&
        errors.push('Preencha uma data válida');

      return errors;
    },
    creditCards() {
      return this.$store.getters.creditCards;
    },
    creditCard() {
      return this.creditCards.find((cc) => cc._id === this.invoice.creditCard);
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        return date.split('-').reverse().join('/');
      }
      return '';
    },
    close() {
      this.dialog = false;
      this.loader = false;
      this.invoice = {
        _id: '',
        closeDate: '',
        creditCard: '',
        paymentDate: '',
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        const data = {
          closeDate: this.invoice.closeDate,
          paymentDate: this.invoice.paymentDate
        }

        updateInvoiceDates(this.invoice._id, data)
          .then((resp) => {
            if (resp.isPaid) {
              this.loader = false;
              this.$noty.warning(
                'Esta fatura já foi paga. Voce não pode alterá-la'
              );
            } else if (resp.sameDate) {
              this.loader = false;
              this.$noty.warning(
                `Voce possui outra fatura com vencimento em ${this.formatDate(
                  this.invoice.paymentDate
                ).slice(3, 10)}`
              );
            } else {
              this.$store.dispatch('saveInvoice', resp);
              this.close();
              this.$noty.success('Datas alteradas com sucesso!');
            }
          })
          .catch((err) => {
            this.loader = false;
            this.$noty.error(
              'Desculpe! Não foi possível alterar os dados desta fatura. Tente novamente mais tarde!'
            );

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    setDefault() {
      if (this.creditCard) {
        let closeDate = this.creditCard.close;
        let paymentDate = this.creditCard.pay;

        if (closeDate < 10) {
          closeDate = `0${closeDate}`;
        }

        if (paymentDate < 10) {
          paymentDate = `0${paymentDate}`;
        }

        this.invoice.closeDate = `${this.invoice.closeDate.slice(
          0,
          7
        )}-${closeDate}`;
        this.invoice.paymentDate = `${this.invoice.paymentDate.slice(
          0,
          7
        )}-${paymentDate}`;

        if (this.creditCard.close > this.creditCard.pay) {
          this.invoice.closeDate = dayjs(this.invoice.closeDate)
            .subtract(1, 'month')
            .format('YYYY-MM-DD');
        }

        this.submit();
      }
    },
  },
  created() {
    this.$bus.$on('showDialogFormInvoiceDates', (invoice) => {
      if (invoice) {
        this.invoice = invoice;
        this.dialog = true;
      }
    });
  },
};
</script>

<style scoped>
@media only screen and (max-width: 490px) {
  .v-card__actions.btn-container {
    display: flex !important;
    flex-direction: column !important;
  }

  .v-card__actions.btn-container .v-btn {
    display: block !important;
    margin-bottom: 10px;
    width: 100%;
  }
}
</style>
