<template>
  <v-card color="grey2">
    <v-card-title
      class="text-uppercase d-flex justify-space-between align-center"
    >
      <span>Projetos de Vida</span>

      <v-btn
        fab
        small
        depressed
        color="grey3"
        v-if="unlock"
        class="grey1--text"
        :disabled="!allowed"
        @click="showDialogProjetoVida"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div class="list-projetos">
        <h1 v-if="!projetos.length" class="grey1--text text-center py-10">Nenhum projeto adicionado</h1>

        <div
          :key="projeto._id"
          class="list-projetos-item"
          v-for="(projeto, index) in projetos"
          @click="showDialogDetalhesProjetoVida(projeto)"
          :class="projetos[index+1] ? 'list-projetos-item-border' : ''"
        >
          <div class="list-projetos-item-container">
            <v-avatar
              size="23"
              class="mr-2"
              color="grey3"
            >
              <v-icon size="13" :color="projeto.tipo === 'entrada' ? 'success' : 'error'">
                {{ projeto.tipo === 'entrada' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
              </v-icon>
            </v-avatar>

            <div class="list-projetos-item__content">
              <span class="list-projetos-item__content-name">{{ projeto.descricao }}</span>
              <span class="list-projetos-item__content-date">{{ formatDate(projeto.data) }}</span>
            </div>
          </div>

          <span class="list-projetos-item-value">
            {{ numberToCurrency((projeto.tipo === 'saída' ? projeto.valor * -1 : projeto.valor)) }}
          </span>
        </div>
      </div>

      <v-slide-x-transition>
        <div class="text-center mt-5" v-if="indFinanceira.projetos.length > 5">
          <v-pagination
            circle
            v-model="pagination.page"
            :length="pagination.totalPages"
          ></v-pagination>
        </div>
      </v-slide-x-transition>
    </v-card-text>

    <DialogDetalhesProjetoVida/>
    <DialogRemoveProjetoIndFinanceira/>
  </v-card>
</template>

<script>
  import moment from 'moment-timezone'
  import { orderBy } from 'lodash'
  import numberToCurrency from "../../../../utils/numberToCurrency";
  import DialogDetalhesProjetoVida from "../dialogs/DialogDetalhesProjetoVida";
  import DialogRemoveProjetoIndFinanceira from "../dialogs/DialogRemoveProjetoIndFinanceira";

  export default {
    name: 'CardProjetosIndFinanceira',
    components: {DialogRemoveProjetoIndFinanceira, DialogDetalhesProjetoVida},
    data: () => ({
      pagination: {
        page: 1,
        itemsPerPage: 5,
        totalItems: 0,
        totalPages: 1
      },
      numberToCurrency
    }),
    computed: {
      allowed() {
        return this.$store.getters.allowed;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      projetos() {
        let skip = (this.pagination.page - 1) * this.pagination.itemsPerPage;
        return orderBy(this.indFinanceira.projetos, 'data').slice(skip, skip + this.pagination.itemsPerPage);
      },
      unlock() {
        return this.indFinanceira.period === moment().format('YYYY-MM');
      }
    },
    methods: {
      formatDate(date) {
        if (date)
          return date.split('-').reverse().join('/');

        return '';
      },
      setPagination() {
        this.pagination.totalItems = this.indFinanceira.projetos.length;
        this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage);

        if (this.pagination.totalPages < this.pagination.page) {
          this.pagination.page--;
        }

        if (!this.pagination.page || this.pagination.page <= 0) {
          this.pagination.page = 1;
        }
      },
      showDialogProjetoVida() {
        if (this.allowed)
          this.$bus.$emit('showDialogProjetoVida');
      },
      showDialogDetalhesProjetoVida(projeto) {
        if (this.allowed)
          this.$bus.$emit('showDialogDetalhesProjetoVida', projeto);
      }
    },
    watch: {
      'indFinanceira.projetos': {
        deep: true,
        handler() {
          this.setPagination();
        }
      }
    },
    created() {
      this.setPagination();
    }
  }
</script>

<style scoped>
  .text-uppercase {
    font-size: 12px;
    letter-spacing: .1em;
  }

  .list-projetos {
    display: flex;
    flex-direction: column;
  }

  .list-projetos h1 {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .list-projetos-item {
    padding: 10px 5px;
    border-radius: 5px;
    margin-top: 10px;
    transition: all .5s ease;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .list-projetos-item:hover {
    background-color: #131313;
  }

  .list-projetos-item-border {
    border-bottom: solid 2px #919191;
  }

  .list-projetos-item-container {
    display: flex;
    align-items: center;
  }

  .list-projetos-item__content {
    display: flex;
    flex-direction: column;
  }

  .list-projetos-item__content-name {
    color: white;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .list-projetos-item__content-date {
    font-size: 9px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .list-projetos-item-value {
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  @media only screen and (min-width: 600px) {
    .text-uppercase {
      font-size: 15px;
    }

    .list-projetos-item-container .v-avatar {
      width: 40px !important;
      height: 40px !important;
    }

    .list-projetos-item-container .v-avatar .v-icon {
      font-size: 20px !important;
    }

    .list-projetos-item__content-name {
      font-size: 15px;
    }

    .list-projetos-item__content-date {
      font-size: 12px;
    }

    .list-projetos-item-value {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 960px) {
    .text-uppercase {
      font-size: 16px;
    }

    .list-projetos-item__content-name {
      font-size: 16px;
    }

    .list-projetos-item__content-date {
      font-size: 12px;
    }

    .list-projetos-item-value {
      font-size: 20px;
    }
  }
</style>
