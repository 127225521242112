import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const payInvoice = (id, data) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'POST';
        let url = `/invoices/${id}/pay`;
        let headers = { Authorization: `Bearer ${idToken}` };

        return httpClient(url, { method, headers, data });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default payInvoice;
