<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="960px" :fullscreen="isMobile">
    <v-card :class="isMobile ? 'no-radius' : ''" v-if="video" height="100%">
      <v-row>
        <v-col :cols="isMobile ? 12 : 6" class="py-0">
          <iframe
            class="mb-0"
            :class="!isMobile ? 'video-player' : ''"
            width="100%"
            height="100%"
            allowfullscreen
            frameborder="0"
            :src="`https://www.youtube.com/embed/${video.cod}?showinfo=0`"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </v-col>

        <v-col :cols="isMobile ? 12 : 6" class="desc-container">
          <div>
            <v-card-title>{{ video.name }}</v-card-title>
            <p class="px-5" style="min-height: 120px;">{{ video.description }}</p>
          </div>

          <div class="px-5">
            <v-chip class="mr-1">{{ getCategoryName(video.category) }}</v-chip>
            <v-chip class="mr-1">{{ getTypeName(video.type) }}</v-chip>
          </div>

          <v-card-actions class="mt-auto px-5">
            <v-spacer></v-spacer>
            <v-btn icon color="error" @click.stop="remove">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-btn icon color="accent" @click.stop="edit">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="accent" @click.stop="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogVideoDetails',
  data: () => ({
    dialog: false,
    video: null,
    videoHeight: '100%'
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.video = null;
    },
    edit() {
      this.$bus.$emit('showDialogFormVideo', this.video);
      this.close();
    },
    remove() {
      this.$bus.$emit('showDialogRemoveVideo', this.video._id);
      this.close();
    },
    getCategoryName(category) {
      switch (category) {
        case 'aposentadoria':
          return 'Aposentadoria';
        case 'fluxo-caixa':
          return 'Fluxo de caixa';
        case 'investimentos':
          return 'Investimentos';
        case 'liquidez':
          return 'Líquidez';
        case 'gestao-riscos':
          return 'Gestão de Riscos';
        case 'gestao-tributaria':
          return 'Gestão Tributária';
        case 'planos-acao':
          return 'Planos de Ação';
        default:
          return 'Outro';
      }
    },
    getTypeName(type) {
      switch (type) {
        case 'customer':
          return 'Cliente';
        case 'company':
          return 'Empresa';
        case 'planner':
          return 'Planejador';
        default:
          return 'Todos';
      }
    }
  },
  created() {
    this.$bus.$on('showDialogVideoDetails', video => {
      if (video) {
        this.video = video;
        this.dialog = true;
      }
    });
  }
};
</script>

<style scoped>
.v-card.no-radius {
  border-radius: 0 !important;
}

.title {
  display: flex;
  align-items: center;
}

.video-player {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.desc-container {
  display: flex;
  flex-direction: column;
}
</style>
