import {sumBy} from 'lodash'
import store from '@/store'

export default (currentPeriod, referencePeriod) => {
  const investments = store.getters.transactions.filter(({date, type, investment, consolidated}) => {
    return (
      investment &&
      type === 'saida' &&
      date.slice(0, 7) === referencePeriod &&
      (referencePeriod > currentPeriod || consolidated)
    )
  })

  return sumBy(investments, 'value')
}
