<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    max-width="960px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey3">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="info"
          @click.stop="close"
          class="back-button"
          :disabled="loader || loaderOther"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="info--text ml-4">
          {{ transfer._id ? 'Editar' : 'Nova' }} Transferência
        </span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-container class="px-0 pt-5" grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <span class="input-label primary--text">Valor</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  v-money="money"
                  class="mt-0 pt-0"
                  v-model="transfer.value"
                  id="input-transfer-value"
                  :error-messages="valueErrors"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-cash
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex xs12 sm6>
                <span class="input-label primary--text">Data</span>
                <v-dialog
                  ref="dialogDate"
                  v-model="dateMenu"
                  :return-value.sync="transfer.date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 pt-0"
                      :value="formatedDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        color="grey1"
                        class="input-icon"
                        slot="prepend-inner"
                      >
                        mdi-calendar
                      </v-icon>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    color="primary"
                    v-model="transfer.date"
                    scrollable
                    locale="pt-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey2" @click="dateMenu = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.dialogDate.save(transfer.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12 sm6>
                <span class="input-label primary--text">Conta de origem</span>
                <v-select
                  color="grey1"
                  item-value="_id"
                  item-text="name"
                  class="mt-0 pt-0"
                  :items="originBankAccounts"
                  @change="resetOriginBankAccount"
                  ref="origin"
                  v-model="transfer.originBankAccount"
                  :error-messages="originBankAccountErrors"
                >
                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color"
                      >mdi-bank</v-icon
                    >
                    <span>{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>

                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-bank
                  </v-icon>
                </v-select>
              </v-flex>

              <v-flex xs12 sm6>
                <span class="input-label primary--text">Conta de destino</span>
                <v-select
                  color="grey1"
                  item-value="_id"
                  item-text="name"
                  class="mt-0 pt-0"
                  :items="destinyBankAccounts"
                  @change="resetDestinyBankAccount"
                  ref="destiny"
                  v-model="transfer.destinyBankAccount"
                  :error-messages="destinyBankAccountErrors"
                >
                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color"
                      >mdi-bank</v-icon
                    >
                    <span>{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>

                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-bank
                  </v-icon>
                </v-select>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Observação</span>
                <v-text-field
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="transfer.obs"
                  :error-messages="obsErrors"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-clipboard-text
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex xs12  class="checkbox-container">
                <v-layout row wrap>
                  <v-flex
                    xs12
                    sm4
                    class="d-flex align-sm-center justify-sm-center"
                    v-if="!transfer._id"
                  >
                    <v-checkbox
                      color="primary"
                      v-model="transfer.consolidated"
                      label="Transferir"
                    ></v-checkbox>
                  </v-flex>

                  <v-flex
                    xs6
                    sm4
                    class="d-flex align-sm-center justify-sm-center"
                    v-if="!transfer._id || (transfer._id && !transfer.fixedId )"
                  >
                    <v-checkbox
                      color="primary"
                      v-model="transfer.fixed"
                      :disabled="transfer.repeat"
                      label="Recorrente"
                    ></v-checkbox>
                  </v-flex>

                  <v-flex
                    xs6
                    sm4
                    class="d-flex align-sm-center justify-sm-center"
                    v-if="!transfer._id"
                  >
                    <v-checkbox
                      color="primary"
                      label="Repetir"
                      v-model="transfer.repeat"
                      :disabled="transfer.fixed"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex
                xs12
                class="d-flex flex-column repetition-desc"
                v-if="transfer._id && transfer.intervalType"
              >
                <span class="grey1--text">
                  {{
                    transfer.fixed
                      ? 'Recorrente'
                      : transfer.repeat
                      ? 'Repetida'
                      : ''
                  }}
                </span>
                <span class="grey1--text">
                  {{
                    formatIntervalType(transfer.intervalType, transfer.interval)
                  }}
                </span>
              </v-flex>

              <v-slide-x-transition>
                <v-flex
                  xs12
                  md3
                  offset-md2
                  v-if="!transfer._id && transfer.repeat"
                >
                  <span class="input-label primary--text"
                    >Número de repetições</span
                  >
                  <v-text-field
                    type="number"
                    color="grey1"
                    class="mt-0 pt-0"
                    :error-messages="totalPeriodsErrors"
                    v-model.number="transfer.totalPeriods"
                  >
                    <v-icon
                      color="grey1"
                      class="input-icon"
                      slot="prepend-inner"
                    >
                      mdi-refresh
                    </v-icon>
                  </v-text-field>
                </v-flex>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-flex
                  xs6
                  md2
                  :offset-md4="transfer.fixed"
                  :offset-lg4="transfer.fixed"
                  v-if="
                   !transfer.fixedId && !transfer.dividedId && (transfer.repeat || transfer.fixed)
                "
                >
                  <span class="input-label primary--text">Intervalo</span>
                  <v-text-field
                    type="number"
                    color="grey1"
                    class="mt-0 pt-0"
                    :error-messages="periodsIntervalErrors"
                    v-model.number="transfer.periodsInterval"
                  >
                    <v-icon
                      color="grey1"
                      class="input-icon"
                      slot="prepend-inner"
                    >
                      mdi-refresh
                    </v-icon>
                  </v-text-field>
                </v-flex>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-flex
                  xs6
                  :md2="transfer.fixed"
                  :md3="transfer.repeat"
                  v-if="
                   !transfer.fixedId && !transfer.dividedId && (transfer.repeat || transfer.fixed)
                "
                >
                  <span class="input-label primary--text"
                    >Tipo de intervalo</span
                  >
                  <v-select
                    color="grey1"
                    class="mt-0 pt-0"
                    :items="periodsTypes"
                    v-model.number="transfer.periodsType"
                  >
                    <v-icon
                      color="grey1"
                      class="input-icon"
                      slot="prepend-inner"
                    >
                      mdi-calendar
                    </v-icon>
                  </v-select>
                </v-flex>
              </v-slide-x-transition>

              <v-flex xs12 class="btn-container">
                <v-btn
                  rounded
                  type="submit"
                  color="primary"
                  :loading="loader"
                  :disabled="loaderOther"
                  class="px-5 darkgrey--text"
                >
                  Salvar {{ !transfer._id ? 'e fechar' : '' }}
                </v-btn>

                <v-btn
                  rounded
                  outlined
                  class="px-5"
                  type="button"
                  color="primary"
                  :disabled="loader"
                  v-if="!transfer._id"
                  :loading="loaderOther"
                  @click="submit(true)"
                >
                  Salvar e adicionar outro
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money';
import { orderBy, pick } from 'lodash';
import ObjectID from 'bson-objectid';
import dayjs from 'dayjs';
import {
  required,
  maxLength,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators';

import removeMoneyMask from '../../../../utils/removeMoneyMask';
import saveCategoryUser from '@/api/categoriesUser/saveCategoryUser';
import saveTransfer from '@/api/transactions/saveTransfer';

export default {
  name: 'DialogBankTransfer',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    dialogDate: false,
    loader: false,
    loaderOther: false,
    dateMenu: false,
    openedPeriod: null,
    transfer: {
      obs: '',
      value: 0,
      date: '',
      fixed: false,
      repeat: false,
      totalPeriods: '',
      periodsInterval: 1,
      consolidated: false,
      periodsType: 'months',
      originBankAccount: '',
      destinyBankAccount: '',
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    },
    periodsTypes: [
      { text: 'Mensal', value: 'months' },
      { text: 'Semanal', value: 'weeks' },
      { text: 'Anual', value: 'years' },
    ],
  }),
  validations: {
    transfer: {
      obs: {
        maxLength: maxLength(50),
      },
      value: {
        required,
        isValid(v) {
          return removeMoneyMask(v) > 0;
        },
      },
      date: {
        required,
      },
      totalPeriods: {
        minValue: minValue(2),
        maxValue: maxValue(120),
      },
      periodsInterval: {
        minValue: minValue(1),
      },
      originBankAccount: {
        required,
      },
      destinyBankAccount: {
        required,
        isDifferent(v) {
          return v !== this.transfer.originBankAccount;
        },
      },
    },
  },
  computed: {
    formatedDate() {
      if (this.transfer.date) {
        return this.transfer.date.split('-').reverse().join('/');
      }
      return '';
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts;
    },
    originBankAccounts() {
      return orderBy(
        this.bankAccounts.filter((b) => !b.archived ),
        'name'
      );
    },
    destinyBankAccounts() {
      return this.originBankAccounts.filter(
        (ba) => ba._id !== this.transfer.originBankAccount
      );
    },
    categories() {
      return this.$store.getters.categories.filter(
        (c) => c.type === 'transfer'
      );
    },
    obsErrors() {
      const errors = [];

      if (!this.$v.transfer.obs.$dirty) {
        return errors;
      }

      !this.$v.transfer.obs.maxLength && errors.push('Observação muito longa');

      return errors;
    },
    valueErrors() {
      const errors = [];

      if (!this.$v.transfer.value.$dirty) {
        return errors;
      }

      !this.$v.transfer.value.required &&
        errors.push('Preencha um valor válido');

      return errors;
    },
    dateErrors() {
      const errors = [];

      if (!this.$v.transfer.date.$dirty) {
        return errors;
      }

      (!this.$v.transfer.date.required || !this.$v.transfer.date.isValid) &&
        errors.push('Preencha uma data válida');

      return errors;
    },
    totalPeriodsErrors() {
      const errors = [];

      if (!this.$v.transfer.totalPeriods.$dirty) {
        return errors;
      }

      (!this.$v.transfer.totalPeriods.minValue ||
        !this.$v.transfer.totalPeriods.minValue) &&
        errors.push('Preencha um período válido');

      return errors;
    },
    periodsIntervalErrors() {
      const errors = [];

      if (!this.$v.transfer.periodsInterval.$dirty) {
        return errors;
      }

      !this.$v.transfer.periodsInterval.minValue &&
        errors.push('Preencha um intervalo válido');

      return errors;
    },
    originBankAccountErrors() {
      const errors = [];
      if (!this.$v.transfer.originBankAccount.$dirty) return errors;

      if(!this.$v.transfer.originBankAccount.required) {
        errors.push('Selecione a conta bancária');
        return errors;
      }
    },
    destinyBankAccountErrors() {
      const errors = [];
      if (!this.$v.transfer.destinyBankAccount.$dirty) return errors;

      if(!this.$v.transfer.destinyBankAccount.required) {
        errors.push('Selecione a conta bancária');
        return errors;
      }
    },

  },
  methods: {
    requestOpenedPeriod() {
      this.$bus.$emit('requestOpenedPeriod')
    },
    getOpenedPeriod(openedPeriod) {
      if (openedPeriod) {
        this.openedPeriod = openedPeriod
      }
    },
    formatIntervalType(intervalType, interval) {
      let str = `Uma vez a cada ${interval} `;

      if (intervalType === 'months') {
        str += interval > 1 ? 'meses' : 'mês';
      } else if (intervalType === 'weeks') {
        str += interval > 1 ? 'semanas' : 'semana';
      } else if (intervalType === 'year') {
        str += interval > 1 ? 'anos' : 'ano';
      }

      return str;
    },
    close() {
      this.dialog = false;
      this.loader = false;
      this.loaderOther = false;
      this.openedPeriod = null
      this.reset();
    },
    reset() {
      this.transfer = {
        obs: '',
        value: 0,
        date: '',
        fixed: false,
        repeat: false,
        totalPeriods: '',
        periodsInterval: 1,
        consolidated: false,
        periodsType: 'months',
        originBankAccount: '',
        destinyBankAccount: '',
      };

      this.transfer.date = dayjs().format('YYYY-MM-DD');
      this.resetMoneyInput();
      this.resetOriginBankAccount()
      this.resetDestinyBankAccount()
      this.$v.$reset();
    },
    resetMoneyInput() {
      let input = document.getElementById('input-transfer-value');
      if (input) {
        input.value = this.transfer.value;
        input.dispatchEvent(new Event('input'));
        input.scrollIntoView();
      }
    },
    async submit(saveOther) {
      try {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          if (saveOther === true) {
            this.loaderOther = true;
          } else {
            this.loader = true;
          }

          let transfer = JSON.parse(JSON.stringify(this.transfer));

          transfer.isTransfer = true;
          transfer.user = this.user._id;
          transfer.value = removeMoneyMask(transfer.value);

          if (transfer.originBankAccount._id) {
            transfer.originBankAccount = transfer.originBankAccount._id
          }

          if (transfer.destinyBankAccount._id) {
            transfer.destinyBankAccount = transfer.destinyBankAccount._id
          }

          if (transfer.fixed || transfer.repeat) {
            transfer.interval = this.transfer.periodsInterval;
            transfer.intervalType = this.transfer.periodsType;
          }

          let originBankAccount = this.bankAccounts.find(
            (ba) => ba._id === transfer.originBankAccount
          );
          let destinyBankAccount = this.bankAccounts.find(
            (ba) => ba._id === transfer.destinyBankAccount
          );

          let subCatName = `De ${originBankAccount.name} p/ ${destinyBankAccount.name}`;

          let category = this.categories[0];
          let subCategory;

          if (!category) {
            category = {
              update: true,
              _id: ObjectID().str,
              name: 'Transferência',
              type: 'transfer',
              color: '#008FFB',
              icon: 'mdi-bank-transfer',
              subs: [],
              user: this.user._id,
            };

            subCategory = {
              _id: ObjectID().str,
              category: category._id,
              name: subCatName,
              user: this.user._id,
            };

            category.subs.push(subCategory);
          } else {
            subCategory = category.subs.find((sc) => sc.name === subCatName);

            if (!subCategory) {
              subCategory = {
                _id: ObjectID().str,
                category: category._id,
                name: subCatName,
                user: this.user._id,
              };

              category.subs.push(subCategory);
              category.update = true;
            }
          }

          transfer.category = category._id;
          transfer.subCategory = subCategory._id;
          transfer.date = transfer.date.split('/').reverse().join('-');


          if (transfer._id && transfer.fixedId) {
            this.$bus.$emit('showDialogUpdateMultipleTransaction', {
              transaction: transfer,
              openedPeriod: this.openedPeriod
            });
            this.loader = false;
            this.loaderOther = false;

            if (category.update) {
              this.saveCategory(category);
            }
          } else {
            category = await saveCategoryUser(category);
            const resp = await saveTransfer(transfer, null, this.openedPeriod);
            if (resp.transactions) {
              resp.transactions.forEach(t => {

                if (!t.bankAccount._id || t.bankAccount.archived === undefined) {
                  let bankAccount = this.bankAccounts.find(ba => {
                    return ba._id === t.bankAccount || ba._id === t.bankAccount._id
                  });

                  t.bankAccount = {
                    _id: bankAccount._id,
                    name: bankAccount.name,
                    archived: bankAccount.archived,
                  };
                }

                t.category = category ? category : t.category;
                t.subCategory = subCategory ? subCategory : t.subCategory;

                this.$store.dispatch('saveTransaction', t);
              });
            }

            if (resp.bankAccounts) {
              resp.bankAccounts.forEach((ba) => {
                this.$store.dispatch('updateBankAccountBalance', ba);
              });
            }

            this.$noty.success('Transferência realizada com sucesso!');
          }

          if (saveOther === true) {
            this.loaderOther = false;
            this.reset();
          } else {
            this.close();
          }
        }
      } catch (err) {
        this.loader = false;
        this.loaderOther = false;
        this.$noty.error(
          'Desculpe! Não foi possível salvar esta transferência. Tente novamente!'
        );

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      }
    },
    resetOriginBankAccount() {
      if(this.transfer.destinyBankAccount === this.transfer.originBankAccount && this.$refs["destiny"]){
        this.$refs["destiny"].reset()
      }
    },
    resetDestinyBankAccount() {
      if(this.transfer.destinyBankAccount === this.transfer.originBankAccount && this.$refs["origin"]){
        this.$refs["origin"].reset();
      }
    },
    saveCategory(category) {
      saveCategoryUser(category)
        .then((savedCategory) => {
          this.$store.dispatch('saveCategory', savedCategory);
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    },
  },
  watch: {
    'transfer.date'(val) {
      if (!this.transfer._id) {
        if (this.$date(val).isSameOrBefore(this.$date())) {
          this.transfer.consolidated = true
        } else {
          this.transfer.consolidated = false
        }
      }
    },
    'transfer.fixed'() {
      if(this.transfer.fixed){
        this.transfer.periodsType = 'months';
        this.transfer.periodsInterval = 1;
      }
    },
  },
  created() {
    this.$bus.$on('showDialogBankTransfer', (transaction) => {
      this.requestOpenedPeriod()

      if (transaction) {
        this.transfer = pick(transaction, [
          '_id',
          'obs',
          'user',
          'date',
          'value',
          'fixed',
          'fixedId',
          'transferId',
          'consolidated',
          'destinyBankAccount',
          'originBankAccount',
          'intervalType',
          'interval',
          'repeat',
        ]);

        this.transfer.value = this.transfer.value.toFixed(2);
        this.resetMoneyInput();

        if (transaction.type === 'entrada') {
          this.transfer.destinyBankAccount = transaction.bankAccount._id;
          this.transfer.originBankAccount =
            transaction.transferOriginBankAccount;
        } else {
          this.transfer.originBankAccount = transaction.bankAccount._id;
          this.transfer.destinyBankAccount =
            transaction.transferDestinyBankAccount;
        }
      } else {
        this.transfer.date = dayjs().format('YYYY-MM-DD');
        this.transfer.consolidated = true
      }

      this.dialog = true;
    });

    this.$bus.$on('closeDialogForm', () => {
      this.close();
    });

    this.$bus.$on('getOpenedPeriod', this.getOpenedPeriod)
  },
};
</script>

<style scoped>
.repetition-desc {
  font-size: 12px !important;
  letter-spacing: 0.1em;
  line-height: 1.5em;
}

.btn-container {
  display: flex;
  flex-direction: column;
}

.btn-container .v-btn {
  margin-bottom: 5px;
}

.v-btn.v-size--default {
  font-size: 11px !important;
}

@media only screen and (min-width: 600px) {
  .btn-container {
    flex-direction: row;
    justify-content: center;
  }

  .btn-container .v-btn {
    margin: 0 5px;
    display: block;
    width: 200px;
  }
}
</style>
