const getCountryPhoneMasks = (selectedCountry, phoneNumber) => {
  if (selectedCountry && phoneNumber) {
    let mask = selectedCountry.masks.find(m => {
      return m.length > phoneNumber.length;
    });
    if (!mask) {
       mask = selectedCountry.masks[0];
    }

    return mask.replace(/-+/gm, '');
  }

  return '';
};

export default getCountryPhoneMasks;
