import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const getCategoriesUser = userId => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/categories-user/user/${userId}`;

        return httpClient(url, { headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default getCategoriesUser;
