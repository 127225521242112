<template>
  <v-dialog
    v-model="dialog"
    fullscreen
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="primary"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="ml-2">Novos usuários cadastrados em {{ formatedDate }}</span>
      </v-card-title>

      <v-progress-linear
        indeterminate
        color="primary"
        v-show="loader"
      ></v-progress-linear>

      <v-card-text class="pt-5">
        <v-container>
          <h3 class="text-center" v-if="!users.length && !loader">Nenhum usuário cadastrado</h3>

          <div
            class="users-list"
            v-if="users.length"
          >
            <v-badge
              :content="getRoleName(user.role, user.isFree)"
              :value="getRoleName(user.role, user.isFree)"
              color="primary"
              :key="user._id"
              v-for="user in users"
              offset-x="30"
              offset-y="10"
            >
              <div
                class="users-list-item"
                @click="goTo(user._id, user.role)"
              >
                <span class="users-list-item--name">{{ user.displayName }}</span>
                <span class="users-list-item--email">{{ user.email }}</span>
              </div>
            </v-badge>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import getNewUsers from '@/api/users/getNewUsers';

export default {
  name: 'DialogNewClients',
  data: () => ({
    dialog: false,
    loader: false,
    user: null,
    role: null,
    currentDate: null,
    users: []
  }),
  computed: {
    formatedDate() {
      if (this.currentDate) {
        return this.currentDate.split('-').reverse().join('/')
      }

      return ''
    }
  },
  methods: {
    openDialog(data) {
      this.currentDate = this.$date().format('YYYY-MM-DD')
      this.user = data.user
      this.role = data.role
      this.dialog = true
      this.getNewUsers()
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.user = null
      this.role = null
    },
    async getNewUsers() {
      try {
        this.loader = true
        this.users = await getNewUsers(
          this.currentDate,
          this.role === 'planner' ? this.user : '',
          this.role === 'company' ? this.user : ''
        )
      } catch (err) {
        this.$noty.error("Desculpe! Não foi possível buscar os novos usuários. Tente novamente mais tarde.")
      } finally {
        this.loader = false
      }
    },
    goTo(_id, role) {
      this.$router.push(`/${role}/${_id}`)
      this.closeDialog()
    },
    getRoleName(role, isFree) {
      switch (role) {
        case 'customer':
          return `Cliente ${isFree ? ' gratuito' : ''}`
        case 'planner':
          return 'Planejador'
        case 'company':
          return 'Empresa'
        default:
          return 'Desconhecida'
      }
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeDialog()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogNewClients', this.openDialog)
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
}

.users-list {
  display: flex;
  flex-direction: column;
}

.users-list-item {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  background-color: #9468D1;
  border-radius: 5px;
  transition: all .5s ease;
  cursor: pointer;
  margin-bottom: 15px;
}

.users-list-item:hover {
  box-shadow: 0 0 1em #000;
}

.users-list-item--name,
.users-list-item--email {
  color: #fff;
  font-size: 16px;
  letter-spacing: .1em;
  line-height: 1.5em;
}

.users-list-item--email {
  font-size: 12px;
}
</style>
