<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    max-width="600px"
    :fullscreen="isMobile"
  >
    <v-card color="grey3" :class="isMobile ? 'no-radius' : ''">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="white--text ml-4">
          {{ category._id ? 'Editar' : 'Adicionar' }} Categoria
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <v-layout row wrap class="px-3">
            <v-flex xs12>
              <h3 class="primary--text input-label">Tipo</h3>
              <v-select
                dense
                color="grey1"
                item-color="primary"
                :items="types"
                item-text="text"
                item-value="value"
                v-model="category.type"
                :error-messages="typeErrors"
              ></v-select>
            </v-flex>

            <v-flex xs12>
              <h3 class="primary--text input-label">Nome</h3>
              <v-text-field
                dense
                type="text"
                color="grey1"
                v-model="category.name"
                :error-messages="nameErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="mb-2 mt-2">
              <v-btn
                dark
                block
                rounded
                depressed
                class="px-5"
                :color="category.color"
                @click="colorMenu = !colorMenu"
                :class="isLight ? 'darkgrey--text' : 'white--text'"
              >
                Escolha a cor
              </v-btn>
            </v-flex>

            <v-flex xs12>
              <v-btn
                block
                dark
                rounded
                depressed
                class="px-5"
                :color="category.color"
                @click="iconMenu = !iconMenu"
                :class="isLight ? 'darkgrey--text' : 'white--text'"
              >
                Escolha o ícone
                <v-icon right>{{ category.icon }}</v-icon>
              </v-btn>
            </v-flex>

            <v-flex xs12 class="mt-5 pt-5 subcat-action">
              <sub class="inner-title primary--text">Sub-categorias</sub>
              <v-chip @click.stop="subcatDialog = true" :disabled="loader">
                <v-icon>mdi-plus</v-icon>
              </v-chip>
            </v-flex>

            <v-flex
              xs12
              v-if="!category.subs.length"
              class="text-center mt-5 inner-title"
            >
              Nenhuma sub-categoria adicionada
            </v-flex>

            <v-flex xs12 class="subs-list">
              <v-slide-x-transition
                :key="sub._id"
                v-for="(sub, i) in category.subs"
              >
                <div class="subs-list-item elevation-2 mb-1">
                  <div class="subs-list-item__content">
                    <span class="sub-name">
                      {{ sub.name }}{{ sub.archived ? '(arquivada)' : '' }}
                      <v-icon small color="grey1" v-if="sub.fixed"
                      >mdi-pin</v-icon
                      >
                    </span>
                  </div>

                  <v-menu bottom left transition="slide-y-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" color="grey1">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click.stop="editSubCategory(sub, i)"
                      >Editar
                      </v-list-item
                      >
                      <v-list-item
                        v-if="sub.__v !== undefined"
                        @click.stop="archiveSubcategory(sub._id)"
                      >
                        {{ sub.archived ? 'Desarquivar' : 'Arquivar' }}
                      </v-list-item>
                      <v-list-item
                        v-else
                        @click.stop="removeSubcategory(sub._id)"
                      >
                        Remover
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-slide-x-transition>
            </v-flex>

            <v-flex xs12 class="mt-5" v-if="category._id">
              <v-checkbox
                color="primary"
                label="Arquivar"
                v-model="category.archived"
              ></v-checkbox>
            </v-flex>
          </v-layout>

          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              fab
              :disabled="!allowed"
              type="submit"
              color="primary"
              :loading="loader"
              class="darkgrey--text mt-5"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>

      <!--      COLORS-->
      <v-bottom-sheet max-width="600px" :inset="!isMobile" v-model="colorMenu">
        <v-card
          color="darkgrey"
          style="border-radius: 15px 15px 0 0 !important"
        >
          <v-card-text class="py-5">
            <v-layout row wrap>
              <v-flex
                xs2
                :key="color"
                class="mb-1 text-center"
                v-for="color in categoryColors"
              >
                <v-btn
                  fab
                  dark
                  small
                  depressed
                  type="button"
                  :color="color"
                  @click.stop="
                    category.color = color;
                    colorMenu = false;
                  "
                ></v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-bottom-sheet>

      <!--      ICONS-->
      <v-bottom-sheet
        scrollable
        max-width="600px"
        :inset="!isMobile"
        v-model="iconMenu"
      >
        <v-card
          height="400px"
          color="darkgrey"
          style="border-radius: 15px 15px 0 0 !important"
        >
          <v-card-text class="py-5">
            <v-card-text>
              <v-layout row wrap>
                <v-flex
                  xs2
                  :key="icon"
                  v-for="icon in categoryIcons"
                  class="mb-5 text-center"
                >
                  <v-btn
                    icon
                    dark
                    depressed
                    type="button"
                    color="darkgrey"
                    @click.stop="
                      category.icon = icon;
                      iconMenu = false;
                    "
                  >
                    <v-icon color="white">{{ icon }}</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card-text>
        </v-card>
      </v-bottom-sheet>

      <v-dialog
        v-model="subcatDialog"
        scrollable
        max-width="500px"
        style="z-index: 10000"
      >
        <v-card>
          <v-card-title>
            <v-btn icon class="mr-2" @click="subcatDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <span>Sub-Categoria</span>
          </v-card-title>

          <v-card-text>
            <v-text-field
              color="grey1"
              label="Nome"
              v-model="currentSubCategory.name"
            ></v-text-field>
            <v-switch
              color="primary"
              :label="`${category.type === 'despesa' ? 'Despesa' : 'Entrada' } Fixa`"
              v-model="currentSubCategory.fixed"
            ></v-switch>

            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn
                dark
                small
                rounded
                depressed
                :disabled="!allowed"
                class="px-5 darkgrey--text"
                color="primary"
                @click.stop="saveSubCategory"
              >
                {{ currentSubCategory._id ? 'Alterar' : 'Adicionar' }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
const tinycolor = require('tinycolor2');
const randomHexColor = require('random-hex-color');
import {sortBy} from 'lodash';
import ObjectID from 'bson-objectid';
import {maxLength, minLength, required} from 'vuelidate/lib/validators';

import categoryIcons from '@/utils/categoryIcons';
import categoryColors from '@/utils/categoryColors';
import saveCategoryUser from '../../../../api/categoriesUser/saveCategoryUser';

export default {
  name: 'DialogCategoryForm',
  data: () => ({
    dialog: false,
    loader: false,
    category: {
      name: '',
      icon: '',
      color: '',
      type: '',
      subs: [],
    },
    types: [
      {text: 'Despesa', value: 'despesa'},
      {text: 'Receita', value: 'receita'},
    ],
    categoryColors,
    colorMenu: false,
    categoryIcons,
    iconMenu: false,
    subcatDialog: false,
    currentSubCategory: {
      name: '',
      fixed: false,
    },
    subcatMenu: false,
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed;
    },
    isLight() {
      return tinycolor(this.category.color).isLight();
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.category.type.$dirty) return errors;
      !this.$v.category.type.required && errors.push('Selecione o tipo');
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.category.name.$dirty) return errors;
      !this.$v.category.name.required && errors.push('Insira o nome');
      !this.$v.category.name.minLength &&
      errors.push('O nome deve conter, no mínimo, 3 caracteres');
      !this.$v.category.name.maxLength &&
      errors.push('O nome deve conter, no máximo, 32 caracteres');
      return errors;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.category = {
        name: '',
        icon: '',
        color: '',
        type: '',
        subs: [],
      };
      this.$v.$reset();
    },
    saveSubCategory() {
      if (this.allowed) {
        if (this.currentSubCategory._id !== undefined) {
          let subCategory = this.category.subs.find(
            (sc) => sc._id === this.currentSubCategory._id
          );

          if (!subCategory) {
            subCategory = this.category.subs[this.currentSubCategory._id];
            delete this.currentSubCategory._id;
          }

          let subCategoryIndex = this.category.subs.indexOf(subCategory);
          this.category.subs.splice(
            subCategoryIndex,
            1,
            this.currentSubCategory
          );
        } else {
          this.currentSubCategory._id = ObjectID.generate();
          this.category.subs.push(this.currentSubCategory);
        }

        this.subcatDialog = false;
        this.currentSubCategory = {
          name: '',
          fixed: false,
        };
      }
    },
    editSubCategory(subCategory, index) {
      if (!subCategory._id) subCategory._id = index;
      this.currentSubCategory = JSON.parse(JSON.stringify(subCategory));
      this.subcatDialog = true;
    },
    removeSubcategory(id) {
      let index = this.category.subs.findIndex((sub) => sub._id === id);
      if (index > -1) this.category.subs.splice(index, 1);
    },
    archiveSubcategory(id) {
      let index = this.category.subs.findIndex((sub) => sub._id === id);
      if (index > -1) {
        this.category.subs[index].archived = !this.category.subs[index]
          .archived;
      }
    },
    sort(list) {
      return sortBy(list, 'name');
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError && this.allowed) {
        this.loader = true;

        if (!this.category.user) this.category.user = this.user._id;

        saveCategoryUser(this.category)
          .then((category) => {
            this.$store.dispatch('saveCategory', category);
            this.close();
            this.$noty.success('Categoria salva com sucesso!');
          })
          .catch((err) => {
            this.loader = false;
            this.$noty.error(
              'Desculpe! Não foi possível salvar esta categoria. Tente novamente!'
            );
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
  },
  validations: {
    category: {
      type: {required},
      name: {required, minLength: minLength(3), maxLength: maxLength(32)},
    },
  },
  created() {
    this.$bus.$on('showDialogFormCategory', (category) => {
      if (category) this.category = JSON.parse(JSON.stringify(category));
      else {
        this.category.color = randomHexColor();
        this.category.icon = this.categoryIcons[0];
      }

      this.dialog = true;
    });
  },
};
</script>

<style scoped>
.dialog-form-title {
  background-color: #131313 !important;
}

.input-label {
  font-size: 9px;
  line-height: 9px;
  letter-spacing: 0.1em;
}

.title-condensed {
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  letter-spacing: 0.1em;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container .input-label {
  font-size: 12px;
  color: white !important;
  cursor: pointer;
}

.subcat-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner-title {
  font-size: 12px;
  letter-spacing: 0.1em;
}

.subs-list {
  max-height: 200px !important;
  margin-top: 10px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 5px;
}

.subs-list::-webkit-scrollbar {
  width: 4px;
}

.subs-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.subs-list::-webkit-scrollbar-thumb {
  background-color: #47484d;
  outline: 5px solid slategrey;
  border-radius: 2px;
}

.subs-list-item {
  padding: 10px 5px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #131313;
  border-radius: 5px;
}

.sub-name {
  font-size: 12px;
  letter-spacing: 0.1em;
}

@media only screen and (min-width: 600px) {
  .input-label {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.1em;
    font-weight: 400;
  }

  .checkbox-container .input-label {
    font-size: 12px;
    color: white !important;
  }
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 788px;
  }

  .checkbox-container .input-label {
    font-size: 16px;
  }

  .v-card__title.profile-title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 1264px) {
  .container {
    max-width: 1020px;
  }
}
</style>
