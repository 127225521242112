import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar lista paginada de usuários
 * @param {number} page
 * @param {number} limit
 * @param {object} filter {search, status, type, company, planner}
 */
const getUsers = (page = 1, limit = 10, filter = {}) =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        if (filter.search === undefined) filter.search = '';
        if (filter.status === undefined) filter.status = '';
        if (filter.type === undefined) filter.type = '';
        if (filter.roles === undefined) filter.roles = '';

        let url = `/users?page=${page}&limit=${limit}&`;

        if (filter.type) url += `type=${filter.type}&`
        if (filter.roles) url += `roles=${filter.roles}&`
        if (filter.search) url += `search=${filter.search}&`
        if (filter.status) url += `status=${filter.status}&`
        if (filter.company) url += `companyId=${filter.company}&`
        if (filter.planner) url += `plannerId=${filter.planner}&`
        if (filter.supervisor) url += `supervisorId=${filter.supervisor}&`

        return httpClient(url, {
          headers: { Authorization: `Bearer ${idToken}` }
        });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });

export default getUsers;
