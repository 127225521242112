<template>
  <v-card outlined height="100%" class="d-flex flex-column justify-space-between">
    <v-card-text>
      <div class="d-flex flex-column ">
        <span class="pat-item-title primary--text">{{ item.descricao }}</span>
        <span class="pat-item-type">{{ item.tipo }}</span>
      </div>

      <div class="d-flex flex-column mt-2">
        <div class="pat-item-info-desc d-flex justify-space-between">
          <div class="d-flex flex-column">
            <span class="input-label">Valor atual</span>
            <span class="white--text input-label">{{ numberToCurrency(item.valorAtual) }}</span>
          </div>

          <div class="d-flex flex-column" v-if="item.tipo !== 'Aplicações financeiras'">
            <span class="input-label">Saldo a pagar</span>
            <span class="white--text input-label">{{ numberToCurrency(item.saldoPagar) }}</span>
          </div>
        </div>

        <div class="pat-item-info-desc d-flex justify-space-between">
          <div class="d-flex flex-column">
            <span class="input-label">Proprietário</span>
            <span class="white--text input-label">{{ item.proprietario }}</span>
          </div>

          <div class="d-flex flex-column" v-if="item.tipo !== 'Aplicações financeiras'">
            <span class="input-label">Taxa</span>
            <span class="white--text input-label">{{ item.taxa.toFixed(2) }}%</span>
          </div>
        </div>

        <div class="pat-item-info-desc d-flex justify-space-between" v-if="item.obs">
          <div class="d-flex flex-column">
            <span class="input-label">Observação</span>
            <span class="white--text input-label">{{ item.obs }}</span>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-actions class="pt-0" v-if="actions && allowed">
      <v-spacer></v-spacer>

      <v-btn
        icon
        color="error"
        @click.stop="deleteItem(item._id)"
        :loading="deleteLoader === item._id"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>

      <v-btn
        icon
        color="white"
        @click.stop="showForm"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';
import deleteItemPatrimonio from '@/api/patrimonio/deleteItemPatrimonio';

export default {
  name: 'CardItemPatrimonio',
  props: ['item', 'actions'],
  data: () => ({
    numberToCurrency,
    deleteLoader: false
  }),
  computed: {
    patrimonio() {
      return this.$store.getters.patrimonio
    },
    allowed() {
      return this.$store.getters.allowed
    }
  },
  methods: {
    showForm() {
      this.$bus.$emit('showDialogFormPatrimonio', this.item)
    },
    async deleteItem() {
      try {
        this.deleteLoader = true

        await deleteItemPatrimonio(this.patrimonio._id, this.item._id)

        let index = this.patrimonio.items.findIndex(item => item._id === this.item._id)

        if (index > -1) {
          this.patrimonio.items.splice(index, 1)
        }
      } catch (err) {
        this.$noty.error('Desculpe. Não conseguimos remover este item. Tente novamente mais tarde')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.deleteLoader = false
      }
    }
  }
}
</script>

<style scoped>
.pat-item-title {
  font-size: 12px !important;
  font-weight: bold;
  letter-spacing: .1em !important;
  text-transform: uppercase;
  line-height: 1em;
}

.pat-item-type {
  font-size: 11px;
  letter-spacing: .1em !important;
}

.pat-item-info-desc {
  margin-top: 5px;
}

.pat-item-info-desc div {
  width: 50%;
}
</style>
