<template>
  <v-container grid-list-md class="pa-0 ma-0">
    <v-layout row wrap class="pa-0 ma-0">
      <v-flex xs12>
        <span class="input-label primary--text">Quando você pagou está fatura?</span>
        <v-dialog
          ref="dialog"
          v-model="periodMenu"
          :return-value.sync="period"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mt-0 pt-0"
              :value="formatedPeriod"
              readonly
              v-bind="attrs"
              v-on="on"
              :loading="transactionsLoader"
            >
              <v-icon
                color="grey1"
                class="input-icon"
                slot="prepend-inner"
              >
                mdi-calendar
              </v-icon>
            </v-text-field>
          </template>

          <v-date-picker
            no-title
            scrollable
            type="month"
            locale="pt-br"
            color="primary"
            v-model="period"
            :max="limitPeriod"
            @input="$refs.dialog.save(period); periodMenu = false;"
          ></v-date-picker>
        </v-dialog>
      </v-flex>

      <v-expand-transition>
        <v-flex xs12 v-if="!transactionsLoader && period">
          <h2 class="text-center" v-if="!transactions.length">
            Nenhuma transação encontrada
          </h2>

          <div v-if="transactions.length">
            <span class="input-label primary--text mb-5">Selecione a transação que corresponde ao pagamento</span>

            <div class="tr-list mt-3">
              <v-card
                class="mb-2"
                :color="transaction === tr._id ? 'primary' : 'darkgrey'"
                v-for="tr in transactions"
                :key="tr._id"
                @click.stop="transaction = tr._id"
              >
                <v-card-text>
                  <div>
                    <div
                      class="tr-list_item-label"
                      :class="transaction === tr._id ? 'darkgrey--text' : 'white--text'"
                    >
                      {{ tr.description }}
                    </div>

                    <div
                      class="tr-list_item-label mt-1"
                      :class="transaction === tr._id ? 'darkgrey--text' : 'primary--text'"
                    >
                      {{ numberToCurrency(tr.value) }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-flex>
      </v-expand-transition>
    </v-layout>
  </v-container>
</template>

<script>
import numberToCurrency from "@/utils/numberToCurrency"
import getNotRevisedTransactionsByBankAccount from "@/api/transactions/getNotRevisedTransactionsByBankAccount";

export default {
  name: 'FormInvoicePaymentFromTransaction',
  props: ['bankAccount'],
  data: () => ({
    numberToCurrency,
    period: null,
    periodMenu: false,
    transactions: [],
    transaction: null,
    transactionsLoader: false
  }),
  validations: {
    period: {
      isValid(v) {
        return !!v && this.$date(v).isValid()
      }
    },
    transaction: {
      required: v => !!v
    }
  },
  computed: {
    limitPeriod() {
      return this.$date().format('YYYY-MM')
    },
    formatedPeriod() {
      if (this.period) {
        return this.$date(this.period).format('MMMM YYYY').toUpperCase()
      }
    },
    periodErrors() {
      if (!this.$v.period.$anyDirty)
        return []

      if (this.$v.period.$anyError)
        return ['Selecione um período válido']
    }
  },
  methods: {
    async getTransactions() {
      try {
        this.transactionsLoader = true

        if (this.bankAccount && this.period) {
          this.transactions = await getNotRevisedTransactionsByBankAccount(this.bankAccount, this.period)
        } else {
          this.transactions = []
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.transactions = []
      } finally {
        this.transactionsLoader = false
      }
    },
    getTransaction() {
      if (this.transaction) {
        return this.transactions.find(tr => tr._id === this.transaction)
      }
    }
  },
  watch: {
    period() {
      this.getTransactions()
      this.transaction = null
    },
    bankAccount() {
      this.getTransactions()
    }
  }
}
</script>

<style scoped>
.tr-list {
  display: flex;
  flex-direction: column;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
}

.tr-list_item-label {
  font-size: 12px;
  line-height: 1em;
}
</style>
