import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'
import store from '@/store'

const uploadUsers = data => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            data,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${idToken}`,
              'Content-Type': 'multipart/form-data',
              role: store.getters.user ? store.getters.user.role : null
            }
          }

          const url = '/users/upload'

          return httpClient(url, options)
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default uploadUsers
