import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const countCompanyEmployees = companyId => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const options = {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        let url = `/companies/${companyId}/count/employees`

        return httpClient(url, options)
      })
      .then(result => resolve(result.data))
      .catch(reject)
  })
}

export default countCompanyEmployees
