<template>
  <v-card
    flat
    color="transparent"
  >
    <v-card-text class="text-center">
      <v-avatar
        size="164"
        color="grey1"
        class="elevation-3"
      >
        <img
          v-if="user.photoURL"
          :src="user.photoURL"
        />
        <v-icon
          v-else
          size="100"
          color="grey lighten-2"
        >
          mdi-account
        </v-icon>
      </v-avatar>

      <div class="company-name mt-5 font-weight-bold">
        {{ user.displayName }}
        <br />
        <input type="file" id="input-file" ref="file" @change="readFile" />
        <label for="input-file" id="input-button">
          <v-chip
            small
            class="mt-2 avatar-icon"
          >{{ user.photoURL ? 'Trocar foto' : 'Adicionar foto' }}</v-chip>
        </label>
      </div>
    </v-card-text>

    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import Jimp from 'jimp';
import firebase from 'firebase/app';
import saveUser from '../../../api/users/saveUser'

export default {
  name: 'CardProfileImage',
  data: () => ({
    photoURL: null,
    loader: false
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    readFile($event) {
      const file = $event.target.files[0] || $event.dataTransfer.files[0];

      if (file.type && /^image\/.+/gm.test(file.type)) {
        this.loader = true;
        let self = this;
        var reader = new FileReader();

        reader.onload = function() {
          Jimp.read(reader.result)
            .then(image => {
              image.scaleToFit(200, Jimp.AUTO, Jimp.RESIZE_BEZIER);
              image.quality(60);
              return image.getBase64Async(Jimp.MIME_JPEG);
            })
            .then(base64 => {
              self.photoURL = base64;
              let storageRef = firebase.storage().ref();
              let profileRef = storageRef.child(`${self.user._id}.jpeg`);
              return profileRef.putString(base64, 'data_url', {
                contentType: 'image/jpeg'
              });
            })
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then(url =>
              firebase.auth().currentUser.updateProfile({ photoURL: url })
            )
            .then(() => {
              let user = JSON.parse(JSON.stringify(self.user));
              user.photoURL = firebase.auth().currentUser.photoURL;
              return saveUser(user);
            })
            .then(user => {
              self.$store.dispatch('setUser', user);
              self.loader = false;
            })
            .catch(err => {
              // eslint-disable-next-line no-undef
              if (process.env.NODE_ENV !== 'production') console.error(err);
              self.loader = false;
              self.$noty.error('Falha ao atualizar foto. Tente novamente!');
            });
        };

        reader.readAsDataURL(file);
      } else {
        this.photoURL = null;
        this.$noty.warning('Arquivo de imagem inválido!');
      }
    }
  }
};
</script>

<style scoped>
#input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.avatar-icon {
  cursor: pointer;
}
</style>
