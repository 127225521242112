import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const saveLifeInsuranceInventoryAsset = (lifeInsuranceId, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            data,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }

          let url = `/life-insurances/${lifeInsuranceId}/other-assets`

          if (data._id) {
            options.method = 'PUT'
            url += `/${data._id}`
          }

          return httpClient(url, options)
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default saveLifeInsuranceInventoryAsset
