<template>
  <v-card outlined height="100%" class="d-flex flex-column justify-space-between">
    <v-card-text>
      <div class="d-flex flex-column ">
        <span class="pat-item-title primary--text">{{ item.descricao }}</span>
        <span class="pat-item-type">{{ item.tipo }}</span>
      </div>

      <div class="d-flex flex-column mt-2">
        <div class="pat-item-info-desc d-flex justify-space-between">
          <div class="d-flex flex-column">
            <span class="input-label">Valor</span>
            <span class="white--text input-label">{{ numberToCurrency(item.valor) }}</span>
          </div>

          <div class="d-flex flex-column">
            <span class="input-label">Data</span>
            <span class="white--text input-label">{{ formatDate(item.data) }}</span>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-actions class="pt-0" v-if="actions && allowed">
      <v-spacer></v-spacer>

      <v-btn
        icon
        color="error"
        @click.stop="deleteItem(item._id)"
        :loading="deleteLoader === item._id"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>

      <v-btn
        icon
        color="white"
        @click.stop="showForm"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { capitalize } from 'lodash'
import numberToCurrency from "../../../../utils/numberToCurrency"
import deleteProjetoIndFinanceira from '@/api/indFinanceira/deleteProjetoIndFinanceira';

export default {
  name: 'CardItemProjetoVida',
  props: ['item', 'actions'],
  data: () => ({
    capitalize,
    numberToCurrency,
    deleteLoader: false
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed;
    },
    indFinanceira() {
      return this.$store.getters.independenciaFinanceira
    }
  },
  methods: {
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY')
      }

      return ''
    },
    deleteItem() {
      this.$store.dispatch('removeProjetoIndFinanceira', this.item._id);
      deleteProjetoIndFinanceira(this.indFinanceira._id, this.item._id)
    },
    showForm() {
      this.$bus.$emit('showDialogProjetoVida', this.item)
    }
  }
}
</script>

<style scoped>
.pat-item-title {
  font-size: 12px !important;
  font-weight: bold;
  letter-spacing: .1em !important;
  text-transform: uppercase;
  line-height: 1em;
}

.pat-item-type {
  font-size: 11px;
  letter-spacing: .1em !important;
}

.pat-item-info-desc {
  margin-top: 5px;
}

.pat-item-info-desc div {
  width: 50%;
}
</style>
