<template>
  <v-card color="grey3" height="100%">
    <v-card-text class="d-flex align-center">
      <div class="card-avatar hidden-xs-only">
        <v-avatar color="transparent">
          <v-icon color="primary">{{ icon }}</v-icon>
        </v-avatar>
      </div>

      <div class="d-flex flex-column align-start pl-2">
        <span class="total-number">{{ total }}</span>
        <span class="total-number-desc primary--text">Faturas {{ statusName }}</span>
      </div>
    </v-card-text>

    <v-progress-linear
      v-show="loader"
      indeterminate
      color="primary"
    ></v-progress-linear>
  </v-card>
</template>

<script>
import getIuguCustomerInvoices from '@/api/iugu/getIuguCustomerInvoices';

export default {
  name: 'CardTotalInvoices',
  props: ['status', 'icon', 'companyId'],
  data: () => ({
    total: 0,
    loader: true
  }),
  computed: {
    statusName() {
      if (this.status) {
        return this.status === 'paid' ? 'Pagas' : 'Pendentes'
      }
      return ''
    }
  },
  methods: {
    countInvoices() {
      getIuguCustomerInvoices(this.companyId, 1, this.status, true)
        .then(resp => {
          this.total = resp.totalItems
          this.loader = false
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }

          this.total = 0
          this.loader = false
        })
    }
  },
  mounted() {
    this.countInvoices()
  }
}
</script>

<style scoped>
.total-number {
  font-size: 15px;
  font-weight: bold;
}

.total-number-desc {
  font-size: 9px;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 375px) {
  .total-number {
    font-size: 15px;
  }
}

@media only screen and (min-width: 414px) {
  .total-number {
    font-size: 18px;
  }

  .total-number-desc {
    font-size: 10px;
  }
}
</style>
