<template>
  <v-card color="transparent" flat>
    <v-card-text class="px-0" v-if="perfil">
      <v-container fluid grid-list-md class="px-0">
        <v-layout row wrap>
          <v-flex
            xs12 md4
            :key="item._id"
            v-for="item in seguros"
          >
            <CardItemProtecaoFamiliar :item="item" :actions="allowed"/>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-card-actions v-if="allowed">
      <v-spacer></v-spacer>

      <v-btn
        v-if="allowed"
        outlined
        rounded
        color="primary"
        class="px-10 text-capitalize"
        @click="showDialogFormSeguroVidaPerfil"
      >
        Adicionar Proteção
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';
import CardItemProtecaoFamiliar from '@/components/Customer/Perfil/cards/CardItemProtecaoFamiliar';

export default {
  name: 'CardProtecaoFamiliar',
  components: { CardItemProtecaoFamiliar },
  data: () => ({
    numberToCurrency
  }),
  computed: {
    perfil() {
      return this.$store.getters.perfil;
    },
    seguros() {
      return this.perfil.protecaoFamiliar.seguros;
    },
    allowed() {
      return this.$store.getters.allowed;
    }
  },
  methods: {
    showDialogFormSeguroVidaPerfil() {
      this.$bus.$emit('showDialogFormSeguroVidaPerfil')
    },
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY')
      }

      return ''
    }
  }
}
</script>
