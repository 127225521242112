import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar lista de nomes dos planejadores cadastrados
 */
const getPlannersNames = (supervisorId) =>
  new Promise((resolve, reject) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          let headers = { Authorization: `Bearer ${idToken}` };

          let url = '/planners/names'

          if (supervisorId) {
            url += `?supervisor=${supervisorId}`
          }

          return httpClient(url, { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });

export default getPlannersNames;
