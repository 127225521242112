<template>
  <v-main>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <CardProfileImage />
        </v-flex>

        <v-flex lg4 offset-lg4 md6 offset-md3 sm8 offset-sm2 xs12>
          <CardFormProfile />
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import getLoggedUserData from '../../utils/getLoggedUserData';

import Menu from '../../components/Global/Menu';
import CardProfileImage from '../../components/Profile/cards/CardProfileImage';
import CardFormProfile from '../../components/Profile/cards/CardFormProfile';
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

export default {
  name: 'Profile',
  // metaInfo() {
  //   return {
  //     title: 'Configurações de acesso',
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo);
  //     },
  //   };
  // },
  components: { CardFormProfile, CardProfileImage, Menu },
  created() {
    getLoggedUserData();
  },
};
</script>

<style scoped>
.container {
  margin-top: 46px
}
</style>
