import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar lista de cidades de determinado estado
 * @param {number} idEstado
 */
const getCidadesByEstado = idEstado =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        return httpClient(`/address/cidades/${idEstado}`, { headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });

export default getCidadesByEstado;
