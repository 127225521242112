import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const getUserCreditCards = (userId, archived = false) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/credit-cards/users/${userId}?archived=${archived}`;

        return httpClient(url, { headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default getUserCreditCards;
