<template>
  <div>
    <v-data-table
      dense
      :loading="loader"
      :headers="headers"
      :items="categories"
      hide-default-footer
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-center py-1 hidden-xs-only" style="width: 90px;">
            <v-icon :color="props.item.color">{{ props.item.icon }}</v-icon>
          </td>

          <td style="width: 100px;" class="hidden-xs-only">
            <v-chip
              small
              :color="props.item.type === 'despesa' ? 'error' : 'success'"
              class="white--text"
            >{{ getType(props.item.type) }}</v-chip>
          </td>

          <td>{{ props.item.name }}</td>

          <td class="text-center" style="width: 90px;">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click.stop="showDialogFormAdminCategory(props.item)">
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>

                <v-list-item @click.stop="showDialogRemoveAdminCategory(props.item._id)">
                  <v-list-item-title>Remover</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="text-center mt-5">
      <v-pagination
        circle
        color="primary"
        v-model="pagination.page"
        :length="pagination.totalPages"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import getAdminCategories from '@/api/categories/getAdminCategories';
import countAdminCategories from '@/api/categories/countAdminCategories';

export default {
  name: 'TableCategories',
  data: () => ({
    pagination: {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      itemsPerPage: 10
    },
    categories: [],
    headers: [
      {
        text: '',
        value: 'icon',
        align: 'center',
        sortable: false,
        class: 'hidden-xs-only'
      },
      {
        text: 'Tipo',
        value: 'type',
        align: 'left',
        sortable: true,
        class: 'hidden-xs-only'
      },
      { text: 'Categoria', value: 'name', align: 'left', sortable: true },
      { text: 'Opções', value: '', align: 'center', sortable: false }
    ],
    loader: false,
    filter: {
      type: '',
      search: ''
    },
    menu: false
  }),
  methods: {
    getCategories(isNew) {
      this.loader = true;

      if (isNew) this.pagination.page = 1;

      getAdminCategories(
          this.pagination.page,
          this.pagination.itemsPerPage,
          this.filter.type,
          this.filter.search
        )
        .then(result => {
          this.pagination.totalItems = result.totalItems;
          this.pagination.totalPages = Math.ceil(
            this.pagination.totalItems / this.pagination.itemsPerPage
          );

          this.categories = result.categories
          this.loader = false;
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
        });
    },
    getType(type) {
      if (type === 'despesa') return 'Despesa';
      else return 'Renda';
    },
    showDialogFormAdminCategory(category) {
      this.$bus.$emit('showDialogFormAdminCategory', category);
    },
    showDialogRemoveAdminCategory(categoryId) {
      this.$bus.$emit('showDialogRemoveAdminCategory', categoryId);
    }
  },
  watch: {
    'pagination.page'() {
      this.getCategories(false);
    }
  },
  created() {
    this.$bus.$on('getAdminCategories', filter => {
      if (filter) this.filter = filter;

      this.getCategories(true);
    });
  }
};
</script>
