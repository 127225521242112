import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

export default data => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const options = {
          data,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        return httpClient('/credit-cards/from-pendent', options)
      })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}
