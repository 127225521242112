import httpClient from '@/plugins/httpClient'
import config from '@/config';

const getPlannerCalendlyEvents = token => {
  return new Promise((resolve, reject) => {
    try {
      const options = {
        headers: {
          'X-TOKEN': token
        }
      }

      httpClient(`${config.calendly.api}/users/me/event_types`, options)
        .then(resp => resolve(resp.data.data))
        .catch(reject)
    } catch(err) {
      reject(err)
    }
  })
}

export default getPlannerCalendlyEvents
