<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="500px"
    :fullscreen="isMobile"
  >
    <v-card color="grey3" v-if="projeto">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="grey1"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-0">
        <v-list two-line color="grey3">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="grey1">mdi-card-text-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ projeto.descricao }}</v-list-item-title>
              <v-list-item-subtitle>Projeto</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="grey1">mdi-clipboard-flow</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ capitalize(projeto.tipo) }}</v-list-item-title>
              <v-list-item-subtitle>Data</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="grey1">mdi-calendar</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ formatDate(projeto.data) }}</v-list-item-title>
              <v-list-item-subtitle>Data</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="grey1">mdi-cash</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ numberToCurrency(projeto.valor) }}</v-list-item-title>
              <v-list-item-subtitle>Valor</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="projeto.recorrente">
            <v-list-item-avatar>
              <v-icon color="grey1">mdi-refresh</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ formatInterval(projeto.tipoPeriodo) }}</v-list-item-title>
              <v-list-item-subtitle>Intervalo de recorrência</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions v-if="unlock">
        <v-spacer></v-spacer>
        <v-btn large icon color="white" @click="showDialogProjetoVida(projeto)" :disabled="!allowed">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn large icon color="error" @click="showDialogRemoveProjetoIndFinanceira(projeto._id)" :disabled="!allowed">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment-timezone'
  import { capitalize } from 'lodash'
  import numberToCurrency from "../../../../utils/numberToCurrency";

  export default {
    name: 'DialogDetalhesProjetoVida',
    data: () => ({
      dialog: false,
      projeto: null,
      numberToCurrency,
      capitalize
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      unlock() {
        return this.indFinanceira.period === moment().format('YYYY-MM');
      },
      allowed() {
        return this.$store.getters.allowed;
      }
    },
    methods: {
      close() {
        this.dialog = false;
        this.projeto = null;
      },
      formatDate(date) {
        if (date)
          return date.split('-').reverse().join('/');

        return '';
      },
      formatInterval(interval) {
        switch (interval) {
          case 'years':
            return 'Anual';
          case 'months':
            return 'Mensal';
          case 'weeks':
            return 'Semanal';
          default:
            return '';
        }
      },
      showDialogProjetoVida(projeto) {
        if (this.allowed) {
          this.$bus.$emit('showDialogProjetoVida', projeto);
          this.close();
        }
      },
      showDialogRemoveProjetoIndFinanceira(projetoId) {
        if (this.allowed) {
          this.$bus.$emit('showDialogRemoveProjetoIndFinanceira', projetoId);
          this.close();
        }
      }
    },
    created() {
      this.$bus.$on('showDialogDetalhesProjetoVida', projeto => {
        if (projeto) {
          this.projeto = JSON.parse(JSON.stringify(projeto));
          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
  .dialog-form-title {
    background-color: transparent !important;
  }
</style>
