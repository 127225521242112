<template>
  <div class="action-plans-list mt-5">
    <v-slide-x-transition
      :key="plan._id"
      v-for="plan in filteredPlans"
    >
      <CardListPlanosAcao :action-plan="plan" class="mb-2"/>
    </v-slide-x-transition>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import CardListPlanosAcao from '@/components/Customer/PlanosAcao/cards/CardListPlanosAcao';


export default {
  name: 'ListPlanosAcao',
  components: { CardListPlanosAcao },
  props: ['completed'],
  data: () => ({
    loader: null,
    type: ''
  }),
  computed: {
    actionPlans() {
      return this.$store.getters.actionPlans;
    },
    filteredPlans() {
      if (this.actionPlans) {
        return orderBy(this.actionPlans.filter(p => (!this.type || p.type.value === this.type) && p.completed === this.completed),['type.value', 'deadline', 'description'])
      }
    }
  },
  created() {
    this.$bus.$on('filterActionPlans', type => {
      if (!this.type || this.type !== type) {
        this.type = type;
      } else {
        this.type = '';
      }
    });
  }
}
</script>

<style scoped>
.action-plans-list {
  display: flex;
  flex-direction: column;
}


</style>
