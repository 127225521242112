<template>
  <v-main v-if="!user">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <div class="loader-container">
          <v-progress-circular :size="50" color="accent" indeterminate class="mb-3"></v-progress-circular>
          <div class="title">Carregando...</div>
        </div>
      </v-layout>
    </v-container>
  </v-main>

  <v-main v-else>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat color="transparent">
            <v-card-title>EAD</v-card-title>
          </v-card>
        </v-flex>

        <v-flex xs12>
          <CardAdminVideos />
        </v-flex>
      </v-layout>
    </v-container>

    <DialogFormVideo />
    <DialogRemoveVideo />
    <DialogVideoDetails />
  </v-main>
</template>

<script>
import getLoggedUserData from '../../utils/getLoggedUserData';

import Menu from '@/components/Global/Menu';
import CardAdminVideos from '@/components/Ead/cards/CardAdminVideos';
import DialogFormVideo from '@/components/Ead/dialogs/DialogFormVideo';
import DialogRemoveVideo from '@/components/Ead/dialogs/DialogRemoveVideo';
import DialogVideoDetails from '@/components/Ead/dialogs/DialogVideoDetails';
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

export default {
  name: 'EAD',
  // metaInfo() {
  //   return {
  //     title: "EAD",
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo)
  //     }
  //   };
  // },
  components: {
    Menu,
    CardAdminVideos,
    DialogFormVideo,
    DialogRemoveVideo,
    DialogVideoDetails
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  created() {
    if (!this.user) getLoggedUserData();
  }
};
</script>

<style scoped>
.container {
  max-width: 960px;
  margin-top: 46px;
  margin-right: auto;
  margin-left: auto;
}
</style>
