<template>
  <v-card color="transparent" flat>
    <v-card-text class="px-0" v-if="perfil">
      <v-container fluid grid-list-md class="px-0" v-if="usuario">
        <v-layout row wrap>
          <v-flex xs12 v-if="conjuge">
            <h3>{{ perfil.informacoesBasicas.usuario.nome }}</h3>
          </v-flex>

          <v-flex xs12>
            <h4>Ocupação atual</h4>
          </v-flex>

          <v-flex xs12 sm6 md4 lg3 class="d-flex flex-column">
            <span class="input-label primary--text">Cargo</span>
            <span class="input-label">{{ usuario.ocupacaoAtual.cargo }}</span>
          </v-flex>

          <v-flex xs12 sm6 md4 lg3 class="d-flex flex-column">
            <span class="input-label primary--text">Remuneração líquida atual</span>
            <span class="input-label">{{ numberToCurrency(usuario.ocupacaoAtual.remuneracaoAtual) }}</span>
          </v-flex>

          <v-flex xs12 sm12 md7 class="d-flex flex-column mt-5">
            <span class="input-label primary--text">Primeira experiência profissional</span>
            <span class="input-label">{{ usuario.primeiraExperiencia }}</span>
          </v-flex>

          <v-flex xs12 v-if="usuario.extras.length" class="mt-5">
            <h4>Fontes de renda extra</h4>
          </v-flex>

          <v-flex
            xs12 sm6 md4 lg3
            :key="extra._id"
            v-for="extra in usuario.extras"
          >
            <v-card color="darkgrey">
              <v-card-text class="d-flex flex-column">
                <span class="input-label primary--text">{{ extra.descricao }}</span>
                <span class="input-label">{{ numberToCurrency(extra.renda) }}</span>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

      <v-divider v-if="conjuge"></v-divider>

      <v-container fluid grid-list-md class="px-0" v-if="conjuge">
        <v-layout row wrap>
          <v-flex xs12 v-if="perfil.informacoesBasicas.conjuge && perfil.informacoesBasicas.conjuge.nome">
            <h3>{{ perfil.informacoesBasicas.conjuge.nome }}</h3>
          </v-flex>

          <v-flex xs12>
            <h4>Ocupação atual</h4>
          </v-flex>

          <v-flex xs12 sm6 md4 lg3 class="d-flex flex-column">
            <span class="input-label primary--text">Cargo</span>
            <span class="input-label">{{ conjuge.ocupacaoAtual.cargo }}</span>
          </v-flex>

          <v-flex xs12 sm6 md4 lg3 class="d-flex flex-column">
            <span class="input-label primary--text">Remuneração líquida atual</span>
            <span class="input-label">{{ numberToCurrency(conjuge.ocupacaoAtual.remuneracaoAtual) }}</span>
          </v-flex>

          <v-flex xs12 sm12 md7 class="d-flex flex-column mt-5">
            <span class="input-label primary--text">Primeira experiência profissional</span>
            <span class="input-label">{{ conjuge.primeiraExperiencia }}</span>
          </v-flex>

          <v-flex xs12 v-if="conjuge.extras.length" class="mt-5">
            <h4>Fontes de renda extra</h4>
          </v-flex>

          <v-flex
            xs12 sm6 md4 lg3
            :key="extra._id"
            v-for="extra in conjuge.extras"
          >
            <v-card color="darkgrey">
              <v-card-text class="d-flex flex-column">
                <span class="input-label primary--text">{{ extra.descricao }}</span>
                <span class="input-label">{{ numberToCurrency(extra.renda) }}</span>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-card-actions class="pb-5">
      <v-spacer></v-spacer>

      <v-btn
        v-if="allowed"
        outlined
        rounded
        color="primary"
        class="px-10 text-capitalize"
        @click="showDialogEditOcupacaoProfissional"
      >
        Editar
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';

export default {
  name: 'CardOcupacaoProfissional',
  data: () => ({
    numberToCurrency
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed
    },
    perfil() {
      return this.$store.getters.perfil
    },
    usuario() {
      if (this.perfil && this.perfil.ocupacaoProfissional) {
        return this.perfil.ocupacaoProfissional.usuario
      }

      return null
    },
    conjuge() {
      if (this.perfil && this.perfil.ocupacaoProfissional) {
        return this.perfil.ocupacaoProfissional.conjuge
      }

      return null
    }
  },
  methods: {
    showDialogEditOcupacaoProfissional() {
      if (this.allowed)
        this.$bus.$emit('showDialogEditOcupacaoProfissional');
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .input-label {
    font-size: 14px;
  }
}
</style>
