<template>
  <v-form @submit.prevent="submit">
    <div class="input-container">
      <span class="input-label primary--text">Crie sua senha</span>
      <v-text-field
        dense
        color="primary"
        v-model="password"
        :error-messages="passwordErrors"
        @click:append="showPass = !showPass"
        :type="!showPass ? 'password' : 'text'"
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
      ></v-text-field>
    </div>

    <div class="input-container mb-5">
      <span class="input-label primary--text">Repita a senha</span>
      <v-text-field
        dense
        color="primary"
        type="password"
        v-model="repeatPassword"
        @input="$v.repeatPassword.$touch()"
        :error-messages="repeatPasswordErrors"
      ></v-text-field>
    </div>

    <v-btn
      block
      rounded
      type="submit"
      color="primary"
      :loading="loader"
      class="darkgrey--text"
    >
      Criar senha
    </v-btn>
  </v-form>
</template>

<script>
import firebase from 'firebase/app'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import updateUserByEmail from '@/api/users/updateUserByEmail';

export default {
  name: 'FormCreatePassword',
  props: ['email'],
  data: () => ({
    loader: false,
    password: '',
    repeatPassword: '',
    showPass: false
  }),
  validations: {
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(32)
    },
    repeatPassword: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(32),
      sameAs(v) {
        return v === this.password
      }
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    passwordErrors() {
      const errors = []

      if (!this.$v.password.$dirty) {
        return errors
      }

      !this.$v.password.required && errors.push('Preencha a senha')
      !this.$v.password.minLength && errors.push('A senha deve conter, no mínimo, 8 caracteres')
      !this.$v.password.maxLength && errors.push('A senha deve conter, no máximo, 32 caracteres')

      return errors
    },
    repeatPasswordErrors() {
      const errors = []

      if (!this.$v.repeatPassword.$dirty) {
        return errors
      }

      !this.$v.repeatPassword.required && errors.push('Preencha a senha')
      !this.$v.repeatPassword.minLength && errors.push('A senha deve conter, no mínimo, 8 caracteres')
      !this.$v.repeatPassword.maxLength && errors.push('A senha deve conter, no máximo, 32 caracteres')
      !this.$v.repeatPassword.sameAs && errors.push('As senhas devem ser iguais')

      return errors
    }
  },
  methods: {
    submit() {
      this.$v.$touch()

      if (!this.$v.$anyError) {
        this.loader = true

        firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
          .then(resp => {
            const data = { uid: resp.user.uid }
            return updateUserByEmail(this.email, data)
          })
          .then(user => {
            this.$store.dispatch('setUser', user);
            this.$router.push('/')
            this.loader = false
          })
        .catch(err => {
          this.loader = false
          this.$noty.error('Desculpe! Tivemos um problema ao criar sua senha. Tente novamente mais tarde!');

          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }
        })
      }
    }
  }
}
</script>
