<template>
  <div>
    <div class="menu-bar" :class="isIOS ? 'ios-padding' : ''">
      <v-btn icon @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-avatar color="transparent">
        <img src="../../../assets/simbolo-uplanner.png" alt="">
      </v-avatar>
    </div>

    <v-navigation-drawer
      dark
      fixed
      temporary
      v-model="drawer"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="user.photoURL" v-if="user.photoURL">
            <v-avatar v-else color="grey3">
              <v-icon color="grey1">mdi-account</v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.displayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="goToHelp">
          <v-list-item-icon>
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Central de ajuda</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/politica-privacidade">
          <v-list-item-icon>
            <v-icon>mdi-security</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Política de privacidade</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            rounded
            block
            color="primary"
            class="darkgrey--text"
            @click="logout"
          >
            Sair
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import logout from '../../../utils/logout';

  export default {
    name: 'MenuPlanner',
    data: () => ({
      drawer: false,
      items: [
        { text: 'Ínicio', icon: 'mdi-home', path: '/' },
        { text: 'Meu cadastro', icon: 'mdi-settings', path: '/cadastro' }
      ]
    }),
    computed: {
      user() {
        return this.$store.getters.user;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      isIOS() {
        return this.$store.getters.isIOS;
      }
    },
    methods: {
      logout() {
        logout();
      },
      goToHelp() {
        window.open('https://www.notion.so/Central-de-ajuda-13ea912ad81c46ad88b27160307932ad', '_blank');
      }
    }
  }
</script>

<style scoped>
  .menu-bar {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    min-height: 1px;
    background-color: #131313;
    position: fixed;
    padding: 10px;
    z-index: 2;
  }

  @media only screen and (min-width: 960px) {
    .menu-bar {
      justify-content: flex-start;
      z-index: 2;
    }
  }
</style>
