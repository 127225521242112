<template>
  <v-dialog fullscreen persistent v-model="dialog">
    <v-card color="darkgrey">
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12>
            <v-window v-model="step" v-if="perfil">
              <v-window-item :value="1">
                <FormInfosBasicas
                  :profile-id="perfil._id"
                  :infos="perfil.informacoesBasicas"
                />
              </v-window-item>

              <v-window-item :value="2">
                <FormRelacionamento
                  :profile-id="perfil._id"
                  :estado-civil="perfil.informacoesBasicas.usuario.estadoCivil"
                  :has-conjuge="!!perfil.informacoesBasicas.conjuge"
                  :relacionamento="perfil.relacionamento"
                />
              </v-window-item>

              <v-window-item :value="3">
                <FormFilhos :profile-id="perfil._id" :sons="perfil.sons" />
              </v-window-item>

              <v-window-item :value="4">
                <FormOcupacaoProfissional
                  :profile-id="perfil._id"
                  :has-conjuge="!!perfil.informacoesBasicas.conjuge"
                  :ocupacao-profissional="perfil.ocupacaoProfissional"
                  :username="perfil.informacoesBasicas.usuario.nome"
                  :conjuge-name="
                    !!perfil.informacoesBasicas.conjuge
                      ? perfil.informacoesBasicas.conjuge.nome
                      : ''
                  "
                />
              </v-window-item>

              <v-window-item :value="5">
                <FormExperienciaFinanceira
                  :profile-id="perfil._id"
                  :has-conjuge="!!perfil.informacoesBasicas.conjuge"
                  :experiencia-financeira="perfil.experienciaFinanceira"
                  :username="perfil.informacoesBasicas.usuario.nome"
                  :conjuge-name="
                    !!perfil.informacoesBasicas.conjuge
                      ? perfil.informacoesBasicas.conjuge.nome
                      : ''
                  "
                />
              </v-window-item>

              <v-window-item :value="6">
                <FormPatrimonio :profile-id="perfil._id"/>
              </v-window-item>

              <v-window-item :value="7">
                <FormProtecaoFamiliar
                  :profile-id="perfil._id"
                  :protecao-familiar="perfil.protecaoFamiliar"
                />
              </v-window-item>

              <v-window-item :value="8">
                <FormIndFinanceira
                  :profile-id="perfil._id"
                  :has-conjuge="!!perfil.informacoesBasicas.conjuge && !!perfil.informacoesBasicas.conjuge.name"
                />
              </v-window-item>
            </v-window>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import FormExperienciaFinanceira from "@/components/Customer/Perfil/forms/FormExperienciaFinanceira";
import FormInfosBasicas from "@/components/Customer/Perfil/forms/FormInfosBasicas";
import FormRelacionamento from "@/components/Customer/Perfil/forms/FormRelacionamento";
import FormFilhos from "@/components/Customer/Perfil/forms/FormFilhos";
import FormOcupacaoProfissional from "@/components/Customer/Perfil/forms/FormOcupacaoProfissional";
import FormPatrimonio from '@/components/Customer/Perfil/forms/FormPatrimonio';
import FormProtecaoFamiliar from '@/components/Customer/Perfil/forms/FormProtecaoFamiliar';
import FormIndFinanceira from '@/components/Customer/Perfil/forms/FormIndFinanceira';
export default {
  name: "DialogProfile",
  components: {
    FormIndFinanceira,
    FormProtecaoFamiliar,
    FormPatrimonio,
    FormOcupacaoProfissional,
    FormFilhos,
    FormRelacionamento,
    FormInfosBasicas,
    FormExperienciaFinanceira,
  },
  data: () => ({
    step: 1,
    dialog: false,
  }),
  computed: {
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === "customer"
      );
    },
    customer() {
      return this.$store.getters[this.isCustomer ? "user" : "customer"];
    },
    perfil() {
      return this.$store.getters.perfil;
    },
  },
  methods: {
    openDialog() {
      if (!this.dialog && this.isCustomer) {
        if (this.customer && this.perfil) {
          this.step = this.perfil.step;
        } else {
          this.step = 1;
        }

        this.dialog = true;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.step = 0;
    },
    changeStep(type, step) {
      if (step) {
        this.step = step;
      } else if (type === "next") {
        if (this.step < 8) {
          this.step++;
        }
      } else if (type === "back") {
        if (this.step > 1) {
          this.step--;
        }
      }
    },
  },
  created() {
    this.$bus.$on("showDialogProfile", this.openDialog);
    this.$bus.$on("closeDialogProfile", this.closeDialog);

    this.$bus.$on("backProfileStep", (step) => {
      this.changeStep("back", step);
    });

    this.$bus.$on("nextProfileStep", (step) => {
      this.changeStep("next", step);
    });
  },
};
</script>

<style scoped>
.container {
  max-width: 960px;
}
</style>
