<template>
  <div class="legend mb-3 d-flex align-center flex-wrap">
    <div
      v-for="item in status"
      :key="item.name"
      class="legend-item d-flex align-center mr-4"
    >
      <v-icon :color="item.color" class="mr-1">{{ item.icon }}</v-icon>
      <span class="grey1--test">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsersStatus',
  data: () => ({
    status: [
      {
        name: 'Habilitado',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      {
        name: 'Desabilitado',
        icon: 'mdi-check-circle',
        color: 'error'
      },
      {
        name: 'Bloqueado',
        icon: 'mdi-account-lock-outline',
        color: 'warning'
      },
      {
        name: 'Pendente',
        icon: 'mdi-account-clock-outline',
        color: 'warning'
      },
      {
        name: 'Ass. suspensa',
        icon: 'mdi-account-cancel-outline',
        color: 'error'
      },
      {
        name: 'Cancelado',
        icon: 'mdi-cancel',
        color: 'error'
      }
    ]
  })
}
</script>
