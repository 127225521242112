import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

export default (userId, transactionsIds) => {

  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const options = {
          data: { ids: transactionsIds },
          method: 'DELETE',
          headers: { Authorization: `Bearer ${idToken}` }
        }

        return httpClient(`/transactions/user/${userId}/not-revised/multiple`, options);
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

