<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="error"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="error--text ml-4">Remover Investimento</span>
      </v-card-title>

      <v-card-text>
        <p class="desc mt-5">
          Tem certeza que deseja remover <b>{{ investment.description }}</b>?
        </p>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            fab
            dark
            small
            color="error"
            @click="submit"
            :loading="loader"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import deleteInvestment from '@/api/investimentos/deleteInvestment';

export default {
  name: 'DialogRemoveInvestment',
  data: () => ({
    dialog: false,
    loader: false,
    investment: {
      _id: '',
      description: ''
    }
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.investment = {
        _id: '',
        description: ''
      };
    },
    submit() {
      this.loader = true;

      deleteInvestment(this.investment._id)
        .then(() => {
          this.$store.dispatch('deleteInvestment', this.investment._id);
          this.close();
          this.$noty.success('Investimento removido com sucesso!')
        })
        .catch(err => {
          this.loader = false;
          this.$noty.error('Desculpe! Não foi possível remover este investimento. Tente novamente!');

          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    }
  },
  created() {
    this.$bus.$on('showDialogRemoveInvestment', investment => {
      if (investment) {
        this.investment = investment;
        this.dialog = true;
      }
    });
  }
}
</script>

<style scoped>
  .desc {
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }
</style>
