<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          class="mr-2"
          color="error"
          :disabled="loader"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="error--text">Remover Conexão</span>
      </v-card-title>

      <v-card-text class="pt-2" v-if="item">
        <p class="white--text">
          Ao remover a conexão com {{ item.connector ? item.connector.name : '' }}, os dados abaixo também serão removidos:
        </p>

        <ul class="white--text">
          <li>Contas bancárias</li>
          <li>Cartões de crédito</li>
          <li>Faturas de cartão de crédito</li>
          <li>Transações</li>
        </ul>

        <h3 class="white--text font-weight-bold mt-3">Tem certeza que deseja remover esta conexão?</h3>

        <v-card-actions class="px-0 mt-5">
          <v-btn
            text
            rounded
            class="px-5"
            color="white"
            :disabled="loader"
            @click="closeDialog"
          >
            Cancelar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            dark
            rounded
            color="error"
            @click="submit"
            :loading="loader"
            class="px-5 text-capitalize"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import anonymizePluggyItem from "@/api/pluggyItems/anonymizePluggyItem";

export default {
  name: 'DialogRemovePluggyItem',
  data: () => ({
    dialog: false,
    loader: false,
    item: null
  }),
  methods: {
    openDialog(item) {
      if (item) {
        this.item = item
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.item = null
    },
    async submit() {
      try {
        this.loader = true

        await anonymizePluggyItem(this.item.id)
        await this.$store.dispatch('clearPluggyItemData', this.item.id)

        this.closeDialog()

        this.$noty.success('Conexão removida com sucesso!')
        this.$bus.$emit('refreshOpenBanking')
        this.$bus.$emit('PLUGGY_CONNECTION_REMOVED')

      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.loader = false
        this.$noty.error('Desculpe! Não conseguimos remover a conexão. Tente novamente mais tarde!')
      }
    }
  },
  created() {
    this.$bus.$on('showDialogRemovePluggyItem', this.openDialog)
  }
}
</script>
