<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          class="mr-2"
          color="error"
          :disabled="loader"
          @click="closeDialog"
        >
          <v-icon>{{ isMobile ? 'mdi-arrow-left' : 'mdi-close' }}</v-icon>
        </v-btn>

        <span class="error--text">Remover minha conta</span>
      </v-card-title>

      <v-card-text class="pt-5">
        <p class="white--text">
          Atenção! Ao remover sua conta, todos os seus dados serão removidos, sem possibilidade de
          recuperá-los. Seu acesso também encerrado.
        </p>

        <p class="warning--text">Tem certeza que deseja remover sua conta?</p>
      </v-card-text>

      <v-card-actions class="pb-5">
        <v-btn
          rounded
          color="primary"
          :disabled="loader"
          @click="closeDialog"
          class="px-5 darkgrey--text text-capitalize"
        >
          Cancelar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          dark
          rounded
          color="error"
          :loading="loader"
          @click="submit"
          class="px-5 text-capitalize"
        >
          Remover minha conta
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase/app";
import deleteFreeUser from "@/api/users/deleteFreeUser";

export default {
  name: 'DialogDeleteFreeUser',
  data: () => ({
    dialog: false,
    loader: false
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    openDialog() {
      if (this.user && this.user.isFree) {
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
    },
    async submit() {
      try {
        if (this.user && this.user.isFree) {
          this.loader = true
          await deleteFreeUser(this.user._id)

          this.closeDialog()
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        document.location.reload()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogDeleteFreeUser', this.openDialog)
  }
}
</script>
