<template>
  <v-dialog
    fullscreen
    v-model="dialog"
    :persistent="loader"
  >
    <v-card color="darkgrey" class="no-radius">
      <v-card-title class="centered-dialog-title safe-area-top pt-3 px-3 darkgrey">
        <v-container class="d-flex align-center justify-space-between mx-auto pa-0">
          <v-btn
            icon
            large
            color="purple"
            :disabled="loader"
            @click="changePeriod('prev')"
          >
            <i class="lni lni-chevron-left-circle"></i>
          </v-btn>

          <span class="period-name">{{ formatedPeriod }}</span>

          <v-btn
            icon
            large
            color="purple"
            :disabled="loader"
            @click="changePeriod('next')"
          >
            <i class="lni lni-chevron-right-circle"></i>
          </v-btn>
        </v-container>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md class="px-0">
          <!-- SKELETON LOADER -->
          <v-layout row wrap v-if="loader">
            <v-flex xs12>
              <v-skeleton-loader
                v-bind="attrs"
                type="card"
              ></v-skeleton-loader>
            </v-flex>

            <v-flex xs12>
              <v-skeleton-loader
                v-bind="attrs"
                type="card"
              ></v-skeleton-loader>
            </v-flex>
          </v-layout>

          <v-layout row wrap v-else-if="history" class="pb-10">
            <v-flex xs12>
              <CardCompanyHistoryInvoice
                :invoice="history.invoice"
                :values="history.values"
              />
            </v-flex>

            <v-flex xs12>
              <CardCompanyHistorySubscriptions
                :invoice="history.invoice"
                :values="history.values"
              />
            </v-flex>

            <v-flex xs12 v-if="history.employees.length">
              <CardCompanyHistoryEmployees
                :employees="orderBy(history.employees, 'displayName')"
                :company-id="user._id"
                :period="period"
                :can-export="true"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <div
        v-ripple
        @click="dialog = false"
        class="btn-close-dialog-bottom primary darkgrey--text safe-area-bottom"
      >
        Fechar
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { orderBy } from 'lodash'
import getCompanyHistory from '@/api/companies/getCompanyHistory';
import numberToCurrency from '@/utils/numberToCurrency';
import CardCompanyHistoryValues from '@/components/Company/cards/CardCompanyHistoryValues';
import CardCompanyHistoryInvoice from '@/components/Company/cards/CardCompanyHistoryInvoice';
import CardCompanyHistorySubscriptions from '@/components/Company/cards/CardCompanyHistorySubscriptions';
import CardCompanyHistoryEmployees from '@/components/Company/cards/CardCompanyHistoryEmployees';

const PERIOD_PATTERN = 'YYYY-MM'
const MONTH_NAME_PATTERN = 'MMM YYYY'

export default {
  name: 'DialogCompanyEmployeesHistory',
  components: { CardCompanyHistoryEmployees, CardCompanyHistorySubscriptions, CardCompanyHistoryInvoice, CardCompanyHistoryValues },
  data: () => ({
    orderBy,
    dialog: false,
    loader: false,
    period: null,
    history: null,
    minPeriod: '',
    maxPeriod: '',
    periodMenu: false,
    numberToCurrency,
    attrs: {
      boilerplate: true,
      elevation: 2
    }
  }),
  computed: {
    isCompany() {
      return this.$store.getters.user && this.$store.getters.user.role === 'company'
    },
    user() {
      return this.isCompany ? this.$store.getters.user : this.$store.getters.company
    },
    formatedPeriod() {
      if (this.period) {
        return this.$date(this.period).format(MONTH_NAME_PATTERN)
      }
      return ''
    },
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
  methods: {
    openDialog() {
      this.period = this.$date().subtract(1, 'month').format(PERIOD_PATTERN);
      this.setPeriods();
      this.dialog = true;
    },
    setPeriods() {
      this.period = this.$date().subtract(1, 'month').format(PERIOD_PATTERN)
      this.maxPeriod = this.period

      if (this.user) {
        this.minPeriod = this.$date(this.user.createdAt).format(PERIOD_PATTERN)
      }
    },
    getData() {
      if (this.user && this.period) {
        this.loader = true;

        getCompanyHistory(this.user._id, this.period)
          .then(history => {
            this.loader = false;
            this.history = history
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }

            this.reset();
            this.loader = false;
          });
      }
    },
    reset() {
      this.history = null;
    },
    close() {
      this.dialog = false;
      this.loader = false;
      this.period = null;
      this.reset();
    },
    getPlanName(identifier) {
      if (identifier.includes('familia')) {
        return 'Plano Familiar'
      } else if (identifier.includes('individual')) {
        return 'Plano Individual'
      }
    },
    changePeriod(type) {
      const currentPeriod = this.$date().format(PERIOD_PATTERN)
      const createdPeriod = this.$date(this.user.createdAt).format(PERIOD_PATTERN)

      let date = this.$date(this.period)

      if (type === 'next') {
        date = date.add(1,'month')
      } else if (type === 'prev') {
        date = date.subtract(1,'month')
      }

      const period = date.format(PERIOD_PATTERN)

      if (period >= createdPeriod && period < currentPeriod) {
        this.period = period
      }
    }
  },
  watch: {
    period() {
      if (!this.loader) {
        this.getData()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogEmployeesHistory', this.openDialog);
  }
};
</script>

<style scoped>
.v-card__title.centered-dialog-title {
  position: fixed;
  top: 0;
  width: 100%;
  height: 58px;
  z-index: 2;
}

.centered-dialog-title .lni {
  font-size: 24px;
}

.period-name {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.container {
  max-width: 960px;
}

.btn-close-dialog-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: 18px;
  font-weight: 500;
  letter-spacing: .1em;
}

.v-card__text {
  margin-top: 60px !important;
}
</style>
