<template>
  <v-dialog
    v-model="dialog"
    max-width="480px"
  >
    <v-card v-if="id">
      <v-card-title class="dialog-form-title">
        <span>{{ canceled ? 'Ativar' : 'Cancelar' }} usuário</span>
      </v-card-title>

      <v-card-title>
        <p class="white--text">Tem certeza que deseja cancelar este usuário?</p>
      </v-card-title>

      <v-card-actions>
        <v-btn
          text
          rounded
          class="px-5"
          color="error"
          :disabled="loader"
          @click.stop="closeDialog"
        >
          Fechar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          small
          rounded
          color="primary"
          :loading="loader"
          @click.stop="submit"
          class="px-5 darkgrey--text"
        >
          {{ canceled ? 'Ativar' : 'Cancelar' }} usuário
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import changeUserCancelStatus from '@/api/users/changeUserCancelStatus';

export default {
  name: 'DialogChangeUserCanceledStatus',
  data: () => ({
    dialog: false,
    loader: false,
    id: null,
    canceled: null
  }),
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    openDialog(data) {
      if (!!data && !!data._id && typeof data.canceled !== 'undefined' && this.user.role === 'admin') {
        this.id = data._id
        this.canceled = data.canceled
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this._id = null
      this.canceled = null
    },
    async submit() {
      try {
        if (this.user.role === 'admin') {
          this.loader = true

          await changeUserCancelStatus(this.id, !this.canceled)

          this.$bus.$emit('managerGetUsers')
          this.closeDialog()
        }
      } catch (err) {
        this.loader = false
        this.$noty.error('Falha ao cancelar usuário. Tente novamente mais tarde!')
      }
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeDialog()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogChangeUserCanceledStatus', this.openDialog)
  }
}
</script>
