<template>
  <v-card flat color="grey3">
    <v-card-title class="primary--text seguro-vida main-title">Questionário</v-card-title>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-container grid-list-md class="pa-0">
          <v-layout row wrap>
            <v-flex xs12 md4>
              <span class="seguro-vida input-label primary--text">Categoria de trabalho</span>
              <v-select
                dense
                color="grey1"
                :items="jobCategories"
                v-model="questionario.categoriaTrabalho"
                :error-messages="categoriaTrabalhoErrors"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-tag-outline
                </v-icon>
              </v-select>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <span class="seguro-vida input-label primary--text">Peso</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                suffix="kg"
                :error-messages="pesoErrors"
                v-model.number="questionario.peso"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-weight-kilogram
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <span class="seguro-vida input-label primary--text">Altura</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                suffix="metros"
                hint="Ex. 1.50"
                persistent-hint
                :error-messages="alturaErrors"
                v-model.number="questionario.altura"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-human-male-height
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md8 lg7>
              <span class="seguro-vida input-label primary--text">Sofre ou já sofreu doenças graves?</span>
              <v-radio-group row v-model="questionario.doencaGrave.status">
                <v-radio
                  label="Não"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Sim"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="questionario.doencaGrave.status">
                <span class="seguro-vida input-label primary--text">Observação</span>
                <v-text-field
                  dense
                  color="grey1"
                  :error-messages="doencaGraveErrors"
                  v-model="questionario.doencaGrave.obs"
                >
                  <v-icon
                    color="grey1"
                    class="seguro-vida input-icon mr-1"
                    slot="prepend-inner"
                  >
                    mdi-pencil
                  </v-icon>
                </v-text-field>
              </v-flex>
            </v-slide-x-transition>

            <v-flex xs12 sm12 md8 lg7>
              <span class="seguro-vida input-label primary--text">
                Está afastado(a) do trabalho ou aposentado(a) por invalidez?
              </span>
              <v-radio-group row v-model="questionario.invalidez.status">
                <v-radio
                  label="Não"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Sim"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="questionario.invalidez.status">
                <span class="seguro-vida input-label primary--text">Observação</span>
                <v-text-field
                  dense
                  color="grey1"
                  :error-messages="invalidezErrors"
                  v-model="questionario.invalidez.obs"
                >
                  <v-icon
                    color="grey1"
                    class="seguro-vida input-icon mr-1"
                    slot="prepend-inner"
                  >
                    mdi-pencil
                  </v-icon>
                </v-text-field>
              </v-flex>
            </v-slide-x-transition>

            <v-flex xs12 sm12 md8 lg7>
              <span class="seguro-vida input-label primary--text">
                Encontra-se com algum problema de saúde ou usa algum tipo de medicamento atualmente?
              </span>
              <v-radio-group row v-model="questionario.problemaSaude.status">
                <v-radio
                  label="Não"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Sim"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="questionario.problemaSaude.status">
                <span class="seguro-vida input-label primary--text">Observação</span>
                <v-text-field
                  dense
                  color="grey1"
                  :error-messages="problemaSaudeErrors"
                  v-model="questionario.problemaSaude.obs"
                >
                  <v-icon
                    color="grey1"
                    class="seguro-vida input-icon mr-1"
                    slot="prepend-inner"
                  >
                    mdi-pencil
                  </v-icon>
                </v-text-field>
              </v-flex>
            </v-slide-x-transition>

            <v-flex xs12 sm12 md8 lg7>
              <span class="seguro-vida input-label primary--text">
                Pratica algum esporte radical ou exerce  atividade, em caráter profissional ou amador, que não sejam
                de linhas regulares?
              </span>
              <v-radio-group row v-model="questionario.atividades.status">
                <v-radio
                  label="Não"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Sim"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="questionario.atividades.status">
                <span class="seguro-vida input-label primary--text">Observação</span>
                <v-text-field
                  dense
                  color="grey1"
                  :error-messages="atividadesErrors"
                  v-model="questionario.atividades.obs"
                >
                  <v-icon
                    color="grey1"
                    class="seguro-vida input-icon mr-1"
                    slot="prepend-inner"
                  >
                    mdi-pencil
                  </v-icon>
                </v-text-field>
              </v-flex>
            </v-slide-x-transition>

            <v-flex xs12 sm12 md8 lg7>
              <span class="seguro-vida input-label primary--text">
                Sofre ou já sofreu de deficiência de órgãos, membros ou sentidos, incluindo doenças  ortopédicas
                relacionadas a esforço repetitivo?
              </span>
              <v-radio-group row v-model="questionario.deficienciaOrgaos.status">
                <v-radio
                  label="Não"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Sim"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="questionario.deficienciaOrgaos.status">
                <span class="seguro-vida input-label primary--text">Observação</span>
                <v-text-field
                  dense
                  color="grey1"
                  :error-messages="deficienciaOrgaosErrors"
                  v-model="questionario.deficienciaOrgaos.obs"
                >
                  <v-icon
                    color="grey1"
                    class="seguro-vida input-icon mr-1"
                    slot="prepend-inner"
                  >
                    mdi-pencil
                  </v-icon>
                </v-text-field>
              </v-flex>
            </v-slide-x-transition>

            <v-flex xs12 sm12 md8 lg7>
              <span class="seguro-vida input-label primary--text">
                É fumante?
              </span>
              <v-radio-group row v-model="questionario.fumante.status">
                <v-radio
                  label="Não"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Sim"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="questionario.fumante.status">
                <span class="seguro-vida input-label primary--text">Observação</span>
                <v-text-field
                  dense
                  color="grey1"
                  :error-messages="fumanteErrors"
                  v-model="questionario.fumante.obs"
                >
                  <v-icon
                    color="grey1"
                    class="seguro-vida input-icon mr-1"
                    slot="prepend-inner"
                  >
                    mdi-pencil
                  </v-icon>
                </v-text-field>
              </v-flex>
            </v-slide-x-transition>

            <v-flex xs12 sm12 md8 lg7>
              <span class="seguro-vida input-label primary--text">
                Fez alguma cirurgia, biópsia ou esteve  internado nos últimos cinco anos?
              </span>
              <v-radio-group row v-model="questionario.cirurgia.status">
                <v-radio
                  label="Não"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Sim"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="questionario.cirurgia.status">
                <span class="seguro-vida input-label primary--text">Observação</span>
                <v-text-field
                  dense
                  color="grey1"
                  :error-messages="cirurgiaErrors"
                  v-model="questionario.cirurgia.obs"
                >
                  <v-icon
                    color="grey1"
                    class="seguro-vida input-icon mr-1"
                    slot="prepend-inner"
                  >
                    mdi-pencil
                  </v-icon>
                </v-text-field>
              </v-flex>
            </v-slide-x-transition>
          </v-layout>
        </v-container>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            type="submit"
            color="primary"
            :loading="loader"
            :disabled="!allowed"
            class="seguro-vida darkgrey--text px-5"
          >
            Salvar
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import updateLifeInsurace from '@/api/lifeInsurance/updateLifeInsurance';

export default {
  name: 'CardInsuranceQuestionario',
  props: ['isSpouse'],
  data: () => ({
    loader: false,
    questionario: {
      categoriaTrabalho: '',
      peso: '',
      altura: '',
      doencaGrave: {
        status: false,
        obs: ''
      },
      problemaSaude: {
        status: false,
        obs: ''
      },
      cirurgia: {
        status: false,
        obs: ''
      },
      invalidez: {
        status: false,
        obs: ''
      },
      atividades: {
        status: false,
        obs: ''
      },
      fumante: {
        status: false,
        obs: ''
      },
      deficienciaOrgaos: {
        status: false,
        obs: ''
      }
    },
    jobCategories: [
      'Autônomo',
      'Empregado',
      'Empregador',
      'Servidor público'
    ]
  }),
  validations: {
    questionario: {
      categoriaTrabalho: {
        required
      },
      peso: {
        isValid(v) {
          return !!v && v > 0
        }
      },
      altura: {
        isValid(v) {
          return !!v && v > 0 && v < 3
        }
      },
      doencaGrave: {
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
      problemaSaude: {
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
      cirurgia: {
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
      invalidez: {
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
      atividades: {
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
      fumante: {
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
      deficienciaOrgaos: {
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
    }
  },
  computed: {
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    },
    allowed() {
      return this.$store.getters.allowed;
    },
    categoriaTrabalhoErrors() {
      const errors = []

      if (!this.$v.questionario.categoriaTrabalho.$dirty) {
        return errors
      }

      !this.$v.questionario.categoriaTrabalho.required && errors.push('Selecione a categoria de trabalho')

      return errors
    },
    pesoErrors() {
      const errors = []

      if (!this.$v.questionario.peso.$dirty) {
        return errors
      }

      !this.$v.questionario.peso.isValid && errors.push('Preencha um peso válido')

      return errors
    },
    alturaErrors() {
      const errors = []

      if (!this.$v.questionario.altura.$dirty) {
        return errors
      }

      !this.$v.questionario.altura.isValid && errors.push('Preencha uma altura válida')

      return errors
    },
    doencaGraveErrors() {
      const errors = []

      if (!this.$v.questionario.doencaGrave.obs.$dirty) {
        return errors
      }

      !this.$v.questionario.doencaGrave.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    problemaSaudeErrors() {
      const errors = []

      if (!this.$v.questionario.problemaSaude.obs.$dirty) {
        return errors
      }

      !this.$v.questionario.problemaSaude.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    cirurgiaErrors() {
      const errors = []

      if (!this.$v.questionario.cirurgia.obs.$dirty) {
        return errors
      }

      !this.$v.questionario.cirurgia.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    invalidezErrors() {
      const errors = []

      if (!this.$v.questionario.invalidez.obs.$dirty) {
        return errors
      }

      !this.$v.questionario.invalidez.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    atividadesErrors() {
      const errors = []

      if (!this.$v.questionario.atividades.obs.$dirty) {
        return errors
      }

      !this.$v.questionario.atividades.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    fumanteErrors() {
      const errors = []

      if (!this.$v.questionario.fumante.obs.$dirty) {
        return errors
      }

      !this.$v.questionario.fumante.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    deficienciaOrgaosErrors() {
      const errors = []

      if (!this.$v.questionario.deficienciaOrgaos.obs.$dirty) {
        return errors
      }

      !this.$v.questionario.deficienciaOrgaos.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
  },
  methods: {
    copyData() {
      if (this.lifeInsurance && this.lifeInsurance.questionario) {
        this.questionario = JSON.parse(JSON.stringify(this.lifeInsurance.questionario))
      }
    },
    submit() {
      if (this.allowed) {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          updateLifeInsurace(this.lifeInsurance._id, { questionario: this.questionario })
            .then(lifeInsurance => {
              this.$store.dispatch('saveLifeInsurance', lifeInsurance)
              this.loader = false
              this.$noty.success('Dados salvos com sucesso!')
            })
            .catch(err => {
              this.loader = false
              this.$noty.error('Desculpe! Não foi possível salvar seus dados. Tente novamente mais tarde!');

              if (process.env.NODE_ENV  !== 'production') {
                console.error(err)
              }
            })
        }
      }
    }
  },
  watch: {
    lifeInsurance: {
      deep: true,
      handler() {
        this.copyData()
      }
    }
  },
  created() {
    this.copyData()
  }
}
</script>
