<template>
  <div>
    <v-data-table hide-default-footer :headers="headers" :items="events" :loading="loader" dense>
      <template v-slot:item="props">
        <tr>
          <td class="text-center">{{ new Date(props.item.date) | moment('DD/MM/YYYY HH:mm:ss') }}</td>
          <td>{{ getEventName(props.item.type) }}</td>
          <td>
            {{ props.item.author ? `${props.item.author.displayName} -
            ${getRoleName(props.item.author.role)}` : '' }}
          </td>
          <td class="text-center py-1">
            <v-btn
              small
              rounded
              depressed
              color="grey lighten-2"
              @click.stop="showDialogUserEvent(props.item)"
            >Detalhes</v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="text-center mt-5">
      <v-pagination
        circle
        color="darkblue"
        dark
        v-model="pagination.page"
        :length="pagination.totalPages"
      ></v-pagination>
    </div>

    <DialogUserEvent />
  </div>
</template>

<script>
import getEventsByUserId from '../../../../../api/events/getEventsByUserId';
import countEventsByUserId from '../../../../../api/events/countEventsByUserId';
import DialogUserEvent from '../dialogs/DialogUserEvent';

export default {
  name: 'TableUserHistory',
  components: { DialogUserEvent },
  props: ['user'],
  data: () => ({
    loader: false,
    headers: [
      { text: 'Data', value: 'date', sortable: true, align: 'center' },
      { text: 'Tipo', value: 'type', sortable: true, align: 'left' },
      {
        text: 'Executado por',
        value: 'author.displayName',
        sortable: true,
        align: 'left'
      },
      { text: 'Detalhes', value: '', sortable: false, align: 'center' }
    ],
    events: [],
    pagination: {
      totalPages: 0,
      page: 1,
      itemsPerPage: 10,
      totalItems: 0
    },
    filter: null,
    eventTypes: [
      { text: 'Cadastro', value: 'signup' },
      { text: 'Login', value: 'signin' },
      { text: 'Habilitado', value: 'status-enabled' },
      { text: 'Desabilitado', value: 'status-disabled' },
      { text: 'Desabilitado', value: 'status-disabled' },
      { text: 'Troca de plano', value: 'plan-changed' },
      { text: 'Troca de senha', value: 'password-changed' },
      { text: 'Troca de email', value: 'email-changed' },
      { text: 'Troca de planejador', value: 'planner-changed' },
      { text: 'Troca de empresa', value: 'company-changed' },
      { text: 'Link de redefinição de senha', value: 'pass-reset-link' }
    ]
  }),
  methods: {
    getEvents() {
      this.loader = true;

      let promises = [
        countEventsByUserId(this.user._id, this.filter ? this.filter.type : ''),
        getEventsByUserId(
          this.user._id,
          this.pagination.page,
          this.pagination.itemsPerPage,
          this.filter ? this.filter.type : '',
          this.filter ? this.filter.sort : -1
        )
      ];

      Promise.all(promises)
        .then(results => {
          this.pagination.totalItems = results[0].count;
          this.pagination.totalPages = Math.ceil(
            this.pagination.totalItems / this.pagination.itemsPerPage
          );
          this.events = results[1];
          this.loader = false;
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
        });
    },
    getEventName(eventType) {
      return this.eventTypes.find(e => e.value === eventType).text;
    },
    getRoleName(role) {
      switch (role) {
        case 'admin':
          return 'Adm';
        case 'company':
          return 'Emp';
        default:
          return 'Plan';
      }
    },
    showDialogUserEvent(userEvent) {
      this.$bus.$emit('showDialogUserEvent', userEvent);
    }
  },
  watch: {
    'pagination.page'() {
      this.getEvents();
    }
  },
  created() {
    this.$bus.$on('getUserHistory', filter => {
      if (filter) {
        this.filter = filter;
        this.getEvents();
      } else {
        this.filter = null;
        if (this.pagination.page === 1) {
          this.getEvents();
        } else {
          this.pagination.page = 1;
        }
      }
    });
  },
  mounted() {
    this.getEvents();
  }
};
</script>
