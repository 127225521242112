<template>
  <v-card flat color="grey3">
    <v-card-title class="primary--text text-center main-title seguro-vida"
      >Informações pessoais</v-card-title
    >
    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-container class="pa-0">
          <v-layout class="pa-0" row wrap>
            <v-flex xs12 md4>
              <span class="seguro-vida input-label primary--text"
                >Nome completo</span
              >
              <v-text-field
                dense
                readonly
                color="grey1"
                :value="generalInfos.nome"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-account-circle-outline
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12 md4>
              <span class="seguro-vida input-label primary--text"
                >Data de nascimento</span
              >

              <v-dialog
                ref="dialog"
                v-model="dateMenu"
                :return-value.sync="generalInfos.dataNascimento"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mt-0 pt-0"
                    :value="formatedDate"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                  >
                    <v-icon
                      color="grey1"
                      class="input-icon"
                      slot="prepend-inner"
                    >
                      mdi-calendar
                    </v-icon>
                  </v-text-field>
                </template>

                <v-date-picker
                  no-title
                  color="primary"
                  v-model="generalInfos.dataNascimento"
                  scrollable
                  locale="pt-br"
                >
                  <v-spacer></v-spacer>

                  <v-btn color="grey2" @click="dateMenu = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="$refs.dialog.save(generalInfos.dataNascimento)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>

            <v-flex xs12 md4>
              <span class="seguro-vida input-label primary--text">CPF</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                v-model="generalInfos.cpf"
                v-mask="'###.###.###-##'"
                :id="'input-insurance-cpf' + lifeInsurance._id"
                @input="$v.generalInfos.cpf.$touch()"
                :error-messages="cpfErrors"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-shield-account-outline
                </v-icon>
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            type="submit"
            color="primary"
            class="darkgrey--text px-5 seguro-vida"
            :loading="loader"
            :disabled="!allowed"
          >
            Salvar
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { isValid } from '@fnando/cpf';
import updateLifeInsurace from '@/api/lifeInsurance/updateLifeInsurance';

export default {
  name: 'CardInsuranceGeneralInfos',
  props: ['isSpouse'],
  data: () => ({
    dialog: false,
    dateMenu: false,
    loader: false,
    generalInfos: {
      cpf: '',
      nome: '',
      dataNascimento: '',
    },
  }),
  validations: {
    generalInfos: {
      cpf: {
        isValid(v) {
          return !!v && isValid(v);
        },
      },
      dataNascimento: {
        isValid(v) {
          return !!v && /\d{4}-\d{2}-\d{2}/gm.test(v);
        },
      },
    },
  },
  computed: {
    formatedDate() {
      if (this.generalInfos.dataNascimento) {
        return this.generalInfos.dataNascimento.split('-').reverse().join('/');
      }
      return '';
    },
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    },
    cpfErrors() {
      const errors = [];

      if (!this.$v.generalInfos.cpf.$anyDirty) {
        return errors;
      }

      !this.$v.generalInfos.cpf.isValid &&
        errors.push('Preencha um CPF válido');

      return errors;
    },
    dataNascimentoErrors() {
      const errors = [];

      if (!this.$v.generalInfos.dataNascimento.$anyDirty) {
        return errors;
      }

      !this.$v.generalInfos.dataNascimento.isValid &&
        errors.push('Preencha uma data válida');

      return errors;
    },
    allowed() {
      return this.$store.getters.allowed;
    },
  },
  methods: {
    submit() {
      if (this.allowed) {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          let generalInfos = JSON.parse(JSON.stringify(this.generalInfos));

          generalInfos.cpf = generalInfos.cpf.replace(/\D+/gm, '');
          generalInfos.dataNascimento = generalInfos.dataNascimento
            .split('/')
            .reverse()
            .join('-');

          updateLifeInsurace(this.lifeInsurance._id, generalInfos)
            .then((lifeInsurance) => {
              this.inputOnCpf();
              this.loader = false;
              this.$noty.success('Dados salvos com sucesso!');
              this.$store.dispatch('saveLifeInsurance', lifeInsurance);
              this.copyData();
            })
            .catch((err) => {
              this.loader = false;
              this.$noty.error(
                'Desculpe! Não foi possível salvar estes dados. Tente novamente mais tarde!'
              );

              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      }
    },
    inputOnCpf() {
      let input = document.getElementById(
        'input-insurance-cpf' + this.lifeInsurance._id
      );
      if (input) {
        input.value = this.generalInfos.cpf;
        input.dispatchEvent(new Event('input'));
      }
    },
    formatDate(date) {
      if (date) {
        return date;
      }
      return '';
    },
    copyData() {
      if (this.lifeInsurance.cpf) {
        this.generalInfos.cpf = this.lifeInsurance.cpf;
      }
      if (this.lifeInsurance.nome) {
        this.generalInfos.nome = this.lifeInsurance.nome;
      }

      if (this.lifeInsurance.dataNascimento) {
        this.generalInfos.dataNascimento = this.formatDate(
          this.lifeInsurance.dataNascimento
        );
      }
      this.inputOnCpf();
    },
  },
  watch: {
    infos: {
      deep: true,
      handler() {
        this.copyData();
      },
    },
  },
  created() {
    this.copyData();
  },
};
</script>
