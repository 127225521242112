<template>
  <v-main v-if="!user">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <div class="loader-container">
          <v-progress-circular :size="50" color="accent" indeterminate class="mb-3"></v-progress-circular>
          <div class="title">Carregando...</div>
        </div>
      </v-layout>
    </v-container>
  </v-main>

  <v-main v-else>
    <v-container grid-list-md v-if="config">
      <v-layout row wrap>
        <v-flex xs12>
          <v-card-title>Imposto de Renda</v-card-title>
        </v-flex>

        <v-flex xs12>
          <CardConfigAliquotaIrpf />
        </v-flex>

        <v-flex xs12>
          <CardConfigDeducoesCompleta />
        </v-flex>

        <v-flex xs12>
          <CardConfigDeducoesSimples />
        </v-flex>

        <v-flex xs12>
          <CardConfigPgbl />
        </v-flex>
      </v-layout>

      <DialogFormConfigAliquotaIrpf />
      <DialogRemoveAliquotaIrpfItem />
    </v-container>
  </v-main>
</template>

<script>
import getIrConfig from '@/api/config/getIrConfig';
import getLoggedUserData from '@/utils/getLoggedUserData';

import Menu from '../../components/Global/Menu';
import CardConfigPgbl from '@/components/ImpostoRenda/cards/CardConfigPgbl';
import CardConfigAliquotaIrpf from '@/components/ImpostoRenda/cards/CardConfigAliquotaIrpf';
import CardConfigDeducoesSimples from '@/components/ImpostoRenda/cards/CardConfigDeducoesSimples';
import CardConfigDeducoesCompleta from '@/components/ImpostoRenda/cards/CardConfigDeducoesCompleta';
import DialogFormConfigAliquotaIrpf from '@/components/ImpostoRenda/dialogs/DialogFormConfigAliquotaIrpf';
import DialogRemoveAliquotaIrpfItem from '@/components/ImpostoRenda/dialogs/DialogRemoveAliquotaIrpfItem';
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

export default {
  name: 'ImpostoRenda',
  // metaInfo() {
  //   return {
  //     title: "Imposto de renda",
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo)
  //     }
  //   };
  // },
  components: {
    Menu,
    CardConfigPgbl,
    CardConfigAliquotaIrpf,
    CardConfigDeducoesSimples,
    CardConfigDeducoesCompleta,
    DialogFormConfigAliquotaIrpf,
    DialogRemoveAliquotaIrpfItem
  },
  data: () => ({
    loader: false
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    config() {
      return this.$store.getters.configIr;
    }
  },
  methods: {
    getConfig() {
      getIrConfig()
        .then(config => {
          this.$store.dispatch('setConfigIr', config);
          this.loader = false;
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
          this.$noty.error('Falha ao buscar configurações! Atualize a página');
        });
    }
  },
  created() {
    if (!this.user) getLoggedUserData();

    this.getConfig();
  }
};
</script>

<style scoped>
.container {
  max-width: 960px;
  margin-top: 46px;
  margin-right: auto;
  margin-left: auto;
}
</style>
