import moment from 'moment'
import checkActionPlansDeadline from '@/api/actionPlans/checkActionPlansDeadline';
import checkIuguSubscriptionStatus from '@/api/iugu/subscriptions/checkIuguSubscriptionStatus';
import saveUser from '@/api/users/saveUser';

export default {
  logout: (state) => {
    state.user = null;
    state.configIr = null;
    state.bankAccounts = [];
    state.transactions = [];
    state.transactionsPeriods = [];
    state.creditCards = [];
    state.categories = [];
    state.invoices = [];
    state.pendencias = [];
    state.orcamento = null;
    state.perfil = null;
    state.patrimonio = null;
    state.independenciaFinanceira = null;
    state.customer = null;
    state.actionPlans = [];
    state.investments = [];
    state.planner = null;
    state.lastActionPlanCheck = null;
    state.lifeInsurances = []
    state.meiosPagamento = []
    state.company = null
    state.recommendations = []
  },
  resetCustomer: (state) => {
    state.bankAccounts = [];
    state.transactions = [];
    state.transactionsPeriods = [];
    state.creditCards = [];
    state.categories = [];
    state.invoices = [];
    state.pendencias = [];
    state.orcamento = null;
    state.perfil = null;
    state.patrimonio = null;
    state.independenciaFinanceira = null;
    state.customer = null;
    state.actionPlans = [];
    state.investments = [];
    state.lastActionPlanCheck = null;
    state.recommendations = []

  },
  setIsMobile: (state, payload) => (state.isMobile = payload),
  setInterest: (state, payload) => (state.interest = payload),
  setIsIOS: (state, payload) => (state.isIOS = payload),
  setUser: async (state, payload) => {
    state.user = payload

    if (typeof state.user.iuguSubscriptionStatus === 'undefined') {
      const { suspended } = state.user.iuguSubscription ? await checkIuguSubscriptionStatus(state.user.iuguSubscription) : false
      const data = {
        _id: state.user._id,
        iuguSubscriptionStatus: !suspended
      }

      const user = await saveUser(data)
      state.user = user
    }
  },
  // admin
  setConfigIr: (state, payload) => (state.configIr = payload),
  saveConfigIrAliquotaIrpf: (state, payload) => {
    let index = state.configIr.aliquotaIrpf.findIndex(al => al._id === payload._id);
    if (index > -1) {
      state.configIr.aliquotaIrpf.splice(index, 1, payload);
    } else {
      state.configIr.aliquotaIrpf.push(payload);
    }
  },
  setPlans: (state, payload) => state.plans = payload,
  savePlan: (state, payload) => {
    let index = state.plans.findIndex(p => p.id === payload.id);

    if (index > -1) {
      state.plans.splice(index, 1, payload)
    } else {
      state.plans.push(payload);
    }
  },
  deletePlan: (state, payload) => {
    let index = state.plans.findIndex(p => p.id === payload);
    if (index > -1) {
      state.plans.splice(index, 1)
    }
  },
  setCompaniesNames: (state, payload) => {
    state.companiesNames = payload;
  },
  setPlannersNames: (state, payload) => {
    state.plannersNames = payload;
  },
  setCompany: (state, payload) => {
    state.company = payload;
  },
  // customer
  // bank accounts
  setBankAccounts: (state, payload) => {
    state.bankAccounts = payload
  },
  saveBankAccount: (state, payload) => {
    if (payload) {
      let index = state.bankAccounts.findIndex(ba => ba._id === payload._id);
      if (index > -1)
        state.bankAccounts.splice(index, 1, payload);
      else
        state.bankAccounts.push(payload);
    }
  },
  updateBankAccountBalance: (state, payload) => {
    if (payload) {
      let index = state.bankAccounts.findIndex(ba => ba._id === payload._id);
      if (index > -1) {
        state.bankAccounts[index].balance = payload.balance;
        state.bankAccounts[index].history = payload.history;
      }
    }
  },
  archiveBankAccount: (state, payload) => {
    let index = state.bankAccounts.findIndex(ba => ba._id === payload._id);
    if (index > -1) {
      state.bankAccounts[index].archived = payload.archived;
      state.bankAccounts[index].archivedIn = payload.archivedIn;
    }

  },
  syncBankAccount: (state, payload) => {
    state.syncBankAccount = payload;
  },
  // transactions
  resetTransactions: state => {
    state.transactions = [];
    state.transactionsPeriods = [];
  },
  setTransactions: (state, payload) => {
    payload.forEach(t => {
      let index = state.transactions.findIndex(tr => tr._id === t._id);
      if (index > -1) {
        state.transactions.splice(index, 1, t);
      } else {
        state.transactions.push(t);
      }
    });
  },
  saveTransaction: (state, payload) => {
    let index = state.transactions.findIndex(t => t._id === payload._id);

    if (index > -1) {
      if (payload.bankAccount && !payload.bankAccount._id) {
        let bankAccount = state.bankAccounts.find(ba => ba._id === payload.bankAccount);
        if (bankAccount) {
          payload.bankAccount = {
            _id: bankAccount._id,
            name: bankAccount.name,
            archived: bankAccount.archived,
          }
        }
      }

      state.transactions.splice(index, 1, payload);
    } else {
      state.transactions.push(payload);
    }
  },
  removeTransaction: (state, payload) => {
    let index = state.transactions.findIndex(t => t._id === payload._id);

    if (index > -1) {
      let transaction = state.transactions[index];

      if (payload.type && (transaction.fixed || transaction.divided)) {
        state.transactions
          .filter(t => {
            if (t.fixedId === transaction.fixedId && t.dividedId === transaction.dividedId) {
              if (payload.type === 'all') {
                return true;
              } else if (transaction.fixed) {
                return moment(t.date).isSameOrAfter(transaction.date);
              } else if (transaction.divided) {
                return t.dividedPart >= transaction.dividedPart;
              }
            }
          })
          .forEach(t => {
            let tIndex = state.transactions.findIndex(tr => tr._id === t._id);
            if (tIndex > -1)
              state.transactions.splice(tIndex, 1);
          });
      } else if (transaction.isTransfer) {
        state.transactions
          .filter(t => t.transferId === transaction.transferId)
          .forEach(t => {
            let tIndex = state.transactions.findIndex(tr => tr._id === t._id);
            if (tIndex > -1)
              state.transactions.splice(tIndex, 1);
          });
      } else {
        state.transactions.splice(index, 1);
      }
    }
  },
  saveTransactionsPeriod: (state, payload) => {
    state.transactionsPeriods.push(payload);
  },
  // categories
  setCategories: (state, payload) => {
    state.categories = payload;

    state.categories.forEach(cat => {
      cat.subs.forEach(sub => {
        if (sub.archived === undefined) {
          sub.archived = false
        }
      })
    })
  },
  saveCategory: (state, payload) => {
    let index = state.categories.findIndex(cat => cat._id === payload._id);

    if (index > -1) {
      state.categories.splice(index, 1, payload);
    } else {
      state.categories.push(payload);
    }
  },
  // credit cards
  setCreditCards: (state, payload) => {
    state.creditCards = payload;
  },
  saveCreditCard: (state, payload) => {
    let index = state.creditCards.findIndex(cc => cc._id === payload._id);

    if (index > -1) {
      state.creditCards.splice(index, 1, payload);
    } else {
      state.creditCards.push(payload);
    }
  },
  updateCreditCardLimit: (state, payload) => {
    let index = state.creditCards.findIndex(cc => cc._id === payload._id);

    if (index > -1) {
      state.creditCards[index].usedLimit = payload.usedLimit;
      state.creditCards[index].availableLimit = payload.availableLimit;
    }
  },
  // invoices
  setInvoices: (state, payload) => {
    state.invoices = payload;
  },
  resetInvoices: state => {
    state.invoices = [];
  },
  saveInvoice: (state, payload) => {
    let index = state.invoices.findIndex(invoice => invoice._id === payload._id);

    if (index > -1) {
      state.invoices.splice(index, 1, payload);
    } else {
      state.invoices.push(payload);
    }
  },
  paidInvoice: (state, payload) => {
    let invoice = state.invoices.find(invoice => invoice._id === payload);

    if (invoice && state.transactions) {
      state.transactions.filter(t => {
        return t.invoice && t.invoice._id ? t.invoice._id === invoice._id : t.invoice === invoice._id;
      }).forEach(t => {
        t.consolidated = invoice.paid;
        t.paymentDate = invoice.paid ? invoice.paidIn : null;
        t.bankAccount = invoice.paid ? invoice.bankAccount : null;
      })
    }
  },
  updateInvoiceValue: (state, payload) => {
    let index = state.invoices.findIndex(inv => inv._id === payload._id);

    if (index > -1)
      state.invoices[index].value = payload.value;
  },
  // pendencias
  setPendencias: (state, payload) => {
    payload.forEach(p => {
      if (p.bankAccount && !p.bankAccount._id) {
        p.bankAccount = state.bankAccounts.find(ba => ba._id === p.bankAccount)
      }

      if (p.creditCard && !p.creditCard._id) {
        p.creditCard = state.creditCards.find(cc => cc._id === p.creditCard)
      }

      let index = state.pendencias.findIndex(pd => pd._id === p._id);

      if (index === -1) {
        state.pendencias.push(p);
      }
    });
  },
  removePendencia: (state, payload) => {
    let index = state.pendencias.findIndex(pd => pd._id === payload);

    if (index > -1)
      state.pendencias.splice(index, 1);
  },
  // orçamento
  setOrcamento: (state, payload) => {
    if (payload) {
      payload.items.enabled.forEach(it => {
        if (it.subCategory && !it.category.name) {
          it.category = state.categories.find(c => c._id === it.category)
        }

        if (it.subCategory && !it.subCategory.name && it.category.subs && it.category.subs.length) {
          it.subCategory = it.category.subs.find(sub => sub._id === it.subCategory)
        }
      })
    }

    state.orcamento = payload;
  },
  saveItemOrcamento: (state, payload) => {
    let index = state.orcamento.items.findIndex(it => it._id === payload._id);

    if (index > -1)
      state.orcamento.items.splice(index, 1, payload);
  },
  // perfil
  setPerfil: (state, payload) => {
    state.perfil = payload;
  },
  updatePerfil: (state, payload) => {
    if (payload.informacoesBasicas) {
      if (payload.informacoesBasicas.usuario)
        state.perfil.informacoesBasicas.usuario = payload.informacoesBasicas.usuario;

      if (payload.informacoesBasicas.conjuge)
        state.perfil.informacoesBasicas.conjuge = payload.informacoesBasicas.conjuge;
    }

    if (payload.relacionamento)
      state.perfil.relacionamento = payload.relacionamento;

    if (payload.sons)
      state.perfil.sons = payload.sons;

    if (payload.ocupacaoProfissional) {
      if (payload.ocupacaoProfissional.usuario) {
        if (payload.ocupacaoProfissional.usuario.primeiraExperiencia !== 'undefined')
          state.perfil.ocupacaoProfissional.usuario.primeiraExperiencia = payload.ocupacaoProfissional.usuario.primeiraExperiencia;

        if (payload.ocupacaoProfissional.usuario.ocupacaoAtual)
          state.perfil.ocupacaoProfissional.usuario.ocupacaoAtual = payload.ocupacaoProfissional.usuario.ocupacaoAtual;

        if (payload.ocupacaoProfissional.usuario.extras !== 'undefined')
          state.perfil.ocupacaoProfissional.usuario.extras = payload.ocupacaoProfissional.usuario.extras;
      }

      if (payload.ocupacaoProfissional.conjuge) {
        if (payload.ocupacaoProfissional.conjuge.primeiraExperiencia !== 'undefined')
          state.perfil.ocupacaoProfissional.conjuge.primeiraExperiencia = payload.ocupacaoProfissional.conjuge.primeiraExperiencia;

        if (payload.ocupacaoProfissional.conjuge.ocupacaoAtual)
          state.perfil.ocupacaoProfissional.conjuge.ocupacaoAtual = payload.ocupacaoProfissional.conjuge.ocupacaoAtual;

        if (payload.ocupacaoProfissional.conjuge.extras !== 'undefined')
          state.perfil.ocupacaoProfissional.conjuge.extras = payload.ocupacaoProfissional.conjuge.extras;
      }
    }

    if (payload.protecaoFamiliar) {
      if (payload.protecaoFamiliar.seguros)
        state.perfil.protecaoFamiliar.seguros = payload.protecaoFamiliar.seguros;
    }

    if (payload.experienciaFinanceira) {
      if (payload.experienciaFinanceira.usuario) {
        if (payload.experienciaFinanceira.usuario.mesada != undefined)
          state.perfil.experienciaFinanceira.usuario.mesada = payload.experienciaFinanceira.usuario.mesada;

        if (payload.experienciaFinanceira.usuario.capacidadePoupanca)
          state.perfil.experienciaFinanceira.usuario.capacidadePoupanca = payload.experienciaFinanceira.usuario.capacidadePoupanca;

        if (payload.experienciaFinanceira.usuario.despesasMensais != undefined)
          state.perfil.experienciaFinanceira.usuario.despesasMensais = payload.experienciaFinanceira.usuario.despesasMensais;

        if (payload.experienciaFinanceira.usuario.primeiroInvestimento)
          state.perfil.experienciaFinanceira.usuario.primeiroInvestimento = payload.experienciaFinanceira.usuario.primeiroInvestimento;

        if (payload.experienciaFinanceira.usuario.experienciasInvestimentos)
          state.perfil.experienciaFinanceira.usuario.experienciasInvestimentos = payload.experienciaFinanceira.usuario.experienciasInvestimentos;

        if (payload.experienciaFinanceira.usuario.fatorExperiencia)
          state.perfil.experienciaFinanceira.usuario.fatorExperiencia = payload.experienciaFinanceira.usuario.fatorExperiencia;
      }

      if (payload.experienciaFinanceira.conjuge) {
        if (payload.experienciaFinanceira.conjuge.mesada != undefined)
          state.perfil.experienciaFinanceira.conjuge.mesada = payload.experienciaFinanceira.conjuge.mesada;

        if (payload.experienciaFinanceira.conjuge.capacidadePoupanca)
          state.perfil.experienciaFinanceira.conjuge.capacidadePoupanca = payload.experienciaFinanceira.conjuge.capacidadePoupanca;

        if (payload.experienciaFinanceira.conjuge.despesasMensais != undefined)
          state.perfil.experienciaFinanceira.conjuge.despesasMensais = payload.experienciaFinanceira.conjuge.despesasMensais;

        if (payload.experienciaFinanceira.conjuge.primeiroInvestimento)
          state.perfil.experienciaFinanceira.conjuge.primeiroInvestimento = payload.experienciaFinanceira.conjuge.primeiroInvestimento;

        if (payload.experienciaFinanceira.conjuge.experienciasInvestimentos)
          state.perfil.experienciaFinanceira.conjuge.experienciasInvestimentos = payload.experienciaFinanceira.conjuge.experienciasInvestimentos;

        if (payload.experienciaFinanceira.conjuge.fatorExperiencia)
          state.perfil.experienciaFinanceira.conjuge.fatorExperiencia = payload.experienciaFinanceira.conjuge.fatorExperiencia;
      }
    }

    if (payload.completed)
      state.perfil.completed = true;

    if (payload.step)
      state.perfil.step = payload.step;
  },
  // patrimonio
  setPatrimonio: (state, payload) => {
    state.patrimonio = payload;
  },
  saveItemPatrimonio: (state, payload) => {
    let index = state.patrimonio.items.findIndex(p => p._id === payload._id);
    if (index > -1)
      state.patrimonio.items.splice(index, 1, payload);
    else
      state.patrimonio.items.push(payload);
  },
  removeItemPatrimonio: (state, payload) => {
    let index = state.patrimonio.items.findIndex(p => p._id === payload);
    if (index > -1)
      state.patrimonio.items.splice(index, 1);
  },
  // independência financeira
  setIndependenciaFinanceira: (state, payload) => {
    payload.test = true
    state.independenciaFinanceira = payload;
  },
  saveProjetoIndFinanceira: (state, payload) => {
    let index = state.independenciaFinanceira.projetos.findIndex(p => p._id === payload._id);
    if (index > -1)
      state.independenciaFinanceira.projetos.splice(index, 1, payload);
    else
      state.independenciaFinanceira.projetos.push(payload);
  },
  removeProjetoIndFinanceira: (state, payload) => {
    let index = state.independenciaFinanceira.projetos.findIndex(p => p._id === payload);
    if (index > -1)
      state.independenciaFinanceira.projetos.splice(index, 1);
  },
  // customer
  setCustomer: async (state, payload) => {
    state.customer = payload;

    if (typeof state.customer.iuguSubscriptionStatus === 'undefined') {
      const { suspended } = state.customer.iuguSubscription ? await checkIuguSubscriptionStatus(state.customer.iuguSubscription) : false
      const data = {
        _id: state.customer._id,
        iuguSubscriptionStatus: !suspended
      }

      const customer = await saveUser(data)
      state.customer = customer
    }
  },
  // liquidez
  saveLiquidez: (state, payload) => {
    state.liquidez = payload;
  },
  // planner
  setPlanner: (state, payload) => {
    state.planner = payload;
  },
  // investments
  setInvestments: (state, payload) => {
    state.investments = payload;
  },
  saveInvestment: (state, payload) => {
    let index = state.investments.findIndex(inv => inv._id === payload._id);

    if (index > -1) {
      state.investments.splice(index, 1, payload);
    } else {
      state.investments.push(payload);
    }
  },
  deleteInvestment: (state, payload) => {
    let index = state.investments.findIndex(inv => inv._id === payload);

    if (index > -1) {
      state.investments.splice(index, 1);
    }
  },
  saveInvestmentTransaction: (state, payload) => {
    let index = state.investments.findIndex(i => i._id === payload.investmentId);
    if (index > -1) {
      let tIndex = state.investments[index].transactions.findIndex(t => t._id === payload.transaction._id);

      if (tIndex > -1) {
        state.investments[index].transactions.splice(tIndex, 1, payload.transaction);
      } else {
        state.investments[index].transactions.push(payload.transaction);
      }
    }
  },
  deleteInvestmentTransaction: (state, payload) => {
    let index = state.investments.findIndex(i => i._id === payload.investmentId);
    if (index > -1) {
      let tIndex = state.investments[index].transactions.findIndex(t => t._id === payload.transactionId);

      if (tIndex > -1) {
        state.investments[index].transactions.splice(tIndex, 1);
      }
    }
  },
  // planos de acao
  setActionPlans: (state, payload) => {
    state.actionPlans = payload;
  },
  saveActionPlan: (state, payload) => {
    let index = state.actionPlans.findIndex(ap => ap._id === payload._id);
    if (index > -1) {
      state.actionPlans.splice(index, 1, payload);
    } else {
      state.actionPlans.push(payload);
    }
  },
  removeActionPlan: (state, payload) => {
    let index = state.actionPlans.findIndex(ap => ap._id === payload);
    if (index > -1) {
      state.actionPlans.splice(index, 1);
    }
  },
  checkActionPlans: state => {
    let currentDate = moment().tz('America/Sao_Paulo').format('YYYY-MM-DD');

    if (state.user && state.user.role === 'customer' && (!state.lastActionPlanCheck || moment(currentDate).tz('America/Sao_Paulo').isAfter(state.lastActionPlanCheck))) {
      checkActionPlansDeadline(state.user._id)
        .then(() => {
          state.lastActionPlanCheck = moment().tz('America/Sao_Paulo').format('YYYY-MM-DD');
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    }
  },
  setLifeInsurances: (state, payload) => {
    state.lifeInsurances = payload;
  },
  saveLifeInsurance: (state, payload) => {
    let index = state.lifeInsurances.findIndex(insurance => insurance._id === payload._id);

    if (index > -1) {
      state.lifeInsurances.splice(index, 1, payload)
    } else {
      state.lifeInsurances.push(payload)
    }
  },
  saveLifeInsuranceDependentsNote: (state, payload) => {
    const lifeInsurance = state.lifeInsurances.find(ins => ins._id === payload.insuranceId);
    if (lifeInsurance) {
      let index = lifeInsurance.custosDependentes.notes.findIndex(note => note._id === payload.note._id);
      if (index > -1) {
        lifeInsurance.custosDependentes.notes.splice(index, 1, payload.note);
      } else {
        lifeInsurance.custosDependentes.notes.push(payload.note);
      }
    }
  },
  deleteLifeInsuranceDependentsNote: (state, payload) => {
    const lifeInsurance = state.lifeInsurances.find(ins => ins._id === payload.insuranceId);
    if (lifeInsurance) {
      let index = lifeInsurance.custosDependentes.notes.findIndex(note => note._id === payload.noteId);
      if (index > -1) {
        lifeInsurance.custosDependentes.notes.splice(index, 1);
      }
    }
  },
  // meios de pagamento
  setMeiosPagamento: (state, payload) => {
    state.meiosPagamento = payload
  },
  saveMeioPagamento: (state, payload) => {
    let index = state.meiosPagamento.findIndex(it => it.id === payload.id)

    if (index > -1) {
      state.meiosPagamento.splice(index, 1, payload)
    } else {
      state.meiosPagamento.push(payload)
    }
  },
  deleteMeioPagamento: (state, payload) => {
    let index = state.meiosPagamento.findIndex(it => it.id === payload)

    if (index > -1) {
      state.meiosPagamento.splice(index, 1)
    }
  },
  // subscription
  setSubscriptionSuspended: (state, payload) => {
    state.subscriptionSuspended = payload
  },

  // recommendations
  setRecommendations: (state, payload) => {
    state.recommendations = payload
  },
  saveRecommendation: (state, payload) => {
    const index = state.recommendations.findIndex(indicacao => indicacao._id === payload._id)

    if (index > -1) {
      state.recommendations.splice(index, 1, payload)
    }

  },

  //banksAndCodes
  setBanksAndCodes: (state, payload) => {
    state.banksAndCodes = payload
  },

  //requestReward
  setRequestReward: (state, payload) => {
    state.requestReward = payload
  },
  saveRequestReward: (state, payload) => {
    let index = state.requestReward.findIndex(it => it._id === payload._id)

    if (index > -1) {
      state.requestReward.splice(index, 1, payload)
    } else {
      state.requestReward.push(payload)
    }
  },
  setOpenBankingError: (state, payload) => {
    state.hasOpenBankingError = payload
  },

  // pluggy
  clearPluggyItemData: (state, payload) => {
    // clear transactions
    const transactions = state.transactions.filter(tr => {
      return !!tr.pluggy && tr.pluggy.item === payload
    })

    transactions.forEach(tr => {
      const index = state.transactions.findIndex(t => t._id === tr._id)
      state.transactions.splice(index, 1)
    })

    // clear bank accounts
    const bankAccounts = state.bankAccounts.filter(bc => {
      return !!bc.pluggy && bc.pluggy.item === payload
    })

    bankAccounts.forEach(bc => {
      const index = state.bankAccounts.findIndex(b => b._id === bc._id)
      state.bankAccounts.splice(index, 1)
    })

    // clear credit cards and invoices
    const creditCards = state.creditCards.filter(cc => {
      return !!cc.pluggy && cc.pluggy.item === payload
    })

    const creditCardsIds = creditCards.map(cc => cc._id)

    const invoices = state.invoices.filter(inv => {
      return creditCardsIds.includes(inv.creditCard) || creditCardsIds.includes(inv.creditCard._id)
    })

    creditCards.forEach(cc => {
      const index = state.creditCards.findIndex(c => c._id === cc._id)
      state.creditCards.splice(index, 1)
    })

    invoices.forEach(inv => {
      const index = state.invoices.findIndex(i => i._id === inv._id)
      state.invoices.splice(index, 1)
    })
  },

  // pendentCreditCards
  setPendentCreditCards: (state, payload) => {
    state.pendentCreditCards = payload
  },
  deletePendentCreditCard: (state, payload) => {
    if (payload) {
      const index = state.pendentCreditCards.findIndex(item => item._id === payload)
      if (index > -1)
        state.pendentCreditCards.splice(index, 1)
    }
  },
  setTotalPendencias: (state, payload) => {
    state.totalPendencias = payload
  }
};
