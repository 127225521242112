<template>
  <v-dialog
    v-model="dialog"
    :persistent="loader"
    :fullscreen="isMobile"
    max-width="400px"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey3">
      <v-card-text class="dialog-form-title safe-area-top pt-4">
        <v-btn
          icon
          color="error"
          :disabled="loader"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="error--text">Remover Meio de Pagamento</span>

        <v-btn icon></v-btn>
      </v-card-text>

      <v-card-text class="pt-5">
        <p class="white--text">
          Tem certeza que deseja remover este meio de pagamento? <br>
          <span class="error--text">Sua assinatura poderá ser suspensa!</span>
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          dark
          rounded
          class="px-5"
          color="error"
          @click="submit"
          :loading="loader"
        >
          Remover
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import deleteIuguCustomerPaymentMethod from '@/api/iugu/paymentMethods/deleteIuguCustomerPaymentMethod';

export default {
  name: 'DialogDeleteMeioPagamento',
  data: () => ({
    dialog: false,
    loader: false,
    paymentMethodId: null
  }),
  computed: {
    user() {
      return this.$store.getters.user
    },
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
  methods: {
    submit() {
      if (this.user && this.user.iuguId) {
        this.loader = true

        deleteIuguCustomerPaymentMethod(this.user.iuguId, this.paymentMethodId)
          .then(() => {
            this.$store.dispatch('deleteMeioPagamento', this.paymentMethodId)
            this.$noty.success('Meio de pagamento removido com sucesso!')
            this.dialog = false
          })
          .catch(err => {
            this.loader = false
            this.$noty.error('Desculpe! Não foi possível remover este meio de pagamento. Tente novamente mais tarde!')
          })
      }
    },
  },
  watch: {
    dialog(v) {
      if (!v) {
        this.loader = false
        this.paymentMethodId = null
      }
    }
  },
  created() {
    this.$bus.$on('showDialogDeleteMeioPagamento', paymentMethodId => {
      if (paymentMethodId) {
        this.paymentMethodId = paymentMethodId
        this.dialog = true
      }
    })
  }
}
</script>

<style scoped>
.v-card__text.dialog-form-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #131313;
}

.v-card__text.dialog-form-title span {
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 600px) {
  .v-card__text.dialog-form-title span {
    font-size: 16px;
  }
}
</style>
