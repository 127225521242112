import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Contar total de usuário registrados
 * @param {object} filter {search, disabledStatus, today, status, type, company, planner}
 */
const countUsers = (filter = {}) =>
  new Promise((resolve, reject) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          if (filter.search === undefined) filter.search = '';
          if (filter.disabledStatus === undefined) filter.disabledStatus = '';
          if (filter.today === undefined) filter.today = '';
          if (filter.status === undefined) filter.status = '';
          if (filter.type === undefined) filter.type = '';

          let url = `/users/count?search=${filter.search}&disabledStatus=${filter.disabledStatus}&today=${filter.today}&status=${filter.status}&type=${filter.type}`;

          if (filter.company) url += `&companyId=${filter.company}`;
          if (filter.planner) url += `&plannerId=${filter.planner}`;

          return httpClient(url, {
            headers: { Authorization: `Bearer ${idToken}` }
          });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });

export default countUsers;
