import firebase from 'firebase/app';
import httpClient from "../../plugins/httpClient";

const getUserIndFinanceira = (userId, period) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let headers = {Authorization: `Bearer ${idToken}`};
          let url = `/independencia-financeira/users/${userId}?period=${period}`;

          return httpClient(url, {headers});
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch (err) {
      reject(err);
    }
  });
}

export default getUserIndFinanceira;
