<template>
  <div>
    <v-progress-linear
      indeterminate
      class="ma-0"
      v-if="loader"
      absolute
      color="primary"
    ></v-progress-linear>

    <v-app-bar flat color="#2d2d2d" v-if="user">
      <v-app-bar-nav-icon color="grey1" x-large @click="drawer = !drawer"></v-app-bar-nav-icon>

      <img src="../../../assets/simbolo-uplanner.png" />

      <v-spacer></v-spacer>

      <v-tabs background-color="#2d2d2d" centered :hide-slider="!routes.includes(route)">
        <v-tab to="/cashflow">Visão geral</v-tab>
        <v-tab to="/transactions">Transações</v-tab>
        <v-tab to="/relatorios">Relatórios</v-tab>
        <v-tab to="/orcamento">Orçamento</v-tab>
        <v-tab to="/ind-financeira">Independência</v-tab>
      </v-tabs>

      <v-spacer></v-spacer>

      <v-menu left bottom close-on-click max-width="293px">
        <template v-slot:activator="{ on }">
          <v-btn icon class="mr-1" v-on="on">
            <v-icon color="grey1">mdi-cog</v-icon>
          </v-btn>
        </template>

        <v-card color="grey2">
          <v-card-text class="pa-2">
            <div class="menu-list">
              <div class="menu-list-item" @click="showDialogCategories">
                <div class="menu-list-item__icon">
                  <v-icon color="primary">mdi-chevron-left</v-icon>
                </div>
                <div class="menu-list-item__content">
                  <span class="menu-list-item__content-title primary--text text-uppercase">
                    Categorias
                  </span>
                  <span class="menu-list-item__content-desc text-right grey1--text">
                    Criar/editar categorias e sub-categorias
                  </span>
                </div>
                <div class="menu-list-item__icon">
                  <v-icon color="primary">mdi-bookmark-outline</v-icon>
                </div>
              </div>

              <div class="menu-list-item" @click="showDialogFormImportOfx">
                <div class="menu-list-item__icon">
                  <v-icon color="primary">mdi-chevron-left</v-icon>
                </div>
                <div class="menu-list-item__content">
                  <span class="menu-list-item__content-title primary--text text-uppercase">
                    Importar OFX
                  </span>
                  <span class="menu-list-item__content-desc text-right grey1--text">
                    Importe seu extrato bancário ou fatura do cartão de crédito em formato .ofx
                  </span>
                </div>
                <div class="menu-list-item__icon">
                  <v-icon color="primary">mdi-bookmark-outline</v-icon>
                </div>
              </div>

              <div class="menu-list-item" @click="showDialogFormImportPdf">
                <div class="menu-list-item__icon">
                  <v-icon color="primary">mdi-chevron-left</v-icon>
                </div>
                <div class="menu-list-item__content">
                  <span class="menu-list-item__content-title primary--text text-uppercase">
                    Importar PDF
                  </span>
                  <span class="menu-list-item__content-desc text-right grey1--text">
                    Importe seu extrato bancário ou fatura do cartão de crédito em formato .pdf
                  </span>
                </div>
                <div class="menu-list-item__icon">
                  <v-icon color="primary">mdi-bookmark-outline</v-icon>
                </div>
              </div>

            </div>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-menu
        left
        bottom
        width="300px"
        origin="top right"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-avatar v-on="on" size="45" color="grey2">
            <img v-if="user && user.photoURL" :src="user.photoURL">
            <v-icon v-else color="grey1">mdi-account</v-icon>
          </v-avatar>
        </template>

        <v-card color="grey2" width="300px">
          <v-card-text>
            <div class="profile-photo">
              <v-avatar size="71" color="grey2">
                <img v-if="user.photoURL" :src="user.photoURL">
                <v-icon v-else color="grey1" x-large>mdi-account</v-icon>
              </v-avatar>
              <div class="profile-name primary--text text-center mt-2">
                {{ user.displayName }}
              </div>
            </div>

            <v-tabs
              dark
              fixed-tabs
              class="mt-5"
              color="primary"
              background-color="transparent"
            >
              <v-tab>Geral</v-tab>
              <v-tab-item style="background-color: transparent !important;">
                <v-list nav>
                  <v-list-item
                    :to="item.path"
                    :key="item.text"
                    v-for="item in menuItems"
                  >
                    <v-list-item-icon>
                      <v-icon color="primary">{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="primary--text menu-item-desc">{{ item.text }}</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="logout">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="primary--text menu-item-desc">Sair</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-tab-item>

              <v-tab>Sobre</v-tab>
              <v-tab-item style="background-color: transparent !important;">
                <v-list nav>
                  <v-list-item @click="goToHelp">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-help-circle-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="primary--text menu-item-desc">Ajuda</v-list-item-title>
                  </v-list-item>

                  <v-list-item to="/termos-uso">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-security</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="primary--text menu-item-desc">
                      Termo de adesão
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item to="/politica-privacidade">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-security</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="primary--text menu-item-desc">
                      Política de privacidade
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      dark
      absolute
      temporary
      width="290px"
      color="grey2"
      v-model="drawer"
    >
      <v-list nav dense :class="isIOS ? 'ios-padding' : ''">
        <v-list-item v-for="(item, i) in items" :to="item.path" :key="i">
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="primary--text menu-item">{{ item.name }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import moment from 'moment-timezone'
  import firebase from 'firebase/app'
import logout from '../../../utils/logout';

  let currentDate = moment().tz('America/Sao_Paulo');
  let currentYear = currentDate.year();
  let currentMonth = currentDate.month() + 1;

  export default {
    name: 'CustomerToolbar',
    data: () => ({
      tabs: null,
      period: '',
      monthStr: '',
      loader: false,
      drawer: false,
      items: [
        { name: 'Relatório', icon: 'mdi-chart-arc', path: '/relatorios' },
        { name: 'Independência', icon: 'mdi-beach', path: '/ind-financeira' },
        { name: 'Objetivos', icon: 'mdi-target', path: '/objetivo' },
        { name: 'Investimentos', icon: 'mdi-chart-areaspline', path: '/investimentos' },
        { name: 'Seguro de vida', icon: 'mdi-security', path: '/seguro-vida' },
        { name: 'Planos de Ação', icon: 'mdi-format-list-checks', path: '/planos-acao' },
      ],
      menuItems: [
        { text: 'Editar perfil', icon: 'mdi-account', path: '/perfil' },
        { text: 'Meu cadastro', icon: 'mdi-file-document-outline', path: '/cadastro' },
        // { text: 'Ajuda', icon: 'mdi-help-circle-outline', path: '' }
      ],
      routes: ['Cashflow', 'Transactions', 'Relatorios', 'Orcamento', 'IndFinanceira'],
      helpLink: 'https://uplanner.zendesk.com/hc/pt-br/categories/360003418172-Web'
    }),
    computed: {
      user() {
        return this.$store.getters.user;
      },
      months() {
        return this.$store.getters.months;
      },
      isIOS() {
        return this.$store.getters.isIOS;
      },
      route() {
        return this.$route.name;
      }
    },
    methods: {
      changePeriod(type) {
        if (this.period) {
          let [year, month] = this.period.split('-');

          year = parseInt(year);
          month = parseInt(month);

          if (type === 'next') {
            month++;
            if (month > 12) {
              year++;
              month = 1;
            }
          } else if (type === 'back') {
            month--;
            if (month < 1) {
              year--;
              month = 12;
            }
          }

          if (month < 10) month = `0${month}`;
          this.period = `${year}-${month}`;
        } else {
          let month = currentMonth;
          if (month < 10) month = `0${month}`;
          this.period = `${currentYear}-${month}`;
        }

        this.updatePeriod();
      },
      formatMonthStr() {
        if (this.period) {
          let [year, month] = this.period.split('-');

          year = parseInt(year);
          month = parseInt(month);

          let monthData = this.months.find(m => m.numero === month);
          if (year === currentYear) this.monthStr = monthData.nome;
          else this.monthStr = `${monthData.nome}/${year}`;
        }
      },
      updatePeriod() {
        this.$bus.$emit('updatePeriod', this.period)
      },
      showMenu() {
        this.$bus.$emit('showMenu');
      },
      showDialogCategories() {
        this.$bus.$emit('showDialogCategories');
      },
      showDialogFormImportOfx() {
        this.$bus.$emit('showDialogFormImportOfx');
      },
      showDialogFormImportPdf() {
        this.$bus.$emit('showDialogFormImportPdf');
      },
      logout() {
        logout()
      },
      goToHelp() {
        let url;

        if (this.isMobile)
          url = 'https://uplanner.zendesk.com/hc/pt-br/categories/360003418172-Web';
        else
          url = 'https://uplanner.zendesk.com/hc/pt-br/categories/360003418172-Web';

        window.open(url, '_blank');
      }
    },
    watch: {
      period() {
        this.formatMonthStr();
      }
    },
    created() {
      this.changePeriod();

      this.$bus.$on('toggleLoader', () => {
        this.loader = !this.loader;
      })
    }
  };
</script>

<style scoped>
  .v-item-group.theme--dark.v-slide-group.v-tabs-bar.primary--text {
    background-color: #2D2D2D !important;
  }

  .month-name {
    color: #9E9E9E;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: .1em;
    min-width: 173px;
    text-align: center;
  }

  .v-window.v-item-group.theme--dark.v-tabs-items {
    background-color: #404040 !important;
  }

  .theme--dark.v-list {
    background-color: #404040 !important;
  }

  .v-btn.btn-chevron .v-icon {
    font-size: 30px !important;
  }

  .menu-list {
    display: flex;
    flex-direction: column;
  }

  .menu-list-item {
    padding: 5px;
    display: flex;
    cursor: pointer;
    transition: all .5s ease;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #919191;
    margin-bottom: 5px;
  }

  .menu-list-item:hover {
    background-color: #2d2d2d;
  }

  .menu-list-item__icon:last-child {
    width: 50px;
    text-align: right;
  }

  .menu-list-item__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .menu-list-item__content-title {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .menu-list-item__content-desc {
    font-size: 9px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .profile-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .profile-name {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .1em;
  }

  .menu-item-desc {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: .1em;
    text-transform: uppercase;
  }
</style>
