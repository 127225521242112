<template>
  <v-card flat color="grey3">
    <v-card-title :class="isIOS ? 'ios-padding' : ''">Ocupação Profissional</v-card-title>

    <v-card-text class="px-1">
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12>
            <h3>{{ username }}</h3>
          </v-flex>

          <v-flex xs12>
            <span class="input-label primary--text">
              Como foi a sua primeira experiência profissional?<br />
              (Estágio? Trabalhou com os pais? Vendeu algo no colégio?)
            </span>
            <v-text-field
              type="text"
              color="grey1"
              class="pt-0 mt-0"
              v-model="usuario.primeiraExperiencia"
              :error-messages="usuarioPrimeiraExperienciaErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <h4>Ocupação atual</h4>
          </v-flex>

          <v-flex xs12 sm12 md6>
            <span class="input-label primary--text">Cargo</span>
            <v-text-field
              type="text"
              color="grey1"
              class="mt-0 pt-0"
              v-model="usuario.ocupacaoAtual.cargo"
              :error-messages="usuarioOcupacaoAtualCargoErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md6>
            <span class="input-label primary--text">Remuneração</span>
            <v-text-field
              type="tel"
              color="grey1"
              class="mt-0 pt-0"
              v-money="money"
              v-model="usuario.ocupacaoAtual.remuneracaoAtual"
              :error-messages="usuarioOcupacaoAtualRemuneracaoErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <h4>Fontes de renda extra</h4>
          </v-flex>

          <v-flex xs12 v-for="(extra, i) in usuario.extras" :key="extra._id">
            <v-layout row wrap class="pa-0 ma-0">
              <v-flex xs12 sm6 md6>
                <span class="input-label white--text">Descrição</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="extra.descricao"
                ></v-text-field>
              </v-flex>

              <v-flex xs10 sm5 md5>
                <span class="input-label white--text">Renda</span>
                <v-text-field
                  type="tel"
                  v-money="money"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="extra.renda"
                ></v-text-field>
              </v-flex>

              <v-flex xs2 sm1 md1 class="pt-5 text-right">
                <v-btn
                  icon
                  color="error"
                  @click="removeExtra(extra._id, 'usuario')"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs12 class="my-2" v-if="usuario.extras[i + 1]">
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 class="text-center">
            <v-btn
              dark
              small
              rounded
              class="px-5"
              color="purple"
              @click="addExtra('usuario')"
            >
              Adicionar fonte de renda extra
            </v-btn>
          </v-flex>

          <v-flex xs12 class="mt-10" v-if="hasConjuge">
            <h3>{{ conjugeName }}</h3>
          </v-flex>

          <v-flex xs12 v-if="hasConjuge">
            <span class="input-label primary--text">
              Como foi a sua primeira experiência profissional?<br />
              (Estágio? Trabalhou com os pais? Vendeu algo no colégio?)
            </span>
            <v-text-field
              type="text"
              color="grey1"
              class="pt-0 mt-0"
              v-model="conjuge.primeiraExperiencia"
              :error-messages="conjugePrimeiraExperienciaErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 v-if="hasConjuge">
            <h4>Ocupação atual</h4>
          </v-flex>

          <v-flex xs12 sm12 md6 v-if="hasConjuge">
            <span class="input-label primary--text">Cargo</span>
            <v-text-field
              type="text"
              color="grey1"
              class="mt-0 pt-0"
              v-model="conjuge.ocupacaoAtual.cargo"
              :error-messages="conjugeOcupacaoAtualCargoErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md6 v-if="hasConjuge">
            <span class="input-label primary--text">Remuneração</span>
            <v-text-field
              type="tel"
              color="grey1"
              class="mt-0 pt-0"
              v-money="money"
              v-model="conjuge.ocupacaoAtual.remuneracaoAtual"
              :error-messages="conjugeOcupacaoAtualRemuneracaoErrors"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 v-if="hasConjuge">
            <h4>Fontes de renda extra</h4>
          </v-flex>

          <v-flex xs12 v-if="hasConjuge">
            <v-layout
              row
              wrap
              class="pa-0 ma-0"
              v-for="(extra, i) in conjuge.extras"
              :key="extra._id"
            >
              <v-flex xs12 sm6 md6>
                <span class="input-label white--text">Descrição</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="extra.descricao"
                ></v-text-field>
              </v-flex>

              <v-flex xs10 sm5 md5>
                <span class="input-label white--text">Renda</span>
                <v-text-field
                  type="tel"
                  v-money="money"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="extra.renda"
                ></v-text-field>
              </v-flex>

              <v-flex xs2 sm1 md1 class="pt-5 text-right">
                <v-btn
                  icon
                  color="error"
                  @click="removeExtra(extra._id, 'usuario')"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs12 class="my-2" v-if="conjuge.extras[i + 1]">
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 class="text-center" v-if="hasConjuge">
            <v-btn
              dark
              small
              rounded
              class="px-5"
              color="purple"
              @click="addExtra('usuario')"
            >
              Adicionar fonte de renda extra
            </v-btn>
          </v-flex>

          <v-flex xs12 class="mt-10">
            <v-card-actions class="pa-0 ma-0">
              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="stepBack"
              >
                Voltar
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="skip"
              >
                Pular
              </v-btn>

              <v-btn
                rounded
                color="primary"
                class="px-5 darkgrey--text"
                :loading="loader"
                @click.stop="submit"
              >
                Próximo
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from "v-money";
import removeMoneyMask from "@/utils/removeMoneyMask";
import updatePerfil from "@/api/perfil/updatePerfil";

export default {
  name: "FormOcupacaoProfissional",
  directives: { money: VMoney },
  props: [
    "profileId",
    "ocupacaoProfissional",
    "hasConjuge",
    "conjugeName",
    "username",
  ],
  data: () => ({
    loader: false,
    usuario: {
      primeiraExperiencia: "",
      ocupacaoAtual: {
        cargo: "",
        remuneracaoAtual: 0,
      },
      extras: [],
    },
    conjuge: {
      primeiraExperiencia: "",
      ocupacaoAtual: {
        cargo: "",
        remuneracaoAtual: 0,
      },
      extras: [],
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
    },
  }),
  validations: {
    usuario: {
      primeiraExperiencia: {
        isValid(v) {
          return !v || v.trim().length <= 512;
        },
      },
      ocupacaoAtual: {
        cargo: {
          isValid(v) {
            return !v || v.trim().length <= 150;
          },
        },
        remuneracaoAtual: {
          isValid(v) {
            return !v || removeMoneyMask(v) >= 0;
          },
        },
      },
    },
    conjuge: {
      primeiraExperiencia: {
        isValid(v) {
          return !this.hasConjuge || !v || v.trim().length <= 512;
        },
      },
      ocupacaoAtual: {
        cargo: {
          isValid(v) {
            return !this.hasConjuge || !v || v.trim().length <= 150;
          },
        },
        remuneracaoAtual: {
          isValid(v) {
            return !this.hasConjuge || !v || removeMoneyMask(v) >= 0;
          },
        },
      },
    },
  },
  computed: {
    // helpers
    isIOS() {
      return this.$store.getters.isIOS;
    },
    // error messages
    usuarioPrimeiraExperienciaErrors() {
      if (!this.$v.usuario.primeiraExperiencia.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.primeiraExperiencia.$anyError) {
        return ["Não utilize mais do que 512 caracteres"];
      }
    },
    usuarioOcupacaoAtualCargoErrors() {
      if (!this.$v.usuario.ocupacaoAtual.cargo.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.ocupacaoAtual.cargo.$anyError) {
        return ["Não utilize mais do que 150 caracteres"];
      }
    },
    usuarioOcupacaoAtualRemuneracaoErrors() {
      if (!this.$v.usuario.ocupacaoAtual.remuneracaoAtual.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.ocupacaoAtual.remuneracaoAtual.$anyError) {
        return ["Preencha um valor maior ou igual a zero"];
      }
    },
    conjugeOcupacaoAtualRemuneracaoErrors() {
      if (!this.$v.conjuge.ocupacaoAtual.remuneracaoAtual.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.ocupacaoAtual.remuneracaoAtual.$anyError) {
        return ["Preencha um valor maior ou igual a zero"];
      }
    },
    conjugeOcupacaoAtualCargoErrors() {
      if (!this.$v.conjuge.ocupacaoAtual.cargo.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.ocupacaoAtual.cargo.$anyError) {
        return ["Não utilize mais do que 150 caracteres"];
      }
    },
    conjugePrimeiraExperienciaErrors() {
      if (!this.$v.conjuge.primeiraExperiencia.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.primeiraExperiencia.$anyError) {
        return ["Não utilize mais do que 512 caracteres"];
      }
    },
  },
  methods: {
    copyData() {
      this.usuario = {
        ...this.usuario,
        ...this.ocupacaoProfissional.usuario,
      };

      this.usuario.ocupacaoAtual.remuneracaoAtual =
        this.usuario.ocupacaoAtual.remuneracaoAtual.toFixed(2);

      if (!this.usuario.extras.length) {
        this.addExtra("usuario");
      } else {
        this.usuario.extras = this.usuario.extras.map((e) => {
          e.renda = e.renda.toFixed(2);
          return e;
        });
      }

      if (this.hasConjuge) {
        this.conjuge = {
          ...this.conjuge,
          ...this.ocupacaoProfissional.conjuge,
        };

        this.conjuge.ocupacaoAtual.remuneracaoAtual =
          this.conjuge.ocupacaoAtual.remuneracaoAtual.toFixed(2);

        if (!this.conjuge.extras.length) {
          this.addExtra("conjuge");
        } else {
          this.conjuge.extras = this.conjuge.extras.map((e) => {
            e.renda = e.renda.toFixed(2);
            return e;
          });
        }
      }
    },
    stepBack() {
      this.$bus.$emit("backProfileStep");
    },
    skip() {
      updatePerfil(this.profileId, { step: 5 }).then((perfil) => {
        this.$store.dispatch("setPerfil", perfil);
      });

      this.$bus.$emit("nextProfileStep");
    },
    addExtra(type) {
      this[type].extras.push({
        _id: `${Date.now()}`,
        descricao: "",
        renda: "0.00",
      });
    },
    removeExtra(id, type) {
      let index = this[type].extras.findIndex((e) => e._id === id);
      if (index > -1) {
        this[type].extras.splice(index, 1);
      }

      if (!this[type].extras.length) {
        this.addExtra(type);
      }
    },
    sanitizeExtras(extras) {
      return extras
        .map((e) => {
          let obj = {
            _id: e._id,
            renda: removeMoneyMask(e.renda),
            descricao: e.descricao.trim(),
          };

          if (!e._id.replace(/\d+/g, "").length) {
            delete obj._id;
          }

          return obj;
        })
        .filter((e) => {
          return (
            typeof e.descricao == "string" &&
            e.descricao.length &&
            e.descricao.length <= 150 &&
            typeof e.renda === "number" &&
            e.renda >= 0
          );
        });
    },
    async submit() {
      try {
        this.$v.$touch();

        if (!this.hasConjuge) {
          this.$v.conjuge.$reset();
        }

        if (!this.$v.$anyError) {
          this.loader = true;

          const usuario = JSON.parse(JSON.stringify(this.usuario));

          usuario.ocupacaoAtual.remuneracaoAtual = removeMoneyMask(
            usuario.ocupacaoAtual.remuneracaoAtual
          );
          usuario.extras = this.sanitizeExtras(usuario.extras);

          let conjuge = JSON.parse(JSON.stringify(this.conjuge));

          if (this.hasConjuge) {
            conjuge.ocupacaoAtual.remuneracaoAtual = removeMoneyMask(
              conjuge.ocupacaoAtual.remuneracaoAtual
            );
            conjuge.extras = this.sanitizeExtras(conjuge.extras);
          } else {
            conjuge = null;
          }

          const ocupacaoProfissional = { usuario, conjuge };
          const perfil = await updatePerfil(this.profileId, {
            ocupacaoProfissional,
            step: 5,
          });

          await this.$store.dispatch("setPerfil", perfil);

          this.$bus.$emit("nextProfileStep");
        }
      } catch (err) {
        this.$noty.error(
          "Desculpe, não foi possível salvar seus dados. Tente novamente mais tarde."
        );

        if (process.env.NODE_ENV !== "production") {
          console.error(err);
        }
      } finally {
        this.loader = false;
      }
    },
  },
  mounted() {
    this.copyData();
  },
};
</script>

<style scoped>
@media only screen and (max-width: 475px) {
  .v-card__actions {
    display: flex;
    flex-direction: column-reverse !important;
    text-align: center;
  }

  .v-card__actions .v-btn {
    display: block;
    margin: 0 0 10px 0 !important;
    width: 100%;
  }
}
</style>
