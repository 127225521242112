<template>
  <v-alert
    prominent
    type="warning"
  >
    <v-row align="center">
      <v-col class="grow darkgrey--text">
        Atenção! Você precisa validar suas credenciais para que possamos sincronizar suas contas com o Open Banking.
      </v-col>
      <v-col class="shrink">
        <v-btn
          rounded
          class="px-5 text-capitalize"
          to="/open-banking"
        >
          Validar credenciais
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'AlertOpenBankingCredentials'
}
</script>
