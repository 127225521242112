<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card color="grey2">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="error"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="error--text ml-4"> Remover item </span>
      </v-card-title>

      <v-card-text>
        <p class="dialog-form-description">Tem certeza que deseja remover este item?</p>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            color="error"
            @click="remove"
            :loading="loader"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import deleteItemOrcamento from "../../../../api/orcamento/deleteItemOrcamento";

  export default {
    name: 'DialogDeleteItemOrcamento',
    data: () => ({
      dialog: false,
      loader: false,
      itemId: null,
      orcamento: null
    }),
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.itemId = null;
        this.orcamento = null;
      },
      remove() {
        if (this.itemId && this.orcamento) {
          this.loader = true;

          deleteItemOrcamento(this.orcamento, this.itemId)
            .then(orcamento => {
              this.$store.dispatch('setOrcamento', orcamento);
              this.close();
              this.$noty.success('Item removido com sucesso!')
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível remover este item. Tente novamente!');
              if (process.env.NODE_ENV !== 'production') console.error(err);
            });
        }
      }
    },
    created() {
      this.$bus.$on('showDialogDeleteItemOrcamento', data => {
        if (data && data.orcamento && data.itemId) {
          this.orcamento = data.orcamento;
          this.itemId = data.itemId;
          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
  .dialog-form-title {
    background-color: #131313 !important;
  }
</style>
