<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card v-if="event">
      <v-card-title>
        <div class="title">Detalhes do Evento</div>
      </v-card-title>

      <v-card-text>
        <v-list>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ getEventName(event.type) }}</v-list-item-title>
              <v-list-item-subtitle>{{ event.date | moment('DD/MM/YYYY HH:mm:ss') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="event.author">
            <v-list-item-content>
              <v-list-item-title>{{ event.author.displayName }} - {{ getRoleName(event.author.role) }}</v-list-item-title>
              <v-list-item-subtitle>Executado por</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="event.type === 'planner-changed'">
            <v-list-item-content>
              <v-list-item-title>{{ event.oldPlanner ? event.oldPlanner.displayName : 'Não possuia' }}</v-list-item-title>
              <v-list-item-subtitle>Planejador anterior</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="event.type === 'planner-changed'">
            <v-list-item-content>
              <v-list-item-title>{{ event.newPlanner ? event.newPlanner.displayName : 'Não possui' }}</v-list-item-title>
              <v-list-item-subtitle>Planejador atual</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="event.type === 'company-changed'">
            <v-list-item-content>
              <v-list-item-title>{{ event.oldCompany ? event.oldCompany.displayName : 'Não possuia' }}</v-list-item-title>
              <v-list-item-subtitle>Empresa anterior</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="event.type === 'company-changed'">
            <v-list-item-content>
              <v-list-item-title>{{ event.newCompany ? event.newCompany.displayName : 'Não possui' }}</v-list-item-title>
              <v-list-item-subtitle>Empresa atual</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="event.type === 'email-changed'">
            <v-list-item-content>
              <v-list-item-title>{{ event.oldEmail }}</v-list-item-title>
              <v-list-item-subtitle>E-mail anterior</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="event.type === 'email-changed'">
            <v-list-item-content>
              <v-list-item-title>{{ event.newEmail }}</v-list-item-title>
              <v-list-item-subtitle>E-mail atual</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogUserEvent',
  data: () => ({
    dialog: false,
    event: null,
    eventTypes: [
      { text: 'Cadastro', value: 'signup' },
      { text: 'Login', value: 'signin' },
      { text: 'Habilitado', value: 'status-enabled' },
      { text: 'Desabilitado', value: 'status-disabled' },
      { text: 'Desabilitado', value: 'status-disabled' },
      { text: 'Troca de plano', value: 'plan-changed' },
      { text: 'Troca de senha', value: 'password-changed' },
      { text: 'Troca de email', value: 'email-changed' },
      { text: 'Troca de planejador', value: 'planner-changed' },
      { text: 'Troca de empresa', value: 'company-changed' },
      { text: 'Link de redefinição de senha', value: 'pass-reset-link' }
    ]
  }),
  methods: {
    getEventName(eventType) {
      return this.eventTypes.find(e => e.value === eventType).text;
    },
    getRoleName(role) {
      switch (role) {
        case 'admin':
          return 'Adm';
        case 'company':
          return 'Emp';
        default:
          return 'Plan';
      }
    }
  },
  created() {
    this.$bus.$on('showDialogUserEvent', data => {
      if (data) {
        this.event = data;
        this.dialog = true;
      }
    });
  }
};
</script>
