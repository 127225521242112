<template>
  <div class="mt-5">
    <v-data-table
      dense
      :items="videos"
      :loading="loader"
      :headers="headers"
      hide-default-footer
      :hide-default-header="isMobile"
    >
      <template v-slot:item="props">
        <tr>
          <td style="width: 90px" class="py-1 hidden-sm-and-down">
            <v-chip
              :small="!isMobile"
              color="primary"
              class="darkgrey--text"
            >{{ getTypeName(props.item.type) }}</v-chip>
          </td>

          <td style="width: 150px" class="py-1 hidden-xs-only">
            <v-chip
              :small="!isMobile"
              color="primary"
              class="darkgrey--text"
            >
              {{ getCategoryName(props.item.category) }}
            </v-chip>
          </td>

          <td>{{ props.item.name }}</td>

          <td style="width: 90px" class="py-1">
            <v-chip
              :small="!isMobile"
              color="grey1"
              @click.stop="showVideo(props.item)"
            >
              Detalhes
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="text-center mt-5">
      <v-pagination circle color="primary" v-model="pagination.page" :length="pagination.totalPages"></v-pagination>
    </div>
  </div>
</template>

<script>
import getVideos from '@/api/videos/getVideos';
import countVideos from '@/api/videos/countVideos';

export default {
  name: 'TableVideos',
  data: () => ({
    pagination: {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      itemPerPage: 10
    },
    headers: [
      {
        text: 'Tipo',
        value: 'type',
        align: 'left',
        sortable: true,
        class: 'hidden-sm-and-down'
      },
      {
        text: 'Categoria',
        value: 'category',
        align: 'left',
        sortable: true,
        class: 'hidden-xs-only'
      },
      {
        text: 'Título',
        value: 'name',
        align: 'left',
        sortable: true,
        class: ''
      },
      {
        text: 'Opções',
        value: '',
        align: 'center',
        sortable: false,
        class: ''
      }
    ],
    filter: {
      search: '',
      type: '',
      category: '',
      active: ''
    },
    loader: false,
    videos: []
  }),
  methods: {
    getVideos(newSearch) {
      this.loader = true;

      if (newSearch) this.pagination.page = 1;

      Promise.all([
        countVideos(
          this.filter.type,
          this.filter.category,
          this.filter.active,
          this.filter.search
        ),
        getVideos(
          this.pagination.page,
          this.pagination.itemPerPage,
          this.filter.type,
          this.filter.category,
          this.filter.active,
          this.filter.search
        )
      ])
        .then(results => {
          this.pagination.totalItems = results[0].count;
          this.pagination.totalPages = Math.ceil(
            this.pagination.totalItems / this.pagination.itemPerPage
          );
          this.videos = results[1];
          this.loader = false;
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
        });
    },
    getCategoryName(category) {
      switch (category) {
        case 'aposentadoria':
          return 'Aposentadoria';
        case 'fluxo-caixa':
          return 'Fluxo de caixa';
        case 'investimentos':
          return 'Investimentos';
        case 'liquidez':
          return 'Líquidez';
        case 'gestao-riscos':
          return 'Gestão de Riscos';
        case 'gestao-tributaria':
          return 'Gestão Tributária';
        case 'planos-acao':
          return 'Planos de Ação';
        default:
          return 'Outro';
      }
    },
    getTypeName(type) {
      switch (type) {
        case 'customer':
          return 'Cliente';
        case 'company':
          return 'Empresa';
        case 'planner':
          return 'Planejador';
        default:
          return 'Todos';
      }
    },
    showVideo(video) {
      this.$bus.$emit('showDialogVideoDetails', video);
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  mounted() {
    this.getVideos(false);
  },
  created() {
    this.$bus.$on('getVideos', filter => {
      if (filter) this.filter = filter;
      this.getVideos(!!filter);
    });
  }
};
</script>
