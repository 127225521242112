<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="768px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''">
      <v-card-title class="dialog-form-title px-2" :class="isIOS ? ['ios-padding', 'fixed-title'] : ''">
        <v-btn
          icon
          class="mr-5"
          color="primary"
          @click.stop="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text">{{ item._id ? 'Editar' : 'Adicionar' }} Seguro</span>
      </v-card-title>

      <v-card-text class="px-2" :class="isMobile ? 'mobile-margin' : ''">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <span class="input-label primary--text">Tipo</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="item.tipo"
                  :items="tipos"
                  :error-messages="tipoErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12 md6 v-if="item.tipo === 'Outro'">
                <span class="input-label primary--text">Nome do tipo</span>
                <v-text-field
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="otherType"
                  :error-messages="otherTypeErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Operadora</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="item.operadora"
                  :error-messages="operadoraErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Valor pago</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-money="money"
                  v-model="item.valorPago"
                  :error-messages="valorPagoErrors"
                  id="seguro-valor-pago"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Repetir</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="item.repetir"
                  :items="repeticoes"
                  :error-messages="repetirErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Nível de satisfação</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="item.nivelSatisfacao"
                  :items="niveisSatisfacao"
                  :error-messages="nivelSatisfacaoErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Data de vencimento</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="item.dataVencimento"
                  :error-messages="dataVencimentoErrors"
                  v-mask="'##/##/####'"
                  placeholder="dd/mm/aaaa"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Observações</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="item.obs"
                  :error-messages="obsErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="pa-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    rounded
                    type="submit"
                    color="primary"
                    :loading="loader"
                    class="px-10 darkgrey--text"
                  >
                    Salvar
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money'
import { capitalize } from 'lodash'
import removeMoneyMask from '@/utils/removeMoneyMask';
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'DialogFormSeguroVidaPerfil',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    item: {
      tipo: '',
      operadora: '',
      valorPago: '',
      repetir: '',
      nivelSatisfacao: '',
      obs: '',
      dataVencimento: ''
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    otherType: '',
    repeticoes: ['mensal', 'anual'],
    tipos: [
      'Plano de saúde',
      'Seguro de automóvel',
      'Seguro de Vida',
      'Outro'
    ],
    niveisSatisfacao: [
      'Muito satisfeito',
      'Satisfeito',
      'Nada satisfeito'
    ]
  }),
  validations: {
    otherType: {
      isValid(v) {
        return this.item.tipo !== 'Outro' || (!!v && v.length <= 150)
      }
    },
    item: {
      tipo: {
        isValid: v => !!v && v.length <= 150
      },
      operadora: {
        isValid: v => !!v && v.length <= 150
      },
      valorPago: {
        isValid: v => !!v && removeMoneyMask(v) >= 0
      },
      repetir: {
        isValid(v) {
          return !!v && this.repeticoes.includes(v)
        }
      },
      nivelSatisfacao: {
        isValid(v) {
          return !!v && this.niveisSatisfacao.includes(v)
        }
      },
      obs: {
        isValid: v => !v || v.length <= 150
      },
      dataVencimento: {
        isValid(v) {
          return !v || /\d{2}\/\d{2}\/\d{4}/g.test(v)
        }
      }
    }
  },
  computed: {
    // error messages
    otherTypeErrors() {
      if (!this.$v.otherType.$anyDirty) {
        return []
      }

      if (this.$v.otherType.$anyError) {
        return ['Preencha um tipo válido']
      }
    },
    tipoErrors() {
      if (!this.$v.item.tipo.$anyDirty) {
        return []
      }

      if (this.$v.item.tipo.$anyError) {
        return ['Selecione um tipo válido']
      }
    },
    operadoraErrors() {
      if (!this.$v.item.operadora.$anyDirty) {
        return []
      }

      if (this.$v.item.operadora.$anyError) {
        return ['Preencha uma operadora válida']
      }
    },
    valorPagoErrors() {
      if (!this.$v.item.valorPago.$anyDirty) {
        return []
      }

      if (this.$v.item.valorPago.$anyError) {
        return ['Preencha um valor válido']
      }
    },
    repetirErrors() {
      if (!this.$v.item.repetir.$anyDirty) {
        return []
      }

      if (this.$v.item.repetir.$anyError) {
        return ['Selecione um intervalo válido']
      }
    },
    nivelSatisfacaoErrors() {
      if (!this.$v.item.nivelSatisfacao.$anyDirty) {
        return []
      }

      if (this.$v.item.nivelSatisfacao.$anyError) {
        return ['Selecione um nível válido']
      }
    },
    obsErrors() {
      if (!this.$v.item.obs.$anyDirty) {
        return []
      }

      if (this.$v.item.obs.$anyError) {
        return ['Preencha uma observação válida']
      }
    },
    dataVencimentoErrors() {
      if (!this.$v.item.dataVencimento.$anyDirty) {
        return []
      }

      if (this.$v.item.dataVencimento.$anyError) {
        return ['Preencha uma data válida']
      }
    },
    // helpers
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    perfil() {
      return this.$store.getters.perfil
    }
  },
  methods: {
    openDialog(item) {
      if (item) {
        this.item = JSON.parse(JSON.stringify(item))

        this.item.valorPago = this.item.valorPago.toFixed(2)
        this.item.dataVencimento = this.$date(this.item.dataVencimento).format('DD/MM/YYYY')

        this.dispatchInputs()
      }

      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.otherType = ''
      this.item = {
        tipo: '',
        operadora: '',
        valorPago: '',
        repetir: '',
        nivelSatisfacao: '',
        obs: '',
        dataVencimento: ''
      }
      this.dispatchInputs()
      this.$v.$reset()
    },
    dispatchInputs() {
      const input = document.getElementById('seguro-valor-pago')
      if (input) {
        input.value = this.item.valorPago
        input.dispatchEvent(new Event('input'))
      }
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          const item = JSON.parse(JSON.stringify(this.item))
          let perfil = JSON.parse(JSON.stringify(this.perfil))

          item.obs = capitalize(item.obs.trim())
          item.valorPago = removeMoneyMask(item.valorPago)
          item.operadora = capitalize(item.operadora.trim())

          if (item.tipo === 'Outro') {
            item.tipo = this.otherType.trim()
          }

          if (item.dataVencimento) {
            item.dataVencimento = item.dataVencimento.split('/').reverse().join('-')
          }

          if (!item._id) {
            perfil.protecaoFamiliar.seguros.push(item)
          } else {
            let index = perfil.protecaoFamiliar.seguros.findIndex(it => it._id === item._id)
            if (index > -1) {
              perfil.protecaoFamiliar.seguros.splice(index, 1, item)
            }
          }

          perfil = await updatePerfil(perfil._id, { protecaoFamiliar: perfil.protecaoFamiliar })
          await this.$store.dispatch('setPerfil', perfil)

          this.closeDialog()
        }
      } catch (err) {
        this.$noty.error('Desculpe! Não conseguimos salvar estes dados. Tente novamente mais tarde.')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.loader = false
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormSeguroVidaPerfil', this.openDialog)
  }
}
</script>

<style scoped>
.v-card__title.dialog-form-title.fixed-title {
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 10;
}

.v-card__text.mobile-margin {
  padding-top: 90px !important;
}
</style>
