<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="550px"
  >
    <v-card color="grey2" :class="isMobile ? 'no-radius' : ''">
      <v-card-title class="px-0 pt-0">
        <v-btn
          icon
          large
          color="grey1"
          class="ml-auto"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="fator" v-for="(fator, i) in fatores" :key="i">
          <h1 class="fator-title white--text">{{ fator.title }}</h1>
          <p class="fator-text grey1--text">{{ fator.text }}</p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DialogFatoresPerfil',
    data: () => ({
      dialog: false,
      fatores: [
        {
          title: 'Cultura',
          text: 'Obrigado pela forma como seus pais ou sua família lidavam com dinheiro. Em caso de conhecer bastante, família já era investidora ou poupadora.'
        },
        {
          title: 'Contextual',
          text: 'Devido a sua experiência profissional e acontecimentos da vida. Em caso de conhecer bastante, influência de amigos, colegas de trabalho, etc.'
        },
        {
          title: 'Individual',
          text: 'Levado pelo comodismo e pela ciência que você precisava verificar este tema mas postergou até este o preenchimento. Em caso de conhecer bastante, curiosidade e interesse pelo tema investimento e finanças'
        },
      ]
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      }
    },
    created() {
      this.$bus.$on('showDialogFatoresPerfil', () => {
        this.dialog = true;
      });
    }
  }
</script>

<style scoped>
  .fator-title, .fator-text {
    font-size: 9px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  @media only screen and (min-width: 600px) {
    .v-card__text {
      padding: 20px 60px 40px 60px !important;
    }

    .fator-title, .fator-text {
      font-size: 12px;
    }
  }
</style>
