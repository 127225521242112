const moment = require('moment-timezone');

const getRendaAtual = (data, rendas) => {
  if (rendas.length) {
    data = moment(data);

    let year = data.year();
    let month = data.month();

    let renda = rendas.find((renda, i) => {
      let dataRenda = moment(renda.data);
      let rendaYear = dataRenda.year();
      let rendaMonth = dataRenda.month();

      if (rendaYear < year || (rendaYear === year && rendaMonth <= month)) {
        if (rendas[i+1]) {
          let next = rendas[i+1];

          let nextData = moment(next.data);
          let nextYear = nextData.year();
          let nextMonth = nextData.month();

          return (nextYear > year || (nextYear === year && nextMonth > month));
        } else {
          return true;
        }
      }
    });

    if (renda)
      return renda.valor;
  }

  return 0;
}

export default getRendaAtual;
