<template>
  <v-navigation-drawer
    v-model="show"
    fixed
    color="grey4"
    class="customer-navigation-drawer safe-area-top pb-5"
    @transitionend="toggleBottomMenu"
    @update:mini-variant="hideMenus"
    :dark="!mobileScreen"
    :temporary="mobileScreen"
    :permanent="!mobileScreen"
    :class="mobileScreen ? 'pb-10' : ''"
    :width="mobileScreen ? width + 'px' : '290px'"
    :expand-on-hover="!mobileScreen"
  >
    <v-list dense class="pb-10">
      <v-list-item two-line class="mb-1 px-2" :to="user.isFree ? '' : '/perfil'">
        <v-list-item-avatar>
          <v-img :src="this.user.photoURL"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="profile-name">
            {{ this.user.displayName }}
          </v-list-item-title>

          <v-list-item-subtitle class="primary--text" v-if="!user.isFree">
            Ver perfil
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="grey1">mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>

      <div class="px-2 mb-1">
        <v-divider></v-divider>
      </div>

      <v-list-item
        v-for="item in modulesItemsFiltered"
        :to="item.route"
        :key="item.text"
        class="pl-2"
      >
        <v-list-item-avatar>
          <i class="lni primary--text" :class="item.icon"></i>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> {{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

<!--      <v-list-item-->
<!--        class="pl-2"-->
<!--        to="/recommendation"-->
<!--        v-if="user && user.role === 'customer' && !user.company && (!user.planner || user.planner.isUplanner)"-->
<!--      >-->
<!--        <v-list-item-avatar>-->
<!--          <i class="lni lni-heart primary&#45;&#45;text"></i>-->
<!--        </v-list-item-avatar>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>Indicações</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->

      <div class="px-2 mb-1">
        <v-divider></v-divider>
      </div>

      <v-list-item class="pl-2" @click.stop="toggleCadastrosMenu">
        <v-list-item-avatar>
          <i class="lni primary--text lni-package"></i>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Cadastrar</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="grey1">{{
            cadastrosMenu ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }}</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-expand-transition>
        <div v-if="cadastrosMenu" class="grey3">
          <v-list-item class="pl-2" @click="showDialogMobileBankAccountsList">
            <v-list-item-avatar> </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Contas bancárias</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pl-2" @click="showDialogCreditCardsList">
            <v-list-item-avatar> </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Cartões de crédito</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pl-2" @click="showDialogCategories">
            <v-list-item-avatar> </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Categorias</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-expand-transition>

      <div class="px-2 my-1">
        <v-divider></v-divider>
      </div>

      <v-list-item class="pl-2" @click.stop="toggleConfigsMenu">
        <v-list-item-avatar>
          <i class="lni primary--text lni-cog"></i>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Configurações</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="grey1">
            {{ configsMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-expand-transition>
        <div v-if="configsMenu" class="grey3">
          <v-list-item class="pl-2" to="/cadastro">
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Meu cadastro</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pl-2" to="/meios-pagamento" v-if="!user.isFree">
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Meios de pagamento</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pl-2" @click="showDialogCustomerSubscription" v-if="!user.isFree">
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Minha assinatura</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pl-2" @click="showDialogFormEmail">
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Redefinir e-mail</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pl-2" @click="ShowFormSenha">
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Redefinir senha</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="user && user.isFree" class="pl-2" @click="removeUser">
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Remover minha conta</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-expand-transition>

      <v-list-item class="pl-2" @click.stop="toggleAboutMenu">
        <v-list-item-avatar>
          <i class="lni primary--text lni-more"></i>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Sobre</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="grey1">{{
            aboutMenu ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }}</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-expand-transition>
        <div v-if="aboutMenu" class="grey3">
          <v-list-item class="pl-2" to="/politica-privacidade">
            <v-list-item-avatar> </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Política de privacidade</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pl-2" to="termos-uso">
            <v-list-item-avatar> </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Termo de adesão</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pl-2">
            <v-list-item-avatar> </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ appVersion }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-expand-transition>

      <div class="px-2 my-1">
        <v-divider></v-divider>
      </div>

      <v-list-item class="pl-2" @click="sair">
        <v-list-item-avatar>
          <i class="lni primary--text lni-power-switch"></i>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import logout from '../../../utils/logout';
import config from '@/config';

export default {
  name: 'navigation-drawer',
  data: () => ({
    show: false,
    width: 320,
    transactionsMenu: false,
    configsMenu: false,
    aboutMenu: false,
    cadastrosMenu: false,
    appVersion: config.appVersion,
    modulesItems: [
      {
        text: 'Início',
        icon: 'lni-home',
        route: '/cashflow',
      },
      {
        text: 'Extrato',
        icon: 'lni-list',
        route: '/transactions',
      },
      {
        text: 'Open Banking',
        icon: 'lni-cloud-sync',
        route: '/open-banking',
      },
      {
        text: 'Orçamento',
        icon: 'lni-spiner-solid',
        route: '/orcamento',
      },
      {
        text: 'Relatórios',
        icon: 'lni-bar-chart',
        route: '/relatorios',
      },
      {
        text: 'Independência',
        icon: 'lni-island',
        route: '/ind-financeira',
      },
      {
        text: 'Objetivos',
        icon: 'lni-target',
        route: '/objetivo',
      },
      {
        text: 'Investimentos',
        icon: 'lni-stats-up',
        route: '/investimentos',
      },
      {
        text: 'Seguro de vida',
        icon: 'lni-money-protection',
        route: '/seguro-vida',
      },
      {
        text: 'Planos de ação',
        icon: 'lni-checkbox',
        route: '/planos-acao',
      }
    ],
  }),
  methods: {
    removeUser() {
      if (this.user && this.user.isFree) {
        this.$bus.$emit('showDialogDeleteFreeUser')
      }
    },
    showDialogCustomerSubscription() {
      this.$bus.$emit('showDialogCustomerSubscription')
    },
    toggleTransactionsMenu() {
      this.transactionsMenu = !this.transactionsMenu;
    },
    toggleConfigsMenu() {
      this.configsMenu = !this.configsMenu;
    },
    toggleAboutMenu() {
      this.aboutMenu = !this.aboutMenu;
    },
    toggleCadastrosMenu() {
      this.cadastrosMenu = !this.cadastrosMenu;
    },
    hideMenus() {
      this.transactionsMenu = false;
      this.configsMenu = false;
      this.aboutMenu = false;
      this.cadastrosMenu = false;
    },
    toggleBottomMenu() {
      this.$bus.$emit('toggle-bottom-menu', this.show);
    },
    showDialogMobileBankAccountsList() {
      this.$bus.$emit('showDialogMobileBankAccountsList');
    },
    showDialogCreditCardsList() {
      this.$bus.$emit('showDialogCreditCardsList');
    },
    showDialogCategories() {
      this.$bus.$emit('showDialogCategories');
    },
    goToHelp() {
      let url;

      if (this.isMobile)
        url =
          'https://uplanner.zendesk.com/hc/pt-br/categories/360003418172-Web';
      else
        url =
          'https://uplanner.zendesk.com/hc/pt-br/categories/360003418172-Web';

      window.open(url, '_blank');
    },
    showDialogFormEmail() {
      this.$bus.$emit('showFormEmail');
    },
    ShowFormSenha() {
      this.$bus.$emit('ShowFormSenha');
    },
    sair() {
      logout();
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    mobileScreen() {
      return this.width < 960;
    },
    modulesItemsFiltered() {
      const itensAuthorized = []

      if (this.user && this.user.permissions) {
        const permissionsArray = Object.entries(this.user.permissions)

        permissionsArray.forEach(key => {
          if (key[1]){

            if(key[0] === 'indFinanceira') {
              itensAuthorized.push('ind-financeira')
            } else if(key[0] === 'lifeInsurance') {
              itensAuthorized.push('seguro-vida')
            } else if(key[0] === 'planosAcao') {
              itensAuthorized.push('planos-acao')
            } else if (key[0] === 'openBanking') {
              itensAuthorized.push('open-banking')
            } else {
              itensAuthorized.push(key[0])
            }
          }
        })
      }

      const itens = this.modulesItems.filter(o => {
        return !itensAuthorized.length || itensAuthorized.includes(o.route.substring(1, o.route.length))
      })

      return itens
    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.hideMenus();
      }
    },
  },
  created() {
    this.$bus.$on('toggle-navigation-drawer', (show) => {
      this.width = window.innerWidth;
      this.show = show;
    });
    this.width = window.innerWidth;

    let self = this;
    window.addEventListener('resize', function () {
      self.width = window.innerWidth;
    });
  },
};
</script>

<style scoped>
.lni {
  font-size: 20px;
}

.profile-name {
  font-size: 20px !important;
  font-weight: bold;
  line-height: 1.5em;
}

.menu-list {
  padding-left: 10px;
  padding-right: 10px;
}

.menu-item {
  width: 100%;
  display: flex;
  min-height: 48px;
  border-radius: 5px;
  align-items: center;
  padding-left: 12px;
  padding-right: 10px;
  border-bottom: 1px solid #131313;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-item span {
  font-weight: 500;
  letter-spacing: 0.03em;
  font-size: 14px;
}

.v-list-item__avatar,
.v-list-item__avatar.v-list-item__avatar--horizontal {
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>
