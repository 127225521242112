<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card color="grey2">
      <v-card-title
        class="dialog-form-title"
        style="border-radius: 0 !important"
      >
        <v-btn
          icon
          color="white"
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        Redefinir e-mail
      </v-card-title>

      <v-card-text class="px-1">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12>
              <h3 class="primary--text input-label">E-mail atual</h3>
              <v-text-field
                dense
                readonly
                color="grey1"
                type="email"
                :value="current"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <h3 class="primary--text input-label">Novo e-mail</h3>
              <v-text-field
                dense
                type="email"
                color="grey1"
                v-model="email"
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            rounded
            @click="submit"
            color="primary"
            :loading="loader"
            class="px-5 darkgrey--text"
          >
            Salvar
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

import checkEmailAlreadyUsed from '../../../../api/users/checkEmailAlreadyUsed';
import updateUserEmail from '../../../../api/users/updateUserEmail';
import logout from '../../../../utils/logout';

export default {
  name: 'FormEmail',
  data: () => ({
    dialog: false,
    loader: false,
    current: '',
    email: '',
    emailUsed: false,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push('Insira seu novo email');
      !this.$v.email.email && errors.push('Insira um e-mail válido');
      this.emailUsed && errors.push('E-mail já cadastrado');
      return errors;
    },
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
  methods: {
    checkEmail() {
      checkEmailAlreadyUsed(this.email.toLowerCase(), this.user._id)
        .then((resp) => {
          this.emailUsed = resp.exists;
        })
        .catch((err) => {
          this.emailUsed = true;
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    },
    submit() {
      this.$v.$touch()

      if (!this.$v.$anyError) {
        this.loader = true;

        updateUserEmail(this.current, this.email.toLowerCase())
          .then(() => {
            logout();
          })
          .catch((err) => {
            this.loader = false;
            this.$noty.error(
              'Desculpe! Não foi possível alterar seu e-mail. Tente novamente!'
            );
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    close() {
      this.dialog = false;
      this.loader = false;
      this.email = '';
      this.emailUsed = false;
      this.$v.$reset();
    },
  },
  validations: {
    email: { required, email },
  },
  watch: {
    email() {
      if (!this.$v.$anyError) this.checkEmail();
    },
  },
  created() {
    this.$bus.$on('showFormEmail', () => {
      this.current = this.user.email;
      this.dialog = true;
    });
  },
};
</script>

<style scoped>
.form-title {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.title-condensed {
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  letter-spacing: 0.1em;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container .input-label {
  font-size: 12px;
  color: white !important;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .form-title {
    font-size: 15px;
  }

  .checkbox-container .input-label {
    font-size: 12px;
    color: white !important;
  }
}

@media only screen and (min-width: 960px) {
  .form-title {
    font-size: 20px;
  }

  .checkbox-container .input-label {
    font-size: 16px;
  }
}
</style>
