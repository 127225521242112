import firebase from 'firebase/app';
import httpClient from '../../plugins/httpClient';

const exportCsv = (data, type, category, subCategory) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'POST'
        let headers = {
          Authorization: `Bearer ${idToken}`
        };
        let url
        if(type === 'extrato') {
           url = `/files/csv-extrato`;
        } else if(type === 'invoice') {
           url = `/files/csv-invoice`;
        }

        return httpClient(url, { method, headers, data});
      })
      .then(resp => {
         if (resp.data.empty) {
          resolve(resp.data)
         } else {
           const url = window.URL.createObjectURL(new Blob(["\ufeff" + resp.data], {type: 'application/vnd.ms-excel;charset=utf-8'}))
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', 'Extrato - UPlanner 2.0' + '.csv');
           document.body.appendChild(link);
           link.click();
           resolve()
         }
      })
       .catch(err =>reject(err));
  });
};

export default exportCsv;
