<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>Alterar plano</v-card-title>
      <v-card-subtitle>Alterar o plano pode gerar novas cobranças ao usuário</v-card-subtitle>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-text-field readonly color="accent" label="Plano atual" :value="currentPlan.name"></v-text-field>

          <v-select
            color="accent"
            item-text="name"
            item-value="_id"
            v-model="newPlan"
            label="Novo plano"
            :items="plansByType"
            @change="$v.newPlan.$touch()"
            :error-messages="newPlanErrors"
          ></v-select>

          <v-card-actions class="pa-0 mt-5">
            <v-btn
              text
              small
              rounded
              depressed
              class="px-5"
              type="button"
              color="accent"
              :disabled="loader"
              @click.stop="close"
            >Cancelar</v-btn>

            <v-spacer></v-spacer>

            <v-btn
              small
              rounded
              depressed
              class="px-5"
              type="submit"
              color="success"
              :loading="loader"
            >Alterar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import updatePlan from '@/api/users/updatePlan';
import getPlansNames from '@/api/plans/getPlansNames';

export default {
  name: 'DialogUpdateUserPlan',
  data: () => ({
    dialog: false,
    loader: false,
    userId: '',
    userType: '',
    currentPlan: '',
    newPlan: '',
    plans: []
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    user() {
      return this.$store.getters.user;
    },
    plansByType() {
      return this.plans.filter(
        plan => plan._id !== this.currentPlan._id && plan.type === this.userType
      );
    },
    newPlanErrors() {
      const errors = [];
      if (!this.$v.newPlan.$dirty) return errors;
      !this.$v.newPlan.required && errors.push('Selecione o plano desejado');
      return errors;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.userId = '';
      this.currentPlan = '';
      this.newPlan = '';
      this.$v.$reset();
    },
    show(data) {
      this.userId = data.userId;
      this.userType = data.userType;
      this.currentPlan = data.currentPlan;
      this.dialog = true;
    },
    getPlans() {
      getPlansNames()
        .then(plans => (this.plans = plans))
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        let data = {
          userId: this.userId,
          newPlan: this.newPlan
        };

        updatePlan(data.userId, data)
          .then(() => {
            this.$noty.success('Plano alterado com sucesso!');
            this.close();
          })
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);
            this.loader = false;
            this.$noty.error(
              'Falha ao atualizar plano! Tente novamente mais tarde.'
            );
          });
      }
    }
  },
  validations: {
    newPlan: { required }
  },
  created() {
    this.$bus.$on('showDialogUpdateUserPlan', data => {
      this.show(data);
    });
  }
};
</script>

<style scoped>
.v-card.no-radius {
  border-radius: 0 !important;
}

.title {
  display: flex;
  align-items: center;
}

.card-title {
  padding: 10px;
}
</style>
