import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const removeLifeInsuranceDependent = (insuranceId, dependentId) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }
          const url = `/life-insurances/${insuranceId}/dependents/${dependentId}`;

          return httpClient(url, options);
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default removeLifeInsuranceDependent;
