<template>
  <v-alert color="warning" outlined type="info">
    <v-row>
      <v-col class="grow">
        Precisamos de alguns dados a mais para concluirmos a sincronização de seus cartões de crédito.
      </v-col>

      <v-col class="shrink">
        <v-btn
          rounded
          color="warning"
          @click="showDialogPendentCreditCards"
          class="text-capitalize darkgrey--text"
        >
          Resolver
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'AlertPendentCreditCards',
  methods: {
    showDialogPendentCreditCards() {
      this.$bus.$emit('showDialogPendentCreditCards')
    }
  }
}
</script>
