import httpClient from '@/plugins/httpClient';

const validateUser = (email, code) => {
  return new Promise((resolve, reject) => {
    try {
      httpClient(`/users/validate-user?email=${email}&code=${code}`)
        .then(resp => resolve(resp.data))
        .catch(reject)
    } catch(err) {
      reject(err)
    }
  })
}

export default validateUser
