const icons = [
  'mdi-food-fork-drink',
  'mdi-train-car',
  'mdi-car',
  'mdi-tshirt-crew',
  'mdi-barcode-scan',
  'mdi-beach',
  'mdi-heart-pulse',
  'mdi-home',
  'mdi-cash',
  'mdi-gift',
  'mdi-chart-line-variant',
  'mdi-dots-horizontal',
  'mdi-airplane',
  'mdi-baby-face',
  'mdi-basket',
  'mdi-bike',
  'mdi-bone',
  'mdi-book-variant',
  'mdi-bag-carry-on',
  'mdi-broom',
  'mdi-bus',
  'mdi-cake-variant',
  'mdi-calculator',
  'mdi-bag-personal',
  'mdi-calendar-minus',
  'mdi-video',
  'mdi-camera',
  'mdi-candycane',
  'mdi-cart',
  'mdi-dog',
  'mdi-cellphone',
  'mdi-chart-line',
  'mdi-church',
  'mdi-factory',
  'mdi-bank',
  'mdi-account-group',
  'mdi-map-marker-outline',
  'mdi-card-bulleted',
  'mdi-cards',
  'mdi-finance',
  'mdi-marker',
  'mdi-map-outline',
  'mdi-fish',
  'mdi-cash-usd',
  'mdi-shield-lock',
  'mdi-flask-empty',
  'mdi-shoe-formal',
  'mdi-shoe-heel',
  'mdi-shovel',
  'mdi-shopping',
  'mdi-charity',
  'mdi-airballoon',
  'mdi-sign-caution',
  'mdi-focus-field',
  'mdi-chat',
  'mdi-silverware',
  'mdi-chef-hat',
  'mdi-mixed-martial-arts',
  'mdi-skull',
  'mdi-sleep',
  'mdi-food',
  'mdi-football',
  'mdi-snowflake',
  'mdi-mother-nurse',
  'mdi-soccer',
  'mdi-motorbike',
  'mdi-sofa',
  'mdi-movie',
  'mdi-muffin',
  'mdi-music',
  'mdi-city',
  'mdi-spa',
  'mdi-format-paint',
  'mdi-spray',
  'mdi-nature',
  'mdi-netflix',
  'mdi-sprout',
  'mdi-amazon',
  'mdi-ambulance',
  'mdi-amplifier',
  'mdi-stadium',
  'mdi-newspaper',
  'mdi-fridge',
  'mdi-fuel',
  'mdi-archive',
  'mdi-gamepad-square',
  'mdi-subway',
  'mdi-coffin',
  'mdi-glass-mug',
  'mdi-glass-flute',
  'mdi-nutrition',
  'mdi-tablet',
  'mdi-taxi',
  'mdi-credit-card',
  'mdi-tennis',
  'mdi-terrain',
  'mdi-baby-bottle',
  'mdi-hammer',
  'mdi-hanger',
  'mdi-paw',
  'mdi-barcode',
  'mdi-tie',
  'mdi-headphones',
  'mdi-basketball',
  'mdi-delete',
  'mdi-tooth',
  'mdi-hook',
  'mdi-truck',
  'mdi-billiards',
  'mdi-ice-cream',
  'mdi-doctor',
  'mdi-dumbbell',
  'mdi-earth',
  'mdi-bottle-wine'
];

export default icons;
