import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const updateUserIuguSubscription = (userId, subscriptionId) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const options = {
          method: 'POST',
          data: { subscription: subscriptionId },
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        return httpClient(`/users/${userId}/subscription`, options)
      })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}

export default updateUserIuguSubscription
