<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    :fullscreen="isMobile"
    :max-width="dialogWidth"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey2">
      <v-card-title class="dialog-form-title hidden-md-and-up" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          :disabled="loader"
          class="back-button"
          @click.stop="close"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="white--text ml-4">Independência financeira</span>
      </v-card-title>

      <v-card-text class="pb-0" v-if="premissas">
        <v-container grid-list-md class="px-0">
          <v-layout row wrap>
            <v-flex xs12 class="hidden-sm-and-down mb-5">
              <v-card-title class="primary--text profile-title py-0 px-0">Independência financeira</v-card-title>
            </v-flex>

            <v-flex xs12 sm12 md6 lg4>
              <h3 class="primary--text input-label">Idade em que deseja alcançar</h3>
              <v-text-field
                dense
                type="text"
                color="grey1"
                persistent-hint
                :hint="user.displayName"
                v-model="premissas.idadeIndependencia"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md6 lg4 v-if="perfil.informacoesBasicas.conjuge && perfil.informacoesBasicas.conjuge.nome">
              <h3 class="primary--text input-label">Idade em que o(a) cônjuge deseja alcançar</h3>
              <v-text-field
                dense
                type="text"
                color="grey1"
                persistent-hint
                v-model="premissas.idadeIndependenciaConjuge"
                :hint="perfil.informacoesBasicas.conjuge.nome"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md6 lg4>
              <h3 class="primary--text input-label">Renda desejada</h3>
              <v-text-field
                dense
                type="text"
                color="grey1"
                v-money="money"
                v-model="premissas.rendaDesejada"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-card-actions class="px-0 mt-5">
                <v-spacer class="hidden-sm-and-down"></v-spacer>

                <v-btn
                  rounded
                  outlined
                  class="px-5"
                  @click="close"
                  color="primary"
                  :disabled="loader"
                >
                  Cancelar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  @click="submit"
                  color="primary"
                  :loading="loader"
                  class="darkgrey--text px-5"
                >
                  Salvar
                </v-btn>

                <v-spacer class="hidden-sm-and-down"></v-spacer>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {VMoney} from 'v-money'
  import removeMoneyMask from "../../../../utils/removeMoneyMask";
  import updateIndFinanceira from "../../../../api/indFinanceira/updateIndFinanceira";

  export default {
    name: 'DialogEditIndFinanceira',
    directives: { money: VMoney },
    data: () => ({
      dialog: false,
      loader: false,
      dialogWidth: '600px',
      premissas: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      }
    }),
    computed: {
      isMobile() {
        return this.$store.getters.isMobile;
      },
      isIOS() {
        return this.$store.getters.isIOS;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      perfil() {
        return this.$store.getters.perfil;
      },
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === `customer`;
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
    },
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.premissas = null;
        this.dialogWidth = '600px';
      },
      submit() {
        this.loader = true;

        let premissas = JSON.parse(JSON.stringify(this.premissas));

        premissas.rendaDesejada = removeMoneyMask(premissas.rendaDesejada);

        updateIndFinanceira(this.indFinanceira._id, { premissas, completed: true })
          .then(indFinanceira => {
            this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
            this.close();
            this.$noty.success('Dados salvos com sucesso!')
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possivel completar seu perfil. Tente novamente!');
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      },
      setDialogWidth() {
        let innerWidth = window.innerWidth;

        if (innerWidth >= 960 && innerWidth <= 1263) {
          this.dialogWidth = '788px';
        } else if (innerWidth >= 1264) {
          this.dialogWidth = '1020px';
        }
      }
    },
    created() {
      this.$bus.$on('showDialogEditIndFinanceira', () => {
        this.setDialogWidth();

        if (this.indFinanceira) {
          this.premissas = JSON.parse(JSON.stringify(this.indFinanceira.premissas));
          this.premissas.rendaDesejada = parseFloat(this.premissas.rendaDesejada).toFixed(2);
        } else {
          this.premissas = {
            idadeIndependencia: '',
            idadeIndependenciaConjuge: '',
            rendaDesejada: 0
          }
        }

        this.dialog = true;
      });
    }
  }
</script>

<style scoped>
  .input-label {
    font-size: 9px;
    line-height: 9px;
    letter-spacing: .1em;
  }

  .title-condensed {
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    letter-spacing: .1em;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
  }

  .checkbox-container .input-label {
    font-size: 12px;
    color: white !important;
    cursor: pointer;
  }

  @media only screen and (min-width: 600px) {
    .input-label {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: .1em;
      font-weight: 400;
    }

    .checkbox-container .input-label {
      font-size: 12px;
      color: white !important;
    }
  }

  @media only screen and (min-width: 960px) {
    .container {
      max-width: 788px;
    }

    .checkbox-container .input-label {
      font-size: 16px;
    }

    .v-card__title.profile-title {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: .1em;
      text-transform: uppercase;
    }
  }

  @media only screen and (min-width: 1264px) {
    .container {
      max-width: 1020px;
    }
  }
</style>
