import firebase from 'firebase/app';
import httpClient from "../../plugins/httpClient";

const saveRendaIndFinanceira = (indFinanceiraId, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let headers = { Authorization: `Bearer ${idToken}` };
          let url = `/independencia-financeira/${indFinanceiraId}/rendas`;
          let method = 'POST';

          if (data._id) {
            method = 'PUT';
            url += `/${data._id}`;
          }

          return httpClient(url, { method, headers, data });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default saveRendaIndFinanceira;
