<template>
  <div
    style="z-index: 10"
    class="bottom-nav elevation-2 safe-area-bottom"
  >
    <router-link
      v-ripple
      class="bottom-nav-btn"
      :to="routes[0]"
    >
      <i
        class="lni lni-home bottom-nav-btn-icon"
        :class="currentRoute === routes[0] && !menu ? 'primary--text' : 'grey1--text'"
      ></i>

      <span
        class="bottom-nav-btn-text primary--text"
        :class="currentRoute === routes[0] && !menu ? 'primary--text' : 'grey1--text'"
      >
        Início
      </span>
    </router-link>

    <div v-ripple class="bottom-nav-btn" @click="menu = !menu">
      <i
        class="lni lni-menu bottom-nav-btn-icon"
        :class="menu ? 'primary--text' : 'grey1--text'"
      ></i>
      <span
        class="bottom-nav-btn-text"
        :class="menu ? 'primary--text' : 'grey1--text'"
      >
        Menu
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyMobileMenu',
  data: () => ({
    menu: false,
    routes: [
      '/company'
    ]
  }),
  computed: {
    currentRoute() {
      return this.$route.path
    }
  },
  watch: {
    menu() {
      this.$bus.$emit('toggleCompanyDrawer', this.menu)
    },
  },
  created() {
    this.$bus.$on('toggleCompanyDrawer', menu => {
      this.menu = menu
    })
  }
}
</script>
