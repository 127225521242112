<template>
  <v-main class="margin-menu">
    <MobilePageTitle title="Perfil" :loader="loader" />

    <v-slide-x-transition>
      <v-container grid-list-md v-if="perfil" class="mt-5 mb-12">
        <v-layout row wrap>
          <v-flex xs12 v-if="user.iuguId && loggedUser.role !== 'customer'">
            <CardIuguData
              :iugu-id="user.iuguId"
              :iugu-subscription="user.iuguSubscription"
            />
          </v-flex>

          <v-flex xs12>
            <CardInformacoesBasica />
          </v-flex>

          <v-flex xs12 v-if="!user.permissions || !user.permissions.perfilLimitado">
            <CardExpansionPerfil />
          </v-flex>
        </v-layout>
      </v-container>
    </v-slide-x-transition>

    <OverlaySync :overlay="loader" />

    <DialogEditSons />
    <DialogEditBasicInfos />
    <DialogEditPatrimonio />
    <DialogEditProjetosVida />
    <DialogEditIndFinanceira />
    <DialogEditRelacionamento />
    <DialogEditProtecaoFamiliar />
    <DialogEditOcupacaoProfissional />
    <DialogEditExperienciaFinanceira />
  </v-main>
</template>

<script>
import moment from 'moment-timezone';
import OverlaySync from '../../components/Global/OverlaySync';
import MobilePageTitle from '../../components/Global/MobilePageTitle';

import getPerfil from '../../api/perfil/getPerfil';
import CardPhoto from '../../components/Customer/Perfil/cards/CardPhoto';
import CardInformacoesBasica from '../../components/Customer/Perfil/cards/CardInformacoesBasica';
import DialogEditBasicInfos from '../../components/Customer/Perfil/dialogs/DialogEditBasicInfos';
import CardExpansionPerfil from '../../components/Customer/Perfil/cards/CardExpansionPerfil';
import DialogEditRelacionamento from '../../components/Customer/Perfil/dialogs/DialogEditRelacionamento';
import DialogEditSons from '../../components/Customer/Perfil/dialogs/DialogEditSons';
import DialogEditOcupacaoProfissional from '../../components/Customer/Perfil/dialogs/DialogEditOcupacaoProfissional';
import DialogEditExperienciaFinanceira from '../../components/Customer/Perfil/dialogs/DialogEditExperienciaFinanceira';
import getUserPatrimonio from '../../api/patrimonio/getUserPatrimonio';
import DialogEditPatrimonio from '../../components/Customer/Perfil/dialogs/DialogEditPatrimonio';
import DialogEditProtecaoFamiliar from '../../components/Customer/Perfil/dialogs/DialogEditProtecaoFamiliar';
import getUserIndFinanceira from '../../api/indFinanceira/getUserIndFinanceira';
import DialogEditProjetosVida from '../../components/Customer/Perfil/dialogs/DialogEditProjetosVida';
import DialogEditIndFinanceira from '../../components/Customer/Perfil/dialogs/DialogEditIndFinanceira';
import CardIuguData from '@/components/Customer/Perfil/cards/CardIuguData';
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

export default {
  name: 'Perfil',
  // metaInfo() {
  //   return {
  //     title: 'Perfil',
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo);
  //     },
  //   };
  // },
  props: ['customer'],
  components: {
    CardIuguData,
    DialogEditIndFinanceira,
    DialogEditProjetosVida,
    DialogEditProtecaoFamiliar,
    DialogEditPatrimonio,
    DialogEditExperienciaFinanceira,
    DialogEditOcupacaoProfissional,
    DialogEditSons,
    DialogEditRelacionamento,
    CardExpansionPerfil,
    DialogEditBasicInfos,
    CardInformacoesBasica,
    CardPhoto,
    OverlaySync,
    MobilePageTitle,
  },
  data: () => ({
    loader: false,
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
      loggedUser() {
        return this.$store.getters.user
      },
      isCustomer() {
        return this.loggedUser && this.loggedUser.role === `customer`;
      },
      user() {
        return this.isCustomer ? this.loggedUser : this.$store.getters.customer;
      },
      perfil() {
        return this.$store.getters.perfil;
      },
      patrimonio() {
        return this.$store.getters.patrimonio;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      }
  },
  methods: {
    getPerfil() {
      if (this.user.role === 'customer') {
        getPerfil(this.user._id)
          .then((perfil) => {
            this.$store.dispatch('setPerfil', perfil);
            this.loader = false;
          })
          .catch((err) => {
            this.$router.push('/');
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    getPatrimonio() {
      if (!this.patrimonio && this.user.role === 'customer') {
        getUserPatrimonio(this.user._id).then((patrimonio) => {
          this.$store.dispatch('setPatrimonio', patrimonio);
        });
      }
    },
    getIndFinanceira() {
      if (!this.indFinanceira && this.user.role === 'customer') {
        let period = moment().tz('America/Sao_Paulo').format('YYYY-MM');
        getUserIndFinanceira(this.user._id, period).then((indFinanceira) => {
          this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
        });
      }
    },
  },
  beforeCreate() {
    this.$vuetify.theme.dark = true;
  },
  created() {
    this.getPerfil();
    this.getPatrimonio();
    this.getIndFinanceira();
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px !important;
}
</style>
