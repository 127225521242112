<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-card class="no-radius" v-if="recommendations">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          @click.stop="dialog = false"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="ml-2"> Convites enviados </span>
      </v-card-title>

      <v-card-text class="pt-5 mt-10">
        <v-container grid-list-md class="mx-0 px-0">
          <v-layout row wrap>
            <v-flex xs12>
              <v-card color="grey2" raised class="pb-6">
                <v-card-text>
                <span class="d-flex flex-column justify-center align-center">
                  <span class="primary--text text-uppercase">Meus Convites</span>
                </span>
                  <v-flex class="d-flex mt-5">
                    <v-flex class="d-flex flex-column justify-center align-center">
                      <div class="mb-5">
                        <span class="primary--text">Visualizados</span>
                      </div>
                      <div v-if="recommendations">
                        <v-row>
                          <v-icon>mdi-eye-check-outline</v-icon>
                          <span class="number-item">
                          {{ recommendations.length }}
                        </span>
                        </v-row>
                      </div>
                    </v-flex>
                    <v-flex class="d-flex flex-column justify-center align-center">
                      <div class="mb-5">
                        <span class="primary--text">Confirmados</span>
                      </div>
                      <div>
                        <v-row>
                          <v-icon>mdi-account-multiple-check-outline</v-icon>
                          <span class="number-item">{{ compras }}</span>
                        </v-row>
                      </div>
                    </v-flex>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>

          <v-layout row wrap v-if="recommendations && recommendations.length">
            <v-flex xs12>
              <v-card color="grey2" raised>
                <v-card-text>
                  <div class="primary--text text-uppercase text-center">
                    Recompensas Pendentes
                  </div>

                  <div class="d-flex flex-column mt-5">
                    <v-card
                      flat
                      :key="i._id"
                      color="transparent"
                      v-for="i in pendingRequestReward"
                    >
                      <v-card-text class="pa-0">
                        <div class="pending-request-item__infos">
                          <span class="pending-request-item__infos-name">{{ i.name }}</span>
                          <span class="pending-request-item__infos-date">
                            {{ formatedDate(i.dateConfirmedPurchase || i.dateAcceptInvite) }}
                          </span>
                        </div>
                      </v-card-text>

                      <v-card-actions class="pa-0">
                        <v-spacer></v-spacer>
                        <v-btn
                          dark
                          text
                          rounded
                          color="purple"
                          @click="showDialogRewardRequest(i)"
                        >
                          Solicitar recompensa
                        </v-btn>
                      </v-card-actions>

                      <v-divider class="grey1"></v-divider>
                    </v-card>
                  </div>

                  <span
                    v-if="!pendingRequestReward.length"
                    class="d-flex justify-center my-4"
                  >
                    Nenhuma solicitação pendente
                  </span>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>

          <v-layout row class="mt-5" v-if="recommendations && recommendations.length">
            <v-flex xs12>
              <span class="d-flex flex-column justify-center align-center">
                <span class="primary--text text-uppercase">Todos os Convites</span>
              </span>
            </v-flex>

            <v-flex
              xs12
              :key="i._id"
              v-for="i in recommendations"
            >
              <v-card color="grey2" raised>
                <v-card-text>
                  <div class="d-flex align-center">
                    <div class="mr-6">
                      <i v-if="!!i.purchased" class="lni lni-rocket purple--text size-icon"></i>
                      <i v-else class="lni lni-alarm-clock primary--text size-icon"></i>
                    </div>

                    <span class="font-weight-bold">
                      {{ i.name }}
                      <span v-if="!!i.purchased" class="primary--text">
                        começou a transformar sua vida financeira <br>
                      </span>

                      <span v-else>
                        está a um passo de transformar a vida financeira...
                      </span>

                      <span v-if="!!i.purchased">
                        em: {{ formatedDate(i.dateAcceptInvite) }}
                      </span>
                    </span>
                  </div>
                </v-card-text>

                <v-card-actions class="pt-0" v-if="i.purchased && i.rewardEnjoyed">
                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    class="px-5"
                    color="purple"
                    @click="showDialogPaymentVoucher(i)"
                  >
                    Ver comprovante
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { orderBy } from 'lodash';

export default {
  name: 'DialogRecommendationInvites',
  data: () => ({
    dialog: false
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    compras() {
      if (this.recommendations) {
        return this.recommendations.filter(i => !!i.purchased).length;
      }

      return 0;
    },
    recommendations() {
      const recommendations = this.$store.getters.recommendations;
      const items = orderBy(recommendations, 'purchased').reverse();

      return items;
    },
    pendingRequestReward() {
      return this.recommendations.filter(i => i.purchased && !i.requestReward);
    }
  },
  methods: {
    showDialogPaymentVoucher(item) {
      this.$bus.$emit('showDialogPaymentVoucher', item);
    },
    showDialogRewardRequest(item) {
      this.$bus.$emit('showDialogRewardRequest', item);
    },
    close() {
      this.dialog = false;
    },
    formatedDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY');
      }

      return '';
    }
  },
  created() {
    this.$bus.$on('showDialogRecommendationInvites', () => {
      this.dialog = true;
    });
  }
};
</script>

<style scoped>
.number-item {
  font-size: 32px;
  line-height: 11px;
  color: #F2F2F2;
  margin: 10px 15px;
}

.dialog-form-title {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
}

.size-icon {
  font-size: 20px;
}

.date-purchased {
  display: flex;
  justify-content: space-between;

}

.pending-request-item {
  padding-top: 5px;
}

.pending-request-item,
.pending-request-item__infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pending-request-item__infos-name {
  color: white;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: .05em;
}

.pending-request-item__infos-date {
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: .05em;
}

.pending-request-item__infos-btn {
  display: flex;
  width: 100%;
  text-align: right;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
