<template>
  <v-container grid-list-md class="pa-0 ma-0">
    <v-layout row wrap class="pa-0 ma-0">
      <v-flex xs12>
        <span class="input-label primary--text">Data de pagamento</span>
        <v-dialog
          ref="dialog"
          v-model="dateMenu"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mt-0 pt-0"
              :value="formatedDate"
              readonly
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                color="grey1"
                class="input-icon"
                slot="prepend-inner"
              >
                mdi-calendar
              </v-icon>
            </v-text-field>
          </template>

          <v-date-picker
            no-title
            color="primary"
            v-model="date"
            scrollable
            locale="pt-br"
          >
            <v-spacer></v-spacer>
            <v-btn color="grey2" @click="dateMenu = false">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              @click="$refs.dialog.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-flex>

      <v-flex xs12>
        <span class="input-label primary--text">Valor pago</span>
        <v-text-field
          type="tel"
          color="grey1"
          v-money="money"
          class="mt-0 pt-0"
          v-model="value"
          id="input-invoice-value"
          :error-messages="valueErrors"
        >
          <v-icon color="grey1" class="input-icon" slot="prepend-inner">
            mdi-cash
          </v-icon>
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {VMoney} from "v-money";
import removeMoneyMask from "@/utils/removeMoneyMask";

export default {
  name: 'FormInvoicePayment',
  props: ['invoiceValue'],
  directives: { money: VMoney },
  data: () => ({
    date: null,
    value: null,
    dateMenu: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    }
  }),
  validations: {
    date: {
      isValid(v) {
        return !!v && this.$date(v).isValid()
      }
    },
    value: {
      isValid(v) {
        return removeMoneyMask(v) > 0
      }
    }
  },
  computed: {
    dateErrors() {
      if (!this.$v.date.$anyDirty)
        return []

      if (this.$v.date.$anyError)
        return ['Preencha uma data válida']
    },
    valueErrors() {
      if (!this.$v.value.$anyDirty)
        return []

      if (this.$v.value.$anyError)
        return ['Preencha um valor válido']
    },
    formatedDate() {
      if (this.date) {
        return this.$date(this.date).format('DD/MM/YYYY')
      }

      return ''
    },
    limitDate() {
      return this.$date().format('YYYY-MM-DD')
    }
  },
  methods: {
    setDate() {
      this.date = this.$date().format('YYYY-MM-DD')
    },
    setValue() {
      if (this.invoiceValue) {
        this.value = this.invoiceValue.toFixed(2)

        const input = document.getElementById('input-invoice-value')
        if (input) {
          input.value = this.value
          input.dispatchEvent(new Event('input'))
        }
      }
    }
  },
  mounted() {
    this.setDate()
    this.setValue()
  }
}
</script>
