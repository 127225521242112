/**
 * Calcular taxa anual líquida sobre determinada taxa bruta anual e aliquota IR
 * @param {number} taxaBrutaAnual
 * @param {number} aliquotaIr
 * @param {number} inflacao
 */
const calcTaxaAnualLiquida = (taxaBrutaAnual, aliquotaIr, inflacao) => {
  if (taxaBrutaAnual !== 0) {
    if (typeof taxaBrutaAnual === 'string')
      taxaBrutaAnual = (parseFloat(taxaBrutaAnual.replace(',', '.')) / 100);
    else
      taxaBrutaAnual /= 100;

    if (typeof aliquotaIr === 'string')
      aliquotaIr = (parseFloat(aliquotaIr.replace(',', '.')) / 100);
    else
      aliquotaIr /= 100;

    if (inflacao && typeof inflacao === 'string')
      inflacao = (parseFloat(inflacao.replace(',', '.')) / 100);
    else if (inflacao)
      inflacao /= 100;
    else
      inflacao = 0;

    let taxaAnualLiquida = ((taxaBrutaAnual - inflacao) * (1 - aliquotaIr)) * 100;

    return parseFloat(taxaAnualLiquida.toFixed(2));
  }

  return 0;
}

export default calcTaxaAnualLiquida;
