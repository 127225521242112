<template>
  <v-card flat height="100%" class="animated fadeInRight">
    <v-card-title class="title-container">
      <span class="total-desc">Usuários por Status</span>

      <v-menu left bottom v-model="menu" :nudge-width="200" :close-on-content-click="false">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-icon v-on="{ ...tooltip, ...menu }">mdi-filter-variant</v-icon>
            </template>
            <span>Filtro</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-card-text>
            <v-select
              label="Tipo"
              color="accent"
              v-model="type"
              :items="userTypes"
              item-text="text"
              item-value="value"
            ></v-select>

            <v-btn
              dark
              small
              block
              rounded
              depressed
              type="button"
              color="darkgrey"
              :loading="loader"
              @click.stop="countUsers"
            >Filtrar</v-btn>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 class="chart-margin">
          <apexchart
            width="100%"
            height="300px"
            type="donut"
            :options="chartOptions.options"
            :series="chartOptions.series"
          ></apexchart>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import countUsersGroupedByStatus from '@/api/users/countUsersGroupedByStatus';

export default {
  name: 'CardCompanyGroupedUsersStatus',
  props: ['user'],
  components: {
    apexchart: VueApexCharts
  },
  data: () => ({
    chartOptions: {
      options: {
        colors: ['#47484D', '#DFCDA6'],
        labels: ['Habilitados', 'Desabilitados'],
        chart: {
          type: 'donut'
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val.toFixed(2) + '%';
          }
        },
        responsive: [
          {
            breakpoint: 1026,
            options: {
              chart: {
                width: '100%'
              },
              legend: {
                position: 'bottom',
                horizontalAlign: 'center'
              }
            }
          }
        ]
      },
      series: [0, 0, 0]
    },
    menu: false,
    userTypes: [
      { text: 'Todos', value: '' },
      { text: 'Clientes', value: 'customer' },
      { text: 'Planejadores', value: 'planner' }
    ],
    type: '',
    loader: false
  }),
  methods: {
    countUsers() {
      this.loader = true;

      let query = {
        companyId: this.user._id,
        type: this.type
      };

      countUsersGroupedByStatus(query)
        .then(resp => {
          let chartOptions = { series: resp };
          this.chartOptions = { ...this.chartOptions, ...chartOptions };
          this.loader = false;
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
        });
    }
  },
  watch: {
    period() {
      this.countUsers();
    }
  },
  created() {
    this.countUsers();
  }
};
</script>

<style scoped>
.total-desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.filter-container {
  display: flex;
  align-items: center;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
