import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Atualizar e-mail de determinado usuário
 * @param {string} currentEmail
 * @param {string} newEmail
 */
const updateUserEmail = (currentEmail, newEmail) =>
  new Promise((resolve, reject) => {
    let user = store.getters.user;

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'POST';
        let data = { currentEmail, newEmail };
        let headers = {
          Authorization: `Bearer ${idToken}`,
          author: user ? user._id : null
        };

        return httpClient(`/users/firebase/email`, { method, data, headers });
      })
      .then(resp => resolve())
      .catch(reject);
  });

export default updateUserEmail;
