<template>
  <div>
    <v-tabs
      centered
      show-arrows
      v-model="tab"
      background-color="transparent"
    >
      <v-tab
        :key="member.nome"
        v-for="member in members"
      >
        {{ member.nome }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        :key="member.nome"
        v-for="member in members"
      >
        <TabItemLifeInsurance :is-spouse="member.isSpouse"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TabItemLifeInsurance from '@/components/Customer/LifeInsurance/tab-items/TabItemLifeInsurance';
export default {
  name: 'TabsLifeInsurance',
  components: { TabItemLifeInsurance },
  data: () => ({
    tab: 'tab-1'
  }),
  computed: {
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === 'customer';
    },
    user() {
      return !this.isCustomer ? this.$store.getters.customer : this.$store.getters.user;
    },
    perfil() {
      return this.$store.getters.perfil;
    },
    members() {
      let members = [
        {
          nome: this.user.displayName.split(/\s+/)[0],
          isSpouse: false
        }
      ];

      if (this.perfil &&
        this.perfil.informacoesBasicas.conjuge && this.perfil.informacoesBasicas.conjuge.nome) {
        members.push({
          nome: this.perfil.informacoesBasicas.conjuge.nome.split(/\s+/)[0],
          isSpouse: true
        })
      }

      return members
    }
  }
}
</script>

<style scoped>
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
</style>
