<template>
  <v-dialog
    fullscreen
    persistent
    v-model="subscriptionSuspended"
  >
    <v-card>
      <v-card-text class="pt-5 safe-area-top safe-area-bottom">
        <h1 class="white--text">
          Sua assinatura foi suspensa<span class="primary--text">.</span>
        </h1>

        <p
          class="mt-5"
          style="font-size: 16px; line-height: 1.5em;"
        >
          Em caso de dúvidas entre contato com nosso suporte utilizando um dos meios de contato abaixo:
        </p>

        <div>
          <div class="span white--text input-label">E-mail</div>
          <a :href="'mailto:' + email" class="primary--text">{{ email }}</a>
        </div>

        <div class="mt-2">
          <div class="span white--text input-label">Whatsapp</div>
          <a :href="'https://api.whatsapp.com/send?phone=55' + whatsapp" class="primary--text">{{ whatsapp }}</a>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogSubscriptionSuspended',
  data: () => ({
    email: 'suporte@uplanner.com.br',
    whatsapp: '41991457440'
  }),
  computed: {
    subscriptionSuspended() {
      return (
        this.$store.getters.user &&
        !this.$store.getters.user.iuguSubscriptionStatus
      )
    }
  }
}
</script>
