<template>
  <v-card raised color="grey2">
    <v-card-title>
      <span class="series-title text-uppercase primary--text">Usuários diários</span>

      <v-menu left bottom v-model="menu" :nudge-width="200" :close-on-content-click="false">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-icon v-on="{ ...tooltip, ...menu }">mdi-filter-variant</v-icon>
            </template>
            <span>Filtro</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-card-text>
            <v-select
              label="Períodos"
              color="accent"
              v-model="period"
              :items="periods"
              item-text="text"
              item-value="value"
            ></v-select>

            <v-select
              label="Tipo"
              color="accent"
              v-model="type"
              :items="userTypes"
              item-text="text"
              item-value="value"
              v-if="user.role !== 'planner'"
            ></v-select>

            <v-btn
              small
              dark
              block
              rounded
              depressed
              type="button"
              color="primary"
              :loading="loader"
              @click.stop="buildChart"
            >
              Filtrar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-card-title>

    <apexchart
      type="line"
      :options="options"
      :series="series"
      width="100%"
      height="350"
    ></apexchart>

    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 sm6 md3 lg2 v-for="(item, i) in legends" :key="i">
          <div class="legend-item">
            <v-avatar size="10" :color="item.color" class="mr-2"></v-avatar>
            <span class="legend-item--title">{{ item.text }}</span>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';
  import countUsersSeries from "../../../api/users/countUsersSeries";

  export default {
    name: 'CardUsersTimeSeries',
    components: {apexchart: VueApexCharts},
    data: () => ({
      options: {
        labels: [],
        colors: ['#FFF', '#D1AC68'],
        chart: {
          type: 'line',
          toolbar: {show: false},
          background: 'rgba(255,255,255, 0)'
        },
        legend: {show: false},
        dataLabels: {enabled: false},
        grid: {show: false},
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        xaxis: {
          show: true,
          type: 'category',
          categories: [],
          labels: {
            rotateAlways: true,
            rotate: -45,
            style: {colors: '#9E9E9E'}
          }
        },
        yaxis: {
          min: 0,
          labels: {
            show: true,
            align: 'right',
            style: {colors: '#9E9E9E'}
          }
        },
        tooltip: {theme: 'dark'},
        markers: {
          size: 4,
          strokeWidth: 0
        }
      },
      legends: [
        {
          text: 'Usuários registrados',
          color: '#FFF'
        },
        {
          text: 'Usuários ativos',
          color: '#D1AC68'
        }
      ],
      series: [
        {
          name: 'Usuários registrados',
          data: []
        },
        {
          name: 'Usuários ativos',
          data: []
        }
      ],
      periods: [
        {
          text: 'Últimos 7 dias',
          value: 'last7'
        },
        {
          text: 'Últimos 15 dias',
          value: 'last15'
        },
        {
          text: 'Últimos 30 dias',
          value: 'last30'
        }
      ],
      period: 'last15',
      menu: false,
      type: '',
      loader: false
    }),
    computed: {
      isPlanner() {
        return this.$store.getters.user && this.$store.getters.user.role === 'planner';
      },
      user() {
        return this.isPlanner ? this.$store.getters.user : this.$store.getters.planner;
      },
      userTypes() {
        let types = [{ text: 'Todos', value: '' }];

        if (this.user.role === 'company' || this.user.role === 'admin') {
          this.types.push({ text: 'Clientes', value: 'customer' });
          this.types.push({ text: 'Planejadores', value: 'planner' });
        }

        if (this.user.role === 'admin')
          this.types.push({ text: 'Empresas', value: 'company' });

        return types;
      }
    },
    methods: {
      buildChart() {
        this.loader = true;

        let filter = {
          period: this.period,
          type: this.type
        };

        Promise.all([
          countUsersSeries(
            'signup',
            filter,
            this.user.role === 'company' ? this.user._id : '',
            this.user.role === 'planner' ? this.user._id : '',
          ),
          countUsersSeries(
            'signin',
            filter,
            this.user.role === 'company' ? this.user._id : '',
            this.user.role === 'planner' ? this.user._id : '',
          )
        ])
        .then(results => {
          let activeUsers = results[1];
          let registeredUsers = results[0];
          let options = JSON.parse(JSON.stringify(this.options));

          options.labels = [];

          this.series.forEach(d => {
            d.data = [];
          });

          registeredUsers.forEach(data => {
            this.series[0].data.push(data.total);
            options.xaxis.categories.push(data.label);
          });

          activeUsers.forEach(data => {
            this.series[1].data.push(data.total);
          });

          this.options = options;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
      }
    },
    created() {
      this.buildChart();
    }
  }
</script>

<style scoped>
  .v-card__title {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .series-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .legends {
    display: flex;
    align-items: center;
  }

  .legend-item {
    display: flex;
    margin-bottom: 2px;
    margin-right: 20px;
  }

  .legend-item div {
    display: flex;
    flex-direction: column;
  }

  .legend-item--title {
    font-size: 12px;
    letter-spacing: .1em;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    line-height: 12px;
  }
</style>
