import httpClient from '@/plugins/httpClient';
import config from '@/config';

const getCalendlyWebhooks = token => {
  return new Promise((resolve, reject) => {
    try {
      const options = {
        headers: {
          'X-TOKEN': token
        }
      }

      httpClient(`${config.calendly.api}/hooks`, options)
        .then(resp => resolve(resp.data.data))
        .catch(reject)
    } catch(err) {
      reject(err)
    }
  })
}

export default getCalendlyWebhooks
