<template>
  <v-card flat>
    <div class="card-title pr-4">
      <v-card-title>Alíquotas IRPF</v-card-title>
      <v-btn
        small
        dark
        rounded
        depressed
        color="primary"
        class="darkgrey--text"
        @click.stop="add"
        :disabled="loader"
      >
        <v-icon>mdi-playlist-plus</v-icon>
      </v-btn>
    </div>

    <v-card-text>
      <v-data-table
        item-key="_id"
        :items="config.aliquotaIrpf"
        :headers="headers"
        hide-default-footer
        :hide-default-header="isMobile"
      >
        <template v-slot:item="props">
          <tr>
            <td>{{ numberToCurrency(props.item.baseCalculoInicial) }}</td>

            <td class="hidden-xs-only">
              {{ props.item.baseCalculoFinal > 0 ? numberToCurrency(props.item.baseCalculoFinal) : 'Sem limite' }}
            </td>

            <td class="hidden-xs-only" style="width: 100px;">
              {{ props.item.aliquota > 0 ? props.item.aliquota.toFixed(2) + '%' : 'Isento' }}
            </td>

            <td class="hidden-xs-only">
              {{ props.item.parcelaDeducao > 0 ? numberToCurrency(props.item.parcelaDeducao) : 'Isento' }}
            </td>

            <td class="text-center" style="width: 90px;">
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click.stop="edit(props.item)">
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click.stop="remove(props.item._id)">
                    <v-list-item-title>Remover</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';

export default {
  name: 'CardConfigAliquotaIrpf',
  data: () => ({
    numberToCurrency,
    selected: [],
    loader: false,
    pagination: {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      itemsPerPage: 10
    },
    headers: [
      {
        text: 'Base de cálculo anual inicial',
        align: 'left',
        value: 'baseCalculoInicial',
        sortable: true,
        class: ''
      },
      {
        text: 'Base de cálculo anual final',
        align: 'left',
        value: 'baseCalculoFinal',
        sortable: true,
        class: ''
      },
      {
        text: 'Alíquota',
        align: 'left',
        value: 'aliquota',
        sortable: true,
        class: 'hidden-xs-only'
      },
      {
        text: 'Parcela a deduzir',
        align: 'left',
        value: 'parcelaDeducao',
        sortable: true,
        class: 'hidden-xs-only'
      },
      {
        text: 'Opções',
        align: 'center',
        value: '',
        sortable: false,
        class: ''
      }
    ]
  }),
  methods: {
    remove(id) {
      this.$bus.$emit('showDialogRemoveAliquotaIrpfItem', id);
    },
    edit(item) {
      this.$bus.$emit('showDialogFormConfigAliquotaIrpf', item);
    },
    add() {
      this.$bus.$emit('showDialogFormConfigAliquotaIrpf', null);
    }
  },
  computed: {
    config() {
      return this.$store.getters.configIr;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  mounted() {
    this.pagination.totalItems = this.selected.length;
    this.pagination.totalPages = Math.ceil(
      this.pagination.totalItems / this.pagination.itemsPerPage
    );
  }
};
</script>

<style scoped>
.card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v-input--selection-controls {
  margin-top: 0;
}
</style>
