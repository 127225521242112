<template>
  <v-card flat color="grey2">
    <v-card-title>
      <span class="total-desc primary--text">Usuários Registrados</span>
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent="getUsers">
        <v-layout row wrap>
          <v-flex lg4 md6 sm7 xs12>
            <v-text-field
              color="grey1"
              persistent-hint
              v-model="search"
              label="Pesquisar"
              hint="Nome, razão social ou e-mail"
            ></v-text-field>
          </v-flex>

          <v-flex lg3 md6 sm5 xs12>
            <v-btn rounded depressed class="mt-2 mr-2 darkgrey--text" type="submit" color="primary">
              <v-icon>mdi-account-search-outline</v-icon>
            </v-btn>

            <v-menu
              bottom
              v-model="menu"
              :nudge-width="200"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      rounded
                      depressed
                      class="mt-2 darkgrey--text"
                      color="primary"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Filtro avançado</span>
                </v-tooltip>
              </template>

              <v-card>
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-select
                      label="Tipo"
                      :items="typeItems"
                      item-text="text"
                      item-value="value"
                      v-model="type"
                      v-if="!['support', 'planner'].includes(user.role)"
                    ></v-select>

                    <v-autocomplete
                      item-value="_id"
                      v-model="planner"
                      label="Planejador"
                      :items="planners"
                      item-text="displayName"
                      v-if="type === 'customer' && user.role !== 'planner'"
                    ></v-autocomplete>

                    <v-autocomplete
                      item-value="_id"
                      v-model="company"
                      label="Empresa"
                      :items="companies"
                      item-text="displayName"
                      v-if="type === 'planner' && (user.role !== 'company' && user.role !== 'planner')"
                    ></v-autocomplete>

                    <v-select
                      label="Status"
                      :items="statusItems"
                      item-text="text"
                      item-value="value"
                      v-model="status"
                    ></v-select>

                    <v-btn
                      dark
                      block
                      rounded
                      depressed
                      type="button"
                      class="darkgrey--text"
                      color="primary"
                      :loading="loader"
                      @click.stop="getUsers"
                    >
                      Filtrar
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-menu>

            <v-menu right bottom v-if="user.role !== 'support'">
              <template v-slot:activator="{ on }">
                <v-btn
                  rounded
                  v-on="on"
                  depressed
                  type="button"
                  color="primary"
                  class="mt-2 ml-2 darkgrey--text"
                >
                  <v-icon>mdi-account-plus-outline</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-if="
                    ['admin', 'comercial'].includes(user.role) ||
                    (!user.isUplanner && user.role === 'planner')
                  "
                  @click.stop="showForm('customer')"
                >
                  <v-list-item-title>Cliente</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="['admin', 'supervisor'].includes(user.role)"
                  @click.stop="showForm('planner')"
                >
                  <v-list-item-title>Planejador</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="['admin', 'comercial'].includes(user.role)"
                  @click.stop="showForm('company')"
                >
                  <v-list-item-title>Empresa</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="user.role === 'admin'"
                  @click.stop="showForm('admin')"
                >
                  <v-list-item-title>Administrador</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="user.role === 'admin'"
                  @click.stop="showForm('support')"
                >
                  <v-list-item-title>Suporte</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="user.role === 'admin'"
                  @click.stop="showForm('supervisor')"
                >
                  <v-list-item-title>Supervisor</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="user.role === 'admin'"
                  @click.stop="showForm('comercial')"
                >
                  <v-list-item-title>Gerente comercial</v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click.stop="showDialogImportUsers"
                  v-if="['admin', 'comercial'].includes(user.role) && !isMobile"
                >
                  <v-list-item-title>Importar Planilha</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-form>

      <TableUsers class="mt-5" />
    </v-card-text>
  </v-card>
</template>

<script>
import TableUsers from './TableUsers';
import getPlannersNames from '@/api/planners/getPlannersNames';
import getCompaniesNames from '@/api/companies/getCompaniesNames';

export default {
  name: 'CardTableUsers',
  components: { TableUsers },
  data: () => ({
    statusItems: [
      {
        text: 'Todos',
        value: ''
      },
      {
        text: 'Habilitados',
        value: 'false'
      },
      {
        text: 'Desabilitados',
        value: 'true'
      }
    ],
    status: '',
    type: '',
    search: '',
    menu: false,
    planners: [],
    companies: [],
    planner: '',
    company: '',
    loader: false
  }),
  methods: {
    getUsers() {
      let filter = {
        type: this.type,
        search: this.search,
        status: this.status
      };

      if (this.user.role === 'planner') {
        filter.planner = this.user._id;
      } if (this.user.role === 'support') {
        filter.type = 'customer'
      } else if (this.user.role === 'comercial' && !filter.type) {
        filter.roles = 'customer,company'
      } else if (this.user.role === 'supervisor') {
        filter.supervisor = this.user._id
        filter.roles = 'customer,planner'
      }

      // if (this.currentCompany) filter.company = this.user._id;
      // else if (this.company) filter.company = this.company;
      // else delete filter.company;
      //
      // if (this.currentPlanner) filter.planner = this.user._id;
      // else if (this.planner) filter.planner = this.planner;
      // else delete filter.planner;

      this.$bus.$emit('managerGetUsers', filter);
      this.menu = false;

      setTimeout(() => {
        this.loader = false;
      }, 3000);
    },
    showForm(type) {
      let event = 'showFormCustomer'
      let data = null

      if (type === 'planner') {
        event = 'showFormPlanner'
      } else if (type === 'company') {
        event = 'showFormCompany'
      } else if (type === 'admin') {
        event = 'showDialogFormUser'
        data = { role: 'admin' }
      } else if (type === 'support') {
        event = 'showDialogFormUser'
        data = { role: 'support' }
      } else if (type === 'supervisor') {
        event = 'showDialogFormUser'
        data = { role: 'supervisor' }
      } else if (type === 'comercial') {
        event = 'showDialogFormUser'
        data = { role: 'comercial' }
      }

      this.$bus.$emit(event, data);
    },
    getPlannersNames() {
      getPlannersNames(this.user.role === 'supervisor' ? this.user._id : null)
        .then(planners => {
          this.planners = planners;
          this.planners.splice(0, 0, { displayName: 'Todos', _id: '' });
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.planners = [{ displayName: 'Todos', _id: '' }];
        });
    },
    getCompaniesNames() {
      getCompaniesNames()
        .then(companies => {
          this.companies = companies;
          this.companies.splice(0, 0, { displayName: 'Todas', _id: '' });
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.namesList = [{ displayName: 'Todas', _id: '' }];
        });
    },
    showDialogImportUsers() {
      if (['admin', 'comercial'].includes(this.user.role)) {
        this.$bus.$emit('showDialogImportUsers')
      }
    }
  },
  computed: {
    currentCompany() {
      return this.$store.getters.company;
    },
    isPlanner() {
      return this.$store.getters.user && this.$store.getters.user.role === 'planner';
    },
    user() {
      return (this.isPlanner || this.$route.name !== 'Planner') ? this.$store.getters.user : this.$store.getters.planner;
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    typeItems() {
      let items = [
        {
          text: 'Todos',
          value: ''
        },
        {
          text: 'Cliente',
          value: 'customer'
        },
      ]

      if (this.user.role === 'admin') {
        items = items.concat([
          {
            text: 'Suporte',
            value: 'support'
          },
          {
            text: 'Comercial',
            value: 'comercial'
          },
          {
            text: 'Supervisor',
            value: 'supervisor'
          }
        ])
      }

      if (['admin', 'comercial'].includes(this.user.role)) {
        items.push({
          text: 'Empresa',
          value: 'company'
        })
      }

      if (['admin', 'supervisor'].includes(this.user.role)) {
        items.push({
          text: 'Planejador',
          value: 'planner'
        })
      }

      return items
    }
  },
  watch: {
    type() {
      this.planner = '';
      this.company = '';
    }
  },
  mounted() {
    this.getUsers();

    if (this.user.role !== 'planner') this.getPlannersNames();

    if (this.user.role !== 'company') this.getCompaniesNames();

    if (this.user.role === 'company') {
      this.typeItems = [
        {
          text: 'Todos',
          value: ''
        },
        {
          text: 'Cliente',
          value: 'customer'
        },
        {
          text: 'Planejador',
          value: 'planner'
        }
      ];
    }
  }
};
</script>

<style scoped>
.total-desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
</style>
