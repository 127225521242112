import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar endereço de um determinado CEP
 * @param {string} cep
 */
const getAddressByCep = cep =>
  new Promise((resolve, reject) => {
    cep = cep.replace(/\D+/g, '').trim();

    if (cep && cep.length === 8) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          let headers = { Authorization: `Bearer ${idToken}` };
          return httpClient(`/address/cep/${cep}`, { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });

export default getAddressByCep;
