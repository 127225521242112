<template>
  <div>
    <div class="transaction-description">
      <span class="transaction-description--text">
        {{ description }}
      </span>

      <span class="transaction-description--value">
        <span>R$</span> {{ value }}
      </span>
    </div>

    <div class="transaction-category">
      <span class="transaction-category--desc">
        {{ categoryDescription }}
      </span>

      <span class="transaction-category--desc" v-if="status">
        {{ status }}
      </span>
    </div>

    <div class="transaction-obs" v-if="observations">
      <v-icon size="10">mdi-file-document-outline</v-icon>
      {{ observations }}
    </div>
  </div>
</template>

<script>
import {truncate} from "lodash";
import stringCapitalize from "@/utils/stringCapitalize";
import currency from "@/utils/currency";

export default {
  name: 'ListTransactionContent',
  props: ['transaction', 'resize'],
  computed: {
    description() {
      let text = ''

      if (this.transaction) {
        const {
          subCategory,
          description,
          category,
          divided,
          dividedPart,
          dividedParts,
          isRevised,
          isRefund
        } = this.transaction

        if (subCategory && subCategory.name) {
          text = this.truncate(subCategory.name)
        } else if (category && category.name) {
          text = this.truncate(category.name)
        } else if (isRevised === false) {
          text = 'Não categorizada'
        } else if (description) {
          text = this.truncate(description)
        } else if (isRefund) {
          text = 'Reembolso'
        }

        if (divided) {
          text += ` ${dividedPart}/${dividedParts}`
        }
      }

      return text
    },
    value() {
      let value = 0

      if (this.transaction) {
        const {isInvoice, type, value: originalValue} = this.transaction
        value = originalValue

        if (isInvoice || type === 'saida'){
          value *= -1
        }
      }

      return currency(value)
    },
    categoryDescription() {
      if (this.transaction) {
        let description = ''
        const {category, creditCard, bankAccount, isInvoice} = this.transaction

        if (category && category.name && !isInvoice) {
          description += category.name + ' | '
        }

        description += creditCard ? creditCard.name : (bankAccount ? bankAccount.name : '')

        return this.truncate(stringCapitalize(description))
      }

      return ''
    },
    status() {
      if (this.transaction && this.transaction.bankAccount) {
        const {type, investment, consolidated} = this.transaction

        if (type === 'entrada') {
          return consolidated ? 'Recebida' : 'Não recebida'
        } else if (investment) {
          return consolidated ? 'Investido' : 'Não investido'
        }

        return consolidated ? 'Paga' : 'Não paga'
      } else if (this.transaction.isInvoice) {
        return 'Não paga'
      }

      return ''
    },
    observations() {
      if (this.transaction) {
        let obs = ''
        let length = 40

        if (this.resize < 320 || (this.resize >= 320 && this.resize < 375)) {
          length = 17
        } else if (this.resize >= 375 && this.resize < 415) {
          length = 32
        } else if (this.resize >= 415 && this.resize < 600) {
          length = 35
        }

        if (this.transaction.obs) {
          obs = this.transaction.obs
        } else if (this.transaction.description) {
          obs = this.transaction.description
        }


        if (this.resize > 600) {
          return obs
        }

        return this.truncate(obs, length)
      }

      return ''
    }
  },
  methods: {
    truncate(string, length) {
      return truncate(string, {length: length || 35})
    }
  }
}
</script>

<style scoped>
.transaction-description {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.transaction-description--text {
  font-size: 12px !important;
  letter-spacing: .1em;
}

.transaction-description--value {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .1em;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.transaction-category {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #9e9e9e;
}

.transaction-category--desc {
  font-size: 12px;
  letter-spacing: .1em;
}

.transaction-obs {
  font-size: 12px !important;
  display: flex;
  align-items: flex-end;
  color: #9e9e9e;
  letter-spacing: .1em;
}

.transaction-obs .v-icon {
  color: #9e9e9e !important;
  margin-right: 5px !important;
  margin-bottom: 4px !important;
}

@media only screen and (min-width: 375px) {
  .transaction-description--value {
    font-size: 15px;
  }

  .transaction-description--value span {
    font-size: 12px;
  }
}

@media only screen and (min-width: 414px) {
  .transaction-description--value {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .transaction-description--text {
    font-size: 15px !important;
  }

  .transaction-description--value {
    font-size: 18px;
  }

  .transaction-description--value span {
    font-size: 12px;
  }

  .transaction-category--icon .v-avatar .v-icon {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 960px) {
  .transaction-description--value {
    font-size: 20px;
  }
}
</style>
