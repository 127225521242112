<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          class="mr-2"
          color="success"
          :disabled="loader"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="success--text">{{ refund._id ? 'Editar' : 'Adicionar' }} Reembolso</span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit" v-if="transaction">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="input-label primary--text">Descrição</span>
                <v-text-field
                  dense
                  readonly
                  disabled
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="refund.description"
                  :readonly="!!transaction.pluggy"
                  :disabled="!!transaction.pluggy"
                  :error-messages="descriptionErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Data</span>
                <v-dialog
                  persistent
                  ref="dialog"
                  width="290px"
                  v-model="dateMenu"
                  :return-value.sync="refund.date"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      readonly
                      v-on="on"
                      dense
                      v-bind="attrs"
                      class="mt-0 pt-0"
                      :value="formatedDate"
                      :readonly="!!transaction.pluggy"
                      :disabled="!!transaction.pluggy"
                      :error-messages="dateErrors"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    scrollable
                    locale="pt-br"
                    color="primary"
                    v-model="refund.date"
                  >
                    <v-spacer></v-spacer>

                    <v-btn
                      color="grey2"
                      @click="dateMenu = false"
                    >
                      Cancelar
                    </v-btn>

                    <v-btn
                      color="primary"
                      @click="$refs.dialog.save(refund.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Valor</span>
                <v-text-field
                  dense
                  id="input-value-pendencia"
                  color="grey1"
                  v-money="money"
                  class="mt-0 pt-0"
                  v-model="refund.value"
                  :readonly="!!transaction.pluggy"
                  :disabled="!!transaction.pluggy"
                  :error-messages="valueErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Cartão de crédito</span>
                <v-text-field
                  dense
                  color="grey1"
                  class="mt-0 pt-0"
                  readonly
                  disabled
                  :value="transaction.creditCard.name"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Fatura</span>
                <v-select
                  dense
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="invoice"
                  :items="invoices"
                  item-text="desc"
                  item-value="period"
                  :error-messages="invoiceErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                  <v-card-actions class="px-0 ma-0">
                    <v-btn
                      rounded
                      outlined
                      color="grey1"
                      type="button"
                      :disabled="loader"
                      @click="closeDialog"
                      class="px-5 text-capitalize"
                    >
                      Cancelar
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                      dark
                      rounded
                      color="success"
                      type="submit"
                      :loading="loader"
                      class="px-5 text-capitalize"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import removeMoneyMask from "@/utils/removeMoneyMask";
import {VMoney} from "v-money";
import getInvoice from "@/api/invoices/getInvoice";
import payInvoice from "@/api/invoices/payInvoice";
import saveRefund from "@/api/invoices/saveRefund";

export default {
  name: 'DialogPendenciaEstorno',
  directives: {money: VMoney},
  data: () => ({
    dialog: false,
    loader: false,
    invoice: null,
    dateMenu: false,
    currentDate: null,
    transaction: null,
    refund: {
      date: '',
      value: '',
      description: ''
    },
    money: {
      precision: 2,
      decimal: ',',
      prefix: 'R$ ',
      thousands: '.'
    }
  }),
  validations: {
    refund: {
      date: {
        isValid(v) {
          return !!v && this.$date(v).isValid()
        }
      },
      value: {
        isValid(v) {
          return !!v && removeMoneyMask(v) > 0
        }
      },
      description: {
        isValid(v) {
          return !v || v.length <= 150
        }
      }
    },
    invoice: {
      isValid(v) {
        return !!v
      }
    }
  },
  computed: {
    months() {
      return this.$store.getters.months
    },
    creditCard() {
      if (this.transaction) {
        return this.transaction.creditCard
      }

      return null
    },
    invoices() {
      let invoices = [];

      if (this.creditCard) {
        const {close, pay} = this.creditCard

        if (
          close > pay ||
          this.currentDate.date() > close
        ) {
          this.currentDate = this.currentDate.add(1, 'month')
        }

        this.currentDate = this.currentDate.subtract(6, 'month')

        for (let i = 1; i < 14; i++) {
          let monthData = this.months.find((m) => m.numero === (this.currentDate.month() + 1));

          invoices.push({
            period: this.currentDate.format('YYYY-MM'),
            desc: `${monthData.sigla} de ${this.currentDate.year()}`
          });

          this.currentDate = this.currentDate.add(1, 'month')
        }
      }

      return invoices;
    },
    formatedDate() {
      if (this.refund.date) {
        return this.$date(this.refund.date).format('DD/MM/YYYY')
      }
    },
    formatedCurrentDate() {
      if (this.currentDate) {
        return this.$date(this.currentDate).format('YYYY-MM-DD')
      }
    },
    dateErrors() {
      if (!this.$v.refund.date.$anyDirty)
        return []

      if (this.$v.refund.date.$anyError)
        return ['Selecione uma data válida']
    },
    valueErrors() {
      if (!this.$v.refund.value.$anyDirty)
        return []

      if (this.$v.refund.value.$anyError)
        return ['Preencha um valor válido']
    },
    descriptionErrors() {
      if (!this.$v.refund.description.$anyDirty)
        return []

      if (this.$v.refund.description.$anyError)
        return ['Preencha uma descrição válida']
    },
    invoiceErrors() {
      if (!this.$v.invoice.$anyDirty)
        return []

      if (this.$v.invoice.$anyError)
        return ['Selecione uma fatura válida']
    },
  },
  methods: {
    openDialog(transaction) {
      if (transaction) {
        this.transaction = transaction
        this.refund.date = transaction.date
        this.refund.value = transaction.value.toFixed(2)

        if (transaction.description)
          this.refund.description = transaction.description

        this.currentDate = this.$date()
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false

      this.invoice = null
      this.transaction = null

      this.refund = {
        date: '',
          value: '',
          description: ''
      }

      this.$v.$reset()
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          const selectedInvoice = await getInvoice(this.creditCard._id, this.invoice)

          if (selectedInvoice.paid) {
            this.$noty.warning('Fatura pagas. Cancele o pagamento ou escolha outra fatura.')
          } else {


            const data = {
              ...this.refund,
              transaction: this.transaction,
              value: removeMoneyMask(this.refund.value)
            }

            const {invoice, creditCard, transaction} = await saveRefund(selectedInvoice._id, data)

            await Promise.all([
              this.$store.dispatch('saveInvoice', invoice),
              this.$store.dispatch('saveCreditCard', creditCard),
              this.$store.dispatch('saveTransaction', transaction)
            ])

            this.closeDialog()
            this.$noty.success('Reembolso adicionado com sucesso!')
          }
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.$noty.error('Desculpe! Não foi possível salvar este item na fatura. Tente novamente mais tarde!')
      } finally {
        this.loader = false
      }
    }
  },
  created() {
    this.$bus.$on('showDialogPendenciaEstorno', this.openDialog)
  }
}
</script>
