<template>
  <div class="pt-5">
    <UsersStatus/>

    <v-data-table
      dense
      :items="users"
      :loading="loader"
      :hide-default-header="isMobile"
      hide-default-footer
      :headers="tableHeaders"
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-center" style="width: 90px; padding: 5px;">
            <v-icon :color="userStatusColor(props.item)">
              {{ userStatusIcon(props.item) }}
            </v-icon>
          </td>

          <td class="hidden-xs-only" style="width: 100px;">
            <v-chip
              small
              :color="getRoleColor(props.item.role, props.item.disabled)"
              :class="props.item.disabled ? 'black--text' : 'white--text'"
            >
              {{ getRoleName(props.item.role) }}
            </v-chip>
          </td>

          <td class="user-name">
            {{
              props.item.displayName.length && isMobile > 22 ?
                props.item.displayName.slice(0, 22) + '...' :
                props.item.displayName
            }}
          </td>

          <td class="hidden-md-and-down">
            {{
              props.item.isUplanner && !props.item.company ?
                'Uplanner' : (props.item.company ?
                  props.item.company.displayName : '')
            }}
          </td>

          <td class="hidden-sm-and-down">
            {{ props.item.planner ? props.item.planner.displayName : '' }}
          </td>

          <td class="hidden-sm-and-down">
            {{ props.item.supervisor ? props.item.supervisor.displayName : '' }}
          </td>

          <td class="hidden-sm-and-down text-center">
            {{ formatDate(props.item.createdAt) }}
          </td>

          <td class="text-center" style="width: 30px; padding: 0">
            <v-menu left>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense nav color="grey2">

                <v-list-item
                  v-if="
                    ['customer', 'planner', 'company'].includes(props.item.role) &&
                    ['planner', 'admin', 'supervisor'].includes(user.role)
                  "
                  style="min-width: 200px"
                  @click.stop="showDetails(props.item)"
                >
                  Detalhes
                </v-list-item>

                <v-list-item
                  style="min-width: 200px;"
                  v-if="['support', 'comercial'].includes(user.role)"
                  @click="showDialogUserContacts(props.item)"
                >
                  Contatos
                </v-list-item>

                <v-list-item
                  style="min-width: 200px"
                  @click.stop.prevent="showDialogFormUser(props.item)"
                  v-if="props.item.uid && ['planner', 'admin', 'supervisor', 'comercial'].includes(user.role)"
                >
                  Editar
                </v-list-item>

                <v-list-item
                  v-if="
                    props.item.role === 'company' &&
                    user.role !== 'support'
                  "
                  style="min-width: 200px"
                  @click.stop="showPurchaseUrl(props.item.displayName, props.item.companyPayment.billingUrl, props.item.role)"
                >
                  Link Colab
                </v-list-item>

                <v-list-item
                  style="min-width: 200px"
                  v-if="props.item.role === 'planner' && props.item.isUplanner && user.role !== 'support'"
                  @click.stop="showPurchaseUrl(props.item.displayName, props.item.atendimento.billingUrl, props.item.role)"
                >
                  Link do planejador
                </v-list-item>

                <v-list-item
                  style="min-width: 200px"
                  @click.stop="showDialogCalendlyPlanner(props.item)"
                  v-if="props.item.role === 'planner' && props.item.isUplanner"
                >
                  Integrar Calendly
                </v-list-item>

                <v-list-item
                  style="min-width: 200px"
                  v-if="!props.item.uid && ['planner', 'admin', 'supervisor'].includes(user.role)"
                  @click.stop="showFormCreateCustomerPassword(props.item._id)"
                >
                  Criar senha
                </v-list-item>

                <v-list-item
                  v-if="
                    props.item.uid &&
                    props.item.locked &&
                    props.item.role === 'customer' &&
                    ['planner', 'admin', 'supervisor'].includes(user.role)
                  "
                  style="min-width: 200px"
                  @click.stop="unlock(props.item._id, props.item.planner, props.item.company)"
                >
                  Desbloquear
                </v-list-item>

                <v-list-item
                  style="min-width: 200px"
                  v-if="props.item.uid && props.item.isUplanner && props.item.role === 'customer'"
                  @click.stop="showDialogMeetingsConfirmation(props.item._id, !!props.item.company)"
                >
                  Reuniões
                </v-list-item>

                <v-list-item
                  v-if="
                    props.item.uid &&
                    props.item.role === 'customer' &&
                    ['planner', 'admin', 'supervisor'].includes(user.role)
                  "
                  style="min-width: 200px"
                  @click.stop="showDialogNotes(props.item)"
                >
                  Anotações
                </v-list-item>

                <v-list-item
                  style="min-width: 200px"
                  v-if="props.item.uid && ['planner', 'admin', 'supervisor'].includes(user.role)"
                  @click.stop="showDialogCustomerActivity(props.item)"
                >
                  Log de Atividades
                </v-list-item>

                <v-list-item
                  v-if="
                    props.item.uid &&
                    props.item.role === 'customer' &&
                    ['planner', 'admin', 'supervisor'].includes(user.role)
                  "
                  style="min-width: 200px"
                  @click.stop="showDialogAcessPermission(props.item)"
                >
                  Editar Permissões
                </v-list-item>

                <v-list-item
                  v-if="
                    props.item.role === 'customer' &&
                    ['admin', 'supervisor'].includes(user.role)
                  "
                  style="min-width: 200px"
                  @click.stop="showDialogChangeUserPlanner(props.item)"
                >
                  Alterar planejador
                </v-list-item>

                <v-list-item
                  v-if="
                    props.item.role === 'planner' &&
                    user.role === 'admin'
                  "
                  style="min-width: 200px"
                  @click.stop="showDialogChangePlannerSupervisor(props.item)"
                >
                  Alterar supervisor
                </v-list-item>

                <v-list-item
                  style="min-width: 200px"
                  v-if="props.item.uid && ['admin', 'supervisor', 'planner'].includes(user.role)"
                  @click.stop="showDialogPasswordResetLink(props.item)"
                >
                  Alterar senha
                </v-list-item>

                <v-list-item
                  style="min-width: 200px"
                  v-if="props.item.uid && ['admin', 'supervisor', 'planner'].includes(user.role)"
                  @click.stop="showDialogEmailUpdate(props.item)"
                >
                  Alterar e-mail
                </v-list-item>

                <v-list-item
                  v-if="
                    (props.item.uid || props.item.iuguSubscription) &&
                    (
                      ['admin', 'supervisor'].includes(user.role) ||
                      (user.role === 'planner' && !user.isUplanner)
                    )
                  "
                  style="min-width: 200px"
                  @click.stop="showDialogUpdateUserStatus(props.item)"
                >
                  {{ props.item.disabled ? 'Habilitar' : 'Desabilitar' }}
                </v-list-item>

                <v-list-item
                  v-if="
                    props.item.company &&
                    props.item.role === 'customer' &&
                    !props.item.iuguSubscription &&
                    ['admin', 'supervisor', 'planner', 'comercial'].includes(user.role)
                  "
                  style="min-width: 200px"
                  @click.stop="changeCancelStatus(props.item)"
                >
                  {{ typeof props.item.canceled !== 'undefined' && props.item.canceled ? 'Ativar' : 'Cancelar' }}
                </v-list-item>

                <v-list-item
                  v-if="
                    props.item.role === 'customer' &&
                    ['admin', 'supervisor'].includes(user.role)
                  "
                  style="min-width: 200px"
                  @click.stop="updateIuguSubscription(props.item)"
                >
                  Atribuir assinatura
                </v-list-item>

              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="text-center mt-5">
      <v-pagination
        circle
        color="primary"
        v-model="pagination.page"
        :length="pagination.totalPages"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import getUsers from '@/api/users/getUsers';
import countUsers from '@/api/users/countUsers';
import changeStatus from '@/api/users/changeStatus';
import UsersStatus from "@/components/Manager/dashboard/utils/UsersStatus";

export default {
  name: 'TableUsers',
  components: {UsersStatus},
  data: () => ({
    loader: false,
    users: [],
    tableHeaders: [
      {
        text: 'Status',
        value: 'disabled',
        sortable: true,
        align: 'center',
        class: ''
      },
      {
        text: 'Tipo',
        value: 'role',
        sortable: true,
        align: 'left',
        class: 'hidden-xs-only'
      },
      {
        text: 'Nome/Razão Social',
        value: 'displayName',
        sortable: true,
        align: 'left',
        class: ''
      },
      {
        text: 'Empresa',
        value: 'company',
        sortable: true,
        align: 'left',
        class: 'hidden-md-and-down'
      },
      {
        text: 'Planejador',
        value: 'planner',
        sortable: true,
        align: 'left',
        class: 'hidden-sm-and-down'
      },
      {
        text: 'Supervisor',
        value: 'supervisor',
        sortable: true,
        align: 'left',
        class: 'hidden-sm-and-down'
      },
      {
        text: 'Registrado em',
        value: 'createdAt',
        sortable: true,
        align: 'center',
        class: 'hidden-sm-and-down'
      },
      {
        text: 'Opções',
        value: 'action',
        sortable: false,
        align: 'center'
      }
    ],
    filter: {
      search: '',
      type: '',
      status: '',
      company: '',
      planner: ''
    },
    pagination: {
      page: 1,
      itemsPerPage: 10
    }
  }),
  methods: {
    showDialogUserContacts(user) {
      this.$bus.$emit('showDialogUserContacts', user)
    },
    getRoleColor(role, disabled) {
      if (disabled) {
        return 'grey lighten-2'
      }

      switch (role) {
        case 'customer':
          return 'success'
        case 'company':
          return 'darkblue'
        case 'planner':
          return 'primary'
        case 'support':
          return 'info'
        case 'comercial':
          return 'warning'
        case 'supervisor':
          return 'error'
        default:
          return ''
      }
    },
    showDialogChangePlannerSupervisor(planner) {
      this.$bus.$emit('showDialogChangePlannerSupervisor', planner)
    },
    showDialogChangeUserPlanner(user) {
      if (user) {
        this.$bus.$emit('showDialogChangeUserPlanner', user)
      }
    },
    changeCancelStatus(user) {
      if (user && user.company && this.user.role === 'admin') {
        const canceled = typeof user.canceled !== 'undefined' ? user.canceled : false;
        this.$bus.$emit('showDialogChangeUserCanceledStatus', {
          _id: user._id,
          canceled
        });
      }
    },
    userStatusColor(user) {
      if (user.disabled) {
        return 'error';
      } else if (user.iuguSubscriptionStatus === false) {
        return 'error';
      } else if (user.canceled && !user.iuguSubscription) {
        return 'error';
      } else if (user.locked) {
        return 'warning';
      } else if (user.company && !user.iuguSubscription) {
        return 'warning';
      } else {
        return 'success';
      }
    },
    userStatusIcon(user) {
      if (user.role !== 'customer') {
        return 'mdi-check-circle';
      } else if (user.disabled) {
        return 'mdi-check-circle';
      } else if (user.iuguSubscriptionStatus === false) {
        return 'mdi-account-cancel-outline';
      } else if (user.canceled && !user.iuguSubscription) {
        return 'mdi-account-cancel-outline';
      } else if (user.locked) {
        return 'mdi-account-lock-outline';
      } else if (user.company && !user.iuguSubscription) {
        return 'mdi-account-clock-outline';
      } else {
        return 'mdi-check-circle';
      }
    },
    showPurchaseUrl(companyName, billingUrl, role) {
      this.$bus.$emit('showDialogCompanyPurchaseUrl', {companyName, billingUrl, role});
    },
    showFormCreateCustomerPassword(userId) {
      this.$bus.$emit('showDialogFormCreateCustomerPassword', userId);
    },
    showDialogMeetingsConfirmation(userId, hasCompany) {
      this.$bus.$emit('showDialogMeetingsConfirmation', {userId, hasCompany});
    },
    unlock(userId, plannerId, companyId) {
      this.$bus.$emit('showDialogUnlockCustomer', {
        user: userId,
        planner: plannerId,
        company: companyId ? companyId : null
      });
    },
    showDialogSetupCalendly(user) {
      if (user.role === 'planner') {
        this.$bus.$emit('showDialogSetupCalendly', user);
      }
    },
    showDialogAcessPermission(user) {
      this.$bus.$emit('showDialogAcessPermission', user);
    },
    showDialogCustomer(user) {
      if (this.user.role === 'customer') {
        this.$bus.$emit('showDialogCustomer', user);
      }
    },
    getUsers(newSearch) {
      this.loader = true;

      if (newSearch) this.pagination.page = 1;

      getUsers(
        this.pagination.page,
        this.pagination.itemsPerPage,
        this.filter
      )
        .then(results => {
          this.pagination.totalItems = results.totalItems;
          this.pagination.totalPages = Math.ceil(
            results.totalItems / this.pagination.itemsPerPage
          );

          this.users = results.users;
          this.loader = false;
        })
        .catch(err => {
          console.error(err);
          this.loader = false;
        });
    },
    getRoleName(role) {
      switch (role) {
        case 'customer':
          return 'Cliente';
        case 'planner':
          return 'Planejador';
        case 'company':
          return 'Empresa'
        case 'support':
          return 'Suporte'
        case 'comercial':
          return 'Comercial'
        case 'supervisor':
          return 'Supervisor'
        default:
          return ''
      }
    },
    showDialogPasswordResetLink(user) {
      if (user.uid) {
        this.$bus.$emit('showDialogPasswordResetLink', {
          userId: user._id,
          email: user.email
        });
      } else {
        this.noPassWarning();
      }
    },
    showDialogFormUser(user) {
      if (user.role === 'company') {
        this.$bus.$emit('showFormCompany', user._id);
      } else if (user.role === 'planner') {
        this.$bus.$emit('showFormPlanner', user._id);
      } else if (user.role === 'customer') {
        if (user.uid) {
          this.$bus.$emit('showFormCustomer', user._id);
        } else {
          this.noPassWarning();
        }
      }
    },
    showDialogEmailUpdate(user) {
      if (user.uid) {
        this.$bus.$emit('showDialogEmailUpdate', user.email);
      } else {
        this.noPassWarning();
      }
    },
    showDialogUpdateUserStatus(user) {
      if (user.uid) {
        this.$bus.$emit('showDialogUpdateUserStatus', user);
      } else {
        this.noPassWarning();
      }
    },
    showDetails(user) {
      this.$store.dispatch('resetCustomer');

      if (user.role === 'planner') {
        this.$router.push(`/planner/${user._id}`);
      } else if (user.role === 'customer') {
        if (user.uid) {
          this.$router.push(`/customer/${user._id}`);
        } else {
          this.noPassWarning();
        }
      } else if (user.role === 'company') {
        this.$router.push(`/company/${user._id}`);
      }
    },
    showDialogUpdateUserPlan(user) {
      let data = {
        userId: user._id,
        userType: user.role,
        currentPlan: user.currentPlan.plan
      };

      this.$bus.$emit('showDialogUpdateUserPlan', data);
    },
    showDialogNotes(user) {
      this.$store.dispatch('setCustomer', user);

      this.$bus.$emit('showDialogNotes', {
        customerId: user._id,
        plannerId: user.planner ? (user.planner._id ? user.planner._id : user.planner) : ''
      });
    },
    showDialogCustomerActivity(userId) {
      this.$bus.$emit('showDialogCustomerActivity', userId);
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    noPassWarning() {
      this.$noty.warning('Usuário sem acesso. Peça ao cliente para criar uma senha por meio do link enviado em seu e-mail!');
    },
    showDialogCalendlyPlanner(planner) {
      if (!!planner) {
        this.$bus.$emit('showDialogCalendlyPlanner', planner);
      }
    },
    updateIuguSubscription(user) {

      this.$bus.$emit('showDialogUpdateUserIuguSubscription', {
        userId: user._id,
        name: user.displayName.split(/\s+/gm)[0],
        subscriptionId: user.iuguSubscription
      })
    }
  },
  watch: {
    'pagination.page'() {
      this.getUsers();
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    user() {
      return this.$store.getters.user;
    },
    planner() {
      return this.$store.getters.planner;
    }
  },
  mounted() {
    this.$bus.$on('managerGetUsers', filter => {
      if (filter) {
        this.filter = filter;

        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getUsers();
        }
      } else {
        this.getUsers();
      }
    });
  }
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .user-name {
    font-size: 12px;
  }
}
</style>
