<template>
  <v-card flat color="grey3">
    <v-card-title class="primary--text seguro-vida main-title">Capital existente</v-card-title>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-container grid-list-md class="pa-0">
          <v-layout row wrap>
            <v-flex xs12>
              <span class="white--text seguro-vida section-title">Previdências</span>
            </v-flex>

            <v-flex xs12 md6>
              <span class="seguro-vida input-label primary--text">Valor total</span>
              <v-text-field
                dense
                readonly
                color="grey1"
                :value="numberToCurrency(capitalExistente.previdencias.total)"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-cash
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12 md6>
              <span class="seguro-vida input-label primary--text">Observações</span>
              <v-text-field
                dense
                color="grey1"
                :error-messages="previdenciasObsErrors"
                v-model="capitalExistente.previdencias.obs"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <span class="white--text seguro-vida section-title">FGTS</span>
            </v-flex>

            <v-flex xs12 md6>
              <span class="seguro-vida input-label primary--text">Valor total</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                v-money="money"
                :error-messages="fgtsTotalErrors"
                v-model="capitalExistente.fgts.total"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-cash
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12 md6>
              <span class="seguro-vida input-label primary--text">Observações</span>
              <v-text-field
                dense
                color="grey1"
                :error-messages="fgtsObsErrors"
                v-model="capitalExistente.fgts.obs"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <span class="white--text seguro-vida section-title">Imposto de renda</span>
            </v-flex>

            <v-flex xs12 md6>
              <span class="seguro-vida input-label primary--text">Como é feita a declaração?</span>
              <v-select
                dense
                color="grey1"
                :items="irTypes"
                item-text="text"
                item-value="text"
                :error-messages="declaracaoIrTipoErrors"
                v-model="capitalExistente.declaracaoIr.tipo"
                @change="$v.capitalExistente.declaracaoIr.tipo.$touch()"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-file-document-outline
                </v-icon>
              </v-select>
            </v-flex>

            <v-flex xs12 md6>
              <span class="seguro-vida input-label primary--text">Observações</span>
              <v-text-field
                dense
                color="grey1"
                :error-messages="declaracaoIrObsErrors"
                v-model="capitalExistente.declaracaoIr.obs"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <span class="white--text seguro-vida section-title">Seguro de vida</span>
            </v-flex>

            <v-flex xs12 md6>
              <span class="seguro-vida input-label primary--text">Já possui seguro de vida?</span>
              <v-radio-group row v-model="capitalExistente.seguroVida.status">
                <v-radio
                  label="Não possuo"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Possuo"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="capitalExistente.seguroVida.status">
                <v-layout row wrap>
                  <v-flex xs12 md6 lg4>
                    <span class="seguro-vida input-label primary--text">Seguradora</span>
                    <v-text-field
                      dense
                      color="grey1"
                      :error-messages="seguroVidaSeguradoraErrors"
                      v-model="capitalExistente.seguroVida.seguradora"
                    >
                      <v-icon
                        color="grey1"
                        class="seguro-vida input-icon mr-1"
                        slot="prepend-inner"
                      >
                        mdi-security
                      </v-icon>
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 md6 lg4>
                    <span class="seguro-vida input-label primary--text">Valor</span>
                    <v-text-field
                      dense
                      type="tel"
                      color="grey1"
                      v-money="money"
                      :error-messages="seguroVidaValorErrors"
                      v-model="capitalExistente.seguroVida.valor"
                    >
                      <v-icon
                        color="grey1"
                        class="seguro-vida input-icon mr-1"
                        slot="prepend-inner"
                      >
                        mdi-cash
                      </v-icon>
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 md6 lg4>
                    <span class="seguro-vida input-label primary--text">Observações</span>
                    <v-text-field
                      dense
                      color="grey1"
                      :error-messages="seguroVidaObsErrors"
                      v-model="capitalExistente.seguroVida.obs"
                    >
                      <v-icon
                        color="grey1"
                        class="seguro-vida input-icon mr-1"
                        slot="prepend-inner"
                      >
                        mdi-pencil
                      </v-icon>
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-slide-x-transition>
          </v-layout>
        </v-container>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            type="submit"
            color="primary"
            class="seguro-vida darkgrey--text px-5"
            :loading="loader"
            :disabled="!allowed"
          >
            Salvar
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'
import { required } from 'vuelidate/lib/validators'
import removeMoneyMask from '@/utils/removeMoneyMask';
import numberToCurrency from '@/utils/numberToCurrency';
import updateLifeInsurace from '@/api/lifeInsurance/updateLifeInsurance';

export default {
  name: 'CardInsuranceCapitalExistente',
  props: ['isSpouse'],
  components: { money: VMoney },
  data: () => ({
    numberToCurrency,
    loader: false,
    capitalExistente: {
      previdencias: {
        total: 0,
        obs: ''
      },
      fgts: {
        total: 0,
        obs: ''
      },
      declaracaoIr: {
        tipo: '',
        obs: ''
      },
      seguroVida: {
        status: false,
        seguradora: '',
        valor: 0
      }
    },
    irTypes: [
      { text: 'Completa' },
      { text: 'Simples' },
      { text: 'Não declaro' }
    ],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    }
  }),
  validations: {
    capitalExistente: {
      previdencias: {
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
      fgts: {
        total: {
          isValid(v) {
            return !!v && removeMoneyMask(v) >= 0
          }
        },
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
      declaracaoIr: {
        tipo: {
          required
        },
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
      seguroVida: {
        seguradora: {
          isValid(v) {
            return !this.capitalExistente.seguroVida.status || !!v
          }
        },
        valor: {
          isValid(v) {
            return !this.capitalExistente.seguroVida.status || removeMoneyMask(v) > 0
          }
        },
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      }
    }
  },
  computed: {
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    },
    allowed() {
      return this.$store.getters.allowed;
    },
    previdenciasObsErrors() {
      const errors = []

      if (!this.$v.capitalExistente.previdencias.obs.$anyDirty) {
        return errors
      }

      !this.$v.capitalExistente.previdencias.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    fgtsTotalErrors() {
      const errors = []

      if (!this.$v.capitalExistente.fgts.total.$anyDirty) {
        return errors
      }

      !this.$v.capitalExistente.fgts.total.isValid && errors.push('Preencha um valor válido')

      return errors
    },
    fgtsObsErrors() {
      const errors = []

      if (!this.$v.capitalExistente.fgts.obs.$anyDirty) {
        return errors
      }

      !this.$v.capitalExistente.fgts.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    declaracaoIrTipoErrors() {
      const errors = []

      if (!this.$v.capitalExistente.declaracaoIr.tipo.$dirty) {
        return errors
      }

      !this.$v.capitalExistente.declaracaoIr.tipo.required && errors.push('Selecione o tipo de declaração')

      return errors
    },
    declaracaoIrObsErrors() {
      const errors = []

      if (!this.$v.capitalExistente.declaracaoIr.obs.$anyDirty) {
        return errors
      }

      !this.$v.capitalExistente.declaracaoIr.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    seguroVidaSeguradoraErrors() {
      const errors = []

      if (!this.$v.capitalExistente.seguroVida.seguradora.$anyDirty) {
        return errors
      }

      !this.$v.capitalExistente.seguroVida.seguradora.isValid && errors.push('Preencha uma seguradora válida')

      return errors
    },
    seguroVidaValorErrors() {
      const errors = []

      if (!this.$v.capitalExistente.seguroVida.valor.$anyDirty) {
        return errors
      }

      !this.$v.capitalExistente.seguroVida.valor.isValid && errors.push('Preencha um valor válido')

      return errors
    },
    seguroVidaObsErrors() {
      const errors = []

      if (!this.$v.capitalExistente.seguroVida.obs.$anyDirty) {
        return errors
      }

      !this.$v.capitalExistente.seguroVida.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
  },
  methods: {
    copyData() {
      if (this.lifeInsurance && this.lifeInsurance.capitalExistente) {
        if (this.lifeInsurance.capitalExistente.previdencias) {
          this.capitalExistente.previdencias = JSON.parse(JSON.stringify(this.lifeInsurance.capitalExistente.previdencias))
        }

        if (this.lifeInsurance.capitalExistente.fgts) {
          this.capitalExistente.fgts = JSON.parse(JSON.stringify(this.lifeInsurance.capitalExistente.fgts))
        }

        if (this.lifeInsurance.capitalExistente.declaracaoIr) {
          this.capitalExistente.declaracaoIr = JSON.parse(JSON.stringify(this.lifeInsurance.capitalExistente.declaracaoIr))
        }

        if (this.lifeInsurance.capitalExistente.seguroVida) {
          this.capitalExistente.seguroVida = JSON.parse(JSON.stringify(this.lifeInsurance.capitalExistente.seguroVida))
        }

        this.capitalExistente.fgts.total = this.capitalExistente.fgts.total.toFixed(2)

        if (this.capitalExistente.seguroVida.status) {
          this.capitalExistente.seguroVida.valor = this.capitalExistente.seguroVida.valor.toFixed(2)
        }

        this.resetMoneyInput()
      }
    },
    resetMoneyInput() {},
    submit() {
      if (this.allowed) {
        this.$v.$touch()

        if (!this.capitalExistente.seguroVida.status) {
          this.capitalExistente.seguroVida.seguradora = ''
          this.capitalExistente.seguroVida.valor = 0
          this.$v.capitalExistente.seguroVida.$reset()
        }

        if (!this.$v.$anyError) {
          this.loader = true

          const capitalExistente = JSON.parse(JSON.stringify(this.capitalExistente))

          capitalExistente.fgts.total = removeMoneyMask(capitalExistente.fgts.total)

          if (capitalExistente.seguroVida.status) {
            capitalExistente.seguroVida.valor = removeMoneyMask(capitalExistente.seguroVida.valor)
          } else {
            capitalExistente.seguroVida.obs = ''
            capitalExistente.seguroVida.valor = 0
          }

          updateLifeInsurace(this.lifeInsurance._id, { capitalExistente })
            .then(lifeInsurance => {
              this.$store.dispatch('saveLifeInsurance', lifeInsurance)
              this.loader = false
              this.$noty.success('Dados salvos com sucesso!')
            })
            .catch(err => {
              this.loader = false
              this.$noty.error('Desculpe! Não foi possível salvar seus dados. Tente novamente mais tarde!');

              if (process.env.NODE_ENV  !== 'production') {
                console.error(err)
              }
            })
        }
      }
    }
  },
  watch: {
    lifeInsurance: {
      deep: true,
      handler() {
        this.copyData();
      }
    }
  },
  created() {
    this.copyData();
  }
}
</script>

<style scoped></style>
