<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    max-width="600px"
    :fullscreen="isMobile"
  >
    <v-card v-if="item" :class="isMobile ? 'no-radius' : ''" color="grey2">
      <v-card-title class="dialog-card-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="grey1"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="grey1--text ml-4">
          {{ title }}
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md class="px-0">
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <span class="input-label primary--text">Sub-categoria</span>
                <v-text-field
                  color="grey1"
                  type="text"
                  class="mt-0 pt-0"
                  :value="item.subCategory.name"
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6>
                <span class="input-label primary--text">Categoria</span>
                <v-text-field
                  color="grey1"
                  type="text"
                  :value="item.category.name"
                  readonly
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-flex>

              <v-flex class="mb-2" xs12 sm6 v-if="recomendacao">
                <span class="input-label primary--text">Consumo médio mensal</span>
                <h3 class="white--text">{{ numberToCurrency(recomendacao.limiteMedia) }}</h3>
              </v-flex>

              <v-flex class="mb-2" xs12 sm6 v-if="recomendacao">
                <span class="input-label primary--text">Limite máximo recomendado</span>
                <h3 class="white--text">{{ numberToCurrency(recomendacao.limiteMaximo) }}</h3>
              </v-flex>

              <v-flex xs12>
                <div class="d-flex align-center">
                  <v-checkbox
                    color="primary"
                    v-model="item.limiteFixo"
                  ></v-checkbox>
                  <span
                    class="input-label primary--text"
                    @click="item.limiteFixo = !item.limiteFixo"
                    style="cursor:pointer;"
                  >
                    Quero definir um limite fixo
                  </span>
                </div>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Meu limite</span>
                <v-text-field
                  :color="limitColor"
                  type="tel"
                  class="mt-0 pt-0"
                  v-money="money"
                  id="input-limite-value"
                  v-model="item.limite"
                  :error-messages="limiteErrors"
                  @input="$v.item.limite.$touch()"
                  :disabled="!item.limiteFixo"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 class="mt-5">
                <h3 class="grey1--text">Alertas</h3>
              </v-flex>

              <v-flex xs6>
                <span class="input-label primary--text">Primeiro alerta</span>
                <v-text-field
                  color="grey1"
                  type="number"
                  class="mt-0 pt-0"
                  suffix="%"
                  v-model.number="item.firstWarning.percent"
                  :error-messages="firstWarningErrors"
                  @input="$v.item.firstWarning.percent.$touch()"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <span class="input-label primary--text">Ao gastar</span>
                <v-text-field
                  color="grey1"
                  readonly
                  class="mt-0 pt-0"
                  :value="firstAlertValue"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <span class="input-label primary--text">Segundo alerta</span>
                <v-text-field
                  color="grey1"
                  type="number"
                  suffix="%"
                  class="mt-0 pt-0"
                  v-model.number="item.secondWarning.percent"
                  :error-messages="secondWarningErrors"
                  @input="$v.item.secondWarning.percent.$touch()"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <span class="input-label primary--text">Ao gastar</span>
                <v-text-field
                  color="grey1"
                  readonly
                  class="mt-0 pt-0"
                  :value="secondAlertValue"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <span class="input-label primary--text">Terceiro alerta</span>
                <v-text-field
                  color="grey1"
                  type="number"
                  suffix="%"
                  class="mt-0 pt-0"
                  v-model.number="item.thirdWarning.percent"
                  :error-messages="thirdWarningErrors"
                  @input="$v.item.thirdWarning.percent.$touch()"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <span class="input-label primary--text">Ao gastar</span>
                <v-text-field
                  color="grey1"
                  readonly
                  class="mt-0 pt-0"
                  :value="thirdAlertValue"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              small
              rounded
              type="submit"
              color="primary"
              class="darkgrey--text px-5"
              :loading="loader"
            >
              Salvar
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { VMoney } from 'v-money'
  import { required } from 'vuelidate/lib/validators'
  import removeMoneyMask from "../../../../utils/removeMoneyMask";
  import numberToCurrency from "../../../../utils/numberToCurrency";
  import updateItemOrcamento from "../../../../api/orcamento/updateItemOrcamento";

  const isZero = v => removeMoneyMask(v) > 0;

  export default {
    name: 'DialogFormItemOrcamento',
    directives: { money: VMoney },
    data: () => ({
      dialog: false,
      loader: false,
      item: null,
      numberToCurrency,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
      innerWidth: 0
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === `customer`;
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
      limitColor() {
        if (this.item && this.recomendacao) {
          let limite = removeMoneyMask(this.item.limite);

          if (limite <= this.recomendacao.limiteMedia)
            return 'success';
          else if (limite <= this.recomendacao.limiteMaximo)
            return 'warning';
          else
            return 'error';
        }
      },
      firstAlertValue() {
        if (this.item) {
          let limite = removeMoneyMask(this.item.limite);
          return numberToCurrency(limite * (this.item.firstWarning.percent / 100));
        }
      },
      secondAlertValue() {
        if (this.item) {
          let limite = removeMoneyMask(this.item.limite);
          return numberToCurrency(limite * (this.item.secondWarning.percent / 100));
        }
      },
      thirdAlertValue() {
        if (this.item) {
          let limite = removeMoneyMask(this.item.limite);
          return numberToCurrency(limite * (this.item.thirdWarning.percent / 100));
        }
      },
      title() {
        if (this.item) {
          let title = this.item.subCategory.name + ' - ' + this.item.category.name;

          let charLimit = 19;

          if (this.innerWidth >= 375)
            charLimit = 25;

          if (this.innerWidth >= 414)
            charLimit = 30;

          if (this.innerWidth >= 600)
            charLimit = 2000;

          if (title.length > charLimit)
            title = title.slice(0,charLimit) + '...';

          return title;
        }
      },
      orcamento() {
        return this.$store.getters.orcamento;
      },
      recomendacao() {
        if (this.item) {
          return this.orcamento.recomendacao.items.find(rec => {
            return rec.category === this.item.category._id && rec.subCategory === this.item.subCategory._id;
          });
        }
      },
      limiteErrors() {
        const errors = [];
        if (!this.$v.item.limite.$dirty) return errors;
        !this.$v.item.limite.required && errors.push('Insira o limite desejado');
        !this.$v.item.limite.isZero && errors.push('Insira um valor maior que zero');
        return errors;
      },
      firstWarningErrors() {
        const errors = [];
        if (!this.$v.item.firstWarning.percent.$dirty) return errors;
        !this.$v.item.firstWarning.percent.required && errors.push('Insira a porcentagem desejada');
        !this.$v.item.firstWarning.percent.isZero && errors.push('Insira um valor maior que zero');
        return errors;
      },
      secondWarningErrors() {
        const errors = [];
        if (!this.$v.item.secondWarning.percent.$dirty) return errors;
        !this.$v.item.secondWarning.percent.required && errors.push('Insira a porcentagem desejada');
        !this.$v.item.secondWarning.percent.isZero && errors.push('Insira um valor maior que zero');
        return errors;
      },
      thirdWarningErrors() {
        const errors = [];
        if (!this.$v.item.thirdWarning.percent.$dirty) return errors;
        !this.$v.item.thirdWarning.percent.required && errors.push('Insira a porcentagem desejada');
        !this.$v.item.thirdWarning.percent.isZero && errors.push('Insira um valor maior que zero');
        return errors;
      }
    },
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.item = null;
        this.$v.$reset();
      },
      submit() {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          let item = JSON.parse(JSON.stringify(this.item));

          item.limite = removeMoneyMask(item.limite);
          item.firstWarning.value = removeMoneyMask(this.firstAlertValue);
          item.secondWarning.value = removeMoneyMask(this.secondAlertValue);
          item.thirdWarning.value = removeMoneyMask(this.thirdAlertValue);
          item.recorrente = false

          updateItemOrcamento(this.orcamento._id, item)
            .then(orcamento => {
              this.$store.dispatch('setOrcamento', orcamento);
              this.close();
              this.$noty.success('Item salvo com sucesso!')
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível salvar este item. Tente novamente!');
              if (process.env.NODE_ENV !== 'production') console.error(err);
            });
        }
      }
    },
    validations: {
      item: {
        limite: { required, isZero },
        firstWarning: {
          percent: { required, isZero }
        },
        secondWarning: {
          percent: { required, isZero }
        },
        thirdWarning: {
          percent: { required, isZero }
        }
      }
    },
    watch: {
      'item.limiteFixo'() {
        if (this.item && !this.item.limiteFixo && this.recomendacao) {
          this.item.limite = this.recomendacao.limiteMaximo.toFixed(2)
          let input = document.getElementById('input-limite-value');
          if (input) {
            input.value = this.item.limite;
            input.dispatchEvent(new Event('input'));
          }
        }
      }
    },
    created() {
      this.$bus.$on('showDialogFormItemOrcamento', data => {
        if (data.item && data.orcamentoId) {
          this.innerWidth = window.innerWidth;
          this.item = JSON.parse(JSON.stringify(data.item));
          this.item.limite = this.item.limite.toFixed(2);
          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
  .dialog-card-title {
    background-color: #131313 !important;
  }
</style>
