<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4">
          {{ renda._id ? 'Editar' : 'Adicionar' }} renda
        </span>
      </v-card-title>

      <v-card-text>
        <div class="pt-5">
          <h3 class="input-label primary--text">Data</h3>
          <div class="d-flex align-end">
            <v-autocomplete
              dense
              hint="Ano"
              color="grey1"
              :items="years"
              v-model="year"
              persistent-hint
              class="mr-1"
            ></v-autocomplete>

            <v-select
              dense
              hint="Mês"
              color="grey1"
              :items="months"
              v-model="month"
              persistent-hint
              item-text="sigla"
              item-value="numero"
            ></v-select>
          </div>
        </div>

        <div class="pt-5">
          <h3 class="input-label primary--text">Valor</h3>
          <v-text-field
            dense
            type="tel"
            color="grey1"
            v-money="money"
            v-model="renda.valor"
            id="input-valor-renda"
            :error-messages="valorErrors"
          ></v-text-field>
        </div>

        <v-card-actions class="px-0 pb-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            small
            color="primary"
            @click="submit"
            :loading="loader"
            class="darkgrey--text"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {VMoney} from 'v-money'
  import moment from 'moment-timezone'
  import removeMoneyMask from "../../../../utils/removeMoneyMask";
  import saveRendaIndFinanceira from "../../../../api/indFinanceira/saveRendaIndFinanceira";

  const isZero = v => removeMoneyMask(v) > 0;

  export default {
    name: 'DialogFormRendaIndFinanceira',
    directives: { money: VMoney },
    data: () => ({
      dialog: false,
      loader: false,
      renda: { data: '', valor: '' },
      years: [],
      year: '',
      month: '',
      initialDate: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
    }),
    computed: {
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      months() {
        return this.$store.getters.months;
      },
      valorErrors() {
        const errors = [];
        if (!this.$v.renda.valor.$dirty) return errors;
        !this.$v.renda.valor.isZero && errors.push('Insira um valor maior do que zero');
        return errors;
      }
    },
    methods: {
      resetMoneyInput() {
        let input = document.getElementById('input-valor-renda');
        if (input) {
          input.value = this.renda.valor;
          input.dispatchEvent(new Event('input'));
        }
      },
      close() {
        this.dialog = false;
        this.loader = false;
        this.renda = { valor: 0, data: '' };

        this.resetMoneyInput();

        this.$v.$reset();
      },
      submit() {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          let renda = JSON.parse(JSON.stringify(this.renda));
          renda.valor = removeMoneyMask(renda.valor);

          let month = this.month;
          if (month < 10)
            month = `0${month}`;

          renda.data = `${this.year}-${month}-01`;

          saveRendaIndFinanceira(this.indFinanceira._id, renda)
            .then(indFinanceira => {
              this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
              this.close();
              this.$noty.success('Renda salva com sucesso!')
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível salvar esta renda. Tente novamente!');
              if (process.env.NODE_ENV !== 'production') console.error(err);
            });
        }
      },
      setYears() {
        this.years = [];
        let startYear = parseInt(this.initialDate.format('YYYY-MM-DD').split('-')[0]);
        let diff = (this.indFinanceira.premissas.idadeExpectativaVida - this.indFinanceira.premissas.idadeIndependencia);
        let endYear = this.initialDate.add(diff, 'years').year();

        for (let i = startYear; i < endYear; i++) {
          this.years.push(i);
        }
      }
    },
    validations: {
      renda: {
        valor: { isZero }
      }
    },
    created() {
      this.$bus.$on('showDialogFormRendaIndFinanceira', renda => {
        this.initialDate = moment().tz('America/Sao_Paulo').add(this.indFinanceira.premissas.anosProducao, 'years');

        if (renda) {
          this.renda = JSON.parse(JSON.stringify(renda));
          this.renda.valor = this.renda.valor.toFixed(2);

          this.resetMoneyInput();

          let [year, month] = this.renda.data.split('-');

          this.year = parseInt(year);
          this.month = parseInt(month);
        } else {
          let [year, month] = this.initialDate.format('YYYY-MM-DD').split('-');

          this.year = parseInt(year);
          this.month = parseInt(month);
        }

        this.setYears();
        this.dialog = true;
      });
    }
  }
</script>
