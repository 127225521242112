import config from "@/config";
import store from "@/store";
import eventBus from '@/plugins/eventBus'
import syncPluggyItem from "@/api/pluggy/syncPluggyItem";
import getPluggyConnectToken from "@/api/pluggy/getPluggyConnectToken";
import getPluggyItemById from "@/api/pluggyItems/getPluggyItemById";

export default {
  loadScript: () => {
    const scriptContainerId = 'pluggy-container';
    let scriptContainer = document.getElementById(scriptContainerId);

    if (!scriptContainer) {
      scriptContainer = document.createElement('script');

      scriptContainer.async = true;
      scriptContainer.type = 'text/javascript';
      scriptContainer.setAttribute('id', scriptContainerId);
      scriptContainer.setAttribute('src', config.pluggy.script);

      document.body.appendChild(scriptContainer);
    }
  },
  onSuccess: ({item}) => {
    try {
      getPluggyItemById(item.id)
        .then(savedItem => {
          if (!savedItem) {
            syncPluggyItem(item.id, {userId: store.getters.user._id})
              .then(({bankAccounts, creditCards, transactions, isRepeated}) => {
                if (!isRepeated) {
                  if (creditCards)
                    creditCards.forEach(cc => store.dispatch('saveCreditCard', cc))

                  if (bankAccounts)
                    bankAccounts.forEach(ba => store.dispatch('saveBankAccount', ba))

                  if (transactions)
                    transactions.forEach(tr => store.dispatch('saveTransaction', tr))

                  dispatchEvent(new Event("PLUGGY_CONNECTION_COMPLETED"))
                }
              })
              .catch(err => {
                if (process.env.NODE_ENV !== 'production') {
                  console.error(err)
                }
              })
          }
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }
        })
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(err)
      }
    }
  },
  useAutomatic: async (itemId, onSuccess) => {
    const accessToken = await getPluggyConnectToken(itemId)

    const pluggyConnect = new PluggyConnect({
      // onEvent: payload => {
      //   dispatchEvent(new CustomEvent(payload.event, {detail: {item: payload.item}}))
      // },
      onSuccess,
      theme: 'dark',
      includeSandbox: false,
      connectToken: accessToken,
      updateItem: itemId,
      onError: err => console.error(err),
    });

    pluggyConnect.init()
  }
}
