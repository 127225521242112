<template>
  <v-card raised color="grey2">
    <v-card-text>
      <div class="text-right hidden-sm-and-down">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon color="grey1" dark v-on="on">mdi-information-outline</v-icon>
          </template>
          <span class="tooltip-content">Total de usuários registrados.</span>
        </v-tooltip>
      </div>

      <div class="total-container">
        <span class="total-number white--text">{{ totalUsers }}</span>
        <span class="total-desc grey1--text">Usuários Registrados</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import countUsers from "../../../api/users/countUsers";

  export default {
    name: 'CardTotalUsers',
    data: () => ({
      totalUsers: 0
    }),
    computed: {
      isPlanner() {
        return this.$store.getters.user && this.$store.getters.user.role === 'planner';
      },
      user() {
        return this.isPlanner ? this.$store.getters.user : this.$store.getters.planner;
      },
    },
    methods: {
      countUsers() {
        let filter = {};

        if (this.user.role === 'planner' || this.user.role === 'company') {
          filter[this.user.role] = this.user._id;
        }

        countUsers(filter)
          .then(resp => {
            this.totalUsers = resp.count;
          })
          .catch(err => {
            this.totalUsers = 0;
            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          });
      }
    },
    created() {
      this.countUsers();
    }
  }
</script>

<style scoped>
  .total-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
    padding-top: 5px;
  }

  .total-number {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  }

  .total-desc {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
    letter-spacing: 0.05em;
    line-height: 1.5em;
  }

  .tooltip-content {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  @media only screen and (max-width: 959px) {
    .v-card__text {
      padding: 10px 5px 5px 5px !important;
    }
  }

  @media only screen and (max-width: 599px) {
    .total-container {
      padding-top: 0;
    }

    .total-number {
      margin-bottom: 0;
      font-size: 25px;
    }

    .total-desc {
      margin-top: 5px;
    }
  }

  @media only screen and (max-width: 507px) {
    .total-desc {
      letter-spacing: auto;
      font-size: 8px;
      text-align: center;
    }
  }
</style>
