<template>
  <v-container grid-list-md class="pa-0 mt-5">
    <v-layout row wrap>
      <v-flex xs6 sm6 md5 offset-md1 lg4 offset-lg2>
        <ListTransactionsTotal
          icon="mdi-wallet"
          :label="verifyPeriod"
          :total="saldoConta"
        />
      </v-flex>

      <v-flex xs6 sm6 md5 lg4>
        <ListTransactionsTotal
          icon="mdi-clipboard-outline"
          label="Balanço Mensal"
          :total="balancoMensal"
        />
      </v-flex>

      <v-flex xs12 v-if="isMobile">
        <v-divider></v-divider>
      </v-flex>

      <div class="lists-container" v-if="transactionsDates.length">
        <div
          :key="date"
          class="transactions-list"
          v-for="(date, i) in transactionsDates"
        >
          <ListTransactionsSubheader
            :date="date"
            :show-balance="showDailyBalance"
            :balance="balanceByDate[date][0].total"
          />

          <swipe-list
            class="px-4"
            :ref="'list-' + date"
            :items="groupedTransactions[date]"
          >
            <template v-slot="{ item, index, revealLeft, revealRight, close }">
              <div class="transaction pb-4 pt-2 animated fadeInLeft transition-fast-out-slow-in">
                <ListTransactionAvatar
                  @click="showForm(item)"
                  :loading="loading"
                  :transaction="item"
                />

                <div
                  @click="showForm(item)"
                  class="transaction-content"
                >
                  <ListTransactionContent
                    :resize="resize"
                    :transaction="item"
                  />
                </div>

                <v-menu bottom left close-on-click v-if="!isMobile">
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" class="btn-menu" :disabled="!allowed">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list v-if="!item.invoice">
                    <v-list-item @click="showForm(item)">
                      <v-list-item-icon>
                        <v-icon color="white">mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="consolidateTransaction(item)" v-if="!item.pluggy && !item.ofx">
                      <v-list-item-icon>
                        <v-icon color="error" v-if="item.consolidated">
                          mdi-close-circle-outline
                        </v-icon>
                        <v-icon color="success" v-else :loading="consolidateLoader">
                          mdi-check-circle-outline
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-title v-if="!item.consolidated">
                        {{ item.type === 'entrada' ? 'Receber' : (item.investment ? 'Investir' : 'Pagar') }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{
                          item.type === 'entrada' ? 'Cancelar recebimento' :
                            (item.investment ? 'Cancelar investimento' : 'Cancelar pagamento')
                        }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="remove(item)" v-if="!item.pluggy">
                      <v-list-item-icon>
                        <v-icon color="error">mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>

                  <v-list v-else>
                    <v-list-item @click="showDialogCustomerInvoiceDetails(item.creditCard._id, item.period)">
                      <v-list-item-icon>
                        <v-icon color="white">mdi-eye-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Ver fatura</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>

            <template v-slot:right="{ item, close }">
              <div class="swipeout-action" v-if="item.invoice">
                <v-btn
                  icon
                  large
                  @click="showDialogCustomerInvoiceDetails(item.creditCard._id, item.period)"
                >
                  <v-icon color="white">mdi-eye-outline</v-icon>
                </v-btn>
              </div>

              <div class="swipeout-action" v-if="!item.invoice && !item.pluggy">
                <v-btn icon large @click="close(); remove(item);" :disabled="!allowed">
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>

              <div class="swipeout-action" v-if="!item.invoice && !item.pluggy">
                <v-btn
                  icon
                  large
                  :loading="consolidateLoader"
                  :disabled="consolidateLoader || !allowed"
                  @click="consolidateTransaction(item)"
                >
                  <v-icon color="error" v-if="item.consolidated">
                    mdi-close-circle-outline
                  </v-icon>
                  <v-icon color="success" v-else :loading="consolidateLoader">
                    mdi-check-circle-outline
                  </v-icon>
                </v-btn>
              </div>

              <div class="swipeout-action" v-if="!item.invoice">
                <v-btn icon large @click="close(); showForm(item);" :disabled="!allowed">
                  <v-icon color="white">mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </swipe-list>

          <v-divider
            color="#707070"
            class="px-5 mt-5"
            v-if="transactionsDates[i+1]"
          ></v-divider>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import {groupBy, orderBy, sumBy, uniqBy, truncate} from 'lodash';
import {SwipeList} from 'vue-swipe-actions';

import currency from '../../../../utils/currency';
import updateTransactionStatus from '../../../../api/transactions/updateTransactionStatus';
import calcBalanceProjectionFromPeriod
  from '@/components/Customer/Cashflow/lists/helpers/calcBalanceProjectionFromPeriod';
import stringCapitalize from "@/utils/stringCapitalize";
import ListTransactionsTotal from "@/components/Customer/Cashflow/lists/cards/ListTransactionsTotal";
import ListTransactionsSubheader from "@/components/Customer/Cashflow/lists/subheaders/ListTransactionsSubheader";
import ListTransactionAvatar from "@/components/Customer/Cashflow/lists/avatar/ListTransactionAvatar";
import ListTransactionContent from "@/components/Customer/Cashflow/lists/content/ListTransactionContent";

export default {
  name: 'ListTransactions',
  props: [
    'type',
    'period',
    'selectedBankAccounts',
    'status',
    'archived',
    'categories',
    'subcategories',
    'filterAplly'
  ],
  components: {
    ListTransactionContent,
    ListTransactionAvatar, ListTransactionsSubheader, ListTransactionsTotal, SwipeList},
  data: () => ({
    currency,
    today: '',
    currentDate: null,
    currentYear: null,
    currentMonth: null,
    consolidateLoader: false,
    loading: '',
    lastMonthBalance: 0,
    resize: ''
  }),
  computed: {
    invoices() {
      const invoices = uniqBy(
        this.transactions
          .filter(tr => {
            return (
              tr.invoice &&
              tr.creditCard &&
              (!tr.creditCard.archived || tr.invoice.paid) &&
              (
                (tr.invoice.paid && tr.invoice.paidIn && tr.invoice.paidIn.slice(0, 7) === this.period) ||
                (!tr.invoice.paid && tr.invoice.paymentDate && tr.invoice.paymentDate.slice(0, 7) === this.period)
              )
            )
          })
          .map(tr => {
            return {...{...tr.invoice, ...{creditCard: tr.creditCard}}, ...{bankAccount: tr.bankAccount}}
          }),
        '_id'
      )

      return invoices.filter(invoice => {
        if (
          (this.type.value === 'all' || this.type.value === 'saida') &&
          !this.categories.length && !this.subcategories.length &&
          (this.status === 'all' || invoice.paid === this.status)
        ) {
          if (!this.archived) {
            if (invoice.creditCard.archived && invoice.paid && !this.isFuture) {
              return !this.selectedBankAccounts.length || (invoice.bankAccount && this.selectedBankAccounts.includes(invoice.bankAccount._id))
            } else {
              return !this.selectedBankAccounts.length || (invoice.bankAccount && this.selectedBankAccounts.includes(invoice.bankAccount._id))
            }
          } else {
            return invoice.bankAccount && invoice.bankAccount.archived
          }
        }
      })
    },
    subCategories() {
      return this.$store.getters.subCategory;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isFuture() {
      return this.period > this.today.slice(0, 7);
    },
    saldoConta() {
      let saldo = 0;

      if (this.selectedBankAccounts.length || this.period <= this.today.slice(0, 7)) {
        const currentPeriod = this.today.slice(0, 7)
        let [year, month] = this.period.split('-').map(num => parseInt(num))

        const bankAccounts = this.bankAccounts.filter(ba => {
          if (
            (this.archived === '' || ba.archived === this.archived) &&
            (!this.selectedBankAccounts.length || this.selectedBankAccounts.includes(ba._id))
          ) {
            let [baYear, baMonth] = ba.initialAmountDate.split('-').map(num => parseInt(num))
            return (year > baYear || (year === baYear && month >= baMonth));
          }
        })

        bankAccounts.forEach(ba => {
          let historyItem = ba.history.find(h => parseInt(h.year) === year && parseInt(h.month) === month);

          if (!historyItem) {
            if (currentPeriod >= this.period) {
              historyItem = ba.history[0]
            } else if (currentPeriod < this.period) {
              historyItem = ba.history[ba.history.length - 1]
            }
          }

          if (historyItem) {
            saldo += historyItem.balance
          }
        })

        if (this.today.slice(0, 7) < this.period) {
          saldo += sumBy(this.invoiceTransactions.filter(itr => !itr.consolidated), itr => itr.value * -1)
        }


      } else {
        const dates = Object.keys(this.balanceByDate)

        if (dates.length) {
          saldo = this.balanceByDate[dates[dates.length - 1]][0].total
        }
      }

      return saldo;
    },
    balancoMensal() {
      let balanco = 0;

      this.filteredTransactions.forEach(t => {
        if (t.type === 'entrada') {
          balanco += t.value;
        } else {
          balanco -= t.value;
        }
      });
      balanco = parseFloat(balanco.toFixed(2));
      return balanco;
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts;
    },
    transactions() {
      return this.$store.getters.transactions;
    },
    bankAccountTransactions() {
      return this.transactions.filter(tr => {
        if (tr.bankAccount && !tr.invoice && tr.date.slice(0, 7) === this.period) {
          return (
            (!this.selectedBankAccounts.length || this.selectedBankAccounts.includes(tr.bankAccount._id)) &&
            (this.archived === '' || tr.bankAccount.archived === this.archived) &&
            (this.status === 'all' || this.status === tr.consolidated) &&
            (!this.categories.length || (tr.category && this.categories.includes(tr.category._id))) &&
            (!this.subcategories.length || (tr.subCategory && this.subcategories.includes(tr.subCategory._id)))
          )
        }
      })
    },
    categoriesTransactions() {
      return this.bankAccountTransactions.filter(t => this.categories.includes(t.category._id) || !this.categories.length);
    },
    subCategoriesTransactions() {
      return this.categoriesTransactions.filter((t) => {
        if (t.subCategory) {
          if (this.subcategories.includes(t.subCategory._id) || !this.subcategories.length) return t;
        }
      });
    },
    invoiceTransactions() {
      return this.invoices.map(invoice => {
        return {
          invoice: invoice._id,
          isInvoice: true,
          value: invoice.value,
          date: invoice.paid ? invoice.paidIn : invoice.paymentDate,
          bankAccount: invoice.bankAccount,
          creditCard: invoice.creditCard,
          category: {
            name: invoice.creditCard.name,
            icon: 'mdi-credit-card',
            color: invoice.creditCard.color
          },
          subCategory: {
            name: `Fatura ${invoice.paymentDate.slice(0, 7).split('-').reverse().join('/')}`
          },
          consolidated: invoice.paid,
          period: invoice.paymentDate.slice(0, 7),
          type: 'saida'
        }
      })
    },
    filteredTransactions() {
      let transactions = this.bankAccountTransactions.filter(t => {
        if (!t.isInvoicePayment && !t.isInvoiceRefund) {
          if (this.type.value === 'entrada' || this.type.value === 'saida') {
            return t.type === this.type.value && !t.isTransfer && !t.investment;
          } else if (this.type.value === 'investment') {
            return t.investment;
          } else if (this.type.value === 'isTransfer') {
            return t.isTransfer;
          } else if (this.type.value === 'all') {
            return t;
          }
        }
      });

      transactions = transactions.concat(this.invoiceTransactions)
      return orderBy(transactions, ['subCategory.name']);
    },
    groupedTransactions() {
      return groupBy(orderBy(this.filteredTransactions, 'date'), 'date');
    },
    transactionsDates() {
      return orderBy(Object.keys(this.groupedTransactions), [], ['desc']);
    },
    periodNumbers() {
      if (this.period) {
        let [year, month] = this.period.split('-');

        return {
          year: parseInt(year),
          month: parseInt(month)
        };
      }
    },
    allowed() {
      return this.$store.getters.allowed;
    },
    verifyPeriod() {
      if (this.$date(this.period).isAfter(this.$date(), 'month')) {
        return 'Saldo Projetado';
      } else if (this.$date(this.period).isSame(this.$date(), 'month')) {
        return 'Saldo em Conta';
      }

      return 'Saldo';
    },
    validBankAccounts() {
      return this.$store.getters.bankAccounts.filter(({_id, archived}) => {
        return (
          (this.archived === '' || archived === this.archived) &&
          (!this.selectedBankAccounts.length || this.selectedBankAccounts.includes(_id))
        )
      })
    },
    initialBalance() {
      let total = 0;
      let [year, month] = this.period.split('-');

      month = Number.parseInt(month) - 1;

      if (month < 1) {
        month = 12;
        year = Number.parseInt(year) - 1;
      }

      if (this.validBankAccounts.length) {
        for (let i = 0; i < this.validBankAccounts.length; i++) {
          const ba = this.validBankAccounts[i];
          let historyItem = ba.history.find(h => h.year == year && h.month == month);

          if (historyItem && historyItem.balance) {
            total = Number.parseFloat((total + historyItem.balance).toFixed(2));
          } else if (ba.initialAmountDate.slice(0, 7) === this.period) {
            total = Number.parseFloat((total + ba.initialAmount).toFixed(2));
          } else {
            historyItem = ba.history[ba.history.length - 1];

            if (historyItem.year < year || (historyItem.year == year && historyItem.month < month)) {
              total = Number.parseFloat((total + historyItem.balance).toFixed(2));
            }
          }
        }
      }

      const currentDate = this.$date();
      const currentPeriod = currentDate.format('YYYY-MM');
      const isFuture = this.period > currentPeriod;

      if (isFuture && this.status === 'all') {
        const previousPeriod = this.$date(this.period).subtract(1, 'month').format('YYYY-MM');
        const previousPeriodProjectionValue = this.calcLastMonthDailyBalance(previousPeriod);
        total = parseFloat((total + previousPeriodProjectionValue).toFixed(2));
      }

      return Number.parseFloat(total.toFixed(2));
    },
    balanceByDate() {
      const balances = [];

      Object.keys(this.groupedTransactions).forEach((date, i) => {
        let balance = {
          date,
          total: balances[i - 1] ? balances[i - 1].total : this.initialBalance
        };

        this.groupedTransactions[date].forEach(t => {
          let isFutureDate = this.isFutureDate(date);

          if (t.isInvoice || this.validBankAccounts.find(b => t.bankAccount && b._id === t.bankAccount._id)) {
            if ((isFutureDate && !t.consolidated) || t.consolidated) {
              if (t.type === 'saida' || t.isInvoice) {
                balance.total -= t.value;
              } else {
                balance.total += t.value;
              }
            }
          }
        });

        balance.total = parseFloat(balance.total.toFixed(2));

        balances.push(balance);
      });
      return groupBy(balances, 'date');
    },
    showDailyBalance() {
      return this.type.value === 'all' && this.status && !this.subCategories && (!this.categories || !this.categories.length);
    }
  },
  methods: {
    truncate(string, length) {
      return truncate(string, {length: length || 35})
    },
    getTransactionDescription(transaction) {
      let description = ''

      if (transaction.subCategory && transaction.subCategory.name) {
        description = this.truncate(transaction.subCategory.name)
      } else if (transaction.description) {
        description =  this.truncate(transaction.description)
      } else if (transaction.category && transaction.category.name) {
        description =  this.truncate(transaction.category.name)
      }

      if (transaction.divided) {
        description += `${transaction.dividedPart}/${transaction.dividedParts}`
      }

      return description
    },
    getTransactionValue(transaction) {
      let value = transaction.value

      if (transaction.isInvoice || transaction.type === 'saida') {
        value *= -1
      }

      return this.currency(value)
    },
    getTransactionCategoryDescription(transaction) {
      let description = ''

      if (transaction.category && transaction.category.name && !transaction.isInvoice) {
        description += transaction.category.name + ' | '
      }

      description += transaction.creditCard ? transaction.creditCard.name : transaction.bankAccount.name

      return this.truncate(stringCapitalize(description))
    },
    getTransactionStatus({type, investment, consolidated}) {
      if (type === 'entrada') {
        return consolidated ? 'Recebida' : 'Não recebida'
      } else if (investment) {
        return consolidated ? 'Investido' : 'Não investido'
      }

      return consolidated ? 'Paga' : 'Não paga'
    },
    getTransactionObservations({obs}) {
      if (this.resize > 600) {
        return obs
      }

      let length = 40

      if (this.resize < 320 || (this.resize >= 320 && this.resize < 375)) {
        length = 17
      } else if (this.resize >= 375 && this.resize < 415) {
        length = 32
      } else if (this.resize >= 415 && this.resize < 600) {
        length = 35
      }

      return this.truncate(obs, length)
    },
    showDialogCustomerInvoiceDetails(creditCardId, period) {
      this.$bus.$emit("showDialogCustomerInvoiceDetails", {creditCardId, period})
    },
    formatListDate(date) {
      return dayjs(date).format('ddd DD/MM/YYYY');
    },
    getDateNumbers(date) {
      let [year, month, day] = date.split('-');

      day = parseInt(day);
      year = parseInt(year);
      month = parseInt(month);

      return {day, month, year};
    },
    showForm(transaction) {
      if (transaction.isInvoice) {
        this.showDialogCustomerInvoiceDetails(transaction.creditCard._id, transaction.period)
      } else if (this.allowed) {
        let event;

        if (transaction.pluggy || transaction.ofx) {
          event = 'showDialogFormPendencia'
        } else if (!transaction.invoice) {
          if (transaction.isTransfer)
            event = 'showDialogBankTransfer';
          else if (transaction.investment)
            event = 'showDialogFormInvestment';
          else if (transaction.type === 'entrada')
            event = 'showDialogFormRevenue';
          else if (transaction.type === 'saida')
            event = 'showDialogFormExpense';
        }

        if (event) {
          this.$bus.$emit(event, transaction);
        }
      }
    },
    consolidateTransaction(item) {
      if (this.allowed) {
        this.loading = item._id;
        this.consolidateLoader = true;

        updateTransactionStatus(item._id)
          .then(resp => {
            if (resp.bankAccount)
              this.$store.dispatch('updateBankAccountBalance', resp.bankAccount);

            if (resp.bankAccounts) {
              resp.bankAccounts.forEach(ba => {
                this.$store.dispatch('updateBankAccountBalance', ba);
              });
            }

            if (resp.transaction) {
              resp.transaction.category = item.category;
              resp.transaction.subCategory = item.subCategory;
              this.$store.dispatch('saveTransaction', resp.transaction);
            }

            if (resp.transactions) {
              resp.transactions.forEach(t => {
                t.category = item.category;
                t.subCategory = item.subCategory;
                this.$store.dispatch('saveTransaction', t);
              });
            }

            this.consolidateLoader = false;
            this.loading = '';
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') console.error(err);
            this.consolidateLoader = true;
            this.loading = '';
          });
      }
    },
    remove(item) {
      if (this.allowed) {
        this.$bus.$emit('showDialogRemoveTransaction', item);
      }
    },
    isFutureDate(date) {
      return this.$date().isSameOrBefore(date, 'day');
    },
    calcLastMonthDailyBalance(period) {
      const self = this;

      const bankAccounts = this.validBankAccounts;
      const filter = {
        period: this.period,
        bankAccounts: self.selectedBankAccounts
      };

      return calcBalanceProjectionFromPeriod(period, self.$date, bankAccounts, filter);
    }
  },
  created() {
    this.currentDate = dayjs();
    this.today = this.currentDate.format('YYYY-MM-DD');
    this.currentYear = this.currentDate.year();
    this.currentMonth = this.currentDate.month() + 1;
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.resize = window.innerWidth;
    });
  }
};
</script>

<style scoped>
.list-title {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.list-title div span:first-child {
  font-size: 12px;
}

.list-title div small {
  font-size: 9px;
}

.lists-container {
  width: 100%;
  min-height: 1px;
}

.transaction {
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  transition: ease all .8s;
  display: flex;
  align-items: center;
}

.transaction .v-avatar.transaction-avatar {
  margin-right: 10px;
}

.transaction-content {
  width: 100%;
}

.transaction-category {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #9e9e9e;
}

.transaction-category--desc {
  font-size: 12px;
  letter-spacing: .1em;
}

.transaction-obs {
  font-size: 12px !important;
  display: flex;
  align-items: flex-end;
  color: #9e9e9e;
  letter-spacing: .1em;
}

.transaction-obs .v-icon {
  color: #9e9e9e !important;
  margin-right: 5px !important;
  margin-bottom: 4px !important;
}

.swipeout-action {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #131313;
}

.swipeout-action:nth-child(1) {
  margin-left: 10px;
}

@media only screen and (min-width: 375px) {
  .card-total .v-avatar {
    width: 22px !important;
    height: 22px !important;
  }

  .transaction-description--value {
    font-size: 15px;
  }

  .transaction-description--value span {
    font-size: 12px;
  }
}

@media only screen and (min-width: 414px) {
  .transaction-description--value {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .list-title {
    font-size: 15px;
  }

  .transaction .v-avatar.transaction-avatar {
    width: 40px !important;
    height: 40px !important;
  }

  .transaction .v-avatar .v-icon {
    font-size: 22px !important;
  }

  .transaction-description--text {
    font-size: 15px !important;
  }

  .transaction-description--value {
    font-size: 18px;
  }

  .transaction-description--value span {
    font-size: 12px;
  }

  .transaction-category--icon .v-avatar .v-icon {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 960px) {
  #app > div > main > div > div.container.container-list.grid-list-md > div > div > div > div div > div.swipeout-list div > div > div > div {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .lists-container {
    border-radius: 5px;
    background-color: #404040;
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .transaction {
    cursor: pointer;
    transition: ease all 1s !important;
  }

  .transaction:hover {
    background-color: #505050;
    opacity: .1;
  }

  .transaction-description--value {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1264px) {
  .transactions-list {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
