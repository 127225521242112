import firebase from "firebase/app"
import httpClient from "@/plugins/httpClient"

export default (accountId, page = 1) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const url = `/pluggy-accounts/${accountId}/transactions?page=${page}`

        const options = {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        return httpClient(url, options)
      })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}
