<template>
  <v-dialog
    fullscreen
    persistent
    v-model="dialog"
  >
    <v-card class="no-radius">
      <v-container
        fill-height
        grid-list-md
        class="py-10 px-5 d-flex flex-column justify-space-between align-start"
      >
        <h1 class="white--text mb-5">
          Bem vind@, {{ firstName }}<span class="primary--text">!</span>
        </h1>

        <v-layout row wrap>
          <v-flex xs12>
            <p>Tudo pronto!</p>
            <p>
              Agora é só aguardar. Seu acesso total ao app será liberado na data da sua
              primeira reunião de planejamento financeiro!
            </p>
            <p>
              Se ainda não recebeu a agenda de seu planejador, não seu preocupe,
              ela é enviada em até 24h úteis após a confirmação do pagamento.
            </p>
            <p>
              Logo, logo você começará a transformar sua vida!
            </p>
          </v-flex>

          <v-flex xs12>
            <p>
              Está com algum problema? Entre em contato pelo e-mail
              <a
                :href="'mailto:' + email"
                class="primary--text"
              >
                {{ email }}
              </a>.
            </p>
          </v-flex>
        </v-layout>

        <v-btn
          text
          block
          class="mt-5"
          color="error"
          @click="logout"
        >
          Sair
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import logout from '@/utils/logout';

let interval

export default {
  name: 'DialogBlockedUser',
  data: () => ({
    dialog: false,
    email: 'suporte@uplanner.com.br'
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    firstName() {
      if (this.user) {
        return this.user.displayName.split(/\s+/gm)[0];
      }

      return '';
    }
  },
  methods: {
    logout() {
      logout()
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        clearInterval(interval)
      }
    }
  },
  created() {
    this.$bus.$on('showDialogBlockedUser', () => {
      this.dialog = true;
    });

    let self = this
    interval = setInterval(() => {
      if (!self.dialog && self.user && self.user.isUplanner && self.user.locked) {
        self.dialog = true
      } else if (self.user && self.user.isUplanner && !self.user.locked) {
        clearInterval(interval)
      }
    }, 1000)
  }
};
</script>

<style scoped>
h1 {
  line-height: 1.2em;
  font-size: 20px;
}

.container {
  max-width: 600px;
}

.call-image {
  display: block;
  max-width: 100%;
  margin: 20px auto;
}

@media only screen and (min-width: 600px) {
  .call-image {
    width:320px;
  }
}
</style>
