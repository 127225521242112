<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="768px"
    :fullscreen="isMobile"
  >
    <v-card color="grey3">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          class="mr-2"
          :disabled="loader"
          @click="closeDialog"
          color="warning"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="warning--text">Editar Despesa de Cartão de Crédito</span>
      </v-card-title>

      <v-card-text v-if="transaction">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="input-label primary--text">Descrição</span>
                <v-text-field
                  dense
                  readonly
                  disabled
                  color="grey1"
                  class="mt-0 pt-0"
                  :value="transaction.description"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Valor</span>
                <v-text-field
                  dense
                  id="input-value-pendencia"
                  color="grey1"
                  v-money="money"
                  class="mt-0 pt-0"
                  v-model="transaction.value"
                  :readonly="!!transaction.pluggy"
                  :disabled="!!transaction.pluggy"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Data</span>
                <v-dialog
                  persistent
                  ref="dialog"
                  width="290px"
                  v-model="dateMenu"
                  :return-value.sync="transaction.date"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      readonly
                      v-on="on"
                      v-bind="attrs"
                      class="mt-0 pt-0"
                      :value="formatedDate"
                      :readonly="!!transaction.pluggy"
                      :disabled="!!transaction.pluggy"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    scrollable
                    locale="pt-br"
                    color="primary"
                    v-model="transaction.date"
                  >
                    <v-spacer></v-spacer>

                    <v-btn
                      color="grey2"
                      @click="dateMenu = false"
                    >
                      Cancelar
                    </v-btn>

                    <v-btn
                      color="primary"
                      @click="$refs.dialog.save(transaction.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Cartão de crédito</span>
                <v-text-field
                  dense
                  color="grey1"
                  class="mt-0 pt-0"
                  readonly
                  disabled
                  :value="transaction.creditCard.name"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md6>
                <span class="input-label primary--text">Fatura</span>
                <v-text-field
                  dense
                  color="grey1"
                  class="mt-0 pt-0"
                  readonly
                  disabled
                  v-if="transaction.pluggy"
                  :value="formatedInvoice"
                ></v-text-field>

                <v-select
                  dense
                  color="grey1"
                  class="mt-0 pt-0"
                  v-if="!transaction.pluggy"
                  :items="invoicesDates"
                  item-value="period"
                  item-text="desc"
                  v-model="transaction.invoice"
                ></v-select>
              </v-flex>

              <v-expand-transition>
                <FormPendencia
                  ref="formPendencia"
                  :dialog="dialog"
                  :transaction="transaction"
                  transaction-type="saida"
                />
              </v-expand-transition>

              <v-flex xs12>
                <span class="input-label primary--text">Observação</span>
                <v-text-field
                  dense
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="transaction.obs"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="px-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    rounded
                    type="submit"
                    color="primary"
                    :loading="loader"
                    class="px-5 darkgrey--text"
                  >
                    Salvar
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {VMoney} from 'v-money'
import {required} from 'vuelidate/lib/validators'
import randomHexcolor from 'random-hex-color'
import removeMoneyMask from "@/utils/removeMoneyMask"
import stringCapitalize from "@/utils/stringCapitalize"
import FormPendencia from "@/components/Customer/Cashflow/forms/FormPendencia"
import saveCategoryUser from "@/api/categoriesUser/saveCategoryUser"
import saveTransaction from "@/api/transactions/saveTransaction"
import saveCreditCardTransaction from "@/api/transactions/saveCreditCardTransaction";
import getInvoice from "@/api/invoices/getInvoice";
import {orderBy, pick} from "lodash";

const ObjectID = require('bson-objectid')

export default {
  name: 'DialogFormPendenciaCartao',
  components: {FormPendencia},
  directives: {money: VMoney},
  data: () => ({
    dialog: false,
    loader: false,
    dateMenu: false,
    transaction: null,
    currentDate: null,
    money: {
      precision: 2,
      decimal: ',',
      prefix: 'R$ ',
      thousands: '.'
    }
  }),
  validations: {
    transaction: {
      obs: {
        isValid(v) {
          return !v || v.length <= 150
        }
      },
      date: {
        isValid(v) {
          return !!v && this.$date(v).isValid()
        }
      },
      value: {
        isValid(v) {
          return removeMoneyMask(v) > 0
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    isIOS() {
      return this.$store.getters.isIOS
    },
    months() {
      return this.$store.getters.months
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    formatedDate() {
      if (this.transaction) {
        return this.$date(this.transaction.date).format('DD/MM/YYYY')
      }

      return ''
    },
    formatedInvoice() {
      if (this.transaction && this.transaction.pluggy && this.transaction.invoice) {
        return this.$date(this.transaction.invoice.paymentDate).format('MMMM YYYY').toUpperCase()
      }

      return ''
    },
    categories() {
      return orderBy(this.$store.getters.categories.filter(cat => cat.type === 'despesa'), cat => cat.name)
    },
    invoicesDates() {
      let invoices = [];

      if (this.transaction && !this.transaction.pluggy) {
        let currentYear = this.currentDate.year();
        let currentDay = this.currentDate.date();
        let currentMonth = this.currentDate.month() + 1;

        if (
          this.transaction.creditCard.close > this.transaction.creditCard.pay ||
          currentDay > this.transaction.creditCard.close
        ) {
          currentMonth++;
          if (currentMonth > 12) {
            currentYear++;
            currentMonth = 1;
          }
        }

        let month = currentMonth;
        if (month < 10) month = `0${month}`;

        currentMonth -= 12;
        if (currentMonth <= 0) {
          currentYear--;
          currentMonth = 12 + currentMonth;
        }

        for (let i = 1; i < 20; i++) {
          let monthData = this.months.find((m) => m.numero === currentMonth);

          if (currentMonth < 10) currentMonth = `0${currentMonth}`;

          invoices.push({
            period: `${currentYear}-${currentMonth}`,
            desc: `${monthData.sigla} de ${currentYear}`
          });

          currentMonth = parseInt(currentMonth) + 1;
          if (currentMonth > 12) {
            currentYear++;
            currentMonth = 1;
          }
        }
      }

      return invoices;
    }
  },
  methods: {
    openDialog(transaction) {
      if (transaction) {
        this.currentDate = this.$date()

        this.transaction = JSON.parse(JSON.stringify(transaction))
        this.transaction.value = transaction.value.toFixed(2)

        if (this.transaction.category) {
          this.transaction.category = this.transaction.category._id
        }

        if (this.transaction.subCategory) {
          this.transaction.subCategory = this.transaction.subCategory._id
        }

        if (!this.transaction.pluggy) {
          this.selectInvoice()
        }

        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.transaction = null
      this.currentDate = null
      this.$v.$reset()
    },
    async saveCategory(data) {
      const category = await saveCategoryUser(data)
      await this.$store.dispatch('saveCategory', category)
    },
    async submit() {
      try {
        this.$v.$touch()

        let hasError = this.$v.$anyError

        const {formPendencia} = this.$refs

        if (formPendencia) {
          formPendencia.$v.$touch()

          if (!hasError) {
            hasError = formPendencia.$v.$anyError
          }
        }

        if (!hasError) {
          this.loader = true

          const transaction = JSON.parse(JSON.stringify(this.transaction))

          transaction.isRevised = true
          transaction.creditCard = transaction.creditCard._id
          transaction.value = removeMoneyMask(transaction.value)

          if (transaction.invoice.paymentDate) {
            transaction.invoice = transaction.invoice.paymentDate.slice(0, 7)
          }

          if (formPendencia) {
            const {category, subCategory, categoryName, subCategoryName} = formPendencia

            if (category === 'outro') {
              const categoryData = {
                subs: [],
                _id: ObjectID().str,
                user: this.user._id,
                color: randomHexcolor(),
                icon: 'mdi-dots-horizontal',
                name: stringCapitalize(categoryName),
                type: 'despesa'
              }

              console.log(categoryData)

              if (subCategory === 'outro') {
                const subCategoryData = {
                  _id: ObjectID().str,
                  name: stringCapitalize(subCategoryName)
                }

                categoryData.subs.push(subCategoryData)
                transaction.subCategory = subCategoryData._id
              }

              await this.saveCategory(categoryData)

              transaction.category = categoryData._id
            } else if (subCategory === 'outro') {
              const selectedCategory = this.categories.find(cat => cat._id === category)

              if (!selectedCategory) {
                throw new Error('Invalid category')
              }

              const subCategoryData = {
                _id: ObjectID().str,
                name: stringCapitalize(subCategoryName)
              }

              selectedCategory.subs.push(subCategoryData)

              transaction.category = category
              transaction.subCategory = subCategoryData._id

              await this.saveCategory(selectedCategory)
            } else {
              transaction.category = category
              transaction.subCategory = subCategory
            }
          }

          const {
            invoices,
            creditCards,
            transaction: savedTransaction,
          } = await saveCreditCardTransaction(transaction)

          invoices.forEach(inv => this.$store.dispatch('updateInvoiceValue', inv))
          creditCards.forEach(cc => this.$store.dispatch('updateCreditCardLimit', cc))

          await this.$store.dispatch('saveTransaction', savedTransaction)

          this.closeDialog()
          this.$noty.success('Transação salva com sucesso!')
          this.$bus.$emit('updateListPendencias')
          this.$store.dispatch('removePendencia', transaction._id)
        }
      } catch
        (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.loader = false
        this.$noty.error('Desculpe! Não conseguimos salvar sua transação. Tente novamente mais tarde!')
      }
    },
    selectInvoice() {
      if (this.transaction && !this.transaction.pluggy) {
        let [year, month, day] = this.transaction.date.split('-');
        const close = this.transaction.creditCard.close;
        const pay = this.transaction.creditCard.pay;

        if (day > close) {
          month = parseInt(month) + 1;
          if (month < 10) {
            month = `0${month}`;
          } else if (month > 12) {
            month = 1;
            year = parseInt(year) + 1;
          }
        }

        if (close > pay) {
          month = parseInt(month) + 1;
          if (month < 10) {
            month = `0${month}`;
          } else if (month > 12) {
            month = 1;
            year = parseInt(year) + 1;
          }
        }

        if (month < 10 && typeof month === 'number') {
          month = `0${month}`;
        }

        this.transaction.invoice = `${year}-${month}`;
      }
    },
  },
  watch: {
    'transaction.date'() {
      this.selectInvoice()
    }
  },
  created() {
    this.$bus.$on('showDialogFormPendenciaCartao', this.openDialog)
  }
}
</script>
