import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const getCompanyEmployeeDetails = (companyId, employeeId) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const url = `/companies/${companyId}/employees/${employeeId}`
        const options = {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        return httpClient(url, options)
      })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}

export default getCompanyEmployeeDetails
