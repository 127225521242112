<template>
  <v-app-bar
    fixed
    dense
    color="grey4"
  >
    <v-app-bar-nav-icon @click="showDrawer"></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
export default {
  name: 'CompanyToolbar',
  methods: {
    showDrawer() {
      this.$bus.$emit('toggleCompanyDrawer', true)
    }
  }
}
</script>
