import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const deleteItemPatrimonio = (patrimonioId, itemId) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'DELETE';
          let headers = { Authorization: `Bearer ${idToken}` };
          let url = `/patrimonio/${patrimonioId}/items/${itemId}`;

          return httpClient(url, { method, headers });
        })
        .then(() => resolve())
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default deleteItemPatrimonio;
