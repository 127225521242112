<template>
  <div class="pt-5">
    <v-data-table
      dense
      :items="users"
      :loading="loader"
      hide-default-footer
      :headers="tableHeaders"
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-center" style="width: 90px; padding: 5px;">
            <v-icon :color="props.item.disabled ? 'grey lighten-2' : 'success'">mdi-check-circle</v-icon>
          </td>
          <td class="hidden-xs-only" style="width: 100px;">
            <v-chip
              small
              :color="props.item.disabled ? 'grey lighten-2' : (props.item.role === 'company' ? 'darkblue' : (props.item.role === 'planner' ? 'darkgrey' : 'primary'))"
              :class="props.item.disabled ? 'black--text' :'white--text'"
            >{{ getRoleName(props.item.role) }}</v-chip>
          </td>
          <td
            class="user-name"
          >{{ props.item.displayName.length && isMobile > 22 ? props.item.displayName.slice(0,22) + '...' : props.item.displayName }}</td>
          <td class="hidden-md-and-down">{{ props.item.company }}</td>
          <td class="hidden-sm-and-down">{{ props.item.planner }}</td>
          <td class="text-center" style="width: 30px; padding: 0">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click.stop="showDetails(props.item)">
                  <v-list-item-title>Detalhes</v-list-item-title>
                </v-list-item>

                <v-list-item @click.stop="showDialogFormUser(props.item)">
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>

                <v-list-item @click.stop="showDialogPasswordResetLink(props.item)">
                  <v-list-item-title>Alterar senha</v-list-item-title>
                </v-list-item>

                <v-list-item @click.stop="showDialogEmailUpdate(props.item.email)">
                  <v-list-item-title>Alterar e-mail</v-list-item-title>
                </v-list-item>

                <v-list-item @click.stop v-if="props.item.role === 'customer'">
                  <v-list-item-title>Alterar plano</v-list-item-title>
                </v-list-item>

                <v-list-item @click.stop="showDialogUpdateUserStatus(props.item)">
                  <v-list-item-title>{{ props.item.disabled ? 'Habilitar' : 'Desabilitar' }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="text-center mt-5">
      <v-pagination
        circle
        color="darkblue"
        v-model="pagination.page"
        :length="pagination.totalPages"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import getUsers from '@/api/users/getUsers';
import countUsers from '@/api/users/countUsers';

export default {
  name: 'TableCompanyUsers',
  props: ['user'],
  data: () => ({
    loader: false,
    users: [],
    tableHeaders: [
      {
        text: 'Status',
        value: 'disabled',
        sortable: true,
        align: 'center',
        class: ''
      },
      {
        text: 'Tipo',
        value: 'role',
        sortable: true,
        align: 'left',
        class: 'hidden-xs-only'
      },
      {
        text: 'Nome/Razão Social',
        value: 'displayName',
        sortable: true,
        align: 'left',
        class: ''
      },
      {
        text: 'Empresa',
        value: 'company',
        sortable: true,
        align: 'left',
        class: 'hidden-md-and-down'
      },
      {
        text: 'Planejador',
        value: 'planner',
        sortable: true,
        align: 'left',
        class: 'hidden-sm-and-down'
      },
      {
        text: 'Opções',
        value: 'action',
        sortable: false,
        align: 'center'
      }
    ],
    filter: {
      search: '',
      type: '',
      status: '',
      company: '',
      planner: ''
    },
    pagination: {
      page: 1,
      itemsPerPage: 10
    }
  }),
  methods: {
    getUsers(newSearch) {
      this.loader = true;

      if (newSearch) this.pagination.page = 1;

      if (this.user.role === 'company') {
        this.filter.company = this.user._id;
        this.filter.planner = null;
      } else if (this.user.role === 'planner') {
        this.filter.planner = this.user._id;
        this.filter.company = this.user.company ? this.user.company : null;
      }

      Promise.all([
        countUsers(this.filter),
        getUsers(
          this.pagination.page,
          this.pagination.itemsPerPage,
          this.filter
        )
      ])
        .then(results => {
          this.pagination.totalItems = results[0].count;
          this.pagination.totalPages = Math.ceil(
            results[0].count / this.pagination.itemsPerPage
          );

          results[1].forEach(user => {
            user.company = user.company ? user.company.displayName : '';
            user.planner = user.planner ? user.planner.displayName : '';
          });

          this.users = results[1];

          this.loader = false;
        })
        .catch(err => {
          console.error(err);
          this.loader = false;
        });
    },
    getRoleName(role) {
      switch (role) {
        case 'customer':
          return 'Cliente';
        case 'planner':
          return 'Planejador';
        default:
          return 'Empresa';
      }
    },
    showDialogPasswordResetLink(user) {
      this.$bus.$emit('showDialogPasswordResetLink', {
        userId: user._id,
        email: user.email
      });
    },
    showDialogFormUser(user) {
      if (user.role === 'company') {
        this.$bus.$emit('showFormCompany', user._id);
      } else if (user.role === 'planner') {
        this.$bus.$emit('showFormPlanner', user._id);
      } else if (user.role === 'customer') {
        this.$bus.$emit('showFormCustomer', user._id);
      }
    },
    showDialogEmailUpdate(email) {
      this.$bus.$emit('showDialogEmailUpdate', email);
    },
    showDialogUpdateUserStatus(user) {
      this.$bus.$emit('showDialogUpdateUserStatus', user);
    },
    showDetails(user) {
      if (user.role === 'company') {
        this.$bus.$emit('showDialogCompanyDetails', user);
      }
    }
  },
  watch: {
    'pagination.page'() {
      this.getUsers();
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  mounted() {
    this.$bus.$on('companyGetUsers', filter => {
      if (filter) {
        this.filter = filter;

        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getUsers();
        }
      } else {
        this.filter = {
          search: '',
          type: '',
          status: '',
          company: '',
          planner: ''
        };
        this.getUsers();
      }
    });
  }
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .user-name {
    font-size: 12px;
  }
}
</style>
