<template>
  <v-dialog
    fullscreen
    persistent
    v-model="dialog"
  >
    <v-card class="no-radius" color="grey2">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          small
          color="white"
          @click.stop="close"
          class="back-button"
        >
          <v-icon x-small>mdi-close</v-icon>
        </v-btn>

        <span class="white--text ml-4">
          Reuniões Realizadas
        </span>
      </v-card-title>

      <v-progress-linear
        v-show="openLoader"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-text class="px-0">
        <v-container grid-list-md>
          <v-flex xs12 v-if="!reunioes" class="mt-5">
            <v-form @submit.prevent="updateUser">
              <div>
                <span class="input-label primary--text">
                  Não conseguimos detectar qual foi sua última reunião com o cliente. <br>
                  Digite o número da última reunião concluída abaixo e clique em "Salvar"
                </span>
                <v-text-field
                  type="number"
                  class="mt-0 pt-0"
                  color="grey1"
                  v-model.number="currentMeeting"
                  :error-messages="currentMeetingErrors"
                  :disabled="formLoader"
                ></v-text-field>
              </div>

              <div class="text-right">
                <v-btn
                  rounded
                  color="primary"
                  type="submit"
                  class="darkgrey--text"
                  :loading="formLoader"
                  :disabled="!allowed"
                >
                  Salvar
                </v-btn>
              </div>
            </v-form>
          </v-flex>


          <v-list color="transparent" v-if="reunioes">
            <v-list-item v-if="hasCompany" class="elevation-2 mb-2 darkgrey rounded">
              <v-list-item-avatar>
                <v-avatar
                  dark
                  size="28"
                  :color="trial ? 'success' : 'grey1'"
                >
                  <v-icon>{{ trial ? 'mdi-check' : 'mdi-clock' }}</v-icon>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="meeting-desc">Reunião de trial</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action v-if="!trial && allowed">
                <v-btn
                  dark
                  small
                  rounded
                  depressed
                  color="primary"
                  class="darkgrey--text"
                  @click="confirm('trial')"
                  :loading="loader === 'trial'"
                  :disabled="(loader !== false && loader !== 'trial')"
                >
                  Concluir
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              :key="key"
              v-if="key <= reunioes || key == reunioes || key == 1"
              v-for="key in orderedMeetings"
              class="elevation-2 mb-2 darkgrey rounded"
            >
              <v-list-item-avatar>
                <v-avatar
                  dark
                  size="28"
                  :color="confirmations[key] ? 'success' : 'grey1'"
                >
                  <v-icon>{{ confirmations[key] ? 'mdi-check' : 'mdi-clock' }}</v-icon>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="meeting-desc">Reunião {{ key }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action v-if="!confirmations[key] && (reunioes === 0 || reunioes == key) && allowed">
                <v-btn
                  dark
                  small
                  rounded
                  depressed
                  color="primary"
                  class="darkgrey--text"
                  @click="confirm(key)"
                  :loading="loader === key"
                  :disabled="(loader !== false && loader !== key)"
                >
                  Concluir
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { orderBy } from 'lodash'
import getUserConfirmations from '@/api/users/getUserConfirmations';
import confirmUserMeeting from '@/api/users/confirmUserMeeting';
import saveUser from '@/api/users/saveUser';

export default {
  name: 'DialogMeetingsConfirmation',
  data: () => ({
    userId: null,
    dialog: false,
    loader: false,
    reunioes: 0,
    confirmations: [],
    openLoader: false,
    currentMeeting: '',
    formLoader: false,
    trial: false,
    hasCompany: false
  }),
  validations: {
    currentMeeting: {
      isValid(v) {
        return v > 0 && v <= 12
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    orderedMeetings() {
      return orderBy(Object.keys(this.confirmations), k => parseInt(k))
    },
    currentMeetingErrors() {
      const errors = []

      if (!this.$v.currentMeeting.$anyDirty) {
        return errors
      }

      if (!this.$v.currentMeeting.isValid) {
        errors.push('Preencha um número válido')
      }

      return errors
    },
    user() {
      return this.$store.getters.user
    },
    allowed() {
      return this.$store.getters.allowed
    }
  },
  methods: {
    updateUser() {
      if (this.allowed) {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.formLoader = true

          const user = {
            _id: this.userId,
            planejamento: {
              confirmations: {},
              reunioes: this.currentMeeting
            }
          }

          for (let i = 1; i <= 12; i++) {
            user.planejamento.confirmations[`${i}`] = i < this.currentMeeting
          }

          saveUser(user)
            .then(user => {
              this.formLoader = false
              this.getConfirmations(user._id)
            })
            .catch(err => {
              if (process.env.NODE_ENV !== 'production') {
                console.error(err)
              }

              this.formLoader = false
              this.$noty.error('Falha ao alterar dados. Tente novamente mais tarde!')
            })
        }
      }
    },
    close() {
      this.userId = null
      this.dialog = false
      this.loader = false
      this.openLoader = false
      this.confirmations = []

      this.currentMeeting = ''
      this.formLoader = false
      this.$v.$reset()
    },
    getConfirmations(userId) {
      this.openLoader = true

      getUserConfirmations(userId)
        .then(data => {
          const reunioesCompletas = Object.keys(data.confirmations).filter(key => {
            return key !== 'trial' && !!data.confirmations[key]
          })

          if (data.confirmations.trial) {
            this.trial = true
          }

          this.reunioes = reunioesCompletas.length + 1
          this.confirmations = data.confirmations
          this.openLoader = false
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }

          this.close()
          this.$noty.error('Falha ao buscar dados do cliente! Tente novamente mais tarde')
        })
    },
    confirm(key) {
      if (((key > 0 && key < 13) || key === 'trial') && this.allowed) {
        this.loader = key

        if (key !== 'trial') {
          key = Number.parseInt(key)
        }

        confirmUserMeeting(this.userId, key)
          .then(() => {
            this.close()
            this.$noty.success('Reunião atualizada com sucesso!')
          })
          .catch(err => {
            this.loader = false
            this.$noty.error('Falha ao concluir reunião! Tente novamente mais tarde.')

            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    }
  },
  created() {
    this.$bus.$on('showDialogMeetingsConfirmation', data => {
      if (data.userId) {
        this.userId = data.userId
        this.hasCompany = data.hasCompany
        this.getConfirmations(this.userId)
        this.dialog = true
      }
    })
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
}

.meeting-desc {
  font-size: 12px !important;
  letter-spacing: .1em;
}
</style>
