import firebase from 'firebase/app';
import httpClient from "../../plugins/httpClient";

const saveProjetoIndFinanceira = (indFinanceiraId, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = data._id ? 'PUT' :  'POST';
          let headers = {Authorization: `Bearer ${idToken}`};
          let url = `/independencia-financeira/${indFinanceiraId}/projetos-vida`;

          if (data._id)
            url += `/${data._id}`;

          return httpClient(url, {headers, method, data});
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch (err) {
      reject(err);
    }
  });
}

export default saveProjetoIndFinanceira;
