<template>
  <v-dialog
    fullscreen
    scrollable
    v-model="dialog"
  >
    <v-card color="grey3" class="no-radius" height="100%">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="white"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="white--text ml-4">Categorias</span>
      </v-card-title>

      <v-card-text class="pa-0 ma-0">
        <v-tabs
          :centered="!isMobile"
          :show-arrows="isMobile"
          v-model="tab"
          :grow="isMobile"
          background-color="darkgrey"
        >
          <v-tab href="#tab-0" class="success--text">Receita</v-tab>
          <v-tab href="#tab-1" class="error--text">Despesa</v-tab>
          <v-tab href="#tab-2" class="grey1--text">Arquivadas</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-0">
            <ListCategories type="receita"/>
          </v-tab-item>

          <v-tab-item value="tab-1">
            <ListCategories type="despesa"/>
          </v-tab-item>

          <v-tab-item value="tab-2">
            <ListCategories type="archived"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-btn
        fab
        :small="isMobile"
        fixed
        right
        bottom
        color="primary"
        @click="showDialogFormCategory"
        class="grey3--text btn-add-category"
      >
        <v-icon :large="!isMobile">mdi-plus</v-icon>
      </v-btn>
    </v-card>


  </v-dialog>
</template>

<script>
  import ListCategories from "../lists/ListCategories";

  export default {
    name: 'DialogCategorias',
    components: {ListCategories},
    data: () => ({
      dialog: false,
      tab: 'tab-0'
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      }
    },
    methods: {
      close() {
        this.dialog = false;
      },
      showDialogFormCategory() {
        this.$bus.$emit('showDialogFormCategory', null);
      }
    },
    created() {
      this.$bus.$on('showDialogCategories', () => {
        this.dialog = true;
      });
    }
  }
</script>

<style scoped>
  .tabs-container {
    background-color: #131313;
  }

  .theme--dark.v-tabs-items {
    background-color: transparent !important;
  }

  @media only screen and (min-width: 1200px) {
    .btn-add-category {
      right: 50px !important;
      bottom: 50px !important;
    }
  }
</style>
