<template>
  <v-card height="100%">
    <v-card-title class="primary--text text-uppercase pb-0">
      Despesas por categoria
    </v-card-title>

    <v-card-text class="mt-4">
      <v-layout row wrap>
        <v-flex xs6 class="pa-0">
          <div class="chart-container">
            <apexchart
              type="donut"
              :options="options"
              :series="series"
            ></apexchart>
          </div>
        </v-flex>

        <v-flex xs6 class="legend-list">
          <div class="legend-item" :key="i" v-for="(item, i) in legends">
            <v-avatar size="10" :color="item.color" class="mr-1"></v-avatar>
            <div>
              <span class="legend-item--title">{{ item.text }}</span>
              <span class="legend-item--value">{{ item.value }}</span>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import VueApexCharts from 'vue-apexcharts';
import numberToCurrency from '../../../../utils/numberToCurrency';

export default {
  name: 'CardChartPieDespesas',
  props: ['period'],
  components: { apexchart: VueApexCharts },
  data: () => ({
    options: {
      colors: [],
      labels: [],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '70%',
            background: 'transparent',
            labels: { show: false }
          }
        }
      },
      dataLabels: { enabled: false },
      legend: { show: false },
      stroke: { width: 0 }
    },
    series: [],
    legends: []
  }),
  methods: {
    buildChart() {
      let amountPerCategory = this.categories.map(cat => {
        return {
          id: cat._id,
          category: cat.name,
          color: cat.color,
          value: 0
        };
      });

      amountPerCategory.push({
        category: 'Não categorizada',
        color: '#D1AC68',
        value: 0
      })

      amountPerCategory.forEach(item => {
        this.expenses
          .filter(t => {
            return (
              (t.category && t.category.name === item.category) ||
              (!t.isInvoicePayment && !t.category && item.category === 'Não categorizada')
            )
          })
          .forEach(t => {
            item.value += t.value;
          });
      });

      amountPerCategory = orderBy(amountPerCategory, 'value', ['desc']);

      let series = [];
      let options = JSON.parse(JSON.stringify(this.options));

      options.colors = [];
      options.labels = [];
      this.legends = [];

      amountPerCategory.forEach(item => {
        if (item.value) {
          series.push(item.value);
          options.colors.push(item.color);
          options.labels.push(item.category);
          this.legends.push({
            text: item.category,
            color: item.color,
            value: numberToCurrency(item.value)
          });
        }
      });

      options.tooltip = {
        y: {
          formatter: v => numberToCurrency(v)
        }
      };

      this.series = series;
      this.options = options;
    }
  },
  computed: {
    currentPeriod() {
      return this.$date().format('YYYY-MM');
    },
    expenses() {
      let transactions = [];

      if (this.period) {
        transactions = [
          ...transactions,
          ...this.$store.getters.transactions.filter(({ invoice, creditCard, type }) => {
            return (
              type === 'saida' &&
              invoice &&
              creditCard &&
              (
                (
                  this.period <= this.currentPeriod &&
                  invoice.paid &&
                  invoice.paidIn.slice(0, 7) === this.period
                ) ||
                (
                  !creditCard.archived &&
                  this.period > this.currentPeriod &&
                  !invoice.paid &&
                  invoice.paymentDate.slice(0, 7) === this.period
                )
              )
            );
          })
        ];

        transactions = [
          ...transactions,
          ...this.$store.getters.transactions.filter(({
            bankAccount,
            invoice,
            date,
            consolidated,
            type,
            isTransfer,
            investment
          }) => {
            return (
              type === 'saida' &&
              !isTransfer &&
              !investment &&
              !invoice &&
              bankAccount &&
              !bankAccount.archived &&
              date.slice(0, 7) === this.period &&
              (this.period > this.currentPeriod || consolidated)
            );
          })
        ];
      }

      return transactions;
    },
    categories() {
      return this.$store.getters.categories.filter(c => c.type === 'despesa');
    }
  },
  watch: {
    transactions: {
      deep: true,
      handler() {
        this.buildChart();
      }
    }
  },
  created() {
    this.buildChart();
  },
  mounted() {
    this.buildChart();
  }
};
</script>

<style scoped>
.chart-container {
  /* height: 120px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-card__title {
  font-size: 12px !important;
  letter-spacing: .1em;
  font-weight: 500;
}

.legend-list {
  height: 100px;
  overflow-y: scroll;
}

.legend-list::-webkit-scrollbar {
  width: 4px;
}

.legend-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.legend-item {
  display: flex;
  margin-bottom: 2px;
}

.legend-item div {
  display: flex;
  flex-direction: column;
}

.legend-item--title {
  font-size: 12px;
  letter-spacing: .1em;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  line-height: 12px;
}

.legend-item--value {
  font-size: 9px;
  line-height: 9px;
  color: #9e9e9e;
}

@media only screen and (min-width: 600px) {
  .chart-container {
    height: 239px;
  }

  .legend-list {
    height: 239px;
  }

  .legend-item {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 960px) {
  .v-card__title {
    font-size: 14px !important;
    line-height: 16px;
  }

  .chart-container {
    height: auto;
    padding-top: 10px;
  }

  .legend-list {
    height: 109px;
  }

  .legend-item {
    margin-bottom: 5px;
  }
}
</style>
