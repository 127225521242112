<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <div class="title">Alterar E-mail</div>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="save">
          <v-text-field readonly color="accent" label="E-mail atual" :value="currentEmail"></v-text-field>

          <v-text-field
            type="email"
            color="accent"
            label="Novo e-mail"
            v-model="newEmail"
            @input="$v.newEmail.$touch()"
            :error-messages="newEmailErrors"
          ></v-text-field>

          <v-card-actions class="pa-0 pb-0">
            <v-btn
              small
              text
              rounded
              class="px-5 primary--text"
              type="button"
              :disabled="loader"
              @click.stop="close"
            >Cancelar</v-btn>

            <v-spacer></v-spacer>

            <v-btn
              small
              rounded
              depressed
              class="px-5 darkgrey--text"
              type="submit"
              color="primary"
              :loading="loader"
            >Salvar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import updateUserEmail from '@/api/users/updateUserEmail';
import checkEmailAlreadyUsed from '@/api/users/checkEmailAlreadyUsed';

export default {
  name: 'DialogFormEmailUpdate',
  data: () => ({
    currentEmail: '',
    newEmail: '',
    dialog: false,
    loader: false,
    emailUsed: false
  }),
  validations: {
    newEmail: { required, email }
  },
  computed: {
    newEmailErrors() {
      const errors = [];
      if (!this.$v.newEmail.$dirty) return errors;
      !this.$v.newEmail.required && errors.push('E-mail é obrigatório');
      !this.$v.newEmail.email && errors.push('E-mail inválido');
      this.emailUsed && errors.push('E-mail já cadastrado');
      this.newEmail === this.currentEmail && errors.push('E-mails iguais');
      return errors;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.currentEmail = '';
      this.newEmail = '';
      this.emailUsed = false;

      this.$v.newEmail.$reset();
    },
    save() {
      this.$v.$touch();

      if (!this.$v.$anyError && !this.emailUsed) {
        this.loader = true;

        updateUserEmail(this.currentEmail, this.newEmail)
          .then(() => {
            this.$bus.$emit('managerGetUsers');
            this.$noty.success('E-mail alterado com sucesso!');
            this.close();
          })
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);

            this.loader = false;
            this.$noty.error('Falha ao alterar e-mail! Tente novamente.');
          });
      }
    },
    checkEmail() {
      this.$v.newEmail.$touch();

      if (!this.$v.newEmail.$anyError) {
        checkEmailAlreadyUsed(this.newEmail)
          .then(resp => {
            this.emailUsed = resp.exists;
          })
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    }
  },
  watch: {
    newEmail() {
      this.checkEmail();
    }
  },
  created() {
    this.$bus.$on('showDialogEmailUpdate', email => {
      this.currentEmail = email;
      this.dialog = true;
    });
  }
};
</script>
