<template>
  <v-dialog
    fullscreen
    scrollable
    v-model="dialog"
    transition="slide-x-reverse-transition"
  >
    <v-card class="no-radius" color="darkgrey" v-if="user">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          class="back-button"
          @click.stop="dialog = false"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4">Meu Perfil</span>
      </v-card-title>

      <v-card-text>
        <div class="profile-photo">
          <v-avatar size="71" color="grey2">
            <img v-if="user.photoURL" :src="user.photoURL">
            <v-icon v-else color="grey1" x-large>mdi-account</v-icon>
          </v-avatar>
          <div class="profile-name primary--text text-center mt-3">
            {{ user.displayName }}
          </div>
        </div>

        <v-tabs
          dark
          fixed-tabs
          class="mt-5"
          color="primary"
          background-color="transparent"
        >
          <v-tab>Geral</v-tab>
          <v-tab-item style="background-color: transparent !important;">
            <v-list nav>
              <v-list-item
                :to="item.path"
                :key="item.text"
                v-for="item in menuItems"
              >
                <v-list-item-icon>
                  <v-icon color="primary">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title class="primary--text menu-item-desc">{{ item.text }}</v-list-item-title>
              </v-list-item>

              <v-list-item @click="logout">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-logout</v-icon>
                </v-list-item-icon>

                <v-list-item-title class="primary--text menu-item-desc">Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-tab-item>

          <v-tab>Sobre</v-tab>
          <v-tab-item>
            <v-list nav>
              <v-list-item @click="goToHelp">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-help-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="primary--text menu-item-desc">Ajuda</v-list-item-title>
              </v-list-item>

              <v-list-item to="/termos-uso">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-security</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="primary--text menu-item-desc">Termo de adesão</v-list-item-title>
              </v-list-item>

              <v-list-item to="/politica-privacidade">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-security</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="primary--text menu-item-desc">Política de privacidade</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import logout from '../../../utils/logout';

  export default {
    name: 'MenuProfileMobile',
    data: () => ({
      dialog: false,
      menuItems: [
        { text: 'Editar perfil', icon: 'mdi-account', path: '/perfil' },
        { text: 'Meu cadastro', icon: 'mdi-file-document-outline', path: '/cadastro' },
      ]
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      user() {
        return this.$store.getters.user;
      }
    },
    methods: {
      logout() {
        logout();
      },
      goToHelp() {
        let url;

        if (this.isMobile)
          url = 'https://uplanner.zendesk.com/hc/pt-br/categories/360003418172-Web';
        else
          url = 'https://uplanner.zendesk.com/hc/pt-br/categories/360003418172-Web';

        window.open(url, '_blank');
      }
    },
    created() {
      this.$bus.$on('showMenuProfileMobile', () => {
        this.dialog = true;
      });
    }
  }
</script>

<style scoped>
  .profile-photo {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
  }

  .profile-name {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .1em;
  }

  .v-window.v-item-group.theme--dark.v-tabs-items {
    background-color: #131313 !important;
  }

  .theme--dark.v-list {
    background-color: #131313 !important;
  }

  .menu-item-desc {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: .1em;
    text-transform: uppercase;
  }
</style>
