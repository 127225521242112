<template>
  <v-main v-if="!user">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <div class="loader-container">
          <v-progress-circular
            :size="50"
            color="accent"
            indeterminate
            class="mb-3"
          ></v-progress-circular>
          <div class="title">Carregando...</div>
        </div>
      </v-layout>
    </v-container>
  </v-main>

  <v-main v-else>
    <Admin v-if="user && ['admin', 'support', 'comercial', 'supervisor'].includes(user.role)" id="content" />
    <Planner v-if="user && user.role === 'planner'" id="content"/>

  </v-main>
</template>

<script>
import getLoggedUserData from '@/utils/getLoggedUserData';

import Admin from './Admin';
import Menu from '../components/Global/Menu';
import DialogFormRevenue from "../components/Customer/Cashflow/dialogs/DialogFormRevenue";
import getPerfil from "../api/perfil/getPerfil";

import Planner from "./planner/Planner";
import MenuPlanner from "../components/Dashboard/menus/MenuPlanner";
import saveEvent from "../api/events/saveEvent";

export default {
  name: 'Index',
  components: {
    MenuPlanner,
    Planner,
    DialogFormRevenue,
    Menu, 
    Admin
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    getPerfil() {
      if (this.user && this.user.role === 'customer') {
        getPerfil(this.user._id)
          .then(perfil => {
            this.$store.dispatch('setPerfil', perfil);
          });
      }

    },
    sendLoginLog() {
      let event = {
        type: 'signin',
        user: this.user._id,
        date: new Date().getTime()
      }

      saveEvent(event);
    }
  },
  watch: {
    user() {
      this.getPerfil();
    }
  },
  created() {
    getLoggedUserData();
    this.getPerfil();
    this.sendLoginLog();
  }
};
</script>
