import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const getPlansNames = (planType = '') =>
  new Promise((resolve, reject) => {
    let user = store.getters.user;

    if (user.role === 'admin') {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let headers = {
            role: user.role,
            Authorization: `Bearer ${idToken}`
          };
          let url = `/plans/names?type=${planType}`;

          return httpClient(url, { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });

export default getPlansNames;
