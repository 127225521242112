import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const deleteIuguCustomerPaymentMethod = (customerId, paymentMethodId) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const url = `/iugu/customers/${customerId}/payment-methods/${paymentMethodId}`
          const options = {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }

          return httpClient(url, options)
        })
        .then(() => resolve())
        .catch(reject)
    } catch (err) {
      reject(err)
    }
  })
}

export default deleteIuguCustomerPaymentMethod
