<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''">
      <v-progress-linear
        indeterminate :height="5"
        color="primary"
        v-if="isMobile && loader"
      ></v-progress-linear>

      <v-card-title class="dialog-form-title">
        <div class="title">
          <v-btn
            icon
            v-if="isMobile"
            class="mr-4 back-button"
            color="white"
            @click.stop.prevent="close"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="white--text">Categoria</span>
        </div>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <v-layout row wrap class="px-3">
            <v-flex xs12>
              <v-select
                color="primary"
                label="Tipo"
                :items="types"
                item-text="text"
                item-value="value"
                v-model="category.type"
                :error-messages="typeErrors"
                @change="$v.category.type.$touch()"
              ></v-select>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                color="primary"
                label="Nome"
                v-model="category.name"
                :error-messages="nameErrors"
                @change="$v.category.name.$touch()"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="mb-2 mt-2">
              <v-menu offset-y close-on-content-click v-model="colorMenu">
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    block
                    rounded
                    depressed
                    v-on="on"
                    :color="category.color"
                    class="px-5"
                  >Escolha a cor</v-btn>
                </template>

                <v-dialog v-model="colorMenu" scrollable :fullscreen="isMobile" max-width="300px">
                  <v-card :class="!isMobile ? 'pt-5' : ''">
                    <v-card-title v-if="isMobile">
                      <v-btn icon class="mr-2" @click="colorMenu = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>Cor
                    </v-card-title>
                    <v-card-text>
                      <v-layout row wrap>
                        <v-flex xs2 :key="color" v-for="color in categoryColors" class="mb-1">
                          <v-btn
                            fab
                            dark
                            small
                            depressed
                            type="button"
                            :color="color"
                            @click.stop="category.color = color; colorMenu = false"
                          ></v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-menu>
            </v-flex>

            <v-flex xs12>
              <v-menu offset-y left close-on-content-click v-model="iconMenu">
                <template v-slot:activator="{ on }">
                  <v-btn
                    block
                    dark
                    rounded
                    depressed
                    v-on="on"
                    :color="category.color"
                    class="px-5"
                  >
                    Escolha o ícone
                    <v-icon right>{{ category.icon }}</v-icon>
                  </v-btn>
                </template>

                <v-dialog v-model="iconMenu" scrollable :fullscreen="isMobile" max-width="600px">
                  <v-card :class="!isMobile ? 'pt-5' : ''">
                    <v-card-title v-if="isMobile">
                      <v-btn icon class="mr-2" @click="iconMenu = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>Ícone
                    </v-card-title>
                    <v-card-text>
                      <v-layout row wrap>
                        <v-flex
                          xs2
                          :key="icon"
                          v-for="icon in categoryIcons"
                          class="mb-5 text-center"
                        >
                          <v-btn
                            icon
                            dark
                            depressed
                            type="button"
                            color="darkgrey"
                            @click.stop="category.icon = icon; iconMenu = false"
                          >
                            <v-icon>{{icon}}</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-menu>
            </v-flex>

            <v-flex xs12 class="mt-5 pt-5 subcat-action">
              <sub class="inner-title">Sub-categorias</sub>
              <v-chip @click.stop="subcatDialog = true" :disabled="loader">
                <v-icon>mdi-plus</v-icon>
              </v-chip>
            </v-flex>

            <v-flex
              xs12
              v-if="!category.subs.length"
              class="text-center mt-5"
            >Nenhuma sub-categoria adicionada</v-flex>

            <v-flex xs12 v-else class="mt-5">
              <v-sheet width="100%" height="200px" class="subcat-list">
                <v-list>
                  <v-list-item
                    two-line
                    v-for="(sub, i) in sort(category.subs)"
                    :key="sub._id ? sub._id : i"
                    style="border-bottom: 1px solid #e5eaf0;"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ sub.name }}</v-list-item-title>
                      <v-list-item-subtitle v-if="sub.fixed">Fixa</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-menu bottom left close-on-content-click>
                      <template v-slot:activator="{ on }">
                        <v-btn icon rounded v-on="on" color="darkgrey">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click.stop="editSubCategory(sub, i)">Editar</v-list-item>
                        <v-list-item @click.stop="removeSubcategory(sub)">Remover</v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item>
                </v-list>
              </v-sheet>
            </v-flex>
          </v-layout>

          <v-card-actions class="px-0 pb-0 mt-5">
            <v-btn
              text
              small
              rounded
              color="primary"
              type="button"
              class="px-5"
              :disabled="loader"
              @click.stop="close"
            >Cancelar</v-btn>

            <v-spacer></v-spacer>

            <v-btn
              small
              rounded
              depressed
              class="px-5 darkgrey--text"
              type="submit"
              color="primary"
              :loading="loader"
            >Salvar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="subcatDialog" scrollable max-width="500px" style="z-index: 10000">
      <v-card>
        <v-card-title>
          <v-btn icon class="mr-2" @click="subcatDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span>Sub-Categoria</span>
        </v-card-title>

        <v-card-text>
          <v-text-field color="accent" label="Nome" v-model="currentSubCategory.name"></v-text-field>
          <v-switch color="accent" label="Despesa fixa" v-model="currentSubCategory.fixed"></v-switch>

          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>
            <v-btn
              dark
              small
              rounded
              depressed
              class="px-5"
              color="darkgrey"
              @click.stop="saveSubCategory"
            >
              Adicionar
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import deleteSubCategory from "../../../api/categories/deleteSubCategory";

const ObjectID = require("bson-objectid");
import { sortBy } from 'lodash';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

import categoryIcons from '@/utils/categoryIcons';
import categoryColors from '@/utils/categoryColors';
import stringCapitalize from '@/utils/stringCapitalize';
import saveAdminCategory from '@/api/categories/saveAdminCategory';

export default {
  name: 'DialogFormAdminCategory',
  data: () => ({
    dialog: false,
    loader: false,
    category: {
      name: '',
      icon: '',
      color: '',
      type: '',
      subs: []
    },
    types: [
      { text: 'Despesa', value: 'despesa' },
      { text: 'Receita', value: 'receita' }
    ],
    categoryColors,
    colorMenu: false,
    categoryIcons,
    iconMenu: false,
    subcatDialog: false,
    currentSubCategory: {
      name: '',
      fixed: false
    },
    subcatMenu: false
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.category = {
        name: '',
        icon: '',
        color: '',
        type: '',
        subs: []
      };
      this.$v.$reset();
    },
    saveSubCategory() {
      if (this.currentSubCategory._id !== undefined) {
        let subCategory = this.category.subs.find(
          sc => sc._id === this.currentSubCategory._id
        );

        if (!subCategory) {
          subCategory = this.category.subs[this.currentSubCategory._id];
          delete this.currentSubCategory._id;
        }

        let subCategoryIndex = this.category.subs.indexOf(subCategory);
        this.category.subs.splice(subCategoryIndex, 1, this.currentSubCategory);
      } else {
        this.category.subs.push(this.currentSubCategory);
      }

      this.subcatDialog = false;
      this.currentSubCategory = {
        name: '',
        fixed: false
      };
    },
    editSubCategory(subCategory, index) {
      if (!subCategory._id) subCategory._id = index;
      this.currentSubCategory = JSON.parse(JSON.stringify(subCategory));
      this.subcatDialog = true;
    },
    removeSubcategory(subcategory) {
      this.loader = true;
      deleteSubCategory(this.category._id, subcategory._id)
        .then(() => {
          let index = this.category.subs.findIndex(sub => sub._id === subcategory._id);
          if (index > -1)
            this.category.subs.splice(index, 1);
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          this.$noty.error('Falha ao remover sub-categoria. Tente novamente');
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        let category = JSON.parse(JSON.stringify(this.category));

        category.name = stringCapitalize(category.name);

        category.subs.forEach(sub => {
          sub.name = stringCapitalize(sub.name);
          sub.category = category._id;
          if (!sub._id)
            sub._id = ObjectID().str;
        })

        saveAdminCategory(category)
          .then(() => {
            this.$bus.$emit('getAdminCategories');
            this.$noty.success('Categoria salva com');
            this.close();
          })
          .catch(err => {
            this.loader = false;
            if (process.env.NODE_ENV !== 'production') console.error(err);
            this.$noty.error(
              'Falha ao salvar categoria. Tente novamente mais tarde.'
            );
          });
      }
    },
    sort(list) {
      return sortBy(list, 'name');
    }
  },
  validations: {
    category: {
      type: { required },
      name: { required, minLength: minLength(3), maxLength: maxLength(32) }
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.category.type.$dirty) return errors;
      !this.$v.category.type.required && errors.push('Selecione o tipo');
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.category.name.$dirty) return errors;
      !this.$v.category.name.required && errors.push('Insira o nome');
      !this.$v.category.name.minLength &&
        errors.push('O nome deve conter, no mínimo, 3 caracteres');
      !this.$v.category.name.maxLength &&
        errors.push('O nome deve conter, no máximo, 32 caracteres');
      return errors;
    }
  },
  created() {
    this.$bus.$on('showDialogFormAdminCategory', category => {
      if (category) {
        this.category = JSON.parse(JSON.stringify(category));
      } else {
        let colorIndex = parseInt(
          Math.random() * (this.categoryColors.length - 0) + 0
        );
        this.category.color = this.categoryColors[colorIndex];

        let iconIndex = parseInt(
          Math.random() * (this.categoryIcons.length - 0) + 0
        );
        this.category.icon = this.categoryIcons[iconIndex];
      }
      this.dialog = true;
    });
  }
};
</script>

<style scoped>
.v-card.no-radius {
  border-radius: 0 !important;
}

.title {
  display: flex;
  align-items: center;
}

.subcat-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.subcat-list {
  overflow: hidden;
  overflow-y: scroll;
}

.subcat-list::-webkit-scrollbar {
  width: 4px;
}

.subcat-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.subcat-list::-webkit-scrollbar-thumb {
  background-color: #47484d;
  outline: 5px solid slategrey;
  border-radius: 2px;
}
</style>
