import firebase from 'firebase/app'
import httpClient from "../../plugins/httpClient";

const getUserReports = (
  userId,
  startDate,
  endDate,
  future,
  bankAccount,
  categoryReceita,
  subCategoryReceita,
  categoryDespesa,
  subCategoryDespesa
) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let headers = { Authorization: `Bearer ${idToken}` };
          let url = `/reports/customers/${userId}?startDate=${startDate}&endDate=${endDate}`;

          if (future)
            url += '&future=true';

          if (bankAccount)
            url += `&bankAccount=${bankAccount}`;

          if (categoryReceita)
            url += `&categoryReceita=${categoryReceita}`;

          if (subCategoryReceita)
            url += `&subCategoryReceita=${subCategoryReceita}`;

          if (categoryDespesa)
            url += `&categoryDespesa=${categoryDespesa}`;

          if (subCategoryDespesa)
            url += `&subCategoryDespesa=${subCategoryDespesa}`;

          return httpClient(url, { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default getUserReports;
