<template>
  <v-card color="grey3" class="no-radius">
    <v-card-title class="text-right">
      <v-btn
        fab
        small
        color="grey2"
        class="ml-auto"
        :disabled="!allowed"
        @click="edit(null)"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="actionPlans"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              <v-btn
                icon
                @click="update(props.item)"
                :loading="loader === props.item._id"
                :color="props.item.completed ? 'error' : 'success'"
              >
                <v-icon>
                  {{ !props.item.completed ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked-outline' }}
                </v-icon>
              </v-btn>
            </td>

            <td @click="showDialogPlanoAcao((props.item))">
              <v-icon :color="getColor(props.item.type.value)">mdi-flag-outline</v-icon>
            </td>

            <td @click="showDialogPlanoAcao((props.item))">
              <span class="tr-text">
                {{ props.item.description.slice(0, 80) }} {{ props.item.description.length > 80 ? '...' : '' }}
              </span>
            </td>

            <td @click="showDialogPlanoAcao((props.item))">
              <span class="tr-text">
                {{ formatDate(props.item.date) }}
              </span>
            </td>

            <td @click="showDialogPlanoAcao((props.item))">
              <span class="tr-text">
                {{ formatDate(props.item.deadline) }}
              </span>
            </td>

            <td @click="showDialogPlanoAcao((props.item))">
              <span class="tr-text">
                {{ printResponsibles(props.item.responsibles) }}
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div class="text-center mt-5">
        <v-pagination
          circle
          color="primary"
          v-model="pagination.page"
          :length="pagination.totalPages"
        ></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import saveActionPlan from '@/api/actionPlans/saveActionPlan';

export default {
  name: 'TablePlanosAcao',
  props: ['completed'],
  data: () => ({
    pagination: {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      itemsPerPage: 10
    },
    headers: [
      { text: 'Tipo', value: 'type', align: 'center', sortable: true, width: '9%' },
      { text: 'Título', value: 'description', align: 'center', sortable: true },
      { text: 'Data', value: 'date', align: 'center', sortable: true },
      { text: 'Prazo', value: 'deadline', align: 'center', sortable: true },
      { text: 'Responsáveis', value: 'responsibles', align: 'left', sortable: false}
    ],
    loader: '',
    type: ''
  }),
  computed: {
    actionPlans() {
      let skip = (this.pagination.page - 1) * this.pagination.itemsPerPage;

      return orderBy(
        this.plans.filter(p => {
          return (!this.type || p.type.value === this.type) && p.completed === this.completed
        }),
        ['type.value', 'deadline', 'description']
      ).slice(skip, skip + this.pagination.itemsPerPage)
    },
    allowed() {
      return this.$store.getters.allowed;
    },
    plans() {
      return this.$store.getters.actionPlans;
    }
  },
  methods: {
    getColor(type) {
      switch (type) {
        case 1:
          return 'error';
        case 2:
          return 'warning';
        default:
          return 'success';
      }
    },
    formatDate(date) {
      if (date)
        return moment(date).add(3, 'hours').format('DD/MM/YYYY');

    },
    printResponsibles(responsibles) {
      let str = responsibles.join(', ');

      if (str.length > 25) {
        return str.slice(0, 25) + '...';
      }

      return str;
    },
    showDialogPlanoAcao(plan) {
      if (this.allowed)
        this.$bus.$emit('showDialogPlanoAcao', plan);
    },
    edit(plan) {
      if (this.allowed)
        this.$bus.$emit('showDialogFormPlanoAcao', plan);
    },
    remove(plan) {
      if (this.allowed) {
        this.$bus.$emit('showDialogRemovePlanoAcao', {
          _id: plan._id,
          description: plan.description
        });
      }
    },
    update(item) {
      if (this.allowed) {
        this.loader = item._id;
        let plan = JSON.parse(JSON.stringify(item));

        plan.completed = !plan.completed;

        saveActionPlan(plan)
          .then(savedPlan => {
            this.$store.dispatch('saveActionPlan', savedPlan);
            this.loader = '';
          })
          .catch(err => {
            this.loader = '';
            this.$noty.error('Desculpe! Não foi possível salvar este plano. Tente novamente!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    },
    setPagination() {
      this.pagination.totalItems = this.plans.filter(p => (!this.type || p.type.value === this.type) && p.completed === this.completed).length;
      this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage);

      if (this.pagination.totalPages < this.pagination.page) {
        this.pagination.page--;
      }

      if (!this.pagination.page)
        this.pagination.page = 1;
    },
    setupHeaders() {
      this.headers.unshift({
        text: this.completed ? 'Cancelar' : 'Concluir',
        value: 'check',
        align: 'center',
        sortable: false
      });

      this.headers.push({
        text: '',
        value: 'actions',
        align: 'center',
        sortable: false
      });
    }
  },
  watch: {
    'plans': {
      deep: true,
      handler() {
        this.setPagination();
      }
    },
    type() {
      this.setPagination();
    }
  },
  created() {
    this.setupHeaders();
    this.setPagination();


    this.$bus.$on('filterActionPlans', type => {
      if (!this.type || this.type !== type) {
        this.type = type;
      } else {
        this.type = '';
      }
    });
  }
};
</script>

<style scoped>
td {
  cursor: pointer;
}
</style>
