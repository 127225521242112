<template>
  <v-card>
    <v-card-title>
      <span class="total-desc">Contatos</span>
    </v-card-title>

    <v-card-text>
      <v-list dense>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>mdi-email</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Email:</v-list-item-subtitle>
            <v-list-item-title>
              <a :href="`mailto:${user.email}`">{{ user.email }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>mdi-whatsapp</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Telefone/WhatsApp:</v-list-item-subtitle>
            <v-list-item-title>
              <a
                target="_blank"
                :href="isWhatsapp(user.phoneNumber) ? `https://wa.me/55${user.phoneNumber}?text=Olá` : `tel:${user.phoneNumber}`"
              >{{ formatPhoneNumber(user.phoneNumber) }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>mdi-google-maps</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Endereço:</v-list-item-subtitle>
            <v-list-item-title>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    v-on="on"
                    target="_blank"
                    :href="`https://www.google.com.br/maps/place/${formatedAddress(user.address)}`"
                  >{{ formatedAddress(user.address) }}</a>
                </template>
                <span>{{ formatedAddress(user.address) }}</span>
              </v-tooltip>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CardCompanyProfileContact',
  props: ['user'],
  methods: {
    formatPhoneNumber(phoneNumber) {
      let ddd = phoneNumber.slice(0, 2);
      let numberOne = phoneNumber.slice(2, 7);
      let numberTwo = phoneNumber.slice(7, phoneNumber.length);

      return `(${ddd}) ${numberOne}-${numberTwo}`;
    },
    isWhatsapp(phoneNumber) {
      return phoneNumber.length === 11;
    },
    formatedAddress(address) {
      return `${address.logradouro} - ${address.numero} - ${address.bairro} - ${address.cidade} - ${address.estado.sigla}`;
    }
  }
};
</script>

<style scoped>
.total-desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
</style>
