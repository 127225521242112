<template>
  <v-card flat color="grey3">
    <v-card-title class="primary--text seguro-vida main-title">Custos de inventário</v-card-title>

    <v-card-text>
      <v-container grid-list-md class="px-0">
        <v-layout row wrap>
          <v-flex xs12>
            <span class="seguro-vida section-title">Bens móveis e imóveis</span>
          </v-flex>

          <v-flex xs12 sm12 md4 lg4>
            <span class="seguro-vida input-label primary--text">Valor</span>
            <v-text-field
              dense
              readonly
              color="grey1"
              :value="numberToCurrency(moveisImoveis.valor)"
            >
              <v-icon
                slot="prepend-inner"
                color="grey1"
                class="seguro-vida input-icon"
              >
                mdi-cash
              </v-icon>
            </v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md4 lg4>
            <span class="seguro-vida input-label primary--text">Estado</span>
            <v-autocomplete
              dense
              :items="estados"
              item-text="nome"
              item-value="sigla"
              color="grey1"
              v-model="moveisImoveis.estado"
            >
              <v-icon
                slot="prepend-inner"
                color="grey1"
                class="seguro-vida input-icon"
              >
                mdi-map-marker
              </v-icon>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 sm12 md4 lg4>
            <span class="seguro-vida input-label primary--text">Taxa</span>
            <v-text-field
              dense
              type="tel"
              suffix="%"
              color="grey1"
              v-model.number="moveisImoveis.taxa"
              :error-messages="moveisImoveisTaxaErrors"
            >
              <v-icon
                slot="prepend-inner"
                color="grey1"
                class="seguro-vida input-icon"
              >
                mdi-percent
              </v-icon>
            </v-text-field>
          </v-flex>

          <v-flex xs12 class="d-flex flex-column align-center justify-center py-5">
            <span class="seguro-vida input-label grey1--text">Total em bens móveis e imóveis</span>
            <span class="seguro-vida total-value white--text">
                <span>R$</span> {{ currency(moveisImoveis.total) }}
              </span>
          </v-flex>

          <v-flex xs12 class="px-5 mb-5">
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12>
            <span class="seguro-vida section-title">Ativos líquidos</span>
          </v-flex>

          <v-flex xs12 sm12 md4 lg4>
            <span class="seguro-vida input-label primary--text">Valor</span>
            <v-text-field
              dense
              readonly
              color="grey1"
              :value="numberToCurrency(ativosLiquidos.valor)"
            >
              <v-icon
                slot="prepend-inner"
                color="grey1"
                class="seguro-vida input-icon"
              >
                mdi-cash
              </v-icon>
            </v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md4 lg4>
            <span class="seguro-vida input-label primary--text">Estado</span>
            <v-autocomplete
              dense
              :items="estados"
              item-text="nome"
              item-value="sigla"
              color="grey1"
              v-model="ativosLiquidos.estado"
            >
              <v-icon
                slot="prepend-inner"
                color="grey1"
                class="seguro-vida input-icon"
              >
                mdi-map-marker
              </v-icon>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 sm12 md4 lg4>
            <span class="seguro-vida input-label primary--text">Taxa</span>
            <v-text-field
              dense
              type="tel"
              suffix="%"
              color="grey1"
              v-model.number="ativosLiquidos.taxa"
              :error-messages="ativosLiquidosTaxaErrors"
            >
              <v-icon
                slot="prepend-inner"
                color="grey1"
                class="seguro-vida input-icon"
              >
                mdi-percent
              </v-icon>
            </v-text-field>
          </v-flex>

          <v-flex xs12 class="d-flex flex-column align-center justify-center py-5">
            <span class="seguro-vida input-label grey1--text">Total em ativos líquidos</span>
            <span class="seguro-vida total-value white--text">
                <span>R$</span> {{ currency(ativosLiquidos.total) }}
              </span>
          </v-flex>

          <v-flex xs12 class="px-5">
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span class="seguro-vida section-title">Outros ativos</span>
          </v-flex>

          <v-flex
            xs12
            class="text-center"
            v-if="!lifeInsurance.custosInventario.outros || !lifeInsurance.custosInventario.outros.length"
          >
            <h3 class="grey1--text">Nenhum ativo adicionado</h3>
          </v-flex>

          <v-flex
            v-else
            xs12 sm6 md4 lg4
            :key="ativo._id"
            v-for="ativo in lifeInsurance.custosInventario.outros"
          >
            <v-card height="100%" class="seguro-vida card-column">
              <v-card-text>
                <v-card-title class="seguro-vida item-title pa-0 white--text">{{ ativo.descricao }}</v-card-title>
                <div class="seguro-vida info-item">
                  <span class="seguro-vida info-item-title primary--text">Valor: </span>
                  <span class="seguro-vida info-item-value">{{ numberToCurrency(ativo.valor) }}</span>
                </div>
                <div class="seguro-vida info-item">
                  <span class="seguro-vida info-item-title primary--text">Estado: </span>
                  <span class="seguro-vida info-item-value">{{ getStateName(ativo.estado) }}</span>
                </div>
                <div class="seguro-vida info-item">
                  <span class="seguro-vida info-item-title primary--text">Taxas: </span>
                  <span class="seguro-vida info-item-value">{{ ativo.taxa.toFixed(2) }}%</span>
                </div>
                <div class="seguro-vida info-item">
                  <span class="seguro-vida info-item-title primary--text">Total: </span>
                  <span class="seguro-vida info-item-value">{{ numberToCurrency(ativo.total) }}</span>
                </div>
              </v-card-text>

              <v-card-actions class="pt-0 text-right">
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  outlined
                  @click="showDialogFormInsuranceInventoryAsset(ativo)"
                  color="primary"
                  :disabled="!allowed"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  outlined
                  color="primary"
                  :disabled="!allowed"
                  @click="showDialogDeleteInsuranceInventoryAsset(ativo._id)"
                >
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>

          <v-flex xs12 class="text-center mt-5">
            <v-btn
              small
              rounded
              outlined
              color="primary"
              class="seguro-vida"
              :disabled="!allowed"
              @click="showDialogFormInsuranceInventoryAsset(null)"
            >
              Adicionar outro ativo
            </v-btn>
          </v-flex>

          <v-flex xs12 class="d-flex flex-column align-center justify-center py-5">
            <span class="seguro-vida input-label grey1--text">Total em outros ativos</span>
            <span class="seguro-vida total-value white--text">
                <span>R$</span> {{ totalOutrosAtivos }}
              </span>
          </v-flex>

          <v-flex xs12 class="px-5 my-5">
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12 class="mt-5">
            <span class="seguro-vida section-title">Passivos não segurados</span>
          </v-flex>

          <v-flex xs12 md4 lg4>
            <span class="seguro-vida input-label primary--text">Valor</span>
            <v-text-field
              dense
              type="tel"
              color="grey1"
              v-money="money"
              v-model="passivosNaoSegurados"
              id="input-passivos-nao-segurados"
            >
              <v-icon
                slot="prepend-inner"
                color="grey1"
                class="seguro-vida input-icon"
              >
                mdi-security
              </v-icon>
            </v-text-field>
          </v-flex>

          <v-flex xs12 class="px-5 my-5">
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12 class="d-flex flex-column align-center justify-center py-5">
            <span class="seguro-vida input-label total-label primary--text">Total em custos de inventário</span>
            <span class="seguro-vida total-value total white--text">
                <span>R$</span> {{ currency(lifeInsurance.custosInventario.total) }}
              </span>
          </v-flex>

          <v-flex xs12>
            <v-card-actions class="px-0">
              <v-spacer></v-spacer>

              <v-btn
                rounded
                color="primary"
                @click="submit"
                :loading="loader"
                :disabled="!allowed"
                class="darkgrey--text seguro-vida px-5"
              >
                Salvar
              </v-btn>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'
import currency from '@/utils/currency';
import numberToCurrency from '@/utils/numberToCurrency';

import FormInsuranceCustoInventario from '@/components/Customer/LifeInsurance/forms/FormInsuranceCustoInventario';
import removeMoneyMask from '@/utils/removeMoneyMask';
import updateLifeInsurace from '@/api/lifeInsurance/updateLifeInsurance';

export default {
  name: 'CardInsuranceInventario',
  directives: { money: VMoney },
  components: { FormInsuranceCustoInventario },
  props: ['isSpouse'],
  data: () => ({
    currency,
    numberToCurrency,
    loader: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    moveisImoveis: {
      taxa: 0,
      estado: ''
    },
    ativosLiquidos: {
      taxa: 0,
      estado: ''
    },
    passivosNaoSegurados: 0,
    total: 0,
    taxas: {
      AC: 2, AL: 2, AP: 4, AM: 2, BA: 3.5,
      CE: 4, DF: 4, ES: 4, GO: 4, MA: 3,
      MT: 2, MS: 2, MG: 3, PA: 4, PB: 2,
      PR: 4, PE: 2, PI: 2, RJ: 4.5, RN: 3,
      RS: 4, RO: 2, RR: 4, SC: 1, SE: 4, SP: 4,
      TO: 2
    }
  }),
  validations: {
    moveisImoveis: {
      taxa: {
        isValid(v) {
          return v !== '' && v !== undefined && v >= 0
        }
      }
    },
    ativosLiquidos: {
      taxa: {
        isValid(v) {
          return v !== '' && v !== undefined && v >= 0
        }
      }
    },
    passivosNaoSegurados: {
      isValid(v) {
        return v !== undefined && removeMoneyMask(v) >= 0
      }
    }
  },
  computed: {
    isCustomer() {
      return this.$store.getters.user.role === 'customer';
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
    },
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    },
    estados() {
      return this.$store.getters.estados;
    },
    moveisImoveisTaxaErrors() {
      const errors = []

      if (!this.$v.moveisImoveis.taxa.$anyDirty) {
        return errors
      }

      !this.$v.moveisImoveis.taxa.isValid && errors.push('Preencha uma taxa válida')

      return errors
    },
    ativosLiquidosTaxaErrors() {
      const errors = []

      if (!this.$v.ativosLiquidos.taxa.$anyDirty) {
        return errors
      }

      !this.$v.ativosLiquidos.taxa.isValid && errors.push('Preencha uma taxa válida')

      return errors
    },
    allowed() {
      return this.$store.getters.allowed
    },
    totalOutrosAtivos() {
      let total = 0

      this.lifeInsurance.custosInventario.outros.forEach(item => {
        total += item.total
      })

      return currency(total)
    }
  },
  methods: {
    copyData() {
      if (this.lifeInsurance && this.lifeInsurance.custosInventario) {
        this.moveisImoveis = JSON.parse(JSON.stringify(this.lifeInsurance.custosInventario.moveisImoveis))
        this.ativosLiquidos = JSON.parse(JSON.stringify(this.lifeInsurance.custosInventario.ativosLiquidos))
        this.passivosNaoSegurados = this.lifeInsurance.custosInventario.passivosNaoSegurados.toFixed(2)
      }
    },
    submit() {
      if (this.allowed) {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          const custosInventario = {
            moveisImoveis: JSON.parse(JSON.stringify(this.moveisImoveis)),
            ativosLiquidos: JSON.parse(JSON.stringify(this.ativosLiquidos)),
            passivosNaoSegurados: removeMoneyMask(this.passivosNaoSegurados)
          }

          updateLifeInsurace(this.lifeInsurance._id, { custosInventario })
            .then(lifeInsurance => {
              this.$store.dispatch('saveLifeInsurance', lifeInsurance)
              this.loader = false
              this.$noty.success('Dados salvos com sucesso!')
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível atualizar seus dados. Tente novamente mais tarde!');

              if (process.env.NODE_ENV !== 'production') {
                console.error(err)
              }
            })
        }
      }
    },
    calcTotal(key) {
      if (this[key] && this[key].taxa) {
        this[key].total = this[key].valor * (this[key].taxa / 100)
      } else {
        this[key].total = 0
      }
    },
    showDialogFormInsuranceInventoryAsset(asset) {
      if (this.allowed && this.lifeInsurance) {
        this.$bus.$emit('showDialogFormInsuranceInventoryAsset', {
          asset,
          insuranceId: this.lifeInsurance._id
        })
      }
    },
    getStateName(sigla) {
      return this.estados.find(e => e.sigla === sigla).nome
    },
    showDialogDeleteInsuranceInventoryAsset(assetId) {
      if (this.allowed && assetId) {
        this.$bus.$emit('showDialogDeleteInsuranceInventoryAsset', {
          assetId,
          insuranceId: this.lifeInsurance._id
        })
      }
    }
  },
  watch: {
    lifeInsurance: {
      deep: true,
      handler() {
        this.copyData();
      }
    },
    'moveisImoveis.taxa'() {
      this.calcTotal('moveisImoveis')
    },
    'ativosLiquidos.taxa'() {
      this.calcTotal('ativosLiquidos')
    },
    'moveisImoveis.estado'() {
      if (this.moveisImoveis.estado) {
        this.moveisImoveis.taxa = this.taxas[this.moveisImoveis.estado] + 8
      } else {
        this.moveisImoveis.taxa = 0
      }
    },
    'ativosLiquidos.estado'() {
      if (this.ativosLiquidos.estado) {
        this.ativosLiquidos.taxa = this.taxas[this.ativosLiquidos.estado] + 8
      } else {
        this.ativosLiquidos.taxa = 0
      }
    }
  },
  created() {
    this.copyData();
  }
}
</script>

<style scoped>
.seguro-vida.input-label.total-label {
  font-size: 15px;
  line-height: 1.5em;
}

.seguro-vida.total-value.total {
  font-size: 24px;
  line-height: 1.5em;
}
</style>
