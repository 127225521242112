<template>
  <v-card color="grey3">
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 class="mb-2">
            <h1 class="payer-title white--text">Valores Mensais<span class="purple--text">.</span></h1>
          </v-flex>

          <v-flex xs12 sm4>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ numberToCurrency(companyValue) }}</span>
              <span class="payer-desc primary--text">Empresa</span>
            </div>
          </v-flex>

          <v-flex xs12 sm4>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ numberToCurrency(individualValue) }}</span>
              <span class="payer-desc primary--text">Plano Individual</span>
            </div>
          </v-flex>

          <v-flex xs12 sm4>
            <div class="d-flex flex-column align-start">
              <span class="payer-value">{{ numberToCurrency(familyValue) }}</span>
              <span class="payer-desc primary--text">Plano Familiar</span>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';

export default {
  name: 'CardCompanyHistoryValues',
  props: ['companyValue', 'familyValue', 'individualValue'],
  data: () => ({
    numberToCurrency
  })
}
</script>

<style scoped>
.payer-title {
  font-size: 18px;
}

.payer-value {
  font-size: 15px;
  font-weight: bold;
}

.payer-desc {
  font-size: 9px;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 414px) {
  .payer-value {
    font-size: 18px;
  }

  .payer-desc {
    font-size: 10px;
  }
}
</style>
