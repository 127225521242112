<template>
  <v-dialog
    fullscreen
    v-model="dialog"
  >
    <v-card :class="isMobile ? 'no-radius' : ''">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="primary"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="ml-2">Detalhes do colaborador</span>
      </v-card-title>

      <v-progress-linear
        indeterminate
        color="primary"
        v-show="loader"
      ></v-progress-linear>

      <v-expand-transition>
        <v-card-text
          class="mt-2"
          v-if="employee"
        >
          <v-container grid-list-md fluid>
            <v-layout row wrap>
              <v-flex xs12 class="text-center">
                <v-avatar
                  size="120"
                  color="darkgrey"
                >
                  <v-img
                    v-if="employee.photoURL"
                    :src="employee.photoURL"
                  ></v-img>

                  <v-icon
                    x-large
                    color="white"
                    v-if="!employee.photoURL"
                  >
                    mdi-account-outline
                  </v-icon>
                </v-avatar>

                <h2 class="white--text mt-3">{{ employee.displayName }}</h2>
              </v-flex>

              <v-flex xs12 sm12 md6 class="mt-8">
                <h3 class="grey1--text">Dados pessoais</h3>

                <v-list two-line dense>
                  <v-list-item class="px-0">
                    <v-list-item-avatar>
                      <v-avatar color="purple">
                        <v-icon color="white">mdi-email-outline</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ employee.email }}</v-list-item-title>
                      <v-list-item-subtitle class="grey1--text">E-mail</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-0">
                    <v-list-item-avatar>
                      <v-avatar color="purple">
                        <v-icon color="white">mdi-whatsapp</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ formatPhoneNumber(employee.phoneNumber) }}</v-list-item-title>
                      <v-list-item-subtitle class="grey1--text">Celular/Whatsapp</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-0">
                    <v-list-item-avatar>
                      <v-avatar color="purple">
                        <v-icon color="white">mdi-account-plus-outline</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ formatDate(employee.createdAt) }}</v-list-item-title>
                      <v-list-item-subtitle class="grey1--text">Data de cadastro</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-0">
                    <v-list-item-avatar>
                      <v-avatar color="purple">
                        <v-icon color="white">mdi-calendar-check-outline</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ countMeetings(employee.planejamento.confirmations) }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey1--text">Reuniões concluídas</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-0">
                    <v-list-item-avatar>
                      <v-avatar color="purple">
                        <v-icon color="white">mdi-login-variant</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ lastSignin ? formatDate(lastSignin.date) : 'Nenhum acesso encontrado' }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey1--text">Último acesso</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>

              <v-flex xs12 sm12 md6 v-if="subscription" class="mt-8">
                <h3 class="grey1--text">Assinatura</h3>
                <v-subtitle v-if="!subscription">Não ativada</v-subtitle>

                <v-list dense>
                  <v-list-item class="px-0" v-if="employee.company.companyPayment.hasTrial">
                    <v-list-item-avatar>
                      <v-avatar color="purple">
                        <v-icon color="white">mdi-calendar-clock</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ trialStart }} a {{ trialEnd }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey1--text">Período de trial</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-0">
                    <v-list-item-avatar>
                      <v-avatar color="purple">
                        <v-icon color="white">mdi-calendar-check-outline</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ formatDate(subscription.created_at) }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey1--text">Data de ativação</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-0">
                    <v-list-item-avatar>
                      <v-avatar color="purple">
                        <v-icon color="white">
                          {{ subscriptionStatus === 'Ativa' ? 'mdi-check' : 'mdi-cancel' }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ subscriptionStatus }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey1--text">Status</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-0">
                    <v-list-item-avatar>
                      <v-avatar color="purple">
                        <v-icon color="white">mdi-apps-box</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ getPlanName(subscription.plan_identifier) }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey1--text">Plano</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-0" v-if="subscriptionStatus !== 'Ativa'">
                    <v-list-item-avatar>
                      <v-avatar color="purple">
                        <v-icon color="white">mdi-calendar-remove-outline</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ formatDate(subscription.updated_at) }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey1--text">
                        {{ subscriptionStatus }} em
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-expand-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import getCompanyEmployeeDetails from '@/api/companies/getCompanyEmployeeDetails'

export default {
  name: 'DialogCompanyEmployee',
  data: () => ({
    dialog: false,
    loader: true,
    employee: null,
    subscription: null,
    lastSignin: null
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isIOS() {
      return this.$store.getters.isIOS
    },
    isCompany() {
      return this.$store.getters.user && this.$store.getters.user.role === 'company'
    },
    company() {
      return this.isCompany ? this.$store.getters.user : this.$store.getters.company
    },
    trialStart() {
      if (this.employee) {
        return this.$date(this.employee.createdAt).format('DD/MM/YYYY')
      }

      return ''
    },
    trialEnd() {
      if (this.employee) {
        return this.$date(this.employee.createdAt).add(30, 'day').format('DD/MM/YYYY')
      }

      return ''
    },
    subscriptionStatus() {
      if (this.subscription) {
        if (this.subscription.suspended) {
          return 'Suspensa'
        } else if (!this.subscription.active) {
          return 'Inativa'
        } else if (this.$date().isAfter(this.subscription.expires_at_iso, 'day')) {
          return 'Expirada'
        } else {
          return 'Ativa'
        }
      }

      return ''
    }
  },
  methods: {
    openDialog(employeeId) {
      if (employeeId && !this.dialog) {
        this.dialog = true
        this.getEmployeeDetails(employeeId)
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = true
      this.employee = null
      this.lastSignin = null
      this.subscription = null
    },
    async getEmployeeDetails(employeeId) {
      try {
        const employeeData = await getCompanyEmployeeDetails(this.company._id, employeeId)

        this.employee = employeeData.user
        this.lastSignin = employeeData.lastSignin
        this.subscription = employeeData.subscription
      } catch (err) {
        this.closeDialog()
        this.$noty.error('Desculpe! Não foi possível buscar os dados deste colaborador. Tente novamente mais tarde.')
      } finally {
        this.loader = false
      }
    },
    formatPhoneNumber(number) {
      if (number) {
        const phonePrefix = number.slice(0,2)
        const phoneNumber = number.slice(2, number.length)

        return `(${phonePrefix}) ${phoneNumber}`
      }

      return ''
    },
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY HH:mm:ss')
      }

      return ''
    },
    getPlanName(planIdentifier) {
      if (planIdentifier) {
        return planIdentifier.includes('individual') ? 'Plano individual' : 'Plano familiar'
      }

      return 'Não identificado'
    },
    countMeetings(confirmations) {
      if (confirmations && typeof confirmations === 'object') {
        return Object.keys(confirmations).filter(k => confirmations[k] === true).length
      }

      return 0
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeDialog()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogCompanyEmployee', this.openDialog)
  }
}
</script>

<style scoped>
.container {
  max-width: 768px;
}
</style>
