<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    :persistent="loader"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey3">
      <v-card-title class="dialog-form-title" :class="isIOS && isMobile ? 'ios-padding' : ''">
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text">
          {{ note._id ? 'Editar' : 'Adicionar' }} Observação
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="input-label primary--text">Título</span>
                <v-text-field
                  dense
                  color="grey1"
                  v-model="note.title"
                  :error-messages="titleErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Observações</span>
                <v-textarea
                  dense
                  rows="3"
                  color="grey1"
                  v-model="note.text"
                  :error-messages="textErrors"
                ></v-textarea>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="pa-0">
                  <v-btn
                    text
                    small
                    rounded
                    class="px-5"
                    type="button"
                    @click="close"
                    color="primary"
                    :disabled="loader"
                  >
                    Cancelar
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    small
                    rounded
                    type="submit"
                    color="primary"
                    :loading="loader"
                    class="px-5 darkgrey--text"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import saveLifeInsuranceDependentsNote from '@/api/lifeInsurance/saveLifeInsuranceDependentsNote';

export default {
  name: 'DialogFormInsuranceDependentNote',
  data: () => ({
    dialog: false,
    loader: false,
    note: {
      title: '',
      text: ''
    },
    insuranceId: ''
  }),
  validations: {
    note: {
      title: {
        isValid(v) {
          return !!v && v.length <= 150
        }
      },
      text: {
        isValid(v) {
          return !!v && v.length <= 256
        }
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    titleErrors() {
      const errors = []

      if (!this.$v.note.title.$anyDirty) {
        return errors
      }

      !this.$v.note.title.isValid && errors.push('Preencha um título válido')

      return errors
    },
    textErrors() {
      const errors = []

      if (!this.$v.note.text.$anyDirty) {
        return errors
      }

      !this.$v.note.text.isValid && errors.push('Preencha uma observação válida')

      return errors
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.loader = false
      this.insuranceId = ''
      this.note = {
        text: '',
        title: ''
      }
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch()

      if (!this.$v.$anyError) {
        this.loader = true

        if (!this.note.date) {
          this.note.date = dayjs()
          this.note.updated = dayjs()
        } else {
          this.note.updated = dayjs()
        }

        saveLifeInsuranceDependentsNote(this.insuranceId, this.note)
          .then(note => {
            this.$store.dispatch('saveLifeInsuranceDependentsNote', {
              note,
              insuranceId: this.insuranceId
            })

            this.close()
          })
          .catch(err => {
            this.loader = false
            this.$noty.error('Desculpe! Não foi possível salvar esta observação. Tente novamente mais tarde!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.close()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormInsuranceDependentNote', data => {
      if (data.note) {
        this.note = JSON.parse(JSON.stringify(data.note))
      }

      if (data.insuranceId) {
        this.insuranceId = data.insuranceId
        this.dialog = true
      }
    });
  }
}
</script>
