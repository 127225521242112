import httpClient from "@/plugins/httpClient";

export default data => {
  return new Promise((resolve, reject) => {
    httpClient('/users/signup', {
      data,
      method: 'POST'
    })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}
