<template>
  <v-main :class="isIOS ? 'ios-padding' : ''">
    <v-container grid-list-md class="px-5">
      <v-layout row wrap>
        <v-flex xs12>
          <v-btn icon large @click="goBack" id="topo">
            <v-icon x-large color="darkgrey">mdi-chevron-left</v-icon>
          </v-btn>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <h1>Termo <br class="hidden-md-and-up">de Adesão<span class="primary--text">.</span></h1>
          <h2 class="doc-subtitle grey--text">
            Condições gerais e aceite eletrônico para utilização de serviços técnicos
            especializados
          </h2>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">1.0</span> Das informações gerais</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">1.1</span>
            O presente Termo de Adesão bem como a Política de Privacidade foram elaborados em respeito pleno e
            isonômico
            às legislações protetoras das relações de consumo, dos contratos de adesão, dos dados pessoais e do
            código
            de ética e condutas profissionais.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">1.2</span>
            A Uplanner é uma startup que visa implementar um modelo de negócio inexistente até o momento,
            elevando os
            riscos e desafios cuja previsibilidade é muito limitada notadamente considerando o envolvimento e
            desenvolvimento de novas tecnologias. Tem como objetivo a democratização do mercado de Planejamento
            Financeiro Pessoal no Brasil.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">2.0</span> Das definições</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">2.1</span>
            A Uplanner (gênero feminino): refere-se à empresa e sua respectiva razão social;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">2.2</span>
            O Uplanner (gênero masculino): refere-se ao Sistema Uplanner, que abrange Webapp e App;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">2.3</span>
            Fornecedor: É a UPLANNER SISTEMA DE PLANEJAMENTO FINANCEIRO LTDA.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">2.4</span>
            Usuário: É toda pessoa física ou jurídica, que aceita as condições de prestação de serviços elencados no
            neste Termo e Política de Privacidade, entendendo os direitos e obrigações a eles inerentes. Para todos os
            fins, entende-se que todos dentro do Uplanner como USUÁRIOS, diferenciados nos acessos elencadas no Item
            3.0.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">3.0</span> Dos acessos e vínculos com os usuários</h3>
          </div>

          <p class="doc-paragraph">
            As modalidades de acesso são divididas em:
          </p>

          <p class="doc-paragraph">
            <span class="sec-number-text">3.1 Planejador Uplanner: </span> É o planejador que presta serviços vinculado
            à Uplanner, atendendo de forma online os clientes Uplanner.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number-text">3.1.1 Cliente Uplanner: </span> É pessoa natural que no exercício de sua
            autonomia de vontade, contrata o serviço de planejamento diretamente com a Uplanner, sendo atendido por um
            planejador online, sendo capaz de mudar de planejador se/quando desejar.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number-text">3.2 Planejador terceiro: </span> É o planejador que contrata a tecnologia
            Uplanner para a execução plena de sua atividade.

          </p>

          <p class="doc-paragraph">
            <span class="sec-number">3.2.1</span>
            Clientes cadastrados pelo Planejador Terceiro terão vínculo permanente. O vínculo só poderá ser quebrado por
            opção do planejador ou quando o cliente a ele solicitar.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">3.2.2</span>
            Ao quebrar o vínculo de um cliente, este ficará com o acesso limitado ao fluxo de caixa sendo capaz de
            contratar a Uplanner, ficando livre para fazer mudanças de plano e planejadores.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">3.2.3</span>
            Caso o planejador cancele seu próprio acesso, poderá escolher a direção de seus clientes, podendo indicá-lo
            a um planejador parceiro usuário do Sistema ou indicá-los a um atendimento on-line realizado por um
            Planejador UPlanner.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number-text">3.3 Acesso empresa: </span> O acesso empresa destina-se as empresas de
            planejamento financeiro pessoal que visam aprimorar sua administração para com seus clientes. Para a devida
            monitoração a empresa cadastrará abaixo de si seus planejadores que por sua vez, cadastrarão seus clientes.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">3.3.1</span>
            Os clientes cadastrados pelo Acesso Empresa ou por seus planejadores possuirão com ela vínculo permanente.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">3.3.2</span>
            Ao desvincular um planejador a Empresa decidirá a redistribuição dos respectivos clientes, podendo
            vinculá-los a outro planejador de sua própria empresa.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">3.3.3</span>
            Os clientes poderão desvincular-se da empresa apenas mediante autorização da mesma, ficando com o acesso
            limitado ao fluxo de caixa, sendo capaz de contratar a Uplanner, com liberdade para fazer mudanças de plano
            e planejadores.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">4.0</span> Das declarações</h3>
          </div>

          <p class="doc-paragraph">
            Ao realizar o aceite, o Usuário declara:
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">4.1</span>
            A plenitude de sua capacidade civil e não sendo, que teve sua incapacidade suprida por meio de assistência
            de seu responsável ao concordar com as condições expostas, de acordo com o exigido no Código Civil
            Brasileiro de 2002.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">4.2</span>
            Que os documentos e informações cedidos pelo Usuário ao Fornecedor são verídicos, corretos, completos,
            consistentes e suficientes e estão atualizados até a data fornecida;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">4.3</span>
            Que Exerce suas atividades em conformidade com a legislação e regulamentação vigentes a elas aplicáveis, de
            acordo com o código de ética, não exercendo qualquer atividade ou comportamento ilícito;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">4.4</span>
            O Cliente UPlanner declara ciência e autorização de que para sua própria segurança as reuniões realizadas
            por Webconferência serão gravadas, e entregues mediante requisição judicial se necessário.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">4.5</span> Que o usuário esta de acordo com todas as cláusulas mencionadas neste
            Termo e em sua Política de Privacidade.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">5.0</span> Da utilização</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">5.1</span>
            Para que o usuário possa ter acesso às funcionalidades do sistema de planejamento financeiro Uplanner,
            deverá ler o presente termo, aceitar suas condições, por meio do clique “Aceito os Termos de Uso e Política
            de Privacidade”, e, por fim, realizar seu cadastro, não sendo a Uplanner responsável pela veracidade das
            informações;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">5.2</span>
            Em caso de novidades nas informações ou funcionalidades será comunicado ao Usuário de modo formal para seu
            exercício de direito quanto aos valores e condições de utilização.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">5.3</span>
            Caso o Usuário necessite de ajuda com qualquer funcionalidade, encontrará facilmente no e-mail
            <a href="mailto:contato@uplanner.com.br" class="primary--text">contato@uplanner.com.br</a>.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">5.4</span>
            O Fornecedor não será responsável sob qualquer hipótese, pelo tratamento oferecido às informações cedidas
            pelo próprio usuário a um Aplicativo Terceiro.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">5.5</span>
            Para entregar um serviço personalizado há compartilhamento de informações para com terceiros selecionados,
            qual chamaremos de Processadores Terceirizados, variados entre fornecedores de infraestrutura técnica a
            serviço de atendimento ao cliente e ferramentas de autenticação.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">5.5.1</span>
            É assegurado pela Uplanner a confiabilidade – obedecendo ao princípio da boa-fé contratual – do
            gerenciamento de informações feito em nosso nome por Processadores Terceirizados. Este processamento é
            realizado de acordo com a Lei nº 13.709, de 14 de agosto de 2018 - Lei Geral de Proteção de Dados Pessoais;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">5.5.2</span>
            Os Processadores Terceirizados com os quais podemos compartilhar elementos dos seus dados pessoais incluem,
            mas não se limitam a:
          </p>

          <ol type="a">
            <li>
              <p class="doc-paragraph">
                processadores de pagamento acionados por nós para armazenar ou gerenciar informações de pagamento com
                segurança, como detalhes de cartão de crédito ou débito;
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                fornecedores de gerenciamento de e-mail e ferramentas de distribuição;
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                fornecedores de serviços de segurança e prevenção de fraudes. (Utilizamos esses fornecedores para
                identificar agentes de SOFTWARE UPLANNER automatizado que podem prejudicar nossos serviços ou para
                prevenir o uso indevido de nossas APIs e proteger especialmente a você);
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                ornecedores de plataformas de SOFTWARE UPLANNER que nos ajudam na comunicação ou no fornecimento de
                serviços de atendimento ao cliente, por exemplo, gerenciamos e respondemos todas as mensagens enviadas
                por meio da nossa central de ajuda usando uma ferramenta terceirizada de gerenciamento de comunicações;
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                fornecedores relacionados ao sistema bancário;
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                fornecedores de serviços de armazenamento na nuvem online e outros serviços de TI essenciais;
              </p>
            </li>
          </ol>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">6.0</span> Dos requisitos técnicos</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">6.1</span>
            O Usuário poderá ter acesso ao UPlanner por meio de WebApp e APP.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">6.2</span>
            É recomentado ao Usuário o sigilo de seu acesso e senha sendo de sua exclusiva responsabilidade a manutenção
            de segredo sobre ela, utilizando-se de senha segura com caracteres maiúsculos e minúsculos e caracteres
            especiais;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">6.3</span>
            Para a observância da Lei Geral de Proteção de Dados Pessoais (lei 13.709/2018) são necessárias as seguintes
            práticas a serem adotadas pelo Usuário:
          </p>

          <ol type="a">
            <li>
              <p class="doc-paragraph">
                a instalação e a atualização rotineira de antivírus nos equipamentos e, sendo caso, nos servidores, de
                firewall (sistema ou combinação de sistemas que proteja a rede contra invasões externas e acessos não
                autorizados), e de antispyware (programa para evitar que um software “espião” - spyware - seja instalado
                na máquina de usuário e capture informações sobre os seus hábitos de navegação ou mesmo outros dados,
                enviando-os para terceiros quando da conexão à internet);
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                a verificação do remetente e a abertura de arquivos que tenham sido encaminhados por pessoas conhecidas
                e verificados pelos antivírus e antispyware;
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                a vedação de acesso a link enviado por e-mail para sites cujo conteúdo seja desconhecido ou suspeito de
                conter software malicioso.
              </p>
            </li>

            <li>
              <p class="doc-paragraph">
                a observância da integralidade da Lei Federal 13.709/2018 em todos os seus expressos termos.
              </p>
            </li>
          </ol>

          <p class="doc-paragraph">
            Parágrafo Único: O Usuário assumirá exclusivamente todo e qualquer dano e multa por ventura aplicada
            decorrente da inobservância da Lei Federal 13.709/2018.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">7.0</span> Das obrigações do usuário</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">7.1</span>
            É obrigação do usuário manter sua senha segura, sob sua responsabilidade as consequências de informá-la a
            terceiros.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">7.2</span>
            É obrigação do usuário efetuar o pagamento integral na data e valor pactuado.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">7.2.1</span>
            Em caso de não recebimento da fatura/link de pagamento o usuário poderá solicitar segunda via através do
            site ou e-mail:
            <a href="mailto:financeiro@uplanner.com.br" class="primary--text">financeiro@uplanner.com.br</a>
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">7.2.2</span>
            O eventual não recebimento da fatura/link de pagamento não desobriga o pagamento, constituindo de pleno
            direito em mora o devedor.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">7.2.3</span>
            No caso de não pagamento até a data do vencimento, o valor da fatura sofrerá
            acréscimo de 2% (dois por cento), a título de multa por atraso, e 1% (um por cento) ao mês, a título de
            juros de mora, calculados pro-rata-temporis desde a data do inadimplemento até o efetivo pagamento.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">7.3</span>
            É dever do Usuário Empresa e do Planejador Terceiro fornecer aos seus usuários infra cadastrados as
            informações necessárias e pertinentes sobre a Uplanner para sua necessária e correta utilização.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">7.3.1</span> Em caso de dúvidas seus clientes de igual modo poderão contatar nossa
            Central de ajuda uma vez que é destinada a todos os Usuários Desta, como mencionada no <strong>item
            5.3.</strong>
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">8.0</span> Das formas de pagamento</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">8.1</span>
            Para Usuários novos, o pagamento para adquirir e usufruir dos respectivos serviços, passa a seguir nos
            seguintes termos:
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">8.1.1</span>
            Entenda-se como novos usuários, aqueles que contratarem os serviços da Uplanner a partir da data de
            02/08/2022.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">8.2</span>
            Todos os serviços oferecidos serão contratados pelo prazo mínimo de 12 meses, conforme valores especificados
            no site que podem ser alterados sem aviso prévio. Sendo renovado automaticamente pelo mesmo período.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">8.2.1</span>
            Do Reajuste: o valor do serviço será reajustado todo mês de Janeiro, conforme indica IPCA, para novos e
            antigos contratos, independente do mês em que foi contratado. Nos reservamos ao direito de ajustar os
            valores, para baixo ou para cima, conforme estratégia comercial.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">8.3</span>
            O pagamento poderá ser realizado de duas formas: boleto ou cartão de crédito.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">8.3.1</span>
            Do pagamento com cartão de crédito
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">8.3.1.1</span>
            O pagamento poderá ser realizado de três formas: à vista, parcelado em até 12x ou pagamentos mensais
            recorrentes.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">8.3.2</span>
            Do pagamento com boleto
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">8.3.2.1</span>
            O pagamento poderá ser realizado apenas na modalidade à vista.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">9.0</span> Das reuniões avulsas</h3>
          </div>

          <p class="doc-paragraph">
            Entende-se por reuniões avulsas, a reunião extra, não incluída no pacote contratado. A reunião avulsa poderá
            ser solicitada pelo usuário, sempre que este sentir necessidade de consultar seu planejador mais de uma vez
            ao mês.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">9.1</span>
            O preço das reuniões avulsas independem do preço do pacote contratado. Sendo um pagamento extra, poderá ser
            contratado quantas vezes o usuário necessitar.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">9.2</span>
            As reuniões avulsas podem ser contratadas apenas na modalidade de pagamento à vista.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">10.0</span> Da renovação automática</h3>
          </div>

          <p class="doc-paragraph">
            Os serviços contratados são renovados automaticamente ao término do ciclo de pagamento de 12 meses, caso o
            usuário não manifeste o desejo pela não renovação através do e-mail <a
            href="mailto:cancelamento@uplanner.com.br">cancelamento@uplanner.com.br</a>.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">11.0</span> Do inadimplemento</h3>
          </div>

          <p class="doc-paragraph">
            Em nome da boa fé contratual, o inadimplemento não implicará no cancelamento imediato do usuário.
          </p>

          <p class="doc-paragraph">
            Em caso de cancelamento do cartão de crédito, dos usuários da modalidade de pagamento, do cartão de crédito
            à vista/parcelado, nossa equipe entrará em contato com o usuário, pelas formas de contato fornecidas por
            ele, para mudança de cartão de crédito no sistema, esclarecimentos ou eventuais negociações, visto que a
            compra deve ser paga de forma integral.
          </p>

          <p class="doc-paragraph">
            Após três meses de inadimplemento, sem negociação com a equipe especializada ou sem retornar as solicitações
            e tentativas de negociações da Equipe UPlanner, o usuário será notificado extrajudicialmente e será inscrito
            no Sistemas de Proteção ao Crédito.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">12.0</span> Da propriedade intelectual e da marca</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">12.1</span>
            Não é concedida licença de uso ao Usuário, relativos às marcas, às patentes, aos direitos autorais ou a
            qualquer outro direito de propriedade intelectual, salvo os expressamente mencionados neste instrumento e
            segundo as condições aqui estabelecidas.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">12.1.1</span>
            Qualquer menção ao nome comercial da Uplanner, bem como às marcas de seus serviços serão considerados
            inválidos, salvo se autorizados previamente e expressamente. De igual modo em relação aos materiais de
            venda, à promoções e divulgações dos SERVIÇO DE PLANEJAMENTO FINANCEIRO PESSOAL, que eventualmente venham a
            ser produzidos pelo Usuário Empresa ou pelo Planejador Terceiro.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">12.1.2</span>
            O FORNECEDOR poderá apoiar a criação de campanha ou material publicitário que envolva os seus serviços e a
            sua marca, autorizando o seu uso ou auxiliando na sua concepção e/ou confecção. Qualquer material produzido
            que possua relação a marca do FORNECEDOR necessitará, expressamente, de sua autorização.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">12.2</span>
            O FORNECEDOR poderá apoiar a criação de campanha ou material publicitário que envolva os seus serviços e a
            sua marca, autorizando o seu uso ou auxiliando na sua concepção e/ou confecção. Qualquer material produzido
            que possua relação a marca do FORNECEDOR necessitará, expressamente, de sua autorização.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">12.3</span>
            Durante a vigência deste contrato, o FORNECEDOR concede ao Usuário, licença limitada, revogável,
            intransferível e não exclusiva de uso da marca, exclusivamente para o os fins a que se destina;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">12.4</span>
            Os direitos relativos à propriedade intelectual do FORNECEDOR permanecerão de propriedade única e exclusiva
            dela.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">12.5</span>
            Na hipótese de rescisão ou término deste acordo por qualquer motivo, o usuário deverá cessar imediatamente o
            uso de toda e qualquer marca, logos, dispositivos, insígnias e propriedade intelectual do FORNECEDOR;
            removendo todos os sinais de publicidade que possam identificar o FORNECEDOR, sendo certo que Usuário
            permitirá acesso do FORNECEDOR nas suas dependências para verificar a execução de tais ações.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">12.6</span>
            O uso indevido ou inapropriado do nome e da marca do FORNECEDOR ou assunção de responsabilidades em nome
            desta, sujeitará o Usuário às medidas judiciais e administrativas cabíveis.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">13.0</span> Das obrigações das partes</h3>
          </div>

          <p class="doc-paragraph">
            As partes comprometem-se a manter sigilo sobre todas as informações comerciais ou técnicas, bem como
            documentação correlata, fornecidas para a finalidade necessária de sua laboração, referentes ao cumprimento
            das obrigações, inclusive as relativas aos detentores de senhas do serviço de consulta, e a não revelar tais
            informações, sob qualquer pretexto, em vista dos princípios do código de ética e conduta profissional bem
            como o sigilo comercial e industrial salvo quando requisitadas pelos órgãos governamentais competentes e
            pelo Poder Judiciário.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">14.0</span> Da responsabilidade corporativa e das práticas de compliance</h3>
          </div>

          <p class="doc-paragraph">
            <b>Para todos os efeitos, as partes declaram que:</b>
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">14.1</span> Não utilizam trabalho ilegal e comprometem-se a não utilizar práticas
            de trabalho análogo ao escravo ou mão-de-obra infantil de menor de 18 (dezoito) anos, salvo este na condição
            de aprendiz, observadas as disposições da Consolidação das Lei do Trabalho, seja direta ou indiretamente,
            por meio de seus fornecedores de produtos e de serviços;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">14.2</span> Não empregam menor de 18 (dezoito) anos, inclusive na condição de
            aprendiz, em locais prejudiciais à sua formação, ao seu desenvolvimento físico, psíquico, moral e social,
            bem como em locais e serviços perigosos ou insalubres, e em horários que não permitam a sua frequência à
            escola e, ainda, em horário noturno, compreendido entre às 22h e às 5h; 9.3 Não utilizam práticas
            discriminatórias e limitativas do acesso à relação de emprego ou de sua manutenção, tais como, mas não se
            limitando, a motivos de sexo, origem, raça, cor, condição física, religião, estado civil, idade, situação
            familiar ou estado gravídico;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">14.3</span> Pagam aos seus empregados, pelo menos, um salário mínimo, além dos
            benefícios previstos em lei;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">14.4</span> Cumprem a legislação trabalhista, quanto às horas de trabalho e aos
            direitos dos empregados e também não dificultam a participação dos empregados em sindicatos;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">14.5</span> Cumprem as normas de saúde, segurança e meio-ambiente, proporcionando
            um ambiente de trabalho seguro e saudável aos seus empregados;
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">14.6</span> Obrigam-se a proteger e a preservar o meio-ambiente, prevenindo e
            erradicando as práticas danosas, em observância à Política Nacional do Meio Ambiente e dos Crimes Ambientais
            e aos atos legais, normativos e administrativos relativos à área ambiental e correlatas, emanados pelas
            esferas Federal, Estaduais e Municipais.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">15.0</span> Da integração com conta(s) bancária(s)</h3>
          </div>

          <p class="doc-paragraph">
            <span class="sec-number">15.1</span> Para obter uma experiência mais personalizada e automatizada o Usuário
            poderá informar seus dados bancários (por exemplo, banco, agência, conta corrente/poupança, número do cartão
            de crédito, etc), juntamente com a senha de acesso ao internet banking, expressamente autorizando o <strong>Uplanner</strong>
            a acessar seus dados financeiros disponibilizados no internet banking das respectivas instituições
            financeiras.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">15.2</span> A única atuação da Uplanner em relação aos dados bancários fornecidos
            pelo Usuário é a de integrar essas informações ao seu sistema, de modo que o Usuário consiga usufruir de
            maneira mais automatizada. O <strong>Uplanner</strong> não movimenta valores, não realiza operações
            financeiras e nem interfere, de maneira alguma, nos ativos e contas bancárias informadas pelo Usuário.
          </p>

          <p class="doc-paragraph">
            <span class="sec-number">15.3</span> O Uplanner coleta as informações das plataformas das instituições
            financeiras informadas pelo Usuário, mas não revisa os dados obtidos. Por esse motivo, o Usuário deverá
            conferir as informações.
          </p>
        </v-flex>

        <v-flex xs12 class="mt-5">
          <div class="doc-sec-title mb-3">
            <h3><span class="sec-number">16.</span> Do foro competente</h3>
          </div>

          <p class="doc-paragraph">
            Fica eleito o foro da Curitiba - PR para dirimir qualquer dúvida oriunda deste contrato, com renúncia de
            qualquer outro, por mais privilegiado que seja.
          </p>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

export default {
  name: 'Termos',
  // metaInfo() {
  //   return {
  //     title: "Termo de adesão",
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo)
  //     }
  //   };
  // },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    }
  },
  methods: {
    goBack() {
      window.history.back();
    }
  },
  beforeMount() {
    this.$vuetify.theme.light = true;
    this.$vuetify.theme.dark = false;
  }
};
</script>

<style scoped>
.v-main {
  background-color: #f6f6f6 !important;
}

.container {
  max-width: 600px !important;
}

h1 {
  font-weight: 900;
  font-size: 40px !important;
  line-height: 1em;
}

.doc-subtitle {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2em;
}

.doc-sec-title::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  background: #D1AC68;
  margin-top: 2px;
  margin-left: -5px;
}

.doc-sec-title h3 {
  font-size: 20px;
  font-weight: 900;
  line-height: 1em;
}

.sec-number {
  font-size: 14px;
  color: grey;
}

.doc-paragraph,
.sec-number-text {
  font-size: 16px;
  line-height: 1.5em;
}

.sec-number-text {
  color: grey;
  font-weight: bold;
}
</style>
