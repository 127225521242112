<template>
  <v-card color="grey3">
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 class="mb-2 d-flex align-center justify-space-between">
            <h1 class="payer-title white--text">Lista de Colaboradores<span class="purple--text">.</span></h1>
            <v-btn
              dark
              small
              rounded
              color="purple"
              :loading="exportLoader"
              @click="exportEmployees"
              v-if="employees.length && canExport"
            >
              <v-icon small class="mr-2" color="white">
                mdi-file-excel-outline
              </v-icon>
              Exportar
            </v-btn>
          </v-flex>

          <v-flex xs12>
            <v-list dense two-line color="transparent">
              <v-list-item
                :key="item._id"
                v-for="item in employeesPerPage"
              >
                <v-list-item-avatar>
                  <v-avatar color="darkgrey">
                    <v-icon color="purple">mdi-account-outline</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.displayName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ getPlanName(item.plan.identifier) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <div class="mt-3">
              <v-pagination
                dark
                circle
                color="purple"
                v-model="pagination.page"
                :length="pagination.totalPages"
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';
import exportCompanyEmployees from '@/api/companies/exportCompanyEmployees';

export default {
  name: 'CardCompanyHistoryEmployees',
  props: ['employees', 'companyId', 'period', 'canExport'],
  data: () => ({
    numberToCurrency,
    pagination: {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      itemsPerPage: 5
    },
    exportLoader: false
  }),
  computed: {
    employeesPerPage() {
      return this.employees.slice(
        ((this.pagination.page - 1) * this.pagination.itemsPerPage),
        (((this.pagination.page - 1) * this.pagination.itemsPerPage) + this.pagination.itemsPerPage)
      )
    }
  },
  methods: {
    getPlanName(identifier) {
      if (identifier.includes('familia')) {
        return 'Plano Familiar'
      } else if (identifier.includes('individual')) {
        return 'Plano Individual'
      }

      return ''
    },
    exportEmployees() {
      if (this.canExport) {
        this.exportLoader = true

        exportCompanyEmployees(this.companyId, this.period)
          .then(() => {
            this.exportLoader = false
          })
          .catch(err => {
            this.exportLoader = false
            this.$noty.error('Falha ao exportar arquivo. Tente novamente mais tarde!')

            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    }
  },
  mounted() {
    this.pagination.page = 1
    this.pagination.totalItems = this.employees.length
    this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
  }
}
</script>

<style scoped>
.payer-title {
  font-size: 18px;
}

.payer-value {
  font-size: 15px;
  font-weight: bold;
}

.payer-desc {
  font-size: 9px;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: .1em;
}

@media only screen and (min-width: 414px) {
  .payer-value {
    font-size: 18px;
  }

  .payer-desc {
    font-size: 10px;
  }
}
</style>
