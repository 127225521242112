<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    :fullscreen="isMobile"
    persistent
  >
    <v-card>
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="primary"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="primary--text ml-4">
           Confirmação de pagamento de Recompensa
        </span>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12>
              <span class="input-label primary--text">Nome Completo</span>
              <v-text-field
                readonly
                type="text"
                xs12 sm6 md4
                dense
                :value="details.completeName"
              >
              </v-text-field>
            </v-flex>

            <v-flex xs12 sm6>
              <span class="input-label primary--text">Agência</span>
              <v-text-field
                readonly
                type="text"
                xs12 sm6 md4
                dense
                :value="details.agency"
              >
              </v-text-field>
            </v-flex>

            <v-flex xs12 sm6>
              <span class="input-label primary--text">Conta (com dígito verficador)</span>
              <v-text-field
                readonly
                xs12 sm6 md4
                dense
                :value="details.account"
              >
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <span class="input-label primary--text">Banco</span>
              <v-text-field
                readonly
                dense
                color="primary"
                xs12 sm6 md4
                :value="details.bankName"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 @click="copyFunction">
              <span class="input-label primary--text">Chave pix</span>
              <v-text-field
                id="keypix"
                dense
                color="primary"
                append-outer-icon="mdi-content-copy"
                :value="details.keyPix"
              >
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <span class="input-label primary--text">Valor a ser reembolsado</span>
              <v-text-field
                readonly
                dense
                :value="numberToCurrency(details.valueReward)"
              >
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <p class="dialog-form-description">
                Para finalizar o processo faça o upload do comprovante de transferência ou
                pix
              </p>
            </v-flex>

            <v-flex xs12>
              <input
                class="style-upload"
                type="file"
                id="input-file"
                ref="fileInput"
                @change="onImageSelected"
                accept=".png"
              >
            </v-flex>

            <v-flex xs12 class="mt-5">
              <v-card-actions class="mb-4 pa-0">
                <v-spacer></v-spacer>
                <v-btn
                  dark
                  rounded
                  class="px-5 darkgrey--text"
                  color="primary"
                  :loading="uploadLoader"
                  @click.stop="save"
                  :disabled="uploadLoader"
                >
                  Confirmar pagamento
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import getRewardRequestById from '@/api/rewardRequests/getRewardRequestById';
import updateRewardRequest from '@/api/rewardRequests/updateRewardRequest';
import Jimp from 'jimp';
import firebase from 'firebase/app';
import numberToCurrency from '@/utils/numberToCurrency';

export default {
  name: 'DialogDetailsPendingRewards',
  data: () => ({
    dialog: false,
    loader: false,
    uploadLoader: false,
    urlImage: '',
    numberToCurrency,
    details: {
      '_id': '',
      'valueReward': 0,
      'accomplished': false,
      'agency': '',
      'account': '',
      'bankName': '',
      'keyPix': '',
      'completeName': '',
      'client': '',
      'recommendationItem': '',
      'file': '',
      'urlPaymentVoucher': ''
    }
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    requestReward() {
      return this.$store.getters.requestReward;
    }
  },
  methods: {
    onImageSelected(event) {
      this.uploadLoader = true;

      let reader = new FileReader();
      let file = event.target.files[0];

      reader.onload = () => {
        Jimp.read(reader.result)
          .then(image => {
            image.quality(70);

            return image.getBase64Async(Jimp.MIME_JPEG);
          })
          .then(base64 => {
            this.image = base64;

            let storageRef = firebase.storage().ref();
            let profileRef = storageRef.child(`${this.details._id}.jpeg`);

            return profileRef.putString(base64, 'data_url', { contentType: 'image/jpeg' });
          })
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(url => {
            this.urlImage = url;
            this.uploadLoader = false;
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') console.error(err);
            this.uploadLoader = false;
            this.$noty.error('Falha ao atualizar foto. Tente novamente!');
          });
      };
      reader.readAsDataURL(file);
    },
    reset() {
      this.urlImage = '';
      this.details = {
        '_id': '',
        'valueReward': 0,
        'accomplished': false,
        'agency': '',
        'account': '',
        'bankName': '',
        'keyPix': '',
        'completeName': '',
        'client': '',
        'recommendationItem': '',
        'file': '',
        'urlPaymentVoucher': ''
      };
    },
    close() {
      this.dialog = false;
      this.reset();
    },
    save() {
      this.loader = true;
      this.details.accomplished = true;
      this.details.urlPaymentVoucher = this.urlImage;

      let formData = new FormData();
      formData.append('file', this.details.file);

      updateRewardRequest(this.details)
        .then(() => {
         this.$bus.$emit("alterRewardRequest")
          this.$noty.success('Tudo certo!');
          this.loader = false;
          this.dialog = false;
        });
    },
    copyFunction() {
      let copyText = document.getElementById('keypix');

      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
    }
  },
  created() {
    this.$bus.$on('showDialogDetailsPendingRewards', (item) => {
      getRewardRequestById(item._id)
        .then(resp => {
          this.details = JSON.parse(JSON.stringify(resp));
        });
      this.dialog = true;
    });

    this.$bus.$on('closeDialogDetailsPendingRewards', () => {
      this.dialog = false;
    });
  }
};
</script>

<style scoped>
.style-upload {
  display: block;
  width: 100%;
  background: #919191;
  color: #131313;
  border: 1px solid #100f0f;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 0px 0px #070707;
  font-weight: 700;
  font-size: 10pt;
}
</style>


