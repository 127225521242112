<template>
  <div class="month-selector">
    <v-btn
      icon
      color="#9E9E9E"
      class="btn-month"
      @click.stop="changePeriod('back')"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <span class="month-name">{{ monthStr }}</span>

    <v-btn
      icon
      color="#9E9E9E"
      class="btn-month"
      @click.stop="changePeriod('next')"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
  import moment from 'moment-timezone'

  export default {
    name: 'ToolbarMonthSelector',
    data: () => ({
      monthStr: '',
      period: '',
      currentDate: null,
      year: ''
    }),
    methods: {
      changePeriod(type) {
        if (type === 'next')
          this.currentDate.add(1, 'months');
        else if (type === 'back')
          this.currentDate.subtract(1, 'months');

        this.period = this.currentDate.format('YYYY-MM');
        this.monthStr = this.currentDate.format('MMMM');

        if (this.currentDate.year() !== this.year)
          this.monthStr += `/${this.currentDate.year()}`;

        this.$bus.$emit('updatePeriod', this.period);
      }
    },
    created() {
      this.currentDate = moment().tz('America/Sao_Paulo');
      this.year = this.currentDate.year();
      this.changePeriod();
    }
  }
</script>
