<template>
  <v-card flat>
    <v-card-title>Deduções</v-card-title>
    <v-card-subtitle>Declaração completa</v-card-subtitle>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-layout row wrap>
          <v-flex lg4 md4 sm12 xs12>
            <v-text-field
              type="tel"
              color="grey1"
              v-money="money"
              label="Dependentes"
              id="deducao-dependentes"
              v-model="deducoesIrCompleto.dependentes"
            ></v-text-field>
          </v-flex>

          <v-flex lg4 md4 sm12 xs12>
            <v-text-field
              type="tel"
              color="grey1"
              v-money="money"
              id="despesas-instrucao"
              label="Despesas com instrução"
              v-model="deducoesIrCompleto.despesasInstrucao"
            ></v-text-field>
          </v-flex>

          <v-flex lg4 md4 sm12 xs12>
            <v-text-field
              type="tel"
              color="grey1"
              v-money="money"
              id="despesas-educacao"
              label="Despesas com educação"
              v-model="deducoesIrCompleto.despesasEducacao"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            rounded
            depressed
            class="px-5 darkgrey--text"
            type="submit"
            color="primary"
            :loading="loader"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money';
import saveConfigIr from '@/api/config/saveConfigIr';
import removeMoneyMask from '@/utils/removeMoneyMask';

export default {
  name: 'CardConfigDeducoesCompleta',
  directives: { money: VMoney },
  data: () => ({
    loader: false,
    money: {
      decimal: ',',
      precision: 2,
      prefix: 'R$ ',
      thousands: '.'
    },
    deducoesIrCompleto: {
      dependentes: 0,
      despesasInstrucao: 0,
      despesasEducacao: 0
    }
  }),
  computed: {
    config() {
      return this.$store.getters.configIr;
    }
  },
  methods: {
    resetMoneyInput(...values) {
      let input = document.getElementById('deducao-dependentes');
      if (input) {
        input.value = values[0];
        input.dispatchEvent(new Event('input'));
      }

      input = document.getElementById('despesas-educacao');
      if (input) {
        input.value = values[1];
        input.dispatchEvent(new Event('input'));
      }

      input = document.getElementById('despesas-instrucao');
      if (input) {
        input.value = values[2];
        input.dispatchEvent(new Event('input'));
      }
    },
    prepareData() {
      if (this.config.deducoesIrCompleto) {
        this.deducoesIrCompleto = JSON.parse(
          JSON.stringify(this.config.deducoesIrCompleto)
        );
        this.deducoesIrCompleto.dependentes = removeMoneyMask(
          this.deducoesIrCompleto.dependentes
        ).toFixed(2);
        this.deducoesIrCompleto.despesasEducacao = removeMoneyMask(
          this.deducoesIrCompleto.despesasEducacao
        ).toFixed(2);
        this.deducoesIrCompleto.despesasInstrucao = removeMoneyMask(
          this.deducoesIrCompleto.despesasInstrucao
        ).toFixed(2);

        this.resetMoneyInput(
          this.deducoesIrCompleto.dependentes,
          this.deducoesIrCompleto.despesasEducacao,
          this.deducoesIrCompleto.despesasInstrucao
        );
      }
    },
    submit() {
      this.loader = true;

      let deducoesIrCompleto = JSON.parse(
        JSON.stringify(this.deducoesIrCompleto)
      );

      deducoesIrCompleto.dependentes = removeMoneyMask(
        deducoesIrCompleto.dependentes
      );
      deducoesIrCompleto.despesasEducacao = removeMoneyMask(
        deducoesIrCompleto.despesasEducacao
      );
      deducoesIrCompleto.despesasInstrucao = removeMoneyMask(
        deducoesIrCompleto.despesasInstrucao
      );

      saveConfigIr(this.config._id, { deducoesIrCompleto })
        .then(config => {
          this.loader = false;
          this.$store.dispatch('setConfigIr', config);
          this.$noty.success('Dados salvos com sucesso!');
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
          this.$noty.error('Falha ao salvar dados! Tente novamente mais tarde');
        });
    }
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.prepareData();
      }
    }
  },
  mounted() {
    this.prepareData();
  }
};
</script>
