<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card color="grey3" v-if="invoiceId">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="error"
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="error--text ml-2">
          Remover Reembolso
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <p>Tem certeza que deseja remover este reembolso?</p>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            dark
            small
            class="mt-3"
            @click="submit"
            color="error"
            :loading="loader"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>


import removeRefund from '@/api/invoices/removeRefund';

export default {
  name: 'DialogRemoveInvoiceRefund',
  data: () => ({
    dialog: false,
    loader: false,
    invoiceId: null,
    refundId: null
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.invoiceId = null;
      this.refundId = null;
    },
    submit() {
      this.loader = true;

      removeRefund(this.invoiceId, this.refundId)
        .then(resp => {
          if (resp.creditCard) {
            this.$store.dispatch('saveCreditCard', resp.creditCard);
          }

          if (resp.invoice) {
            resp.invoice.creditCard = resp.creditCard._id;
            this.$store.dispatch('saveInvoice', resp.invoice);
          }

          this.close();
          this.$noty.success('Reembolso removido com sucesso!')
        })
        .catch(err => {
          this.loader = false;
          this.$noty.error('Desculpe! Não foi possível remover este reembolso. Tente novamente!');

          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    }
  },
  created() {
    this.$bus.$on('showDialogRemoveInvoiceRefund', data => {
      if (data.invoiceId && data.refundId) {
        this.refundId = data.refundId;
        this.invoiceId = data.invoiceId;
        this.dialog = true;
      }
    });
  }
}
</script>
