import httpClient from "../../plugins/httpClient";


const URL = 'https://brasilapi.com.br/api/cep/v1/'


const getAddressByCep = cep => {
  return new Promise((resolve, reject) => {
    try {
      httpClient(URL + cep)
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default getAddressByCep;
