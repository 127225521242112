<template>
  <v-card
    class="uplanner-slider-item"
    v-if="card"
    :color="card.color"
  >
    <v-progress-linear
      indeterminate
      color="white"
      v-show="loader"
    ></v-progress-linear>

    <v-card-title class="uplanner-slider-item-title">
      <img
        class="card-flag"
        :src="getImgUrl(card.flag)"
        @click="showDialogInvoice(card._id)"
      >

      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :disabled="!allowed">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list nav dense>
          <v-list-item @click="showForm(card)">
            Editar
          </v-list-item>

          <v-list-item to="/open-banking" v-if="card.pluggy">
            Detalhes
          </v-list-item>

          <v-list-item @click="showDialogArchiveCreditCard(card)">
            <span v-if="card.pluggy">{{ card.archived ? 'Ativar sincronização' : 'Desativar sincronização' }}</span>
            <span v-if="!card.pluggy">{{ card.archived ? 'Desarquivar' : 'Arquivar' }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>

    <v-card-text @click="showDialogInvoice(card._id)" style="cursor: pointer;">
      <div class="card-name white--text">
          <span class="card-name--text">
            {{ card.name.slice(0, 14) }}{{ card.name.length > 14 ? '...' : '' }}
          </span>

        <div class="card-invoice-total">
            <span class="card-invoice-total__value">
               <span>R$</span> {{ currency(invoiceValue) }}
            </span>
          <span class="card-invoice-total__desc">Fatura aberta</span>
        </div>
      </div>

      <v-progress-linear
        rounded
        reactive
        height="25"
        :buffer-value="100"
        class="black--text"
        color="grey lighten-4"
        :value="card.limit ? ((card.usedLimit * 100) / card.limit) : 0"
      >
        <template v-slot="{ value }">
          <strong v-if="card.limit">{{ Math.ceil((card.usedLimit * 100) / card.limit) }}%</strong>
          <strong v-else>0%</strong>
        </template>
      </v-progress-linear>

      <span class="card-limit white--text" v-if="card.limit">
        <span class="card-limit--desc">Limite disponível </span>
        <span class="card-limit--value">
          <span>R$</span> {{ currency(card.availableLimit) }}
        </span>
      </span>

      <span class="card-limit white--text" v-else>
        <span class="card-limit--desc">Limite flexível</span>
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import currency from '@/utils/currency';
import getInvoice from '@/api/invoices/getInvoice';
import getInvoiceTransactions from "@/api/transactions/getInvoiceTransactions";

export default {
  name: 'CardCreditCard',
  props: ['card'],
  data: () => ({
    currency,
    loader: false,
    period: null
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed;
    },
    invoiceValue() {
      if (this.card && this.period) {
        let invoice = this.$store.getters.invoices.find(invoice => {
          return invoice.creditCard === this.card._id && invoice.paymentDate.slice(0,7) === this.period
        })

        if (invoice) {
          return invoice.value
        }
      }

      return 0
    }
  },
  methods: {
    getImgUrl(flag) {
      let images = require.context('../../../../assets/credit-card/', false, /\.png$/);
      return images('./' + flag + '.png');
    },
    showDialogInvoice(creditCardId) {
      const period = this.$date().add(1, 'month').format('YYYY-MM');
      this.$bus.$emit('showDialogCustomerInvoiceDetails', { creditCardId, period });
    },
    showDialogCreditCardsList() {
      this.$bus.$emit('showDialogCreditCardsList');
    },
    showForm(creditCard) {
      if (this.allowed) {
        this.$bus.$emit('showDialogFormCreditCard', creditCard);
      }
    },
    async getInvoiceValue() {
      try {
        this.loader = true

        const currentDate = this.$date();

        let [year, month, day] = currentDate.format('YYYY-MM-DD').split('-');

        const close = this.card.close;
        const pay = this.card.pay;

        if (currentDate.date() > close) {
          month = parseInt(month) + 1;

          if (month < 10) {
            month = `0${month}`;
          } else if (month > 12) {
            month = 1;
            year = parseInt(year) + 1;
          }
        }

        if (close > pay) {
          month = parseInt(month) + 1;

          if (month < 10) {
            month = `0${month}`;
          } else if (month > 12) {
            month = 1;
            year = parseInt(year) + 1;
          }
        }

        if (month < 10 && typeof month === 'number') {
          month = `0${month}`;
        }

        const period = `${year}-${month}`;
        this.period = period

        let invoice = await getInvoice(this.card._id, period)
        await this.$store.dispatch('saveInvoice', invoice)

        await getInvoiceTransactions(invoice._id)
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.loader = false
      }
    },
    showDialogArchiveCreditCard(card) {
      if (this.allowed)
        this.$bus.$emit('showDialogArchiveCreditCard', card);
    },
    async getTransactions(invoiceId) {
      const transactions = await getInvoiceTransactions(invoiceId)
      transactions.forEach(tr => this.$store.dispatch('saveTransaction', tr))
    }
  },
  created() {
    this.getInvoiceValue()
  }
};
</script>
