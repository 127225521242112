<template>
  <v-dialog
    v-model="dialog"
    :persistent="loader"
    max-width="500px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="error"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="error--text">Remover ativo</span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <p class="white--text">
            Tem certeza que deseja remover este ativo?
          </p>

          <v-card-actions class="pa-0">
            <v-btn
              text
              small
              rounded
              class="px-5"
              type="button"
              @click="close"
              color="white"
              :disabled="loader"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              small
              dark
              rounded
              type="submit"
              color="error"
              :loading="loader"
              class="px-5"
            >
              Remover
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import removeLifeInsuranceInventoryAsset from '@/api/lifeInsurance/removeLifeInsuranceInventoryAsset';

export default {
  name: 'DialogDeleteInsuranceInventoryAsset',
  data: () => ({
    dialog: false,
    loader: false,
    insuranceId: null,
    assetId: null
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.insuranceId = null
      this.assetId = null
    },
    submit() {
      this.loader = true

      removeLifeInsuranceInventoryAsset(this.insuranceId, this.assetId)
        .then(lifeInsurance => {
          this.$store.dispatch('saveLifeInsurance', lifeInsurance)
          this.close()
        })
        .catch(err => {
          this.loader = false;
          this.$noty.error('Desculpe! Não foi possível remover este ativo. Tente novamente mais tarde!');

          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }
        })
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.close()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogDeleteInsuranceInventoryAsset', data => {
      if (data && data.insuranceId && data.assetId) {
        this.insuranceId = data.insuranceId
        this.assetId = data.assetId
        this.dialog = true
      }
    })
  }
}
</script>

<style scoped></style>
