<template>
  <div class="uplanner-slider" v-dragscroll>
    <v-card
      dark
      color="darkgrey"
      class="uplanner-slider-item pa-0 mr-2"
      v-for="item in items"
      :key="item.id"
    >
      <div class="d-flex align-center justify-space-between pa-4 mb-10">
        <div class="d-flex align-center">
          <v-img
            width="32"
            height="21"
            class="mr-1"
            :src="item.connector.imageUrl"
          ></v-img>

          <h2 class="pluggy-item-name white--text">
            {{ item.connector.name }}
          </h2>
        </div>

        <div class="d-flex align-center justify-end">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                block
                v-on="on"
                color="white"
                v-bind="attrs"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list nav>
              <v-list-item>
                <v-list-item-title>Remover conexão</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          small
          rounded
          color="warning"
          class="px-5 text-capitalize"
          @click="showWidget(item.id)"
        >
          Atualizar credenciais
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import pluggyWidget from "@/utils/pluggy/pluggyWidget";

export default {
  name: 'CarousselOpenBankingWithError',
  props: ['items'],
  methods: {
    showWidget(itemId) {
      pluggyWidget.loadScript()
      pluggyWidget.useAutomatic(itemId, pluggyWidget.onSuccess)
    }
  }
}
</script>
