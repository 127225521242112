<template>
  <v-card flat class="pa-0" color="transparent">
    <v-card-text class="pa-0">
      <v-container grid-list-md class="pa-0">
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-cash</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Renda desejada atual</span>
                <span class="item-data-value white--text">{{ numberToCurrency(rendaDesejada) }}</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-cash</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Renda projetada do INSS</span>
                <span class="item-data-value white--text">{{ numberToCurrency(rendas.rendaInss) }}</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-cash</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Outras fontes de renda</span>
                <span class="item-data-value white--text">{{ numberToCurrency(rendas.rendaOutros) }}</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12>
<!--            <div class="item">-->
<!--              <v-icon size="17" class="item-icon" color="grey1">mdi-cash</v-icon>-->
<!--              <div class="item-data">-->
<!--                <span class="item-data-label primary&#45;&#45;text">Renda líquida</span>-->
<!--                <span class="item-data-value white&#45;&#45;text">{{ numberToCurrency(rendaLiquida) }}</span>-->
<!--              </div>-->
<!--            </div>-->

            <div class="renda-liquida">
              <span class="renda-value primary--text">{{ numberToCurrency(rendaLiquida) }}</span>
              <span class="renda-desc grey1--text">Renda líquida</span>
            </div>
          </v-flex>

          <v-flex xs12 class="mt-6">
            <v-card-actions class="px-0 btn-container">
              <v-spacer></v-spacer>

              <v-btn
                rounded
                outlined
                class="px-5 mr-2"
                color="primary"
                v-if="unlock"
                :disabled="!allowed"
                @click="showDialogformRendaIndFinanceira"
              >
                Editar
              </v-btn>

              <v-badge
                bordered
                color="primary"
                overlap
                :content="rendas.items.length"
              >
                <v-btn
                  rounded
                  outlined
                  class="px-5"
                  color="primary"
                  @click="showDialogRendasIndFinanceira"
                >
                  Rendas desejadas
                </v-btn>
              </v-badge>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { orderBy } from 'lodash'
  import moment from 'moment-timezone'
  import numberToCurrency from "../../../../utils/numberToCurrency";
  import getRendaAtual from "../../../../utils/indFinanceira/getRendaAtual";

  export default {
    name: 'CardRendaIndFinanceira',
    data: () => ({
      numberToCurrency,
      currentDate: null
    }),
    computed: {
      allowed() {
        return this.$store.getters.allowed;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      rendas() {
        return this.indFinanceira.rendas;
      },
      items() {
        return orderBy(this.rendas.items, 'data');
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      rendaDesejada() {
        if (this.currentDate) {
          if (this.items.length === 1) {
            let dataRenda = moment(this.items[0].data);

            if (dataRenda.year() > this.currentDate.year() ||
              (dataRenda.year() === this.currentDate.year() && dataRenda.month() >= this.currentDate.month())) {
              return this.items[0].valor;
            }
          } else if (this.items.length) {
            let filtered = this.items.filter(it => {
              let dataRenda = moment(it.data);

              return (dataRenda.year() > this.currentDate.year() ||
                (dataRenda.year() === this.currentDate.year() && dataRenda.month() >= this.currentDate.month()));
            });

            if (filtered.length)
              return filtered[0].valor;
          }
        }

        return 0;
      },
      rendaLiquida() {
        let total = this.rendaDesejada - (this.rendas.rendaInss + this.rendas.rendaOutros);
        if (total < 0) total = 0;
        return total;
      },
      unlock() {
        return this.indFinanceira.period === this.currentDate.format('YYYY-MM');
      }
    },
    methods: {
      showDialogformRendaIndFinanceira() {
        this.$bus.$emit('showDialogformRendaIndFinanceira');
      },
      showDialogRendasIndFinanceira() {
        this.$bus.$emit('showDialogRendasIndFinanceira');
      }
    },
    created() {
      this.currentDate = moment().tz('America/Sao_Paulo');
    }
  }
</script>

<style scoped>
  .item {
    border-bottom: solid 2px #919191;
    display: flex;
    align-items: flex-end;
  }

  .item-icon {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .item-data {
    display: flex;
    flex-direction: column;
  }

  .item-data-label {
    font-size: 9px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .item-data-value {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .1em;
  }

  .renda-liquida {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }

  .renda-value {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .renda-desc {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 390px) {
    .btn-container {
      display: flex;
      flex-direction: column;
    }

    .btn-container .v-btn {
      margin-bottom: 5px;
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @media only screen and (min-width: 600px) {
    .item-data-label {
      font-size: 12px;
    }

    .item-data-value {
      font-size: 15px;
    }

    .renda-value {
      font-size: 24px;
    }

    .renda-desc {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 960px) {
    .v-icon.item-icon {
      font-size: 20px !important;
    }

    .item-data-value {
      font-size: 16px;
    }
  }
</style>
