import dayjs from 'dayjs'
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const exportCompanyEmployees = (companyId, period) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            headers: {
              Authorization: `Bearer ${idToken}`,
              'Content-Disposition': "attachment; filename=template.xlsx",
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
          }

          const url = `/companies/${companyId}/export-employees?period=${period}`

          return httpClient(url, options)
        })
        .then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute(
            'download',
            `UPlanner Colab - Colaboradores Fatura ${dayjs(period).format('MM/YYYY')}.xlsx`
          );

          document.body.appendChild(link);
          link.click();

          resolve()
        })
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default exportCompanyEmployees
