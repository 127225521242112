import firebase from 'firebase/app';

import store from '@/store/index';
import httpClient from '@/plugins/httpClient';

const saveAdminCategory = category =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let data = category;
        let method = category._id ? 'PUT' : 'POST';
        let url = `/categories${category._id ? '/' + category._id : ''}`;
        let headers = {
          role: store.getters.user.role,
          Authorization: `Bearer ${idToken}`
        };

        return httpClient(url, { data, method, headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });

export default saveAdminCategory;
