<template>
  <v-dialog
    v-model="dialog"
    max-width="960px"
    persistent
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey3" class="safe-area-bottom">
        <v-card-title
          class="dialog-form-title"
          :class="isIOS ? 'ios-padding' : ''"
        >
          <v-btn
            icon
            color="error"
            @click.stop="close"
            class="back-button"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span color="error" class="ml-4">Filtrar Transações</span>
        </v-card-title>
      <v-card-text>
        <v-container class="px-0 pt-5" grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm6 md4>
              <v-select
                dense
                return-object
                color="primary"
                item-text="text"
                :items="types"
                v-model="type"
                label="Tipo de Transação"
              ></v-select>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <v-select
                dense
                color="primary"
                item-value="value"
                item-text="text"
                :items="bankStatus"
                v-model="bankAccountStatus"
                label="Status da conta"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md4>
                <v-select
                  dense
                  color="primary"
                  item-color="primary"
                  :items="statusItens"
                  item-text="text"
                  item-value="value"
                  v-model="statusFilter"
                  label="Status da Transação"
                ></v-select>
              </v-flex>
            <v-flex xs12 sm6 md4>
              <v-select
                dense
                multiple
                return-object
                color="primary"
                item-text="name"
                :items="filteredBankAccounts"
                v-model="selectedBankAccounts"
                label="Conta bancária"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-select
                dense
                multiple
                return-object
                item-color="primary"
                :items="categories"
                item-text="name"
                v-model="categoriesFiltered"
                label="Categorias"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-select
                dense
                multiple
                return-object
                item-color="primary"
                :items="subCategories"
                item-text="name"
                v-model="subCategoriesFiltered"
                label="SubCategorias"
              ></v-select>
            </v-flex>
            </v-layout>
          </v-container>
      <v-row
        align="center"
        justify="space-around"
      >
      <v-btn
        rounded
        color="primary"
        :loading="loader"
        class="px-5 darkgrey--text"
        @click="apply"
      >
        Aplicar Filtro
      </v-btn>
      </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { orderBy } from 'lodash';
import dayjs from 'dayjs';

export default {
  name: 'DialogFormFilterTransactions',
  data: () => ({
    dialog: false,
    loader:false,
    periodFim:'',
    period:'',
    bankStatus: [
      { text: 'Todas', value: '' },
      { text: 'Ativas', value: false },
      { text: 'Arquivadas', value: true },
    ],
    bankAccountStatus: false,
    selectedBankAccounts: [],
    categoriesFiltered: [],
    subCategoriesFiltered: [],
    statusFilter: 'all',
    statusItens: [
      { text: 'Todas', value: 'all' },
      { text: 'Pagas/Recebidas', value: true },
      { text: 'Não pagas/Não recebidas', value: false }
    ],
    type: { text: 'Transações', value: 'all', type: ''},
    types: [
      { text: 'Todas', value: 'all', type: 'all', color: 'red', icon: 'mdi-clipboard-flow' },
      { text: 'Despesas', value: 'saida', type: 'despesa', color: 'error', icon: 'mdi-arrow-down' },
      { text: 'Receitas', value: 'entrada', type: 'receita', color: 'success', icon: 'mdi-arrow-up' },
      { text: 'Investimentos', value: 'investment', color: 'primary', icon: 'mdi-chart-line-variant' },
      { text: 'Transferências', value: 'isTransfer', color: 'info', icon: 'mdi-bank-transfer' },
    ],
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === 'customer'
    },
    user(){
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts
    },
    filteredBankAccounts() {
      return orderBy(this.bankAccounts.filter(ba => {
        return this.bankAccountStatus === '' || ba.archived === this.bankAccountStatus;
      }), 'name');
    },
    isMobile(){
      return this.$store.getters.isMobile
    },
    categories(){
       return orderBy(this.$store.getters.categories.filter(cat => {
         return !cat.archived && cat.user === this.user._id && (this.type.value === 'all' || cat.type === this.type.type);
       }), 'name')
    },
    subCategories(){
     const subcats = [];

     if(this.categoriesFiltered){
       this.categoriesFiltered.forEach(cat => {
         cat.subs.forEach(sub => subcats.push(sub))
       })
     }
      return orderBy(subcats, 'name')
    },
  },
  created() {
    this.$bus.$on('showDialogFormFilterTransactions', (filters) => {
        this.type = filters.type
        this.categoriesFiltered = filters.categories
        this.subCategoriesFiltered = filters.subCategories
        this.selectedBankAccounts = filters.selectedBankAccounts
        this.statusFilter = filters.statusFilter
        this.bankAccountStatus = filters.bankAccountStatus
        this.period = filters.period

      this.dialog = true;
    });
  },
  methods: {
    close(){
      this.dialog = false
      this.loader = false
    },
    apply() {
      this.loader = true

     let filters = {
       selectedBankAccounts: this.selectedBankAccounts,
       bankAccountStatus: this.bankAccountStatus,
       statusFilter: this.statusFilter,
       categories: this.categoriesFiltered,
       subCategories: this.subCategoriesFiltered,
       type: this.type,
       dateRangeText: this.dateRangeText,
       filterAplly: true
      }

      this.$bus.$emit('returnFilters', filters)
      this.dialog = false
      this.loader = false
    },
  },
  watch: {
    type(val){
      this.categoriesFiltered = []
      this.subCategoriesFiltered = []
    },
  }
}
</script>

