import httpClient from '@/plugins/httpClient';
import config from '@/config';

const deleteCalendlyWebhooks = (token, hookId) => {
  return new Promise((resolve, reject) => {
    try {
      const options = {
        method: `DELETE`,
        headers: {
          'X-TOKEN': token
        }
      }

      const url = `${config.calendly.api}/hooks/${hookId}`

      httpClient(url, options)
        .then(() => resolve())
        .catch(reject)
    } catch(err) {
      reject(err)
    }
  })
}

export default deleteCalendlyWebhooks
