<template>
  <v-card
    raised
    height="100%"
    class="d-flex flex-column justify-space-between"
    color="darkgrey"
  >
    <div class="account-bar">
      <div class="account-title">
        <v-avatar size="30" color="darkgrey" class="mr-2">
          <v-icon small :color="bankAccount.color">mdi-bank</v-icon>
        </v-avatar>
        <span class="account-title__content">{{ bankAccount.name }}</span>
      </div>

      <v-menu
        left
        bottom
        origin="right top"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            color="grey1"
            class="btn-menu"
            :disabled="!allowed"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense nav>
          <v-list-item
            @click="showDialogFormBankAccount(bankAccount)"
          >
            Editar
          </v-list-item>

          <v-list-item
            v-if="!bankAccount.pluggy"
            @click="showDialogFormInitialAmount(bankAccount)"
          >
            Alterar saldo inicial
          </v-list-item>

          <v-list-item
            v-if="bankAccount.pluggy"
            @click="showDetails(bankAccount.pluggy.item)"
          >
            Detalhes
          </v-list-item>

          <v-list-item
            @click="showDialogArchiveBankAccount(bankAccount)"
          >
            <span v-if="bankAccount.pluggy">
              {{ bankAccount.archived ? 'Ativar' : 'Desativar' }} sincronização
            </span>

            <span v-if="!bankAccount.pluggy">
              {{ bankAccount.archived ? 'Desarquivar' : 'Arquivar' }}
            </span>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="account-details">
      <div class="account-details-item">
        <div class="accoutn-details-item-label mr-1 grey1--text">Banco:</div>
        <div class="accoutn-details-item-value">{{ formatBankName(bankAccount.bank) }}</div>
      </div>

      <div class="account-details-item" v-if="!bankAccount.pluggy">
        <div class="accoutn-details-item-label mr-1 grey1--text"> Saldo Inicial:</div>
        <div class="accoutn-details-item-value">
          {{numberToCurrency(bankAccount.initialAmount)}}
        </div>
      </div>

      <div class="account-details-item">
        <div class="accoutn-details-item-label mr-1 grey1--text">{{ verifyPeriod }}</div>
        <div class="accoutn-details-item-value">R$ {{ getBalance(bankAccount) }}</div>
      </div>
    </div>
  </v-card>
</template>

<script>
import stringCapitalize from '../../../../utils/stringCapitalize';
import currency from '../../../../utils/currency';
import numberToCurrency from '../../../../utils/numberToCurrency';

export default {
  name: 'CardBankAccount',
  props: ['bankAccountId', 'period'],
  data: () => ({
    loader: false,
    year: null,
    month: null,
    currentDate: null,
    numberToCurrency
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    bankAccount() {
      return this.$store.getters.bankAccounts.find(ba => ba._id === this.bankAccountId)
    },
    allowed() {
      return this.$store.getters.allowed || this.user.role === 'admin';
    },
    verifyPeriod() {
      let [year, month] = this.period.split('-');
      if (
        year > this.year ||
        (month > this.month && year >= this.year)
      ) {
        return 'Saldo projetado:';
      }
      if (year == this.year && month == this.month) {
        return 'Saldo em conta:';
      }
      return 'Saldo:';
    }
  },
  methods: {
    showDetails(itemId) {
      this.$router.push(`/open-banking?item=${itemId}`)
      this.$bus.$emit('closeDialogMobileBankAccountsList')
    },
    formatBankName(bankName) {
      if (bankName)
        return stringCapitalize(bankName.replace(/-+/gm, ' '));
    },
    getBalance(bankAccount) {
      let balance = 0;
      let year, month;

      if (!this.period) {
        let date = new Date();

        year = date.getFullYear();
        month = date.getMonth() + 1;
      } else {
        [year, month] = this.period.split('-');
        year = parseInt(year);
        month = parseInt(month);
      }

      if (year == this.year && month == this.month) {
        balance = parseFloat(bankAccount.balance.toFixed(3));
      } else {
        let history = bankAccount.history.find(item => item.year == year && item.month == month);
        let [baYear, baMonth] = bankAccount.initialAmountDate.split('-');

        if (year > baYear || (year == baYear && month >= baMonth)) {
          if (history && (history.year > baYear || (history.year == baYear && history.month >= baMonth))) {
            balance = parseFloat(history.balance.toFixed(3));
          } else {
            balance = parseFloat(bankAccount.history[bankAccount.history.length - 1].balance.toFixed(3));
          }
        } else {
          balance = 0;
        }
      }

      return currency(balance);
    },
    showDialogFormBankAccount(bankAccount) {
      if (this.allowed)
        this.$bus.$emit('showDialogFormBankAccount', bankAccount);
    },
    showDialogFormInitialAmount(bankAccount) {
      if (this.allowed)
        this.$bus.$emit('showDialogFormInitialAmount', bankAccount);
    },
    showDialogArchiveBankAccount(bankAccount) {
      if (this.allowed)
        this.$bus.$emit('showDialogArchiveBankAccount', bankAccount);
    }
  },
  created() {
    this.currentDate = this.$date();
    this.year = this.$date().year();
    this.month = this.$date().month() + 1;
  }
};
</script>

<style scoped>
.account-bar {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-title {
  display: flex;
  align-items: center;
}

.account-title__content {
  font-size: 15px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.account-details {
  display: flex;
  padding: 0px 10px 10px 10px;
  flex-direction: column;
}

.account-details-item {
  display: flex;
}

.accoutn-details-item-label {
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.accoutn-details-item-value {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: .1em;
}
</style>
