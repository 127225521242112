<template>
  <v-main class="pb-10 pluggy">
    <OverlaySync v-if="itemsLoader" text="Buscando conexões..."/>

    <div class="open-banking_container">
      <div class="open-banking_row text-center py-9">
        <h1 class="open-banking_title primary--text">Open Banking</h1>
        <span class="open-banking_subtitle grey1--text">Automatização de lançamentos</span>
      </div>

      <div class="open-banking_row mt-10">
        <h1 class="open-banking_title primary--text mb-2">
          Conexões ({{ items.length }})
        </h1>

        <CarousselOpenBankingItems
          ref="carouselOpenBankingItems"
          :items="itemsWithError.concat(updatedItems)"
        />
      </div>

      <div class="open-banking_row my-6">
        <v-btn
          rounded
          color="primary"
          @click="showWidget"
          class="darkgrey--text px-5"
        >
          Adicionar nova conexão
        </v-btn>
      </div>

      <div class="open-banking_row" v-if="selectedItem">
        <v-divider></v-divider>
      </div>

      <div class="open-banking_row py-9" v-if="selectedItem">
        <h1 class="open-banking_title white--text">Detalhes de {{ selectedItem.connector ? selectedItem.connector.name : '' }}</h1>
        <OpenBankingAccountsExpansion :item="selectedItem"/>
      </div>
    </div>

    <DialogRemovePluggyItem/>
  </v-main>
</template>

<script>
import OverlaySync from "@/components/Global/OverlaySync"
import CarousselOpenBankingItems from "@/components/Customer/OpenBanking/caroussel/CarousselOpenBankingItems"
import OpenBankingAccountsExpansion from "@/components/Customer/OpenBanking/expansion/OpenBankingAccountsExpansion";

import pluggyItemStatusErrors from "@/utils/pluggy/pluggyItemStatusErrors";
import getPluggyItemsByUserId from "@/api/pluggyItems/getPluggyItemsByUserId"
import CarousselOpenBankingWithError
  from "@/components/Customer/OpenBanking/caroussel/CarousselOpenBankingItemsWithError";
import pluggyWidget from '../../utils/pluggy/pluggyWidget'
import DialogRemovePluggyItem from "@/components/Customer/Cashflow/dialogs/DialogRemovePluggyItem";

export default {
  name: 'OpenBanking',
  components: {
    DialogRemovePluggyItem,
    CarousselOpenBankingWithError,
    OpenBankingAccountsExpansion,
    OverlaySync,
    CarousselOpenBankingItems
  },
  data: () => ({
    items: [],
    selectedItem: null,
    itemsLoader: false
  }),
  computed: {
    user() {
      return this.$store.getters.user
    },
    updatedItems() {
      return this.items.filter(item => {
        return (!pluggyItemStatusErrors.status.includes(item.status) &&
          !pluggyItemStatusErrors.executionStatus.includes(item.executionStatus))
      })
    },
    itemsWithError() {
      return this.items.filter(item => {
        return (pluggyItemStatusErrors.status.includes(item.status) ||
          pluggyItemStatusErrors.executionStatus.includes(item.executionStatus))
      })
    }
  },
  methods: {
    async getPluggyItems() {
      try {
        this.itemsLoader = true
        this.items = await getPluggyItemsByUserId(this.user._id)
        this.$bus.$emit('setPluggyDefaultItem')
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        await this.$router.push('/')
      } finally {
        this.itemsLoader = false
      }
    },
    showWidget() {
      pluggyWidget.loadScript()
      pluggyWidget.useAutomatic(null, pluggyWidget.onSuccess)
    },
    setItem(itemId) {
      this.selectedItem = this.items.find(item => item.id === itemId)
    }
  },
  created() {
    this.getPluggyItems()
    this.$bus.$on('setPluggyItem', this.setItem)
    this.$bus.$on('refreshOpenBanking', this.getPluggyItems)
  }
}
</script>

<style scoped>
@import "../../styles/pluggy-items.css";
</style>
