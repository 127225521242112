<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="grey1"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="grey1--text ml-4">
          Desbloquear cliente
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <p>
          Tem certeza que deseja desbloquear este cliente? <br>
          <span class="error--text">Ao desbloqueá-lo, o cliente passará a ter acesso completo ao sistema!</span>
        </p>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            small
            color="primary"
            @click="submit"
            class="darkgrey--text"
            :loading="loader"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import unlockUser from '@/api/users/unlockUser';

export default {
  name: 'DialogUnlockCustomer',
  data: () => ({
    dialog: false,
    loader: false,
    user: null,
    planner: null,
    company: null
  }),
  methods: {
    close() {
      this.dialog = false
      this.loader = false
      this.user = null
      this.planner = null
      this.company = null
    },
    submit() {
      this.loader = true

      unlockUser(this.user, {
        planner: this.planner,
        customer: this.company
      })
      .then(resp => {
        if (resp.unlocked) {
          this.$noty.warning('Este usuário já foi desbloqueado por outro planejador!')
        } else {
          this.$noty.success('Usuário desbloqueado com sucesso!')
        }

        this.$bus.$emit('getUsersWithoutPlanner')
        this.$bus.$emit('managerGetUsers')

        this.close()
      })
      .catch(err => {
        this.loader = false
        this.$noty.error('Desculpe! Não foi possível desbloquear este usuário. Tente novamente mais tarde!')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      })
    }
  },
  created() {
    this.$bus.$on('showDialogUnlockCustomer', data => {
      if (data.user && data.planner) {
        this.user = data.user
        this.planner = data.planner
        this.company = data.company
        this.dialog = true
      }
    })
  }
}
</script>
