<template>
  <v-card>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap align-center>
          <v-flex xs11>
            <v-form @submit.prevent="listar" class="d-flex align-center">
              <v-text-field
                color="grey1"
                persistent-hint
                v-model="filter.nome"
                label="Pesquisar"
                hint="Nome indicado"
                class="mr-2"
                :disabled="loader"
              ></v-text-field>

              <v-btn
                icon
                large
                outlined
                depressed
                type="submit"
                color="grey1"
                :disabled="loader"
              >
                <i class="lni lni-search"></i>
              </v-btn>
            </v-form>
          </v-flex>

          <v-flex xs1>
            <v-menu
              bottom
              left
              v-model="menu"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on: menu }">
                <v-btn
                  icon
                  large
                  outlined
                  color="grey1"
                  v-on="{ ...menu }"
                  :disabled="loader"
                >
                  <i class="lni lni-funnel"></i>
                </v-btn>
              </template>

              <v-card width="300px" color="darkgrey">
                <v-card-text>
                  <v-form @submit.prevent="listar">
                    <v-autocomplete
                      label="Status da Recompensa"
                      color="grey1"
                      :items="status"
                      v-model="filter.status"
                    ></v-autocomplete>

                    <v-card-actions class="px-0">
                      <v-spacer></v-spacer>

                      <v-btn
                        small
                        block
                        rounded
                        color="primary"
                        class="px-5 darkgrey--text"
                        type="submit"
                        :loading="loader"
                      >
                        Filtrar
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-flex>


          <v-flex xs12>
            <v-data-table
              hide-default-footer
              :headers="headers"
              :items="formatDataTable"
              :loading="loader"
            >
              <template v-slot:item="props">
                <tr>
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.user }}</td>
                  <td class="text-center">{{ props.item.inviteAccepted }}</td>
                  <td class="text-center">{{ props.item.dateConfirmedPurchase }}</td>
                  <td class="text-center">{{ props.item.rewardEnjoyed }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>

          <v-flex xs12>
            <v-pagination
              circle
              color="primary"
              class="darkgrey--text"
              v-model="pagination.page"
              :length="pagination.pageCount"
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { orderBy } from 'lodash';
import getRecommendations from '@/api/recommendations/getRecommendations';
import dayjs from 'dayjs';

export default {
  name: 'tableAllRecommendations',
  data: () => ({
    status: ['Todas', 'Pendente', 'Concedida'],
    loader: false,
    recommendations: [],
    rec: [],
    filter: {
      nome: ''
    },
    pagination: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalItems: 0
    },
    headers: [
      {
        text: 'Cliente Indicado',
        value: 'name',
        align: 'start',
        class: 'text-center',
        sortable: true
      },
      {
        text: 'Por',
        value: 'user',
        align: 'start',
        class: 'text-center',
        sortable: true
      },
      {
        text: 'Convite aceito em',
        value: 'inviteAccepted',
        align: 'center',
        class: 'text-center',
        sortable: true
      },
      {
        text: 'Compra confirmada em',
        value: 'dateConfirmedPurchase',
        align: 'center',
        class: 'text-center',
        sortable: true
      },
      {
        text: 'Status da recompensa',
        value: 'rewardEnjoyed',
        align: 'center',
        class: 'text-center',
        sortable: true
      }
    ]
  }),
  methods: {
    listar() {
      getRecommendations(this.filter.nome, this.filter.status, this.pagination.page)
        .then(resp => {
          this.rec = resp.items;
          this.pagination.totalItems = resp.totalItems;
          this.pagination.pageCount = (resp.totalItems ? Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage) : 0);
          this.loader = false;
          this.menu = false;

        })
        .catch(err => {
          this.$noty.error('Algo deu errado!');
        });
    },
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY')
      }

      return ''
    }
  },
  computed: {
    plans() {
      return this.$store.getters.plans;
    },
    orderedPlans() {
      return orderBy(this.plans, ['name', 'identifier']);
    },
    formatDataTable() {
      return this.rec.map(b => {
        return {
          name: b.name,
          user: b.user.displayName,
          inviteAccepted: this.formatDate(b.dateAcceptInvite),
          rewardEnjoyed: b.rewardEnjoyed ? 'Concedida' : 'Pendente',
          dateConfirmedPurchase: this.formatDate(b.dateConfirmedPurchase)
        }
      })
    }
  },
  watch: {
    'filter.nome'() {
      if (!this.filter.nome) {
        this.listar();
      }
    },
    'pagination.page'() {
      this.listar();
    }
  },
  created() {
    this.listar();
  }
};
</script>
