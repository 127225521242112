<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="isMobile"
    max-width="768px"
  >
    <v-card class="no-radius">
      <v-card-title
        class="dialog-form-title px-2"
        :class="isIOS ? ['ios-padding', 'fixed-title'] : ''"
      >
        <v-btn
          icon
          class="mr-5"
          color="primary"
          @click.stop="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text">{{ item._id ? 'Editar' : 'Adicionar' }} Patrimônio</span>
      </v-card-title>

      <v-card-text class="px-2" :class="isMobile ? 'mobile-margin' : ''">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <span class="input-label primary--text">Tipo</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="item.tipo"
                  :items="tipos"
                  :error-messages="itemTipoErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12 md6 v-if="item.tipo === 'Outro'">
                <span class="input-label primary--text">Nome do tipo</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="otherType"
                  :error-messages="otherTypeErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 :md6="item.tipo !== 'Outro'">
                <span class="input-label primary--text">Descrição</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="item.descricao"
                  :error-messages="itemDescricaoErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Proprietário</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="item.proprietario"
                  :items="proprietarios"
                  :error-messages="itemProprietarioErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 v-if="item.isShared && proprietarios.length === 3">
                <span class="input-label primary--text">Percentual de {{ proprietarios[0] }}</span>
                <v-text-field
                  type="number"
                  color="grey1"
                  class="mt-0 pt-0"
                  suffix="%"
                  v-model.number="item.percentualUsuario"
                  :error-messages="itemPercentualUsuarioErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 v-if="item.isShared && proprietarios.length === 3">
                <span class="input-label primary--text">Percentual de {{ proprietarios[1] }}</span>
                <v-text-field
                  type="number"
                  color="grey1"
                  class="mt-0 pt-0"
                  suffix="%"
                  v-model.number="item.percentualConjuge"
                  :error-messages="itemPercentualConjugeErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 :sm4="showExtraInputs">
                <span class="input-label primary--text">Valor Atual</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-money="money"
                  v-model="item.valorAtual"
                  :error-messages="itemValorAtualErrors"
                  id="pat-item-valor-atual"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 v-if="showExtraInputs">
                <span class="input-label primary--text">Saldo a pagar</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-money="money"
                  v-model="item.saldoPagar"
                  :error-messages="itemSaldoPagarErrors"
                  id="pat-item-saldo-atual"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 v-if="showExtraInputs">
                <span class="input-label primary--text">Taxa</span>
                <v-text-field
                  type="number"
                  color="grey1"
                  class="mt-0 pt-0"
                  suffix="%"
                  v-model.number="item.taxa"
                  :error-messages="itemTipoErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 v-if="showExtraInputs">
                <span class="input-label primary--text">Observações</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="item.obs"
                  :error-messages="itemObsErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="px-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    rounded
                    type="submit"
                    color="primary"
                    :loading="loader"
                    class="px-10 darkgrey--text"
                  >
                    Salvar
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money';
import removeMoneyMask from '@/utils/removeMoneyMask';
import stringCapitalize from '@/utils/stringCapitalize';
import saveItemPatrimonio from '@/api/patrimonio/saveItemPatrimonio';

export default {
  name: 'DialogFormPatrimonio',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    item: {
      tipo: '',
      descricao: '',
      proprietario: '',
      percentualUsuario: 50,
      percentualConjuge: 50,
      valorAtual: 0,
      saldoPagar: 0,
      taxa: 0,
      isSpouse: false,
      isShared: false,
      obs: ''
    },
    otherType: '',
    tipos: [
      'Imóvel',
      'Móvel',
      'Empréstimos/Dívidas',
      'Aplicações financeiras',
      'Previdência',
      'Outro'
    ],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    }
  }),
  validations: {
    item: {
      tipo: {
        isValid(v) {
          return !!v && this.tipos.includes(v);
        }
      },
      descricao: {
        isValid: v => !!v && v.length <= 150
      },
      proprietario: {
        isValid(v) {
          return !!v && this.proprietarios.includes(v);
        }
      },
      percentualUsuario: {
        isValid(v) {
          return !this.isShared || (v > 0 && v <= 100);
        }
      },
      percentualConjuge: {
        isValid(v) {
          return !this.isShared || (v > 0 && v <= 100);
        }
      },
      valorAtual: {
        isValid: v => !!v && removeMoneyMask(v) >= 0
      },
      saldoPagar: {
        isValid(v) {
          return !this.showExtraInputs || (!!v && removeMoneyMask(v) >= 0);
        }
      },
      taxa: {
        isValid(v) {
          return !this.showExtraInputs || v >= 0;
        }
      },
      obs: {
        isValid: v => !v || v.length <= 150
      }
    },
    otherType: {
      isValid(v) {
        return this.item.tipo !== 'Outro' || (!!v && v.length <= 150);
      }
    }
  },
  computed: {
    // error messages
    otherTypeErrors() {
      if (this.$v.otherType.$anyError) {
        return ['Preencha um tipo válido'];
      }

      return [];
    },
    itemTipoErrors() {
      if (this.$v.item.tipo.$anyError) {
        return ['Selecione um tipo válido'];
      }

      return [];
    },
    itemDescricaoErrors() {
      if (this.$v.item.descricao.$anyError) {
        return ['Preencha uma descrição válida'];
      }

      return [];
    },
    itemPercentualUsuarioErrors() {
      if (this.$v.item.percentualUsuario.$anyError) {
        return ['Preencha um percentual válido'];
      }

      return [];
    },
    itemProprietarioErrors() {
      if (this.$v.item.proprietario.$anyError) {
        return ['Selecione um proprietário válido'];
      }

      return [];
    },
    itemPercentualConjugeErrors() {
      if (this.$v.item.percentualConjuge.$anyError) {
        return ['Preencha um percentual válido'];
      }

      return [];
    },
    itemValorAtualErrors() {
      if (this.$v.item.valorAtual.$anyError) {
        return ['Preencha um valor válido'];
      }

      return [];
    },
    itemSaldoPagarErrors() {
      if (this.$v.item.saldoPagar.$anyError) {
        return ['Preencha um saldo válido'];
      }

      return [];
    },
    itemTaxaErrors() {
      if (this.$v.item.taxa.$anyError) {
        return ['Preencha uma taxa válida'];
      }

      return [];
    },
    itemObsErrors() {
      if (this.$v.item.obs.$anyError) {
        return ['Preencha uma observação válida'];
      }

      return [];
    },
    // helpers
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    showExtraInputs() {
      return ['Imóvel', 'Móvel', 'Empréstimos/Dívidas'].includes(this.item.tipo);
    },
    // data
    perfil() {
      return this.$store.getters.perfil;
    },
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === 'customer'
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer
    },
    patrimonio() {
      return this.$store.getters.patrimonio;
    },
    proprietarios() {
      const proprietarios = [];

      if (this.dialog) {
        if (this.perfil && this.perfil.informacoesBasicas &&
          this.perfil.informacoesBasicas.usuario && this.perfil.informacoesBasicas.usuario.nome) {
          proprietarios.push(this.perfil.informacoesBasicas.usuario.nome);
        } else if (this.user) {
          proprietarios.push(this.user.displayName)
        }

        if (this.perfil && this.perfil.informacoesBasicas &&
          this.perfil.informacoesBasicas.conjuge && this.perfil.informacoesBasicas.conjuge.nome) {
          proprietarios.push(this.perfil.informacoesBasicas.conjuge.nome);
        }

        proprietarios.unshift('Compartilhado');
      }

      return proprietarios;
    }
  },
  methods: {
    openDialog(item) {
      if (item) {
        this.item = JSON.parse(JSON.stringify(item));

        this.item.valorAtual = this.item.valorAtual.toFixed(2);
        this.item.saldoPagar = this.item.saldoPagar.toFixed(2);

        this.dispatchMoneyInputs();
      }

      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.loader = false;
      this.otherType = '';
      this.item = {
        tipo: '',
        descricao: '',
        proprietario: '',
        percentualUsuario: 50,
        percentualConjuge: 50,
        valorAtual: 0,
        saldoPagar: 0,
        taxa: 0,
        isSpouse: false,
        isShared: false,
        obs: ''
      };
      this.dispatchMoneyInputs();
      this.$v.$reset();
    },
    dispatchMoneyInputs() {
      let input = document.getElementById('pat-item-valor-atual');
      if (input) {
        input.value = this.item.valorAtual;
        input.dispatchEvent(new Event('input'));
      }

      input = document.getElementById('pat-item-saldo-pagar');
      if (input) {
        input.value = this.item.saldoPagar;
        input.dispatchEvent(new Event('input'));
      }
    },
    async submit() {
      try {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          const item = JSON.parse(JSON.stringify(this.item));

          item.valorAtual = removeMoneyMask(item.valorAtual);
          item.saldoPagar = removeMoneyMask(item.saldoPagar);

          if (item.tipo === 'Outro') {
            item.tipo = stringCapitalize(this.otherType);
          }

          const savedItem = await saveItemPatrimonio(this.patrimonio._id, item);
          await this.$store.dispatch('saveItemPatrimonio', savedItem);

          this.$noty.success('Dados salvos com sucesso!');
          this.closeDialog();
        }
      } catch (err) {
        this.$noty.error('Desculpe! Não foi possível salvar este item. Tente novamente mais tarde.');

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.loader = false;
      }
    }
  },
  watch: {
    'item.isShared'() {
      this.item.percentualUsuario = 50;
      this.item.percentualConjuge = 50;
    },
    'item.proprietario'(val) {
      this.item.isShared = val === 'Compartilhado';
      this.item.isSpouse = (!!this.perfil.informacoesBasicas.conjuge && val === this.perfil.informacoesBasicas.conjuge.nome);
    },
    'item.percentualUsuario'() {
      if (this.item.percentualUsuario < 0) {
        this.item.percentualUsuario = 0;
      } else if (this.item.percentualUsuario > 100) {
        this.item.percentualUsuario = 100;
      }

      if (this.item.isShared) {
        this.item.percentualConjuge = 100 - this.item.percentualUsuario;
      }
    },
    'item.percentualConjuge'() {
      if (this.item.percentualConjuge < 0) {
        this.item.percentualConjuge = 0;
      } else if (this.item.percentualConjuge > 100) {
        this.item.percentualConjuge = 100;
      }

      if (this.item.isShared) {
        this.item.percentualUsuario = 100 - this.item.percentualConjuge;
      }
    },
    'item.tipo'(val) {
      if (val !== 'Outro') {
        this.otherType = '';
        this.$v.otherType.$reset();
      }
    },
    'showExtraInputs'(val) {
      if (!val) {
        this.item.obs = '';
        this.item.taxa = 0;
        this.item.saldoPagar = '0.00';
        this.dispatchMoneyInputs();
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormPatrimonio', this.openDialog);
  }
};
</script>

<style scoped>
.v-card__title.dialog-form-title.fixed-title {
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 10;
}

.v-card__text.mobile-margin {
  padding-top: 90px !important;
}
</style>
