<template>
  <v-main>
    <OverlaySync
      :overlay="loader"
      text="Buscando dados..."
    />

    <SystemBar
      :style="isIOS ? 'margin-top: 98px' : 'margin-top: 68px'"
      class="safe-area-top"
      :subscription-id="customer.iuguSubscription"
      :user-name="customer.displayName"
      :plan-name="customer.plan ? customer.plan.name : ''"
      v-if="customer"
    />

    <v-container v-if="customer" fluid grid-list-md>
      <v-flex xs12>
        <v-tabs
          grow
          show-arrows
          v-model="tabs"
          background-color="transparent"
        >
          <v-tab
            :key="i"
            v-for="(tab, i) in items"
          >
            {{ tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <Perfil/>
          </v-tab-item>

          <v-tab-item>
            <Cashflow/>
          </v-tab-item>

          <v-tab-item>
            <Transactions/>
          </v-tab-item>

          <v-tab-item>
            <Orcamento/>
          </v-tab-item>

          <v-tab-item>
            <Relatorios/>
          </v-tab-item>

          <v-tab-item>
            <IndFinanceira/>
          </v-tab-item>

          <v-tab-item>
            <Objetivo/>
          </v-tab-item>

          <v-tab-item>
            <Investimentos/>
          </v-tab-item>

          <v-tab-item>
            <SeguroVida/>
          </v-tab-item>

          <v-tab-item>
            <PlanosAcao/>
          </v-tab-item>

          <v-tab-item>
            <CategoriesPlanner/>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
  import getUserById from '../../api/users/getUserById';
  import OverlaySync from '../../components/Global/OverlaySync';
  import MenuPlanner from '../../components/Dashboard/menus/MenuPlanner';
  import SystemBar from '../../components/Planner/customer/bars/SystemBar';
  import Cashflow from '../customer/Cashflow';
  import Transactions from '../customer/Transactions';
  import Orcamento from '../customer/Orcamento';
  import Relatorios from '../customer/Relatorios';
  import IndFinanceira from '../customer/IndFinanceira';
  import Perfil from '../customer/Perfil';
  import CategoriesPlanner from '../../components/Customer/Categorias/views/CategoriesPlanner';
  import Objetivo from '../customer/Objetivo';
  import Investimentos from '@/views/customer/Investimentos';
  import getCustomerData from '@/utils/getCustomerData';
  import PlanosAcao from '@/views/customer/PlanosAcao';
  // import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';
  import SeguroVida from '@/views/customer/SeguroVida';

  export default {
    name: `Customer`,
    // metaInfo() {
    //   return {
    //     title: "Cliente do planejador",
    //     changed(metaInfo) {
    //       sendMetaInfoData(metaInfo)
    //     }
    //   };
    // },
    components: {
      SeguroVida,
      PlanosAcao,
      Investimentos,
      Objetivo,
      CategoriesPlanner,
      Perfil,
      IndFinanceira,
      Relatorios,
      Orcamento,
      Transactions,
      Cashflow,
      SystemBar,
      MenuPlanner,
      OverlaySync
    },
    data: () => ({
      loader: true,
      tabs: `tab-1`,
      items: [
        'Perfil',
        'Visão geral',
        'Extrato',
        'Orçamento',
        'Relatórios',
        'Independência',
        'Objetivos',
        'Investimentos',
        'Seguro de vida',
        'Planos de ação',
        'Categorias'
      ]
    }),
    computed: {
      customer() {
        return this.$store.getters.customer;
      },
      isIOS() {
        return this.$store.getters.isIOS;
      }
    },
    methods: {
      getData() {
        this.loader = true;
        let id = this.$route.params.id;

        this.$store.dispatch(`resetCustomer`);

        if (id) {
          getUserById(id)
            .then(user => {
              this.$store.dispatch(`setCustomer`, user);
              return getCustomerData();
            })
            .then(() => {
              this.loader = false;
            })
            .catch(err => {
              this.$router.push(`/`);
              if (process.env.NODE_ENV !== `production`) {
                console.error(err);
              }
            })
        } else {
          this.$router.push(`/`);
        }
      }
    },
    beforeCreate() {
      this.$vuetify.theme.dark = true;
    },
    mounted() {
      this.getData();
    }
  }
</script>

<style scoped>
.container {
  max-width: 1366px !important;
}
</style>
