<template>
  <v-sheet width="100%" color="transparent">

    <div class="avatar-container">
      <input
        type="file"
        id="input-file"
        ref="fileInput"
        accept="image/*"
        @change="onImageSelected"
      >
      <v-badge
        bottom
        bordered
        offset-x="20"
        offset-y="15"
        color="primary"
        icon="mdi-pencil"
      >
        <v-avatar
          size="71"
          id="avatar"
          color="grey2"
          v-if="!uploadLoader"
          @click="canEdit ? $refs.fileInput.click() : ''"
        >

          <img v-if="user.photoURL" :src="user.photoURL">
          <v-icon v-else large color="grey1">mdi-account</v-icon>
        </v-avatar>

        <v-progress-circular
          :size="71"
          indeterminate
          color="primary"
          v-if="uploadLoader"
        ></v-progress-circular>
      </v-badge>

      <div class="name-container mt-3" @click="canEdit ? edit = !edit : ''">
        <v-slide-x-transition>
          <span class="ml-2 name-content primary--text" v-if="!edit">{{ user.displayName }}</span>
        </v-slide-x-transition>
        <v-slide-x-reverse-transition>
          <v-text-field
            dense
            v-if="edit"
            id="input-name"
            class="pa-0 ma-0"
            v-model="editName"
          ></v-text-field>
        </v-slide-x-reverse-transition>

        <v-icon class="ml-2" color="grey1" small v-if="canEdit">
          {{ edit ? 'mdi-check' : 'mdi-pencil' }}
        </v-icon>
      </div>
    </div>
  </v-sheet>
</template>

<script>
  import Jimp from 'jimp';
  import firebase from 'firebase/app'
  import AvatarCropper from "vue-avatar-cropper";
  import stringCapitalize from "../../../../utils/stringCapitalize";
  import OverlaySync from "../../../Global/OverlaySync";
  import saveUser from "../../../../api/users/saveUser";

  export default {
    name: 'CardPhoto',
    components: {OverlaySync, AvatarCropper },
    data: () => ({
      edit: false,
      editName: '',
      image: null,
      uploadLoader: false
    }),
    computed: {
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === `customer`;
      },
      isIOS() {
        return this.$store.getters.isIOS;
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      canEdit() {
        return false
      }
    },
    methods: {
      saveName() {
        let name = stringCapitalize(this.editName);

        if (name !== this.user.displayName) {
          this.user.displayName = name;
          firebase.auth().currentUser.updateProfile({ displayName: name });
        }

        this.editName = '';
      },
      onImageSelected(event) {
        this.uploadLoader = true;

        let reader = new FileReader();
        let file = event.target.files[0];

        reader.onload = () => {
          Jimp.read(reader.result)
            .then(image => {
              image.quality(70);
              image.resize(256, 256);

              return image.getBase64Async(Jimp.MIME_JPEG);
            })
            .then(base64 => {
              this.image = base64;

              let storageRef = firebase.storage().ref();
              let profileRef = storageRef.child(`${this.user._id}.jpeg`);

              return profileRef.putString(base64, 'data_url', {contentType: 'image/jpeg'});
            })
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then(url => firebase.auth().currentUser.updateProfile({ photoURL: url }))
            .then(() => {
              let user = JSON.parse(JSON.stringify(this.user));

              user.photoURL = firebase.auth().currentUser.photoURL;

              return saveUser(user);
            })
            .then(user => {
              this.$store.dispatch('setUser', user);
              this.uploadLoader = false;
            })
            .catch(err => {
              if (process.env.NODE_ENV !== 'production') console.error(err);
              this.uploadLoader = false;
              this.$noty.error('Falha ao atualizar foto. Tente novamente!');
            });
        }

        reader.readAsDataURL(file);
      }
    },
    watch: {
      edit() {
        if (this.edit) {
          this.editName = this.user.displayName;
          let input = document.getElementById('input-name');
          if (input)
            input.focus();
        } else {
          this.saveName();
        }
      }
    }
  }
</script>

<style scoped>
  .avatar-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .name-container {
    display: flex;
    align-items: center;
  }

  .name-content {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .1em;
  }

  #input-file {
    display: none;
  }

  .v-avatar {
    cursor: pointer !important;
  }
</style>
