<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="960px">
    <v-card>
      <v-card-title
        class="dialog-form-title"
        style="border-radius: 0 !important"
      >
        <v-btn icon color="white" @click.stop="close" class="back-button">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="ml-4"> Importar Extrato Bancário </span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-container class="px-0 pt-5" grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-select
                  v-model="importPdf"
                  label="Tipo de Arquivo"
                  :items="formatFile"
                >
                </v-select>
              </v-flex>

              <v-flex xs12 md4>
                <v-select
                  return-object
                  v-model="defaultFileType"
                  :items="fileType"
                  item-text="name"
                  label="Tipo do arquivo"
                  prepend-inner-icon="mdi-file-document"
                  single-line
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                md4
                v-if="
                  defaultFileType === 'Conta bancária' && importPdf === 'PDF'
                "
              >
                <v-select
                  :items="bankExtratoPdf"
                  item-text="name"
                  item-value="value"
                  label="Operadora"
                  v-model="bank"
                  prepend-inner-icon="mdi-bank"
                  single-line
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                md4
                v-if="
                  defaultFileType === 'Cartão de crédito' && importPdf === 'PDF'
                "
              >
                <v-select
                  :items="bankCreditCardsPdf"
                  item-text="name"
                  item-value="value"
                  label="Banco"
                  v-model="bank"
                  prepend-inner-icon="mdi-bank"
                  single-line
                ></v-select>
              </v-flex>

              <v-flex xs12 md4>
                <v-select
                  v-if="defaultFileType === 'Conta bancária'"
                  item-text="name"
                  item-value="value"
                  v-model="fileData.bankAccount"
                  :items="
                    importPdf === 'OFX' ? bankAccounts : filteredBankAccounts
                  "
                  label="Conta bancária"
                  prepend-inner-icon="mdi-bank"
                  :error-messages="bankAccountErrors"
                  @change="$v.fileData.bankAccount.$touch()"
                  return-object
                  single-line
                >
                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color"
                      >mdi-bank</v-icon
                    >
                    <span>{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>

                <v-select
                  v-else
                  return-object
                  v-model="fileData.creditCard"
                  :items="
                    importPdf === 'OFX' ? creditCards : filteredCreditCards
                  "
                  item-text="name"
                  label="Cartão de crédito"
                  prepend-inner-icon="mdi-credit-card"
                  single-line
                  :error-messages="creditCardErrors"
                  @change="$v.fileData.creditCard.$touch()"
                >
                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color"
                      >mdi-bank</v-icon
                    >
                    <span>{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12 md8 v-if="importPdf === 'PDF'">
                <v-file-input
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="file"
                  accept=".pdf"
                  label="Selecione o PDF"
                  :error-messages="fileImportErrors"
                ></v-file-input>
              </v-flex>
              <v-flex xs12 v-else>
                <v-file-input
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="file"
                  accept=".ofx"
                  label="Selecione o OFX"
                  :error-messages="fileImportErrors"
                ></v-file-input>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              fab
              small
              type="submit"
              color="primary"
              :loading="loader"
              class="darkgrey--text px-5"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { orderBy } from 'lodash';
import config from '../../../../config';
import importPdf from '@/api/files/importPdf';
import importOfx from '@/api/files/importOfx';
import { required, requiredIf } from 'vuelidate/lib/validators';
import retirarAcentos from '@/utils/retirarAcentos';

export default {
  name: 'DialogFormImportPdf',
  data: () => ({
    dialog: false,
    loader: false,
    importPdf: false,
    defaultFileType: 'Conta bancária',
    fileType: ['Conta bancária', 'Cartão de crédito'],
    formatFile: ['OFX'],
    bank: '',
    bankCreditCardsPdf: config.bankCreditCardsPdf,
    bankExtratoPdf: config.bankExtratoPdf,
    file: [],
    fileData: {
      base64String: '',
      bankAccount: '',
      creditCard: '',
      userId: '',
    },
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.defaultFileType = 'Conta bancária';
      this.file = [];
      this.fileData = {
        bank: '',
        base64String: '',
        bankAccount: '',
        creditCard: '',
        userId: '',
      };
      this.bank = '';
      this.$v.$reset();
    },
    async submit() {
      try {
        this.$v.$touch();

        if (this.fileData.bankAccount) {
          this.$v.fileData.creditCard.$reset();
        } else if (this.fileData.creditCard) {
          this.$v.fileData.bankAccount.$reset();
        }

        if (
          !this.$v.fileData.bankAccount.$anyError &&
          !this.$v.fileData.creditCard.$anyError &&
          !this.$v.file.$anyError
        ) {
          this.loader = true;

          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                resolve(reader.result);
                reader = null;
              };
              reader.onerror = (error) => reject(error);
            });

          let fileData = JSON.parse(JSON.stringify(this.fileData));

          fileData.base64String = await toBase64(this.file);
          fileData.userId = this.user._id;

          if (this.defaultFileType === 'Conta bancária') {
            fileData.bankAccount = fileData.bankAccount._id;
            fileData.bank = this.bank;
            fileData.creditCard = null;
          } else {
            fileData.creditCard = fileData.creditCard._id;
            fileData.bank = this.fileData.bank;
            fileData.bankAccount = null;
          }
          let pendencias;
          if (this.importPdf === 'PDF') {
            pendencias = await importPdf(fileData);
          } else {
            pendencias = await importOfx(fileData);
          }



          if (pendencias.length) {
            pendencias.forEach(tr => {
              if (tr.bankAccount) {
                tr.bankAccount = this.bankAccounts.find(ba => ba._id === tr.bankAccount)
              }

              if (tr.creditCard) {
                tr.creditCard = this.creditCards.find(cc => cc._id === tr.creditCard)
              }


              this.$store.dispatch('saveTransaction', tr)
            })
          }

          this.$noty.success(`${pendencias.length} transações importadas`);
          this.close();
        }
      } catch (err) {
        this.loader = false;
        this.$noty.error(
          'Desculpe! Não foi possível importar. Tente novamente!'
        );

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      }
    },
  },
  computed: {
    bankAccounts() {
      return this.$store.getters.bankAccounts.filter((ba) => !ba.archived && !ba.pluggy);
    },
    filteredBankAccounts() {
      if (this.bank) {
        return this.bankAccounts.filter(
          (ba) =>
            !ba.archived &&
            !ba.pluggy &&
            retirarAcentos(ba.bank.toLowerCase()) === this.bank.toLowerCase()
        );
      }
    },
    creditCards() {
      return this.$store.getters.creditCards.filter((cc) => !cc.archived && !cc.pluggy);
    },
    filteredCreditCards() {
      if (this.bank) {
        return this.creditCards.filter(
          (cc) =>
            !cc.archived &&
            !cc.pluggy &&
            retirarAcentos(cc.bank.toLowerCase()) === this.bank.toLowerCase()
        );
      }
    },
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === 'customer'
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
    },
    fileImportErrors() {
      const errors = [];
      if (!this.$v.file.$dirty) return errors;
      !this.$v.file.required && errors.push('Selecione o extrato');
      return errors;
    },
    bankAccountErrors() {
      const errors = [];
      if (!this.$v.fileData.bankAccount.$dirty) return errors;
      !this.$v.fileData.bankAccount.required &&
        errors.push('Selecione a conta bancária');
      return errors;
    },
    creditCardErrors() {
      const errors = [];
      if (!this.$v.fileData.creditCard.$dirty) return errors;
      !this.$v.fileData.creditCard.required &&
        errors.push('Selecione o cartão');
      return errors;
    },
  },
  validations: {
    fileData: {
      bankAccount: { required },
      creditCard: { required },
    },
    file: { required },
  },
  watch: {
    defaultFileType() {
      if (this.defaultFileType === 'Conta bancária') {
        this.fileData.bankAccount = null;
        this.$v.fileData.creditCard.$reset();
      } else if (this.defaultFileType === 'Cartão de crédito') {
        this.fileData.creditCard = null;
        this.$v.fileData.bankAccount.$reset();
      }
      this.bank = null;
      this.$v.$reset();
    },
    bank() {
      if (
        this.defaultFileType === 'Conta bancária' &&
        this.filteredBankAccounts
      ) {
        this.fileData.bankAccount = this.filteredBankAccounts[0];
        this.$v.fileData.creditCard.$reset();
      } else if (this.defaultFileType === 'Cartão de crédito') {
        this.fileData.creditCard = this.filteredCreditCards[0];
        this.$v.fileData.bankAccount.$reset();
      }
    },
    fileData: {
      deep: true,
      handler() {
        if (this.fileData.creditCard && this.fileData.creditCard.bank) {
          this.fileData.bank = this.fileData.creditCard.bank;
        }
      },
    },
  },
  created() {
    this.$bus.$on('showDialogFormImportPdf', () => {
      this.dialog = true;
      this.bank = null;
    });
  },
};
</script>
