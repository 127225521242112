import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const unlockUser = (userId, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          data.locked = false

          let url = `/users/${userId}/unlock`
          let options = {
            data,
            method: 'PUT',
            headers: { Authorization: `Bearer ${idToken}` }
          }

          return httpClient(url, options)
        })
        .then(resp => resolve(resp.data))
        .catch(reject)
    } catch(err) {
      reject(err)
    }
  })
}

export default unlockUser
