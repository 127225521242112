<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4">
          {{ aplicacao._id ? 'Editar' : 'Adicionar' }} aplicação
        </span>
      </v-card-title>

      <v-card-text>
        <div class="pt-5">
          <h3 class="input-label primary--text">Data</h3>
          <div class="d-flex align-end">
            <v-autocomplete
              dense
              hint="Ano"
              color="grey1"
              :items="years"
              v-model="year"
              persistent-hint
              class="mr-1"
            ></v-autocomplete>

            <v-select
              dense
              hint="Mês"
              color="grey1"
              :items="months"
              v-model="month"
              persistent-hint
              item-text="sigla"
              item-value="numero"
            ></v-select>
          </div>
        </div>

        <div class="pt-5">
          <h3 class="input-label primary--text">Valor</h3>
          <v-text-field
            dense
            type="tel"
            color="grey1"
            v-money="money"
            v-model="aplicacao.valor"
            id="input-valor-aplicacao"
          ></v-text-field>
        </div>

        <v-card-actions class="px-0 pb-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            small
            color="primary"
            @click="submit"
            :loading="loader"
            class="darkgrey--text"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {VMoney} from 'v-money'
  import moment from 'moment-timezone'
  import { required } from 'vuelidate/lib/validators'
  import removeMoneyMask from "../../../../utils/removeMoneyMask";
  import saveAplicacaoIndFinanceira from "../../../../api/indFinanceira/saveAplicacaoIndFinanceira";

  export default {
    name: 'DialogFormAplicacao',
    directives: { money: VMoney },
    data: () => ({
      dialog: false,
      loader: false,
      aplicacao: {
        data: '',
        valor: ''
      },
      currentDate: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
      isBefore: false,
      isValid: false,
      years: [],
      year: '',
      month: ''
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      dataInicial() {
        if (this.indFinanceira.dataInicial)
          return this.indFinanceira.dataInicial.split('-').reverse().join("/");

        return this.currentDate;
      },
      dataErrors() {
        const errors = [];
        if (!this.$v.aplicacao.data.$dirty) return errors;
        !this.$v.aplicacao.data.required && errors.push('Insira a data inicial da aplicação');
        this.isBefore && errors.push(`Insira uma data maior ou igual a ${this.dataInicial}`);
        !this.isValid && errors.push(`Insira uma data no padrão DD/MM/AAAA`);
        return errors;
      },
      months() {
        return this.$store.getters.months;
      }
    },
    methods: {
      submit() {
        this.loader = true;

        let aplicacao = JSON.parse(JSON.stringify(this.aplicacao));

        aplicacao.valor = removeMoneyMask(aplicacao.valor);

        let month = this.month;
        if (month < 10)
          month = `0${month}`;

        if (!this.year) this.year = this.currentDate.year();

        aplicacao.data = `${this.year}-${month}-01`;

        saveAplicacaoIndFinanceira(this.indFinanceira._id, aplicacao)
          .then(indFinanceira => {
            this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
            this.close();
            this.$noty.success('Aplicação salva com sucesso!')
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível salvar esta aplicação. Tente novamente!');
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      },
      close() {
        this.dialog = false;
        this.loader = false;
        this.aplicacao = { data: this.currentDate.format('YYYY-MM-DD'), valor: 0 };
        this.resetMoneyInput()
      },
      resetMoneyInput() {
        let input = document.getElementById('input-valor-aplicacao');
        if (input) {
          input.value = this.aplicacao.valor;
          input.dispatchEvent(new Event('input'));
        }
      },
      setYears() {
        this.years = [];
        let startYear = parseInt(this.indFinanceira.dataInicial.split('-')[0]);
        let endYear = startYear + this.indFinanceira.premissas.anosProducao;

        for (let i = startYear; i < endYear; i++) {
          this.years.push(i);
        }
      }
    },
    watch: {
      'aplicacao.data'(newValue) {
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(newValue)) {
          let date = newValue.split('/').reverse().join('-');
          let dataInicial = this.dataInicial.split('/').reverse().join('-');
          this.isBefore = moment(date).isBefore(dataInicial);
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      }
    },
    created() {
      this.$bus.$on('showFormDialogAplicacao', aplicacao => {
        this.currentDate = moment().tz('America/Sao_Paulo');

        if (aplicacao) {
          this.aplicacao = JSON.parse(JSON.stringify(aplicacao));
          this.aplicacao.valor = this.aplicacao.valor.toFixed(2);

          let [year, month] = this.aplicacao.data.split('-');
          this.year = parseInt(year);
          this.month = parseInt(month);
        } else {
          this.year = this.currentDate.year();
          this.month = this.currentDate.month() + 1;
        }

        this.resetMoneyInput()
        this.setYears();
        this.dialog = true;
      });
    }
  }
</script>

<style scoped>
  .input-label {
    font-size: 9px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }
</style>
