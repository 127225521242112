<template>
  <v-dialog v-model="dialog" persistent fullscreen scrollable>
    <v-card class="no-radius" color="grey3">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="white--text">Contas bancárias</span>
      </v-card-title>

      <div class="month-selector">
        <v-btn
          icon
          color="grey1"
          class="btn-month"
          @click.stop="changePeriod('back')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="month-name">{{ monthStr }}</span>

        <v-btn
          icon
          color="grey1"
          class="btn-month"
          @click.stop="changePeriod('next')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-card-text class="pa-0">
        <v-tabs
          :grow="isMobile"
          centered
          v-model="tab"
          color="primary"
          background-color="transparent"
        >
          <v-tab :key="item.name" v-for="item in tabs">
            {{ item.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :key="item.name" v-for="item in tabs">
            <TabItemBankAccounts :period="period" :archived="item.archived" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <DialogFormInitialAmount />
    <DialogArchiveBankAccount />

    <v-btn
      fab
      dark
      right
      small
      bottom
      fixed
      absolute
      color="primary"
      class="hidden-md-and-up"
      style="margin-bottom: 40px"
      @click.stop="showDialogFormBankAccount"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import TabItemBankAccounts from '@/components/Customer/Cashflow/tab-items/TabItemBankAccounts';
import DialogFormInitialAmount from '@/components/Customer/Cashflow/dialogs/DialogFormInitialAmount';
import DialogArchiveBankAccount from '@/components/Customer/Cashflow/dialogs/DialogArchiveBankAccount';

export default {
  name: 'DialogMobileBankAccountsList',
  components: {
    DialogArchiveBankAccount,
    DialogFormInitialAmount,
    TabItemBankAccounts,
  },
  data: () => ({
    dialog: false,
    loader: false,
    period: '',
    currentDate: null,
    year: null,
    month: null,
    periodPattern: 'YYYY-MM',
    datePattern: 'YYYY-MM-DD',
    monthStr: '',
    periods: [],
    periodsInterval: null,
    tab: null,
    tabs: [
      {
        name: 'Ativas',
        archived: false,
      },
      {
        name: 'Arquivadas',
        archived: true,
      },
    ],
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.periods = [];
    },
    changePeriod(type) {
      if (type === 'next') {
        this.currentDate = this.currentDate.add(1, 'month');
      } else if (type === 'back') {
        this.currentDate = this.currentDate.subtract(1, 'month');
      }

      this.period = this.currentDate.format(this.periodPattern);
      this.monthStr = this.currentDate.format('MMMM');

      if (this.currentDate.year() !== this.year) {
        this.monthStr += `/${this.currentDate.year()}`;
      }
    },
    showDialogFormBankAccount() {
      if (this.allowed) {
        if (this.isCustomer) {
          this.$bus.$emit('showDialogAddAccount', { type: 'bank-account' });
        } else {
          this.$bus.$emit('showDialogFormBankAccount')
        }
      }
    },
  },
  created() {
    this.$bus.$on('showDialogMobileBankAccountsList', () => {
      this.currentDate = dayjs();
      this.year = this.currentDate.year();
      this.month = this.currentDate.month() + 1;
      this.changePeriod();
      this.dialog = true;
    });

    this.$bus.$on('closeDialogMobileBankAccountsList', this.close)
  },
};
</script>

<style scoped>
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
</style>
