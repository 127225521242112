<template>
  <v-card flat color="grey3">
    <v-card-title class="primary--text seguro-vida main-title">Outras coberturas</v-card-title>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-container grid-list-md class="pa-0">
          <v-layout row wrap>
            <v-flex xs12>
              <span class="seguro-vida input-label primary--text">Diária de Incapacidade Temporária (DIT)</span>
              <v-radio-group row v-model="outrasCoberturas.dit.status">
                <v-radio
                  label="Não"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Sim"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="outrasCoberturas.dit.status">
                <span class="seguro-vida input-label primary--text">Observações</span>
                <v-text-field
                  dense
                  color="grey1"
                  :error-messages="ditObsErrors"
                  v-model="outrasCoberturas.dit.obs"
                >
                  <v-icon
                    color="grey1"
                    class="seguro-vida input-icon mr-1"
                    slot="prepend-inner"
                  >
                    mdi-security
                  </v-icon>
                </v-text-field>
              </v-flex>
            </v-slide-x-transition>

            <v-flex xs12>
              <span class="seguro-vida input-label primary--text">Possui responsabilidade civil?</span>
              <v-radio-group row v-model="outrasCoberturas.responsabilidadeCivil.status">
                <v-radio
                  label="Não"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Sim"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 v-if="outrasCoberturas.responsabilidadeCivil.status">
                <span class="seguro-vida input-label primary--text">Observações</span>
                <v-text-field
                  dense
                  color="grey1"
                  :error-messages="responsabilidadeCivilObsErrors"
                  v-model="outrasCoberturas.responsabilidadeCivil.obs"
                >
                  <v-icon
                    color="grey1"
                    class="seguro-vida input-icon mr-1"
                    slot="prepend-inner"
                  >
                    mdi-security
                  </v-icon>
                </v-text-field>
              </v-flex>
            </v-slide-x-transition>
          </v-layout>
        </v-container>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            type="submit"
            color="primary"
            :loading="loader"
            :disabled="!allowed"
            class="seguro-vida darkgrey--text px-5"
          >
            Salvar
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import updateLifeInsurace from '@/api/lifeInsurance/updateLifeInsurance';

export default {
  name: 'CardOutrasCoberturas',
  props: ['isSpouse'],
  data: () => ({
    loader: false,
    outrasCoberturas: {
      dit: {
        status: false,
        obs: ''
      },
      responsabilidadeCivil: {
        status: false,
        obs: ''
      }
    }
  }),
  validations: {
    outrasCoberturas: {
      dit: {
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      },
      responsabilidadeCivil: {
        obs: {
          isValid(v) {
            return !v || v.length <= 150
          }
        }
      }
    }
  },
  computed: {
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    },
    ditObsErrors() {
      const errors = []

      if (!this.$v.outrasCoberturas.dit.obs.$dirty) {
        return errors
      }

      !this.$v.outrasCoberturas.dit.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    responsabilidadeCivilObsErrors() {
      const errors = []

      if (!this.$v.outrasCoberturas.responsabilidadeCivil.obs.$dirty) {
        return errors
      }

      !this.$v.outrasCoberturas.responsabilidadeCivil.obs.isValid && errors.push('Não utilize mais do que 150 caracteres')

      return errors
    },
    allowed() {
      return this.$store.getters.allowed
    }
  },
  methods: {
    copyData() {
      if (this.lifeInsurance && this.lifeInsurance.outrasCoberturas) {
        this.outrasCoberturas = JSON.parse(JSON.stringify(this.lifeInsurance.outrasCoberturas))
      }
    },
    submit() {
      if (this.allowed) {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          updateLifeInsurace(this.lifeInsurance._id, { outrasCoberturas: this.outrasCoberturas })
            .then(lifeInsurance => {
              this.$store.dispatch('saveLifeInsurance', lifeInsurance)
              this.loader = false
              this.$noty.success('Dados salvos com sucesso!')
            })
            .catch(err => {
              this.loader = false
              this.$noty.error('Desculpe! Não foi possível salvar seus dados. Tente novamente mais tarde!');

              if (process.env.NODE_ENV  !== 'production') {
                console.error(err)
              }
            })
        }
      }
    }
  },
  watch: {
    lifeInsurance: {
      deep: true,
      handler() {
        this.copyData()
      }
    }
  },
  created() {
    this.copyData()
  }
}
</script>

<style scoped></style>
