<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          class="mr-2"
          color="primary"
          :disabled="loader"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span>{{ user._id ? 'Editar' : 'Adicionar' }} {{ title }}</span>
      </v-card-title>

      <v-card-text class="mt-2">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md class="pa-0">
            <v-layout column>
              <v-flex xs12>
                <span class="input-label primary--text">Nome</span>
                <v-text-field
                  dense
                  color="grey1"
                  class="pt-0 mt-0"
                  v-model="user.displayName"
                  :error-messages="displayNameErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">E-mail</span>
                <v-text-field
                  dense
                  type="email"
                  color="grey1"
                  class="pt-0 mt-0"
                  v-model="user.email"
                  :error-messages="emailErrors"
                  @change="$v.user.email.$touch()"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Senha</span>
                <v-text-field
                  dense
                  color="grey1"
                  class="pt-0 mt-0"
                  v-model="user.password"
                  :error-messages="passwordErrors"
                  @click:append="showPass = !showPass"
                  :type="showPass ? 'text' : 'password'"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Repita a senha</span>
                <v-text-field
                  dense
                  color="grey1"
                  class="pt-0 mt-0"
                  v-model="user.repeatPassword"
                  :error-messages="repeatPasswordErrors"
                  :type="showRepeatPass ? 'text' : 'password'"
                  @click:append="showRepeatPass = !showRepeatPass"
                  :append-icon="showRepeatPass ? 'mdi-eye' : 'mdi-eye-off'"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions class="px-0">
            <v-btn
              rounded
              outlined
              color="grey1"
              type="button"
              :disabled="loader"
              class="text-capitalize px-5"
              @click.stop="closeDialog"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              class="text-capitalize darkgrey--text px-5"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import checkEmailAlreadyUsed from "@/api/users/checkEmailAlreadyUsed";
import { email, required, minLength, maxLength } from 'vuelidate/lib/validators'
import stringCapitalize from "@/utils/stringCapitalize";
import createUser from "@/api/users/createUser";

export default {
  name: 'DialogFormUser',
  data: () => ({
    dialog: false,
    loader: false,
    showPass: false,
    emailUsed: false,
    showRepeatPass: false,
    user: {
      email: '',
      password: '',
      role: 'support',
      displayName: '',
      repeatPassword: ''
    }
  }),
  validations: {
    user: {
      email: {
        email,
        required,
        maxLength: maxLength(150),
        isUnique(value) {
          if (!value) {
            return true
          }

          return new Promise((resolve, reject) => {
            const email = this.user.email.trim().toLowerCase()

            checkEmailAlreadyUsed(email)
              .then(resp => resolve(!resp.exists))
              .catch(err => {
                if (process.env.NODE_ENV !== 'production') {
                  console.error(err)
                }

                resolve(true)
              })
          })
        }
      },
      displayName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(50)
      },
      repeatPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(50),
        isSame(v) {
          return v.trim() === this.user.password.trim()
        }
      }
    }
  },
  computed: {
    title() {
      switch (this.user.role) {
        case 'comercial':
          return 'Gerente comercial'
        case 'admin':
          return 'Administrador'
        case 'supervisor':
          return 'Supervisor PFP'
        default:
          return 'Suporte'
      }
    },
    emailErrors() {
      if (!this.$v.user.email.$anyDirty) {
        return []
      }
      if (!this.$v.user.email.isUnique) {
        return ['E-mail já cadastrado']
      } else if (this.$v.user.email.$anyError) {
        return ['Preencha um e-mail válido']
      }
    },
    passwordErrors() {
      if (!this.$v.user.password.$anyDirty) {
        return []
      }
      if (this.$v.user.displayName.$anyError) {
        return ['Preencha uma senha válida']
      }
    },
    displayNameErrors() {
      if (!this.$v.user.displayName.$anyDirty) {
        return []
      }
      if (this.$v.user.displayName.$anyError) {
        return ['Preencha um nome válido']
      }
    },
    repeatPasswordErrors() {
      if (!this.$v.user.repeatPassword.$anyDirty) {
        return []
      }
      if (this.$v.user.repeatPassword.$anyError) {
        return ['As senhas precisam ser iguais']
      }
    },
  },
  methods: {
    openDialog(user) {
      if (user) {
        this.user = { ...this.user, ...user }
      }

      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.loader = false

      this.user = {
        email: '',
        password: '',
        role: 'support',
        displayName: '',
        repeatPassword: ''
      }

      this.$v.$reset()
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          const user = JSON.parse(JSON.stringify(this.user))

          user.email = user.email.trim().toLowerCase()
          user.displayName = stringCapitalize(user.displayName)

          delete user.repeatPassword

          await createUser(user)

          this.$bus.$emit('managerGetUsers')
          this.$noty.success('Usuário salvo com sucesso')

          this.closeDialog()
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.loader = false
        this.$noty.error('Falha ao adicionar usuário. Tente novamente mais tarde!')
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormUser', this.openDialog)
  }
}
</script>
