<template>
  <v-card>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap align-center>
          <v-flex xs11>
            <v-form @submit.prevent="listar" class="d-flex align-center">
              <v-text-field
                color="grey1"
                persistent-hint
                v-model="filter.nome_email"
                label="Pesquisar"
                hint="Nome ou e-mail"
                class="mr-2"
                :disabled="loader"
              ></v-text-field>

              <v-btn
                icon
                large
                outlined
                depressed
                type="submit"
                color="grey1"
                :disabled="loader"
              >
                <i class="lni lni-search"></i>
              </v-btn>
            </v-form>
          </v-flex>

          <v-flex xs1>
            <v-menu
              bottom
              left
              v-model="menu"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on: menu }">
                <v-btn
                  icon
                  large
                  outlined
                  color="grey1"
                  v-on="{ ...menu }"
                  :disabled="loader"
                >
                  <i class="lni lni-funnel"></i>
                </v-btn>
              </template>

              <v-card width="300px" color="darkgrey">
                <v-card-text>
                  <v-form @submit.prevent="listar">
                    <v-autocomplete
                      label="Status"
                      color="grey1"
                      :items="status"
                      v-model="filter.status"
                    ></v-autocomplete>

                    <v-card-actions class="px-0">
                      <v-spacer></v-spacer>

                      <v-btn
                        small
                        block
                        rounded
                        color="primary"
                        class="px-5 darkgrey--text"
                        type="submit"
                        :loading="loader"
                      >
                        Filtrar
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-flex>

          <v-flex xs12>
            <v-data-table
              hide-default-footer
              :headers="headers"
              :items="formatDataTable"
              :loading="loader"
            >
              <template v-slot:item="props">
                <tr @click="showDialogDetailsPendingRewards(props.item)">
                  <td>{{ props.item.completeName }}</td>
                  <td class="text-center">{{ props.item.dateRequest }}</td>
                  <td class="text-center">{{ props.item.accomplished }}</td>
                  <td class="text-center">{{ numberToCurrency(props.item.valueReward) }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>

          <v-flex xs12>
            <v-pagination
              circle
              color="primary"
              class="darkgrey--text"
              v-model="pagination.page"
              :length="pagination.pageCount"
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';
import getRewardRequests from '@/api/rewardRequests/getRewardRequests';

export default {
  name: 'TablePendingRewards',
  data: () => ({
    menu: false,
    filter: {
      nome_email: '',
      status: 'Todos'
    },
    numberToCurrency,
    status: ['Todos', 'Pendente', 'Concluída'],
    requestReward: [],
    pagination: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalItems: 0
    },
    loader: false,
    headers: [
      {
        text: 'Nome',
        value: 'completeName',
        align: 'left',
        sortable: true
      },
      {
        text: 'Data da solicitação',
        value: 'dateRequest',
        align: 'center',
        sortable: true
      },
      {
        text: 'Reembolso',
        value: 'accomplished',
        align: 'center',
        sortable: true
      },
      {
        text: 'Valor Reembolso',
        value: 'valueReward',
        align: 'center',
        sortable: true
      }
    ]
  }),
  methods: {
    showDialogDetailsPendingRewards(item) {
      this.$bus.$emit('showDialogDetailsPendingRewards', item);
    },
    listar() {
      this.loader = true

      getRewardRequests(this.filter.nome_email, this.filter.status, this.pagination.page)
        .then(resp => {
          this.requestReward = resp.items;

          this.pagination.totalItems = resp.totalItems;
          this.pagination.pageCount = (resp.totalItems ?
            Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage) : 0);
          this.loader = false;
          this.menu = false;

          this.$store.dispatch('saveRequestReward', resp);
          this.loader = false
        })
        .catch(err => {
          this.loader = false
          this.$noty.error('Desculpe! Não foi possível listar os itens desta lista. Tente novamente mais tarde!');

          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }
        });
    }
  },
  computed: {
    formatDataTable() {
      if (this.requestReward) {
        const requestReward = [];

        for (let i = 0; i < this.requestReward.length; i++) {
          requestReward.push({
            dateRequest: this.$date().format('DD/MM/YYYY'),
            accomplished: this.requestReward[i].accomplished ? 'Concluída' : 'Pendente',
            completeName: this.requestReward[i].completeName,
            valueReward: this.requestReward[i].valueReward,
            _id: this.requestReward[i]._id
          });
        }

        return requestReward;
      }
    },
  },
  watch: {
    'pagination.page'() {
      this.listar();
    },
    'filter.nome_email'() {
      if (!this.filter.nome_email) {
        this.listar();
      }
    }
  },
  created() {
    this.listar()

    this.$bus.$on("alterRewardRequest", () => {
      this.listar()
    })
  }
};
</script>

<style scoped>
.v-data-table__wrapper table tbody tr td {
  cursor: pointer !important;
}
</style>
