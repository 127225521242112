<template>
  <v-dialog
    fullscreen
    v-model="dialog"
  >
    <v-card color="grey3" class="no-radius">
      <v-card-title class="safe-area-top pt-3 darkgrey d-flex justify-space-between">
        <v-btn
          icon
          color="primary"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text dialog-title">Usuário Importados</span>

        <v-btn icon></v-btn>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-container grid-list-md v-if="success.length">
          <v-layout row wrap>
            <v-flex xs12 sm12 md8 offset-md2 lg6 offset-lg3>
              <h1 class="success--text">Sucesso</h1>
            </v-flex>

            <v-flex xs12 sm12 md8 offset-md2 lg6 offset-lg3>
              <v-list two-line>
                <v-list-item v-for="(user, i) in success" :key="i">
                  <v-list-item-avatar>
                    <v-avatar color="darkgrey">
                      <span class="success--text">{{ user.line }}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ user.displayName }}</v-list-item-title>
                    <v-list-item-subtitle class="success--text">Importado com sucesso!</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container grid-list-md class="mt-5" v-if="error.length">
          <v-layout row wrap>
            <v-flex xs12 sm12 md8 offset-md2 lg6 offset-lg3>
              <h1 class="error--text">Falhas</h1>
            </v-flex>

            <v-flex xs12 sm12 md8 offset-md2 lg6 offset-lg3>
              <v-list two-line>
                <v-list-item v-for="(user, i) in error" :key=i>
                  <v-list-item-avatar>
                    <v-avatar color="darkgrey">
                      <span class="error--text">{{ user.line }}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ user.displayName }}</v-list-item-title>
                    <v-list-item-subtitle class="error--text">{{ user.error }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogUploadUsersResult',
  data: () => ({
    dialog: false,
    success: [],
    error: []
  }),
  methods: {
    openDialog(result) {
      if (result) {
        this.success = result.success
        this.error = result.withError
        this.dialog = true
      }
    },
    reset() {
      this.error = []
      this.success = []
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.reset()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogUploadUsersResult', this.openDialog)
  }
}
</script>
