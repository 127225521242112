<template>
  <v-card color="grey3">
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="walletName === 'independence' && loader"
    ></v-progress-linear>

    <v-card-title class="primary--text text-uppercase">
      {{ walletTitle }}

      <v-btn
        fab
        small
        depressed
        color="grey2"
        :disabled="!allowed"
        @click="showDialogFormWalletInvestment(walletName, null)"
      >
        <v-icon large color="grey1">mdi-plus</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="px-1" v-if="wallet">
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 v-if="walletName === 'independence' && !!liquidez">
            <div class="independence-container">
              <span class="independence-target-dec">
                Selecione  seu objetivo para independencia financeira
              </span>
              <v-radio-group
                color="primary"
                :row="!isMobile"
                :column="isMobile"
                v-model="isConsumptionValue"
                :disabled="loader || period !== currentPeriod"
              >
                <v-radio label="Viver de renda" :value="false"></v-radio>
                <v-radio label="Consumo de patrimônio" :value="true"></v-radio>
              </v-radio-group>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md4 lg4>
            <v-card :color="isMobile ? 'grey3' : 'grey2'" :flat="isMobile" :raised="!isMobile">
              <v-card-text>
                <div class="wallet-values-container">
                  <v-icon
                    class="mr-3"
                    :large="!isMobile"
                    :color="isMobile ? 'grey1' : 'primary'"
                  >
                    mdi-cash-multiple
                  </v-icon>

                  <div>
                    <span class="wallet-values-desc primary--text">Total necessário</span>
                    <span class="wallet-values-value">
                      <span>R$</span> {{ currency(wallet.totalNeeded) }}
                    </span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 sm12 md4 lg4>
            <v-card :color="isMobile ? 'grey3' : 'grey2'" :flat="isMobile" :raised="!isMobile">
              <v-card-text>
                <div class="wallet-values-container">
                  <v-icon
                    class="mr-3"
                    :large="!isMobile"
                    :color="isMobile ? 'grey1' : 'primary'"
                  >
                    mdi-cash-register
                  </v-icon>

                  <div>
                    <span class="wallet-values-desc primary--text">Total alocado</span>
                    <span class="wallet-values-value">
                      <span>R$</span> {{ currency(wallet.totalApplied) }}
                    </span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 sm12 md4 lg4>
            <v-card :color="isMobile ? 'grey3' : 'grey2'" :flat="isMobile" :raised="!isMobile">
              <v-card-text>
                <div class="wallet-values-container">
                  <v-icon
                    class="mr-3"
                    :large="!isMobile"
                    :color="isMobile ? 'grey1' : 'primary'"
                  >
                    mdi-cash
                  </v-icon>

                  <div>
                    <span class="wallet-values-desc primary--text">Total restante</span>
                    <span class="wallet-values-value">
                      <span>R$</span> {{ currency(totalRemaining) }}
                    </span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-slide-x-transition>
            <v-flex xs12 class="hidden-sm-and-down mt-5" v-if="filteredInvestments.length">
              <TableWalletInvestments :wallet="walletName" :items="filteredInvestments"/>
            </v-flex>
          </v-slide-x-transition>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-card-actions class="hidden-md-and-up pb-5">
      <v-spacer></v-spacer>

      <v-btn
        small
        rounded
        outlined
        class="px-5"
        color="primary"
        @click="showDialogWalletInvestments"
      >
        Ver ativos
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { orderBy } from 'lodash'
  import currency from '@/utils/currency';
  import updateLiquidez from '@/api/liquidez/updateLiquidez';
  import getInvestmentsWallets from '@/api/investimentos/getInvestmentsWallets';
  import TableWalletInvestments from '@/components/Customer/Investimentos/tables/TableWalletInvestments';

  export default {
    name: 'CardInvestmentsWallet',
    components: { TableWalletInvestments },
    props: ['wallet', 'walletName', 'period'],
    data: () => ({
      currency,
      loader: false,
      currentPeriod: '',
      isConsumptionValue: false
    }),
    computed: {
      allowed() {
        return this.$store.getters.allowed;
      },
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === `customer`;
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
      investments() {
        return this.$store.getters.investments;
      },
      filteredInvestments() {
        return orderBy(this.investments.filter(inv => {
          if (this.period) {
            if (inv.wallet === this.walletName) {
              return inv.initialDate.slice(0, 7) <= this.period
            }
          }
        }), ['description', 'date']);
      },
      totalRemaining() {
        if (this.wallet) {
          let total = this.wallet.totalNeeded - this.wallet.totalApplied;
          return total > 0 ? total : 0;
        }

        return 0;
      },
      walletTitle() {
        switch (this.walletName) {
          case 'security':
            return 'Carteira de Reserva de Emergência';
          case 'independence':
            return 'Carteira da Independência Financeira';
          case 'life-projects':
            return 'Carteira dos Projetos de Vida';
          default:
            return 'Carteira';
        }
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      liquidez() {
        return this.$store.getters.liquidez;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      }
    },
    methods: {
      calcTotalApplied() {
        let total = 0;

        this.filteredInvestments.forEach(inv => {
          total += inv.initialValue;

          inv.transactions
            .filter(t => {
              return (
                this.$date(t.date).isSameOrAfter(inv.initialDate, 'day') &&
                this.$date(t.date).isSameOrBefore(this.period, 'month')
              )
            })
            .forEach(t => {
              total += ((t.type === 'in' || t.type === 'var') ? t.value : (t.value * -1));
            });
        });

        if (this.wallet)
          this.wallet.totalApplied = total;
      },
      updateIndependenceWallet() {
        if (this.allowed) {
          this.loader = true;

          let data = {
            isConsumptionValue: this.isConsumptionValue,
            independenceValue: this.isConsumptionValue ? this.indFinanceira.patrimonio.patrimonioConsumo : this.indFinanceira.patrimonio.patrimonioRenda
          }

          updateLiquidez(this.liquidez._id, data)
            .then(liquidez => {
              this.$store.dispatch('saveLiquidez', liquidez);
              return getInvestmentsWallets(this.user._id, this.period)
            })
            .then(wallets => {
              this.$bus.$emit('updateInvestmentWallets', wallets);
              this.loader = false;
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível alterar sua carteira. Tente novamente mais tarde!');

              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      },
      showDialogFormWalletInvestment(wallet, investment) {
        if (this.allowed)
          this.$bus.$emit('showDialogFormWalletInvestment', { wallet, investment });
      },
      showDialogWalletInvestments() {
        this.$bus.$emit('showDialogWalletInvestments', this.walletName);
      }
    },
    watch: {
      period() {
        this.calcTotalApplied();
      },
      wallet() {
        this.calcTotalApplied();
      },
      isConsumptionValue() {
        this.updateIndependenceWallet();
      },
      investments: {
        deep: true,
        handler() {
          this.calcTotalApplied();
        }
      }
    },
    created() {
      this.calcTotalApplied();
      this.currentPeriod = this.$date().format('YYYY-MM');

      if (this.liquidez) {
        this.isConsumptionValue = this.liquidez.isConsumptionValue;
      }
    }
  }
</script>

<style scoped>
  .v-card__title {
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 1.5em !important;
    letter-spacing: .1em !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .wallet-values-container {
    display: flex;
    align-items: center;
  }

  .wallet-values-container div {
    display: flex;
    flex-direction: column;
  }

  .wallet-values-desc {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .wallet-values-value {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .wallet-values-value span {
    font-size: 12px;
  }

  .independence-container {
    display: flex;
    flex-direction: column;
  }

  .independence-target-dec {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  @media only screen and (min-width: 600px) {
    .v-card__title,
    .wallet-values-desc,
    .wallet-values-value {
      font-size: 15px !important;
    }

    .independence-target-dec {
      font-size: 15px;
    }
  }

  @media only screen and (min-width: 960px) {
    .wallet-values-value span {
      font-size: 15px;
    }

    .independence-container {
      flex-direction: row;
      align-items: center;
    }

    .independence-target-dec {
      margin-right: 20px;
    }
  }

  @media only screen and (min-width: 960px) {
    .v-card__title,
    .wallet-values-desc,
    .wallet-values-value {
      font-size: 16px !important;
    }

    .independence-target-dec {
      font-size: 16px;
    }
  }
</style>
