<template>
  <v-card raised color="grey3" v-if="note" @click="show = !show">
    <v-card-title class="primary--text">{{ note.title }}</v-card-title>
    <v-card-subtitle>{{ formatDate(note.createdAt) }}</v-card-subtitle>
    <v-card-text>
      <p v-html="note.content.slice(0,140) + '...'"></p>
    </v-card-text>

    <v-bottom-sheet v-model="show" scrollable :inset="!isMobile">
      <v-card>
        <v-card-title class="primary--text">{{ note.title }}</v-card-title>

        <v-card-text>
          <p v-html="note.content"></p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            icon
            color="white"
            v-if="user.role === 'planner'"
            @click="edit" :disabled="removeLoader"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            icon
            color="error"
            v-if="user.role === 'planner'"
            @click="remove" :loading="removeLoader"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
  import moment from 'moment-timezone'
  import deleteNote from '../../../../api/notes/deleteNote';

  export default {
    name: 'CardNote',
    props: ['note'],
    data: () => ({
      show: false,
      removeLoader: false
    }),
    computed: {
      isMobile() {
        return this.$store.getters.isMobile;
      },
      user() {
        return this.$store.getters.user
      }
    },
    methods: {
      formatDate(date) {
        if (date)
          return this.$date(date).format('DD/MM/YYYY HH:mm');

        return '';
      },
      edit() {
        this.$bus.$emit('showDialogFormNote', this.note);
        this.show = false;
      },
      remove() {
        this.removeLoader = true;

        deleteNote(this.note._id)
          .then(() => {
            this.removeLoader = false;
            this.show = false;
            this.$bus.$emit('noteSaved');
          })
          .catch(err => {
            this.removeLoader = false;
            this.$noty.error('Desculpe! Não foi possível remover sua anotação. Tente novamente!');
            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          })
      }
    }
  }
</script>

<style scoped>
  .v-card__title {
    font-size: 12px !important;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
    text-transform: uppercase !important;
  }

  .v-card__subtitle {
    font-size: 10px !important;
    line-height: 1.5em;
    letter-spacing: .1em;
  }
</style>
