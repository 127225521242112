<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    max-width="788px"
    :fullscreen="isMobile"
  >
    <v-card color="grey2" :class="isMobile ? 'no-radius' : ''">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="primary"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text ml-4">
          {{ bankAccount._id ? 'Editar' : 'Adicionar' }} conta bancária
        </span>
      </v-card-title>

      <v-card-text>
        <!-- <v-form @submit.prevent="submit"> -->
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm12 md6 lg6 v-if="!bankAccount.pluggy">
              <span class="input-label primary--text">Tipo</span>
              <v-select
                dense
                color="grey1"
                :items="tiposConta"
                item-color="primary"
                v-model="bankAccount.type"
                :error-messages="typeErrors"
                @change="$v.bankAccount.type.$touch()"
              ></v-select>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 sm12 md6 lg6 v-if="bankAccount.type === 'outro' && !bankAccount.pluggy">
                <span class="input-label primary--text">Descrição do tipo</span>
                <v-text-field
                  dense
                  type="text"
                  color="grey1"
                  v-model="otherType"
                ></v-text-field>
              </v-flex>
            </v-slide-x-transition>

            <v-flex xs12 sm12 md6 lg6 v-if="!bankAccount.pluggy">
              <span class="input-label primary--text">Banco</span>
              <v-select
                dense
                color="grey1"
                :items="bancos"
                item-color="primary"
                v-model="bankAccount.bank"
                :error-messages="bankErrors"
                @change="$v.bankAccount.bank.$touch()"
              ></v-select>
            </v-flex>

            <v-slide-x-transition>
              <v-flex xs12 sm12 md6 lg6 v-if="bankAccount.bank === 'outro' && !bankAccount.pluggy">
                <span class="input-label primary--text">Nome do banco</span>
                <v-text-field
                  dense
                  type="text"
                  color="grey1"
                  v-model="otherBank"
                ></v-text-field>
              </v-flex>
            </v-slide-x-transition>

            <v-flex xs12 sm12 md6 lg6>
              <span class="input-label primary--text">Nome da conta</span>
              <v-text-field
                dense
                type="text"
                color="grey1"
                v-model="bankAccount.name"
                :error-messages="nameErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md6 lg6 v-if="!bankAccount._id && !bankAccount.pluggy">
              <span class="input-label primary--text">Saldo inicial</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                v-money="money"
                id="input-account-initial-amount"
                v-model="bankAccount.initialAmount"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md6 lg6 v-if="!bankAccount._id && !bankAccount.pluggy">
              <span class="input-label primary--text"
                >Data do saldo inicial</span
              >

              <v-dialog
                ref="dialog"
                v-model="dateMenu"
                :return-value.sync="bankAccount.initialAmountDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mt-0 pt-0"
                    :value="formatDate"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="initialAmountDateErrors"
                  >
                    <v-icon
                      color="grey1"
                      class="input-icon"
                      slot="prepend-inner"
                    >
                      mdi-calendar
                    </v-icon>
                  </v-text-field>
                </template>

                <v-date-picker
                  no-title
                  color="primary"
                  v-model="bankAccount.initialAmountDate"
                  scrollable
                  locale="pt-br"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(bankAccount.initialAmountDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>

            <v-flex xs12 sm12 md6 lg6 class="mt-5" v-if="!bankAccount.pluggy">
              <v-btn
                block
                rounded
                depressed
                type="button"
                :color="bankAccount.color"
                @click="colorMenu = !colorMenu"
                :class="isLight ? 'darkgrey--text' : 'white--text'"
              >
                Cor da conta
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-actions class="px-0 mt-5">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            depressed
            @click="submit"
            color="primary"
            :loading="loader"
            class="darkgrey--text px-5"
          >
            Salvar
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
        <!-- </v-form> -->
      </v-card-text>

      <!--      COLORS-->
      <v-bottom-sheet max-width="600px" :inset="!isMobile" v-model="colorMenu">
        <v-card
          color="darkgrey"
          style="border-radius: 15px 15px 0 0 !important"
        >
          <v-card-text class="py-5">
            <v-layout row wrap>
              <v-flex
                xs2
                :key="color"
                class="mb-1 text-center"
                v-for="color in categoryColors"
              >
                <v-btn
                  fab
                  dark
                  small
                  depressed
                  type="button"
                  :color="color"
                  @click.stop="
                    bankAccount.color = color;
                    colorMenu = false;
                  "
                ></v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money';
import moment from 'moment-timezone';
import config from '../../../../config';
const tinycolor = require('tinycolor2');
const randomHexColor = require('random-hex-color');
import categoryColors from '@/utils/categoryColors';
import { required, maxLength } from 'vuelidate/lib/validators';
import removeMoneyMask from '../../../../utils/removeMoneyMask';
import stringCapitalize from '../../../../utils/stringCapitalize';
import saveBankAccount from '../../../../api/bankAccounts/saveBankAccount';
const CryptoJS = require('crypto-js');

const validDate = (v) => /^\d{2}\/\d{2}\/\d{4}$/gm.test(v);

export default {
  name: 'DialogFormBankAccount',
  components: { money: VMoney },
  data: () => ({
    dialog: false,
    dateMenu: false,
    loader: false,
    currentDate: '',
    bankAccount: {
      name: '',
      bank: '',
      color: '',
      initialAmount: 0,
      initialAmountDate: '',
      automatic: false,
    },
    money: {
      decimal: ',',
      precision: 2,
      prefix: 'R$ ',
      thousands: '.',
    },
    tiposConta: [
      { text: 'Conta corrente', value: 'conta-corrente' },
      { text: 'Poupança', value: 'poupanca' },
      { text: 'Dinheiro', value: 'dinheiro' },
      { text: 'Outro', value: 'outro' },
    ],
    otherType: '',
    bancos: [
      { text: 'Banco do Brasil', value: 'banco-do-brasil' },
      { text: 'Bradesco', value: 'bradesco' },
      { text: 'Caixa Econômica Federal', value: 'caixa-economica-federal' },
      { text: 'HSBC', value: 'hsbc' },
      { text: 'Inter', value: 'inter' },
      { text: 'Itaú', value: 'itau' },
      { text: 'Nubank', value: 'nubank' },
      { text: 'Santander', value: 'santander' },
      { text: 'Outro', value: 'outro' },
    ],
    otherBank: '',
    colorMenu: false,
    categoryColors,
    showPass: false,
  }),
  computed: {
    formatDate() {
      if (this.bankAccount.initialAmountDate) {
        return moment(this.bankAccount.initialAmountDate).format('DD/MM/YYYY');
      }
      return '';
    },
    isLight() {
      return tinycolor(this.bankAccount.color).isLight();
    },
    bankBots() {
      return config.bankBots;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    hasBot() {
      return (
        this.bankAccount &&
        !this.bankAccount._id &&
        this.bankBots.includes(this.bankAccount.bank)
      );
    },
    needsCpf() {
      return this.bankAccount.bank === 'caixa-economica-federal';
    },
    needsAgency() {
      return ['banco-do-brasil', 'itau'].includes(this.bankAccount.bank);
    },
    needsAccount() {
      return ['banco-do-brasil', 'itau', 'inter'].includes(
        this.bankAccount.bank
      );
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.bankAccount.name.$dirty) return errors;
      !this.$v.bankAccount.name.required && errors.push('Descreva sua conta');
      !this.$v.bankAccount.name.maxLength &&
        errors.push('Não utilize mais do que 100 caracteres');
      return errors;
    },
    bankErrors() {
      const errors = [];
      if (!this.$v.bankAccount.bank.$dirty) return errors;
      !this.$v.bankAccount.bank.required && errors.push('Selecione o banco');
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.bankAccount.type.$dirty) return errors;
      !this.$v.bankAccount.type.required &&
        errors.push('Selecione o tipo da conta');
      return errors;
    },
    initialAmountDateErrors() {
      const errors = [];
      if (!this.$v.bankAccount.initialAmountDate.$dirty) return errors;
      !this.$v.bankAccount.initialAmountDate.isValid && errors.push('Selecione a data do saldo inicial');
      return errors;
    },
    otherTypeErrors() {},
    otherBankErrors() {},
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.bankAccount = {
        name: '',
        bank: '',
        color: '',
        initialAmount: 0,
        initialAmountDate: '',
        automatic: false,
      };
      this.otherBank = ''
      this.otherType = ''
      this.resetMoneyInput();
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (this.bankAccount.type === 'outro') {
        this.$v.otherType.$touch();
      } else {
        this.$v.otherType.$reset();
      }

      if (this.bankAccount.bank === 'outro') {
        this.$v.otherBank.$touch();
      } else {
        this.$v.otherBank.$reset();
      }

      if (this.bankAccount._id) {
        this.$v.bankAccount.initialAmountDate.$reset();
      }

      if (
        !this.$v.$anyError ||
        (!this.$v.bankAccount.bank.$anyError &&
          !this.$v.bankAccount.name.$anyError &&
          !this.$v.bankAccount.initialAmountDate.$anyError)
      ) {
        this.loader = true;

        let bankAccount = JSON.parse(JSON.stringify(this.bankAccount));

        bankAccount.initialAmount = removeMoneyMask(bankAccount.initialAmount);

        if (bankAccount.bank === 'outro') {
          bankAccount.bank = stringCapitalize(this.otherBank);
          bankAccount.hasOtherBank = true
        }

        if (bankAccount.type === 'outro') {
          bankAccount.type = stringCapitalize(this.otherType);
          bankAccount.hasOtherType = true
        }

        if (!bankAccount.user) {
          bankAccount.user = this.user;
        }

        delete bankAccount.history;

        saveBankAccount(bankAccount)
          .then((savedBankAccount) => {
            this.$store.dispatch('saveBankAccount', savedBankAccount);
            this.close();
            this.$noty.success('Conta bancária salva com sucesso!');
          })
          .catch((err) => {
            this.loader = false;
            this.$noty.error(
              'Desculpe! Não foi possível salvar sua conta. Tente novamente!'
            );
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    resetMoneyInput() {
      let input = document.getElementById('input-account-initial-amount');
      if (input) {
        input.value = this.bankAccount.initialAmount;
        input.dispatchEvent(new Event('input'));
      }
    },
  },
  validations: {
    bankAccount: {
      bank: { required },
      type: { required },
      name: { required, maxLength: maxLength(50) },
      initialAmountDate: {
        isValid(v) {
          return !!v && /\d{4}-\d{2}-\d{2}/.test(v)
        }
      }
    },
    otherType: { maxLength: maxLength(50) },
    otherBank: { maxLength: maxLength(50) },
  },
  watch: {
    'bankAccount.bank'() {
      if (!this.bankBots.includes(this.bankAccount.bank)) {
        this.bankAccount.automatic = false;
        this.bankAccount.cpf = '';
        this.bankAccount.pass = '';
        this.bankAccount.agency = '';
        this.bankAccount.account = '';
      }
    },
  },
  created() {
    this.currentDate = moment().tz('America/Sao_Paulo').format('DD/MM/YYYY');

    this.$bus.$on('showDialogFormBankAccount', (bankAccount) => {
      if (bankAccount) {
        this.bankAccount = JSON.parse(JSON.stringify(bankAccount));
        this.bankAccount.initialAmount = parseFloat(this.bankAccount.initialAmount.toFixed(2));

        const bank = this.bankAccount.bank.trim().toLowerCase().replace(/\s+/g, '-')

        if (!this.bancos.find(bc => bc.value === bank)) {
          this.otherBank = stringCapitalize(this.bankAccount.bank)
          this.bankAccount.bank = 'outro'
        }

        const type = this.bankAccount.type.trim().toLowerCase().replace(/\s+/g, '-')

        if (!this.tiposConta.find(tp => tp.value === type)) {
          this.otherType = stringCapitalize(this.bankAccount.type)
          this.bankAccount.type = 'outro'
        }

        this.resetMoneyInput();
      } else {
        this.bankAccount.color = randomHexColor();
        this.bankAccount.initialAmountDate = moment()
          .tz('America/Sao_Paulo')
          .format('YYYY-MM-DD');
      }
      this.dialog = true;
    });
  },
};
</script>

<style scoped>
.v-btn.v-size--default {
  font-size: 11px !important;
}
</style>
