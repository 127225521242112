<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    :fullscreen="isMobile"
    max-width="500px"
  >
    <v-card
      color="grey2"
      :class="isMobile ? 'no-radius' : ''"
    >
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="grey1"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="grey1--text ml-4"> Adicionar item </span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <v-select
                  color="grey1"
                  item-color="grey1"
                  label="Categoria"
                  v-model="item.category"
                  :items="filteredCategories"
                  return-object
                  item-text="name"
                  @change="$v.item.category.$touch()"
                  :error-messages="categoryErrors"
                >
                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color">{{ item.icon }}</v-icon>
                    <span>{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <v-icon small class="mr-2">{{ item.icon }}</v-icon>
                      <span class="white--text">{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12>
                <v-select
                  color="grey1"
                  item-color="grey1"
                  label="Sub categoria"
                  v-model="item.subCategory"
                  :items="subCategories"
                  item-value="_id"
                  item-text="name"
                  @change="$v.item.subCategory.$touch()"
                  :error-messages="subCategoryErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  color="grey1"
                  label="Limite"
                  type="tel"
                  v-money="money"
                  v-model="item.limite"
                  id="input-value-item"
                  :error-messages="limiteErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 class="mt-5">
                Alertas
              </v-flex>

              <v-flex xs6>
                <v-text-field
                  color="grey1"
                  type="number"
                  label="Primeiro alerta"
                  suffix="%"
                  v-model.number="item.firstWarning.percent"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <v-text-field
                  color="grey1"
                  readonly
                  label="Ao gastar"
                  :value="firstAlertValue"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <v-text-field
                  color="grey1"
                  type="number"
                  label="Segundo alerta"
                  suffix="%"
                  v-model.number="item.secondWarning.percent"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <v-text-field
                  color="grey1"
                  readonly
                  label="Ao gastar"
                  :value="secondAlertValue"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <v-text-field
                  color="grey1"
                  type="number"
                  label="Terceiro alerta"
                  suffix="%"
                  v-model.number="item.thirdWarning.percent"
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <v-text-field
                  color="grey1"
                  readonly
                  label="Ao gastar"
                  :value="thirdAlertValue"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              small
              rounded
              type="submit"
              color="primary"
              class="darkgrey--text px-5"
              :loading="loader"
            >
              Salvar
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { orderBy } from 'lodash'
  import { VMoney } from 'v-money'
  import { required } from 'vuelidate/lib/validators'

  import removeMoneyMask from "../../../../utils/removeMoneyMask";
  import numberToCurrency from "../../../../utils/numberToCurrency";
  import addItemOrcamento from "../../../../api/orcamento/addItemOrcamento";

  const isZero = v => removeMoneyMask(v) > 0;

  export default {
    name: 'DialogFormAddItemOrcamento',
    directives: { money: VMoney },
    data: () => ({
      dialog: false,
      loader: false,
      item: {
        category: null,
        subCategory: '',
        limiteFixo: true,
        limite: 0,
        firstWarning: {
          percent: 50
        },
        secondWarning: {
          percent: 75
        },
        thirdWarning: {
          percent: 80
        }
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
    }),
    computed: {
      orcamento() {
        return this.$store.getters.orcamento;
      },
      categories() {
        return this.$store.getters.categories;
      },
      filteredCategories() {
        return orderBy(this.categories.filter(t => t.type === 'despesa'), ['name']);
      },
      subCategories() {
        let subs = [];

        if (this.orcamento && this.item.category) {
          this.item.category.subs.forEach(sub => {
            let index = this.orcamento.items.enabled.findIndex(it => {
              return it.category._id === this.item.category._id && it.subCategory._id === sub._id;
            });

            if (index === -1 && !sub.fixed) {
              subs.push(sub);
            }
          });
        }
        subs = subs.filter(s => !s.archived)
        return orderBy(subs, ['name']);
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      isIOS() {
        return this.$store.getters.isIOS;
      },
      firstAlertValue() {
        if (this.item) {
          let limite = removeMoneyMask(this.item.limite);
          return numberToCurrency(limite * (this.item.firstWarning.percent / 100));
        }
      },
      secondAlertValue() {
        if (this.item) {
          let limite = removeMoneyMask(this.item.limite);
          return numberToCurrency(limite * (this.item.secondWarning.percent / 100));
        }
      },
      thirdAlertValue() {
        if (this.item) {
          let limite = removeMoneyMask(this.item.limite);
          return numberToCurrency(limite * (this.item.thirdWarning.percent / 100));
        }
      },
      categoryErrors() {
        const errors = [];
        if (!this.$v.item.category.$dirty) return errors;
        !this.$v.item.category.required && errors.push('Selecione a categoria');
        return errors;
      },
      subCategoryErrors() {
        const errors = [];
        if (!this.$v.item.subCategory.$dirty) return errors;
        !this.$v.item.subCategory.required && errors.push('Selecione a sub categoria');
        return errors;
      },
      limiteErrors() {
        const errors = [];
        if (!this.$v.item.limite.$dirty) return errors;
        !this.$v.item.limite.isZero && errors.push('Insira um valor maior do que zero');
        return errors;
      }
    },
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;

        this.item = {
          category: null,
          subCategory: '',
          limiteFixo: true,
          limite: 0,
          firstWarning: {
            percent: 50
          },
          secondWarning: {
            percent: 75
          },
          thirdWarning: {
            percent: 80
          }
        };
        this.resetMoneyInput();
        this.$v.$reset();
      },
      resetMoneyInput() {
        let input = document.getElementById('input-value-item');

        if (input) {
          input.value = this.item.limite;
          input.dispatchEvent(new Event('input'));
        }
      },
      submit() {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          let item = JSON.parse(JSON.stringify(this.item));

          item.limite = removeMoneyMask(item.limite);
          item.recorrente = false

          addItemOrcamento(this.orcamento._id, item)
            .then(orcamento => {
              this.$store.dispatch('setOrcamento', orcamento);
              this.close();
              this.$noty.success('Item salvo com sucesso!')
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível salvar este item. Tente novamente!');
              if (process.env.NODE_ENV !== 'production') console.error(err);
            });
        }
      }
    },
    validations: {
      item: {
        category: { required },
        subCategory: { required },
        limite: { isZero }
      }
    },
    created() {
      this.$bus.$on('showDialogFormAddItemOrcamento', () => {
        if (this.orcamento) {
          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
  .dialog-form-title {
    background-color: #131313;
  }

  .input-label {
    line-height: 1.5em;
  }
</style>
