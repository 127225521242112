<template>
  <v-card flat class="pa-0" color="transparent">
    <v-card-text class="pa-0">
      <v-container grid-list-md class="pa-0">
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-calendar</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Data inicial</span>
                <span class="item-data-value white--text">{{ formatDate }}</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-cake-variant</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Idade atual</span>
                <span class="item-data-value white--text">{{ premissas.idadeAtual }} anos</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-beach</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Idade de independência financeira</span>
                <span class="item-data-value white--text">{{ premissas.idadeIndependencia }} anos</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-timer-sand-empty</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Expectativa de vida</span>
                <span class="item-data-value white--text">{{ premissas.idadeExpectativaVida }} anos</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-cash</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Horizonte de produção/acumulação</span>
                <span class="item-data-value white--text">{{ premissas.anosProducao }} anos</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-cash</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Patrimônio líquido atual</span>
                <span class="item-data-value white--text">{{ numberToCurrency(premissas.patrimonioLiquidoAtual) }}</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 lg4>
            <div class="item">
              <v-icon size="17" class="item-icon" color="grey1">mdi-chart-line-variant</v-icon>
              <div class="item-data">
                <span class="item-data-label primary--text">Aplicação mensal atual</span>
                <span class="item-data-value white--text">{{ aplicacaoAtual }}</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12 class="mt-6">
            <v-card-actions class="px-0 btn-container">
              <v-spacer></v-spacer>

              <v-btn
                rounded
                outlined
                v-if="unlock"
                color="primary"
                class="px-5 mr-2"
                :disabled="!allowed"
                @click="showDialogFormInfosGerais"
              >
                Editar
              </v-btn>

              <v-badge
                overlap
                bordered
                color="primary"
                :content="aplicacoes.length"
              >
                <v-btn
                  rounded
                  outlined
                  class="px-5"
                  color="primary"
                  @click="showDialogAplicacoesMensais"
                >
                  Aplicações mensais
                </v-btn>
              </v-badge>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import moment from 'moment-timezone'
  import numberToCurrency from "../../../../utils/numberToCurrency";

  export default {
    name: 'CardInformacoesGerais',
    data: () => ({
      currentDate: null,
      numberToCurrency
    }),
    computed: {
      allowed() {
        return this.$store.getters.allowed;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      premissas() {
        if (this.indFinanceira)
          return this.indFinanceira.premissas;
      },
      perfil() {
        return this.$store.getters.perfil;
      },
      aplicacoes() {
        return this.indFinanceira.aplicacoes;
      },
      aplicacaoAtual() {
        if (this.aplicacoes && this.aplicacoes.length) {
          let filtered = this.aplicacoes.filter(ap => {
            return moment(ap.data).isSameOrBefore(this.currentDate);
          });

          if (filtered.length) {
            let current = filtered[filtered.length - 1];
            return numberToCurrency(current.valor);
          }
        }

        return numberToCurrency(0);
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      unlock() {
        return this.indFinanceira.period === this.currentDate.format('YYYY-MM');
      },
      formatDate() {
        if (this.indFinanceira && this.indFinanceira.dataInicial) {
          return this.indFinanceira.dataInicial.split('-').reverse().join('/');
        }
        return '';
      }
    },
    methods: {
      setIdadeAtual() {
        if (this.premissas && !this.premissas.idadeAtual && this.perfil && this.perfil.informacoesBasicas.usuario.dataNascimento) {
          let dataNascimento = this.perfil.informacoesBasicas.usuario.dataNascimento;
          this.premissas.idadeAtual = this.currentDate.diff(dataNascimento, 'years');
        }
      },
      setIdadeIndependencia() {
        if (this.premissas && !this.premissas.idadeIndependencia) {
          this.premissas.idadeIndependencia = 70;
        }
      },
      setExpectativaVida() {
        if (this.premissas && !this.premissas.idadeExpectativaVida) {
          this.premissas.idadeExpectativaVida = 100;
        }
      },
      setAnosProducao() {
        this.premissas.anosProducao = this.premissas.idadeIndependencia - this.premissas.idadeAtual;
      },
      showDialogFormInfosGerais() {
        this.$bus.$emit('showDialogFormInfosGerais', this.premissas);
      },
      showDialogAplicacoesMensais() {
        this.$bus.$emit('showDialogAplicacoesMensais');
      }
    },
    watch: {
      premissas: {
        deep: true,
        handler() {
          this.setIdadeAtual();
          this.setIdadeIndependencia();
          this.setExpectativaVida();
          this.setAnosProducao();
        }
      }
    },
    created() {
      this.currentDate = moment().tz('America/Sao_Paulo');
      this.setIdadeAtual();
      this.setIdadeIndependencia();
      this.setExpectativaVida();
      this.setAnosProducao();
    }
  }
</script>

<style scoped>
  .item {
    border-bottom: solid 2px #919191;
    display: flex;
    align-items: flex-end;
  }

  .item-icon {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .item-data {
    display: flex;
    flex-direction: column;
  }

  .item-data-label {
    font-size: 9px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .item-data-value {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .1em;
  }

  @media only screen and (max-width: 390px) {
    .btn-container {
      display: flex;
      flex-direction: column;
    }

    .btn-container .v-btn {
      margin-bottom: 5px;
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @media only screen and (min-width: 600px) {
    .item-data-label {
      font-size: 12px;
    }

    .item-data-value {
      font-size: 15px;
    }
  }

  @media only screen and (min-width: 960px) {
    .v-icon.item-icon {
      font-size: 20px !important;
    }

    .item-data-value {
      font-size: 16px;
    }
  }
</style>
