<template>
  <v-avatar
    :color="avatarProps.color"
    class="transaction-avatar elevation-0"
  >
    <v-icon v-if="showIcon">{{ avatarProps.icon }}</v-icon>

    <v-progress-circular
      v-else
      size="28"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </v-avatar>
</template>

<script>
export default {
  name: 'ListTransactionAvatar',
  props: ['loading', 'transaction'],
  computed: {
    avatarProps() {
      if (this.showIcon && this.transaction.category) {
        return {
          color: this.transaction.category.color,
          icon: this.transaction.category.icon
        }
      }

      return {
        color: 'warning',
        icon: 'mdi-alert'
      }
    },
    showIcon() {
      return this.loading !== this.transaction._id
    }
  }
}
</script>

<style scoped>
.v-avatar {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
}
</style>
