import firebase from 'firebase/app';
import httpClient from '../../plugins/httpClient';

/**
 * Verifica se determinado CPF/CNPJ está em uso
 * @param cpf_cnpj
 * @returns {Promise<unknown>}
 */
const checkCpfCnpj = (cpf_cnpj, userId = '', userRole = '') =>
  new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        return httpClient(
          `/users/check/cpf-cnpj?cpf_cnpj=${cpf_cnpj}&userId=${userId}&userRole=${userRole}`,
          { headers }
        );
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });

export default checkCpfCnpj;
