<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
    :fullscreen="isMobile"
  >
    <v-card
      color="grey3"
      v-if="invoiceId"
      :class="isMobile ? 'no-radius' : ''"
    >
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="success"
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="success--text ml-2">
          {{ refund._id ? 'Editar' : 'Adicionar' }} Reembolso
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <div>
            <div class="label-input primary--text">Valor reembolsado</div>
            <v-text-field
              dense
              type="tel"
              color="grey1"
              v-money="money"
              class="mt-0 pt-0"
              id="input-refund-value"
              v-model="refund.value"
              :error-messages="valueErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon"
                slot="prepend-inner"
              >
                mdi-cash
              </v-icon>
            </v-text-field>
          </div>

          <div>
            <div class="label-input primary--text">Data</div>
            <v-dialog
              ref="dialog"
              v-model="dateMenu"
              :return-value.sync="refund.date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="mt-0 pt-0"
                  :value="formatedDate"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    color="grey1"
                    class="input-icon"
                    slot="prepend-inner"
                  >
                    mdi-calendar
                  </v-icon>
                </v-text-field>
              </template>

              <v-date-picker
                no-title
                color="primary"
                v-model="refund.date"
                scrollable
                locale="pt-br"
                :min="minDate"
                :max="maxDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(refund.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>

          <div>
            <div class="label-input primary--text">Descrição</div>
            <v-text-field
              dense
              type="text"
              color="grey1"
              hint="Opcional"
              persistent-hint
              class="mt-0 pt-0"
              v-model="refund.description"
              :error-messages="descriptionErrors"
            >
              <v-icon
                color="grey1"
                class="input-icon"
                slot="prepend-inner"
              >
                mdi-clipboard-text
              </v-icon>
            </v-text-field>
          </div>

          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              dark
              rounded
              class="px-5"
              color="success"
              type="submit"
              :loading="loader"
            >
              Salvar
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {VMoney} from 'v-money'
import dayjs from 'dayjs'
import removeMoneyMask from '@/utils/removeMoneyMask';
import { required, maxLength } from 'vuelidate/lib/validators';
import saveRefund from '@/api/invoices/saveRefund';

export default {
  name: 'DialogFormInvoiceRefund',
  directives: {money: VMoney},
  data: () => ({
    dialog: false,
    loader: false,
    invoiceId: null,
    dateMenu: false,
    refund: {
      date: '',
      value: '',
      description: ''
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    minDate: '',
    maxDate: ''
  }),
  methods: {
    resetMoneyInput() {
      let input = document.getElementById('input-refund-value');
      if (input) {
        input.value = this.refund.value;
        input.dispatchEvent(new Event('input'));
      }
    },
    close() {
      this.dialog = false;
      this.loader = false;
      this.refund = {
        date: '',
        value: '',
        description: ''
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        let refund = JSON.parse(JSON.stringify(this.refund));

        refund.value = removeMoneyMask(refund.value);
        refund.date = refund.date.split('/').reverse().join('-');

        saveRefund(this.invoiceId, refund)
          .then(resp => {
            if (resp.creditCard) {
              this.$store.dispatch('saveCreditCard', resp.creditCard);
            }

            if (resp.invoice) {
              resp.invoice.creditCard = resp.creditCard._id;
              this.$store.dispatch('saveInvoice', resp.invoice);
            }

            this.close();
            this.$noty.success('Reembolso salvo com sucesso!')
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível salvar este reembolso. Tente novamente!');

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          })
      }
    }
  },
  validations: {
    refund: {
      date: {
        required
      },
      value: {
        greaterThanZero(v) {
          return removeMoneyMask(v) > 0;
        }
      },
      description: { maxLength: maxLength(50) }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.refund.date.$dirty) return errors;
      !this.$v.refund.date.required && errors.push('Insira a data');
      !this.$v.refund.date.isValid && errors.push('Data inválida. Siga o padrão dd/mm/aaaa');
      return errors;
    },
    valueErrors() {
      const errors = [];
      if (!this.$v.refund.value.$dirty) return errors;
      !this.$v.refund.value.greaterThanZero && errors.push('Insira um valor maior do que R$ 0,00');
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.refund.description.$dirty) return errors;
      !this.$v.refund.description.maxLength && errors.push('A descrição deve conter, no máximo, 150 caracteres');
      return errors;
    },
    formatedDate() {
      if (this.refund.date) {
        return this.refund.date.split('-').reverse().join('/')
      }
      return ''
    }
  },
  created() {
    this.$bus.$on('showDialogFormInvoiceRefund', data => {
      if (data.invoiceId) {
        this.invoiceId = data.invoiceId;

        const referenceDate = this.$date(data.referenceDate)

        this.maxDate = referenceDate.subtract(1, 'day').format('YYYY-MM-DD')
        this.minDate = referenceDate.subtract(1, 'month').format('YYYY-MM-DD')

        if (data.refund) {
          this.refund = JSON.parse(JSON.stringify(data.refund));
          this.refund.value = this.refund.value.toFixed(2);
        } else {
          this.refund.date = this.$date().format('YYYY-MM-DD')
        }

        this.resetMoneyInput();
        this.dialog = true;
      }
    });
  }
}
</script>

<style scoped>
.label-input {
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.v-btn.v-size--default {
  font-size: 11px !important;
}
</style>
