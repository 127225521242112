import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const getUserBankAccounts = userId => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/bank-accounts/users/${userId}`;

        return httpClient(url, { headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  })
}

export default getUserBankAccounts;