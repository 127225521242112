<template>
  <v-dialog
    persistent
    :fullscreen="isMobile"
    v-model="dialog"
    max-width="560px"
  >
    <v-card>
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4">
         Permissão de Acesso
        </span>
      </v-card-title>
      <v-card-text class="px-1">
        <v-container grid-list-md>
          <v-layout row wrap ml-4>
            <v-flex xs12>
              <v-checkbox
                dense
                label="Perfil limitado"
                v-model="permissions.perfilLimitado"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-checkbox
                dense
                label="Independência"
                v-model="permissions.indFinanceira"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-checkbox
                dense
                label="Objetivos"
                v-model="permissions.objetivo"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-checkbox
                dense
                label="Orçamento"
                v-model="permissions.orcamento"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-checkbox
                dense
                label="Relatórios"
                v-model="permissions.relatorios"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-checkbox
                dense
                label="Investimentos"
                v-model="permissions.investimentos"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-checkbox
                dense
                label="Seguro de Vida"
                v-model="permissions.lifeInsurance"
              >
              </v-checkbox>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-checkbox
                dense
                label="Planos de Ação"
                v-model="permissions.planosAcao"
              >
              </v-checkbox>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions class="safe-area-bottom">
        <v-spacer></v-spacer>
        <v-btn
          rounded
          class="mb-4 px-5 darkgrey--text"
          color="primary"
          @click.stop="submit"
          :loading="loader"
        >
          Salvar
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import savePermissions from '@/api/users/permissionsUser'

export default {
  name: "DialogAccessPermission",
  data: () => ({
    dialog: false,
    loader: false,
    userId: '',
    permissions: {
      perfilLimitado: false,
      cashflow: true,
      transactions: true,
      relatorios: true,
      planosAcao: true,
      orcamento: false,
      indFinanceira: false,
      objetivo: false,
      investimentos: false,
      lifeInsurance:  false,
      openBanking: false
    }
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isIOS() {
      return this.$store.getters.isIOS
    },
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    close() {
     this.reset()
    },
    reset() {
      this.dialog = false
      this.loader = false
      this.userId = ''
      this.permissions = {
        cashflow: true,
        transactions: true,
        relatorios: true,
        planosAcao: true,
        orcamento: false,
        indFinanceira: false,
        objetivo: false,
        investimentos: false,
        lifeInsurance:  false,
        openBanking: false
      }
    },
    submit() {
      this.loader = true
      savePermissions(this.userId, this.permissions)
        .then(() => {
          this.$bus.$emit('managerGetUsers')
          this.$noty.success("Salvo com sucesso!")
          this.reset()
        })
      .catch(err => {
        this.$noty.error("Não foi possível salvar!")
      })
    }
  },
  created() {
    this.$bus.$on('showDialogAcessPermission', (item) => {
      const user = JSON.parse(JSON.stringify(item))

      this.permissions = user.permissions
      this.userId = user._id

      if(!item.permissions) {
        this.permissions = {
          perfilLimitado: false,
          cashflow: true,
          transactions: true,
          relatorios: true,
          planosAcao: true,
          orcamento: true,
          indFinanceira: true,
          objetivo: true,
          investimentos: true,
          lifeInsurance:  true,
          openBanking: true
        }
      }

      this.dialog = true
    })
  }
}
</script>

