<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    :fullscreen="isMobile"
    :max-width="dialogWidth"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey2">
      <v-card-title class="dialog-form-title hidden-md-and-up" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          :disabled="loader"
          class="back-button"
          @click.stop="close"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="white--text ml-4">Projetos de vida</span>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-container grid-list-md class="px-0">
          <v-layout row wrap>
            <v-flex xs12 class="hidden-sm-and-down mb-5">
              <v-card-title class="primary--text profile-title py-0 px-0">Projetos de vida</v-card-title>
            </v-flex>

            <v-slide-x-transition :key="item._id" v-for="item in projetos">
              <v-flex xs12 sm12 md6 lg4 class="mt-3">
                <v-card color="dakgrey1 column-card" raised height="100%">
                  <v-card-text>
                    <span class="item-title white--text">{{ item.descricao }}</span>

                    <div class="list-item">
                    <span class="pat-item-desc grey1--text">
                      <b>Tipo: </b> {{ item.tipo }}
                    </span>
                    <span class="pat-item-desc grey1--text">
                      <b>Valor: </b> {{ numberToCurrency(item.valor) }}
                    </span>
                    <span class="pat-item-desc grey1--text">
                      <b>Data: </b> {{ formatDate(item.data) }}
                    </span>
                    <span class="pat-item-desc grey1--text" v-if="item.tipoPeriodo">
                      <b>Intervalo: </b> {{ formatType(item.tipoPeriodo) }}
                    </span>
                    </div>
                  </v-card-text>

                  <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      fab
                      x-small
                      outlined
                      color="primary"
                      @click="showDialogProjeto(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      x-small
                      outlined
                      color="primary"
                      @click="removeProjeto(item._id)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-slide-x-transition>

            <v-flex xs12>
              <v-card-actions class="pa-0 mt-5">
                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  outlined
                  class="px-5"
                  type="button"
                  color="primary"
                  @click="showDialogProjeto(null)"
                >
                  Adicionar projeto
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-flex>

            <v-flex xs12>
              <v-card-actions class="px-0 mt-5">
                <v-spacer class="hidden-sm-and-down"></v-spacer>

                <v-btn
                  rounded
                  outlined
                  class="px-5"
                  @click="close"
                  color="primary"
                >
                  Cancelar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  @click="close"
                  color="primary"
                  class="darkgrey--text px-5"
                >
                  Salvar
                </v-btn>

                <v-spacer class="hidden-sm-and-down"></v-spacer>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

  import numberToCurrency from "../../../../utils/numberToCurrency";
  import deleteProjetoIndFinanceira from "../../../../api/indFinanceira/deleteProjetoIndFinanceira";

  export default {
    name: 'DialogEditProjetosVida',
    data: () =>  ({
      dialog: false,
      loader: false,
      dialogWidth: '600px',
      numberToCurrency
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === `customer`;
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      projetos() {
        if (this.indFinanceira)
          return this.indFinanceira.projetos;

        return [];
      }
    },
    methods: {
      showDialogProjeto(projeto) {
        this.$bus.$emit('showDialogProjetoVida', projeto);
      },
      removeProjeto(id) {
        this.$store.dispatch('removeProjetoIndFinanceira', id);
        deleteProjetoIndFinanceira(this.indFinanceira._id, id);
      },
      formatDate(date) {
        if (date)
          return date.split('-').reverse().join('/');

        return '';
      },
      formatType(type) {
        switch (type) {
          case 'years':
            return 'Anual';
          case 'months':
            return 'Mensal';
          case 'weeks':
            return 'Semanal';
          default:
            return '';
        }
      },
      close() {
        this.dialog = false;
        this.loader = false;
        this.dialogWidth = '600px';
      },
      submit() {},
      setDialogWidth() {
        let innerWidth = window.innerWidth;

        if (innerWidth >= 960 && innerWidth <= 1263) {
          this.dialogWidth = '788px';
        } else if (innerWidth >= 1264) {
          this.dialogWidth = '1020px';
        }
      }
    },
    created() {
      this.$bus.$on('showDialogEditProjetosVida', () => {
        this.setDialogWidth();
        this.dialog = true;
      });
    }
  }
</script>

<style scoped>
  .input-label {
    font-size: 9px;
    line-height: 9px;
    letter-spacing: .1em;
  }

  .title-condensed {
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    letter-spacing: .1em;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
  }

  .checkbox-container .input-label {
    font-size: 12px;
    color: white !important;
    cursor: pointer;
  }

  .v-card.column-card {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }

  .item-title {
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .list-item {
    display: flex;
    flex-direction: column;
  }

  .pat-item-desc {
    font-size: 10px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  @media only screen and (min-width: 600px) {
    .input-label {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: .1em;
      font-weight: 400;
    }

    .checkbox-container .input-label {
      font-size: 12px;
      color: white !important;
    }

    .item-title {
      font-size: 14px;
    }

    .pat-item-desc {
      font-size: 12px;
    }
  }

  @media only screen and (min-width: 960px) {
    .container {
      max-width: 788px;
    }

    .checkbox-container .input-label {
      font-size: 16px;
    }

    .v-card__title.profile-title {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: .1em;
      text-transform: uppercase;
    }

    .item-title {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 1264px) {
    .container {
      max-width: 1020px;
    }
  }
</style>
