import logout from './logout';
import store from '../store/index';
import firebase from 'firebase/app';
import moment from 'moment-timezone'

import getUserByUid from '@/api/users/getUserByUid';
import getUserBankAccounts from '@/api/bankAccounts/getUserBankAccounts'
import getCategoriesUser from "../api/categoriesUser/getCategoriesUser";
import countCategoriesUser from "../api/categoriesUser/countCategoriesUser";
import getUserCreditCards from "../api/creditCards/getUserCreditCards";
import getCurrentInvoice from "./creditCards/getCurrentInvoice";

export default async () => {
  try {
    let firebaseUser = firebase.auth().currentUser;

    let storedUser = await getUserByUid(firebaseUser.uid);
    store.dispatch('setUser', storedUser);

    // if (storedUser.role === 'customer')
    //   await getCustomerData(storedUser._id);

    return;
  } catch (err) {
    if (process.env.NODE_ENV !== 'production') console.error(err);
    logout();
  }
};

function getCustomerData(userId) {
  return new Promise((resolve, reject) => {
    let promises = [
      getUserBankAccounts(userId),
      countCategoriesUser(userId),
      getUserCreditCards(userId)
    ];

    Promise.all(promises)
      .then(results => {
        store.dispatch('setBankAccounts', results[0]);

        if (results[1].count !== store.getters.categories.length) {
          getCategoriesUser(userId)
            .then(categories => {
              store.dispatch('setCategories', categories);
              resolve()
            });
        } else {
          resolve();
        }

        store.dispatch('setCreditCards', results[2]);
        results[2].forEach(cc => {
          getCurrentInvoice(cc);
        });
      })
      .catch(reject);
  });
}
