<template>
  <v-card flat color="grey3">
    <v-card-title :class="isIOS ? 'ios-padding' : ''">Filhos</v-card-title>

    <v-card-text class="px-1">
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 v-for="filho in filhos" :key="filho._id">
            <v-layout row wrap class="px-0 mx-0">
              <v-flex xs12 sm9 md3>
                <span class="input-label primary--text">Nome</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="filho.nome"
                ></v-text-field>
              </v-flex>

              <v-flex xs6 sm3 md2>
                <span class="input-label primary--text">Idade</span>
                <v-text-field
                  type="number"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model.number="filho.idade"
                  suffix="anos"
                ></v-text-field>
              </v-flex>

              <v-flex xs6 sm4 md2>
                <span class="input-label primary--text">Custo mensal</span>
                <v-text-field
                  type="tel"
                  v-money="money"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="filho.custoMensal"
                ></v-text-field>
              </v-flex>

              <v-flex xs10 sm7 md4>
                <span class="input-label primary--text">Descrição do custo</span>
                <v-text-field
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="filho.descricao"
                ></v-text-field>
              </v-flex>

              <v-flex xs2 sm1 md1 class="pt-5 text-right">
                <v-btn
                  icon
                  color="error"
                  @click="removeSon(filho._id)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs12 class="my-2">
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 class="my-5 text-center">
            <v-btn
              dark
              rounded
              small
              color="purple"
              class="px-5"
              :disabled="loader"
              @click.stop="addSon"
            >
              Adicionar filho
            </v-btn>
          </v-flex>

          <v-flex xs12>
            <v-card-actions
              class="pa-0 ma-0"
            >
              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="stepBack"
              >
                Voltar
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="skip"
              >
                Pular
              </v-btn>

              <v-btn
                rounded
                color="primary"
                class="px-5 darkgrey--text"
                :loading="loader"
                @click.stop="submit"
              >
                Próximo
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'
import stringCapitalize from '@/utils/stringCapitalize';
import removeMoneyMask from '@/utils/removeMoneyMask';
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'FormFilhos',
  props: ['sons', 'profileId'],
  directives: { money: VMoney },
  data: () => ({
    loader: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    filhos: []
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    }
  },
  methods: {
    copyData() {
      this.filhos = JSON.parse(JSON.stringify(this.sons)).map(son => {
        son.custoMensal = son.custoMensal.toFixed(2)
        return son
      })

      if (!this.filhos.length) {
        this.addSon()
      }
    },
    addSon() {
      this.filhos.push({
        _id: `${Date.now()}`,
        nome: '',
        descricao: '',
        idade: '',
        custoMensal: 'R$ 0,00'
      })
    },
    removeSon(id) {
      const index = this.filhos.findIndex(son => son._id === id)
      if (index > -1) {
        this.filhos.splice(index, 1)
      }

      if (!this.filhos.length) {
        this.addSon()
      }
    },
    stepBack() {
      this.$bus.$emit('backProfileStep')
    },
    async submit() {
      try {
        this.loader = true

        let sons = JSON.parse(JSON.stringify(this.filhos))

        sons = sons.map(son => {
          const obj = {
            _id: son._id,
            nome: stringCapitalize(son.nome),
            idade: Number.parseInt(son.idade),
            descricao: son.descricao.trim(),
            custoMensal: removeMoneyMask(son.custoMensal)
          }

          if (!son._id.replace(/\d+/g, '').length) {
            delete obj._id
          }

          return obj
        }).filter(son => {
          return (
            (typeof son.nome === 'string' && son.nome.length <= 256) &&
            (typeof son.idade === 'number' && !Number.isNaN(son.idade) && son.idade >= 0 && son.idade <= 100) &&
            (!son.descricao || (typeof son.descricao === 'string' && son.descricao.length <= 256)) &&
            (typeof son.custoMensal === 'number' && son.idade >= 0)
          )
        })

        const hasSons = !!sons.length

        const perfil = await updatePerfil(this.profileId, { sons, hasSons, step: 4 })

        await this.$store.dispatch('setPerfil', perfil)

        this.$bus.$emit('nextProfileStep')
      } catch (err) {
        this.$noty.error('Desculpe, não foi possível salvar seus dados. Tente novamente mais tarde.')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.loader = false
      }
    },
    skip() {
      updatePerfil(this.profileId, { step: 4 })
        .then(perfil => {
          this.$store.dispatch('setPerfil', perfil)
        })

      this.$bus.$emit('nextProfileStep')
    }
  },
  mounted() {
    this.copyData()
  }
}
</script>

<style scoped>
@media only screen and (max-width: 475px) {
  .v-card__actions {
    display: flex;
    flex-direction: column-reverse !important;
    text-align: center;
  }

  .v-card__actions .v-btn {
    display: block;
    margin: 0 0 10px 0 !important;
    width: 100%;
  }
}
</style>
