import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';
import store from '@/store'

const changeIuguSubscriptionPlan = (subscriptionId, planIdentifier) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const author = store.getters.user ? store.getters.user._id : null
        const url = `/iugu/subscriptions/${subscriptionId}/change_plan/${planIdentifier}`
        const options = {
          method: 'POST',
          headers: {
            author,
            Authorization: `Bearer ${idToken}`
          }
        }

        return httpClient(url, options)
      })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}

export default changeIuguSubscriptionPlan
