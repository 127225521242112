import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Salvar plano de ação
 * @param data
 * @returns {Promise<Object>}
 */
const saveActionPlan = data => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'POST';
          let url = `/action-plans`;
          let headers = { Authorization: `Bearer ${idToken}` };

          if (data._id) {
            method = "PUT";
            url += `/${data._id}`;
          }

          return httpClient(url, { headers, method, data });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default saveActionPlan;
