import httpClient from "../../plugins/httpClient";
import firebase from 'firebase/app';

const getRecommendations = (nome = '', status= '', page = 1, limit = 5) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'GET';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/recommendations?name=${nome}&status=${status}&page=${page}&limit=${limit}`;
        return httpClient(url, { headers, method });
      })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(reject);
  });
}

export default getRecommendations
