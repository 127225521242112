<template>
  <v-card>
    <v-card-title>
      <span class="total-desc">Informações Básicas</span>
    </v-card-title>

    <v-card-text>
      <v-list dense>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Nome fantasia:</v-list-item-subtitle>
            <v-list-item-title>{{ user.displayName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>mdi-account-card-details</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Razão social:</v-list-item-subtitle>
            <v-list-item-title>{{ user.razaoSocial }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>mdi-card-text</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>CNPJ:</v-list-item-subtitle>
            <v-list-item-title>{{ formatCnpj(user.cpf_cnpj) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { format as formatCnpj } from '@fnando/cnpj';

export default {
  name: 'CardCompanyProfileBasicInfo',
  props: ['user'],
  data: () => ({
    formatCnpj
  })
};
</script>

<style scoped>
.total-desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
</style>
