<template>
  <v-form @submit.prevent="submit">
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <h1 class="primary--text">E-mail</h1>
        </v-flex>

        <v-flex xs12>
          <span class="input-label primary--text">E-mail atual</span>
          <v-text-field
            dense
            disabled
            type="email"
            color="grey1"
            :value="user.email"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <span class="input-label primary--text">Novo e-mail</span>
          <v-text-field
            dense
            type="email"
            color="grey1"
            v-model="email"
            @input="$v.email.$touch()"
            :error-messages="emailErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 class="text-center">
          <v-btn
            rounded
            type="submit"
            color="primary"
            :loading="loader"
            class="darkgrey--text"
          >
            Alterar
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import checkEmailAlreadyUsed from '../../../api/users/checkEmailAlreadyUsed';
  import logout from '../../../utils/logout';
  import updateUserEmail from '../../../api/users/updateUserEmail';

  export default {
    name: 'FormUpdateEmail',
    data: () => ({
      email: '',
      loader: false,
      emailUsed: false
    }),
    computed: {
      user() {
        return this.$store.getters.user;
      },
      emailErrors() {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        !this.$v.email.required && errors.push('Insira o novo e-mail');
        !this.$v.email.email && errors.push('E-mail inválido');
        this.emailUsed && errors.push('E-mail já cadastrado');
        return errors;
      },
    },
    methods: {
      checkEmail() {
        if (this.email && !this.$v.email.$anyError) {
          let email = this.email.toLowerCase().trim();

          checkEmailAlreadyUsed(email, this.user._id)
            .then(resp => (this.emailUsed = resp.exists))
            .catch(err => {
              if (process.env.NODE_ENV !== 'production') console.error(err);
            });
        }
      },
      submit() {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          updateUserEmail(this.user.email, this.email)
            .then(() => logout())
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível alterar seu e-mail. Tente novamente!');

              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      }
    },
    validations: {
      email: { required, email }
    },
    watch: {
      email() {
        this.checkEmail();
      }
    }
  }
</script>

<style scoped>
  h1, h4 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  }

  h4 {
    font-size: 16px;
  }

  .input-label {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
  }
</style>
