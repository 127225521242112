<template>
  <div class="chart-card">
    <div class="mb-2">
      <h1 class="chart-card-title primary--text">Receita Mensal</h1>
      <h1 class="chart-card-title primary--text">
        Média do período:
        <span class="success--text">{{ numberToCurrency(calcAverage) }}</span>
      </h1>
    </div>

    <apexchart
      type="area"
      :options="options"
      :series="series"
      width="100%"
      height="350"
    ></apexchart>

    <div class="chart-card-legends mt-2">
      <v-layout row wrap>
        <v-flex xs6 sm3 md6 v-for="item in legends" :key="item.text">
          <div class="chart-card-legends-item">
            <v-avatar size="10" :color="item.color" class="mr-2"></v-avatar>
            <span class="chart-card-legends-item--title">{{ item.text }}</span>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';
  import numberToCurrency from "../../../utils/numberToCurrency";
  import mediaPeriodo from '@/utils/relatorios/mediaPeriodo';
  import mediaMensal from '@/utils/relatorios/mediaMensal';

  export default {
    name: 'CardReceitaMensal',
    props: [
      'transactions',
      'labels',
      'startDate',
      'endDate',
      'future'
    ],
    components: { apexchart: VueApexCharts },
    data: () => ({
      numberToCurrency,
      options: {
        colors: ['#338A2D', '#9e9e9e'],
        labels: ['Receita', 'Média móvel'],
        chart: {
          toolbar: { show: false },
          background: 'rgba(255,255,255, 0)'
        },
        dataLabels: { enabled: false },
        legend: { show: false },
        grid: { show: false },
        stroke: {
          width: [4,0],
          curve: 'smooth'
        },
        xaxis: {
          show: true,
          type: 'category',
          categories: [],
          labels: {
            rotateAlways: true,
            rotate: -45,
            style: {
              colors: '#9E9E9E'
            }
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: v => numberToCurrency(v)
          }
        },
        markers: {
          size: 5,
          colors: '#32892C',
          strokeWidth: 0
        },
      },
      series: [
        {
          name: 'Receita',
          type: 'line',
          data: []
        },
        {
          name: 'Média mensal',
          type: 'column',
          data: []
        },
      ],
      legends: [
        {
          text: 'Receita',
          color: '#338A2D'
        },
        {
          text: 'Média mensal',
          color: '#9e9e9e'
        }
      ]
    }),
    computed: {
      calcAverage() {
        return mediaPeriodo(this.transactions, this.startDate, this.endDate, this.future)
      }
    },
    methods: {
      buildChart() {
        let options = JSON.parse(JSON.stringify(this.options));

        options.xaxis.categories = this.labels;

        options.yaxis = {
          labels: {
            show: false
          }
        }

        this.series[0].data = this.transactions;
        this.series[1].data = mediaMensal(this.transactions, this.startDate, this.endDate, this.future)

        this.options = options;
      }
    },
    watch: {
      transactions: {
        deep: true,
        handler() {
          this.buildChart();
        }
      }
    },
    mounted() {
      this.buildChart();
    }
  }
</script>

