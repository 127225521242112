/**
 * Cálcular taxa mensal de determinada taxa anual
 * @param {number} taxaAnual
 */
const calcTaxaMensal = taxaAnual => {
  if (taxaAnual !== 0) {
    if (typeof taxaAnual === 'string')
      taxaAnual = (parseFloat(taxaAnual.replace(',', '.')) / 100);
    else
      taxaAnual /= 100;

    let taxaMensal = (Math.pow((1 + taxaAnual), (1 / 12)) - 1) * 100;

    return parseFloat(taxaMensal.toFixed(2));
  }

  return 0;
}

export default calcTaxaMensal;
