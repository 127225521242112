<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card >
      <v-card-title class="dialog-form-title">
        {{ user ? user.displayName : 'Buscando usuário...' }}
      </v-card-title>

      <v-progress-linear
        v-show="loader"
        color="primary"
      ></v-progress-linear>

      <v-card-text v-if="user" >
        <v-list two-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="primary--text">E-mail</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="primary--text">WhatsApp</v-list-item-title>
              <v-list-item-subtitle>+{{ user.phoneCountryCode }} {{ user.phoneNumber }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import getUserById from "@/api/users/getUserById";

export default {
  name: 'DialogUserContacts',
  data: () => ({
    dialog: false,
    loader: false,
    user: null
  }),
  methods: {
    openDialog(user) {
      this.getUser(user._id)
      this.dialog = true
    },
    async getUser(userId) {
      try {
        this.loader = true
        this.user = await getUserById(userId)
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.dialog = false
        this.$noty.error('Falha ao buscar contatos do usuário')
      } finally {
        this.loader = false
      }
    }
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.user = null
      }
    }
  },
  created() {
    this.$bus.$on('showDialogUserContacts', this.openDialog)
  }
}
</script>
