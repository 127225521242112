<template>
  <v-main v-if="user" class="margin-menu safe-area-top safe-area-bottom">
    <v-progress-linear
      indeterminate
      class="ma-0"
      v-if="loader"
      absolute
      color="primary"
    ></v-progress-linear>

    <ToolbarMonthSelector class="hidden-sm-and-down"/>
    <v-container grid-list-md class="dark-bg ">
      <v-layout row wrap>
        <v-flex xs12 class="filter-container">
          <div class="btn-container hidden-sm-and-down">
            <v-btn
              small
              rounded
              outlined
              type="button"
              color="primary"
              class="btn-excel"
              @click="showDialogFormImportOfx"
              v-if="!isCustomer && allowed"
            >
              Importar OFX
            </v-btn>
            <v-btn
              small
              rounded
              outlined
              type="button"
              color="primary"
              class="btn-excel"
              @click="showDialogFormImportPdf"
              v-if="!isCustomer && allowed"
            >
              Importar PDF
            </v-btn>
          </div>

          <v-flex v-if="isMobile" class="d-flex align-center justify-space-between">
            <v-btn
              text
              rounded
              color="white"
              @click="showDialogFormFilterTransactions"
            >
              Filtros
              <v-icon small class="ml-1">mdi-filter-variant</v-icon>
            </v-btn>

            <v-btn
              v-if="!isMobile"
              rounded
              color="white"
              @click="exportCsv"
              :loading="loaderExport"
              class="hidden-sm-and-down"
            >
              Exportar
              <v-icon small class="ml-1">mdi-file-excel</v-icon>
            </v-btn>
          </v-flex>
        </v-flex>


        <v-flex xs12 class="month-selector hidden-md-and-up">
          <v-btn
            icon
            color="grey1"
            class="btn-month"
            @click.stop="changePeriod('back')"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <span class="month-name">{{ monthStr }}</span>

          <v-btn
            icon
            color="grey1"
            class="btn-month"
            @click.stop="changePeriod('next')"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-flex>

        <v-flex xs12 v-if="!isMobile" class="d-flex align-center justify-space-between">
          <v-btn
            small
            rounded
            color="grey2"
            class="primary--text elevation-5 btn-filtros ml-2"
            @click="showDialogFormFilterTransactions"
          >
            Filtros
            <v-icon small>mdi-filter-variant</v-icon>
          </v-btn>
          <v-btn
            small
            rounded
            color="grey2"
            class="primary--text elevation-5 btn-filtros ml-2"
            @click="exportCsv"
            :loading="loaderExport"
          >
            Exportar
            <v-icon small class="ml-1">mdi-file-excel</v-icon>
          </v-btn>
        </v-flex>

        <div class="filter-container">
          <div v-if="filters.selectedBankAccounts.length">
            <span class="input-label grey1--text ml-2">Contas bancárias selecionadas</span>
            <div :class=" isMobile ? '' : 'd-flex ml-1'">
              <v-chip
                class="mr-1"
                :class="isMobile ? 'ml-1' : ''"
                close
                small
                :color="ba.color"
                @click:close="filter('bankAccounts', 1, ba._id)"
                v-model="filters.selectedBankAccounts"
                v-for="ba in filters.selectedBankAccounts"
                :key="ba._id"
              >
                {{ ba.name }}
              </v-chip>
            </div>
          </div>
          <div>
            <span v-if="filters.bankAccountStatus !== ''"
                  class="input-label grey1--text ml-2">Status Conta Bancária</span>
            <div :class=" isMobile ? '' : 'd-flex ml-1'">
              <v-chip
                small
                v-if="filters.bankAccountStatus !== ''"
                class="mr-1 mt-1"
                :class="isMobile ? 'ml-1' : ''"
                close
                @click:close="filter('bankAccountStatus', 2)"
                v-model="filters.bankAccountStatus"
              >
                {{ baStatus }}
              </v-chip>
            </div>
          </div>
          <div>
            <span v-if="filters.type.value !== 'all'" class="input-label grey1--text ml-2">Tipo de Transação</span>
            <div :class=" isMobile ? '' : 'd-flex ml-1'">
              <v-chip
                small
                :color="filters.type.color"
                v-if="filters.type.value !== 'all'"
                class="mr-1 mt-1"
                :class="isMobile ? 'ml-1' : ''"
                close
                @click:close="filter('type', 3)"
                v-model="filters.type"
              >
                {{ filters.type.text }}
              </v-chip>
            </div>
          </div>
          <div v-if="filters.statusFilter !== 'all'">
            <span class="input-label grey1--text ml-2">Status Transação</span>
            <div :class=" isMobile ? '' : 'd-flex ml-1'">
              <v-chip
                small
                v-if="filters.statusFilter !== 'all'"
                class="mr-1 mt-1"
                :class="isMobile ? 'ml-1' : ''"
                close
                @click:close="filter('statusFilter', 4)"
                v-model="filters.statusFilter"
              >
                {{ filterStatus }}
              </v-chip>
            </div>
          </div>
          <div>
            <span v-if="filters.categories.length" class="input-label grey1--text ml-2">Categorias selecionadas</span>
            <div :class=" isMobile ? '' : 'd-flex ml-1'">
              <v-chip
                class="mr-1 mt-1"
                :class="isMobile ? 'ml-1' : ''"
                close
                small
                :color="ca.color"
                @click:close="filter('categories', 5, ca._id)"
                v-model="filters.categories"
                v-for="ca in filters.categories"
                :key="ca._id"
              >
                {{ ca.name }}
              </v-chip>
            </div>
          </div>
          <div>
            <span v-if="filters.subCategories.length"
                  class="input-label grey1--text ml-2">SubCategorias selecionadas</span>
            <div :class=" isMobile ? '' : 'd-flex ml-1'">
              <v-chip
                class="mr-1 mt-1"
                :class="isMobile ? 'ml-1' : ''"
                close
                small
                :color="ca.color"
                @click:close="filter('subcategories', 6, ca._id)"
                v-model="filters.subCategories"
                v-for="ca in filters.subCategories"
                :key="ca._id"
              >
                {{ ca.name }}
              </v-chip>
            </div>
          </div>
        </div>
      </v-layout>
    </v-container>

    <v-container grid-list-md class="container-list">
      <v-layout row wrap>
        <v-flex xs12>
          <ListTransactions
            :type="filters.type"
            :period="period"
            :status="filters.statusFilter"
            :archived="filters.bankAccountStatus"
            :selected-bank-accounts="filteredBankAccountsIds"
            :categories="filteredCategoriesIds"
            :subcategories="filteredSubCategoriesIds"
          />
        </v-flex>
      </v-layout>
    </v-container>

    <DialogFormFilterTransactions/>
  </v-main>
</template>

<script>
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import getLoggedUserData from '../../utils/getLoggedUserData';
import getUserTransactions from '../../api/transactions/getUserTransactions';

import ButtonForms from '@/components/Customer/menu/ButtonForms';
import CustomerToolbar from '../../components/Customer/toolbars/CustomerToolbar';
import CustomerMobileMenu from '../../components/Customer/menu/CustomerMobileMenu';
import ListTransactions from '../../components/Customer/Cashflow/lists/ListTransactions';
import ToolbarMonthSelector from '../../components/Customer/toolbars/ToolbarMonthSelector';
import DialogFormFilterTransactions from '../../components/Customer/Cashflow/dialogs/DialogFormFilterTransactions';
import exportCsv from '@/api/files/exportCsv';

let currentDate = moment().tz('America/Sao_Paulo');
let currentYear = currentDate.year();

export default {
  name: 'Transactions',
  components: {
    ToolbarMonthSelector,
    ButtonForms,
    CustomerToolbar,
    ListTransactions,
    CustomerMobileMenu,
    DialogFormFilterTransactions
  },
  data: () => ({
    monthStr: '',
    groupedTransactions: '',
    loader: false,
    loaderExport: false,
    menuFilter: false,
    period: '',
    filters: {},
    default: {
      type: {text: 'Todas', value: 'all', color: 'white'},
      categories: [],
      subCategories: [],
      selectedBankAccounts: [],
      statusFilter: 'all',
      bankAccountStatus: false,
      dateRangeText: ''
    },
    openedPeriod: null
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === `customer`;
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
    },
    months() {
      return this.$store.getters.months;
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts;
    },
    baStatus() {
      return this.filters.bankAccountStatus ? 'Arquivadas' : 'Ativas';
    },
    filterStatus() {
      return this.filters.statusFilter ? 'Pagas/Recebidas' : 'Não Pagas/Não Recebidas';
    },
    periodNumbers() {
      let year, month, day;

      [year, month] = this.period.split('-');

      return {
        year: parseInt(year),
        month: parseInt(month)
      };
    },
    filteredBankAccountsIds() {
      const ids = [];

      if (this.filters.selectedBankAccounts) {
        this.filters.selectedBankAccounts.forEach(ba => {
          ids.push(ba._id);
        });
      }

      return ids;
    },
    filteredCategoriesIds() {
      if (this.filters.categories) {
        const ids = [];
        this.filters.categories.forEach(ba => {
          ids.push(ba._id);
        });
        return ids;
      }
    },
    filteredSubCategoriesIds() {
      if (this.filters.subCategories) {
        const ids = [];

        this.filters.subCategories.forEach(ba => {
          ids.push(ba._id);
        });
        return ids;
      }
    },
    transactions() {
      return this.$store.getters.transactions;
    }
  },
  methods: {
    reset() {
      this.filters = JSON.parse(JSON.stringify(this.default));
    },
    exportCsv() {
      this.loaderExport = true;

      const data = {
        userId: this.user._id,
        type: this.filters.type.value,
        period: this.period,
        status: this.filters.statusFilter,
        archived: this.filters.bankAccountStatus,
        bankAccounts: this.filteredBankAccountsIds,
        categories: this.filteredCategoriesIds,
        subCategories: this.filteredSubCategoriesIds
      };

      if (!data.categories) {
        data.filter.categories = [];
      }

      if (!data.subCategories) {
        data.filter.subCategories = [];
      }

      exportCsv(data, 'extrato')
        .then(resp => {
          if (resp && resp.empty) {
            this.$noty.error('Não foi possível exportar, nenhuma transação encontrada!');
          }

          this.loaderExport = false;
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }

          this.loaderExport = false;
          this.$noty.error('Desculpe! Não foi possível exportar seu arquivo. Tente novamente mais tarde!');
        });
    },
    showDialogFormImportOfx() {
      this.$bus.$emit('showDialogFormImportOfx');
    },
    showDialogFormImportPdf() {
      this.$bus.$emit('showDialogFormImportPdf');
    },
    showDialogFormFilterTransactions() {
      const filters = {
        type: this.filters.type,
        categories: this.filters.categories,
        subCategories: this.filters.subCategories,
        selectedBankAccounts: this.filters.selectedBankAccounts,
        statusFilter: this.filters.statusFilter,
        bankAccountStatus: this.filters.bankAccountStatus,
        period: this.period
      };
      this.$bus.$emit('showDialogFormFilterTransactions', filters);
    },
    filterTransactions() {
      this.$bus.$emit('filterTransactions', this.filters.type);
    },
    changePeriod(type) {
      let {year, month} = this.periodNumbers;

      if (type === 'next') {
        month++;
        if (month > 12) {
          year++;
          month = 1;
        }
      } else if (type === 'back') {
        month--;
        if (month < 1) {
          year--;
          month = 12;
        }
      }

      if (month < 10)
        month = `0${month}`;

      this.period = `${year}-${month}`;
    },
    getTransactions() {
      this.loader = true;

      if (this.user) {
        getUserTransactions(this.user._id, this.period)
          .then(transactions => {
            this.loader = false;

            if (!this.transactions.length) {
              this.$store.dispatch('setTransactions', transactions);
            } else if (transactions.length) {
              transactions.forEach((t) => {
                this.$store.dispatch('saveTransaction', t);
              });
            }
          })
          .catch(err => {
            this.loader = false;
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    formatMonthStr() {
      let {year, month} = this.periodNumbers;
      let monthData = this.months.find(m => m.numero === month);
      if (year === currentYear)
        this.monthStr = monthData.nome;
      else
        this.monthStr = `${monthData.nome}/${year}`;
    },
    filter(chave, chipNumber, idRemove) {

      if (chave === 'bankAccounts') {
        const index = this.filteredBankAccountsIds.findIndex(ba => ba === idRemove);
        const indexSelectedba = this.filters.selectedBankAccounts.findIndex(ba => ba._id === idRemove);

        if (index > -1 && indexSelectedba > -1) {
          this.filteredBankAccountsIds.splice(index, 1);
          this.filters.selectedBankAccounts.splice(indexSelectedba, 1);
        }
      } else if (chave === 'categories') {
        const index = this.filters.categories.findIndex(ca => ca._id === idRemove);
        const indexSelectedcat = this.filteredCategoriesIds.findIndex(ca => ca === idRemove);

        if (index > -1 && indexSelectedcat > -1) {
          this.filteredCategoriesIds.splice(indexSelectedcat, 1);
          this.filters.categories.splice(index, 1);

          if (this.filters.subCategories) {
            let subsIndex = [];

            this.filters.subCategories.forEach((sub, i) => {
              if (sub.category === idRemove) {
                subsIndex.push(i);
              }
            });
            subsIndex.forEach(index => {//remove a da 0 e a que estava na 1 vai para a 0,
              // então, remover sempre a da 0
              this.filters.subCategories.splice(0, 1);
            });
          }
        }
      } else if (chave === 'subcategories') {
        const index = this.filters.subCategories.findIndex(ca => ca._id === idRemove);
        const indexSelected = this.filteredSubCategoriesIds.findIndex(ca => ca === idRemove);

        if (index > -1 && indexSelected > -1) {
          this.filteredSubCategoriesIds.splice(indexSelected, 1);
          this.filters.subCategories.splice(index, 1);
        }

      } else if (chave === 'type') {
        this.filters.type = {text: 'Todas', value: 'all'};
        this.filters.categories = [];
        this.filters.subCategories = [];

      } else if (chave === 'statusFilter') {
        this.filters.statusFilter = 'all';
      } else if (chave === 'bankAccountStatus') {
        this.filters.bankAccountStatus = '';
      }
    },
    responseOpenedPeriod() {
      this.$bus.$emit('getOpenedPeriod', this.period)
    },
    setFilterType(type) {
      if (type === 'entrada') {
        this.filters.type = {
          text: 'Receitas',
          value: 'entrada',
          type: 'receita',
          color: 'success',
          icon: 'mdi-arrow-up'
        }
      } else if (type === 'saida') {
        this.filters.type = {
          text: 'Despesas',
          value: 'saida',
          type: 'despesa',
          color: 'error',
          icon: 'mdi-arrow-down'
        }
      }
    }
  },
  watch: {
    'filters.type'() {
      this.filterTransactions();
    },
    period() {
      this.formatMonthStr();
      this.getTransactions();
      this.filters.dateRangeText = '';
    },
    filters: {
      handler() {
      },
      deep: true
    }
  },
  beforeCreate() {
    this.$vuetify.theme.dark = true;
  },
  created() {
    this.$bus.$on('closeDialogCustomerInvoiceDetails', (period) => {
      this.getTransactions();
    });

    getLoggedUserData();
    this.reset();
    this.period = currentDate.format('YYYY-MM');
    this.getTransactions();

    this.$bus.$on('updatePeriod', period => {
      this.period = period;
    });

    this.$bus.$on('returnFilters', (filters) => {
      this.filters.bankAccountStatus = filters.bankAccountStatus;
      this.filters.statusFilter = filters.statusFilter;
      this.filters.selectedBankAccounts = filters.selectedBankAccounts;
      this.filters.categories = filters.categories;
      this.filters.subCategories = filters.subCategories;
      this.filters.type = filters.type;
      this.filters.dateRangeText = filters.dateRangeText;
    });

    this.$bus.$on('exportCsv', groupedTransactions => {
      this.groupedTransactions = groupedTransactions;
    });

    if (this.$route.query.type) {
      this.setFilterType(this.$route.query.type)
    }

    this.$bus.$on('requestOpenedPeriod', this.responseOpenedPeriod)
  }
};
</script>

<style scoped>
.containerFilters {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.v-content {
  background-color: #2D2D2D !important;
}

.container.dark-bg {
  background: #131313 !important;
}

.container.container-list {
  padding-bottom: 70px !important;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
}

.filter-container span {
  font-size: 12px;

}

.v-btn, .v-list-item__title {
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  letter-spacing: .1em !important;
}

.v-input--selection-controls {
  margin-top: 0;
}

.uppercase-btn {
  text-transform: uppercase !important;
  font-size: 10px !important;
}

@media only screen and (min-width: 600px) {
  .filter-container {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .filter-container .v-btn, .v-list-item__title {
    font-size: 20px !important;
  }
}

@media only screen and (min-width: 960px) {
  .v-content {
    background-color: #131313 !important;
  }

  .container {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .v-btn.btn-excel, .v-btn.btn-filtros {
    font-size: 12px !important;
    margin-left: 10px;
  }
}

@media only screen and (min-width: 1264px) {
  .container {
    max-width: 1020px;
  }

  .v-btn.btn-excel, .v-btn.btn-filtros {
    font-size: 15px !important;
  }
}
</style>
