<template>
  <v-card flat color="grey2">
    <v-card-title>Videos</v-card-title>

    <v-card-text>
      <v-form @submit.prevent="getVideos">
        <v-layout row wrap>
          <v-flex lg4 md4 sm6 xs12>
            <v-text-field
              color="grey1"
              label="Pesquisar"
              hint="Título ou código do video"
              v-model="filter.search"
            ></v-text-field>
          </v-flex>

          <v-flex lg3 md2 sm6 xs12>
            <v-btn rounded depressed dark color="primary" type="submit" class="mt-2 mr-2 darkgrey--text">
              <v-icon>mdi-search-web</v-icon>
            </v-btn>

            <v-menu bottom :nudge-width="200" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      dark
                      rounded
                      depressed
                      class="mt-2 darkgrey--text"
                      color="primary"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Filtro avançado</span>
                </v-tooltip>
              </template>

              <v-card>
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-select
                      label="Tipo"
                      :items="types"
                      item-text="text"
                      item-value="value"
                      v-model="filter.type"
                    ></v-select>

                    <v-select
                      label="Categoria"
                      :items="categories"
                      item-text="text"
                      item-value="value"
                      v-model="filter.category"
                    ></v-select>

                    <v-select
                      label="Status"
                      :items="status"
                      item-text="text"
                      item-value="value"
                      v-model="filter.active"
                    ></v-select>

                    <v-btn
                      dark
                      block
                      rounded
                      depressed
                      type="button"
                      color="primary"
                      class="darkgrey--text"
                      @click.stop="getVideos"
                    >Filtrar</v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-menu>

            <v-btn
              dark
              rounded
              depressed
              type="button"
              color="primary"
              class="mt-2 ml-2 darkgrey--text"
              @click.stop.prevent="showDialogFormVideo"
            >
              <v-icon>mdi-playlist-plus</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>

      <TableVideos />
    </v-card-text>
  </v-card>
</template>

<script>
import TableVideos from '@/components/Ead/tables/TableVideos';

export default {
  name: 'CardAdminVideos',
  components: { TableVideos },
  data: () => ({
    filter: {
      search: '',
      type: '',
      category: '',
      active: ''
    },
    types: [
      { text: 'Todos', value: '' },
      { text: 'Cliente', value: 'customer' },
      { text: 'Empresa', value: 'company' },
      { text: 'Planejador', value: 'planner' }
    ],
    categories: [
      { text: 'Todas', value: '' },
      { text: 'Aposentadoria', value: 'aposentadoria' },
      { text: 'Fluxo de caixa', value: 'fluxo-caixa' },
      { text: 'Gestão de riscos', value: 'gestao-riscos' },
      { text: 'Gestão tributária', value: 'gestao-tributaria' },
      { text: 'Investimentos', value: 'investimentos' },
      { text: 'Liquidez', value: 'liquidez' },
      { text: 'Planos de ação', value: 'planos-acao' },
      { text: 'Outros', value: 'outro' }
    ],
    status: [
      { text: 'Todos', value: '' },
      { text: 'Ativos', value: true },
      { text: 'Inativos', value: false }
    ]
  }),
  methods: {
    showDialogFormVideo() {
      this.$bus.$emit('showDialogFormVideo');
    },
    getVideos() {
      this.$bus.$emit('getVideos', this.filter);
    }
  }
};
</script>
