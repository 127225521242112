<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
  >
    <v-card class="no-radius">
      <v-card-title
        class="dialog-form-title px-2"
        :class="isIOS ? ['ios-padding', 'fixed-title'] : ''"
      >
        <v-btn
          icon
          class="mr-5"
          color="primary"
          @click.stop="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text">Patrimônio</span>
      </v-card-title>

      <v-card-text class="px-2" :class="isMobile ? 'mobile-margin' : ''" v-if="patrimonio">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex
              xs12 md4
              :key="item._id"
              v-for="item in patrimonio.items"
            >
              <CardItemPatrimonio :item="item" :actions="true"/>
            </v-flex>

            <v-flex xs12 class="mt-10 text-center">
              <v-btn
                dark
                small
                rounded
                class="px-5"
                color="purple"
                @click.stop="showForm(null)"
              >
                Adicionar patrimônio
              </v-btn>
            </v-flex>

            <v-flex xs12 class="mt-10">
              <v-card-actions class="pa-0 ma-0">
                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  color="primary"
                  class="px-5 darkgrey--text"
                  :loading="loader"
                  @click.stop="closeDialog"
                >
                  Salvar
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';
import deleteItemPatrimonio from '@/api/patrimonio/deleteItemPatrimonio';
import CardItemPatrimonio from '@/components/Customer/Perfil/cards/CardItemPatrimonio';

export default {
  name: 'DialogEditoPatrimonio',
  components: { CardItemPatrimonio },
  data: () => ({
    dialog: false,
    loader: false,
    numberToCurrency,
    deleteLoader: ''
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    patrimonio() {
      return this.$store.getters.patrimonio
    }
  },
  methods: {
    openDialog() {
      if (this.patrimonio) {
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.deleteLoader = ''
    },
    showForm(item) {
      this.$bus.$emit('showDialogFormPatrimonio', item)
    },
    async deleteItem(itemId) {
      try {
        if (itemId) {
          this.deleteLoader = itemId

          await deleteItemPatrimonio(this.patrimonio._id, itemId)

          let index = this.patrimonio.items.findIndex(item => item._id === itemId)
          if (index > -1) {
            this.patrimonio.items.splice(index, 1)
          }
        }
      } catch (err) {
        this.$noty.error('Desculpe. Não conseguimos remover este item. Tente novamente mais tarde')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.deleteLoader = ''
      }
    }
  },
  created() {
    this.$bus.$on('showDialogEditPatrimonio', this.openDialog)
  }
}
</script>

<style scoped>
.v-card__title.dialog-form-title.fixed-title {
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 10;
}

.v-card__text.mobile-margin {
  padding-top: 90px !important;
}
</style>
