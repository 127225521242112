import store from '@/store/index'
import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

/**
 * Salvar dados de determinado usuário
 * @param {object} user
 */
const saveUser = user => {
  return new Promise((resolve, reject) => {
    try {
      let currentUser = store.getters.user;

      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let data = user;
          let method = user._id ? 'PUT' : 'POST';
          let url = `/users${user._id ? '/' + user._id : ''}`;
          let headers = {
            Authorization: `Bearer ${idToken}`,
            author: currentUser ? currentUser._id : null
          };

          return httpClient(url, { data, method, headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default saveUser;
