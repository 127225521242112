import httpClient from '@/plugins/httpClient';
import firebase from 'firebase/app';

const updateUserByEmail = (email, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            data,
            method: 'PUT',
            headers: {
              Authorization:  `Bearer ${idToken}`
            }
          }

          return httpClient(`/users/update-by-email/${email}`, options)
        })
        .then(resp => resolve(resp.data))
        .catch(reject)
    } catch(err) {
      reject(err)
    }
  })
}

export default updateUserByEmail
