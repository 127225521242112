<template>
  <v-form @submit.prevent="" class="d-flex align-start">
    <v-text-field
      dense
      rounded
      outlined
      color="primary"
      label="Pesquisar"
      append-icon="mdi-search-web"
    ></v-text-field>
  </v-form>
</template>

<script>
export default {
  name: 'FormSearchPlanosAcao',
  data: () => ({
    search: ''
  }),
  methods: {
    submit() {
      this.$bus.$emit('getActionPlans', this.search);
    }
  }
}
</script>
