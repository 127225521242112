<template>
  <v-main class="margin-menu safe-area-bottom">
    <OverlaySync v-if="overlay || !user" />

    <v-progress-linear
      absolute
      height="6"
      indeterminate
      color="primary"
      :active="loader"
    ></v-progress-linear>

    <v-toolbar
      flat
      class="px-2"
      color="darkgrey"
      :class="isIOS ? 'ios-padding' : ''"
    >
      <v-btn icon class="hidden-md-and-up" v-if="isCustomer"></v-btn>

      <v-spacer class="hidden-md-and-up" v-if="isCustomer"></v-spacer>

      <div class="month-selector">
        <v-btn
          icon
          color="grey1"
          class="btn-month"
          @click.stop="changePeriod('back')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="month-name">{{ monthStr }}</span>

        <v-btn
          icon
          color="grey1"
          class="btn-month"
          @click.stop="changePeriod('next')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-toolbar>

    <v-container grid-list-md v-if="!overlay && user" class="pb-10">
      <v-layout row wrap>
        <v-flex xs12 :class="isIOS ? 'mt-10' : ''">
          <CardTotal :period="period" />
        </v-flex>

        <v-flex xs12 v-if="hasOpenBankingError && isCustomer">
          <AlertOpenBankingCredentials/>
        </v-flex>

        <v-flex xs12 v-if="pendencias">
          <CardPendencias :period="period"/>
        </v-flex>

        <v-flex xs12 v-if="showCarousel">
          <CardCarousel />
        </v-flex>

        <v-flex xs12 v-if="creditCards.length">
          <CardCreditCards />
        </v-flex>

        <v-flex xs12 sm12 md4 lg4 v-if="revenue.length">
          <CardChartPieReceitas :period="period" />
        </v-flex>

        <v-flex xs12 sm12 md4 lg4 v-if="expense.length">
          <CardChartPieDespesas :period="period" />
        </v-flex>

        <v-flex xs12 sm12 md4 lg4 v-if="revenue.length || expense.length">
          <CardChartBarBalanco :period="period" />
        </v-flex>

        <v-slide-x-transition>
          <v-flex xs12 v-if="showGrafico">
            <CardGraficoIndFinanceira tab="cashflow" />
          </v-flex>
        </v-slide-x-transition>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import moment from 'moment-timezone';
import OverlaySync from '../../components/Global/OverlaySync';
import getUserTransactions from '../../api/transactions/getUserTransactions';
import CardTotal from '../../components/Customer/Cashflow/cards/CardTotal';
import CardCarousel from '../../components/Customer/Cashflow/cards/CardCarousel';
import CardCreditCards from '../../components/Customer/Cashflow/cards/CardCreditCards';
import CardChartPieReceitas from '../../components/Customer/Cashflow/cards/CardChartPieReceitas';
import CardChartPieDespesas from '../../components/Customer/Cashflow/cards/CardChartPieDespesas';
import CardChartBarBalanco from '../../components/Customer/Cashflow/cards/CardChartBarBalanco';
import getCustomerData from '../../utils/getCustomerData';
import ButtonForms from '../../components/Customer/menu/ButtonForms';
import CardPendencias from '../../components/Customer/Cashflow/cards/CardPendencias';
import getPendencias from '../../api/pendencias/getPendencias';
import MenuProfileMobile from '../../components/Customer/menu/MenuProfileMobile';
import saveEvent from '../../api/events/saveEvent';
import CardGraficoIndFinanceira from '../../components/Customer/IndFinanceira/cards/CardGraficoIndFinanceira';
import getCategoriesUser from "@/api/categoriesUser/getCategoriesUser";
import filterPeriodTransactions from "@/utils/filterPeriodTransactions";
import hasPluggyItemsWithError from "@/api/pluggyItems/hasPluggyItemsWithError";
import AlertOpenBankingCredentials from "@/components/Customer/OpenBanking/alerts/AlertOpenBankingCredentials";
import getPendentCreditCardsByUserId from "@/api/pendentCreditCards/getPendentCreditCardsByUserId";
import getTotalPendencias from "@/api/pendencias/getTotalPendencias";

export default {
  name: 'Cashflow',
  components: {
    AlertOpenBankingCredentials,
    CardGraficoIndFinanceira,
    MenuProfileMobile,
    CardPendencias,
    ButtonForms,
    CardChartBarBalanco,
    CardChartPieDespesas,
    CardChartPieReceitas,
    CardCreditCards,
    CardCarousel,
    CardTotal,
    OverlaySync,
  },
  data: () => ({
    year: '',
    period: '',
    monthStr: '',
    loader: false,
    overlay: false,
    periodPattern: 'YYYY-MM',
    timezone: 'America/Sao_Paulo',
    currentDate: null,
    currentYear: null,
    currentMonth: null,
  }),
  computed: {
    hasOpenBankingError() {
      return this.$store.getters.hasOpenBankingError
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    transactions() {
      return this.$store.getters.transactions
    },
    currentTransactions() {
      let [year, month] = this.period.split('-');

      if (
        year > this.currentYear ||
        (year == this.currentYear && month > this.currentMonth)
      ) {
        return this.transactions.filter((t) => {
          return (
            (t.invoice && t.invoice.paid && t.invoice.paidIn.slice(0, 7) === this.period) ||
            (t.bankAccount && !t.bankAccount.archived && t.date.slice(0, 7) === this.period) ||
            (t.creditCard && !t.creditCard.archived && t.invoice && !t.invoice.paid && t.invoice.paymentDate.slice(0, 7) === this.period)
          )
        });
      } else {
        return this.transactions.filter((t) => {
          return (
            (t.invoice && t.invoice.paid && t.invoice.paidIn.slice(0, 7) === this.period) ||
            (t.consolidated && t.bankAccount && !t.bankAccount.archived && t.date.slice(0, 7) === this.period)
          )
        });
      }
    },
    revenue() {
      if (this.period) {
        let [year, month] = this.period.split('-');

        if (
          year > this.currentYear ||
          (year == this.currentYear && month > this.currentMonth)
        ) {
          return this.currentTransactions.filter((t) => {
            return (
              t.type === 'entrada' &&
              !t.investment &&
              (t.invoice || (t.bankAccount && !t.bankAccount.archived))
            );
          });
        } else {
          return this.currentTransactions.filter((t) => {
            return (
              t.type === 'entrada' &&
              !t.investment &&
              t.consolidated &&
              t.bankAccount &&
              !t.bankAccount.archived
            );
          });
        }
      }

      return [];
    },
    expense() {
      if (this.period) {
        let [year, month] = this.period.split('-');

        if (
          year > this.currentYear ||
          (year == this.currentYear && month > this.currentMonth)
        ) {
          return this.currentTransactions.filter((t) => {
            return (
              t.type === 'saida' &&
              !t.investment &&
              (t.invoice || (t.bankAccount && !t.bankAccount.archived))
            );
          });
        } else {
          return this.currentTransactions.filter((t) => {
            return (
              t.type === 'saida' &&
              !t.investment &&
              t.consolidated &&
              t.bankAccount &&
              !t.bankAccount.archived
            );
          });
        }
      }

      return [];
    },
    creditCards() {
      return this.$store.getters.creditCards;
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts;
    },
    showCarousel() {
      return (
        !this.transactions.length ||
        this.creditCards.length ||
        this.bankAccounts.length
      );
    },
    pendencias() {
      return (
        this.$store.getters.totalPendencias &&
        (
          this.$store.getters.totalPendencias.revenue.items ||
          this.$store.getters.totalPendencias.expenses.items ||
          this.$store.getters.totalPendencias.investments.items
        )
      )
    },
    transactionsPeriods() {
      return this.$store.getters.transactionsPeriods;
    },
    indFinanceira() {
      return this.$store.getters.independenciaFinanceira;
    },
    showGrafico() {
      return (
        this.indFinanceira && this.indFinanceira.patrimonio.patrimonioRenda
      );
    }
  },
  methods: {
    prepareDates() {
      this.currentDate = moment().tz(this.timezone);
      this.currentYear = this.currentDate.year();
      this.currentMonth = this.currentDate.month() + 1;
      this.year = this.currentDate.year();
      this.changePeriod();
    },
    formatMonthStr() {
      this.monthStr = this.currentDate.format('MMMM');

      if (this.currentDate.year() !== this.year)
        this.monthStr += `/${this.currentDate.year()}`;
    },
    changePeriod(type) {
      if (type === 'next') this.currentDate.add(1, 'months');
      else if (type === 'back') this.currentDate.subtract(1, 'months');

      this.period = this.currentDate.format(this.periodPattern);
      this.formatMonthStr();
      this.$bus.$emit('updatePeriod', this.period);
    },
    checkPluggyItemsErrors(userId) {
      hasPluggyItemsWithError(userId)
        .then(resp => {
          this.$store.dispatch('setOpenBankingError', resp.hasItemsWithError)
        })
        .catch(err => console.error(err))
    },
    getData() {
      if (!this.transactionsPeriods.includes(this.period)) {
        this.loader = true;
        this.overlay = true;

        getUserTransactions(this.user._id, this.period)
          .then((transactions) => {
            this.$store.dispatch('setTransactions', transactions);
            this.loader = false;
            this.overlay = false;
          })
          .catch((err) => {
            console.error(err);
            this.loader = false;
            this.overlay = false;
          });
      }
    },
    getCategories() {
      if (this.user) {
        getCategoriesUser(this.user._id)
          .then(categories => {
            this.$store.dispatch('setCategories', categories)
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    },
    getPendencias() {
      getTotalPendencias(this.user._id)
        .then(response => {
          this.$store.dispatch('setTotalPendencias', response)
        })

      getPendencias(this.user._id)
        .then(response => {
          this.$store.dispatch('setPendencias', response)
        })
    },
    getCustomerData() {
      this.overlay = true;

      this.getCategories()
      this.getPendentCreditCards()
      this.checkPluggyItemsErrors(this.user._id)
      this.getPendencias()

      getCustomerData()
        .then(() => {
          this.prepareDates();
          this.overlay = false;
        })
        .catch((err) => {
          this.overlay = false;
          if (process.env.NODE_ENV !== 'production') console.error(err);
        });
    },
    showMenuProfileMobile() {
      this.$bus.$emit('showMenuProfileMobile');
    },
    async getPendentCreditCards() {
      try {
        if (this.user) {
          const pendentCreditCards = await getPendentCreditCardsByUserId(this.user._id)
          await this.$store.dispatch('setPendentCreditCards', pendentCreditCards)
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      }
    },
    sendLoginLog() {
      let user = this.$store.getters.user;

      if (user && user.role === 'customer') {
        let event = {
          type: 'signin',
          user: user._id,
          date: new Date().getTime(),
        };

        saveEvent(event);
      }
    },
  },
  watch: {
    user() {
      if (this.user) this.prepareDates();
    },
    period(newValue, oldValue) {
      if (oldValue) this.getData();
    },
  },
  beforeCreate() {
    this.$vuetify.theme.dark = true;
  },
  created() {
    this.getCustomerData();
    this.$bus.$on('updateListPendencias', this.getPendencias)
  },
  mounted() {
    this.sendLoginLog();
    this.$store.dispatch('checkActionPlans');
  },
};
</script>

<style scoped>
.v-content {
  background-color: #131313;
  padding-bottom: 70px !important;
}
.month-selector {
  width: 960px;
}

.container {
  max-width: 1020px;
}

@media only screen and (min-width: 960px) {
  .month-selector {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
