<template>
  <v-card flat class="animated fadeInRight">
    <v-card-title>
      <span class="total-desc">Usuários Vínculados</span>
    </v-card-title>

    <v-card-text>
      <v-form @submit.prevent="getUsers">
        <v-layout row wrap>
          <v-flex lg4>
            <v-text-field
              color="accent"
              persistent-hint
              v-model="search"
              label="Pesquisar"
              hint="Nome ou e-mail"
            ></v-text-field>
          </v-flex>

          <v-flex lg3>
            <v-btn dark rounded depressed class="mt-2 mr-2" type="submit" color="darkgrey">
              <v-icon>mdi-account-search-outline</v-icon>
            </v-btn>

            <v-menu bottom v-model="menu" :nudge-width="200" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      dark
                      rounded
                      depressed
                      class="mt-2"
                      color="darkgrey"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Filtro avançado</span>
                </v-tooltip>
              </template>

              <v-card>
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-select
                      label="Tipo"
                      :items="typeItems"
                      item-text="text"
                      item-value="value"
                      v-model="type"
                      v-if="user.role !== 'planner'"
                    ></v-select>

                    <v-autocomplete
                      item-value="_id"
                      v-model="planner"
                      label="Planejador"
                      :items="planners"
                      item-text="displayName"
                      v-if="type === 'customer' && user.role !== 'planner'"
                    ></v-autocomplete>

                    <v-select
                      label="Status"
                      :items="statusItems"
                      item-text="text"
                      item-value="value"
                      v-model="status"
                    ></v-select>

                    <v-btn
                      dark
                      block
                      small
                      rounded
                      depressed
                      type="button"
                      color="darkgrey"
                      :loading="loader"
                      @click.stop="getUsers"
                    >Filtrar</v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-menu>

            <v-menu right bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  dark
                  rounded
                  v-on="on"
                  depressed
                  type="button"
                  color="darkgrey"
                  class="mt-2 ml-2"
                >
                  <v-icon>mdi-account-plus-outline</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click.stop="showForm('customer')">
                  <v-list-item-title>Cliente</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="user.role !== 'planner'" @click.stop="showForm('planner')">
                  <v-list-item-title>Planejador</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="user.role === 'admin'" @click.stop="showForm('company')">
                  <v-list-item-title>Empresa</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="user.role === 'admin'" @click.stop="showForm('admin')">
                  <v-list-item-title>Administrados</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-form>

      <TableCompanyUsers :user="user" />
    </v-card-text>
  </v-card>
</template>

<script>
import getPlannersNames from '@/api/planners/getPlannersNames';
import TableCompanyUsers from '../tables/TableCompanyUsers';

export default {
  name: 'CardCompanyTableUsers',
  components: { TableCompanyUsers },
  props: ['user'],
  data: () => ({
    statusItems: [
      {
        text: 'Todos',
        value: ''
      },
      {
        text: 'Habilitados',
        value: 'false'
      },
      {
        text: 'Desabilitados',
        value: 'true'
      }
    ],
    status: '',
    typeItems: [
      {
        text: 'Todos',
        value: ''
      },
      {
        text: 'Cliente',
        value: 'customer'
      },
      {
        text: 'Planejador',
        value: 'planner'
      }
    ],
    type: '',
    search: '',
    menu: false,
    planners: [],
    companies: [],
    planner: '',
    company: '',
    loader: false
  }),
  methods: {
    getUsers() {
      let filter = {
        type: this.type,
        search: this.search,
        status: this.status
      };

      if (this.user.role === 'company') {
        filter.company = this.user._id;
        filter.planner = '';
      } else if (this.user.role === 'planner') {
        filter.planner = this.user._id;
        filter.company = this.user.company ? this.user.company : '';
      }

      this.$bus.$emit('companyGetUsers', filter);
      this.menu = false;

      setTimeout(() => {
        this.loader = false;
      }, 3000);
    },
    showForm(type) {
      if (type === 'customer') {
        this.$bus.$emit('showFormCustomer');
      } else if (type === 'planner') {
        this.$bus.$emit('showFormPlanner');
      } else if (type === 'company') {
        this.$bus.$emit('showFormCompany');
      } else {
        this.$bus.$emit('showFormAdmin');
      }
    },
    getPlannersNames() {
      getPlannersNames()
        .then(planners => {
          this.planners = planners;
          this.planners.splice(0, 0, { displayName: 'Todos', _id: '' });
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.planners = [{ displayName: 'Todos', _id: '' }];
        });
    }
  },
  watch: {
    type() {
      this.planner = '';
    }
  },
  mounted() {
    this.getUsers();

    if (this.user.role !== 'planner') this.getPlannersNames();
  }
};
</script>

<style scoped>
.total-desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
</style>
