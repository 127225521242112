<template>
  <div class="safe-area-top safe-area-bottom">
    <div class="btn-container">
      <v-btn
        rounded
        outlined
        color="primary"
        @click="expandAll"
        class="px-5 text-capitalize"
      >
        {{ this.panels.length < 9 ? 'Ver' : 'Ocultar' }} perfil completo
      </v-btn>
    </div>

    <v-expansion-panels
      multiple
      class="mt-5"
      v-model="panels"
    >
      <v-expansion-panel v-if="hasRelationship">
        <v-expansion-panel-header class="primary--text">Relacionamento</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CardRelacionamento/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="primary--text">Filhos</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CardSons/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="primary--text">Ocupação Profissional</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CardOcupacaoProfissional/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="primary--text">Experiência Financeira</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CardExperienciaFinanceira/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="primary--text">Patrimônio</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CardPatrimônio/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="primary--text">Proteção Familiar</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CardProtecaoFamiliar/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="primary--text">Projetos de Vida</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CardProjetosVida/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="primary--text">Independência Financeira</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CardIndFinanceira/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CardRelacionamento from '@/components/Customer/Perfil/cards/CardRelacionamento';
import CardSons from '@/components/Customer/Perfil/cards/CardSons';
import CardOcupacaoProfissional from '@/components/Customer/Perfil/cards/CardOcupacaoProfissional';
import CardExperienciaFinanceira from '@/components/Customer/Perfil/cards/CardExperienciaFinanceira';
import CardPatrimônio from '@/components/Customer/Perfil/cards/CardPatrimônio';
import CardProjetosVida from '@/components/Customer/Perfil/cards/CardProjetosVida';
import CardIndFinanceira from '@/components/Customer/Perfil/cards/CardIndFinanceira';
import CardProtecaoFamiliar from '@/components/Customer/Perfil/cards/CardProtecaoFamiliar';

export default {
  name: 'CardExpansionPerfil',
  components: {
    CardProtecaoFamiliar,
    CardIndFinanceira,
    CardProjetosVida,
    CardPatrimônio,
    CardExperienciaFinanceira,
    CardOcupacaoProfissional,
    CardSons,
    CardRelacionamento
  },
  data: () => ({
    panels: []
  }),
  computed: {
    perfil() {
      return this.$store.getters.perfil;
    },
    hasRelationship() {
      return ['Casado(a)', 'União estável'].includes(this.perfil.informacoesBasicas.usuario.estadoCivil);
    }
  },
  methods: {
    expandAll() {
      if (this.panels.length < 9) {
        this.panels = [0, 1, 2, 3, 4, 5, 6, 7, 8];
      } else {
        this.panels = [];
      }
    }
  }
};
</script>

<style scoped>
.btn-container {
  margin-top: 10px;
  text-align: center;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #404040;
}

@media only screen and (min-width: 960px) {
  .btn-container {
    text-align: right;
  }
}
</style>
