<template>
  <v-overlay :value="overlay" z-index="10" opacity="0.9">
    <div class="overlay-loader">
      <v-progress-circular indeterminate size="50" color="primary" class="mb-2"></v-progress-circular>
      <span class="primary--text">{{ !!text ? text : 'Sincronizando...' }}</span>
    </div>
  </v-overlay>
</template>

<script>
  export default {
    name: 'OverlaySync',
    props: ['overlay', 'text']
  }
</script>

<style scoped>
  .overlay-loader {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .overlay-loader span {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: .1em;
  }
</style>
