<template>
  <v-card flat>
    <v-card-title>Deduções</v-card-title>
    <v-card-subtitle>Declaração simples</v-card-subtitle>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-layout row wrap>
          <v-flex lg4 md4 sm12 xs12>
            <v-text-field
              type="tel"
              color="grey1"
              v-money="money"
              id="limite-tributaveis"
              label="Limite de Rendimentos Tributáveis"
              v-model="deducoesIrSimples.limiteRedimentosTributaveis"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            rounded
            depressed
            class="px-5 darkgrey--text"
            type="submit"
            color="primary"
            :loading="loader"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money';
import saveConfigIr from '@/api/config/saveConfigIr';
import removeMoneyMask from '@/utils/removeMoneyMask';

export default {
  name: 'CardConfigDeducoesSimples',
  directives: { money: VMoney },
  data: () => ({
    loader: false,
    money: {
      decimal: ',',
      precision: 2,
      prefix: 'R$ ',
      thousands: '.'
    },
    deducoesIrSimples: {
      limiteRedimentosTributaveis: 0
    }
  }),
  computed: {
    config() {
      return this.$store.getters.configIr;
    }
  },
  methods: {
    resetMoneyInput(value) {
      let input = document.getElementById('limite-tributaveis');
      if (input) {
        input.value = value;
        input.dispatchEvent(new Event('input'));
      }
    },
    prepareData() {
      if (this.config.deducoesIrSimples) {
        this.deducoesIrSimples = JSON.parse(
          JSON.stringify(this.config.deducoesIrSimples)
        );
        this.deducoesIrSimples.limiteRedimentosTributaveis = removeMoneyMask(
          this.deducoesIrSimples.limiteRedimentosTributaveis
        ).toFixed(2);

        this.resetMoneyInput(
          this.deducoesIrSimples.limiteRedimentosTributaveis
        );
      }
    },
    submit() {
      this.loader = true;
      let deducoesIrSimples = JSON.parse(
        JSON.stringify(this.deducoesIrSimples)
      );
      deducoesIrSimples.limiteRedimentosTributaveis = removeMoneyMask(
        deducoesIrSimples.limiteRedimentosTributaveis
      );

      saveConfigIr(this.config._id, { deducoesIrSimples })
        .then(config => {
          this.loader = false;
          this.$store.dispatch('setConfigIr', config);
          this.$noty.success('Dados salvos com sucesso!');
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
          this.$noty.error('Falha ao salvar dados! Tente novamente mais tarde');
        });
    }
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.prepareData();
      }
    }
  },
  mounted() {
    this.prepareData();
  }
};
</script>
