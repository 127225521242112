<template>
  <div
    class="bottom-nav elevation-2 safe-area-bottom"
    style="z-index: 10"
  >
    <router-link
      v-ripple
      :key="item.route"
      v-for="item in routes.slice(0,2)"
      class="bottom-nav-btn"
      :to="item.route"
    >
      <i
        class="lni bottom-nav-btn-icon"
        :class="(currentRoute === item.route && !menu ? 'primary--text' : 'grey1--text') + ` ${item.icon}`"
      ></i>
      <span
        class="bottom-nav-btn-text primary--text"
        :class="currentRoute === item.route && !menu ? 'primary--text' : 'grey1--text'"
      >
        {{item.label}}
      </span>
    </router-link>

    <div class="bottom-nav-btn">
      <div
        class="menu elevation-12"
        v-ripple
        @click.stop="showMenu"
        :style="sheet ? 'z-index: 3' : ''"
        :class="isIOS ? 'ios-margin-bottom' : ''"
      >
        <i
          class="darkgrey--text lni"
          :class="sheet ? 'lni-close' : 'lni-plus'"
        ></i>
      </div>

      <v-overlay :value="sheet" z-index="1" color="darkgrey" opacity="0.8"></v-overlay>

      <div
        class="menu-items"
        :style="sheet ? 'z-index: 2' : ''"
        :class="isIOS ? 'ios-margin-bottom' : ''"
      >
        <div
          class="item1 success"
          id="item1"
          @click="
            showDialogFormRevenue();
            showMenu();
          "
        >
          <i class="white--text lni lni-revenue"></i>
          <small class="grey1--text" v-if="sheet">Receita</small>
        </div>

        <div
          class="item2 error"
          id="item2"
          @click="
            showDialogFormExpense();
            showMenu();
          "
        >
          <i class="white--text lni lni-wallet"></i>
          <small class="grey1--text" v-if="sheet">Débito</small>
        </div>

        <div
          class="item3 warning"
          id="item3"
          @click="
            showDialogFormCreditCardExpense();
            showMenu();
          "
        >
          <i class="white--text lni lni-credit-cards"></i>
          <small class="grey1--text" v-if="sheet">
            Cartão de<br>Crédito
          </small>
        </div>

        <div
          class="item4 info"
          id="item4"
          @click="
            showDialogBankTransfer();
            showMenu();
          "
        >
          <i class="white--text lni lni-investment"></i>
          <small class="grey1--text smaller" v-if="sheet">Transf.</small>
        </div>

        <div
          class="item5 primary"
          id="item5"
          @click="
            showDialogFormInvestment();
            showMenu();
          "
        >
          <i class="white--text lni lni-stats-up"></i>
          <small class="grey1--text" v-if="sheet">Investimento</small>
        </div>
      </div>
    </div>

    <router-link
      v-ripple
      :key="item.route"
      v-for="item in routes.slice(2,3)"
      class="bottom-nav-btn"
      :to="item.route"
    >
      <i
        class="lni bottom-nav-btn-icon"
        :class="(currentRoute === item.route && !menu ? 'primary--text' : 'grey1--text') + ` ${item.icon}`"
      ></i>
      <span
        class="bottom-nav-btn-text primary--text"
        :class="currentRoute === item.route && !menu ? 'primary--text' : 'grey1--text'"
      >
        {{item.label}}
      </span>
    </router-link>

    <div v-ripple class="bottom-nav-btn" @click="menu = !menu">
      <i
        class="lni lni-menu bottom-nav-btn-icon"
        :class="menu ? 'primary--text' : 'grey1--text'"
      ></i>
      <span
        class="bottom-nav-btn-text"
        :class="menu ? 'primary--text' : 'grey1--text'"
      >
        Menu
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bottom-menu',
  data: () => ({
    sheet: false,
    menu: false,
    nav: 'inicio',
    counter: 0
  }),
  computed: {
    user() {
      return this.$store.getters.user
    },
    currentRoute() {
      return this.$route.path
    },
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    routes() {
      const routes = []

      if (this.user) {
        if (this.user.permissions.cashflow) {
          routes.push({
            label: 'Início',
            route: '/cashflow',
            icon: 'lni-home'
          })
        }

        if (this.user.permissions.transactions) {
          routes.push({
            label: 'Extrato',
            route: '/transactions',
            icon: 'lni-list'
          })
        }

        if (this.user.permissions.orcamento) {
          routes.push({
            label: 'Orçamento',
            route: '/orcamento',
            icon: 'lni-spiner-solid'
          })
        } else {
          routes.push({
            label: 'Relatórios',
            route: '/relatorios',
            icon: 'lni-bar-chart'
          })
        }
      }

      return routes
    }
  },
  methods: {
    showMenu() {
      this.menu = false;

      if (!this.sheet) {
        this.sheet = true;

        if (this.counter === 0) {
          if (window.innerWidth < 375) {
            document.getElementById('item1').style.transform =
              'translate(-120px,-60px)';
            document.getElementById('item2').style.transform =
              'translate(-60px,-75px)';
            document.getElementById('item3').style.transform =
              'translate(0px,-85px)';
            document.getElementById('item4').style.transform =
              'translate(60px,-75px)';
            document.getElementById('item5').style.transform =
              'translate(120px,-60px)';
          } else {
            document.getElementById('item1').style.transform =
              'translate(-140px,-60px)';
            document.getElementById('item2').style.transform =
              'translate(-70px,-90px)';
            document.getElementById('item3').style.transform =
              'translate(0px,-100px)';
            document.getElementById('item4').style.transform =
              'translate(70px,-90px)';
            document.getElementById('item5').style.transform =
              'translate(140px,-60px)';
          }
        }
      } else {
        this.sheet = false;

        if (this.counter === 0) {
          document.getElementById('item1').style.transform =
            'translate(0px,0px)';
          document.getElementById('item2').style.transform =
            'translate(0px,0px)';
          document.getElementById('item3').style.transform =
            'translate(0px,0px)';
          document.getElementById('item4').style.transform =
            'translate(0px,0px)';
          document.getElementById('item5').style.transform =
            'translate(0px,0px)';
        }
      }
    },
    showDialogFormRevenue() {
      this.fab = false;
      this.$bus.$emit('showDialogFormRevenue');
    },
    showDialogFormExpense() {
      this.fab = false;
      this.$bus.$emit('showDialogFormExpense');
    },
    showDialogBankTransfer() {
      this.fab = false;
      this.$bus.$emit('showDialogBankTransfer');
    },
    showDialogFormInvestment() {
      this.$bus.$emit('showDialogFormInvestment');
    },
    showDialogFormCreditCardExpense() {
      this.$bus.$emit('showDialogFormCreditCardExpense');
    },
  },
  watch: {
    menu() {
      this.$bus.$emit('toggle-navigation-drawer', this.menu);
    },
  },
  created() {
    this.$bus.$on('toggle-bottom-menu', (status) => {
      this.menu = status;
    });
  },
};
</script>

<style scoped>
.lni {
  font-size: 20px;
}

.bottom-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 56px;
  background-color: #2d2d2d;
  position: fixed;
  bottom: 0;
  padding-left: 0;
}

.bottom-nav-btn {
  display: flex;
  height: 56px;
  flex-grow: 1;
  width: calc(100% / 5);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none !important;
}

.bottom-nav-btn-text {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.menu {
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: #d1ac68;
  border-radius: 50%;
  position: absolute;
  bottom: -9.5px;
  margin-bottom: 20px !important;
}

.menu-items {
  height: 56px;
  width: 56px;
  position: absolute;
  margin: auto auto 10px auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.menu-items.ios-margin-bottom {
  margin-bottom: calc(env(safe-area-inset-bottom) + 10px) !important;
}

.ios-margin-bottom {
  margin-bottom: calc(env(safe-area-inset-bottom) + 20px) !important;
}

.menu-items > div {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s linear;
  text-align: center;
}

.menu-items > div small {
  position: absolute;
  top: 58px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.1em;
  font-size: 10px;
}

.item2 {
  bottom: 56px;
}

.item3 {
  bottom: 112px;
}

.item4 {
  bottom: 168px;
}

.item5 {
  bottom: 224px;
}
</style>
