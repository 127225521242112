<template>
  <div>
    <OverlaySync
      v-if="accountsLoader"
      text="Buscando contas..."
    />

    <v-container grid-list-md class="px-0">
      <v-layout row wrap class="px-0">
        <v-flex
          xs12
          class="mb-3"
          :key="account.id"
          v-for="account in accounts"
        >
          <CardOpenBankingAccount
            :account="account"
            :connector="item.connector"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import getPluggyAccountsByPluggyItemId from "@/api/pluggyItems/getPluggyAccountsByPluggyItemId";
import OverlaySync from "@/components/Global/OverlaySync";
import CardOpenBankingAccount from "@/components/Customer/OpenBanking/cards/CardOpenBankingAccount";

export default {
  name: 'OpenBankingAccountsExpansion',
  components: {CardOpenBankingAccount, OverlaySync},
  props: ['item'],
  data: () => ({
    accounts: [],
    accountsLoader: false
  }),
  methods: {
    async getAccounts() {
      try {
        this.accountsLoader = true
        this.accounts = await getPluggyAccountsByPluggyItemId(this.item.id)
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.accountsLoader = false
      }
    }
  },
  watch: {
    item() {
      this.getAccounts()
    }
  },
  mounted() {
    this.getAccounts()
  }
}
</script>

<style scoped>
.v-card.bordered-card {
  border: 1px #919191 solid !important;
}
</style>
