import moment from 'moment-timezone'

const plotReal = (
  taxaProducao,
  taxaConsumo,
  periodos,
  periodosProducao,
  patrimonioAtual,
  aplicacoes,
  rendas,
  rendaInss,
  rendaOutros,
  projetosVida,
  dataInicial
) => {
  taxaProducao /= 100;
  taxaConsumo /= 100;
  dataInicial = moment(dataInicial);

  let valores = [ patrimonioAtual ];

  for (let i = 1; i <= periodos; i++) {
    let year = dataInicial.year();
    let month = dataInicial.month();

    let projetos = projetosVida.filter(p => {
      return p.data.slice(0,7) === dataInicial.format('YYYY-MM');
    });

    let totalProjetos = 0;
    let projetosLen = projetos.length;
    for (let p = 0; p < projetosLen; p++) {
      if (projetos[p].tipo === 'entrada') totalProjetos += projetos[p].valor;
      else totalProjetos -= projetos[p].valor;
    }

    if (i <= periodosProducao) {
      let aplicacao = aplicacoes.find((ap, index) => {
        let dataAplicacao = moment(ap.data);
        let yearAp = dataAplicacao.year();
        let monthAp = dataAplicacao.month();

        if (yearAp === year && monthAp === month) {
          return true;
        } else if (yearAp < year || (yearAp === year && monthAp < month)) {
          let next = aplicacoes[index + 1];

          if (next) {
            let nextDate = moment(next.data);
            let nextYear = nextDate.year();
            let nextMonth = nextDate.month();

            return (nextYear > year || (nextYear === year && nextMonth > month));
          } else {
            return true;
          }
        }
      });

      let valorAplicacao = aplicacao ? aplicacao.valor : 0;

      let sd = valores[i-1] * (1 + taxaProducao);
      let sdf = sd + valorAplicacao + totalProjetos;

      valores.push(sdf);
    } else {
      let renda = rendas.find((r, index) => {
        let dataRenda = moment(r.data);
        let yearR = dataRenda.year();
        let monthR = dataRenda.month();

        if (yearR === year && monthR === month) {
          return true;
        } else if (yearR < year || (yearR === year && monthR < month)) {
          let next = rendas[index + 1];

          if (next) {
            let nextDate = moment(next.data);
            let nextYear = nextDate.year();
            let nextMonth = nextDate.month();

            return (nextYear > year || (nextYear === year && nextMonth > month));
          } else {
            return true;
          }
        }
      });

      let valorRenda = renda ? ((renda.valor - (rendaInss + rendaOutros)) * -1) : 0;

      let sd = valores[i-1] * (1 + taxaConsumo);
      let sdf = sd + valorRenda + totalProjetos;

      valores.push(sdf);
    }

    dataInicial = moment(dataInicial.add(1, 'months'));
  }

  return valores;
}

export default plotReal;
