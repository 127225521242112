<template>
  <v-dialog scrollable persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>Remover Categoria</v-card-title>
      <v-card-subtitle>Tem certeza que deseja remover esta categoria?</v-card-subtitle>

      <v-card-text>
        <p>Esta categoria não poderá mais ser utilizada por novos usuários após a remoção.</p>

        <v-card-actions class="pa-0">
          <v-btn
            text
            small
            rounded
            class="px-5"
            color="accent"
            :disabled="loader"
            @click.stop="close"
          >Cancelar</v-btn>

          <v-spacer></v-spacer>

          <v-btn
            small
            rounded
            depressed
            class="px-5"
            color="error"
            :loading="loader"
            @click.stop="remove"
          >Remover</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import deleteAdminCategory from '@/api/categories/deleteAdminCategory';

export default {
  name: 'DialogRemoveAdminCategory',
  data: () => ({
    dialog: false,
    loader: false,
    categoryId: ''
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.companyId = '';
    },
    remove() {
      this.loader = true;

      deleteAdminCategory(this.categoryId)
        .then(() => {
          this.$bus.$emit('getAdminCategories');
          this.close();
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
          this.$noty.error('Falha ao remover categoria! Tente novamente');
        });
    }
  },
  created() {
    this.$bus.$on('showDialogRemoveAdminCategory', categoryId => {
      if (categoryId) {
        this.categoryId = categoryId;
        this.dialog = true;
      }
    });
  }
};
</script>

<style scoped>
.v-card.no-radius {
  border-radius: 0 !important;
}

.title {
  display: flex;
  align-items: center;
}
</style>
