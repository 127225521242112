import httpClient from '@/plugins/httpClient';

/**
 * Check if an email is already user
 * @param {string} email
 */
const checkEmailAlreadyUsed = (email, userId = '') => {
  return new Promise((resolve, reject) => {
    httpClient(`/users/check/email?email=${email}&userId=${userId}`)
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}


export default checkEmailAlreadyUsed;
