<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="error"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="error--text ml-4">
          Remover renda
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <p class="description">Tem certeza que deseja remover esta renda?</p>

        <v-card-actions class="px-0 pb-0">
          <v-spacer></v-spacer>

          <v-btn
            fab
            dark
            small
            color="error"
            @click="submit"
            :loading="loader"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import deleteRendaIndFinanceira from "../../../../api/indFinanceira/deleteRendaIndFinanceira";

  export default {
    name: 'DialogRemoveRendaIndFinanceira',
    data: () => ({
      dialog: false,
      loader: false,
      id: null
    }),
    computed: {
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      }
    },
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.id = null;
      },
      submit() {
        this.loader = true;

        deleteRendaIndFinanceira(this.indFinanceira._id, this.id)
          .then(indFinanceira => {
            this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
            this.close();
            this.$noty.success('Renda removido com sucesso!')
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível remover esta renda. Tente novamente!');
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    created() {
      this.$bus.$on('showDialogRemoveRendaIndFinanceira', id => {
        if (id) {
          this.id = id;
          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
  .description {
    color: white;
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }
</style>
