import firebase from 'firebase/app'
import httpClient from "@/plugins/httpClient"

export default () => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const options = {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }

        return httpClient('/aliquota-nfse', options)
      })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}
