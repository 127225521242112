import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const listBanksAndCodes = async () => {
   const resp = await firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = '/banks-and-codes';

        return httpClient(url, { headers });
      })
       return resp.data
}

export default listBanksAndCodes;
