<template>
  <v-dialog fullscreen persistent scrollable v-model="dialog">
    <v-card color="grey lighten-3" sytle="border-radius: 0 !important;">
      <v-progress-linear
        class="ma-0"
        indeterminate
        color="accent"
        v-if="showLoader"
        sytle="border-radius: 0 !important;"
      ></v-progress-linear>

      <v-card-title v-if="user" class="title">
        <v-btn icon large color="accent" @click.stop="close">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        {{ user.displayName }}
      </v-card-title>

      <v-card-text v-if="!showLoader" class="px-0">
        <v-container>
          <v-layout row wrap>
            <v-flex xs12>
              <v-tabs
                v-model="tab"
                show-arrows
                hide-slider
                background-color="transparent"
                color="accent"
              >
                <v-tab v-for="(item, i) in tabs" :key="i" :href="`#tab-${i}`">{{ item }}</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item value="tab-0">
                  <TabUsers :user="user" />
                </v-tab-item>

                <v-tab-item value="tab-1"></v-tab-item>

                <v-tab-item value="tab-2" class="px-3">
                  <TabProfile :user="user" />
                </v-tab-item>

                <v-tab-item value="tab-3" class="px-3">
                  <TabHistory :user="user" />
                </v-tab-item>
              </v-tabs-items>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TabUsers from '../company-details/tabs/TabUsers';
import getUserById from '../../../../api/users/getUserById';
import TabHistory from '../company-details/tabs/TabHistory';
import TabProfile from '../company-details/tabs/TabProfile';

export default {
  name: 'DialogCompanyDetails',
  components: {
    TabProfile,
    TabHistory,
    TabUsers
  },
  data: () => ({
    dialog: false,
    user: null,
    tab: 'tab-0',
    tabs: ['Usuários', 'Financeiro', 'Perfil', 'Histórico']
  }),
  computed: {
    showLoader() {
      return !this.user;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.user = null;
      this.company = null;
      this.tab = 'tab-0';
    },
    getData() {
      getUserById(this.user._id)
        .then(user => {
          this.user = user;
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.close();
          this.$noty.error(
            'Falha ao buscar usuário. Atualize a página e tente novamente!'
          );
        });
    }
  },
  created() {
    this.$bus.$on('showDialogCompanyDetails', user => {
      this.user = user;
      this.dialog = true;
      this.getData();
    });
  }
};
</script>

<style scoped>
.container {
  max-width: 1264px;
  margin-left: auto;
  margin-right: auto;
}

.theme--light.v-tabs-items {
  background-color: transparent !important;
}

.title {
  display: flex;
  align-items: center;
}

.title .v-btn {
  margin-right: 10px;
}
</style>
