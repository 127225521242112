<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    max-width="960px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey3">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="primary"
          :disabled="loader || loaderOther"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text ml-4">
          {{ transaction._id ? 'Editar' : 'Novo' }} Investimento
        </span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit()">
          <v-container class="px-0 pt-5" grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <span class="input-label primary--text">Valor</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  v-money="money"
                  class="mt-0 pt-0"
                  v-model="transaction.value"
                  id="input-investment-value"
                  :error-messages="valueErrors"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-cash
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <span class="input-label primary--text">Data</span>
                <v-dialog
                  ref="dialog"
                  v-model="dateMenu"
                  :return-value.sync="transaction.date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 pt-0"
                      :value="formatedDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        color="grey1"
                        class="input-icon"
                        slot="prepend-inner"
                      >
                        mdi-calendar
                      </v-icon>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    color="primary"
                    v-model="transaction.date"
                    scrollable
                    locale="pt-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey2" @click="dateMenu = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.dialog.save(transaction.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12 md4>
                <span class="input-label primary--text">Conta bancária</span>
                <v-select
                  color="grey1"
                  item-text="name"
                  item-value="_id"
                  class="mt-0 pt-0"
                  menu-props="auto"
                  :items="bankAccounts"
                  v-model="transaction.bankAccount"
                  :error-messages="bankAccountErrors"
                  @change="$v.transaction.bankAccount.$touch()"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-bank
                  </v-icon>

                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color"
                    >mdi-bank
                    </v-icon
                    >
                    <span>{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Observação</span>
                <v-text-field
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="transaction.obs"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-clipboard-text
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-layout row wrap>
                  <v-flex
                    xs12
                    sm4
                    class="d-flex align-sm-center justify-sm-center"
                    v-if="!transaction._id"
                  >
                    <v-checkbox
                      color="primary"
                      v-model="transaction.consolidated"
                      label="Investir"
                    ></v-checkbox>
                  </v-flex>

                  <v-flex
                    xs6
                    sm4
                    class="d-flex align-sm-center justify-sm-center"

                    v-if="(!transaction.fixedId && !transactionRepeated) && transaction._id || !transaction._id"
                    {{ !transactionRepeated}}
                  >
                    <v-checkbox
                      label="Recorrente"
                      color="primary"
                      v-model="transaction.fixed"
                      :disabled="transaction.repeat"
                    ></v-checkbox>
                  </v-flex>

                  <v-flex
                    xs6
                    sm4
                    class="d-flex align-sm-center justify-sm-center"
                    v-if="(!transaction.fixedId && !transactionRepeated) && !transaction._id"
                  >
                    <v-checkbox
                      label="Repetir"
                      color="primary"
                      v-model="transaction.repeat"
                      :disabled="transaction.fixed"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex
                xs12
                md3
                offset-md2
                v-if="!transactionRepeated && transaction.repeat"
              >
                <span class="input-label primary--text"
                >Número de repetições</span
                >
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model.number="transaction.repeatNumber"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-refresh
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex
                xs6
                md2
                :offset-md4="transaction.fixed"
                v-if="!transaction.fixedId && !transactionRepeated && (transaction.fixed || transaction.repeat)"
              >
                <span class="input-label primary--text">Intervalo</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model.number="transaction.periodsInterval"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-refresh
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex
                xs6
                :md2="transaction.fixed"
                :md3="transaction.repeat"
                v-if="!transaction.fixedId && !transactionRepeated && (transaction.fixed || transaction.repeat)"
              >
                <span class="input-label primary--text">Tipo de intervalo</span>
                <v-select
                  color="grey1"
                  class="mt-0 pt-0"
                  :items="periodsTypes"
                  v-model="transaction.periodsType"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-calendar
                  </v-icon>
                </v-select>
              </v-flex>

              <v-flex
                xs12
                class="d-flex flex-column repetition-desc"
                v-if="transaction._id && transaction.intervalType"
              >
                <span class="grey1--text">
                  {{
                    transaction.fixed
                      ? 'Recorrente'
                      : transaction.repeat
                        ? 'Repetida'
                        : transaction.divided
                          ? 'Parcelada'
                          : ''
                  }}
                </span>
                <span class="grey1--text" v-if="!transaction.divided">
                  {{
                    formatIntervalType(
                      transaction.intervalType,
                      transaction.interval
                    )
                  }}
                </span>
                <span class="grey1--text" v-if="transaction.divided">
                  Parcela {{ transaction.dividedPart }}/{{
                    transaction.dividedParts
                  }}
                </span>
              </v-flex>
            </v-layout>
          </v-container>

          <div class="btn-container">
            <v-btn
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              :disabled="loaderOther"
              class="px-5 darkgrey--text"
            >
              Salvar {{ !transaction._id ? 'e fechar' : '' }}
            </v-btn>

            <v-btn
              rounded
              outlined
              class="px-5"
              type="button"
              color="primary"
              :disabled="loader"
              v-if="!transaction._id"
              :loading="loaderOther"
              @click="submit(true)"
            >
              Salvar e adicionar outro
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { orderBy } from 'lodash';

const ObjectID = require('bson-objectid');
import { VMoney } from 'v-money';
import dayjs from 'dayjs';
import { required } from 'vuelidate/lib/validators';

import removeMoneyMask from '../../../../utils/removeMoneyMask';
import saveTransaction from '../../../../api/transactions/saveTransaction';
import saveCategoryUser from '../../../../api/categoriesUser/saveCategoryUser';

const isZero = (v) => removeMoneyMask(v) > 0;

export default {
  name: 'DialogFormInvestment',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    transactionRepeated: false,
    transaction: {
      obs: '',
      date: '',
      user: '',
      value: 0,
      bankAccount: '',
      fixed: false,
      repeat: false,
      periodsInterval: 1,
      periodsType: 'months',
      consolidated: false,
      repeatNumber: '',
      investment: true
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    dateMenu: false,
    periodsTypes: [
      { text: 'Mensal', value: 'months' },
      { text: 'Semanal', value: 'weeks' },
      { text: 'Anual', value: 'years' }
    ],
    loaderOther: false,
    openedPeriod: null
  }),
  methods: {
    requestOpenedPeriod() {
      this.$bus.$emit('requestOpenedPeriod');
    },
    getOpenedPeriod(openedPeriod) {
      if (openedPeriod) {
        this.openedPeriod = openedPeriod;
      }
    },
    formatIntervalType(intervalType, interval) {
      let str = `Uma vez a cada ${interval} `;

      if (intervalType === 'months') {
        str += interval > 1 ? 'meses' : 'mês';
      } else if (intervalType === 'weeks') {
        str += interval > 1 ? 'semanas' : 'semana';
      } else if (intervalType === 'year') {
        str += interval > 1 ? 'anos' : 'ano';
      }

      return str;
    },
    reset() {
      this.dateMenu = false;

      this.transaction = {
        obs: '',
        date: dayjs().format('YYYY-MM-DD'),
        user: '',
        value: 0,
        bankAccount: '',
        fixed: false,
        repeat: false,
        periodsInterval: 1,
        periodsType: 'months',
        consolidated: false,
        repeatNumber: '',
        investment: true
      };

      this.resetMoneyInput();
      this.$v.$reset();
    },
    close() {
      this.dialog = false;
      this.loader = false;
      this.loaderOther = false;
      this.openedPeriod = null
      this.reset();
    },
    resetMoneyInput() {
      let input = document.getElementById('input-investment-value');

      if (input) {
        input.value = this.transaction.value;
        input.dispatchEvent(new Event('input'));
        input.scrollIntoView();
      }
    },
    async submit(saveOther) {
      try {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = saveOther !== true;
          this.loaderOther = saveOther === true;

          let transaction = JSON.parse(JSON.stringify(this.transaction));

          transaction.type = 'saida';
          transaction.investment = true;
          transaction.user = this.user._id;
          transaction.category = this.categories[0];
          transaction.value = removeMoneyMask(transaction.value);

          if (transaction.fixed || transaction.divided || transaction.repeat) {
            transaction.interval = this.transaction.periodsInterval;
            transaction.intervalType = this.transaction.periodsType;
          }

          if (transaction.category)
            transaction.subCategory = transaction.category.subs[0];

          let category = this.categories[0];
          let subCategory;

          if (category) subCategory = category.subs[0];

          if (!category) {
            category = {
              subs: [],
              update: true,
              color: '#00E396',
              user: this.user._id,
              _id: ObjectID().str,
              name: 'Investimentos',
              type: 'investment',
              icon: 'mdi-chart-line-variant'
            };

            if (subCategory) {
              subCategory = {
                fixed: false,
                _id: ObjectID().str,
                name: 'Investimento',
                category: category._id
              };

              category.subs.push(subCategory);
            }
          } else if (!subCategory) {
            subCategory = {
              fixed: false,
              _id: ObjectID().str,
              name: 'Investimento',
              category: category._id
            };

            category.update = true;
            category.subs.push(subCategory);
          }

          if (!category.user) {
            category.update = true;
            category.user = this.user._id;
          }

          if (category.update) {
            category = await saveCategoryUser(category);
            this.$store.dispatch('saveCategory', category);
          }

          transaction.category = category._id;

          if (transaction.subCategory) {
            transaction.subCategory = subCategory._id;
          }

          if (transaction.bankAccount._id) {
            transaction.bankAccount = transaction.bankAccount._id
          }

          if (transaction._id && transaction.fixedId) {
            this.$bus.$emit('showDialogUpdateMultipleTransaction', {
              transaction,
              openedPeriod: this.openedPeriod
            });
            this.loader = false;
            this.loaderOther = false;
          } else {
            saveTransaction(transaction, null, this.openedPeriod)
              .then((resp) => {
                if (resp.transaction) {
                  if (!resp.transaction.bankAccount._id || resp.transaction.archived === undefined) {
                    let bankAccount = this.bankAccounts.find(ba => {
                      return ba._id === resp.transaction.bankAccount || ba._id === resp.transaction.bankAccount._id;
                    });
                    resp.transaction.bankAccount = {
                      _id: bankAccount._id,
                      name: bankAccount.name,
                      archived: bankAccount.archived
                    };
                  }

                  resp.transaction.category = category
                    ? category
                    : transaction.category;
                  resp.transaction.subCategory = subCategory
                    ? subCategory
                    : transaction.subCategory;

                  this.$store.dispatch('saveTransaction', resp.transaction);
                }

                if (resp.transactions) {
                  resp.transactions.forEach(t => {
                    if (!t.bankAccount._id || t.bankAccount.archived === undefined) {
                      let bankAccount = this.bankAccounts.find(ba => {
                        return ba._id === t.bankAccount || ba._id === t.bankAccount._id;
                      });
                      t.bankAccount = {
                        _id: bankAccount._id,
                        name: bankAccount.name,
                        archived: bankAccount.archived
                      };
                    }

                    t.category = category ? category : t.category;
                    t.subCategory = subCategory ? subCategory : t.subCategory;

                    this.$store.dispatch('saveTransaction', t);
                  });
                }

                if (resp.bankAccount) {
                  this.$store.dispatch(
                    'updateBankAccountBalance',
                    resp.bankAccount
                  );
                }

                if (resp.bankAccounts) {
                  resp.bankAccounts.forEach((ba) => {
                    this.$store.dispatch('updateBankAccountBalance', ba);
                  });
                }

                if (resp.transaction && resp.transaction.consolidated) {
                  if (this.$route.name === 'Orcamento') {
                    this.$bus.$emit('updateOrcamento');
                  } else if (this.$route.name === 'Relatorios') {
                    this.$bus.$emit('updateRelatorio');
                  }
                }

                this.loader = false;
                this.loaderOther = false;
                this.$noty.success('Investimento salvo com sucesso!');

                if (saveOther === true) {
                  this.reset();
                } else {
                  this.close();
                }
              })
              .catch((err) => {
                this.loader = false;
                this.loaderOther = false;
                this.$noty.error(
                  'Desculpe! Não foi possível salvar este investimento. Tente novamente!'
                );

                if (process.env.NODE_ENV !== 'production') {
                  console.error(err);
                }
              });
          }
        }
      } catch (err) {
        this.loader = false;
        this.loaderOther = false;
        this.$noty.error(
          'Desculpe! Não foi possível salvar este investimento. Tente novamente!'
        );

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    bankAccounts() {
      return orderBy(
        this.$store.getters.bankAccounts.filter((ba) => !ba.archived && !ba.pluggy),
        'name'
      );
    },
    formatedDate() {
      if (this.transaction.date)
        return dayjs(this.transaction.date).format('DD/MM/YYYY');

      return '';
    },
    valueErrors() {
      const errors = [];
      if (!this.$v.transaction.value.$dirty) return errors;
      !this.$v.transaction.value.isZero &&
      errors.push('Insira um valor maior do que zero');
      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.transaction.date.$dirty) return errors;
      !this.$v.transaction.date.required && errors.push('Selecione uma data');
      !this.$v.transaction.date.isValid &&
      errors.push('Preencha uma data válida');
      return errors;
    },
    bankAccountErrors() {
      const errors = [];
      if (!this.$v.transaction.bankAccount.$dirty) return errors;
      !this.$v.transaction.bankAccount.required &&
      errors.push('Selecione a conta bancária');
      return errors;
    },
    categories() {
      return this.$store.getters.categories.filter(
        (cat) => cat.type === 'investment'
      );
    }
  },
  validations: {
    transaction: {
      value: { isZero },
      date: {
        required
      },
      bankAccount: { required }
    }
  },
  watch: {
    'transaction.date'(val) {
      if (!this.transaction._id) {
        if (this.$date(val).isSameOrBefore(this.$date())) {
          this.transaction.consolidated = true;
        } else {
          this.transaction.consolidated = false;
        }
      }
    },
    'transaction.fixed'() {
      if (this.transaction.fixed) {
        this.transaction.periodsType = 'months';
        this.transaction.periodsInterval = 1;
      }
    }
  },
  created() {
    this.$bus.$on('showDialogFormInvestment', (transaction) => {
      this.requestOpenedPeriod()

      if (transaction) {
        this.transactionRepeated = transaction.repeat;
        this.transaction = JSON.parse(JSON.stringify(transaction));
        this.transaction.value = this.transaction.value.toFixed(2);
        this.resetMoneyInput();
      } else {
        if (this.bankAccounts.length === 1) {
          this.transaction.bankAccount = this.bankAccounts[0];
        }

        this.transaction.date = dayjs().format('YYYY-MM-DD');
        this.transaction.consolidated = true;
      }

      this.dialog = true;
    });

    this.$bus.$on('closeDialogForm', () => {
      this.close();
    });

    this.$bus.$on('getOpenedPeriod', this.getOpenedPeriod)
  }
};
</script>

<style scoped>
.repetition-desc {
  font-size: 12px !important;
  letter-spacing: 0.1em;
  line-height: 1.5em;
}

.btn-container {
  display: flex;
  flex-direction: column;
}

.btn-container .v-btn {
  margin-bottom: 5px;
}

.v-btn.v-size--default {
  font-size: 11px !important;
}

@media only screen and (min-width: 600px) {
  .btn-container {
    flex-direction: row;
    justify-content: center;
  }

  .btn-container .v-btn {
    margin: 0 5px;
    display: block;
    width: 200px;
  }
}
</style>
