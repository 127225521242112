import moment from 'moment-timezone'

const plotRendaConsumo = (
  taxaProducao,
  taxaConsumo,
  periodos,
  periodosProducao,
  patrimonioAtual,
  aplicacao,
  rendaDesejada
) => {
  taxaProducao = taxaProducao ? parseFloat((taxaProducao / 100).toFixed(4)) : 0
  taxaConsumo = taxaConsumo ? parseFloat((taxaConsumo / 100).toFixed(4)) : 0

  if (!aplicacao) {
    aplicacao = 0
  }

  let valores = [ patrimonioAtual ];

  for (let i = 1; i <= periodos; i++) {
    let taxa = 0;
    let aporte = 0;
    let valor = valores[i-1];

    if (i <= periodosProducao) {
      taxa = taxaProducao;
      aporte = aplicacao;
    } else {
      taxa = taxaConsumo;
      aporte = (rendaDesejada * -1);
    }

    let sd = valor * (1 + taxa);
    let sdf = sd + aporte;

    valores.push(sdf);
  }

  return valores;
}

export default plotRendaConsumo;
