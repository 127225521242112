import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const removeLifeInsuranceDependentsNote = (lifeInsuranceId, noteId) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }

          const url = `/life-insurances/${lifeInsuranceId}/notes/${noteId}`

          return httpClient(url, options)
        })
        .then(() => resolve())
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default removeLifeInsuranceDependentsNote
