<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card v-if="planner">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          class="mr-2"
          color="primary"
          :disabled="loader"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text">Alterar Supervisor de {{ plannerName }}</span>
      </v-card-title>

      <v-card-text class="mt-2">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout column>
              <v-flex xs12 v-if="planner.supervisor">
                <span class="input-label primary--text">Supervisor atual</span>
                <v-text-field
                  dense
                  readonly
                  color="grey1"
                  class="mt-0 pt-0"
                  :value="planner.supervisor.displayName"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Novo supervisor</span>
                <v-select
                  dense
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="supervisor"
                  :error-messages="supervisorErrors"
                  :items="validSupervisors"
                  item-text="displayName"
                  item-value="_id"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions class="px-0">
            <v-btn
              rounded
              outlined
              color="grey1"
              type="button"
              :disabled="loader"
              class="text-capitalize px-5"
              @click.stop="closeDialog"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              class="text-capitalize darkgrey--text px-5"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import getSupervisorsNames from "@/api/users/getSupervisorsNames"
import changePlannerSupervisor from "@/api/users/changePlannerSupervisor"

export default {
  name: 'DialogChangePlannerSupervisor',
  data: () => ({
    dialog: false,
    loader: false,
    planner: null,
    supervisor: null,
    supervisors: []
  }),
  validations: {
    supervisor: { required }
  },
  computed: {
    plannerName() {
      if (this.planner) {
        return this.planner.displayName.split(/\s+/gm)[0]
      }

      return ''
    },
    validSupervisors() {
      if (this.planner) {
        return this.supervisors.filter(sp => {
          return !this.planner.supervisor || sp._id !== this.planner.supervisor._id
        })
      }

      return []
    },
    supervisorErrors() {
      if (!this.$v.supervisor.$anyDirty) {
        return []
      }
      if (this.$v.supervisor.$anyError) {
        return ['Selecione o supervisor']
      }
    }
  },
  methods: {
    async getNames() {
      try {
        this.supervisors = await getSupervisorsNames()
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.supervisors = []
      }
    },
    openDialog(planner) {
      if (planner) {
        this.planner = planner
        this.getNames()
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.planner = null
      this.supervisor = null

      this.$v.$reset()
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          await changePlannerSupervisor(this.planner._id, this.supervisor)

          this.closeDialog()

          this.$bus.$emit('managerGetUsers')
          this.$noty.success('Supervisor alterado com sucesso!')
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }

        this.loader = false
        this.$noty.error('Falha ao alterar Supervisor')
      }
    }
  },
  created() {
    this.$bus.$on('showDialogChangePlannerSupervisor', this.openDialog)
  }
}
</script>
