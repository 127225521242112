export default number => {
  if (number !== undefined && number !== null && !Number.isNaN(number) && Number.isFinite(number)) {
    let currency = number.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });

    currency = currency.slice(3, currency.length);

    if (number < 0)
      currency = '-' + currency;

    return currency;
  }

  return '';
}

