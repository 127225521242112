import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar total em série de determinado evento
 * @param {string} event
 * @param {object} filter {period, type}
 * @param {string} companyId
 */
const countUsersSeries = (event, filter, companyId = '', plannerId = '') =>
  new Promise((resolve, reject) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          let url = `/users/count/series?event=${event}&period=${filter.period}&type=${filter.type}`;
          let headers = { Authorization: `Bearer ${idToken}` };

          if (companyId) url += `&companyId=${companyId}`;
          if (plannerId) url += `&plannerId=${plannerId}`;

          return httpClient(url, { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    }
  });

export default countUsersSeries;
