import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

/**
 * Update invoice dates
 * @param {string} invoiceId
 * @param {Object} data { closeDate, paymentDate }
 * @returns {Promise<Object>}
 */
const updateInvoiceDates = (invoiceId, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            data,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }
          return httpClient(`/invoices/${invoiceId}/dates`, options)
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default updateInvoiceDates
