import firebase from 'firebase/app';

import store from '@/store/index';
import httpClient from '@/plugins/httpClient';

/**
 * Buscar todas as categorias de forma paginada
 * @param page
 * @param limit
 * @param type
 * @param search
 * @returns {Promise<unknown>}
 */
const getAdminCategories = (page = 1, limit = 10, type = '', search = '') => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let user = store.getters.user;
        let headers = {
          role: user.role,
          Authorization: `Bearer ${idToken}`
        };
        let url = `/categories?page=${page}&limit=${limit}&search=${search}&type=${type}`;

        return httpClient(url, { headers });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
};

export default getAdminCategories;
