<template>
  <v-form @submit.prevent="submit">
    <v-container
      grid-list-md
      v-if="usuario"
    >
      <v-layout row wrap>
        <v-flex xs12>
          <span class="primary--text">Nome fantasia</span>
          <v-text-field
            v-model="usuario.displayName"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="displayNameErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6>
          <span class="primary--text">CPF</span>
          <v-text-field
            type="tel"
            v-model="usuario.cpf_cnpj"
            color="grey1"
            class="mt-0 pt-0"
            v-mask="'###.###.###-##'"
            :error-messages="cpfErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6>
          <span class="primary--text">Whatsapp</span>

          <v-layout row wrap>
            <v-flex xs3 class="py-0">
              <v-select
                color="grey1"
                class="mt-0 pt-0"
                v-model="usuario.phoneCountryCode"
                :items="countryPhoneMasks"
                item-value="codigo"
                item-text="sigla"
              >
                <template v-slot:item="{ item, index }">
                  <img
                    :src="item.flag"
                    :alt="item.sigla"
                    style="width: 32px; height: 16px;"
                  >
                </template>

                <template v-slot:selection="{ item, index }">
                  <img
                    :src="item.flag"
                    :alt="item.sigla"
                    style="width: 32px; height: 16px;"
                  >
                </template>
              </v-select>
            </v-flex>

            <v-flex xs9 class="py-0">
              <v-text-field
                type="tel"
                color="grey1"
                class="mt-0 pt-0"
                v-mask="phoneMask"
                v-model="usuario.phoneNumber"
                :error-messages="phoneNumberErrors"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12>
          <h2 class="title">Endereço</h2>
        </v-flex>

        <v-flex xs12 md3>
          <span class="primary--text">CEP</span>
          <v-text-field
            type="tel"
            v-model="usuario.address.cep"
            color="grey1"
            class="mt-0 pt-0"
            v-mask="'#####-###'"
            :error-messages="cepErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md9>
          <span class="primary--text">Logradouro</span>
          <v-text-field
            v-model="usuario.address.logradouro"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="logradourErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md3>
          <span class="primary--text">Número</span>
          <v-text-field
            type="number"
            v-model.number="usuario.address.numero"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="numeroErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md9>
          <span class="primary--text">Complemento</span>
          <v-text-field
            v-model="usuario.address.complemento"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="complementoErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md4>
          <span class="primary--text">Bairro</span>
          <v-text-field
            v-model="usuario.address.bairro"
            color="grey1"
            class="mt-0 pt-0"
            :error-messages="bairroErrors"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md4>
          <span class="primary--text">Cidade</span>
          <v-autocomplete
            v-model="usuario.address.cidade"
            color="grey1"
            class="mt-0 pt-0"
            :items="cidades"
            item-text="nome"
            item-value="nome"
            :error-messages="cidadeErrors"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs12 md4>
          <span class="primary--text">Estado</span>
          <v-autocomplete
            v-model="usuario.address.estado"
            color="grey1"
            class="mt-0 pt-0"
            :items="estados"
            return-object
            item-text="nome"
            :error-messages="estadoErrors"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs12 class="text-right">
          <v-btn
            rounded
            type="submit"
            color="primary"
            :loading="loader"
            class="darkgrey--text"
          >
            Salvar
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { pick } from 'lodash'
import firebase from 'firebase/app'
import * as cpf from "@fnando/cpf"
import { required, maxLength, minValue, maxValue } from 'vuelidate/lib/validators'
import getAddressByCep from '@/api/brasil-api/getAddressByCep';
import getCidadesByEstado from '@/api/address/getCidadesByEstado';
import stringCapitalize from '@/utils/stringCapitalize';
import saveUser from '@/api/users/saveUser';
import countryPhoneMasks from '@/utils/countryPhoneMasks';
import getSelectedCountry from '@/utils/phone/getSelectedCountry';
import getCountryPhoneMasks from '@/utils/phone/getCountryPhoneMasks';

export default {
  name: 'FormCadastroCustomer',
  data: () => ({
    usuario: {
      razaoSocial: '',
      displayName: '',
      cpf_cnpj: '',
      phoneNumber: '',
      phoneCountryCode: '',
      address: {
        cep: '',
        numero: '',
        bairro: '',
        cidade: '',
        logradouro: '',
        complemento: '',
        estado: null
      }
    },
    cidades: [],
    loader: false,
    addressLoader: false,
    countryPhoneMasks
  }),
  validations: {
    usuario: {
      displayName: {
        required,
        max: maxLength(150)
      },
      cpf_cnpj: {
        isValid: v => cpf.isValid(v)
      },
      phoneNumber: {
        isValid: v => !!v && v.replace(/\D+/gm, '').length === 11
      },
      phoneCountryCode: {
        required
      },
      address: {
        cep: {
          isValid: v => !!v && v.replace(/\D+/gm, '').length === 8
        },
        numero: {
          min: minValue(1),
          max: maxValue(100000)
        },
        bairro: {
          required,
          max: maxLength(150)
        },
        cidade: {
          required,
          max: maxLength(150)
        },
        logradouro: {
          required,
          max: maxLength(150)
        },
        complemento: {
          max: maxLength(150)
        },
        estado: { required }
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    perfil() {
      return this.$store.getters.perfil
    },
    selectedCountryCode() {
      return getSelectedCountry(this.usuario.phoneCountryCode)
    },
    phoneMask() {
      return getCountryPhoneMasks(this.selectedCountryCode, this.usuario.phoneNumber)
    },
    estados() {
      return this.$store.getters.estados
    },
    displayNameErrors() {
      if (!this.$v.usuario.displayName.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.displayName.required || !this.$v.usuario.displayName.max) {
        return ['Preencha um nome válido']
      }
    },
    cpfErrors() {
      if (!this.$v.usuario.cpf_cnpj.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.cpf_cnpj.isValid) {
        return ['Preencha um CPF válido']
      }
    },
    phoneNumberErrors() {
      if (!this.$v.usuario.phoneNumber.$anyDirty && !this.$v.usuario.phoneCountryCode.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.phoneNumber.isValid || !this.$v.usuario.phoneCountryCode.required) {
        return ['Preencha um número válido']
      }
    },
    cepErrors() {
      if (!this.$v.usuario.address.cep.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.cep.isValid) {
        return ['Preencha um CEP válido']
      }
    },
    numeroErrors() {
      if (!this.$v.usuario.address.numero.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.numero.min || !this.$v.usuario.address.numero.max) {
        return ['Preencha um número válido']
      }
    },
    bairroErrors() {
      if (!this.$v.usuario.address.bairro.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.bairro.required || !this.$v.usuario.address.bairro.max) {
        return ['Preencha um bairro válido']
      }
    },
    cidadeErrors() {
      if (!this.$v.usuario.address.cidade.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.cidade.required || !this.$v.usuario.address.cidade.max) {
        return ['Selecione uma cidade válida']
      }
    },
    logradourErrors() {
      if (!this.$v.usuario.address.logradouro.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.logradouro.required || !this.$v.usuario.address.logradouro.max) {
        return ['Preencha um logradouro válido']
      }
    },
    complementoErrors() {
      if (!this.$v.usuario.address.complemento.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.complemento.max) {
        return ['Preencha um complemento válido']
      }
    },
    estadoErrors() {
      if (!this.$v.usuario.address.estado.$anyDirty) {
        return []
      }
      if (!this.$v.usuario.address.estado.required) {
        return ['Selecione um estado válido']
      }
    }
  },
  methods: {
    copy() {
      if (this.user) {
        if (!this.perfil || !this.perfil.informacoesBasicas.usuario) {
          this.usuario = pick(this.user, Object.keys(this.usuario))
          this.usuario.cpf_cnpj = cpf.format(this.usuario.cpf_cnpj)
        } else {
          this.usuario.cpf_cnpj = this.perfil.informacoesBasicas.usuario.cpf
          this.usuario.displayName = this.perfil.informacoesBasicas.usuario.nome
          this.usuario.address = this.perfil.informacoesBasicas.usuario.endereco
          this.usuario.phoneNumber = this.perfil.informacoesBasicas.usuario.phoneNumber

          if (this.usuario.phoneNumber.length > 11) {
            this.usuario.phoneNumber = this.perfil.informacoesBasicas.usuario.phoneNumber.slice(2, 13)
          }
        }

        if (!this.usuario.phoneCountryCode) {
          this.usuario.phoneCountryCode = '55'
        }

        if (!this.usuario.address) {
          this.usuario.address = {
            cep: '',
            numero: '',
            bairro: '',
            cidade: '',
            logradouro: '',
            complemento: '',
            estado: null
          }
        }
      }
    },
    getAddress() {
      this.$v.usuario.address.cep.$touch()

      if (!this.$v.usuario.address.cep.$anyError) {
        this.addressLoader = true

        getAddressByCep(this.usuario.address.cep.replace(/\D+/gm, ''))
          .then(address => {
            this.usuario.address.logradouro = address.street
            this.usuario.address.cidade = address.city
            this.usuario.address.bairro = address.neighborhood
            this.usuario.address.estado = this.estados.find(e => e.sigla === address.state.toUpperCase())
            this.addressLoader = false
          })
          .catch(err => {
            console.error(err)
            this.addressLoader = false
          })
      }
    },
    getCidades() {
      if (this.usuario.address.estado) {
        getCidadesByEstado(this.usuario.address.estado.id)
          .then(cidades => {
            this.cidades = cidades
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError) {
          this.loader = true

          const usuario = JSON.parse(JSON.stringify(this.usuario))

          usuario._id = this.user._id
          usuario.cpf_cnpj = usuario.cpf_cnpj.replace(/\D+/gm, '')
          usuario.phoneNumber = usuario.phoneNumber.replace(/\D+/gm, '')
          usuario.displayName = stringCapitalize(usuario.displayName)
          usuario.address.cep = usuario.address.cep.replace(/\D+/gm, '')

          await firebase.auth().currentUser.updateProfile({ displayName: usuario.displayName })

          const user = await saveUser(usuario)

          this.$store.dispatch('setUser', user)
          this.loader = false
          this.$noty.success('Dados atualizados com sucesso!')
        }
      } catch (err) {
        this.loader = false
        this.$noty.error('Desculpe! Não foi possível salvar seus dados. Tente novamente mais tarde!')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      }
    }
  },
  watch: {
    'usuario.address.cep'() {
      this.getAddress()
    },
    'usuario.address.estado'() {
      this.getCidades()
    }
  },
  mounted() {
    this.copy()
  }
}
</script>
