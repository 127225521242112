<template>
  <v-dialog scrollable persistent v-model="dialog" max-width="500px" :fullscreen="isMobile">
    <v-card :class="isMobile ? 'no-radius' : ''">
      <v-progress-linear indeterminate :height="10" color="success" v-if="isMobile && loader"></v-progress-linear>

      <v-card-title>
        <div class="title">
          <v-btn icon v-if="isMobile" class="mr-4" @click.stop.prevent="close">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>Remover Video
        </div>
      </v-card-title>

      <v-card-text>
        <p>Tem certeza que deseja remover este vídeo?</p>
        <v-card-actions class="px-0 mt-5">
          <v-btn
            text
            rounded
            class="px-5"
            color="accent"
            type="buttom"
            :disabled="loader"
            @click.stop="close"
          >Cancelar</v-btn>

          <v-spacer></v-spacer>

          <v-btn
            rounded
            depressed
            class="px-5"
            color="error"
            :loading="loader"
            @click.stop="remove"
          >Remover</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import deleteVideo from '@/api/videos/deleteVideo';

export default {
  name: 'DialogRemoveVideo',
  data: () => ({
    dialog: false,
    loader: false,
    videoId: ''
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.videoId = '';
    },
    remove() {
      this.loader = true;

      deleteVideo(this.videoId)
        .then(() => {
          this.$noty.success('Video removido com sucesso!');
          this.$bus.$emit('getVideos');
          this.close();
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
          this.$noty.error(
            'Falha ao remover video. Tente novamente mais tarde'
          );
        });
    }
  },
  created() {
    this.$bus.$on('showDialogRemoveVideo', videoId => {
      if (videoId) {
        this.videoId = videoId;
        this.dialog = true;
      }
    });
  }
};
</script>

<style scoped>
.v-card.no-radius {
  border-radius: 0 !important;
}

.title {
  display: flex;
  align-items: center;
}
</style>