import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const calculateCompanyPyers = companyId => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        const options = {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }
        const url = `/companies/${companyId}/calculate-payers`

        return httpClient(url, options)
      })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}

export default calculateCompanyPyers
