import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const saveCategoryUser = data => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = data._id ? 'PUT' : 'POST';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/categories-user${data._id ? '/' + data._id : ''}`;

        return httpClient(url, { method, headers, data });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default saveCategoryUser;
