<template>
  <v-card flat color="transparent" class="card-border-bottom" v-if="user">
    <v-card-text class="px-0">
      <v-layout row wrap>
        <v-flex
          xs12
          class="text-center hidden-md-and-up"
          @click="showBankAccountsDialog"
        >
          <div class="balance-number primary--text">
            <span>R$</span> {{ balance }}
          </div>
          <div class="balance-desc primary--text">Saldo em Contas</div>
        </v-flex>

        <v-flex
          xs12
          class="border-bottom balance-name-container hidden-sm-and-down"
        >
          <div>
            <div class="balance-name">
              <span class="primary--text"
              >Olá, {{ user.displayName.split(/\s+/)[0] }}!</span
              >
              <br/>

              <span class="primary--text">
                Seu saldo {{ verifyPeriod }}:
              </span>
            </div>
          </div>

          <div @click.stop="showBankAccountsDialog" class="text-right">
            <span class="balance-number primary--text">
              <span>R$</span> {{ balance }}
            </span>
          </div>
        </v-flex>

        <v-flex xs6 md4 lg4 class="mt-2">
          <v-btn
            rounded
            outlined
            color="success"
            class="btn-revenue"
            @click="goTo('/transactions?type=entrada')"
          >
            <div>
              <v-icon>mdi-arrow-up</v-icon>
              <span> <span class="btn-icon">R$</span> {{ revenue }} </span>
            </div>
          </v-btn>
        </v-flex>

        <v-flex xs6 md4 offset-md4 lg4 offset-lg4 class="mt-2">
          <v-btn
            rounded
            outlined
            color="error"
            class="btn-expense"
            @click="goTo('/transactions?type=saida')"
          >
            <div>
              <v-icon>mdi-arrow-down</v-icon>
              <span> <span class="btn-icon">R$</span> {{ expense }} </span>
            </div>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-divider color="grey" class="mt-5" v-if="isMobile"></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import {sumBy, uniqBy} from 'lodash';
import currency from '../../../../utils/currency';
import totalRevenueFromPeriod from "@/utils/cashflow/totalRevenueFromPeriod";
import totalExpensesFromPeriod from "@/utils/cashflow/totalExpensesFromPeriod";

export default {
  name: 'CardTotal',
  props: ['period'],
  computed: {
    periodNumbers() {
      if (this.period) {
        return this.period.split('-');
      }
      return [];
    },
    currentPeriod() {
      return this.$date().format('YYYY-MM');
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === `customer`;
    },
    user() {
      return (this.isCustomer ? this.$store.getters.user : this.$store.getters.customer);
    },
    revenue() {
      return currency(totalRevenueFromPeriod(this.currentPeriod, this.period))
    },
    expense() {
      return currency(totalExpensesFromPeriod(this.currentPeriod, this.period))
    },
    verifyPeriod() {
      if (this.period > this.currentPeriod) {
        return 'projetado é';
      } else if (this.period === this.currentPeriod) {
        return 'em conta é';
      } else {
        return 'era';
      }
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts;
    },
    activeBankAccounts() {
      const [year, month] = this.periodNumbers

      return this.bankAccounts.filter(ba => {
        if (!ba.archived) {
          const [baYear, baMonth] = ba.initialAmountDate.split('-')
          return year > baYear || (year == baYear && month >= baMonth)
        }
      });
    },
    balance() {
      let total = 0
      const [year, month] = this.periodNumbers

      this.$store.getters.bankAccounts.forEach(bankAccount => {
        if (!bankAccount.archived && bankAccount.initialAmountDate.slice(0, 7) <= this.period) {
          let historyItem = bankAccount.history.find(h => h.year == year && h.month == month);

          if (!historyItem) {
            historyItem = bankAccount.history[bankAccount.history.length - 1]
          }

          if (historyItem) {
            total += historyItem.balance
          }
        }
      })

      total = parseFloat(total.toFixed(2))

      return currency(total)
    }
  },
  methods: {
    showBankAccountsDialog() {
      this.$bus.$emit('showDialogMobileBankAccountsList');
    },
    showDialogFormRevenue() {
      this.$bus.$emit('showDialogFormRevenue');
    },
    goTo(path) {
      if (this.isCustomer) this.$router.push(path);
    },
    calcBalance() {
      let balance = 0;
      let year, month;
      let queryPeriod;

      if (!this.period) {
        year = this.currentYear;
        month = this.currentMonth;
      } else {
        queryPeriod = this.period + '-01';
        [year, month] = this.periodNumbers;

        year = parseInt(year);
        month = parseInt(month);
      }

      let accounts = this.activeBankAccounts.filter(ba => {
        return (
          moment(queryPeriod).isSameOrAfter(ba.initialAmountDate, 'year') &&
          moment(queryPeriod).isSameOrAfter(ba.initialAmountDate, 'month')
        );
      });

      if (year === this.currentYear && month === this.currentMonth) {
        if (accounts.length) {
          balance = accounts.reduce((a, b) => {
            return { balance: a.balance + b.balance };
          }).balance;
        }
      } else if (
        year > this.currentYear ||
        (year == this.currentYear && month > this.currentMonth)
      ) {
        for (let i = 0; i < accounts.length; i++) {
          let bankAccount = accounts[i];

          let historyItem = bankAccount.history.find(
            h => h.year == year && h.month == month
          );
          if (!historyItem)
            historyItem = bankAccount.history[bankAccount.history.length - 1];

          balance += historyItem.balance;
        }
      } else {
        for (let i = 0; i < accounts.length; i++) {
          let bankAccount = accounts[i];

          let historyItem = bankAccount.history.find(
            h => h.year == year && h.month == month
          );

          if (!historyItem) {
            let initial = bankAccount.history.find(h => h.initial);

            if (
              year > initial.year ||
              (year == initial.year && month >= initial.month)
            )
              historyItem = bankAccount.history[bankAccount.history.length - 1];
          }

          if (historyItem) balance += historyItem.balance;
        }
      }

      this.balance = currency(balance);
    }
  }
};
</script>

<style scoped>
.balance-number {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.1em;
  font-family: 'Roboto Condensed', Roboto, san-serif !important;
}

.balance-number span {
  font-size: 18px;
}

.balance-desc {
  font-family: 'Roboto Condensed', Roboto, san-serif !important;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.btn-revenue,
.btn-expense {
  font-size: 10px !important;
  font-family: 'Roboto Condensed', Roboto, san-serif !important;
  letter-spacing: 0.1em;
}

.btn-revenue span.btn-icon,
.btn-expense span.btn-icon {
  font-size: 9px !important;
}

.btn-revenue .v-icon,
.btn-expense .v-icon {
  font-size: 11px !important;
  margin-right: 2px;
}

.btn-expense {
  display: block;
  margin-left: auto;
}

@media only screen and (min-width: 375px) {
  .btn-revenue,
  .btn-expense {
    font-size: 12px !important;
  }

  .btn-revenue .v-icon,
  .btn-expense .v-icon {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 414px) {
  .btn-revenue,
  .btn-expense {
    font-size: 14px !important;
  }

  .btn-revenue .v-icon,
  .btn-expense .v-icon {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 600px) {
  .balance-number {
    font-size: 35px;
  }

  .balance-number span {
    font-size: 20px;
  }

  .balance-desc {
    font-size: 13px;
    font-weight: bold;
  }

  .btn-revenue,
  .btn-expense {
    margin-top: 10px;
    font-size: 24px !important;
  }

  .btn-revenue span.btn-icon,
  .btn-expense span.btn-icon {
    font-size: 13px !important;
  }

  .btn-revenue .v-btn__content > div,
  .btn-expense .v-btn__content > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 960px) {
  .border-bottom {
    border-bottom: solid 1px #d1ac68;
  }

  .balance-name-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .balance-name {
    text-transform: none !important;
    font-size: 12px;
    letter-spacing: 0.1em;
  }

  .balance-number {
    font-size: 25px;
    cursor: pointer;
  }

  .balance-number span {
    font-size: 15px;
  }

  .btn-desc {
    font-size: 15px;
  }
}
</style>
