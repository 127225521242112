import Vue from 'vue';
import money from 'v-money';
import VueMeta from 'vue-meta';
import VueNoty from 'vuejs-noty';
import Vuelidate from 'vuelidate';
import VueMoment from 'vue-moment';
import firebase from 'firebase/app';
import VueCroppie from 'vue-croppie';
import moment from 'moment-timezone';
import VueTheMask from 'vue-the-mask';
import dayjs from 'dayjs'
import VueDragscroll from 'vue-dragscroll'


import App from './App.vue';

import store from './store';
import router from './router';
import config from './config';
import vuetify from './plugins/vuetify';
import eventBus from './plugins/eventBus';

import 'dayjs/locale/pt-br';
import '@babel/polyfill';
import 'croppie/croppie.css';
import './registerServiceWorker';
import 'vuejs-noty/dist/vuejs-noty.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'roboto-fontface/css/roboto-condensed/roboto-condensed-fontface.css';
import VueSocialSharing from 'vue-social-sharing'

require('firebase/auth');
require('firebase/storage');
require('moment/locale/pt-br');

dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/localeData'));
dayjs.extend(require('dayjs/plugin/isSameOrAfter'))
dayjs.extend(require('dayjs/plugin/isSameOrBefore'))

dayjs.locale('pt-br')

Vue.prototype.$date = dayjs;

Vue.config.productionTip = false;

Vue.use(VueSocialSharing)
Vue.use(money);
Vue.use(VueMeta);
Vue.use(eventBus);
Vue.use(Vuelidate);
Vue.use(VueCroppie);
Vue.use(VueTheMask);
Vue.use(VueDragscroll)
Vue.use(VueMoment, { moment });
Vue.use(VueNoty, {
  timeout: 3000,
  theme: 'metroui',
  progressBar: true,
  layout: 'topRight',
});

let app;

firebase.initializeApp(config.firebase);

firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  }
});
