<template>
  <v-container grid-list-md color="transparent">
    <v-layout row wrap>
      <v-flex lg4 md4 sm4 xs4>
        <CardCompanyDetailsChart :user="user" type="planners" />
      </v-flex>

      <v-flex lg4 md4 sm4 xs4>
        <CardCompanyDetailsChart :user="user" type="customers" />
      </v-flex>

      <v-flex lg4 md4 sm4 xs4>
        <CardCompanyDetailsChart :user="user" type="invoice" />
      </v-flex>

      <v-flex lg6 md6 sm12 xs12>
        <CardCompanyActiveUsers :user="user" />
      </v-flex>

      <v-flex lg6 md6 sm12 xs12>
        <CardCompanyGroupedUsersStatus :user="user" />
      </v-flex>

      <v-flex xs12>
        <CardCompanyTableUsers :user="user" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CardCompanyTableUsers from '../cards/CardCompanyTableUsers';
import CardCompanyActiveUsers from '../cards/CardCompanyActiveUsers';
import CardCompanyDetailsChart from '../cards/CardCompanyDetailsChart';
import CardCompanyGroupedUsersStatus from '../cards/CardCompanyGroupedUsersStatus';

export default {
  name: 'TabUsers',
  components: {
    CardCompanyTableUsers,
    CardCompanyActiveUsers,
    CardCompanyDetailsChart,
    CardCompanyGroupedUsersStatus
  },
  props: ['user']
};
</script>
