import store from '@/store/index';
import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const deleteSubCategory = (categoryId, subCategoryId) => {
  console.log(categoryId, subCategoryId)

  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'DELETE';
          let url = `/categories/${categoryId}/subs/${subCategoryId}`;
          let headers = {
            role: store.getters.user.role,
            Authorization: `Bearer ${idToken}`
          };

          return httpClient(url, { method, headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default deleteSubCategory;
