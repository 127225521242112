import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Mudar plano de determinado usuário
 * @param {string} userId
 * @param {object} data
 */
const updatePlan = (userId, data) =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'POST';
        let url = `/plans/users/${userId}/change`;
        let headers = {
          Author: store.getters.user._id,
          Authorization: `Bearer ${idToken}`
        };

        return httpClient(url, { method, headers, data });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });

export default updatePlan;
