<template>
  <v-container grid-list-md color="transparent">
    <v-layout row wrap>
      <v-flex lg4>
        <CardCompanyProfileBasicInfo :user="user" />
      </v-flex>

      <v-flex lg4>
        <CardCompanyProfileContact :user="user" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CardCompanyProfileBasicInfo from '../cards/CardCompanyProfileBasicInfo';
import CardCompanyProfileContact from '../cards/CardCompanyProfileContact';
export default {
  name: 'TabProfile',
  components: { CardCompanyProfileContact, CardCompanyProfileBasicInfo },
  props: ['user']
};
</script>

