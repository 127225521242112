import firebase from 'firebase/app';
import httpClient from '../../plugins/httpClient';

/**
 * Delete customer`s note
 * @param noteId
 * @returns {Promise<unknown>}
 */
const deleteNote = noteId => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'DELETE';
          let url = `/notes/${noteId}`;
          let headers = { Authorization: `Bearer ${idToken}` };

          return httpClient(url, { method, headers });
        })
        .then(() => resolve())
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default deleteNote;
