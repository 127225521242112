<template>
  <div class="carousel-container">
    <v-slide-group
      multiple
      mobile-breakpoint="2560"
    >
      <v-slide-item
        :key="i"
        class="mr-3"
        v-for="(card, i) in cards"
      >
        <v-card :color="!i ? 'primary' : 'grey3'">
          <v-card-title :color="!i ? 'darkgrey' : 'primary'">
            <v-avatar :color="!i ? 'darkgrey' : 'primary'">
              <v-icon :color="!i ? 'primary' : 'darkgrey'">{{ card.icon }}</v-icon>
            </v-avatar>

            <span :class="!i ? 'black--text' : 'primary--text'">{{ card.title }}</span>
          </v-card-title>

          <v-card-text class="pb-0">
            <p :class="i ? 'primary--text' : ''">
              {{ card.description }}
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              rounded
              depressed
              @click="showForm(card.type)"
              :color="!i ? 'darkgrey' : 'primary'"
              :class="!i ? 'primary--text' : 'black--text'"
            >
              {{ card.btn }}
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
  export default {
    name: 'CardCarousel',
    data: () => ({

    }),
    computed: {
      allowed() {
        return this.$store.getters.allowed;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      transactions() {
        return this.$store.getters.transactions;
      },
      bankAccounts() {
        return this.$store.getters.bankAccounts;
      },
      creditCards() {
        return this.$store.getters.creditCards;
      },
      cards() {
        let cards = [];

        if (!this.bankAccounts.length) {
          cards.push({
            icon: 'mdi-bank',
            title: 'Contas bancárias',
            description: 'Cadastre suas contas bancárias e fique atento ao seu saldo!',
            btn: 'Adicionar conta',
            type: 'bank-account'
          });
        }

        if (!this.creditCards.length) {
          cards.push({
            icon: 'mdi-credit-card',
            title: 'Cartões de crédito',
            description: 'Melhore o seu controle financeiro cadastrando os seus cartões. Não deixe pra depois!',
            btn: 'Adicionar cartão',
            type: 'credit-card'
          });
        }

        if (!this.transactions.length) {
          cards.push({
            icon: 'mdi-chart-bar-stacked',
            title: 'Balanço mensal',
            description: 'Você não tem nenhuma transação registrada. Começe com as despesas fixas do mês!',
            btn: 'Adicionar despesa',
            type: 'transaction'
          });
        }

        return cards;
      }
    },
    methods: {
      showForm(type) {
        let event;

        if (type === 'bank-account')
          event = 'showDialogAddAccount';
        else if (type === 'credit-card')
          event = 'showDialogAddAccount';
        else if (type === 'transaction')
          event = 'showDialogFormExpense';

        this.$bus.$emit(event, {type});
      }
    }
  }
</script>

<style scoped>
  .v-card {
    max-width: 209px !important;
  }

  .v-card__title {
    display: flex !important;
    width: 209px !important;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 0 !important;
  }

  .v-card__title span {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .1em;
    margin-top: 10px;
    font-weight: bold;
  }

  .v-card__text p {
    font-size: 10px;
    color: #131313;
    line-height: 1.5em;
    letter-spacing: .1em;
    height: 45px !important;
  }

  .v-card__actions .v-btn {
    display: block;
    width: 145px !important;
    font-size: 10px !important;
  }

  @media only screen and (min-width: 600px) {
    .v-card {
      max-width: 240px !important;
    }

    .v-card__title {
      width: 240px !important;
    }

    .v-card__title span {
      font-size: 15px;
    }

    .v-card__text p {
      font-size: 12px;
    }

    .v-card__actions .v-btn {
      width: 100% !important;
      font-size: 12px !important;
    }
  }

  @media only screen and (min-width: 768px) {
    .v-card {
      width: 298px !important;
    }
  }

  @media only screen and (min-width: 960px) {
    .v-card {
      max-width: 320px !important;
    }

    .v-card__title {
      width: 100% !important;
      flex-direction: row;
      margin-bottom: 20px;
    }

    .v-card__title .v-avatar {
      margin-right: 10px !important;
    }

    .v-card__text p {
      text-align: center;
      font-size: 12px;
    }

    .v-card__actions .v-btn {
      width: 180px !important;
      margin-bottom: 10px;
    }
  }
</style>
