<template>
  <div>
    <v-data-table
      :items="walletInvestments"
      class="elevation-2"
      hide-default-footer
      :headers="tableHeaders"
    >
      <template v-slot:item.total="{ item }">
        <span class="table-item">{{ calcTotal(item) }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu
          left
          bottom
          origin="right top"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              :disabled="!allowed"
              color="grey1"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list nav dense>
            <v-list-item @click="showDialogWalletInvestment(item._id)">Detalhes</v-list-item>
            <v-list-item @click="showDialogFormInvestmentTransaction(item._id, item.initialDate)">Aportar/Resgatar</v-list-item>
            <v-list-item @click="showDialogFormRentabilidade(item._id)">Adicionar rentabilidade</v-list-item>
            <v-list-item @click="showFormWalletInvestment(item)">Editar</v-list-item>
            <v-list-item @click="showDialogRemoveInvestment(item)">Remover</v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <div class="text-center mt-5">
      <v-pagination
        circle
        color="primary"
        v-model="pagination.page"
        :length="pagination.totalPages"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import numberToCurrency from '@/utils/numberToCurrency';

export default {
  name: 'TableWalletInvestments',
  props: ['wallet', 'items'],
  data: () => ({
    tableHeaders: [
      { text: 'Descrição', value: 'description', align: 'left', sortable: true, class: '' },
      { text: 'Categoria', value: 'category', align: 'left', sortable: true, class: '' },
      { text: 'Tipo', value: 'type', align: 'left', sortable: true, class: '' },
      { text: 'Total', value: 'total', align: 'left', sortable: false, class: '' },
      { text: '', value: 'actions', align: 'center', sortable: false, class: '' },
    ],
    pagination: {
      page: 1,
      itemsPerPage: 5,
      totalItems: 0,
      totalPages: 0
    }
  }),
  computed: {
    allowed() {
      return true
    },
    investments() {
      return this.$store.getters.investments;
    },
    walletInvestments() {
      return orderBy(
        this.investments
          .filter(inv => inv.wallet === this.wallet)
          .slice(((this.pagination.page - 1) * this.pagination.itemsPerPage), (this.pagination.page * this.pagination.itemsPerPage)),
      'date'
      );
    }
  },
  methods: {
    calcTotal(item) {
      let total = item.initialValue;

      item.transactions.forEach(t => {
        if (this.$date(t.date).isSameOrAfter(item.initialDate)) {
          total += (t.type === 'in' || t.type === 'var') ? t.value : (t.value * -1);
        }
      });

      return numberToCurrency(total);
    },
    showFormWalletInvestment(investment) {
      if (this.allowed)
        this.$bus.$emit('showDialogFormWalletInvestment', { wallet: this.wallet, investment });
    },
    showDialogRemoveInvestment(item) {
      if (this.allowed) {
        this.$bus.$emit('showDialogRemoveInvestment', {
          _id: item._id,
          description: item.description
        });
      }
    },
    showDialogFormInvestmentTransaction(investmentId, initialDate) {
      if (this.allowed)
        this.$bus.$emit('showDialogFormInvestmentTransaction', { investmentId, initialDate });
    },
    setupPagination() {
      this.pagination.totalItems = this.items.length;
      this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage);
    },
    showDialogWalletInvestment(investmentId) {
      this.$bus.$emit('showDialogWalletInvestment', investmentId);
    },
    showDialogFormRentabilidade(investmentId) {
      if (this.allowed)
        this.$bus.$emit('showDialogFormRentabilidade', { investmentId });
    }
  },
  watch: {
    items: {
      deep: true,
      handler() {
        this.setupPagination();
      }
    }
  },
  created() {
    this.setupPagination();
  }
}
</script>

<style scoped>
.table-item {
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: .1em;
  cursor: pointer;
  padding: 10px 10px 10px 0;
}
</style>
