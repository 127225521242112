<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="400px"
    :fullscreen="isMobile"
    persistent
  >
    <v-card color="grey3" :class="isMobile ? 'no-radius' : ''">
      <v-card-title class="dialog-form-title pt-3 safe-area-top">
        <v-btn
          icon
          color="primary"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="primary--text ml-4">
           Solicitação de Recompensa
        </span>
      </v-card-title>

      <v-form @submit="submit">
        <v-card-text class="pt-5">
          <label>Agência</label>
          <v-text-field
            v-model="data.agency"
            :error-messages="errorData"
            type="text"
            v-mask="'###########'"
            xs12 sm6 md4
            dense
          >
          </v-text-field>
          <label>Conta (com dígito verficador)</label>
          <v-text-field
            v-model="data.account"
            xs12 sm6 md4
            :error-messages="errorData"
            dense
            v-mask="'###################'"
          >
          </v-text-field>
          <label>Banco</label>
          <v-autocomplete
            v-model="data.bankName"
            :items=listBanksAndCodes
            dense
            :error-messages="errorData"
            color="primary"
            xs12 sm6 md4
          ></v-autocomplete>
          <v-flex xs12>
            <label>Chave Pix</label>
            <v-text-field
              dense
              v-model="data.keyPix"
              :error-messages="errorData"
            >
            </v-text-field>
          </v-flex>
        </v-card-text>
        <v-card-actions class="mb-4">
          <v-spacer></v-spacer>
          <v-btn
            dark
            rounded
            class="px-5"
            color="primary"
            type="submit"
            :loading="loader"
          >
            Salvar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import listBanksAndCodes from '@/api/banksAndCodes/listBanksAndCodes';
import createRewardRequest from '@/api/rewardRequests/createRewardRequest';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'DialogRewardRequest',
  data: () => ({
    dialog: false,
    loader: false,
    recommendationItem: '',
    data: {
      agency: '',
      account: '',
      bankName: '',
      keyPix: ''
    }
  }),
  validations: {
    data: {
      agency: {
        required,
        isValid(v) {
          return !!v && v.length <= 150;
        }
      },
      account: {
        required,
        isValid(v) {
          return !!v && v.length <= 150;
        }
      },
      bankName: {
        required,
        isValid(v) {
          return !!v && v.length <= 150;
        }
      },
      keyPix: {
        required,
        isValid(v) {
          return !!v && v.length <= 150;
        }
      }
    }
  },

  methods: {
    reset() {
      this.loader = false,
        this.data = {
          agency: '',
          account: '',
          bankName: '',
          keyPix: ''
        };

      this.$v.$reset();
    },
    close() {
      this.dialog = false;
      this.reset()
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.data.completeName = this.user.displayName;
        this.data.user = this.user._id;
        this.data.recommendationItem = this.recommendationItem._id;
        this.data.valueReward = this.recommendationItem.valueReward;

        this.loader = true;

        createRewardRequest(this.data)
          .then((resp) => {
            this.$bus.$emit('rewardRequest')

            this.$noty.success('Solicitação de reembolso enviada com sucesso! Aguarde pelo reembolso!');
            this.dialog = false;
            this.$store.dispatch('saveRecommendation', resp);

            this.reset();
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }

            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível processar sua solicitação. Tente novamente mais tarde!');
          });
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    listBanksAndCodes() {
      return this.$store.getters.banksAndCodes;
    },
    user() {
      return this.$store.getters.user;
    },
    errorData() {
      const errors = [];
      if (!this.$v.data.keyPix.$dirty) return errors;

      if (!this.$v.data.keyPix.required
        || (!this.$v.data.agency.required
          || !this.$v.data.account.required
          || !this.$v.data.bankName.required)) {
        errors.push('Preencha todos os Campos');
      }
      return errors;
    }
  },
  created() {
    this.$bus.$on('showDialogRewardRequest', async (recommendationItem) => {
      this.dialog = true;
      this.recommendationItem = recommendationItem;

      if (!this.listBanksAndCodes.length) {
        this.loader = true;
        const list = await listBanksAndCodes();
        this.$store.dispatch('setBanksAndCodes', list);
        this.loader = false;
      }
    });
  }
};
</script>
<style scoped>

</style>
