<template></template>

<script>
import createPluggyItem from "@/api/pluggyItems/createPluggyItem";
import saveBankAccount from "@/api/bankAccounts/saveBankAccount";
import saveCreditCard from "@/api/creditCards/saveCreditCard";

export default {
  name: 'PluggyWidgetEventListener',
  computed: {
    user() {
      return this.$store.getters.user
    },
    bankAccounts() {
      return this.$store.getters.bankAccounts
    },
    creditCards() {
      return this.$store.getters.creditCards
    }
  },
  methods: {
    syncPluggItem() {
      this.$bus.$emit('refreshOpenBanking')
      this.$bus.$emit('updateListPendencias')
    },
    async savePluggyItem(data) {
      try {
        const item = data.detail.item
        item.user = this.user._id
        await createPluggyItem(item)
        this.$bus.$emit('refreshOpenBanking')
      } catch (err) {
        console.error(err)
      }
    },
    updateBalances() {
      if (this.bankAccounts.length) {
        const promises = this.bankAccounts.map(ba => {
          return saveBankAccount(ba)
        })

        Promise
          .all(promises)
          .then(results => {
            results.forEach(ba => this.$store.dispatch('saveBankAccount', ba))
          })
      }

      if (this.creditCards.length) {
        const promises = this.creditCards.map(cc => {
          return saveCreditCard(cc)
        })

        Promise
          .all(promises)
          .then(results => {
            results.forEach(cc => this.$store.dispatch('saveCreditCard', cc))
          })
      }
    }
  },
  created() {
    addEventListener('LOGIN_SUCCESS', this.savePluggyItem)
    addEventListener('LOGIN_MFA_SUCCESS', this.savePluggyItem)
    addEventListener('PLUGGY_CONNECTION_COMPLETED', this.syncPluggItem)
    this.$bus.$on('PLUGGY_CONNECTION_REMOVED', this.updateBalances)
  }
}
</script>
