<template>
  <div class="ma-0 pa-0">
    <v-app-bar 
      color="darkgrey" 
      :bottom="true" 
      fixed 
      :style="isIOS ? 'padding-bottom: 30px !important;' : ''"
    >
      <v-btn color="transparent" ripple icon to="/cashflow">
        <v-icon :class="route === 'Cashflow' ? 'primary--text' : 'grey1--text'">
          mdi-home-outline
        </v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn :color="route === 'Transactions' ? 'primary' : 'grey1'" ripple icon to="/transactions">
        <v-icon>
          mdi-format-list-bulleted
        </v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-bottom-sheet
        v-model="fab"
        origin="bottom"
        overlay-color="black"
        :overlay-opacity="0.7"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            top
            dark
            color="primary"
            class="speed-dialg-btn"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>

        <v-sheet class="pa-5" style="margin-bottom: 56px" color="rgba(0,0,0,.2)">
          <v-layout row wrap>
            <v-flex xs4 class="mb-5">
              <div class="d-flex flex-column align-center" @click="showDialogFormInvestment">
                <v-btn
                  fab
                  dark
                  small
                  class="mb-2"
                  color="primary"
                >
                  <v-icon>mdi-chart-line-variant</v-icon>
                </v-btn>
                <small>Investimento</small>
              </div>
            </v-flex>

            <v-flex xs4>
              <div class="d-flex flex-column align-center" @click="showBankAccountsDialog">
                <v-btn
                  fab
                  dark
                  small
                  class="mb-2"
                  color="grey2"
                >
                  <v-icon>mdi-bank</v-icon>
                </v-btn>
                <small>Conta bancária</small>
              </div>
            </v-flex>

            <v-flex xs4 class="mb-5">
              <div class="d-flex flex-column align-center" @click="showDialogBankTransfer">
                <v-btn
                  fab
                  dark
                  small
                  class="mb-2"
                  color="info"
                >
                  <v-icon>mdi-clipboard-flow</v-icon>
                </v-btn>
                <small>Transferência</small>
              </div>
            </v-flex>

            <v-flex xs4>
              <div class="d-flex flex-column align-center" @click="showDialogFormRevenue">
                <v-btn
                  fab
                  dark
                  small
                  class="mb-2"
                  color="success"
                >
                  <v-icon>mdi-cash</v-icon>
                </v-btn>
                <small>Receita</small>
              </div>
            </v-flex>

            <v-flex xs4>
              <div class="d-flex flex-column align-center" @click="showDialogFormExpense">
                <v-btn
                  fab
                  dark
                  small
                  class="mb-2"
                  color="error"
                >
                  <v-icon>mdi-cash</v-icon>
                </v-btn>
                <small>Despesa</small>
              </div>
            </v-flex>

            <v-flex xs4>
              <div class="d-flex flex-column align-center" @click="showDialogFormCreditCardExpense">
                <v-btn
                  fab
                  dark
                  small
                  class="mb-2"
                  color="warning"
                >
                  <v-icon>mdi-credit-card</v-icon>
                </v-btn>
                <small>Despesa Cartão</small>
              </div>
            </v-flex>
          </v-layout>
        </v-sheet>
      </v-bottom-sheet>

      <v-spacer></v-spacer>

      <v-btn :color="route === 'Orcamento' ? 'primary' : 'grey1'" ripple icon to="/orcamento">
        <v-icon>
          mdi-clipboard-text-outline
        </v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn icon color="grey1" @click="drawer = !drawer">
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      dark fixed temporary
      v-model="drawer"
      width="290px"
    >
      <v-list dense nav :class="isIOS ? 'ios-padding' : ''">
        <v-list-item :key="i" v-for="(item, i) in items" :to="item.path">
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="primary--text menu-item">{{ item.name }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item @click="showDialogConfiguracoes">
          <v-list-item-icon>
            <v-icon color="primary">mdi-cog</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="primary--text menu-item">Configurações</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-overlay :value="fab" :z-index="4"></v-overlay>
  </div>
</template>

<script>
import Menu from "../../Global/Menu";
export default {
  name: 'CustomerMobileMenu',
  components: {Menu},
  data: () => ({
    fab: false,
    drawer: false,
    sheet: false,
    items: [
      { name: 'Relatório', icon: 'mdi-chart-arc', path: '/relatorios' },
      { name: 'Independência', icon: 'mdi-beach', path: '/ind-financeira' },
      { name: 'Objetivos', icon: 'mdi-target', path: '/objetivo' },
      { name: 'Investimentos', icon: 'mdi-chart-areaspline', path: '/investimentos' },
      { name: 'Seguro de vida', icon: 'mdi-security', path: '/seguro-vida' },
      { name: 'Planos de Ação', icon: 'mdi-format-list-checks', path: '/planos-acao' },
    ]
  }),
  computed: {
    route() {
      return this.$route.name;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    }
  },
  methods: {
    showDialogFormRevenue() {
      this.fab = false;
      this.$bus.$emit('showDialogFormRevenue');
    },
    showDialogFormExpense() {
      this.fab = false;
      this.$bus.$emit('showDialogFormExpense');
    },
    showDialogBankTransfer() {
      this.fab = false;
      this.$bus.$emit('showDialogBankTransfer');
    },
    showDialogFormInvestment() {
      this.fab = false;
      this.$bus.$emit('showDialogFormInvestment');
    },
    showDialogFormCreditCardExpense() {
      this.fab = false;
      this.$bus.$emit('showDialogFormCreditCardExpense');
    },
    showMenu() {
      this.$bus.$emit('showMenu');
    },
    showDialogConfiguracoes() {
      this.$bus.$emit('showDialogConfiguracoes');
    },
    showBankAccountsDialog() {
      this.$bus.$emit('showDialogMobileBankAccountsList');
    }
  },
  created() {
    this.$bus.$on('showMenu', () => {
      this.drawer = true;
    });
  }
};
</script>

<style scoped>
.v-sheet.v-sheet--tile.theme--dark.v-toolbar.v-toolbar--bottom.v-app-bar.v-app-bar--fixed {
  border-top: 2px solid #919191 !important;
}

.v-dialog.v-dialog--active {
  box-shadow: 0 0 0 0 rgba(0,0,0,0), 0 0 0 0 rgba(0,0,0,0), 0 0 0 0 rgba(0,0,0,0) !important;
}

.v-speed-dial__list {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.speed-dialg-btn {
  margin-bottom: 35px
}

.menu-item {
  font-size: 12px !important;
  font-weight: bold !important;
  letter-spacing: .1em;
  text-transform: uppercase !important;
}

@media screen and (min-width: 600px) {
  .v-toolbar {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .speed-dialg-btn {
    width: 65px !important;
    height: 65px !important;
  }

  .speed-dialg-btn .v-icon {
    font-size: 40px !important;
  }
}
</style>
