import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Change user's status
 * @param {string} userId
 * @param {boolean} keepCustomers
 * @param {boolean} disablePlanners
 */
const changeStatus = (userId, data) => {
  return new Promise((resolve, reject) => {
    try {
      const user = store.getters.user

      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const url = `/users/${userId}/status`
          const options = {
            data,
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${idToken}`,
              author: user ? user._id : null
            }
          }

          return httpClient(url, options)
        })
        .then(() => resolve())
        .catch(reject)
    } catch (err) {
      reject(err)
    }
  })
};

export default changeStatus;
