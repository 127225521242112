<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey2">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn icon :disabled="loader" class="back-button" @click.stop="close">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="white--text ml-4">
          {{ projeto._id ? 'Editar' : 'Adicionar' }} projeto
        </span>
      </v-card-title>

      <v-card-title>
        <v-form style="width: 100%" @submit.prevent="submit">
          <v-container grid-list-md class="px-0">
            <v-layout row wrap>
              <v-flex xs12>
                <h3 class="primary--text input-label">Tipo</h3>
                <v-select
                  dense
                  color="grey1"
                  :items="tipos"
                  item-text="text"
                  item-value="value"
                  v-model="projeto.tipo"
                  :error-messages="tipoErrors"
                  @change="$v.projeto.tipo.$touch()"
                  prepend-inner-icon="mdi-bookmark-outline"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <h3 class="primary--text input-label">Descrição</h3>
                <v-text-field
                  dense
                  type="text"
                  color="grey1"
                  v-model="projeto.descricao"
                  :error-messages="descricaoErrors"
                  @change="$v.projeto.descricao.$touch()"
                  prepend-inner-icon="mdi-file-document-outline"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <h3 class="primary--text input-label">Data</h3>
                <v-dialog
                  ref="dialog"
                  v-model="dateMenu"
                  :return-value.sync="projeto.data"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 pt-0"
                      :value="formatDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        color="grey1"
                        class="input-icon"
                        slot="prepend-inner"
                      >
                        mdi-calendar
                      </v-icon>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    color="primary"
                    v-model="projeto.data"
                    scrollable
                    locale="pt-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey2" @click="dateMenu = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.dialog.save(projeto.data)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12>
                <h3 class="primary--text input-label">Valor</h3>
                <v-text-field
                  dense
                  type="tel"
                  color="grey1"
                  v-money="money"
                  v-model="projeto.valor"
                  id="input-valor-projeto"
                  :error-messages="valorErrors"
                  @change="$v.projeto.valor.$touch()"
                  prepend-inner-icon="mdi-cash"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 v-if="!projeto._id">
                <div class="checkbox-container">
                  <v-checkbox
                    flat
                    color="primary"
                    v-model="projeto.recorrente"
                  ></v-checkbox>
                  <h3
                    class="primary--text input-label"
                    @click="projeto.recorrente = !projeto.recorrente"
                  >
                    É um projeto recorrente?
                  </h3>
                </div>
              </v-flex>

              <v-slide-x-transition>
                <v-flex xs12 v-if="projeto.recorrente && !projeto._id">
                  <v-select
                    dense
                    color="grey1"
                    :items="periodos"
                    item-text="text"
                    item-value="value"
                    prefix="Este é um projeto"
                    v-model="projeto.tipoPeriodo"
                  ></v-select>
                </v-flex>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-flex xs12 v-if="projeto.recorrente && !projeto._id">
                  <v-select
                    dense
                    color="grey1"
                    :items="intervalos"
                    item-text="text"
                    item-value="value"
                    v-model.number="projeto.intervaloPeriodo"
                    :error-messages="intervaloPeriodoErrors"
                    @change="$v.projeto.intervaloPeriodo.$touch()"
                  ></v-select>
                </v-flex>
              </v-slide-x-transition>

              <v-slide-x-transition>
                <v-flex xs12 v-if="projeto.recorrente && !projeto._id">
                  <v-select
                    dense
                    color="grey1"
                    :items="repeticoes"
                    item-text="text"
                    item-value="value"
                    v-model.number="projeto.parcelas"
                    :error-messages="parcelasErrors"
                    @change="$v.projeto.parcelas.$touch()"
                    prefix="Repetir "
                  ></v-select>
                </v-flex>
              </v-slide-x-transition>
            </v-layout>
          </v-container>

          <v-card-actions class="px-0">
            <v-spacer></v-spacer>
            <v-btn
              fab
              small
              type="submit"
              color="primary"
              :loading="loader"
              class="darkgrey--text"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money';
import { capitalize } from 'lodash';
import { required, maxLength, minValue } from 'vuelidate/lib/validators';
import removeMoneyMask from '../../../../utils/removeMoneyMask';
import saveProjetoIndFinanceira from '../../../../api/indFinanceira/saveProjetoInfFinanceira';
import moment from 'moment-timezone';

const isZero = (v) => removeMoneyMask(v) > 0;

export default {
  name: 'DialogFormProjetoVida',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    dateMenu: false,
    projeto: {
      data: '',
      tipo: '',
      prazo: '',
      valor: '',
      parcelas: 2,
      descricao: '',
      recorrente: false,
      intervaloPeriodo: 1,
      tipoPeriodo: 'years',
    },
    tipos: [
      { text: 'Entrada', value: 'entrada' },
      { text: 'Saída', value: 'saída' },
    ],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    },
    periodos: [
      { text: 'anual', value: 'years' },
      { text: 'mensal', value: 'months' },
      { text: 'semanal', value: 'weeks' },
    ],
    prazos: [
      'Curto prazo (1 a 2 anos)',
      'Médio prazo (3 a 4 anos)',
      'Longo prazo (5 anos ou mais)',
    ],
  }),
  computed: {
    formatDate() {
      if (this.projeto.data)
        return moment(this.projeto.data).format('DD/MM/YYYY');

      return '';
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    perfil() {
      return this.$store.getters.perfil;
    },
    indFinanceira() {
      return this.$store.getters.independenciaFinanceira;
    },
    selectedPeriod() {
      switch (this.projeto.tipoPeriodo) {
        case 'years':
          return 'anos';
        case 'months':
          return 'meses';
        case 'weeks':
          return 'semanas';
        default:
          return '';
      }
    },
    intervalos() {
      let intervalos = [];

      for (let i = 1; i <= 10; i++) {
        let type;

        if (i === 1 && this.selectedPeriod === 'meses') type = 'mês';
        else
          type = this.selectedPeriod.slice(0, this.selectedPeriod.length - 1);

        intervalos.push({
          text: `Repetir a cada ${i} ${i > 1 ? this.selectedPeriod : type}`,
          value: i,
        });
      }

      return intervalos;
    },
    valorErrors() {
      const errors = [];
      if (!this.$v.projeto.valor.$dirty) return errors;
      !this.$v.projeto.valor.isZero &&
        errors.push('Insira um valor maior do que zero');
      return errors;
    },
    dataErrors() {
      const errors = [];
      if (!this.$v.projeto.data.$dirty) return errors;
      !this.$v.projeto.data.required &&
        errors.push('Defina uma data para concluir seu projeto');
      !this.$v.projeto.data.isValid &&
        errors.push('Insira um data válida (DD/MM/AAAA)');
      return errors;
    },
    tipoErrors() {
      const errors = [];
      if (!this.$v.projeto.tipo.$dirty) return errors;
      !this.$v.projeto.tipo.required &&
        errors.push('Selecione o tipo do projeto');
      return errors;
    },
    descricaoErrors() {
      const errors = [];
      if (!this.$v.projeto.descricao.$dirty) return errors;
      !this.$v.projeto.descricao.required &&
        errors.push('Descreva seu projeto');
      return errors;
    },
    parcelasErrors() {
      const errors = [];
      if (!this.$v.projeto.parcelas.$dirty) return errors;
      !this.$v.projeto.parcelas.minValue &&
        errors.push('O número de parcelas deve ser maior do que 1');
      !this.$v.projeto.parcelas.required &&
        errors.push('Informe por quanta vezes deseja repetir este projeto');
      return errors;
    },
    intervaloPeriodoErrors() {
      const errors = [];
      if (!this.$v.projeto.intervaloPeriodo.$dirty) return errors;
      !this.$v.projeto.intervaloPeriodo.required &&
        errors.push('Selecione o intervalo de recorrência do projeto');
      return errors;
    },
    repeticoes() {
      let repeticoes = [];

      for (let i = 2; i <= 100; i++) {
        repeticoes.push({ text: `${i} vezes`, value: i });
      }

      return repeticoes;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.projeto = {
        data: '',
        tipo: '',
        prazo: '',
        valor: '',
        parcelas: 2,
        descricao: '',
        recorrente: false,
        intervaloPeriodo: 1,
        tipoPeriodo: 'years',
      };
      this.resetMoneyInput();
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.projeto.recorrente || this.projeto._id) {
        this.$v.projeto.parcelas.$reset();
        this.$v.projeto.tipoPeriodo.$reset();
        this.$v.projeto.intervaloPeriodo.$reset();
      }

      if (
        !this.$v.projeto.valor.$anyError &&
        !this.$v.projeto.data.$anyError &&
        !this.$v.projeto.tipo.$anyError &&
        !this.$v.projeto.tipoPeriodo.$anyError &&
        !this.$v.projeto.parcelas.$anyError &&
        !this.$v.projeto.descricao.$anyError &&
        !this.$v.projeto.intervaloPeriodo.$anyError
      ) {
        this.loader = true;

        let projeto = JSON.parse(JSON.stringify(this.projeto));

        if (!projeto.recorrente) {
          delete projeto.parcelas;
          delete projeto.tipoPeriodo;
          delete projeto.intervaloPeriodo;
        }

        projeto.valor = removeMoneyMask(projeto.valor);
        projeto.descricao = capitalize(projeto.descricao);
        projeto.data = projeto.data.split('/').reverse().join('-');

        saveProjetoIndFinanceira(this.indFinanceira._id, projeto)
          .then((indFinanceira) => {
            this.$store.dispatch('setIndependenciaFinanceira', indFinanceira);
            this.close();
            this.$noty.success('Projeto salvo com sucesso!');
          })
          .catch((err) => {
            this.loader = false;
            this.$noty.error(
              'Desculpe! Não foi possível salvar este projeto. Tente novamente!'
            );
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      }
    },
    resetMoneyInput() {
      let input = document.getElementById('input-valor-projeto');
      if (input) {
        input.value = this.projeto.valor;
        input.dispatchEvent(new Event('input'));
      }
    },
  },
  validations: {
    projeto: {
      valor: { isZero },
      data: {
        required,
      },
      tipo: { required },
      tipoPeriodo: { required },
      parcelas: { required, minValue: minValue(2) },
      descricao: { required, maxLength: maxLength(100) },
      intervaloPeriodo: { required, minValue: minValue(1) },
    },
  },
  created() {
    this.$bus.$on('showDialogProjetoVida', (projeto) => {
      if (projeto) {
        this.projeto = JSON.parse(JSON.stringify(projeto));
        this.projeto.valor = parseFloat(this.projeto.valor).toFixed(2);
        this.resetMoneyInput();
      } else {
        this.projeto.data = moment()
          .tz('America/Sao_Paulo')
          .format('YYYY-MM-DD');
      }
      this.dialog = true;
    });
  },
};
</script>

<style scoped>
.input-label {
  font-size: 9px;
  line-height: 9px;
  letter-spacing: 0.1em;
}

.title-condensed {
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  letter-spacing: 0.1em;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container .input-label {
  font-size: 12px;
  color: white !important;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 788px;
  }
}

@media only screen and (min-width: 1264px) {
  .container {
    max-width: 1020px;
  }
}
</style>
