<template>
  <v-dialog
    v-model="dialog"
    fullscreen
  >
    <v-card color="darkgrey" class="no-radius">
      <v-card-title class="safe-area-top pt-3 darkgrey d-flex justify-space-between">
        <v-btn
          icon
          color="primary"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text dialog-title">Fatura {{ period }}</span>

        <v-btn icon></v-btn>
      </v-card-title>

      <v-card-text class="mt-5" v-if="invoice">
        <v-container grid-list-md class="px-0">
          <v-layout row wrap>
            <v-flex xs12>
              <CardCompanyHistoryInvoice
                :invoice="invoice.invoice"
                :values="invoice.values"
              />
            </v-flex>

            <v-flex xs12>
              <CardCompanyHistorySubscriptions
                :invoice="invoice.invoice"
                :values="invoice.values"
              />
            </v-flex>

            <v-flex xs12 v-if="invoice.employees.length">
              <CardCompanyHistoryEmployees :employees="orderBy(invoice.employees, 'displayName')"/>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {orderBy} from 'lodash'
import CardCompanyHistoryValues from '@/components/Company/cards/CardCompanyHistoryValues';
import CardCompanyHistorySubscriptions from '@/components/Company/cards/CardCompanyHistorySubscriptions';
import CardCompanyHistoryEmployees from '@/components/Company/cards/CardCompanyHistoryEmployees';
import CardCompanyHistoryInvoice from '@/components/Company/cards/CardCompanyHistoryInvoice';
export default {
  name: 'DialogCompanyFutureInvoiceDetails',
  components: { CardCompanyHistoryInvoice, CardCompanyHistoryEmployees, CardCompanyHistorySubscriptions, CardCompanyHistoryValues },
  data: () => ({
    orderBy,
    dialog: false,
    invoice: null,
    period: null
  }),
  methods: {
    openDialog(invoice) {
      if (invoice) {
        this.invoice = invoice
        this.period = this.$date().format('MMMM YYYY')
        this.dialog = true
      }
    },
    reset() {
      this.invoice = null
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.reset()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogCompanyFutureInvoiceDetails', this.openDialog)
  }
}
</script>
