<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card>
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="ml-4">Editar</span>
      </v-card-title>

      <v-card-text>
        <span class="dialog-form-description">
          Você está atualizando uma transação que se repete. O que deseja fazer?<br>
          <span class="error--text">Isso pode demorar um pouco!</span>
        </span>

        <v-card-actions class="px-0 mt-4 dialog-form-btn-container">
          <v-btn
            block
            rounded
            color="primary"
            class="black--text mb-2"
            :loading="loader && !type"
            :disabled="loader && !!type"
            @click.stop="saveTransaction('')"
          >
            Atualizar apenas essa
          </v-btn>

          <v-btn
            block
            rounded
            color="primary"
            class="black--text mb-2"
            :loading="loader && type === 'next'"
            :disabled="loader && type !== 'next'"
            @click.stop="saveTransaction('next')"
          >
            Atualizar essa e as próximas
          </v-btn>

          <v-btn
            block
            rounded
            color="primary"
            class="black--text mb-2"
            :loading="loader && type === 'all'"
            :disabled="loader && type !== 'all'"
            @click.stop="saveTransaction('all')"
          >
            Atualizar todas
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import saveTransfer from "../../../../api/transactions/saveTransfer";
  import saveTransaction from "../../../../api/transactions/saveTransaction";
  import saveCreditCardTransaction from "../../../../api/transactions/saveCreditCardTransaction";

  export default {
    name: 'DialogUpdateMultipleTransaction',
    data: () => ({
      dialog: false,
      loader: false,
      transactions: null,
      openedInvoice: null,
      openedPeriod: null,
      type: ''
    }),
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.type = '';
        this.transaction = null;
        this.openedInvoice = null
        this.openedPeriod = null
      },
      saveTransaction(type) {
        this.type = type;
        this.loader = true;

        this.$bus.$emit('closeDialogForm');

        let request;


        if (this.transaction.isTransfer) {
          request = saveTransfer(this.transaction, this.type, this.openedPeriod);
        } else if (this.transaction.creditCard) {
          request = saveCreditCardTransaction(this.transaction, this.type, this.openedInvoice);
        } else {
          request = saveTransaction(this.transaction, this.type, this.openedPeriod);
        }

        request
          .then(resp => {
            if (resp.bankAccounts) {
              resp.bankAccounts.forEach(ba => {
                this.$store.dispatch('updateBankAccountBalance', ba);
              });
            }

            if (resp.bankAccount) {
              this.$store.dispatch('updateBankAccountBalance', resp.bankAccount);
            }

            if (resp.transaction) {
              if (resp.transaction.bankAccount &&
                (!resp.transaction.bankAccount._id || resp.transaction.archived === undefined)) {
                let bankAccount = this.bankAccounts.find(ba => {
                  return ba._id === resp.transaction.bankAccount || ba._id === resp.transaction.bankAccount._id
                });

                resp.transaction.bankAccount = {
                  _id: bankAccount._id,
                  name: bankAccount.name,
                  archived: bankAccount.archived
                }
              }

              if (resp.transaction.creditCard &&
                (!resp.transaction.creditCard._id || resp.transaction.creditCard.archived === undefined)) {
                let creditCard = this.creditCards.find(cc => {
                  return cc._id === resp.transaction.creditCard || cc._id === resp.transaction.creditCard._id
                });
                resp.transaction.creditCard = {
                  _id: creditCard._id,
                  name: creditCard.name,
                  archived: creditCard.archived
                }
              }

              if (resp.transaction.category && !resp.transaction.category.name) {
                let category = this.categories.find(cat => cat._id === resp.transaction.category);
                let subCategory;

                if (category) {
                  subCategory = category.subs.find(subC => subC._id === resp.transaction.subCategory);
                }

                resp.transaction.category = category;
                resp.transaction.subCategory = subCategory;
              }

              this.$store.dispatch('saveTransaction', resp.transaction);
            }

            if (resp.transactions) {
              let category = this.categories.find(cat => cat._id === resp.transactions[0].category);
              let subCategory;

              if (category) {
                subCategory = category.subs.find(subC => subC._id === resp.transactions[0].subCategory);
              }

              resp.transactions.forEach(t => {
                if (!t.bankAccount._id || t.bankAccount.archived === undefined) {
                  let bankAccount = this.bankAccounts.find(ba => {
                    return ba._id === t.bankAccount || ba._id === t.bankAccount._id
                  });

                  t.bankAccount = {
                    _id: bankAccount._id,
                    name: bankAccount.name,
                    archived: bankAccount.archived
                  }
                }

                t.category = category;
                t.subCategory = subCategory;

                this.$store.dispatch('saveTransaction', t);
              });
            }

            if (resp.invoice) {
              this.$store.dispatch('saveInvoice', resp.invoice);
            }

            if (resp.invoices) {
              resp.invoices.forEach(invoice => {
                this.$store.dispatch('saveInvoice', invoice);
              });
            }

            if (resp.creditCard && resp.creditCard._id) {
              this.$store.dispatch('updateCreditCardLimit', resp.creditCard);
            }

            if (resp.creditCards) {
              resp.creditCards.forEach(cc => {
                this.$store.dispatch('updateCreditCardLimit', cc);
              });
            }

            this.close();
            this.$noty.success('Transações atualizadas com sucesso!')
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }

            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível salvar esta renda. Verifique os dados e tente novamente!');
          });
      }
    },
    computed: {
      bankAccounts() {
        return this.$store.getters.bankAccounts;
      },
      isCustomer() {
        return this.$store.getters.user && this.$store.getters.user.role === `customer`;
      },
      user() {
        return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer;
      },
      categories() {
        return this.$store.getters.categories;
      },
      creditCards() {
        return this.$store.getters.creditCards;
      }
    },
    created() {
      this.$bus.$on('showDialogUpdateMultipleTransaction', data => {
        if (data && data.transaction) {
          this.transaction = data.transaction || data;
          this.openedInvoice = data.openedInvoice
          this.openedPeriod = data.openedPeriod
          this.dialog = true;
        }
      });
    }
  }
</script>
