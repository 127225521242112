import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const saveInvestmentTransaction = (investmentId, data) => {
  return new Promise((resolve, reject) => {
    try {
      if (!investmentId)
        reject({ message: 'investment-id-not-sent' });

      if (!data)
        reject({ message: 'data-not-sent' });

      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'POST';
          let url = `/investments/${investmentId}/transactions`;
          let headers = { Authorization: `Bearer ${idToken}` };

          if (data._id) {
            method = 'PUT';
            url += `/${data._id}`;
          }

          return httpClient(url, { headers, method, data });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default saveInvestmentTransaction;
