<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
  >
    <v-card class="no-radius">
      <v-card-title
        class="dialog-form-title px-2"
        :class="isIOS ? ['ios-padding', 'fixed-title'] : ''"
      >
        <v-btn
          icon
          class="mr-5"
          color="primary"
          @click.stop="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text">Relacionamento</span>
      </v-card-title>

      <v-card-text class="px-2" :class="isMobile ? 'mobile-margin' : ''">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6 md4 v-if="hasConjuge">
                <span class="input-label primary--text">A quanto tempo se conhecem?</span>
                <v-select
                  class="pt-0 mt-0"
                  :items="tempos"
                  v-model="relacionamento.conhecem"
                  :error-messages="conhecemErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md4 v-if="['Casado(a)', 'União estável'].includes(estadoCivil)">
                <span class="input-label primary--text">A quanto tempo são casados?</span>
                <v-select
                  class="pt-0 mt-0"
                  :items="tempos"
                  v-model="relacionamento.casados"
                  :error-messages="casadosErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md4 v-if="estadoCivil === 'Divorciado(a)'">
                <span class="input-label primary--text">A quanto tempo são divorciados?</span>
                <v-select
                  class="pt-0 mt-0"
                  :items="tempos"
                  v-model="relacionamento.divorciados"
                  :error-messages="divorciadosErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md4 v-if="estadoCivil === 'Viúvo(a)'">
                <span class="input-label primary--text">A quanto tempo é viúvo(a)?</span>
                <v-select
                  class="pt-0 mt-0"
                  :items="tempos"
                  v-model="relacionamento.viuvo"
                  :error-messages="viuvoErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md4 v-if="['Casado(a)', 'União estável'].includes(estadoCivil)">
                <span class="input-label primary--text">Qual é o regime de bens?</span>
                <v-select
                  class="pt-0 mt-0"
                  :items="regimes"
                  v-model="relacionamento.regimeBens"
                  :error-messages="regimeBensErrors"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="pa-0 ma-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    rounded
                    color="primary"
                    class="px-5 darkgrey--text"
                    :loading="loader"
                    @click.stop="submit"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'DialogEditRelacionamento',
  data: () => ({
    dialog: false,
    loader: false,
    relacionamento: {
      viuvo: '',
      casados: '',
      regimeBens: '',
      divorciados: '',
      conhecem: ''
    },
    tempos: [
      'Menos de 1 ano',
      'Entre 1 e 5 anos',
      'Entre 5 e 10 anos',
      'Mais de 10 anos'
    ],
    regimes: [
      'Comunhão parcial de bens',
      'Comunhão universal de bens',
      'Separação total de bens',
      'Separação obrigatória de bens',
      'Participação final no aquestos'
    ]
  }),
  validations: {
    relacionamento: {
      viuvo: {
        isValid(v) {
          return this.estadoCivil !== 'Viúvo(a)' || !!v && this.tempos.includes(v);
        }
      },
      casados: {
        isValid(v) {
          return !['Casado(a)', 'União estável'].includes(this.estadoCivil) || !!v && this.tempos.includes(v);
        }
      },
      regimeBens: {
        isValid(v) {
          return !['Casado(a)', 'União estável'].includes(this.estadoCivil) || !!v && this.regimes.includes(v);
        }
      },
      divorciados: {
        isValid(v) {
          return this.estadoCivil !== 'Divorciado(a)' || !!v && this.tempos.includes(v);
        }
      },
      conhecem: {
        isValid(v) {
          return !this.hasConjuge || !!v && this.tempos.includes(v);
        }
      }
    }
  },
  computed: {
    // helpers
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    perfil() {
      return this.$store.getters.perfil;
    },
    hasConjuge() {
      return this.perfil && !!this.perfil.informacoesBasicas.conjuge;
    },
    estadoCivil() {
      if (this.perfil) {
        return this.perfil.informacoesBasicas.usuario.estadoCivil;
      }

      return '';
    },
    // error messages
    viuvoErrors() {
      if (!this.$v.relacionamento.viuvo.$anyDirty) {
        return [];
      }

      if (this.$v.relacionamento.viuvo.$anyError) {
        return ['Selecione um período'];
      }
    },
    casadosErrors() {
      if (!this.$v.relacionamento.casados.$anyDirty) {
        return [];
      }

      if (this.$v.relacionamento.casados.$anyError) {
        return ['Selecione um período'];
      }
    },
    divorciadosErrors() {
      if (!this.$v.relacionamento.divorciados.$anyDirty) {
        return [];
      }

      if (this.$v.relacionamento.divorciados.$anyError) {
        return ['Selecione um período'];
      }
    },
    conhecemErrors() {
      if (!this.$v.relacionamento.conhecem.$anyDirty) {
        return [];
      }

      if (this.$v.relacionamento.conhecem.$anyError) {
        return ['Selecione um período'];
      }
    },
    regimeBensErrors() {
      if (!this.$v.relacionamento.regimeBens.$anyDirty) {
        return [];
      }

      if (this.$v.relacionamento.regimeBens.$anyError) {
        return ['Selecione um regime'];
      }
    }
  },
  methods: {
    openDialog() {
      if (this.perfil && this.perfil.relacionamento) {
        this.relacionamento = JSON.parse(JSON.stringify(this.perfil.relacionamento));
      }

      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.loader = false;
      this.relacionamento = {
        viuvo: '',
        casados: '',
        regimeBens: '',
        divorciados: '',
        conhecem: ''
      };
      this.$v.$reset();
    },
    async submit() {
      try {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          const perfil = await updatePerfil(this.perfil._id, { relacionamento: this.relacionamento });
          await this.$store.dispatch('setPerfil', perfil);

          this.$noty.success('Dados salvos com sucesso!');
          this.closeDialog();
        }
      } catch (err) {
        this.$noty.error('Desculpe, não foi possível salvar seus dados. Tente novamente mais tarde.');

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      } finally {
        this.loader = false;
      }
    }
  },
  created() {
    this.$bus.$on('showDialogEditRelacionamento', this.openDialog);
  }
};
</script>

<style scoped>
.v-card__title.dialog-form-title.fixed-title {
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 10;
}

.v-card__text.mobile-margin {
  padding-top: 90px !important;
}
</style>
