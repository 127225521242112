<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="400px" :fullscreen="isMobile">
    <v-card :class="isMobile ? 'no-radius' : ''" v-if="item">
      <v-progress-linear indeterminate :height="5" color="primary" v-if="isMobile && loader"></v-progress-linear>

      <v-card-title>
        <div class="title">
          <v-btn icon v-if="isMobile" class="mr-4" @click.stop.prevent="closeDialog">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ item._id ? 'Editar' : 'Adionar' }} Alíquota
        </div>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-text-field
            type="tel"
            color="primary"
            v-money="money"
            v-model="item.baseCalculoInicial"
            label="Base de cálculo anual inicial"
            id="base-calculo-inicial"
          ></v-text-field>

          <v-text-field
            type="tel"
            color="primary"
            v-money="money"
            v-model="item.baseCalculoFinal"
            label="Base de cálculo anual final"
            id="base-calculo-final"
          ></v-text-field>

          <v-text-field
            hint="Em %"
            type="number"
            color="primary"
            persistent-hint
            label="Alíquota"
            v-model.number="item.aliquota"
          ></v-text-field>

          <v-text-field
            type="tel"
            color="primary"
            v-money="money"
            label="Parcela a deduzir"
            v-model="item.parcelaDeducao"
            id="parcela-deducao"
          ></v-text-field>

          <v-card-actions class="pa-0">
            <v-btn
              text
              rounded
              small
              class="px-5"
              type="button"
              color="primary"
              :disabled="loader"
              @click.stop="closeDialog"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              rounded
              small
              depressed
              class="px-5 darkgrey--text"
              type="submit"
              color="primary"
              :loading="loader"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money';
import removeMoneyMask from '@/utils/removeMoneyMask';
import saveAliquotaIr from '@/api/config/saveAliquotaIr';

export default {
  name: 'DialogFormConfigAliquotaIrpf',
  directives: {
    money: VMoney
  },
  data: () => ({
    dialog: false,
    loader: false,
    item: {
      aliquota: 0,
      parcelaDeducao: '0.00',
      baseCalculoFinal: '0.00',
      baseCalculoInicial: '0.00'
    },
    money: {
      decimal: ',',
      precision: 2,
      prefix: 'R$ ',
      thousands: '.'
    }
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    config() {
      return this.$store.getters.configIr;
    }
  },
  methods: {
    showDialog(item) {
      if (item) {
        this.item = JSON.parse(JSON.stringify(item));
        this.item.parcelaDeducao = this.item.parcelaDeducao.toFixed(2);
        this.item.baseCalculoFinal = this.item.baseCalculoFinal.toFixed(2);
        this.item.baseCalculoInicial = this.item.baseCalculoInicial.toFixed(2);
        this.resetMoneyInput(
          this.item.baseCalculoInicial,
          this.item.baseCalculoFinal,
          this.item.parcelaDeducao
        );
      }
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.loader = false;
      this.item = {
        aliquota: 0,
        parcelaDeducao: '0.00',
        baseCalculoFinal: '0.00',
        baseCalculoInicial: '0.00'
      };
      this.resetMoneyInput(0, 0, 0);
    },
    resetMoneyInput(value1, value2, value3) {
      let input = document.getElementById('base-calculo-inicial');

      if (input) {
        input.value = value1;
        input.dispatchEvent(new Event('input'));
      }

      input = document.getElementById('base-calculo-final');

      if (input) {
        input.value = value2;
        input.dispatchEvent(new Event('input'));
      }

      input = document.getElementById('parcela-deducao');

      if (input) {
        input.value = value3;
        input.dispatchEvent(new Event('input'));
      }
    },
    submit() {
      this.loader = true;

      let item = JSON.parse(JSON.stringify(this.item));

      item.parcelaDeducao = removeMoneyMask(item.parcelaDeducao);
      item.baseCalculoFinal = removeMoneyMask(item.baseCalculoFinal);
      item.baseCalculoInicial = removeMoneyMask(item.baseCalculoInicial);

      saveAliquotaIr(this.config._id, item)
        .then(item => {
          this.$store.dispatch('saveConfigIrAliquotaIrpf', item);
          this.$noty.success('Item salvo com sucesso!');
          this.closeDialog();
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
          this.$noty.error('Falha ao salvar item! Tente novamente mais tarde');
        });
    }
  },
  created() {
    this.$bus.$on('showDialogFormConfigAliquotaIrpf', item => {
      this.showDialog(item);
    });
  }
};
</script>

<style scoped>
.v-card.no-radius {
  border-radius: 0 !important;
}

.title {
  display: flex;
  align-items: center;
}
</style>
