<template>
  <v-card color="grey3">
    <v-card-text class="text-center">
      <span class="total-desc primary--text text-uppercase">Total alocado</span>
      <span class="total-value"><span>R$</span> {{ currency(totalApplied) }}</span>
    </v-card-text>
  </v-card>
</template>

<script>
import currency from '@/utils/currency';

export default {
  name: 'CardTotalApplied',
  props: ['period'],
  data: () => ({currency}),
  computed: {
    totalApplied() {
      let total = 0;
      const [year, month] = this.period.split('-')

      this.$store.getters.investments
        .filter(inv => {
          let [invYear, invMonth] = inv.initialDate.split('-');
          return invYear < year || (invYear === year && invMonth <= month);
        })
        .forEach(inv => {
          total += inv.initialValue;

          inv.transactions
            .filter(t => {
              return (
                this.$date(t.date).isSameOrAfter(inv.initialDate, 'day') &&
                this.$date(t.date).isSameOrBefore(this.period, 'month')
              )
            })
            .forEach(t => {
              total += ((t.type === 'in' || t.type === 'var') ? t.value : (t.value * -1));
            });
        });

      return total
    },
  }
}
</script>

<style scoped>
.v-card__text {
  display: flex !important;
  flex-direction: column !important;
}

.total-desc {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.total-value {
  font-size: 20px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.total-value span {
  font-size: 12px;
}

@media only screen and (min-width: 600px) {
  .total-desc {
    font-size: 15px;
  }

  .total-value {
    font-size: 24px;
  }

  .total-value span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1264px) {
  .total-desc {
    font-size: 16px;
  }
}
</style>
