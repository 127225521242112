<template>
  <v-card outlined height="100%" class="d-flex flex-column justify-space-between">
    <v-card-text>
      <div class="d-flex flex-column ">
        <span class="pat-item-title primary--text">{{ item.operadora }}</span>
        <span class="pat-item-type">{{ item.tipo }}</span>
      </div>

      <div class="d-flex flex-column mt-2">
        <div class="pat-item-info-desc d-flex justify-space-between">
          <div class="d-flex flex-column">
            <span class="input-label">Valor pago</span>
            <span class="white--text input-label">{{ numberToCurrency(item.valorPago) }}</span>
          </div>

          <div class="d-flex flex-column">
            <span class="input-label">Intervalo</span>
            <span class="white--text input-label">{{ item.repetir }}</span>
          </div>
        </div>

        <div class="pat-item-info-desc d-flex justify-space-between">
          <div class="d-flex flex-column">
            <span class="input-label">Nível de satisfação</span>
            <span class="white--text input-label">{{ item.nivelSatisfacao }}</span>
          </div>

          <div class="d-flex flex-column">
            <span class="input-label">Vencimento</span>
            <span class="white--text input-label">{{ formatedDate }}</span>
          </div>
        </div>

        <div class="pat-item-info-desc d-flex" v-if="item.obs">
          <div class="d-flex flex-column grow">
            <span class="input-label">Observação</span>
            <span class="white--text input-label">{{ item.obs }}</span>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-actions class="pt-0" v-if="actions">
      <v-spacer></v-spacer>

      <v-btn
        icon
        color="error"
        :loading="deleteLoader"
        @click.stop="deleteItem"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>

      <v-btn
        icon
        color="white"
        @click.stop="showForm"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'CardItemProtecaoFamiliar',
  props: ['item', 'actions'],
  data: () => ({
    numberToCurrency,
    deleteLoader: false
  }),
  computed: {
    perfil() {
      return this.$store.getters.perfil
    },
    allowed() {
      return this.$store.getters.allowed
    },
    formatedDate() {
      if (this.item) {
        return this.$date(this.item.dataVencimento).format('DD/MM/YYYY')
      }

      return ''
    }
  },
  methods: {
    async deleteItem() {
      try {
        this.deleteLoader = true

        let perfil = JSON.parse(JSON.stringify(this.perfil))

        const index = perfil.protecaoFamiliar.seguros.findIndex(item => item._id === this.item._id)

        if (index > -1) {
          perfil.protecaoFamiliar.seguros.splice(index, 1)
        }

        perfil = await updatePerfil(perfil._id, {  protecaoFamiliar: perfil.protecaoFamiliar })
        await this.$store.dispatch('setPerfil', perfil)
      } catch (err) {
        this.$noty.error('Desculpe! Não foi possível remover este item. Tente novamente mais tarde!')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.deleteLoader = false
      }
    },
    showForm() {
      this.$bus.$emit('showDialogFormSeguroVidaPerfil', this.item)
    }
  }
}
</script>

<style scoped>
.pat-item-title {
  font-size: 12px !important;
  font-weight: bold;
  letter-spacing: .1em !important;
  text-transform: uppercase;
  line-height: 1em;
}

.pat-item-type {
  font-size: 11px;
  letter-spacing: .1em !important;
}

.pat-item-info-desc {
  margin-top: 5px;
}

.pat-item-info-desc div {
  width: 50%;
}
</style>
