import { render, staticRenderFns } from "./DialogCancelIuguSubscription.vue?vue&type=template&id=7856bc18&"
import script from "./DialogCancelIuguSubscription.vue?vue&type=script&lang=js&"
export * from "./DialogCancelIuguSubscription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports