<template>
  <v-card flat color="grey3" v-if="reposicaoRendaFamiliar">
    <v-card-title class="primary--text seguro-vida main-title">
      Reposição de renda familiar
    </v-card-title>

    <v-card-text>
      <v-form @submit.prevent="submit">
        <v-container class="pa-0">
          <v-layout class="pa-0" row wrap>
            <v-flex xs12 md4>
              <span class="seguro-vida input-label primary--text">Despesas mensais correntes</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                v-money="money"
                v-model="reposicaoRendaFamiliar.despesasMensais"
                :id="'input-insurance-despesas-mensais' + lifeInsurance._id"
                :error-messages="despesasMensaisErrors"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-cash-usd
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12 md4>
              <span class="seguro-vida input-label primary--text">Desconto renda líquida do conjuge</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                v-money="money"
                v-model="reposicaoRendaFamiliar.descontoRendaConjuge"
                :id="'input-insurance-renda-conjuge' + lifeInsurance._id"
                :error-messages="descontoRendaConjugeErrors"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-tag-outline
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12 md4>
              <span class="seguro-vida input-label primary--text">Desconto rendas extras</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                v-money="money"
                v-model="reposicaoRendaFamiliar.descontoRendaExtra"
                :id="'input-insurance-renda-extra' + lifeInsurance._id"
                :error-messages="descontoRendaExtraErrors"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-tag-outline
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12 class="d-flex flex-column align-center justify-center py-5">
              <span class="seguro-vida input-label grey1--text">Reposição de renda necessária</span>
              <span class="seguro-vida total-value white--text">
                <span>R$</span> {{ currency(reposicaoRendaFamiliar.reposicaoNecessaria) }}
              </span>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <span class="seguro-vida input-label primary--text">Prazo de cobertura</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                suffix="anos"
                v-model.number="reposicaoRendaFamiliar.prazoCobertura"
                :error-messages="prazoCoberturaErrors"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-calendar
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md4>
              <span class="seguro-vida input-label primary--text">Investindo recursos a</span>
              <v-text-field
                dense
                type="tel"
                color="grey1"
                suffix="%"
                v-model.number="reposicaoRendaFamiliar.taxaInvestimento"
                :error-messages="taxaInvestimentoErrors"
              >
                <v-icon
                  color="grey1"
                  class="seguro-vida input-icon mr-1"
                  slot="prepend-inner"
                >
                  mdi-chart-line-variant
                </v-icon>
              </v-text-field>
            </v-flex>

            <v-flex xs12 class="d-flex flex-column align-center justify-center py-5">
              <span class="seguro-vida input-label total-label primary--text">Cobertura total necessária</span>
              <span class="seguro-vida total-value total white--text">
                <span>R$</span> {{ currency(reposicaoRendaFamiliar.coberturaTotal) }}
              </span>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            type="submit"
            color="primary"
            class="darkgrey--text seguro-vida px-5"
            :loading="loader"
            :disabled="!allowed"
          >
            Salvar
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'
import currency from '@/utils/currency'
import removeMoneyMask from '@/utils/removeMoneyMask'
import updateLifeInsurace from '@/api/lifeInsurance/updateLifeInsurance'

export default {
  name: 'CardInsuranceReposicaoRendaFamiliar',
  props: ['isSpouse'],
  components: { money: VMoney },
  data: () => ({
    currency,
    loader: false,
    reposicaoRendaFamiliar: {
      despesasMensais: 0,
      descontoRendaConjuge: 0,
      descontoRendaExtra: 0,
      reposicaoNecessaria: 0,
      prazoCobertura: 1,
      taxaInvestimento: 1,
      coberturaTotal: 0
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    }
  }),
  validations: {
    reposicaoRendaFamiliar: {
      despesasMensais: {
        isValid(v) {
          return !!v && removeMoneyMask(v) >= 0;
        }
      },
      descontoRendaConjuge: {
        isValid(v) {
          return !!v && removeMoneyMask(v) >= 0;
        }
      },
      descontoRendaExtra: {
        isValid(v) {
          return !!v && removeMoneyMask(v) >= 0;
        }
      },
      prazoCobertura: {
        isValid(v) {
          return !!v && v >= 0;
        }
      },
      taxaInvestimento: {
        isValid(v) {
          return !!v && v >= 0;
        }
      },
    }
  },
  computed: {
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    },
    despesasMensaisErrors() {
      const errors = [];

      if (!this.$v.reposicaoRendaFamiliar.despesasMensais.$anyDirty) {
        return errors;
      }

      !this.$v.reposicaoRendaFamiliar.despesasMensais.isValid && errors.push('Preencha um valor válido');

      return errors;
    },
    descontoRendaConjugeErrors() {
      const errors = [];

      if (!this.$v.reposicaoRendaFamiliar.descontoRendaConjuge.$anyDirty) {
        return errors;
      }

      !this.$v.reposicaoRendaFamiliar.descontoRendaConjuge.isValid && errors.push('Preencha um valor válido');

      return errors;
    },
    descontoRendaExtraErrors() {
      const errors = [];

      if (!this.$v.reposicaoRendaFamiliar.descontoRendaExtra.$anyDirty) {
        return errors;
      }

      !this.$v.reposicaoRendaFamiliar.descontoRendaExtra.isValid && errors.push('Preencha um valor válido');

      return errors;
    },
    prazoCoberturaErrors() {
      const errors = [];

      if (!this.$v.reposicaoRendaFamiliar.prazoCobertura.$anyDirty) {
        return errors;
      }

      !this.$v.reposicaoRendaFamiliar.prazoCobertura.isValid && errors.push('Preencha um prazo válido');

      return errors;
    },
    taxaInvestimentoErrors() {
      const errors = [];

      if (!this.$v.reposicaoRendaFamiliar.taxaInvestimento.$anyDirty) {
        return errors;
      }

      !this.$v.reposicaoRendaFamiliar.taxaInvestimento.isValid && errors.push('Preencha uma taxa válida');

      return errors;
    },
    allowed() {
      return this.$store.getters.allowed
    }
  },
  methods: {
    copyData() {
      this.reposicaoRendaFamiliar = {
        despesasMensais: this.lifeInsurance.reposicaoRendaFamiliar.despesasMensais.toFixed(2),
        descontoRendaConjuge: this.lifeInsurance.reposicaoRendaFamiliar.descontoRendaConjuge.toFixed(2),
        descontoRendaExtra: this.lifeInsurance.reposicaoRendaFamiliar.descontoRendaExtra.toFixed(2),
        reposicaoNecessaria: this.lifeInsurance.reposicaoRendaFamiliar.reposicaoNecessaria,
        prazoCobertura: this.lifeInsurance.reposicaoRendaFamiliar.prazoCobertura,
        taxaInvestimento: this.lifeInsurance.reposicaoRendaFamiliar.taxaInvestimento,
        coberturaTotal: this.lifeInsurance.reposicaoRendaFamiliar.coberturaTotal,
      }
      this.resetMoneyInputs();
    },
    resetMoneyInputs() {
      let input = document.getElementById('input-insurance-despesas-mensais' + this.lifeInsurance._id)
      if (input) {
        input.value = this.reposicaoRendaFamiliar.despesasMensais
        input.dispatchEvent(new Event('input'))
      }

      input = document.getElementById('input-insurance-renda-conjuge' + this.lifeInsurance._id)
      if (input) {
        input.value = this.reposicaoRendaFamiliar.descontoRendaConjuge
        input.dispatchEvent(new Event('input'))
      }

      input = document.getElementById('input-insurance-renda-extra' + this.lifeInsurance._id)
      if (input) {
        input.value = this.reposicaoRendaFamiliar.descontoRendaExtra
        input.dispatchEvent(new Event('input'))
      }
    },
    submit() {
      if (this.allowed) {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          this.loader = true;

          let reposicaoRendaFamiliar = JSON.parse(JSON.stringify(this.reposicaoRendaFamiliar));

          reposicaoRendaFamiliar.despesasMensais = removeMoneyMask(reposicaoRendaFamiliar.despesasMensais);
          reposicaoRendaFamiliar.descontoRendaConjuge = removeMoneyMask(reposicaoRendaFamiliar.descontoRendaConjuge);
          reposicaoRendaFamiliar.descontoRendaExtra = removeMoneyMask(reposicaoRendaFamiliar.descontoRendaExtra);

          updateLifeInsurace(this.lifeInsurance._id, { reposicaoRendaFamiliar })
            .then(lifeInsurance => {
              this.loader = false;
              this.$store.dispatch('saveLifeInsurance', lifeInsurance);
              this.copyData();
              this.$noty.success('Dados salvos com sucesso!');
            })
            .catch(err => {
              this.loader = false;
              this.$noty.error('Desculpe! Não foi possível salvar estes dados. Tente novamente!');

              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      }
    }
  },
  watch: {
    lifeInsurance: {
      deep: true,
      handler() {
        this.copyData();
      }
    }
  },
  created() {
    this.copyData()
  }
}
</script>

<style scoped>
.seguro-vida.input-label.total-label {
  font-size: 15px;
  line-height: 1.5em;
}

.seguro-vida.total-value.total {
  font-size: 24px;
  line-height: 1.5em;
}
</style>
