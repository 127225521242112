<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card color="grey3">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          color="error"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="error--text ml-4">Remover Aporte/Resgate</span>
      </v-card-title>

      <v-card-text>
        <p class="desc mt-5">
          Tem certeza que deseja remover este item?
        </p>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            fab
            dark
            small
            color="error"
            @click="submit"
            :loading="loader"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import deleteInvestmentTransaction from '@/api/investimentos/deleteInvestmentTransaction';

export default {
  name: 'DialogRemoveInvestmentTransaction',
  data: () => ({
    dialog: false,
    loader: false,
    investmentId: null,
    transactionId: null
  }),
  computed: {
    investments() {
      return this.$store.getters.investments;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.investmentId = null;
      this.transactionId = null;
    },
    submit() {
      this.loader = true;

      deleteInvestmentTransaction(this.investmentId, this.transactionId)
        .then(() => {
          this.$store.dispatch('deleteInvestmentTransaction', {
            investmentId: this.investmentId,
            transactionId: this.transactionId
          });

          this.close();

          this.$noty.success('Dados removidos com sucesso!')
        })
        .catch(err => {
          this.loader = false;
          this.$noty.error('Desculpe! Não conseguimos remover este item. Tente novamente mais tarde');

          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    }
  },
  created() {
    this.$bus.$on('showDialogRemoveInvestmentTransaction', data => {
      if (data.investmentId && data.transactionId) {
        this.investmentId = data.investmentId;
        this.transactionId = data.transactionId;
        this.dialog = true;
      }
    })
  }
}
</script>
