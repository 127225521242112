<template>
  <v-container grid-list-md>
    <v-layout row wrap class="py-5">
      <v-flex
        xs12 sm6 md4 lg3
        class="mb-2"
        :key="cat._id"
        @click="showDialogFormCategory(cat)"
        v-for="cat in filteredCategories"
      >
        <div class="categories-list-item">
          <v-avatar :color="cat.color">
            <v-icon color="white">{{ cat.icon }}</v-icon>
          </v-avatar>

          <div class="categories-list-item-content">
            <span class="categories-list-item-content__name">
              {{ cat.name }} <span v-if="type === 'archived'"> - {{ cat.type }}</span>
            </span>
            <span class="categories-list-item-content__sub grey1--text">
              {{ cat.subs.length }} sub-categories
            </span>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { orderBy } from 'lodash'

  export default {
    name: 'ListCategories',
    props: ['type'],
    computed: {
      allowed() {
        return this.$store.getters.allowed;
      },
      categories() {
        return this.$store.getters.categories;
      },
      filteredCategories() {
        return orderBy(this.categories.filter(cat => (this.type === 'archived' && cat.archived) || (!cat.archived && cat.type === this.type)), 'name');
      }
    },
    methods: {
      showDialogFormCategory(category) {
        this.$bus.$emit('showDialogFormCategory', category);
      }
    }
  }
</script>

<style scoped>
  .categories-list-item {
    display: flex;
    align-items: center;
  }

  .categories-list-item .v-avatar {
    margin-right: 20px;
  }

  .categories-list-item-content {
    display: flex;
    flex-direction: column;
  }

  .categories-list-item-content__name {
    font-size: 12px !important;
    letter-spacing: .1em;
    line-height: 1.5em;
    color: white;
  }

  .categories-list-item-content__sub {
    font-size: 10px !important;
    letter-spacing: .1em;
    line-height: 1.5em;
  }

  @media only screen and (min-width: 600px) {
    .categories-list-item {
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      transition: all .5s ease;
    }

    .categories-list-item:hover {
      background-color: #404040;
    }

    .categories-list-item-content__name {
      font-size: 15px !important;
    }

    .categories-list-item-content__sub {
      font-size: 12px !important;
    }
  }

  @media only screen and (min-width: 960px) {
    .categories-list-item-content__name {
      font-size: 18px !important;
    }

    .categories-list-item-content__sub {
      font-size: 15px !important;
    }
  }
</style>
