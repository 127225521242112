<template>
  <v-container grid-list-md class="pt-0">
    <OverlaySync :overlay="loader" text=""/>

    <v-layout row wrap v-if="lifeInsurance">
      <v-flex xs12>
        <CardInsuranceGeneralInfos :isSpouse="isSpouse"/>
      </v-flex>

      <v-flex xs12>
        <CardInsuranceReposicaoRendaFamiliar :isSpouse="isSpouse"/>
      </v-flex>

      <v-flex xs12>
        <CardInsuranceDespesasFuneral :isSpouse="isSpouse"/>
      </v-flex>

      <v-flex xs12>
        <CardInsuranceDependentes :isSpouse="isSpouse"/>
      </v-flex>

      <v-flex xs12>
        <CardInsuranceInventario :isSpouse="isSpouse"/>
      </v-flex>

      <v-flex xs12>
        <CardInsuranceCapitalExistente :isSpouse="isSpouse"/>
      </v-flex>

      <v-flex xs12>
        <CardCoberturaTotal :isSpouse="isSpouse"/>
      </v-flex>

      <v-flex xs12>
        <CardOutrasCoberturas :is-spouse="isSpouse"/>
      </v-flex>

      <v-flex xs12>
        <CardInsuranceQuestionario :is-spouse="isSpouse"/>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import getLifeInsurance from '@/api/lifeInsurance/getLifeInsurance';
import OverlaySync from '@/components/Global/OverlaySync';
import CardInsuranceGeneralInfos from '@/components/Customer/LifeInsurance/cards/CardInsuranceGeneralInfos';
import CardInsuranceReposicaoRendaFamiliar
  from '@/components/Customer/LifeInsurance/cards/CardInsuranceReposicaoRendaFamiliar';
import CardInsuranceDespesasFuneral from '@/components/Customer/LifeInsurance/cards/CardInsuranceDespesasFuneral';
import CardInsuranceDependentes from '@/components/Customer/LifeInsurance/cards/CardInsuranceDependentes';
import CardInsuranceInventario from '@/components/Customer/LifeInsurance/cards/CardInsuranceInventario';
import CardInsuranceCapitalExistente from '@/components/Customer/LifeInsurance/cards/CardInsuranceCapitalExistente';
import CardCoberturaTotal from '@/components/Customer/LifeInsurance/cards/CardCoberturaTotal';
import CardOutrasCoberturas from '@/components/Customer/LifeInsurance/cards/CardOutrasCoberturas';
import CardInsuranceQuestionario from '@/components/Customer/LifeInsurance/cards/CardInsuranceQuestionario';

export default {
  name: 'TabItemLifeInsurance',
  components: {
    CardInsuranceQuestionario,
    CardOutrasCoberturas,
    CardCoberturaTotal,
    CardInsuranceCapitalExistente,
    CardInsuranceInventario,
    CardInsuranceDependentes,
    CardInsuranceDespesasFuneral,
    CardInsuranceReposicaoRendaFamiliar,
    CardInsuranceGeneralInfos,
    OverlaySync
  },
  props: ['isSpouse'],
  data: () => ({
    loader: false
  }),
  computed: {
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === 'customer';
    },
    user() {
      return !this.isCustomer ? this.$store.getters.customer : this.$store.getters.user;
    },
    lifeInsurance() {
      if (this.isSpouse) {
        return this.$store.getters.spouseLifeInsurance;
      } else {
        return this.$store.getters.userLifeInsurance;
      }
    }
  },
  methods: {
    getLifeInsurance() {
      if (this.user) {
        this.loader = true;
        getLifeInsurance(this.user._id, this.isSpouse)
          .then(lifeInsurance => {
            this.$store.dispatch('saveLifeInsurance', lifeInsurance)
            this.loader = false;
          })
          .catch(err => {
            this.$router.push('/');
            this.loader = false;

            if (process.env.NODE_ENV !== 'production') {
              console.error(err);
            }
          });
      }
    }
  },
  created() {
    this.getLifeInsurance();
  }
}
</script>
