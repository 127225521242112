import { render, staticRenderFns } from "./FormProtecaoFamiliar.vue?vue&type=template&id=3696da28&scoped=true&"
import script from "./FormProtecaoFamiliar.vue?vue&type=script&lang=js&"
export * from "./FormProtecaoFamiliar.vue?vue&type=script&lang=js&"
import style0 from "./FormProtecaoFamiliar.vue?vue&type=style&index=0&id=3696da28&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3696da28",
  null
  
)

export default component.exports