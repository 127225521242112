<template>
  <div class="pt-5">
    <v-data-table
      dense
      :items="users"
      :loading="loader"
      hide-default-footer
      :headers="tableHeaders"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          x-small
          rounded
          depressed
          color="primary"
          class="darkgrey--text"
          @click="unlock(item._id)"
        >
          Desbloquear
        </v-btn>
      </template>
    </v-data-table>

    <div class="text-center mt-5">
      <v-pagination
        circle
        color="darkgrey"
        v-model="pagination.page"
        :length="pagination.totalPages"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import getUsersWithoutPlanner from '@/api/users/getUsersWithoutPlanner'

export default {
  name: 'TableLockedCustomers',
  data: () => ({
    search: '',
    users: [],
    loader: false,
    tableHeaders: [
      { text: 'Nome', value: 'displayName', align: 'left', class: '' },
      { text: 'E-mail', value: 'email', align: 'left', class: '' },
      { text: 'Celular', value: 'phoneNumber', align: 'left', class: '' },
      { text: '', value: 'actions', align: 'center', class: '' }
    ],
    pagination: {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      itemsPerPage: 10
    }
  }),
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    getUsers(newSearch) {
      this.loader = true

      if (newSearch === true && this.pagination.page !== 1) {
        this.pagination.page = 1
      } else {
        getUsersWithoutPlanner(this.pagination.page, this.pagination.itemsPerPage, this.search)
          .then(resp => {
            this.pagination.totalItems = resp.count
            this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
            this.users = resp.users
            this.loader = false
          })
          .catch(err => {
            this.loader = false
            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    },
    unlock(userId) {
      this.$bus.$emit('showDialogUnlockCustomer', {
        user: userId,
        planner: this.user._id,
        company: this.user.company
      })
    }
  },
  watch: {
    'pagination.page'() {
      this.getUsers()
    }
  },
  mounted() {
    this.getUsers()

    this.$bus.$on('getUsersWithoutPlanner', search => {
      this.search = search ? search : ''
      this.getUsers(true)
    })
  }
}
</script>
