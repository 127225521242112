<template>
  <v-container grid-list-md class="px-0" v-if="creditCard && invoice">
    <v-layout row wrap>
      <v-flex xs12 class="hidden-md-and-up">
        <div class="d-flex align-center justify-space-between">
          <v-avatar class="mr-4" size="30" color="darkgrey">
            <v-icon size="20" :color="creditCard.color">
              mdi-credit-card-outline
            </v-icon>
          </v-avatar>

          <div class="d-flex flex-column align-center justify-center">
            <span class="card-name-text">{{ creditCard.name.slice(0, 25) }}</span>
            <span class="card-name-bank grey1--text">{{ formatBankName(creditCard.bank) }}</span>
          </div>

          <img :src="getImgUrl(creditCard.flag)" class="card-flag ml-4" />
        </div>
      </v-flex>

      <v-flex md6 class="hidden-sm-and-down mt-3">
        <v-card color="grey3" height="100%" class="card-cc-info">
          <v-card-title class="card-header d-flex align-center justify-space-between">
            <v-avatar size="40" color="darkgrey" class="mr-2">
              <v-icon :color="creditCard.color" size="27">mdi-credit-card</v-icon>
            </v-avatar>

            <div class="card-name d-flex flex-column align-center">
              <span class="card-name-text">
                {{ creditCard.name.slice(0, 25) }}
                {{ creditCard.archived ? '(Arquivado)' : '' }}
              </span>
              <span class="card-name-bank">{{ formatBankName(creditCard.bank) }}</span>
            </div>

            <img :src="getImgUrl(creditCard.flag)" class="card-flag" />
          </v-card-title>

          <v-card-text>
            <div class="card-limit d-flex align-center justify-space-between">
              <div class="card-limit-info d-flex flex-column">
                <span class="card-limit-text">Disponível</span>
                <span class="card-limit-value">
                  <span>R$</span> {{ currency(creditCard.availableLimit) }}
                </span>
              </div>

              <div class="card-limit-info d-flex flex-column">
                <span class="card-limit-text">Limite</span>
                <span class="card-limit-value">
                  <span>R$</span> {{ currency(creditCard.limit) }}
                </span>
              </div>
            </div>

            <v-progress-linear
              rounded
              reactive
              height="30"
              :buffer-value="100"
              class="mt-1"
              color="primary"
              :value="(creditCard.usedLimit * 100) / creditCard.limit"
            >
              <template v-slot="{ value }">
                <strong>
                  {{ creditCardPercentLimit }}%
                </strong>
              </template>
            </v-progress-linear>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex md6 class="hidden-sm-and-down mt-3">
        <v-card color="grey3" height="100%" class="card-cc-info">
          <v-card-text class="pt-5">
            <v-hover v-slot:default="{ hover }">
              <div class="mb-2" @click="showDialogFormInvoiceDates">
                <span class="invoice-date">
                  <v-icon
                    size="14"
                    class="mr-2"
                    :color="hover ? 'primary' : 'grey1'"
                  >
                    mdi-calendar
                  </v-icon>

                  <span :class="hover ? 'primary--text' : 'grey1--text'">
                    {{ formatInvoiceDate(invoice.closeDate) }}</span>

                    <v-icon
                      size="14"
                      class="ml-auto"
                      :color="hover ? 'primary' : 'grey1'"
                    >
                      mdi-chevron-down
                    </v-icon>
                </span>
                <small class="invoice-date-desc">Data de fechamento</small>
              </div>
            </v-hover>

            <v-hover v-slot:default="{ hover }">
              <div class="mb-2" @click="showDialogFormInvoiceDates">
                <span class="invoice-date">
                  <v-icon
                    size="14"
                    class="mr-2"
                    :color="hover ? 'primary' : 'grey1'"
                  >
                    mdi-calendar
                  </v-icon>

                  <span :class="hover ? 'primary--text' : 'grey1--text'">
                    {{ formatInvoiceDate(invoice.paymentDate) }}
                  </span>

                  <v-icon
                    size="14"
                    class="ml-auto"
                    :color="hover ? 'primary' : 'grey1'"
                  >
                    mdi-chevron-down
                  </v-icon>
                </span>
                <small class="invoice-date-desc">Data de vencimento</small>
              </div>
            </v-hover>

            <div class="invoice-value">
              <span class="invoice-value-desc">Valor {{ creditCard.pluggy ? 'estimado' : '' }} da fatura</span>
              <span class="invoice-value-number">
                <span>R$</span> {{ currency(invoice.value) }}
              </span>
            </div>
          </v-card-text>

          <span class="badge" :class="invoiceStatus.bgColor">
            {{ invoiceStatus.text }}
          </span>
        </v-card>
      </v-flex>

      <v-flex
        xs6
        class="hidden-md-and-up mt-2"
        @click="showDialogFormInvoiceDates"
      >
        <span class="invoice-date">
          <v-icon color="grey1" size="14" class="mr-2"> mdi-calendar </v-icon>

          <span>{{ formatInvoiceDate(invoice.closeDate) }}</span>

          <v-icon color="grey1" size="14" class="ml-auto">
            mdi-chevron-down
          </v-icon>
        </span>
        <small class="invoice-date-desc">Data de fechamento</small>
      </v-flex>

      <v-flex
        xs6
        class="hidden-md-and-up mt-2"
        @click="showDialogFormInvoiceDates"
      >
        <span class="invoice-date">
          <v-icon color="grey1" size="14" class="mr-2"> mdi-calendar </v-icon>

          <span>{{ formatInvoiceDate(invoice.paymentDate) }}</span>

          <v-icon color="grey1" size="14" class="ml-auto">
            mdi-chevron-down
          </v-icon>
        </span>
        <small class="invoice-date-desc">Data de vencimento</small>
      </v-flex>

      <v-flex xs12 class="text-center invoice-value hidden-md-and-up">
        <span class="invoice-value-desc">Valor {{ creditCard.pluggy ? 'estimado' : '' }} da fatura</span>
        <span class="invoice-value-number mt-2">
          <span>R$</span> {{ currency(invoice.value) }}
        </span>
      </v-flex>

      <v-flex xs12 class="d-flex justify-center mt-2">
        <v-btn
          small
          rounded
          depressed
          color="primary"
          @click="invoicePayment"
          :loading="paymentLoader"
          v-if="transactions"
          :disabled="!allowed || creditCard.archived"
          class="black--text px-4 invoice-payment-btn mx-1"
        >
          {{ invoice.paid ? 'Cancelar pagamento' : 'Pagar' }}
        </v-btn>

        <v-slide-x-transition>
          <v-btn
            small
            rounded
            outlined
            depressed
            color="primary"
            v-if="!invoice.paid && !creditCard.pluggy"
            class="px-4 invoice-payment-btn mx-1"
            @click="showDialogFormInvoiceRefund(null)"
            :disabled="paymentLoader || !allowed || creditCard.archived"
          >
            Reembolso
          </v-btn>
        </v-slide-x-transition>
      </v-flex>

      <v-flex xs12 class="hidden-md-and-up my-5">
        <v-divider></v-divider>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import currency from '@/utils/currency';
import stringCapitalize from '@/utils/stringCapitalize';

export default {
  name: 'InvoiceHeader',
  props: ['creditCard', 'invoiceId', 'paymentLoader', 'transactions', 'currentDate'],
  data: () => ({
    currency
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed
    },
    invoice() {
      return this.$store.getters.invoices.find(inv => inv._id === this.invoiceId)
    },
    creditCardPercentLimit() {
      if (this.creditCard) {
        return Math.ceil((this.creditCard.usedLimit * 100) / this.creditCard.limit)
      }

      return 0
    },
    invoiceStatus() {
      let status = {
        text: '',
        color: '',
        bgColor: ''
      };

      let date = this.$date();

      if (this.invoice) {
        if (this.invoice.paid) {
          status.text = 'Fatura paga';
          status.color = 'success--text';
          status.bgColor = 'badge-success';
        } else if (!this.transactions) {
          status.text = 'Fatura vazia';
          status.color = 'success--text';
          status.bgColor = 'badge-success';
        } else {
          let closeDate = this.$date(this.invoice.closeDate);
          let paymentDate = this.$date(this.invoice.paymentDate);
          if (date.isAfter(paymentDate)) {
            status.text = 'Fatura vencida';
            status.color = 'error--text';
            status.bgColor = 'badge-error';
          } else if (
            date.isSameOrAfter(closeDate) &&
            this.currentDate.isSameOrBefore(paymentDate)
          ) {
            status.text = 'Fatura fechada';
            status.color = 'warning--text';
            status.bgColor = 'badge-warning';
          } else {
            status.text = 'Fatura aberta';
            status.color = 'success--text';
            status.bgColor = 'badge-success';
          }
        }
      }

      return status;
    },
  },
  methods: {
    formatBankName(bank) {
      if (bank) {
        return stringCapitalize(bank.replace(/-+/g, ' '))
      }

      return bank
    },
    getImgUrl(flag) {
      let images = require.context(
        '@/assets/credit-card/',
        false,
        /\.png$/
      );
      return images('./' + flag + '.png');
    },
    showDialogFormInvoiceDates() {
      if (
        this.invoice &&
        this.allowed &&
        !this.invoice.paid &&
        !this.creditCard.archived
      ) {
        this.$bus.$emit('showDialogFormInvoiceDates', {
          _id: this.invoice._id,
          creditCard: this.creditCard._id,
          closeDate: this.invoice.closeDate,
          paymentDate: this.invoice.paymentDate
        });
      } else if (this.invoice.paid) {
        this.$noty.warning('Fatura paga!', 'warning');
      }
    },
    formatInvoiceDate(date) {
      if (date) {
        return this.$date(date).format('DD MMM, YYYY');
      }

      return ''
    },
    invoicePayment() {
      if (this.allowed && !this.creditCard.archived)
        this.$bus.$emit('showDialogInvoicePayment', this.invoice);
    },
    showDialogFormInvoiceRefund(refund) {
      if (this.allowed && !this.invoice.paid && !this.creditCard.archived) {
        this.$bus.$emit('showDialogFormInvoiceRefund', {
          refund,
          invoiceId: this.invoice._id,
          referenceDate: this.invoice.closeDate
        });
      }
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 768px;
}

.card-name-text {
  color: white;
  font-size: 12px;
  line-height: 1.5em;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.card-name-bank {
  font-size: 9px;
  letter-spacing: 0.1em;
}

.card-flag {
  display: block;
  width: 32px;
}

.invoice-date {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1.5px solid #9e9e9e;
  cursor: pointer;
}

.invoice-date span {
  font-size: 12px;
  letter-spacing: 0.1em;
  color: white;
}

small.invoice-date-desc {
  font-size: 11px;
  letter-spacing: 0.1em;
  color: #9e9e9e;
}

.invoice-value {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.invoice-value-desc {
  color: #9e9e9e;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
}

.invoice-value-number {
  color: white;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0.1em;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.invoice-value-number span {
  font-size: 10px;
}

.badge {
  width: 150px;
  position: absolute;
  right: auto;
  bottom: auto;
  left: calc(100% - 100px);
  top: -15px;
  font-size: 16px;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  font-weight: bold;
  text-align: center;
  border-radius: 15px !important;
}

.badge-error {
  background-color: #ff5252;
}

.badge-success {
  background-color: #4aa84e;
}

.badge-warning {
  background-color: #ffc107;
}

@media only screen and (min-width: 600px) {
  .v-avatar {
    width: 42px !important;
    height: 42px !important;
  }

  .v-avatar .v-icon {
    font-size: 24px !important;
  }

  .card-name-text {
    font-size: 15px;
  }

  .card-name-bank {
    font-size: 12px;
  }

  .card-flag {
    width: 48px;
  }

  .invoice-value-desc {
    font-size: 15px;
    line-height: 1.5em;
  }

  .invoice-value-number {
    font-size: 35px;
    line-height: 1em;
  }

  .invoice-value-number span {
    font-size: 20px;
  }

  .v-btn.invoice-payment-btn {
    display: block;
    min-width: 147px;
    margin-top: 20px;
    height: 40px !important;
  }

  .v-btn.invoice-payment-btn .v-btn__content {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 960px) {
  .card-limit {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .card-limit-info {
    display: flex;
    flex-direction: column;
  }

  .card-limit-info:nth-child(2) {
    align-items: flex-end;
  }

  .card-limit-text {
    font-size: 10px;
    line-height: 2em;
    letter-spacing: 0.1em;
  }

  .card-limit-value {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1em;
    font-weight: bold;
    color: white;
  }

  .card-limit-value span {
    font-size: 9px;
  }

  .invoice-value-desc {
    font-size: 12px;
  }

  .invoice-value-number {
    font-size: 22px;
  }

  .invoice-value-number > span {
    font-size: 12px;
  }
}
</style>
