import store from '@/store/index'
import firebase from "firebase/app";
import moment from 'moment-timezone'
import dayjs from 'dayjs'
import logout from "./logout";
import getUserByUid from "../api/users/getUserByUid";
import getUserBankAccounts from "../api/bankAccounts/getUserBankAccounts";
import getUserCreditCards from "../api/creditCards/getUserCreditCards";
import getCategoriesUser from "../api/categoriesUser/getCategoriesUser";
import getUserTransactions from "../api/transactions/getUserTransactions";
import getInvoice from "../api/invoices/getInvoice";
import getUserPatrimonio from "../api/patrimonio/getUserPatrimonio";
import getUserIndFinanceira from "../api/indFinanceira/getUserIndFinanceira";
import getUserActionPlans from '@/api/actionPlans/getUserActionPlans';

const TIMEZONE = 'America/Sao_Paulo';
const PERIOD_PATTERN = 'YYYY-MM';

const getCustomerData = async () => {
  try {
    let customer = store.getters.customer;
    let user = customer ? customer : store.getters.user;
    let firebaseUser = firebase.auth().currentUser;

    if (!firebaseUser) {
      logout();
    } else if (!user) {
      user = await getUserByUid(firebaseUser.uid);
      store.dispatch('setUser', user);
    }

    if (user.role === 'customer') {
      let currentDate = moment().tz(TIMEZONE);
      let period = currentDate.format(PERIOD_PATTERN);

      let promises = [
        getUserBankAccounts(user._id),
        getUserCreditCards(user._id),
        getCategoriesUser(user._id),
        getUserPatrimonio(user._id),
        getUserIndFinanceira(user._id, period),
        getUserActionPlans(user._id)
      ];

      if (!store.getters.transactionsPeriods.includes(period)) {
        promises.push(getUserTransactions(user._id, period));
      }

      let results = await Promise.all(promises);

      store.dispatch('setBankAccounts', results[0]);
      store.dispatch('setCreditCards', results[1]);
      store.dispatch('setCategories', results[2]);
      store.dispatch('setPatrimonio', results[3]);
      store.dispatch('setIndependenciaFinanceira', results[4]);
      store.dispatch('setActionPlans', results[5].actionPlans);

      if (results[6]) {
        // store.dispatch('saveTransactionsPeriod', period);
        store.dispatch('setTransactions', results[6]);
      }

      let invoicesPromises = [];
      let today = currentDate.date();

      results[1].forEach(cc => {
        let [day, month, year] = dayjs().format('DD-MM-YYYY').split('-')

        if (day > cc.close) {
          month = parseInt(month) + 1
          if (month < 10) {
            month = `0${month}`
          } else if (month > 12) {
            month = 1
            year = parseInt(year) + 1
          }
        }

        if (cc.close > cc.pay) {
          month = parseInt(month) + 1
          if (month < 10) {
            month = `0${month}`
          } else if (month > 12) {
            month = 1
            year = parseInt(year) + 1
          }
        }

        if (month < 10 && typeof month === 'number') {
          month = `0${month}`
        }

        invoicesPromises.push(getInvoice(cc._id, `${year}-${month}`));
      });

      let invoicesResults = await Promise.all(invoicesPromises);

      invoicesResults.forEach(result => {
        if (result.invoice)
          store.dispatch('saveInvoice', result.invoice);

        if (result.transactions)
          store.dispatch('setTransactions', result.transactions);
      });
    }

    return new Promise((resolve, reject) => resolve());
  } catch(err) {
    return new Promise((resolve, reject) => reject(err));
  }
}

export default getCustomerData;
