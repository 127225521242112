<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="400px">
    <v-card :class="isMobile ? 'no-radius' : ''">
      <v-progress-linear indeterminate :height="5" color="success" v-if="isMobile && loader"></v-progress-linear>

      <v-card-title>
        <div class="title">
          <v-btn icon v-if="isMobile" class="mr-4" @click.stop.prevent="closeDialog">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>Remover Alíquota
        </div>
      </v-card-title>

      <v-card-text>
        <p>Tem certeza que deseja remover esta alíquota?</p>
        <v-card-actions class="pa-0">
          <v-btn
            text
            small
            rounded
            type="button"
            :disabled="loader"
            @click.stop.prevent="closeDialog"
          >Cancelar</v-btn>

          <v-spacer></v-spacer>

          <v-btn
            rounded
            small
            depressed
            class="px-5"
            color="error"
            :loading="loader"
            @click.stop="remove"
          >Remover</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import deleteAliquotaIr from '@/api/config/deleteAliquotaIr';

export default {
  name: 'DialogRemoveAliquotaIrpfItem',
  data: () => ({
    dialog: false,
    loader: false,
    itemId: ''
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    config() {
      return this.$store.getters.configIr;
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.loader = false;
      this.itemId = '';
    },
    remove() {
      this.loader = true;

      deleteAliquotaIr(this.config._id, this.itemId)
        .then(config => {
          this.$store.dispatch('setConfigIr', config);
          this.$noty.success('Item removido com sucesso!');
          this.closeDialog();
        })
        .catch(err => {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'production') console.error(err);
          this.loader = false;
          this.$noty.error('Falha ao remover item! Tente novamente mais tarde');
        });
    }
  },
  created() {
    this.$bus.$on('showDialogRemoveAliquotaIrpfItem', itemId => {
      if (itemId) {
        this.itemId = itemId;
        this.dialog = true;
      }
    });
  }
};
</script>

<style scoped>
.v-card.no-radius {
  border-radius: 0 !important;
}

.title {
  display: flex;
  align-items: center;
}
</style>