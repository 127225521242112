import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Listar planos de ação de determinado usuário
 * @param userId
 * @returns {Promise<Array>}
 */
const getUserActionPlans = (userId) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let url = `/action-plans/users/${userId}`;
          let headers = { Authorization: `Bearer ${idToken}` };

          return httpClient(url, { headers });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default getUserActionPlans;
