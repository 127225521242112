/**
 * Remove caracteres contábeis
 * @param value
 */
const removeMoneyMask = value => {
  if (typeof value === 'undefined') {
    return 0;
  } else if (typeof value === 'number') {
    return value
  } else if (!Number.isNaN(Number.parseFloat(value))) {
    return Number.parseFloat(value)
  }

  let regExp = /^R\$\s-?(\d{1,3}.+)?\d{1,3},\d{2}$/gm;

  if (regExp.test(value)) {
    let numbers = value.replace(/\D+/g, '').split('');

    numbers.splice(numbers.length - 2, 0, '.');
    numbers = numbers.join('');

    let result = parseFloat(numbers);

    if (value.includes('-')) result *= -1;

    return result;
  }

  return 0.0;
};

export default removeMoneyMask;
