import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const deleteInvestment = investmentId => {
  return new Promise((resolve, reject) => {
    try {
      if (!investmentId)
        reject({ message: 'investment-id-not-sent' });

      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'DELETE';
          let url = `/investments/${investmentId}`;
          let headers = { Authorization: `Bearer ${idToken}` };

          return httpClient(url, { headers, method });
        })
        .then(resp => resolve())
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default deleteInvestment;
