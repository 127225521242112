<template>
  <v-card color="darkgrey" flat>
    <v-card-title class="title white--text">
      Colaboradores
      <span class="primary--text">.</span>
    </v-card-title>

    <v-card-text v-if="user">
      <v-container grid-list-md fluid class="px-0">
        <v-layout row wrap>
          <v-flex xs12 sm6 md4 lg2>
            <CardTotalEmployess
              :total="total"
              description="Cadastrados"
              icon="mdi-account-multiple-outline"
              tooltip="Total de colaboradores cadastrados"
            />
          </v-flex>

          <v-flex xs12 sm6 md4 lg2>
            <CardTotalEmployess
              :total="totalPendent"
              description="Pendentes"
              icon="mdi-account-clock-outline"
              :total-parts="total"
              tooltip="Total de colaboradores que não ativaram o acesso"
            />
          </v-flex>

          <v-flex xs12 sm6 md4 lg2>
            <CardTotalEmployess
              :total="totalCanceled"
              description="Cancelados"
              icon="mdi-account-cancel-outline"
              :total-parts="total"
              tooltip="Total de colaboradores que desistiram antes de ativar o acesso"
            />
          </v-flex>

          <v-flex xs12 sm6 md4 lg2>
            <CardTotalEmployess
              :total="totalTrial"
              description="Trial"
              icon="mdi-account-alert-outline"
              :total-parts="total"
              tooltip="Total de colaboradores em período trial (primeiros 30 dias após data de cadastro)"
            />
          </v-flex>

          <v-flex xs12 sm6 md4 lg2>
            <CardTotalEmployess
              :total="totalEnabled"
              description="Ativos"
              icon="mdi-account-check-outline"
              :total-parts="total"
              tooltip="Total de colaboradores com assinatura ativa e que terminaram o período de trial"
            />
          </v-flex>

          <v-flex xs12 sm6 md4 lg2>
            <CardTotalEmployess
              :total="totalDisabled"
              description="Desabilitados"
              icon="mdi-account-remove-outline"
              :total-parts="total"
              tooltip="Total de colaboradores que desativaram as assinaturas"
            />
          </v-flex>

          <v-flex xs12>
            <CardCompanyPayers/>
          </v-flex>

          <v-flex xs12>
            <v-card color="grey3">
              <v-card-text>
                <v-form @submit.prevent="getEmployees">
                  <v-container grid-list-md>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <span class="primary--text text-uppercase filters-title">Filtros</span>
                      </v-flex>

                      <v-flex xs12 md6 lg8>
                        <span class="primary--text input-label">Pesquisar pelo nome</span>
                        <v-text-field
                          color="grey1"
                          class="mt-0 pt-0"
                          v-model="filter.search"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 md5 lg3>
                        <span class="primary--text input-label">Status</span>
                        <v-select
                          color="grey1"
                          class="mt-0 pt-0"
                          v-model="filter.status"
                          :items="statusItems"
                          item-text="text"
                          item-value="value"
                        ></v-select>
                      </v-flex>

                      <v-btn
                        small
                        block
                        rounded
                        type="submit"
                        color="primary"
                        :loading="loader"
                        class="darkgrey--text hidden-md-and-up"
                      >
                        Filtrar
                      </v-btn>

                      <v-btn
                        fab
                        small
                        type="submit"
                        color="primary"
                        :loading="loader"
                        class="darkgrey--text hidden-sm-and-down mt-4 ml-3"
                      >
                        <v-icon>mdi-account-search-outline</v-icon>
                      </v-btn>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

      <v-data-table
        :loading="loader"
        :items="employees"
        hide-default-footer
        :headers="tableHeaders"
      >
        <template v-slot:item="props" class="grey3">
          <tr>
            <td
              class="text-center"
              style="width: 90px; padding: 5px;"
              @click.stop="showEmployeeDetails(props.item._id)"
            >
              <v-icon :color="getUserStatusColor(props.item)">
                {{ getUserStatusIcon(props.item) }}
              </v-icon>
            </td>

            <td
              class="name-column"
              @click.stop="showEmployeeDetails(props.item._id)"
            >
              {{ props.item.displayName }}
            </td>

            <td
              style="width: 180px"
              class="text-center hidden-sm-and-down"
            >
              <v-btn
                x-small
                rounded
                depressed
                color="primary"
                class="darkgrey--text px-5"
                @click="showEmployeeDetails(props.item._id)"
              >
                Detalhes
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div class="my-5 table-caption">
        <div class="d-flex align-center pb-1" v-for="item in legends" :key="item.text">
          <v-icon small :color="item.color" class="mr-2">{{ item.icon }}</v-icon>
          <span class="grey1--text input-label">{{ item.text }}</span>
        </div>
      </div>

      <div class="mt-5">
        <v-pagination
          circle
          color="primary"
          v-model="pagination.page"
          :length="pagination.totalPages"
        ></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import getCompanyEmployess from '@/api/companies/getCompanyEmployees';
import CardEmployee from '@/components/Company/cards/CardEmployee';
import CardTotalEmployess from '@/components/Company/cards/CardTotalEmployees';
import countCompanyEmployees from '@/api/companies/countCompanyEmployees';
import CardCompanyPayers from '@/components/Company/cards/CardCompanyPayers';

export default {
  name: 'TableCompanyEmployess',
  components: { CardCompanyPayers, CardTotalEmployess, CardEmployee },
  data: () => ({
    width: 320,
    loader: false,
    employees: [],
    filter: {
      search: '',
      status: ''
    },
    pagination: {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      itemsPerPage: 10
    },
    tableHeaders: [
      {
        text: 'Status',
        align: 'center',
        sortable: false,
        value: 'disabled',
        class: 'hidden-xs-only'
      },
      {
        text: 'Nome',
        align: 'left',
        sortable: false,
        value: 'displayName'
      },
      {
        text: '',
        align: 'center',
        sortable: false,
        value: 'action',
        class: 'hidden-sm-and-down'
      }
    ],
    total: 0,
    totalEnabled: 0,
    totalDisabled: 0,
    totalPendent: 0,
    totalTrial: 0,
    totalCanceled: 0,
    statusItems: [
      { text: 'Todos', value: '' },
      { text: 'Ativos', value: 'active' },
      { text: 'Cancelados', value: 'canceled' },
      { text: 'Desabilitados', value: 'disabled' },
      { text: 'Pendentes', value: 'pending' },
      { text: 'Trial', value: 'trial' }
    ],
    legends: [
      {
        text: 'Ativo',
        icon: 'mdi-account-check-outline',
        color: 'success'
      },
      {
        text: 'Pendente',
        icon: 'mdi-account-clock-outline',
        color: 'warning'
      },
      {
        text: 'Trial',
        icon: 'mdi-account-alert-outline',
        color: 'warning'
      },
      {
        text: 'Cancelado',
        icon: 'mdi-account-cancel-outline',
        color: 'error'
      },
      {
        text: 'Desabilitado',
        icon: 'mdi-account-remove-outline',
        color: 'error'
      }
    ]
  }),
  computed: {
    isCompany() {
      return this.$store.getters.user && this.$store.getters.user.role === 'company'
    },
    user() {
      return this.isCompany ? this.$store.getters.user : this.$store.getters.company
    },
  },
  methods: {
    setAndWatchWidth() {
      var self = this

      self.width = window.innerWidth
      window.addEventListener('resize', function () {
        self.width = window.innerWidth
      })
    },
    getEmployees() {
      if (this.user) {
        this.loader = true

        getCompanyEmployess(this.user._id, this.pagination.page, this.filter.search, this.filter.status)
          .then(data => {
            this.employees = data.items
            this.pagination.totalItems = data.totalItems
            this.pagination.totalPages = (data.totalItems ?
              Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage) : 0)
            this.loader = false
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }

            this.loader = false
          })
      }
    },
    truncateName(name) {
      if (name) {
        const len = name.length

        if (this.width < 375 && len > 19) {
          return name.slice(0, 19) + '...'
        } else if (this.width < 414 && len > 30) {
          return name.slice(0, 28) + '...'
        } else if (this.width < 600 && len > 35) {
          return name.slice(0, 35) + '...'
        }

        return name
      }
      return ''
    },
    showEmployeeDetails(employeeId) {
      this.$bus.$emit('showDialogCompanyEmployee', employeeId)
    },
    count() {
      if (this.user) {
        countCompanyEmployees(this.user._id)
          .then(results => {
            this.total = results.total
            this.totalTrial = results.trial
            this.totalCanceled = results.canceled
            this.totalEnabled = results.active
            this.totalPendent = results.pendent
            this.totalDisabled = results.disabled
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }
          })
      }
    },
    getUserStatusIcon(user) {
      if (user.disabled || user.iuguSubscriptionStatus === false) {
        return 'mdi-account-remove-outline'
      } else if (user.canceled && !user.iuguSubscription) {
        return 'mdi-account-cancel-outline'
      } else if (!user.disabled && !user.iuguSubscription) {
        return 'mdi-account-clock-outline'
      } else if (!user.disabled && user.iuguSubscription &&
        user.iuguSubscriptionStatus !== false && this.$date().diff(user.createdAt, 'day') <= 30) {
        return 'mdi-account-alert-outline'
      } else {
        return 'mdi-account-check-outline'
      }
    },
    getUserStatusColor(user) {
      if (user.disabled || user.iuguSubscriptionStatus === false) {
        return 'error'
      } else if (user.canceled && !user.iuguSubscription) {
        return 'error'
      } else if (!user.disabled && !user.iuguSubscription) {
        return 'warning'
      } else if (!user.disabled && user.iuguSubscription &&
        user.iuguSubscriptionStatus !== false && this.$date().diff(user.createdAt, 'day') <= 30) {
        return 'warning'
      } else {
        return 'success'
      }
    }
  },
  watch: {
    'pagination.page'() {
      this.getEmployees()
    },
    user() {
      if (!this.isCompany) {
        this.getEmployees()
        this.setAndWatchWidth()
        this.count()
      }
    }
  },
  created() {
    if (this.isCompany) {
      this.getEmployees()
      this.setAndWatchWidth()
      this.count()
    }
  }
}
</script>

<style scoped>
.theme--dark.v-data-table {
  background-color: #2D2D2D !important;
}

.v-card__title.title {
  font-size: 30px !important;
  font-weight: bold;
  letter-spacing: .02em !important;
}

tr {
  cursor: pointer;
}

td.name-column {
  font-size: 11px !important;
  letter-spacing: .1em;
}

.table-caption {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.filters-title {
  font-size: 9px;
  line-height: 1.5em;
  letter-spacing: .1em;
}

.input-label {
  font-size: 9px;
}

@media only screen and (min-width: 375px) {
  td.name-column {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 414px) {
  .filters-title {
    font-size: 10px;
  }

  .input-label {
    font-size: 10px;
  }
}

@media only screen and (min-width: 600px) {
  .table-caption {
    flex-direction: row;
  }

  .table-caption > div {
    margin-right: 15px;
  }
}
</style>
