<template>
  <v-container grid-list-md fluid class="pb-10">
    <v-layout row wrap>
      <v-flex xs12 class="d-flex align-center justify-center">
        <div class="balance">
          <span class="balance-desc">{{ verifyPeriod }}</span>
          <span class="balance-value"> <span>R$</span> {{ balance }} </span>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md4
        lg3
        :key="bankAccount._id"
        v-for="bankAccount in filteredBankAccounts"
      >
        <CardBankAccount :period="period" :bank-account-id="bankAccount._id" />
      </v-flex>
      <v-flex xs12 class="d-flex align-center justify-center mt-10" v-if="!archived && !isMobile">

        <v-btn
          rounded
          outlined
          class="px-5"
          color="primary"
          :disabled="!allowed"
          @click.stop="showDialogFormBankAccount(null)"
        >
          Nova conta bancária
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import currency from '@/utils/currency';
import CardBankAccount from '@/components/Customer/Cashflow/cards/CardBankAccount';

export default {
  name: 'TabItemBankAccounts',
  components: { CardBankAccount },
  props: ['period', 'archived'],
  data: () => ({
    currentYear: null,
    currentMonth: null,
  }),
  computed: {
    bankAccounts() {
      return this.$store.getters.bankAccounts;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    filteredBankAccounts() {
      const [year, month] = this.periodNumbers;

      return orderBy(
        this.bankAccounts.filter((b) => {
          if (b.archived === this.archived) {
            const [baYear, baMonth] = b.initialAmountDate.split('-');
            return baYear < year || (baYear === year && baMonth <= month);
          }
        }),
        ['name', 'bank']
      );
    },
    periodNumbers() {
      if (this.period) {
        return this.period.split('-');
      }
      return [];
    },
    balance() {
      let total = 0;
      const [year, month] = this.periodNumbers;

      this.filteredBankAccounts.forEach((ba) => {
        let historyItem = ba.history.find(
          (h) => h.year == year && h.month == month
        );

        if (!historyItem) {
          historyItem = ba.history[ba.history.length - 1];
        }

        if (historyItem) {
          total += historyItem.balance;
        }
      });

      return currency(total);
    },
    verifyPeriod() {
      let [year, month] = this.periodNumbers;

      if (
        year > this.currentYear ||
        (month > this.currentMonth && year == this.currentYear)
      ) {
        return 'Saldo projetado';
      } else if (year == this.currentYear && month == this.currentMonth) {
        return 'Saldo em conta';
      }

      return 'Saldo';
    },
    allowed() {
      return this.$store.getters.allowed;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
  methods: {
    showDialogFormBankAccount() {
      if (this.allowed) {
        if (this.isCustomer) {
          this.$bus.$emit('showDialogAddAccount', { type: 'bank-account' });
        } else {
          this.$bus.$emit('showDialogFormBankAccount')
        }
      }
    },
  },
  created() {
    let date = dayjs();
    this.currentYear = date.year();
    this.currentMonth = date.month() + 1;
  },
};
</script>

<style scoped>
.container {
  max-width: 1264px;
}

.balance {
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: baseline;
  justify-content: space-between;
}

.balance-desc {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1em;
}

.balance-value {
  font-size: 18px;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  text-align: right;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.balance-value span {
  font-size: 12px;
}

.v-btn.v-size--default {
  font-size: 11px !important;
}

@media only screen and (min-width: 600px) {
  .balance-desc {
    font-size: 16px;
  }

  .balance-value {
    font-size: 24px;
  }
}

@media only screen and (min-width: 960px) {
  .balance {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #131313;
    padding: 15px 20px 5px 20px;
    border-radius: 10px;
    margin-bottom: 50px;
    -webkit-box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.75);
  }

  .balance-desc {
    color: #9e9e9e;
    font-size: 16px;
    font-weight: 400;
  }

  .balance-value {
    font-size: 25px;
    color: white;
    font-weight: bold;
  }
}
</style>
