<template>
  <v-main :class="role === 'customer' ? 'margin-menu' : ''">
    <OverlaySync
      v-show="loader"
      text="Carregando..."
    />

    <v-container
      grid-list-md
      class="pt-3 safe-area-top safe-area-bottom mb-10 mt-md-10"
    >
      <v-layout row wrap>
        <v-flex xs12 px-4 pt-3>
          <h1>Meu cadastro<span class="purple--text">.</span></h1>
        </v-flex>

        <v-flex xs12>
          <FormCompanyCadastro v-if="role === 'company'"/>
          <FormCadastroPlanner v-if="role === 'planner'"/>
          <FormCadastroCustomer v-if="role === 'customer'"/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import OverlaySync from '@/components/Global/OverlaySync';
import CompanyNavigationDrawer from '@/components/Company/CompanyNavigationDrawer';
import CompanyToolbar from '@/components/Company/CompanyToolbar';
import CompanyMobileMenu from '@/components/Company/CompanyMobileMenu';
import FormCompanyCadastro from '@/components/Company/forms/FormCompanyCadastro';
import FormCadastroPlanner from '@/components/Planner/forms/FormCadastroPlanner';
import FormCadastroCustomer from '@/components/Customer/Cadastro/forms/FormCadastroCustomer';

export default {
  name: 'Cadastro',
  components: {
    FormCadastroCustomer,
    FormCadastroPlanner,
    FormCompanyCadastro,
    CompanyMobileMenu,
    CompanyToolbar,
    CompanyNavigationDrawer,
    OverlaySync
  },
  data: () => ({
    loader: false
  }),
  computed: {
    user() {
      return this.$store.getters.user
    },
    role() {
      if (this.user) {
        return this.user.role
      }

      return ''
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 768px !important;
}
</style>
