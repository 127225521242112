import firebase from 'firebase/app';
import httpClient from '../../plugins/httpClient';

const updateLiquidez = (liquidezId, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'PUT';
          let url = `/liquidez/${liquidezId}`;
          let headers = { Authorization: `Bearer ${idToken}` };

          return httpClient(url, { headers, method, data });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default updateLiquidez;
