import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Enviar link de redefinição de senha
 * @param {string} userId
 * @param {string} email
 */
const sendResetPassLink = (userId, email) =>
  new Promise((resolve, reject) => {
    let user = store.getters.user;

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        let headers = {
          Authorization: `Bearer ${idToken}`,
          author: user ? user._id : null
        };

        let url = `/users/firebase/send-pass-reset-link?userId=${userId}&email=${email}`;

        return httpClient(url, { headers });
      })
      .then(() => resolve())
      .catch(reject);
  });

export default sendResetPassLink;
