import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const updateAplicacaoOrcamento = (orcamentoId, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'PUT';
          let headers = { Authorization: `Bearer ${idToken}` };
          let url = `/orcamentos/${orcamentoId}/aplicacao`;

          return httpClient(url, { method, headers, data });
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default updateAplicacaoOrcamento;
