<template>
  <v-dialog
    persistent
    fullscreen
    v-model="dialog"
    :fullscreen="isMobile"
    :max-width="dialogWidth"
  >
    <v-card color="grey3" :class="isMobile ? 'no-radius' : ''">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="white"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4">
          Rendas desejadas
        </span>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout row wrap class="pt-5">
            <v-flex xs12 class="d-flex justify-space-between align-center">
              <div></div>

              <v-btn
                fab
                small
                depressed
                color="grey2"
                :disabled="!allowed"
                class="grey1--text hidden-sm-and-down"
                @click="showDialogFormRendaIndFinanceira(null)"
              >
                <v-icon large>mdi-plus</v-icon>
              </v-btn>
            </v-flex>

            <v-flex xs12 class="mt-5">
              <div class="lista-aplicacaoes" :style="'height: ' + listHeight + ';'">
                <v-slide-x-transition
                  :key="renda._id"
                  v-for="(renda, i) in rendas"
                >
                  <div
                    class="aplicacao"
                    :class="rendas[i+1] ? 'aplicacao-border' : ''"
                  >
                    <div class="aplicacao-infos">
                    <span
                      class="aplicacao-infos__value"
                    >
                      {{ numberToCurrency(renda.valor) }}
                    </span>
                      <span class="aplicacao-infos__date grey1--text">a partir de {{ formatDate(renda.data) }}</span>
                    </div>

                    <v-menu bottom left transition="slide-x-reverse-transition">
                      <template v-slot:activator="{ on }">
                        <v-btn icon dark v-on="on" :disabled="!allowed">
                          <v-icon color="grey1">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item class="menu-item" @click="edit(renda)">Editar</v-list-item>
                        <v-list-item class="menu-item" @click="remove(renda._id)">Remover</v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-slide-x-transition>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-btn
        fab
        fixed
        right
        bottom
        color="primary"
        :disabled="!allowed"
        @click="showDialogFormRendaIndFinanceira(null)"
        class="grey3--text btn-add-category hidden-md-and-up"
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
    </v-card>

    <DialogFormRendaIndFinanceira/>
    <DialogRemoveRendaIndFinanceira/>
  </v-dialog>
</template>

<script>
  import {orderBy} from 'lodash'
  import moment from 'moment-timezone'
  import numberToCurrency from "../../../../utils/numberToCurrency";
  import DialogFormRendaIndFinanceira from "./DialogFormRendaIndFinanceira";
  import DialogRemoveRendaIndFinanceira from "./DialogRemoveRendaIndFinanceira";

  export default {
    name: 'DialogRendasIndFinanceira',
    components: {DialogRemoveRendaIndFinanceira, DialogFormRendaIndFinanceira},
    data: () => ({
      dialog: false,
      loader: false,
      currentDate: null,
      listHeight: '100px',
      dialogWidth: '788px',
      numberToCurrency
    }),
    computed: {
      allowed() {
        return this.$store.getters.user && ['planner', 'customer'].includes(this.$store.getters.user.role);
      },
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      indFinanceira() {
        return this.$store.getters.independenciaFinanceira;
      },
      rendas() {
        if (this.indFinanceira)
          return orderBy(this.indFinanceira.rendas.items, 'data');

        return [];
      },
      rendaAtual() {
        let len = this.rendas.length;
        let currentDate = moment().tz('America/Sao_Paulo').format('YYYY-MM-DD');

        if (len === 1 && moment(this.rendas[0].data).isSameOrBefore(currentDate, ['year', 'month'])) {
          return this.rendas[0].valor;
        } else {
          let filtered = this.rendas.filter(ap => {
            return moment(ap.data).isSameOrBefore(currentDate, ['year', 'month']);
          });

          if (filtered.length)
            return filtered[filtered.length-1].valor;
        }

        return 0;
      }
    },
    methods: {
      edit(item) {
        this.showDialogFormRendaIndFinanceira(item);
      },
      remove(id) {
        this.$bus.$emit('showDialogRemoveRendaIndFinanceira', id);
      },
      setDimensions() {
        let width = window.innerWidth;
        let height = window.innerHeight;

        if (width < 414) {
          this.listHeight = (height - 220) + 'px';
        } else if (width < 600) {
          this.listHeight = (height - 220) + 'px';
        } else if (width <= 768) {
          this.listHeight = (height - 220) + 'px';
        } else if (width < 1264 && this.isMobile) {
          this.listHeight = (height - 220) + 'px';
        } else if (width >= 960 && !this.isMobile) {
          this.listHeight = '450px';
        }

        if (width < 1264)
          this.dialogWidth = '788px';
        else
          this.dialogWidth = '980px';
      },
      close() {
        this.dialog = false;
      },
      showDialogFormRendaIndFinanceira(renda) {
        if (this.allowed)
          this.$bus.$emit('showDialogFormRendaIndFinanceira', renda);
      },
      formatDate(date) {
        if (date)
          return moment(date).locale('pt-BR').format('MMMM YYYY');

        return '';
      },
    },
    created() {
      this.$bus.$on("showDialogRendasIndFinanceira", () => {
        this.setDimensions();
        this.currentDate = moment().tz('America/Sao_Paulo');
        this.dialog = true;
      });
    }
  }
</script>

<style scoped>
  .current-title {
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: .1em;
  }

  .current-desc {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .1em;
  }

  .lista-aplicacaoes {
    overflow-y: scroll;
  }

  .lista-aplicacaoes::-webkit-scrollbar {
    width: 4px;
  }

  .lista-aplicacaoes::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
  }

  .aplicacao {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .aplicacao-infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .aplicacao-infos__value {
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: white !important;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .aplicacao-infos__date {
    font-size: 9px;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .aplicacao-border {
    border-bottom: solid 2px #919191;
  }

  .v-list-item.menu-item {
    font-size: 12px !important;
    letter-spacing: .1em !important;
  }

  @media only screen and (min-width: 600px) {
    .current-title {
      font-size: 22px;
      font-weight: bold;
    }

    .current-desc {
      font-size: 14px;
    }

    .aplicacao-infos__value {
      font-size: 20px;
    }

    .aplicacao-infos__date {
      font-size: 12px;
    }
  }
</style>
