<template>
  <v-main :class="route === 'Customer' ? 'bg' : ''" class="margin-menu">
    <OverlaySync :overlay="loader" text="Calculando..." />

    <MobilePageTitle title="Objetivos" :loader="loader" />

    <v-container grid-list-md v-if="liquidez" class="pb-10">
      <v-layout row wrap class="pb-10">
        <v-flex xs12 class="py-10 hidden-sm-and-down" v-if="!isMobile">
          <div class="page-title">Objetivos</div>
        </v-flex>
        <v-flex
          xs12
          sm12
          md6
          offset-md3
          lg4
          offset-lg4
          class="order-md-1 order-lg-1"
        >
          <v-card color="grey2">
            <div class="total">
              <span class="total-desc primary--text text-uppercase"
                >Total de aplicações</span
              >
              <span class="total-value">
                <span>R$</span> {{ currency(liquidez.total) }}
              </span>
            </div>
          </v-card>
        </v-flex>

        <v-flex xs12 class="hidden-md-and-down order-lg-2"></v-flex>

        <v-flex xs12 sm12 md6 lg3 class="order-md-4 order-lg-3">
          <CardSecurityWallet />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 class="order-md-2 order-lg-4">
          <CardLifeProjectsLiquidez />
        </v-flex>

        <v-flex xs12 sm12 md6 lg3 class="order-md-5 order-lg-5">
          <CardIndependenciaLiquidez />
        </v-flex>
      </v-layout>
    </v-container>

    <ButtonForms class="hidden-sm-and-down" v-if="isCustomer"/>
  </v-main>
</template>

<script>
import currency from '../../utils/currency';
import getLiquidezByUserId from '../../api/liquidez/getLiquidezByUserId';
import getLoggedUserData from '../../utils/getLoggedUserData';

import OverlaySync from '../../components/Global/OverlaySync';
import CustomerToolbar from '../../components/Customer/toolbars/CustomerToolbar';
import MobilePageTitle from '../../components/Global/MobilePageTitle';
import CardSecurityWallet from '../../components/Customer/Objetivo/cards/CardSecurityWallet';
import CardLifeProjectsLiquidez from '../../components/Customer/Objetivo/cards/CardLifeProjectsLiquidez';
import CardIndependenciaLiquidez from '../../components/Customer/Objetivo/cards/CardIndependenciaLiquidez';
// import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

export default {
  name: 'Objetivo',
  // metaInfo() {
  //   return {
  //     title: 'Objetivos',
  //     changed(metaInfo) {
  //       sendMetaInfoData(metaInfo);
  //     },
  //   };
  // },
  components: {
    CardIndependenciaLiquidez,
    CardLifeProjectsLiquidez,
    CardSecurityWallet,
    MobilePageTitle,
    CustomerToolbar,
    OverlaySync,
  },
  data: () => ({
    loader: false,
    currency,
    route: '',
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === 'customer'
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    liquidez() {
      return this.$store.getters.liquidez;
    },
  },
  methods: {
    getLiquidez() {
      getLiquidezByUserId(this.user._id)
        .then((liquidez) => {
          this.$store.dispatch('saveLiquidez', liquidez);
          this.loader = false;
        })
        .catch((err) => {
          this.$router.push('/');

          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
        });
    },
    getData() {
      if (!this.user) {
        getLoggedUserData().then(() => {
          this.getLiquidez();
        });
      } else {
        this.getLiquidez();
      }
    },
  },
  beforeCreate() {
    this.$vuetify.theme.dark = true;
  },
  created() {
    this.loader = true;
    this.getData();
  },
  mounted() {
    this.route = this.$route.name;
  },
};
</script>

<style scoped>
.v-main.bg {
  background-color: #131313 !important;
}

.container {
  max-width: 1264px;
}

.total {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-desc {
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.total-value {
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  color: white;
  font-size: 18px;
  line-height: 1.5em;
}

.total-value > span {
  font-size: 10px;
}

@media only screen and (min-width: 600px) {
  .total-desc {
    font-size: 15px;
  }

  .total-value {
    font-size: 24px;
  }

  .total-value > span {
    font-size: 20px;
  }
}

@media only screen and (min-width: 960px) {
  .page-title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 1.5em;
    letter-spacing: 0.1em;
  }
}

@media only screen and (min-width: 1264px) {
  .total-desc {
    font-size: 16px;
  }
}
</style>
