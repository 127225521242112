<template>
  <v-dialog
    :fullscreen="isMobile"
    scrollable
    persistent
    v-model="dialog"
    max-width="960px"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" v-if="customer">
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="white"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="white--text ml-4">
          Atividades de {{ customer.displayName.split(/\s+/gm)[0] }}
        </span>
      </v-card-title>

      <v-card-text class="px-1">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm12 md6 lg4>
              <span class="input-label primary--text">Filtrar por tipo</span>
              <v-select
                color="grey1"
                :items="types"
                v-model="type"
                item-text="text"
                :loading="loader"
                item-value="value"
                @change="getActivity"
              ></v-select>
            </v-flex>

            <v-flex xs12 class="pa-0">
              <v-data-table
                hide-default-footer
                :headers="tableHeaders"
                :items="events"
                :loading="loader"
              >
                <template v-slot:body="{items}">
                  <tbody>
                    <tr v-for="item in items" :key="item._id" @click="showDetails('tr', item)">
                      <td>{{ formatType(item.type) }}</td>
                      <td class="text-center">{{ formatDate(item.date) }}</td>
                      <td class="hidden-sm-and-down">{{ item.author ? item.author.displayName : '' }}</td>
                      <td class="text-center hidden-xs-only">
                        <v-btn
                          small
                          rounded
                          depressed
                          color="primary"
                          class="darkgrey--text"
                          @click="showDetails('btn', item)"
                        >
                          Detalhes
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-flex>

            <v-flex xs12>
              <v-pagination
                circle
                color="primary"
                v-model="pagination.page"
                :length="pagination.totalPages"
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>

    <EventDetails/>
  </v-dialog>
</template>

<script>
  import moment from 'moment-timezone'
  import getEventsByUserId from '../../../../api/events/getEventsByUserId';
  import EventDetails from '../bottom-sheets/EventDetails';

  export default {
    name: 'DialogCustomerActivity',
    components: { EventDetails },
    data: () => ({
      dialog: false,
      loader: true,
      customer: null,
      events: [],
      pagination: {
        page: 1,
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10
      },
      tableHeaders: [
        { text: 'Tipo', value: 'type', sortable: true, align: 'left', class: '' },
        { text: 'Data', value: 'date', sortable: true, align: 'center', class: '' },
        { text: 'Executado por', value: 'author.displayName', sortable: true, align: 'left', class: 'hidden-sm-and-down' },
        { text: '', value: 'actions', sortable: false, align: 'center', class: 'hidden-xs-only' }
      ],
      types: [
        { text: 'Todos', value: '' },
        { text: 'Login', value: 'signin' },
        { text: 'Redefinição de senha requisitada', value: 'pass-reset-link' },
        { text: 'Registro', value: 'signup' },
        { text: 'Senha atualizada', value: 'password-changed' },
        { text: 'Troca de planejador', value: 'planner-changed' },
        { text: 'Troca de plano', value: 'plan-changed' },
        { text: 'Usuário habilitado', value: 'status-enabled' },
        { text: 'Usuário desabilitado', value: 'status-disabled' },
      ],
      type: ''
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      }
    },
    methods: {
      getActivity() {
        if (this.customer) {
          this.loader = true;

          getEventsByUserId(this.customer._id, this.pagination.page, this.pagination.itemsPerPage, this.type)
            .then(resp => {
              this.events = resp.events;
              this.pagination.totalItems = resp.count;
              this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage);
              this.loader = false;
            })
            .catch(err => {
              this.close();
              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            });
        }
      },
      close() {
        this.dialog = false;
        this.loader = false;
        this.customer = null;
        this.events = [];
        this.pagination = {
          page: 1,
          totalItems: 0,
          totalPages: 0,
          itemsPerPage: 10
        }
      },
      formatType(type) {
        switch (type) {
          case 'signin':
            return 'Login';
          case 'signup':
            return 'Registro';
          case 'planner-changed':
            return 'Troca de planejador';
          case 'status-enabled':
            return 'Usuário habilitado';
          case 'status-disabled':
            return 'Usuário desabilitado';
          case 'plan-changed':
            return 'Troca de plano';
          case 'password-changed':
            return 'Alteração de senha';
          case 'pass-reset-link':
            return 'Redefinição de senha requisitada';
          case 'subscription-suspended':
            return 'Assinatura suspenda'
          case 'subscription-activated':
            return 'Assinatura ativada'
          default:
            return '';
        }
      },
      formatDate(date) {
        if (date)
          return moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss');

        return '';
      },
      showDetails(origin, item) {
        if ((origin === 'tr' && window.innerWidth < 600) || origin === 'btn') {
          this.$bus.$emit('showEventDetails', item);
        }
      }
    },
    watch: {
      'pagination.page'() {
        this.getActivity();
      }
    },
    created() {
      this.$bus.$on('showDialogCustomerActivity', customer => {
        if (customer) {
          this.customer = customer;
          this.dialog = true;
          this.getActivity();
        }
      });
    }
  }
</script>

<style scoped>
  .container {
    max-width: 960px;
  }

  tbody tr td {
    font-size: 12px !important;
    line-height: 1.5em;
    letter-spacing: .1em;
  }
</style>
