<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
  >
    <v-card class="no-radius">
      <v-card-title
        class="dialog-form-title px-2"
        :class="isIOS ? ['ios-padding', 'fixed-title'] : ''"
      >
        <v-btn
          icon
          class="mr-5"
          color="primary"
          @click.stop="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="primary--text">Experiência Financeira</span>
      </v-card-title>

      <v-card-text class="px-2" :class="isMobile ? 'mobile-margin' : ''">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12>
              <h3>{{ username }}</h3>
            </v-flex>

            <v-flex xs12 class="mt-5">
              <span>Experiência com dinheiro na infância e prática de poupar durante a vida.</span>
            </v-flex>

            <v-flex xs12>
              <span class="input-label primary--text">Recebia mesada na infância?</span>
              <v-switch
                flat
                inset
                color="primary"
                :label="usuario.mesada ? 'Recebia' : 'Não recebia'"
                v-model="usuario.mesada"
                :error-messages="usuarioMesadaErrors"
              ></v-switch>
            </v-flex>

            <v-flex xs12 class="mt-5">
            <span>
              E hoje, gasta mais do que ganha, gasta exatamente o que ganha ou sobra alguma coisa? <br>
              Se sobra, qual a sua estimativa?
            </span>
            </v-flex>

            <v-flex xs12 sm6 md6>
              <span class="input-label primary--text">Capacidade atual de poupar</span>
              <v-select
                color="grey1"
                class="pt-0 mt-0"
                :items="capacidades"
                v-model="usuario.capacidadePoupanca.descricao"
                :error-messages="usuarioCapacidadePoupancaDescricaoErrors"
              ></v-select>
            </v-flex>

            <v-flex xs12 sm6 md6>
              <span class="input-label primary--text">Estimativa de poupança</span>
              <v-text-field
                color="grey1"
                class="pt-0 mt-0"
                v-money="money"
                v-model="usuario.capacidadePoupanca.estimativa"
                id="usuario-estimativa-popupanca"
                :error-messages="usuarioCapacidadePoupancaEstimativaErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="mt-5">
            <span>
              Quanto você gasta por mês, considerando gastos fixos como: aluguel, energia, telefone. <br>
              Custos variáveis como: lazer, supermercado e outros.
            </span>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text">Estimativa de despesas mensais</span>
              <v-text-field
                color="grey1"
                class="pt-0 mt-0"
                v-money="money"
                v-model="usuario.despesasMensais"
                id="usuario-despesas-mensais"
                :error-messages="usuarioDespesasMensaisErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="mt-5">
            <span>
              Experiência com investimentos <br>
              Com quais investimentos você já teve experiências?
            </span>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="primary--text input-label">Selecionar investimentos</span>
              <v-select
                multiple
                color="grey1"
                class="pt-0 mt-0"
                v-model="usuario.experienciasInvestimentos"
                :items="investimentos"
              ></v-select>
            </v-flex>

            <v-flex xs12 class="mt-5">
            <span>
              Como você determina sua experiência com investimentos? <br>
              <a
                href="#!"
                class="purple--text"
                @click.prevent="showDialogFatoresPerfil"
              >
                Classifique de acordo com os seguintes fatores.
              </a>
            </span>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text">Selecionar fator de experiência</span>
              <v-select
                color="grey1"
                class="pt-0 mt-0"
                v-model="usuario.fatorExperiencia"
                :items="fatoresExperiencia"
                :error-messages="usuarioFatorExperienciaErrors"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container grid-list-md v-if="hasConjuge">
          <v-layout row wrap>
            <v-flex xs12 class="my-5">
              <v-divider></v-divider>
            </v-flex>

            <v-flex xs12>
              <h3>{{ conjugeName }}</h3>
            </v-flex>

            <v-flex xs12 class="mt-5">
              <span>Experiência com dinheiro na infância e prática de poupar durante a vida.</span>
            </v-flex>

            <v-flex xs12>
              <span class="input-label primary--text">Recebia mesada na infância?</span>
              <v-switch
                flat
                inset
                color="primary"
                :label="conjuge.mesada ? 'Recebia' : 'Não recebia'"
                v-model="conjuge.mesada"
                :error-messages="conjugeMesadaErrors"
              ></v-switch>
            </v-flex>

            <v-flex xs12 class="mt-5">
            <span>
              E hoje, gasta mais do que ganha, gasta exatamente o que ganha ou sobra alguma coisa? <br>
              Se sobra, qual a sua estimativa?
            </span>
            </v-flex>

            <v-flex xs12 sm6 md6>
              <span class="input-label primary--text">Capacidade atual de poupar</span>
              <v-select
                color="grey1"
                class="pt-0 mt-0"
                :items="capacidades"
                v-model="conjuge.capacidadePoupanca.descricao"
                :error-messages="conjugeCapacidadePoupancaDescricaoErrors"
              ></v-select>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text">Estimativa de poupança</span>
              <v-text-field
                color="grey1"
                class="pt-0 mt-0"
                v-money="money"
                v-model="conjuge.capacidadePoupanca.estimativa"
                id="conjuge-estimativa-popupanca"
                :error-messages="conjugeCapacidadePoupancaEstimativaErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="mt-5">
            <span>
              Quanto você gasta por mês, considerando gastos fixos como: aluguel, energia, telefone. <br>
              Custos variáveis como: lazer, supermercado e outros.
            </span>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text">Estimativa de despesas mensais</span>
              <v-text-field
                color="grey1"
                class="pt-0 mt-0"
                v-money="money"
                v-model="conjuge.despesasMensais"
                id="conjuge-despesas-mensais"
                :error-messages="conjugeDespesasMensaisErrors"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="mt-5">
            <span>
              Experiência com investimentos <br>
              Com quais investimentos você já teve experiências?
            </span>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="primary--text input-label">Selecionar investimentos</span>
              <v-select
                multiple
                color="grey1"
                class="pt-0 mt-0"
                v-model="conjuge.experienciasInvestimentos"
                :items="investimentos"
              ></v-select>
            </v-flex>

            <v-flex xs12 class="mt-5">
            <span>
              Como você determina sua experiência com investimentos? <br>
              <a
                href="#!"
                class="purple--text"
                @click.prevent="showDialogFatoresPerfil"
              >
                Classifique de acordo com os seguintes fatores.
              </a>
            </span>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <span class="input-label primary--text">Selecionar fator de experiência</span>
              <v-select
                color="grey1"
                class="pt-0 mt-0"
                v-model="conjuge.fatorExperiencia"
                :items="fatoresExperiencia"
                :error-messages="conjugeFatorExperienciaErrors"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 class="mt-10">
              <v-card-actions class="pa-0 ma-0">
                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  color="primary"
                  class="px-5 darkgrey--text"
                  :loading="loader"
                  @click.stop="submit"
                >
                  Salvar
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VMoney } from 'v-money';
import removeMoneyMask from '@/utils/removeMoneyMask';
import updatePerfil from '@/api/perfil/updatePerfil';

export default {
  name: 'DialogEditExperienciaFinanceira',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    fatoresExperiencia: [
      'Cultural',
      'Contextual',
      'Individual'
    ],
    capacidades: [
      'Gasto mais do que ganho',
      'Gasto tudo o que ganho',
      'Gasto menos do que ganho'
    ],
    investimentos: [
      'Ações',
      'CDB',
      'Debêntures',
      'Fundo de ações',
      'Fundo de renda fixa',
      'Fundo imobiliário',
      'Fundo multimercados',
      'Imobiliário',
      'LCI/LCA/LC',
      'Poupança',
      'Previdência',
      'Título de capitalização',
      'Título público'
    ],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    usuario: {
      mesada: false,
      despesasMensais: '',
      primeiroInvestimento: '',
      fatorExperiencia: '',
      experienciasInvestimentos: [],
      capacidadePoupanca: {
        descricao: '',
        estimativa: ''
      }
    },
    conjuge: {
      mesada: false,
      despesasMensais: '',
      primeiroInvestimento: '',
      fatorExperiencia: '',
      experienciasInvestimentos: [],
      capacidadePoupanca: {
        descricao: '',
        estimativa: ''
      }
    }
  }),
  validations: {
    usuario: {
      mesada: {
        isValid: v => typeof v === 'boolean'
      },
      despesasMensais: {
        isValid: v => typeof v !== 'undefined' && removeMoneyMask(v) >= 0
      },
      primeiroInvestimento: {
        isValid: v => !v || v.length <= 150
      },
      fatorExperiencia: {
        isValid(v) {
          return !v || this.fatoresExperiencia.includes(v);
        }
      },
      capacidadePoupanca: {
        descricao: {
          isValid(v) {
            return !v || this.capacidades.includes(v)
          }
        },
        estimativa: {
          isValid: v => !v || removeMoneyMask(v) >= 0
        }
      }
    },
    conjuge: {
      mesada: {
        isValid: v => typeof v === 'boolean'
      },
      despesasMensais: {
        isValid: v => typeof v !== 'undefined' && removeMoneyMask(v) >= 0
      },
      primeiroInvestimento: {
        isValid: v => !v || v.length <= 150
      },
      fatorExperiencia: {
        isValid(v) {
          return !v || this.fatoresExperiencia.includes(v);
        }
      },
      capacidadePoupanca: {
        descricao: {
          isValid(v) {
            return !v || this.capacidades.includes(v);
          }
        },
        estimativa: {
          isValid: v => !v || removeMoneyMask(v) >= 0
        }
      }
    }
  },
  computed: {
    // helpers
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    perfil() {
      return this.$store.getters.perfil;
    },
    hasConjuge() {
      return this.perfil && this.perfil.informacoesBasicas.conjuge
    },
    username() {
      if (this.perfil) {
        return this.perfil.informacoesBasicas.usuario.nome
      }

      return ''
    },
    conjugeName() {
      if (this.hasConjuge) {
        return this.perfil.informacoesBasicas.conjuge.nome
      }

      return ''
    },
    // errors messages
    usuarioMesadaErrors() {
      if (!this.$v.usuario.mesada.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.mesada.$anyError) {
        return ['Marque uma opção válida'];
      }
    },
    usuarioDespesasMensaisErrors() {
      if (!this.$v.usuario.despesasMensais.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.despesasMensais.$anyError) {
        return ['Preencha um valor válido'];
      }
    },
    usuarioPrimeiroInvestimentoErrors() {
      if (!this.$v.usuario.primeiroInvestimento.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.primeiroInvestimento.$anyError) {
        return ['Preencha uma descrição válida'];
      }
    },
    usuarioFatorExperienciaErrors() {
      if (!this.$v.usuario.fatorExperiencia.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.fatorExperiencia.$anyError) {
        return ['Selecione uma opção válida'];
      }
    },
    usuarioCapacidadePoupancaDescricaoErrors() {
      if (!this.$v.usuario.capacidadePoupanca.descricao.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.capacidadePoupanca.descricao.$anyError) {
        return ['Preencha uma descrição válida'];
      }
    },
    usuarioCapacidadePoupancaEstimativaErrors() {
      if (!this.$v.usuario.capacidadePoupanca.estimativa.$anyDirty) {
        return [];
      }

      if (this.$v.usuario.capacidadePoupanca.estimativa.$anyError) {
        return ['Preencha uma estimativa válida'];
      }
    },
    conjugeMesadaErrors() {
      if (!this.$v.conjuge.mesada.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.mesada.$anyError) {
        return ['Marque uma opção válida'];
      }
    },
    conjugeDespesasMensaisErrors() {
      if (!this.$v.conjuge.despesasMensais.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.despesasMensais.$anyError) {
        return ['Preencha um valor válido'];
      }
    },
    conjugePrimeiroInvestimentoErrors() {
      if (!this.$v.conjuge.primeiroInvestimento.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.primeiroInvestimento.$anyError) {
        return ['Preencha uma descrição válida'];
      }
    },
    conjugeFatorExperienciaErrors() {
      if (!this.$v.conjuge.fatorExperiencia.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.fatorExperiencia.$anyError) {
        return ['Selecione uma opção válida'];
      }
    },
    conjugeCapacidadePoupancaDescricaoErrors() {
      if (!this.$v.conjuge.capacidadePoupanca.descricao.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.capacidadePoupanca.descricao.$anyError) {
        return ['Preencha uma descrição válida'];
      }
    },
    conjugeCapacidadePoupancaEstimativaErrors() {
      if (!this.$v.conjuge.capacidadePoupanca.estimativa.$anyDirty) {
        return [];
      }

      if (this.$v.conjuge.capacidadePoupanca.estimativa.$anyError) {
        return ['Preencha uma estimativa válida'];
      }
    }
  },
  methods: {
    openDialog() {
      if (this.perfil) {
        this.usuario = JSON.parse(JSON.stringify(this.perfil.experienciaFinanceira.usuario))

        this.usuario.despesasMensais = this.usuario.despesasMensais.toFixed(2)
        this.usuario.capacidadePoupanca.estimativa = this.usuario.capacidadePoupanca.estimativa.toFixed(2)

        let input = document.getElementById('usuario-despesas-mensais')
        if (input) {
          input.value = this.usuario.despesasMensais
          input.dispatchEvent(new Event('input'))
        }

        input = document.getElementById('usuario-estimativa-popupanca')
        if (input) {
          input.value = this.usuario.capacidadePoupanca.estimativa
          input.dispatchEvent(new Event('input'))
        }

        if (this.hasConjuge) {
          if (this.perfil.experienciaFinanceira.conjuge) {
            this.conjuge = JSON.parse(JSON.stringify(this.perfil.experienciaFinanceira.conjuge))
            this.conjuge.despesasMensais = this.conjuge.despesasMensais.toFixed(2)
            this.conjuge.capacidadePoupanca.estimativa = this.conjuge.capacidadePoupanca.estimativa.toFixed(2)

            input = document.getElementById('conjuge-despesas-mensais')
            if (input) {
              input.value = this.conjuge.despesasMensais
              input.dispatchEvent(new Event('input'))
            }

            input = document.getElementById('conjuge-estimativa-popupanca')
            if (input) {
              input.value = this.conjuge.capacidadePoupanca.estimativa
              input.dispatchEvent(new Event('input'))
            }
          }
        }

        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.usuario = {
        mesada: false,
          despesasMensais: '',
          primeiroInvestimento: '',
          fatorExperiencia: '',
          experienciasInvestimentos: [],
          capacidadePoupanca: {
          descricao: '',
            estimativa: ''
        }
      }
      this.conjuge = {
        mesada: false,
          despesasMensais: '',
          primeiroInvestimento: '',
          fatorExperiencia: '',
          experienciasInvestimentos: [],
          capacidadePoupanca: {
          descricao: '',
            estimativa: ''
        }
      }
    },
    showDialogFatoresPerfil() {
      this.$bus.$emit('showDialogFatoresPerfil')
    },
    async submit() {
      try {
        this.$v.$touch()

        if (!this.hasConjuge) {
          this.$v.conjuge.$reset()
        }

        if (!this.$v.$anyError) {
          this.loader = true

          const usuario = JSON.parse(JSON.stringify(this.usuario))

          usuario.despesasMensais = removeMoneyMask(usuario.despesasMensais)
          usuario.capacidadePoupanca.estimativa = removeMoneyMask(usuario.capacidadePoupanca.estimativa)

          let conjuge = null

          if (this.hasConjuge) {
            conjuge = JSON.parse(JSON.stringify(this.conjuge))
            conjuge.despesasMensais = removeMoneyMask(conjuge.despesasMensais)
            conjuge.capacidadePoupanca.estimativa = removeMoneyMask(conjuge.capacidadePoupanca.estimativa)
          }

          const experienciaFinanceira = { usuario, conjuge }
          const perfil = await updatePerfil(this.perfil._id, { experienciaFinanceira })

          await this.$store.dispatch('setPerfil', perfil)

          this.$noty.success('Dados salvos com sucesso!')
          this.closeDialog()
        }
      } catch (err) {
        this.$noty.error("Desculpe, não foi possível salvar seus dados. Tente novamente mais tarde.");

        if (process.env.NODE_ENV !== "production") {
          console.error(err);
        }
      } finally {
        this.loader = false
      }
    }
  },
  created() {
    this.$bus.$on('showDialogEditExperienciaFinanceira', this.openDialog)
  }
}
</script>

<style scoped>
.v-card__title.dialog-form-title.fixed-title {
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 10;
}

.v-card__text.mobile-margin {
  padding-top: 90px !important;
}
</style>
