<template>
  <v-card
    flat
    color="darkgrey"
    class="no-radius hidden-md-and-up"
  >
    <v-card-title class="dialog-form-title safe-area-top pb-2">
      <v-btn
        icon
        @click="goBack"
        :disabled="loader"
        class="back-button"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <span class="ml-4">{{ capitalize(title) }}</span>
    </v-card-title>
  </v-card>
</template>

<script>
  import {capitalize} from 'lodash'

  export default {
    name: 'MobilePageTitle',
    props: ['title', 'loader'],
    data: () => ({
      capitalize
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      }
    },
    methods: {
      goBack() {
        window.history.back();
      }
    }
  }
</script>
