<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card dark color="#282828">
      <v-card-title>Redefinir senha</v-card-title>
      <v-card-subtitle>Envie um link de redefinição para seu e-mail</v-card-subtitle>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-text-field
            dense
            rounded
            outlined
            type="email"
            label="E-mail"
            v-model="email"
            @input="$v.email.$touch()"
            :error-messages="emailErrors"
          ></v-text-field>

          <v-card-actions class="pa-0 btn-container">
            <v-btn
              text
              rounded
              class="px-5"
              type="button"
              :disabled="loader"
              @click.stop.prevent="close"
            >Cancelar</v-btn>

            <v-spacer></v-spacer>

            <v-btn
              rounded
              depressed
              class="px-5 black--text"
              type="submit"
              color="primary"
              :loading="loader"
            >Enviar link</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/app';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'DialogForgotPassword',
  data: () => ({
    dialog: false,
    loader: false,
    email: ''
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loader = false;
      this.email = '';
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loader = true;

        firebase
          .auth()
          .sendPasswordResetEmail(this.email)
          .then(() => {
            this.$noty.success(
              'Link enviado com sucesso! Verifique sua caixa de entrada'
            );
            this.close();
          })
          .catch(err => {
            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV !== 'production') console.error(err);
            this.loader = false;
            this.$noty.error(
              'Falha ao enviar link! Verifique o e-mail digitado e tente novamente'
            );
          });
      }
    }
  },
  validations: {
    email: { required, email }
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push('E-mail é obrigatório');
      !this.$v.email.email && errors.push('E-mail inválido');
      return errors;
    }
  },
  created() {
    this.$bus.$on('showDialogForgotPassword', email => {
      if (email) this.email = email;
      this.dialog = true;
    });
  }
};
</script>

<style scoped>
@media only screen and (max-width: 400px) {
  .btn-container {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .btn-container .v-btn {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>
