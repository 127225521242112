<template>
  <v-card color="transparent" flat>
    <v-card-title class="title">
      Faturas Empresa
      <span class="primary--text">.</span>
    </v-card-title>

    <v-card-text >
      <v-container grid-list-md class="px-0">
        <v-layout row wrap>
          <v-flex xs12 sm4 md4 lg4>
            <CardTotalInvoices
              status="pending"
              icon="mdi-progress-alert"
              :company-id="user.iuguId"
            />
          </v-flex>

          <v-flex xs12 sm4 md4 lg4>
            <CardTotalInvoices
              status="paid"
              icon="mdi-progress-check"
              :company-id="user.iuguId"
            />
          </v-flex>

          <v-flex xs12 sm4 md4 lg4>
            <CardFutureInvoice
              icon="mdi-file-document-outline"
              :company-id="user._id"
            />
          </v-flex>
        </v-layout>
      </v-container>

      <v-data-table
        :items="invoices"
        :loading="loader"
        :headers="headers"
        hide-default-footer
        class="company-invoices-table"
      >
        <template v-slot:item="props" class="grey3">
          <tr>
            <td
              class="text-center"
              style="width: 90px; padding: 5px;"
              @click.stop="showDialogCompanyInvoice(props.item)"
            >
              <v-icon
                :color="invoiceStatusColor(props.item.status)"
              >
                {{ getIconByStatus(props.item.status) }}
              </v-icon>
            </td>

            <td
              class="text-center due_date"
              @click.stop="showDialogCompanyInvoice(props.item)"
            >
              {{ formatDate(props.item.created_at_iso) }}
            </td>

            <td
              class="text-center hidden-xs-only"
              @click.stop="showDialogCompanyInvoice(props.item)"
            >
              {{ formatDate(props.item.due_date) }}
            </td>

            <td
              class="text-center hidden-xs-only"
              @click.stop="showDialogCompanyInvoice(props.item)"
            >
              {{ formatDate(props.item.paid_at) }}
            </td>

            <td
              class="text-center hidden-xs-only"
              @click.stop="showDialogCompanyInvoice(props.item)"
            >
              {{ formatDate(props.item.canceled_at) }}
            </td>

            <td
              class="text-center value"
              @click.stop="showDialogCompanyInvoice(props.item)"
            >
              {{ props.item.total }}
            </td>

            <td
              style="width: 180px"
              class="text-center hidden-sm-and-down"
            >
              <v-btn
                x-small
                rounded
                depressed
                color="primary"
                class="darkgrey--text px-5"
                @click.stop="showDialogCompanyInvoice(props.item)"
              >
                Detalhes
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div class="my-5 table-caption">
        <div class="d-flex align-center pb-1">
          <v-icon small color="success" class="mr-2">mdi-progress-check</v-icon>
          <span class="grey1--text input-label">Pagamento confirmado</span>
        </div>

        <div class="d-flex align-center pb-1">
          <v-icon small color="warning" class="mr-2">mdi-progress-alert</v-icon>
          <span class="grey1--text input-label">Pagamento pendente</span>
        </div>

        <div class="d-flex align-center pb-1">
          <v-icon small color="error" class="mr-2">mdi-progress-close</v-icon>
          <span class="grey1--text input-label">Fatura cancelada</span>
        </div>
      </div>

      <div class="mt-5">
        <v-pagination
          circle
          color="primary"
          v-model="pagination.page"
          :length="pagination.totalPages"
        ></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import getIuguCustomerInvoices from '@/api/iugu/getIuguCustomerInvoices';
import numberToCurrency from '@/utils/numberToCurrency';
import CardTotalInvoices from '@/components/Company/cards/CardTotalInvoices';
import CardFutureInvoice from '@/components/Company/cards/CardFutureInvoice';

export default {
  name: 'TableCompanyInvoices',
  components: { CardFutureInvoice, CardTotalInvoices },
  data: () => ({
    loader: false,
    invoices: [],
    status: '',
    pagination: {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      itemsPerPage: 10
    },
    headers: [
      {
        text: 'Status',
        value: 'status',
        align: 'center',
        sortable: false
      },
      {
        text: 'Criada em',
        value: 'created_at',
        align: 'center',
        sortable: false
      },
      {
        text: 'Vence em',
        value: 'due_date',
        align: 'center',
        sortable: false,
        class: 'hidden-xs-only'
      },
      {
        text: 'Paga em',
        value: 'paid_at',
        align: 'center',
        sortable: false,
        class: 'hidden-xs-only'
      },
      {
        text: 'Cancelada em',
        value: 'canceled_at',
        align: 'center',
        sortable: false,
        class: 'hidden-xs-only'
      },
      {
        text: 'Valor total',
        value: 'paid_cents',
        align: 'center',
        sortable: false
      },
      {
        text: '',
        value: 'action',
        align: 'center',
        sortable: false,
        class: 'hidden-sm-and-down'
      }
    ]
  }),
  computed: {
    isCompany() {
      return this.$store.getters.user && this.$store.getters.user.role === 'company'
    },
    user() {
      return this.isCompany ? this.$store.getters.user : this.$store.getters.company
    },
  },
  methods: {
    getInvoices() {
      this.loader = true

      getIuguCustomerInvoices(this.user.iuguId, this.pagination.page, this.status)
        .then(data => {
          this.invoices = data.items
          this.pagination.totalItems = data.totalItems
          this.pagination.totalPages = data.totalItems ? Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage) : 0
          this.loader = false
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }

          this.loader = false
        })
    },
    invoiceStatusName(status) {
      return status === 'paid' ? 'Paga' : 'Pendente'
    },
    invoiceStatusColor(status) {
      switch (status) {
        case 'canceled':
          return 'error';
        case 'paid':
          return 'success';
        default:
          return 'warning'
      }
    },
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY')
      }
      return ''
    },
    showDialogCompanyInvoice(invoice) {
      this.$bus.$emit('showDialogCompanyInvoice', invoice)
    },
    getIconByStatus(status) {
      switch (status) {
        case 'canceled':
          return 'mdi-progress-close';
        case 'paid':
          return 'mdi-progress-check';
        default:
          return 'mdi-progress-alert'
      }
    }
  },
  watch: {
    'pagination.page'() {
      this.getInvoices()
    }
  },
  created() {
    this.getInvoices()
  }
}
</script>

<style scoped>
.theme--dark.v-data-table {
  background-color: #2D2D2D !important;
}

.v-card__title.title {
  font-size: 30px !important;
  font-weight: bold;
  letter-spacing: .02em !important;
}

tr {
  cursor: pointer;
}

td.name-column {
  font-size: 11px !important;
  letter-spacing: .1em;
}

.table-caption {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.due_date,
.value {
  font-size: 10px !important;
}

@media only screen and (min-width: 375px) {
  td.name-column {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 600px) {
  .table-caption {
    flex-direction: row;
  }

  .table-caption > div {
    margin-right: 15px;
  }

  .due_date,
  .value {
    font-size: 14px !important;
  }
}
</style>
