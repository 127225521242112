import httpClient from '@/plugins/httpClient';
import config from '@/config';

const createCalendlyWebhook = token => {
  return new Promise((resolve, reject) => {
    try {
      const options = {
        method: 'POST',
        data: {
          url: config.calendly.listener,
          events: ['invitee.created', 'invitee.canceled']
        },
        headers: {
          'X-TOKEN': token
        }
      }

      httpClient(`${config.calendly.api}/hooks`, options)
        .then(resp => resolve(resp.data))
        .catch(reject)
    } catch(err) {
      reject(err)
    }
  })
}

export default createCalendlyWebhook
