<template>
  <div class="uplanner-slider" v-dragscroll>
    <CardCreditCard
      :card="card"
      :key="card._id"
      v-for="card in orderedCreditCards"
    />

    <v-card class="uplanner-slider-item" color="transparent" flat>
      <v-card-text class="d-flex flex-column align-start justify-start">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              v-on="on"
              class="mb-2"
              color="grey1"
              :disabled="!allowed"
              @click="showForm(null)"
            >
              <v-icon>mdi-credit-card-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar cartão</span>
        </v-tooltip>

        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              v-on="on"
              color="grey1"
              id="btn-list"
              @click="showDialogCreditCardsList"
            >
              <v-icon>mdi-credit-card-multiple</v-icon>
            </v-btn>
          </template>
          <span>Ver cartões</span>
        </v-tooltip>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import CardCreditCard from '@/components/Customer/Cashflow/cards/CardCreditCard';
import currency from '@/utils/currency';

export default {
  name: 'CardCreditCards',
  components: { CardCreditCard },
  data: () => ({
    currency
  }),
  computed: {
    allowed() {
      return this.$store.getters.allowed
    },
    creditCards() {
      return this.$store.getters.creditCards
    },
    orderedCreditCards() {
      return orderBy(this.creditCards.filter(cc => !cc.archived), cc => cc.name)
    },
    isCustomer() {
      return (!!this.$store.getters.user && this.$store.getters.user.role === 'customer')
    }
  },
  methods: {
    showDialogCreditCardsList() {
      this.$bus.$emit('showDialogCreditCardsList')
    },
    showForm(card) {
      if (this.allowed) {
        if (card) {
          this.$bus.$emit('showDialogFormCreditCard', card);
        } else {
          if (this.isCustomer) {
            this.$bus.$emit('showDialogAddAccount', {type: 'credit-card'});
          } else {
            this.$bus.$emit('showDialogFormCreditCard');
          }
        }
      }
    }
  }
}
</script>
