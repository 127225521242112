<template>
  <v-dialog
    fullscreen
    persistent
    v-model="dialog"
  >
    <v-card>
      <v-card-title
        class="dialog-form-title"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          class="mr-2"
          color="white"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="white--text">Cartões Pendentes</span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-container grid-list-md>
          <v-flex xs12>
            <h1 class="primary--text mb-2">Cartões pendentes</h1>
            <p class="white--text">
              Não temos dados suficientes para sincronizar alguns de seus cartões. Por favor,
              preencha os dados pendentes para que possamos continuar com sua sincronização!
            </p>
          </v-flex>
        </v-container>

        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex
              xs12 md6
              v-for="card in pendentCreditCards"
              :key="card._id"
            >
              <v-card
                :color="card.color"
                :dark="isDark(card.color)"
                :light="!isDark(card.color)"
              >
                <v-card-title>{{ card.name }}</v-card-title>
                <v-card-subtitle>{{ card.bank }}</v-card-subtitle>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    small
                    rounded
                    depressed
                    color="darkgrey"
                    class="px-5 text-capitalize"
                    @click="showFormPendentCreditCard(card)"
                  >
                    Preencher
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>

    <DialogFormPendentCreditCard/>
  </v-dialog>
</template>

<script>
import DialogFormPendentCreditCard from "@/components/Customer/OpenBanking/dialogs/DialogFormPendentCreditCard";
const tinycolor = require('tinycolor2')

export default {
  name: 'DialogPendentCreditCards',
  components: {DialogFormPendentCreditCard},
  data: () => ({
    dialog: false
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isMobile
    },
    pendentCreditCards() {
      return this.$store.getters.pendentCreditCards
    },
  },
  methods: {
    openDialog() {
      if (this.pendentCreditCards.length) {
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
    },
    showFormPendentCreditCard(pendentCreditCard) {
      this.$bus.$emit('showDialogFormPendentCreditCard', pendentCreditCard)
    },
    isDark(color) {
      return tinycolor(color).isDark()
    }
  },
  watch: {
    pendentCreditCards(oldVal) {
      if (!this.pendentCreditCards.length && oldVal.length) {
        this.closeDialog()
        this.$noty.success('Você não possui mais cartões pendentes!')
      }
    }
  },
  created() {
    this.$bus.$on('showDialogPendentCreditCards', this.openDialog)
  }
}
</script>

<style scoped>
.container {
  max-width: 768px;
}
</style>
