import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const changeUserCancelStatus = (userId, canceled) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const url = `/users/${userId}/canceled?canceled=${canceled}`
          const options = {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }

          return httpClient(url, options)
        })
        .then(() => resolve())
        .catch(reject)
    } catch (err) {
      reject(err)
    }
  })
}

export default changeUserCancelStatus
