<template>
  <v-main>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <img
            class="logo"
            alt="UPlanner 2.0"
            src="../assets/logo.png"
          >
        </v-flex>

        <v-flex xs12 class="text-center my-10">
          <h1>É hora de criar sua senha<span class="primary--text">!</span></h1>
        </v-flex>

        <v-flex xs12>
          <FormCreatePassword :email="email"/>
        </v-flex>

        <v-flex xs12>
          <div class="link-container text-center mt-3">
            <router-link
              to="/politica-privacidade"
              class="primary--text"
            >
              Politica de privacidade
            </router-link>
          </div>

          <div class="link-container text-center mt-3">
            <router-link
              to="/termos-uso"
              class="primary--text"
            >
              Termo de adesão
            </router-link>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import FormCreatePassword from '@/components/Customer/CreatePassword/forms/FormCreatePassword';
import validateUser from '@/api/users/validateUser';

export default {
  name: 'CreatePassword',
  components: { FormCreatePassword },
  data: () => ({
    email: ''
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
  methods: {
    goToLogin() {
      if (this.isMobile) {
        this.$router.push('/auth')
      } else {
        this.$router.push('/login')
      }
    },
    checkQuery() {
      let query = this.$route.query;

      if (query.email && query.code) {
        this.email = query.email
        this.code = query.code
        this.validateUser()
      } else {
        this.goToLogin()
      }
    },
    validateUser() {
      let email = this.email.toLowerCase().trim()

      validateUser(email, this.code)
        .then(resp => {
          if (!resp.valid) {
            this.goToLogin()
          }
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }

          this.goToLogin()
        })
    }
  },
  created() {
    this.checkQuery()
  }
}
</script>

<style scoped>
.logo {
  display: block;
  width: 80px;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 24px;
  line-height: 1.1em;
}

.link-container {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .1em;
}

.container {
  max-width: 400px;
}
</style>
