<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    max-width="960px"
    :fullscreen="isMobile"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey2">
      <v-card-title
        class="dialog-form-title"
        style="border-radius: 0 !important"
        :class="isIOS ? 'ios-padding' : ''"
      >
        <v-btn
          icon
          color="warning"
          @click.stop="close"
          class="back-button"
          :disabled="loader || loaderOther"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="warning--text ml-4">
          {{ transaction._id ? 'Editar' : 'Nova' }} Despesa de Cartão
        </span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit(false)">
          <v-container class="px-0 pt-5" grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <span class="input-label primary--text">Valor</span>
                <v-text-field
                  type="tel"
                  color="grey1"
                  v-money="money"
                  class="mt-0 pt-0"
                  v-model="transaction.value"
                  id="input--invoice-expense-value"
                  :error-messages="valueErrors"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-cash
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <span class="input-label primary--text">Data de compra</span>
                <v-dialog
                  ref="dialog"
                  v-model="dateMenu"
                  :return-value.sync="transaction.date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 pt-0"
                      :value="formatDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        color="grey1"
                        class="input-icon"
                        slot="prepend-inner"
                      >
                        mdi-calendar
                      </v-icon>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    no-title
                    color="primary"
                    v-model="transaction.date"
                    scrollable
                    locale="pt-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey2" @click="dateMenu = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.dialog.save(transaction.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12 md4>
                <span class="input-label primary--text">Cartão</span>
                <v-select
                  color="grey1"
                  return-object
                  item-text="name"
                  item-value="_id"
                  class="mt-0 pt-0"
                  menu-props="auto"
                  :items="activeCreditCards"
                  v-model="transaction.creditCard"
                  :error-messages="creditCardErrors"
                  @change="$v.transaction.creditCard.$touch()"
                  :disabled="
                    !!transaction._id &&
                    (!!transaction.fixedId || !!transaction.dividedId)
                  "
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-credit-card
                  </v-icon>

                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color"
                    >mdi-bank
                    </v-icon
                    >
                    <span>{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12 md4>
                <span class="input-label primary--text">Fatura</span>
                <v-select
                  color="grey1"
                  item-text="desc"
                  item-value="period"
                  class="mt-0 pt-0"
                  menu-props="auto"
                  :items="invoicesDates"
                  v-model="transaction.invoice"
                  :error-messages="invoiceErrors"
                  @change="$v.transaction.invoice.$touch()"
                  :disabled="
                    !!transaction._id &&
                    (!!transaction.fixedId || !!transaction.dividedId)
                  "
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-credit-card
                  </v-icon>
                </v-select>
              </v-flex>

              <v-flex xs12 md4>
                <span class="input-label primary--text">Categoria</span>
                <v-select
                  color="grey1"
                  return-object
                  item-text="name"
                  class="mt-0 pt-0"
                  menu-props="auto"
                  :items="categories"
                  v-model="transaction.category"
                  :error-messages="categoryErrors"
                  @change="$v.transaction.category.$touch()"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-bookmark-outline
                  </v-icon>

                  <template v-slot:item="{ item, index }">
                    <v-icon small class="mr-2" :color="item.color">{{
                        item.icon
                      }}
                    </v-icon>
                    <span>{{ item.name }}</span>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip small outlined :color="item.color">
                      <v-icon small class="mr-2">{{ item.icon }}</v-icon>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>

              <v-flex
                xs12
                md4
                v-if="
                  transaction.category &&
                  !transaction.category._id &&
                  transaction.category.name === 'Outro'
                "
              >
                <span class="input-label primary--text">Nome da categoria</span>
                <v-text-field
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="otherCategory"
                  @input="$v.otherCategory.$touch()"
                  :error-messages="otherCategoryErrors"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-bookmark-outline
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <span class="input-label primary--text">Sub-categoria</span>
                <v-select
                  color="grey1"
                  return-object
                  item-text="name"
                  class="mt-0 pt-0"
                  menu-props="auto"
                  :items="subCategories"
                  v-model="transaction.subCategory"
                  :error-messages="subCategoryErrors"
                  @change="$v.transaction.subCategory.$touch()"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-bookmark-multiple-outline
                  </v-icon>

                  <template v-slot:item="{ item, index }">
                    <span>{{ item.name }}</span>
                    <v-icon small class="ml-2" v-if="item.fixed" color="error"
                    >mdi-pin
                    </v-icon
                    >
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <span>{{ item.name }}</span>
                    <v-icon small class="ml-2" v-if="item.fixed"
                    >mdi-pin
                    </v-icon
                    >
                  </template>
                </v-select>
              </v-flex>

              <v-flex
                xs12
                md4
                v-if="
                  transaction.subCategory &&
                  transaction.subCategory.name === 'Outro'
                "
              >
                <span class="input-label primary--text"
                >Nome da sub-categoria</span
                >
                <v-text-field
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="otherSubcategory"
                  @input="$v.otherSubcategory.$touch()"
                  :error-messages="otherSubCategoryErrors"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-bookmark-multiple-outline
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Observação</span>
                <v-text-field
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="transaction.obs"
                >
                  <v-icon color="grey1" class="input-icon" slot="prepend-inner">
                    mdi-clipboard-text
                  </v-icon>
                </v-text-field>
              </v-flex>

              <v-flex xs6 md3 offset-md4 v-if=" !transaction.fixedId && !transaction.dividedId">
                <v-checkbox
                  label="Recorrente"
                  color="primary"
                  v-model="transaction.fixed"
                ></v-checkbox>
              </v-flex>

              <v-flex xs6 md3 v-if=" !transaction.fixedId && !transaction.dividedId">
                <v-checkbox
                  color="primary"
                  label="Parcelar"
                  :disabled="transaction.fixed"
                  v-model="transaction.divided"
                ></v-checkbox>
              </v-flex>

              <v-slide-x-transition>
                <v-flex
                  xs12
                  md4
                  offset-md4
                  v-if="transaction.divided && !transaction.fixedId && !transaction.dividedId"
                >
                  <span class="input-label primary--text">Parcelas</span>
                  <v-select
                    color="grey1"
                    class="mt-0 pt-0"
                    item-text="text"
                    :items="parcelas"
                    menu-props="auto"
                    item-value="value"
                    v-model="transaction.dividedParts"
                  >
                    <v-icon
                      color="grey1"
                      class="input-icon"
                      slot="prepend-inner"
                    >
                      mdi-format-list-numbered
                    </v-icon>
                  </v-select>
                </v-flex>
              </v-slide-x-transition>

              <v-flex
                xs12
                class="d-flex flex-column repetition-desc"
                v-if="transaction.dividedId || transaction.fixedId"
              >
                <span class="grey1--text">
                  {{
                    transaction.fixed
                      ? 'Recorrente'
                      : transaction.repeat
                      ? 'Repetida'
                      : transaction.divided
                        ? 'Parcelada'
                        : ''
                  }}
                </span>
                <span class="grey1--text" v-if="transaction.dividedId || transaction.fixedId">
                  {{
                    formatIntervalType(
                      transaction.intervalType,
                      transaction.interval
                    )
                  }}
                </span>
                <span class="grey1--text" v-if="transaction.dividedId">
                  Parcela {{ transaction.dividedPart }}/{{
                    transaction.dividedParts
                  }}
                </span>
              </v-flex>
            </v-layout>
          </v-container>

          <div class="btn-container">
            <v-btn
              rounded
              type="submit"
              color="primary"
              :loading="loader"
              :disabled="loaderOther"
              class="px-5 darkgrey--text"
            >
              Salvar {{ !transaction._id ? 'e fechar' : '' }}
            </v-btn>

            <v-btn
              rounded
              outlined
              class="px-5"
              type="button"
              color="primary"
              :disabled="loader"
              v-if="!transaction._id"
              :loading="loaderOther"
              @click="submit(true)"
            >
              Salvar e adicionar outro
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import removeMoneyMask from '../../../../utils/removeMoneyMask';
import stringCapitalize from '../../../../utils/stringCapitalize';
import saveCategoryUser from '../../../../api/categoriesUser/saveCategoryUser';

const ObjectID = require('bson-objectid');
import { VMoney } from 'v-money';
import randomHexcolor from 'random-hex-color';
import { orderBy } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import numberToCurrency from '../../../../utils/numberToCurrency';
import saveCreditCardTransaction from '../../../../api/transactions/saveCreditCardTransaction';
import getInvoiceById from "@/api/invoices/getInvoiceById";

const isZero = (v) => removeMoneyMask(v) > 0;

export default {
  name: 'DialogFormCreditCardExpense',
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    loader: false,
    openedInvoice: null,
    transaction: {
      obs: '',
      date: '',
      user: '',
      value: 0,
      category: null,
      subCategory: '',
      fixed: false,
      repeat: false,
      periodsInterval: 1,
      periodsType: 'months',
      consolidated: false,
      repeatNumber: '',
      divided: false,
      dividedParts: 2,
      creditCard: '',
      invoice: ''
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2
    },
    dateMenu: false,
    periodsTypes: [
      { text: 'Mensal', value: 'months' },
      { text: 'Semanal', value: 'weeks' },
      { text: 'Anual', value: 'years' }
    ],
    otherCategory: '',
    otherSubcategory: '',
    currentDate: null,
    loaderOther: false
  }),
  methods: {
    formatIntervalType(intervalType, interval) {
      let str = `Uma vez a cada ${interval} `;

      if (intervalType === 'months') {
        str += interval > 1 ? 'meses' : 'mês';
      } else if (intervalType === 'weeks') {
        str += interval > 1 ? 'semanas' : 'semana';
      } else if (intervalType === 'year') {
        str += interval > 1 ? 'anos' : 'ano';
      }

      return str;
    },
    reset(saveOther) {
      this.dateMenu = false;
      this.otherCategory = '';
      this.otherSubcategory = '';

      if (!saveOther) {
        this.transaction = {
          obs: '',
          date: dayjs().format('YYYY-MM-DD'),
          user: '',
          value: 0,
          category: null,
          subCategory: '',
          fixed: false,
          repeat: false,
          periodsInterval: 1,
          periodsType: 'months',
          consolidated: false,
          repeatNumber: '',
          divided: false,
          dividedParts: 2,
          creditCard: '',
          invoice: ''
        };
      } else {
        this.transaction.fixed = false;
        this.transaction.repeat = false;
        this.transaction.divided = false;
        this.transaction.value = 0;
        this.transaction.obs = '';
      }

      this.resetMoneyInput();
      this.$v.$reset();
    },
    close() {
      this.dialog = false;
      this.loader = false;
      this.loaderOther = false;
      this.openedInvoice = null
      this.reset(false);
    },
    resetMoneyInput() {
      let input = document.getElementById('input--invoice-expense-value');

      if (input) {
        input.value = this.transaction.value;
        input.dispatchEvent(new Event('input'));
        input.scrollIntoView();
      }
    },
    async submit(saveOther) {
      try {
        this.$v.$touch();

        if (
          this.transaction.category &&
          this.transaction.category.name !== 'Outro'
        ) {
          this.$v.otherCategory.$reset();
        }

        if (
          this.transaction.subCategory &&
          this.transaction.subCategory.name !== 'Outro'
        ) {
          this.$v.otherSubcategory.$reset();
        }

        if (
          !this.$v.transaction.$anyError &&
          !this.$v.otherCategory.$anyError &&
          !this.$v.otherSubcategory.$anyError
        ) {
          this.loader = saveOther !== true;
          this.loaderOther = saveOther === true;

          let transaction = JSON.parse(JSON.stringify(this.transaction));

          transaction.type = 'saida';
          transaction.user = this.user._id;
          transaction.creditCard = transaction.creditCard._id;
          transaction.value = removeMoneyMask(transaction.value);

          if (transaction.fixed || transaction.divided || transaction.repeat) {
            transaction.interval = this.transaction.periodsInterval;
            transaction.intervalType = this.transaction.periodsType;
          }

          let category = transaction.category;
          let subCategory = transaction.subCategory;

          if (category.name === 'Outro') {
            category = {
              subs: [],
              update: true,
              type: 'despesa',
              user: this.user._id,
              _id: ObjectID().str,
              color: randomHexcolor(),
              icon: 'mdi-dots-horizontal',
              name: stringCapitalize(this.otherCategory)
            };

            if (subCategory) {
              subCategory = {
                fixed: false,
                _id: ObjectID().str,
                category: category._id,
                name: stringCapitalize(this.otherSubcategory)
              };

              category.subs.push(subCategory);
            }
          } else if (subCategory && subCategory.name === 'Outro') {
            subCategory = {
              fixed: false,
              _id: ObjectID().str,
              category: category._id,
              name: stringCapitalize(this.otherSubcategory)
            };

            category.update = true;
            category.subs.push(subCategory);
          }

          if (!category.user) {
            category.update = true;
            category.user = this.user._id;
          }

          if (category.update) {
            category = await saveCategoryUser(category);
            await this.$store.dispatch('saveCategory', category);
          }

          transaction.category = category._id;

          if (transaction.subCategory) {
            transaction.subCategory = subCategory._id;
          }

          if (transaction._id && (transaction.fixedId || transaction.dividedId)) {
            this.$bus.$emit('showDialogUpdateMultipleTransaction', {
              transaction,
              openedInvoice: this.openedInvoice
            });
            this.loader = false;
            this.loaderOther = false;
          } else {
            const resp = await saveCreditCardTransaction(transaction, null, this.openedInvoice);

            if (resp.creditCard)
              await this.$store.dispatch('updateCreditCardLimit', resp.creditCard);

            if (resp.creditCards) {
              resp.creditCards.forEach((cc) => {
                this.$store.dispatch('updateCreditCardLimit', cc);
              });
            }

            if (resp.invoice) {
              await this.$store.dispatch('updateInvoiceValue', resp.invoice);
            }

            if (resp.invoices) {
              resp.invoices.forEach((inv) => {
                this.$store.dispatch('updateInvoiceValue', inv);
              });
            }

            if (resp.transaction) {
              resp.transaction.category = (category
                ? category
                : transaction.category);

              resp.transaction.subCategory = (subCategory
                ? subCategory
                : transaction.subCategory);

              resp.transaction.invoice = resp.invoice
              resp.transaction.creditCard = resp.creditCard

              await this.$store.dispatch('saveTransaction', resp.transaction);
            }

            if (resp.transactions) {
              resp.transactions.forEach((t) => {
                t.category = category ? category : t.category;
                t.subCategory = subCategory ? subCategory : t.subCategory;

                t.invoice = resp.invoices.find(inv => inv._id === t.invoice)
                t.creditCard = resp.creditCards.find(cc => cc._id === t.creditCard)

                this.$store.dispatch('saveTransaction', t);
              });
            }

            if (resp.transaction && resp.transaction.consolidated) {
              if (this.$route.name === 'Orcamento') {
                this.$bus.$emit('updateOrcamento');
              } else if (this.$route.name === 'Relatorios') {
                this.$bus.$emit('updateRelatorio');
              }
            }

            this.loader = false;
            this.loaderOther = false;

            if (resp.isPaid) {
              this.$noty.warning(
                'Você não pode inserir despesas em faturas pagas!'
              );
            } else {
              this.$noty.success('Despesa salva com sucesso!');

              if (saveOther === true) {
                this.reset(true);
              } else {
                this.close();
              }
            }
          }
        }
      } catch (err) {
        this.loader = false;
        this.loaderOther = false;

        this.$noty.error('Desculpe! Não foi possível salvar esta despesa. Tente novamente!');

        if (process.env.NODE_ENV !== 'production') {
          console.error(err);
        }
      }
    },
    selectInvoice() {
      let [year, month, day] = this.transaction.date.split('-');
      const close = this.transaction.creditCard.close;
      const pay = this.transaction.creditCard.pay;

      if (day > close) {
        month = parseInt(month) + 1;
        if (month < 10) {
          month = `0${month}`;
        } else if (month > 12) {
          month = 1;
          year = parseInt(year) + 1;
        }
      }

      if (close > pay) {
        month = parseInt(month) + 1;
        if (month < 10) {
          month = `0${month}`;
        } else if (month > 12) {
          month = 1;
          year = parseInt(year) + 1;
        }
      }

      if (month < 10 && typeof month === 'number') {
        month = `0${month}`;
      }

      this.transaction.invoice = `${year}-${month}`;
    },
    requestOpenedInvoice() {
      this.$bus.$emit('requestOpenedInvoice')
    },
    getOpenedInvoice(openedInvoice) {
      if (openedInvoice) {
        this.openedInvoice = openedInvoice
      }
    }
  },
  computed: {
    isIOS() {
      return this.$store.getters.isIOS;
    },
    isCustomer() {
      return (
        this.$store.getters.user && this.$store.getters.user.role === `customer`
      );
    },
    user() {
      return this.isCustomer
        ? this.$store.getters.user
        : this.$store.getters.customer;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    creditCards() {
      return this.$store.getters.creditCards;
    },
    activeCreditCards() {
      return orderBy(
        this.creditCards.filter((cc) => !cc.archived && !cc.pluggy),
        cc => cc._id && cc.name && cc.flag
      )
    },
    months() {
      return this.$store.getters.months;
    },
    invoices() {
      return this.$store.getters.invoices;
    },
    invoicesDates() {
      let invoices = [];

      if (this.transaction.creditCard) {
        let currentYear = this.currentDate.year();
        let currentDay = this.currentDate.date();
        let currentMonth = this.currentDate.month() + 1;

        if (
          this.transaction.creditCard.close > this.transaction.creditCard.pay ||
          currentDay > this.transaction.creditCard.close
        ) {
          currentMonth++;
          if (currentMonth > 12) {
            currentYear++;
            currentMonth = 1;
          }
        }

        let month = currentMonth;
        if (month < 10) month = `0${month}`;

        currentMonth -= 12;
        if (currentMonth <= 0) {
          currentYear--;
          currentMonth = 12 + currentMonth;
        }

        for (let i = 1; i < 19; i++) {
          let monthData = this.months.find((m) => m.numero === currentMonth);

          if (currentMonth < 10) currentMonth = `0${currentMonth}`;

          invoices.push({
            period: `${currentYear}-${currentMonth}`,
            desc: `${monthData.sigla} de ${currentYear}`
          });

          currentMonth = parseInt(currentMonth) + 1;
          if (currentMonth > 12) {
            currentYear++;
            currentMonth = 1;
          }
        }
      }

      return invoices;
    },
    formatDate() {
      if (this.transaction.date)
        return this.transaction.date.split('-').reverse().join('/');

      return '';
    },
    categories() {
      let categories = orderBy(
        this.$store.getters.categories.filter((cat) => {
          return !cat.archived && cat.type === 'despesa';
        }),
        'name'
      );

      categories.push({
        name: 'Outro',
        color: '#D1AC68',
        icon: 'mdi-dots-horizontal',
        subs: []
      });

      return categories;
    },
    subCategories() {
      let subs = [];

      if (this.transaction.category) {
        subs = orderBy(
          this.transaction.category.subs.filter((s) => !s.archived),
          'name'
        );
      }

      subs.push({ name: 'Outro' });
      return subs;
    },
    parcelas() {
      let parcelas = [];
      let value = removeMoneyMask(this.transaction.value);

      for (let i = 2; i < 120; i++) {
        parcelas.push({
          value: i,
          text: `${i}x de ${numberToCurrency(value)}`
        });
      }

      return parcelas;
    },
    valueErrors() {
      const errors = [];
      if (!this.$v.transaction.value.$dirty) return errors;
      !this.$v.transaction.value.isZero &&
      errors.push('Insira um valor maior do que zero');
      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.transaction.date.$dirty) return errors;
      !this.$v.transaction.date.required && errors.push('Selecione uma data');
      return errors;
    },
    categoryErrors() {
      const errors = [];
      if (!this.$v.transaction.category.$dirty) return errors;
      !this.$v.transaction.category.required &&
      errors.push('Selecione uma categoria');
      return errors;
    },
    subCategoryErrors() {
      const errors = [];
      if (!this.$v.transaction.subCategory.$dirty) return errors;
      !this.$v.transaction.subCategory.required &&
      errors.push('Selecione uma sub-categoria');
      return errors;
    },
    creditCardErrors() {
      const errors = [];
      if (!this.$v.transaction.creditCard.$dirty) return errors;
      !this.$v.transaction.creditCard.required &&
      errors.push('Selecione o cartão');
      return errors;
    },
    invoiceErrors() {
      const errors = [];
      if (!this.$v.transaction.invoice.$dirty) return errors;
      !this.$v.transaction.invoice.required &&
      errors.push('Selecione a fatura');
      return errors;
    },
    otherCategoryErrors() {
      const errors = [];
      if (!this.$v.otherCategory.$dirty) return errors;
      !this.$v.otherCategory.required &&
      errors.push('Digite o nome da categoria');
      return errors;
    },
    otherSubCategoryErrors() {
      const errors = [];
      if (!this.$v.otherSubcategory.$dirty) return errors;
      !this.$v.otherSubcategory.required &&
      errors.push('Digite o nome da sub-categoria');
      return errors;
    }
  },
  watch: {
    'transaction.category'() {
      if (
        this.transaction.category &&
        this.transaction.category.name === 'Outro'
      ) {
        this.transaction.subCategory = this.subCategories[
        this.subCategories.length - 1
          ];
      }
    },
    'transaction.subCategory'() {
      if (
        !this.transaction._id &&
        this.transaction.subCategory &&
        this.transaction.subCategory.fixed
      ) {
        this.transaction.fixed = true;
      }
    },
    'transaction.date'(newValue, oldValue) {
      if (
        oldValue &&
        /^\d{4}-\d{2}-\d{2}$/gm.test(newValue) &&
        this.transaction.creditCard &&
        !this.transaction._id
      ) {
        this.selectInvoice();
      }
    },
    'transaction.creditCard'(newValue) {
      if (newValue && !this.transaction._id) {
        this.selectInvoice();
      }
    },
    'transaction.fixed'() {
      this.transaction.intervalType = 'months';
      this.transaction.interval = 1;
    },
    'transaction.divided'() {
      this.transaction.intervalType = 'months';
      this.transaction.interval = 1;
    },
    'transaction.repeat'() {
      this.transaction.intervalType = 'months';
      this.transaction.interval = 1;
    }
  },
  validations: {
    transaction: {
      value: {
        isZero(v) {
          return removeMoneyMask(v) > 0;
        }
      },
      date: {
        required
      },
      category: { required },
      subCategory: { required },
      creditCard: { required },
      invoice: { required }
    },
    otherCategory: { required },
    otherSubcategory: { required }
  },
  created() {
    this.$bus.$on('showDialogFormCreditCardExpense', (transaction) => {
      this.requestOpenedInvoice()

      if (transaction) {

        this.transaction = JSON.parse(JSON.stringify(transaction));

        if (!this.transaction.creditCard._id) {
          this.transaction.creditCard = this.activeCreditCards.find((cc) => {
            return cc._id === this.transaction.creditCard;
          });
        }

        if (!this.transaction.invoice._id) {
          this.transaction.invoice = this.invoices.find(i => {
            return i._id === this.transaction.invoice || i._id === this.transaction.invoice._id;
          });
        }

        this.transaction.value = this.transaction.value.toFixed(2);
        this.resetMoneyInput(false);

        this.transaction.category = this.categories.find((cat) => {
          return (
            cat.type === 'despesa' &&
            cat.name === this.transaction.category.name
          );
        });

        if (this.transaction.invoice.paymentDate) {
          this.transaction.invoice = this.transaction.invoice.paymentDate.slice(0, 7);
        }
      } else {
        if (this.creditCards.length === 1) {
          this.transaction.creditCard = this.creditCards[0];
        }

        this.transaction.date = dayjs().format('YYYY-MM-DD');
      }

      this.currentDate = dayjs();
      this.dialog = true;
    });

    this.$bus.$on('closeDialogForm', () => {
      this.close();
    });

    this.currentDate = dayjs();

    this.$bus.$on('getOpenedInvoice', this.getOpenedInvoice)
  }
};
</script>

<style scoped>
.repetition-desc {
  font-size: 12px !important;
  letter-spacing: 0.1em;
  line-height: 1.5em;
}

.btn-container {
  display: flex;
  flex-direction: column;
}

.btn-container .v-btn {
  margin-bottom: 5px;
}

.v-btn.v-size--default {
  font-size: 11px !important;
}

@media only screen and (min-width: 600px) {
  .btn-container {
    flex-direction: row;
    justify-content: center;
  }

  .btn-container .v-btn {
    margin: 0 5px;
    display: block;
    width: 200px;
  }
}
</style>
