<template>
  <v-system-bar color="darkgrey" dark>
    <v-icon color="primary">mdi-account</v-icon>
    <span class="user-name primary--text">
      {{ userName }}
      <span v-if="planName"> - {{ planName }}</span>
      <span v-if="isDivided"> - Parcelado</span>
    </span>
  </v-system-bar>
</template>

<script>
  import iuguSubscriptionIsDivided from "@/api/iugu/subscriptions/iuguSubscriptionIsDivided";

  export default {
    name: 'SystemBar',
    props: ['userName', 'planName', 'subscriptionId'],
    data: () => ({
      isDivided: false
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      }
    },
    methods: {
      async checkSubscriptionIsDivided() {
        try {
          if (this.subscriptionId) {
            const data = await iuguSubscriptionIsDivided(this.subscriptionId)
            this.isDivided = data.isDivided
          }
        } catch (err) {
          this.isDivided = false

          if (process.env.NODE_ENV !== 'production') {
            console.error(err)
          }
        }
      }
    },
    created() {
      this.checkSubscriptionIsDivided()
    }
  }
</script>

<style scoped>
  .user-name {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .1em;
  }
</style>
