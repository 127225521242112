<template>
  <v-content>
    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-tabs
            centered
            v-model="tab"
            :grow="isMobile"
            background-color="darkgrey"
          >
            <v-tab href="#tab-0" class="success--text">Receita</v-tab>
            <v-tab href="#tab-1" class="error--text">Despesa</v-tab>
            <v-tab href="#tab-2" class="white--text">Arquivadas</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-0">
              <ListCategories type="receita"/>
            </v-tab-item>

            <v-tab-item value="tab-1">
              <ListCategories type="despesa"/>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <ListCategories type="archived"/>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>

      <v-btn
        fab
        :small="isMobile"
        fixed
        right
        bottom
        color="primary"
        :disabled="!allowed"
        @click="showDialogFormCategory"
        class="grey3--text btn-add-category"
      >
        <v-icon :large="!isMobile">mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </v-content>
</template>

<script>
  import ListCategories from '../lists/ListCategories';
  import getCategoriesUser from '@/api/categoriesUser/getCategoriesUser';
  export default {
    name: 'CategoriesPlanner',
    components: { ListCategories },
    data: () => ({
      tab: 'tab-0'
    }),
    computed: {
      allowed() {
        return this.$store.getters.user && ['planner', 'customer'].includes(this.$store.getters.user.role);
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      categories() {
        return this.$store.getters.categories;
      },
      customer() {
        return this.$store.getters.customer;
      }
    },
    methods: {
      showDialogFormCategory() {
        this.$bus.$emit('showDialogFormCategory', null);
      },
      getCategories() {
        if (!this.categories.length && this.customer) {
          getCategoriesUser(this.customer._id)
            .then(categories => {
              this.$store.dispatch('setCategories', categories);
            })
            .catch(err => {
              if (process.env.NODE_ENV !== 'production') {
                console.error(err);
              }
            })
        }
      }
    },
    created() {
      this.getCategories();
    }
  }
</script>

<style scoped>
  .v-content {
    background-color: #131313;
    padding-bottom: 70px !important;
  }
</style>
