<template>
  <v-dialog fullscreen persistent v-model="dialog">
    <v-card color="grey2" class="no-radius">
      <div class="d-flex flex-column toolbar-group">
        <div
          class="invoice-toolbar d-flex align-center justify-space-between darkgrey px-5"
          :class="isIOS ? 'ios-padding' : ''"
        >
          <v-btn
            icon
            large
            class="mr-5"
            color="primary"
            :disabled="loader"
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <span class="month-name primary--text" v-if="creditCard">
            Fatura do cartão {{ creditCard.name }}
          </span>

          <v-btn icon large></v-btn>
        </div>

        <div
          class="invoice-toolbar d-flex align-center justify-center darkgrey pb-2 pt-3 px-5"
        >
          <div class="month-selector">
            <v-btn
              icon
              large
              class="mr-5"
              color="white"
              :disabled="loader"
              @click="changePeriod('back')"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <span class="month-name white--text">{{ monthStr }}</span>

            <v-btn
              icon
              large
              class="mr-5"
              color="white"
              :disabled="loader"
              @click="changePeriod('next')"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>

        <v-progress-linear
          indeterminate
          color="primary"
          v-show="loader"
        ></v-progress-linear>
      </div>

      <v-expand-transition>
        <v-card-text v-if="!!invoice">
          <div v-if="invoice" class="invoice-status hidden-md-and-up dark-bg">
            <span class="invoice-status-text" :class="invoiceStatus.color">
              {{ invoiceStatus.text }}
            </span>
          </div>

          <InvoiceHeader
            :credit-card="creditCard"
            :invoice-id="invoice._id"
            :payment-loader="paymentLoader"
            :transactions="transactions.length"
            :current-date="currentDate"
          />

          <InvoiceTransactionsList
            :invoice-id="invoice._id"
            :credit-card="creditCard"
            :period="period"
          />
        </v-card-text>
      </v-expand-transition>

      <ButtonForms class="hidden-sm-and-down"/>
    </v-card>

    <DialogInvoicePayment/>
    <DialogFormInvoiceDates/>
    <DialogFormInvoiceRefund/>
    <DialogRemoveInvoiceRefund/>
    <MenuProfileMobile v-if="isMobile && isCustomer"/>
  </v-dialog>
</template>

<script>
import currency from '@/utils/currency';
import getInvoice from '@/api/invoices/getInvoice';
import getInvoiceTransactions from '@/api/transactions/getInvoiceTransactions'
import InvoiceHeader from '@/components/Customer/Invoice/components/InvoiceHeader';
import DialogFormInvoiceDates from '@/components/Customer/Invoice/dialogs/DialogFormInvoiceDates';
import DialogInvoicePayment from '@/components/Customer/Invoice/dialogs/DialogInvoicePayment';
import DialogFormInvoiceRefund from '@/components/Customer/Invoice/dialogs/DialogFormInvoiceRefund';
import InvoiceTransactionsList from '@/components/Customer/Invoice/components/InvoiceTransactionsList';
import MenuProfileMobile from '@/components/Customer/menu/MenuProfileMobile';
import DialogRemoveInvoiceRefund from '@/components/Customer/Invoice/dialogs/DialogRemoveInvoiceRefund';
import ButtonForms from '@/components/Customer/menu/ButtonForms';

export default {
  name: 'DialogCustomerInvoiceDetails',
  components: {
    ButtonForms,
    DialogRemoveInvoiceRefund,
    MenuProfileMobile,
    InvoiceTransactionsList,
    DialogFormInvoiceRefund,
    DialogInvoicePayment,
    DialogFormInvoiceDates,
    InvoiceHeader
  },
  data: () => ({
    dialog: false,
    loader: false,
    paymentLoader: false,
    period: null,
    creditCardId: null,
    invoice: null,
    transactions: [],
    monthStr: '',
    currency,
    currentDate: null
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.role === 'customer'
    },
    invoiceStatus() {
      let status = {
        text: '',
        color: '',
        bgColor: ''
      };

      let date = this.$date();

      if (this.invoice) {
        if (this.invoice.paid) {
          status.text = 'Fatura paga';
          status.color = 'success--text';
          status.bgColor = 'badge-success';
        } else if (!this.transactions.length) {
          status.text = 'Fatura vazia';
          status.color = 'success--text';
          status.bgColor = 'badge-success';
        } else {
          let closeDate = this.$date(this.invoice.closeDate);
          let paymentDate = this.$date(this.invoice.paymentDate);
          if (date.isAfter(paymentDate)) {
            status.text = 'Fatura vencida';
            status.color = 'error--text';
            status.bgColor = 'badge-error';
          } else if (
            date.isSameOrAfter(closeDate) &&
            this.currentDate.isSameOrBefore(paymentDate)
          ) {
            status.text = 'Fatura fechada';
            status.color = 'warning--text';
            status.bgColor = 'badge-warning';
          } else {
            status.text = 'Fatura aberta';
            status.color = 'success--text';
            status.bgColor = 'badge-success';
          }
        }
      }

      return status;
    },
    creditCard() {
      return this.$store.getters.creditCards.find(cc => cc._id === this.creditCardId)
    },
    allowed() {
      return this.$store.getters.allowed
    }
  },
  methods: {
    async openDialog(data) {
      try {
        this.currentDate = this.$date()

        if (!data.creditCardId) {
          throw new Error('creditCardId not sent')
        }

        this.creditCardId = data.creditCardId
        this.period = !data.period ? this.$date() : this.$date(data.period)

        this.dialog = true
        this.overlay = true

        await this.getData()
      } catch (err) {
        this.closeDialog()
        this.$noty.error('Desculpe! Não conseguimos buscar os detalhes de sua fatura. Tente novamente mais tarde.')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      }
    },
    closeDialog() {
      this.dialog = false
      this.period = null
      this.creditCardId = null
    },
    async getData() {
      try {
        this.loader = true

        this.invoice = await getInvoice(this.creditCardId, this.period.format('YYYY-MM'))
        await this.$store.dispatch('saveInvoice', this.invoice)

        this.transactions = await getInvoiceTransactions(this.invoice._id)

        this.transactions.forEach(tr => this.$store.dispatch('saveTransaction', tr))
      } catch (err) {
        this.closeDialog()
        this.$noty.error('Desculpe! Não conseguimos buscar os detalhes de sua fatura. Tente novamente mais tarde.')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.loader = false
      }
    },
    changePeriod(type) {
      if (!this.creditCard.archived) {
        if (type === 'next') {
          this.period = this.period.add(1, 'month');
        } else {
          this.period = this.period.add(-1, 'month');
        }

        this.getData();
      }
    },
    formatMonthStr() {
      if (this.period) {
        this.monthStr = this.period.format('MMMM');

        const year = this.period.year();

        if (year !== this.$date().year()) {
          this.monthStr += `/${year}`;
        }
      }
    },
    responseOpenedInvoice() {
      if (this.dialog) {
        this.$bus.$emit('getOpenedInvoice', this.invoice._id)
      }
    }
  },
  watch: {
    period() {
      this.formatMonthStr()
    }
  },
  created() {
    this.$bus.$on('showDialogCustomerInvoiceDetails', this.openDialog)
    this.$bus.$on('requestOpenedInvoice', this.responseOpenedInvoice)
  }
}
</script>

<style scoped>
.ios-padding {
  padding-top: 50px !important;
}

.toolbar-group {
  position: fixed;
  width: 100%;
  z-index: 10;
}

.invoice-toolbar {
  height: 58px;
}

.invoice-toolbar span {
  font-size: 14px;
}

.month-selector {
  padding-left: 0;
  padding-right: 0;
  width: 100% !important;
}

.invoice-status {
  padding: 10px 10px 10px 10px;
  text-align: center;
}

.invoice-status-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.1em;
}

.v-card__text {
  padding-top: 120px !important;
}


</style>
