<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    :persistent="loader"
  >
    <v-card color="grey2" v-if="orcamento">
      <v-card-title class="dialog-card-title">
        <v-btn
          icon
          color="grey1"
          :disabled="loader"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="grey1--text ml-4">
          Alterar despesa fixa
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-text-field
                  type="tel"
                  color="grey1"
                  v-money="money"
                  label="Valor da despesa fixa"
                  v-model="value"
                  id="input-orcamento-despesa"
                  :disabled="fixed"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6>
                <v-switch
                  color="primary"
                  v-model="fixed"
                  label="Fixar valor"
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>

            <v-btn
              fab
              small
              type="submit"
              color="primary"
              class="darkgrey--text px-5"
              :loading="loader"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { VMoney } from 'v-money'
  import removeMoneyMask from "../../../../utils/removeMoneyMask";
  import updateDespesaFixa from "../../../../api/orcamento/updateDespesaFixa";

  export default {
    name: 'DialogDespesaFixa',
    directives: { money: VMoney },
    data: () => ({
      dialog: false,
      loader: false,
      value: 0,
      orcamento: null,
      fixed: true,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
    }),
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.value = 0;
        this.orcamento = null;
        this.resetMoneyInput();
      },
      submit() {
        this.loader = true;

        let data = {
          value: removeMoneyMask(this.value),
          fixed: this.fixed
        }

        updateDespesaFixa(this.orcamento, data)
          .then(orcamento => {
            this.$store.dispatch('setOrcamento', orcamento);
            this.close();
          })
          .catch(err => {
            this.loader = false;
            this.$noty.error('Desculpe! Não foi possível alterar seu orçamento. Tente novamente!');
            if (process.env.NODE_ENV !== 'production') console.error(err);
          });
      },
      resetMoneyInput() {
        let input = document.getElementById('input-orcamento-despesa');
        if (input) {
          input.value = this.value;
          input.dispatchEvent(new Event('input'));
        }
      }
    },
    created() {
      this.$bus.$on('showDialogDespesaFixa', data => {
        if (data.orcamento) {
          this.orcamento = data.orcamento;
          this.value = data.value.toFixed(2);
          this.fixed = data.fixed;
          this.resetMoneyInput();
          this.dialog = true
        }
      });
    }
  }
</script>

<style scoped>
  .dialog-card-title {
    background-color: #131313 !important;
  }
</style>
