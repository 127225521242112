<template>
  <v-card flat color="grey2">
    <v-card-title>
      <span class="total-desc">Usuários Novos</span>
    </v-card-title>

    <v-card-text>
      <v-form
        @submit.prevent="submit"
        class="search-form"
      >
        <v-layout row wrap>
          <v-flex lg4 md6 sm7 xs12>
            <v-text-field
              class="mr-2"
              color="primary"
              persistent-hint
              v-model="search"
              label="Pesquisar"
              hint="Buscar por nome ou e-mail"
            ></v-text-field>
          </v-flex>

          <v-flex lg3 md6 sm5 xs12>
            <v-btn
              rounded
              depressed
              type="submit"
              color="primary"
              class="mt-2 mr-2 darkgrey--text"
            >
              <v-icon>mdi-account-search-outline</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>

      <TableLockedCustomers class="mt-5"/>
    </v-card-text>

    <DialogUnlockCustomer/>
  </v-card>
</template>

<script>
import TableLockedCustomers from '@/components/Planner/tables/TableLockedCustomers';
import DialogUnlockCustomer from '@/components/Planner/dialogs/DialogUnlockCustomer';
export default {
  name: 'CardLockedCustomers',
  components: { DialogUnlockCustomer, TableLockedCustomers },
  data: () => ({
    search: ''
  }),
  methods: {
    submit() {
      this.$bus.$emit('getUsersWithoutPlanner', this.search)
    }
  }
}
</script>

<style scoped>
.total-desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
</style>
