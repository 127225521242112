import store from '@/store/index';
import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

/**
 * Remover video do EAD
 * @param {string} videoId
 */
const deleteVideo = videoId =>
  new Promise((resolve, reject) => {
    let user = store.getters.user;

    if (user.role === 'admin') {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          let method = 'DELETE';
          let headers = {
            role: user.role,
            Authorization: `Bearer ${idToken}`
          };
          let url = `/videos/${videoId}`;

          return httpClient(url, { headers, method });
        })
        .then(() => resolve())
        .catch(reject);
    }
  });

export default deleteVideo;
