<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="darkgrey d-flex align-center">
        <v-btn
          icon
          color="white"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="white--text">
          Link {{ role === 'company' ? 'Colab' : 'de compra' }} - {{ companyName }}
        </span>
      </v-card-title>

      <v-card-text class="pt-5">
        <p class="white--text">
          <b>Link {{ role === 'company' ? 'Colab' : 'de compra' }}:</b> {{ billingUrl }}
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogCompanyPurchaseUrl',
  data: () => ({
    dialog: false,
    companyName: '',
    billingUrl: '',
    role: 'company'
  }),
  methods: {
    openDialog(data) {
      if (data.companyName && data.billingUrl) {
        this.billingUrl = data.billingUrl
        this.companyName = data.companyName
        this.role = data.role ? data.role : 'company'
        this.dialog = true
      }
    },
    close() {
      this.dialog = false
      this.billingUrl = ''
      this.companyName = ''
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.close()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogCompanyPurchaseUrl', this.openDialog)
  }
}
</script>
