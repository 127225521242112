import firebase from 'firebase/app';
import httpClient from "../../plugins/httpClient";

const deletePendencia = pendenciaId => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'DELETE';
        let url = `/pendencias/${pendenciaId}`;
        let headers = { Authorization: `Bearer ${idToken}` };

        return httpClient(url, { headers, method });
      })
      .then(resp => resolve())
      .catch(reject);
  });
}

export default deletePendencia;
