<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    :persistent="loader"
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          class="mr-2"
          color="primary"
          :disabled="loader"
          @click.stop="closeDialog"
        >
          <v-icon>{{ isMobile ? 'mdi-arrow-left' : 'mdi-close' }}</v-icon>
        </v-btn>

        <span class="primary--text">Cadastro UPlanner 2.0</span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="input-label primary--text">Nome completo</span>
                <v-text-field
                  dense
                  type="text"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="user.displayName"
                  :error-messages="displayNameErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">E-mail</span>
                <v-text-field
                  dense
                  type="email"
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="user.email"
                  :loading="emailLoader"
                  :error-messages="emailErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Senha</span>
                <v-text-field
                  dense
                  color="grey1"
                  class="mt-0 pt-0"
                  v-model="user.password"
                  :error-messages="passwordErrors"
                  @click:append="showPass = !showPass"
                  :type="showPass ? 'text' : 'password'"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <span class="input-label primary--text">Repita a senha</span>
                <v-text-field
                  dense
                  color="grey1"
                  type="password"
                  class="mt-0 pt-0"
                  v-model="user.repeatPassword"
                  :error-messages="repeatPasswordErrors"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 class="input-container d-flex align-center">
                <v-checkbox
                  color="purple"
                  @change="saveTermsClick"
                  v-model="user.termsAndPrivacyAccepted"
                ></v-checkbox>

                <span class="input-label white--text">
                  Declaro que li e aceito os

                  <a
                    href="https://uplanner.app/#/termos-uso"
                    target="_blank"
                    class="primary--text text-uppercase"
                  >
                    Termos de adesão
                  </a>

                  e

                  <a
                    href="https://uplanner.app/#/politica-privacidade"
                    target="_blank"
                    class="primary--text text-uppercase"
                  >
                    Política de privacidade
                  </a>
                </span>
              </v-flex>

              <v-flex xs12>
                <v-card-actions class="px-0">
                  <v-spacer v-if="!isMobile"></v-spacer>

                  <v-btn
                    large
                    rounded
                    type="submit"
                    color="primary"
                    :block="isMobile"
                    :loading="loader"
                    class="text-capitalize darkgrey--text"
                    :disabled="!user.termsAndPrivacyAccepted"
                  >
                    Cadastrar
                  </v-btn>

                  <v-spacer v-if="!isMobile"></v-spacer>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, email, minLength, maxLength} from 'vuelidate/lib/validators'
import checkEmailAlreadyUsed from "@/api/users/checkEmailAlreadyUsed";
import stringCapitalize from "@/utils/stringCapitalize";
import firebase from "firebase/app";
import userSignup from "@/api/users/userSignup";
import getIpData from "@/api/ipapi/getIpData";
import {pick} from "lodash";

export default {
  name: 'DialogCadastro',
  data: () => ({
    dialog: false,
    loader: false,
    showPass: false,
    emailLoader: false,
    processingTerms: false,
    user: {
      displayName: '',
      email: '',
      password: '',
      repeatPassword: '',
      termsAndPrivacyAccepted: false
    },
    terms: []
  }),
  validations: {
    user: {
      email: {
        email,
        required,
        maxLength: maxLength(150),
        isAvailable(v) {
          if (!v)
            return true

          return new Promise((resolve, reject) => {
            checkEmailAlreadyUsed(this.user.email.trim().toLowerCase())
              .then(({exists}) => {
                resolve(!exists)
              })
              .catch(() => resolve(true))
          })
        }
      },
      displayName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
        isValid(v) {
          return !!v && v.trim().split(/\s+/).length >= 2
        }
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(24)
      },
      repeatPassword: {
        isValid(v) {
          return !!v && v === this.user.password
        }
      }
    }
  },
  computed: {
    // error messages
    emailErrors() {
      if (!this.$v.user.email.$anyDirty)
        return []
      if (!this.$v.user.email.isAvailable)
        return ['E-mail indisponível']
      if (this.$v.user.email.$anyError)
        return ['Preencha um e-mail válido']
    },
    passwordErrors() {
      if (!this.$v.user.password.$anyDirty)
        return []
      if (this.$v.user.password.$anyError)
        return ['Preencha uma senha válida']
    },
    displayNameErrors() {
      if (!this.$v.user.displayName.$anyDirty)
        return []
      if (this.$v.user.displayName.$anyError)
        return ['Preencha um nome completo válido']
    },
    repeatPasswordErrors() {
      if (!this.$v.user.repeatPassword.$anyDirty)
        return []
      if (this.$v.user.repeatPassword.$anyError)
        return ['A senhas devem ser iguais']
    },

    // helpers
    isIOS() {
      return this.$store.getters.isIOS
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isLoggedIn() {
      return !!this.$store.getters.user
    },
  },
  methods: {
    openDialog() {
      if (!this.isLoggedIn) {
        this.dialog = true
      }
    },
    closeDialog() {
      this.dialog = false
      this.loader = false
      this.emailIsUsed = false

      this.user = {
        displayName: '',
        email: '',
        password: '',
        repeatPassword: ''
      }

      this.$v.$reset()
    },
    async checkEmail() {},
    async submit() {
      try {
        this.$v.$touch()

        if (!this.$v.$anyError && this.user.termsAndPrivacyAccepted && !this.processingTerms) {
          this.loader = true
          let {email, password, displayName} = this.user

          email = email.trim().toLowerCase()
          displayName = stringCapitalize(displayName)

          const firebaseUser = await firebase.auth().createUserWithEmailAndPassword(email, password)

          await firebaseUser.user.updateProfile({displayName})

          try {
            await userSignup({
              email,
              displayName,
              terms: this.terms,
              uid: firebaseUser.user.uid,
              termsAndPrivacyAccepted: this.user.termsAndPrivacyAccepted
            })

            this.$noty.success('Cadastro realizado com sucesso!')

          } catch (err) {
            if (process.env.NODE_ENV !== 'production') {
              console.error(err)
            }

            await firebaseUser.user.delete()
          }

          this.closeDialog()
        }
      } catch (err) {
        this.closeDialog()

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      }
    },
    async saveTermsClick() {
      try {
        this.processingTerms = true
        const ipData = await getIpData()

        const acceptData = {
          accepted: this.user.termsAndPrivacyAccepted,
          date: this.$date().toISOString(),
          ipData: pick(ipData, [ 'ip', 'version', 'timezone' ])
        }

        this.terms.push(acceptData)
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.processingTerms = false
      }
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeDialog()
      }
    }
  },
  created() {
    this.$bus.$on('showDialogCadastro', this.openDialog)
  }
}
</script>
