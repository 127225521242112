<template>
  <v-card flat color="grey3">
    <v-card-title :class="isIOS ? 'ios-padding' : ''">Proteção Familiar</v-card-title>

    <v-card-text class="px-1" v-if="protecaoFamiliar">
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex
            xs12 md4
            :key="item._id"
            v-for="item in protecaoFamiliar.seguros"
          >
            <v-card
              outlined
              height="100%"
              class="d-flex flex-column justify-space-between"
            >
              <v-card-text>
                <div class="d-flex flex-column ">
                  <span class="pat-item-title primary--text">{{ item.operadora }}</span>
                  <span class="pat-item-type">{{ item.tipo }}</span>
                </div>

                <div class="d-flex flex-column mt-2">
                  <div class="pat-item-info-desc d-flex justify-space-between">
                    <div class="d-flex flex-column">
                      <span class="input-label">Valor pago</span>
                      <span class="white--text input-label">{{ numberToCurrency(item.valorPago) }}</span>
                    </div>

                    <div class="d-flex flex-column">
                      <span class="input-label">Repetição</span>
                      <span class="white--text input-label">{{ item.repetir }}</span>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-column mt-2" v-if="item.dataVencimento">
                  <div class="pat-item-info-desc d-flex justify-space-between">
                    <div class="d-flex flex-column" style="width: 100% !important;">
                      <span class="input-label">Vencimento</span>
                      <span class="white--text input-label">{{ formatDate(item.dataVencimento) }}</span>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-column mt-2" v-if="item.obs">
                  <div class="pat-item-info-desc d-flex justify-space-between">
                    <div class="d-flex flex-column" style="width: 100% !important;">
                      <span class="input-label">Observação</span>
                      <span class="white--text input-label">{{ item.obs }}</span>
                    </div>
                  </div>
                </div>
              </v-card-text>

              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  icon
                  color="error"
                  :loading="deleteLoader === item._id"
                  @click.stop="removeItem(item._id)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>

                <v-btn
                  icon
                  color="white"
                  @click.stop="showForm(item)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>

          <v-flex xs12 class="mt-10 text-center">
            <v-btn
              dark
              small
              rounded
              class="px-5"
              color="purple"
              @click.stop="showForm(null)"
            >
              Adicionar seguro
            </v-btn>
          </v-flex>

          <v-flex xs12 class="mt-10">
            <v-card-actions class="pa-0 ma-0 submit-buttons">
              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="stepBack"
              >
                Voltar
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="skip"
              >
                Pular
              </v-btn>

              <v-btn
                rounded
                color="primary"
                class="px-5 darkgrey--text"
                :loading="loader"
                @click.stop="submit(null)"
              >
                Próximo
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import updatePerfil from '@/api/perfil/updatePerfil';
import numberToCurrency from '@/utils/numberToCurrency';

export default {
  name: 'FormProtecaoFamiliar',
  props: ['profileId', 'protecaoFamiliar'],
  data: () => ({
    numberToCurrency,
    loader: false,
    deleteLoader: ''
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    }
  },
  methods: {
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY')
      }

      return ''
    },
    skip() {
      updatePerfil(this.profileId, { step: 8 }).then((perfil) => {
        this.$store.dispatch("setPerfil", perfil);
      });

      this.$bus.$emit("nextProfileStep");
    },
    stepBack() {
      this.$bus.$emit("backProfileStep");
    },
    async submit() {
      try {
        this.loader = true

        const protecaoFamiliar = JSON.parse(JSON.stringify(this.protecaoFamiliar))

        protecaoFamiliar.seguros =  protecaoFamiliar.seguros.map(item => {
          if (typeof item._id === 'number') {
            delete item._id
          }

          return item
        })

        const perfil = await updatePerfil(this.profileId, { protecaoFamiliar, step: 8 })
        await this.$store.dispatch('setPerfil', perfil)

        this.$bus.$emit('nextProfileStep')
      } catch (err) {
        this.$noty.error('Desculpe! Não conseguimos salvar seus dados. Tente novamente mais tarde.')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.loader = false
      }
    },
    showForm(item) {
      this.$bus.$emit('showDialogFormSeguroVidaPerfil', item)
    },
    removeItem(itemId) {
      if (itemId) {
        this.deleteLoader = itemId

        setTimeout(() => {
          const index = this.protecaoFamiliar.seguros.findIndex(item => item._id === itemId)
          if (index > -1) {
            this.protecaoFamiliar.seguros.splice(index, 1)
          }

          this.deleteLoader = itemId
        }, 1500)
      }
    }
  }
}
</script>

<style scoped>
.pat-item-title {
  font-size: 12px !important;
  font-weight: bold;
  letter-spacing: .1em !important;
  text-transform: uppercase;
  line-height: 1em;
}

.pat-item-type {
  font-size: 11px;
  letter-spacing: .1em !important;
}

.pat-item-info-desc {
  margin-top: 5px;
}

.pat-item-info-desc div {
  width: 50%;
}

@media only screen and (max-width: 475px) {
  .v-card__actions.submit-buttons {
    display: flex;
    flex-direction: column-reverse !important;
    text-align: center;
  }

  .v-card__actions.submit-buttons .v-btn {
    display: block;
    margin: 0 0 10px 0 !important;
    width: 100%;
  }
}
</style>
