import { render, staticRenderFns } from "./DialogEditPatrimonio.vue?vue&type=template&id=44fcd71a&scoped=true&"
import script from "./DialogEditPatrimonio.vue?vue&type=script&lang=js&"
export * from "./DialogEditPatrimonio.vue?vue&type=script&lang=js&"
import style0 from "./DialogEditPatrimonio.vue?vue&type=style&index=0&id=44fcd71a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44fcd71a",
  null
  
)

export default component.exports