<template>
  <v-dialog
    fullscreen
    scrollable
    v-model="dialog"
  >
    <v-card color="grey2" class="no-radius">
      <v-card-title class="dialog-form-title" :class="isIOS ? 'ios-padding' : ''">
        <v-btn
          icon
          color="white"
          @click.stop="close"
          class="back-button"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="white--text ml-4">Configurações</span>
      </v-card-title>

      <v-card-text class="pt-5">
        <div class="menu-list">
          <div class="menu-list-item" @click="showDialogCategories">
            <div class="menu-list-item__container">
              <div class="menu-list-item__icon">
                <v-icon color="primary">mdi-bookmark-outline</v-icon>
              </div>
              <div class="menu-list-item__content">
              <span class="menu-list-item__content-title primary--text text-uppercase">
                Categorias
              </span>
                <span class="menu-list-item__content-desc grey1--text">
                Criar/editar categorias e sub-categorias
              </span>
              </div>
            </div>
            <div class="menu-list-item__icon text-right">
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DialogMenuConfiguracoes',
    data: () => ({
      dialog: false
    }),
    computed: {
      isIOS() {
        return this.$store.getters.isIOS;
      }
    },
    methods: {
      close() {
        this.dialog = false;
      },
      showDialogCategories() {
        this.$bus.$emit('showDialogCategories');
        this.close();
      },
    },
    created() {
      this.$bus.$on('showDialogConfiguracoes', () => {
        this.dialog = true;
      });
    }
  }
</script>

<style scoped>
  .menu-list {
    display: flex;
    flex-direction: column;
  }

  .menu-list-item {
    padding: 5px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    transition: all .5s ease;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #919191;
    margin-bottom: 5px;
  }

  .menu-list-item:hover {
    background-color: #2d2d2d;
  }

  .menu-list-item__container {
    display: flex;
  }

  .menu-list-item__icon:first-child {
    width: 50px;
    text-align: left;
  }

  .menu-list-item__content {
    display: flex;
    flex-direction: column;
  }

  .menu-list-item__content-title {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: .1em;
  }

  .menu-list-item__content-desc {
    font-size: 9px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .1em;
  }
</style>
