import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const getUserTransactions = (userId, period) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/transactions/user/${userId}?period=${period}`;

        return httpClient(url, {headers});
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export default getUserTransactions;