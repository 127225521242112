import firebase from 'firebase/app'
import httpClient from '@/plugins/httpClient'

const getIuguSubscription = async subscriptionId => {
  if (!subscriptionId) {
    throw new Error('missing subscriptionId')
  }

  const idToken = await firebase.auth().currentUser.getIdToken(true)

  const url = `/iugu/subscriptions/${subscriptionId}`
  const options = {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }

  const resp = await httpClient(url, options)

  return resp.data
}

export default getIuguSubscription
