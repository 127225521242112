<template>
  <v-content>
    <v-container fill-height>
      <div class="grid-item">
        <h1 class="auth-title">
          Para transformar a <br>
          sua vida só falta <br>
          <span class="primary--text">um cadastro</span>
        </h1>

        <img src="../assets/web/login-logo.png" class="auth-logo hidden-lg-and-up"/>
        <img src="../assets/web/logo-desktop.png" class="auth-logo hidden-md-and-down"/>
      </div>

      <div class="grid-item auth-form">
        <div class="mb-5">
          <v-btn
            icon
            small
            outlined
            class="mr-2 social-btn"
            color="primary"
            :loading="googleLoader"
            @click.stop="authWithSocial('google')"
          >
            <v-icon>mdi-google</v-icon>
          </v-btn>

          <v-btn
            icon
            small
            outlined
            class="social-btn"
            color="primary"
            :loading="facebookLoader"
            @click.stop="authWithSocial('facebook')"
          >
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
        </div>

        <v-form @submit.prevent="signup">
          <v-text-field
            dark
            dense
            outlined
            rounded
            type="text"
            class="mt-1"
            label="Nome"
            v-model="displayName"
            :error-messages="displayNameErrors"
            @input="$v.displayName.$touch()"
          ></v-text-field>

          <v-text-field
            dark
            dense
            outlined
            rounded
            type="email"
            class="mt-1"
            label="E-mail"
            v-model="email"
            :error-messages="emailErrors"
            @input="$v.email.$touch()"
          ></v-text-field>

          <v-text-field
            dark
            dense
            outlined
            rounded
            class="mt-1"
            label="Senha"
            v-model="password"
            :error-messages="passwordErrors"
            @input="$v.password.$touch()"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
          ></v-text-field>

          <div class="terms-check pl-2 py-0">
            <v-checkbox dark v-model="terms"></v-checkbox>
            <span>
                Eu aceito os
                <a>termos e condições</a>
              </span>
          </div>
          <div class="form-actions mt-5">
            <v-btn
              rounded
              depressed
              type="submit"
              color="primary"
              :loading="loader"
              class="black--text"
            >
              Cadastre-se
            </v-btn>

            <v-btn
              rounded
              outlined
              depressed
              to="/login"
              type="button"
              color="primary"
            >
              Login
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-container>
  </v-content>
</template>

<script>
  import firebase from 'firebase/app';
  import {
    required,
    email,
    minLength,
    maxLength
  } from 'vuelidate/lib/validators';

  import createUser from '../api/users/createUser';
  import checkEmailAlreadyUsed from '../api/users/checkEmailAlreadyUsed';
  import authWithSocial from "../utils/authWithGoogle";
  // import sendMetaInfoData from '@/utils/analytics/sendMetaInfoData';

  export default {
    name: 'Signup',
    // metaInfo() {
    //   return {
    //     title: "Registro",
    //     changed(metaInfo) {
    //       sendMetaInfoData(metaInfo)
    //     }
    //   };
    // },
    data: () => ({
      loader: false,
      email: '',
      password: '',
      displayName: '',
      terms: false,
      show: false,
      emailUsed: false,
      googleLoader: false,
      facebookLoader: false
    }),
    methods: {
      signup() {
        this.$v.$touch();

        if (!this.$v.$anyError && this.terms && !this.loader) {
          this.loader = true;

          let user = {
            email: this.email,
            displayName: this.displayName,
            password: this.password,
            role: 'customer'
          };

          firebase
            .auth()
            .createUserWithEmailAndPassword(user.email, user.password)
            .then(result => {
              user.uid = result.user.uid;
              user.projectClient = true;

              delete user.password;
              result.user.updateProfile({displayName: user.displayName});
              return createUser(user);
            })
            .then(user => {
              this.$store.dispatch('setUser', user);
              this.$router.push('/');
              this.loader = false;
            })
            .catch(err => {
              console.error(err);
              this.$noty.error('Desculpe! Não foi possível criar seu usuário!');
              this.loader = false;
            });
        } else if (!this.terms) {
          this.$noty.warning('Você precisa aceitar os termos e condições!');
        }
      },
      goToLogin() {
        this.$router.push('/login');
      },
      checkEmail() {
        if (!this.$v.email.$anyError) {
          let email = this.email.toLowerCase().trim();

          checkEmailAlreadyUsed(email)
            .then(resp => (this.emailUsed = resp.exists))
            .catch(err => {
              // eslint-disable-next-line no-undef
              if (process.env.NODE_ENV !== 'production') console.error(err);
            });
        }
      },
      authWithSocial(type) {
        if (!this.googleLoader && !this.facebookLoader) {
          if (type === 'google')
            this.googleLoader = true;
          else
            this.facebookLoader = true;

          authWithSocial(false, type)
            .then(result => this.$router.push('/'))
            .catch(err => {
              if (process.env.NODE_ENV !== 'production')
                console.error(err);

              this.googleLoader = false;
              this.facebookLoader = false;

              if (err.code && err.code !== "auth/popup-closed-by-user")
                this.$noty.error("Desculpe! Não foi possível acessar sua conta. Verifique seus dados e tente novamente!");
            });
        }
      }
    },
    validations: {
      email: {required, email},
      displayName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100)
      },
      password: {required, minLength: minLength(8), maxLength: maxLength(100)}
    },
    computed: {
      emailErrors() {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        !this.$v.email.required && errors.push('E-mail é obrigatório');
        !this.$v.email.email && errors.push('E-mail inválido');
        this.emailUsed && errors.push('E-mail indisponível');
        return errors;
      },
      passwordErrors() {
        const errors = [];
        if (!this.$v.password.$dirty) return errors;
        !this.$v.password.required && errors.push('Senha é obrigatória');
        !this.$v.password.minLength &&
        errors.push('A senha deve conter, no mínimo, 8 caracteres');
        !this.$v.password.maxLength &&
        errors.push('A senha deve conter, no máximo, 100 caracteres');
        return errors;
      },
      displayNameErrors() {
        const errors = [];
        if (!this.$v.displayName.$dirty) return errors;
        !this.$v.displayName.required && errors.push('Nome é obrigatório');
        !this.$v.displayName.minLength &&
        errors.push('O nome deve conter, no mínimo, 3 caracteres');
        !this.$v.displayName.maxLength &&
        errors.push('O nome deve conter, no máximo, 100 caracteres');
        return errors;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      }
    },
    watch: {
      email() {
        this.checkEmail();
      }
    },
    created() {
      if (this.isMobile) {
        this.$router.push('/auth');
      }
    }
  };
</script>

<style scoped>
  .v-content {
    background: url('../assets/web/desktop-bg2.png') center no-repeat no-repeat;
    background-size: cover;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 728px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .grid-item {
    width: 100%;
    min-height: 1px;
  }

  .auth-title {
    color: white;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', 'Roboto',  sans-serif;
    line-height: 1.1em;
  }

  .auth-form {
    width: 228px;
    display: flex;
    flex-direction: column;
  }

  .v-btn.social-btn .v-icon {
    font-size: 15px !important;
  }

  .v-input__control .v-text-field__details {
    margin-bottom: 0 !important;
  }

  .terms-check {
    display: flex;
    align-items: center;
    font-size: 11px;
    color: white;
    letter-spacing: 0.05em;
    height: 44px;
  }

  .terms-check a {
    text-decoration: underline;
    color: white;
  }

  .form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form-actions .v-btn {
    width: 104px !important;
    height: 29px !important;
  }

  .form-actions .v-btn.v-size--default {
    font-size: 10px !important;
  }

  .auth-logo {
    position: fixed;
    bottom: 30px;
    right: 15%;
  }

  @media only screen and (min-width: 1264px) {
    .v-content {
      background: url('../assets/web/desktop-bg22.png') center no-repeat no-repeat;
      background-size: cover;
      padding-top: 70px !important;
      padding-bottom: 45px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .container {
      max-width: 960px !important;
    }

    .auth-form {
      width: 303px;
    }

    .v-btn.social-btn {
      width: 34px;
      height: 34px;
    }

    .v-btn.social-btn .v-icon {
      font-size: 23px !important;
    }

    .form-actions .v-btn {
      width: 137px !important;
      height: 38px !important;
    }

    .form-actions .v-btn.v-size--default {
      font-size: 13px !important;
    }
  }
</style>
