<template>
  <v-card color="transparent" flat>
    <v-card-text class="pa-0" v-if="sons && sons.length">
      <v-container fluid grid-list-md class="px-0">
        <v-layout row wrap>
          <v-flex xs12 sm6 md4 lg3 v-for="son in sons" :key="son._id">
            <v-card color="darkgrey">
              <v-card-title class="primary--text">{{ son.nome }}</v-card-title>

              <v-card-text>
                <div class="d-flex flex-column">
                  <span class="input-label primary--text">Idade</span>
                  <span class="input-label">{{ son.idade }} anos</span>
                </div>

                <div class="d-flex flex-column">
                  <span class="input-label primary--text">Custo mensal</span>
                  <span class="input-label">
                    {{ numberToCurrency(son.custoMensal) }}
                    <span v-if="son.descricao">, {{ son.descricao }}</span>
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-card-actions class="pb-5">
      <v-spacer></v-spacer>

      <v-btn
        v-if="allowed"
        outlined
        rounded
        color="primary"
        class="px-10 text-capitalize"
        @click="showDialogEditSons"
      >
        Editar
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import numberToCurrency from "../../../../utils/numberToCurrency"

export default {
  name: 'CardSons',
  data: () => ({
    numberToCurrency
  }),
  computed: {
    perfil() {
      return this.$store.getters.perfil;
    },
    sons() {
      if (this.perfil) {
        return this.perfil.sons
      }

      return null
    },
    allowed() {
      return this.$store.getters.allowed;
    }
  },
  methods: {
    showDialogEditSons() {
      if (this.allowed) {
        this.$bus.$emit('showDialogEditSons')
      }
    },
  }
}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .input-label {
    font-size: 14px;
  }
}
</style>
