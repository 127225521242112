import firebase from 'firebase/app';
import httpClient from '@/plugins/httpClient';

const saveLifeInsuranceSon = (insuranceId, data) => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const options = {
            data,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }
          const url = `/life-insurances/${insuranceId}/sons`;

          return httpClient(url, options);
        })
        .then(resp => resolve(resp.data))
        .catch(reject);
    } catch(err) {
      reject(err);
    }
  });
}

export default saveLifeInsuranceSon;
