import firebase from "firebase/app";
import httpClient from "../../plugins/httpClient";

const deleteTransaction = (id, deleteType = '') => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        let method = 'DELETE';
        let headers = { Authorization: `Bearer ${idToken}` };
        let url = `/transactions/${id}?deleteType=${deleteType}`;

        return httpClient(url, { headers, method });
      })
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}

export  default deleteTransaction;

