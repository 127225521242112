<template>
  <v-card flat color="grey3">
    <v-card-title :class="isIOS ? 'ios-padding' : ''">Patrimônio</v-card-title>
    <v-card-subtitle>
      <span>Informações para termos uma visão geral do seu patrimônio.</span>
    </v-card-subtitle>

    <v-card-text class="px-1" v-if="patrimonio">
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex
            xs12 md4
            :key="item._id"
            v-for="item in patrimonio.items"
          >
            <v-card outlined height="100%" class="d-flex flex-column justify-space-between">
              <v-card-text>
                <div class="d-flex flex-column ">
                  <span class="pat-item-title primary--text">{{ item.descricao }}</span>
                  <span class="pat-item-type">{{ item.tipo }}</span>
                </div>

                <div class="d-flex flex-column mt-2">
                  <div class="pat-item-info-desc d-flex justify-space-between">
                    <div class="d-flex flex-column">
                      <span class="input-label">Valor atual</span>
                      <span class="white--text input-label">{{ numberToCurrency(item.valorAtual) }}</span>
                    </div>

                    <div class="d-flex flex-column">
                      <span class="input-label">Saldo a pagar</span>
                      <span class="white--text input-label">{{ numberToCurrency(item.saldoPagar) }}</span>
                    </div>
                  </div>

                  <div class="pat-item-info-desc d-flex justify-space-between">
                    <div class="d-flex flex-column">
                      <span class="input-label">Proprietário</span>
                      <span class="white--text input-label">{{ item.proprietario }}</span>
                    </div>

                    <div class="d-flex flex-column">
                      <span class="input-label">Taxa</span>
                      <span class="white--text input-label">{{ item.taxa.toFixed(2) }}%</span>
                    </div>
                  </div>

                  <div class="pat-item-info-desc d-flex justify-space-between" v-if="item.obs">
                    <div class="d-flex flex-column">
                      <span class="input-label">Observação</span>
                      <span class="white--text input-label">{{ item.obs }}</span>
                    </div>
                  </div>
                </div>
              </v-card-text>

              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  icon
                  color="error"
                  @click.stop="deleteItem(item._id)"
                  :loading="deleteLoader === item._id"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>

                <v-btn
                  icon
                  color="white"
                  @click.stop="showForm(item)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>

          <v-flex xs12 class="mt-10 text-center">
            <v-btn
              dark
              small
              rounded
              class="px-5"
              color="purple"
              @click.stop="showForm(null)"
            >
              Adicionar patrimônio
            </v-btn>
          </v-flex>

          <v-flex xs12 class="mt-10">
            <v-card-actions class="pa-0 ma-0 submit-buttons">
              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="stepBack"
              >
                Voltar
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                rounded
                outlined
                color="grey1"
                class="px-5"
                :disabled="loader"
                @click.stop="skip"
              >
                Pular
              </v-btn>

              <v-btn
                rounded
                color="primary"
                class="px-5 darkgrey--text"
                :loading="loader"
                @click.stop="submit(null)"
              >
                Próximo
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency';
import deleteItemPatrimonio from '@/api/patrimonio/deleteItemPatrimonio';
import updatePerfil from '@/api/perfil/updatePerfil';
import getUserPatrimonio from '@/api/patrimonio/getUserPatrimonio';

export default {
  name: 'FormPatrimonio',
  props: ['profileId'],
  data: () => ({
    loader: false,
    numberToCurrency,
    deleteLoader: ''
  }),
  computed: {
    isIOS() {
      return this.$store.getters.isIOS
    },
    patrimonio() {
      return this.$store.getters.patrimonio
    },
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === 'customer'
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer
    }
  },
  methods: {
    showForm(item) {
      this.$bus.$emit('showDialogFormPatrimonio', item)
    },
    async deleteItem(itemId) {
      try {
        if (itemId) {
          this.deleteLoader = itemId

          await deleteItemPatrimonio(this.patrimonio._id, itemId)

          let index = this.patrimonio.items.findIndex(item => item._id === itemId)
          if (index > -1) {
            this.patrimonio.items.splice(index, 1)
          }
        }
      } catch (err) {
        this.$noty.error('Desculpe. Não conseguimos remover este item. Tente novamente mais tarde')

        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      } finally {
        this.deleteLoader = ''
      }
    },
    async submit(skip) {
      try {
        if (!skip) {
          this.loader = true
        }

        updatePerfil(this.profileId, { step: 7 }).then((perfil) => {
          this.$store.dispatch("setPerfil", perfil);
        });

        this.$bus.$emit("nextProfileStep");
      } catch (err) {
        this.$noty.error(
          "Desculpe, não foi possível salvar seus dados. Tente novamente mais tarde."
        );

        if (process.env.NODE_ENV !== "production") {
          console.error(err);
        }
      } finally {
        this.loader = false
      }
    },
    skip() {
      this.submit(true)
    },
    stepBack() {
      this.$bus.$emit("backProfileStep");
    },
    async getPatrimonio() {
      try {
        const patrimonio = await getUserPatrimonio(this.user._id)
        await this.$store.dispatch('setPatrimonio', patrimonio)
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      }
    }
  },
  mounted() {
    this.getPatrimonio()
  }
}
</script>

<style scoped>
.pat-item-title {
  font-size: 12px !important;
  font-weight: bold;
  letter-spacing: .1em !important;
  text-transform: uppercase;
  line-height: 1em;
}

.pat-item-type {
  font-size: 11px;
  letter-spacing: .1em !important;
}

.pat-item-info-desc {
  margin-top: 5px;
}

.pat-item-info-desc div {
  width: 50%;
}

@media only screen and (max-width: 475px) {
  .v-card__actions.submit-buttons {
    display: flex;
    flex-direction: column-reverse !important;
    text-align: center;
  }

  .v-card__actions.submit-buttons .v-btn {
    display: block;
    margin: 0 0 10px 0 !important;
    width: 100%;
  }
}
</style>
