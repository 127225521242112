<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400px"
  >
    <v-card color="grey2" v-if="bankAccount">
      <v-card-title class="dialog-form-title">
        <v-btn
          icon
          @click.stop="close"
          class="back-button"
          :disabled="loader"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="ml-4">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <p class="dialog-form-description">{{content}}</p>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            color="primary"
            :loading="loader"
            class="px-5 darkgrey--text"
            @click.stop="submit"
          >
            {{ buttonText }}
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import saveBankAccount from '@/api/bankAccounts/saveBankAccount';

  export default {
    name: 'DialogArchiveBankAccount',
    data: () => ({
      dialog: false,
      loader: false,
      bankAccount: null
    }),
    methods: {
      close() {
        this.dialog = false;
        this.loader = false;
        this.bankAccount = {
          _id: '',
          archived: false
        };
      },
      submit() {
        this.loader = true;

        const bankAccount = JSON.parse(JSON.stringify(this.bankAccount))

        bankAccount.archived = !bankAccount.archived

        saveBankAccount(bankAccount)
          .then(savedBankAccount => {
            this.$store.dispatch('archiveBankAccount', savedBankAccount);
            this.close();
            this.$noty.success(`Conta ${savedBankAccount.archived ? 'arquivada' : 'desarquivada'} com sucesso!`)

            this.$bus.$emit('updateListPendencias')
          })
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') console.error(err);
              this.loader = false;
              this.$noty.error('Falha ao salvar conta. Verifique os dados e tente novamente!');
          });
      }
    },
    computed: {
      title() {
        if (this.bankAccount) {
          if (this.bankAccount.archived) {
            if (this.bankAccount.pluggy) {
              return 'Ativar sincronização'
            } else {
              return 'Desarquivar conta'
            }
          } else {
            if (this.bankAccount.pluggy) {
              return 'Desativar sincronização'
            } else {
              return 'Arquivar conta'
            }
          }
        }
      },
      content() {
        if (this.bankAccount) {
          if (this.bankAccount.pluggy) {
            return `Tem certeza que deseja ${this.bankAccount.archived ? 'ativar' : 'desativar'} a sincronização da conta?`
          } else {
            return `Tem certeza que deseja ${this.bankAccount.archived ? 'desarquivar' : 'arquivar'} a conta?`
          }
        }
      },
      buttonText() {
        if (this.bankAccount) {
          if (this.bankAccount.pluggy) {
            return this.bankAccount.archived ? 'Ativar' : 'Desativar'
          } else {
            return this.bankAccount.archived ? 'Desarquivar' : 'Arquivar'
          }
        }
      }
    },
    created() {
      this.$bus.$on('showDialogArchiveBankAccount', bankAccount => {
        if (bankAccount) {
          this.bankAccount = JSON.parse(JSON.stringify(bankAccount));
          this.dialog = true;
        }
      });
    }
  }
</script>

<style scoped>
.v-btn.v-size--default {
  font-size: 11px !important;
}
</style>
