<template>
  <v-card @click="">
    <v-card-text class="d-flex align-center justify-start px-2 py-2">
      <div class="employee-avatar mr-2">
        <v-avatar size="40" color="darkgrey">
          <v-img v-if="employee.photoURL" :src="employee.photoURL"></v-img>
          <i class="lni lni-user primary--text" v-if="!employee.photoURL"></i>
        </v-avatar>
      </div>

      <div class="employee-infos">
        <div class="employee-infos-info">
          <b>Nome: </b>
          <span>{{ truncateName(employee.displayName) }}</span>
        </div>

        <div class="employee-infos-info">
          <b>Data de inicio: </b>
          <span>{{ formatDate(employee.createdAt) }}</span>
        </div>

        <div class="employee-infos-info">
          <b>Status: </b>
          <span>{{ statusDesc(employee.disabled) }}</span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CardEmployee',
  props: ['employee'],
  methods: {
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY HH:mm')
      }
      return ''
    },
    statusDesc(status) {
      return !status ? 'Habilitado' : 'Desabilitado'
    },
    truncateName(name) {
      if (name) {
        const len = name.length
        return len > 22 ? name.slice(0,22) + '...' : name
      }
      return ''
    }
  }
}
</script>
