<template>
  <v-container grid-list-md class="pa-0 mt-md-5" v-if="creditCard && invoice">
    <v-card flat :color="isMobile ? 'transparent' : 'grey3'">
      <v-card-text class="pa-0 pa-md-4">
        <v-layout row wrap>
          <v-flex xs12 class="d-flex align-center justify-space-between">
            <div>
              <v-menu
                right
                bottom
                class="mr-2"
                v-model="menuFilter"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    color="primary"
                    class="hidden-md-and-up"
                  >
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    rounded
                    v-on="on"
                    color="darkgrey"
                    class="primary--text elevation-5 btn-filtros hidden-sm-and-down"
                  >
                    Filtros
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-card width="290px" color="grey2">
                  <v-card-text>
                    <v-form @submit.prevent="">
                      <v-select
                        item-value="_id"
                        item-text="name"
                        label="Categoria"
                        menu-props="auto"
                        :items="categories"
                        v-model="filter.category"
                        prepend-inner-icon="mdi-bookmark-outline"
                      >
                        <template v-slot:item="{ item, index }">
                          <v-icon small class="mr-2" :color="item.color">
                            {{ item.icon }}
                          </v-icon>
                          <span>{{ item.name }}</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                          <v-chip small outlined :color="item.color">
                            <v-icon small class="mr-2">{{ item.icon }}</v-icon>
                            <span>{{ item.name }}</span>
                          </v-chip>
                        </template>
                      </v-select>

                      <v-select
                        item-text="name"
                        item-value="_id"
                        label="Sub categoria"
                        menu-props="auto"
                        :items="subCategories"
                        v-model="filter.subCategory"
                        prepend-inner-icon="mdi-bookmark-multiple-outline"
                      ></v-select>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-menu>

              <v-btn
                small
                rounded
                color="darkgrey"
                @click="exportCsv"
                :loading="loaderExport"
                v-if="transactions.length"
                class="primary--text elevation-5 btn-filtros hidden-sm-and-down ml-2"
              >
                Exportar
                <v-icon small class="ml-1">mdi-file-excel</v-icon>
              </v-btn>
            </div>

            <span class="primary--text invoice-tr-list-title">Itens da fatura</span>
          </v-flex>

          <v-flex
            xs12
            class="mt-2 "
          >
            <div class="lists-container" v-if="transactionsDates.length">
              <div
                :key="date"
                class="transactions-list"
                v-for="(date, i) in transactionsDates"
              >
                <v-subheader class="list-title primary--text mb-0 pa-0">
                  {{ formatListDate(date).toUpperCase() }}
                </v-subheader>

                <swipe-list
                  :ref="'list-' + date"
                  :items="groupedTransactions[date]"
                >
                  <template v-slot="{ item, index, revealLeft, revealRight, close }">
                    <div class="transaction py-4 px-lg-2 animated fadeInLeft transition-fast-out-slow-in">
                      <ListTransactionAvatar
                        @click="showForm(item)"
                        :transaction="item"
                      />

                      <div
                        @click="showForm(item)"
                        class="transaction-content ml-5"
                      >
                        <ListTransactionContent
                          :resize="resize"
                          :transaction="item"
                        />
                      </div>

                      <v-menu
                        bottom
                        left
                        close-on-click
                        v-if="!isMobile && !invoice.paid"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            large
                            icon
                            v-on="on"
                            class="btn-menu ml-2"
                            :disabled="!allowed || creditCard.archived"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click="showForm(item)" v-if="!item.isRefund">
                            <v-list-item-icon>
                              <v-icon color="white">mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Editar</v-list-item-title>
                          </v-list-item>

                          <v-list-item @click="remove(item)" v-if="!item.pluggy && !creditCard.pluggy">
                            <v-list-item-icon>
                              <v-icon color="error">mdi-trash-can-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Remover</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>

                  <template
                    v-slot:right="{ item, close }"
                    v-if="isMobile && !invoice.paid"
                  >
                    <div class="swipeout-action" v-if="!item.pluggy && !creditCard.pluggy">
                      <v-btn
                        icon
                        large
                        @click="
                          close();
                          remove(item);
                        "
                        :disabled="!allowed || creditCard.archived"
                      >
                        <v-icon color="error">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>

                    <div class="swipeout-action" v-if="!item.isRefund">
                      <v-btn
                        icon
                        large
                        @click="
                          close();
                          showForm(item);
                        "
                        :disabled="!allowed || creditCard.archived"
                      >
                        <v-icon color="white">mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </swipe-list>

                <v-divider></v-divider>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { groupBy, orderBy } from 'lodash';
import { SwipeList } from 'vue-swipe-actions';
import currency from '@/utils/currency';
import removeMoneyMask from '@/utils/removeMoneyMask';
import exportCsv from '@/api/files/exportCsv';
import ListTransactionAvatar from "@/components/Customer/Cashflow/lists/avatar/ListTransactionAvatar";
import ListTransactionContent from "@/components/Customer/Cashflow/lists/content/ListTransactionContent";

export default {
  name: 'InvoiceTransactionsList',
  props: ['invoiceId', 'creditCard', 'period'],
  components: {ListTransactionContent, ListTransactionAvatar, SwipeList },
  data: () => ({
    currency,
    filter: {
      category: 'all',
      subCategory: 'all'
    },
    loaderExport: false,
    menuFilter: false,
    resize: ''
  }),
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    allowed() {
      return this.$store.getters.allowed;
    },
    transactions() {
      let transactions = this.$store.getters.transactions.filter(tr => {
        return (
          !tr.isInvoicePayment &&
          tr.invoice &&
          (tr.invoice === this.invoiceId || tr.invoice._id === this.invoiceId) &&
          (this.filter.category === 'all' || tr.category._id === this.filter.category) &&
          (this.filter.subCategory === 'all' || tr.subCategory._id === this.filter.subCategory)
        )
      });

      if (this.filter.category === 'all' && this.filter.subCategory === 'all') {
        const refunds = this.invoice.refunds.map(refund => {
          return {
            _id: refund._id,
            type: 'entrada',
            isRefund: true,
            value: refund.value * -1,
            date: this.$date(refund.date).format('YYYY-MM-DD'),
            category: {
              name: 'Reembolso',
              color: 'success',
              icon: 'mdi-cash'
            },
            subCategory: { name: 'Reembolso' },
            obs: refund.description
          }
        })

        transactions = transactions.concat(refunds)
      }

      return transactions
    },
    groupedTransactions() {
      return groupBy(this.transactions, tr => this.$date(tr.date).format('YYYY-MM-DD'));
    },
    transactionsDates() {
      return orderBy(Object.keys(this.groupedTransactions), k => k, 'desc');
    },
    categories() {
      let items = [
        {
          name: 'Todas',
          icon: 'mdi-horizontal-dots',
          _id: 'all',
          color: 'primary'
        }
      ];

      items = items.concat(this.$store.getters.categories);

      return items;
    },
    subCategories() {
      let items = [
        {
          name: 'Todas',
          _id: 'all'
        }
      ];

      if (this.filter.category !== 'all') {
        const category = this.$store.getters.categories.find(cat => cat._id === this.filter.category)

        if (category && category.subs) {
          items = items.concat(category.subs)
        }
      }

      return items;
    },
    invoice() {
      return this.$store.getters.invoices.find(inv => inv._id === this.invoiceId);
    },
    isCustomer() {
      return this.$store.getters.user && this.$store.getters.user.role === 'customer'
    },
    user() {
      return this.isCustomer ? this.$store.getters.user : this.$store.getters.customer
    }
  },
  methods: {
    exportCsv() {
      this.loaderExport = true

      const data = {
        userId: this.user._id,
        period: this.period.format('YYYY-MM-DD'),
        invoice: this.invoice._id,
        category: this.filter.category._id,
        subCategory: this.filter.subCategory._id
      };

      exportCsv(data, 'invoice', this.filter.category, this.filter.subCategory)
        .then(resp => {
          if (resp && resp.empty) {
            this.$noty.error('Não foi possível exportar, nenhuma transação encontrada!');
          }

          this.loaderExport = false;
        })
        .catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }

          this.loaderExport = false;
          this.$noty.error('Desculpe! Não foi possível exportar seu arquivo. Tente novamente mais tarde!');
        });
    },
    formatListDate(date) {
      return this.$date(date).format('ddd DD/MM/YYYY');
    },
    showForm(transaction) {
      if (this.allowed && this.creditCard && !this.creditCard.archived) {
        if (!this.invoice.paid) {
          if (this.creditCard.pluggy) {
            this.$bus.$emit('showDialogFormPendenciaCartao', transaction)
          } else {
            if (transaction.isRefund) {
              this.showDialogFormInvoiceRefund({
                value: removeMoneyMask(transaction.value) * -1,
                date: transaction.date,
                description: transaction.obs,
                _id: transaction._id
              });
            } else {
              this.$bus.$emit('showDialogFormCreditCardExpense', transaction);
            }
          }
        } else {
          this.paidAlert();
        }
      }
    },
    remove(transaction) {
      if (this.allowed && !this.creditCard.archived) {
        if (!this.invoice.paid) {
          if (transaction.isRefund) {
            this.$bus.$emit('showDialogRemoveInvoiceRefund', {
              invoiceId: this.invoice._id,
              refundId: transaction._id
            });
          } else {
            this.$bus.$emit('showDialogRemoveTransaction', transaction);
          }
        } else {
          this.paidAlert();
        }
      }
    },
    showDialogFormInvoiceRefund(refund) {
      if (this.allowed && !this.invoice.paid && !this.creditCard.archived) {
        this.$bus.$emit('showDialogFormInvoiceRefund', {
          refund,
          invoiceId: this.invoice._id,
          referenceDate: this.invoice.closeDate
        });
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.resize = window.innerWidth;
    });
  }
};
</script>

<style scoped>
.container {
  max-width: 768px;
}

.invoice-tr-list-title {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.list-title {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .1em;
}

.lists-container {
  width: 100%;
  min-height: 1px;
}

.transaction {
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  transition: ease all 0.8s;
  display: flex;
  align-items: center;
}

.transaction-content {
  width: 100%;
}

.transaction-description {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.transaction-description--text {
  font-size: 12px !important;
  letter-spacing: 0.1em;
}

.transaction-description--value {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

.transaction-category {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #9e9e9e;
}

.transaction-category--desc {
  font-size: 12px;
  letter-spacing: 0.1em;
}

.transaction-obs {
  font-size: 12px !important;
  display: flex;
  align-items: flex-end;
  color: #9e9e9e;
  letter-spacing: 0.1em;
}

.transaction-obs .v-icon {
  color: #9e9e9e !important;
  margin-right: 5px !important;
  margin-bottom: 4px !important;
}

.swipeout-action {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #131313;
}

.swipeout-action:nth-child(1) {
  margin-left: 10px;
}

@media only screen and (min-width: 375px) {
  .transaction-description--value span {
    font-size: 12px;
  }

}

@media only screen and (min-width: 414px) {
  .transaction-description--value {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .invoice-list-header .invoice-list-header > span,
  .list-title {
    font-size: 15px;
  }

  .transaction-description--text {
    font-size: 15px !important;
  }

  .transaction-description--value {
    font-size: 18px;
  }

  .transaction-description--value span {
    font-size: 12px;
  }

  .transaction-category--icon .v-avatar .v-icon {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 960px) {
  .v-btn.btn-filtros {
    font-size: 15px !important;
    margin-left: 10px;
    text-transform: none;
    letter-spacing: 0.1em;
  }

  .v-subheader.list-title {
    padding-left: 10px !important;
    font-size: 18px;
  }

  .transactions-list {
    padding: 30px;
  }

  .transaction {
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    transition: ease all 1s !important;
  }

  .transaction-description--value {
    font-size: 20px;
  }
}
</style>
