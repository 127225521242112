<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isMobile"
    max-width="768px"
  >
    <v-card :class="isMobile ? 'no-radius' : ''" color="grey4">
      <v-card-title
        class="darkgrey"
        :class="[isMobile ? 'mobile' : '', isIOS ? 'ios-padding' : '']"
      >
        <v-btn
          icon
          color="primary"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="primary--text dialog-title">Detalhes da fatura</span>
        <v-btn icon></v-btn>
      </v-card-title>

      <v-system-bar
        dark
        lights-out
        :color="invoiceStatusColor"
        :class="isMobile ? 'mobile' : ''"
      >
        <v-spacer></v-spacer>

        <span color="white" class="status-bar-title">Fatura {{ invoiceStatusName }}</span>

        <v-spacer></v-spacer>
      </v-system-bar>

      <v-card-text v-if="invoice" class="px-2" :class="!isMobile ? 'no-mobile' : ''">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm4 md4 class="d-flex align-center">
              <v-avatar class="mr-2" color="darkgrey">
                <v-icon color="grey1">mdi-calendar</v-icon>
              </v-avatar>

              <div class="content d-flex flex-column align-start">
                <span class="content--value">{{ formatDate(invoice.created_at_iso) }}</span>
                <span class="content--desc primary--text">Data de emissão</span>
              </div>
            </v-flex>

            <v-flex xs12 sm4 md4 class="d-flex align-center">
              <v-avatar class="mr-2" color="darkgrey">
                <v-icon color="grey1">mdi-calendar-alert</v-icon>
              </v-avatar>

              <div class="content d-flex flex-column align-start">
                <span class="content--value">{{ formatDate(invoice.due_date) }}</span>
                <span class="content--desc primary--text">Data de vencimento</span>
              </div>
            </v-flex>

            <v-flex xs12 sm4 md4 class="d-flex align-center" v-if="invoice.paid_at">
              <v-avatar class="mr-2" color="darkgrey">
                <v-icon color="grey1">mdi-calendar-check-outline</v-icon>
              </v-avatar>

              <div class="content d-flex flex-column align-start">
                <span class="content--value">{{ formatDate(invoice.paid_at) }}</span>
                <span class="content--desc primary--text">Data de pagamento</span>
              </div>
            </v-flex>

            <v-flex xs12 sm4 md4 class="d-flex align-center" v-if="invoice.canceled_at">
              <v-avatar class="mr-2" color="darkgrey">
                <v-icon color="grey1">mdi-calendar-remove-outline</v-icon>
              </v-avatar>

              <div class="content d-flex flex-column align-start">
                <span class="content--value">{{ formatDate(invoice.canceled_at) }}</span>
                <span class="content--desc primary--text">Data de cancelamento</span>
              </div>
            </v-flex>

            <v-flex
              xs12
              v-for="(item, index) in employeesData"
              :key="index"
              class="mt-5"
            >
              <h1 class="item-title primary--text">{{ item.description }}</h1>

              <v-layout row wrap>
                <v-flex xs12 sm4 class="d-flex align-center">
                  <v-avatar class="mr-2" color="darkgrey">
                    <v-icon color="grey1">mdi-cash-multiple</v-icon>
                  </v-avatar>

                  <div class="content d-flex flex-column align-start">
                    <span class="content--value">{{ item.price }}</span>
                    <span class="content--desc primary--text">Preço unitário</span>
                  </div>
                </v-flex>

                <v-flex xs12 sm4 class="d-flex align-center">
                  <v-avatar class="mr-2" color="darkgrey">
                    <v-icon color="grey1">mdi-account-group-outline</v-icon>
                  </v-avatar>

                  <div class="content d-flex flex-column align-start">
                    <span class="content--value">{{ item.quantity }}</span>
                    <span class="content--desc primary--text">Colaboradores</span>
                  </div>
                </v-flex>

                <v-flex xs12 sm4 class="d-flex align-center">
                  <v-avatar class="mr-2" color="darkgrey">
                    <v-icon color="grey1">mdi-cash-multiple</v-icon>
                  </v-avatar>

                  <div class="content d-flex flex-column align-start">
                    <span class="content--value">{{ item.totalPrice }}</span>
                    <span class="content--desc primary--text">Sub-total</span>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12 class="d-flex align-center mt-10 mb-10 mb-md-0">
              <v-avatar class="mr-2" color="darkgrey">
                <v-icon color="grey1">mdi-cash-multiple</v-icon>
              </v-avatar>

              <div class="content d-flex flex-column align-start">
                <span class="content--value-total">{{ invoiceTotal }}</span>
                <span class="content--desc-total primary--text">Preço total</span>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-btn
        dark
        block
        large
        :href="invoice.secure_url"
        target="_blank"
        color="purple"
        :class="isMobile ? 'mobile' : ''"
        class="invoice-btn elevation-6"
        v-if="invoice && invoice.status === 'pending'"
      >
        {{ invoice.status === 'pending' ? 'Pagar' : 'Acessar' }} fatura
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import numberToCurrency from '@/utils/numberToCurrency'

export default {
  name: 'DialogCompanyInvoice',
  data: () => ({
    dialog: false,
    invoice: null,
    numberToCurrency
  }),
  created() {
    this.$bus.$on('showDialogCompanyInvoice', this.showDialog)
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isIOS() {
      return this.$store.getters.isIOS
    },
    invoiceStatusName() {
      if (this.invoice) {
        switch (this.invoice.status) {
          case 'canceled':
            return 'Cancelada';
          case 'paid':
            return 'Paga';
          default:
            return 'Pendente';
        }
      }

      return ''
    },
    invoiceStatusColor() {
      if (this.invoice) {
        switch (this.invoice.status) {
          case 'canceled':
            return 'error';
          case 'paid':
            return 'success';
          default:
            return 'warning';
        }
      }

      return ''
    },
    invoiceStatusIcon() {
      if (this.invoice) {
        switch (this.invoice.status) {
          case 'canceled':
            return 'mdi-progress-close';
          case 'paid':
            return 'mdi-progress-check';
          default:
            return 'mdi-progress-alert'
        }
      }

      return ''
    },
    invoiceTotal() {
      let total = 0

      if (this.invoice && this.invoice.total_cents > 0) {
        total = parseFloat((this.invoice.total_cents / 100).toFixed(2))
      }

      return numberToCurrency(total)
    },
    employeesData() {
      if (this.invoice) {
        return this.invoice.items.map(item => {
          return {
            description: item.description,
            price: numberToCurrency((item.price_cents / 100)),
            quantity: item.quantity,
            totalPrice: numberToCurrency(((item.price_cents / 100) * item.quantity))
          }
        })
      }

      return []
    }
  },
  methods: {
    showDialog(invoice) {
      if (invoice && !this.dialog) {
        this.invoice = invoice
        this.dialog = true
      }
    },
    formatDate(date) {
      if (date) {
        return this.$date(date).format('DD/MM/YYYY')
      }

      return ''
    }
  }
}
</script>

<style scoped>
.v-card__title {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.v-card__title.mobile {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
}

.v-system-bar.mobile {
  margin-top: 58px;
}

.dialog-title {
  font-size: 18px;
  letter-spacing: .1em;
  font-weight: 500;
}

.content--value,
.content--desc {
  letter-spacing: .05em;
  line-height: 1.4em;
}

.content--value-total {
  font-size: 20px;
}

.content--desc {
  font-size: 12px;
}

.status-bar-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: .05em;
  color: white;
}

.item-title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .05em;
}

.invoice-btn {
  border-radius: 5px 5px 0 0;
  padding-bottom: calc(10px + env(safe-area-inset-top)) !important;
  padding-top: 10px !important;
}

.invoice-btn.mobile {
  display: block;
  position: fixed;
  bottom: 0;
}
</style>
